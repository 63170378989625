import { v4 } from 'uuid';
import { InterventionItem } from 'models/api-response';
import { Box, ButtonLinkIcon } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSRadio, ELSTextBox } from 'components/common/els';
import { FormField, FormFieldLabel, InterventionField, InterventionStatus, SectionTitle } from './constants';

export interface InterventionItemsViewProps {
  interventionItems: InterventionItem[];
  onFormFieldChange: Function;
  onInterventionActionChange?: Function;
  onAddInterventionAction?: Function;
  onAddInterventionItem: Function;
  isSidebarView: boolean;
  focusedInterventionFieldId: string;
  resetFocusedInterventionFieldId: Function;
}

const InterventionItemsView = (props: InterventionItemsViewProps) => {
  const { interventionItems, onFormFieldChange, onAddInterventionItem, onAddInterventionAction, isSidebarView, focusedInterventionFieldId, resetFocusedInterventionFieldId } =
    props;

  const previousName = isSidebarView ? 'Sidebar-' : '';

  const handleBlurForInterventionFields = () => {
    if (focusedInterventionFieldId) {
      resetFocusedInterventionFieldId();
    }
  };

  return (
    <Box>
      <h3 className="u-els-margin-bottom">{SectionTitle.INTERVENTIONS}</h3>
      {interventionItems?.map((item, index) => (
        <Box key={v4()}>
          <ELSFlex className="u-flex-gutter-3x u-els-margin-bottom-2x" wrap left>
            <ELSFlexItem w="1o1">
              <ELSTextBox
                value={item.name}
                autoFocus={focusedInterventionFieldId === `${InterventionField.NAME}-${index}`}
                changeHandler={(event) => onFormFieldChange(InterventionField.NAME, event, index)}
                blurHandler={() => {
                  handleBlurForInterventionFields();
                }}
              >
                {FormFieldLabel.INTERVENTION}
              </ELSTextBox>
            </ELSFlexItem>
          </ELSFlex>
          <Box ml2>
            <ELSFlex className="u-flex-gutter-3x u-els-margin-bottom-2x" wrap left>
              <ELSFlexItem>
                <ELSRadio
                  checked={item.status === InterventionStatus.COMPLETE}
                  changeHandler={(event) => onFormFieldChange(InterventionField.STATUS, event, index)}
                  name={`${previousName}${FormField.INTERVENTION_STATUS_COMPLETE}-${index}`}
                  value={InterventionStatus.COMPLETE}
                >
                  {FormFieldLabel.INTERVENTION_STATUS_COMPLETE}
                </ELSRadio>
              </ELSFlexItem>
              <ELSFlexItem>
                <ELSRadio
                  checked={item.status === InterventionStatus.ONGOING}
                  changeHandler={(event) => onFormFieldChange(InterventionField.STATUS, event, index)}
                  name={`${previousName}${FormField.INTERVENTION_STATUS_ONGOING}-${index}`}
                  value={InterventionStatus.ONGOING}
                >
                  {FormFieldLabel.INTERVENTION_STATUS_ONGOING}
                </ELSRadio>
              </ELSFlexItem>
              <ELSFlexItem>
                <ELSRadio
                  checked={item.status === InterventionStatus.DISCONTINUED}
                  changeHandler={(event) => onFormFieldChange(InterventionField.STATUS, event, index)}
                  name={`${previousName}${FormField.INTERVENTION_STATUS_DISCONTINUED}-${index}`}
                  value={InterventionStatus.DISCONTINUED}
                >
                  {FormFieldLabel.INTERVENTION_STATUS_DISCONTINUED}
                </ELSRadio>
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="u-flex-gutter-3x u-els-margin-bottom-2x" wrap left>
              <ELSFlexItem w="1o1">
                <ELSTextBox
                  value={item.rationale}
                  autoFocus={focusedInterventionFieldId === `${InterventionField.RATIONALE}-${index}`}
                  changeHandler={(event) => onFormFieldChange(InterventionField.RATIONALE, event, index)}
                  blurHandler={() => {
                    handleBlurForInterventionFields();
                  }}
                >
                  {FormFieldLabel.RATIONALE}
                </ELSTextBox>
              </ELSFlexItem>
            </ELSFlex>
            {isSidebarView && (
              <>
                {item.actions?.map((action, actionIndex) => (
                  <ELSFlex key={v4()} className="u-flex-gutter-3x u-els-margin-bottom-2x" wrap left>
                    <ELSFlexItem w="1o1">
                      <ELSTextBox
                        value={action}
                        autoFocus={focusedInterventionFieldId === `${InterventionField.ACTIONS}-${index}-${actionIndex}`}
                        changeHandler={(event) => onFormFieldChange(InterventionField.ACTIONS, event, index, actionIndex)}
                        blurHandler={() => {
                          handleBlurForInterventionFields();
                        }}
                      >
                        {FormFieldLabel.INTERVENTION_ACTION}
                      </ELSTextBox>
                    </ELSFlexItem>
                  </ELSFlex>
                ))}
                <ButtonLinkIcon
                  iconName="plus"
                  iconSize="3o4"
                  className="u-els-margin-bottom-2x"
                  text="add additional intervention action"
                  onClick={() => onAddInterventionAction(index)}
                  preventAutoPending
                />
              </>
            )}
          </Box>
        </Box>
      ))}
      <ButtonLinkIcon iconName="plus" iconSize="3o4" className="u-els-margin-bottom-2x" text="add additional intervention" onClick={onAddInterventionItem} preventAutoPending />
    </Box>
  );
};

InterventionItemsView.displayName = 'InterventionItemsView';
export default InterventionItemsView;

import { LanguageKeys } from 'lang';
import { get, includes, toNumber, uniq } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { VitalSignsRecord } from 'models/api-response';
import { DropDownItem } from 'models/ui';
import { Locales, VitalSign } from 'constants/app.constant';
import { dateTimeHelper, unitConverter } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import VitalSignsChartView, { VitalSignsChartViewProps } from './VitalSignsChartView';
import AtoEAssessmentChart, { AtoEAssessmentChartProps } from './au/AtoEAssessmentChart';
import { getScoreByDataKey } from './helpers';
import { ChartField, VitalSignChartConfig, VitalSignsChartDataKey, VitalSignsChartList } from './types';

export interface VitalSignsChartProps {
  chartHistory: VitalSignsRecord[];
  locale: Locales;
  intl: IntlShape;
  isTitleHidden?: boolean;
  displaySingleChartOnSummaryPage?: boolean;
}
interface VitalSignsChartState {
  selectedChart: string;
  secondSelectedChart?: string;
}

class VitalSignsChart extends Component<VitalSignsChartProps, VitalSignsChartState> {
  charts: DropDownItem[] = [];
  chartConfig: VitalSignChartConfig;
  fieldUnit = {};

  constructor(props: VitalSignsChartProps) {
    super(props);
    const { intl, locale } = props;
    if (locale === Locales.EN_AU) {
      this.state = {
        selectedChart: ChartField.PULSE,
        secondSelectedChart: ChartField.BLOODPRESSURE
      };
      this.charts = [
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.RESPIRATION, value: ChartField.RESPIRATION },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.OXYGENATION, value: ChartField.OXYGENATION },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.BLOOD_PRESSURE, value: ChartField.BLOODPRESSURE },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.PULSE, value: ChartField.PULSE },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.TEMPERATURE, value: ChartField.TEMPERATURE }
      ];
    } else {
      this.state = {
        selectedChart: ChartField.TEMPERATURE
      };
      this.charts = [
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.TEMPERATURE, value: ChartField.TEMPERATURE },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.PULSE, value: ChartField.PULSE },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.RESPIRATION, value: ChartField.RESPIRATION },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.BLOOD_PRESSURE, value: ChartField.BLOODPRESSURE },
        { name: LanguageKeys.VITAL_SIGNS.GRAPH_NAME.OXYGENATION, value: ChartField.OXYGENATION }
      ];
    }

    this.charts = this.charts.map((item) => ({ ...item, name: intl.formatMessage({ id: item.name }) }));

    this.fieldUnit = {
      [ChartField.TEMPERATURE]: intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.TEMPERATURE_UNIT }),
      [ChartField.PULSE]: 'BPM',
      [ChartField.RESPIRATION]: '/min',
      [ChartField.BLOODPRESSURE]: 'mmHg'
    };

    const graphWidth = intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.GRAPH_WIDTH });
    const formattedGraphWidth = includes(graphWidth, '%') ? graphWidth : toNumber(graphWidth);
    this.chartConfig = {
      [ChartField.TEMPERATURE]: {
        width: formattedGraphWidth,
        yLabel: intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.TEMPERATURE_AXIS_LABEL }),
        dataKeys: [VitalSignsChartDataKey.TEMPERATURE],
        data: []
      },
      [ChartField.PULSE]: {
        width: formattedGraphWidth,
        yLabel: 'Pulse (BPM)',
        dataKeys: [VitalSignsChartDataKey.PULSE],
        data: []
      },
      [ChartField.RESPIRATION]: {
        width: formattedGraphWidth,
        yLabel: 'Respiration (Respirations/min)',
        dataKeys: [VitalSignsChartDataKey.RESPIRATION],
        data: []
      },
      [ChartField.BLOODPRESSURE]: {
        width: formattedGraphWidth,
        yLabel: 'Blood Pressure (mmHg)',
        dataKeys: [VitalSignsChartDataKey.SYSTOLIC, VitalSignsChartDataKey.DIASTOLIC],
        data: []
      },
      [ChartField.OXYGENATION]: {
        width: formattedGraphWidth,
        yLabel: 'Oxygen Saturation %',
        dataKeys: [VitalSignsChartDataKey.SATURATION],
        data: []
      }
    };
  }

  handleChartChange = ({ target: { value, name } }) => {
    if (name === VitalSignsChartList.FIRST_CHART) {
      this.setState({ selectedChart: value });
    }
    if (name === VitalSignsChartList.SECOND_CHART) {
      this.setState({ secondSelectedChart: value });
    }
  };

  getOxygenationUnit = (item) => {
    const {
      choice,
      value,
      method: { choice: methodChoice }
    } = item?.oxygenation?.delivery;
    if (!choice) return '%';

    if (choice === VitalSign.VitalSign.Oxygenation.ROOM_AIR) return '%, Room Air';

    if (choice === VitalSign.VitalSign.Oxygenation.OXYGEN_LMIN) return `%, Oxygen in Use ${value || ''} L/min, ${methodChoice}`;

    if (choice === VitalSign.VitalSign.Oxygenation.OXYGEN_PERCENT) return `%, Oxygen in Use ${value || ''}%, ${methodChoice}`;

    return '';
  };

  getTemperatureValue = (item: VitalSignsRecord): number => {
    const { value: temperatureFahrenheit } = item?.temperature;
    const temperatureCelsius = unitConverter.convertFahrenheitToCelsius(temperatureFahrenheit.toString());
    return this.props.intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.TEMPERATURE_VALUE }, { temperatureFahrenheit, temperatureCelsius });
  };

  buildChartData = (field: string) => {
    const { chartHistory, intl, locale } = this.props;
    return chartHistory
      .filter((item) => item.active)
      .filter((item) => get(item, [field, 'value']) || this.chartConfig[field].dataKeys.some((key) => get(item, uniq([field, key, 'value']))))
      .map((item) =>
        Object.assign(
          {},
          {
            date: dateTimeHelper.formatDate({ date: moment(item.chartingAt).toDate(), locale }),
            dateTime: dateTimeHelper.toMoment(item.chartingAt).format(intl.formatMessage({ id: LanguageKeys.GRAPH_DATE_TIME_FORMAT }))
          },
          ...this.chartConfig[field].dataKeys.map((key) => {
            const unit = field === ChartField.OXYGENATION ? this.getOxygenationUnit(item) : this.fieldUnit[field];
            const value = field === ChartField.TEMPERATURE ? this.getTemperatureValue(item) : Number(get(item, uniq([field, key, 'value'])));
            if (key !== VitalSignsChartDataKey.DIASTOLIC && locale === Locales.EN_AU) {
              return {
                [key]: value,
                [`${key}Display`]: `${value} ${unit}`,
                score: getScoreByDataKey(value, key)
              };
            }
            return {
              [key]: value,
              [`${key}Display`]: `${value} ${unit}`
            };
          })
        )
      );
  };

  buildChartConfig = (field: string) => ({ ...this.chartConfig[field], data: this.buildChartData(field) });

  render() {
    const { selectedChart, secondSelectedChart } = this.state;
    const { isTitleHidden, locale, displaySingleChartOnSummaryPage } = this.props;

    if (locale === Locales.EN_AU) {
      const chartConfig = this.buildChartConfig(selectedChart);
      const secondChartConfig = this.buildChartConfig(secondSelectedChart);
      const viewProps: AtoEAssessmentChartProps = {
        chartConfig,
        secondChartConfig,
        selectedChart,
        secondSelectedChart,
        charts: this.charts,
        onChartChange: this.handleChartChange,
        isTitleHidden,
        locale,
        displaySingleChartOnSummaryPage
      };
      return <AtoEAssessmentChart {...viewProps} />;
    }

    const chartConfig = this.buildChartConfig(selectedChart);
    const viewProps: VitalSignsChartViewProps = {
      chartConfig,
      selectedChart,
      charts: this.charts,
      onChartChange: this.handleChartChange,
      isTitleHidden,
      locale
    };
    return <VitalSignsChartView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});

export { VitalSignsChart as BaseVitalSignsChart };
export default compose(injectIntl, connect(mapStateToProps))(VitalSignsChart);

import cx from 'classnames';
import { isEmpty, orderBy, sumBy } from 'lodash';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose, withHandlers, withState } from 'recompose';
import { AssignmentTopic, CaseStudy, Step } from 'models/ui';
import { apiHelper, appHelper, caseStudyHelper } from 'helpers';
import { resetCaseStudyDemoStorage } from 'helpers/caseStudy.helper';
import { caseStudyService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { instructorActions, instructorSelectors } from 'redux/ducks/instructor';
import { Avatar, ButtonLinkIcon, ProductFilter } from 'components/common';
import { ELSButton, ELSDataTable, ELSFlex, ELSFlexItem, ELSPageLoader, ELSRadio } from 'components/common/els';
import CaseStudyQuickView from './CaseStudyQuickView';
import './case.study.content.scss';

interface CaseStudyContentProps {
  isLoading: boolean;
  assignmentTopics: AssignmentTopic[];
  caseStudies: CaseStudy[];
  selectedCaseStudy: CaseStudy;
  prevCaseStudyDemo: CaseStudy;
  setCaseStudies: Function;
  setSelectedCaseStudy: Function;
  setIsLoading: Function;
  onRowAction: Function;
  onRowSelection: Function;
  resetCaseStudyDemo: Function;
  courseId: string;
  steps: Step[];
  isBookOrganized: boolean;
  setNewAssignment: Function;
}

export const CaseStudyContent = (props: CaseStudyContentProps & RouteComponentProps) => {
  const {
    assignmentTopics,
    onRowSelection,
    isLoading,
    setIsLoading,
    caseStudies,
    setCaseStudies,
    selectedCaseStudy,
    prevCaseStudyDemo,
    setSelectedCaseStudy,
    resetCaseStudyDemo,
    onRowAction,
    courseId
  } = props;

  const [visibleCaseStudies, setVisibleCaseStudies] = useState(caseStudies);
  const [isProductFiltersEnable, setProductFiltersEnable] = useState(false);
  const [productFilters, setProductFilters] = useState([]);
  const [productsToFilter, setProductsToFilter] = useState([]);

  const handleCaseStudy = (caseStudy) => {
    if (caseStudy) {
      onRowSelection(caseStudy);
      const { nextStep } = appHelper.getStepInfo(props.steps);
      props.history.push(nextStep.route);
    }
  };

  const handleCloseSideBar = () => {
    setSelectedCaseStudy(null);
    resetCaseStudyDemoStorage();
    resetCaseStudyDemo();
  };

  useEffect(() => {
    const fetchCaseStudiesWithProductMapping = async () => {
      setIsLoading(true);

      const { data: caseStudiesData } = await caseStudyService.getCaseStudies(courseId);
      const { isStandalone, caseStudyProductMapping, products } = await caseStudyHelper.getCaseStudyProductsMapping(courseId);

      const newCaseStudies = caseStudiesData.map((item) => ({ ...item, isSelected: false, product: caseStudyProductMapping[item.id] }));
      const parsedQuery = queryString.parse(props?.history?.location?.search);
      const { caseStudyId } = parsedQuery;
      const caseStudy = caseStudyId && newCaseStudies.find(({ id }) => id === caseStudyId);

      handleCaseStudy(caseStudy);
      setProductFiltersEnable(isStandalone);
      setCaseStudies(newCaseStudies);
      setVisibleCaseStudies(newCaseStudies);
      setProductsToFilter(products);

      if (prevCaseStudyDemo) {
        setSelectedCaseStudy(prevCaseStudyDemo);
      }

      setIsLoading(false);
    };

    fetchCaseStudiesWithProductMapping().catch((err) => {
      apiHelper.showApiError(`can not get case studies ${err}`);
    });
  }, []);

  useEffect(() => {
    const newCaseStudies = !isEmpty(productFilters)
      ? caseStudies.filter((caseStudy) => {
          return productFilters.includes(caseStudy?.product?.name);
        })
      : caseStudies;
    setVisibleCaseStudies(newCaseStudies);
  }, [productFilters]);

  const id = assignmentTopics[0]?.vtwId;
  const sorter = (caseStudy) => Number(caseStudy.sherpathModule?.match(/\d+/g)?.[0]);
  const comingSoonCount = sumBy(caseStudies, (a) => a.isComingSoon);
  const newTableData = useMemo(() => {
    const sortedCaseStudies = orderBy(visibleCaseStudies, sorter);
    const seenTitles = new Set();
    const practiceAssignmentTitle = 'SimChart Practice Assignment';
    const deduplicatedCaseStudies = sortedCaseStudies.filter((item) => {
      if (item.title === practiceAssignmentTitle) {
        const notSeenBefore = !seenTitles.has(practiceAssignmentTitle);
        seenTitles.add(practiceAssignmentTitle);
        return notSeenBefore;
      }
      return true;
    });
    const practiceAssignmentIndex = deduplicatedCaseStudies.findIndex((item) => item.title === practiceAssignmentTitle);
    const reorderedCaseStudies =
      practiceAssignmentIndex !== -1
        ? [
            deduplicatedCaseStudies[practiceAssignmentIndex],
            ...deduplicatedCaseStudies.slice(0, practiceAssignmentIndex),
            ...deduplicatedCaseStudies.slice(practiceAssignmentIndex + 1)
          ]
        : deduplicatedCaseStudies;

    return reorderedCaseStudies.map((item) => ({
      ...item,
      isSelected: item.id === id,
      cssModifier: item.isComingSoon ? 'comingSoonStyle' : ''
    }));
  }, [id, visibleCaseStudies, caseStudies]);

  const radioRenderer = (row) => (
    <ELSRadio id={row.id} name={row.id} value={row.id} checked={row.isSelected} changeHandler={() => onRowSelection(row)} isDisabled={row.isComingSoon}>
      <></>
    </ELSRadio>
  );

  const patientRenderer = (row) => (
    <ELSFlex middle left>
      <ELSFlexItem>
        <Avatar className="patient-avatar u-els-text-center" name={`${row?.patient?.firstName?.charAt(0)}${row?.patient?.lastName?.charAt(0)}`} />
      </ELSFlexItem>
      <ELSFlexItem>
        <span className="patient-name">{`${row?.patient?.firstName} ${row?.patient?.lastName}`}</span>
      </ELSFlexItem>
    </ELSFlex>
  );

  const chapterRenderer = (row) => <b>{row.sherpathModule}</b>;

  const actionsRenderer = (row) => {
    if (row.isComingSoon) {
      return (
        <ELSButton
          type="debuttonize"
          iconName="enlarge"
          iconPosition="right"
          iconSize="1x"
          text="Quick view"
          className={cx('c-els-button--with-icon c-els-button--middle c-els-button--with-icon-right', 'comingSoonStyle')}
        />
      );
    }

    return <ButtonLinkIcon iconName="enlarge" iconPosition="right" iconSize="1x" text="Quick view" onClick={() => onRowAction(row)} />;
  };

  return (
    <div className="case-study-content">
      {isLoading && <ELSPageLoader />}
      {!isLoading && isProductFiltersEnable && <ProductFilter setProductFilters={setProductFilters} products={productsToFilter} />}
      <ELSDataTable data={newTableData} cssModifier="c-els-table--row-highlight c-els-table--border-bottom">
        <column header=" " customRender={radioRenderer} />
        {props.isBookOrganized && <column header="Chapter" sortable field="chapter" customRender={chapterRenderer} />}
        <column header="Title" field="title" customRender={(row) => `${row.title}${row.isComingSoon ? ' (coming soon)' : ''}`} />
        <column header="Diagnosis" field="diagnosis" />
        <column header="Concepts (Giddens)" field="concept" />
        <column header="Patient" field="patient" customRender={patientRenderer} />
        <column header="Action" field="action" customRender={actionsRenderer} />
      </ELSDataTable>
      <p className="u-els-italic u-els-color-n9 u-els-padding">
        {`${caseStudies.length - comingSoonCount} SimChart Case Studies available`}
        {comingSoonCount > 0 && `, ${comingSoonCount} SimChart Case Studies coming soon`}
      </p>

      <CaseStudyQuickView
        selectedCaseStudy={selectedCaseStudy}
        initialShowPreview={!!prevCaseStudyDemo}
        onSelectClick={() => handleCaseStudy(selectedCaseStudy)}
        onCloseSideBar={handleCloseSideBar}
        isBookOrganized={props.isBookOrganized}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  assignmentTopics: instructorSelectors.getAssignmentTopics(state),
  prevCaseStudyDemo: instructorSelectors.getCaseStudyDemo(state),
  courseId: appSelectors.getCourseId(state),
  isBookOrganized: instructorSelectors.getIsBookOrganized(state)
});

const mapDispatchToProps = (dispatch) => ({
  setNewAssignment: (payload) => dispatch(instructorActions.setNewAssignment(payload)),
  resetCaseStudyDemo: () => dispatch(instructorActions.setCaseStudyDemo(null))
});

export const onRowSelectionHandler = (props) => (row) => {
  const { setNewAssignment } = props;
  const topic = { vtwId: row.id, text: row.diagnosis };
  setNewAssignment({ assignmentTopics: [topic], caseStudyId: row.id, title: row.title });
};

export const handleRowAction = (props) => (row) => {
  const { setSelectedCaseStudy } = props;
  setSelectedCaseStudy(row);
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', false),
  withState('caseStudies', 'setCaseStudies', []),
  withState('selectedCaseStudy', 'setSelectedCaseStudy', null),
  withHandlers({
    onRowSelection: onRowSelectionHandler,
    onRowAction: handleRowAction
  })
)(CaseStudyContent);

import { ChartFragment } from 'models/api-response';
import { Sidebar } from 'components/common';
import DataReviewSidebar from './DataReviewSidebar';

export interface SavedVitalSignsProps {
  isSidebarOpen: boolean;
  selectedRecord: ChartFragment;
  navId: string;
  onClose: () => void;
  loadData: () => Promise<void>;
}

export const SavedVitalSignsReview = ({ isSidebarOpen, selectedRecord, onClose, navId, loadData }: SavedVitalSignsProps) => {
  return (
    <Sidebar open={isSidebarOpen} onClose={onClose}>
      <DataReviewSidebar fragment={selectedRecord} navId={navId} loadData={loadData} />
    </Sidebar>
  );
};

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartLabel, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import DrugScreenView from './DrugScreenView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export enum SectionTitle {
  PRESCRIPTION_DRUG_USE = 'Street/Recreational/Excessive Prescription Drug Use',
  USING = 'Using',
  PROVIDER_NOTIFICATION = 'Provider Notification',
  QUIT = 'Quit'
}

export enum FormField {
  PRESCRIPTION_DRUG_USE = 'streetRecreationalExcessivePrescriptionDrugUse',
  DRUGS_CURRENTLY_USING = 'drugsCurrentlyUsing',
  DRUGS_CURRENTLY_USING_OTHER = 'drugsCurrentlyUsingOther',
  AMOUNT_STREET_DRUGS = 'amountOfStreetDrugsEachDay',
  USED_DRUGS_PAST_24_HOURS = 'usedAnyOfTheseDrugsPast24Hours',
  HOW_LONG_USED_DRUGS = 'howLongUsedStreetDrugs',
  PLAN_TO_QUIT = 'doYouPlanToQuit',
  QUIT_DATE = 'quitDate',
  PROVIDER_NOTIFIED = 'providerNotifiedPositiveScreen',
  PROVIDER_NAME = 'providerName',
  HOW_LONG_QUIT = 'howLongDidYouQuit'
}

interface DrugScreenState {
  isLocked: boolean;
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class DrugScreen extends Component<ChartComponentProps, DrugScreenState> {
  static displayName = 'DrugScreen';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.DRUG_SCREEN, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField, getFragmentValue, getFragmentContentIds } = chartHelper;
    const { fragment } = this.state;
    const dataMap = new Map();
    dataMap.set(
      FormField.PRESCRIPTION_DRUG_USE,
      createFormField({
        name: FormField.PRESCRIPTION_DRUG_USE,
        type: FormFieldInputType.RADIO_CHOICE,
        contentIds: getFragmentContentIds(fragment, SectionTitle.PRESCRIPTION_DRUG_USE, FormField.PRESCRIPTION_DRUG_USE)
      })
    );
    dataMap.set(
      FormField.DRUGS_CURRENTLY_USING,
      createFormField({
        name: FormField.DRUGS_CURRENTLY_USING,
        type: FormFieldInputType.MULTI_SELECT_RADIO,
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.DRUGS_CURRENTLY_USING),
        label: ChartLabel.MARK_ALL_THAT_APPLY
      })
    );
    dataMap.set(
      FormField.DRUGS_CURRENTLY_USING_OTHER,
      createFormField({
        name: FormField.DRUGS_CURRENTLY_USING_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.USING, FormField.DRUGS_CURRENTLY_USING_OTHER),
        label: 'Other'
      })
    );
    dataMap.set(
      FormField.AMOUNT_STREET_DRUGS,
      createFormField({
        name: FormField.AMOUNT_STREET_DRUGS,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.USING, FormField.AMOUNT_STREET_DRUGS),
        label: 'What is the amount of street drugs used each day?'
      })
    );
    dataMap.set(
      FormField.USED_DRUGS_PAST_24_HOURS,
      createFormField({
        name: FormField.USED_DRUGS_PAST_24_HOURS,
        type: FormFieldInputType.RADIO_CHOICE,
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.USED_DRUGS_PAST_24_HOURS),
        label: 'Have you used any of these drugs within the past 24 hours?'
      })
    );
    dataMap.set(
      FormField.HOW_LONG_USED_DRUGS,
      createFormField({
        name: FormField.HOW_LONG_USED_DRUGS,
        type: FormFieldInputType.MULTI_SELECT_RADIO,
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.HOW_LONG_USED_DRUGS),
        label: 'How long have you used street drugs?'
      })
    );
    dataMap.set(
      FormField.PLAN_TO_QUIT,
      createFormField({
        name: FormField.PLAN_TO_QUIT,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.PLAN_TO_QUIT),
        label: 'Do you plan to quit?'
      })
    );
    dataMap.set(
      FormField.QUIT_DATE,
      createFormField({
        name: FormField.QUIT_DATE,
        type: FormFieldInputType.DATE,
        value: getFragmentValue(fragment, SectionTitle.USING, FormField.QUIT_DATE),
        label: 'When is your quit date?'
      })
    );
    dataMap.set(
      FormField.PROVIDER_NOTIFIED,
      createFormField({
        name: FormField.PROVIDER_NOTIFIED,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: getFragmentContentIds(fragment, SectionTitle.PROVIDER_NOTIFICATION, FormField.PROVIDER_NOTIFIED)
      })
    );
    dataMap.set(
      FormField.PROVIDER_NAME,
      createFormField({
        name: FormField.PROVIDER_NAME,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.PROVIDER_NOTIFICATION, FormField.PROVIDER_NAME),
        label: 'Provider Name'
      })
    );
    dataMap.set(
      FormField.HOW_LONG_QUIT,
      createFormField({
        name: FormField.HOW_LONG_QUIT,
        type: FormFieldInputType.DROPDOWN,
        value: getFragmentValue(fragment, SectionTitle.QUIT, FormField.HOW_LONG_QUIT),
        label: 'How long ago did you quit?'
      })
    );
    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Drug Screen';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.PRESCRIPTION_DRUG_USE,
          records: [buildPatientRecord(formFieldMap, FormField.PRESCRIPTION_DRUG_USE)]
        },
        {
          parentSectionTitle: SectionTitle.PRESCRIPTION_DRUG_USE,
          sectionTitle: SectionTitle.USING,
          records: [
            buildPatientRecord(formFieldMap, FormField.DRUGS_CURRENTLY_USING),
            buildPatientRecord(formFieldMap, FormField.DRUGS_CURRENTLY_USING_OTHER),
            buildPatientRecord(formFieldMap, FormField.AMOUNT_STREET_DRUGS),
            buildPatientRecord(formFieldMap, FormField.USED_DRUGS_PAST_24_HOURS),
            buildPatientRecord(formFieldMap, FormField.HOW_LONG_USED_DRUGS),
            buildPatientRecord(formFieldMap, FormField.PLAN_TO_QUIT),
            buildPatientRecord(formFieldMap, FormField.QUIT_DATE)
          ]
        },
        {
          parentSectionTitle: SectionTitle.PRESCRIPTION_DRUG_USE,
          sectionTitle: SectionTitle.PROVIDER_NOTIFICATION,
          records: [buildPatientRecord(formFieldMap, FormField.PROVIDER_NOTIFIED), buildPatientRecord(formFieldMap, FormField.PROVIDER_NAME)]
        },
        {
          parentSectionTitle: SectionTitle.PRESCRIPTION_DRUG_USE,
          sectionTitle: SectionTitle.QUIT,
          records: [buildPatientRecord(formFieldMap, FormField.HOW_LONG_QUIT)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap
    };
    return <DrugScreenView {...viewProps} />;
  }
}

export { DrugScreen as BaseDrugScreen };
export default compose(withChartLogic, withAdmissionHistory)(DrugScreen);

import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const PsychosocialInterventionsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="psychosocial-interventions">
      <h1>{SectionTitle.PSYCHOSOCIAL_INTERVENTIONS}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.NURSE_PATIENT_RELATIONSHIP}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.NURSE_PATIENT_RELATIONSHIP)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PSYCHOPHYSIOLOGIC_FUNCTIONING}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PSYCHOPHYSIOLOGIC_FUNCTIONING)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};
PsychosocialInterventionsView.displayName = 'PsychosocialInterventionsView';
export default PsychosocialInterventionsView;

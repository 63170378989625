import { ChartFragment } from 'models/api-response';
import { Box, MultiLine } from 'components/common';
import { ELSFlex, ELSIcon, ELSTooltip } from 'components/common/els';
import LaboratoryTooltip from '../laboratory/laboratory-tootip/LaboratoryTooltip';

interface ReadOnlyInputProps {
  chartFragment: ChartFragment;
  formFieldId?: string;
  customTitle: string;
  notRenderBlankField?: boolean;
  labelTooltip?: string;
}
const ReadOnlyInput = (props: ReadOnlyInputProps) => {
  const { chartFragment, formFieldId, customTitle, notRenderBlankField, labelTooltip } = props;

  const item = chartFragment?.chartData[formFieldId];
  let content;
  if (item) {
    content = <MultiLine key={item.id} text={item.content || '- -'} />;
  } else {
    if (notRenderBlankField) {
      return null;
    }
    content = <div>- -</div>;
  }

  return (
    <Box className="read-only-input" pt pb>
      <ELSFlex wrap left>
        <h4>{customTitle}</h4>
        {labelTooltip && (
          <span className="u-els-margin-left-1o2">
            <ELSTooltip wrapperClassName="chart-input__label-tooltip" content={<LaboratoryTooltip ranges={labelTooltip.split('|')} />} showClose>
              <ELSIcon prefix="gizmo" name="information" title="" size="1x" />
            </ELSTooltip>
          </span>
        )}
      </ELSFlex>
      {content}
    </Box>
  );
};

ReadOnlyInput.displayName = 'ReadOnlyInput';
export default ReadOnlyInput;

import { Component } from 'react';
import { ELSCheckBox, ELSFlex, ELSFlexItem } from 'components/common/els';

interface SectionTypeFilterProps {
  values: { [x: string]: boolean };
  onChange: Function;
}

export default class SectionTypeFilter extends Component<SectionTypeFilterProps> {
  render() {
    const { values, onChange } = this.props;
    return (
      <div className="section-type-filter section-box">
        <div className="u-els-font-size-caption u-els-color-n6">Filter items by type</div>
        <ELSFlex left>
          <ELSFlexItem className="u-els-margin-right-3x">
            <ELSCheckBox id="overview" checked={values.overview} labelText="Overview" changeHandler={(event, value) => onChange('overview', value)} />
          </ELSFlexItem>
          <ELSFlexItem className="u-els-margin-right-3x">
            <ELSCheckBox id="phases" checked={values.phases} labelText="Phases" changeHandler={(event, value) => onChange('phases', value)} />
          </ELSFlexItem>
          <ELSFlexItem>
            <ELSCheckBox id="questions" checked={values.questions} labelText="Questions" changeHandler={(event, value) => onChange('questions', value)} />
          </ELSFlexItem>
        </ELSFlex>
      </div>
    );
  }
}

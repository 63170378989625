import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import AdmissionDataReadOnly from './AdmissionDataReadOnly';

export interface AdmissionDataViewAuProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  patientContext: PatientContext;
  intl: IntlShape;
  locale: Locales;
}

const AdmissionDataViewAu = ({
  chartActionsProps,
  isLocked,
  fragment,
  statusFragment,
  resetAll,
  formSubmittedCount,
  chartMetaFormFields,
  patientContext,
  intl
}: AdmissionDataViewAuProps) => {
  return (
    <div className="admission-data">
      {isLocked ? (
        <AdmissionDataReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={patientContext} />
      ) : (
        <>
          <h1>{LanguageKeys.ADMISSION_DATA.ADMISSION_DATA}</h1>
          <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONFIRM_IDENTIFICATION)} formSubmittedCount={formSubmittedCount} />

          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS })}</h2>

              <ELSFlex className="o-els-flex-layout--gutters-1x1o2" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_PHONE)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_REL_ORG)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField
                    formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_AWARE_OF_ADMISSION)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </ELSFlexItem>

            <ELSFlexItem>
              <h2 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.ADMISSION_DATA.PREFERRED_NAME })}</h2>

              <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_PREFERRED_NAME)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_PREFERRED_PRONOUNS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />

          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_ADVANCED_CARE_PLANNING)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField
                formFieldMap={chartMetaFormFields}
                formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_IS_PATIENT_ATSI)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>

            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_DOES_PATIENT_HAVE_A_CARER)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ELSFlex className="o-els-flex-layout--gutters-1x1o2" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_CARER_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_CARER_PHONE)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_CARER_REL_ORG)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_IS_PATIENT_A_CARER)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ELSFlex className="o-els-flex-layout--gutters-1x1o2" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_PHONE)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_REL_ORG)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />

          <Box mt2>
            <h2 className="u-els-margin-bottom">This Admission</h2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_DATE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ESTIMATED_DISCHARGE_DATE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ADMITTED_FROM)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ADMITTED_FROM_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ARRIVED_BY)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ARRIVED_BY_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_PREGNANCY_RELATED)} formSubmittedCount={formSubmittedCount}>
            <h2 className="u-els-margin-bottom">{LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}</h2>
            <Box>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.PREGNANCY_RELATED)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                  <ErrorFormField
                    formFieldMap={chartMetaFormFields}
                    formField={chartMetaFormFields.get(FormField.PREGNANCY_RELATED_SELECT)}
                    formSubmittedCount={formSubmittedCount}
                  />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.PREGNANCY_RELATED_SELECT_OTHER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_CONTRACTIONS)} formSubmittedCount={formSubmittedCount}>
                    <h3>Contractions</h3>
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_TIME_OF_ONSET)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_FREQUENCY)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_DURATION)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_QUALITY)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_FLUID)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_COLOR)} formSubmittedCount={formSubmittedCount} />
                  </ErrorFormField>
                </ELSFlexItem>
              </ELSFlex>
            </Box>
          </ErrorFormField>

          <SectionDivider />
          <h2 className="u-els-margin-bottom">Verbal Admission History Information Unavailable</h2>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.VERBAL_ADMISSION_HISTORY)} formSubmittedCount={formSubmittedCount} />

          <SectionDivider />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Past Admissions</h2>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PAST_ADMISSION_HOSPITAL)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PAST_ADMISSION_REASON)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PAST_ADMISSION_WHERE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Informant / Historian</h2>
              <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.INFORMANT_HISTORIAN_INFORMATION_FROM)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INFORMANT_HISTORIAN_NAME)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

AdmissionDataViewAu.displayName = 'AdmissionDataView';
export default AdmissionDataViewAu;

import { ImpressionFragment } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import { FormField } from './Impressions';

export interface ImpressionsReadOnlyProps {
  selectedRecord: ImpressionFragment;
}
const ImpressionsReadOnly = (props: ImpressionsReadOnlyProps) => {
  const record = props.selectedRecord;
  return (
    <>
      <h2>Impressions</h2>
      <Box className="sng-article">
        {!record && <h3>Chart Omitted</h3>}
        {record && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2">
              <ELSFlexItem w="1o3">
                <h4>Impressions</h4>
                <MultiLineWithEmpty text={record?.chartData?.[FormField.IMPRESSIONS]} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Plan</h4>
                <MultiLineWithEmpty text={record?.chartData?.[FormField.PLAN]} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Provider Signature</h4>
                <MultiLineWithEmpty text={record?.chartData?.[FormField.SIGNATURE]} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-top">
              <ELSFlexItem w="1o3">
                <h4>Day</h4>
                <MultiLineWithEmpty text={record?.chartData?.[FormField.DAY]} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Time</h4>
                <MultiLineWithEmpty text={record?.chartData?.[FormField.TIME]} />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </>
  );
};

ImpressionsReadOnly.displayName = 'ImpressionsReadOnly';
export default ImpressionsReadOnly;

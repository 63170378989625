import { IntlShape } from 'react-intl';
import { ChartFragment } from 'models/api-response';
import { PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { LandingChart } from './AdmissionHistory';
import './admission.history.scss';
import AdmissionHistoryTile from './tile/AdmissionHistoryTile';
import ActivityOccupationalTherapyReadOnly from '../activity-occupational-therapy/ActivityOccupationalTherapyReadOnly';
import AdmissionDataReadOnly from '../admission-data/AdmissionDataReadOnly';
import AdolescentRiskAssessmentReadOnly from '../adolescent-risk-assessment/AdolescentRiskAssessmentReadOnly';
import AlcoholScreenReadOnly from '../alcohol-screen/AlcoholScreenReadOnly';
import { AllergyInformationChartHistory } from '../allergy-information/AllergyInformationHistory';
import AllergyInformationReadOnly from '../allergy-information/AllergyInformationReadOnly';
import ChildRiskAssessmentReadOnly from '../child-risk-assessment/ChildRiskAssessmentReadOnly';
import DevelopmentalScreenReadOnly from '../developmental-screen/DevelopmentalScreenReadOnly';
import DrugScreenReadOnly from '../drug-screen/DrugScreenReadOnly';
import FallRiskAssessmentReadOnly from '../fall-risk-assessment/FallRiskAssessmentReadOnly';
import HealthHistoryReadOnly from '../health-history/HealthHistoryReadOnly';
import ImmunizationsReadOnly from '../immunizations/ImmunizationsReadOnly';
import InfantToddlerAssessmentReadOnly from '../infant-toddler-assessment/InfantToddlerAssessmentReadOnly';
import MobilityPhysicalTherapyReadOnly from '../mobility-physical-therapy/MobilityPhysicalTherapyReadOnly';
import NutritionScreenReadOnly from '../nutrition-screen/NutritionScreenReadOnly';
import PlanningHospitalStayReadOnly from '../planning-hospital-stay/PlanningHospitalStayReadOnly';
import PsychosocialScreenReadOnly from '../psychosocial-screen/PsychosocialScreenReadOnly';
import RoleRelationshipReadOnly from '../role-relationship/RoleRelationshipReadOnly';
import SmokingScreenReadOnly from '../smoking-screen/SmokingScreenReadOnly';

interface AdmissionHistoryViewProps {
  isAssessmentOwner: boolean;
  isAssessmentSubmitted: boolean;
  isChartLocked: boolean;
  inViewMode: boolean;
  landingFragment: ChartFragment;
  charts: LandingChart[];
  onCompleteClick: Function;
  onReviewClick: Function;
  onBackClick: Function;
  onTileClick: Function;
  patientContext: PatientContext;
  intl: IntlShape;
}

export enum Label {
  COMPLETE_ADMISSION_HISTORY = 'Complete Admission History',
  NO_FURTHER_CHANGES = 'Note: Once the Admission History is completed, no further changes can be made.'
}

const AdmissionHistoryView = (props: AdmissionHistoryViewProps) => {
  const { isAssessmentOwner, isAssessmentSubmitted, isChartLocked, inViewMode, landingFragment, charts, patientContext, intl } = props;
  const { onCompleteClick, onReviewClick, onBackClick, onTileClick } = props;
  const isReview = !isChartLocked && inViewMode;
  const findChart = (navId: string) => (charts.find((chart) => chart.navId === navId) ? charts.find((chart) => chart.navId === navId) : { fragment: {}, chartHistory: {} });
  return (
    <>
      <Box pb2 className="admission-history">
        <h1>{`${isReview ? 'Review - ' : ''}Admission History`}</h1>
        {!inViewMode && (
          <Box py3>
            <ol>
              <ELSFlex className="u-flex-grid-1x u-flex-gutter-1x" wrap left>
                {charts
                  .filter((item) => item.shown)
                  .map((item) => (
                    <ELSFlexItem key={item.navId} w="1o2" md="1o1">
                      <li>
                        <AdmissionHistoryTile title={item.name} active={item.hasData} onClick={() => onTileClick(item.routePath)} />
                      </li>
                    </ELSFlexItem>
                  ))}
              </ELSFlex>
            </ol>
          </Box>
        )}
        {inViewMode && (
          <Box mb2>
            {isChartLocked && <Headline statusFragment={landingFragment} hiddenCreatedBy />}
            <Box mt2>
              <AdmissionDataReadOnly hideHeadline chartFragment={findChart(NAV_ID.ADMISSION_DATA).fragment} statusFragment={null} patientContext={patientContext} />
              <HealthHistoryReadOnly hideHeadline chartFragment={findChart(NAV_ID.HEALTH_HISTORY).fragment} statusFragment={null} patientContext={patientContext} />
              <ChildRiskAssessmentReadOnly hideHeadline chartFragment={findChart(NAV_ID.CHILD_RISK_ASSESSMENT).fragment} statusFragment={null} />
              <PlanningHospitalStayReadOnly hideHeadline chartFragment={findChart(NAV_ID.PLANNING_HOSPITAL_STAY).fragment} statusFragment={null} patientContext={patientContext} />
              <AllergyInformationReadOnly
                hideHeadline
                chartFragments={findChart(NAV_ID.ALLERGY_INFORMATION).chartHistory as AllergyInformationChartHistory[]}
                statusFragment={null}
              />
              <ImmunizationsReadOnly hideHeadline chartFragment={findChart(NAV_ID.IMMUNIZATIONS).fragment} statusFragment={null} />
              <DrugScreenReadOnly hideHeadline chartFragment={findChart(NAV_ID.DRUG_SCREEN).fragment} statusFragment={null} />
              <SmokingScreenReadOnly hideHeadline chartFragment={findChart(NAV_ID.SMOKING_SCREEN).fragment} statusFragment={null} />
              <FallRiskAssessmentReadOnly isHideHeadline chartFragment={findChart(NAV_ID.FALL_RISK_ASSESSMENT).fragment} statusFragment={null} />
              <AlcoholScreenReadOnly hideHeadline chartFragment={findChart(NAV_ID.ALCOHOL_SCREEN).fragment} statusFragment={null} patientContext={patientContext} />
              <ActivityOccupationalTherapyReadOnly hideHeadline chartFragment={findChart(NAV_ID.ACTIVITY_OCCUPATIONAL_THERAPY).fragment} statusFragment={null} />
              <NutritionScreenReadOnly hideHeadline chartFragment={findChart(NAV_ID.NUTRITION_SCREEN).fragment} statusFragment={null} patientContext={patientContext} intl={intl} />
              <AdolescentRiskAssessmentReadOnly hideHeadline chartFragment={findChart(NAV_ID.ADOLESCENT_RISK_ASSESSMENT).fragment} statusFragment={null} />
              <MobilityPhysicalTherapyReadOnly
                hideHeadline
                chartFragment={findChart(NAV_ID.MOBILITY_PHYSICAL_THERAPY).fragment}
                statusFragment={null}
                patientContext={patientContext}
              />
              <RoleRelationshipReadOnly hideHeadline chartFragment={findChart(NAV_ID.ROLE_RELATIONSHIP).fragment} statusFragment={null} />
              <InfantToddlerAssessmentReadOnly hideHeadline chartFragment={findChart(NAV_ID.INFANT_TODDLER_ASSESSMENT).fragment} statusFragment={null} />
              <DevelopmentalScreenReadOnly hideHeadline chartFragment={findChart(NAV_ID.DEVELOPMENTAL_SCREEN).fragment} statusFragment={null} />
              <PsychosocialScreenReadOnly hideHeadline chartFragment={findChart(NAV_ID.PSYCHOSOCIAL_SCREEN).fragment} statusFragment={null} patientContext={patientContext} />
            </Box>
          </Box>
        )}
        {isAssessmentOwner && !isAssessmentSubmitted && !isChartLocked && (
          <div>
            <h4>{Label.COMPLETE_ADMISSION_HISTORY}</h4>
            <div className="u-els-font-size-meta">
              <p>{Label.NO_FURTHER_CHANGES}</p>
            </div>
            <Box mt3>
              <ELSButton className="sng-button" text="Complete" onClick={onCompleteClick} />
              {!isReview && <ELSButton className="sng-button u-els-margin-left" type="secondary" text="Review" onClick={onReviewClick} />}
              {isReview && <ELSButton className="sng-button u-els-margin-left" type="secondary" text="Back" onClick={onBackClick} />}
            </Box>
          </div>
        )}
      </Box>
    </>
  );
};

AdmissionHistoryView.displayName = 'AdmissionHistoryView';
export default AdmissionHistoryView;

import MultiLine from '../multi-line/MultiLine';

interface InstructionProps {
  instruction: string;
}

const Instruction = (props: InstructionProps) => <div>{props.instruction && <MultiLine text={props.instruction} />}</div>;

Instruction.displayName = 'Instruction';
export default Instruction;

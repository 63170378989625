import { ChartFragment, Record } from 'models/api-response';
import { GenderPatientContext } from 'models/enum';
import { PatientContext } from 'models/ui';
import { Locales, PEDIATRIC_AGE_UNDER_9, PEDIATRIC_AGE_UNDER_16, PEDIATRIC_AGE_UP_TO_1, WOMAN_HEALTH_MIN_AGE_IN_DAYS } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import PreviousPregnancies from './PreviousPregnancies';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface HealthHistoryReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
}

const HealthHistoryReadOnly = (props: HealthHistoryReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext } = props;

  const previousPregnanciesRecords = (chartFragment?.chartData?.records.find((record) => record.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES)?.records as Record[]) || [];
  const previousPregnanciesHistory = previousPregnanciesRecords?.map((record) => ({
    ...record,
    [FormField.HOW_LONG_AGO]: record[FormField.HOW_LONG_AGO]?.content || '',
    [FormField.GESTATIONAL_WEEKS]: record[FormField.GESTATIONAL_WEEKS]?.content || '',
    [FormField.TYPE_OF_DELIVERY]: record[FormField.TYPE_OF_DELIVERY]?.content || '',
    [FormField.DURATION_OF_LABOR]: record[FormField.DURATION_OF_LABOR]?.content || '',
    [FormField.COMPLICATIONS]: record[FormField.COMPLICATIONS]?.content || '',
    [FormField.OUTCOME]: record[FormField.OUTCOME]?.content || ''
  }));
  const isWomenHealth = patientContext?.ageInDays >= WOMAN_HEALTH_MIN_AGE_IN_DAYS && patientContext?.gender === GenderPatientContext.FEMALE;
  const isPediatric = patientContext?.ageInDays < PEDIATRIC_AGE_UNDER_16;
  const isUnder9Years = patientContext?.ageInDays < PEDIATRIC_AGE_UNDER_9;
  const isUpTo1Year = patientContext?.ageInDays < PEDIATRIC_AGE_UP_TO_1;

  const renderWithChartFragment = () => (
    <>
      {isWomenHealth && (
        <Box mb2>
          <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
            <ELSFlexItem w="1o3" md="1o1">
              <Box>
                <h3>{SectionTitle.OBSTETRIC_HISTORY}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_ARE_YOU_PREGNANT}
                  customTitle="Are you pregnant?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_BABY_DUE}
                  customTitle="Baby's due"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_COMFIRMED_ULTRASOUND}
                  customTitle="Confirmed by ultrasound?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_LAST_MENSTRUAL_PERIOD}
                  customTitle="Last menstrual period"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_ARE_YOU_BREAST_FEEDING}
                  customTitle="Are you breastfeeding?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_GRAVIDA}
                  customTitle="Gravida"
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.OBSTETRIC_HISTORY} formFieldId={FormField.OBSTETRIC_HISTORY_PARA} customTitle="Para" />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.OBSTETRIC_HISTORY} formFieldId={FormField.OBSTETRIC_HISTORY_TERM} customTitle="Term" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_PRETERM}
                  customTitle="Preterm"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                  formFieldId={FormField.OBSTETRIC_HISTORY_ABORTION}
                  customTitle="Abortion"
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.OBSTETRIC_HISTORY} formFieldId={FormField.OBSTETRIC_HISTORY_LIVING} customTitle="Living" />
              </Box>
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <h3>History of Current Pregnancy</h3>
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_RECEIVED_PRENATAL_CARE}
                customTitle="Received prenatal care?"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_PROVIDER_NAME}
                customTitle="Provider name"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_RISK_FACTORS_IDENTIFIED}
                customTitle="Risk factors identified"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_GROUP_B_STREPTOCOCCUS_STATUS}
                customTitle="Group B Streptococcus status"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_VAGINAL_DISCHARGE}
                customTitle="Vaginal discharge"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_DESCRIBE}
                customTitle="Describe"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_PROBLEMS_IDENTIFIED}
                customTitle="Problems/concerns identified"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_MULTIPLE_GESTATION}
                customTitle="Multiple gestation?"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_IS_FETUS_ACTIVE}
                customTitle="Is fetus active?"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.HISTORY_OF_CURRENT_PREGNANCY}
                formFieldId={FormField.CURRENT_PREGNANCY_TIME_OF_LAST_ACTIVITY}
                customTitle="Time of last activity"
              />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <h3>Last Oral Intake</h3>
              <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.LAST_ORAL_INTAKE} formFieldId={FormField.LAST_ORAL_INTAKE_DAY} customTitle="Day" />
              <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.LAST_ORAL_INTAKE} formFieldId={FormField.LAST_ORAL_INTAKE_TIME} customTitle="Time" />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.LAST_ORAL_INTAKE}
                formFieldId={FormField.LAST_ORAL_INTAKE_DAY_DESCRIPTION}
                customTitle="Description of intake"
              />
            </ELSFlexItem>
          </ELSFlex>
          <h3>Previous Pregnancies</h3>
          <PreviousPregnancies isLocked chartHistory={previousPregnanciesHistory} />
        </Box>
      )}
      <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>Any previous health problems reported?</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.NO_PREVIOUS_HEALTH_PROBLEM} formFieldId={FormField.NO_PREVIOUS_HEALTH_PROBLEM} customTitle="" />
        </ELSFlexItem>
        {!isPediatric && patientContext.locale !== Locales.EN_AU && (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>{SectionTitle.ENVIRONMENT_HISTORY}</h3>
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_CONCERN}
              customTitle="What concerns do you have about your living environment (stairs, personal safety, etc.)?"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_EXPOSE}
              customTitle="Are you exposed to hazardous material and/or chemicals in the workplace or living environment?"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_EXPLAIN}
              customTitle="If yes, explain."
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_MILITARY}
              customTitle="Have you been in the military?"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_BRANCH}
              customTitle="Branch of service"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_DATE}
              customTitle="Dates of military service"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_POSITION}
              customTitle="Position (rank and operations)"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_DEPLOYMENT}
              customTitle="Deployments (date/location)"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_ENVIRONMENTAL}
              customTitle="Environmental exposures while in military"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_INJURIES}
              customTitle="Injuries experienced while in military"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENVIRONMENT_HISTORY}
              formFieldId={FormField.ENV_HISTORY_ASSAULTS}
              customTitle="Assaults experienced while in military (physical, verbal, sexual)"
            />
          </ELSFlexItem>
        )}
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.TRAVEL}</h3>
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.TRAVEL}
            formFieldId={FormField.TRAVEL}
            customTitle="Have you traveled to a foreign country in the past 12 months?"
          />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.TRAVEL}
            formFieldId={FormField.TRAVEL_EXPLAIN}
            customTitle="If yes, explain where and when the travel took place."
          />
        </ELSFlexItem>

        {!isPediatric ? (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>{SectionTitle.MENTAL_HEALTH}</h3>
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.MENTAL_HEALTH} formFieldId={FormField.MENTAL_HEALTH} customTitle="" />
          </ELSFlexItem>
        ) : (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>{SectionTitle.GENETIC_DISORDERS}</h3>
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.GENETIC_DISORDERS} formFieldId={FormField.GENETIC_DISORDERS} customTitle="" />
          </ELSFlexItem>
        )}
        {isUnder9Years && (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>{SectionTitle.GENERAL}</h3>
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.GENERAL} formFieldId={FormField.GENERAL} customTitle="" />
          </ELSFlexItem>
        )}
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.NEUROLOGICAL_PROBLEMS}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.NEUROLOGICAL_PROBLEMS} formFieldId={FormField.NEUROLOGICAL_PROBLEMS} customTitle="" />
        </ELSFlexItem>
        {!isPediatric ? (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>{SectionTitle.RESPIRATORY_PROBLEMS}</h3>
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.RESPIRATORY_PROBLEMS} formFieldId={FormField.RESPIRATORY_PROBLEMS} customTitle="" />
          </ELSFlexItem>
        ) : (
          <>
            <ELSFlexItem w="1o3" md="1o1">
              <h3>{SectionTitle.RESPIRATORY_UPPER}</h3>
              <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.RESPIRATORY_UPPER} formFieldId={FormField.RESPIRATORY_UPPER} customTitle="" />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <h3>{SectionTitle.RESPIRATORY_LOWER}</h3>
              <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.RESPIRATORY_LOWER} formFieldId={FormField.RESPIRATORY_LOWER} customTitle="" />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <h3>{SectionTitle.RESPIRATORY_OTHER}</h3>
              <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.RESPIRATORY_OTHER} formFieldId={FormField.RESPIRATORY_OTHER} customTitle="" />
            </ELSFlexItem>
          </>
        )}

        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.CARDIOVASCULAR_PROBLEMS}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.CARDIOVASCULAR_PROBLEMS} formFieldId={FormField.CARDIOVASCULAR_PROBLEMS} customTitle="" />
        </ELSFlexItem>
        {isPediatric && (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>{SectionTitle.CONGENITAL_HEART_DEFECT}</h3>
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.CONGENITAL_HEART_DEFECT} formFieldId={FormField.CONGENITAL_HEART_DEFECT} customTitle="" />
          </ELSFlexItem>
        )}
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.SENSORY_IMPAIRMENT}</h3>
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT}
            formFieldId={FormField.SENSORY_HEARING}
            customTitle="Hearing impairment"
          />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT_CHOICE}
            formFieldId={FormField.SENSORY_HEARING_CHOICE}
            customTitle=""
            notRenderBlankField
          />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT}
            formFieldId={FormField.SENSORY_VISUAL}
            customTitle="Visual impairment"
          />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT_CHOICE}
            formFieldId={FormField.SENSORY_VISUAL_CHOICE}
            customTitle=""
            notRenderBlankField
          />
          {isPediatric && (
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.INTEGUMENTORY_PROBLEMS}
              formFieldId={FormField.INTEGUMENTORY_PROBLEMS}
              customTitle="Integumentary Problems"
            />
          )}
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.ENDOCRINE_DISORDERS}</h3>
          {!isUpTo1Year ? (
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_THYROID_DISEASE}
              formFieldId={FormField.ENDOCRINE_THYROID}
              customTitle="Thyroid disease"
            />
          ) : (
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.ENDOCRINE_DISORDERS}
              formFieldId={FormField.ENDOCRINE_DISORDERS}
              customTitle="Endocrine Disorders"
            />
          )}
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_LIVER_DISEASE}
            formFieldId={FormField.ENDOCRINE_LIVER}
            customTitle="Liver disease"
          />
          {!isUpTo1Year && (
            <>
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_OTHER_DISORDERS}
                formFieldId={FormField.ENDOCRINE_OTHER}
                customTitle="Other disorders"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_PITUITARY_DISORDER}
                formFieldId={FormField.ENDOCRINE_PITUITARY}
                customTitle="Pituitary disorder"
              />
              <ReadOnlyInput
                chartFragment={chartFragment}
                sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_DIABETES}
                formFieldId={FormField.ENDOCRINE_DIABETES}
                customTitle="Diabetes"
              />
            </>
          )}
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_DIABETES_COMPLIANT}
            formFieldId={FormField.ENDOCRINE_DIABETES_COMPLIANT}
            customTitle=""
            notRenderBlankField
          />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.ENDOCRINE_DISORDERS_DIABETES_YEARS}
            formFieldId={FormField.ENDOCRINE_YEARS}
            customTitle="Years with diabetes"
            notRenderBlankField
          />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.GASTROINTESTINAL_PROBLEMS}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.GASTROINTESTINAL_PROBLEMS} formFieldId={FormField.GASTROINTESTINAL} customTitle="" />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3 className="u-word-wrap--break-word">{SectionTitle.HEMATOLOGIC_PROBLEMS}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.HEMATOLOGIC_PROBLEMS} formFieldId={FormField.HEMATOLOGIC} customTitle="" />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.HEMATOLOGIC_CANCER}
            formFieldId={FormField.HEMATOLOGIC_CANCER}
            customTitle=""
            notRenderBlankField
          />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.GENITOURINARY}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.GENITOURINARY} formFieldId={FormField.GENITOURINARY} customTitle="" />
        </ELSFlexItem>
        {!isPediatric && (
          <ELSFlexItem w="1o3" md="1o1">
            <h3>Sexuality/Reproductive</h3>
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SEXUALITY} formFieldId={FormField.SEXUALITY} customTitle="Reproductive problems" />
            <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SEXUALITY_ACTIVE} formFieldId={FormField.SEXUALITY_ACTIVE} customTitle="Sexually active?" />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.SEXUALITY_NUMBER_PARTNERS}
              formFieldId={FormField.SEXUALITY_NUMBER_PARTNERS}
              customTitle="Number of sex partners"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.SEXUALITY_SEX_PARTNER}
              formFieldId={FormField.SEXUALITY_SEX_PARTNER}
              customTitle="Sex of partners"
            />
            <ReadOnlyInput
              chartFragment={chartFragment}
              sectionTitle={SectionTitle.SEXUALITY_BIRTH_CONTROL}
              formFieldId={FormField.SEXUALITY_BIRTH_CONTROL}
              customTitle="Birth control used"
            />
          </ELSFlexItem>
        )}

        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.MUSCULOSKELETAL_PROBLEMS}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.MUSCULOSKELETAL_PROBLEMS} formFieldId={FormField.MUSCULOSKELETAL} customTitle="" />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.OTHER_HEALTH_HISTORY}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.OTHER_HEALTH_HISTORY} formFieldId={FormField.OTHER_HEALTH_HISTORY} customTitle="" />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.PREVIOUS_SURGERY}</h3>
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.PREVIOUS_SURGERY_NONE}
            formFieldId={FormField.PREVIOUS_SURGERY_NONE}
            customTitle=""
            notRenderBlankField
          />
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.PREVIOUS_SURGERY} formFieldId={FormField.PREVIOUS_SURGERY} customTitle="" />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.ANESTHESIA_PROBLEMS}</h3>
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.ANESTHESIA_PROBLEMS} formFieldId={FormField.ANESTHESIA} customTitle="" />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.ANESTHESIA_NOTE}
            formFieldId={FormField.ANESTHESIA_NOTE}
            customTitle="Problems reported?"
            notRenderBlankField
          />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h3>{SectionTitle.RECENT_FEVER_REQUIRING_ANTIBIOTICS}</h3>
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.RECENT_FEVER_REQUIRING_ANTIBIOTICS}
            formFieldId={FormField.RECENT_FEVER}
            customTitle=""
            notRenderBlankField
          />
          <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.RECENT_FEVER_ONSET} formFieldId={FormField.RECENT_FEVER_ONSET} customTitle="Onset" />
          <ReadOnlyInput
            chartFragment={chartFragment}
            sectionTitle={SectionTitle.RECENT_FEVER_ONSET_OTHER}
            formFieldId={FormField.RECENT_FEVER_ONSET_OTHER}
            customTitle="Other"
            notRenderBlankField
          />
        </ELSFlexItem>
      </ELSFlex>
    </>
  );

  const renderBody = () => {
    if (chartFragment) {
      return renderWithChartFragment();
    }
    return <h3>Chart Omitted</h3>;
  };

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Health History</h2>
      <Box className="sng-article">{renderBody()}</Box>
    </Box>
  );
};

HealthHistoryReadOnly.displayName = 'HealthHistoryReadOnly';
export default HealthHistoryReadOnly;

import cx from 'classnames';
import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { IntlShape } from 'react-intl';
import { BloodGlucoseRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import './blood.glucose.history.scss';

export interface BloodGlucoseHistoryProps {
  chartHistory: BloodGlucoseRecord[];
  chartMetaContentMap?: Map<string, string>;
  deleteHistory: Function;
  intl?: IntlShape;
  locale?: Locales;
}

class BloodGlucoseHistory extends Component<BloodGlucoseHistoryProps> {
  glucoseReadingRenderer = (record) => (
    <div className={cx({ 'row--deleted': !record.active })}>
      {`${record.glucoseReading} ${this.props.intl.formatMessage({ id: LanguageKeys.BLOOD_GLUCOSE.GLUCOSE_READING_UNIT })}`}
    </div>
  );

  choicesRenderer = (record) => (
    <div className={cx({ 'row--deleted': !record.active })}>{chartHelper.findContentFromMap(this.props.chartMetaContentMap, record.nursingActions.choices)}</div>
  );

  timeSinceLastMealRenderer = (record) => (
    <div className={cx({ 'row--deleted': !record.active })}>{chartHelper.findContentFromMap(this.props.chartMetaContentMap, record.timeSinceLastMeal)}</div>
  );

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { deleteHistory, locale } = this.props;
    const dataSource = {
      title: 'Chart History',
      headers: [
        { text: 'Chart Time', field: 'chartingAt' },
        { text: 'BG Level', field: 'glucoseReading', align: ColumnAlign.Right, customRender: this.glucoseReadingRenderer },
        { text: 'BG Action', field: 'choices', customRender: this.choicesRenderer },
        { text: 'Last Meal', field: 'timeSinceLastMeal', customRender: this.timeSinceLastMealRenderer },
        { text: 'Notes', field: 'notes' },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { field: 'actionDelete' }
      ],
      data: this.props.chartHistory,
      css: ['column__chart-time', 'column__bg-level', 'column__bg-action', 'column__meal', 'column__note', 'column__entry-by', 'column__delete']
    };

    return (
      <Box mt>
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} locale={locale} />
      </Box>
    );
  }
}

export default BloodGlucoseHistory;

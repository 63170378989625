import { delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { v4 } from 'uuid';
import { AssessmentRS, ChartFragment } from 'models/api-response';
import { ELSModalServiceType, ELSToastServiceType } from 'models/els';
import { FragmentType, UserRole } from 'models/enum';
import { appHelper } from 'helpers';
import { chartService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { Box, ELSTextarea, LoseDataWarning, MultiLine, withFormUtilities } from 'components/common';
import { ELSButton, ELSIcon } from 'components/common/els';
import './chart.comment.scss';
import UnsavedChangesHandler from '../unsaved-changes-handler/UnsavedChangesHandler';

interface ChartCommentProps {
  modalService: ELSModalServiceType;
  userRole: string;
  navElementId: string;
  assessment: AssessmentRS;
  selectedFragment?: ChartFragment;
  toastService: ELSToastServiceType;
  showSaveSuccess: Function;
  setSelectedNavId: Function;
  getHasChartComment: (hasChartComment: boolean, isFirstLoadChartComment: boolean) => void;
}
interface ChartCommentState {
  commentFragment: ChartFragment;
  commentText: string;
  isCommentChanged: boolean;
  delayRouterPause: boolean;
}

class ChartComment extends Component<ChartCommentProps, ChartCommentState> {
  static displayName = 'ChartComment';
  originNavElementId: string;
  warningUnsavedCommentModalId = 'loseDataModal';

  constructor(props) {
    super(props);
    this.state = {
      commentFragment: null,
      commentText: '',
      isCommentChanged: false,
      delayRouterPause: true
    };
    this.originNavElementId = this.props.navElementId;
  }

  componentDidMount() {
    const { assessment, navElementId, selectedFragment } = this.props;
    const commentNavId = selectedFragment?.navElementId ?? navElementId;
    if (assessment && commentNavId) {
      this.loadData(assessment.simChartId, commentNavId);
    }
    delay(() => this.setState({ delayRouterPause: false }), 2000);
  }

  loadData = (chartId: string, navElementId: string) => {
    appHelper.useLoader(
      chartService.loadFragments({ chartId, navIds: [navElementId], fragmentTypes: [FragmentType.GRADE] }).then(({ data }) => {
        this.handleLoadedData(data);
      }),
      { errorMessage: 'can not load chart comment' }
    );
  };

  handleLoadedData = (data: ChartFragment[]) => {
    const { selectedFragment, getHasChartComment } = this.props;
    let commentFragment = selectedFragment ? data.find((fragment) => fragment.linkedFragmentId === selectedFragment.fragmentId) : data[0];
    const commentText = commentFragment?.chartData?.comment || '';

    if (!commentFragment) {
      commentFragment = null;
    }
    this.setState({
      commentFragment,
      commentText
    });
    getHasChartComment(!!commentText, false);
  };

  handleSaveClick = () => {
    const { assessment, navElementId, showSaveSuccess, selectedFragment } = this.props;
    const { commentText, commentFragment } = this.state;
    const commentNavId = selectedFragment?.navElementId ?? navElementId;
    const fragmentId = commentFragment?.fragmentId || `${commentNavId}#${v4()}`;
    const linkedFragmentId = selectedFragment?.fragmentId;

    return chartService
      .saveComment(assessment.simChartId, fragmentId, commentNavId, commentText, linkedFragmentId)
      .then(() => {
        const savedFragment = {
          fragmentId,
          fragmentType: FragmentType.GRADE,
          navElementId: commentNavId,
          chartData: { comment: commentText },
          linkedFragmentId
        };
        this.setState({ commentFragment: savedFragment, isCommentChanged: false });
      })
      .then(() => showSaveSuccess());
  };

  handleNavigationAttempt = () =>
    new Promise((resolve) => {
      const { modalService } = this.props;
      if (!this.state.isCommentChanged) {
        resolve(true);
      } else {
        modalService.openModal({
          modalId: this.warningUnsavedCommentModalId,
          content: (
            <LoseDataWarning
              onLeaveClick={() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                resolve(true);
              }}
              onStayClick={() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                this.props.setSelectedNavId(this.originNavElementId);
                resolve(false);
              }}
            />
          )
        });
      }
    });

  handleCommentChange = (event) => this.setState({ commentText: event.target.value, isCommentChanged: true });

  renderCommentForStudent = (commentText: string) => {
    if (!commentText) {
      return (
        <div>
          <ELSIcon prefix="gizmo" name="information" size="1x" align="sub" customClass="u-els-margin-right-1o2" />
          <span> No instructor feedback yet. </span>
        </div>
      );
    }

    return <MultiLine text={commentText} />;
  };

  render() {
    const { userRole } = this.props;
    const { commentText } = this.state;
    return (
      <>
        <div className="chart-comments">
          {userRole === UserRole.INSTRUCTOR && (
            <>
              <p>Instructor Chart Comments</p>
              <ELSTextarea
                name="chart-comments-textarea"
                className="chart-comments__textarea u-els-margin-top-1o2"
                rows={2}
                value={commentText}
                onChange={this.handleCommentChange}
              />
              <ELSButton className="u-els-margin-top" text="Save" isDisabled={!this.state.isCommentChanged} onClick={this.handleSaveClick} />
            </>
          )}
          {userRole === UserRole.STUDENT && (
            <Box mb>
              <div>Instructor Chart Comments</div>
              {this.renderCommentForStudent(commentText)}
            </Box>
          )}
        </div>
        {!this.state.delayRouterPause && userRole === UserRole.INSTRUCTOR && (
          <UnsavedChangesHandler handler={this.handleNavigationAttempt} hasUnsavedChanges={() => this.state.isCommentChanged} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: appSelectors.getUserRole(state),
  assessment: appSelectors.getAssessment(state),
  navElementId: appSelectors.getNavId(state)
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

export { ChartComment as BaseChartComment };
export default compose(connect(mapStateToProps, mapDispatchToProps), withFormUtilities)(ChartComment);

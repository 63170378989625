import { ReactNode } from 'react';
import { appHelper } from 'helpers';

interface MultiLineProps extends React.HTMLAttributes<HTMLElement> {
  text: ReactNode;
}

const MultiLine = (props: MultiLineProps) => {
  const { text, ...restProps } = props;
  return <p {...restProps} dangerouslySetInnerHTML={{ __html: appHelper.newLine2Br(text) }} />;
};

MultiLine.displayName = 'MultiLine';
export default MultiLine;

import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const ObstetricAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;

  return (
    <div className="obstetric-assessment">
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <h1>{SectionTitle.OBSTETRIC_ASSESSMENT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <Box mt2 className="obstetric-assessment">
        <ELSFlex className="u-els-margin-top-2x u-flex-grid-4x" left wrap>
          <ELSFlexItem w="1o4" md="1o2">
            <ErrorFormField formField={formFieldMap.get(FormField.FUNDAL_HEIGHT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VAGINAL_DISCHARGE_RADIO)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VAGINAL_DISCHARGE_COLOR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VAGINAL_DISCHARGE_ODOR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VAGINAL_DISCHARGE_PH)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VAGINAL_DISCHARGE_FERNING)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VAGINAL_DISCHARGE_GROUP_BSTREPTOCOCCUS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o4" md="1o2">
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES_TIME_OF_RUPTURE_TIME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES_TIME_OF_RUPTURE_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES_ODOR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES_COLOR_OF_FLUID)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES_VISCOSITY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEMBRANES_FLUID_AMOUNT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.OTHER_TEXT_AREA)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

ObstetricAssessmentView.displayName = 'ObstetricAssessmentView';
export default ObstetricAssessmentView;

import { PatientCardRecord } from 'models/api-response';
import { PatientCardCategory } from 'models/ui';
import { Box } from 'components/common';
import { ELSCheckBox, ELSDropDown } from 'components/common/els';
import { Section, SectionTitle } from './constants';
import PatientCardTable from './PatientCardTable';
import './patient.card.scss';

export interface PatientCardViewProps {
  generalInformation: PatientCardRecord[];
  actionList: PatientCardRecord[];
  categories: PatientCardCategory[];
  categoryValue: string;
  showGeneralInformation: boolean;
  showActionList: boolean;
  onCategoryChange: Function;
  onGeneralInformationChange: Function;
  onActionListChange: Function;
  onHoldRecord: Function;
  handleDiscontinueClick: Function;
  onDescriptionClick: Function;
}

const PatientCardView = (props: PatientCardViewProps) => {
  const { generalInformation, actionList, categories, categoryValue } = props;
  const { showGeneralInformation, showActionList, onCategoryChange, onGeneralInformationChange, onActionListChange } = props;
  const { onHoldRecord, handleDiscontinueClick, onDescriptionClick } = props;

  return (
    <div className="patient-card">
      <h1>{SectionTitle.PATIENT_CARD}</h1>
      <Box className="u-els-margin-top patient-card__filter">
        <Box>
          <ELSCheckBox labelText="General Information" checked={showGeneralInformation} changeHandler={onGeneralInformationChange} />
        </Box>
        <Box className="u-els-padding-left">
          <ELSCheckBox labelText="Action List" checked={showActionList} changeHandler={onActionListChange} />
        </Box>
        <Box className="u-els-padding-left-3x patient-card__categories">
          <ELSDropDown options={categories} value={categoryValue} changeHandler={onCategoryChange} />
        </Box>
      </Box>
      {showGeneralInformation && (
        <Box mt2>
          <h2>General Information</h2>
          <PatientCardTable section={Section.GENERAL_INFORMATION} chartHistory={generalInformation} onHoldRecord={onHoldRecord} handleDiscontinueClick={handleDiscontinueClick} />
        </Box>
      )}
      {showActionList && (
        <Box mt2>
          <h2>Action List</h2>
          <PatientCardTable
            section={Section.ACTION_LIST}
            chartHistory={actionList}
            onHoldRecord={onHoldRecord}
            handleDiscontinueClick={handleDiscontinueClick}
            onDescriptionClick={onDescriptionClick}
          />
        </Box>
      )}
    </div>
  );
};

PatientCardView.displayName = 'PatientCardView';
export default PatientCardView;

import { isEmpty, omit } from 'lodash';
import { Component, ReactElement } from 'react';
import { IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { AssignmentTargetType, AssignmentType } from 'models/enum';
import { AssignmentWorkflowComponentProps, NewAssignment } from 'models/ui';
import { Locales, RoutePath } from 'constants/app.constant';
import { apiHelper, appHelper } from 'helpers';
import { assignmentService, navigationService } from 'services';
import { instructorActions, instructorSelectors } from 'redux/ducks/instructor';
import { CreateAssignmentFooter, Stepper, withFormUtilities } from 'components/common';
import { appSelectors } from 'redux/ducks/app';

enum AssignmentTypeDisplayValues {
  CASE_STUDY = 'SimChart Case Study',
  EMPTY_EHR = 'SimChart Empty EHR',
  SIMULATION = 'Simulation'
}

export interface CreateAssignmentWrapperProps {
  children(arg: AssignmentWorkflowComponentProps): ReactElement;
  newAssignment: NewAssignment;
  setNewAssignment: Function;
  resetNewAssignment: Function;
  setSavedAssignment: Function;
  setDueDateError: Function;
  dueDateError: string;
  showSaveSuccess: VoidFunction;
  setIsEdit: Function;
  setPrevCreateAssignmentStep: Function;
  locale: Locales;
  intl?: IntlShape;
}

class CreateAssignmentWrapper extends Component<CreateAssignmentWrapperProps & RouteComponentProps> {
  handleSaveAndCompleteClick = () => {
    this.props.setPrevCreateAssignmentStep('');

    if (this.props.dueDateError) {
      // stop saving if there is a dueDateError
      return;
    }

    let payload: NewAssignment = {
      ...this.props.newAssignment,
      assignmentGoals: [{ goal: 1 }], // assignmentGoals MUST have goal, otherwise assessment can not be created from assignment
      targetType: this.props.newAssignment.isAllStudents ? AssignmentTargetType.COURSE : AssignmentTargetType.STUDENT
    };

    payload = omit(payload, 'isAllStudents');
    appHelper.useLoader(
      assignmentService
        .createNewAssignment(payload)
        .then(async ({ data }) => {
          const { assignmentType, eolsAssignmentId } = data;
          this.props.setSavedAssignment({
            assignmentType,
            assignmentId: `${eolsAssignmentId}`
          });
          await navigationService.navigateToAppLinkSource(JSON.stringify({ assignmentIds: [eolsAssignmentId] }));
        }))
      .then(this.props.showSaveSuccess)
      .then(() => this.props.setIsEdit(false))
      .catch((err) => apiHelper.showApiError(`can not save assignment ${err}`));
  };

  getSteps = () => {
    const { location, newAssignment } = this.props;
    const { pathname } = location;

    return appHelper.createSteps(
      [
        {
          title: 'Select type',
          completedStepLinkText: AssignmentTypeDisplayValues[newAssignment.assignmentType],
          route: RoutePath.instructor.home
        },
        {
          title: newAssignment.assignmentType === AssignmentType.CASE_STUDY ? 'Choose content' : 'Choose content (optional)',
          completedStepLinkText: '',
          route: RoutePath.instructor.contentSelection
        },
        {
          title: 'Configure item',
          completedStepLinkText: '',
          route: RoutePath.instructor.configureAssignment
        }
      ],
      pathname
    );
  };

  disableNextButton = () => {
    const { location, newAssignment, dueDateError } = this.props;
    const { pathname } = location;

    let disableNextButton = false;

    /**
     * TODO
     * revisit this logic after we add content to select for step two after selecting Empty EHR as the assignmentType
     * this temporarily enable the next button for the Content Selection page/step
     */
    if (newAssignment.assignmentType === AssignmentType.EMPTY_EHR && pathname === RoutePath.instructor.contentSelection) {
      return false;
    }

    if (pathname === RoutePath.instructor.contentSelection) {
      // disable the next button until an assignmentTopic is selected
      disableNextButton = !newAssignment.assignmentTopics.length;
    } else if (pathname === RoutePath.instructor.configureAssignment) {
      const hasDueDateError = !!dueDateError;
      /**
       * iterate over each value in newAssignment
       * if the value is a typeof string, check to make sure it's not empty
       * if it is empty, return true and stop
       * else keep checking
       * if every value returns false then the user can proceed
       * objectives and instructions fields are optional
       */
      const assignmentToCheck = omit(newAssignment, 'objectives', 'instructions', 'students');
      disableNextButton = Object.values(assignmentToCheck).some((value) => (typeof value === 'string' ? isEmpty(value.trim()) : false)) || hasDueDateError;
    }
    return disableNextButton;
  };

  render() {
    const { children, newAssignment, setNewAssignment, dueDateError, setDueDateError, resetNewAssignment, locale, intl } = this.props;
    const steps = this.getSteps();
    const { activeStep } = appHelper.getStepInfo(steps);

    const childProps: AssignmentWorkflowComponentProps = {
      newAssignment,
      steps,
      setNewAssignment,
      resetNewAssignment,
      dueDateError,
      setDueDateError,
      locale,
      intl
    };

    return (
      <div className="create-assignment-workflow">
        <h1 className="create-assignment-workflow__page-heading u-els-font-size-h1 u-els-margin-top-2x u-els-margin-bottom-2x u-els-margin-left-1x1o2 u-els-margin-right-1x1o2">
          Add a SimChart item
        </h1>
        <Stepper steps={steps} />
        <h2 className="create-assignment-workflow__step-heading u-els-font-size-h2 u-els-margin-top-2x u-els-margin-bottom-2x u-els-text-center">{activeStep?.heading}</h2>
        {children(childProps)}
        <CreateAssignmentFooter steps={steps} onSave={this.handleSaveAndCompleteClick} disableNextButton={this.disableNextButton()} />
      </div>
    );
  }
}

export { CreateAssignmentWrapper as BaseCreateAssignmentWrapper };

const mapStateToProps = (state) => ({
  newAssignment: instructorSelectors.getNewAssignment(state),
  dueDateError: instructorSelectors.getDueDateError(state),
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  setNewAssignment: (payload) => dispatch(instructorActions.setNewAssignment(payload)),
  resetNewAssignment: () => dispatch(instructorActions.resetNewAssignment()),
  setSavedAssignment: (payload) => dispatch(instructorActions.setSavedAssignment(payload)),
  setDueDateError: (payload) => dispatch(instructorActions.setDueDateError(payload)),
  setIsEdit: (payload) => dispatch(instructorActions.setIsEdit(payload)),
  setPrevCreateAssignmentStep: (payload) => dispatch(instructorActions.setPrevCreateAssignmentStep(payload))
});

export default compose(
  withFormUtilities,
  withProps(() => ({
    location: useLocation(),
    history: useHistory()
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateAssignmentWrapper);

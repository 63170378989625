import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ELSTokenHelper } from '@els/els-ui-common-react';
import { HashLinkResponse } from 'models/api-response';
import { AssessmentStatus, AssignmentType, UserRole } from 'models/enum';
import { CourseEntitlement, SimChartAssignment, User } from 'models/ui';
import { AppConstant, AppLinkActions, DefaultLocale, RouteParams, RoutePath, organizedISBNS } from 'constants/app.constant';
import { apiHelper, appHelper } from 'helpers';
import { getDefaultRequestHeaders } from 'helpers/api.helper';
import { isAssessmentGradingAllowed } from 'helpers/assignment.helper';
import history from 'helpers/history';
import { applinkService, assignmentService, caseStudyService, cookieService, courseService, featureService, logService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { instructorActions } from 'redux/ducks/instructor';
import { studentActions, studentSelectors } from 'redux/ducks/student';

interface RedirectPageProps {
  setLoggedInUser: Function;
  setUserRole: Function;
  setAuthor: Function;
  setIsBookOrganized: Function;
  setFullAssignment: Function;
  setLocale: (locale: string) => void;
  fullAssignment: SimChartAssignment;
  isInProgressGradingEnabledFlag: boolean;
  setIsInProgressGradingEnabledFlag: (flagValue: boolean) => void;
}
class RedirectPage extends Component<RedirectPageProps> {
  static displayName = 'RedirectPage';

  async componentDidMount(): Promise<void> {
    const { setIsInProgressGradingEnabledFlag } = this.props;
    await featureService.isInProgressGradingEnabled().then((flagValue) => {
      setIsInProgressGradingEnabledFlag(flagValue);
    });

    // check to see if we are here from a hash link
    const queryParams = queryString.parse(window.location.hash);
    const id = queryParams['/redirect?linkHash'];
    sessionStorage.setItem(AppConstant.IS_DEMO_EHR_NAVIGATED, 'false');

    this.props.setFullAssignment(null);

    if (id && typeof id === 'string') {
      // if we are here from a hash link, load it and pass the data
      applinkService
        .getlinkHashSession(id, getDefaultRequestHeaders({ 'X-App-ID': 'SIMSNG' }))
        .then((response: HashLinkResponse) => {
          appHelper.saveToken(response.data.token);
          localStorage.setItem(AppConstant.COOKIE_LINK_ID, response.data.linkId);
          this.finishLoad(response.data.token, response.data.linkId);
        })
        .catch(() => {
          this.clearCookies();
          history.push(RoutePath.home);
        });
    } else {
      console.log('load id from local storage', localStorage.getItem(AppConstant.COOKIE_LINK_ID));
      this.finishLoad(localStorage.getItem(AppConstant.TOKEN_KEY), localStorage.getItem(AppConstant.COOKIE_LINK_ID));
    }
  }

  finishLoad = (tokenParam: string, linkIdParam: string) => {
    // if we dont have them at this point, bail.
    if (isNil(tokenParam) || isNil(linkIdParam)) {
      history.push(RoutePath.home);
      return;
    }
    const { user } = ELSTokenHelper.parseToken(tokenParam);
    const expiredTime = moment(user.expireDate);
    if (expiredTime.isBefore(moment())) {
      this.clearCookies();
      history.push(RoutePath.home);
      return;
    }
    // because we are getting the token from hash link, we have to assume that the user needs to be logged in.
    const { firstName, lastName, emailAddress } = user;
    const appUser: User = { id: user.userId, firstName, lastName, emailAddress };
    this.props.setLoggedInUser(appUser);
    this.props.setUserRole(user.role);
    this.props.setAuthor(cookieService.get(AppConstant.COOKIE_IS_AUTHOR) === 'true');
    appHelper.saveToken(tokenParam);

    // This makes the data call to the app link link service returning the params.
    // The params contain the assignmentId, courseId, and Action that was sent.
    // Based on this action, params from app link a the Instructor or Student is sent the specific page.
    assignmentService
      .getSessionParams(linkIdParam)
      .then((applinkData) => this.redirectToCorrespondingPage(applinkData, user))
      .catch((error) => {
        logService.log(`Failed to app link call: ${error}`);
      });
  };
  clearCookies = () => {
    cookieService.remove(AppConstant.COOKIE_TOKEN_NAME);
    cookieService.remove(AppConstant.COOKIE_IS_AUTHOR);
  };
  getContentIdFromLinkData = (applinkData) => {
    const { contentId } =
      applinkData.data.outPostBody && applinkData.data.outPostBody.catalogItem && applinkData.data.outPostBody.catalogItem.attributes
        ? applinkData.data.outPostBody.catalogItem.attributes
        : { contentId: '' };
    return contentId;
  };

  handleInstructorRedirect = async (applinkData) => {
    const { action } = applinkData.data;
    const { assignmentType } = applinkData.data;
    const { assignmentId } = applinkData.data.outPostBody;

    switch (action) {
      case AppLinkActions.ASSIGNMENT_CREATE:
        history.push({
          pathname: RoutePath.instructor.home,
          search: this.getContentIdFromLinkData(applinkData) ? `?caseStudyId=${this.getContentIdFromLinkData(applinkData)}` : ''
        });
        break;
      case AppLinkActions.ASSIGNMENT_EDIT:
        history.push(RoutePath.instructor.editAssignment.replace(RouteParams.ASSIGNMENT_ID, assignmentId));
        break;
      case AppLinkActions.ASSIGNMENT_PREVIEW:
        // there is no assignment id in these scenarios, but a case study ID is sent from courseware.
        if (!assignmentId) {
          const caseStudyRes = await caseStudyService.prepareDemoCaseStudy({ caseStudyId: this.getContentIdFromLinkData(applinkData) });
          const { demoAssessmentId } = caseStudyRes.data;
          history.push(RoutePath.instructor.caseStudyDemo.replace(RouteParams.ASSIGNMENT_ID, demoAssessmentId));
        } else if (assignmentType === AssignmentType.CASE_STUDY) {
          history.push(RoutePath.instructor.caseStudyDemo.replace(RouteParams.ASSIGNMENT_ID, assignmentId));
        } else if (assignmentType === AssignmentType.EMPTY_EHR) {
          history.push(RoutePath.instructor.blankCharting.replace(RouteParams.ASSIGNMENT_ID, assignmentId));
        }
        break;

      case AppLinkActions.ASSIGNMENT_PERFORMANCE_VIEW:
        history.push(RoutePath.instructor.grade.replace(RouteParams.ASSIGNMENT_ID, assignmentId));
        break;

      default:
        history.push(RoutePath.home);
        break;
    }
  };

  handleStudentRedirect = async (applinkData, user) => {
    const { fullAssignment, isInProgressGradingEnabledFlag } = this.props;
    const { action } = applinkData.data;
    const { assignmentId } = applinkData.data.outPostBody;
    const assessmentId = fullAssignment.eolsAssignmentId;
    const submissionRS = await assignmentService
      .getAssessmentSubmissionSummary(assessmentId, user.userId)
      .then((response) => response.data)
      .catch(() => {
        return null; // Return null instead of Error object to create new assessment on first access.
      });

    switch (action) {
      case AppLinkActions.ASSESSMENT_START: {
        if (submissionRS && isAssessmentGradingAllowed(isInProgressGradingEnabledFlag, submissionRS.status)) {
          this.sendStudentToSubmissionOrAssignment(assignmentId, user);
          break;
        }
        // Use assessmentCreateAllowed indicate that an assignment should be created
        // if not found when fetching.
        // Because courseware doesn't call api to create simchart assessment in some cases like course copy.
        history.push({
          pathname: RoutePath.student.launchAssignmentEntryPoint.replace(RouteParams.ASSIGNMENT_ID, assignmentId),
          search: `?assessmentCreateAllowed=true`
        });
        break;
      }
      case AppLinkActions.ASSIGNMENT_PERFORMANCE_VIEW: {
        this.sendStudentToSubmissionOrAssignment(assignmentId, user);
        break;
      }

      default:
        history.push(RoutePath.student.home);
        break;
    }
  };

  redirectToCorrespondingPage = async (applinkData, user) => {
    const { assignmentId } = applinkData.data.outPostBody;
    const { courseId } = user.appParams;
    await this.checkCourseISBNs(courseId);
    await this.checkIsbnThenGetLocale({ courseId, assignmentId });

    if (AppLinkActions.AUTHORING === applinkData.data.action) {
      // TODO: ANZ Post MVP.  Set locale in authoring mode
      this.props.setAuthor(true);
      history.push(`${RoutePath.instructor.author}?locale=${DefaultLocale}`);
    } else if (user.role === UserRole.INSTRUCTOR) {
      await this.handleInstructorRedirect(applinkData);
    } else if (user.role === UserRole.STUDENT) {
      await this.handleStudentRedirect(applinkData, user);
    }
  };

  sendStudentToSubmissionOrAssignment = async (assignmentId, user) => {
    const response = await assignmentService.getOrCreateAssessmentByUserIdAndAssignmentId(assignmentId, user.userId);
    history.push(RoutePath.student.assignmentSubmission.replace(RouteParams.ASSESSMENT_ID, response.data.id));
  };

  checkIsbnThenGetLocale = async ({ courseId, assignmentId }: { courseId: string; assignmentId: number }): Promise<void> => {
    const { setFullAssignment } = this.props;
    if (!assignmentId) {
      try {
        const resCourse = await courseService.getCourseSectionIsbn(courseId);
        await this.getLocale(resCourse?.data);
      } catch (error) {
        this.getLocale(null);
      }
    } else {
      try {
        const assignment = await this.getFullAssignmentFromApi(assignmentId);
        setFullAssignment(assignment);

        if (assignment?.isbn) {
          await this.getLocale(assignment?.isbn);
          return;
        }

        const resCourse = await courseService.getCourseSectionIsbn(courseId);
        await this.getLocale(resCourse?.data);
      } catch (error) {
        console.log('checkIsbnThenGetLocale have assignmentId', error);
        this.getLocale(null);
      }
    }
  };

  getLocale = async (isbn: string): Promise<void> => {
    const { setLocale } = this.props;
    let locale = DefaultLocale;

    if (!isbn) {
      setLocale(locale);
      return;
    }

    await assignmentService
      .getIsbnLocale(isbn)
      .then((res) => {
        locale = res.data;
      })
      .finally(() => {
        setLocale(locale);
      });
  };

  getFullAssignmentFromApi = async (assignmentId: number): Promise<SimChartAssignment> => {
    const { fullAssignment } = this.props;

    if (isEmpty(fullAssignment)) {
      const assignmentRes = await assignmentService.getSimChartAssignment(assignmentId);
      return assignmentRes.data;
    }

    return this.props.fullAssignment;
  };

  checkCourseISBNs = async (courseId: string): Promise<void> => {
    const entitlementList: CourseEntitlement[] = [];
    return courseService
      .getCourse(courseId)
      .then(async (res) => {
        res.data.entitlements.forEach((entitlement) => entitlementList.push(JSON.parse(entitlement.data)));
        return this.checkProductTypeKeysForBookOrObjectISBNs(entitlementList);
      })
      .then((result) => {
        this.props.setIsBookOrganized(result);
      })
      .catch((err) => apiHelper.showApiError(`can not get course ${err}`));
  };

  checkProductTypeKeysForBookOrObjectISBNs = async (courseEntitlements: CourseEntitlement[]): Promise<boolean> => {
    return courseEntitlements.some(
      (courseEntitlement) => courseEntitlement.productTypeKey === organizedISBNS.SHERPATH_BOOK_ORGANIZED || courseEntitlement.productTypeKey === organizedISBNS.OBJECTIVE_ORGANIZED
    );
  };

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  fullAssignment: studentSelectors.getFullAssignment(state),
  isInProgressGradingEnabledFlag: appSelectors.getIsInProgressGradingEnabledFlag(state)
});

const mapDispatchToProps = {
  fetchMenuItems: appActions.fetchMenuItems,
  setLoggedInUser: appActions.setLoggedInUser,
  setUserRole: appActions.setUserRole,
  setAuthor: (value) => appActions.setIsAuthor(value),
  setIsBookOrganized: (value) => instructorActions.setIsBookOrganized(value),
  setFullAssignment: studentActions.setFullAssignment,
  setLocale: (locale: string) => appActions.setLocale(locale),
  setIsInProgressGradingEnabledFlag: (flagValue: boolean) => appActions.setIsInProgressGradingEnabledFlag(flagValue)
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);

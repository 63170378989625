import { Component } from 'react';
import { LaboratoryHistoryRecord } from 'models/api-response';
import { Locales } from 'constants/app.constant';
import { ChartHistory } from 'components/common';
import { ELSButton } from 'components/common/els';

export interface LaboratoryHistoryProps {
  chartHistory: LaboratoryHistoryRecord[];
  locale: Locales;
  deleteHistory: Function;
  onStatusClick: Function;
}

class LaboratoryHistory extends Component<LaboratoryHistoryProps> {
  static displayName = 'LaboratoryHistory';
  statusRender = (row) => {
    return <ELSButton type="link" text={row.status} onClick={() => this.props.onStatusClick(row)} />;
  };
  render() {
    const dataSource = {
      title: 'Chart History',
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true },
        { text: 'Frequency', field: 'frequency' },
        { text: 'Order Description', field: 'orderDescription' },
        { text: 'Order Details', field: 'orderDetails' },
        { text: 'Special Instructions', field: 'notes' },
        { text: 'Status', customRender: this.statusRender },
        { field: 'actionDelete' }
      ],
      css: ['column__chart-time', '', '', '', '', '', 'column__delete'],
      data: this.props.chartHistory
    };
    return <ChartHistory dataSource={dataSource} deleteRecord={this.props.deleteHistory} />;
  }
}

export default LaboratoryHistory;

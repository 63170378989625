import { v4 } from 'uuid';
import { ELSIcon } from 'components/common/els';
import './multiline.tooltip.scss';

interface MultilineTooltipProps {
  contents: string[];
  onClose: Function;
}

const MultilineTooltip = (props: MultilineTooltipProps) => (
  <div className="c-els-flyout c-els-flyout--top-left multiline-tooltip">
    <div className="c-els-flyout__pointer" />
    <div className="multiline-tooltip__close-btn">
      <ELSIcon name="close" prefix="hmds" color="n3" onClick={props.onClose} />
    </div>
    {props.contents?.map((content) => (
      <div key={`${content}-${v4()}`} className="c-els-flyout__window">{`• ${content}`}</div>
    ))}
  </div>
);

MultilineTooltip.displayName = 'MultilineTooltip';
export default MultilineTooltip;

export enum FormField {
  CATEGORY = 'category',
  ORDER_DESCRIPTION = 'laboratoryTest',
  ORDER_PRIORITY = 'priority',
  ORDER_FREQUENCY = 'frequency',
  ORDER_START_DATE = 'startDate',
  ORDER_START_TIME = 'startTime',
  SPECIMEN = 'specimen',
  SPECIAL_INSTRUCTIONS = 'specialInstructions',
  STATUS = 'status',
  RESULTS_RECEIVED_DATE = 'resultsReceivedDate',
  RESULTS_RECEIVED_TIME = 'resultsReceivedTime'
}

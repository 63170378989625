import cx from 'classnames';
import { FormFieldDataType, ScaleDirection } from 'models/enum';
import { ChartMetaFormField, ContentItem, GroupScaleQuestion } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, Scale, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from 'components/features/chart/special-charts/delivery-room-record/constants';
import ScoreTable from './ScoreTable';
import TotalScoreTable from './TotalScoreTable';
import MultiLine from '../multi-line/MultiLine';

interface ScaleWrapperProps {
  id: string;
  showRollUp: boolean;
  chartContent: ContentItem[];
  onChange: Function;
  disabled: boolean;
  contextDirectionMap?: Map<string, ScaleDirection>;
  groupScaleQuestion?: GroupScaleQuestion[];
  showScoreInAnswer?: boolean;
  isAriaRequired?: boolean;
  formFieldMap: Map<string, ChartMetaFormField>;
  renderNestedField?: Function;
  isHiddenScore: boolean;
  isNotSaveChildValueInScore?: boolean;
}

const ScaleWrapper = (props: ScaleWrapperProps) => {
  const getScore = (item: ChartMetaFormField): string => {
    return chartHelper.isUnscoredQuestion(item) ? null : item.extraData.score?.value || '0';
  };
  const scaleRollUpWrapper = props.chartContent.find((item) => item.formFieldId === chartHelper.getScaleRollUpWrapperId(props.id));
  const scaleQuestions = props.chartContent
    .filter((item) => item.dataType === FormFieldDataType.SCALE_QUESTION && !props.formFieldMap.get(item.formFieldId).hide)
    .map((item) => props.formFieldMap.get(item.formFieldId));
  return (
    <div className="scale-wrapper">
      {props.groupScaleQuestion && (
        <div>
          {props.groupScaleQuestion.map((group) => {
            const questions = scaleQuestions.filter((question) => group.formFields.includes(question.name));
            return (
              <div key={group.label}>
                {!props.isHiddenScore ? (
                  <h4>{group.label}</h4>
                ) : (
                  <Box mb>
                    <MultiLine text={group.label} />
                  </Box>
                )}
                <ELSFlex className="u-flex-gutter-3x" wrap left>
                  <ELSFlexItem w={props.isHiddenScore ? '1o1' : '1o2'} md="1o1">
                    {questions.map((item) => (
                      <Scale
                        key={props.id}
                        id={props.id}
                        formFieldId={item.name}
                        questionId={item.extraData.questionId}
                        title={item.extraData.questionTitle}
                        question={item.label}
                        options={item.chartContent.filter((opt) => !opt.isHidden)}
                        onClick={(event, option) => props.onChange(event, option, item)}
                        contexts={item.extraData.questionContexts}
                        disabled={props.disabled}
                        contextDirection={props.contextDirectionMap?.get(item.name)}
                        showScoreInAnswer={props.showScoreInAnswer}
                        isAriaRequired={props.isAriaRequired}
                        renderNestedField={props.renderNestedField}
                        isNotSaveChildValueInScore={props.isNotSaveChildValueInScore}
                      />
                    ))}
                  </ELSFlexItem>
                  {!props.isHiddenScore && (
                    <ELSFlexItem w="1o2" md="1o1">
                      <ScoreTable group={group} scaleQuestions={questions} />
                    </ELSFlexItem>
                  )}
                </ELSFlex>
                {group.hasSectionDivider && (
                  <Box className="scale-divider">
                    <SectionDivider />
                  </Box>
                )}
              </div>
            );
          })}

          <Box className="scale-divider">
            <SectionDivider />
          </Box>
          <span className="scale__delivery score-tag">{`Total Score: ${scaleRollUpWrapper?.score?.value || 0}`}</span>
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2">
              <h2>Total Score</h2>
            </ELSFlexItem>
            <ELSFlexItem w="1o2 u-els-padding-top-3x">
              <TotalScoreTable scaleQuestions={scaleQuestions} />
            </ELSFlexItem>
          </ELSFlex>
        </div>
      )}
      {!props.groupScaleQuestion &&
        scaleQuestions.map((item) => (
          <div key={item.name}>
            <Scale
              id={props.id}
              formFieldId={item.name}
              questionId={item.extraData.questionId}
              title={item.extraData.questionTitle}
              question={item.label}
              score={getScore(item)}
              options={item.chartContent.filter((opt) => !opt.isHidden)}
              onClick={(event, option) => props.onChange(event, option, item)}
              contexts={item.extraData.questionContexts}
              disabled={props.disabled}
              contextDirection={props.contextDirectionMap?.get(item.name)}
              showScoreInAnswer={props.showScoreInAnswer}
              isAriaRequired={props.isAriaRequired}
              renderNestedField={props.renderNestedField}
              isNotSaveChildValueInScore={props.isNotSaveChildValueInScore}
            />
            <Box className="scale-divider">
              <SectionDivider />
            </Box>
          </div>
        ))}
      {props.showRollUp && (
        <div>
          <ELSFlex spaceBetween wrap>
            <ELSFlexItem middle>
              <h2>{scaleRollUpWrapper.label}</h2>
            </ELSFlexItem>
            {/* SIMNG-4575: Show total score of each type instead of total score of all type */}
            {!props.isHiddenScore && props.id !== FormField.APGAR_SCORE && (
              <ELSFlexItem middle>
                <span className="u-els-bold score-tag">{`Total Score: ${scaleRollUpWrapper.score?.value || 0}`}</span>
              </ELSFlexItem>
            )}
          </ELSFlex>
          <Box mt>
            {props.chartContent
              ?.filter((content) => content.dataType === FormFieldDataType.SCALE_ROLL_UP || content.dataType === FormFieldDataType.SCALE_ROLL_UP_HIGHLIGHT)
              .map((content) => (
                <Box key={content.id} className={cx('roll-up-content', { 'scale-roll-up--highlight': content.dataType === FormFieldDataType.SCALE_ROLL_UP_HIGHLIGHT })}>
                  <p>
                    <span className="special-character">•</span>
                    <span className="roll-up-text">{content.label}</span>
                  </p>
                </Box>
              ))}
          </Box>
        </div>
      )}
    </div>
  );
};

ScaleWrapper.displayName = 'ScaleWrapper';
export default ScaleWrapper;

import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { Box, ButtonLinkIcon, ChartActions, ChartPermission, ErrorFormField, SectionDivider, withEmbeddedChart } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import EdinburghPostnatalDepressionScaleComp from 'components/features/chart/special-charts/edinburgh-postnatal-depression-scale/EdinburghPostnatalDepressionScale';
import PsychosocialAssessmentComp from 'components/features/chart/system-assessment/psychosocial-assessment/PsychosocialAssessment';
import { FormField, FormFieldLabel, SectionTitle } from './constants';

export const EmbeddedPsychosocialAssessment = withEmbeddedChart(PsychosocialAssessmentComp);
export const EmbeddedEdinburghPostnatalViewAssessment = withEmbeddedChart(EdinburghPostnatalDepressionScaleComp);

export interface PostpartumAssessmentViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  handlePsychosocialClick: Function;
  handleEdinburghClick: Function;
  contextDirectionMap?: Map<string, ScaleDirection>;
  isEdinburghSidebarOpen: boolean;
  isPsychosocialSidebarOpen: boolean;
  edinburghSidebarKey: number;
  psychosocialSidebarKey: number;
  handleCloseSidebar: Function;
  enableEmbeddedChart: boolean;
  onSaveEdinburghEmbeddedChart: Function;
  onSavePsychosocialEmbeddedChart: Function;
  onDepressionRadioChange: Function;
  totalPsychosocialScore: string;
  totalEdinburghDepressionScore: string;
  totalScore: string;
}

const PostpartumAssessmentView = (props: PostpartumAssessmentViewProps) => {
  const {
    chartActionsProps,
    formFieldMap,
    formSubmittedCount,
    contextDirectionMap,
    handlePsychosocialClick,
    handleEdinburghClick,
    isEdinburghSidebarOpen,
    edinburghSidebarKey,
    handleCloseSidebar,
    isPsychosocialSidebarOpen,
    psychosocialSidebarKey,
    onSaveEdinburghEmbeddedChart,
    onSavePsychosocialEmbeddedChart,
    enableEmbeddedChart,
    onDepressionRadioChange,
    totalPsychosocialScore,
    totalEdinburghDepressionScore,
    totalScore
  } = props;

  return (
    <div className="postpartum-assessment">
      <h1>{SectionTitle.POSTPARTUM_ASSESSMENT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <SectionDivider />

      <Box mt2>
        <h2>{SectionTitle.POSTPARTUM}</h2>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.ROOMING_STATUS)}
              options={{ displayHorizontal: true }}
              formSubmittedCount={formSubmittedCount}
            />
            <h3>{SectionTitle.BEHAVIORAL}</h3>
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.SIGNS_DEPRESSION_SADNESS)}
              options={{ displayHorizontal: true }}
              formSubmittedCount={formSubmittedCount}
              onChange={onDepressionRadioChange}
            />
            <div className="o-els-flex-layout o-els-flex-layout--wrap o-els-flex-layout--left">
              <ButtonLinkIcon
                isDisabled={!enableEmbeddedChart}
                iconName="enlarge"
                iconSize="1x"
                text="Psychosocial Assessment"
                onClick={() => handlePsychosocialClick()}
                preventAutoPending
                className="o-els-flex-layout__item u-els-width-1o2"
              />
              <ButtonLinkIcon
                isDisabled={!enableEmbeddedChart}
                iconName="enlarge"
                iconSize="1x"
                text="Edinburgh Postnatal Depression Scale"
                onClick={() => handleEdinburghClick()}
                preventAutoPending
                className="o-els-flex-layout__item u-els-width-1o2"
              />
            </div>
            <SectionDivider />
            <div className="scale-wrapper">
              <div className="score-box">
                <div>
                  <h4>{SectionTitle.TOTAL_PSYCHOSOCIAL_SCORE}</h4>
                </div>
                <div className="score-tag">
                  <span className="u-els-bold">{`${FormFieldLabel.TOTAL_SCORE} ${totalPsychosocialScore}`}</span>
                </div>
              </div>
              <SectionDivider />
              <div className="score-box">
                <div>
                  <h4>{SectionTitle.TOTAL_EDINBURGH_POSTNATAL_DEPRESSION_SCORE}</h4>
                </div>
                <div className="score-tag">
                  <span className="u-els-bold">{`${FormFieldLabel.TOTAL_SCORE} ${totalEdinburghDepressionScore}`}</span>
                </div>
              </div>
              <SectionDivider />
              <div className="score-box">
                <div>
                  <h4>{SectionTitle.TOTAL_SCORE}</h4>
                </div>
                <div className="score-tag">
                  <span className="u-els-bold">{`${FormFieldLabel.TOTAL_SCORE} ${totalScore}`}</span>
                </div>
              </div>
            </div>
            <SectionDivider />

            <h3>{SectionTitle.BREASTS}</h3>
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.BREAST_FEEDING_RADIO)}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
              formSubmittedCount={formSubmittedCount}
            />
            <ELSFlex left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-margin-top"
                  formField={formFieldMap.get(FormField.BREAST_FEEDING_CHECKBOX)}
                  useDefaultMargin={false}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_LATCH_BREAST_FEEDING_ASSESSMENT_SCALE)} formSubmittedCount={props.formSubmittedCount}>
        <SectionDivider />
        <Box mt2>
          <h2>{SectionTitle.LATCH_BREAST_FEEDING_ASSESSMENT}</h2>
          <ErrorFormField
            formField={formFieldMap.get(FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE)}
            formSubmittedCount={formSubmittedCount}
            options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: true }}
            formFieldMap={formFieldMap}
          />
        </Box>
        <p className="u-els-italic u-els-margin-bottom-3x u-els-margin-top-3x">{FormFieldLabel.HIGH_SCORE_TEXT}</p>
        <SectionDivider />
        <p className="u-els-italic u-els-margin-bottom-3x u-els-margin-top-3x">{FormFieldLabel.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE_TEXT}</p>
      </ErrorFormField>

      <SectionDivider />

      <Box mt2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{SectionTitle.FUNDUS}</h3>
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.FUNDUS_CHARACTERISTICS)}
              options={{ displayHorizontal: true }}
              formSubmittedCount={formSubmittedCount}
            />
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.FUNDUS_POSITION)}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
              formSubmittedCount={formSubmittedCount}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.FUNDUS_POSITION_DEVIATED)} useDefaultMargin={false} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FUNDUS_HEIGHT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{SectionTitle.LOCHIA}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.LOCHIA_COLOR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.LOCHIA_QUANTITY)} useDefaultMargin={false} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={formFieldMap.get(FormField.LOCHIA_QUANTITY_OTHER)}
                  useDefaultMargin={false}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{SectionTitle.PERINEUM}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PERINEUM_EDEMA)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PERINEUM_INCISION_LACERATION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PERINEUM_COLOR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PERINEUM_OTHER)} useDefaultMargin={false} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={formFieldMap.get(FormField.PERINEUM_OTHER_DRAINAGE_DESCRIBE)}
                  useDefaultMargin={false}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{SectionTitle.RECTUM}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RECTUM_HEMORRHOIDS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      <EmbeddedEdinburghPostnatalViewAssessment
        key={edinburghSidebarKey}
        isSidebarOpen={isEdinburghSidebarOpen}
        onClose={handleCloseSidebar}
        selectedNavId={NAV_ID.EDINBURGH_POSTNATAL_DEPRESSION}
        onSave={onSaveEdinburghEmbeddedChart}
        onDiscard={handleCloseSidebar}
      />
      <EmbeddedPsychosocialAssessment
        key={psychosocialSidebarKey}
        isSidebarOpen={isPsychosocialSidebarOpen}
        onClose={handleCloseSidebar}
        selectedNavId={NAV_ID.PSYCHOSOCIAL_ASSESSMENT}
        onSave={onSavePsychosocialEmbeddedChart}
        onDiscard={handleCloseSidebar}
      />
    </div>
  );
};

export default PostpartumAssessmentView;

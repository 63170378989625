import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment, ImpressionFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartComponentProps } from 'models/ui';
import { NAV_ID, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withFormUtilities } from 'components/common';
import ImpressionsView, { ImpressionsViewProps } from './ImpressionsView';

export enum FormField {
  IMPRESSIONS = 'impressions',
  PLAN = 'plan',
  SIGNATURE = 'signature',
  DAY = 'day',
  TIME = 'time'
}

interface ImpressionsState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ImpressionFragment;
}

export interface ImpressionsProps extends ChartComponentProps {
  assessment: AssessmentRS;
  enableDisplayRecordsButton: boolean;
}

class Impressions extends Component<ImpressionsProps, ImpressionsState> {
  static displayName = 'Impressions';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      statusFragment: null,
      selectedRecord: null
    };
  }

  componentDidMount() {
    const { assessment, isAuthor } = this.props;
    if (!isAuthor) {
      const navIds = [NAV_ID.HISTORY_PHYSICAL, NAV_ID.IMPRESSIONS];
      const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];
      appHelper.useLoader(chartService.loadFragments({ chartId: assessment.simChartId, navIds, fragmentTypes }).then(this.bindChartHistory), {
        errorMessage: 'can not load chart fragment'
      });
    }
  }

  bindChartHistory = ({ data: fragments }) => {
    const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.IMPRESSIONS, NAV_ID.HISTORY_PHYSICAL);
    this.setState({
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId),
      statusFragment,
      selectedRecord: chartFragment
    });
  };

  saveChartData = (fragment: ChartFragment, isDelete: boolean) => {
    return appHelper.useLoader(
      this.props
        .saveChartData(fragment)
        .then(() => this.props.loadChartData())
        .then(this.bindChartHistory)
        .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
    );
  };

  handleCreate = (newRecord: ImpressionFragment) => {
    const record: ImpressionFragment = {
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData: {
        ...newRecord.chartData
      }
    };
    return this.saveChartData(record, false);
  };

  handleUpdate = (fragment: ImpressionFragment) => this.saveChartData(fragment, false);

  handleCancel = () => this.setState({ selectedRecord: null }, () => this.navigateToHistoryAndPhysical());

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  render() {
    const viewProps: ImpressionsViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      selectedNavId: this.props.selectedNavId,
      onNavigate: this.navigateToHistoryAndPhysical,
      onSave: this.state.selectedRecord ? this.handleUpdate : this.handleCreate,
      onCancel: this.handleCancel,
      displayAuthoringData: this.props.displayAuthoringData,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      ...appHelper.getChartSharedProps(this.props)
    };

    return <ImpressionsView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

const enhancers = [connect(mapStateToProps), withFormUtilities];
export { Impressions as BaseImpressions };
export default compose(...enhancers)(Impressions);

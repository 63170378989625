import convert from 'convert-units';
import { HeightWeightRecord } from 'models/api-response';
import { ChartMetaFormField } from 'models/ui';
import { chartService } from 'services';
import { FormField } from './constants';

export const getStringValue = (value: number) => (value ? value.toString() : '');

export const getFixedStringValue = (value: number, fixed = 0) => (value ? value.toFixed(fixed).toString() : '');

export const convertForHeightFeetAndInchInput = (feet: number, inch: number) => {
  let newFeet: number;
  let newInch: number;
  let newCm: number;

  if (feet || inch) {
    let totalInch = 0;
    if (feet) {
      totalInch = convert(feet).from('ft').to('in');
    }
    if (inch) {
      totalInch += inch;
    }
    newCm = convert(totalInch).from('in').to('cm');
    newFeet = Math.floor(totalInch / 12);
    newInch = totalInch % 12;
  }

  return { newFeet, newInch, newCm };
};

export const convertForHeightCmInput = (cm: number) => {
  let newFeet: number;
  let newInch: number;

  if (cm) {
    const totalInch = convert(cm).from('cm').to('in');
    newFeet = Math.floor(totalInch / 12);
    newInch = Math.round(totalInch) % 12;
  }

  return { newFeet, newCm: cm, newInch };
};

export const convertForWeightPoundAndOunceInput = (pound: number, ounce: number) => {
  let newPound: number;
  let newOunce: number;
  let newKg: number;
  let newGram: number;

  if (pound || ounce) {
    let totalOunce = 0;
    if (pound) {
      totalOunce = convert(pound).from('lb').to('oz');
    }
    if (ounce) {
      totalOunce += ounce;
    }

    const totalGram = convert(totalOunce).from('oz').to('g');

    newOunce = totalOunce % 16;
    newPound = Math.floor(totalOunce / 16);
    newKg = Math.floor(totalGram / 1000);
    newGram = totalGram % 1000;
  }

  return { newGram, newKg, newOunce, newPound };
};

export const convertForWeightKgAndGramInput = (kg: number, gram: number) => {
  let newPound: number;
  let newOunce: number;
  let newKg: number;
  let newGram: number;

  if (kg || gram) {
    let totalGram = 0;
    if (kg) {
      totalGram = convert(kg).from('kg').to('g');
    }
    if (gram) {
      totalGram += gram;
    }
    const totalOunce = convert(totalGram).from('g').to('oz');
    newKg = Math.floor(totalGram / 1000);
    newGram = totalGram % 1000;
    newPound = Math.floor(totalOunce / 16);
    newOunce = totalOunce % 16;
  }

  return { newGram, newKg, newOunce, newPound };
};

export const convertForAbdominalGirthCmInput = (abdominalGirthCm: number) => {
  let abdominalGirthInch: number;
  if (abdominalGirthCm) {
    const totalInch = convert(abdominalGirthCm).from('cm').to('in');
    abdominalGirthInch = Math.round(totalInch) % 12;
  }
  return abdominalGirthInch;
};

export const convertForAbdominalGirthInchInput = (abdominalGirthInch: number) => {
  let abdominalGirthCm: number;
  if (abdominalGirthInch) {
    abdominalGirthCm = convert(abdominalGirthInch).from('in').to('cm');
  }
  return abdominalGirthCm;
};

export const convertForHeadCircumferenceCmInput = (headCircumferenceCm: number) => {
  let headCircumferenceInch: number;
  if (headCircumferenceCm) {
    const totalInch = convert(headCircumferenceCm).from('cm').to('in');
    headCircumferenceInch = Math.round(totalInch) % 12;
  }
  return headCircumferenceInch;
};

export const convertForHeadCircumferenceInchInput = (headCircumferenceInch: number) => {
  let headCircumferenceCm: number;
  if (headCircumferenceInch) {
    headCircumferenceCm = convert(headCircumferenceInch).from('in').to('cm');
  }
  return headCircumferenceCm;
};

export const convertForChestCircumferenceCmInput = (chestCircumferenceCm: number) => {
  let chestCircumferenceInch: number;
  if (chestCircumferenceCm) {
    const totalInch = convert(chestCircumferenceCm).from('cm').to('in');
    chestCircumferenceInch = Math.round(totalInch) % 12;
  }
  return chestCircumferenceInch;
};

export const convertForChestCircumferenceInchInput = (chestCircumferenceInch: number) => {
  let chestCircumferenceCm: number;
  if (chestCircumferenceInch) {
    chestCircumferenceCm = convert(chestCircumferenceInch).from('in').to('cm');
  }
  return chestCircumferenceCm;
};

export const flattenHeightData = (data: HeightWeightRecord, field: FormField): HeightWeightRecord => {
  let heightWeightRecord: HeightWeightRecord;

  const getMeasureValue = (value) => (value ? parseInt(value, 0) : undefined);

  const feet: number | undefined = getMeasureValue(data.height?.feet);
  const inch: number | undefined = getMeasureValue(data.height?.inches);
  const cm: number | undefined = getMeasureValue(data.height?.centimeters);

  const pound: number | undefined = getMeasureValue(data.weight?.pounds);
  const ounce: number | undefined = getMeasureValue(data.weight?.ounces);
  const kg: number | undefined = getMeasureValue(data.weight?.kilograms);
  const gram: number | undefined = getMeasureValue(data.weight?.grams);

  switch (field) {
    case FormField.HEIGHT_FEET:
    case FormField.HEIGHT_INCH: {
      const { newCm, newFeet, newInch } = convertForHeightFeetAndInchInput(feet, inch);
      heightWeightRecord = {
        ...data,
        height: {
          centimeters: getFixedStringValue(newCm, 1),
          feet: getStringValue(newFeet),
          inches: getStringValue(newInch)
        }
      };
      break;
    }
    case FormField.HEIGHT_CM: {
      const { newCm, newFeet, newInch } = convertForHeightCmInput(cm);
      heightWeightRecord = {
        ...data,
        height: {
          centimeters: getStringValue(newCm),
          feet: getStringValue(newFeet),
          inches: getStringValue(newInch)
        }
      };
      break;
    }
    case FormField.WEIGHT_POUND:
    case FormField.WEIGHT_OUNCE: {
      const { newGram, newKg, newOunce, newPound } = convertForWeightPoundAndOunceInput(pound, ounce);
      heightWeightRecord = {
        ...data,
        weight: {
          grams: getStringValue(newGram),
          ounces: getFixedStringValue(newOunce),
          kilograms: getStringValue(newKg),
          pounds: getStringValue(newPound),
          choice: data.weight?.choice,
          weightAdmission: data.weight?.weightAdmission
        }
      };
      break;
    }
    case FormField.WEIGHT_KG:
    case FormField.WEIGHT_GRAM: {
      const { newGram, newKg, newOunce, newPound } = convertForWeightKgAndGramInput(kg, gram);
      heightWeightRecord = {
        ...data,
        weight: {
          grams: getStringValue(newGram),
          ounces: getFixedStringValue(newOunce),
          kilograms: getStringValue(newKg),
          pounds: getStringValue(newPound),
          choice: data.weight?.choice,
          weightAdmission: data.weight?.weightAdmission
        }
      };
      break;
    }
    default:
      break;
  }
  return heightWeightRecord;
};

export const getChartInputData = (formFieldMap: Map<string, ChartMetaFormField>, heightOrWeightChange: Function) => {
  const { buildPatientRecord } = chartService;

  let feetValue = formFieldMap.get(FormField.HEIGHT_FEET).value;
  let inchValue = formFieldMap.get(FormField.HEIGHT_INCH).value;
  let cmValue = formFieldMap.get(FormField.HEIGHT_CM).value;
  const ounceValue = formFieldMap.get(FormField.WEIGHT_OUNCE).value;
  const poundValue = formFieldMap.get(FormField.WEIGHT_POUND).value;
  const kgValue = formFieldMap.get(FormField.WEIGHT_KG).value;
  const gramValue = formFieldMap.get(FormField.WEIGHT_GRAM).value;
  const abdominalGirthCm = formFieldMap.get(FormField.ABDOMINAL_GIRTH_CM).value;
  const abdominalGirthInch = formFieldMap.get(FormField.ABDOMINAL_GIRTH_INCH).value;
  const anteriorFontanel = buildPatientRecord(formFieldMap, FormField.ANTERIOR_FONTANEL);
  const posteriorFontanel = buildPatientRecord(formFieldMap, FormField.POSTERIOR_FONTANEL);
  const headCircumferenceCm = formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_CM).value;
  const headCircumferenceInch = formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_INCH).value;
  const chestCircumferenceCm = formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_CM).value;
  const chestCircumferenceInch = formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_INCH).value;

  let updatedFields: ChartMetaFormField[] = [];
  if (cmValue && !inchValue && !feetValue) {
    updatedFields = heightOrWeightChange(formFieldMap.get(FormField.HEIGHT_CM));
  } else if (!cmValue && feetValue && !inchValue) {
    updatedFields = heightOrWeightChange(formFieldMap.get(FormField.HEIGHT_FEET));
  } else if (!cmValue && !feetValue && inchValue) {
    updatedFields = heightOrWeightChange(formFieldMap.get(FormField.HEIGHT_INCH));
  } else {
    updatedFields = heightOrWeightChange(formFieldMap.get(FormField.HEIGHT_INCH));
  }
  feetValue = updatedFields.find((field) => field.name === FormField.HEIGHT_FEET).value;
  inchValue = updatedFields.find((field) => field.name === FormField.HEIGHT_INCH).value;
  cmValue = updatedFields.find((field) => field.name === FormField.HEIGHT_CM).value;

  const abdominalGirth = abdominalGirthCm && abdominalGirthInch ? { abdominalGirthCm, abdominalGirthInch } : null;
  const headCircumference = headCircumferenceCm && headCircumferenceInch ? { headCircumferenceCm, headCircumferenceInch } : null;
  const chestCircumference = chestCircumferenceCm && chestCircumferenceInch ? { chestCircumferenceCm, chestCircumferenceInch } : null;

  return { cmValue, feetValue, inchValue, gramValue, kgValue, poundValue, ounceValue, abdominalGirth, anteriorFontanel, posteriorFontanel, headCircumference, chestCircumference };
};

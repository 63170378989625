import cx from 'classnames';
import { Student } from 'models/api-response';
import { SortDirection } from 'models/enum';
import { appHelper } from 'helpers';
import { Box, ButtonLinkIcon, InfoText } from 'components/common';
import { ELSCheckBox, ELSFlex, ELSFlexItem, ELSIcon, ELSTextBox } from 'components/common/els';
import './student.list.scss';

interface StudentListViewProps {
  courseName: string;
  allStudents: Student[];
  nameSearchValue: string;
  isAllStudentsChecked: boolean;
  showStudentList: boolean;
  sortDirection: SortDirection;
  onShowStudentList: Function;
  onSearchNameChange: Function;
  onStudentSort: Function;
  onAllStudentsChange: Function;
  onStudentChange: Function;
}

const StudentListView = (props: StudentListViewProps) => {
  const { courseName, allStudents, showStudentList, nameSearchValue, isAllStudentsChecked, sortDirection } = props;
  const { onShowStudentList, onSearchNameChange, onStudentSort, onAllStudentsChange, onStudentChange } = props;
  const studentSelectedCount = allStudents.filter((item) => item.selected).length;
  const studentSelectedText = appHelper.toSinglePlural(studentSelectedCount, `1 student selected`, `${studentSelectedCount} students selected`);
  const showStudentListIcon = showStudentList ? 'chevron-up' : 'chevron-down';
  const sortStudentListIcon = sortDirection === SortDirection.ASC ? 'sorting-up2' : 'sorting-down';
  const shouldDisabledAllOptions = allStudents.some((item) => item.isDisabledSelect);

  return (
    <div className="student-list">
      <ELSFlex middle spaceBetween>
        <ELSFlexItem>
          <span className="u-els-bold u-els-font-size-h4">Select Students</span>
          <div className="u-els-display-inline-block u-els-padding-left-1o2">
            <ButtonLinkIcon text={studentSelectedText} iconName={showStudentListIcon} iconPosition="right" iconSize="3o4" onClick={onShowStudentList} preventAutoPending />
          </div>
        </ELSFlexItem>
        <ELSFlexItem>
          <InfoText text='New students will also get assigned if "all students" are selected.' />
        </ELSFlexItem>
      </ELSFlex>

      {showStudentList && (
        <Box pl2>
          <Box mt>
            <ELSIcon prefix="gizmo" name="institution" align="sub" />
            <span className="u-els-padding-left u-els-font-size-h4">{courseName}</span>
          </Box>
          <Box mt className="u-els-width-1o2">
            <ELSTextBox iconLeft="search" iconLeftPrefix="gizmo" placeholder="Search students" value={nameSearchValue} changeHandler={onSearchNameChange} />
          </Box>
          <Box mt2 className="student-list__table">
            <ELSFlex left className="student-list__table-header">
              <ELSFlexItem>
                <ELSCheckBox isDisabled={shouldDisabledAllOptions} checked={isAllStudentsChecked} changeHandler={onAllStudentsChange} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ButtonLinkIcon text="All Students" iconName={sortStudentListIcon} iconPosition="right" iconSize="3o4" onClick={onStudentSort} preventAutoPending />
              </ELSFlexItem>
            </ELSFlex>
            {allStudents.map((student) => (
              <ELSFlex key={String(student.id)} left className={cx('student-list__row u-els-padding', { 'u-els-display-none': !student.isShown })}>
                <ELSFlexItem>
                  <ELSCheckBox isDisabled={student.isDisabledSelect} checked={student.selected} changeHandler={(event, checked) => onStudentChange(student.id, checked)} />
                </ELSFlexItem>
                <ELSFlexItem>{`${student.firstName} ${student.lastName}`.trim()}</ELSFlexItem>
              </ELSFlex>
            ))}
          </Box>
        </Box>
      )}
    </div>
  );
};

StudentListView.displayName = 'StudentListView';
export default StudentListView;

export enum FormField {
  BRADEN_SCALE = 'bradenScale',
  SENSORY_PERCEPTION = 'sensoryPerception',
  MOISTURE = 'moisture',
  ACTIVITY = 'activity',
  MOBILITY = 'mobility',
  NUTRITION = 'nutrition',
  FRICTION_SHEAR = 'frictionShear'
}

export enum Section {
  BRADEN_SCALE = 'Braden Scale',
  BRADEN_SCALE_PREDICTING_PRESSURE = 'Braden Scale for Predicting Pressure Sore Risk ©',
  TOTAL_BRADEN_SCALE_SCORE = 'Total Braden Scale Score'
}

export enum FormFieldLabel {
  NOTE = 'NOTE: If other major risk factors (such as advanced age, fever, poor dietary intake of protein, diastolic pressure below 60, hemodynamic instability) are present advance to next level of risk'
}

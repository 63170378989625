import { ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { AccordionBadge, Box, Sidebar } from 'components/common';
import ProgressNotesFormView from './ProgressNotesFormView';

export interface ProgressNotesSidebarViewProps {
  open: boolean;
  chartingAt: string;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  isDisabledSave: boolean;
  onClose: Function;
  onUpdate: Function;
  lastFocusableElement: number;
  sidebarProps: Function;
  locale: Locales;
}
const ProgressNotesSidebarView = ({
  open,
  chartingAt,
  chartMetaFormFields,
  formSubmittedCount,
  isDisabledSave,
  onClose: onCloseClick,
  onUpdate,
  lastFocusableElement,
  sidebarProps,
  locale
}: ProgressNotesSidebarViewProps) => (
  <Sidebar open={open} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} lastEnabledElement={lastFocusableElement} sidebarProps={sidebarProps}>
    <Box mt3>
      <h2>Edit Progress Note</h2>
      <Box mt>
        <AccordionBadge>{chartingAt && dateTimeHelper.formatDate({ date: dateTimeHelper.toMomentWithParsers(chartingAt).toDate(), locale, includeTime: true })}</AccordionBadge>
      </Box>
    </Box>
    <div className="sidebar__divider" />
    <ProgressNotesFormView chartMetaFormFields={chartMetaFormFields} formSubmittedCount={formSubmittedCount} isDisabledSave={isDisabledSave} onSave={onUpdate} isSidebarView />
  </Sidebar>
);

ProgressNotesSidebarView.displayName = 'ProgressNotesSidebarView';
export default ProgressNotesSidebarView;

import ConfirmationModal from './ConfirmationModal';

interface DiscardConfirmationModalProps {
  onOkClick: Function;
  onCancelClick: Function;
}

const DiscardConfirmationModal = (props: DiscardConfirmationModalProps) => (
  <ConfirmationModal message="Are you sure you want to discard changes? Your changes will not be saved." onOkClick={props.onOkClick} onCancelClick={props.onCancelClick} />
);
DiscardConfirmationModal.displayName = 'DiscardConfirmationModal';
export default DiscardConfirmationModal;

import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionLabel } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface ChildRiskAssessmentReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const AdolescentRiskAssessmentReadOnly = (props: ChildRiskAssessmentReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>{SectionLabel.ADOLESCENT_RISK_ASSESSMENT}</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.DENTAL_CARE}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.DENTAL_CARE} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.DENTAL_CARE_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.SEAT_BELT_USE}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.SEAT_BELT_USE} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.SEAT_BELT_USE_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.RIDING_IN_CAR}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.RIDING_IN_CAR} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.RIDING_IN_CAR_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.CARRYING_A_WEAPON}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.SLEEP} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.SLEEP_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.FEEL_UNSAFE_AT_SCHOOL}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.CARRYING_A_WEAPON} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.CARRYING_A_WEAPON_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.ATTEMPTED_SUICIDE}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.FEEL_UNSAFE_AT_SCHOOL} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.FEEL_UNSAFE_AT_SCHOOL_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.ABUSE_MALTREATMENT}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.ATTEMPTED_SUICIDE} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.ATTEMPTED_SUICIDE_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.SLEEP}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.ABUSE_MALTREATMENT} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.ABUSE_MALTREATMENT_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.AUTOMOBILE_CRASH}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.AUTOMOBILE_CRASH} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.AUTOMOBILE_CRASH_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

AdolescentRiskAssessmentReadOnly.displayName = 'AdolescentRiskAssessmentReadOnly';
export default AdolescentRiskAssessmentReadOnly;

import cx from 'classnames';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';

export interface WarningCardProps {
  className?: string;
  message: string;
}

const WarningCard = (props: WarningCardProps) => {
  const { className, message } = props;
  return (
    <div className={cx('c-els-card c-els-card--small c-els-card--border-negative', className)}>
      <ELSFlex left>
        <ELSFlexItem>
          <ELSIcon name="alert" prefix="gizmo" color="warn" />
        </ELSFlexItem>
        <ELSFlexItem className="u-els-bold u-els-margin-left">{message}</ELSFlexItem>
      </ELSFlex>
    </div>
  );
};

WarningCard.displayName = 'WarningCard';
export default WarningCard;

/* eslint-disable sonarjs/no-duplicate-string */
import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { buildPatientRecord, buildPatientRecords } from 'services/chart.service';
import { FormField } from './constants';

export const respiratoryAssessmentFormFieldBuilderItems = (locale: Locales): ChartMetaFormFieldParams[] => [
  { name: FormField.NO_ASSESSMENT_REQUIRED, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.OXYGEN_IN_USE, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.OXYGENATION_SUPPORT, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.SUCTIONING, type: FormFieldInputType.CHECK_BOX },
  {
    name: FormField.AIRWAY_IN_USE,
    type: locale === Locales.EN_AU ? FormFieldInputType.DROPDOWN : FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_IN_USE
  },
  { name: FormField.GAG_REFLEX_NOT_PRESENT_OPTIONS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.LOCATION, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.LOCATION },
  { name: FormField.SUCTIONING_AMOUNT, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT },
  { name: FormField.SUCTIONING_COLOR, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR },
  { name: FormField.SUCTIONING_CONSISTENCY, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.CONSISTENCY },
  { name: FormField.SUCTIONING_ODOR, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.ODOR },
  { name: FormField.AIRWAY_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.TYPE_OF_AIRWAY },
  { name: FormField.PROD_COUGH_AMOUNT, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT },
  { name: FormField.PROD_COUGH_COLOR, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR },
  { name: FormField.PROD_COUGH_CONSISTENCY, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.CONSISTENCY },
  { name: FormField.PROD_COUGH_SECRETION_ODOR, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.RESPIRATORY_ASSESSMENT.SECRETION_ODOR },
  { name: FormField.LUNG_ANTERIOR_IMG, type: FormFieldInputType.IMAGE },
  { name: FormField.LUNG_LATERAL_LEFT_IMG, type: FormFieldInputType.IMAGE },
  { name: FormField.LUNG_LATERAL_RIGHT_IMG, type: FormFieldInputType.IMAGE },
  { name: FormField.LUNG_POSTERIOR_IMG, type: FormFieldInputType.IMAGE },
  { name: FormField.RESPIRATORY_PATTERN, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.RESPIRATORY_EFFORT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.COLOUR, type: FormFieldInputType.MULTISELECT_DROPDOWN, isHidden: locale !== Locales.EN_AU },
  { name: FormField.SOUNDS, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.RESPIRATORY_ASSESSMENT.BREATH_SOUNDS },
  { name: FormField.OXYGEN_DELIVERY_SYSTEM, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGEN_DELIVERY_SYSTEM },
  { name: FormField.WHEEZE_DESCRIPTION, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.RESPIRATORY_ASSESSMENT.WHEEZE_DESCRIPTION },
  { name: FormField.OXYGEN_AMOUNT, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.OXYGENATION_EVALUATION, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION_EVALUATION },
  { name: FormField.AIRWAY_TYPE_CUFFED_UNCUFFED, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.CHEST_TUBE, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.GAG_REFLEX, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.COUGH_TYPE, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH_TYPE },
  { name: FormField.COUGH_STRENGTH, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH_STRENGTH },
  { name: FormField.LUNG_NOTE, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.OXYGENATION_NOTE, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.COUGH_NOTE, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.AIRWAY_COMMENTS, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.RESPIRATORY_ASSESSMENT.COMMENTS, isHidden: locale !== Locales.EN_AU },
  { name: FormField.OXYGEN_LMIN, type: FormFieldInputType.TEXT_BOX },
  { name: FormField.OXYGEN_PERCENT, type: FormFieldInputType.TEXT_BOX, isHidden: locale === Locales.EN_AU },
  { name: FormField.OXYGENATION_SUPPORT_INCENTIVE_ML, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION_INCENTIVE },
  { name: FormField.AIRWAY_SIZE, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.SIZE },
  { name: FormField.GAG_REFLEX_NOT_PRESENT_OTHER, type: FormFieldInputType.TEXT_BOX },
  { name: FormField.RESPIRATORY_RATE, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.BREATHS_MIN, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AIRWAY_POSITION, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.POSITION, isHidden: locale === Locales.EN_AU },
  { name: FormField.SUCTIONING_AMOUNT_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.SUCTIONING_COLOR_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.SUCTIONING_CONSISTENCY_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.SUCTIONING_ODOR_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.AIRWAY_TYPE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.PROD_COUGH_AMOUNT_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.PROD_COUGH_COLOR_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.PROD_COUGH_CONSISTENCY_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  { name: FormField.PROD_COUGH_SECRETION_ODOR_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER },
  {
    name: FormField.OXYGEN_SATURATION_SPO2,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGEN_SATURATION_SPO2,
    isHidden: locale !== Locales.EN_AU
  }
];

export const buildRecordsForEnUS = (CHART_NAME: string, formFieldMap: Map<string, ChartMetaFormField>, intl: IntlShape) => [
  {
    sectionTitle: CHART_NAME,
    records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
  },
  {
    parentSectionTitle: CHART_NAME,
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_PATTERN }),
    records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_PATTERN)]
  },
  {
    parentSectionTitle: CHART_NAME,
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_EFFORT }),
    records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_EFFORT)]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION }),
    records: [
      buildPatientRecord(formFieldMap, FormField.OXYGEN_IN_USE),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_AMOUNT, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGEN_IN_USE })),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_LMIN, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_IN_LMIN })),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_PERCENT, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_IN_PERCENT })),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY_SYSTEM),
      buildPatientRecord(formFieldMap, FormField.OXYGENATION_NOTE),
      buildPatientRecord(formFieldMap, FormField.OXYGENATION_EVALUATION)
    ]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_SUPPORT }),
    records: [...buildPatientRecords(formFieldMap, FormField.OXYGENATION_SUPPORT), buildPatientRecord(formFieldMap, FormField.OXYGENATION_SUPPORT_INCENTIVE_ML)]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.SUCTIONING }),
    records: [
      ...buildPatientRecords(formFieldMap, FormField.SUCTIONING),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_AMOUNT),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_AMOUNT_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_OTHER })),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_COLOR),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_COLOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR_OTHER })),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_CONSISTENCY),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_CONSISTENCY_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CONSISTENCY_OTHER })),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_ODOR),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_ODOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ODOR_OTHER }))
    ]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ARTIFICIAL_AIRWAY }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_TYPE }),
    records: [
      buildPatientRecord(formFieldMap, FormField.AIRWAY_IN_USE),
      buildPatientRecord(formFieldMap, FormField.AIRWAY_TYPE),
      buildPatientRecord(formFieldMap, FormField.AIRWAY_TYPE_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_OTHER })),
      buildPatientRecord(formFieldMap, FormField.AIRWAY_TYPE_CUFFED_UNCUFFED)
    ]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ARTIFICIAL_AIRWAY }),
    records: [buildPatientRecord(formFieldMap, FormField.AIRWAY_SIZE), buildPatientRecord(formFieldMap, FormField.AIRWAY_POSITION)]
  },

  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CHEST_TUBE }),
    records: [buildPatientRecord(formFieldMap, FormField.CHEST_TUBE, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CHEST_TUBE }))]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.GAG_REFLEX }),
    records: [
      buildPatientRecord(formFieldMap, FormField.GAG_REFLEX, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.GAG_REFLEX })),
      ...buildPatientRecords(formFieldMap, FormField.GAG_REFLEX_NOT_PRESENT_OPTIONS),
      buildPatientRecord(formFieldMap, FormField.GAG_REFLEX_NOT_PRESENT_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER }))
    ]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH }),
    records: [
      buildPatientRecord(formFieldMap, FormField.COUGH_TYPE),
      buildPatientRecord(formFieldMap, FormField.COUGH_STRENGTH),
      buildPatientRecord(formFieldMap, FormField.COUGH_NOTE)
    ]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.PRODUCTIVE_COUGH_ASSESSMENT }),
    records: [
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_AMOUNT),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_AMOUNT_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_OTHER })),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_COLOR),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_COLOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR_OTHER })),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_CONSISTENCY),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_CONSISTENCY_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CONSISTENCY_OTHER })),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_SECRETION_ODOR),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_SECRETION_ODOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ODOR_OTHER }))
    ]
  }
];

export const buildRecordsForEnAU = (CHART_NAME: string, formFieldMap: Map<string, ChartMetaFormField>, intl: IntlShape) => [
  {
    sectionTitle: CHART_NAME,
    records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_TYPE }),
    records: [
      buildPatientRecord(formFieldMap, FormField.AIRWAY_IN_USE),
      buildPatientRecord(formFieldMap, FormField.AIRWAY_TYPE),
      buildPatientRecord(formFieldMap, FormField.AIRWAY_TYPE_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AIRWAY_OTHER })),
      buildPatientRecord(formFieldMap, FormField.AIRWAY_TYPE_CUFFED_UNCUFFED)
    ]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ARTIFICIAL_AIRWAY }),
    records: [buildPatientRecord(formFieldMap, FormField.AIRWAY_SIZE), buildPatientRecord(formFieldMap, FormField.AIRWAY_COMMENTS)]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.INSPECTION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_RATE }),
    records: [buildPatientRecord(formFieldMap, FormField.RESPIRATORY_RATE)]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.INSPECTION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_PATTERN }),
    records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_PATTERN)]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.INSPECTION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_EFFORT }),
    records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_EFFORT)]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.INSPECTION }),
    records: [buildPatientRecord(formFieldMap, FormField.COLOUR, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR }))]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION }),
    records: [
      buildPatientRecord(formFieldMap, FormField.OXYGEN_IN_USE),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_SATURATION_SPO2),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_AMOUNT, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGEN_IN_USE })),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_LMIN, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_IN_LMIN })),
      buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY_SYSTEM),
      buildPatientRecord(formFieldMap, FormField.OXYGENATION_NOTE),
      buildPatientRecord(formFieldMap, FormField.OXYGENATION_EVALUATION)
    ]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_SUPPORT }),
    records: [...buildPatientRecords(formFieldMap, FormField.OXYGENATION_SUPPORT), buildPatientRecord(formFieldMap, FormField.OXYGENATION_SUPPORT_INCENTIVE_ML)]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.SUCTIONING }),
    records: [
      ...buildPatientRecords(formFieldMap, FormField.SUCTIONING),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_AMOUNT),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_AMOUNT_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_OTHER })),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_COLOR),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_COLOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR_OTHER })),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_CONSISTENCY),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_CONSISTENCY_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CONSISTENCY_OTHER })),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_ODOR),
      buildPatientRecord(formFieldMap, FormField.SUCTIONING_ODOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ODOR_OTHER }))
    ]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CHEST_TUBE }),
    records: [buildPatientRecord(formFieldMap, FormField.CHEST_TUBE, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CHEST_TUBE }))]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH }),
    records: [
      buildPatientRecord(formFieldMap, FormField.COUGH_TYPE),
      buildPatientRecord(formFieldMap, FormField.COUGH_STRENGTH),
      buildPatientRecord(formFieldMap, FormField.COUGH_NOTE)
    ]
  },
  {
    parentSectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH }),
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.PRODUCTIVE_COUGH_ASSESSMENT }),
    records: [
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_AMOUNT),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_AMOUNT_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.AMOUNT_OTHER })),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_COLOR),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_COLOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COLOR_OTHER })),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_CONSISTENCY),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_CONSISTENCY_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CONSISTENCY_OTHER })),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_SECRETION_ODOR),
      buildPatientRecord(formFieldMap, FormField.PROD_COUGH_SECRETION_ODOR_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ODOR_OTHER }))
    ]
  },
  {
    sectionTitle: intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.GAG_REFLEX }),
    records: [
      buildPatientRecord(formFieldMap, FormField.GAG_REFLEX, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.GAG_REFLEX })),
      ...buildPatientRecords(formFieldMap, FormField.GAG_REFLEX_NOT_PRESENT_OPTIONS),
      buildPatientRecord(formFieldMap, FormField.GAG_REFLEX_NOT_PRESENT_OTHER, intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OTHER }))
    ]
  }
];

import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface DevelopmentalScreenReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const DevelopmentalScreenReadOnly = (props: DevelopmentalScreenReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box className="developmental-screen-read-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Developmental Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.DEVELOPMENT_AGE}
                  customTitle={SectionTitle.DEVELOPMENT_AGE}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.CHRONOLOGICAL_AGE}
                  customTitle={SectionTitle.CHRONOLOGICAL_AGE}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.GROSS_MOTOR_SKILLS}
                  customTitle={SectionTitle.GROSS_MOTOR_SKILLS}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.FINE_MOTOR_SKILLS}
                  customTitle={SectionTitle.FINE_MOTOR_SKILLS}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.SENSORY_SKILLS}
                  customTitle={SectionTitle.SENSORY_SKILLS}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.VOCALIZATION_SKILLS}
                  customTitle={SectionTitle.VOCALIZATION_SKILLS}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DEVELOPMENT_SCREEN}
                  formFieldId={FormField.SOCIALIZATION_COGNITION}
                  customTitle={SectionTitle.SOCIALIZATION_COGNITION}
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

DevelopmentalScreenReadOnly.displayName = 'DevelopmentalScreenReadOnly';
export default DevelopmentalScreenReadOnly;

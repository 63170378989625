import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constant';

export interface MentalStatusEditViewProps {
  selectedRecord: ChartFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const MentalStatusEditView = (props: MentalStatusEditViewProps) => {
  const { selectedRecord, chartActionsProps } = props;
  return (
    <>
      <Box mt2>
        <h2 className="u-els-margin-bottom-2x">{SectionTitle.MENTAL_STATUS_EXAMINATION}</h2>
        <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.APPEARANCE)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.BEHAVIORAL_ACTIVITY)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.SPEECH)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.THOUGHT_FORM)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.THOUGHT_CONTENT)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.SUICIDAL_IMPULSES)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.HOMICIDAL_IMPULSES)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.ORIENTATION)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.MEMORY)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.MOOD)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.AFFECT)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.JUDGMENT_INSIGHT)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.formFieldMap.get(FormField.ATTENTION)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission fragmentType={selectedRecord?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </>
  );
};

MentalStatusEditView.displayName = 'MentalStatusEditView';
export default MentalStatusEditView;

import { LanguageKeys } from 'lang';
import { ELSFlex, ELSFlexItem } from '@els/els-ui-common-react';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

export type SkinAssessmentViewProps = ChartViewProps;

export default function SkinAssessmentView({ chartActionsProps, formFieldMap, formSubmittedCount }: SkinAssessmentViewProps) {
  return (
    <div className="skin-assessment">
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <h1>{LanguageKeys.SKIN_ASSESSMENT.SKIN_ASSESSMENT}</h1>

      <div className="u-els-margin-top-2x">
        <ELSFlex left>
          <ELSFlexItem w="1o3">
            <h2>{LanguageKeys.SKIN_ASSESSMENT.PRESSURE_INJURY_PREVENTION}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.PRESSURE_INJURY_PREVENTION_BRADEN_SCALE_COMPLETED)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex left className="u-flex-gutter-4x">
          <ELSFlexItem w="1o3" md="1o2">
            <h2>{LanguageKeys.SKIN_ASSESSMENT.EXISTING_WOUNDS}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.EXISTING_WOUNDS_PATIENT_HAS_EXISTING_WOUNDS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.EXISTING_WOUNDS_LOCATIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o2">
            <h2>{LanguageKeys.SKIN_ASSESSMENT.WOUND_CARE_PLAN}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.WOUND_CARE_PLAN_WOUND_CARE_PLAN_COMPLETED)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <ChartActions {...chartActionsProps} />
    </div>
  );
}

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, Labels } from './constants';
import PlanningHospitalStayView from './PlanningHospitalStayView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface PlanningHospitalStayProps extends ChartComponentProps {
  patientContext: PatientContext;
}
interface PlanningHospitalStayState {
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  isLocked: boolean;
}

class PlanningHospitalStay extends Component<PlanningHospitalStayProps, PlanningHospitalStayState> {
  static displayName = 'PlanningHospitalStay';

  constructor(props) {
    super(props);
    this.state = {
      resetAll: 0,
      fragment: null,
      statusFragment: null,
      isLocked: false
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.PLANNING_HOSPITAL_STAY, NAV_ID.ADMISSION_HISTORY);

        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  getSavedValue = (fragment: ChartFragment, formField: string, key: string) => {
    const records = fragment?.chartData.records.filter((item) => item.formField === formField);
    return records?.map((item) => item[key]);
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { fragment } = this.state;
    const dataMap = new Map();

    const radioChoices = [
      { name: FormField.DURING_HOSPITALIZATION, label: Labels.DURING_HOSPITALIZATION },
      { name: FormField.CONTACT_PERSON, label: Labels.CONTACT_PERSON },
      { name: FormField.CAN_READ, label: Labels.CAN_READ },
      { name: FormField.CAN_WRITE, label: Labels.CAN_WRITE },
      { name: FormField.BARRIERS_LEARNING, label: Labels.BARRIERS_LEARNING },
      { name: FormField.PRIMARY_LANGUAGE_NEED_TRANSLATOR, label: Labels.PRIMARY_LANGUAGE_NEED_TRANSLATOR },
      { name: FormField.HAVE_LIVING, label: Labels.HAVE_LIVING },
      { name: FormField.ATTEND_PRENATAL_CLASSES, label: Labels.ATTEND_PRENATAL_CLASSES },
      { name: FormField.DOCUMENTED_BIRTH_PLAN, label: Labels.DOCUMENTED_BIRTH_PLAN },
      { name: FormField.ANESTHESIA_DESIRED, label: Labels.ANESTHESIA_DESIRED },
      { name: FormField.TUBAL_LIGATION_PLANNED, label: Labels.TUBAL_LIGATION_PLANNED },
      { name: FormField.PLANS_FOR_BIRTH_CONSENT_SIGNED, label: Labels.CONSENT_SIGNED },
      { name: FormField.NEWBORN_NUTRITION, label: Labels.NEWBORN_NUTRITION },
      { name: FormField.CIRCUMCISION_PLANNED, label: Labels.CIRCUMCISION_PLANNED },
      { name: FormField.NEWBORN_PLANS_CONSENT_SIGNED, label: Labels.CONSENT_SIGNED },
      { name: FormField.QUESTION_PARENT_NEED_TRANSLATOR, label: Labels.PRIMARY_LANGUAGE_NEED_TRANSLATOR },
      { name: FormField.QUESTION_PARENT_BARRIERS_LEARNING, label: Labels.BARRIERS_LEARNING }
    ];
    const textBoxes = [
      { name: FormField.DURING_HOSPITALIZATION_NAME, label: Labels.DURING_HOSPITALIZATION_NAME },
      { name: FormField.COMMUNICATE_OTHER, label: Labels.OTHERS },
      { name: FormField.PROBLEM_EXISTED_OTHER, label: Labels.OTHERS },
      { name: FormField.CONTACT_PERSON_NAME, label: Labels.CONTACT_PERSON_NAME },
      { name: FormField.CONTACT_PERSON_PHONE, label: Labels.CONTACT_PERSON_PHONE },
      { name: FormField.PRIMARY_LANGUAGE_OTHER, label: Labels.OTHERS },
      { name: FormField.EDUCATION_LEVEL_OTHER, label: Labels.OTHERS },
      { name: FormField.PREFER_INSTRUCTIONS_OTHER, label: Labels.OTHERS },
      { name: FormField.PROFESSION_OCCUPATION, label: Labels.PROFESSION_OCCUPATION },
      { name: FormField.NAMES_PERSONS_TO_BE_PRESENT, label: Labels.NAMES_PERSONS_TO_BE_PRESENT },
      { name: FormField.SPECIFIC_REQUESTS_DURING_LABOR, label: Labels.SPECIFIC_REQUESTS_DURING_LABOR },
      { name: FormField.PEDIATRICIAN_NAME, label: Labels.PEDIATRICIAN_NAME },
      { name: FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS_OTHER, label: Labels.OTHERS },
      { name: FormField.QUESTION_PARENT_EDUCATION_LEVEL_OTHER, label: Labels.OTHERS }
    ];
    const multiSelectRadios = [
      { name: FormField.COMMUNICATE, label: Labels.COMMUNICATE },
      { name: FormField.PROBLEM_EXISTED, label: Labels.PROBLEM_EXISTED },
      { name: FormField.PRIMARY_LANGUAGE, label: Labels.PRIMARY_LANGUAGE },
      { name: FormField.LIVING_AVAILABLE, label: Labels.LIVING_AVAILABLE },
      { name: FormField.PREFER_INSTRUCTIONS, label: Labels.PREFER_INSTRUCTIONS },
      { name: FormField.EDUCATION_LEVEL, label: Labels.EDUCATION_LEVEL },
      { name: FormField.QUESTION_PARENT_EDUCATION_LEVEL, label: Labels.EDUCATION_LEVEL },
      { name: FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS, label: Labels.PREFER_INSTRUCTIONS }
    ];
    const textAreas = [
      { name: FormField.ADMITTED_HOSPITAL, label: Labels.ADMITTED_HOSPITAL },
      { name: FormField.HEALTH_PROBLEM_HOME, label: Labels.HEALTH_PROBLEM_HOME },
      { name: FormField.VISITOR_RESTRICTIONS, label: Labels.VISITOR_RESTRICTIONS },
      { name: FormField.CIRCUMSTANCE_ADMITTED_HOSPITAL, label: Labels.CIRCUMSTANCE_ADMITTED_HOSPITAL }
    ];
    const multiselectDropDowns = [{ name: FormField.BARRIERS_LEARNING_PROBLEMS }, { name: FormField.QUESTION_PARENT_BARRIERS_LEARNING_PROBLEMS }];
    const containers = [FormField.CONTAINER_QUESTION_PARENT, FormField.CONTAINER_WOMEN_HEALTH];

    containers.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CONTAINER })));
    multiSelectRadios.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({ name: item.name, type: FormFieldInputType.MULTI_SELECT_RADIO, label: item.label, contentIds: this.getSavedValue(fragment, item.name, 'contentId') })
      )
    );
    radioChoices.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({ name: item.name, type: FormFieldInputType.RADIO_CHOICE, label: item.label, contentIds: this.getSavedValue(fragment, item.name, 'contentId') })
      )
    );
    textAreas.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({ name: item.name, type: FormFieldInputType.TEXT_AREA, label: item.label, value: this.getSavedValue(fragment, item.name, 'value')?.[0] })
      )
    );
    textBoxes.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({ name: item.name, type: FormFieldInputType.TEXT_BOX, label: item.label, value: this.getSavedValue(fragment, item.name, 'value')?.[0] })
      )
    );
    multiselectDropDowns.forEach((item) =>
      dataMap.set(item.name, createFormField({ name: item.name, type: FormFieldInputType.MULTISELECT_DROPDOWN, contentIds: this.getSavedValue(fragment, item.name, 'contentId') }))
    );
    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Planning for Hospital Stay';
    const chartData = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        buildPatientRecord(formFieldMap, FormField.DURING_HOSPITALIZATION),
        buildPatientRecord(formFieldMap, FormField.DURING_HOSPITALIZATION_NAME),
        buildPatientRecord(formFieldMap, FormField.COMMUNICATE),
        buildPatientRecord(formFieldMap, FormField.COMMUNICATE_OTHER),
        buildPatientRecord(formFieldMap, FormField.PROFESSION_OCCUPATION),
        buildPatientRecord(formFieldMap, FormField.PROBLEM_EXISTED),
        buildPatientRecord(formFieldMap, FormField.PROBLEM_EXISTED_OTHER),
        buildPatientRecord(formFieldMap, FormField.ADMITTED_HOSPITAL),
        buildPatientRecord(formFieldMap, FormField.CIRCUMSTANCE_ADMITTED_HOSPITAL),
        buildPatientRecord(formFieldMap, FormField.CONTACT_PERSON),
        buildPatientRecord(formFieldMap, FormField.CONTACT_PERSON_NAME),
        buildPatientRecord(formFieldMap, FormField.CONTACT_PERSON_PHONE),
        buildPatientRecord(formFieldMap, FormField.HEALTH_PROBLEM_HOME),
        buildPatientRecord(formFieldMap, FormField.CAN_READ),
        buildPatientRecord(formFieldMap, FormField.CAN_WRITE),
        buildPatientRecord(formFieldMap, FormField.BARRIERS_LEARNING),
        ...buildPatientRecords(formFieldMap, FormField.BARRIERS_LEARNING_PROBLEMS),
        buildPatientRecord(formFieldMap, FormField.PRIMARY_LANGUAGE),
        buildPatientRecord(formFieldMap, FormField.PRIMARY_LANGUAGE_OTHER),
        buildPatientRecord(formFieldMap, FormField.PRIMARY_LANGUAGE_NEED_TRANSLATOR),
        buildPatientRecord(formFieldMap, FormField.HAVE_LIVING),
        buildPatientRecord(formFieldMap, FormField.LIVING_AVAILABLE),
        buildPatientRecord(formFieldMap, FormField.PREFER_INSTRUCTIONS),
        buildPatientRecord(formFieldMap, FormField.PREFER_INSTRUCTIONS_OTHER),
        buildPatientRecord(formFieldMap, FormField.VISITOR_RESTRICTIONS),
        buildPatientRecord(formFieldMap, FormField.EDUCATION_LEVEL),
        buildPatientRecord(formFieldMap, FormField.EDUCATION_LEVEL_OTHER),
        buildPatientRecord(formFieldMap, FormField.ATTEND_PRENATAL_CLASSES),
        buildPatientRecord(formFieldMap, FormField.NAMES_PERSONS_TO_BE_PRESENT),
        buildPatientRecord(formFieldMap, FormField.DOCUMENTED_BIRTH_PLAN),
        buildPatientRecord(formFieldMap, FormField.ANESTHESIA_DESIRED),
        buildPatientRecord(formFieldMap, FormField.SPECIFIC_REQUESTS_DURING_LABOR),
        buildPatientRecord(formFieldMap, FormField.TUBAL_LIGATION_PLANNED),
        buildPatientRecord(formFieldMap, FormField.PLANS_FOR_BIRTH_CONSENT_SIGNED),
        buildPatientRecord(formFieldMap, FormField.PEDIATRICIAN_NAME),
        buildPatientRecord(formFieldMap, FormField.NEWBORN_NUTRITION),
        buildPatientRecord(formFieldMap, FormField.CIRCUMCISION_PLANNED),
        buildPatientRecord(formFieldMap, FormField.NEWBORN_PLANS_CONSENT_SIGNED),
        buildPatientRecord(formFieldMap, FormField.QUESTION_PARENT_NEED_TRANSLATOR),
        buildPatientRecord(formFieldMap, FormField.QUESTION_PARENT_BARRIERS_LEARNING),
        ...buildPatientRecords(formFieldMap, FormField.QUESTION_PARENT_BARRIERS_LEARNING_PROBLEMS),
        buildPatientRecord(formFieldMap, FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS),
        buildPatientRecord(formFieldMap, FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS_OTHER),
        buildPatientRecord(formFieldMap, FormField.QUESTION_PARENT_EDUCATION_LEVEL),
        buildPatientRecord(formFieldMap, FormField.QUESTION_PARENT_EDUCATION_LEVEL_OTHER)
      ]
    };
    chartData.records = chartData.records.filter((item) => !!item);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData } as ChartFragment;
  };

  render() {
    const { fragment, statusFragment, isLocked } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, patientContext } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData
    };
    const viewProps = {
      fragment,
      statusFragment,
      isLocked,
      chartActionsProps,
      formSubmittedCount,
      formFieldMap,
      resetAll: this.state.resetAll,
      patientContext
    };
    return <PlanningHospitalStayView {...viewProps} />;
  }
}

export { PlanningHospitalStay as BasePlanningHospitalStay };
export default compose(withChartLogic, withAdmissionHistory)(PlanningHospitalStay);

import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';

interface InfoTextProps {
  text: string;
  title?: string;
}

const InfoText = (props: InfoTextProps) => (
  <ELSFlexItem className="info-text">
    <ELSFlex>
      <ELSFlexItem className="u-els-margin-right-1o2">
        <ELSFlex middle>
          <ELSIcon prefix="gizmo" name="information" size="1x" />
        </ELSFlex>
      </ELSFlexItem>
      <ELSFlexItem>
        <p>
          <strong>{props.title}</strong>
        </p>
        <p>{props.text}</p>
      </ELSFlexItem>
    </ELSFlex>
  </ELSFlexItem>
);

InfoText.displayName = 'InfoText';
export default InfoText;

/* eslint-disable class-methods-use-this */
import produce from 'immer';
import { ValidationActionType } from 'models/enum';
import { ChartMetaFormField } from 'models/ui';
import BaseHandler, { ExecuteParams } from './interface';

export default class DisableHandler implements BaseHandler {
  execute({ field, ruleIdsByFieldMap, ruleRegistry, allowClearValue }: ExecuteParams): ChartMetaFormField {
    const ruleIds = ruleIdsByFieldMap.get(field.name);
    const result = ruleIds.some((id) => {
      const rule = ruleRegistry.get(id);
      if (rule.actionType === ValidationActionType.DISABLE && rule.result === true) {
        return true;
      }
      return false;
    });

    return produce(field, (draft) => {
      draft.disabled = result;
      if (allowClearValue && result) {
        draft.value = draft.defaultValue;

        // Deselect all options
        if (draft.chartContent) {
          draft.chartContent.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.selected = false;
          });
        }
      }
    });
  }
}

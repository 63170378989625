import cx from 'classnames';
import { FormFieldInputType } from 'models/enum';
import { DefaultLabelContentProps, LabelContainerProps } from './types';

export function DefaultLabelContent({ formField, hideAsterisk }: DefaultLabelContentProps) {
  const asterisk = formField.isRequired && !hideAsterisk ? <span className="chart-input__label-asterisk">* </span> : <></>;

  let formFieldId = null;
  switch (formField.inputType) {
    case FormFieldInputType.TEXT_BOX:
    case FormFieldInputType.TEXT_AREA:
    case FormFieldInputType.TIME:
    case FormFieldInputType.DROPDOWN:
      formFieldId = `field-input-${formField.name}`;
      break;
    default:
      formFieldId = formField.name;
      break;
  }

  const scaleLabel = (
    <h2 className="chart-input__label-scale">
      {asterisk}
      {formField.label}
    </h2>
  );
  const radioOrCheckBoxLabel = (
    <p>
      {asterisk}
      {formField.label}
    </p>
  );
  const normalLabel = (
    <label htmlFor={formFieldId}>
      {asterisk}
      {formField.label}
    </label>
  );

  let labelElement = null;
  switch (formField.inputType) {
    case FormFieldInputType.SCALE:
      labelElement = scaleLabel;
      break;
    case FormFieldInputType.RADIO_CHOICE:
    case FormFieldInputType.CHECK_BOX:
      labelElement = radioOrCheckBoxLabel;
      break;
    default:
      labelElement = normalLabel;
      break;
  }

  return <>{labelElement}</>;
}

export default function LabelContainer({ children, className, formField }: LabelContainerProps) {
  const { name } = formField;

  return formField.inputType === FormFieldInputType.RADIO_CHOICE ? (
    <legend id={`chart-field-label-${name}`} className={cx('chart-input__label', className)}>
      {children}
    </legend>
  ) : (
    <div id={`chart-field-label-${name}`} className={cx('chart-input__label', className)}>
      {children}
    </div>
  );
}

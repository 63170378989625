import { Component } from 'react';
import { defaultCustomDateRender, defaultCustomRender } from 'helpers/chartRender.helper';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import { FormField, HomeMedicationHistoryProps } from './constants';

class HomeMedicationPrescriptionHistoryTable extends Component<HomeMedicationHistoryProps> {
  static displayName = 'HomeMedicationPrescriptionHistoryTable';

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { chartHistory, deleteHistory, locale } = this.props;
    const actionHeader = deleteHistory ? { field: 'actionDelete' } : {};
    const dataSource = {
      headers: [
        { text: 'Medication', field: 'medication', sortable: true, customRender: defaultCustomRender(FormField.MEDICATION) },
        { text: 'Dose', field: 'doseMedications', customRender: defaultCustomRender(FormField.DOSE) },
        { text: 'Form', field: 'formMedications', customRender: defaultCustomRender(FormField.FORM) },
        { text: 'Route', field: 'routeMedications', customRender: defaultCustomRender(FormField.ROUTE) },
        { text: 'Frequency', field: 'frequencyMedications', customRender: defaultCustomRender(FormField.FREQUENCY) },
        { text: 'Last Dose', field: 'lastDoseDate', customRender: defaultCustomDateRender(FormField.LAST_DOSE_DATE, locale) },
        { text: 'Last Time', field: 'lastDoseTime', customRender: defaultCustomRender(FormField.LAST_DOSE_TIME) },
        { text: 'Indication', field: 'indication', customRender: defaultCustomRender(FormField.INDICATION) },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { ...actionHeader }
      ],
      css: ['column__chart-time', '', '', '', '', '', '', '', 'column__entry-by', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt>
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} showDeleteColumn={!!deleteHistory} />
      </Box>
    );
  }
}

export default HomeMedicationPrescriptionHistoryTable;

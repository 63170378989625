import { Box, ChartActions, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const SpecialPrecautionsIsolationAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;

  return (
    <div className="special-precautions-isolation-assessment">
      <h1>{SectionTitle.SPECIAL_PRECAUTIONS_ISOLATION_ASSESSMENT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.STANDARD_PRECAUTIONS}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.STANDARD_PRECAUTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.RESPIRATORY_PRECAUTIONS}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RESPIRATORY_PRECAUTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.CONTACT_PRECAUTIONS}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CONTACT_PRECAUTIONS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_PRECAUTIONS_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.DROPLET_PRECAUTIONS}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DROPLET_PRECAUTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

SpecialPrecautionsIsolationAssessmentView.displayName = 'SpecialPrecautionsIsolationAssessmentView';
export default SpecialPrecautionsIsolationAssessmentView;

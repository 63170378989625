import cx from 'classnames';
import { dateTimeHelper } from 'helpers';
import { chartService } from 'services';

interface DeleteTrackerProps {
  firstName: string;
  lastName: string;
  dateTime: string;
  className?: string;
}

const DeleteTracker = (props: DeleteTrackerProps) => {
  const firstName = props.firstName || '';
  const lastName = props.lastName || '';
  const fullName = chartService.formatName({ firstName, lastName }).trim() || 'unknown';
  return <span className={cx('u-els-color-n7 u-els-italic', props.className)}>{`Deleted by ${fullName} on ${dateTimeHelper.toDateTime(props.dateTime)}`}</span>;
};

DeleteTracker.displayName = 'DeleteTracker';
export default DeleteTracker;

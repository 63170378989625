import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { DemoSource } from 'models/enum';
import { CaseStudy, SimChartAssignment } from 'models/ui';
import { RouteParams, RoutePath } from 'constants/app.constant';
import { apiHelper, appHelper } from 'helpers';
import { assignmentService, caseStudyService } from 'services';
import { studentActions, studentSelectors } from 'redux/ducks/student';
import { PageHeader } from 'components/common';
import CaseStudyPreviewContent from 'components/common/case-study-preview-content/CaseStudyPreviewContent';
import { ELSButton, ELSPageLoader } from 'components/common/els';
import './case.study.demo.scss';

interface MatchParams {
  assignmentId: string;
}

interface CaseStudyDemoProps {
  fullAssignment: SimChartAssignment;
  assignmentDemoSource: string;
  assignmentDemoUrl: string;
  setAssignmentDemoSource: Function;
  setAssignmentDemoUrl: Function;
}

interface CaseStudyDemoState {
  caseStudy: CaseStudy;
  isLoading: boolean;
}

class CaseStudyDemo extends Component<CaseStudyDemoProps & RouteComponentProps<MatchParams>, CaseStudyDemoState> {
  constructor(props) {
    super(props);
    this.state = {
      caseStudy: null,
      isLoading: false
    };
  }
  componentDidMount() {
    this.props.setAssignmentDemoSource('');
    this.props.setAssignmentDemoUrl('');
    this.loadData();
  }

  loadData = async () => {
    try {
      this.setState({ isLoading: true });
      const { assignmentId } = this.props.match.params;
      const dataAssignment = (this.props.fullAssignment && { data: this.props.fullAssignment }) || (await assignmentService.getSimChartAssignment(Number(assignmentId)));

      const caseStudyRes = await caseStudyService.getCaseStudy(dataAssignment.data.caseStudyId);
      this.setState({ caseStudy: caseStudyRes.data });
    } catch (err) {
      apiHelper.showApiError(`can not load data ${err}`);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleSwitchToStudentViewClick = () =>
    appHelper.useLoader(
      caseStudyService.prepareDemoCaseStudy({ caseStudyId: this.state.caseStudy.id }).then(({ data }) => {
        const { demoAssessmentId } = data;
        const path = RoutePath.student.caseStudyLanding.replace(RouteParams.ASSESSMENT_ID, demoAssessmentId);
        this.props.setAssignmentDemoSource(DemoSource.CASE_STUDY_DEMO);
        this.props.setAssignmentDemoUrl(this.props.history.location.pathname);
        this.props.history.push(path);
      })
    );

  render() {
    const { isLoading, caseStudy } = this.state;
    if (isLoading) {
      return <ELSPageLoader />;
    }

    return (
      caseStudy && (
        <>
          <PageHeader title="SimChart Assignment">
            <ELSButton text="Switch to Student View" type="primary" onClick={this.handleSwitchToStudentViewClick} />
          </PageHeader>
          <div className="case-study-demo">
            <CaseStudyPreviewContent caseStudy={caseStudy} />
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  assignmentDemoSource: studentSelectors.getAssignmentDemoSource(state),
  assignmentDemoUrl: studentSelectors.getAssignmentDemoUrl(state),
  fullAssignment: studentSelectors.getFullAssignment(state)
});

const mapDispatchToProps = (dispatch) => ({
  setAssignmentDemoSource: (source: string) => dispatch(studentActions.setAssignmentDemoSource(source)),
  setAssignmentDemoUrl: (url: string) => dispatch(studentActions.setAssignmentDemoUrl(url))
});

export { CaseStudyDemo as BaseCaseStudyDemo };
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CaseStudyDemo);

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './SmokingScreen';
import SmokingScreenReadOnly from './SmokingScreenReadOnly';
import './smoking.screen.scss';

interface SmokingScreenViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const SmokingScreenView = (props: SmokingScreenViewProps) => {
  const { isLocked, fragment, statusFragment, resetAll, formSubmittedCount, chartMetaFormFields } = props;
  return (
    <div className="smoking-screen">
      {isLocked ? (
        <SmokingScreenReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Smoking Screen</h1>
          <ErrorCard chartMetaFormFields={props.chartMetaFormFields} showErrorCard={props.formSubmittedCount > 0} />

          <Box mt2>
            <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField
                  className="error-field--no-bottom"
                  formField={chartMetaFormFields.get(FormField.LIVE_WITH_SMOKER)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField
                  className="error-field--no-bottom"
                  formField={chartMetaFormFields.get(FormField.IS_SMOKER)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
              </ELSFlexItem>
            </ELSFlex>
          </Box>

          <SectionDivider />
          <h2 className="u-els-margin-bottom">Smoker Status</h2>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.SMOKER_STATUS)} formSubmittedCount={formSubmittedCount} />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">Using</h3>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_HOW_MUCH)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_HOW_LONG)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_QUIT)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_QUIT_OTHER)} formSubmittedCount={formSubmittedCount} />
              <h3 className="u-els-margin-bottom">Already Quit</h3>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TOBACCO_QUIT_WHEN)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.TEMPTATION_TRIGGER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">Plan to Quit</h3>
              <p className="u-els-margin-bottom">What is your motivation for quitting now?</p>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.QUIT_MOTIVATION)} formSubmittedCount={formSubmittedCount} />
              <p className="u-els-margin-bottom">Do you have any concerns about quitting?</p>
              <ErrorFormField key={`quit-concern-${resetAll}`} formField={chartMetaFormFields.get(FormField.QUIT_CONCERN)} formSubmittedCount={formSubmittedCount} />
              <p className="u-els-margin-bottom">If quit before, why did you start using tobacco again?</p>
              <div className="smoking-screen__start-again">
                <ErrorFormField key={`quit-start-again-${resetAll}`} formField={chartMetaFormFields.get(FormField.QUIT_START_AGAIN)} formSubmittedCount={formSubmittedCount} />
              </div>
              <ErrorFormField key={`quit-date-${resetAll}`} formField={chartMetaFormFields.get(FormField.QUIT_DATE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.USE_NICOTINE_REPLACEMENT)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

SmokingScreenView.displayName = 'SmokingScreenView';
export default SmokingScreenView;

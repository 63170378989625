import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, LaboratoryHistoryRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { studentSelectors } from 'redux/ducks/student';
import { withChartLogic } from 'components/common';
import { FormField } from './constants';
import LaboratoryView from './LaboratoryView';
import { getLaboratoryFormFieldBuilderItems } from './helper';
import { FormField as CommonFormField } from '../shared/constants';

interface LaboratoryState {
  chartHistory: LaboratoryHistoryRecord[];
  fragments: ChartFragment[];
  selectedLaboratory: LaboratoryHistoryRecord;
}

export interface LaboratoryProps extends ChartComponentProps {
  enableMultiStepsAuthoring: Function;
  phaseIndex: number;
}

class Laboratory extends Component<LaboratoryProps, LaboratoryState> {
  static displayName = 'Laboratory';

  constructor(props) {
    super(props);
    this.state = {
      chartHistory: [],
      fragments: [],
      selectedLaboratory: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  buildFormFields = () => {
    const { isAuthor, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getLaboratoryFormFieldBuilderItems(isAuthor).forEach(({ label, isHidden, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ ...item, label: label && intl.formatMessage({ id: label }) }));
      }
    });

    return dataMap;
  };

  deleteHistory = (record) => {
    const deleteFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.fragmentId);
    appHelper.useLoader(this.props.deleteChartData(deleteFragment).then(this.loadData).then(this.props.showDeleteSuccess), { errorMessage: 'can not delete chart history' });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildFormFields(), afterSave: this.props.backToSourceLocation || this.loadData });
  };

  handleStatusClick = (selectedLaboratory: LaboratoryHistoryRecord) => {
    this.setState({ selectedLaboratory });
  };

  handleSidebarClose = () => {
    this.setState({ selectedLaboratory: null });
  };

  handleChartUpdate = () => {
    this.loadData();
    this.props.showSaveSuccess();
    this.setState({ selectedLaboratory: null });
  };

  buildFragment = () => {
    const { formFieldMap, isAuthor, locale } = this.props;
    const { buildPatientRecord } = chartService;
    const { toISO } = dateTimeHelper;
    const orderDateValue = formFieldMap.get(FormField.ORDER_START_DATE).value;

    if (orderDateValue) {
      formFieldMap.set(FormField.ORDER_START_DATE, { ...formFieldMap.get(FormField.ORDER_START_DATE), value: toISO(orderDateValue, locale) });
    }

    const chartData = {
      [FormField.CATEGORY]: buildPatientRecord(formFieldMap, FormField.CATEGORY),
      [FormField.ORDER_DESCRIPTION]: buildPatientRecord(formFieldMap, FormField.ORDER_DESCRIPTION),
      [FormField.ORDER_PRIORITY]: buildPatientRecord(formFieldMap, FormField.ORDER_PRIORITY),
      [FormField.ORDER_FREQUENCY]: buildPatientRecord(formFieldMap, FormField.ORDER_FREQUENCY),
      [FormField.ORDER_START_DATE]: buildPatientRecord(formFieldMap, FormField.ORDER_START_DATE),
      [FormField.ORDER_START_TIME]: buildPatientRecord(formFieldMap, FormField.ORDER_START_TIME),
      [CommonFormField.ORDER_START_TIME_OFFSET]: buildPatientRecord(formFieldMap, CommonFormField.ORDER_START_TIME_OFFSET),
      [FormField.SPECIMEN]: buildPatientRecord(formFieldMap, FormField.SPECIMEN),
      [FormField.SPECIAL_INSTRUCTIONS]: buildPatientRecord(formFieldMap, FormField.SPECIAL_INSTRUCTIONS),
      [FormField.STATUS]: LanguageKeys.LABORATORY.PENDING
    };
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, groupFragmentRef: isAuthor ? chartHelper.createFragmentId(NAV_ID.LABORATORY) : undefined, chartData } as ChartFragment;
  };

  loadData = () => {
    this.props.initState(this.buildFormFields());
    this.props.enableMultiStepsAuthoring(true);
    appHelper.useLoader(
      this.props.loadChartData().then(({ data }) => {
        let fragments;
        const fragmentGroups = chartHelper.groupFragmentsById(data);
        if (this.props.isAuthor) {
          // The first element of fragmentGroup is the latest one
          fragments = Array.from(fragmentGroups.values()).map((fragGroup) => fragGroup[0]);
        } else {
          fragments = Array.from(fragmentGroups.values()).map((fragGroup) => {
            // Prefer CHARTING records
            const studentFragment = fragGroup.find((frag) => frag.fragmentType === FragmentType.CHARTING);
            return studentFragment || fragGroup.find((frag) => frag.phaseIndex === this.props.phaseIndex) || fragGroup[0];
          });
        }
        const laboratoryHistoryRecord = fragments.map((fragment: ChartFragment) => this.transformToHistoryRecord(fragment));
        this.setState({ chartHistory: laboratoryHistoryRecord, fragments: data });
      }),
      { errorMessage: 'can not load chart fragment' }
    );
  };

  transformToHistoryRecord = (fragment) => {
    const { locale } = this.props;
    const { formatDate, toMomentWithParsers } = dateTimeHelper;

    const getFormattedDateByLocale = (value: string): string =>
      formatDate({
        date: toMomentWithParsers(value).toDate(),
        locale
      });
    const formattedOrderStartDate = getFormattedDateByLocale(fragment.chartData[FormField.ORDER_START_DATE]?.content);
    const formattedResultsReceivedDate = getFormattedDateByLocale(fragment.chartData[FormField.RESULTS_RECEIVED_DATE]?.content);
    return {
      ...fragment,
      notesTitle: LanguageKeys.LABORATORY.SPECIAL_INSTRUCTIONS,
      orderTime: `${formattedOrderStartDate} ${fragment.chartData[FormField.ORDER_START_TIME]?.content}`,
      frequency: fragment.chartData[FormField.ORDER_FREQUENCY].content,
      orderDescription: fragment.chartData[FormField.ORDER_DESCRIPTION].content,
      orderDetails: fragment.chartData[FormField.SPECIMEN].content,
      notes: fragment.chartData[FormField.SPECIAL_INSTRUCTIONS]?.content,
      status: fragment.chartData[FormField.STATUS],
      chartData: {
        ...fragment.chartData,
        resultsReceivedDate: {
          content: formattedResultsReceivedDate,
          value: formattedResultsReceivedDate
        }
      }
    };
  };

  render() {
    const { enableDisplayRecordsButton, hasUnsavedChanges, displayAuthoringData, formFieldMap, formSubmittedCount, saveChartData, intl, locale } = this.props;
    const { chartHistory, selectedLaboratory } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      orderDescriptionField: formFieldMap.get(FormField.ORDER_DESCRIPTION),
      chartHistory,
      deleteHistory: this.deleteHistory,
      formFieldMap,
      formSubmittedCount,
      saveChartData,
      selectedLaboratory,
      onStatusClick: this.handleStatusClick,
      onSidebarClose: this.handleSidebarClose,
      onLaboratoryUpdate: this.handleChartUpdate,
      locale,
      intl,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <LaboratoryView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  phaseIndex: studentSelectors.getPhaseIndex(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];
export { Laboratory as BaseLaboratory };
export default compose(...enhancers)(Laboratory);

import { FormattedMessage } from 'react-intl';
import { ELSButton, ELSFlex, ELSIcon, ELSTab, ELSTabGroup } from 'components/common/els';
import NavigationComponent from 'components/common/navigation-component/NavigationComponent';
import { CaseStudyLanguageKeys } from 'lang/caseStudy';
import { NavigateProps, Tabs } from './CaseStudyInfo';
import CaseStudyOverview from './CaseStudyOverview';
import Instruction from './Instruction';
import './case.study.info.scss';

export interface CaseStudyInfoViewProps {
  activeTab: number;
  markPhaseDoneIsDisabled: boolean;
  onMarkPhaseDoneClick: () => void;
  onTabGroupClick: (tabIndex: number) => void;
  overview: string;
  markPhaseDoneText: string;
  instruction: string;
  navigateProps: NavigateProps;
}

const CaseStudyInfoView = (props: CaseStudyInfoViewProps) => {
  return (
    <div className="case-study-info">
      <ELSFlex className="case-study-info__header" middle column center>
        <ELSButton
          iconSize="1x"
          iconName="checkmark"
          iconPosition="left"
          text={props.markPhaseDoneText}
          onClick={props.onMarkPhaseDoneClick}
          isDisabled={props.markPhaseDoneIsDisabled}
        />
        <ELSFlex className="u-els-margin-top">
          <ELSIcon name="alert" prefix="gizmo" size="2x" align="sub" />
          <p className="note">
            <FormattedMessage id={CaseStudyLanguageKeys.CASE_STUDY_INFO.WARNING_MARK_PHASE_1_DONE} />
          </p>
        </ELSFlex>
      </ELSFlex>
      <h2 className="case-study-info__title u-els-margin-top-3o2 u-els-margin-bottom-1o2 u-els-margin-2x u-els-margin-1x@desktop">Case Study Information</h2>
      <NavigationComponent
        className="u-els-margin-top-1x u-els-margin-bottom-1x u-els-margin-2x u-els-margin-1x@desktop"
        title="Phase"
        current={props.navigateProps.current}
        total={props.navigateProps.total}
        onPrev={props.navigateProps.onPrev}
        onNext={props.navigateProps.onNext}
        disableNext={props.navigateProps.disableNext}
      />
      <div className="case-study-info__tab-group">
        <ELSTabGroup onChangeActiveIndex={props.onTabGroupClick} activeIndex={props.activeTab}>
          <ELSTab title="Instructions" />
          <ELSTab title="Case Study Overview" />
        </ELSTabGroup>
      </div>
      <div className="u-els-margin-2x u-els-margin-1x@desktop">
        {props.activeTab === Tabs.INSTRUCTIONS && <Instruction instruction={props.instruction} />}
        {props.activeTab === Tabs.CASE_STUDY_OVERVIEW && <CaseStudyOverview overview={props.overview} />}
      </div>
    </div>
  );
};

CaseStudyInfoView.displayName = 'CaseStudyInfoView';
export default CaseStudyInfoView;

import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';

const NoteShortcutKey = () => (
  <Box my className="c-els-card c-els-card--border-primary">
    <ELSFlex>
      <ELSFlexItem className="u-els-margin-right">
        <ELSIcon prefix="gizmo" name="information" />
      </ELSFlexItem>
      <ELSFlexItem w="1o4">
        <strong>ASSISTANCE KEY</strong>
        <p>1 = Dependent</p>
        <p>2 = Maximum</p>
        <p>3 = Moderate</p>
        <p>4a = Minimum</p>
        <p>4b = CGA</p>
        <p>5 = SBA</p>
        <p>6 = Mod/l</p>
        <p>7 = Independent</p>
      </ELSFlexItem>
      <ELSFlexItem w="1o4">
        <strong>PATIENT EDUCATION KEY</strong>
        <p>P = Patient</p>
        <p>F = Family</p>
        <p>1= Spouse</p>
        <p>2 = Brother/Sister</p>
        <p>3 = Son/Daughter</p>
        <p>4 = Significant Other</p>
        <p>5 = No family</p>
        <p>6 = Other</p>
      </ELSFlexItem>
      <ELSFlexItem w="1o4">
        <strong>METHOD KEY</strong>
        <p>V = Verbal</p>
        <p>W = Written</p>
        <p>T = Task performance</p>
      </ELSFlexItem>
      <ELSFlexItem w="1o4">
        <strong>COMPREHENSION KEY</strong>
        <p>A = Return demonstration</p>
        <p>B = Restates information accurately</p>
        <p>C = Answers questions accurately</p>
        <p>D = Describes how info/skill will be applied at home</p>
        <p>E = Unable to comprehend</p>
        <p>F = Noncompliant</p>
      </ELSFlexItem>
    </ELSFlex>
  </Box>
);

NoteShortcutKey.displayName = 'NoteShortcutKey';
export default NoteShortcutKey;

import { ValidationActionType, ValidationRuleType } from 'models/enum';
import { ChartMetaFormField } from 'models/ui';
import FieldNode from './FieldNode';
import Rule from './Rule';

export const handleMappingRuleIdsByField = ({
  ruleIdsByField,
  ruleRegistry,
  fieldNode,
  getFieldNode,
  fieldIdByValueMap,
  field
}: {
  ruleIdsByField: string[];
  ruleRegistry: Map<string, Rule>;
  fieldNode: FieldNode;
  getFieldNode: Function;
  fieldIdByValueMap: Map<string, string>;
  field: ChartMetaFormField;
}): void => {
  ruleIdsByField.forEach((ruleId) => {
    const rule = ruleRegistry.get(ruleId);
    const { actionType } = rule;
    const needProcessRuleResult = () => rule.isResultChanged && actionType !== ValidationActionType.VALIDATION;

    if (rule.parentId) {
      const parentRule = ruleRegistry.get(rule.parentId);
      parentRule.childRuleIds.push(ruleId);
      let fieldNodeToBeSubcribed = getFieldNode(parentRule.ruleInfo.formFieldId);
      if (parentRule.ruleInfo.chartFieldSelectionValue) {
        const fieldIdByValue = fieldIdByValueMap.get(parentRule.ruleInfo.chartFieldSelectionValue);
        fieldNodeToBeSubcribed = getFieldNode(fieldIdByValue);
      }
      const subInfo = {
        key: parentRule.id,
        actionType,
        fieldNode,
        needProcessRuleResult,
        ruleIdToValidate: parentRule.id,
        ruleIdToProcessResult: ruleId
      };
      fieldNodeToBeSubcribed.subscribe(subInfo);
    }

    let allowDefaultSubscription = true;
    if (rule.ruleInfo.validationActionType === ValidationActionType.PREFILL) {
      allowDefaultSubscription = false;
      const targetFieldIds = new Set<string>();
      (rule.ruleInfo.targets ?? []).forEach((target) => {
        targetFieldIds.add(target.formFieldId);
      });
      targetFieldIds.forEach((targetId) => {
        const targetFieldNode = getFieldNode(targetId);
        targetFieldNode.allowClearValue = false;
        const needRefillValue = !!field.fieldIdsToClearValue?.includes(targetId);
        const subInfo = {
          key: ruleId,
          actionType,
          fieldNode: targetFieldNode,
          needProcessRuleResult: () => rule.isResultChanged || needRefillValue,
          ruleIdToValidate: ruleId,
          ruleIdToProcessResult: ruleId
        };
        fieldNode.subscribe(subInfo);
      });
    }

    if (rule.ruleInfo.validationType === ValidationRuleType.CHART_CONTENT_SELECTED || rule.ruleInfo.validationType === ValidationRuleType.CHART_CONTENT_NOT_SELECTED) {
      allowDefaultSubscription = false;
      const fieldIdByValue = fieldIdByValueMap.get(rule.ruleInfo.chartFieldSelectionValue);
      const fieldNodeToBeSubcribed = getFieldNode(fieldIdByValue);
      const subInfo = {
        key: ruleId,
        actionType,
        fieldNode,
        needProcessRuleResult,
        ruleIdToValidate: ruleId,
        ruleIdToProcessResult: ruleId
      };
      fieldNodeToBeSubcribed.subscribe(subInfo);
    }

    if (allowDefaultSubscription) {
      const fieldNodeToBeSubcribed = getFieldNode(rule.ruleInfo.formFieldId);
      const subInfo = {
        key: ruleId,
        actionType,
        fieldNode,
        needProcessRuleResult,
        ruleIdToValidate: ruleId,
        ruleIdToProcessResult: ruleId
      };
      fieldNodeToBeSubcribed.subscribe(subInfo);
    }
  });
};

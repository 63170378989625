import cx from 'classnames';
import { Component } from 'react';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import './save.cancel.footer.scss';

export interface SaveCancelFooterProps {
  className?: string;
  onCancel: Function;
  onSave: Function;
  disableSaveButton: boolean;
}

class SaveCancelFooter extends Component<SaveCancelFooterProps> {
  static defaultProps = {
    disableSaveButton: true
  };

  render() {
    return (
      <div className={cx('save-cancel__footer', this.props.className)}>
        <ELSFlex spaceBetween>
          <ELSFlexItem middle>
            <ELSButton type="link" text="Cancel" onClick={this.props.onCancel} />
          </ELSFlexItem>
          <ELSFlexItem>
            <ELSButton id="save-btn" text="Save" onClick={this.props.onSave} isDisabled={this.props.disableSaveButton} />
          </ELSFlexItem>
        </ELSFlex>
      </div>
    );
  }
}

export default SaveCancelFooter;

import { ELSFlex, ELSFlexItem } from '@els/els-ui-common-react';
import { ChartFragment } from 'models/api-response';
import { Box } from 'components/common';
import { FormField as MorseFormField, Section as MorseSectionTitle } from 'components/features/chart/special-charts/morse-fall-scale/constants';
import { SectionTitle } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface FallRiskAssessmentViewProps {
  isHideHeadline?: boolean;
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
}

export interface FallRiskChartItem<T = string> {
  id: string;
  active: boolean;
  formField: string;
  content: T;
  preventDelete: boolean;
  title: string;
}

export default function FallRiskAssessmentReadOnly({ isHideHeadline, chartFragment }: FallRiskAssessmentViewProps) {
  return (
    <Box mt2>
      {!isHideHeadline && <h1>Admission History</h1>}
      <h2>{SectionTitle.FALL_RISK_ASSESSMENT}</h2>

      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2" wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE}
                  formFieldId={MorseFormField.MORSE_FALL_SCALE}
                  customTitle="Fall Risk Assignment Score"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE}
                  formFieldId={MorseFormField.RISK_LEVEL}
                  customTitle="Risk Level"
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
}

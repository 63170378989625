export enum Section {
  MORSE_FALL_SCALE = 'Morse Fall Scale',
  TOTAL_MORSE_FALL_SCALE_SCORE = 'Total Fall Risk Score'
}

export enum FormField {
  MORSE_FALL_SCALE = 'morseFallScale',
  HISTORY_OF_FALLING = 'historyOfFalling',
  SECONDARY_DIAGNOSIS = 'secondaryDiagnosis',
  AMBULATORY_AID = 'ambulatoryAid',
  IV_OR_IV_ACCESS = 'ivOrIvAccess',
  GAIT = 'gait',
  MENTAL_STATUS = 'mentalStatus',
  RISK_LEVEL = 'riskLevel'
}

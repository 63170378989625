import { ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import AllergiesHistory, { AllergiesChartHistory } from './AllergiesHistory';

interface AllergiesViewProps extends ChartViewProps {
  deleteHistory: Function;
  chartHistory: AllergiesChartHistory[];
}

const AllergiesView = (props: AllergiesViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="allergies">
      <h1>{SectionTitle.ALLERGIES}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.NO_KNOWN_ALLERGIES)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <h2 className="u-els-margin-bottom-2x">Chart Inputs</h2>
          <ErrorFormField formField={formFieldMap.get(FormField.ALLERGY_TYPE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN_OTHER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN_MEDICATION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN_MEDICATION_OTHER)} formSubmittedCount={formSubmittedCount} />
          <h4 className="u-els-margin-bottom">{SectionTitle.REACTIONS}</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.REACTIONS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.REACTIONS_OTHER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.SEVERITY)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.INFORMANT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.INFORMANT_OTHER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.CONFIDENCE_LEVEL)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="2o3" md="1o1">
          <AllergiesHistory chartHistory={props.chartHistory} deleteHistory={props.deleteHistory} />
        </ELSFlexItem>
      </ELSFlex>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

export default AllergiesView;

export enum FormField {
  GENERAL = 'general',
  VITAL_SIGNS = 'vitalSigns',
  INTEGUMENT = 'integument',
  HEENT = 'heent',
  CARDIOVASCULAR = 'cardiovascular',
  RESPIRATORY = 'respiratory',
  GASTROINTESTINAL = 'gastrointestinal',
  GENITOURINARY = 'genitourinary',
  MUSCULOSKELETAL = 'musculoskeletal',
  NEUROLOGIC = 'neurologic',
  DEVELOPMENTAL = 'developmental',
  ENDOCRINE = 'endocrine',
  GENITALIA = 'genitalia',
  LYMPHATIC = 'lymphatic',
  FUNDAL_HEIGHT = 'fundalHeight',
  PELVIS_TYPE = 'pelvisType',
  VAGINA = 'vagina',
  PRESENTATION = 'presentation',
  SACRUM = 'sacrum',
  ADNEXA = 'adnexa',
  DIAGONAL_CONJUGATE = 'diagonalConjugate',
  VULVA = 'vulva',
  FETAL_HEART_RATE = 'fetalHeartRate',
  SPINES = 'spines',
  CERVIX = 'cervix',
  FETAL_MOVEMENT = 'fetalMovement',
  SP_ARCH = 'SPArch',
  OBSTETRIC_GENITALIA = 'obstetricGenitalia',
  CURRENT_PREGNANCY = 'currentPregnancy',
  ASSESSMENT_PLAN = 'assessmentPlan',
  SUMMARY = 'summary',
  NAUSEA_VOMITING = 'nauseaVomiting',
  NAUSEA_VOMITING_NOTES = 'nauseaVomitingNotes',
  VAGINAL_BLEEDING = 'vaginalBleeding',
  VAGINAL_BLEEDING_NOTES = 'vaginalBleedingNotes',
  VAGINAL_DISCHARGE = 'vaginalDischarge',
  VAGINAL_DISCHARGE_NOTES = 'vaginalDischargeNotes',
  URINARY_SIGNS_SYMPTOMS = 'urinarySignsSymptoms',
  URINARY_SIGNS_SYMPTOMS_NOTES = 'urinarySignsSymptomsNotes',
  CONSTIPATION = 'constipation',
  CONSTIPATION_NOTES = 'constipationNotes',
  FEVER_RASH = 'feverRash',
  FEVER_RASH_NOTES = 'feverRashNotes',
  INFECTION = 'infection',
  INFECTION_NOTES = 'infectionNotes',
  CURRENT_PREGNANCY_OTHER = 'currentPregnancyOther',
  CURRENT_PREGNANCY_OTHER_NOTES = 'currentPregnancyOtherNotes'
}

export enum FormFieldLabel {
  GENERAL = 'General',
  VITAL_SIGNS = 'Vital Signs',
  INTEGUMENT = 'Integument',
  HEENT = 'HEENT',
  CARDIOVASCULAR = 'Cardiovascular',
  RESPIRATORY = 'Respiratory',
  GASTROINTESTINAL = 'Gastrointestinal',
  GENITOURINARY = 'Genitourinary',
  MUSCULOSKELETAL = 'Musculoskeletal',
  NEUROLOGIC = 'Neurologic',
  DEVELOPMENTAL = 'Developmental',
  ENDOCRINE = 'Endocrine',
  GENITALIA = 'Genitalia',
  LYMPHATIC = 'Lymphatic',
  FUNDAL_HEIGHT = 'Fundal height',
  PELVIS_TYPE = 'Pelvis Type',
  VAGINA = 'Vagina',
  PRESENTATION = 'Presentation',
  SACRUM = 'Sacrum',
  ADNEXA = 'Adnexa',
  DIAGONAL_CONJUGATE = 'Diagonal conjugate',
  VULVA = 'Vulva',
  FETAL_HEART_RATE = 'Fetal heart rate',
  SPINES = 'Spines',
  CERVIX = 'Cervix',
  FETAL_MOVEMENT = 'Fetal Movement',
  SP_ARCH = 'SP arch',
  SUMMARY = 'Summary',
  NAUSEA_VOMITING = 'Nausea/Vomiting',
  NOTES = 'Notes',
  OTHER = 'Other',
  VAGINAL_BLEEDING = 'Vaginal Bleeding',
  VAGINAL_DISCHARGE = 'Vaginal Discharge',
  URINARY_SIGNS_SYMPTOMS = 'Urinary Signs and Symptoms',
  CONSTIPATION = 'Constipation',
  FEVER_RASH = 'Fever/Rash',
  INFECTION = 'Infection'
}

export enum Section {
  OBSTETRIC_GENITALIA = 'Obstetric/Genitalia',
  CURRENT_PREGNANCY = 'Current Pregnancy',
  ASSESSMENT_PLAN = 'Assessment and Plan'
}

import { Box, MultiLine } from 'components/common';

export interface CaseStudyPhasesProps {
  phases: { id: string; title: string; instruction?: string }[];
}

const CaseStudyPhases = (props: CaseStudyPhasesProps) => (
  <div className="quick-view__tab-body">
    {props.phases?.length > 0 &&
      props.phases.map((phase) => (
        <Box key={phase.id} mt2>
          <b>{phase.title}</b>
          <MultiLine text={phase.instruction} />
        </Box>
      ))}
  </div>
);

CaseStudyPhases.displayName = 'CaseStudyPhases';
export default CaseStudyPhases;

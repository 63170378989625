import { Component } from 'react';
import { defaultCustomRender } from 'helpers/chartRender.helper';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import { FormField, HomeMedicationHistoryProps } from './constants';

class HomeMedicationOverTheCounterHistoryTable extends Component<HomeMedicationHistoryProps> {
  static displayName = 'HomeMedicationOverTheCounterHistoryTable';

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const actionHeader = deleteHistory ? { field: 'actionDelete' } : {};
    const dataSource = {
      headers: [
        { text: 'Brand Name', field: 'brandName', sortable: true, customRender: defaultCustomRender(FormField.BRAND_NAME) },
        { text: 'Generic Name', field: 'genericName', customRender: defaultCustomRender(FormField.GENERIC_NAME) },
        { text: 'Product Type', field: 'productType', customRender: defaultCustomRender(FormField.PRODUCT_TYPE) },
        { text: 'Form', field: 'productDetailsFormValue', customRender: defaultCustomRender(FormField.OVER_THE_COUNTER_PRODUCT_FORM) },
        { text: 'Dose', field: 'overTheCounterProductsDose', customRender: defaultCustomRender(FormField.OVER_THE_COUNTER_PRODUCT_DOSE) },
        {
          text: 'Taken In Last 24h',
          field: 'overTheCounterProductsProducftTakenInLast24Hours',
          customRender: defaultCustomRender(FormField.OVER_THE_COUNTER_PRODUCT_TAKEN_IN_LAST_24HOURS)
        },
        {
          text: 'Reason',
          field: 'overTheCounterProductsReasonForTakingProduct',
          customRender: defaultCustomRender(FormField.OVER_THE_COUNTER_PRODUCT_REASON_FOR_TAKING_PRODUCT)
        },
        { text: 'Result', field: 'overTheCounterProductsResultsFollowingUse', customRender: defaultCustomRender(FormField.OVER_THE_COUNTER_PRODUCT_RESULT_FOLLOWING_USE) },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { ...actionHeader }
      ],
      css: ['column__chart-time', '', '', '', '', '', '', '', 'column__entry-by', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt>
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} showDeleteColumn={!!deleteHistory} />
      </Box>
    );
  }
}

export default HomeMedicationOverTheCounterHistoryTable;

import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { FragmentType } from 'models/enum';
import { Locales } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import { Badge, ButtonLinkIcon, ChartPermission, SavedPatientCharting } from 'components/common';
import { ELSButton, ELSIcon } from 'components/common/els';

export interface SavedWoundsViewProps {
  columns: any;
  onRecordUpdate: Function;
  locale: Locales;
}

const SavedPatientWrapper = (props: SavedWoundsViewProps) => {
  const { onRecordUpdate, ...restProps } = props;
  const { location, status, type } = props.columns;

  const locationRenderer = (row, onViewClick) => {
    if (row.active) {
      return <ELSButton type="link" text={row.location} onClick={() => onViewClick(row)} />;
    }
    return <span className={cx({ 'row--deleted': !row.active })}>{row.location}</span>;
  };

  const entryTimeRenderer = (row) => (
    <Badge className={cx('c-els-badge--inline', { 'row--deleted': !row.active })}>
      {dateTimeHelper.formatDate({ date: dateTimeHelper.toMomentWithParsers(row.entryTime).toDate(), locale: props.locale, includeTime: true })}
    </Badge>
  );

  const statusRenderer = (row) => {
    return (
      row.active && (
        <div className="saved-patient-charting__status">
          <Badge className={cx('saved-patient-charting__badge', { 'saved-patient-charting__badge--active': row[status.field] === status.active })}>{row[status.field]}</Badge>
          {row[status.field] === status.active && (
            <ButtonLinkIcon
              className="saved-patient-charting__update-btn o-els-icon-svg--sub u-cursor-pointer"
              iconSize="1x"
              iconName="new-window"
              text="Update"
              onClick={() => onRecordUpdate(row)}
            />
          )}
        </div>
      )
    );
  };

  const actionsRenderer = (row, onViewClick, onDeleteClick) => {
    return (
      row.active && (
        <div className={cx('saved-patient-charting__action', { 'u-cursor-pointer': row.active })}>
          <ButtonLinkIcon className="o-els-icon-svg--sub" iconName="enlarge" iconSize="1x" text="View" onClick={() => onViewClick(row)} />
          <ChartPermission fragmentCreatorId={row.fragmentCreatorId}>
            {({ allowDeleteFragment }) => {
              const showTrashIcon = allowDeleteFragment && row.active && row.fragmentType !== FragmentType.AUTHORED;
              return showTrashIcon && <ELSIcon customClass="saved-patient-charting__trash-icon" name="trash" prefix="gizmo" color="secondary" onClick={() => onDeleteClick(row)} />;
            }}
          </ChartPermission>
        </div>
      )
    );
  };

  return (
    <div>
      <SavedPatientCharting {...restProps}>
        {({ onViewClick, onDeleteClick, defaultCustomRender }) => [
          <column key={location.field} field={location.field} header={location.header} sortable customRender={(row) => locationRenderer(row, onViewClick)} />,
          <column key={status.field} field={status.field} header={status.header} sortable customRender={statusRenderer} />,
          <column key={type.field} field={type.field} header={type.header} customRender={defaultCustomRender(type.field)} />,
          <column key="entryTime" field="entryTime" header="Entry Time" sortable customRender={entryTimeRenderer} />,
          <column key="enteredBy" field="enteredBy" header="Entered By" customRender={defaultCustomRender('enteredBy')} />,
          <column key="actions" header="Actions" customRender={(row) => actionsRenderer(row, onViewClick, onDeleteClick)} />
        ]}
      </SavedPatientCharting>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});

const enhancers = [connect(mapStateToProps)];
export { SavedPatientWrapper as BaseSavedPatientWrapper };
export default compose(...enhancers)(SavedPatientWrapper);

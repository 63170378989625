import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, SectionLabel } from './constants';
import ChildRiskAssessmentView from './ChildRiskAssessmentView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface ChildRiskAssessmentState {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class ChildRiskAssessment extends Component<ChartComponentProps, ChildRiskAssessmentState> {
  static displayName = 'ChildRiskAssessment';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.CHILD_RISK_ASSESSMENT, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentContentIds } = chartHelper;
    const dataMap = new Map();

    const radioButtons = new Map();
    radioButtons.set(FormField.DENTAL_CARE, `${SectionLabel.DENTAL_CARE}:`);
    radioButtons.set(FormField.CHILD_PASSENGER_SAFETY, `${SectionLabel.CHILD_PASSENGER_SAFETY}:`);
    radioButtons.set(FormField.ABUSE_MALTREATMENT, `${SectionLabel.ABUSE_MALTREATMENT}:`);
    radioButtons.set(FormField.SLEEP, `${SectionLabel.SLEEP}:`);
    radioButtons.set(FormField.PLAYGROUND_INJURIES, `${SectionLabel.PLAYGROUND_INJURIES}:`);
    radioButtons.set(FormField.POISONINGS, `${SectionLabel.POISONINGS}:`);
    radioButtons.set(FormField.WATER_SAFETY, `${SectionLabel.WATER_SAFETY}:`);
    radioButtons.set(FormField.RESIDENTIAL_FIRES, `${SectionLabel.RESIDENTIAL_FIRES}:`);
    radioButtons.set(FormField.BICYCLE_RELATED_INJURIES, `${SectionLabel.BICYCLE_RELATED_INJURIES}:`);

    const textBoxes = [
      { name: FormField.DENTAL_CARE_DESCRIBE },
      { name: FormField.CHILD_PASSENGER_SAFETY_DESCRIBE },
      { name: FormField.ABUSE_MALTREATMENT_DESCRIBE },
      { name: FormField.SLEEP_DESCRIBE },
      { name: FormField.PLAYGROUND_INJURIES_DESCRIBE },
      { name: FormField.POISONINGS_DESCRIBE },
      { name: FormField.WATER_SAFETY_DESCRIBE },
      { name: FormField.RESIDENTIAL_FIRES_DESCRIBE },
      { name: FormField.BICYCLE_RELATED_INJURIES_DESCRIBE }
    ];
    textBoxes.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.TEXT_AREA,
          label: SectionLabel.DESCRIBE,
          value: chartHelper.getFragmentValue(fragment, SectionLabel.PRESENCE_OF_RISK, item.name)
        })
      )
    );
    radioButtons.forEach((label, name) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.RADIO_CHOICE,
          label,
          contentIds: getFragmentContentIds(fragment, SectionLabel.PRESENCE_OF_RISK, name)
        })
      )
    );

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: SectionLabel.CHILD_RISK_ASSESSMENT,
      records: [
        {
          sectionTitle: SectionLabel.PRESENCE_OF_RISK,
          records: [
            buildPatientRecord(formFieldMap, FormField.DENTAL_CARE),
            buildPatientRecord(formFieldMap, FormField.DENTAL_CARE_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.CHILD_PASSENGER_SAFETY),
            buildPatientRecord(formFieldMap, FormField.CHILD_PASSENGER_SAFETY_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.ABUSE_MALTREATMENT),
            buildPatientRecord(formFieldMap, FormField.ABUSE_MALTREATMENT_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.SLEEP),
            buildPatientRecord(formFieldMap, FormField.SLEEP_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.PLAYGROUND_INJURIES),
            buildPatientRecord(formFieldMap, FormField.PLAYGROUND_INJURIES_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.POISONINGS),
            buildPatientRecord(formFieldMap, FormField.POISONINGS_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.WATER_SAFETY),
            buildPatientRecord(formFieldMap, FormField.WATER_SAFETY_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.RESIDENTIAL_FIRES),
            buildPatientRecord(formFieldMap, FormField.RESIDENTIAL_FIRES_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.BICYCLE_RELATED_INJURIES),
            buildPatientRecord(formFieldMap, FormField.BICYCLE_RELATED_INJURIES_DESCRIBE)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord };
  };

  render() {
    const { isLocked, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      chartActionsProps,
      formSubmittedCount,
      formFieldMap
    };
    return <ChildRiskAssessmentView {...viewProps} />;
  }
}

export { ChildRiskAssessment as BaseChildRiskAssessment };
export default compose(withChartLogic, withAdmissionHistory)(ChildRiskAssessment);

import { AllergyRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, DropDownItem } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSDropDown, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import AllergyInformationTable from './AllergyInformationTable';
import HerbalAndNaturalRemedyProductsTable from './HerbalAndNaturalRemedyProductsTable';
import OverTheCounterProductsTable from './OverTheCounterProductsTable';
import PrescriptionMedicationsTable from './PrescriptionMedicationsTable';
import './medication.reconciliation.scss';

export interface UserData {
  individualProvidingInformation: string;
  informationObtainedBy: string;
  medicationReviewBy: string;
  providerSignature: string;
}

export interface MedicationReconciliationViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  allergyData: AllergyRecord[];
  showAllergyInformation: boolean;
  prescriptionMedicationsData: object[];
  counterProductsData: object[];
  remedyProductsData: object[];
  userData: UserData;
  showHomeMedicationsInformation: boolean;
  chartActionsProps: ChartActionsComponentProps;
  changeHandler: Function;
  isAuthor: boolean;
  authorOptions: DropDownItem[];
  onFieldChange: Function;
}

const MedicationReconciliationView = (props: MedicationReconciliationViewProps) => {
  const { formFieldMap, allergyData, prescriptionMedicationsData, counterProductsData, remedyProductsData, userData, formSubmittedCount, isAuthor, authorOptions } = props;
  const { showAllergyInformation, showHomeMedicationsInformation, changeHandler, onFieldChange } = props;

  return (
    <div className="medication-reconciliation">
      <h1>{SectionTitle.MEDICATION_RECONCILIATION}</h1>
      {showAllergyInformation && (
        <Box mt2>
          <h2>Allergy Information</h2>
          <AllergyInformationTable chartHistory={allergyData} />
        </Box>
      )}

      {showHomeMedicationsInformation && (
        <Box mt2>
          <h2>Medications Prior to Admission</h2>
          <SectionDivider mt />
          <Box>
            <h3 className="u-els-margin-bottom">Prescription Medications</h3>
            <PrescriptionMedicationsTable chartHistory={prescriptionMedicationsData} changeHandler={changeHandler} />
          </Box>

          <Box mt2>
            <h3>Over-the counter Products</h3>
            <OverTheCounterProductsTable chartHistory={counterProductsData} changeHandler={changeHandler} />
          </Box>

          <Box mt2>
            <h3>Herbal and Natural Remedy Products</h3>
            <HerbalAndNaturalRemedyProductsTable chartHistory={remedyProductsData} changeHandler={changeHandler} />
          </Box>
        </Box>
      )}

      <SectionDivider />

      <Box className="information" mb4 mt3>
        <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <h5>{`Individual Providing Information: ${userData.individualProvidingInformation}`}</h5>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h5>{`Information obtained by: ${userData.informationObtainedBy}`}</h5>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-1x u-flex-row-gap-2x" left wrap>
              <ELSFlexItem>
                <h5>
                  <Box className="medications-reviewed-by">
                    Medications Reviewed by:&nbsp;
                    {isAuthor ? (
                      <>
                        <ELSDropDown
                          id="medications-reviewed-name"
                          name="information-obtained-name"
                          options={authorOptions}
                          changeHandler={({ target }) => onFieldChange(FormField.MEDICATIONS_REVIEWER_ID, target.value)}
                        />
                        <ErrorFormField
                          useDefaultMargin={false}
                          className="input-time-field u-els-margin-bottom-1x1o2"
                          formField={formFieldMap.get(FormField.MEDICATIONS_REVIEWED_TIME)}
                          formSubmittedCount={formSubmittedCount}
                        />
                      </>
                    ) : (
                      userData.medicationReviewBy
                    )}
                  </Box>
                </h5>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h5>
              <Box className="provider-signature">
                Provider Signature:&nbsp;
                {isAuthor ? (
                  <>
                    <ELSDropDown
                      id="provider-signature-name"
                      name="provider-signature-name"
                      options={authorOptions}
                      changeHandler={({ target }) => onFieldChange(FormField.PROVIDER_SIGNATURE_ID, target.value)}
                    />
                    <ErrorFormField
                      useDefaultMargin={false}
                      className="input-time-field u-els-margin-bottom-1x1o2"
                      formField={formFieldMap.get(FormField.PROVIDER_SIGNATURE_TIME)}
                      formSubmittedCount={formSubmittedCount}
                    />
                  </>
                ) : (
                  userData.providerSignature
                )}
              </Box>
            </h5>
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <h2>Additional Prescriptions Given at Time of Discharge</h2>
      <Box mt2>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o4" md="1o1">
            <h5 className="u-els-bold">Medication</h5>
            <ErrorFormField formField={formFieldMap.get(FormField.MEDICATION_ONE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEDICATION_TWO)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEDICATION_THREE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MEDICATION_FOUR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o4" md="1o1">
            <h5 className="u-els-bold">Directions (Dose, Route, Frequency)</h5>
            <ErrorFormField formField={formFieldMap.get(FormField.DIRECTION_ONE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.DIRECTION_TWO)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.DIRECTION_THREE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.DIRECTION_FOUR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o4" md="1o1">
            <h5 className="u-els-bold">Indications</h5>
            <ErrorFormField formField={formFieldMap.get(FormField.INDICATION_ONE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.INDICATION_TWO)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.INDICATION_THREE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.INDICATION_FOUR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o4" md="1o1">
            <h5 className="u-els-bold">Special Instructions</h5>
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTION_ONE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTION_TWO)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTION_THREE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTION_FOUR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <Box pb3 className="u-els-text-center">
        <div>
          <span className="u-els-bold">NOTE: </span>
          <span>Please bring this Medication Record with you to your Health Care Provider&apos;s office or on return to hospital.</span>
        </div>
      </Box>

      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

MedicationReconciliationView.displayName = 'MedicationReconciliationView';
export default MedicationReconciliationView;

import { ScaleRollUpContent } from 'models/ui';
import ScaleResult from './ScaleResult';

export interface ScaleRollUpProps {
  scaleRollUpContent: ScaleRollUpContent;
  sectionTitle: string;
}

export enum Label {
  TOTAL_SCORE = 'Total Score: '
}

const ScaleRollUp = (props: ScaleRollUpProps) => {
  const { scaleRollUpContent, sectionTitle } = props;
  const totalScore = scaleRollUpContent?.total;

  return (
    <div className="scale-wrapper">
      <ScaleResult label={sectionTitle} isHiddenScore={false} score={totalScore} isHighlightContent={!!scaleRollUpContent} />
    </div>
  );
};

ScaleRollUp.displayName = 'ScaleRollUp';
export default ScaleRollUp;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import CompletePhysicalAssessmentView from './CompletePhysicalAssessmentView';
import { Title } from '../shared/constants';

export interface CompletePhysicalAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

class CompletePhysicalAssessment extends Component<CompletePhysicalAssessmentProps> {
  static displayName = 'CompletePhysicalAssessment';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkboxes = [FormField.NO_ASSESSMENT_REQUIRED];
    const textAreas = [
      FormField.GENERAL,
      FormField.INTEGUMENTARY,
      FormField.HEAD_NECK,
      FormField.EARS_NOSE_THROAT,
      FormField.EYES_VISION,
      FormField.RESPIRATORY,
      FormField.CARDIOVASCULAR,
      FormField.PERIPHERAL_VASCULAR,
      FormField.LYMPHATIC,
      FormField.BREASTS,
      FormField.ABDOMEN,
      FormField.MUSCULOSKELETAL,
      FormField.NEUROLOGIC,
      FormField.PELVIC_GENITALIA,
      FormField.PROSTATE_GENITALIA,
      FormField.RECTAL,
      FormField.OTHER,
      FormField.ASSESSMENT_SUMMARY,
      FormField.PLAN
    ];

    checkboxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));

    dataMap.set(FormField.GENERAL, { ...dataMap.get(FormField.GENERAL), label: FormFieldLabel.GENERAL });
    dataMap.set(FormField.INTEGUMENTARY, { ...dataMap.get(FormField.INTEGUMENTARY), label: FormFieldLabel.INTEGUMENTARY });
    dataMap.set(FormField.HEAD_NECK, { ...dataMap.get(FormField.HEAD_NECK), label: FormFieldLabel.HEAD_NECK });
    dataMap.set(FormField.EARS_NOSE_THROAT, { ...dataMap.get(FormField.EARS_NOSE_THROAT), label: FormFieldLabel.EARS_NOSE_THROAT });
    dataMap.set(FormField.EYES_VISION, { ...dataMap.get(FormField.EYES_VISION), label: FormFieldLabel.EYES_VISION });
    dataMap.set(FormField.RESPIRATORY, { ...dataMap.get(FormField.RESPIRATORY), label: FormFieldLabel.RESPIRATORY });
    dataMap.set(FormField.CARDIOVASCULAR, { ...dataMap.get(FormField.CARDIOVASCULAR), label: FormFieldLabel.CARDIOVASCULAR });
    dataMap.set(FormField.PERIPHERAL_VASCULAR, { ...dataMap.get(FormField.PERIPHERAL_VASCULAR), label: FormFieldLabel.PERIPHERAL_VASCULAR });
    dataMap.set(FormField.LYMPHATIC, { ...dataMap.get(FormField.LYMPHATIC), label: FormFieldLabel.LYMPHATIC });
    dataMap.set(FormField.BREASTS, { ...dataMap.get(FormField.BREASTS), label: FormFieldLabel.BREASTS });
    dataMap.set(FormField.ABDOMEN, { ...dataMap.get(FormField.ABDOMEN), label: FormFieldLabel.ABDOMEN });
    dataMap.set(FormField.MUSCULOSKELETAL, { ...dataMap.get(FormField.MUSCULOSKELETAL), label: FormFieldLabel.MUSCULOSKELETAL });
    dataMap.set(FormField.NEUROLOGIC, { ...dataMap.get(FormField.NEUROLOGIC), label: FormFieldLabel.NEUROLOGIC });
    dataMap.set(FormField.PELVIC_GENITALIA, { ...dataMap.get(FormField.PELVIC_GENITALIA), label: FormFieldLabel.PELVIC_GENITALIA });
    dataMap.set(FormField.PROSTATE_GENITALIA, { ...dataMap.get(FormField.PROSTATE_GENITALIA), label: FormFieldLabel.PROSTATE_GENITALIA });
    dataMap.set(FormField.RECTAL, { ...dataMap.get(FormField.RECTAL), label: FormFieldLabel.RECTAL });
    dataMap.set(FormField.OTHER, { ...dataMap.get(FormField.OTHER), label: FormFieldLabel.OTHER });
    dataMap.set(FormField.ASSESSMENT_SUMMARY, { ...dataMap.get(FormField.ASSESSMENT_SUMMARY), label: FormFieldLabel.ASSESSMENT_SUMMARY });
    dataMap.set(FormField.PLAN, { ...dataMap.get(FormField.PLAN), label: FormFieldLabel.PLAN });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;

    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.COMPLETE_PHYSICAL_ASSESSMENT_NARRATIVE,
        fields: [
          FormField.NO_ASSESSMENT_REQUIRED,
          FormField.GENERAL,
          FormField.INTEGUMENTARY,
          FormField.HEAD_NECK,
          FormField.EARS_NOSE_THROAT,
          FormField.EYES_VISION,
          FormField.RESPIRATORY,
          FormField.CARDIOVASCULAR,
          FormField.PERIPHERAL_VASCULAR,
          FormField.LYMPHATIC,
          FormField.BREASTS,
          FormField.ABDOMEN,
          FormField.MUSCULOSKELETAL,
          FormField.NEUROLOGIC,
          FormField.PELVIC_GENITALIA,
          FormField.PROSTATE_GENITALIA,
          FormField.RECTAL,
          FormField.OTHER,
          FormField.ASSESSMENT_SUMMARY,
          FormField.PLAN
        ],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: SectionTitle.COMPLETE_PHYSICAL_ASSESSMENT_NARRATIVE,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragmentData = { ...basicInfo, chartData: cleanRecord } as ChartFragment;
    this.props.saveChart([fragmentData], { defaultFormFields: this.buildFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => {
    this.props.initState(this.buildFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { formFieldMap, formSubmittedCount, displayAuthoringData } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData
    };
    const viewProps = {
      formFieldMap,
      formSubmittedCount,
      chartActionsProps
    };
    return <CompletePhysicalAssessmentView {...viewProps} />;
  }
}

export { CompletePhysicalAssessment as BaseCompletePhysicalAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(CompletePhysicalAssessment);

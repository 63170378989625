import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { LaboratoryTestResult } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper, dateTimeHelper } from 'helpers';
import { withChartLogic } from 'components/common';
import { FormField } from './LaboratoryTest';
import LaboratoryTestResultCardView, { LaboratoryTestResultCardViewProps } from './LaboratoryTestResultCardView';

interface LaboratoryTestResultCardState {
  [FormField.TEST_RESULT]: string;
  [FormField.DATE_OF_TEST_RESULT]: string;
  [FormField.TEST_RESULT_LEVEL]: string;
  [FormField.TEST_RESULT_SIGNIFICANCE]: string;
}

export interface LaboratoryTestResultCardProps extends ChartComponentProps {
  showError: boolean;
  labResult: LaboratoryTestResult;
  onLabTestResultChange: Function;
}

export const buildFormFields = (labResult: LaboratoryTestResult): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();
  dataMap.set(
    FormField.DATE_OF_TEST_RESULT,
    createFormField({
      name: FormField.DATE_OF_TEST_RESULT,
      type: FormFieldInputType.DATE,
      label: 'Date of Test Result (mm-dd-yyyy)',
      value: labResult[FormField.DATE_OF_TEST_RESULT] || ''
    })
  );
  return dataMap;
};

class LaboratoryTestResultCard extends Component<LaboratoryTestResultCardProps, LaboratoryTestResultCardState> {
  static displayName = 'LaboratoryTestResultCard';

  constructor(props) {
    super(props);
    this.state = {
      [FormField.TEST_RESULT]: '',
      [FormField.DATE_OF_TEST_RESULT]: '',
      [FormField.TEST_RESULT_LEVEL]: '',
      [FormField.TEST_RESULT_SIGNIFICANCE]: ''
    };
  }

  componentDidMount() {
    const { initState, labResult } = this.props;
    initState(buildFormFields(labResult));
    this.setState({
      [FormField.TEST_RESULT]: labResult[FormField.TEST_RESULT],
      [FormField.DATE_OF_TEST_RESULT]: labResult[FormField.DATE_OF_TEST_RESULT],
      [FormField.TEST_RESULT_LEVEL]: labResult[FormField.TEST_RESULT_LEVEL],
      [FormField.TEST_RESULT_SIGNIFICANCE]: labResult[FormField.TEST_RESULT_SIGNIFICANCE]
    });
  }

  componentDidUpdate(prevProps: Readonly<LaboratoryTestResultCardProps>): void {
    if (!isEqual(this.props.labResult, prevProps.labResult)) {
      this.setState({
        [FormField.TEST_RESULT]: this.props.labResult[FormField.TEST_RESULT],
        [FormField.DATE_OF_TEST_RESULT]: this.props.labResult[FormField.DATE_OF_TEST_RESULT],
        [FormField.TEST_RESULT_LEVEL]: this.props.labResult[FormField.TEST_RESULT_LEVEL],
        [FormField.TEST_RESULT_SIGNIFICANCE]: this.props.labResult[FormField.TEST_RESULT_SIGNIFICANCE]
      });
    }
  }

  handleInputChange = (field: string, e) => {
    const value = e.target?.value || e.value;
    this.setState<never>({ [field]: value });
    this.handleLabTestResultChange(field, value);
  };

  handleDateChange = (field: string, value: string) => {
    const date = dateTimeHelper.toDate(value, '', true);
    this.setState<never>({ [field]: date });
    this.handleLabTestResultChange(field, date);
  };

  handleLabTestResultChange = (field, value) => {
    this.props.onLabTestResultChange({
      ...this.props.labResult,
      [field]: value
    });
  };

  render() {
    const { formFieldMap, labResult, showError } = this.props;
    const viewProps: LaboratoryTestResultCardViewProps = {
      chartMetaFormFields: formFieldMap,
      labResult,
      showError,
      [FormField.TEST_RESULT]: this.state[FormField.TEST_RESULT],
      [FormField.DATE_OF_TEST_RESULT]: this.state[FormField.DATE_OF_TEST_RESULT],
      [FormField.TEST_RESULT_LEVEL]: this.state[FormField.TEST_RESULT_LEVEL],
      [FormField.TEST_RESULT_SIGNIFICANCE]: this.state[FormField.TEST_RESULT_SIGNIFICANCE],
      onInputChange: this.handleInputChange,
      onDateChange: this.handleDateChange
    };
    return <LaboratoryTestResultCardView {...viewProps} />;
  }
}

export { LaboratoryTestResultCard as BaseLaboratoryTestResultCard };
export default withChartLogic(LaboratoryTestResultCard);

import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { VitalSignsRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { cssHelper } from 'helpers';
import { Box, ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import VitalSignsChart from './VitalSignsChart';
import VitalSignsHistory from './VitalSignsHistory';
import '../vital.signs.common.scss';

export interface VitalSignsViewProps {
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartHistory: VitalSignsRecord[];
  deleteHistory: Function;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  intl: IntlShape;
}

const VitalSignsView = ({ chartMetaFormFields, chartHistory, deleteHistory, formSubmittedCount, chartActionsProps, intl }: VitalSignsViewProps) => (
  <>
    <div className="vital-signs-common chart-input">
      <h1 className="chart-input__header">{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS })}</h1>
      <ELSFlex left className="o-els-flex-layout--wrap@mobile o-els-flex-layout--wrap@tablet">
        <ELSFlexItem>
          <div
            className={cssHelper.mapElsCss({
              els: 'padding-right-3x',
              md: 'padding-right-1x1o2',
              lg: 'padding-right-2x'
            })}
          >
            <h2 className="u-els-font-size-h2 u-els-margin-top">{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.CHART_INPUTS })}</h2>
            <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
            <div id="" className="chart-input__section-title chart-input__section-title--first">
              <h4>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.TEMPERATURE })}</h4>
            </div>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.TEMPERATURE_FAHRENHEIT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.TEMPERATURE_CELSIUS)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.TEMPERATURE_SITE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.PULSE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.PULSE_SITE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.RESPIRATION)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <div id="" className="chart-input__section-title">
              <h4>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.BLOOD_PRESSURE })}</h4>
            </div>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_SYSTOLIC)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_DIASTOLIC)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem className="u-els-margin-right">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_SITE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_POSITION)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <div id="" className="chart-input__section-title">
              <h4>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.OXYGENATION })}</h4>
            </div>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_SATURATION)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_SITE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_SITE_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <Box mt2>
              <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_AMOUNT_PERCENT)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT_OTHER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_AMOUNT_LITER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_LITER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_LITER_OTHER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.NOTE)}
              formSubmittedCount={formSubmittedCount}
              options={{
                labelCss: 'chart-input__section-title'
              }}
            />
            <ChartActions {...chartActionsProps} />
          </div>
        </ELSFlexItem>
        <ELSFlexItem className="o-els-flex-layout__item--grow chart-input__scroll-table-container">
          <div className="chart-input__table-graph-container">
            <VitalSignsHistory chartHistory={chartHistory} deleteHistory={deleteHistory} />
            <Box mt2>
              <VitalSignsChart chartHistory={chartHistory} />
            </Box>
          </div>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  </>
);

VitalSignsView.displayName = 'VitalSignsView';
export default VitalSignsView;

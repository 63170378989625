import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, Section } from './constants';
import BradenScaleView from './BradenScaleView';

class BradenScale extends Component<ChartComponentProps> {
  static displayName = 'BradenScale';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(FormField.BRADEN_SCALE, createFormField({ name: FormField.BRADEN_SCALE, type: FormFieldInputType.SCALE }));

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.BRADEN_SCALE,
      records: [
        {
          sectionTitle: Section.BRADEN_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.BRADEN_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_BRADEN_SCALE_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.BRADEN_SCALE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.BRADEN_SCALE))?.score?.value,
              linkedFormFieldIds: [FormField.SENSORY_PERCEPTION, FormField.MOISTURE, FormField.ACTIVITY, FormField.MOBILITY, FormField.NUTRITION, FormField.FRICTION_SHEAR]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.SENSORY_PERCEPTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.MOISTURE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.ACTIVITY, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.MOBILITY, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.NUTRITION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.FRICTION_SHEAR, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  render() {
    const { formFieldMap } = this.props;

    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps = {
      formFieldMap,
      chartActionsProps,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };
    return <BradenScaleView {...viewProps} />;
  }
}

export { BradenScale as BaseBradenScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(BradenScale);

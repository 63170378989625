import { LanguageKeys } from 'lang';
import { ChartFragment, ChartFragmentRS, DiagnosticTestsOrderEntryRecord } from 'models/api-response';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import Notice from 'components/features/chart/order-entry/shared/Notice';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import DiagnosticTestsSidebar from './DiagnosticTestsSidebar';
import DiagnosticTestsTable from './DiagnosticTestsTable';
import './diagnostic.tests.scss';
import { FormField as CommonFormField } from '../shared/constants';

export interface DiagnosticTestsViewProps extends ChartViewProps {
  chartHistory: DiagnosticTestsOrderEntryRecord[];
  selectedDiagnosticTest: DiagnosticTestsOrderEntryRecord;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  deleteHistory: Function;
  onDiagnosticTestUpdate: Function;
  onStatusClick: Function;
  onSidebarClose: Function;
}

export interface DiagnosticTestsSidebarProps {
  diagnosticTest: ChartFragment;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  onDiagnosticTestUpdate: Function;
  onCloseClick: Function;
}

const DiagnosticTestsView = (props: DiagnosticTestsViewProps) => {
  const {
    chartActionsProps,
    formFieldMap,
    formSubmittedCount,
    chartHistory,
    deleteHistory,
    selectedDiagnosticTest,
    onDiagnosticTestUpdate,
    onStatusClick,
    onSidebarClose,
    saveChartData
  } = props;
  const sidebarProps: DiagnosticTestsSidebarProps = {
    diagnosticTest: selectedDiagnosticTest,
    saveChartData,
    onDiagnosticTestUpdate,
    onCloseClick: onSidebarClose,
    ...appHelper.getChartSharedProps(props)
  };
  return (
    <div className="diagnostic-test-entry">
      <h1>{LanguageKeys.ORDER_DIAGNOSTIC_TESTS.DIAGNOSTIC_TESTS}</h1>

      <Box mt>
        <Notice />
        <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      </Box>

      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o3" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">Chart Inputs</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.CATEGORY)} formSubmittedCount={formSubmittedCount} />

            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_DESCRIPTION_DETAIL)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_PRIORITY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.TRANSPORTATION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.IV_REQUIRED)} options={{ displayHorizontal: true }} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.OXYGEN_REQUIRED)} options={{ displayHorizontal: true }} formSubmittedCount={formSubmittedCount} />

            <h3 className="u-els-margin-bottom u-els-margin-top-2x">Order Start</h3>
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_START_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_START_TIME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="2o3" md="1o1" className="u-els-margin-top">
            <h2>Orders</h2>
            <DiagnosticTestsTable chartHistory={chartHistory} deleteHistory={deleteHistory} onStatusClick={onStatusClick} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      <DiagnosticTestsSidebar {...sidebarProps} />
    </div>
  );
};

DiagnosticTestsView.displayName = 'DiagnosticTestsView';
export default DiagnosticTestsView;

import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, NutritionOrderEntryRecord, Section } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { DateFormatByLocale } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { formatDate } from 'helpers/datetime.helper';
import { chartService } from 'services';
import { appActions } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import NutritionOrderEntryView, { NutritionOrderEntryViewProps } from './NutritionOrderEntryView';
import { getNutritionOrderFormFieldBuilderItems } from './helpers';
import { FormField as CommonFormField } from '../shared/constants';

export interface NutritionOrderEntryProps extends ChartComponentProps {
  updatePatient: Function;
  enableMultiStepsAuthoring: Function;
}

interface NutritionOrderEntryState {
  fragments: ChartFragment[];
  chartHistory: NutritionOrderEntryRecord[];
}

class NutritionOrderEntry extends Component<NutritionOrderEntryProps, NutritionOrderEntryState> {
  static displayName = 'NutritionOrderEntry';

  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: []
    };
  }

  componentDidMount() {
    this.loadNutritionOrderEntryData();
    this.props.enableMultiStepsAuthoring(true);
  }

  loadNutritionOrderEntryData = () => {
    const { initState, loadChartData, locale, updatePatient } = this.props;

    initState(this.buildDefaultFormFields());

    return appHelper.useLoader(
      loadChartData().then(({ data }) => {
        const records = data.map(({ fragmentId: id, chartData, ...rest }) => {
          const orderDate = chartData[FormField.DATE_FIELD];
          // Date record being saved with the formatted date to the db, reformat by safety parsing.
          const formattedOrderDate = formatDate({
            date: moment(orderDate, Object.values(DateFormatByLocale)).toDate(),
            locale
          });
          const orderTime = `${formattedOrderDate} ${chartData[FormField.TIME_FIELD]}`;
          return { ...rest, ...chartData, id, orderTime };
        });
        const nutritionForPatient = records?.map((record) => ({
          orderDescription: record.orderDescription,
          orderDetail: record.orderDetail
        }));

        this.setState({ fragments: data, chartHistory: records });
        updatePatient({ nutritionRecords: nutritionForPatient });
      })
    );
  };

  deleteHistory = (record) => {
    const deleteFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(this.props.deleteChartData(deleteFragment).then(this.loadNutritionOrderEntryData).then(this.props.showDeleteSuccess));
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { isAuthor, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getNutritionOrderFormFieldBuilderItems({ isAuthor }).forEach(({ isHidden, name, label, ...item }) => {
      if (!isHidden) {
        const translatedLabel = label && intl.formatMessage({ id: label });
        dataMap.set(name, createFormField({ ...item, name, label: translatedLabel }));
      }
    });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.NUTRITION, fields: [FormField.NUTRITION_TYPE], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.DATE_TIME_FIELD,
        fields: [FormField.DATE_FIELD, FormField.TIME_FIELD, CommonFormField.ORDER_START_TIME_OFFSET],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.DIET_TYPE,
        fields: [FormField.DIET_TYPE, FormField.TUBE_FEEDING, FormField.CONSISTENCY, FormField.NOTHING_BY_MOUNT, FormField.SPECIAL_DIET, FormField.FORMULA],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.FLUID_RESTRICTION,
        fields: [FormField.DAY_SHIFT, FormField.EVENING_SHIFT, FormField.NIGHT_SHIFT, FormField.TOTAL_HOUR],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.CALORIE_COUNT,
        fields: [FormField.CALORIE_COUNT],
        formFieldMap
      })
    ];
  };

  getDataFromDietType = (recordsErrorFormField: Section) => {
    const orderDescription = recordsErrorFormField?.records[0]?.content;
    let orderDetail = '';
    if (typeof recordsErrorFormField?.records[1]?.content === 'boolean') {
      orderDetail = recordsErrorFormField?.records[1]?.title || '';
    } else {
      orderDetail = recordsErrorFormField?.records[1]?.content || '';
    }
    return { orderDescription, orderDetail };
  };

  getDataFromFluidRestriction = (recordsErrorFormField: Section) => {
    const orderDescription = recordsErrorFormField?.sectionTitle;
    const { dayShift, eveningShift, nightShift, totalHour } = this.calculate24HourTotal();
    const orderDetail = `Day Shift: ${dayShift} mL, Evening Shift: ${eveningShift} mL, Night Shift: ${nightShift} mL, 24 Hour Total: ${totalHour} mL`;
    return { orderDescription, orderDetail };
  };

  getDataFromCalorieCount = (recordsErrorFormField: Section) => {
    const orderDescription = recordsErrorFormField?.sectionTitle;
    const orderDetail = recordsErrorFormField?.records[0]?.content || '';
    return { orderDescription, orderDetail };
  };

  handleSaveClick = () => {
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });

    const record = {
      chartTitle: 'Order Entry',
      fragmentTitle: SectionTitle.NUTRITION,
      records: this.createSections()
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const { records } = cleanRecord;

    const getDataFormFieldFunctionMap = {
      diet: this.getDataFromDietType,
      fluidRestriction: this.getDataFromFluidRestriction,
      calorieCount: this.getDataFromCalorieCount
    };

    const nutritionSection = records.find((section) => section.sectionTitle === SectionTitle.NUTRITION);
    const orderStartDateTimeSection = records.find((section) => section.sectionTitle === SectionTitle.DATE_TIME_FIELD);
    const nutritionTypeSection = records.find((section) => [SectionTitle.DIET_TYPE, SectionTitle.FLUID_RESTRICTION, SectionTitle.CALORIE_COUNT].includes(section.sectionTitle));

    const nutritionTypeValue = nutritionSection.records.find((fieldRecord) => fieldRecord.formField === FormField.NUTRITION_TYPE).value;

    const { orderDescription, orderDetail } = getDataFormFieldFunctionMap[nutritionTypeValue](nutritionTypeSection);

    const orderStartDateValue = orderStartDateTimeSection?.records.find((fieldRecord) => fieldRecord.formField === FormField.DATE_FIELD)?.value;
    const orderStartTimeValue = orderStartDateTimeSection?.records.find((fieldRecord) => fieldRecord.formField === FormField.TIME_FIELD)?.value;

    const chartData = {
      orderDescription,
      orderDetail,
      isHold: false,
      isDiscontinued: false,
      [FormField.NUTRITION_TYPE]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.NUTRITION, FormField.NUTRITION_TYPE)[0]?.content,
      [FormField.DATE_FIELD]: orderStartDateValue,
      [FormField.TIME_FIELD]: orderStartTimeValue,
      [CommonFormField.ORDER_START_TIME_OFFSET]: chartHelper.getFragmentRecords(
        { chartData: cleanRecord },
        SectionTitle.DATE_TIME_FIELD,
        CommonFormField.ORDER_START_TIME_OFFSET
      )[0]?.value,
      [FormField.DIET_TYPE]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.DIET_TYPE, FormField.DIET_TYPE)[0]?.content,
      [FormField.CONSISTENCY]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.DIET_TYPE, FormField.CONSISTENCY)[0]?.content,
      [FormField.NOTHING_BY_MOUNT]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.DIET_TYPE, FormField.NOTHING_BY_MOUNT).map((item) => item.title),
      [FormField.TUBE_FEEDING]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.DIET_TYPE, FormField.TUBE_FEEDING),
      [FormField.FORMULA]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.DIET_TYPE, FormField.FORMULA),
      [FormField.SPECIAL_DIET]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.DIET_TYPE, FormField.SPECIAL_DIET),
      [FormField.DAY_SHIFT]: chartHelper.getFragmentValue({ chartData: cleanRecord }, SectionTitle.FLUID_RESTRICTION, FormField.DAY_SHIFT),
      [FormField.NIGHT_SHIFT]: chartHelper.getFragmentValue({ chartData: cleanRecord }, SectionTitle.FLUID_RESTRICTION, FormField.NIGHT_SHIFT),
      [FormField.EVENING_SHIFT]: chartHelper.getFragmentValue({ chartData: cleanRecord }, SectionTitle.FLUID_RESTRICTION, FormField.EVENING_SHIFT),
      [FormField.TOTAL_HOUR]: chartHelper.getFragmentValue({ chartData: cleanRecord }, SectionTitle.FLUID_RESTRICTION, FormField.TOTAL_HOUR),
      [FormField.CALORIE_COUNT]: chartHelper.getFragmentRecords({ chartData: cleanRecord }, SectionTitle.FLUID_RESTRICTION, FormField.CALORIE_COUNT)[0]?.content
    };

    this.props.saveChart([{ ...basicInfo, chartData }], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.afterSave });
  };

  afterSave = () => {
    this.loadNutritionOrderEntryData().then(() => {
      if (this.props.backToSourceLocation) {
        this.props.backToSourceLocation();
      }
    });
  };

  getValueFromInputHourShift = (formFieldId: string): number => {
    const { formFieldMap } = this.props;
    return formFieldMap.get(formFieldId)?.errors.length === 0 ? parseInt(this.props.formFieldMap.get(formFieldId).value, 10) : 0;
  };

  calculate24HourTotal = () => {
    const dayShift = this.getValueFromInputHourShift(FormField.DAY_SHIFT);
    const eveningShift = this.getValueFromInputHourShift(FormField.EVENING_SHIFT);
    const nightShift = this.getValueFromInputHourShift(FormField.NIGHT_SHIFT);
    const totalHour = dayShift + eveningShift + nightShift;
    return { dayShift, eveningShift, nightShift, totalHour };
  };

  render() {
    const { formFieldMap } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: NutritionOrderEntryViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      isDietTypeHide: formFieldMap.get(FormField.DIET_TYPE)?.hide,
      isFluidRestrictionHide: formFieldMap.get(FormField.DAY_SHIFT)?.hide,
      isCalorieCountHide: formFieldMap.get(FormField.CALORIE_COUNT)?.hide,
      totalHourValue: this.calculate24HourTotal().totalHour,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory
    };
    return <NutritionOrderEntryView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (newPatientData) => dispatch(appActions.updatePatient(newPatientData))
});

const enhancers = [withSavedPatientChartsPage, withChartLogic, connect(null, mapDispatchToProps)];

export { NutritionOrderEntry as BaseNutritionOrderEntry };
export default compose(...enhancers)(NutritionOrderEntry);

import { Component } from 'react';
import { v4 } from 'uuid';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField } from './constants';
import PreviousPregnanciesSidebarView from './PreviousPregnanciesSidebarView';

interface PreviousPregnanciesSidebarProps extends ChartComponentProps {
  isSidebarOpen: boolean;
  addPregnancyInfo: Function;
  handleCloseSidebar: Function;
}
export class PreviousPregnanciesSidebar extends Component<PreviousPregnanciesSidebarProps> {
  static displayName = 'PreviousPregnanciesSidebar';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = () => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const textBoxes = [
      { name: FormField.HOW_LONG_AGO, label: 'How long ago?' },
      { name: FormField.GESTATIONAL_WEEKS, label: 'Gestational weeks' },
      { name: FormField.DURATION_OF_LABOR, label: 'Duration of labor' },
      { name: FormField.COMPLICATIONS, label: 'Complications' },
      { name: FormField.OUTCOME, label: 'Outcome' }
    ];
    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    dataMap.set(FormField.TYPE_OF_DELIVERY, createFormField({ name: FormField.TYPE_OF_DELIVERY, type: FormFieldInputType.DROPDOWN, label: 'Type of delivery' }));
    return dataMap;
  };

  buildFragment = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;
    return {
      id: v4(),
      active: true,
      [FormField.HOW_LONG_AGO]: buildPatientRecord(formFieldMap, FormField.HOW_LONG_AGO),
      [FormField.GESTATIONAL_WEEKS]: buildPatientRecord(formFieldMap, FormField.GESTATIONAL_WEEKS),
      [FormField.TYPE_OF_DELIVERY]: buildPatientRecord(formFieldMap, FormField.TYPE_OF_DELIVERY),
      [FormField.DURATION_OF_LABOR]: buildPatientRecord(formFieldMap, FormField.DURATION_OF_LABOR),
      [FormField.COMPLICATIONS]: buildPatientRecord(formFieldMap, FormField.COMPLICATIONS),
      [FormField.OUTCOME]: buildPatientRecord(formFieldMap, FormField.OUTCOME)
    };
  };

  handleSaveClick = () => {
    this.props.addPregnancyInfo(this.buildFragment());
  };

  render() {
    const chartActionsProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' }),
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      isSidebarOpen: this.props.isSidebarOpen,
      onCloseClick: this.props.handleCloseSidebar,
      sidebarProps: this.props.sidebarProps
    };
    return <PreviousPregnanciesSidebarView {...viewProps} />;
  }
}

export { PreviousPregnanciesSidebar as BasePreviousPregnanciesSidebar };
export default withChartLogic(PreviousPregnanciesSidebar);

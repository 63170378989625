export enum FormField {
  INTEGUMENT = 'integument',
  HEENT = 'HEENT',
  CARDIOVASCULAR = 'cardiovascular',
  RESPIRATORY = 'respiratory',
  GASTROINTESTINAL = 'gastrointestinal',
  GENITOURINARY = 'genitourinary',
  MUSCULOSKELETAL = 'musculoskeletal',
  NEUROLOGIC = 'neurologic',
  DEVELOPMENTAL = 'developmental',
  ENDOCRINE = 'endocrine',
  GENITALIA = 'genitalia',
  LYMPHATIC = 'lymphatic'
}

export enum FormFieldLabel {
  INTEGUMENT = 'Integument:',
  HEENT = 'HEENT:',
  CARDIOVASCULAR = 'Cardiovascular:',
  RESPIRATORY = 'Respiratory:',
  GASTROINTESTINAL = 'Gastrointestinal:',
  GENITOURINARY = 'Genitourinary:',
  MUSCULOSKELETAL = 'Musculoskeletal:',
  NEUROLOGIC = 'Neurologic:',
  DEVELOPMENTAL = 'Developmental:',
  ENDOCRINE = 'Endocrine:',
  GENITALIA = 'Genitalia:',
  LYMPHATIC = 'Lymphatic:'
}

export enum SectionTitle {
  REVIEW_OF_SYSTEMS = 'Review of Systems'
}

export class JsonToYamlConverterService {
  public static json2yaml(json): string {
    const ret = [];
    JsonToYamlConverterService.convert(json, ret);
    return ret.join('\n');
  }

  private static getType(obj) {
    const type = typeof obj;

    if (obj instanceof Array) {
      return 'array';
    }
    if (type === 'string') {
      return 'string';
    }
    if (type === 'boolean') {
      return 'boolean';
    }
    if (type === 'number') {
      return 'number';
    }
    if (type === 'undefined' || obj === null) {
      return 'null';
    }
    return 'hash';
  }

  private static convert(obj, ret) {
    const type = JsonToYamlConverterService.getType(obj);

    switch (type) {
      case 'array':
        JsonToYamlConverterService.convertArray(obj, ret);
        break;
      case 'hash':
        JsonToYamlConverterService.convertHash(obj, ret);
        break;
      case 'string':
        JsonToYamlConverterService.convertString(obj, ret);
        break;
      case 'null':
        ret.push('null');
        break;
      case 'number':
        ret.push(obj.toString());
        break;
      case 'boolean':
        ret.push(obj ? 'true' : 'false');
        break;
      default:
    }
  }

  private static convertArray(obj, ret) {
    if (obj.length === 0) {
      ret.push('[]');
    }
    obj.forEach((objItem) => {
      const ele = objItem;
      const recurse = [];
      JsonToYamlConverterService.convert(ele, recurse);

      for (let j = 0; j < recurse.length; j += 1) {
        ret.push((j === 0 ? '- ' : '  ') + recurse[j]);
      }
    });
  }

  private static convertHash(obj, ret) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const k in obj) {
      const recurse = [];
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(k)) {
        const ele = obj[k];
        JsonToYamlConverterService.convert(ele, recurse);
        const type = JsonToYamlConverterService.getType(ele);
        if (type === 'string' || type === 'null' || type === 'number' || type === 'boolean') {
          ret.push(`${JsonToYamlConverterService.normalizeString(k)}: ${recurse[0]}`);
        } else {
          ret.push(`${JsonToYamlConverterService.normalizeString(k)}: `);
          recurse.forEach((item) => ret.push(`  ${item}`));
        }
      }
    }
  }

  private static normalizeString(str) {
    if (str.match(/^#/)) {
      return JSON.stringify(str);
    }
    return str;
  }

  private static convertString(obj, ret) {
    ret.push(JsonToYamlConverterService.normalizeString(obj));
  }
}

import { DiagnosisFragment, DiagnosticTestFragment, LaboratoryTestFragment, MedicationFragment } from 'models/api-response';
import { Box } from 'components/common';
import { ELSTab, ELSTabGroup } from 'components/common/els';
import FullReport from './FullReport';
import { Tabs } from './Report';
import SummaryReport from './SummaryReport';

export interface ReportViewProps {
  activeTab: Tabs;
  patientInfo: string;
  studentInfo: string;
  diagnosisRecords: DiagnosisFragment[];
  medicationRecords: MedicationFragment[];
  laboratoryTestRecords: LaboratoryTestFragment[];
  diagnosticTestsRecords: DiagnosticTestFragment[];
  onTabGroupClick: Function;
}
const ReportView = (props: ReportViewProps) => (
  <div className="pre-clinical-manager__page">
    <Box mt mb2>
      <ELSTabGroup onChangeActiveIndex={() => props.onTabGroupClick(props.activeTab)} activeIndex={props.activeTab} ariaLabel="Report">
        <ELSTab title="Summary">
          <SummaryReport
            patientInfo={props.patientInfo}
            studentInfo={props.studentInfo}
            diagnosisRecords={props.diagnosisRecords}
            medicationRecords={props.medicationRecords}
            diagnosticTestsRecords={props.diagnosticTestsRecords}
            laboratoryTestRecords={props.laboratoryTestRecords}
          />
        </ELSTab>
        <ELSTab title="Full Report">
          <FullReport
            patientInfo={props.patientInfo}
            studentInfo={props.studentInfo}
            diagnosisRecords={props.diagnosisRecords}
            medicationRecords={props.medicationRecords}
            diagnosticTestsRecords={props.diagnosticTestsRecords}
            laboratoryTestRecords={props.laboratoryTestRecords}
          />
        </ELSTab>
      </ELSTabGroup>
    </Box>
  </div>
);

ReportView.displayName = 'ReportView';
export default ReportView;

import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { PatientContext } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { getFragmentValue } from 'helpers/chart.helper';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface MobilityPhysicalTherapyReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
}

const MobilityPhysicalTherapyReadOnly = (props: MobilityPhysicalTherapyReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext } = props;
  const isPhysicalTherapyProblemsNotRequired =
    getFragmentValue(chartFragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS, FormField.PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED) === '';
  const isPhysiotherapistReasonForReferralExist =
    getFragmentValue(chartFragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION, FormField.ADDITIONAL_INFORMATION_PHYSIOTHERAPIST_REASON_FOR_REFERRAL) !== '';
  const isOccupationalTherapistReasonForReferralExist =
    getFragmentValue(chartFragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION, FormField.ADDITIONAL_INFORMATION_OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL) !==
    '';
  return (
    <Box className="mobility-physical-therapy-read-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Mobility and Physical Therapy Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              {patientContext.locale === Locales.EN_AU && (
                <>
                  <ELSFlexItem w="1o3" md="1o1">
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH}
                      formFieldId={FormField.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH}
                      customTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH}
                      customHeadingLevel={3}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o3" md="1o1">
                    <ELSFlex wrap left gutters className="o-els-flex-layout--gutters-1x1o2">
                      <ELSFlexItem w="1o1">
                        <ReadOnlyInput
                          chartFragment={chartFragment}
                          sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK}
                          formFieldId={FormField.FALLS_RISK_PATIENT}
                          customTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK}
                          customHeadingLevel={3}
                        />
                      </ELSFlexItem>
                      <ELSFlexItem w="1o1">
                        <ReadOnlyInput
                          chartFragment={chartFragment}
                          sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK}
                          formFieldId={FormField.FALLS_RISK_ADDITIONAL_COMMENTS}
                          customTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_COMMENTS}
                        />
                      </ELSFlexItem>
                    </ELSFlex>
                  </ELSFlexItem>

                  <ELSFlexItem w="1o3" md="1o1">
                    <ELSFlex wrap left gutters className="o-els-flex-layout--gutters-1x1o2">
                      <ELSFlexItem w="1o1">
                        <ReadOnlyInput
                          chartFragment={chartFragment}
                          sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION}
                          formFieldId={FormField.ADDITIONAL_INFORMATION}
                          customTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION}
                          customHeadingLevel={3}
                        />
                      </ELSFlexItem>
                      {isPhysiotherapistReasonForReferralExist && (
                        <ELSFlexItem w="1o1">
                          <ReadOnlyInput
                            chartFragment={chartFragment}
                            sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION}
                            formFieldId={FormField.ADDITIONAL_INFORMATION_PHYSIOTHERAPIST_REASON_FOR_REFERRAL}
                            customTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSIOTHERAPIST_REASON_FOR_REFERRAL}
                          />
                        </ELSFlexItem>
                      )}
                      {isOccupationalTherapistReasonForReferralExist && (
                        <ELSFlexItem w="1o1">
                          <ReadOnlyInput
                            chartFragment={chartFragment}
                            sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION}
                            formFieldId={FormField.ADDITIONAL_INFORMATION_OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL}
                            customTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL}
                          />
                        </ELSFlexItem>
                      )}
                    </ELSFlex>
                  </ELSFlexItem>
                </>
              )}

              {patientContext.locale !== Locales.EN_AU && (
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.MOBILITY_PHYSICAL_THERAPY_SCREEN}
                    formFieldId={FormField.ORDERS_PHYSICAL_THERAPY}
                    customTitle="Orders Physical Therapy"
                  />
                </ELSFlexItem>
              )}

              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS}
                  formFieldId={!isPhysicalTherapyProblemsNotRequired ? FormField.PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED : FormField.PHYSICAL_THERAPY_PROBLEMS_SELECT}
                  customTitle="New Physical Therapy Problems"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS}
                  formFieldId={FormField.PHYSICAL_THERAPY_PROBLEMS_CONDITION}
                  customTitle="Physical Therapy Condition"
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

MobilityPhysicalTherapyReadOnly.displayName = 'MobilityPhysicalTherapyReadOnly';
export default MobilityPhysicalTherapyReadOnly;

import { dateTimeHelper } from 'helpers';
import BaseValidator, { ExecuteParams } from './interface';

export default class PastDateValidator implements BaseValidator {
  isPastDate: boolean;

  constructor(isPastDate = false) {
    this.isPastDate = isPastDate;
  }

  execute({ field }: ExecuteParams): boolean {
    this.isPastDate = dateTimeHelper.isPastDate(field.value);
    return this.isPastDate;
  }
}

export enum SectionTitle {
  CARDIOVASCULAR_PERIPHERAL_INTERVENTIONS = 'Cardiovascular - Peripheral Vascular Interventions',
  CARDIOVASCULAR_PERIPHERAL_CARE = 'Cardiovascular - Peripheral Vascular Care',
  CARDIOVASCULAR_CARE = 'Cardiovascular Care',
  DVT_SUPPRESSION = 'Deep Vein Thrombosis (DVT) Suppression',
  DRAIN_OR_TUBE_CARE = 'Drain or Tube Care',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Cardiovascular - Peripheral Vascular Care Notes'
}

export enum FormField {
  NO_CARE = 'noCareRequired',
  CARDIOVASCULAR_CARE = 'cardiovascularCare',
  DVT_SUPPRESSION = 'deepVeinThrombosisSuppression',
  DRAIN_OR_TUBE_CARE = 'drainOrTubeCare',
  PATIENT_RESPONSE = 'patientResponse',
  CARE_NOTES = 'cardioVascularCareNotes'
}

import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';

export enum FormField {
  SUMMARY_SITUATION = 'summaryOfSituation',
  PERTINENT_BACKGROUND_SITUATION = 'pertinentBackgroundSituation',
  ASSESSMENT_SITUATION = 'assessmentSituation',
  REC_REQUEST_FOR_ORDERS = 'recRequestForOrders',
  IDENTIFY_INTRODUCE = 'indentifyIntroduce',
  OBSERVATIONS_MOST_RECENT = 'observationsMostRecent'
}

export const sbarReportFormFieldBuilderItems = (locale: Locales): ChartMetaFormFieldParams[] => [
  {
    name: FormField.SUMMARY_SITUATION,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SBAR_REPORT.SUMMARY_OF_THE_SITUATION
  },
  {
    name: FormField.PERTINENT_BACKGROUND_SITUATION,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SBAR_REPORT.PERTINENT_BACKGROUND_OF_THE_SITUATION
  },
  {
    name: FormField.ASSESSMENT_SITUATION,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SBAR_REPORT.ASSESSMENT_OF_THE_SITUATION
  },
  {
    name: FormField.REC_REQUEST_FOR_ORDERS,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SBAR_REPORT.RECOMMENDATIONS_AND_OR_REQUEST_FOR_ORDERS
  },
  {
    name: FormField.IDENTIFY_INTRODUCE,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SBAR_REPORT.INTRODUCE_SELF_AND_INTRODUCE_PATIENT,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.OBSERVATIONS_MOST_RECENT,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SBAR_REPORT.MOST_RECENT_VITAL_SIGNS_INTRAVENOUS_LINES_OTHER_DEVICES,
    isHidden: locale !== Locales.EN_AU
  }
];

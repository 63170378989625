import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { LoadableChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

export interface DrainsTubesViewProps extends LoadableChartViewProps {
  isTracheostomyVisible?: boolean;
}

const DrainsTubesView = (props?: DrainsTubesViewProps) => {
  const { fragment, formFieldMap, formSubmittedCount } = props;
  return (
    <div className="drains-tubes">
      <h1>Drains - Tubes</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.DISCONTINUED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.LOCATION)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_TUBE_TYPE)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider mt />
      {props.isTracheostomyVisible && (
        <>
          <Box mb>
            <h4>{SectionTitle.TRACHEOSTOMY}</h4>
          </Box>
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.TRACHEOSTOMY_TYPE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ELSFlex left middle>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.TRACHEOSTOMY_SIZE)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-bottom-1x"
                  useDefaultMargin={false}
                />
                <span className="u-els-margin-left-1o2">mm</span>
              </ELSFlex>
              <ErrorFormField formField={formFieldMap.get(FormField.TRACHEOSTOMY_CUFF)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            </ELSFlexItem>
          </ELSFlex>
        </>
      )}
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.TUBE_PLACEMENT}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.TUBE_PLACEMENT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.SUCTION}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.SUCTION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.DRAIN_TUBE_AREA}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_TUBE_AREA)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.DRAINAGE}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAINAGE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.DRAIN_TUBE_PATENCY}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_TUBE_PATENCY)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.PATIENT_RESPONSE}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.DRAIN_TUBE_CARE}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_TUBE_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_TUBE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

DrainsTubesView.displayName = 'DrainsTubesView';
export default DrainsTubesView;

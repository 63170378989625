import cx from 'classnames';
import moment from 'moment';
import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, Section, buildScaleRollUps } from './constants';

export interface BallardMaturationalAssessmentViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  isEmbeddedChart?: boolean;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const BallardMaturationalAssessmentView = (props: BallardMaturationalAssessmentViewProps) => {
  const { formFieldMap, formSubmittedCount, isEmbeddedChart } = props;
  const totalNeuromuscularMaturityScore = formFieldMap
    .get(FormField.NEUROMUSCULAR_MATURITY)
    ?.chartContent.find((item) => item.formFieldId === FormField.NEUROMUSCULAR_MATURITY_ROLL_UP)?.score.value;
  const totalPhysicalMaturityScore = formFieldMap.get(FormField.PHYSICAL_MATURITY)?.chartContent.find((item) => item.formFieldId === FormField.PHYSICAL_MATURITY_ROLL_UP)
    ?.score.value;
  const totalScore = totalNeuromuscularMaturityScore || totalPhysicalMaturityScore ? Number(totalNeuromuscularMaturityScore) + Number(totalPhysicalMaturityScore) : NaN;
  const scaleRollUps = buildScaleRollUps(totalScore);
  return (
    <div className="ballard-maturational-assessment">
      <h1 className="u-els-margin-top">Ballard Maturational Assessment</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} isEmbeddedChart={isEmbeddedChart} />
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BIRTH_WEIGHT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.DAY_OF_BIRTH)}
            formSubmittedCount={formSubmittedCount}
            options={{ disableBefore: moment().toDate() }}
          />
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.DAY_OF_EXAM)}
            formSubmittedCount={formSubmittedCount}
            options={{ disableBefore: moment().toDate() }}
          />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.AGE_WHEN_EXAMINED)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.APGAR_SCORE_1_MINUTE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.APGAR_SCORE_5_MINUTE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.APGAR_SCORE_10_MINUTE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.LENGTH)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TIME_OF_BIRTH)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TIME_OF_EXAM)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2>Gestational Age</h2>
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BY_WEEKS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BY_ULTRASOUND)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BY_DATES)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BY_EXAM)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <Box mt>
        <h2>{Section.NEUROMUSCULAR_MATURITY}</h2>
      </Box>
      <ErrorFormField
        className="u-els-margin-top"
        formField={formFieldMap.get(FormField.NEUROMUSCULAR_MATURITY)}
        formSubmittedCount={formSubmittedCount}
        formFieldMap={formFieldMap}
      />
      <Box mt>
        <h2>{Section.PHYSICAL_MATURITY}</h2>
      </Box>
      <ErrorFormField
        className="u-els-margin-top"
        formField={formFieldMap.get(FormField.PHYSICAL_MATURITY)}
        formSubmittedCount={formSubmittedCount}
        options={{ showScoreInAnswer: true }}
        formFieldMap={formFieldMap}
      />
      <SectionDivider />
      <div className="scale-wrapper">
        <ELSFlex spaceBetween wrap>
          <ELSFlexItem middle>
            <h2>Total Score</h2>
          </ELSFlexItem>
          <ELSFlexItem middle>
            <span className="u-els-bold score-tag">{`Total Score: ${totalScore || 0}`}</span>
          </ELSFlexItem>
        </ELSFlex>
        <Box mt>
          {scaleRollUps.map((item) => (
            <Box key={item.content} className={cx('roll-up-content', { 'scale-roll-up--highlight': item.highlight })}>
              <p>
                <span className="special-character">•</span>
                <span className="roll-up-text">{item.content}</span>
              </p>
            </Box>
          ))}
        </Box>
      </div>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

BallardMaturationalAssessmentView.displayName = 'BallardMaturationalAssessmentView';
export default BallardMaturationalAssessmentView;

import React, { Component } from 'react';
import { withHTMLHeadSEO } from 'components/common';
import { MetaTypes, toObject, toYaml } from './ConverterService';
import { ChartFieldContentMeta, ChartFieldMeta, ChartFieldValidationMeta, PatientContextContent, PatientContextValidation } from './MetadataInput';
import MetadataToolHomePageView, { MetadataToolHomePageViewProps } from './MetadataToolHomePageView';

export interface MetadataToolState {
  chartFields: ChartFieldMeta[];
  chartFieldContent: ChartFieldContentMeta[];
  chartFieldValidations: ChartFieldValidationMeta[];
  chartFieldSelected: ChartFieldMeta;
  contentSelected: ChartFieldContentMeta;
  validationSelected: ChartFieldValidationMeta;
  isSideBarContentOpen: boolean;
  isSideBarValidationOpen: boolean;
}

class MetadataToolHomePage extends Component<any, MetadataToolState> {
  constructor(props) {
    super(props);
    this.state = {
      chartFields: [],
      chartFieldContent: [],
      chartFieldValidations: [],
      chartFieldSelected: null,
      contentSelected: null,
      validationSelected: null,
      isSideBarContentOpen: false,
      isSideBarValidationOpen: false
    };
  }
  onSaveChartField = (chartField: ChartFieldMeta) => {
    const chartFieldArray: ChartFieldMeta[] = Object.assign([], this.state.chartFields);
    let chartFieldToSave;
    // check if chart field exists
    if (this.state.chartFieldSelected) {
      chartFieldToSave = chartFieldArray.find((item) => item.id === this.state.chartFieldSelected.id);
    }
    if (!chartFieldToSave) {
      chartFieldArray.push(chartField);
      this.setState((state) => ({ ...state, chartFields: chartFieldArray }));
    } else {
      const chartFieldIndex = chartFieldArray.findIndex((item) => item.id === this.state.chartFieldSelected.id);
      chartFieldArray[chartFieldIndex].id = chartField.id;
      chartFieldArray[chartFieldIndex].navigationElement.id = chartField.navigationElement.id;
      chartFieldArray[chartFieldIndex].formFieldId = chartField.formFieldId;
      chartFieldArray[chartFieldIndex].isDataPoint = chartField.isDataPoint;
      this.setState((state) => ({ ...state, chartFields: chartFieldArray }));
    }
  };

  onChartFieldContentSave = (contentMeta: ChartFieldContentMeta) => {
    const contentArray: ChartFieldContentMeta[] = Object.assign([], this.state.chartFieldContent);
    let contentToSave;
    // check if content exists
    if (this.state.contentSelected) {
      contentToSave = contentArray.find((item) => item.id === this.state.contentSelected.id);
    }
    if (!contentToSave) {
      contentArray.push(contentMeta);
      this.setState((state) => ({ ...state, chartFieldContent: contentArray }));
    } else {
      const contentIndex = contentArray.findIndex((item) => item.id === this.state.contentSelected.id);
      contentArray[contentIndex].id = contentMeta.id;
      contentArray[contentIndex].chartField.id = contentMeta.chartField.id;
      contentArray[contentIndex].value = contentMeta.value;
      contentArray[contentIndex].label = contentMeta.label;
      contentArray[contentIndex].order = contentMeta.order;
      contentArray[contentIndex].dataType = contentMeta.dataType;
      this.setState((state) => ({ ...state, chartFieldContent: contentArray }));
    }
  };

  onValidationSave = (validationMeta: ChartFieldValidationMeta) => {
    const validationArray: ChartFieldValidationMeta[] = Object.assign([], this.state.chartFieldValidations);
    let validationToSave;

    // check if validation exists
    if (this.state.validationSelected) {
      validationToSave = validationArray.find((item) => item.id === this.state.validationSelected.id);
    }
    if (!validationToSave) {
      validationArray.push(validationMeta);
      this.setState((state) => ({ ...state, chartFieldValidations: validationArray }));
    } else {
      const validationIndex = validationArray.findIndex((item) => item.id === this.state.validationSelected.id);
      validationArray[validationIndex].id = validationMeta.id;
      validationArray[validationIndex].chartField.id = validationMeta.chartField.id;
      validationArray[validationIndex].validationType = validationMeta.validationActionType;
      validationArray[validationIndex].message = validationMeta.message;
      validationArray[validationIndex].minRange = validationMeta.minRange;
      validationArray[validationIndex].maxRange = validationMeta.maxRange;
      validationArray[validationIndex].regex = validationMeta.regex;
      this.setState((state) => ({ ...state, chartFieldValidations: validationArray }));
    }
  };

  toYaml = () => {
    const meta = {
      chartFields: this.state.chartFields,
      chartFieldContent: this.state.chartFieldContent,
      chartFieldValidations: this.state.chartFieldValidations
    };
    toYaml(meta);
  };

  onContentRowClick = (contentId) => {
    const selected = this.state.chartFieldContent.find((content) => content.id === contentId);
    this.setState((state) => ({
      contentSelected: {
        ...state.contentSelected,
        id: selected.id,
        chartField: {
          id: selected.chartField.id
        },
        value: selected.value,
        label: selected.label,
        order: selected.order,
        dataType: selected.dataType
      }
    }));
  };

  onChartFieldRowClick = (id) => {
    const selected = this.state.chartFields.find((chartField) => chartField.id === id);
    this.setState((state) => ({
      ...state,
      chartFieldSelected: {
        ...state.chartFieldSelected,
        id: selected.id,
        navigationElement: selected.navigationElement,
        formFieldId: selected.formFieldId,
        isDataPoint: selected.isDataPoint
      }
    }));
  };

  onValidationRowClick = (validationId) => {
    const selected = this.state.chartFieldValidations.find((validation) => validation.id === validationId);
    this.setState((state) => ({
      ...state,
      validationSelected: {
        ...state.validationSelected,
        id: selected.id,
        chartField: {
          id: selected.chartField.id
        },
        validationType: selected.validationType,
        validationActionType: selected.validationActionType,
        message: selected.message,
        parentValidation: {
          id: selected?.parentValidation?.id ? selected?.parentValidation?.id : null
        },
        minRange: selected.minRange,
        maxRange: selected.maxRange,
        regex: selected.regex
      }
    }));
  };

  openContentSideBar = (contentId) => {
    const selected = this.state.chartFieldContent.find((content) => content.id === contentId);
    this.setState(() => ({ isSideBarContentOpen: true, contentSelected: selected }));
  };

  closeContentSideBar = () => {
    this.setState({ isSideBarContentOpen: false });
  };

  openValidationSideBar = (validationId) => {
    const selected = this.state.chartFieldValidations.find((validation) => validation.id === validationId);
    this.setState(() => ({ isSideBarValidationOpen: true, validationSelected: selected }));
  };

  closeValidationSideBar = () => {
    this.setState({ isSideBarValidationOpen: false });
  };

  onChartFieldRemoveClick = (id) => {
    this.setState((state) => ({ chartFields: state.chartFields.filter((item) => item.id !== id), chartFieldSelected: null }));
  };

  onContentRemoveClick = (id) => {
    this.setState((state) => ({ chartFieldContent: state.chartFieldContent.filter((item) => item.id !== id), contentSelected: null }));
  };

  onValidationRemoveClick = (id) => {
    this.setState((state) => ({ chartFieldValidations: state.chartFieldValidations.filter((item) => item.id !== id), validationSelected: null }));
  };

  onContentContextRemoveClick = (condition) => {
    const attributeToRemove = this.state.chartFieldContent.find((item) => item.id === this.state.contentSelected.id);
    attributeToRemove.attrOverrides = attributeToRemove.attrOverrides.filter((item) => item.condition !== condition);
    this.onChartFieldContentSave(attributeToRemove);
  };

  onValidationContextRemoveClick = (condition) => {
    const attributeToRemove = this.state.chartFieldValidations.find((item) => item.id === this.state.validationSelected.id);
    attributeToRemove.attrOverrides = attributeToRemove.attrOverrides.filter((item) => item.condition !== condition);
    this.onValidationSave(attributeToRemove);
  };

  importExistingYaml = (eventKey: any) => {
    if (eventKey.target.value !== '') {
      const metaTypes: MetaTypes = toObject(eventKey.target.value);
      this.setState({ chartFields: metaTypes.chartFields, chartFieldContent: metaTypes.chartFieldContent, chartFieldValidations: metaTypes.chartFieldValidations });
    }
  };

  onContentContextSave = (contentContext: PatientContextContent) => {
    const contentArray: ChartFieldContentMeta[] = Object.assign([], this.state.chartFieldContent);
    const contentToSave = this.state.chartFieldContent.find((item) => item.id === this.state.contentSelected.id);
    const contentIndex = this.state.chartFieldContent.findIndex((item) => item.id === this.state.contentSelected.id);

    // if content doesn't have attribute overrides
    if (!contentToSave.attrOverrides) {
      // create a new object that has attribute overrides
      const newContentToSave = {
        id: contentToSave.id,
        chartField: {
          id: contentToSave.chartField.id
        },
        version: contentToSave.version,
        active: contentToSave.active,
        value: contentToSave.value,
        label: contentToSave.label,
        order: contentToSave.order,
        dataType: contentToSave.dataType,
        attrOverrides: []
      };
      newContentToSave.attrOverrides.push(contentContext);
      contentArray[contentIndex].attrOverrides = newContentToSave.attrOverrides;
      this.setState((state) => ({ ...state, chartFieldContent: contentArray }));
    } else {
      contentToSave.attrOverrides.push(contentContext);
      contentArray[contentIndex].attrOverrides = contentToSave.attrOverrides;
      this.setState((state) => ({ ...state, chartFieldContent: contentArray }));
    }
  };

  onValidationContextSave = (validationContext: PatientContextValidation) => {
    const validationArray: ChartFieldValidationMeta[] = Object.assign([], this.state.chartFieldValidations);
    const validationToSave = this.state.chartFieldValidations.find((item) => item.id === this.state.validationSelected.id);
    const validationIndex = this.state.chartFieldValidations.findIndex((item) => item.id === this.state.validationSelected.id);

    // if validation doesn't have attribute overrides
    if (!validationToSave.attrOverrides) {
      // create a new object that has attribute overrides
      const newValidationToSave = {
        id: validationToSave.id,
        chartField: {
          id: validationToSave.chartField.id
        },
        version: validationToSave.version,
        active: validationToSave.active,
        validationType: validationToSave.validationType,
        validationActionType: validationToSave.validationActionType,
        message: validationToSave.message,
        minRange: validationToSave.minRange,
        maxRange: validationToSave.maxRange,
        regex: validationToSave.regex,
        attrOverrides: []
      };
      newValidationToSave.attrOverrides.push(validationContext);
      validationArray[validationIndex].attrOverrides = newValidationToSave.attrOverrides;
      this.setState((state) => ({ ...state, chartFieldValidations: validationArray }));
    } else {
      validationToSave.attrOverrides.push(validationContext);
      validationArray[validationIndex].attrOverrides = validationToSave.attrOverrides;
      this.setState((state) => ({ ...state, chartFieldValidations: validationArray }));
    }
  };

  render() {
    const viewProps: MetadataToolHomePageViewProps = {
      chartFieldData: this.state.chartFields,
      chartFieldContentData: this.state.chartFieldContent,
      chartFieldValidations: this.state.chartFieldValidations,
      onAddChartField: this.onSaveChartField,
      onAddChartFieldContent: this.onChartFieldContentSave,
      onAddValidation: this.onValidationSave,
      metaTypes: {
        chartFields: this.state.chartFields,
        chartFieldContent: this.state.chartFieldContent,
        chartFieldValidations: this.state.chartFieldValidations
      },
      toYaml: this.toYaml,
      importExistingYaml: this.importExistingYaml,
      onContentRowClick: this.onContentRowClick,
      onChartFieldRowClick: this.onChartFieldRowClick,
      onValidationRowClick: this.onValidationRowClick,
      onValidationRemoveClick: this.onValidationRemoveClick,
      onContentRemoveClick: this.onContentRemoveClick,
      onChartFieldRemoveClick: this.onChartFieldRemoveClick,
      chartFieldSelected: this.state.chartFieldSelected,
      contentSelected: this.state.contentSelected,
      validationSelected: this.state.validationSelected,
      isContentSideBarOpen: this.state.isSideBarContentOpen,
      isValidationSideBarOpen: this.state.isSideBarValidationOpen,
      openContentSideBar: this.openContentSideBar,
      closeContentSideBar: this.closeContentSideBar,
      openValidationSideBar: this.openValidationSideBar,
      closeValidationSideBar: this.closeValidationSideBar,
      onContentContextSave: this.onContentContextSave,
      onValidationContextSave: this.onValidationContextSave,
      onContentContextRemoveClick: this.onContentContextRemoveClick,
      onValidationContextRemoveClick: this.onValidationContextRemoveClick
    };
    return (
      <div>
        <MetadataToolHomePageView {...viewProps} />
      </div>
    );
  }
}

export { MetadataToolHomePage as BaseMetadataToolHomePage };
export default withHTMLHeadSEO({ title: 'Metadata Homepage' })(MetadataToolHomePage);

require('assets/img/chevron-right-light.svg');
require('assets/img/chevron-right-light-small.svg');

export enum SIZE {
  SMALL = 'small',
  X_SMALL = 'x-small'
}

interface ChevronRightLightIconProps {
  size?: string;
}

const ChevronRightLightIcon = (props: ChevronRightLightIconProps) => {
  if (props.size === SIZE.SMALL || props.size === SIZE.X_SMALL) {
    const height = props.size === SIZE.SMALL ? '22' : '16';
    return (
      <svg width="13" height={height} preserveAspectRatio="none" aria-hidden="true">
        <use xlinkHref="#chevron-right-light-small" />
      </svg>
    );
  }

  return (
    <svg width="27" height="52" preserveAspectRatio="none" aria-hidden="true">
      <use xlinkHref="#chevron-right-light" />
    </svg>
  );
};

ChevronRightLightIcon.displayName = 'ChevronRightLightIcon';
export default ChevronRightLightIcon;

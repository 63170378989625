import cx from 'classnames';
import { Box, ChartHistory } from 'components/common';
import { RadioRenderer } from './shared';

export interface RemedyProductsTableProps {
  chartHistory: object[];
  changeHandler: Function;
}

const HerbalAndNaturalRemedyProductsTable = (props: RemedyProductsTableProps) => {
  const { chartHistory, changeHandler } = props;
  const css = [
    'column__product',
    'column__preparation',
    'column__strength',
    'column__dose',
    'column__route',
    'column__taken',
    'column__indication',
    'column__admit',
    'column__discharge'
  ];

  const resumeOnAdmitRenderer = (row) => <RadioRenderer row={row} changeHandler={changeHandler} homeMedicationType="remedyProductsData" fieldChangeName="resumeOnAdmit" />;

  const resumeOnDischargeRenderer = (row) => <RadioRenderer row={row} changeHandler={changeHandler} homeMedicationType="remedyProductsData" fieldChangeName="resumeOnDischarge" />;

  const customRenderer = (active, value) => <div className={cx({ 'row--deleted': !active })}>{value}</div>;

  const dataSource = {
    headers: [
      { field: 'product', text: 'Product', customRender: (row) => customRenderer(row.active, row.product) },
      { field: 'preparation', text: 'Preparation', customRender: (row) => customRenderer(row.active, row.preparation) },
      { field: 'strength', text: 'Strength', customRender: (row) => customRenderer(row.active, row.strength) },
      { field: 'herbalAndNaturalRemedyProductsDose', text: 'Dose', customRender: (row) => customRenderer(row.active, row.herbalAndNaturalRemedyProductsDose) },
      { field: 'herbalAndNaturalRemedyProductsRoute', text: 'Route', customRender: (row) => customRenderer(row.active, row.herbalAndNaturalRemedyProductsRoute) },
      {
        field: 'herbalAndNaturalRemedyProductsProductTakenInLast24Hours',
        text: 'Taken in Last 24 Hrs.',
        customRender: (row) => customRenderer(row.active, row.herbalAndNaturalRemedyProductsProductTakenInLast24Hours)
      },
      { field: 'resumeOnAdmit', text: 'Resume on Admit', customRender: (row) => resumeOnAdmitRenderer(row) },
      { field: 'resumeOnDischarge', text: 'Resume on Discharge', customRender: (row) => resumeOnDischargeRenderer(row) }
    ],
    css,
    data: chartHistory
  };
  return (
    <Box mt className="remedy-products-table">
      <ChartHistory dataSource={dataSource} showNumEntries={false} />
    </Box>
  );
};

HerbalAndNaturalRemedyProductsTable.displayName = 'HerbalAndNaturalRemedyProductsTable';
export default HerbalAndNaturalRemedyProductsTable;

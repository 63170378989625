import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';

export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'noAssessmentRequired',
  MENTAL_STATUS_ACTIONS_PLANNED = 'mentalStatusActionsPlanned',
  MENTAL_STATUS_ACTIONS_PLANNED_NOTES = 'mentalStatusActionsPlannedNotes',
  MENTAL_STATUS_ACTIONS_TAKEN = 'mentalStatusActionsTaken',
  MENTAL_STATUS_ACTIONS_TAKEN_NOTES = 'mentalStatusActionsTakenNotes',
  VISION_ACTIONS_PLANNED = 'visionActionsPlanned',
  VISION_ACTIONS_PLANNED_NOTES = 'visionActionsPlannedNotes',
  VISION_ACTIONS_TAKEN = 'visionActionsTaken',
  VISION_ACTIONS_TAKEN_NOTES = 'visionActionsTakenNotes',
  TOILETING_ACTIONS_PLANNED = 'toiletingActionsPlanned',
  TOILETING_ACTIONS_PLANNED_NOTES = 'toiletingActionsPlannedNotes',
  TOILETING_ACTIONS_TAKEN = 'toiletingActionsTaken',
  TOILETING_ACTIONS_TAKEN_NOTES = 'toiletingActionsTakenNotes',
  TOILETING_HAS_FALL_RISK_BEEN_DISCUSSED_AND_INTERVENTION_DEVELOPED = 'toiletingHasFallRiskBeenDiscussedAndInterventionDeveloped',
  TOILETING_COMMENTS = 'toiletingComments',
  FALL_PRECAUTIONS = 'fallPrecautions',
  FALL_PRECAUTIONS_NOTES = 'fallPrecautionsNotes',
  LEVEL_OF_SAFETY_PRECAUTIONS = 'levelOfSafetyPrecautions',
  LEVEL_OF_SAFETY_PRECAUTIONS_NOTES = 'levelOfSafetyPrecautionsNotes',
  NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY = 'nursePatientRelationshipStrategiesForSafety',
  NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_NOTES = 'nursePatientRelationshipStrategiesForSafetyNotes',
  SAFE_IN_MILIEU = 'safetyInMilieu',
  SAFE_IN_MILIEU_NOTES = 'safetyInMilieuNotes',
  GENERAL_SAFETY = 'generalSafety',
  GENERAL_SAFETY_NOTES = 'generalSafetyNotes',
  ROOM_PLAN_GRADATION = 'roomPlanGradation',
  ROOM_PLAN_GRADATION_NOTES = 'roomPlanGradationNotes',
  PATIENT_RESPONSE_TO_SAFETY_MEASURES = 'patientResponseToSafetyMeasures',
  PATIENT_RESPONSE_TO_SAFETY_MEASURES_NOTES = 'patientResponseToSafetyMeasuresNotes'
}

export enum Container {
  FALL_PRECAUTIONS_CONTAINER = 'fallPrecautionsContainer',
  LEVEL_OF_SAFETY_PRECAUTIONS_CONTAINER = 'levelOfSafetyPrecautionsContainer',
  NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_CONTAINER = 'nursePatientRelationshipStrategiesForSafetyContainer',
  SAFE_IN_MILIEU_CONTAINER = 'safetyInMilieuContainer',
  GENERAL_SAFETY_CONTAINER = 'generalSafetyContainer',
  ROOM_PLAN_GRADATION_CONTAINER = 'roomPlanGradationContainer',
  PATIENT_RESPONSE_TO_SAFETY_MEASURES_CONTAINER = 'patientResponseToSafetyMeasuresContainer'
}

export const getSafetyFormFieldBuilderItems = (locale: Locales): ChartMetaFormFieldParams[] => [
  {
    name: FormField.ASSESSMENT_NOT_REQUIRED,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.MENTAL_STATUS_ACTIONS_PLANNED,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.MENTAL_STATUS_ACTIONS_TAKEN,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.VISION_ACTIONS_PLANNED,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.VISION_ACTIONS_TAKEN,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.TOILETING_ACTIONS_PLANNED,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.TOILETING_ACTIONS_TAKEN,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.FALL_PRECAUTIONS,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.GENERAL_SAFETY,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.LEVEL_OF_SAFETY_PRECAUTIONS,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.PATIENT_RESPONSE_TO_SAFETY_MEASURES,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.ROOM_PLAN_GRADATION,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.SAFE_IN_MILIEU,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.TOILETING_HAS_FALL_RISK_BEEN_DISCUSSED_AND_INTERVENTION_DEVELOPED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.SAFETY.HAS_FALL_RISK_BEEN_DISCUSSED_AND_INTERVENTION_DEVELOPED,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.MENTAL_STATUS_ACTIONS_PLANNED_NOTES,
    type: FormFieldInputType.TEXT_AREA,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.MENTAL_STATUS_ACTIONS_TAKEN_NOTES,
    type: FormFieldInputType.TEXT_AREA,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.VISION_ACTIONS_PLANNED_NOTES,
    type: FormFieldInputType.TEXT_AREA,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.VISION_ACTIONS_TAKEN_NOTES,
    type: FormFieldInputType.TEXT_AREA,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.TOILETING_ACTIONS_PLANNED_NOTES,
    type: FormFieldInputType.TEXT_AREA,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.TOILETING_ACTIONS_TAKEN_NOTES,
    type: FormFieldInputType.TEXT_AREA,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.TOILETING_COMMENTS,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.SAFETY.COMMENTS,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.FALL_PRECAUTIONS_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.GENERAL_SAFETY_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.LEVEL_OF_SAFETY_PRECAUTIONS_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.PATIENT_RESPONSE_TO_SAFETY_MEASURES_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.ROOM_PLAN_GRADATION_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.SAFE_IN_MILIEU_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: Container.FALL_PRECAUTIONS_CONTAINER,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: Container.GENERAL_SAFETY_CONTAINER,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: Container.LEVEL_OF_SAFETY_PRECAUTIONS_CONTAINER,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: Container.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_CONTAINER,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: Container.PATIENT_RESPONSE_TO_SAFETY_MEASURES_CONTAINER,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: Container.ROOM_PLAN_GRADATION_CONTAINER,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: Container.SAFE_IN_MILIEU_CONTAINER,
    type: FormFieldInputType.CONTAINER
  }
];

export const sections: {
  container: Container;
  title: string;
  field: FormField;
  note: FormField;
}[] = [
  { container: Container.FALL_PRECAUTIONS_CONTAINER, title: LanguageKeys.SAFETY.FALL_PRECAUTIONS, field: FormField.FALL_PRECAUTIONS, note: FormField.FALL_PRECAUTIONS_NOTES },
  {
    container: Container.LEVEL_OF_SAFETY_PRECAUTIONS_CONTAINER,
    title: LanguageKeys.SAFETY.LEVEL_OF_SAFETY_PRECAUTIONS,
    field: FormField.LEVEL_OF_SAFETY_PRECAUTIONS,
    note: FormField.LEVEL_OF_SAFETY_PRECAUTIONS_NOTES
  },
  {
    container: Container.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_CONTAINER,
    title: LanguageKeys.SAFETY.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY,
    field: FormField.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY,
    note: FormField.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_NOTES
  },
  { container: Container.SAFE_IN_MILIEU_CONTAINER, title: LanguageKeys.SAFETY.SAFE_IN_MILIEU, field: FormField.SAFE_IN_MILIEU, note: FormField.SAFE_IN_MILIEU_NOTES },
  { container: Container.GENERAL_SAFETY_CONTAINER, title: LanguageKeys.SAFETY.GENERAL_SAFETY, field: FormField.GENERAL_SAFETY, note: FormField.GENERAL_SAFETY_NOTES },
  {
    container: Container.ROOM_PLAN_GRADATION_CONTAINER,
    title: LanguageKeys.SAFETY.ROOM_PLAN_GRADATION,
    field: FormField.ROOM_PLAN_GRADATION,
    note: FormField.ROOM_PLAN_GRADATION_NOTES
  },
  {
    container: Container.PATIENT_RESPONSE_TO_SAFETY_MEASURES_CONTAINER,
    title: LanguageKeys.SAFETY.PATIENT_RESPONSE_TO_SAFETY_MEASURES,
    field: FormField.PATIENT_RESPONSE_TO_SAFETY_MEASURES,
    note: FormField.PATIENT_RESPONSE_TO_SAFETY_MEASURES_NOTES
  }
];

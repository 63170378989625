/* eslint-disable no-param-reassign */
import produce from 'immer';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ContentItem } from 'models/ui';
import BaseHandler, { ExecuteParams } from './interface';
import Rule from '../Rule';

// This regex is to remove value. Ex: remove 'bb'
// aa|bb|cc|bbb => aa|cc|bbb
const getContentItemRegex = (contentItem: ContentItem) => new RegExp(`\\b${contentItem.value}\\||(\\|*\\b${contentItem.value})+$`, 'g');

export default class HideContentHandler implements BaseHandler {
  isReverse: boolean;

  constructor(isReverse = false) {
    // Use isReverse to make this handler show content if a rule is matched
    this.isReverse = isReverse;
  }

  updateContentItem = (contentItem: ContentItem, ruleRegistry: Map<string, Rule>, isReverse: boolean): void => {
    const ruleSet = contentItem?.validationSetIds?.map((id) => ruleRegistry.get(id)) ?? [];
    const hasInvalidField = ruleSet?.some((rule) => rule.result);
    contentItem.isHidden = isReverse ? !hasInvalidField : hasInvalidField;
    if (contentItem.isHidden) contentItem.selected = false;
  };

  getFieldValueAfterContentUpdate = (fieldValue: string, contentItem: ContentItem): string => {
    let updatedFieldValue = fieldValue.replace(getContentItemRegex(contentItem), '');
    if (contentItem.selected) {
      updatedFieldValue = updatedFieldValue ? updatedFieldValue.concat(`|${contentItem.value}`) : contentItem.value;
    }
    return updatedFieldValue;
  };

  execute({ field, ruleId, ruleRegistry, contentIndexesByRuleMap }: ExecuteParams): ChartMetaFormField {
    return produce(field, (draft) => {
      let fieldValue = draft.value;
      const key = `${field.name}_${ruleId}`;
      const contentIndexes = contentIndexesByRuleMap.get(key);
      if (contentIndexes) {
        contentIndexes.forEach((index) => {
          const contentItem = draft.chartContent[index];
          if (contentItem) {
            this.updateContentItem(contentItem, ruleRegistry, this.isReverse);
            fieldValue = this.getFieldValueAfterContentUpdate(fieldValue, contentItem);
          }
        });
      }
      draft.value = draft.inputType === FormFieldInputType.SCALE ? draft.value : fieldValue;
    });
  }
}

import { ChartFragment } from 'models/api-response';
import { AppMessage } from 'constants/app.constant';
import { ChartHistory } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, FormFieldLabel, SectionTitle } from '../shared/constants';

interface InterventionHistoryProps {
  chartHistory: ChartFragment[];
}

const InterventionHistory = (props: InterventionHistoryProps) => {
  const { chartHistory } = props;

  const dataSource = {
    headers: [
      { text: FormFieldLabel.TIME, field: FormField.INTERVENTION_DATE_TIME, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.FUNCTIONAL_MOBILITY, field: FormField.FUNCTIONAL_MOBILITY, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.LEFT, field: FormField.LEFT, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.RIGHT, field: FormField.RIGHT, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.SIT_SUPINE, field: FormField.SIT_SUPINE, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.SIT_STAND, field: FormField.SIT_STAND, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.PIVOL, field: FormField.PIVOL, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.DEVIATIONS, field: FormField.DEVIATIONS, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.STAIRS, field: FormField.STAIRS, section: SectionTitle.ROLLING },
      { text: FormFieldLabel.DEVICE, field: FormField.DEVICE, section: SectionTitle.GAIN },
      { text: FormFieldLabel.ASSISTANCE, field: FormField.ASSISTANCE, section: SectionTitle.GAIN },
      { text: FormFieldLabel.WEIGHT_BEARING, field: FormField.WEIGHT_BEARING, section: SectionTitle.GAIN },
      { text: FormFieldLabel.DISTANCE, field: FormField.DISTANCE, section: SectionTitle.GAIN },
      { text: FormFieldLabel.LOCATION, field: FormField.LOCATION, section: SectionTitle.PAIN },
      { text: FormFieldLabel.THERAPUTIC_EXERCISES, field: FormField.THERAPUTIC_EXERCISES, section: SectionTitle.PAIN },
      { text: FormFieldLabel.TREATMENT, field: FormField.TREATMENT, section: SectionTitle.PAIN },
      { text: FormFieldLabel.PATIENT_FAMILY_TEACHING, field: FormField.PATIENT_FAMILY_TEACHING, section: SectionTitle.PAIN },
      { text: FormFieldLabel.PHYSICAL_THERAPIST_NAME, field: FormField.PHYSICAL_THERAPIST_NAME, section: SectionTitle.PAIN },
      { text: FormFieldLabel.PHYSICAL_THERAPIST_SIGNATURE, field: FormField.PHYSICAL_THERAPIST_SIGNATURE, section: SectionTitle.PAIN }
    ],
    data: chartHistory
  };

  return (
    <div className="intervention">
      <ELSFlex left wrap>
        <ELSFlexItem w={chartHistory.length === 0 ? '1o2' : '1o1'}>
          <ChartHistory
            noTableDataMessage=" "
            renderVertical
            tableTitleContents={[SectionTitle.NO_SECTION, SectionTitle.ROLLING, SectionTitle.GAIN, SectionTitle.PAIN]}
            dataSource={dataSource}
          />
        </ELSFlexItem>
        {chartHistory.length === 0 && (
          <ELSFlexItem w="1o2">
            <div className="margin-top-4x1o2 u-els-margin-left table-message">{AppMessage.NO_TABLE_DATA}</div>
          </ELSFlexItem>
        )}
      </ELSFlex>
    </div>
  );
};

InterventionHistory.displayName = 'InterventionHistory';
export default InterventionHistory;

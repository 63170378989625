import { ChartFragment } from 'models/api-response';
import { ScaleRollUpContent } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { Box, ButtonLinkIcon, ChartActions, ChartPermission, ErrorFormField, ScaleRollUp, withEmbeddedChart } from 'components/common';
import MorseFallScale from 'components/features/chart/special-charts/morse-fall-scale/MorseFallScale';
import { Section as MorseSectionTitle } from 'components/features/chart/special-charts/morse-fall-scale/constants';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, Label, SectionTitle } from './constants';
import FallRiskAssessmentReadOnly from './FallRiskAssessmentReadOnly';

export interface FallRiskAssessmentViewProps extends ChartViewProps {
  isLocked: boolean;
  isMorseSidebarOpen: boolean;
  handleMorseClick: Function;
  handleCloseSidebar: Function;
  handleDiscardSidebar: Function;
  onEmbeddedChartSave: Function;
  chartContent: ScaleRollUpContent;
  sidebarKey: number;
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  morseChartFragment: ChartFragment;
}

export const EmbeddedMorseFallScale = withEmbeddedChart(MorseFallScale);

const FallRiskAssessmentView = (props: FallRiskAssessmentViewProps) => {
  const {
    isLocked,
    chartActionsProps,
    chartContent,
    formFieldMap,
    formSubmittedCount,
    handleCloseSidebar,
    handleDiscardSidebar,
    handleMorseClick,
    isMorseSidebarOpen,
    onEmbeddedChartSave,
    sidebarKey,
    chartFragment,
    morseChartFragment
  } = props;

  if (isLocked) {
    return <FallRiskAssessmentReadOnly {...props} />;
  }

  return (
    <div>
      <h1>{SectionTitle.FALL_RISK_ASSESSMENT}</h1>
      <Box mt>
        <p>{Label.HIGH_FALL_RISK_PROTOCOL}</p>
      </Box>
      <Box mt2 mb2>
        <ButtonLinkIcon iconName="enlarge" iconSize="1x" text="Morse Fall Scale" onClick={() => handleMorseClick()} preventAutoPending />
      </Box>
      <EmbeddedMorseFallScale
        key={sidebarKey}
        isSidebarOpen={isMorseSidebarOpen}
        onClose={handleCloseSidebar}
        onSave={onEmbeddedChartSave}
        onDiscard={handleDiscardSidebar}
        selectedNavId={NAV_ID.MORSE_FALL_SCALE}
        chartFragment={chartFragment}
        morseChartFragment={morseChartFragment}
        {...appHelper.getChartSharedProps(props)}
      />

      <ScaleRollUp sectionTitle={MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE} scaleRollUpContent={chartContent} />

      <Box mt mb>
        <h2>{SectionTitle.FALL_PREVENTION_PROTOCOL}</h2>
      </Box>
      <ErrorFormField formSubmittedCount={formSubmittedCount} formField={formFieldMap.get(FormField.PROTOCOL_IN_EFFECT)} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

FallRiskAssessmentView.displayName = 'FallRiskAssessmentView';
export default FallRiskAssessmentView;

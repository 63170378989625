import cx from 'classnames';
import { ReactNode } from 'react';

interface AccordionTitleProps {
  children: ReactNode;
  className?: string;
}

const AccordionTitle = (props: AccordionTitleProps) => (
  <div className={cx('u-els-display-inline-block u-els-color-secondary', props.className)}>
    <p>{props.children}</p>
  </div>
);

AccordionTitle.displayName = 'AccordionTitle';
export default AccordionTitle;

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField } from './constants';
import CardiovascularPeripheralVascularInterventionsView from './CardiovascularPeripheralVascularInterventionsView';
import { Title } from '../shared/constants';

class CardiovascularPeripheralVascularInterventions extends Component<ChartComponentProps> {
  static displayName = 'CardiovascularPeripheralVascularInterventions';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const multiselectDropdowns = [FormField.CARDIOVASCULAR_CARE, FormField.DVT_SUPPRESSION, FormField.DRAIN_OR_TUBE_CARE, FormField.PATIENT_RESPONSE];

    dataMap.set(FormField.NO_CARE, createFormField({ name: FormField.NO_CARE, type: FormFieldInputType.CHECK_BOX }));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    dataMap.set(FormField.CARE_NOTES, createFormField({ name: FormField.CARE_NOTES, type: FormFieldInputType.TEXT_AREA }));
    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap, intl } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.CARDIOVASCULAR_PERIPHERAL_CARE, fields: [FormField.NO_CARE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.CARDIOVASCULAR_CARE, fields: [FormField.CARDIOVASCULAR_CARE], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.DVT_SUPPRESSION }),
        fields: [FormField.DVT_SUPPRESSION],
        formFieldMap
      }),
      chartHelper.buildSection({ sectionTitle: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.DRAIN_OR_TUBE_CARE, fields: [FormField.DRAIN_OR_TUBE_CARE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.PATIENT_RESPONSE, fields: [FormField.PATIENT_RESPONSE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.CARE_NOTES, fields: [FormField.CARE_NOTES], formFieldMap })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SYSTEM_NURSING_INTERVENTIONS,
      fragmentTitle: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.CARDIOVASCULAR_PERIPHERAL_INTERVENTIONS,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const { enableDisplayRecordsButton, hasUnsavedChanges, handleDiscardClick, displayAuthoringData, formFieldMap, formSubmittedCount, intl } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      intl
    };
    return <CardiovascularPeripheralVascularInterventionsView {...viewProps} />;
  }
}

export { CardiovascularPeripheralVascularInterventions as BaseCardiovascularPeripheralVascularInterventions };
export default compose(withSavedPatientChartsPage, withChartLogic)(CardiovascularPeripheralVascularInterventions);

import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionLabel } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface ChildRiskAssessmentReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const ChildRiskAssessmentReadOnly = (props: ChildRiskAssessmentReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>{SectionLabel.CHILD_RISK_ASSESSMENT}</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.DENTAL_CARE}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.DENTAL_CARE} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.DENTAL_CARE_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.CHILD_PASSENGER_SAFETY}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.CHILD_PASSENGER_SAFETY} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  formFieldId={FormField.CHILD_PASSENGER_SAFETY_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.ABUSE_MALTREATMENT}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.ABUSE_MALTREATMENT} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.ABUSE_MALTREATMENT_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.SLEEP}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.SLEEP} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.SLEEP_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.PLAYGROUND_INJURIES}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.PLAYGROUND_INJURIES} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.PLAYGROUND_INJURIES_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.POISONINGS}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.POISONINGS} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.POISONINGS_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.WATER_SAFETY}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.WATER_SAFETY} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.WATER_SAFETY_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.RESIDENTIAL_FIRES}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.RESIDENTIAL_FIRES} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionLabel.PRESENCE_OF_RISK}
                  formFieldId={FormField.RESIDENTIAL_FIRES_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{`${SectionLabel.BICYCLE_RELATED_INJURIES}:`}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionLabel.PRESENCE_OF_RISK} formFieldId={FormField.BICYCLE_RELATED_INJURIES} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  formFieldId={FormField.BICYCLE_RELATED_INJURIES_DESCRIBE}
                  customTitle={`${SectionLabel.DESCRIBE}:`}
                  notRenderBlankField
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

ChildRiskAssessmentReadOnly.displayName = 'ChildRiskAssessmentReadOnly';
export default ChildRiskAssessmentReadOnly;

import { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { AssessmentRS, ChartFragment } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { PhaseProgress } from 'models/enum';
import { CaseStudyPhase, CaseStudySequence } from 'models/ui';
import { NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { apiHelper, appHelper, assignmentHelper } from 'helpers';
import { caseStudyService, chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import CaseStudyInfoView, { CaseStudyInfoViewProps } from './CaseStudyInfoView';
import { ConfirmationModal } from '..';
import { ELSWithModalService } from '../els';

export enum Tabs {
  INSTRUCTIONS = 0,
  CASE_STUDY_OVERVIEW = 1
}

export interface PhaseInfo {
  title: string;
  instruction?: string;
}

export interface CaseStudyInfoProps {
  assessmentId: number;
  sequenceChunk: CaseStudySequence;
  modalService: ELSModalServiceType;
  history: History;
  assessment: AssessmentRS;
  setShowQuiz: () => void;
}

export interface NavigateProps {
  current: number;
  total: number;
  disableNext?: boolean;
  onNext: () => void;
  onPrev: () => void;
}

interface CaseStudyInfoState {
  activeTab: number;
  current: number;
  selectedPhase: CaseStudyPhase;
}

class CaseStudyInfo extends Component<CaseStudyInfoProps & RouteComponentProps, CaseStudyInfoState> {
  static displayName = 'CaseStudyInfo';

  constructor(props) {
    super(props);

    const { sequenceChunk } = props;
    let index = sequenceChunk.startedPhaseList.findIndex((item) => item.id === sequenceChunk.phaseId);
    index = index > 0 ? index : 0;
    this.state = {
      activeTab: Tabs.INSTRUCTIONS,
      current: index,
      selectedPhase: sequenceChunk.startedPhaseList[index]
    };
  }

  handleTabGroupClick = (tabIndex: number) => this.setState({ activeTab: tabIndex });

  handleMarkPhaseDoneClick = () => {
    return appHelper.useLoader(
      chartService.loadFragments({ chartId: this.props.assessment.simChartId }).then(({ data }) => {
        const studentInputData: ChartFragment[] = data?.filter((item) => item.navElementId !== NAV_ID.TIME);
        if (studentInputData.length) {
          this.savePhase();
        } else {
          this.openWarningModal();
        }
      }),
      { errorMessage: 'can not load fragment' }
    );
  };

  openWarningModal = () => {
    this.props.modalService.openModal({
      modalId: 'warningModal',
      color: 'primary',
      content: (
        <ConfirmationModal
          showIcon
          header="Please confirm."
          message="Before proceeding to the quiz associated with this phase of the case study, please ensure that you have completed all student actions noted in the Phase Instructions."
          onOkClick={() => {
            this.props.modalService.closeModal('warningModal');
            this.savePhase();
          }}
          onCancelClick={() => this.props.modalService.closeModal('warningModal')}
        />
      )
    });
  };

  handleNextPhase = () => {
    this.setState((prevState) => {
      const { sequenceChunk } = this.props;
      const { current } = prevState;
      const selectedPhase = sequenceChunk.startedPhaseList[current + 1];
      return { current: current + 1, selectedPhase };
    });
  };

  handlePrevPhase = () => {
    this.setState((prevState) => {
      const { sequenceChunk } = this.props;
      const { current } = prevState;
      const selectedPhase = sequenceChunk.startedPhaseList[current - 1];
      return { current: current - 1, selectedPhase };
    });
  };

  savePhase() {
    caseStudyService
      .completePhase(this.props.assessmentId)
      .then(({ data }) => {
        if (data.status === PhaseProgress.IN_PROGRESS) {
          this.props.setShowQuiz();
        }
        if (data.status === PhaseProgress.COMPLETED) {
          const caseStudyHome = RoutePath.student.caseStudyLanding.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessmentId));
          this.props.history.push(caseStudyHome);
        }
      })
      .catch((err) => apiHelper.showApiError(`can not mark phase done ${err}`));
  }

  render() {
    const { sequenceChunk, assessment } = this.props;
    const { activeTab, selectedPhase, current } = this.state;
    const overview = sequenceChunk.caseStudyOverview?.overview;
    const markPhaseDoneIsDisabled = sequenceChunk.status !== PhaseProgress.IN_PROGRESS;
    const navigateProps = {
      current: current + 1,
      total: sequenceChunk.totalPhases,
      disableNext: current + 1 === sequenceChunk.startedPhaseList.length,
      onNext: this.handleNextPhase,
      onPrev: this.handlePrevPhase
    };
    const markPhaseDoneText = assignmentHelper.isAssessmentSubmitted(assessment.status) ? 'All Phases Marked Done' : `Mark ${sequenceChunk.content.title} as Done`;

    assignmentHelper.resetEmptyEhrDemoStorage();

    const viewProps: CaseStudyInfoViewProps = {
      activeTab,
      overview,
      markPhaseDoneText,
      instruction: selectedPhase?.instruction,
      navigateProps,
      onTabGroupClick: this.handleTabGroupClick,
      onMarkPhaseDoneClick: this.handleMarkPhaseDoneClick,
      markPhaseDoneIsDisabled
    };

    return <CaseStudyInfoView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

export { CaseStudyInfo as BaseCaseStudyInfo };
export default compose(
  withProps(() => ({
    history: useHistory()
  })),
  ELSWithModalService,
  connect(mapStateToProps)
)(CaseStudyInfo);

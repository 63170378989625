import { ChartMetaFormField, ColumnAlign, GroupScaleQuestion } from 'models/ui';
import { ELSDataTable } from 'components/common/els';

export interface ScoreTableProps {
  group: GroupScaleQuestion;
  scaleQuestions: ChartMetaFormField[];
}

const ScoreTable = (props: ScoreTableProps) => {
  const { group, scaleQuestions } = props;
  const score = {};
  const headers = [
    { text: '1 minute', field: '', align: ColumnAlign.Left },
    { text: '5 minute', field: '', align: ColumnAlign.Left },
    { text: '10 minute', field: '', align: ColumnAlign.Left }
  ];
  group.formFields.forEach((fieldId, index) => {
    headers[index].field = fieldId;
    scaleQuestions.forEach((question) => {
      if (question.name === fieldId) {
        score[fieldId] = question.extraData.score?.value || 0;
      }
    });
  });

  return (
    <ELSDataTable data={[score]}>
      {headers.map((col) => (
        <column key={`${col.field}`} field={col.field} header={col.text} align={col.align} />
      ))}
    </ELSDataTable>
  );
};

ScoreTable.displayName = 'ScoreTable';
export default ScoreTable;

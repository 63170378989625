import { Component } from 'react';
import { SectionNavIdPatterns } from 'constants/app.constant';
import { SavedPatientCharting } from 'components/common';

class SavedBasicNursingCare extends Component {
  static displayName = 'SavedBasicNursingCare';

  render() {
    const viewProps = {
      chartTitle: 'Saved Basic Nursing Care',
      navIds: [SectionNavIdPatterns.BASIC_NURSING]
    };
    return <SavedPatientCharting {...this.props} {...viewProps} />;
  }
}

export default SavedBasicNursingCare;

import cx from 'classnames';
import { IntlShape } from 'react-intl';
import { ChartMetaFormField } from 'models/ui';
import { ErrorFormFieldV2 } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { LanguageKeys } from 'lang';
import { addsEmergencyCallConditions, addsScoringActions, FormField } from '../constants';
import { ADDSPointMap } from '../types';
import { isScoreInRange } from '../helpers';

export interface AToEAssessmentADDSProps {
  totalScore: string;
  formFieldMap?: Map<string, ChartMetaFormField>;
  intl?: IntlShape;
}

export default function AToEAssessmentADDS({ totalScore, intl, formFieldMap }: AToEAssessmentADDSProps) {
  const parsedTotalScore = Number(totalScore);
  const isShownNursingActions = (!Number.isNaN(parsedTotalScore) && parsedTotalScore > 0) || totalScore === ADDSPointMap.EMERGENCY_CALL;

  return (
    <div id="vital-signs-adds">
      <div className="chart-input__section-title">
        <ELSFlex left wrap gutters middle>
          <ELSFlexItem>
            <h2 className="u-els-display-inline">{LanguageKeys.VITAL_SIGNS.ADDS_VERBOSE}</h2>
          </ELSFlexItem>
          <ELSFlexItem>
            <div data-testid="adds-total-score" className="u-els-display-inline c-vital-signs-adds__total-score">
              {intl?.formatMessage({ id: LanguageKeys.VITAL_SIGNS.TOTAL_SCORE_TEMPLATE }, { totalScore: <strong>{totalScore}</strong> })}
            </div>
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <ELSFlex gutters left className="u-els-margin-top">
        <ELSFlexItem w="2o3">
          <div className="c-els-card c-els-card--small">
            <ELSFlex wrap left gutters>
              {addsScoringActions.map(({ title, actions, scoreRanges }) => {
                const isHighlight = isScoreInRange(scoreRanges, totalScore);
                return (
                  <ELSFlexItem key={title} w="1o2" className="u-els-margin-bottom-1x1o2">
                    <div
                      className={cx('c-els-card c-els-card--small u-els--full-height', {
                        'u-vital-signs-adds__score-range--highlight': isHighlight,
                        'c-els-card--border-none': !isHighlight
                      })}
                    >
                      <strong>{title}</strong>
                      <ul className="c-els-list c-els-list--no-indent">
                        {actions.map((action: string) => (
                          <li key={action} className="c-els-list__item">
                            {action}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </ELSFlexItem>
                );
              })}
            </ELSFlex>

            <div className="c-vital-signs-adds--level-emergency-call c-els-card c-els-card--small c-els-card--border-none">
              <strong>{addsEmergencyCallConditions.title}</strong>
              <ul className="c-els-list c-els-list--no-indent">
                {addsEmergencyCallConditions.conditions.map((condition) => (
                  <li key={condition} className="c-els-list__item">
                    {condition}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </ELSFlexItem>
        {isShownNursingActions && (
          <ELSFlexItem grow>
            <h4>{LanguageKeys.VITAL_SIGNS.NURSING_ACTIONS}</h4>

            <div className="u-els-margin-left">
              <ErrorFormFieldV2 formField={formFieldMap?.get(FormField.NURSING_ACTIONS)} formSubmittedCount={0} useDefaultMargin={false} className="u-els-margin-bottom" />
              <ErrorFormFieldV2 formField={formFieldMap?.get(FormField.NURSING_ACTIONS_COMMENT)} formSubmittedCount={0} />
            </div>
          </ELSFlexItem>
        )}
      </ELSFlex>
    </div>
  );
}

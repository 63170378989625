export enum FormField {
  CHIEF_COMPLAINT = 'chiefComplaint',
  LOCATION = 'location',
  TIMING = 'timing',
  QUALITY = 'quality',
  CONTEXT = 'context',
  SEVERITY = 'severity',
  MODIFYING_ALLEVIATING_FACTORS = 'modifyingAlleviatingFactors',
  ASSOCIATED_SIGNS_AND_SYMPTOM = 'associatedSignAndSymptom',
  INTERVENTIONS_AND_RESPONSE = 'interventionsAndResponse',
  PATIENT_PERCEPTION = 'patientPerception'
}

export enum SectionTitle {
  SYMPTOM_ANALYSIS = 'Symptom Analysis'
}

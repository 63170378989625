import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { FormField, SectionTitle } from './constants';
import './injections.scss';

export interface InjectionsViewProps {
  fragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const InjectionsView = (props: InjectionsViewProps) => {
  const { formSubmittedCount, fragment, formFieldMap } = props;
  return (
    <div className="injections">
      <h1>Injections</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.COMPLETE)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.INJECTION_TYPE)} formSubmittedCount={formSubmittedCount} inputProps={{ disableRadioContent: () => !!fragment }} />
      <SectionDivider />
      <ErrorFormField formField={formFieldMap.get(FormField.LOCATION)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
      <h4 className="u-els-margin-bottom-1o2">{SectionTitle.INJECTION_SITE_ASSESSMENT}</h4>
      <ErrorFormField formField={formFieldMap.get(FormField.INJECTION_SITE_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField
        formField={formFieldMap.get(FormField.FINDINGS_ACTION_TAKEN)}
        formSubmittedCount={formSubmittedCount}
        inputProps={{ className: 'injections__action-taken' }}
      />
      <ErrorFormField formField={formFieldMap.get(FormField.NOTES)} formSubmittedCount={formSubmittedCount} inputProps={{ className: 'injections__notes' }} />

      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

InjectionsView.displayName = 'InjectionsView';
export default InjectionsView;

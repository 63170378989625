export enum SectionTitle {
  PAIN_INTERVENTIONS = 'Pain Interventions',
  PAIN_CARE = 'Pain Care',
  PATIENT_RESPONSE = 'Patient Response',
  PAIN_CARE_NOTES = 'Pain Care Notes'
}

export enum FormField {
  NO_CARE_REQUIRED = 'noCareRequired',
  PAIN_CARE = 'painCare',
  PATIENT_RESPONSE = 'patientResponse',
  PAIN_CARE_NOTES = 'painCareNotes'
}

import cx from 'classnames';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { Pill } from '@els/els-react--pill';
import { TimeSpentChart } from 'models/api-response';
import { UserRole } from 'models/enum';
import { ChartComment } from 'models/ui';
import { Locales, TimeSpentMsg } from 'constants/app.constant';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { appSelectors } from 'redux/ducks/app';
import { ButtonLinkIcon } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSIcon } from 'components/common/els';
import './chart.comment.list.scss';

export interface ChartCommentListProps {
  className?: string;
  title?: string;
  chartComments: ChartComment[];
  onViewChartClick: Function;
  userRole: string;
  timeSpent?: TimeSpentChart[];
  locale?: Locales;
  isInProgressGradingEnabledFlag?: boolean;
}

export const renderTimeSpentPerChart = (id: string, timeSpent: TimeSpentChart[]): string => {
  if (timeSpent.length === 0) return '';
  const { totalTime } = timeSpent.find((item) => item.navId === id);
  if (totalTime > 0) {
    return `(${totalTime.toString()} min)`;
  }
  return `(${TimeSpentMsg.LESS_THAN_ONE_MIN})`;
};

const renderComment = (comment, locale, isInProgressGradingEnabledFlag) => {
  if (!comment.contents.length) {
    return (
      <>
        <ELSIcon prefix="gizmo" name="information" size="1x" align="sub" customClass="u-els-margin-right-1o2" />
        <span> No instructor feedback yet. </span>
      </>
    );
  }

  return (
    <ul className="c-els-list">
      {comment.contents.map((content) => (
        isInProgressGradingEnabledFlag ? (<div key={content?.id} className="chart-comment-list__comment-details u-els-display-flex u-els-font-size-body">
          <Pill
            label={formatDate({
              date: toMomentWithParsers(comment.createdAt).toDate(),
              locale,
              includeTime: true
            })}
            className="u-els-color-black u-els-background-color-n6 u-els-margin-right u-els-height-1o1"
          />
          <p className="chart-comment-list__comment-content">{content}</p>
        </div>) : (<li className="c-els-list__item chart-comment-list__comment-content" key={content}>
          {content}
        </li>)
      ))}
    </ul>
  );
};

const ChartCommentList = (props: ChartCommentListProps) => {
  const { className, title, chartComments, onViewChartClick, timeSpent, userRole, locale, isInProgressGradingEnabledFlag } = props;

  return (
    <div className={cx('chart-comment-list', className)}>
      <p className="chart-comment-list__comment-title u-els-font-size-h2">{title || 'Chart Comments'}</p>

      {chartComments.length > 0 && (
        <ELSAccordion id="CommentsAccordion">
          {chartComments.map((item) => (
            <ELSAccordionItem
              key={item.id}
              id={`${item.id}`}
              leftIcon="chevron-down"
              title={`${item.title} ${!isNil(timeSpent?.find((value) => value.navId === item.id)) ? renderTimeSpentPerChart(item.id, timeSpent) : ''}`}
              titleClass="c-els-accordion__button-text"
              size="small"
              isExpanded={!!item.contents.length}
            >
              {renderComment(item, locale, isInProgressGradingEnabledFlag)}
              <ButtonLinkIcon
                iconName="arrow-right"
                iconPosition="right"
                iconSize="1x"
                text="View EHR Charts"
                className="u-els-margin-top"
                onClick={() => onViewChartClick(item.path)}
              />
            </ELSAccordionItem>
          ))}
        </ELSAccordion>
      )}

      {chartComments.length === 0 && (
        <ELSAccordion id="NoChartsAccordion">
          <p className="chart-comment-list__chart-comments">
            <ELSIcon prefix="gizmo" name="information" customClass="chart-comment-list--information-hollow" size="1x" />
            {userRole === UserRole.INSTRUCTOR ? 'Student did not fill out any charts.' : 'You did not fill out any charts.'}
          </p>
        </ELSAccordion>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRole: appSelectors.getUserRole(state),
  isInProgressGradingEnabledFlag: appSelectors.getIsInProgressGradingEnabledFlag(state)
});

ChartCommentList.displayName = 'ChartCommentList';

export { ChartCommentList as BaseChartCommentList };
export default connect(mapStateToProps)(ChartCommentList);

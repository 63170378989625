export enum SectionTitle {
  ACTIVITY = 'Activity',
  AMBULATION_LOCOMOTION = 'Ambulation/Locomotion',
  TURNING_RANGE_MOTION = 'Turning/Range of Motion'
}

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentOrCareRequired',
  ACTIVITY_LIST = 'activity',
  AMBULATION_LOCOMOTION_LIST = 'ambulationLocomotion',
  TURNING_RANGE_MOTION_LIST = 'turningRangeOfMotion',
  ACTIVITY_NOTES = 'activityNotes',
  AMBULATION_LOCOMOTION_NOTES = 'ambulationLocomotionNotes',
  TURNING_RANGE_MOTION_NOTES = 'turningRangeOfMotionNotes'
}

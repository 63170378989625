import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';

export enum FormField {
  TIME_LAST_MEAL = 'timeLastMeal',
  GLUCOSE_READING = 'glucoseReading',
  NURSING_ACTIONS = 'nursingActions',
  NURSING_ACTIONS_OTHER = 'nursingActionsOther',
  NOTE = 'note'
}

export const getBloodGlucoseFormFields = (locale: Locales): ChartMetaFormFieldParams[] => [
  {
    name: FormField.TIME_LAST_MEAL,
    type: FormFieldInputType.DROPDOWN,
    label: LanguageKeys.BLOOD_GLUCOSE.TIME_SINCE_LAST_MEAL
  },
  {
    name: FormField.GLUCOSE_READING,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.BLOOD_GLUCOSE.GLUCOSE_READING_WITH_UNIT
  },
  {
    name: FormField.NURSING_ACTIONS,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.BLOOD_GLUCOSE.NURSING_ACTIONS
  },
  {
    name: FormField.NURSING_ACTIONS_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.OTHER,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.NOTE,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.BLOOD_GLUCOSE.NOTES
  }
];

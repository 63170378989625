export const ClinicalSetupLanguageKeys = {
  CLINICAL_SETUP: {
    FIRST_DATE: 'First Day Of Clinical (mm-dd-yyyy)',
    FIRST_DATE_INPUT: 'Input date field for First Day of Clinical',
    BIRTH_DATE: 'Date Of Birth (mm-dd-yyyy)',
    BIRTH_DATE_INPUT: 'Input date field for Date of Birth',
    SEX: 'Sex recorded at birth',
    MRN: 'MRN',
    FIRST_DAY_OF_CLINICAL: 'First day of clinical',
    FIRST_AND_LAST_INITIAL: 'First and last initial',
    DATE_OF_BIRTH: 'Date of birth',
    CLINICAL_UNIT: 'Clinical unit',
    PATIENT_IDENTIFIER: 'Patient identifier (2 characters required, alpha or numeric)',
    FIRST_INITIAL: 'First initial',
    LAST_NAME: 'Last name',
    HIPAA_COMPLIANCE: 'HIPAA Compliance',
    PATIENT_NAME: 'Patient Name',
    ROOM_NUMBER: 'Room Number',
    CLINICAL_INFO: 'Clinical Info',
    PROVIDER_INFO: 'Provider Info',
    SPECIFY_BELOW: 'Specify Below',
    GENDER: 'Gender',
    PRONOUNS: 'Pronouns'
  }
};

export type ClinicalSetupMessageType = Partial<Record<keyof typeof ClinicalSetupLanguageKeys, string>>;

export const ClinicalSetupEnUs: ClinicalSetupMessageType = {
  [ClinicalSetupLanguageKeys.CLINICAL_SETUP.HIPAA_COMPLIANCE]: 'HIPAA Compliance'
};

export const ClinicalSetupEnAu: ClinicalSetupMessageType = {
  [ClinicalSetupLanguageKeys.CLINICAL_SETUP.HIPAA_COMPLIANCE]: 'Privacy Policy'
};

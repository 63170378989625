export enum FormField {
  NO_KNOWN_ALLERGIES = 'noKnownAllergies',
  ALLERGY_TYPE = 'allergyType',
  ALLERGEN = 'allergen',
  ALLERGEN_OTHER = 'allergenOther',
  ALLERGEN_MEDICATION = 'medication',
  ALLERGEN_MEDICATION_OTHER = 'allergenMedicationOther',
  REACTIONS = 'reactions',
  REACTIONS_OTHER = 'reactionsOther',
  SEVERITY = 'severity',
  INFORMANT = 'informant',
  INFORMANT_OTHER = 'informantOther',
  CONFIDENCE_LEVEL = 'confidenceLevel'
}

export enum FormFieldLabel {
  ALLERGY_TYPE = 'Allergy type',
  ALLERGEN = 'Allergen',
  REACTIONS = 'Reactions',
  SEVERITY = 'Reaction Severity',
  INFORMANT = 'Informant',
  CONFIDENCE_LEVEL = 'Confidence level',
  OTHER = 'Other'
}

export enum SectionTitle {
  ALLERGIES = 'Allergies',
  REACTIONS = 'Reactions'
}

import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { FormField, Section } from './constants';

export interface HamiltonAnxietyRatingScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const HamiltonAnxietyRatingScaleView = (props: HamiltonAnxietyRatingScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="hamilton-anxiety-rating-scale">
      <h1>{Section.HAMILTON_ANXIETY_RATING_SCALE}</h1>
      <p>
        <span className="u-els-color-error">* </span>
        All fields in this chart are required
      </p>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.HAMILTON_ANXIETY_ASSESSMENT_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: true }}
      />
      <ChartActions {...chartActionsProps} />
      <p className="u-els-margin-bottom">Modified from: Hamilton, M. The assessment of anxiety states by rating. Br J Med Psychol, 32:50-55, 1959.</p>
    </div>
  );
};

export default HamiltonAnxietyRatingScaleView;

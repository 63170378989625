export enum FormField {
  BIRTH_WEIGHT = 'birthWeight',
  LENGTH = 'length',
  DAY_OF_BIRTH = 'dayOfBirth',
  TIME_OF_BIRTH = 'timeOfBirth',
  DAY_OF_EXAM = 'dayOfExam',
  TIME_OF_EXAM = 'timeOfExam',
  AGE_WHEN_EXAMINED = 'ageWhenExamined',
  APGAR_SCORE_1_MINUTE = 'apgarScore1Minute',
  APGAR_SCORE_5_MINUTE = 'apgarScore5Minute',
  APGAR_SCORE_10_MINUTE = 'apgarScore10Minute',
  BY_WEEKS = 'byWeeks',
  BY_DATES = 'byDates',
  BY_ULTRASOUND = 'byUltrasound',
  BY_EXAM = 'byExam',
  PHYSICAL_MATURITY = 'physicalMaturity',
  SKIN = 'skin',
  LANUGO = 'lanugo',
  PLANTAR_SURFACE = 'plantarSurface',
  BREAST = 'breast',
  EYE_EAR = 'eyeEar',
  GENITALS_MALE = 'genitalsMale',
  GENITALS_FEMALE = 'genitalsFemale',
  NEUROMUSCULAR_MATURITY = 'neuromuscularMaturity',
  POSTURE = 'posture',
  SQUARE_WINDOW_WRIST = 'squareWindowWrist',
  ARM_RECOIL = 'armRecoil',
  POPLITEAL_ANGLE = 'poplitealAngle',
  SCARF_SIGN = 'scarfSign',
  HEEL_TO_EAR = 'heelToEar',
  NEUROMUSCULAR_MATURITY_ROLL_UP = 'neuromuscularMaturity-scaleRollUpWrapper',
  PHYSICAL_MATURITY_ROLL_UP = 'physicalMaturity-scaleRollUpWrapper'
}

export enum FormFieldLabel {
  BIRTH_WEIGHT = 'Birth weight',
  LENGTH = 'Length',
  DAY_OF_BIRTH = 'Day of birth (mm-dd-yyyy)',
  TIME_OF_BIRTH = 'Time of birth',
  DAY_OF_EXAM = 'Day of exam (mm-dd-yyyy)',
  TIME_OF_EXAM = 'Time of exam',
  AGE_WHEN_EXAMINED = 'Age when examined',
  APGAR_SCORE_1_MINUTE = 'Apgar score, 1 minute',
  APGAR_SCORE_5_MINUTE = 'Apgar score, 5 minute',
  APGAR_SCORE_10_MINUTE = 'Apgar score, 10 minute',
  BY_WEEKS = 'By weeks',
  BY_DATES = 'By dates',
  BY_ULTRASOUND = 'By ultrasound',
  BY_EXAM = 'By exam'
}

export enum Section {
  BALLARD_MATURATIONAL_ASSESSMENT = 'Ballard Maturational Assessment',
  GESTATIONAL_AGE = 'Gestational Age',
  PHYSICAL_MATURITY = 'Physical Maturity',
  TOTAL_PHYSICAL_MATURITY_SCORE = 'Total Physical Maturity Score',
  NEUROMUSCULAR_MATURITY = 'Neuromuscular Maturity',
  TOTAL_NEUROMUSCULAR_MATURITY_SCORE = 'Total Neuromuscular Maturity Score'
}

export const buildScaleRollUps = (totalScore): { content: string; highlight: boolean }[] => [
  { content: '-10 for 20 weeks', highlight: totalScore < -5 },
  { content: '-5 for 22 weeks', highlight: totalScore >= -5 && totalScore < 0 },
  { content: '0 for 24 weeks', highlight: totalScore >= 0 && totalScore < 5 },
  { content: '5 for 26 weeks', highlight: totalScore >= 5 && totalScore < 10 },
  { content: '10 for 28 weeks', highlight: totalScore >= 10 && totalScore < 15 },
  { content: '15 for 30 weeks', highlight: totalScore >= 15 && totalScore < 20 },
  { content: '20 for 32 weeks', highlight: totalScore >= 20 && totalScore < 25 },
  { content: '25 for 34 weeks', highlight: totalScore >= 25 && totalScore < 30 },
  { content: '30 for 36 weeks', highlight: totalScore >= 30 && totalScore < 35 },
  { content: '35 for 38 weeks', highlight: totalScore >= 35 && totalScore < 40 },
  { content: '40 for 40 weeks', highlight: totalScore >= 40 && totalScore < 45 },
  { content: '45 for 42 weeks', highlight: totalScore >= 45 && totalScore < 50 },
  { content: '50 for 44 weeks', highlight: totalScore >= 50 }
];

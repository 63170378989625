import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { FormField, SectionLabel } from './constants';
import AdolescentRiskAssessmentReadOnly from './AdolescentRiskAssessmentReadOnly';
import './adolescent.risk.assessment.scss';

interface AdolescentRiskAssessmentViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const AdolescentRiskAssessmentView = (props: AdolescentRiskAssessmentViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, formFieldMap } = props;
  return (
    <div className="adolescent-risk-assessment">
      {isLocked ? (
        <AdolescentRiskAssessmentReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Adolescent Risk Assessment</h1>
          <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />

          <h3>{`${SectionLabel.PRESENCE_OF_RISK}:`}</h3>
          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.DENTAL_CARE)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.DENTAL_CARE_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.SEAT_BELT_USE)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.SEAT_BELT_USE_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.RIDING_IN_CAR)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.RIDING_IN_CAR_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.SLEEP)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.SLEEP_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.CARRYING_A_WEAPON)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.CARRYING_A_WEAPON_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.FEEL_UNSAFE_AT_SCHOOL)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.FEEL_UNSAFE_AT_SCHOOL_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.ATTEMPTED_SUICIDE)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.ATTEMPTED_SUICIDE_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.ABUSE_MALTREATMENT)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.ABUSE_MALTREATMENT_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.AUTOMOBILE_CRASH)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.AUTOMOBILE_CRASH_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <SectionDivider />
          <p>{`${SectionLabel.APPROPRIATE_REFERRAL}.`}</p>

          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

AdolescentRiskAssessmentView.displayName = 'AdolescentRiskAssessmentView';
export default AdolescentRiskAssessmentView;

import cx from 'classnames';
import { isEmpty } from 'lodash';
import { v4 } from 'uuid';
import { CarePlanRecord } from 'models/api-response';
import { DropDownItem } from 'models/ui';
import { ChartValue } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';
import { AccordionBadge, AccordionTitle, Box, ChartPermission } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton, ELSDropDown, ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';
import { FormFieldLabel, SectionTitle } from './constants';

export interface CarePlanHistoryViewProps {
  chartHistory: CarePlanRecord[];
  priorityItems: DropDownItem[];
  onEdit: Function;
  onDelete: Function;
  onPriorityChange: Function;
}

const CarePlanHistoryView = (props: CarePlanHistoryViewProps) => {
  const { chartHistory, priorityItems, onEdit, onDelete, onPriorityChange } = props;

  const canEdit = (record: CarePlanRecord) => record.active && !record?.chartData.planResolved;
  const renderText = (value: string) => (!isEmpty(value) ? value : ChartValue.VALUE_NONE);
  const isActionEmpty = (actions: string[]) => actions.length === 1 && actions[0] === '';
  const interventionActionText = (action: string[]) => (isActionEmpty(action) ? ChartValue.VALUE_NONE : '');

  return (
    <div className="sng-accordion">
      {chartHistory?.length === 0 && <NothingToSee>Nothing to see, yet! Get started adding care plans.</NothingToSee>}
      {chartHistory?.length > 0 && (
        <ELSAccordion id="carePlanAccordion">
          {chartHistory.map((record) => (
            <ELSAccordionItem
              key={record.fragmentId}
              id={record.fragmentId}
              leftIcon="chevron-down"
              customHeader={
                <>
                  <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !record.active })}>{record.title}</AccordionTitle>
                  <div className="u-els-margin-right-2x priority">
                    <span>Priority</span>
                    <span onClick={(event) => event.stopPropagation()} aria-hidden="true">
                      <ELSDropDown
                        inline
                        options={priorityItems}
                        value={record.chartData.priority?.toString() ?? ''}
                        isDisabled={!record.active}
                        changeHandler={(event) => onPriorityChange(record, event)}
                      />
                    </span>
                  </div>
                  {record?.chartData.planResolved && <AccordionBadge className="resolved u-els-margin-right-2x">Plan Resolved</AccordionBadge>}
                  <AccordionBadge className="u-els-margin-right-2x">{dateTimeHelper.toDateTime(record.chartTime)}</AccordionBadge>
                  <div className="creator-name">{`Created by ${record.fullName}`}</div>
                </>
              }
            >
              <Box mb>
                <MultiLineWithEmpty text={SectionTitle.ASSESSMENT_CUES} />
              </Box>
              <Box mb>
                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h4>{FormFieldLabel.SUBJECTIVE_DATA}</h4>
                    <MultiLineWithEmpty text={record?.subjectiveData} />

                    <h4 className="u-els-margin-top">Outcome</h4>
                    <MultiLineWithEmpty text={record?.outcome?.expectedOutcome} />
                    <MultiLineWithEmpty text={`Status: ${renderText(record?.outcome?.status)}`} />

                    <h4 className="u-els-margin-top">{SectionTitle.INTERVENTIONS}</h4>
                    {record?.interventionItems?.length === 0 ? (
                      <MultiLineWithEmpty text={undefined} />
                    ) : (
                      <ul className="intervention-list">
                        {record?.interventionItems?.map((item) => (
                          <li key={`interventionItem-${v4()}`}>
                            <MultiLineWithEmpty text={item?.name} />
                            <MultiLineWithEmpty text={`Rationale: ${renderText(item?.rationale)}`} />
                            <MultiLineWithEmpty text={`Status: ${renderText(item?.status)}`} />
                            <MultiLineWithEmpty text={`Intervention Actions: ${interventionActionText(item.actions)}`} />
                            {!isActionEmpty(item.actions) && (
                              <ul className="action-list">
                                {item?.actions?.map((action) => (
                                  <li key={`interventionAction-${v4()}`}>
                                    <MultiLineWithEmpty text={action} />
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </ELSFlexItem>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h4>{FormFieldLabel.OBJECTIVE_DATA}</h4>
                    <MultiLineWithEmpty text={record?.objectiveData} />

                    <h4 className="u-els-margin-top">{FormFieldLabel.INTERVENTION_PLAN}</h4>
                    <MultiLineWithEmpty text={record?.interventionPlan} />
                  </ELSFlexItem>
                </ELSFlex>
              </Box>
              <>
                {canEdit(record) && (
                  <ChartPermission fragmentCreatorId={record.creator?.id}>
                    {({ allowEditFragment, allowDeleteFragment }) => (
                      <Box mt2>
                        {allowEditFragment && <ELSButton className="sng-button u-els-margin-right" text="Edit" onClick={() => onEdit(record)} />}
                        {allowDeleteFragment && <ELSButton className="sng-button c-els-button--secondary" text="Delete" onClick={() => onDelete(record)} />}
                      </Box>
                    )}
                  </ChartPermission>
                )}
              </>
            </ELSAccordionItem>
          ))}
        </ELSAccordion>
      )}
    </div>
  );
};

CarePlanHistoryView.displayName = 'CarePlanHistoryView';
export default CarePlanHistoryView;

import { DropDownItem } from 'models/ui';
import { AppMessage, Locales } from 'constants/app.constant';
import { Box } from 'components/common';
import HistoryLineChart, { CustomRenderLeftYAxisLinesProps } from 'components/common/chart/line/HistoryLineChart';
import { ELSDropDown } from 'components/common/els';
import { BloodPressureChartProps, OxygenationChartProps, PulseChartProps, RespirationChartProps, TemperatureChartProps, VitalSignsChartList } from '../types';

export interface AtoEAssessmentChartViewProps {
  chartConfig: TemperatureChartProps | PulseChartProps | RespirationChartProps | BloodPressureChartProps | OxygenationChartProps;
  secondChartConfig: TemperatureChartProps | PulseChartProps | RespirationChartProps | BloodPressureChartProps | OxygenationChartProps;
  charts: DropDownItem[];
  selectedChart: string;
  secondSelectedChart: string;
  onChartChange: Function;
  locale: Locales;
  isTitleHidden?: boolean;
  displaySingleChartOnSummaryPage?: boolean;
  customRenderLeftYAxisLines: ({ dataKeys, handleGetCoordinate, handleDotClick, handleAnimationStart, handleAnimationEnd }: CustomRenderLeftYAxisLinesProps) => JSX.Element[];
}

const AtoEAssessmentChartView = ({
  chartConfig,
  secondChartConfig,
  charts,
  selectedChart,
  secondSelectedChart,
  onChartChange,
  isTitleHidden,
  locale,
  displaySingleChartOnSummaryPage,
  customRenderLeftYAxisLines
}: AtoEAssessmentChartViewProps) => (
  <div className="vital-signs-history" aria-hidden>
    {!isTitleHidden && <h2>Chart Graph</h2>}
    <Box mt className="chart-input__dropdown">
      <ELSDropDown inline options={charts} value={selectedChart} name={VitalSignsChartList.FIRST_CHART} changeHandler={onChartChange} />
    </Box>
    <Box mt className="u-overflow-x--auto u-overflow-y--hidden">
      {chartConfig?.data?.length ? <HistoryLineChart {...chartConfig} locale={locale} customRenderLeftYAxisLinesProps={customRenderLeftYAxisLines} /> : AppMessage.NO_GRAPH_DATA}
    </Box>

    {!displaySingleChartOnSummaryPage && (
      <>
        <Box mt className="chart-input__dropdown">
          <ELSDropDown inline options={charts} value={secondSelectedChart} name={VitalSignsChartList.SECOND_CHART} changeHandler={onChartChange} />
        </Box>
        <Box mt className="u-overflow-x--auto u-overflow-y--hidden">
          {secondChartConfig?.data?.length ? (
            <HistoryLineChart {...secondChartConfig} locale={locale} customRenderLeftYAxisLinesProps={customRenderLeftYAxisLines} />
          ) : (
            AppMessage.NO_GRAPH_DATA
          )}
        </Box>
      </>
    )}
  </div>
);

AtoEAssessmentChartView.displayName = 'AtoEAssessmentChartView';
export default AtoEAssessmentChartView;

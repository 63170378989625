import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const GenitourinaryInterventionsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="genitourinary-interventions">
      <h1>Genitourinary Interventions</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.URINARY_SYSTEM_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.URINARY_SYSTEM_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.DRAIN_TUBE_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_TUBE_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.GENITOURINARY_CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

GenitourinaryInterventionsView.displayName = 'Genitourinary Interventions';
export default GenitourinaryInterventionsView;

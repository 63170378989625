import { ChartFragment, ReviewOfSystemsFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, Headline } from 'components/common';
import ReviewOfSystemsEditView from './ReviewOfSystemsEditView';
import ReviewOfSystemsReadonly from './ReviewOfSystemsReadOnly';
import { SectionTitle } from './constant';

export interface ReviewOfSystemsViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ReviewOfSystemsFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const ReviewOfSystemsView = (props: ReviewOfSystemsViewProps) => (
  <div className="review-of-systems">
    {!props.isLocked ? (
      <>
        <h1>{SectionTitle.REVIEW_OF_SYSTEMS}</h1>
        <Box mt2>
          <ReviewOfSystemsEditView {...props} />
        </Box>
      </>
    ) : (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <ReviewOfSystemsReadonly selectedRecord={props.selectedRecord} />
      </>
    )}
  </div>
);

ReviewOfSystemsView.displayName = 'ReviewOfSystemsView';
export default ReviewOfSystemsView;

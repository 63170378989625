import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const ActivityView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="nutrition-hydration">
      <h1>{SectionTitle.ACTIVITY}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.ACTIVITY}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ACTIVITY_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ACTIVITY_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.AMBULATION_LOCOMOTION}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.AMBULATION_LOCOMOTION_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.AMBULATION_LOCOMOTION_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>
        <Box mt mb2 className="c-els-divider u-els-width-1o1" />
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.TURNING_RANGE_MOTION}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TURNING_RANGE_MOTION_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.TURNING_RANGE_MOTION_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

ActivityView.displayName = 'ActivityView';
export default ActivityView;

import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const EliminationView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <>
      <h1>Elimination</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.FUNCTIONAL_ABILITY}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.FUNCTIONAL_ABILITY)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.FUNCTIONAL_ABILITY_NOTES)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.ELIMINATION_NURSING_ACTIONS}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.ELIMINATION_NURSING_ACTIONS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ELIMINATION_NURSING_ACTIONS_NOTES)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </>
  );
};

EliminationView.displayName = 'EliminationView';
export default EliminationView;

import { PsychiatricHistoryFragment } from 'models/api-response';
import { Box, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import ReadOnlyInput from 'components/features/chart/admission-history/shared/ReadOnlyInput';
import { FormField, FormFieldLabel, SectionTitle } from './constant';

export interface PsychiatricHistoryReadOnlyProps {
  selectedRecord: PsychiatricHistoryFragment;
}

const PsychiatricHistoryReadonly = (props: PsychiatricHistoryReadOnlyProps) => {
  const record = props.selectedRecord;
  return (
    <>
      <h2>{SectionTitle.PSYCHIATRIC_HISTORY}</h2>
      <Box className="sng-article">
        {!record ? (
          <h3>Chart Omitted</h3>
        ) : (
          <>
            <h3 className="u-els-margin-bottom-2x">{SectionTitle.PATIENT_INFORMATION}</h3>
            <SectionDivider />
            <ELSFlex wrap left className="u-flex-gutter-3x u-flex-row-gap-2x">
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PATIENT_INFORMATION}
                  formFieldId={FormField.CHIEF_INFORMANT}
                  customTitle={FormFieldLabel.CHIEF_INFORMANT}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PATIENT_INFORMATION}
                  formFieldId={FormField.CHIEF_COMPLAINT}
                  customTitle={FormFieldLabel.CHIEF_COMPLAINT}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PATIENT_INFORMATION}
                  formFieldId={FormField.HISTORY_CURRENT_PROBLEM}
                  customTitle={FormFieldLabel.HISTORY_CURRENT_PROBLEM}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PATIENT_INFORMATION}
                  formFieldId={FormField.ALLERGIES}
                  customTitle={FormFieldLabel.ALLERGIES}
                />
              </ELSFlexItem>
            </ELSFlex>
            <h3 className="u-els-margin-bottom-2x">{SectionTitle.PSYCHIATRIC_HISTORY}</h3>
            <SectionDivider />
            <ELSFlex wrap left className="u-flex-gutter-3x u-flex-row-gap-2x">
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.UNABLE_ASSESS}
                  customTitle={FormFieldLabel.UNABLE_ASSESS}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.PAST_PSYCHIATRIC_HISTORY}
                  customTitle={FormFieldLabel.PAST_PSYCHIATRIC_HISTORY}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS}
                  customTitle={FormFieldLabel.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.SUICIDE_HISTORY}
                  customTitle={FormFieldLabel.SUICIDE_HISTORY}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.OUTPATIENT_TREATMENT}
                  customTitle={FormFieldLabel.OUTPATIENT_TREATMENT}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.ALCOHOL_USE}
                  customTitle={FormFieldLabel.ALCOHOL_USE}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.SUBSTANCE_USE}
                  customTitle={FormFieldLabel.SUBSTANCE_USE}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.ELECTROCONVULSIVE_THERAPY}
                  customTitle={FormFieldLabel.ELECTROCONVULSIVE_THERAPY}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                  formFieldId={FormField.FAMILY_HISTORY}
                  customTitle={FormFieldLabel.FAMILY_HISTORY}
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </>
  );
};
export default PsychiatricHistoryReadonly;

import cx from 'classnames';
import { NotGradedIcon } from 'components/common';
import AssessmentStatusDetails from './AssessmentStatusDetails';
import IncompletedAssessmentStatusBadge from './IncompletedAssessmentStatusBadge';
import './base-grade.scss';

export interface NotGradedGradeProps {
  className?: string;
  formattedDueDate: string;
  isCompleted?: boolean;
  assessmentStatus: string;
}

const NotGradedGrade = (props: NotGradedGradeProps) => {
  const { className, formattedDueDate, isCompleted, assessmentStatus } = props;

  return (
    <div className={cx('grade', className)}>
      <NotGradedIcon />
      <div className="legend">
        <span className="u-els-font-size-h3">{isCompleted ? 'Complete' : 'Incomplete'}</span>
        <IncompletedAssessmentStatusBadge assessmentStatus={assessmentStatus} />
        <AssessmentStatusDetails assessmentStatus={assessmentStatus} formattedDueDate={formattedDueDate} />
      </div>
    </div>
  );
};

export default NotGradedGrade;

import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import AdmissionDataReadOnly from './AdmissionDataReadOnly';

export interface AdmissionDataViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  patientContext: PatientContext;
}

const AdmissionDataView = ({
  chartActionsProps,
  isLocked,
  fragment,
  statusFragment,
  resetAll,
  formSubmittedCount,
  chartMetaFormFields,
  patientContext
}: AdmissionDataViewProps) => {
  return (
    <div className="admission-data">
      {isLocked ? (
        <AdmissionDataReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={patientContext} />
      ) : (
        <>
          <h1>{LanguageKeys.ADMISSION_DATA.ADMISSION_DATA}</h1>
          <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />

          <Box mt2>
            <h2 className="u-els-margin-bottom">This Admission</h2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ADMITTED_FROM)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ADMITTED_FROM_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ARRIVED_BY)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ADMISSION_ARRIVED_BY_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_PREGNANCY_RELATED)} formSubmittedCount={formSubmittedCount}>
            <h2 className="u-els-margin-bottom">{LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}</h2>
            <Box>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.PREGNANCY_RELATED)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                  <ErrorFormField
                    formFieldMap={chartMetaFormFields}
                    formField={chartMetaFormFields.get(FormField.PREGNANCY_RELATED_SELECT)}
                    formSubmittedCount={formSubmittedCount}
                  />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.PREGNANCY_RELATED_SELECT_OTHER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_CONTRACTIONS)} formSubmittedCount={formSubmittedCount}>
                    <h3>Contractions</h3>
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_TIME_OF_ONSET)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_FREQUENCY)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_DURATION)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_QUALITY)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_FLUID)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTRACTIONS_COLOR)} formSubmittedCount={formSubmittedCount} />
                  </ErrorFormField>
                </ELSFlexItem>
              </ELSFlex>
            </Box>
          </ErrorFormField>

          <SectionDivider />
          <h2 className="u-els-margin-bottom">Verbal Admission History Information Unavailable</h2>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.VERBAL_ADMISSION_HISTORY)} formSubmittedCount={formSubmittedCount} />

          <SectionDivider />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Past Admissions</h2>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PAST_ADMISSION_HOSPITAL)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PAST_ADMISSION_REASON)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PAST_ADMISSION_WHERE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Informant / Historian</h2>
              <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.INFORMANT_HISTORIAN_INFORMATION_FROM)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INFORMANT_HISTORIAN_NAME)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

AdmissionDataView.displayName = 'AdmissionDataView';
export default AdmissionDataView;

import { isBoolean } from 'lodash';
import { ChartFragment } from 'models/api-response';
import { Box, MultiLine } from 'components/common';
import './read.only.input.scss';

interface ReadOnlyInputProps {
  chartFragment: ChartFragment;
  sectionTitle?: string;
  formFieldId?: string;
  listFormFieldId?: string[];
  customTitle: string;
  notRenderBlankField?: boolean;
  customHeadingLevel?: 1 | 2 | 3 | 5 | 6;
}
const ReadOnlyInput = (props: ReadOnlyInputProps) => {
  const { chartFragment, sectionTitle, formFieldId, listFormFieldId, customTitle, notRenderBlankField, customHeadingLevel } = props;

  let records;

  if (sectionTitle) {
    const section = chartFragment.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
    records = section?.records?.filter((item) => item.formField === formFieldId || listFormFieldId?.includes(item.formField));
  } else {
    records = chartFragment?.chartData?.records?.filter((item) => item.formField === formFieldId);
  }

  let content = records?.map((item) => (isBoolean(item.content) ? <div key={item.id}>{item.title}</div> : <MultiLine key={item.id} text={item.content || '- -'} />));

  if (!content?.length) {
    if (notRenderBlankField) {
      return null;
    }
    content = <div>- -</div>;
  }

  const HeadingLevel = (customHeadingLevel ? `h${customHeadingLevel}` : 'h4') as keyof JSX.IntrinsicElements;

  return (
    <Box className="read-only-input" pt pb>
      <HeadingLevel>{customTitle}</HeadingLevel>
      {content}
    </Box>
  );
};

ReadOnlyInput.displayName = 'ReadOnlyInput';
export default ReadOnlyInput;

import cx from 'classnames';
import { ChartActionsComponentProps, ChartMetaFormField, MarRecord } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider, Sidebar } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { DOSE_MODE, FormField } from './constants';

export interface MarHoldAndDiscontinueFormSidebarViewProps {
  selectedDosageRecord: MarRecord;
  doseFormMode: string;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  isAuthor: boolean;
  sidebarProps: Function;
}

const MarHoldAndDiscontinueFormSidebarView = (props: MarHoldAndDiscontinueFormSidebarViewProps) => {
  const { selectedDosageRecord, doseFormMode, formFieldMap, formSubmittedCount, chartActionsProps, onCloseClick, isAuthor, sidebarProps } = props;
  const drugName = selectedDosageRecord?.drugName;
  const route = selectedDosageRecord?.route;
  const dose = selectedDosageRecord?.dose;
  const frequency = selectedDosageRecord?.frequency;
  const specialInstructions = selectedDosageRecord?.specialInstructions;
  const getTimeField = () => {
    if (isAuthor) return formFieldMap.get(FormField.DISCONTINUE_TIME_OFFSET);
    return formFieldMap.get(doseFormMode === DOSE_MODE.HOLD ? FormField.HOLD_TIME : FormField.DISCONTINUE_TIME);
  };
  return (
    <Sidebar
      open={(doseFormMode === DOSE_MODE.HOLD || doseFormMode === DOSE_MODE.DISCONTINUE) && !!selectedDosageRecord}
      onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
      sidebarProps={sidebarProps}
    >
      <Box mt3>
        {doseFormMode === DOSE_MODE.HOLD && <h2>Hold</h2>}
        {doseFormMode === DOSE_MODE.DISCONTINUE && <h2>Discontinue</h2>}
        <h3 className="u-els-margin-top u-els-margin-bottom-3o4">{drugName}</h3>
        {[route, dose, frequency].filter(Boolean).length > 0 && <span className="u-els-bold u-els-margin-right">{[route, dose, frequency].filter(Boolean).join(' · ')}</span>}
        {specialInstructions && <span className="u-els-italic u-els-color-n7">{specialInstructions}</span>}
      </Box>
      <SectionDivider />
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} className="u-els-margin-bottom" />
      <Box className="mar-dosage">
        <ELSFlex left wrap />
        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(doseFormMode === DOSE_MODE.HOLD ? FormField.HOLD_DATE : FormField.DISCONTINUE_DATE)}
              formSubmittedCount={formSubmittedCount}
              className="u-els-margin-bottom-1x1o2"
              inputProps={{ isDisabled: true }}
            />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              useDefaultMargin={false}
              formField={getTimeField()}
              formSubmittedCount={formSubmittedCount}
              className={cx('u-els-margin-bottom-1x1o2', { 'mar-dosage__input-offset-time': isAuthor })}
            />
          </ELSFlexItem>
        </ELSFlex>
        <ErrorFormField
          formField={formFieldMap.get(doseFormMode === DOSE_MODE.HOLD ? FormField.HOLD_COMMENTS : FormField.DISCONTINUE_COMMENTS)}
          formSubmittedCount={formSubmittedCount}
          useDefaultMargin={false}
          className="u-els-margin-bottom-1x1o2"
        />
        <ELSFlex className="o-els-flex-layout-gutters-1o2">
          <ELSFlexItem>
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(doseFormMode === DOSE_MODE.HOLD ? FormField.HOLD_FIRST_INITIAL : FormField.DISCONTINUE_FIRST_INITIAL)}
              formSubmittedCount={formSubmittedCount}
              className="mar-dosage__initials u-els-margin-bottom-1x1o2 u-els-margin-right-2x"
            />
          </ELSFlexItem>
          <ELSFlexItem grow>
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(doseFormMode === DOSE_MODE.HOLD ? FormField.HOLD_LAST_INITIAL : FormField.DISCONTINUE_LAST_INITIAL)}
              formSubmittedCount={formSubmittedCount}
              className="mar-dosage__initials u-els-margin-bottom-1x1o2"
            />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </Sidebar>
  );
};

MarHoldAndDiscontinueFormSidebarView.displayName = 'MarHoldAndDiscontinueFormSidebarView';
export default MarHoldAndDiscontinueFormSidebarView;

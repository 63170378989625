export const chartConfig = {
  labelColor: '#505050',
  dataColors: ['#00c1ff', '#00327f', 'transparent'],
  line: {
    defaultWidth: 500,
    defaultHeight: 300,
    defaultMargin: { top: 5, right: 5, bottom: 30, left: 10 },
    defaultLegendHeight: 50
  },
  bar: {
    defaultWidth: 500,
    defaultHeight: 300,
    defaultMargin: { top: 5, right: 5, bottom: 30, left: 10 },
    defaultLegendHeight: 50
  }
};

export enum FormField {
  TEACHING_IS_COMPLETE = 'teachingComplete',
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory',
  TEACHING_TOPICS = 'teachingTopics',
  TOPICS_TAUGHT = 'topicsTaught',
  PERSONS_TAUGHT = 'personsTaught',
  TEACHING_METHODS = 'teachingMethods',
  LEARNING_BARRIERS = 'learningBarriers',
  OUTCOME = 'outcome',
  ADDITIONAL_COMMENTS = 'additionalComments',
  TEACHING_TOPICS_OTHER = 'teachingTopicsOther',
  TOPICS_TAUGHT_OTHER = 'topicsTaughtOther'
}

export enum SectionTitle {
  PATIENT_TEACHING = 'Patient Teaching',
  CATEGORY = 'Category',
  SUB_CATEGORY = 'Sub Category',
  TEACHING_TOPICS = 'Teaching Topics',
  TOPICS_TAUGHT = 'Topics Taught',
  PERSONS_TAUGHT = 'Person(s) Taught',
  TEACHING_METHODS = 'Teaching Method(s)',
  LEARNING_BARRIERS = 'Learning Barriers',
  OUTCOME = 'Outcome'
}

import { MedicationFragment } from 'models/api-response';
import { ChartMetaFormField } from 'models/ui';
import { LastElement } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ErrorFormField, Sidebar } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';

export interface EditMedicationSidebarViewProps {
  open: boolean;
  medication: MedicationFragment;
  formSubmittedCount: number;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  onSaveClick: Function;
  onCloseClick: Function;
  sidebarProps: Function;
}
const EditMedicationSidebarView = (props: EditMedicationSidebarViewProps) => {
  const { open, medication, chartMetaFormFields, formSubmittedCount, onSaveClick, onCloseClick, sidebarProps } = props;
  const medicationText = medication?.chartData?.medicationOther || medication?.chartData?.medication;
  return (
    <Sidebar
      open={open}
      onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
      lastEnabledElement={LastElement.ENABLED_SAVE_BUTTON}
      sidebarProps={sidebarProps}
    >
      <Box mt3>
        <h2>Edit Medication</h2>
        <h3 className="u-els-margin-top">{medicationText}</h3>
      </Box>
      <div className="sidebar__divider" />
      <ELSFlex column>
        <ErrorFormField formField={chartMetaFormFields.get(FormField.CLASSIFICATION)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.CLASSIFICATION_OTHER)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.ROUTE)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.ROUTE_OTHER)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.DOSE)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.FREQUENCY)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.DATE_ORDERED)} formSubmittedCount={formSubmittedCount} />
      </ELSFlex>
      <ELSFlex className="u-flex-grid-2x" wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.COMMENT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.THERAPEUTIC_EFFECT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.ACTION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.CONTRAINDICATIONS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.SIDE_EFFECT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.LIFE_THREATENING)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.RECOMMENDED)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.NURSING_INTERVENTIONS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <Box mt3 mb>
        <ELSButton className="sng-button u-els-margin-right" text="Save" onClick={onSaveClick} />
      </Box>
    </Sidebar>
  );
};

EditMedicationSidebarView.displayName = 'EditMedicationSidebarView';
export default EditMedicationSidebarView;

import cx from 'classnames';
import { ChartFragment } from 'models/api-response';
import { ButtonLinkIcon, ChartHistory } from 'components/common';
import { FormField, SectionTitle } from './constants';

export interface PreviousPregnanciesProps {
  isLocked?: boolean;
  chartHistory: ChartFragment[];
  onDeleteClick?: Function;
}

export const customRender = (field) => (row) => {
  return <div className={cx({ 'row--deleted': !row.active })}>{row[field] || ''}</div>;
};

const PreviousPregnancies = (props: PreviousPregnanciesProps) => {
  const howLongAgoRenderer = ({ id, active, howLongAgo }) => {
    const hasAction = active && !props.isLocked;
    return (
      <div className="u-els-display-flex">
        <div className={cx({ 'row--deleted': !active })}>{howLongAgo || ''}</div>
        {hasAction && (
          <ButtonLinkIcon className="u-els-margin-left" iconName="trash" iconPrefix="gizmo" iconSize="1x" text="" onClick={() => props.onDeleteClick(id)} isDisabled={!active} />
        )}
      </div>
    );
  };

  const dataSource = {
    headers: [
      {
        text: 'How long ago?',
        field: FormField.HOW_LONG_AGO,
        section: SectionTitle.NO_SECTION,
        customRender: howLongAgoRenderer
      },
      {
        text: 'Gestational Weeks',
        field: FormField.GESTATIONAL_WEEKS,
        section: SectionTitle.PREVIOUS_PREGNANCIES,
        customRender: customRender(FormField.GESTATIONAL_WEEKS)
      },
      {
        text: 'Type of Delivery',
        field: FormField.TYPE_OF_DELIVERY,
        section: SectionTitle.PREVIOUS_PREGNANCIES,
        customRender: customRender(FormField.TYPE_OF_DELIVERY)
      },
      {
        text: 'Duration of Labor',
        field: FormField.DURATION_OF_LABOR,
        section: SectionTitle.PREVIOUS_PREGNANCIES,
        customRender: customRender(FormField.DURATION_OF_LABOR)
      },
      {
        text: 'Complications',
        field: FormField.COMPLICATIONS,
        section: SectionTitle.PREVIOUS_PREGNANCIES,
        customRender: customRender(FormField.COMPLICATIONS)
      },
      {
        text: 'Outcome',
        field: FormField.OUTCOME,
        section: SectionTitle.PREVIOUS_PREGNANCIES,
        customRender: customRender(FormField.OUTCOME)
      }
    ],
    data: props.chartHistory
  };
  return (
    <div>
      <ChartHistory noTableDataMessage=" " renderVertical tableTitleContents={[SectionTitle.NO_SECTION, SectionTitle.PREVIOUS_PREGNANCIES]} dataSource={dataSource} />
    </div>
  );
};

export default PreviousPregnancies;

import { ChartFragment } from 'models/api-response';
import { getFragmentValue } from 'helpers/chart.helper';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './RoleRelationship';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface RoleRelationshipReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const RoleRelationshipReadOnly = (props: RoleRelationshipReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;
  const isRolePerformanceOther = getFragmentValue(chartFragment, SectionTitle.ROLE_RELATIONSHIP, FormField.ROLE_PERFORMANCE) === 'rolePerformanceOther';
  const isFamilyProcessesOther = getFragmentValue(chartFragment, SectionTitle.ROLE_RELATIONSHIP, FormField.FAMILY_PROCESSES) === 'familyProcessesOther';
  const isCareGiverRoleOther = getFragmentValue(chartFragment, SectionTitle.ROLE_RELATIONSHIP, FormField.CAREGIVER_ROLE) === 'caregiverRoleOther';
  const isSocialInteractionsOther = getFragmentValue(chartFragment, SectionTitle.ROLE_RELATIONSHIP, FormField.SOCIAL_INTERACTIONS) === 'socialInteractionsOther';
  const isPatientContextMale = true; // default for patient context

  return (
    <Box className="role-relationship-read-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Role - Relationship</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                  formFieldId={FormField.MARTIAL_PARTNER_STATUS}
                  customTitle="Marital/Partner Status"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                  formFieldId={isRolePerformanceOther ? FormField.ROLE_PERFORMANCE_OTHER : FormField.ROLE_PERFORMANCE}
                  customTitle="Role Performance"
                />

                {!isPatientContextMale && (
                  <>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                      formFieldId={FormField.SOCIAL_RESOURCES}
                      customTitle="Social Resources"
                    />
                  </>
                )}
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                  formFieldId={isFamilyProcessesOther ? FormField.FAMILY_PROCESSES_OTHER : FormField.FAMILY_PROCESSES}
                  customTitle="Family Processes"
                />
                {!isPatientContextMale && (
                  <>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                      formFieldId={FormField.READINESS_NEWBORN}
                      customTitle="Readiness for Newborn"
                    />
                  </>
                )}
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                  formFieldId={isSocialInteractionsOther ? FormField.SOCIAL_INTERACTIONS_OTHER : FormField.SOCIAL_INTERACTIONS}
                  customTitle="Social Interactions"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                  formFieldId={isCareGiverRoleOther ? FormField.CAREGIVER_ROLE_OTHER : FormField.CAREGIVER_ROLE}
                  customTitle="Caregiver Role"
                />
                {!isPatientContextMale && (
                  <>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.ROLE_RELATIONSHIP}
                      formFieldId={FormField.FEELING_READINESS}
                      customTitle="Feeling of Readiness for Newborn Care"
                    />
                  </>
                )}
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

RoleRelationshipReadOnly.displayName = 'RoleRelationshipReadOnly';
export default RoleRelationshipReadOnly;

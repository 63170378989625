import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSaveAndDiscardHandlers from 'components/common/with-chart-logic/withSaveAndDiscardHandlers';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, Section } from './constants';
import BallardMaturationalAssessmentView, { BallardMaturationalAssessmentViewProps } from './BallardMaturationalAssessmentView';

class BallardMaturationalAssessment extends Component<ChartComponentProps> {
  static displayName = 'BallardMaturationalAssessment';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<ChartComponentProps>) {
    if (prevProps.isEmbededSidebarOpen !== this.props.isEmbededSidebarOpen) {
      this.props.sidebarProps().setIsChartSidebarOpen(this.props.isEmbededSidebarOpen);
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const textBoxes = [
      {
        name: FormField.BIRTH_WEIGHT,
        label: FormFieldLabel.BIRTH_WEIGHT
      },
      {
        name: FormField.LENGTH,
        label: FormFieldLabel.LENGTH
      },
      {
        name: FormField.TIME_OF_BIRTH,
        label: FormFieldLabel.TIME_OF_BIRTH
      },
      {
        name: FormField.TIME_OF_EXAM,
        label: FormFieldLabel.TIME_OF_EXAM
      },
      {
        name: FormField.AGE_WHEN_EXAMINED,
        label: FormFieldLabel.AGE_WHEN_EXAMINED
      },
      {
        name: FormField.BY_WEEKS,
        label: FormFieldLabel.BY_WEEKS
      },
      {
        name: FormField.BY_DATES,
        label: FormFieldLabel.BY_DATES
      },
      {
        name: FormField.BY_EXAM,
        label: FormFieldLabel.BY_EXAM
      },
      {
        name: FormField.BY_ULTRASOUND,
        label: FormFieldLabel.BY_ULTRASOUND
      }
    ];
    const dropdowns = [
      {
        name: FormField.APGAR_SCORE_1_MINUTE,
        label: FormFieldLabel.APGAR_SCORE_1_MINUTE
      },
      {
        name: FormField.APGAR_SCORE_5_MINUTE,
        label: FormFieldLabel.APGAR_SCORE_5_MINUTE
      },
      {
        name: FormField.APGAR_SCORE_10_MINUTE,
        label: FormFieldLabel.APGAR_SCORE_10_MINUTE
      }
    ];
    const scales = [
      {
        name: FormField.NEUROMUSCULAR_MATURITY,
        errorLabel: Section.NEUROMUSCULAR_MATURITY
      },
      {
        name: FormField.PHYSICAL_MATURITY,
        errorLabel: Section.PHYSICAL_MATURITY
      }
    ];
    const dates = [
      {
        name: FormField.DAY_OF_BIRTH,
        label: FormFieldLabel.DAY_OF_BIRTH
      },
      {
        name: FormField.DAY_OF_EXAM,
        label: FormFieldLabel.DAY_OF_EXAM
      }
    ];

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    dropdowns.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.DROPDOWN })));
    scales.forEach(({ name, errorLabel }) => dataMap.set(name, createFormField({ name, errorLabel, type: FormFieldInputType.SCALE })));
    dates.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.DATE })));

    return dataMap;
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.BALLARD_MATURATIONAL_ASSESSMENT,
      records: [
        {
          sectionTitle: Section.BALLARD_MATURATIONAL_ASSESSMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.BIRTH_WEIGHT),
            buildPatientRecord(formFieldMap, FormField.LENGTH),
            buildPatientRecord(formFieldMap, FormField.DAY_OF_BIRTH),
            buildPatientRecord(formFieldMap, FormField.TIME_OF_BIRTH),
            buildPatientRecord(formFieldMap, FormField.DAY_OF_EXAM),
            buildPatientRecord(formFieldMap, FormField.TIME_OF_EXAM),
            buildPatientRecord(formFieldMap, FormField.AGE_WHEN_EXAMINED),
            buildPatientRecord(formFieldMap, FormField.APGAR_SCORE_1_MINUTE),
            buildPatientRecord(formFieldMap, FormField.APGAR_SCORE_5_MINUTE),
            buildPatientRecord(formFieldMap, FormField.APGAR_SCORE_10_MINUTE)
          ]
        },
        {
          sectionTitle: Section.GESTATIONAL_AGE,
          records: [
            buildPatientRecord(formFieldMap, FormField.BY_WEEKS),
            buildPatientRecord(formFieldMap, FormField.BY_DATES),
            buildPatientRecord(formFieldMap, FormField.BY_ULTRASOUND),
            buildPatientRecord(formFieldMap, FormField.BY_EXAM)
          ]
        },
        {
          sectionTitle: Section.NEUROMUSCULAR_MATURITY,
          records: [...buildPatientRecords(formFieldMap, FormField.NEUROMUSCULAR_MATURITY, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_NEUROMUSCULAR_MATURITY_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.NEUROMUSCULAR_MATURITY,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.NEUROMUSCULAR_MATURITY))?.score?.value,
              linkedFormFieldIds: [FormField.POSTURE, FormField.SQUARE_WINDOW_WRIST, FormField.ARM_RECOIL, FormField.POPLITEAL_ANGLE, FormField.SCARF_SIGN, FormField.HEEL_TO_EAR]
            })
          ]
        },
        {
          sectionTitle: Section.PHYSICAL_MATURITY,
          records: [...buildPatientRecords(formFieldMap, FormField.PHYSICAL_MATURITY, false, true, true)]
        },
        {
          sectionTitle: Section.TOTAL_PHYSICAL_MATURITY_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.PHYSICAL_MATURITY,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.PHYSICAL_MATURITY))?.score?.value,
              linkedFormFieldIds: [
                FormField.SKIN,
                FormField.LANUGO,
                FormField.PLANTAR_SURFACE,
                FormField.BREAST,
                FormField.EYE_EAR,
                FormField.GENITALS_MALE,
                FormField.GENITALS_FEMALE
              ]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  render() {
    const { formFieldMap, formSubmittedCount, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, isEmbeddedChart } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: BallardMaturationalAssessmentViewProps = {
      chartActionsProps,
      formSubmittedCount,
      formFieldMap,
      isEmbeddedChart
    };
    return <BallardMaturationalAssessmentView {...viewProps} />;
  }
}

export { BallardMaturationalAssessment as BaseBallardMaturationalAssessment };
export const BallardMaturationalAssessmentStandalone = compose(withSavedPatientChartsPage, withChartLogic)(BallardMaturationalAssessment);
export default compose(withSavedPatientChartsPage, withSaveAndDiscardHandlers)(BallardMaturationalAssessment);

import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import { LoadableChartViewProps } from '../../shared/constants';

const PatientTeachingView = (props: LoadableChartViewProps) => {
  const { fragment, chartActionsProps, formFieldMap, formSubmittedCount } = props;
  return (
    <div className="patient-teaching">
      <h1>{SectionTitle.PATIENT_TEACHING}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField useDefaultMargin={false} formField={formFieldMap.get(FormField.TEACHING_IS_COMPLETE)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.CATEGORY)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ isDisabled: !!fragment }}
            />
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.SUB_CATEGORY)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ isDisabled: !!fragment }}
            />
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.TEACHING_TOPICS)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ isDisabled: !!fragment }}
            />
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.TEACHING_TOPICS_OTHER)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ isDisabled: !!fragment }}
            />
            <h4>{SectionTitle.TOPICS_TAUGHT}</h4>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TOPICS_TAUGHT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TOPICS_TAUGHT_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h4>{SectionTitle.PERSONS_TAUGHT}</h4>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PERSONS_TAUGHT)} formSubmittedCount={formSubmittedCount} />
            <h4>{SectionTitle.TEACHING_METHODS}</h4>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TEACHING_METHODS)} formSubmittedCount={formSubmittedCount} />
            <h4>{SectionTitle.LEARNING_BARRIERS}</h4>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.LEARNING_BARRIERS)} formSubmittedCount={formSubmittedCount} />
            <h4>{SectionTitle.OUTCOME}</h4>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OUTCOME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ADDITIONAL_COMMENTS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

PatientTeachingView.displayName = 'PatientTeachingView';
export default PatientTeachingView;

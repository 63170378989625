import { LanguageKeys } from 'lang';
import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

const RestraintsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="restraints">
      <h1>{LanguageKeys.RESTRAINTS.RESTRAINTS}</h1>
      <Box mt2>
        <h2>{LanguageKeys.RESTRAINTS.MONITORING_INTERVENTIONS_AND_VISUAL_CHECK}</h2>
        <Box p mb2 mt className="c-els-card c-els-card--border-primary">
          <ELSFlex className="u-els-margin-bottom-1o2">
            <ELSFlexItem className="u-els-margin-right">
              <ELSIcon prefix="gizmo" name="information" />
            </ELSFlexItem>
            <ELSFlexItem grow>
              <p>
                <strong>For violent/self-destructive behavior restraints (Behavioral): </strong>
                Reevaluation and documentation is required every 15 minutes.
              </p>
            </ELSFlexItem>
          </ELSFlex>
          <p className="u-els-margin-bottom">
            <strong>For nonviolent/nonself-destructive behavior restraints (Nonbehavioral): </strong>
            Reevaluation and documentation is required every 2 hours.
          </p>
          <p>
            <strong>For all patients: </strong>
            Document the patient&rsquo;s response to restraints, plan of care, education, and changes in behavior/mental status. Update plan of care as necessary.
          </p>
        </Box>
      </Box>
      <Box>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{LanguageKeys.RESTRAINTS.INITIATION}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INITIATION_DAY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.INITIATION_TIME)} formSubmittedCount={formSubmittedCount} />
            <h3>{LanguageKeys.RESTRAINTS.SPECIAL_PRECAUTIONS}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SPECIAL_PRECAUTIONS)} formSubmittedCount={formSubmittedCount} />
            <h3>{LanguageKeys.RESTRAINTS.PROVIDER_NOTIFICATION}</h3>
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.PROVIDER_NOTIFICATION_RADIO)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PROVIDER_NOTIFICATION_DAY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.PROVIDER_NOTIFICATION_TIME)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{LanguageKeys.RESTRAINTS.DISCONTINUATION}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DISCONTINUATION_DAY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.DISCONTINUATION_TIME)} formSubmittedCount={formSubmittedCount} />
            <h3>{LanguageKeys.RESTRAINTS.CRITERIA_MET_FOR_RESTRAINT}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CRITERIA_MET_FOR_RESTRAINT_RADIO)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CRITERIA_MET_FOR_RESTRAINT)} formSubmittedCount={formSubmittedCount} />
            <h3>{LanguageKeys.RESTRAINTS.ALTERNATIVE_METHODS_UTILIZED_PRIOR}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ALTERNATIVE_METHODS_UTILIZED_PRIOR)} formSubmittedCount={formSubmittedCount} />
            <h3>{LanguageKeys.RESTRAINTS.TYPE_OF_RESTRAINT}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE_OF_RESTRAINT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RESTRAINT_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{LanguageKeys.RESTRAINTS.ASSESSMENT_DURING_USE_OF_RESTRAINTS}</h2>
        <Box mt>
          <ELSFlex className="u-flex-gutter-3x" left wrap>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h3>{LanguageKeys.RESTRAINTS.CONTINUED_NEED_FOR_RESTRAINTS}</h3>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CONTINUED_NEED_FOR_RESTRAINTS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h3>{LanguageKeys.RESTRAINTS.SKIN_CIRCULATION}</h3>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SKIN_CIRCULATION)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h3>{LanguageKeys.RESTRAINTS.FLUID_NUTRITION}</h3>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FLUID_NUTRITION)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h3>{LanguageKeys.RESTRAINTS.ELIMINATION}</h3>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ELIMINATION)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h3>{LanguageKeys.RESTRAINTS.BEHAVIORAL_MENTAL_STATUS}</h3>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BEHAVIORAL_MENTAL_STATUS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SPECIAL_PRECAUTIONS_RESTRAINTS_NOTES)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

RestraintsView.displayName = 'RestraintsView';
export default RestraintsView;

import { ChartFragment } from 'models/api-response';
import { GenderPatientContext } from 'models/enum';
import { PatientContext } from 'models/ui';
import { PEDIATRIC_MAX_AGE_IN_DAYS, WOMAN_HEALTH_MIN_AGE_IN_DAYS } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, Labels, SectionTitle } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

interface PlanningHospitalStayReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
}

const PlanningHospitalStayReadOnly = (props: PlanningHospitalStayReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext } = props;
  const isPediatric = patientContext?.ageInDays < PEDIATRIC_MAX_AGE_IN_DAYS;
  const isWomenHealth = patientContext?.ageInDays >= WOMAN_HEALTH_MIN_AGE_IN_DAYS && patientContext?.gender === GenderPatientContext.FEMALE;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Planning For Hospital Stay</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o4" md="1o1">
                {isPediatric && (
                  <>
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.DURING_HOSPITALIZATION} customTitle={Labels.DURING_HOSPITALIZATION} />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      formFieldId={FormField.DURING_HOSPITALIZATION_NAME}
                      customTitle={Labels.DURING_HOSPITALIZATION_NAME}
                      notRenderBlankField
                    />
                  </>
                )}
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.COMMUNICATE} customTitle={Labels.COMMUNICATE} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.COMMUNICATE_OTHER} customTitle={Labels.OTHERS} notRenderBlankField />
                {!isPediatric && (
                  <>
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PROFESSION_OCCUPATION} customTitle={Labels.PROFESSION_OCCUPATION} />
                  </>
                )}
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CONTACT_PERSON} customTitle={Labels.CONTACT_PERSON} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CONTACT_PERSON_NAME} customTitle={Labels.CONTACT_PERSON_NAME} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CONTACT_PERSON_PHONE} customTitle={Labels.CONTACT_PERSON_PHONE} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PROBLEM_EXISTED} customTitle={Labels.PROBLEM_EXISTED} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PROBLEM_EXISTED_OTHER} customTitle={Labels.OTHERS} notRenderBlankField />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.ADMITTED_HOSPITAL} customTitle={Labels.ADMITTED_HOSPITAL} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.HEALTH_PROBLEM_HOME} customTitle={Labels.HEALTH_PROBLEM_HOME} />
                {!isPediatric && (
                  <>
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CIRCUMSTANCE_ADMITTED_HOSPITAL} customTitle={Labels.CIRCUMSTANCE_ADMITTED_HOSPITAL} />
                  </>
                )}
              </ELSFlexItem>
              <ELSFlexItem w="1o4" md="1o1">
                {!isPediatric && (
                  <>
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CAN_READ} customTitle={Labels.CAN_READ} />
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CAN_WRITE} customTitle={Labels.CAN_WRITE} />
                  </>
                )}
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.BARRIERS_LEARNING} customTitle={Labels.BARRIERS_LEARNING} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.BARRIERS_LEARNING_PROBLEMS} customTitle={Labels.BARRIERS_LEARNING_PROBLEMS} />
              </ELSFlexItem>
              <ELSFlexItem w="1o4" md="1o1">
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PRIMARY_LANGUAGE} customTitle={Labels.PRIMARY_LANGUAGE} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PRIMARY_LANGUAGE_OTHER} customTitle={Labels.OTHERS} notRenderBlankField />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PRIMARY_LANGUAGE_NEED_TRANSLATOR} customTitle={Labels.PRIMARY_LANGUAGE_NEED_TRANSLATOR} />
                {!isPediatric && (
                  <>
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.HAVE_LIVING} customTitle={Labels.HAVE_LIVING} />
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.LIVING_AVAILABLE} customTitle={Labels.LIVING_AVAILABLE} />
                  </>
                )}
              </ELSFlexItem>
              <ELSFlexItem w="1o4" md="1o1">
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PREFER_INSTRUCTIONS} customTitle={Labels.PREFER_INSTRUCTIONS} />
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PREFER_INSTRUCTIONS_OTHER} customTitle={Labels.OTHERS} notRenderBlankField />
                {!isPediatric && (
                  <>
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.EDUCATION_LEVEL} customTitle={Labels.EDUCATION_LEVEL} />
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.EDUCATION_LEVEL_OTHER} customTitle={Labels.OTHERS} notRenderBlankField />
                  </>
                )}
                <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.VISITOR_RESTRICTIONS} customTitle={Labels.VISITOR_RESTRICTIONS} />
              </ELSFlexItem>
            </ELSFlex>
            {isPediatric && (
              <Box mb2>
                <h3>{SectionTitle.QUESTION_PARENT}</h3>
                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o4" md="1o1">
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.QUESTION_PARENT_NEED_TRANSLATOR} customTitle={Labels.PRIMARY_LANGUAGE_NEED_TRANSLATOR} />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o4" md="1o1">
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS} customTitle={Labels.PREFER_INSTRUCTIONS} />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      formFieldId={FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS_OTHER}
                      customTitle={Labels.OTHERS}
                      notRenderBlankField
                    />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o4" md="1o1">
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.QUESTION_PARENT_EDUCATION_LEVEL} customTitle={Labels.EDUCATION_LEVEL} />
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.QUESTION_PARENT_EDUCATION_LEVEL_OTHER} customTitle={Labels.OTHERS} notRenderBlankField />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o4" md="1o1">
                    <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.QUESTION_PARENT_BARRIERS_LEARNING} customTitle={Labels.BARRIERS_LEARNING} />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      formFieldId={FormField.QUESTION_PARENT_BARRIERS_LEARNING_PROBLEMS}
                      customTitle={Labels.BARRIERS_LEARNING_PROBLEMS}
                    />
                  </ELSFlexItem>
                </ELSFlex>
              </Box>
            )}
            {isWomenHealth && (
              <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <h2>{SectionTitle.PLANNING_FOR_LABOR}</h2>
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.ATTEND_PRENATAL_CLASSES} customTitle={Labels.ATTEND_PRENATAL_CLASSES} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h2>{SectionTitle.PLANS_FOR_BIRTH}</h2>
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.NAMES_PERSONS_TO_BE_PRESENT} customTitle={Labels.NAMES_PERSONS_TO_BE_PRESENT} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.DOCUMENTED_BIRTH_PLAN} customTitle={Labels.DOCUMENTED_BIRTH_PLAN} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.TUBAL_LIGATION_PLANNED} customTitle={Labels.TUBAL_LIGATION_PLANNED} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PLANS_FOR_BIRTH_CONSENT_SIGNED} customTitle={Labels.CONSENT_SIGNED} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.ANESTHESIA_DESIRED} customTitle={Labels.ANESTHESIA_DESIRED} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.SPECIFIC_REQUESTS_DURING_LABOR} customTitle={Labels.SPECIFIC_REQUESTS_DURING_LABOR} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h2>{SectionTitle.NEWBORN_PLANS}</h2>
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.PEDIATRICIAN_NAME} customTitle={Labels.PEDIATRICIAN_NAME} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.CIRCUMCISION_PLANNED} customTitle={Labels.CIRCUMCISION_PLANNED} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.NEWBORN_PLANS_CONSENT_SIGNED} customTitle={Labels.CONSENT_SIGNED} />
                  <ReadOnlyInput chartFragment={chartFragment} formFieldId={FormField.NEWBORN_NUTRITION} customTitle={Labels.NEWBORN_NUTRITION} />
                </ELSFlexItem>
              </ELSFlex>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

PlanningHospitalStayReadOnly.displayName = 'PlanningHospitalStayReadOnly';
export default PlanningHospitalStayReadOnly;

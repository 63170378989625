import { delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment, ReviewOfSystemsFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { buildPatientRecord } from 'services/chart.service';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import ReviewOfSystemsView, { ReviewOfSystemsViewProps } from './ReviewOfSystemsView';
import { FormField, FormFieldLabel } from './constant';

export interface ReviewOfSystemsProps extends ChartComponentProps {
  assessment: AssessmentRS;
}

export interface ReviewOfSystemsState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ReviewOfSystemsFragment;
}

export const TEXT_AREAS = [
  {
    name: FormField.INTEGUMENT,
    label: FormFieldLabel.INTEGUMENT
  },
  {
    name: FormField.HEENT,
    label: FormFieldLabel.HEENT
  },
  {
    name: FormField.CARDIOVASCULAR,
    label: FormFieldLabel.CARDIOVASCULAR
  },
  {
    name: FormField.RESPIRATORY,
    label: FormFieldLabel.RESPIRATORY
  },
  {
    name: FormField.GASTROINTESTINAL,
    label: FormFieldLabel.GASTROINTESTINAL
  },
  {
    name: FormField.GENITOURINARY,
    label: FormFieldLabel.GENITOURINARY
  },
  {
    name: FormField.MUSCULOSKELETAL,
    label: FormFieldLabel.MUSCULOSKELETAL
  },
  {
    name: FormField.NEUROLOGIC,
    label: FormFieldLabel.NEUROLOGIC
  },
  {
    name: FormField.DEVELOPMENTAL,
    label: FormFieldLabel.DEVELOPMENTAL
  },
  {
    name: FormField.ENDOCRINE,
    label: FormFieldLabel.ENDOCRINE
  },
  {
    name: FormField.GENITALIA,
    label: FormFieldLabel.GENITALIA
  },
  {
    name: FormField.LYMPHATIC,
    label: FormFieldLabel.LYMPHATIC
  }
];
export const buildFormFields = (record?: ChartFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();

  TEXT_AREAS.forEach(({ name, label }) =>
    dataMap.set(
      name,
      createFormField({
        name,
        type: FormFieldInputType.TEXT_AREA,
        label,
        value: record?.chartData?.[name]?.content || record?.chartData?.reviews?.[name]
      })
    )
  );

  return dataMap;
};

class ReviewOfSystems extends Component<ReviewOfSystemsProps, ReviewOfSystemsState> {
  static displayName = 'ReviewOfSystems';

  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      statusFragment: null,
      selectedRecord: null
    };
  }

  componentDidMount() {
    const { assessment, isAuthor } = this.props;

    if (!isAuthor) {
      const navIds = [NAV_ID.HISTORY_PHYSICAL, NAV_ID.REVIEW_OF_SYSTEMS, NAV_ID.MEDICAL_HISTORY];
      const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];

      appHelper.useLoader(
        chartService
          .loadFragments({
            chartId: assessment.simChartId,
            navIds,
            fragmentTypes
          })
          .then(this.bindChartHistory),
        {
          errorMessage: 'can not load chart fragment'
        }
      );
    }

    this.props.initState(buildFormFields());
  }

  bindChartHistory = ({ data: fragments }) => {
    const selectedRecord = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.REVIEW_OF_SYSTEMS, NAV_ID.HISTORY_PHYSICAL);
    this.setState({
      selectedRecord,
      statusFragment,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(buildFormFields(selectedRecord));
  };

  buildFragment = () => {
    const { formFieldMap, chartingTime } = this.props;
    const chartData = Object.fromEntries(TEXT_AREAS.map(({ name }) => [name, buildPatientRecord(formFieldMap, name)]));
    return {
      ...this.state.selectedRecord,
      ...chartService.createBaseFragment({ chartingTime }),
      chartData
    };
  };

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: buildFormFields(), afterSave: this.afterSave });
  };

  afterSave = () =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.state.isLocked,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };
    const viewProps: ReviewOfSystemsViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartActionsProps
    };
    return <ReviewOfSystemsView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];
export { ReviewOfSystems as BaseReviewOfSystems };
export default compose(...enhancers)(ReviewOfSystems);

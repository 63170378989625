import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { FormField } from './constants';

export interface MorseFallScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  contextDirectionMap?: Map<string, ScaleDirection>;
  chartActionsProps: ChartActionsComponentProps;
}

export enum SectionTitle {
  MORSE_FALL_SCALE = 'Morse Fall Scale'
}

const MorseFallScaleView = (props: MorseFallScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, contextDirectionMap, chartActionsProps } = props;

  return (
    <div className="morse-fall-scale">
      <h1 className="u-els-margin-top">{SectionTitle.MORSE_FALL_SCALE}</h1>
      <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.MORSE_FALL_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap }}
      />
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

MorseFallScaleView.displayName = 'MorseFallScaleView';
export default MorseFallScaleView;

export enum SectionTitle {
  POSTPARTUM_ASSESSMENT = 'Postpartum Assessment',
  POSTPARTUM = 'Postpartum',
  BEHAVIORAL = 'Behavioral',
  BREASTS = 'Breasts',
  LATCH_BREAST_FEEDING_ASSESSMENT = 'LATCH Breastfeeding Assessment',
  LATCH_SCORE = 'LATCH Score',
  FUNDUS = 'Fundus',
  PERINEUM = 'Perineum',
  PERINEUM_COLOR = 'Perineum Color',
  PERINEUM_OTHER = 'Perineum Other',
  LOCHIA = 'Lochia',
  RECTUM = 'Rectum',
  TOTAL_PSYCHOSOCIAL_SCORE = 'Total Psychosocial Score',
  TOTAL_EDINBURGH_POSTNATAL_DEPRESSION_SCORE = 'Total Edinburgh Postnatal Depression Score',
  TOTAL_SCORE = 'Total Score'
}

export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'assessmentNotRequired',
  ROOMING_STATUS = 'roomingInStatus',
  SIGNS_DEPRESSION_SADNESS = 'signsOfDepressionOrSadness',
  BREAST_FEEDING_RADIO = 'breastfeedingRadio',
  BREAST_FEEDING_CHECKBOX = 'breastfeedingCheckbox',
  LATCH_BREAST_FEEDING_ASSESSMENT_SCALE = 'latchBreastfeedingAssessmentScale',
  CONTAINER_LATCH_BREAST_FEEDING_ASSESSMENT_SCALE = 'containerLatchBreastfeedingAssessmentScale',
  FUNDUS_CHARACTERISTICS = 'fundusCharacteristics',
  FUNDUS_POSITION = 'fundusPosition',
  FUNDUS_POSITION_DEVIATED = 'fundusPositionDeviated',
  FUNDUS_HEIGHT = 'fundusHeight',
  LOCHIA_COLOR = 'lochiaColor',
  LOCHIA_QUANTITY = 'lochiaQuantity',
  LOCHIA_QUANTITY_OTHER = 'lochiaQuantityOther',
  PERINEUM_EDEMA = 'perineumEdema',
  PERINEUM_INCISION_LACERATION = 'perineumIncisionOrLaceration',
  PERINEUM_COLOR = 'perineumColor',
  PERINEUM_OTHER = 'perineumOther',
  PERINEUM_OTHER_DRAINAGE_DESCRIBE = 'perineumOtherDrainageDescribe',
  RECTUM_HEMORRHOIDS = 'rectumHemorrhoids'
}

export enum FormFieldLabel {
  ROOMING_STATUS = 'Rooming in status',
  SIGN_DEPRESSION_SADNESS = 'Signs of depression or sadness',
  BREAST_FEEDING_RADIO = 'Breastfeeding',
  FUNDUS_CHARACTERISTICS = 'Fundus characteristics',
  FUNDUS_POSITION = 'Position',
  FUNDUS_HEIGHT = 'Height',
  LOCHIA_COLOR = 'Color',
  LOCHIA_QUANTITY = 'Quantity',
  PERINEUM_EDEMA = 'Edema',
  PERINEUM_INCISION_LACERATION = 'Incision or laceration',
  PERINEUM_COLOR = 'Color',
  PERINEUM_OTHER = 'Other',
  PERINEUM_OTHER_DRAINAGE_DESCRIBE = 'Describe',
  RECTUM_HEMORRHOIDS = 'Hemorrhoids',
  HIGH_SCORE_TEXT = 'High score correlates with good attachment of infant to breast.',
  LATCH_BREAST_FEEDING_ASSESSMENT_SCALE_TEXT = 'Modified from: Jensen D, Wallace S, Kelsay P: LATCH: A breastfeeding charting system and documentation tool. J Obstet Gynecol Neonatal Nurs, 23, 27–32, 1994.',
  TOTAL_SCORE = 'Total Score: '
}

export enum ScaleFormField {
  LATCH = 'latch',
  AUDIBLE_SWALLOWING = 'audibleSwallowing',
  TYPE_NIPPLE = 'typeOfNipple',
  COMFORT_BREAST_NIPPLE = 'comfortBreastNipple',
  HOLD_POSITIONING = 'holdPositioning'
}

export enum FormField {
  CLINICAL_INSTITUTE_SCALE = 'ratingScale',
  PULSE_HEART_RATE = 'pulseHeartRate',
  BLOOD_PRESSURE = 'bloodPressure',
  NAUSEA_VOMITING = 'nauseaVomiting',
  TREMOR = 'tremor',
  PAROXYSMAL_SWEATS = 'paroxysmalSweats',
  ANXIETY = 'anxiety',
  AGITATION = 'agitation',
  TACTILE_DISTURBANCES = 'tactileDisturbances',
  AUDITORY_DISTURBANCES = 'auditoryDisturbances',
  VISUAL_DISTURBANCES = 'visualDisturbances',
  HEADACHE_FULLNESS_IN_HEAD = 'headacheFullnessHead',
  ORIENTATION_CLOUDING_SENSORIUM = 'orientationCloudingSensorium'
}

export enum FormFieldLabel {
  PULSE_HEART_RATE = 'Pulse or heart rate, taken for one minute:',
  BLOOD_PRESSURE = 'Blood Pressure:',
  TOTAL_SCORE = 'Total Score',
  ALL_FIELDS_REQUIRED = 'All fields in this chart are required',
  NOTE = 'Note: This assessment for monitoring withdrawal symptoms requires approximately 5 minutes to administer. The maximum score is 67 (see instrument). Patients scoring less than 10 do not usually need additional medication for withdrawal.',
  MODIFIED_FROM = 'Modified from: Sullivan, J.T., Sykora, K., Schneiderman, J., Naranjo, C.A. & Sellers, E.M. (1989). Assessment of alcohol withdrawal: The revised Clinical Institute Withdrawal Assessment for Alcohol scale (CIWA-Ar). British Journal of Addiction, 84, 1353-1357, 1989.',
  MAXIMUM_SCORE = 'Maximum possible score 67.'
}

export enum Section {
  CLINICAL_INSTITUTE_SCALE = 'Clinical Institute Withdrawal Assessment of Alcohol Scale (CIWA-Ar)',
  TOTAL_CIWA_SCORE = 'Total CIWA-Ar Score'
}

export enum Title {
  SPECIAL_CHARTS = 'Special Charts'
}

import { delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment, MentalStatusFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { getFragmentValue } from 'helpers/chart.helper';
import { chartService, navigationService } from 'services';
import { buildPatientRecord } from 'services/chart.service';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { MentalStatusView, MentalStatusViewProps } from './MentalStatusView';
import { FormField, FormFieldLabel, SectionTitle } from './constant';

export interface MentalStatusProps extends ChartComponentProps {
  assessment: AssessmentRS;
}

export interface MentalStatusState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: MentalStatusFragment;
}

class MentalStatus extends Component<MentalStatusProps, MentalStatusState> {
  static displayName = 'MentalStatus';

  constructor(props) {
    super(props);

    this.state = {
      selectedRecord: null,
      isLocked: false,
      statusFragment: null
    };
  }

  componentDidMount() {
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory), {
      errorMessage: 'can not load chart fragment'
    });
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (fragment?: ChartFragment): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textAreas = [
      {
        name: FormField.APPEARANCE,
        label: FormFieldLabel.APPEARANCE,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.APPEARANCE)
      },
      {
        name: FormField.SPEECH,
        label: FormFieldLabel.SPEECH,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.SPEECH)
      },
      {
        name: FormField.THOUGHT_CONTENT,
        label: FormFieldLabel.THOUGHT_CONTENT,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.THOUGHT_CONTENT)
      },
      {
        name: FormField.HOMICIDAL_IMPULSES,
        label: FormFieldLabel.HOMICIDAL_IMPULSES,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.HOMICIDAL_IMPULSES)
      },
      {
        name: FormField.MEMORY,
        label: FormFieldLabel.MEMORY,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.MEMORY)
      },
      {
        name: FormField.AFFECT,
        label: FormFieldLabel.AFFECT,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.AFFECT)
      },
      {
        name: FormField.ATTENTION,
        label: FormFieldLabel.ATTENTION,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.ATTENTION)
      },
      {
        name: FormField.BEHAVIORAL_ACTIVITY,
        label: FormFieldLabel.BEHAVIORAL_ACTIVITY,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.BEHAVIORAL_ACTIVITY)
      },
      {
        name: FormField.THOUGHT_FORM,
        label: FormFieldLabel.THOUGHT_FORM,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.THOUGHT_FORM)
      },
      {
        name: FormField.SUICIDAL_IMPULSES,
        label: FormFieldLabel.SUICIDAL_IMPULSES,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.SUICIDAL_IMPULSES)
      },
      {
        name: FormField.ORIENTATION,
        label: FormFieldLabel.ORIENTATION,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.ORIENTATION)
      },
      {
        name: FormField.MOOD,
        label: FormFieldLabel.MOOD,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.MOOD)
      },
      {
        name: FormField.JUDGMENT_INSIGHT,
        label: FormFieldLabel.JUDGMENT_INSIGHT,
        value: getFragmentValue(fragment, SectionTitle.MENTAL_STATUS_EXAMINATION, FormField.JUDGMENT_INSIGHT)
      }
    ];

    textAreas.forEach(({ name, label, value }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.TEXT_AREA,
          label,
          value
        })
      )
    );
    return dataMap;
  };

  bindChartHistory = ({ data: fragments }) => {
    const selectedRecord = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.MENTAL_STATUS, NAV_ID.HISTORY_PHYSICAL);
    this.setState({
      statusFragment,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(this.buildFormFields(selectedRecord));
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'History and Physical',
      fragmentTitle: SectionTitle.MENTAL_STATUS_EXAMINATION,
      records: [
        {
          sectionTitle: SectionTitle.MENTAL_STATUS_EXAMINATION,
          records: [
            buildPatientRecord(formFieldMap, FormField.APPEARANCE),
            buildPatientRecord(formFieldMap, FormField.BEHAVIORAL_ACTIVITY),
            buildPatientRecord(formFieldMap, FormField.ATTENTION),
            buildPatientRecord(formFieldMap, FormField.THOUGHT_CONTENT),
            buildPatientRecord(formFieldMap, FormField.THOUGHT_FORM),
            buildPatientRecord(formFieldMap, FormField.JUDGMENT_INSIGHT),
            buildPatientRecord(formFieldMap, FormField.HOMICIDAL_IMPULSES),
            buildPatientRecord(formFieldMap, FormField.SUICIDAL_IMPULSES),
            buildPatientRecord(formFieldMap, FormField.AFFECT),
            buildPatientRecord(formFieldMap, FormField.SPEECH),
            buildPatientRecord(formFieldMap, FormField.MOOD),
            buildPatientRecord(formFieldMap, FormField.ORIENTATION),
            buildPatientRecord(formFieldMap, FormField.MEMORY)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.selectedRecord?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildFormFields(),
      afterSave: this.afterSave
    });
  };

  afterSave = () =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.state.isLocked,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };
    const viewProps: MentalStatusViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartActionsProps
    };
    return <MentalStatusView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];
export { MentalStatus as BaseMentalStatus };
export default compose(...enhancers)(MentalStatus);

import { CaseStudySequence } from 'models/ui';
import { MultiLine } from 'components/common';

export interface CaseStudyOverviewViewProps {
  sequence: CaseStudySequence;
}

const CaseStudyOverviewView = (props: CaseStudyOverviewViewProps) => (
  <div>
    <h1 className="u-els-margin-top-3x">{props.sequence.content?.title}</h1>
    <p className="u-els-margin-top-2x u-els-margin-bottom">
      <MultiLine text={props.sequence.content?.overview} />
    </p>
  </div>
);

CaseStudyOverviewView.displayName = 'CaseStudyOverviewView';
export default CaseStudyOverviewView;

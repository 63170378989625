import React, { Component, RefObject } from 'react';
import { ELSDayPickerType } from 'models/els';
import { dateTimeHelper } from 'helpers';
import { ELSDate } from 'components/common/els';
import './sng.date.scss';

export interface SNGDateProps {
  value: string;
  disabled?: boolean;
  onDateChange: Function;
  componentRef?: RefObject<ELSDayPickerType>;
  disableAfter?: Date;
  disableBefore?: Date;
  id?: string;
  onBlur?: Function;
  selectOnly?: boolean;
  isDisabled?: boolean;
  inputAriaLabel?: string;
  ariaRequired?: boolean;
  usePropValue?: boolean;
}

class SNGDate extends Component<SNGDateProps> {
  datePickerRef: RefObject<ELSDayPickerType>;

  static displayName = 'SNGDate';

  constructor(props) {
    super(props);
    this.datePickerRef = React.createRef();
  }

  handleTouchStart = () => this.datePickerRef.current.setState({ showOverlay: true });

  handleDateChange = (e, date, metadata) => this.props.onDateChange(metadata.originalInput);

  render() {
    const { value, componentRef, usePropValue, onBlur, selectOnly, isDisabled, inputAriaLabel, ariaRequired } = this.props;

    return (
      <div className="sng-date-container">
        <ELSDate
          {...this.props}
          isDisabled={isDisabled}
          dayPickerInputRef={this.datePickerRef}
          componentRef={componentRef}
          iconRight="calendar"
          iconRightPrefix="gizmo"
          placeHolder=""
          value={value}
          changeHandler={this.handleDateChange}
          formatDate={(date, format) => dateTimeHelper.datePickerFormatterWithCustomFormat(date, format)}
          parseDate={(date, format) => dateTimeHelper.datePickerParserWithCustomFormat(date, format)}
          usePropValue={usePropValue}
          textFieldProps={{
            onBlur,
            readOnly: selectOnly,
            onTouchStart: isDisabled ? null : this.handleTouchStart,
            'aria-label': inputAriaLabel || 'Input date field',
            'aria-required': ariaRequired
          }}
        />
      </div>
    );
  }
}

export default SNGDate;

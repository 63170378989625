import { appHelper } from 'helpers';
import { ELSTextarea, TitleTooltip } from 'components/common';
import { FormFieldLabel } from './constants';

export interface ObjectiveInputProps {
  objective: string;
  onObjectiveChange: Function;
}

const ObjectiveInput = (props: ObjectiveInputProps) => (
  <div>
    <TitleTooltip
      className="u-els-margin-bottom"
      titleClassname="u-els-font-size-h3 title-tooltip__line-height-h3"
      title="Objectives"
      tooltip="Add learning objectives your students will meet upon completion of this assignment. Students will see the objectives when they launch the assignment."
    />
    <ELSTextarea
      id="assignment-objective"
      textLabel={FormFieldLabel.ASSIGNMENT_OBJECTIVES}
      placeholder={appHelper.getPlaceholder(FormFieldLabel.ASSIGNMENT_OBJECTIVES)}
      value={props.objective}
      onChange={props.onObjectiveChange}
    />
  </div>
);

ObjectiveInput.displayName = 'ObjectiveInput';
export default ObjectiveInput;

export enum SectionTitle {
  GLASGOW_COMA_SCALE = 'Glasgow Coma Scale',
  TOTAL_SCORE = 'Total Glasgow Score'
}

export enum FormField {
  GLASGOW_COMA_SCALE = 'ratingScale',
  EYE_OPENING_RESPONSE = 'eyeOpeningResponse',
  BEST_MOTOR_RESPONSE = 'bestMotorResponse',
  BEST_VERBAL_RESPONSE = 'bestVerbalResponse',
  EYE_OPENING_RESPONSE_CHECKBOX = 'eyeOpeningResponseCheckbox',
  BEST_VERBAL_RESPONSE_CHECKBOX = 'bestVerbalResponseCheckbox'
}

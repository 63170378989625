import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, neurologicalAssessmentFormFieldBuilderItems } from './constants';
import NeurologicalAssessmentView, { NeurologicalAssessmentViewProps } from './NeurologicalAssessmentView';

export interface NeurologicalAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

interface NeurologicalAssessmentState {
  resetAll: number;
}

class NeurologicalAssessment extends Component<NeurologicalAssessmentProps, NeurologicalAssessmentState> {
  static displayName = 'NeurologicalAssessment';

  constructor(props) {
    super(props);
    this.state = {
      resetAll: 0
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = () => {
    const { locale } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    neurologicalAssessmentFormFieldBuilderItems(locale).forEach(({ name, type, label, errorLabel, isHidden }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, type, label, errorLabel }));
      }
    });

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.afterSave });
  };

  afterSave = () => {
    this.resetForm();
    this.props.navigateToSavedPatientCharting();
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'System Assessment',
      fragmentTitle: LanguageKeys.NEUROLOGICAL_ASSESSMENT,
      records: [
        {
          sectionTitle: LanguageKeys.NEUROLOGICAL_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
        },
        {
          sectionTitle: LanguageKeys.LEVEL_CONSCIOUSNESS_ORIENTATION,
          records: [
            buildPatientRecord(formFieldMap, FormField.ORIENTATION),
            ...buildPatientRecords(formFieldMap, FormField.CONSCIOUSNESS_LEVEL),
            buildPatientRecord(formFieldMap, FormField.CONSCIOUSNESS_LEVEL_NOTES)
          ]
        },
        {
          sectionTitle: LanguageKeys.EMOTIONAL_STATE,
          records: [...buildPatientRecords(formFieldMap, FormField.EMOTIONAL_STATE), buildPatientRecord(formFieldMap, FormField.EMOTIONAL_STATE_NOTES)]
        },
        {
          parentSectionTitle: LanguageKeys.PUPILS,
          sectionTitle: LanguageKeys.LEFT,
          records: [buildPatientRecord(formFieldMap, FormField.PUPILS_LEFT_REACTIVITY), buildPatientRecord(formFieldMap, FormField.PUPILS_LEFT_SIZE)]
        },
        {
          parentSectionTitle: LanguageKeys.PUPILS,
          sectionTitle: LanguageKeys.RIGHT,
          records: [buildPatientRecord(formFieldMap, FormField.PUPILS_RIGHT_REACTIVITY), buildPatientRecord(formFieldMap, FormField.PUPILS_RIGHT_SIZE)]
        },
        {
          parentSectionTitle: LanguageKeys.PUPILS,
          sectionTitle: 'Notes',
          records: [buildPatientRecord(formFieldMap, FormField.PUPILS_NOTES)]
        },
        {
          sectionTitle: LanguageKeys.HAND_GRIP,
          records: [
            buildPatientRecord(formFieldMap, FormField.HAND_GRIP_BILATERAL_ASSESSMENT),
            buildPatientRecord(formFieldMap, FormField.HAND_GRIP_LEFT_STRENGTH),
            buildPatientRecord(formFieldMap, FormField.HAND_GRIP_RIGHT_STRENGTH),
            buildPatientRecord(formFieldMap, FormField.HAND_GRIP_NOTES)
          ]
        },
        {
          sectionTitle: LanguageKeys.FOOT_PUMPS,
          records: [
            buildPatientRecord(formFieldMap, FormField.FOOT_PUMPS_BILATERAL_ASSESSMENT),
            buildPatientRecord(formFieldMap, FormField.FOOT_PUMPS_LEFT_STRENGTH),
            buildPatientRecord(formFieldMap, FormField.FOOT_PUMPS_RIGHT_STRENGTH),
            buildPatientRecord(formFieldMap, FormField.FOOT_PUMPS_NOTES)
          ]
        },
        {
          sectionTitle: LanguageKeys.CENTRAL_NERVOUS_SYSTEM_ASSESSMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.CENTRAL_NERVOUS_NO_PROBLEMS),
            ...buildPatientRecords(formFieldMap, FormField.CENTRAL_NERVOUS_PROBLEMS),
            buildPatientRecord(formFieldMap, FormField.CENTRAL_NERVOUS_NOTES)
          ]
        },
        {
          parentSectionTitle: LanguageKeys.EXTRAPYRAMIDAL_SIDE_EFFECTS,
          sectionTitle: LanguageKeys.AKATHISIA,
          records: [...buildPatientRecords(formFieldMap, FormField.EPS_AKATHISIA), buildPatientRecord(formFieldMap, FormField.EPS_AKATHISIA_OTHER)]
        },
        {
          parentSectionTitle: LanguageKeys.EXTRAPYRAMIDAL_SIDE_EFFECTS,
          sectionTitle: LanguageKeys.ACUTE_DYSTONIA,
          records: [...buildPatientRecords(formFieldMap, FormField.EPS_ACUTE_DYSTONIA), buildPatientRecord(formFieldMap, FormField.EPS_ACUTE_DYSTONIA_OTHER)]
        },
        {
          parentSectionTitle: LanguageKeys.EXTRAPYRAMIDAL_SIDE_EFFECTS,
          sectionTitle: LanguageKeys.PSEUDOPARKINSONISM,
          records: [...buildPatientRecords(formFieldMap, FormField.EPS_PSEUDOPARKINSONISM), buildPatientRecord(formFieldMap, FormField.EPS_PSEUDOPARKINSONISM_OTHER)]
        },
        {
          parentSectionTitle: LanguageKeys.EXTRAPYRAMIDAL_SIDE_EFFECTS,
          sectionTitle: LanguageKeys.TARDIVE_DYSKINESIA,
          records: [...buildPatientRecords(formFieldMap, FormField.EPS_TARDIVE_DYSKINESIA), buildPatientRecord(formFieldMap, FormField.EPS_TARDIVE_DYSKINESIA_OTHER)]
        },
        {
          sectionTitle: LanguageKeys.NEUROLEPTIC_MALIGNANT_SYNDROME,
          records: [...buildPatientRecords(formFieldMap, FormField.NEUROLEPTIC_MALIGNANT_SYNDROME)]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  resetForm = () => {
    this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));
  };

  render() {
    const { formFieldMap, formSubmittedCount, hasUnsavedChanges, enableDisplayRecordsButton, locale } = this.props;
    const toolTips = {
      [FormField.EPS_AKATHISIA]: formFieldMap.get(FormField.EPS_AKATHISIA)?.labelTooltip || '',
      [FormField.EPS_ACUTE_DYSTONIA]: formFieldMap.get(FormField.EPS_ACUTE_DYSTONIA)?.labelTooltip || '',
      [FormField.EPS_PSEUDOPARKINSONISM]: formFieldMap.get(FormField.EPS_PSEUDOPARKINSONISM)?.labelTooltip || '',
      [FormField.EPS_TARDIVE_DYSKINESIA]: formFieldMap.get(FormField.EPS_TARDIVE_DYSKINESIA)?.labelTooltip || ''
    };
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: NeurologicalAssessmentViewProps = {
      resetAll: this.state.resetAll,
      toolTips,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      locale
    };
    return <NeurologicalAssessmentView {...viewProps} />;
  }
}

export { NeurologicalAssessment as BaseNeurologicalAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(NeurologicalAssessment);

import { ChartActions, ChartPermission, ErrorFormField, TitleTooltip } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const MusculoskeletalInterventionsView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;
  return (
    <div className="musculoskeletal-interventions">
      <h1>{SectionTitle.MUSCULOSKELETAL_INTERVENTIONS}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <h2>{SectionTitle.JOINT_REPAIR_REPLACEMENT_CARE}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.JOINT_REPAIR_REPLACEMENT_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <h2>{SectionTitle.FRACTURE_CARE}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FRACTURE_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
          <h2>{SectionTitle.TRACTION_CARE}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TRACTION_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
          <h2>{SectionTitle.PIN_TONG_CARE}</h2>
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.EXTERNAL_FIXATOR)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PIN_TONG_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
          <TitleTooltip
            className="u-els-margin-bottom"
            titleClassname="u-els-font-size-h2"
            title={SectionTitle.DRAIN_TUBE_CARE}
            tooltip={formFieldMap.get(FormField.DRAIN_TUBE_CARE)?.labelTooltip || ''}
          />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DRAIN_TUBE_CARE)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
          <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

MusculoskeletalInterventionsView.displayName = 'MusculoskeletalInterventionsView';
export default MusculoskeletalInterventionsView;

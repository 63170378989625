import { LanguageKeys } from 'lang';
import { camelCase } from 'lodash';
import { LaboratoryHistoryRecord } from 'models/api-response';
import { ExternalReference } from 'models/ui';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface LaboratoryReadonlyProps {
  laboratory: LaboratoryHistoryRecord;
  referenceFields: ExternalReference[];
}
const LaboratoryReadonly = (props: LaboratoryReadonlyProps) => {
  return (
    <Box className="Laboratory-test-read-only">
      <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
        <ELSFlexItem>
          {props.referenceFields.map(({ label, range }) => (
            <ReadOnlyInput key={label} chartFragment={props.laboratory} formFieldId={camelCase(label)} customTitle={label} labelTooltip={range} />
          ))}
          <ReadOnlyInput chartFragment={props.laboratory} formFieldId={FormField.RESULTS_RECEIVED_DATE} customTitle={LanguageKeys.LABORATORY.RESULTS_RECEIVED_DATE} />
          <ReadOnlyInput chartFragment={props.laboratory} formFieldId={FormField.RESULTS_RECEIVED_TIME} customTitle={LanguageKeys.LABORATORY.RESULTS_RECEIVED_TIME} />
        </ELSFlexItem>
      </ELSFlex>
    </Box>
  );
};

LaboratoryReadonly.displayName = 'LaboratoryReadonly';
export default LaboratoryReadonly;

/* eslint-disable class-methods-use-this */
import { FormFieldDataType, FormFieldInputType } from 'models/enum';
import isNil from 'lodash/fp/isNil';
import BaseValidator, { ExecuteParams } from './interface';

export default class EmptyValidator implements BaseValidator {
  isReverse: boolean;

  constructor(isReverse = false) {
    this.isReverse = isReverse;
  }

  execute({ field, allFields }: ExecuteParams): boolean {
    let result = false;

    if (field.inputType === FormFieldInputType.SCALE) {
      result = field.chartContent
        .filter((content) => content.dataType === FormFieldDataType.SCALE_QUESTION)
        .some((content) => {
          const questionField = allFields.get(content.formFieldId);
          return isNil(questionField.extraData.score?.value);
        });
    } else if (typeof field.value === 'string') {
      result = field.value.length === 0;
    }

    if (this.isReverse) {
      result = !result;
    }
    return result;
  }
}

import { get, groupBy, map, orderBy, sumBy, uniq } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ChartFragment, IntakeOutputRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartComponentProps, DropDownItem, IntakeOutputTotalPerDayItem, SkipLinkSection } from 'models/ui';
import { ChartAmountUnit, DateTimeFormatByLocale, GRAPH_DATE_TIME_FORMAT, Locales, NAV_ID, SkipLinkId, VitalSign } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { SavedPatientCharting } from 'components/common';
import SummaryView from './SummaryView';
import SummaryViewAU from './SummaryViewAU';
import { ChartHistoryTitle, IntakeOutputType, SummaryState, SummaryViewProps, VitalSignChartConfig, VitalSignChartField } from './models';

class Summary extends Component<ChartComponentProps, SummaryState> {
  static displayName = 'Summary';

  vitalSignCharts: DropDownItem[] = [];
  vitalSignChartConfig: VitalSignChartConfig;
  vitalSignsHistoryFragments: ChartFragment[] = [];
  fieldUnit = {};

  constructor(props) {
    super(props);
    this.state = {
      selectedVitalSignChart: VitalSignChartField.TEMPERATURE,
      vitalSignsHistory: [],
      intakeOutputTotalPerDayLatest: null,
      bloodGlucoseLatest: null,
      painAssessmentLatest: null
    };

    this.vitalSignCharts = [
      { name: 'Temperature', value: VitalSignChartField.TEMPERATURE },
      { name: 'Pulse', value: VitalSignChartField.PULSE },
      { name: 'Respiration', value: VitalSignChartField.RESPIRATION },
      { name: 'Blood Pressure', value: VitalSignChartField.BLOODPRESSURE },
      { name: 'Oxygenation', value: VitalSignChartField.OXYGENATION }
    ];

    this.fieldUnit = {
      [VitalSignChartField.TEMPERATURE]: 'F',
      [VitalSignChartField.PULSE]: 'BPM',
      [VitalSignChartField.RESPIRATION]: '/min',
      [VitalSignChartField.BLOODPRESSURE]: 'mmHg'
    };

    this.vitalSignChartConfig = {
      [VitalSignChartField.TEMPERATURE]: {
        width: 800,
        yLabel: 'Temperature (F)',
        dataKeys: [VitalSignChartField.TEMPERATURE],
        data: []
      },
      [VitalSignChartField.PULSE]: {
        width: 800,
        yLabel: 'Pulse (BPM)',
        dataKeys: [VitalSignChartField.PULSE],
        data: []
      },
      [VitalSignChartField.RESPIRATION]: {
        width: 800,
        yLabel: 'Respiration (Respirations/min)',
        dataKeys: [VitalSignChartField.RESPIRATION],
        data: []
      },
      [VitalSignChartField.BLOODPRESSURE]: {
        width: 800,
        yLabel: 'Blood Pressure (mmHg)',
        dataKeys: ['systolic', 'diastolic'],
        data: []
      },
      [VitalSignChartField.OXYGENATION]: {
        width: 800,
        yLabel: 'Oxygen Saturation %',
        dataKeys: ['saturation'],
        data: []
      }
    };
  }

  componentDidMount() {
    return appHelper.useLoader(this.loadChartData());
  }

  loadChartData = async () => {
    const { locale } = this.props;
    const navIds = [NAV_ID.VITALSIGNS, NAV_ID.INTAKE_OUTPUT, NAV_ID.BLOOD_GLUCOSE, NAV_ID.PAIN_ASSESSMENT];
    const fragmentTypes = [FragmentType.AUTHORED, FragmentType.CHARTING];
    return chartService
      .loadFragments({
        chartId: this.props.assessment.simChartId,
        navIds,
        fragmentTypes
      })
      .then(({ data }) => {
        const sections: SkipLinkSection[] = [];

        sections.push(SkipLinkId.navigation, SkipLinkId.main, SkipLinkId.footer);
        this.props.setSkipLinkSections(sections);
        const records = data.map((fragment) => {
          const { fragmentId: id, navElementId, active, chartingAt, createdAt, creator, modifier } = fragment;
          return { id, navElementId, active, chartingAt, createdAt, creator, modifier, ...fragment.chartData };
        });
        const vitalSignsHistory = records.filter((item) => item.navElementId === NAV_ID.VITALSIGNS);
        this.vitalSignsHistoryFragments = data.filter((fragment) => fragment.navElementId === NAV_ID.VITALSIGNS);
        const bloodGlucoseHistory = records.filter((item) => item.navElementId === NAV_ID.BLOOD_GLUCOSE);
        const bloodGlucoseLatest = orderBy(
          bloodGlucoseHistory.map((item) => ({
            ...item,
            chartingAt: dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(item.chartingAt).toDate(), customFormat: DateTimeFormatByLocale[locale] }),
            createdAt: item.createdAt
          })),
          ['chartingAt', 'createdAt'],
          ['desc', 'desc']
        )[0];
        const painAssessmentHistory = orderBy(
          records
            .filter((item) => item.navElementId === NAV_ID.PAIN_ASSESSMENT)
            .map((item) => {
              const painAssessmentSection = item.records.find((record) => record.sectionTitle === 'Pain Assessment');
              const intensityOfPain = painAssessmentSection?.records.find((record) => record.formField === 'intensityOfPain');
              const intensityOfPainOnMovement = painAssessmentSection?.records.find((record) => record.formField === 'intensityOfPainOnMovement');
              return {
                numericPainLevel: intensityOfPain?.content || '',
                numericPainLevelOnMovement: intensityOfPainOnMovement?.content || '',
                chartingAt: dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(item.chartingAt).toDate(), customFormat: DateTimeFormatByLocale[locale] }),
                createdAt: item.createdAt
              };
            }),
          ['chartingAt', 'createdAt'],
          ['desc', 'desc']
        );
        const painAssessmentLatest = painAssessmentHistory[0];
        const intakeOutputHistory = records.filter((item) => item.navElementId === NAV_ID.INTAKE_OUTPUT);
        const intakeOutputTotalPerDayLatest = this.calculateTotalPerDayData(intakeOutputHistory)[0];
        this.setState({ vitalSignsHistory, intakeOutputTotalPerDayLatest, bloodGlucoseLatest, painAssessmentLatest });
      });
  };

  calculateTotalPerDayData = (intakeOutputHistory: IntakeOutputRecord[]): IntakeOutputTotalPerDayItem[] => {
    const groupByDate = groupBy(
      orderBy(intakeOutputHistory, ['chartingAt', 'createdAt'], ['desc', 'desc']).filter((item) => item.active && item.unit === ChartAmountUnit.UNIT_ML),
      (item) => dateTimeHelper.toDate(item.chartingAt)
    );

    return map(groupByDate, (itemsPerDate, date: string) => ({
      date,
      ...Object.assign(
        {},
        ...map(groupBy(itemsPerDate, 'type'), (itemsPerType, type: IntakeOutputType.INTAKE | IntakeOutputType.OUTPUT) => {
          return {
            [type]: Math.round(sumBy(itemsPerType, ({ value }) => Number(value)))
          };
        })
      )
    }));
  };

  handleChartChange = ({ target: { value } }) => {
    this.setState({ selectedVitalSignChart: value });
  };

  getOxygenationUnit = (item) => {
    const {
      choice,
      value,
      method: { choice: methodChoice }
    } = item?.oxygenation?.delivery;
    if (!choice) return '%';

    if (choice === VitalSign.VitalSign.Oxygenation.ROOM_AIR) return '%, Room Air';

    if (choice === VitalSign.VitalSign.Oxygenation.OXYGEN_LMIN) return `%, Oxygen in Use ${value || ''} L/min, ${methodChoice}`;

    if (choice === VitalSign.VitalSign.Oxygenation.OXYGEN_PERCENT) return `%, Oxygen in Use ${value || ''}%, ${methodChoice}`;

    return '';
  };

  buildVitalSignChartData = (field: string) =>
    this.state.vitalSignsHistory
      .filter((item) => item.active)
      .filter((item) => get(item, [field, 'value']) || this.vitalSignChartConfig[field].dataKeys.some((key) => get(item, uniq([field, key, 'value']))))
      .map((item) =>
        Object.assign(
          {},
          {
            date: dateTimeHelper.toDate(item.chartingAt),
            dateTime: dateTimeHelper.toMoment(item.chartingAt).format(GRAPH_DATE_TIME_FORMAT)
          },
          ...this.vitalSignChartConfig[field].dataKeys.map((key) => {
            const unit = field === VitalSignChartField.OXYGENATION ? this.getOxygenationUnit(item) : this.fieldUnit[field];
            const value = Number(get(item, uniq([field, key, 'value'])));
            return {
              [key]: value,
              [`${key}Display`]: `${value} ${unit}`
            };
          })
        )
      );

  buildVitalSignChartConfig = (field: string) => ({ ...this.vitalSignChartConfig[field], data: this.buildVitalSignChartData(field) });

  handleNavigation = (url: string) => navigationService.navigateToChart(url, this.props.assessment.eolsAssessmentId);

  render() {
    const { selectedVitalSignChart, bloodGlucoseLatest, painAssessmentLatest, intakeOutputTotalPerDayLatest, vitalSignsHistory } = this.state;
    const { locale, intl } = this.props;
    const vitalSignChartConfig = this.buildVitalSignChartConfig(this.state.selectedVitalSignChart);
    const navIds = chartHelper.getSummaryChartIds();
    const viewProps: SummaryViewProps = {
      loadData: this.loadChartData,
      vitalSignChartConfig,
      vitalSignsHistoryFragments: this.vitalSignsHistoryFragments,
      vitalSignCharts: this.vitalSignCharts,
      selectedVitalSignChart,
      intakeOutputTotalPerDayLatest,
      bloodGlucoseLatest,
      painAssessmentLatest,
      onChartChange: this.handleChartChange,
      onNavigation: this.handleNavigation,
      chartHistory: vitalSignsHistory,
      tableTitle: ChartHistoryTitle.SUMMARY_HISTORY,
      locale,
      intl
    };
    return (
      <>
        {locale === Locales.EN_AU ? <SummaryViewAU {...viewProps} /> : <SummaryView {...viewProps} />}
        <div className="u-els-margin-bottom-5x">
          <SavedPatientCharting {...this.props} chartTitle="Chart History" navIds={navIds} />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSkipLinkSections: (skipLinkSections: SkipLinkSection[]) => dispatch(appActions.setSkipLinkSections(skipLinkSections))
});

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state),
  locale: appSelectors.getLocale(state)
});

export { Summary as BaseSummary };
export default connect(mapStateToProps, mapDispatchToProps)(Summary);

export enum FormField {
  CONTAINER_ENVIRONMENT_HISTORY = 'containerEnvironmentalHistory',
  CONTAINER_MENTAL_HEALTH = 'containerMentalHealth',
  CONTAINER_GENETIC_DISORDERS = 'containerGeneticDisorders',
  CONTAINER_GENERAL = 'containerGeneral',
  CONTAINER_RESPIRATORY_PROBLEMS = 'containerRespiratoryProblems',
  CONTAINER_RESPIRATORY_PROBLEMS_PEDIATRIC = 'containerRespiratoryProblemsPediatric',
  CONTAINER_CONGENITAL_HEART_DEFECT = 'containerCongenitalHeartDefect',
  CONTAINER_INTEGUMENTARY_PROBLEMS = 'containerIntegumentaryProblems',
  CONTAINER_SEXUALITY_REPRODUCTIVE = 'containerSexualityReproductive',
  CONTAINER_THYROID_DISEASE = 'containerThyroidDisease',
  CONTAINER_ENDOCRINE_DISORDERS = 'containerEndocrineDisorders',
  CONTAINER_OTHER_DISORDERS = 'containerOtherDisorders',
  CONTAINER_PITUITARY_DISORDER = 'containerPituitaryDisorder',
  CONTAINER_DIABETES = 'containerDiabetes',
  CONTAINER_OBSTETRIC_HISTORY = 'containerObstetricHistory',
  CONTAINER_HISTORY_CURRENT_PREGNANCY = 'containerHistoryCurrentPregnancy',
  CONTAINER_LAST_ORAL_INTAKE = 'containerLastOralIntake',
  CONTAINER_PREVIOUS_PREGNANCIES = 'containerPreviousPregnancies',
  NO_PREVIOUS_HEALTH_PROBLEM = 'noPreviousHealthProblem',
  OBSTETRIC_HISTORY_ARE_YOU_PREGNANT = 'areYouPregnant',
  OBSTETRIC_HISTORY_BABY_DUE = 'babyDue',
  OBSTETRIC_HISTORY_COMFIRMED_ULTRASOUND = 'comfirmedUltrasound',
  OBSTETRIC_HISTORY_LAST_MENSTRUAL_PERIOD = 'lastMenstrualPeriod',
  OBSTETRIC_HISTORY_ARE_YOU_BREAST_FEEDING = 'areYouBreastfeeding',
  OBSTETRIC_HISTORY_GRAVIDA = 'gravida',
  OBSTETRIC_HISTORY_PARA = 'para',
  OBSTETRIC_HISTORY_TERM = 'term',
  OBSTETRIC_HISTORY_PRETERM = 'preterm',
  OBSTETRIC_HISTORY_ABORTION = 'abortion',
  OBSTETRIC_HISTORY_LIVING = 'living',
  CURRENT_PREGNANCY_RECEIVED_PRENATAL_CARE = 'receivedPrenatalCare',
  CURRENT_PREGNANCY_PROVIDER_NAME = 'providerName',
  CURRENT_PREGNANCY_RISK_FACTORS_IDENTIFIED = 'riskFactorsIdentified',
  CURRENT_PREGNANCY_GROUP_B_STREPTOCOCCUS_STATUS = 'groupBStreptococcusStatus',
  CURRENT_PREGNANCY_VAGINAL_DISCHARGE = 'vaginalDischarge',
  CURRENT_PREGNANCY_DESCRIBE = 'describe',
  CURRENT_PREGNANCY_PROBLEMS_IDENTIFIED = 'problemsIdentified',
  CURRENT_PREGNANCY_MULTIPLE_GESTATION = 'multipleGestation',
  CURRENT_PREGNANCY_IS_FETUS_ACTIVE = 'isFetusActive',
  CURRENT_PREGNANCY_TIME_OF_LAST_ACTIVITY = 'timeOfLastActivity',
  LAST_ORAL_INTAKE_DAY = 'lastOralIntakeDay',
  LAST_ORAL_INTAKE_DAY_DESCRIPTION = 'lastOralIntakeDayDescription',
  LAST_ORAL_INTAKE_TIME = 'lastOralIntakeTime',
  ENV_HISTORY_CONCERN = 'livingEnvironmentConcerns',
  ENV_HISTORY_EXPOSE = 'exposeHazardousMaterial',
  ENV_HISTORY_EXPLAIN = 'exposeHazardousMaterialExplain',
  ENV_HISTORY_MILITARY = 'beenInMilitary',
  ENV_HISTORY_BRANCH = 'branchOfService',
  ENV_HISTORY_DATE = 'datesOfMilitaryService',
  ENV_HISTORY_POSITION = 'positionRankOperations',
  ENV_HISTORY_DEPLOYMENT = 'deploymentsDateLocation',
  ENV_HISTORY_ENVIRONMENTAL = 'environmentalExposuresInMilitary',
  ENV_HISTORY_INJURIES = 'injuriesExperiencedInMilitary',
  ENV_HISTORY_ASSAULTS = 'assaultsExperiencedInMilitary',
  TRAVEL = 'travelToForeignCountryPast12Months',
  TRAVEL_EXPLAIN = 'travelToForeignCountryPast12MonthsExplain',
  MENTAL_HEALTH = 'mentalHealth',
  GENETIC_DISORDERS = 'geneticDisorders',
  GENERAL = 'general',
  NEUROLOGICAL_PROBLEMS = 'neurologicalProblems',
  RESPIRATORY_PROBLEMS = 'respiratoryProblems',
  RESPIRATORY_UPPER = 'respiratoryProblemsUpper',
  RESPIRATORY_LOWER = 'respiratoryProblemsLower',
  RESPIRATORY_OTHER = 'respiratoryProblemsOther',
  CARDIOVASCULAR_PROBLEMS = 'cardiovascularProblems',
  CONGENITAL_HEART_DEFECT = 'congenitalHeartDefect',
  SENSORY_HEARING = 'hearingImpairment',
  SENSORY_HEARING_CHOICE = 'hearingImpairmentAssistance',
  SENSORY_VISUAL = 'visualImpairment',
  SENSORY_VISUAL_CHOICE = 'visualImpairmentAssistance',
  INTEGUMENTORY_PROBLEMS = 'integumentaryProblems',
  ENDOCRINE_THYROID = 'thyroidDisease',
  ENDOCRINE_DISORDERS = 'endocrineDisorders',
  ENDOCRINE_LIVER = 'liverDisease',
  ENDOCRINE_OTHER = 'otherDisorders',
  ENDOCRINE_PITUITARY = 'pituitaryDisorder',
  ENDOCRINE_DIABETES = 'diabetes',
  ENDOCRINE_DIABETES_COMPLIANT = 'compliantDiabeticRegimen',
  ENDOCRINE_YEARS = 'yearsWithDiabetes',
  GASTROINTESTINAL = 'gastrointestinalProblems',
  HEMATOLOGIC = 'hematologicImmunologicProblems',
  HEMATOLOGIC_CANCER = 'hematologicImmunologicProblemsCancer',
  GENITOURINARY = 'genitourinaryProblems',
  SEXUALITY = 'reproductiveProblems',
  SEXUALITY_ACTIVE = 'sexuallyActive',
  SEXUALITY_NUMBER_PARTNERS = 'numberOfSexPartners',
  SEXUALITY_SEX_PARTNER = 'sexOfPartners',
  SEXUALITY_BIRTH_CONTROL = 'birthControlUsed',
  MUSCULOSKELETAL = 'musculoskeletalProblems',
  PREVIOUS_SURGERY = 'previousSurgery',
  PREVIOUS_SURGERY_NONE = 'previousSurgeryNone',
  ANESTHESIA = 'anesthesiaProblemsReported',
  ANESTHESIA_NOTE = 'anesthesiaProblems',
  OTHER_HEALTH_HISTORY = 'otherHealthHistory',
  RECENT_FEVER = 'recentFeverNoRecentFever',
  RECENT_FEVER_ONSET = 'recentFeverRequiringAntibioticsOnset',
  RECENT_FEVER_ONSET_OTHER = 'recentFeverRequiringAntibioticsOnsetOther',

  // Previous Pregnancies (sidebar)
  HOW_LONG_AGO = 'howLongAgo',
  GESTATIONAL_WEEKS = 'gestationalWeeks',
  TYPE_OF_DELIVERY = 'typeOfDelivery',
  DURATION_OF_LABOR = 'durationOfLabor',
  COMPLICATIONS = 'complications',
  OUTCOME = 'outcome'
}

export enum SectionTitle {
  NO_SECTION = 'none',
  NO_PREVIOUS_HEALTH_PROBLEM = 'No Previous Health Problem',
  OBSTETRIC_HISTORY = 'Obstetric History',
  HISTORY_OF_CURRENT_PREGNANCY = 'History of Current Pregnancy',
  LAST_ORAL_INTAKE = 'Last oral intake',
  PREVIOUS_PREGNANCIES = 'Previous Pregnancies',
  ENVIRONMENT_HISTORY = 'Environmental History',
  TRAVEL = 'Travel',
  MENTAL_HEALTH = 'Mental Health',
  GENETIC_DISORDERS = 'Genetic Disorders',
  GENERAL = 'General',
  RESPIRATORY_PROBLEMS = 'Respiratory Problems',
  NEUROLOGICAL_PROBLEMS = 'Neurological Problems',
  RESPIRATORY_UPPER = 'Upper respiratory infections',
  RESPIRATORY_LOWER = 'Lower respiratory infections',
  RESPIRATORY_OTHER = 'Other problems',
  CARDIOVASCULAR_PROBLEMS = 'Cardiovascular Problems',
  CONGENITAL_HEART_DEFECT = 'Congenital heart defect (CHD)',
  SENSORY_IMPAIRMENT = 'Sensory Impairment',
  SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT = 'Hearing impairment',
  SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT_CHOICE = 'Hearing impairment Choice',
  SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT = 'Visual impairment',
  SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT_CHOICE = 'Visual impairment Choice',
  INTEGUMENTORY_PROBLEMS = 'Integumentary Problems',
  ENDOCRINE_DISORDERS = 'Endocrine Disorders',
  ENDOCRINE_DISORDERS_THYROID_DISEASE = 'Thyroid disease',
  ENDOCRINE_DISORDERS_LIVER_DISEASE = 'Liver disease',
  ENDOCRINE_DISORDERS_OTHER_DISORDERS = 'Other disorders',
  ENDOCRINE_DISORDERS_PITUITARY_DISORDER = 'Pituitary disorder',
  ENDOCRINE_DISORDERS_DIABETES = 'Diabetes',
  ENDOCRINE_DISORDERS_DIABETES_COMPLIANT = 'Diabetes complaint',
  ENDOCRINE_DISORDERS_DIABETES_YEARS = 'Diabetes complaint years',
  GASTROINTESTINAL_PROBLEMS = 'Gastrointestinal Problems',
  HEMATOLOGIC_PROBLEMS = 'Hematologic/Immunologic Problems',
  HEMATOLOGIC_CANCER = 'Hematologic Cancer',
  GENITOURINARY = 'Genitourinary Problems',
  SEXUALITY = 'Sexuality',
  SEXUALITY_ACTIVE = 'Sexuality Active',
  SEXUALITY_NUMBER_PARTNERS = 'Sexuality Number Partners',
  SEXUALITY_SEX_PARTNER = 'Sexuality Sex Partner',
  SEXUALITY_BIRTH_CONTROL = 'Sexuality Birth Control',
  MUSCULOSKELETAL_PROBLEMS = 'Musculoskeletal Problems',
  PREVIOUS_SURGERY = 'Previous Surgery',
  PREVIOUS_SURGERY_NONE = 'Previous Surgery None',
  ANESTHESIA_PROBLEMS = 'Anesthesia Problems',
  ANESTHESIA_NOTE = 'Anesthesia Note',
  OTHER_HEALTH_HISTORY = 'Other Health History',
  RECENT_FEVER_REQUIRING_ANTIBIOTICS = 'Recent Fever Requiring Antibiotics',
  RECENT_FEVER_ONSET = 'Recent Fever Onset',
  RECENT_FEVER_ONSET_OTHER = 'Recent Fever Onset Other'
}

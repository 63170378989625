import { ChartFragmentRS, LaboratoryHistoryRecord } from 'models/api-response';
import { ChartMetaFormField } from 'models/ui';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import LaboratoryHistory from './LaboratoryHistory';
import LaboratorySidebar from './LaboratorySidebar';
import './laboratory.scss';
import Notice from '../shared/Notice';
import { FormField as CommonFormField } from '../shared/constants';

export interface LaboratoryViewProps extends ChartViewProps {
  chartHistory: LaboratoryHistoryRecord[];
  selectedLaboratory: LaboratoryHistoryRecord;
  deleteHistory: Function;
  onSidebarClose: Function;
  onStatusClick: Function;
  onLaboratoryUpdate: Function;
  orderDescriptionField: ChartMetaFormField;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const LaboratoryView = (props: LaboratoryViewProps) => {
  const { formFieldMap, formSubmittedCount, selectedLaboratory, orderDescriptionField, saveChartData, onStatusClick, onSidebarClose, onLaboratoryUpdate, intl, locale } = props;
  const sidebarProps = {
    laboratoryRecord: selectedLaboratory,
    onCloseClick: onSidebarClose,
    onLaboratoryUpdate,
    orderDescriptionField,
    saveChartData,
    intl,
    ...appHelper.getChartSharedProps(props)
  };
  return (
    <div className="laboratory-orders">
      <h1>Laboratory</h1>
      <Box mt>
        <Notice />
        <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      </Box>
      <Box mt3>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <h2 className="u-els-margin-bottom-2x">Chart Inputs</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.CATEGORY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_DESCRIPTION)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_PRIORITY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_FREQUENCY)} formSubmittedCount={formSubmittedCount} />
            <h3 className="u-els-margin-bottom">Order start</h3>
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_START_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_START_TIME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIMEN)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="2o3" md="1o1">
            <LaboratoryHistory chartHistory={props.chartHistory} deleteHistory={props.deleteHistory} onStatusClick={onStatusClick} locale={locale} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <LaboratorySidebar {...sidebarProps} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

LaboratoryView.displayName = 'LaboratoryView';
export default LaboratoryView;

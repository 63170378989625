import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, HorizontalScrollableContainer } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import GeneralOrdersTable from './GeneralOrdersTable';
import './general-orders.scss';
import Notice from '../shared/Notice';
import { FormField as CommonFormField } from '../shared/constants';

export interface GeneralOrdersViewProps extends ChartViewProps {
  chartHistory: ChartFragment[];
  deleteHistory: Function;
  onPatientCardLinkClick: Function;
}

const GeneralOrdersView = (props: GeneralOrdersViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, chartHistory, deleteHistory, onPatientCardLinkClick } = props;

  return (
    <div className="general-orders">
      <h1>General Orders</h1>
      <Notice />
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem className="u-els-margin-top general-orders__chart-input">
            <h2 className="u-els-margin-bottom">Chart Inputs</h2>
            <ErrorFormField
              useDefaultMargin={false}
              className="u-els-margin-bottom-1x1o2 general-orders__order-description"
              formField={formFieldMap.get(FormField.ORDER_DESCRIPTION)}
              formSubmittedCount={formSubmittedCount}
            />
            <ErrorFormField
              useDefaultMargin={false}
              className="u-els-margin-bottom-1x1o2"
              formFieldMap={formFieldMap}
              formField={formFieldMap.get(FormField.ORDER_DETAILS)}
              formSubmittedCount={formSubmittedCount}
            />
            <h3 className="u-els-margin-bottom u-els-margin-top-2x">{LanguageKeys.GENERAL_ORDER.ORDER_START}</h3>
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.ORDER_DATE)}
              formSubmittedCount={formSubmittedCount}
              className="u-els-margin-bottom-1x1o2"
            />
            <ErrorFormField
              useDefaultMargin={false}
              className="input-time-field u-els-margin-bottom-1x1o2"
              formField={formFieldMap.get(FormField.ORDER_TIME)}
              formSubmittedCount={formSubmittedCount}
            />
            <ErrorFormField
              useDefaultMargin={false}
              className="input-time-field u-els-margin-bottom-1x1o2"
              formField={formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET)}
              formSubmittedCount={formSubmittedCount}
            />
          </ELSFlexItem>
          <ELSFlexItem className="o-els-flex-layout__item--grow u-els-margin-top chart-input__scroll-table-container">
            <HorizontalScrollableContainer>
              <h2>Orders</h2>
              <GeneralOrdersTable chartHistory={chartHistory} deleteHistory={deleteHistory} />
              <Box mt>
                <ELSFlex>
                  <ELSFlexItem className="u-els-margin-right">
                    <ELSIcon prefix="gizmo" name="information" size="1x" />
                  </ELSFlexItem>
                  <ELSFlexItem grow>
                    <p>
                      <span>{'View a list of general orders, nutrition orders, and patient teaching for the patient in the '}</span>
                      <ELSButton type="link" text="Patient Card" onClick={onPatientCardLinkClick} />
                      <span>.</span>
                    </p>
                  </ELSFlexItem>
                </ELSFlex>
              </Box>
            </HorizontalScrollableContainer>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

GeneralOrdersView.displayName = 'GeneralOrdersView';
export default GeneralOrdersView;

export enum FormField {
  DAY = 'day',
  TIME = 'time',
  SEX = 'sex',
  TYPE_DELIVERY = 'typeDelivery',
  TYPE_DELIVERY_OTHER = 'typeDeliveryOther',
  COMPLICATIONS_DURING_DELIVERY = 'complicationsDuringDelivery',
  COMPLICATIONS_DURING_DELIVERY_TEXT = 'complicationsDuringDeliveryText',
  APGAR_SCORE = 'apgarScore',
  RESUSCITATION = 'resuscitation',
  COMMENTS = 'comments',
  EYE_PROPHYLAXIS_TIME = 'eyeProphylaxisTime',
  EYE_PROPHYLAXIS_SITE = 'eyeProphylaxisSite',
  EYE_PROPHYLAXIS_INITIALS = 'eyeProphylaxisInitials',
  VITAMIN_K_TIME = 'vitaminKTime',
  VITAMIN_K_SITE = 'vitaminKSite',
  VITAMIN_K_INITIALS = 'vitaminKInitials',
  NALOXONE_TIME = 'naloxoneTime',
  NALOXONE_SITE = 'naloxoneSite',
  NALOXONE_INITIALS = 'naloxoneInitials',
  HEPATITIS_B_VACCINE_TIME = 'hepatitisBVaccineTime',
  HEPATITIS_B_VACCINE_SITE = 'hepatitisBVaccineSite',
  HEPATITIS_B_VACCINE_INITIALS = 'hepatitisBVaccineInitials',
  HEPATITIS_B_IMMUNE_GLOBULIN_TIME = 'hepatitisBImmuneGlobulinTime',
  HEPATITIS_B_IMMUNE_GLOBULIN_SITE = 'hepatitisBImmuneGlobulinSite',
  HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS = 'hepatitisBImmuneGlobulinInitials',
  OTHER_MEDICATIONS_TIME = 'otherMedicationsTime',
  OTHER_MEDICATIONS_SITE = 'otherMedicationsSite',
  OTHER_MEDICATIONS_INITIALS = 'otherMedicationsInitials',
  NUTRITION = 'nutrition',
  DESCRIBE = 'describe',
  DELIVERY_PLACENTA = 'deliveryPlacenta',
  PROVIDER = 'provider',
  TYPE = 'type',
  CORD = 'cord',
  FIRST_STAGE_HOURS = 'firstStageHours',
  SECOND_STAGE_HOURS = 'secondStageHours',
  SECOND_STAGE_MINUTES = 'secondStageMinutes',
  THIRD_STAGE_MINUTES = 'thirdStageMinutes',
  HEART_RATE_1_MINUTES = 'heartRate1Minutes',
  HEART_RATE_5_MINUTES = 'heartRate5Minutes',
  HEART_RATE_10_MINUTES = 'heartRate10Minutes',
  RESPIRATORY_RATE_1_MINUTES = 'respiratoryRate1Minutes',
  RESPIRATORY_RATE_5_MINUTES = 'respiratoryRate5Minutes',
  RESPIRATORY_RATE_10_MINUTES = 'respiratoryRate10Minutes',
  MUSCLE_TONE_1_MINUTES = 'muscleTone1Minutes',
  MUSCLE_TONE_5_MINUTES = 'muscleTone5Minutes',
  MUSCLE_TONE_10_MINUTES = 'muscleTone10Minutes',
  REFLEX_IRRITABILITY_1_MINUTES = 'reflexIrritability1Minutes',
  REFLEX_IRRITABILITY_5_MINUTES = 'reflexIrritability5Minutes',
  REFLEX_IRRITABILITY_10_MINUTES = 'reflexIrritability10Minutes',
  COLOR_1_MINUTES = 'color1Minutes',
  COLOR_5_MINUTES = 'color5Minutes',
  COLOR_10_MINUTES = 'color10Minutes'
}

export enum FormFieldLabel {
  DAY = 'Day',
  TIME = 'Time',
  SEX = 'Sex',
  TYPE_DELIVERY = 'Type of Delivery',
  TYPE_DELIVERY_OTHER = 'Other',
  COMPLICATIONS_DURING_DELIVERY = 'Any complications during delivery?',
  COMPLICATIONS_DURING_DELIVERY_TEXT = 'Explain complications during delivery',
  COMMENTS = 'Comments',
  MEDICATIONS_TIME = 'Time',
  MEDICATIONS_SITE = 'Site',
  MEDICATIONS_INITIALS = 'Initials',
  MINUTES = 'Minutes',
  HOURS = 'Hours',
  DESCRIBE = 'Describe',
  DELIVERY_PLACENTA = 'Delivery of placenta',
  PROVIDER = 'Provider/CNM',
  TYPE = 'Type',
  CORD = 'Cord'
}

export enum SectionTitle {
  DELIVERY_ROOM_RECORD = 'Delivery Room Record',
  DELIVERY = 'Delivery',
  TYPE_OF_DELIVERY = 'Type of delivery',
  DURATION_OF_STAGE_OF_LABOR = 'Duration of Stage of Labor',
  INITIAL_CARE_NEW_BORN = 'Initial Care of New Born',
  APGAR_SCALE = 'Apgar Scale',
  RESUSCITATION = 'Resuscitation',
  EYE_PROPHYLACTIC = 'Eye Prophylactic',
  VITAMIN_K = 'Vitamin K',
  NALOXONE = 'Naloxone',
  HBV = 'Hepatitis B Vaccine',
  HBIG = 'Hepatitis B Immune Globulin (HBIG)',
  Other = 'Other',
  HEART_RATE = 'Heart Rate',
  RESPIRATORY_RATE = 'Respiratory Rate',
  MUSCLE_TONE = 'Muscle tone',
  REFLEX_IRRITABILITY = 'Reflex irritability',
  COLOR = 'Color'
}

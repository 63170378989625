import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams, ChartTableHeader } from 'models/ui';

export enum FormField {
  ANKLE_BRACHIAL_TEST_INDEX_001 = 'ankleBrachialTestIndex-001',
  ANKLE_BRACHIAL_TEST_INDEX_002 = 'ankleBrachialTestIndex-002',
  ANKLE_BRACHIAL_TEST_INDEX_003 = 'ankleBrachialTestIndex-003',
  ANKLE_BRACHIAL_TEST_INDEX_004 = 'ankleBrachialTestIndex-004',
  ANKLE_BRACHIAL_TEST_INDEX_005 = 'ankleBrachialTestIndex-005',
  ANKLE_BRACHIAL_TEST_INDEX_006 = 'ankleBrachialTestIndex-006',
  ANKLE_BRACHIAL_TEST_INDEX_007 = 'ankleBrachialTestIndex-007',
  CAPILLARY_REFILL_LEFT_FOOT = 'capillaryRefillLeftFoot',
  CAPILLARY_REFILL_LEFT_HAND = 'capillaryRefillLeftHand',
  CAPILLARY_REFILL_MUCOUS_MEMBRANES_COLOR = 'capillaryRefillMucousMembranesColor',
  CAPILLARY_REFILL_MUCOUS_MEMBRANES_MOISTURE = 'capillaryRefillMucousMembranesMoisture',
  CAPILLARY_REFILL_NOTES = 'capillaryRefillNotes',
  CAPILLARY_REFILL_RIGHT_FOOT = 'capillaryRefillRightFoot',
  CAPILLARY_REFILL_RIGHT_HAND = 'capillaryRefillRightHand',
  CARDIAC_ASSESSMENT_ELECTROCARDIOGRAM = 'cardiacAssessmentElectrocardiogram',
  CARDIAC_ASSESSMENT_MARK_ALL = 'cardiacAssessmentMarkAll',
  CARDIAC_ASSESSMENT_NOTES = 'cardiacAssessmentNotes',
  CARDIAC_MONITOR = 'cardiacMonitor',
  CARDIAC_MONITOR_ALARMS_TESTED = 'cardiacMonitorAlarmsTested',
  CARDIAC_MONITOR_LOWER_LIMIT = 'cardiacMonitorLowerLimit',
  CARDIAC_MONITOR_UPPER_LIMIT = 'cardiacMonitorUpperLimit',
  CARDIAC_RHYTHM_MARK_ALL = 'cardiacRhythmMarkAll',
  CONTINUOUS_TELEMETRY = 'continuousTelemetry',
  EDEMA_LOWER_EXTREMITIES = 'edemaLowerExtremities',
  EDEMA_NOTES = 'edemaNotes',
  EDEMA_UPPER_EXTREMITIES = 'edemaUpperExtremities',
  GENERALIZED_NONPITTING_EDEMA = 'generalizedNonpittingEdema',
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  NO_CARDIAC_PROBLEMS_NOTED = 'noCardiacProblemsNoted',
  NO_EDEMA_NOTED = 'noEdemaNoted',
  PERIPHERAL_VASCULAR_MARK_ALL = 'peripheralVascularMarkAll',
  PERIPHERAL_VASCULAR_OTHER = 'peripheralVascularOther',
  PULSES_APICAL = 'pulsesApical',
  PULSES_LOCATION = 'pulsesLocation',
  PULSES_LOCATION_CAROTID_MURMUR_NOTED = 'pulsesLocationCarotidMurmurNoted',
  PULSES_LOCATION_IMG = 'pulsesLocationImg',
  PULSES_LOCATION_LEVEL = 'pulsesLocationLevel',
  PULSES_LOCATION_LEVEL_DOPPLER = 'pulsesLocationLevelDoppler',
  PULSES_LOCATION_PERIPHERAL_PULSE = 'pulsesLocationPeripheralPulse',
  PULSES_MURMUR_DESCRIPTION = 'pulsesMurmurDescription',
  PULSES_MURMUR_NOTED = 'pulsesMurmurNoted',
  TELEMETRY_ALARMS_TESTED = 'telemetryAlarmsTested',
  TELEMETRY_LOWER_LIMIT = 'telemetryLowerLimit',
  TELEMETRY_NOTES = 'telemetryNotes',
  TELEMETRY_UPPER_LIMIT = 'telemetryUpperLimit'
}

export enum PulseLocationTableHeaderText {
  LOCATION = 'Location',
  PULSE = 'Pulse',
  MURMUR = 'Murmur?',
  IRREGULAR = 'Irregular?'
}

export enum PulseLocationTableHeaderField {
  LOCATION = 'location',
  PULSE_AND_DOPPLER = 'pulseAndDoppler',
  MURMUR = 'murmur',
  IRREGULAR = 'irregular',
  ACTION_DELETE = 'actionDelete'
}

export const PulseLocationTableHeaders: ChartTableHeader[] = [
  { text: PulseLocationTableHeaderText.LOCATION, field: PulseLocationTableHeaderField.LOCATION, sortable: true },
  { text: PulseLocationTableHeaderText.PULSE, field: PulseLocationTableHeaderField.PULSE_AND_DOPPLER },
  { text: PulseLocationTableHeaderText.MURMUR, field: PulseLocationTableHeaderField.MURMUR },
  { text: PulseLocationTableHeaderText.IRREGULAR, field: PulseLocationTableHeaderField.IRREGULAR },
  { field: PulseLocationTableHeaderField.ACTION_DELETE }
];

export const CardiovascularAssessmentFormFieldBuilderItems: ChartMetaFormFieldParams[] = [
  {
    name: FormField.NO_ASSESSMENT_REQUIRED,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.PULSES_APICAL,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.APICAL
  },
  {
    name: FormField.PULSES_MURMUR_NOTED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.MURMUR_NOTED
  },
  {
    name: FormField.PULSES_MURMUR_DESCRIPTION,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.MURMUR_DESCRIPTION
  },
  {
    name: FormField.PULSES_LOCATION,
    type: FormFieldInputType.DROPDOWN,
    label: LanguageKeys.LOCATION
  },
  {
    name: FormField.PULSES_LOCATION_IMG,
    type: FormFieldInputType.IMAGE
  },
  {
    name: FormField.PULSES_LOCATION_LEVEL,
    type: FormFieldInputType.RADIO_CHOICE
  },
  {
    name: FormField.PULSES_LOCATION_LEVEL_DOPPLER,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.PULSES_LOCATION_CAROTID_MURMUR_NOTED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.MURMUR_NOTED
  },
  {
    name: FormField.PULSES_LOCATION_PERIPHERAL_PULSE,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.PULSES
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_001,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.RIGHT_ARM_SYSTOLIC_BLOOD_PRESSURE
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_002,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.LEFT_ARM_SYSTOLIC_BLOOD_PRESSURE
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_003,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.RIGHT_ANKLE_SYSTOLIC_PRESSURE_POSTERIOR_TIBIAL
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_004,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.RIGHT_ANKLE_SYSTOLIC_PRESSURE_DORSALIS_PEDIS
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_005,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.LEFT_ANKLE_SYSTOLIC_PRESSURE_POSTERIOR_TIBIAL
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_006,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.LEFT_ANKLE_SYSTOLIC_PRESSURE_DORSALIS_PEDIS
  },
  {
    name: FormField.ANKLE_BRACHIAL_TEST_INDEX_007,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.INDEX_SCORE
  },
  {
    name: FormField.PERIPHERAL_VASCULAR_MARK_ALL,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    label: LanguageKeys.MARK_ALL_THAT_APPLY
  },
  {
    name: FormField.PERIPHERAL_VASCULAR_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.OTHER
  },
  {
    name: FormField.CAPILLARY_REFILL_LEFT_HAND,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.LEFT_HAND
  },
  {
    name: FormField.CAPILLARY_REFILL_LEFT_FOOT,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.LEFT_FOOT
  },
  {
    name: FormField.CAPILLARY_REFILL_RIGHT_HAND,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.RIGHT_HAND
  },
  {
    name: FormField.CAPILLARY_REFILL_RIGHT_FOOT,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.RIGHT_FOOT
  },
  {
    name: FormField.CAPILLARY_REFILL_MUCOUS_MEMBRANES_COLOR,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.MUCOUS_MEMBRANES_COLOR
  },
  {
    name: FormField.CAPILLARY_REFILL_MUCOUS_MEMBRANES_MOISTURE,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.MUCOUS_MEMBRANES_MOISTURE
  },
  {
    name: FormField.CAPILLARY_REFILL_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.NO_EDEMA_NOTED,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.GENERALIZED_NONPITTING_EDEMA,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.EDEMA_UPPER_EXTREMITIES,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.UPPER_EXTREMITIES
  },
  {
    name: FormField.EDEMA_LOWER_EXTREMITIES,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.LOWER_EXTREMITIES
  },
  {
    name: FormField.EDEMA_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.NO_CARDIAC_PROBLEMS_NOTED,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.CARDIAC_ASSESSMENT_MARK_ALL,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.CARDIAC_ASSESSMENT_ELECTROCARDIOGRAM,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.CARDIAC_ASSESSMENT_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.CONTINUOUS_TELEMETRY,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.CONTINUOUS_TELEMETRY
  },
  {
    name: FormField.TELEMETRY_UPPER_LIMIT,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.UPPER_LIMIT
  },
  {
    name: FormField.TELEMETRY_LOWER_LIMIT,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.LOWER_LIMIT
  },
  {
    name: FormField.CARDIAC_RHYTHM_MARK_ALL,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.TELEMETRY_ALARMS_TESTED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ALARMS_TESTED
  },
  {
    name: FormField.TELEMETRY_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.CARDIAC_MONITOR,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.CARDIAC_MONITOR_UPPER_LIMIT,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.UPPER_LIMIT
  },
  {
    name: FormField.CARDIAC_MONITOR_LOWER_LIMIT,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.LOWER_LIMIT
  },
  {
    name: FormField.CARDIAC_MONITOR_ALARMS_TESTED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ALARMS_TESTED
  }
];

import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';

export enum FormField {
  CLINICAL_FIRST_DAY = 'clinicalFirstDay',
  CLINICAL_UNIT = 'clinicalUnit',
  PATIENT_NAME = 'patientName',
  PATIENT_IDENTIFIER = 'patientIdentifier',
  PATIENT_SEX = 'patientSex',
  PATIENT_DOB = 'patientDateOfBirth',
  PROVIDER_FIRST_LAST_INITIAL = 'providerFirstLastInitial',
  STUDENT_FIRST_INITIAL = 'studentFirstInitial',
  STUDENT_LAST_NAME = 'studentLastName',
  HIPAA = 'hipaa',
  MRN = 'MRN',
  ROOM_NUMBER = 'roomNumber',
  CLINICAL_INFO = 'clinicalInfo',
  PROVIDER_INFO = 'providerInfo',
  SPECIFY_BELOW = 'specifyBelow',
  GENDER = 'gender',
  PRONOUNS = 'pronouns'
}

export const getClinicalSetupFormFieldBuilderItems = (locale: Locales, isShowClinicalUnit: boolean, patientData): ChartMetaFormFieldParams[] => [
  {
    name: FormField.CLINICAL_FIRST_DAY,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.CLINICAL_SETUP.FIRST_DAY_OF_CLINICAL,
    value: dateTimeHelper.formatDate({
      date: patientData?.[FormField.CLINICAL_FIRST_DAY] ? dateTimeHelper.toMoment(patientData?.[FormField.CLINICAL_FIRST_DAY]).toDate() : undefined,
      locale
    }),
    errorLabel: LanguageKeys.CLINICAL_SETUP.FIRST_DATE
  },
  {
    name: FormField.CLINICAL_UNIT,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.CLINICAL_SETUP.CLINICAL_UNIT,
    contentIds: patientData?.[FormField.CLINICAL_UNIT].choices.map((item) => item.id),
    isHidden: !isShowClinicalUnit
  },
  {
    name: FormField.PATIENT_IDENTIFIER,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.CLINICAL_SETUP.PATIENT_IDENTIFIER,
    value: patientData?.[FormField.PATIENT_IDENTIFIER]
  },
  { name: FormField.PATIENT_SEX, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.CLINICAL_SETUP.SEX, value: patientData?.[FormField.PATIENT_SEX] },
  { name: FormField.SPECIFY_BELOW, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.SPECIFY_BELOW, value: patientData?.[FormField.SPECIFY_BELOW] },
  { name: FormField.GENDER, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.CLINICAL_SETUP.GENDER, value: patientData?.[FormField.GENDER] },
  { name: FormField.PRONOUNS, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.PRONOUNS, value: patientData?.[FormField.PRONOUNS] },
  {
    name: FormField.PATIENT_DOB,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.CLINICAL_SETUP.DATE_OF_BIRTH,
    value: dateTimeHelper.formatDate({
      date: patientData?.[FormField.PATIENT_DOB] ? dateTimeHelper.toMoment(patientData?.[FormField.PATIENT_DOB]).toDate() : undefined,
      locale
    }),
    errorLabel: LanguageKeys.CLINICAL_SETUP.BIRTH_DATE
  },
  {
    name: FormField.PROVIDER_FIRST_LAST_INITIAL,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.CLINICAL_SETUP.FIRST_AND_LAST_INITIAL,
    value: patientData?.[FormField.PROVIDER_FIRST_LAST_INITIAL]
  },
  { name: FormField.STUDENT_FIRST_INITIAL, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.FIRST_INITIAL },
  { name: FormField.STUDENT_LAST_NAME, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.LAST_NAME },
  { name: FormField.HIPAA, type: FormFieldInputType.TEXT_BLOCK, label: LanguageKeys.CLINICAL_SETUP.HIPAA_COMPLIANCE },
  { name: FormField.PATIENT_NAME, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.PATIENT_NAME },
  { name: FormField.MRN, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.MRN },
  { name: FormField.ROOM_NUMBER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.CLINICAL_SETUP.ROOM_NUMBER },
  { name: FormField.CLINICAL_INFO, type: FormFieldInputType.TEXT_BLOCK, label: LanguageKeys.CLINICAL_SETUP.CLINICAL_INFO },
  { name: FormField.PROVIDER_INFO, type: FormFieldInputType.TEXT_BLOCK, label: LanguageKeys.CLINICAL_SETUP.PROVIDER_INFO }
];

export enum SectionTitle {
  RESPIRATORY_INTERVENTIONS = 'Respiratory Interventions',
  RESPIRATORY_CARE = 'Respiratory Care',
  DRAIN_TUBE_CARE = 'Drain or Tube Care',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Respiratory Care Notes'
}

export enum FormField {
  NO_CARE = 'noCareRequired',
  RESP_INTERVENTIONS_CARE = 'respiratoryCare',
  RESP_DRAIN_TUBE_CARE = 'drainOrTubeCare',
  PATIENT_RESPONSE = 'patientResponse',
  CARE_NOTES = 'respiratoryCareNotes'
}

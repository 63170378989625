import cx from 'classnames';
import { Component } from 'react';
import { PatientCardRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Box, ChartHistory } from 'components/common';
import { ELSButton, ELSIcon, ELSTooltip } from 'components/common/els';
import { Section } from './constants';

export interface PatientCardTableProps {
  chartHistory: PatientCardRecord[];
  onHoldRecord: Function;
  section: Section;
  handleDiscontinueClick: Function;
  onDescriptionClick?: Function;
}

class PatientCardTable extends Component<PatientCardTableProps> {
  static displayName = 'PatientCardTable';

  descriptionRenderer = (row: PatientCardRecord) => {
    return row.linkTo ? (
      <ELSButton
        className={cx({ 'u-els-font-family-bold u-els-color-secondary': this.props.section === Section.ACTION_LIST })}
        type="link"
        text={row.description}
        onClick={() => this.props.onDescriptionClick?.(row)}
      />
    ) : (
      <p>{row.description}</p>
    );
  };

  customActionHeader = () => {
    return (
      <div className="patient-card-table__action-header">
        <div className="label">Actions</div>
        <div className="icon">
          <ELSTooltip content="To hold or discontinue" showClose>
            <ELSIcon prefix="gizmo" name="information" color="secondary" size="1x" />
          </ELSTooltip>
        </div>
      </div>
    );
  };

  actionRenderer = (row) => {
    const { onHoldRecord, handleDiscontinueClick } = this.props;
    const { isHold } = row;
    return (
      <div className="patient-card-table__action-field">
        <button className="sng-button-icon-wrapper" type="button" aria-label="Hole" onClick={() => onHoldRecord(row)}>
          <ELSIcon color="secondary" name={isHold ? 'pause-solid' : 'pause-hollow'} size="1x" />
        </button>

        <button
          className={cx('sng-button-icon-wrapper', { 'discontinue-button': isHold })}
          type="button"
          aria-label="Discontinue"
          onClick={() => handleDiscontinueClick(row)}
          disabled={isHold}
        >
          <ELSIcon color={isHold ? 'n3' : 'secondary'} name="hand" size="1x" />
        </button>
      </div>
    );
  };

  render() {
    const { chartHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true },
        { text: 'Description', field: 'description', align: ColumnAlign.Left, customRender: this.descriptionRenderer },
        { text: 'Category', field: 'category', sortable: true, align: ColumnAlign.Left },
        { text: 'Last Performed', field: 'lastPerformed', align: ColumnAlign.Left },
        { text: 'Actions', field: 'actions', customHeader: this.customActionHeader(), customRender: this.actionRenderer }
      ],
      css: ['column__chart-time', 'column__description', 'column__category', '', 'column__note'],
      data: chartHistory
    };
    return (
      <Box mt className="patient-card-table">
        <ChartHistory dataSource={dataSource} />
      </Box>
    );
  }
}

export default PatientCardTable;

import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const PostpartumInterventionsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="respiratory-interventions">
      <h1>{SectionTitle.POSTPARTUM_INTERVENTIONS}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.POSTPARTUM_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.POSTPARTUM_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.NEWBORN_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.NEWBORN_CARE)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.MOTHER_NEWBORN_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.MOTHER_NEWBORN_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <Box mb>
        <h2>{SectionTitle.POSTPARTUM_CARE_NOTES}</h2>
      </Box>
      <ErrorFormField formField={formFieldMap.get(FormField.POSTPARTUM_CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};
PostpartumInterventionsView.displayName = 'PostpartumInterventionsView';
export default PostpartumInterventionsView;

import { Box } from 'components/common';
import { ELSButton } from 'components/common/els';

interface DeletionConfirmationModalProps {
  message?: string;
  onConfirmClick: Function;
  onCancelClick: Function;
}

const DeletionConfirmationModal = (props: DeletionConfirmationModalProps) => (
  <div role="dialog" aria-modal="true" aria-label={props.message || 'Are you sure you want to delete this entry?'}>
    <p>{props.message ? props.message : 'Are you sure you want to delete this entry?'}</p>
    <Box mt2>
      <ELSButton className="sng-button u-els-margin-right" type="warn" text="Delete" ariaLabel="Delete Entry" onClick={props.onConfirmClick} />
      <ELSButton className="sng-button" type="secondary" text="Cancel" ariaLabel="Close Navigation" onClick={props.onCancelClick} />
    </Box>
  </div>
);

DeletionConfirmationModal.displayName = 'DeletionConfirmationModal';
export default DeletionConfirmationModal;

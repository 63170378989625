export enum Section {
  MODIFIED_BRADEN_Q_SCALE = 'Modified Braden Q Scale',
  TOTAL_SCORE = 'Total Score'
}

export enum FormField {
  MODIFIED_BRADEN_Q_SCALE = 'ratingScale',
  SENSORY_PERCEPTION = 'sensoryPerception',
  MOISTURE = 'moisture',
  ACTIVITY = 'activity',
  MOBILITY = 'mobility',
  NUTRITION = 'nutrition',
  FRICTION_SHEAR = 'frictionShear',
  TISSUE_PERFUSION = 'tissuePerfusion'
}

import { LanguageKeys } from 'lang';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, HeightWeightRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, HeightWeightGraphItem } from 'models/ui';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { appActions } from 'redux/ducks/app';
import { ErrorFormField, withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, GRAPH_CHART_TYPE, getHeightWeightFormFieldBuilderItems } from './constants';
import HeightWeightView, { HeightWeightViewProps } from './HeightWeightView';
import {
  convertForAbdominalGirthCmInput,
  convertForAbdominalGirthInchInput,
  convertForChestCircumferenceCmInput,
  convertForChestCircumferenceInchInput,
  convertForHeadCircumferenceCmInput,
  convertForHeadCircumferenceInchInput,
  convertForHeightCmInput,
  convertForHeightFeetAndInchInput,
  convertForWeightKgAndGramInput,
  convertForWeightPoundAndOunceInput,
  flattenHeightData,
  getChartInputData,
  getFixedStringValue,
  getStringValue
} from './helper';

export interface HeightWeightProps extends ChartComponentProps {
  updatePatient: Function;
}

interface HeightWeightState {
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartHistory: HeightWeightRecord[];
  graphData: HeightWeightGraphItem[];
  fragments: ChartFragment[];
  graphChartType: string;
}

class HeightWeight extends Component<HeightWeightProps, HeightWeightState> {
  constructor(props) {
    super(props);
    this.state = this.buildDefaultState();
  }

  componentDidMount() {
    this.loadHeightWeight();
  }

  componentDidUpdate(prevProps: Readonly<HeightWeightProps>, prevState: Readonly<HeightWeightState>) {
    if (this.state.graphChartType !== prevState.graphChartType) {
      this.setState((state) => ({
        graphData: this.transformGraphData(state.chartHistory)
      }));
    }
  }

  infantContainer = (children) => (
    <ErrorFormField formField={this.props.formFieldMap.get(FormField.CONTAINER_INFANT)} formSubmittedCount={this.props.formSubmittedCount}>
      {children}
    </ErrorFormField>
  );

  buildDefaultState = (): HeightWeightState => ({
    chartHistory: [],
    chartMetaFormFields: this.buildDefaultFormFields(),
    graphData: [],
    fragments: [],
    graphChartType: GRAPH_CHART_TYPE.WEIGHT
  });

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getHeightWeightFormFieldBuilderItems(this.heightOrWeightChange).forEach(({ name, type, label, onGroupChange, errorLabel }) => {
      dataMap.set(name, createFormField({ name, type, label, onGroupChange, errorLabel }));
    });

    return dataMap;
  };

  buildWeightDisplay = (item) => {
    const { intl } = this.props;
    let poundsAndOunces = '';
    let kilogramsAndGrams = '';
    if (item.weight.pounds) {
      poundsAndOunces = `${poundsAndOunces}${item.weight.pounds} lb `;
    }
    if (item.weight.ounces) {
      poundsAndOunces = `${poundsAndOunces}${item.weight.ounces} oz`;
    }

    if (item.weight.kilograms) {
      kilogramsAndGrams = `${kilogramsAndGrams}${item.weight.kilograms} kg `;
    }
    if (item.weight.grams) {
      kilogramsAndGrams = `${kilogramsAndGrams}${item.weight.grams} g`;
    }
    return intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.WEIGHT_DISPLAY }, { poundsAndOunces, kilogramsAndGrams });
  };

  transformGraphData = (dataRes: HeightWeightRecord[]): HeightWeightGraphItem[] => {
    const { intl, locale } = this.props;
    return dataRes.reduce((result: HeightWeightGraphItem[], item) => {
      if (!item.active) return result;
      let weightDisplay = '';
      let abdominalGirthDisplay = '';
      let canPush = false;
      if (this.state.graphChartType === GRAPH_CHART_TYPE.WEIGHT && item.weight) {
        weightDisplay = this.buildWeightDisplay(item);
        canPush = true;
      } else if (this.state.graphChartType === GRAPH_CHART_TYPE.ABDOMINAL_GIRTH && item.abdominalGirth) {
        const { abdominalGirthCm, abdominalGirthInch } = item.abdominalGirth;
        abdominalGirthDisplay = `${abdominalGirthInch}"/ ${abdominalGirthCm} cm`;
        canPush = true;
      }
      if (canPush) {
        result.push({
          date: dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(item.chartingAt).toDate(), locale }),
          dateTime: dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(item.chartingAt).toDate(), locale }),
          weight: intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.Y_LINE_IN_THE_HEIGHT_WEIGHT_GRAPH }, { pounds: item.weight?.pounds, kilograms: item.weight?.kilograms }),
          weightDisplay,
          abdominalGirth: item.abdominalGirth?.abdominalGirthCm,
          abdominalGirthDisplay
        });
      }
      return result;
    }, []);
  };

  updateMapValue = (heightField: ChartMetaFormField, value: string) => {
    const newHeightField = { ...heightField, value };
    this.props.formFieldMap.set(heightField.name, newHeightField);
  };

  heightOrWeightChange = (chartField: ChartMetaFormField): ChartMetaFormField[] => {
    const { formFieldMap } = this.props;
    let feet = Number(formFieldMap.get(FormField.HEIGHT_FEET).value);
    let inch = Number(formFieldMap.get(FormField.HEIGHT_INCH).value);
    let cm = Number(formFieldMap.get(FormField.HEIGHT_CM).value);
    let pound = Number(formFieldMap.get(FormField.WEIGHT_POUND).value);
    let ounce = Number(formFieldMap.get(FormField.WEIGHT_OUNCE).value);
    let kg = Number(formFieldMap.get(FormField.WEIGHT_KG).value);
    let gram = Number(formFieldMap.get(FormField.WEIGHT_GRAM).value);
    let abdominalGirthCm = Number(formFieldMap.get(FormField.ABDOMINAL_GIRTH_CM)?.value);
    let abdominalGirthInch = Number(formFieldMap.get(FormField.ABDOMINAL_GIRTH_INCH)?.value);
    let headCircumferenceCm = Number(formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_CM)?.value);
    let headCircumferenceInch = Number(formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_INCH)?.value);
    let chestCircumferenceCm = Number(formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_CM)?.value);
    let chestCircumferenceInch = Number(formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_INCH)?.value);

    switch (chartField.name) {
      case FormField.HEIGHT_FEET:
      case FormField.HEIGHT_INCH: {
        const { newCm, newFeet, newInch } = convertForHeightFeetAndInchInput(feet, inch);
        cm = newCm;
        feet = newFeet;
        inch = newInch;
        break;
      }

      case FormField.HEIGHT_CM: {
        const { newCm, newFeet, newInch } = convertForHeightCmInput(cm);
        cm = newCm;
        feet = newFeet;
        inch = newInch;
        break;
      }

      case FormField.WEIGHT_POUND:
      case FormField.WEIGHT_OUNCE: {
        const { newGram, newKg, newOunce, newPound } = convertForWeightPoundAndOunceInput(pound, ounce);
        gram = newGram;
        kg = newKg;
        ounce = newOunce;
        pound = newPound;
        break;
      }
      case FormField.WEIGHT_KG:
      case FormField.WEIGHT_GRAM: {
        const { newGram, newKg, newOunce, newPound } = convertForWeightKgAndGramInput(kg, gram);
        gram = newGram;
        kg = newKg;
        ounce = newOunce;
        pound = newPound;
        break;
      }
      case FormField.ABDOMINAL_GIRTH_CM:
        abdominalGirthInch = convertForAbdominalGirthCmInput(abdominalGirthCm);
        break;
      case FormField.ABDOMINAL_GIRTH_INCH:
        abdominalGirthCm = convertForAbdominalGirthInchInput(abdominalGirthInch);
        break;
      case FormField.HEAD_CIRCUMFERENCE_CM:
        headCircumferenceInch = convertForHeadCircumferenceCmInput(headCircumferenceCm);
        break;
      case FormField.HEAD_CIRCUMFERENCE_INCH:
        headCircumferenceCm = convertForHeadCircumferenceInchInput(headCircumferenceInch);
        break;
      case FormField.CHEST_CIRCUMFERENCE_CM:
        chestCircumferenceInch = convertForChestCircumferenceCmInput(chestCircumferenceCm);
        break;
      case FormField.CHEST_CIRCUMFERENCE_INCH:
        chestCircumferenceCm = convertForChestCircumferenceInchInput(chestCircumferenceInch);
        break;
      default:
        break;
    }

    this.updateMapValue(formFieldMap.get(FormField.HEIGHT_FEET), getStringValue(feet));
    this.updateMapValue(formFieldMap.get(FormField.HEIGHT_INCH), getStringValue(inch));
    this.updateMapValue(formFieldMap.get(FormField.HEIGHT_CM), getFixedStringValue(cm, 1));
    this.updateMapValue(formFieldMap.get(FormField.WEIGHT_POUND), getStringValue(pound));
    this.updateMapValue(formFieldMap.get(FormField.WEIGHT_OUNCE), getFixedStringValue(ounce));
    this.updateMapValue(formFieldMap.get(FormField.WEIGHT_KG), getStringValue(kg));
    this.updateMapValue(formFieldMap.get(FormField.WEIGHT_GRAM), getFixedStringValue(gram));
    this.updateMapValue(formFieldMap.get(FormField.ABDOMINAL_GIRTH_CM), getFixedStringValue(abdominalGirthCm, 1));
    this.updateMapValue(formFieldMap.get(FormField.ABDOMINAL_GIRTH_INCH), getStringValue(abdominalGirthInch));
    this.updateMapValue(formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_CM), getFixedStringValue(headCircumferenceCm, 1));
    this.updateMapValue(formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_INCH), getStringValue(headCircumferenceInch));
    this.updateMapValue(formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_CM), getFixedStringValue(chestCircumferenceCm, 1));
    this.updateMapValue(formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_INCH), getStringValue(chestCircumferenceInch));

    return [
      formFieldMap.get(FormField.HEIGHT_FEET),
      formFieldMap.get(FormField.HEIGHT_INCH),
      formFieldMap.get(FormField.HEIGHT_CM),
      formFieldMap.get(FormField.WEIGHT_POUND),
      formFieldMap.get(FormField.WEIGHT_OUNCE),
      formFieldMap.get(FormField.WEIGHT_KG),
      formFieldMap.get(FormField.WEIGHT_GRAM),
      formFieldMap.get(FormField.ABDOMINAL_GIRTH_CM),
      formFieldMap.get(FormField.ABDOMINAL_GIRTH_INCH),
      formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_CM),
      formFieldMap.get(FormField.HEAD_CIRCUMFERENCE_INCH),
      formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_CM),
      formFieldMap.get(FormField.CHEST_CIRCUMFERENCE_INCH)
    ];
  };

  loadHeightWeight = (updatePatientRibbon?: boolean) => {
    this.props.initState(this.buildDefaultFormFields());

    return appHelper.useLoader(
      this.props.loadChartData().then(({ data }) => {
        const records = data.map((fragment) => {
          const { fragmentId: id, active, chartingAt, createdAt, creator, modifier } = fragment;
          return { id, active, chartingAt, createdAt, creator, modifier, ...fragment.chartData };
        });
        // loop to fix old data that was just saved in grams
        const fixedRecords: HeightWeightRecord[] = [];
        (records as HeightWeightRecord[]).forEach((value) => {
          let newRecord: HeightWeightRecord = value;
          if (value.weight && value.weight.grams && !value.weight.pounds) {
            newRecord = flattenHeightData(newRecord, FormField.WEIGHT_GRAM);
          }
          if (value.height && value.height.centimeters && !value.height.feet) {
            newRecord = flattenHeightData(newRecord, FormField.HEIGHT_CM);
          }
          fixedRecords.push(newRecord);
        });

        const graphData = this.transformGraphData(fixedRecords);
        this.setState((prevState) => ({
          ...prevState,
          graphData,
          chartHistory: fixedRecords,
          fragments: data
        }));

        // update patient data if we are saving or deleting a record
        if (updatePatientRibbon) {
          const latestHeightWeightValues = chartHelper.getLatestHeightWeightValues(data);
          this.props.updatePatient({
            heightWeightRecord: { ...latestHeightWeightValues }
          });
        }
      })
    );
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const { createBaseFragment } = chartService;
    const basicInfo = createBaseFragment({ chartingTime: this.props.chartingTime });
    const {
      cmValue,
      feetValue,
      inchValue,
      gramValue,
      kgValue,
      poundValue,
      ounceValue,
      abdominalGirth,
      anteriorFontanel,
      posteriorFontanel,
      headCircumference,
      chestCircumference
    } = getChartInputData(formFieldMap, this.heightOrWeightChange);

    const chartData = {
      notes: formFieldMap.get(FormField.NOTE).value,
      // if the user does not enter a value for height, set height to null
      height: cmValue ? { centimeters: cmValue, feet: feetValue, inches: inchValue } : null,
      weight:
        gramValue || kgValue || poundValue
          ? {
              pounds: poundValue,
              ounces: ounceValue,
              kilograms: kgValue,
              grams: gramValue,
              choice: formFieldMap.get(FormField.WEIGHT_METHOD).value,
              weightAdmission: Boolean(formFieldMap.get(FormField.WEIGHT_ADMISSION).value)
            }
          : null,
      abdominalGirth,
      anteriorFontanel,
      posteriorFontanel,
      headCircumference,
      chestCircumference
    };
    return { ...basicInfo, chartData };
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(() => this.loadHeightWeight(true))
        .then(this.props.showDeleteSuccess)
    );
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildDefaultFormFields(),
      afterSave: this.afterSave
    });
  };

  afterSave = () => {
    return this.loadHeightWeight(true).then(() => {
      if (this.props.backToSourceLocation) {
        this.props.backToSourceLocation();
      }
    });
  };

  onSelectGraph = ({ target: { value } }) => {
    this.setState({ graphChartType: value });
  };

  render() {
    const { handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, getContentMap, intl, locale } = this.props;
    const { graphData, chartHistory, graphChartType } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: HeightWeightViewProps = {
      chartMetaFormFields: formFieldMap,
      formSubmittedCount,
      graphData,
      chartHistory,
      deleteHistory: this.deleteHistory,
      chartMetaContentMap: getContentMap(),
      graphChartType,
      yGraphLabel:
        graphChartType === GRAPH_CHART_TYPE.WEIGHT ? intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.WEIGHT_IN_POUNDS }) : LanguageKeys.HEIGHT_WEIGHT.ABDOMINAL_GIRTH_IN_CM,
      onSelectGraph: this.onSelectGraph,
      infantContainer: this.infantContainer,
      intl,
      chartActionsProps,
      locale
    };

    return <HeightWeightView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (newPatientData) => dispatch(appActions.updatePatient(newPatientData))
});

const enhancers = [withChartLogic, withSavedPatientChartsPage, connect(null, mapDispatchToProps)];

export { HeightWeight as BaseHeightWeight };
export default compose(...enhancers)(HeightWeight);

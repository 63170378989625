import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const NeurologicalInterventionsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="neurological-interventions">
      <h1>Neurological Interventions</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.NEURO_INTERVENTIONS_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.NEURO_INTERVENTIONS_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.SEIZURE_PRECAUTIONS}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.SEIZURE_PRECAUTIONS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.EXTRAPYRAMIDAL_SIDE_EFFECTS}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.EXTRAPYRAMIDAL_SIDE_EFFECTS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.NEUROLEPTIC_MALIGNANT_SYNDROME}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.NEUROLEPTIC_MALIGNANT_SYNDROME)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};
NeurologicalInterventionsView.displayName = 'NeurologicalInterventionsView';
export default NeurologicalInterventionsView;

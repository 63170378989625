import { camelCase } from 'lodash';
import { LaboratoryHistoryRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, ExternalReference } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, Sidebar } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import LaboratoryReadonly from './LaboratoryReadonly';
import { FormField as CommonFormField } from '../shared/constants';

export interface LaboratorySidebarViewProps {
  openSidebar: boolean;
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  isReadOnly: boolean;
  laboratoryRecord: LaboratoryHistoryRecord;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  referenceFields: ExternalReference[];
  sidebarName: string;
  sidebarProps: Function;
}

const LaboratorySidebarView = (props: LaboratorySidebarViewProps) => {
  const { openSidebar, formSubmittedCount, formFieldMap, isReadOnly, laboratoryRecord, chartActionsProps, onCloseClick, referenceFields, sidebarName, sidebarProps } = props;
  return (
    <Sidebar open={openSidebar} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
      <Box mt3>
        <h2>{`Laboratory Results - ${sidebarName}`}</h2>
      </Box>
      <div className="sidebar__divider" />
      {isReadOnly ? (
        <LaboratoryReadonly laboratory={laboratoryRecord} referenceFields={referenceFields} />
      ) : (
        <Box mt className="laboratory-sidebar">
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} className="u-els-margin-bottom" />
          {referenceFields.map((field) => (
            <ELSFlex left middle gutters key={camelCase(field.label)}>
              <ELSFlexItem className="laboratory-sidebar__reference-input">
                <ErrorFormField formField={formFieldMap.get(camelCase(field.label))} formSubmittedCount={formSubmittedCount} enableTooltip />
              </ELSFlexItem>
              <ELSFlexItem grow>
                <span>{field.unit}</span>
              </ELSFlexItem>
            </ELSFlex>
          ))}
          <Box>
            <h4 className="u-els-margin-bottom">Results Received</h4>
            <ErrorFormField formField={formFieldMap.get(FormField.RESULTS_RECEIVED_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.RESULTS_RECEIVED_TIME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(CommonFormField.RESULTS_RECEIVED_TIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
          </Box>
          <Box mt4 mb>
            <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
          </Box>
        </Box>
      )}
    </Sidebar>
  );
};
LaboratorySidebarView.displayName = 'LaboratorySidebarView';
export default LaboratorySidebarView;

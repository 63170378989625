/* eslint-disable no-param-reassign */
import produce from 'immer';
import { orderBy, unionBy } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { ELSModalServiceType } from 'models/els';
import { FormFieldDataType, FormFieldInputType, ValidationActionType, ValidationRuleType } from 'models/enum';
import { ChartFieldContent, ChartMetaFormField, ChartMetadata, ContentItem, PatientContext, Score, SideBarCommonProps, ValidationRule } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { isUnscoredQuestion } from 'helpers/chart.helper';
import { chartService, logService, patientContextService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { ConfirmationModal, ValidationManager } from 'components/common';
import { ELSWithModalService } from 'components/common/els';
import {
  clearQuestionDataIfScaleDataIsBlank,
  getCurrentQuestionFieldForUpdateScale,
  handleForCheckboxDropdownMultiselectDropDownRadioChoiceMultiselectRadio,
  handleForImage,
  handleForScale,
  handleForTextBlock,
  handleForTextBoxAndTextArea
} from './helper';

export interface WithChartMetadataProps {
  selectedNavId: string;
  patientContext: PatientContext;
  pushUnsavedChangesList: Function;
  modalService: ELSModalServiceType;
  existedChartMetadata?: ChartMetadata;
  locale: Locales;
}

interface WithChartMetadataState {
  chartMetadata: ChartMetadata;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  isDirty: boolean;
  initStateCounter: number;
  isMetadataLoaded: boolean;
  isChartSidebar: boolean;
  isChartSidebarOpen: boolean;
  customUnsavedChanges: Function;
}

const withChartMetadata = (WrappedComponent) => {
  class WithChartMetadata extends Component<WithChartMetadataProps, WithChartMetadataState> {
    componentId = v4();
    validationManager: ValidationManager;
    extraValidationRules: ValidationRule[] = [];
    warningUnsavedCommentModalId = 'loseDataModal';

    constructor(props) {
      super(props);
      this.state = {
        isDirty: false,
        initStateCounter: 0,
        isMetadataLoaded: false,
        isChartSidebar: false,
        isChartSidebarOpen: false,
        chartMetadata: props.existedChartMetadata || {
          chartFieldContentSet: [],
          chartFieldValidationSet: []
        },
        formFieldMap: new Map<string, ChartMetaFormField>(),
        formSubmittedCount: 0,
        customUnsavedChanges: null
      };
      this.validationManager = new ValidationManager();
    }

    componentDidMount() {
      if (this.props.pushUnsavedChangesList) {
        this.props.pushUnsavedChangesList({ [this.componentId]: this.hasUnsavedChanges });
      }

      this.fetchChartMetadataWithPatientContextCheck();
    }

    componentDidUpdate(prevProps: Readonly<WithChartMetadataProps>, prevState: Readonly<WithChartMetadataState>) {
      const { chartMetadata, formFieldMap, isMetadataLoaded, initStateCounter } = this.state;
      const { isMetadataLoaded: preIsMetadataLoaded, initStateCounter: prevInitStateCounter } = prevState;
      const { patientContext } = this.props;
      const { patientContext: prevPatientContext } = prevProps;

      // This is to prevent fetch old data when locale is about to change.
      if (prevPatientContext && patientContext && patientContext.locale !== prevPatientContext.locale) {
        this.setState(() => ({ initStateCounter: prevInitStateCounter + 1, isMetadataLoaded: false }));
        this.fetchChartMetadataWithPatientContextCheck();
      }

      if (this.state.customUnsavedChanges !== prevState.customUnsavedChanges) {
        this.props.pushUnsavedChangesList({ [this.componentId]: this.state.customUnsavedChanges });
      }

      if (((isMetadataLoaded && !preIsMetadataLoaded) || initStateCounter !== prevInitStateCounter) && formFieldMap.size > 0) {
        // Initialize content to UI controls
        const newFormFieldMap = this.hydrateContentNew(formFieldMap, chartMetadata.chartFieldContentSet);
        this.setState({ formFieldMap: newFormFieldMap }, () => {
          if (chartMetadata.chartFieldValidationSet?.length > 0) {
            const ruleInfos = this.getValidationRules(chartMetadata.chartFieldValidationSet, this.extraValidationRules);
            this.validationManager.init(ruleInfos, newFormFieldMap);
            this.runAllRules();
          }
        });
      }
    }

    getValidationRules = (originalValidationRules: ValidationRule[], customValidationRules: ValidationRule[]): ValidationRule[] => {
      if (!originalValidationRules?.length) {
        return [];
      }
      if (!customValidationRules?.length) {
        return originalValidationRules;
      }

      return unionBy(customValidationRules, originalValidationRules, 'id');
    };

    fetchChartMetadataWithPatientContextCheck = async () => {
      let patientContext = null;
      if (patientContextService.isChartRequirePatientContext(this.props.selectedNavId)) {
        patientContext = this.props.patientContext;
      }

      await appHelper.useLoader(this.fetchChartMetadata(patientContext));
    };

    fetchChartMetadata = async (patientContext: PatientContext) => {
      if (!this.props.selectedNavId) {
        return Promise.resolve({});
      }

      return chartService
        .fetchChartMetadata(this.props.selectedNavId, patientContext)
        .then(({ data }) => {
          this.setState({ chartMetadata: { ...data }, isMetadataLoaded: true });
        })
        .catch((err) => {
          logService.log(`can not fetch and process chart metadata ${err}`);
        });
    };

    checkQuestionInScale = (formFieldId: string, questionFormFieldIds: string[]): boolean => questionFormFieldIds.some((item: string) => formFieldId === item);

    getScaleOption = (scaleQuestionId: string, chartFieldContentSet: ChartFieldContent[], score: Score): ContentItem[] =>
      chartFieldContentSet
        .filter(
          (fieldContent) =>
            fieldContent.formFieldId === scaleQuestionId && (fieldContent.dataType === FormFieldDataType.CHOICE || fieldContent.dataType === FormFieldDataType.NESTED_CHOICE)
        )
        .map((fieldContent) => ({
          id: fieldContent.id,
          name: fieldContent.label,
          dataType: fieldContent.dataType,
          label: fieldContent.label,
          value: fieldContent.value,
          displayOrder: fieldContent.order,
          validationSetIds: fieldContent.validationSetIds,
          isActive: fieldContent.active,
          selected: fieldContent.value === score?.value,
          resourceUri: fieldContent.resourceUri,
          nestedFieldId: fieldContent.nestedFieldId,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange: () => {}
        }));

    getQuestionContexts = (scaleQuestionId: string, chartFieldContentSet: ChartFieldContent[]): string[] =>
      chartFieldContentSet
        .filter((fieldContent) => fieldContent.formFieldId === scaleQuestionId && fieldContent.dataType === FormFieldDataType.SCALE_QUESTION_CONTEXT)
        .sort((left, right) => left.order - right.order)
        .map((fieldContent) => fieldContent.label);

    hydrateContentNew = (formFieldMap: Map<string, ChartMetaFormField>, chartFieldContentSet: ChartFieldContent[]) => {
      if (!chartFieldContentSet?.length) {
        return formFieldMap;
      }

      const newFieldMap = new Map(formFieldMap);
      formFieldMap.forEach((fieldItem) => {
        const newField = produce<ChartMetaFormField>(fieldItem, (field) => {
          let chartContent: ContentItem[] = [];

          switch (field.inputType) {
            case FormFieldInputType.CHECK_BOX:
            case FormFieldInputType.DROPDOWN:
            case FormFieldInputType.MULTISELECT_DROPDOWN:
            case FormFieldInputType.RADIO_CHOICE:
            case FormFieldInputType.MULTI_SELECT_RADIO: {
              // Create a default item for dropdown
              if (field.inputType === FormFieldInputType.DROPDOWN) {
                chartContent.push({
                  id: '',
                  name: '',
                  label: '',
                  value: '',
                  displayOrder: -1,
                  selected: true,
                  isActive: true,
                  onChange: this.handleEnhanceCheckBox
                });
              }
              handleForCheckboxDropdownMultiselectDropDownRadioChoiceMultiselectRadio(chartContent, field, chartFieldContentSet, this.handleEnhanceCheckBox);
              break;
            }
            case FormFieldInputType.TEXT_BOX:
            case FormFieldInputType.TEXT_AREA: {
              handleForTextBoxAndTextArea(chartContent, field, chartFieldContentSet);
              break;
            }
            case FormFieldInputType.TEXT_BLOCK: {
              handleForTextBlock(chartContent, field, chartFieldContentSet);
              break;
            }
            case FormFieldInputType.SCALE: {
              chartContent = handleForScale({
                field,
                chartFieldContentSet,
                formFieldMap,
                newFieldMap,
                getScaleOption: this.getScaleOption,
                getQuestionContexts: this.getQuestionContexts,
                checkQuestionInScale: this.checkQuestionInScale
              });
              break;
            }
            case FormFieldInputType.IMAGE: {
              handleForImage(chartContent, field, chartFieldContentSet);
              break;
            }
            default:
              break;
          }

          chartContent = orderBy(chartContent, 'displayOrder');
          field.chartContent = chartContent;
          // Search for a label tooltip related to the current chartField
          const labelTooltip = chartFieldContentSet.find(
            (fieldContent) =>
              fieldContent.dataType === FormFieldDataType.INFO && fieldContent.formFieldId === field.name && !fieldContent.expandedLabel && !fieldContent.collapsedLabel
          )?.label;

          // if one is found set the value of labelTooltip to that
          if (labelTooltip) {
            field.labelTooltip = labelTooltip;
          }
        });

        newFieldMap.set(newField.name, newField);
      });

      return newFieldMap;
    };

    addNestedTextField = (contentItem: ContentItem, formFieldMap: Map<string, ChartMetaFormField>) => {
      const nestedFieldId = chartHelper.getNestedFieldId(contentItem.value);
      if (formFieldMap.has(nestedFieldId)) {
        return;
      }
      const nestedField = chartHelper.createFormField({ name: nestedFieldId, type: FormFieldInputType.TEXT_BOX });
      nestedField.onChange = this.updateHandler;
      nestedField.onBlur = this.blurHandler;
      formFieldMap.set(nestedFieldId, nestedField);
      const newValidations: ValidationRule[] = [
        {
          message: null,
          id: `${contentItem.id}-other-validation-show`,
          formFieldId: nestedFieldId,
          targets: [],
          validationType: ValidationRuleType.CHART_CONTENT_NOT_SELECTED,
          validationActionType: ValidationActionType.HIDE,
          chartFieldSelectionValue: contentItem.value,
          parentValidationId: null
        },
        {
          message: null,
          id: `${contentItem.id}-other-validation-required-1`,
          formFieldId: nestedFieldId,
          targets: [],
          validationType: ValidationRuleType.CHART_CONTENT_SELECTED,
          validationActionType: ValidationActionType.VALIDATION,
          chartFieldSelectionValue: contentItem.value,
          parentValidationId: null
        },
        {
          message: 'Please provide input',
          id: `${contentItem.id}-other-validation-required-2`,
          formFieldId: nestedFieldId,
          targets: [],
          validationType: ValidationRuleType.EMPTY,
          validationActionType: ValidationActionType.REQUIRED,
          chartFieldSelectionValue: null,
          parentValidationId: `${contentItem.id}-other-validation-required-1`
        }
      ];
      this.validationManager.buildRuleAndFieldNode(newValidations, formFieldMap);
    };

    handleChangedField = (changedField: ChartMetaFormField) => {
      this.setState((prevState: WithChartMetadataState) => {
        const allFieldsMap = new Map(prevState.formFieldMap);
        const changedFields = [changedField];

        // Some extra changed fields
        if (changedField.fieldIdsToClearValue && changedField.value !== allFieldsMap.get(changedField.name).value) {
          const result = this.clearFieldValue(changedField.fieldIdsToClearValue, allFieldsMap);
          changedFields.push(...result);
        }

        if (changedField.onChangePreValidation) {
          const preValidationChanges = changedField.onChangePreValidation(changedField, allFieldsMap) || [];
          changedFields.push(...preValidationChanges);
        }

        // Update these changed fields to map
        changedFields.forEach((changingField) => {
          allFieldsMap.set(changingField.name, changingField);
        });

        // Validate fields and then get updated fields
        changedFields.forEach((changingField) => {
          const updatedFieldsMap: Map<string, ChartMetaFormField> = this.validationManager.validateField(changingField, allFieldsMap);
          updatedFieldsMap.forEach((updatedItem) => {
            allFieldsMap.set(updatedItem.name, updatedItem);
            clearQuestionDataIfScaleDataIsBlank(allFieldsMap, updatedItem);
          });
        });

        const changedFieldAfterValidation = allFieldsMap.get(changedField.name);
        if (changedFieldAfterValidation.onChangePostValidation) {
          changedFieldAfterValidation.onChangePostValidation(changedFieldAfterValidation, allFieldsMap);
        }

        return { formFieldMap: allFieldsMap };
      });
    };

    clearFieldValue = (fieldIds: string[], allFields: Map<string, ChartMetaFormField>) => {
      const changedFields = [];
      fieldIds.forEach((fieldId) => {
        const field = allFields.get(fieldId);
        if (!field) {
          return;
        }
        const newField = produce(field, (draft) => {
          if (draft.chartContent) {
            draft.chartContent.forEach((content) => {
              content.selected = false;
            });
          }
          draft.value = '';
        });
        changedFields.push(newField);
        allFields.set(fieldId, newField);
      });
      return changedFields;
    };

    /**
     *
     * @param field
     * @param cbIn
     */

    handleCheckBox = (field: ChartMetaFormField, cbIn: ContentItem): string | number => {
      const handleForCheckboxAndMultiselectDropDown = (draft, item: ContentItem) => {
        if (cbIn.value === item.value) {
          item.selected = !item.selected;
        }
        if (item.selected) {
          draft.value = draft.value?.concat(`|${item.value}`) || item.value;
        }
      };

      const handleForRadioChoiceAndMultiselectRadio = (draft, item: ContentItem) => {
        if (cbIn.id === item.id) {
          item.selected = true;
          draft.value = item.value;
        } else {
          item.selected = false;
        }
      };

      const newField = produce(field, (draft) => {
        draft.value = '';
        draft.chartContent.forEach((item: ContentItem) => {
          switch (draft.inputType) {
            case FormFieldInputType.CHECK_BOX:
            case FormFieldInputType.MULTISELECT_DROPDOWN:
              handleForCheckboxAndMultiselectDropDown(draft, item);
              break;
            case FormFieldInputType.RADIO_CHOICE:
            case FormFieldInputType.MULTI_SELECT_RADIO:
              handleForRadioChoiceAndMultiselectRadio(draft, item);
              break;
            default:
              break;
          }
        });
      });

      this.handleChangedField(newField);
      this.setState({ isDirty: true });
      return newField.value;
    };

    handleEnhanceCheckBox = (
      field: ChartMetaFormField,
      cbIn: ContentItem,
      scaleOptions: { id: string; formFieldId: string; option: ContentItem },
      isNotSaveChildValueInScore: boolean | undefined
    ) => {
      const totalCheckBoxValue = this.handleCheckBox(field, cbIn);
      if (scaleOptions?.id) {
        const { formFieldMap } = this.state;
        this.updateScale(
          formFieldMap.get(scaleOptions.id),
          undefined,
          {
            option: scaleOptions.option,
            question: formFieldMap.get(scaleOptions.formFieldId),
            additionalScore: totalCheckBoxValue
          },
          isNotSaveChildValueInScore
        );
      }
    };

    resetSubsetOfFormFields = (fields: ChartMetaFormField[]) => {
      fields.forEach((field) => {
        const newField = produce(this.state.formFieldMap.get(field.name), (draft) => {
          draft.value = '';
          const selectedItem = draft.chartContent.find((item) => item.selected);
          if (selectedItem) selectedItem.selected = false;
        });
        this.handleChangedField(newField);
      });
    };

    initState = (data: Map<string, ChartMetaFormField>, initStateCallback?: Function, extraValidationRules?: ValidationRule[]) => {
      if (data) {
        data.forEach((field) => {
          field.onChange = this.updateHandler;
          field.onBlur = this.blurHandler;
          if (field.inputType === FormFieldInputType.SCALE) {
            field.onChange = this.updateScale;
          }
        });

        this.extraValidationRules = this.extraValidationRules.length ? this.extraValidationRules : extraValidationRules || [];

        this.setState(
          (prevState) => ({
            ...prevState,
            formFieldMap: data,
            formSubmittedCount: 0,
            isDirty: false,
            initStateCounter: prevState.initStateCounter + 1
          }),
          () => initStateCallback && initStateCallback()
        );
      }
    };

    updateFieldValue = (field: ChartMetaFormField, value: unknown): void => {
      const { isMetadataLoaded } = this.state;
      if (isMetadataLoaded) {
        const customHTMLEvent = {
          target: {
            value
          }
        };

        this.updateHandler(field, customHTMLEvent);
      }
    };

    updateHandler = (field: ChartMetaFormField, event) => {
      const { locale } = this.props;

      const newField = produce(field, (draft) => {
        // if the form field is a date type, event will actually be a date object
        if (draft.inputType === FormFieldInputType.DATE) {
          draft.value = dateTimeHelper.formatDate({
            date: event,
            defaultIfEmpty: event,
            locale
          });
        } else {
          const { value } = event.target;
          draft.value = value;

          if (draft.inputType === FormFieldInputType.DROPDOWN) {
            // select the content
            const mapContent = draft.chartContent.map((content) => ({ ...content, selected: content.value === value }));
            draft.chartContent = mapContent;
          }
        }
      });

      this.handleChangedField(newField);
      this.setState({ isDirty: true });
    };

    updateScale = (
      scaleField: ChartMetaFormField,
      event: React.SyntheticEvent<HTMLInputElement>,
      { option, question, additionalScore }: { option: ContentItem; question: ChartMetaFormField; additionalScore: string | number },
      isNotSaveChildValueInScore?: boolean | undefined
    ) => {
      const currentQuestionField = getCurrentQuestionFieldForUpdateScale(question, option, additionalScore, isNotSaveChildValueInScore);

      const newField = produce(scaleField, (draft) => {
        let newTotalScore = '';
        draft.chartContent.forEach((item) => {
          if (item.dataType === FormFieldDataType.SCALE_QUESTION) {
            const isCurrentQuestion = item.formFieldId === question.name;
            const questionField = isCurrentQuestion ? currentQuestionField : this.state.formFieldMap.get(item.formFieldId);
            newTotalScore = this.calculateTotalScoreScale(questionField, newTotalScore);
          }
        });
        if (!isUnscoredQuestion(question)) {
          const scaleRollUpWrapper = draft.chartContent.find((item) => item.formFieldId === chartHelper.getScaleRollUpWrapperId(draft.name));
          scaleRollUpWrapper.score = { label: newTotalScore, value: newTotalScore };
        }
        draft.value = newTotalScore;
      });

      this.handleChangedField(currentQuestionField);
      this.handleChangedField(newField);
      this.setState({ isDirty: true });
    };

    calculateTotalScoreScale = (questionField: ChartMetaFormField, newTotalScore: string): string => {
      const questionFieldValue = questionField.extraData.score?.value;
      // regex checks for positive and negative integers
      const matchQuestionScore = questionFieldValue?.match(/-?\d+/g);
      const matchTotalScore = newTotalScore.match(/-?\d+/g);
      const tempTotalScore = (Number(matchQuestionScore?.[0] || '0') + Number(matchTotalScore?.[0] || '0')).toString();
      return tempTotalScore + (newTotalScore?.replace(matchTotalScore?.[0], '') || '')?.trim() + (questionFieldValue?.replace(matchQuestionScore?.[0], '') || '')?.trim();
    };

    updateFormSubmitted = () => {
      this.setState((prevState) => ({ formSubmittedCount: prevState.formSubmittedCount + 1 }));
    };

    resetFormSubmitted = () => {
      this.setState(() => ({ formSubmittedCount: 0 }));
    };

    setFormFieldLabel = (formFieldId: string, label: string) => {
      this.setState(
        produce((state: WithChartMetadataState) => {
          state.formFieldMap.get(formFieldId).label = label;
        })
      );
    };

    blurHandler = (field: ChartMetaFormField) => {
      let updatedFields = [];
      if (field.onGroupChange && field.errors.length === 0) {
        updatedFields = [...field.onGroupChange(field)];
        if (!updatedFields.find((item) => item.name === field.name)) {
          updatedFields.push(field);
        }
        updatedFields.forEach((item) => {
          this.handleChangedField(item);
        });
      }
    };

    runAllRules = () => {
      this.setState((prevState: WithChartMetadataState) => {
        const newFieldMap = this.validationManager.runAllRules(prevState.formFieldMap);
        return { ...prevState, formFieldMap: newFieldMap };
      });
    };

    getContentMap = (): Map<string, string> => {
      const dataMap: Map<string, string> = new Map<string, string>();
      this.state.chartMetadata.chartFieldContentSet.forEach((content: ChartFieldContent) => dataMap.set(content.value, content.label));
      return dataMap;
    };

    hasChangesInTargetFields = (fields: ChartMetaFormField[]): boolean => {
      // Map the values of "formFieldMap" into array, check if there is any element exist in "fields" and have "value" different from "defaultValue"
      return Array.from(this.state.formFieldMap.values()).some((formField) => fields.includes(formField) && formField.value !== formField.defaultValue);
    };

    hasUnsavedChanges = (): boolean => {
      const hasChanged = Array.from(this.state.formFieldMap.values()).some((formField) => formField.value !== formField.defaultValue);

      const hasUnsavedChanges = hasChanged && this.state.isDirty;

      // check if the user entered a value
      return this.state.isChartSidebar ? hasUnsavedChanges && this.state.isChartSidebarOpen : hasUnsavedChanges;
    };

    setDirty = (isDirty: boolean) => this.setState({ isDirty });

    setCustomUnsavedChanges = (customUnsavedChanges: Function) => this.setState({ customUnsavedChanges });

    handleCloseAttempt = (handleClose: Function) => {
      const { modalService } = this.props;
      if (!this.hasUnsavedChanges()) {
        handleClose();
      } else {
        modalService.openModal({
          modalId: this.warningUnsavedCommentModalId,
          content: (
            <ConfirmationModal
              header="Before you Leave..."
              message="The slide out menu contains unsaved changes. Do you wish to close the menu?"
              okButtonText="Leave this menu"
              cancelButtonText="Stay in the menu"
              onOkClick={() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                handleClose();
              }}
              onCancelClick={() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
              }}
            />
          )
        });
      }
    };

    sidebarProps = (): SideBarCommonProps => {
      return {
        setIsChartSidebarOpen: (isChartSidebarOpen: boolean) => {
          if (!this.state.isChartSidebar) this.setState({ isChartSidebar: true });
          this.setState({ isChartSidebarOpen });
        },
        handleCloseAttempt: this.handleCloseAttempt
      };
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          sidebarProps={this.sidebarProps}
          setCustomUnsavedChanges={this.setCustomUnsavedChanges}
          fetchChartMetadata={this.fetchChartMetadata}
          updateFormSubmitted={this.updateFormSubmitted}
          resetFormSubmitted={this.resetFormSubmitted}
          resetSubsetOfFormFields={this.resetSubsetOfFormFields}
          hasUnsavedChanges={this.hasUnsavedChanges()}
          hasChangesInTargetFields={this.hasChangesInTargetFields}
          initState={this.initState}
          getContentMap={this.getContentMap}
          setDirty={this.setDirty}
          setFormFieldLabel={this.setFormFieldLabel}
          addNestedTextField={this.addNestedTextField}
          updateFieldValue={this.updateFieldValue}
        />
      );
    }
  }
  const mapStateToProps = (state) => ({
    locale: appSelectors.getLocale(state)
  });

  return ELSWithModalService(connect(mapStateToProps)(WithChartMetadata));
};

export default withChartMetadata;

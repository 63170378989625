import cx from 'classnames';
import { keys } from 'lodash';
import { Component, Fragment } from 'react';
import { PhaseType } from 'models/enum';
import { CaseStudy } from 'models/ui';
import { Box } from 'components/common';
import getOr from 'lodash/fp/getOr';
import sortBy from 'lodash/fp/sortBy';
import SectionOverview from './SectionOverview';
import SectionPhase from './SectionPhase';
import SectionTypeFilter from './SectionTypeFilter';
import './case.study.preview.content.scss';
import { ELSQuestionWrapper } from '../case-study-quiz/ELSQuestionWrapper';

interface CaseStudyPreviewProp {
  className?: string;
  caseStudy: CaseStudy;
}

interface CaseStudyPreviewState {
  typeFilterValues: { [x: string]: boolean };
}

export class CaseStudyPreviewContent extends Component<CaseStudyPreviewProp, CaseStudyPreviewState> {
  constructor(props) {
    super(props);
    this.state = {
      typeFilterValues: {
        overview: true,
        phases: true,
        questions: true
      }
    };
  }

  handleTypeFilterChange = (key: string, value: boolean) => {
    this.setState((prevState) => ({
      typeFilterValues: {
        ...prevState.typeFilterValues,
        [key]: value
      }
    }));
  };

  render() {
    const { className, caseStudy } = this.props;
    if (!caseStudy) return null;

    const { typeFilterValues } = this.state;
    const correctResponse = 'qtiData.correctResponse';
    const phases = sortBy(['displayOrder'], caseStudy.phases);
    const totalSections = phases.length - 1; // overview should not count so -1
    let totalQuestions = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    return (
      <div className={cx('case-study-preview-content', className)}>
        <h2 className="case-study-name">{caseStudy.title}</h2>

        <SectionTypeFilter values={typeFilterValues} onChange={this.handleTypeFilterChange} />
        {phases.map((phase, sectionPhaseIndex) => {
          questionIndex = 0;
          if (phase.type === PhaseType.OVERVIEW && typeFilterValues.overview) {
            return <SectionOverview key={phase.id} title={phase.title} content={phase.overview} />;
          }
          if (phase.type === PhaseType.PHASE) {
            sectionIndex += 1;
            totalQuestions = 0;
            (phase.steps || []).forEach(() => {
              if (typeFilterValues.questions) {
                totalQuestions += 1;
              }
            });
            return (
              <Fragment key={phase.id}>
                {typeFilterValues.phases && (
                  <SectionPhase
                    sectionIndex={sectionIndex}
                    sectionLength={totalSections}
                    sectionPhaseIndex={sectionPhaseIndex}
                    title={phase.title}
                    instructions={phase.instruction}
                    chartsInvolved={phase.chartsInvolved}
                  />
                )}
                {typeFilterValues.questions &&
                  phase.steps?.map((step) => {
                    questionIndex += 1;

                    return (
                      <div key={step.id} className="section-quiz-question section-box">
                        <Box mb>{`${questionIndex} of ${totalQuestions} — Quiz Question`}</Box>
                        <ELSQuestionWrapper
                          answerCorrectnessText={undefined}
                          questionVtwId={step.vtwId}
                          questionType={step.qtiData.questionType}
                          questionPrompt={step.qtiData.prompt}
                          questionChoices={step.qtiData.responseChoices}
                          questionFeedbacks={getOr({}, 'qtiData.feedback')(step)}
                          correctAnswers={getOr([], correctResponse)(step)}
                          selectedAnswers={keys(step.qtiData.responseChoices)}
                          correctAnswer={getOr('', correctResponse)(step)}
                          selectedAnswer={getOr('', correctResponse)(step)}
                        />
                      </div>
                    );
                  })}
              </Fragment>
            );
          }
          return null;
        })}
      </div>
    );
  }
}

export default CaseStudyPreviewContent;

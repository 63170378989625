import cx from 'classnames';
import { Component, Fragment } from 'react';
import { ELSFlexItem } from '@els/els-ui-common-react';
import { FormFieldInputType, ValidationActionType } from 'models/enum';
import { ErrorField } from 'models/ui';
import { dumpInputProps } from './constants';
import ErrorFormFieldLayout from './ErrorFormFieldLayout';
import Input from './Input';
import './label.scss';
import { ErrorFormFieldProps, InputProps } from './types';
import { getProps, isHiddenRadioGroup } from './utils';
import '../../error-form-field/error.form.field.scss';

class ErrorFormField extends Component<ErrorFormFieldProps> {
  static displayName = 'ErrorFormFieldV2';
  static defaultProps: Partial<ErrorFormFieldProps> = {
    useDefaultMargin: true,
    enableTooltip: true
  };

  getErrors = (): ErrorField[] => {
    const { formField, formSubmittedCount } = this.props;
    const { errors } = formField;

    if (formSubmittedCount === 0) {
      return errors.filter((error) => error?.type !== ValidationActionType.REQUIRED);
    }

    return errors;
  };

  renderNestedField = (nestedFieldId: string, onChange?: InputProps['onChange']) => {
    const { formFieldMap, formSubmittedCount, displayHorizontal } = this.props;

    if (nestedFieldId) {
      const fieldIds = nestedFieldId.split('|');
      return fieldIds.map((id) => (
        <Fragment key={id}>
          <ELSFlexItem>
            <ErrorFormField
              useDefaultMargin={false}
              className={cx('u-els-margin-bottom-1x', {
                'u-els-margin-left-2x u-els-margin-top-1o2': !displayHorizontal
              })}
              hideAsterisk
              formSubmittedCount={formSubmittedCount}
              formField={formFieldMap.get(id)}
              onChange={onChange}
            />
          </ELSFlexItem>
        </Fragment>
      ));
    }
    return null;
  };

  render() {
    const { formField, formSubmittedCount, formFieldMap, enableTooltip, className, useDefaultMargin, inputProps, labelProps, badgeProps } = this.props;

    if (!formField) {
      return null;
    }

    const { name, hide, inputType, isRequired } = formField;
    const fieldId = `chart-field-${name}`;

    if (hide) {
      return <div id={fieldId} />;
    }

    const isEnabledTooltip = !!(enableTooltip && (formField.labelTooltip?.length > 0 || formField.labelMultilineTooltip));
    const tooltipLabel = formField.labelTooltip || formField.labelMultilineTooltip;
    const tooltipProps = {
      isEnabledTooltip,
      tooltipLabel
    };
    const errors = this.getErrors();
    const customInputProps: InputProps = {
      ...getProps<InputProps>(Object.keys(dumpInputProps), dumpInputProps),
      ...inputProps,
      fieldId,
      formField,
      renderNestedField: this.renderNestedField
    };

    if (inputType === FormFieldInputType.CONTAINER) {
      return <Input {...customInputProps} />;
    }

    if (inputType === FormFieldInputType.RADIO_CHOICE) {
      const isHidden = isHiddenRadioGroup({ formField, hideRadioContent: inputProps?.hideRadioContent });

      return (
        !isHidden && (
          <fieldset aria-required={isRequired || null}>
            <ErrorFormFieldLayout
              fieldId={fieldId}
              formField={formField}
              formFieldMap={formFieldMap}
              badgeProps={badgeProps}
              className={className}
              errors={errors}
              formSubmittedCount={formSubmittedCount}
              inputProps={customInputProps}
              labelProps={labelProps}
              tooltipProps={tooltipProps}
            />
          </fieldset>
        )
      );
    }

    return (
      <ErrorFormFieldLayout
        fieldId={fieldId}
        formField={formField}
        formFieldMap={formFieldMap}
        badgeProps={badgeProps}
        className={className}
        useDefaultMargin={useDefaultMargin}
        errors={errors}
        formSubmittedCount={formSubmittedCount}
        inputProps={customInputProps}
        labelProps={labelProps}
        tooltipProps={tooltipProps}
      />
    );
  }
}

export default ErrorFormField;

import { ChartFragmentRS, LaborProgressRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, LaborGraphItem } from 'models/ui';
import { appHelper, cssHelper } from 'helpers';
import { Box, ChartActions, ErrorCard, ErrorFormField, GraphContainer, HistoryLineChart } from 'components/common';
import { ELSDropDown, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, GRAPH_TYPES } from './constants';
import LaborProgressHistory from './LaborProgressHistory';
import LaborProgressOnsetSidebar, { LaborProgressOnsetSidebarProps } from './LaborProgressOnsetSidebar';
import './labor.progress.history.scss';

export interface LaborProgressViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  chartHistory: LaborProgressRecord[];
  deleteHistory: Function;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  graphData: LaborGraphItem[];
  dataKeys: string[];
  rightDataKeys: string[];
  leftYGraphLabel: string;
  rightYGraphLabel: string;
  onSelectGraph: Function;
  graphChartType: string;
  xAxisDataKey: string;
  onsetDateTime: string;
  isOnsetSidebarOpen: boolean;
  yAxisName?: object;
  legendType: string;
  strokeWidth: number;
  dotFormat: {
    stroke: string;
    strokeWidth: number;
  };
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  onSavedData: Function;
  isAuthor: boolean;
}

const LaborProgressView = (props: LaborProgressViewProps) => {
  const { isOnsetSidebarOpen, onsetDateTime, saveChartData, onSavedData, isAuthor } = props;
  const laborProgressOnsetSidebarProps: Partial<LaborProgressOnsetSidebarProps> = {
    isOnsetSidebarOpen,
    saveChartData,
    onSavedData,
    isAuthor,
    ...appHelper.getChartSharedProps(props)
  };
  const renderGraphTypeSelect = () => (
    <Box mt className="chart-input__dropdown">
      <ELSDropDown inline options={GRAPH_TYPES} value={props.graphChartType} changeHandler={props.onSelectGraph} />
    </Box>
  );
  return (
    <div className="labor-progress-common chart-input">
      <h1 className="chart-input__header">Labor Progress</h1>
      <ELSFlex right>{`Active Labor Onset: ${onsetDateTime}`}</ELSFlex>
      <ELSFlex left className="o-els-flex-layout--wrap@mobile o-els-flex-layout--wrap@tablet">
        <ELSFlexItem>
          <div
            className={cssHelper.mapElsCss({
              els: 'padding-right-3x',
              md: 'padding-right-1x1o2',
              lg: 'padding-right-2x'
            })}
          >
            <h2 className="u-els-margin-top">Chart Inputs</h2>
            <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
            <div className="chart-input__section-title chart-input__section-title--first u-els-margin-bottom">
              <h4>Fetal Assessment</h4>
            </div>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={props.formFieldMap.get(FormField.BASELINE_BPM)} formSubmittedCount={props.formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={props.formFieldMap.get(FormField.MONITOR_MODE)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={props.formFieldMap.get(FormField.VARIABILITY)} formSubmittedCount={props.formSubmittedCount} />
            <ErrorFormField formField={props.formFieldMap.get(FormField.ACCELERATIONS)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={props.formFieldMap.get(FormField.DECELERATIONS)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
            <div className="chart-input__section-title u-els-margin-bottom">
              <h4>Uterine Activity</h4>
            </div>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={props.formFieldMap.get(FormField.FREQUENCY)} formSubmittedCount={props.formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={props.formFieldMap.get(FormField.MONITOR_MODE_DROPDOWN)} formSubmittedCount={props.formSubmittedCount} />
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={props.formFieldMap.get(FormField.DURATION)} formSubmittedCount={props.formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={props.formFieldMap.get(FormField.INTENSITY)} formSubmittedCount={props.formSubmittedCount} />
            <div className="chart-input__section-title u-els-margin-bottom">
              <h4>Vaginal Assessment</h4>
            </div>
            <ErrorFormField formField={props.formFieldMap.get(FormField.CERVICAL_DILATION)} formSubmittedCount={props.formSubmittedCount} />
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={props.formFieldMap.get(FormField.EFFACEMENT)} formSubmittedCount={props.formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={props.formFieldMap.get(FormField.STATION)} formSubmittedCount={props.formSubmittedCount} />
            <ErrorFormField formField={props.formFieldMap.get(FormField.POSITION)} formSubmittedCount={props.formSubmittedCount} />
            <ErrorFormField
              formField={props.formFieldMap.get(FormField.NOTES)}
              formSubmittedCount={props.formSubmittedCount}
              options={{
                labelCss: 'chart-input__section-title'
              }}
            />
            <ChartActions {...props.chartActionsProps} />
          </div>
        </ELSFlexItem>
        <ELSFlexItem className="o-els-flex-layout__item--grow chart-input__scroll-table-container">
          <div className="chart-input__table-graph-container">
            <h2 className="u-els-margin-bottom u-els-margin-top">Chart History</h2>
            <LaborProgressHistory chartHistory={props.chartHistory} deleteHistory={props.deleteHistory} chartMetaFormFields={props.formFieldMap} />
            <Box mt2>
              <GraphContainer dataSize={props.graphData.length} renderGraphTypeSelect={renderGraphTypeSelect}>
                <HistoryLineChart
                  xAxisDataKey={props.xAxisDataKey}
                  width={600}
                  yLabel={props.leftYGraphLabel}
                  rightYLabel={props.rightYGraphLabel}
                  data={props.graphData}
                  dataKeys={props.dataKeys}
                  rightDataKeys={props.rightDataKeys}
                  yAxisName={props.yAxisName}
                  legendType={props.legendType}
                  strokeWidth={props.strokeWidth}
                  dotFormat={props.dotFormat}
                />
              </GraphContainer>
            </Box>
          </div>
        </ELSFlexItem>
      </ELSFlex>
      <LaborProgressOnsetSidebar {...laborProgressOnsetSidebarProps} />
    </div>
  );
};

LaborProgressView.displayName = 'LaborProgressView';
export default LaborProgressView;

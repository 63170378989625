import { ChartActionsComponentProps, ChartMetaFormField, ErrorGroup, GroupScaleQuestion } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { FormField } from './constants';

export interface MoodDisorderQuestionnaireViewProps {
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  groupScaleQuestion: GroupScaleQuestion[];
  errorGroups: ErrorGroup[];
  formFieldMap: Map<string, ChartMetaFormField>;
}

const MoodDisorderQuestionnaireView = (props: MoodDisorderQuestionnaireViewProps) => {
  const { formFieldMap, formSubmittedCount, groupScaleQuestion, errorGroups, chartActionsProps } = props;
  return (
    <div className="mood-disorder-questionnaire">
      <h1>Mood Disorder Questionnaire</h1>
      <h3 className="u-els-margin-top">Mood Disorder Questionnaire</h3>
      <Box p mt2 mb className="c-els-card c-els-card--border-primary">
        <ELSFlex>
          <ELSFlexItem className="u-els-margin-right">
            <ELSIcon prefix="gizmo" name="information" />
          </ELSFlexItem>
          <ELSFlexItem grow>
            <p>
              <strong>Criteria for results: </strong>
              Answering Yes to seven or more of the events in question 1, answering Yes to question 2, and answering Moderate problem or Serious problem to question 3 is considered
              a positive screen result for bipolar disorder.
            </p>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} sections={errorGroups} />
      <ErrorFormField
        formField={formFieldMap.get(FormField.MOOD_DISORDER_QUESTIONNAIRE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ groupScaleQuestion, isHiddenScore: true }}
      />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      <p className="u-els-margin-bottom u-els-margin-top">
        Modified from: Hirschfeld, R. et al. [2000]. Development and validation of a screening instrument for bipolar spectrum disorder. The Mood Disorder Questionnaire, Am J
        Psychiatry, 157[11], 1873-1875. ©2004 Eli Lilly and Company.
      </p>
    </div>
  );
};

export default MoodDisorderQuestionnaireView;

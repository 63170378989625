import { Component, ReactNode } from 'react';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import SkinAssessmentView, { SkinAssessmentViewProps } from './SkinAssessmentView';
import { buildDefaultSkinAssessmentFormFieldItems, buildSkinAssessmentFragmentRecords } from './helpers';

export type SkinAssessmentProps = ChartComponentProps;

class SkinAssessment extends Component<SkinAssessmentProps> {
  componentDidMount(): void {
    this.initialize();
  }

  initialize = (): void => {
    const { initState } = this.props;
    initState(buildDefaultSkinAssessmentFormFieldItems());
  };

  handleAfterSaved = (): void => {
    const { backToSourceLocation, navigateToSavedPatientCharting } = this.props;
    if (backToSourceLocation) backToSourceLocation();
    else navigateToSavedPatientCharting();
  };

  handleSaveClick = (): void => {
    const { saveChart, chartingTime, formFieldMap } = this.props;
    saveChart([buildSkinAssessmentFragmentRecords({ chartingTime, formFieldMap })], {
      defaultFormFields: buildDefaultSkinAssessmentFormFieldItems(),
      afterSave: this.handleAfterSaved
    });
  };

  render(): ReactNode {
    const { formSubmittedCount, formFieldMap, enableDisplayRecordsButton, hasUnsavedChanges, handleDiscardClick, displayAuthoringData } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: () => handleDiscardClick(this.initialize),
      onSaveClick: this.handleSaveClick
    };

    const viewProps: SkinAssessmentViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount
    };

    return <SkinAssessmentView {...viewProps} />;
  }
}

export default SkinAssessment;

import { LanguageKeys } from 'lang';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { ChartActionsComponentProps, ChartMetaFormField, GroupScaleQuestion } from 'models/ui';
import { Locales, NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import './delivery.room.summary.scss';

export interface DeliveryRoomSummaryViewProps {
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  groupScaleQuestion: GroupScaleQuestion[];
  formFieldMap: Map<string, ChartMetaFormField>;
  locale: Locales;
  intl: IntlShape;
}

const DeliveryRoomSummaryView = (props: DeliveryRoomSummaryViewProps) => {
  const { formFieldMap, formSubmittedCount, groupScaleQuestion, locale, intl } = props;
  return (
    <div className="delivery-room-summary">
      <h1>{LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY_ROOM_SUMMARY}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <Box mt2>
        <h2>{LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY}</h2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.DAY)}
              formSubmittedCount={formSubmittedCount}
              options={{ disableBefore: moment().toDate() }}
            />
            {locale !== Locales.EN_AU && (
              <ErrorFormField
                className="u-els-margin-top"
                formField={formFieldMap.get(FormField.SEX)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            )}
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DELIVERY_PLACENTA)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE_DELIVERY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE_DELIVERY_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TIME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PROVIDER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CORD)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <Box>
          <h2>{LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR}</h2>
          <div className="stage">
            <Box mt>
              <h4>{intl.formatMessage({ id: LanguageKeys.DELIVERY_ROOM_SUMMARY.ORDER_OF_STAGES }, { order: 'First' })}</h4>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FIRST_STAGE_HOURS)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt>
              <h4>{intl.formatMessage({ id: LanguageKeys.DELIVERY_ROOM_SUMMARY.ORDER_OF_STAGES }, { order: 'Second' })}</h4>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SECOND_STAGE_HOURS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SECOND_STAGE_MINUTES)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box mt>
              <h4>{intl.formatMessage({ id: LanguageKeys.DELIVERY_ROOM_SUMMARY.ORDER_OF_STAGES }, { order: 'Third' })}</h4>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.THIRD_STAGE_MINUTES)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </div>
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.COMPLICATIONS_DURING_DELIVERY)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.COMPLICATIONS_DURING_DELIVERY_TEXT)} formSubmittedCount={formSubmittedCount} />
        </Box>
      </Box>
      <SectionDivider />
      <h2>{intl.formatMessage({ id: LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN })}</h2>
      <h3>{intl.formatMessage({ id: LanguageKeys.DELIVERY_ROOM_SUMMARY.APGAR_SCALE })}</h3>
      <ErrorFormField formField={formFieldMap.get(FormField.APGAR_SCORE)} formFieldMap={formFieldMap} formSubmittedCount={formSubmittedCount} options={{ groupScaleQuestion }} />
      <h3>{LanguageKeys.DELIVERY_ROOM_SUMMARY.RESUSCITATION}</h3>
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RESUSCITATION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.COMMENTS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <h3>{LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS}</h3>
      <Box mt>
        <h4>{LanguageKeys.DELIVERY_ROOM_SUMMARY.EYE_PROPHYLACTIC}</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EYE_PROPHYLAXIS_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EYE_PROPHYLAXIS_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EYE_PROPHYLAXIS_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>{LanguageKeys.DELIVERY_ROOM_SUMMARY.VITAMIN_K}</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VITAMIN_K_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VITAMIN_K_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VITAMIN_K_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>{LanguageKeys.DELIVERY_ROOM_SUMMARY.NALOXONE}</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NALOXONE_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NALOXONE_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NALOXONE_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>{LanguageKeys.DELIVERY_ROOM_SUMMARY.HEPATITIS_B_VACCINE}</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_VACCINE_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_VACCINE_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_VACCINE_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>{LanguageKeys.DELIVERY_ROOM_SUMMARY.HEPATITIS_B_IMMUNE_GLOBULIN}</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>{LanguageKeys.OTHER}</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OTHER_MEDICATIONS_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OTHER_MEDICATIONS_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OTHER_MEDICATIONS_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <SectionDivider />
      <h2>{LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT}</h2>
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <h3>{LanguageKeys.DELIVERY_ROOM_SUMMARY.NUTRITION}</h3>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NUTRITION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BREAST_MINUTES)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FORMULA_AMOUNT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FORMULA_TYPE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.GLUCOSE_AMOUNT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <h3>{LanguageKeys.DELIVERY_ROOM_SUMMARY.ELIMINATION}</h3>
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.FIRST_WEEK)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.FIRST_STOOL)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DESCRIBE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2>{LanguageKeys.DELIVERY_ROOM_SUMMARY.GENERAL_NOTES}</h2>
      <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.GENERAL_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
      <RelatedCharts chartIds={[NAV_ID.NEWBORN_ASSESSMENT]} />
    </div>
  );
};

DeliveryRoomSummaryView.displayName = 'DeliveryRoomSummaryView';
export default DeliveryRoomSummaryView;

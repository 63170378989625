import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { FormField as CommonFormField, Title } from '../shared/constants';

export enum FormField {
  CATEGORY = 'category',
  ORDER_DESCRIPTION = 'diagnosticTest',
  ORDER_DESCRIPTION_DETAIL = 'diagnosticTestDetail',
  ORDER_PRIORITY = 'orderPriority',
  TRANSPORTATION = 'transportation',
  IV_REQUIRED = 'ivRequired',
  OXYGEN_REQUIRED = 'oxygenRequired',
  ORDER_START_DATE = 'orderStartDate',
  ORDER_START_TIME = 'orderStartTime',
  SPECIAL_INSTRUCTIONS = 'specialInstructions',
  STATUS = 'status',
  EXAM_DATE = 'exam_date',
  REPORT = 'report',
  IMPRESSION = 'impression',
  RECOMMENDATION = 'recommendation',
  NAME = 'name',
  SIGNATURE = 'signature',
  RESULTS_RECEIVED_DATE = 'resultsReceivedDate',
  RESULTS_RECEIVED_TIME = 'resultsReceivedTime'
}

export enum Status {
  PENDING = 'Pending',
  COMPLETED = 'Completed'
}

export const getDiagnosticTestsFormFieldBuilderItems = (isAuthor: boolean): ChartMetaFormFieldParams[] => [
  {
    name: FormField.CATEGORY,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.CATEGORY
  },
  {
    name: FormField.ORDER_DESCRIPTION,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.ORDER_DESCRIPTION
  },
  {
    name: FormField.ORDER_DESCRIPTION_DETAIL,
    type: FormFieldInputType.TEXT_BOX
  },
  {
    name: FormField.ORDER_PRIORITY,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.ORDER_PRIORITY
  },
  {
    name: FormField.TRANSPORTATION,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.TRANSPORTATION
  },
  {
    name: FormField.IV_REQUIRED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.IV_REQUIRED,
    errorLabel: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.IV_REQUIRED
  },
  {
    name: FormField.OXYGEN_REQUIRED,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.OXYGEN_REQUIRED
  },
  {
    name: FormField.ORDER_START_DATE,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.DATE_LABEL,
    isHidden: isAuthor
  },
  {
    name: FormField.ORDER_START_TIME,
    type: FormFieldInputType.TIME,
    label: LanguageKeys.TIME_LABEL,
    defaultValue: '00:00',
    isHidden: isAuthor
  },
  {
    name: CommonFormField.ORDER_START_TIME_OFFSET,
    type: FormFieldInputType.TEXT_BOX,
    label: Title.START_TIME_OFFSET,
    value: '00:00',
    isHidden: !isAuthor
  },
  {
    name: FormField.SPECIAL_INSTRUCTIONS,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.SPECIAL_INSTRUCTIONS
  }
];

export const getDiagnosticTestsSidebarFormFieldBuilderItems = (isAuthor: boolean, chartData): ChartMetaFormFieldParams[] => [
  {
    name: FormField.CATEGORY,
    type: FormFieldInputType.TEXT_BOX,
    value: chartData[FormField.CATEGORY].content,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.CATEGORY
  },
  {
    name: FormField.ORDER_DESCRIPTION,
    type: FormFieldInputType.TEXT_BOX,
    value: chartData[FormField.ORDER_DESCRIPTION].content,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.ORDER_DESCRIPTION
  },
  {
    name: FormField.EXAM_DATE,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.EXAM_DATE
  },
  {
    name: FormField.REPORT,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.REPORT
  },
  {
    name: FormField.IMPRESSION,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.IMPRESSION
  },
  {
    name: FormField.RECOMMENDATION,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.RECOMMENDATION
  },
  {
    name: FormField.NAME,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.NAME
  },
  {
    name: FormField.SIGNATURE,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.SIGNATURE
  },
  {
    name: FormField.RESULTS_RECEIVED_DATE,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.DATE_LABEL,
    errorLabel: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.RESULTS_RECEIVED_DATE,
    isHidden: isAuthor
  },
  {
    name: FormField.RESULTS_RECEIVED_TIME,
    type: FormFieldInputType.TIME,
    label: LanguageKeys.TIME_LABEL,
    errorLabel: LanguageKeys.ORDER_DIAGNOSTIC_TESTS.RESULTS_RECEIVED_TIME,
    value: '00:00',
    isHidden: isAuthor
  },
  {
    name: CommonFormField.RESULTS_RECEIVED_TIME_OFFSET,
    type: FormFieldInputType.TEXT_BOX,
    label: Title.RESULTS_RECEIVED_TIME_OFFSET,
    value: '00:00',
    isHidden: !isAuthor
  }
];

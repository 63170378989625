export enum FormField {
  EDINBURGH_POSTNATAL_DEPRESSION_SCALE = 'ratingScale',
  FIRST_QUESTION = '1IHaveBeenAbleToLaughAndSeeTheFunnySideOfThings',
  SECOND_QUESTION = '2IHaveLookedForwardWithEnjoymentToThings',
  THIRD_QUESTION = '3IHaveBlamedMyselfUnnecessarilyWhenThingsWentWrong',
  FOURTH_QUESTION = '4IHaveBeenAnxiousAorWorriedForNoGoodReason',
  FIFTH_QUESTION = '5IHaveFeltScaredOrPanickyForNoVeryGoodReason',
  SIXTH_QUESTION = '6ThingsHaveBeenGettingOnTopOfMe',
  SEVENTH_QUESTION = '7IHaveBeenSoUnhappyThatIHaveHadDifficultySleeping',
  EIGHTH_QUESTION = '8IHaveFeltSadOrMiserable',
  NINTH_QUESTION = '9IHaveBeenSoUnhappyThatIHaveBeenCrying',
  TENTH_QUESTION = '10TheThoughtOfHarmingMyselfHasOccurredToMe'
}
export enum SectionTitle {
  EDINBURGH_POSTNATAL_DEPRESSION_SCALE_SECTION = 'Edinburgh Postnatal Depression Scale',
  IN_THE_PAST_7_DAYS = 'In the past 7 days',
  SCORING = 'Scoring'
}

export enum Label {
  INSTRUCTIONS_FOR_USING = 'Instructions for using the Edinburgh Postnatal Depression Scale:',
  MOTHER_FEELING_7_DAYS = 'The mother is asked which response comes closest to how she has been feeling over the previous 7 days.',
  ALL_ITEMS_MUST_BE_COMPLETED = 'All items must be completed.',
  AVOID_DISCUSSING_ANSWERS = 'Care should be taken to avoid the possibility of the mother discussing her answers with others.',
  MOTHER_ANSWER_IN_FIRST_PERSON = 'Ask questions so the mother can answer in the first person',
  MODIFIED_FROM = 'Modified from Cox JL, Holden JM, and Sagovsky R: Detection of postnatal depression: development of the 10-item Edinburgh Postnatal Depression Scale, Br J Psychiatr\n' +
    '          150:782-786, 1987.',
  COPYRIGHT = '© 1987 The Royal College of Psychiatrists. Cox, J.L., Holden, J.M., & Sagovsky, R. (1987). Detection of postnatal depression. Development of the 10-item Edinburgh\n' +
    '          Postnatal Depression Scale. British Journal of Psychiatry, 150, 782-786. Written permission must be obtained from the Royal College of Psychiatrists for copying and\n' +
    '          distribution to others or for republication (in print, online or by any other medium).',
  TRANSLATIONS_OF_THE_SCALE = 'Translations of the scale, and guidance as to its use, may be found in Cox, J.L., Holden, J & Henshaw, C. (2014) Perinatal Mental Health: The Edinburgh Postnatal\n' +
    '          Depression Scale (EPDS) Manual. 2nd Edn. London: RCPsych Publications.',
  RCPSYCH_LINK = 'https://www.rcpsych.ac.uk/usefulresources/publications/books/rcpp/9781909726130.aspx'
}

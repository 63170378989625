import { Component, RefObject } from 'react';
import TimeField from 'react-simple-timefield';
import { ELSDayPickerType } from 'models/els';
import { dateTimeHelper } from 'helpers';
import { ELSFlex } from 'components/common/els';
import './sng.datetime.scss';
import { SNGDate } from '..';

export interface SNGDateTimeProps {
  datePickerRef: RefObject<ELSDayPickerType>;
  dateValue: string;
  timeValue: string;
  disabled?: boolean;
  onDateChange: Function;
  onTimeChange: Function;
  selectOnly?: boolean;
  disableAfter?: Date;
  disableBefore?: Date;
  labelDate?: string;
  labelTime?: string;
}
class SNGDateTime extends Component<SNGDateTimeProps> {
  static displayName = 'SNGDateTime';

  handleDateChange = (value) => {
    let date = '';
    if (typeof value === 'object') {
      date = dateTimeHelper.toDate(value.toString());
    } else {
      const regexDateString = /^\d{1,2}-\d{1,2}-\d{1,4}/;
      date = regexDateString.test(value) ? dateTimeHelper.toDate(value, null, true) : null;
    }
    this.props.onDateChange(date);
  };

  handleTimeChange = (event, time) => this.props.onTimeChange(time);

  render() {
    return (
      <ELSFlex left className="sng-datetime">
        <div className="sng-datetime__date">
          {this.props.labelDate && <div>{this.props.labelDate}</div>}
          <SNGDate
            disabled={this.props.disabled}
            selectOnly={this.props.selectOnly}
            componentRef={this.props.datePickerRef}
            disableBefore={this.props.disableBefore}
            disableAfter={this.props.disableAfter}
            value={this.props.dateValue}
            onDateChange={this.handleDateChange}
            inputAriaLabel="Date field"
          />
        </div>
        <div className="u-els-margin-left-1o2">
          {this.props.labelDate && <div>{this.props.labelTime}</div>}
          <TimeField
            aria-label="Time field"
            disabled={this.props.disabled}
            className="sng-datetime__time c-els-field__input"
            value={this.props.timeValue}
            onChange={this.handleTimeChange}
          />
        </div>
      </ELSFlex>
    );
  }
}

export default SNGDateTime;

export enum FormField {
  ID = 'id',
  DOSAGE_DATE = 'dosageDate',
  DOSAGE_TIME = 'dosageTime',
  ADMINISTERED_DATE = 'administeredDate',
  ADMINISTERED_TIME = 'administeredTime',
  ADMINISTERED_TIME_OFFSET = 'administeredTimeOffset',
  COMMENTS = 'comments',
  FIRST_INITIAL = 'firstInitial',
  LAST_INITIAL = 'lastInitial',
  HOLD_DATE = 'holdDate',
  HOLD_TIME = 'holdTime',
  HOLD_FIRST_INITIAL = 'holdFirstInitial',
  HOLD_LAST_INITIAL = 'holdLastInitial',
  HOLD_COMMENTS = 'holdComments',
  IS_HOLD = 'isHold',
  DISCONTINUE_DATE = 'discontinueDate',
  DISCONTINUE_TIME = 'discontinueTime',
  DISCONTINUE_TIME_OFFSET = 'discontinueTimeOffset',
  DISCONTINUE_FIRST_INITIAL = 'discontinueFirstInitial',
  DISCONTINUE_LAST_INITIAL = 'discontinueLastInitial',
  DISCONTINUE_COMMENTS = 'discontinueComments',
  IS_DISCONTINUED = 'isDiscontinued'
}

export enum FormFieldLabel {
  DOSAGE_DATE = 'Dosage Date',
  DOSAGE_TIME = 'Dosage Time',
  ADMINISTERED_DATE = 'Administered Date',
  ADMINISTERED_TIME = 'Administered Time',
  ADMINISTERED_TIME_OFFSET = 'Administered Time Offset',
  COMMENTS = 'Comments (Administration Notes, Dose Given, etc.)',
  FIRST_INITIAL = 'First Initial',
  LAST_INITIAL = 'Last Initial',
  HOLD_DATE = 'Hold Date',
  HOLD_TIME = 'Hold Time',
  HOLD_COMMENTS = 'Comments (Reason for hold, Notes, etc.)',
  DISCONTINUE_DATE = 'Discontinue Date',
  DISCONTINUE_TIME = 'Discontinue Time',
  DISCONTINUE_TIME_OFFSET = 'Discontinue Time Offset',
  DISCONTINUE_COMMENTS = 'Comments (Reason for discontinue, Notes, etc.)'
}

export const DOSE_MODE = {
  ADMINISTER: 'ADMINISTER',
  HOLD: 'HOLD',
  DISCONTINUE: 'DISCONTINUE'
};

export const INACTIVE_TYPE = {
  COMPLETED: 'Completed',
  DISCONTINUED: 'Discontinued',
  PAST_DUE: 'Past due',
  EXPIRED: 'Expired',
  STAT_ADMINISTERED: 'Stat! Administered'
};

export const INACTIVE_ORDERS_SHOW_DOSAGE_TIMES_COUNT = 500;
export const EXPIRED_DAYS = 8;

export const ORDER_TYPES = {
  PRN: 'PRN',
  STAT: 'Stat!',
  IV: 'Routine'
};

export const SPECIAL_DOSAGE_MAPPING_RULES = {
  NORMAL: 0,
  AFTER_START_TIME: 1,
  AFTER_FIRST_DOSE: 2,
  TIMES_IN_A_WEEK: 3,
  ONCE_THEN_DROP_OFF: 4
};

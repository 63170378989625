import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const NutritionHydrationView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="nutrition-hydration">
      <h1>{SectionTitle.NUTRITION_HYDRATION_CARE}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.NUTRITION_CARE}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NUTRITION_CARE_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.NUTRITION_CARE_LIST_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.HYDRATION_CARE}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HYDRATION_CARE_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.HYDRATION_CARE_LIST_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <SectionDivider mt />
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.DIABETIC_CARE}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DIABETIC_CARE_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.DIABETIC_CARE_LIST_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

NutritionHydrationView.displayName = 'NutritionHydrationView';
export default NutritionHydrationView;

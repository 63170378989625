import cx from 'classnames';
import { Fragment } from 'react';
import TimeField from 'react-simple-timefield';
import { FormFieldDataType, FormFieldInputType, MultiSelectType } from 'models/enum';
import { ChartMetaFormField, ContentItem } from 'models/ui';
import { ELSTextarea, GenericImage, SNGDate, ScaleWrapper, TextBlock, ToggleNotesField } from 'components/common';
import { ELSCheckBox, ELSDropDown, ELSFlex, ELSFlexItem, ELSRadio, ELSTextBox } from 'components/common/els';
import MultiselectDropdownEF from 'components/common/multi-select/MultiselectDropdownEF';
import { dumpDateProps, dumpFlexItemProps, dumpInputOptions } from './constants';
import { BaseComponentProps, FlexItemProps, InputOptions, InputProps } from './types';
import { getProps } from './utils';
import { SNGDateProps } from '../../date/SNGDate';

export function Dropdown({ formField, options }: BaseComponentProps) {
  const { name, chartContent, value, disabled, label, isRequired, onBlur, onChange } = formField;
  return (
    <div className="chart-input__dropdown">
      <ELSDropDown
        id={name}
        options={chartContent}
        value={value}
        changeHandler={(e) => onChange(formField, e)}
        blurHandler={() => onBlur(formField)}
        isDisabled={disabled}
        {...options}
        ariaLabel={label}
        aria-required={isRequired}
      />
    </div>
  );
}

export function MultiselectDropdown({ formField, options }: BaseComponentProps) {
  const { isRequired, value } = formField;
  return (
    <div className="chart-input__multi-select-dropdown">
      <MultiselectDropdownEF ariaRequired={isRequired} chartFormField={formField} value={value} {...options} />
    </div>
  );
}

export function TextBox({ formField, options }: BaseComponentProps) {
  const { name, value, disabled, isRequired, onBlur, onChange } = formField;
  return (
    <ELSTextBox
      id={name}
      cssClasses="chart-input__textbox chart-input__input--pull-right"
      value={value}
      changeHandler={(e) => onChange(formField, e)}
      blurHandler={() => onBlur(formField)}
      isDisabled={disabled}
      ariaRequired={isRequired}
      {...options}
    />
  );
}

export function CheckBox({ formField, options }: BaseComponentProps) {
  const { name, disabled, chartContent, onChange } = formField;

  if (!chartContent?.length) {
    return null;
  }

  const { displayHorizontal, renderNestedField, innerClassName } = options ?? {};

  return (
    <ELSFlex left column={!displayHorizontal} className={cx(innerClassName)}>
      {chartContent.map((item) => (
        <Fragment key={item.id}>
          <ELSFlexItem className={cx({ 'u-els-margin-right': displayHorizontal })}>
            <ELSCheckBox
              className={item.dataType === FormFieldDataType.NESTED_CHOICE ? 'u-els-margin-left-2x' : undefined}
              id={`${name}-${item.value}`}
              value={item.value}
              checked={item.selected}
              changeHandler={onChange(item)}
              isDisabled={disabled}
            >
              <label htmlFor={`field-input-${name}-${item.value}`}>{item.label}</label>
            </ELSCheckBox>
          </ELSFlexItem>
          {renderNestedField(item.nestedFieldId)}
        </Fragment>
      ))}
    </ELSFlex>
  );
}

export function TextArea({ formField, options }: BaseComponentProps) {
  const { chartContent, name, value, disabled, onChange } = formField;

  if (chartContent?.length && chartContent[0].expandedLabel && chartContent[0].collapsedLabel) {
    const { label, expandedLabel, collapsedLabel } = chartContent[0];

    return (
      <ToggleNotesField
        className="chart-input__toggle"
        id={name}
        value={value}
        label={label}
        showLabel={collapsedLabel}
        hideLabel={expandedLabel}
        isDisabled={disabled}
        onChange={(e) => onChange(formField, e)}
        {...options}
      />
    );
  }

  return (
    <ELSTextarea
      id={name}
      rows={4}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        onChange(formField, e);
      }}
      {...options}
    />
  );
}

export function RadioChoice({ formField, options }: BaseComponentProps) {
  const { name, disabled, chartContent, onChange } = formField;

  if (!chartContent || chartContent.length === 0) {
    return null;
  }

  const { hideRadioContent, disableRadioContent, radioPrefixName, innerClassName, displayHorizontal, renderNestedField } = options ?? {};
  const prefix = radioPrefixName ? `${radioPrefixName}-` : '';
  const nestedFieldIds = chartContent.map((item) => item.nestedFieldId);

  return (
    <>
      <ELSFlex left wrap className={cx('u-els-margin-top-1o2', innerClassName)} column={!displayHorizontal}>
        {chartContent.map(
          (item) =>
            !hideRadioContent?.(item) && (
              <Fragment key={item.id}>
                <ELSFlexItem className={cx({ 'u-els-margin-right': displayHorizontal })}>
                  {item.resourceUri && <GenericImage id={item.id} altText={item.label} src={`${item.resourceUri}`} />}
                  <ELSRadio
                    id={`${prefix}-${name}-${item.value}`}
                    name={`${prefix}${name}`}
                    value={item.value}
                    checked={item.selected}
                    changeHandler={onChange(item)}
                    isDisabled={disableRadioContent ? disableRadioContent(item) : item.disabled || disabled}
                  >
                    <label htmlFor={`field-input-${prefix}-${name}-${item.value}`}>{item.label}</label>
                  </ELSRadio>
                </ELSFlexItem>
                {!displayHorizontal && renderNestedField(item.nestedFieldId)}
              </Fragment>
            )
        )}
      </ELSFlex>
      {nestedFieldIds.length > 0 && displayHorizontal && (
        <ELSFlex left className="u-els-margin-left-2x">
          <ELSFlexItem>{nestedFieldIds.map((nestedFieldId) => renderNestedField(nestedFieldId))}</ELSFlexItem>
        </ELSFlex>
      )}
    </>
  );
}

export interface DateProps extends BaseComponentProps {
  options?: SNGDateProps;
}

export function DateField({ formField, options }: DateProps) {
  const { name, value, disabled, isRequired, onBlur, onChange } = formField;
  let datePreviousValue = '';
  let dateComponentKey = 0;

  // if date value change to blank, reset component
  if (datePreviousValue && !value) {
    dateComponentKey += 1;
  }

  datePreviousValue = value;

  return (
    <SNGDate
      key={`${dateComponentKey}`}
      id={name}
      value={value}
      disableBefore={options?.disableBefore}
      onDateChange={(e) => onChange(formField, e)}
      onBlur={() => onBlur(formField)}
      usePropValue
      isDisabled={disabled}
      ariaRequired={isRequired}
      {...options}
    />
  );
}

export function Time({ formField, options }: BaseComponentProps) {
  const { name, value, disabled, isRequired, onBlur, onChange } = formField;

  const timeFieldId = name ? `field-input-${name}` : undefined;
  return (
    <div className="time-field-container u-els-display-inline-block">
      <TimeField
        id={timeFieldId}
        className="c-els-field__input"
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(formField, e)}
        onBlur={() => onBlur(formField)}
        style={{ width: '100%' }}
        aria-required={isRequired}
        {...options}
      />
    </div>
  );
}

export function MultiSelectRadio({ formField, options }: BaseComponentProps) {
  const { value, chartContent, isRequired } = formField;

  if (!chartContent?.length) {
    return null;
  }

  return (
    <div className="chart-input__multi-select-dropdown">
      <MultiselectDropdownEF ariaRequired={isRequired} chartFormField={formField} value={value} selectType={MultiSelectType.SINGLE} {...options} />
    </div>
  );
}

export interface ScaleProps extends BaseComponentProps {
  formFieldMap: Map<string, ChartMetaFormField>;
}

export function Scale({ formField, formFieldMap, options }: ScaleProps) {
  const { chartContent, name, onChange, disabled, isRequired } = formField;
  const {
    showScaleRollUp,
    contextDirectionMap: originContextDirectionMap,
    groupScaleQuestion,
    showScoreInAnswer,
    isHiddenScore,
    isNotSaveChildValueInScore,
    renderNestedField
  } = options ?? {};
  if (!chartContent?.length) {
    return null;
  }

  const contextDirectionMap = originContextDirectionMap ?? new Map();
  return (
    <ScaleWrapper
      id={name}
      onChange={(event, option, question) => onChange(formField, event, { option, question })}
      chartContent={chartContent}
      showRollUp={showScaleRollUp ?? true}
      disabled={disabled}
      contextDirectionMap={contextDirectionMap}
      groupScaleQuestion={groupScaleQuestion}
      showScoreInAnswer={showScoreInAnswer}
      isAriaRequired={isRequired}
      formFieldMap={formFieldMap}
      renderNestedField={renderNestedField}
      isHiddenScore={isHiddenScore}
      isNotSaveChildValueInScore={isNotSaveChildValueInScore}
    />
  );
}

export function Image({ formField }: Omit<BaseComponentProps, 'options'>) {
  const { chartContent, name } = formField;
  if (!chartContent?.length) {
    return null;
  }

  const [content] = chartContent;
  return <GenericImage id={name} altText={name} src={`${content.resourceUri}`} />;
}

export function CustomTextBlock({ formField }: Omit<BaseComponentProps, 'options'>) {
  const { chartContent } = formField;

  if (!chartContent?.length) {
    return null;
  }

  const [content] = chartContent;
  return <TextBlock content={content.label} />;
}

export default function Input(props: InputProps) {
  const { fieldId, formField, formFieldMap, children, onChange: baseOnChange } = props;
  const { inputType, onChange: originalOnChange } = formField;

  const onChange = (field: ChartMetaFormField, event, extraParam?) => {
    if (baseOnChange) {
      baseOnChange(event);
    }

    originalOnChange(field, event, extraParam);
  };

  const buildOnChange = (item: ContentItem) => {
    return () => {
      if (baseOnChange) {
        const extraParam = baseOnChange<{ isNotSaveChildValueInScore: boolean }>(formField, item);
        item.onChange(formField, item, extraParam, extraParam?.isNotSaveChildValueInScore);
      } else {
        item.onChange(formField, item);
      }
    };
  };

  // To make sure isActive compatible with current metadata, default value isActive is true.
  const chartContent = formField.chartContent?.filter((item) => !item.isHidden && (item.isActive || item.isActive === undefined));

  const transformedFormField: ChartMetaFormField = {
    ...formField,
    onChange
  };

  const inputOptions: InputOptions = getProps<InputOptions, InputProps>(Object.keys(dumpInputOptions), props);
  const containerOptions: FlexItemProps = getProps<FlexItemProps, InputProps>(Object.keys(dumpFlexItemProps), props);

  const baseComponentProps: BaseComponentProps = {
    options: inputOptions,
    formField: transformedFormField
  };

  switch (inputType) {
    case FormFieldInputType.CONTAINER: {
      return (
        <ELSFlexItem id={fieldId} {...containerOptions}>
          {children}
        </ELSFlexItem>
      );
    }
    case FormFieldInputType.DROPDOWN: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent
        }
      };
      return <Dropdown {...customComponentProps} />;
    }
    case FormFieldInputType.MULTISELECT_DROPDOWN:
      return <MultiselectDropdown {...baseComponentProps} />;
    case FormFieldInputType.TEXT_BOX:
      return <TextBox {...baseComponentProps} />;
    case FormFieldInputType.CHECK_BOX: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent,
          onChange: buildOnChange
        }
      };
      return <CheckBox {...customComponentProps} />;
    }
    case FormFieldInputType.TEXT_AREA: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent
        }
      };
      return <TextArea {...customComponentProps} />;
    }
    case FormFieldInputType.RADIO_CHOICE: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent,
          onChange: buildOnChange
        }
      };
      return <RadioChoice {...customComponentProps} />;
    }
    case FormFieldInputType.DATE: {
      const dateProps = getProps<SNGDateProps, InputProps>(Object.keys(dumpDateProps), props);
      const customComponentProps: DateProps = {
        ...baseComponentProps,
        options: dateProps
      };
      return <DateField {...customComponentProps} />;
    }
    case FormFieldInputType.TIME: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent
        }
      };
      return <Time {...customComponentProps} />;
    }
    case FormFieldInputType.MULTI_SELECT_RADIO:
      return <MultiSelectRadio {...baseComponentProps} />;
    case FormFieldInputType.SCALE: {
      const customComponentProps: ScaleProps = {
        ...baseComponentProps,
        formFieldMap,
        formField: {
          ...transformedFormField,
          chartContent
        }
      };
      return <Scale {...customComponentProps} />;
    }
    case FormFieldInputType.IMAGE: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent
        }
      };
      return <Image {...customComponentProps} />;
    }
    case FormFieldInputType.TEXT_BLOCK: {
      const customComponentProps: BaseComponentProps = {
        ...baseComponentProps,
        formField: {
          ...transformedFormField,
          chartContent
        }
      };
      return <CustomTextBlock {...customComponentProps} />;
    }
    default:
      return <>{children}</>;
  }
}

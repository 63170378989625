export enum SectionTitle {
  SKIN_CARE = 'Skin Care',
  PRESSURE_INJURY_REDUCTION = 'Pressure Injury Reduction'
}

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentOrCareRequired',
  SKIN_CARE_LIST = 'skinCare',
  SKIN_CARE_LIST_NOTES = 'skinCareNotes',
  PRESSURE_ULCER_REDUCTION = 'pressureUlcerReduction',
  PRESSURE_ULCER_REDUCTION_NOTES = 'pressureUlcerReductionNotes'
}

import { Box } from 'components/common';
import { MetaTypes, toYaml } from './ConverterService';
import MetadataInput, { ChartFieldContentMeta, ChartFieldMeta, ChartFieldValidationMeta } from './MetadataInput';
import MetadataTable from './MetadataTable';
import OverrideSideBar from './OverrideSideBar';

export interface MetadataToolHomePageViewProps {
  chartFieldData: ChartFieldMeta[];
  chartFieldContentData: ChartFieldContentMeta[];
  chartFieldValidations: ChartFieldValidationMeta[];
  chartFieldSelected: ChartFieldMeta;
  contentSelected: ChartFieldContentMeta;
  validationSelected: ChartFieldValidationMeta;
  onAddChartField: Function;
  onAddChartFieldContent: Function;
  onAddValidation: Function;
  onContentRowClick: Function;
  onContentRemoveClick: Function;
  onChartFieldRowClick: Function;
  onChartFieldRemoveClick: Function;
  onValidationRowClick: Function;
  onValidationRemoveClick: Function;
  metaTypes: MetaTypes;
  toYaml: Function;
  importExistingYaml: (e?: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  isContentSideBarOpen: boolean;
  isValidationSideBarOpen: boolean;
  openContentSideBar: Function;
  closeContentSideBar: Function;
  openValidationSideBar: Function;
  closeValidationSideBar: Function;
  onContentContextSave: Function;
  onValidationContextSave: Function;
  onContentContextRemoveClick: Function;
  onValidationContextRemoveClick: Function;
}

const MetadataToolHomePageView = (props: MetadataToolHomePageViewProps) => (
  <Box p3>
    <h2 className="u-els-color-primary">Metadata Tool</h2>
    <br />
    <MetadataInput
      onSaveChartField={props.onAddChartField}
      onSaveChartContent={props.onAddChartFieldContent}
      onSaveChartValidation={props.onAddValidation}
      chartFieldList={props.chartFieldData}
      parentValidationIdList={props.chartFieldValidations}
      contentSelectionIdList={props.chartFieldContentData}
      selectedChartField={props.chartFieldSelected}
      selectedChartContent={props.contentSelected}
      selectedValidation={props.validationSelected}
    />
    <MetadataTable
      onChartFieldRowClick={props.onChartFieldRowClick}
      onContentRowClick={props.onContentRowClick}
      onValidationRowClick={props.onValidationRowClick}
      onChartFieldRemoveClick={props.onChartFieldRemoveClick}
      onContentRemoveClick={props.onContentRemoveClick}
      onValidationRemoveClick={props.onValidationRemoveClick}
      chartFields={props.chartFieldData}
      chartFieldContent={props.chartFieldContentData}
      chartFieldValidations={props.chartFieldValidations}
      openContentSideBar={props.openContentSideBar}
      openValidationSideBar={props.openValidationSideBar}
    />

    <OverrideSideBar
      chartFieldContentData={props.chartFieldContentData}
      chartFieldValidations={props.chartFieldValidations}
      isContentSideBarOpen={props.isContentSideBarOpen}
      isValidationSideBarOpen={props.isValidationSideBarOpen}
      openContentSideBar={props.openContentSideBar}
      closeContentSideBar={props.closeContentSideBar}
      openValidationSideBar={props.openValidationSideBar}
      closeValidationSideBar={props.closeValidationSideBar}
      selectedContent={props.contentSelected}
      selectedValidation={props.validationSelected}
      onContentContextSave={props.onContentContextSave}
      onValidationContextSave={props.onValidationContextSave}
      onContentContextRemoveClick={props.onContentContextRemoveClick}
      onValidationContextRemoveClick={props.onValidationContextRemoveClick}
    />

    <h2>Yaml Input</h2>
    <textarea onChange={props.importExistingYaml} rows={10} cols={100} />
    <h2>Yaml Output</h2>
    <textarea value={toYaml(props.metaTypes)} readOnly rows={10} cols={100} />
  </Box>
);
export default MetadataToolHomePageView;

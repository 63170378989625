import cx from 'classnames';
import { Box, ChartHistory } from 'components/common';
import { RadioRenderer } from './shared';

export interface OverTheCounterProductsTableProps {
  chartHistory: object[];
  changeHandler: Function;
}

const OverTheCounterProductsTable = (props: OverTheCounterProductsTableProps) => {
  const { chartHistory, changeHandler } = props;
  const css = ['column__brand-name', 'column__generic-name', 'column__product-type', 'column__dose', 'column__taken', 'column__indication', 'column__admit', 'column__discharge'];

  const resumeOnAdmitRenderer = (row) => <RadioRenderer row={row} changeHandler={changeHandler} homeMedicationType="counterProductsData" fieldChangeName="resumeOnAdmit" />;

  const resumeOnDischargeRenderer = (row) => <RadioRenderer row={row} changeHandler={changeHandler} homeMedicationType="counterProductsData" fieldChangeName="resumeOnDischarge" />;

  const customRenderer = (active, value) => <div className={cx({ 'row--deleted': !active })}>{value}</div>;

  const dataSource = {
    headers: [
      { field: 'brandName', text: 'Brand Name', customRender: (row) => customRenderer(row.active, row.brandName) },
      { field: 'genericName', text: 'Generic Name', customRender: (row) => customRenderer(row.active, row.genericName) },
      { field: 'productType', text: 'Product Type', customRender: (row) => customRenderer(row.active, row.productType) },
      { field: 'productDetailsFormValue', text: 'Form', customRender: (row) => customRenderer(row.active, row.productDetailsFormValue) },
      { field: 'overTheCounterProductsDose', text: 'Dose', customRender: (row) => customRenderer(row.active, row.overTheCounterProductsDose) },
      {
        field: 'overTheCounterProductsProductTakenInLast24Hours',
        text: 'Take in Last 24 Hrs.',
        customRender: (row) => customRenderer(row.active, row.overTheCounterProductsProductTakenInLast24Hours)
      },
      { field: 'resumeOnAdmit', text: 'Resume on Admit', customRender: (row) => resumeOnAdmitRenderer(row) },
      { field: 'resumeOnDischarge', text: 'Resume on Discharge', customRender: (row) => resumeOnDischargeRenderer(row) }
    ],
    css,
    data: chartHistory
  };
  return (
    <Box mt className="counter-products-table">
      <ChartHistory dataSource={dataSource} showNumEntries={false} />
    </Box>
  );
};

OverTheCounterProductsTable.displayName = 'OverTheCounterProductsTable';
export default OverTheCounterProductsTable;

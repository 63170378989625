import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel, SaveType, SectionTitle } from './constants';
import { AllergyInformationChartHistory } from './AllergyInformationHistory';
import AllergyInformationView from './AllergyInformationView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface AllergyInformationProps extends ChartComponentProps {
  updatePatient: Function;
  onEnableDisplayRecords: Function;
}

interface AllergyInformationState {
  isLocked: boolean;
  chartHistory: AllergyInformationChartHistory[];
  fragments: ChartFragment[];
  statusFragment: ChartFragment;
}

class AllergyInformation extends Component<AllergyInformationProps, AllergyInformationState> {
  static displayName = 'AllergyInformation';

  fragmentTypes: FragmentType[];
  navIds: string[];

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      chartHistory: [],
      fragments: [],
      statusFragment: null
    };
    this.fragmentTypes = [FragmentType.AUTHORED, FragmentType.CHARTING, FragmentType.STATUS];
    this.navIds = [NAV_ID.ALLERGIES];
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
    const loadAllergyInfoFragmentStatus = this.props.loadChartData();
    const loadAllergiesFragment = this.props.loadChartData(this.fragmentTypes, this.navIds);
    appHelper.useLoader(Promise.all([loadAllergyInfoFragmentStatus, loadAllergiesFragment]).then(this.loadChartData));
  }

  buildFormFields = () => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const radioChoices = [
      {
        name: FormField.ALLERGY_TYPE,
        label: FormFieldLabel.ALLERGY_TYPE
      },
      {
        name: FormField.SEVERITY,
        label: FormFieldLabel.SEVERITY
      },
      {
        name: FormField.CONFIDENCE_LEVEL,
        label: FormFieldLabel.CONFIDENCE_LEVEL
      }
    ];
    const multiselectRadios = [
      {
        name: FormField.ALLERGEN,
        label: FormFieldLabel.ALLERGEN
      },
      {
        name: FormField.ALLERGEN_MEDICATION,
        label: FormFieldLabel.ALLERGEN
      },
      {
        name: FormField.INFORMANT,
        label: FormFieldLabel.INFORMANT
      }
    ];
    const textBoxes = [
      { name: FormField.ALLERGEN_OTHER, label: FormFieldLabel.OTHER },
      {
        name: FormField.ALLERGEN_MEDICATION_OTHER,
        label: FormFieldLabel.OTHER
      },
      { name: FormField.REACTIONS_OTHER, label: FormFieldLabel.OTHER },
      { name: FormField.INFORMANT_OTHER, label: FormFieldLabel.OTHER }
    ];

    dataMap.set(
      FormField.NO_KNOWN_ALLERGIES,
      createFormField({
        name: FormField.NO_KNOWN_ALLERGIES,
        type: FormFieldInputType.CHECK_BOX
      })
    );
    dataMap.set(
      FormField.REACTIONS,
      createFormField({
        name: FormField.REACTIONS,
        type: FormFieldInputType.MULTISELECT_DROPDOWN
      })
    );
    radioChoices.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label })));
    multiselectRadios.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTI_SELECT_RADIO, label })));
    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, label })));

    return dataMap;
  };

  loadChartData = (apiRes) => {
    const [allergyInfoFragment, allergiesFragments] = apiRes;

    const statusFragment = chartHelper.findStatusFragment(allergyInfoFragment.data, NAV_ID.ALLERGY_INFORMATION, NAV_ID.ADMISSION_HISTORY);
    const isLocked = chartHelper.isChartLocked(allergyInfoFragment.data, this.props.selectedNavId);
    this.setState({ isLocked, statusFragment }, () => this.updateChartHistory(allergiesFragments));
  };

  updateChartHistory = (apiRes) => {
    const { isAuthor } = this.props;
    const { statusFragment } = this.state;
    const chartHistoryAllergies = apiRes.data.map((fragment) => {
      return {
        active: fragment.active,
        id: fragment.fragmentId,
        chartingAt: fragment.chartingAt,
        createdAt: fragment.createdAt,
        updatedAt: fragment.updatedAt,
        creator: fragment.creator,
        modifier: fragment.modifier,
        [FormField.NO_KNOWN_ALLERGIES]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.NO_KNOWN_ALLERGIES)[0]?.title,
        [FormField.ALLERGY_TYPE]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGY_TYPE)[0]?.content,
        [FormField.ALLERGEN]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN)[0]?.content,
        [FormField.ALLERGEN_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_OTHER)[0]?.content,
        [FormField.ALLERGEN_MEDICATION]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_MEDICATION)[0]?.content,
        [FormField.ALLERGEN_MEDICATION_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_MEDICATION_OTHER)[0]?.content,
        [FormField.REACTIONS]: chartHelper
          .getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.REACTIONS)
          .map((item) => item.title)
          .filter((item) => item !== 'Other'),
        [FormField.REACTIONS_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.REACTIONS_OTHER)[0]?.content,
        [FormField.SEVERITY]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.SEVERITY)[0]?.content,
        [FormField.INFORMANT]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.INFORMANT)[0]?.content,
        [FormField.INFORMANT_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.INFORMANT_OTHER)[0]?.content,
        [FormField.CONFIDENCE_LEVEL]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.CONFIDENCE_LEVEL)[0]?.content
      };
    });

    const chartHistory = chartHistoryAllergies
      .filter((fragment) => isAuthor || moment(fragment.createdAt).isBefore(statusFragment?.completedAt))
      .map((fragment) => {
        const active = (!fragment.active && moment(fragment.updatedAt).isAfter(statusFragment?.completedAt)) || fragment.active;
        return { ...fragment, active };
      });

    const chartFragments = apiRes.data?.filter((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    this.setState({ chartHistory, fragments: chartFragments });

    let unknownLatestAllergyInformation = null;
    chartHistoryAllergies
      .filter((record) => record.noKnownAllergies && record.active)
      .forEach((record) => {
        if (!unknownLatestAllergyInformation) {
          unknownLatestAllergyInformation = record;
        }
        if (moment(record.createdAt).isAfter(unknownLatestAllergyInformation.createdAt)) {
          unknownLatestAllergyInformation = record;
        }
      });
    const allergyInformationForPatient = chartHistoryAllergies
      .filter((record) => {
        const isAfterUnknownLatestAllergyInformation = unknownLatestAllergyInformation ? moment(record.createdAt).isAfter(unknownLatestAllergyInformation?.chartingAt) : true;
        return record.active && !record.noKnownAllergies && isAfterUnknownLatestAllergyInformation;
      })
      .map((record) => ({
        [FormField.ALLERGY_TYPE]: record.allergyType,
        [FormField.ALLERGEN]: record.allergen,
        [FormField.ALLERGEN_OTHER]: record.allergenOther,
        [FormField.ALLERGEN_MEDICATION]: record.medication,
        [FormField.ALLERGEN_MEDICATION_OTHER]: record.allergenMedicationOther
      }));
    this.props.updatePatient({
      allergyRecords: allergyInformationForPatient
    });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  handleSaveClick = (type: string) => {
    const afterSaveContinueAuthor = this.props.isAuthor ? this.afterSave : this.props.afterSave;
    const saveOptions = {
      addAllergy: { navId: NAV_ID.ALLERGIES, defaultFormFields: this.buildFormFields(), afterSave: this.afterSave },
      saveContinue: { navId: NAV_ID.ALLERGIES, defaultFormFields: this.buildFormFields(), afterSave: afterSaveContinueAuthor }
    };
    this.props.saveChart([this.buildFragment()], saveOptions[type]);
  };

  afterSave = () => {
    this.props.loadChartData(this.fragmentTypes, this.navIds).then(this.updateChartHistory);
    if (this.props.isAuthor) {
      this.props.onEnableDisplayRecords(true);
    }
  };

  buildFragment = () => {
    const record = {
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ALLERGIES,
        fields: [
          FormField.NO_KNOWN_ALLERGIES,
          FormField.ALLERGY_TYPE,
          FormField.ALLERGEN,
          FormField.ALLERGEN_OTHER,
          FormField.ALLERGEN_MEDICATION,
          FormField.ALLERGEN_MEDICATION_OTHER,
          FormField.REACTIONS,
          FormField.REACTIONS_OTHER,
          FormField.SEVERITY,
          FormField.INFORMANT,
          FormField.INFORMANT_OTHER,
          FormField.CONFIDENCE_LEVEL
        ],
        formFieldMap
      })
    ];
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false }, NAV_ID.ALLERGIES)
        .then(() => this.props.loadChartData(this.fragmentTypes, this.navIds))
        .then(this.updateChartHistory)
        .then(this.props.showDeleteSuccess)
    );
  };

  render() {
    const { isLocked, chartHistory, statusFragment } = this.state;
    const { isAuthor, formFieldMap, formSubmittedCount } = this.props;
    const addAllergyActionProps: ChartActionsComponentProps = {
      saveButtonType: 'secondary',
      saveButtonText: 'Add Allergy',
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: () => this.handleSaveClick(SaveType.ADD_ALLERGY)
    };
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: () => this.handleSaveClick(SaveType.SAVE_CONTINUE)
    };
    const viewProps = {
      isLocked,
      isAuthor,
      chartActionsProps,
      addAllergyActionProps,
      chartHistory,
      statusFragment,
      formFieldMap,
      formSubmittedCount,
      deleteHistory: this.deleteHistory
    };
    return <AllergyInformationView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  isAuthor: appSelectors.getIsAuthor(state)
});

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (newPatientData) => dispatch(appActions.updatePatient(newPatientData))
});

const enhancers = [withAdmissionHistory, withChartLogic, connect(mapStateToProps, mapDispatchToProps)];

export { AllergyInformation as BaseAllergyInformation };
export default compose(...enhancers)(AllergyInformation);

import cx from 'classnames';
import { ReactNode } from 'react';

export interface BadgeProps {
  children: ReactNode;
  className?: string | { [key: string]: boolean };
  inline?: boolean;
}

const Badge = (props: BadgeProps) => (
  <div className={cx('u-els-display-inline-block c-els-badge c-els-badge--background-subtile u-els-nowrap', props.className, { 'c-els-badge--inline': props.inline })}>
    {props.children}
  </div>
);

Badge.displayName = 'Badge';
export default Badge;

import cx from 'classnames';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Paginator } from '@els/els-react--paginator';
import { AppMessage, FULL_TIME_FORMAT, Locales } from 'constants/app.constant';
import { getPaginationConfig } from 'helpers/app.helper';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { Box, ButtonLinkIcon, ChartPermission, DeleteCell } from 'components/common';
import { A11y, ADDS_HISTORY_TABLE_LIMIT, headersMap } from '../constants';
import { VitalSignsSuperKeyValueMap, VitalSignsDataSuperKey } from '../types';
import './atoeAssessmentHistoryDataTable.scss';

export interface AtoEAssessmentHistoryDataTableProps {
  dataSource: VitalSignsSuperKeyValueMap[];
  keys?: {
    value: VitalSignsDataSuperKey;
    customClassnames?: string;
  }[];
  deleteRecord?: Function;
  showDeleteConfirmation(onConfirmClick: Function): Function;
  showDeleteColumn?: boolean;
  sidebarProps?: {
    onOpen: (id: string) => void;
  };
}

/**
 * This Data Table is specifically for A to E Assessment History only
 * @param param0
 * @returns
 */
export default function AtoEAssessmentHistoryDataTable({ dataSource, sidebarProps, ...props }: AtoEAssessmentHistoryDataTableProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { onOpen } = sidebarProps;

  if (!dataSource && dataSource.length === 0) {
    return (
      <Box id="qa-table__no-data" mt mb2>
        {AppMessage.NO_TABLE_DATA}
      </Box>
    );
  }

  const { realizedPage, sliceStart, sliceEnd } = getPaginationConfig(dataSource.length, ADDS_HISTORY_TABLE_LIMIT, currentPage);
  const slicedDataSource = dataSource.slice(sliceStart, sliceEnd);
  const showMessage = `Showing ${slicedDataSource.length} of ${dataSource.length} entries`;

  return (
    <>
      <header className="c-els-vitalSigns-data-table__header u-els-margin-bottom">
        <span data-test-id="counting-message">{showMessage}</span>
        <Paginator
          className="o-els-flex-layout__item"
          resultsPerPage={ADDS_HISTORY_TABLE_LIMIT}
          currentPage={realizedPage}
          totalResults={dataSource.length}
          a11y={A11y}
          onPageChange={setCurrentPage}
        />
      </header>
      <section className="c-els-vitalSigns-data-table__container">
        <table className="c-els-vitalSigns-data-table c-els-table--no-dividers c-els-table--vertical-dividers u-els-text-right">
          <thead className="c-els-table__head">
            <tr className="c-els-table__row c-els-table__row--head">
              <th scope="col" className="c-els-vitalSigns-data-table__cell c-els-vitalSigns-data-table__cell--header c-els-vitalSigns-data-table__cell--min-width">
                {' '}
              </th>
              {slicedDataSource.map((item) => {
                const header = item.CHARTING_AT.value;
                const date = formatDate({ date: toMomentWithParsers(String(header)).toDate(), locale: Locales.EN_AU });
                const time = toMomentWithParsers(String(header)).format(FULL_TIME_FORMAT);
                return (
                  <th
                    key={uuidv4()}
                    scope="col"
                    className="c-els-vitalSigns-data-table__cell c-els-vitalSigns-data-table__cell--header c-els-vitalSigns-data-table__cell--min-width u-els-text-right"
                  >
                    <strong>{date}</strong>
                    <p>{time}</p>
                    <ButtonLinkIcon
                      iconName="enlarge"
                      iconSize="1x"
                      text="View"
                      onClick={() => {
                        onOpen(item.ID.value.toString());
                      }}
                      className="u-els-float-right u-els-margin-top-1o2"
                      preventAutoPending
                      isDisabled={!item.ACTIVE.value}
                    />
                  </th>
                );
              })}
              {slicedDataSource.length < ADDS_HISTORY_TABLE_LIMIT &&
                Array.from(Array(ADDS_HISTORY_TABLE_LIMIT - slicedDataSource.length).keys()).map(() => (
                  <th key={uuidv4()} scope="col" className="c-els-vitalSigns-data-table__cell--header c-els-vitalSigns-data-table__cell--min-width">
                    {' '}
                  </th>
                ))}
            </tr>
          </thead>

          <TableBody dataSource={slicedDataSource} {...props} />
        </table>
      </section>
    </>
  );
}

export function TableBody({ keys, dataSource, deleteRecord, showDeleteConfirmation, showDeleteColumn }: AtoEAssessmentHistoryDataTableProps) {
  return (
    <tbody className="c-els-table__body" data-test-id="atoe-data-table-body">
      {Array.from(keys).map(({ value: key, customClassnames }) => {
        const className = cx('c-els-vitalSigns-data-table__cell', customClassnames);
        if (headersMap[key].isHidden) {
          return null;
        }
        return (
          <tr key={key} className="c-els-table__row">
            <td className={`${className} u-els-text-left`}>{headersMap[key].value}</td>

            {dataSource.map((item) => {
              const isActive = Boolean(item.ACTIVE.value);
              if (key === VitalSignsDataSuperKey.DELETE && showDeleteColumn) {
                return (
                  <td key={uuidv4()} className={cx(className, item[key].customClassnames)}>
                    <ChartPermission fragmentCreatorId={item.CREATOR.value}>
                      {({ allowDeleteFragment }) => {
                        return (
                          allowDeleteFragment && (
                            <DeleteCell
                              isActive={isActive}
                              onDeleteClick={() => {
                                showDeleteConfirmation(() => deleteRecord({ id: item.ID.value }));
                              }}
                            />
                          )
                        );
                      }}
                    </ChartPermission>
                  </td>
                );
              }
              return (
                <td key={uuidv4()} className={cx(className, { 'c-els-vitalSigns-data-table--deleted': !isActive }, item[key].customClassnames)}>
                  {item[key].value !== undefined && item[key].value !== null ? String(item[key].value) : ''}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

// eslint-disable-next-line import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires
const icon = require('!!raw-loader!assets/img/success.svg');

interface SuccessIconProps {
  id?: string;
  className?: string;
}

const SuccessIcon = (props: SuccessIconProps) => (
  // eslint-disable-next-line react/no-danger
  <span id={props.id} className={props.className} dangerouslySetInnerHTML={{ __html: icon }} />
);
SuccessIcon.displayName = 'SuccessIcon';
export default SuccessIcon;

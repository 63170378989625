import { ChartMetaFormField, ValidationRule } from 'models/ui';
import BaseValidator from './validators/interface';
import ValidatorManager from './validators/validatorManager';

class Rule {
  ruleInfo: ValidationRule;
  validator: BaseValidator;
  selfResult: boolean | null = null;
  parentResult = true;
  isResultChanged = false;
  childRuleIds: string[] = [];

  constructor(ruleInfo: ValidationRule) {
    this.ruleInfo = ruleInfo;
    this.validator = ValidatorManager.getValidator(ruleInfo.validationType);
  }

  get actionType() {
    return this.ruleInfo.validationActionType;
  }

  get id() {
    return this.ruleInfo.id;
  }

  get parentId() {
    return this.ruleInfo.parentValidationId;
  }

  get result() {
    return this.selfResult && this.parentResult;
  }

  validateRule = (fieldId: string, allFields: Map<string, ChartMetaFormField>): boolean => {
    const field = allFields.get(fieldId);
    const previousResult = this.result;
    this.selfResult = this.validator.execute({ ruleInfo: this.ruleInfo, field, allFields });
    this.isResultChanged = previousResult !== this.result;
    return this.result;
  };
}

export default Rule;

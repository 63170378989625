import { Component } from 'react';
import { SectionNavIdPatterns } from 'constants/app.constant';
import { SavedPatientCharting } from 'components/common';

class SavedSystemNursingInterventions extends Component {
  static displayName = 'savedSystemNursingInterventions';

  render() {
    const viewProps = {
      chartTitle: 'Saved System Nursing Interventions',
      navIds: [SectionNavIdPatterns.SYSTEM_NURSING]
    };
    return <SavedPatientCharting {...this.props} {...viewProps} />;
  }
}

export default SavedSystemNursingInterventions;

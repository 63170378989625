import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorFormField, Sidebar } from 'components/common';
import { ELSCheckBox, ELSFlex, ELSFlexItem } from 'components/common/els';
import InterventionHistory from './InterventionHistory';
import NoteShortcutKey from '../shared/NoteShortcutKey';
import { FormField, FormFieldLabel, SectionTitle } from '../shared/constants';

export interface OccupationalSidebarViewProps {
  isOpen: boolean;
  intervention: object[];
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  isCompleted: boolean;
  isTherapyCompleted: boolean;
  onCheckCompleted: Function;
  sidebarProps: Function;
}

const OccupationalSidebarView = (props: OccupationalSidebarViewProps) => {
  const { isOpen, chartActionsProps, intervention, formFieldMap, formSubmittedCount, isCompleted, isTherapyCompleted, onCheckCompleted, sidebarProps, onCloseClick } = props;
  const interventionChartHistory = intervention || [];
  return (
    <Sidebar open={isOpen} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
      <Box mt3>
        <h2>Occupational Therapy Consultation</h2>
      </Box>
      <div className="sidebar__divider" />
      <ELSFlex className="u-flex-grid-2x" wrap>
        {!isTherapyCompleted && (
          <ELSFlexItem w="1o3">
            <h3>Chart Inputs</h3>
            <ELSCheckBox id="isCompleted" checked={isCompleted} labelText={FormFieldLabel.COMPLETE} changeHandler={onCheckCompleted} />
            <Box my>
              <h4>{SectionTitle.ADLS}</h4>
            </Box>
            <ELSFlex className="u-flex-grid-2x section-input" wrap left>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.EATING_ADLS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.GROOMING)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.UPPER_EXTREMITY_BATH)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.LOWER_EXTREMITY_BATH)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.UPPER_EXTRIMITY_DRESS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.LOWER_EXTRIMITY_DRESS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.TOILETING)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <Box my>
              <h4>{SectionTitle.TRANSFERS}</h4>
            </Box>
            <ELSFlex className="u-flex-grid-2x section-input" wrap left>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.EATING_TRANSFERS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <ErrorFormField formField={formFieldMap.get(FormField.SHOWER_TUB)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
            <Box my>
              <h4>{SectionTitle.PAIN}</h4>
            </Box>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.LOCATION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.THERAPUTIC_EXERCISES)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TREATMENT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PATIENT_FAMILY_TEACHING)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OCCUPATIONAL_THERAPIST_NAME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OCCUPATIONAL_THERAPIST_SIGNATURE)} formSubmittedCount={formSubmittedCount} />
            <Box mt>
              <h4>{SectionTitle.INTERVENTION}</h4>
            </Box>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INTERVENTION_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INTERVENTION_TIME)} formSubmittedCount={formSubmittedCount} />
            <Box mt3 mb>
              <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
            </Box>
          </ELSFlexItem>
        )}
        <ELSFlexItem w={isTherapyCompleted ? '1o1' : '2o3'}>
          <h3>Interventions</h3>
          <InterventionHistory chartHistory={interventionChartHistory} />
        </ELSFlexItem>
      </ELSFlex>
      <NoteShortcutKey />
    </Sidebar>
  );
};

OccupationalSidebarView.displayName = 'OccupationalSidebarView';
export default OccupationalSidebarView;

import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, Sidebar } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';

export interface LaborProgressOnsetSidebarViewProps {
  chartActionsProps: ChartActionsComponentProps;
  isOnsetSidebarOpen: boolean;
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  onClickOutside: Function;
  requireSave: boolean;
  sidebarProps: Function;
}

const LaborProgressOnsetSidebarView = (props: LaborProgressOnsetSidebarViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, isOnsetSidebarOpen, onClickOutside: onCloseClick, requireSave, sidebarProps } = props;
  return (
    <Sidebar
      open={isOnsetSidebarOpen}
      onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
      requireSave={requireSave}
      sidebarProps={sidebarProps}
      hideCloseButton
    >
      <Box mt mb>
        <h2>Active Labor Onset</h2>
      </Box>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} className="u-els-margin-bottom" />
      <Box className="active-labor-onset">
        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.LABOR_ONSET_DATETIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.LABOR_ONSET_DATE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.LABOR_ONSET_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </Sidebar>
  );
};

LaborProgressOnsetSidebarView.displayName = 'LaborProgressOnsetSidebarView';
export default LaborProgressOnsetSidebarView;

import cx from 'classnames';

export interface ELSTextareaViewProps {
  textLabel?: string;
  rows?: number;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [restProps: string]: any;
}

const defaultProps: ELSTextareaViewProps = {
  rows: 3
};

const ELSTextareaView = (props: ELSTextareaViewProps) => {
  const { value, textLabel, id, ...others } = props;
  return (
    <div className="c-els-field">
      <span className="c-els-field__label">
        {textLabel && <span className="c-els-field__label-text">{textLabel}</span>}
        <textarea {...others} id={id ? `field-input-${id}` : undefined} value={value || ''} rows={props.rows} className={cx('c-els-field__input', props.className)} />
      </span>
    </div>
  );
};

ELSTextareaView.defaultProps = defaultProps;
ELSTextareaView.displayName = 'ELSTextareaView';
export default ELSTextareaView;

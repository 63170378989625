import produce from 'immer';
import { Component } from 'react';
import { connect } from 'react-redux';
import { ChartFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, NavigationItem, PatientContext } from 'models/ui';
import { NAV_ID, RouteParams } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import history from 'helpers/history';
import { chartService, navigationService } from 'services';
import { compose } from 'redux';
import { appSelectors } from 'redux/ducks/app';
import { withFormUtilities } from 'components/common';
import HistoryPhysicalView, { HistoryPhysicalViewProps } from './HistoryPhysicalView';

export interface LandingChart {
  navId: string;
  name: string;
  hasData: boolean;
  routePath: string;
  fragment?: ChartFragment;
}

interface HistoryPhysicalState {
  selectedRecord: ChartFragment;
  charts: LandingChart[];
  isChartLocked: boolean;
}
export interface HistoryPhysicalProps extends ChartComponentProps {
  showConfirmationModal: Function;
  showSuccessModal: Function;
  hideSuccessModal: Function;
  menuItems: NavigationItem[];
  patientContext: PatientContext;
}
class HistoryPhysical extends Component<HistoryPhysicalProps, HistoryPhysicalState> {
  static displayName = 'HistoryPhysical';

  constructor(props) {
    super(props);
    this.state = {
      selectedRecord: null,
      charts: navigationService.buildChartFromNavigationItems(this.props.menuItems, NAV_ID.PROVIDER_CHART, NAV_ID.HISTORY_PHYSICAL),
      isChartLocked: false
    };
  }

  componentDidMount() {
    if (this.props.isAuthor) {
      this.props.saveChartData({
        lockedNavIds: this.props.menuItems
      });
    } else {
      this.loadData();
    }
  }

  loadData = () => {
    const { assessment } = this.props;
    const navIds = [NAV_ID.HISTORY_PHYSICAL, ...this.state.charts.map((chart) => chart.navId)];
    const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];

    return appHelper.useLoader(
      chartService.loadFragments({ chartId: assessment.simChartId, navIds, fragmentTypes }).then(({ data: fragments }) => {
        this.setState(
          produce((state) => {
            state.isChartLocked = chartHelper.isChartLocked(fragments, NAV_ID.HISTORY_PHYSICAL);
            state.selectedRecord = chartHelper.findFragmentByNavId(fragments, NAV_ID.HISTORY_PHYSICAL, FragmentType.STATUS);
            state.charts = state.charts.map((navElement) => {
              const navFragment = fragments.find((fragment) => fragment.navElementId === navElement.navId && fragment.fragmentType !== FragmentType.STATUS) || null;
              const hasData = !!navFragment;

              return {
                ...navElement,
                hasData,
                fragment: navFragment
              };
            });
          })
        );
      }),
      { errorMessage: 'can not load fragments' }
    );
  };

  handleCompleteClick = () => {
    this.props.showConfirmationModal({
      showIcon: true,
      header: 'Please confirm.',
      message: 'Are you sure that you want to complete the History and Physical? Once completed, no further changes can be made.',
      onOkClick: this.handleConfirmCompleteClick
    });
  };

  handleConfirmCompleteClick = () => {
    const { assessment, chartingTime } = this.props;
    const navIds = [NAV_ID.HISTORY_PHYSICAL, ...this.state.charts.map((chart) => chart.navId)];
    const chartingAt = chartService.getChartingTime(chartingTime);

    appHelper.useLoader(
      chartService.lockFragments({ chartId: assessment.simChartId, navIds, chartingAt }).then(() =>
        this.props.showSuccessModal({
          message: 'History and Physical was successfully completed.',
          actions: [{ text: 'View Completed History and Physical', onClick: this.handleViewCompletedHistoryPhysical }]
        })
      ),
      { errorMessage: 'can not lock chart' }
    );
  };

  handleViewCompletedHistoryPhysical = () => {
    this.props.hideSuccessModal();
    this.loadData();
  };

  handleTileClick = (routePath: string) => {
    if (this.props.isAuthor) {
      const path = routePath.replace(`/${RouteParams.ASSESSMENT_ID}`, '');
      history.push(path);
    } else {
      navigationService.navigateToChart(routePath, this.props.assessment.eolsAssessmentId);
    }
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked: false,
      onSaveClick: this.handleCompleteClick,
      enableSaveButton: !this.props.isAuthor,
      enableDisplayRecordsButton: this.props.isAuthor,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      saveButtonText: 'Complete'
    };

    const historyPhysicalViewCharts = new Map<string, LandingChart>();
    this.state.charts.forEach((navElement) => {
      historyPhysicalViewCharts.set(navElement.navId, navElement);
    });
    const viewProps: HistoryPhysicalViewProps = {
      selectedRecord: this.state.selectedRecord,
      charts: historyPhysicalViewCharts,
      isChartLocked: this.state.isChartLocked,
      onTileClick: this.handleTileClick,
      onCompleteClick: this.handleCompleteClick,
      patientContext: this.props.patientContext,
      chartActionsProps
    };

    return <HistoryPhysicalView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  menuItems: appSelectors.getMenuItems(state)
});

export { HistoryPhysical as BaseHistoryPhysical };
export default compose(connect(mapStateToProps, null), withFormUtilities)(HistoryPhysical);

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './ActivityOccupationalTherapy';
import ActivityOccupationalTherapyReadOnly from './ActivityOccupationalTherapyReadOnly';

interface ActivityOccupationalTherapyViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const ActivityOccupationalTherapyView = (props: ActivityOccupationalTherapyViewProps) => {
  const { isLocked, fragment, formSubmittedCount, chartMetaFormFields, resetAll, statusFragment } = props;
  return (
    <div className="activity-occupational-therapy">
      {isLocked ? (
        <ActivityOccupationalTherapyReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Activity - Occupational Therapy Screen</h1>
          <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
          <Box mt2>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <Box mb>
                  <h2>{SectionTitle.NEW_OCCUPATIONAL_THERAPY}</h2>
                </Box>
                <Box mb>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.NO_NEW_OCCUPATIONAL)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
                </Box>
                <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.NEW_OCCUPATIONAL_THERAPY)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <Box mb>
                  <h2>{SectionTitle.IMPAIRMENT_ENVIDENCE}</h2>
                </Box>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.IMPAIRMENT_ENVIDENCE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.OCCUPATIONAL_THERAPY_RESTRICTION)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
          </Box>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

ActivityOccupationalTherapyView.displayName = 'ActivityOccupationalTherapyView';
export default ActivityOccupationalTherapyView;

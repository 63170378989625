import cx from 'classnames';
import { ELSButton } from '../els';

export interface ButtonLinkIconProps {
  className?: string;
  preventAutoPending?: boolean;
  iconPrefix?: 'gizmo' | 'hmds';
  iconName: string;
  text?: string;
  onClick?: Function;
  iconPosition?: 'left' | 'right';
  iconSize?: string;
  nativeProps?: object;
  isDisabled?: boolean;
  ariaLabel?: string;
}

const ButtonLinkIcon = (props: ButtonLinkIconProps) => (
  <ELSButton
    type="link"
    className={cx('c-els-button--with-icon c-els-button--middle', `c-els-button--with-icon-${props.iconPosition}`, props.className)}
    preventAutoPending={props.preventAutoPending}
    iconPrefix={props.iconPrefix}
    iconName={props.iconName}
    iconPosition={props.iconPosition}
    iconSize={props.iconSize}
    text={props.text}
    onClick={props.onClick}
    nativeProps={props.nativeProps}
    isDisabled={props.isDisabled}
    ariaLabel={props.ariaLabel}
  />
);

ButtonLinkIcon.displayName = 'ButtonLinkIcon';
export default ButtonLinkIcon;

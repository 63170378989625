export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  GENERAL = 'general',
  INTEGUMENTARY = 'integumentary',
  HEAD_NECK = 'headNeck',
  EARS_NOSE_THROAT = 'earsNoseThroat',
  EYES_VISION = 'eyesVision',
  RESPIRATORY = 'respiratory',
  CARDIOVASCULAR = 'cardiovascular',
  PERIPHERAL_VASCULAR = 'peripheralVascular',
  LYMPHATIC = 'lymphatic',
  BREASTS = 'breasts',
  ABDOMEN = 'abdomen',
  MUSCULOSKELETAL = 'musculoskeletal',
  NEUROLOGIC = 'neurologic',
  PELVIC_GENITALIA = 'pelvicGenitalia',
  PROSTATE_GENITALIA = 'prostateGenitalia',
  RECTAL = 'rectal',
  OTHER = 'other',
  ASSESSMENT_SUMMARY = 'assessmentSummary',
  PLAN = 'plan'
}

export enum FormFieldLabel {
  GENERAL = 'General',
  INTEGUMENTARY = 'Integumentary',
  HEAD_NECK = 'Head/neck',
  EARS_NOSE_THROAT = 'Ears/nose/throat',
  EYES_VISION = 'Eyes/vision',
  RESPIRATORY = 'Respiratory',
  CARDIOVASCULAR = 'Cardiovascular',
  PERIPHERAL_VASCULAR = 'Peripheral vascular',
  LYMPHATIC = 'Lymphatic',
  BREASTS = 'Breasts',
  ABDOMEN = 'Abdomen',
  MUSCULOSKELETAL = 'Musculoskeletal',
  NEUROLOGIC = 'Neurologic',
  PELVIC_GENITALIA = 'Female: pelvic/genitalia',
  PROSTATE_GENITALIA = 'Male: prostate/genitalia',
  RECTAL = 'Rectal',
  OTHER = 'Other',
  ASSESSMENT_SUMMARY = 'Assessment Summary',
  PLAN = 'Plan'
}

export enum SectionTitle {
  COMPLETE_PHYSICAL_ASSESSMENT_NARRATIVE = 'Complete Physical Assessment - Narrative'
}

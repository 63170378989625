import cx from 'classnames';
import { SyntheticEvent } from 'react';
import { ELSButton } from '@els/els-component-button-react';
import { ELSIcon } from '@els/els-component-form-field-react';
import { NavigationItem } from 'models/ui';
import { Box } from 'components/common';
import { NavigationSearchBar } from 'components/common/layout';
import './navigation.scss';

interface NavItemProps {
  item: NavigationItem;
  active?: boolean;
  onClick?(event: SyntheticEvent): void;
}

export const NavItem = ({ item: { text, path, children }, active, onClick }: NavItemProps) => (
  <a href={path} onClick={onClick} className={cx('nav__item', { 'nav__item--active': active })}>
    <div className="nav__item__label">
      <p>{text}</p>
    </div>
    {children?.length > 0 && <ELSIcon name="navigate-right" prefix="gizmo" color="n9" />}
  </a>
);

interface NavigationViewProps {
  allCharts: NavigationItem[];
  navItems: NavigationItem[];
  currentPath?: string;
  navTitle?: string;
  show?: boolean;
  goBack?(event?: SyntheticEvent): void;
  onNavItemClick?(event: SyntheticEvent, item: NavigationItem): void;
}

const NavigationView = ({ allCharts, currentPath, navTitle, navItems, goBack, onNavItemClick, show }: NavigationViewProps) => (
  <div className="nav">
    <div className={cx('js-animation-container', { animated: show !== null, show, hide: show === false })}>
      <div className="nav__animation">
        <div className="nav__wrapper" id="nav_start">
          <div className="nav__header">
            {navTitle && (
              <ELSButton
                onClick={goBack}
                pendingTimeout={1000}
                iconName="navigate-left"
                iconPrefix="gizmo"
                className="u-els-margin-right"
                ariaLabel="Go back to previous menu page"
              />
            )}
            {navTitle || 'MENU'}
          </div>
          <NavigationSearchBar items={allCharts} />
          {navItems[0]?.isTopChart && (
            <div className="nav-item--top">
              <NavItem item={navItems[0]} active={navItems[0].path === currentPath} onClick={(event) => onNavItemClick(event, navItems[0])} />
            </div>
          )}
          <Box mb className="c-els-divider u-els-width-1o1" />
          <ul role="navigation">
            {navItems
              .filter((item) => !item.isTopChart)
              .map((item) => (
                <li key={item.id}>
                  <NavItem item={item} active={item.path === currentPath} onClick={(event) => onNavItemClick(event, item)} />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

NavigationView.defaultProps = {
  show: null
};
NavigationView.displayName = 'NavigationView';
export default NavigationView;

import { DropDownItem } from 'models/ui';
import { AppMessage, Locales } from 'constants/app.constant';
import { Box } from 'components/common';
import HistoryLineChart from 'components/common/chart/line/HistoryLineChart';
import { ELSDropDown } from 'components/common/els';
import { BloodPressureChartProps, OxygenationChartProps, PulseChartProps, RespirationChartProps, TemperatureChartProps, VitalSignsChartList } from './types';

export interface VitalSignsChartViewProps {
  chartConfig: TemperatureChartProps | PulseChartProps | RespirationChartProps | BloodPressureChartProps | OxygenationChartProps;
  charts: DropDownItem[];
  selectedChart: string;
  onChartChange: Function;
  locale: Locales;
  isTitleHidden?: boolean;
}

const VitalSignsChartView = ({ chartConfig, charts, selectedChart, onChartChange, isTitleHidden, locale }: VitalSignsChartViewProps) => (
  <div className="vital-signs-history" aria-hidden>
    {!isTitleHidden && <h2>Chart Graph</h2>}
    <Box mt className="chart-input__dropdown">
      <ELSDropDown inline options={charts} value={selectedChart} name={VitalSignsChartList.FIRST_CHART} changeHandler={onChartChange} />
    </Box>
    <Box mt className="u-overflow-x--auto u-overflow-y--hidden">
      {chartConfig?.data?.length ? <HistoryLineChart {...chartConfig} locale={locale} /> : AppMessage.NO_GRAPH_DATA}
    </Box>
  </div>
);

VitalSignsChartView.displayName = 'VitalSignsChartView';
export default VitalSignsChartView;

import cx from 'classnames';
import { AppMessage, DateFormatByLocale, DateTimeFormatByLocale, Locales, MOMENT_INCLUSIVITY_ALL, MOMENT_UNIT_DAYS } from 'constants/app.constant';
import { formatDate, toMomentWithFormat, toMomentWithParsers } from 'helpers/datetime.helper';
import { Box, ClinicalKeyDrugInformation, HorizontalScrollableContainer } from 'components/common';
import { ELSButton, ELSDataTable, ELSIcon, ELSTooltip } from 'components/common/els';
import { INACTIVE_ORDERS_SHOW_DOSAGE_TIMES_COUNT, INACTIVE_TYPE, ORDER_TYPES } from './constants';
import dosageTimesMapping from './dosageTimesMapping';
import { getIsShowAdmin } from './helper';

export interface MarTableProps {
  viewDate: string;
  columnHeaderCss?: string[];
  data: object[];
  hideFrequency?: boolean;
  hideAction?: boolean;
  hideStatus?: boolean;
  onDosageClick?: Function;
  handleHoldMedication?: Function;
  handleUnHoldMedication?: Function;
  handleDiscontinueMedication?: Function;
  hideAdministration: boolean;
  locale: Locales;
}

const MarTable = (props: MarTableProps) => {
  const { columnHeaderCss, data, hideFrequency, hideAction, hideStatus, handleHoldMedication, handleDiscontinueMedication, handleUnHoldMedication, viewDate, locale } = props;
  const dosageTimesRender = (row) => {
    const { inactiveType, isDiscontinued, orderType, dosageTimes, frequency, isAnyAdministered } = row;
    const isInactiveOrder = inactiveType || isDiscontinued;
    const result = dosageTimes
      ?.filter((item) => row.inactiveType !== INACTIVE_TYPE.COMPLETED || item.dosageTime !== 'Administer')
      .slice(0, isInactiveOrder ? INACTIVE_ORDERS_SHOW_DOSAGE_TIMES_COUNT : undefined)
      .map((item) => {
        const { isAdministered, id, administeredDate, administeredTime, firstInitial, lastInitial, dosageDate, dosageTime } = item;
        const administeredDateDisplay = formatDate({
          date: toMomentWithParsers(administeredDate).toDate(),
          locale
        });
        const dosageDateDisplay = formatDate({
          date: toMomentWithParsers(dosageDate).toDate(),
          locale
        });
        if (isAdministered) {
          return (
            <Box className="dosage-time" key={id}>
              <Box className="u-els-margin-right-1o4">
                <ELSIcon name="checkmark" size="1x" color="white" customClass="round-icon round-icon--administered" />
              </Box>
              <ELSButton type="link" text={`${administeredDateDisplay} ${administeredTime} - ${firstInitial}${lastInitial}`} onClick={() => props.onDosageClick?.(row, item)} />
            </Box>
          );
        }
        if (row.isDiscontinued || row.inactiveType === INACTIVE_TYPE.EXPIRED) {
          return (
            <Box className="dosage-time" key={id}>
              <div className="c-els-table__cell-content row--deleted">{`${dosageDateDisplay} ${dosageTime}`}</div>
            </Box>
          );
        }
        return (
          orderType !== ORDER_TYPES.PRN &&
          orderType !== ORDER_TYPES.STAT &&
          dosageTime !== 'Administer' && (
            <Box className="dosage-time" key={id}>
              {dosageDate && (
                <>
                  <div className="prefix u-els-margin-right-1o4">•</div>
                  <ELSButton
                    className={cx({ 'is-hold': row.isHold })}
                    type="link"
                    text={`${dosageDateDisplay} ${dosageTime}`}
                    onClick={() => row.inactiveType !== INACTIVE_TYPE.PAST_DUE && !row.isHold && props.onDosageClick?.(row, item)}
                  />
                </>
              )}
            </Box>
          )
        );
      });
    const showAdmin = getIsShowAdmin(dosageTimesMapping, frequency, dosageTimes, props.hideAdministration, isInactiveOrder, isAnyAdministered);
    return (
      <>
        {showAdmin && (
          <ELSButton
            isDisabled={row.isHold || row.inactiveType}
            className={cx({ 'is-hold': row.isHold || row.inactiveType })}
            type="link"
            text="Administer"
            onClick={() => props.onDosageClick?.(row, undefined)}
          />
        )}
        {result}
      </>
    );
  };
  const actionRender = (row) => {
    const { isHold } = row;
    return (
      <div className="mar-table__action-field">
        <button className="sng-button-icon-wrapper" type="button" aria-label="Hold" onClick={() => (isHold ? handleUnHoldMedication(row) : handleHoldMedication(row))}>
          <ELSIcon color="secondary" name={isHold ? 'pause-solid' : 'pause-hollow'} size="1x" />
        </button>
        <button disabled={isHold} className="sng-button-icon-wrapper" type="button" aria-label="Discontinue" onClick={() => handleDiscontinueMedication({ ...row })}>
          <ELSIcon color={isHold ? 'n3' : 'secondary'} name="hand" size="1x" />
        </button>
      </div>
    );
  };

  const informationRender = (row) => {
    return (
      <div className="mar-table_information-field">
        <span>
          {row.drugName}
          <ClinicalKeyDrugInformation drugName={row.drugName} showLink />
        </span>
      </div>
    );
  };

  const orderStartRender = (row) => {
    const dateTimeDisplay = formatDate({ date: toMomentWithFormat(row.orderStart, Object.values(DateTimeFormatByLocale)).toDate(), locale, includeTime: true });
    return <span>{dateTimeDisplay}</span>;
  };

  const orderStopRender = (row) => {
    const dateTimeDisplay = formatDate({ date: toMomentWithFormat(row.orderStop, Object.values(DateTimeFormatByLocale)).toDate(), locale, includeTime: true });
    return <span>{dateTimeDisplay}</span>;
  };

  // Now only show status for Discontinued status
  const renderStatus = (row) => row.isDiscontinued && row.status;

  const filterByViewData = ({ dosageTimes, orderStart, orderStop }, date) => {
    const hasDosageTimesWithDate = dosageTimes?.some((dosage) => dosage.dosageDate === date);
    const startDate = toMomentWithFormat(orderStart, Object.values(DateFormatByLocale));
    const stopDate = toMomentWithFormat(orderStop, Object.values(DateFormatByLocale));
    return hasDosageTimesWithDate || toMomentWithFormat(date, Object.values(DateFormatByLocale)).isBetween(startDate, stopDate, MOMENT_UNIT_DAYS, MOMENT_INCLUSIVITY_ALL);
  };

  const mappingDosageTimeByViewDate = (item, date) => {
    const isInactiveOrder = item.inactiveType || item.isDiscontinued;
    const dosageTimes = isInactiveOrder
      ? item.dosageTimes
      : item.dosageTimes?.filter((dosageTime) => formatDate({ date: toMomentWithParsers(dosageTime.dosageDate).toDate(), locale }) === date);
    return { ...item, originDosageTimes: item.dosageTimes, dosageTimes };
  };

  const mappingIsAnyAdministered = (item) => {
    const isAnyAdministered = item.dosageTimes?.some((dosageTime) => dosageTime.isAdministered);
    return { ...item, originDosageTimes: item.dosageTimes, isAnyAdministered };
  };

  const filteredData = data
    .map((item) => mappingIsAnyAdministered(item))
    .map((item) => mappingDosageTimeByViewDate(item, viewDate))
    .filter((item) => filterByViewData(item, viewDate));

  return (
    <div className="mar-table">
      <HorizontalScrollableContainer>
        <ELSDataTable data={filteredData} columnHeaderCss={columnHeaderCss}>
          <column field="drugName" header="Drug Name" customRender={informationRender} />
          <column field="orderStart" header="Order Start" sortable customRender={orderStartRender} />
          <column field="orderStop" header="Order Stop" sortable customRender={orderStopRender} />
          <column field="dose" header="Dose" />
          <column field="route" header="Route" />
          {!hideFrequency && <column field="frequency" header="Frequency" />}
          <column field="dosageTimes" header="Dosage Time(s)" customRender={dosageTimesRender} />
          {!hideAction && (
            <column
              field="action"
              header={
                <>
                  <span>Actions </span>
                  <ELSTooltip content="To hold or discontinue" showClose>
                    <ELSIcon prefix="gizmo" name="information" size="1x" align="top" color="secondary" />
                  </ELSTooltip>
                </>
              }
              customRender={actionRender}
            />
          )}
          {!hideStatus && <column field="status" header="Status" customRender={renderStatus} />}
        </ELSDataTable>
      </HorizontalScrollableContainer>
      {filteredData.length === 0 && (
        <Box className="qa-table__no-data" mt mb2>
          <span>{AppMessage.NO_TABLE_DATA}</span>
        </Box>
      )}
    </div>
  );
};
MarTable.displayName = 'MarTable';
export default MarTable;

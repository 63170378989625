import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { FormField, FormFieldLabel, Section } from './constants';
import './braden.scale.scss';

export interface BradenScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  contextDirectionMap?: Map<string, ScaleDirection>;
  chartActionsProps: ChartActionsComponentProps;
}

const BradenScaleView = (props: BradenScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, contextDirectionMap, chartActionsProps } = props;

  return (
    <div className="braden-scale">
      <h1>{Section.BRADEN_SCALE_PREDICTING_PRESSURE}</h1>
      <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
      <p>{FormFieldLabel.NOTE}</p>
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.BRADEN_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ contextDirectionMap }}
      />
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

export default BradenScaleView;

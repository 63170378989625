import moment from 'moment';
import { DATE_FORMAT } from 'constants/app.constant';
import { ELSFlex, ELSFlexItem } from 'components/common/els';

interface PatientInfoProps {
  patientInfo: string;
  studentInfo: string;
}

const PatientInfo = (props: PatientInfoProps) => {
  return (
    <>
      <h1 className="u-els-margin-top-2x u-els-margin-bottom-2x">
        {'Pre-Clinical Manager Report - '}
        {moment().format(DATE_FORMAT)}
      </h1>
      <ELSFlex className="u-els-margin-bottom-2x report__info" left>
        <ELSFlexItem className="u-els-margin-top u-els-margin-bottom u-els-padding-left">
          <span>
            {'Patient: '}
            <b>{props.patientInfo}</b>
          </span>
        </ELSFlexItem>
        <ELSFlexItem className="u-els-margin-left-2x u-els-margin-top u-els-margin-bottom u-els-padding-left">
          <span>
            {'Student Nurse: '}
            <b>{props.studentInfo}</b>
          </span>
        </ELSFlexItem>
      </ELSFlex>
    </>
  );
};

export default PatientInfo;

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, getNutritionScreenFormFieldBuilderItems } from './constants';
import NutritionScreenView, { NutritionScreenViewProps } from './NutritionScreenView';
import { buildPatientRecordsWithLinkedNestedFormFieldId } from './helper';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export interface NutritionScreenProps extends ChartComponentProps {
  patientContext: PatientContext;
}
interface NutritionScreenState {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class NutritionScreen extends Component<NutritionScreenProps, NutritionScreenState> {
  static displayName = 'NutritionScreen';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.NUTRITION_SCREEN, NAV_ID.ADMISSION_HISTORY);

        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields(this.state.fragment));
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (fragment: ChartFragment): Map<string, ChartMetaFormField> => {
    const { intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getNutritionScreenFormFieldBuilderItems(fragment).forEach(({ label, ...item }) => {
      dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
    });

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Nutrition Screen';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY,
          records: [...buildPatientRecords(formFieldMap, FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY)]
        },
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.GENERA_NUTRITION_SCREEN,
          records: [...buildPatientRecordsWithLinkedNestedFormFieldId(formFieldMap, FormField.GENERA_NUTRITION_SCREEN, true)],
          nestedFieldRecords: [buildPatientRecord(formFieldMap, FormField.UNEXPLAINED_WEIGHT_LOSS_GREATER_WEIGHT)]
        },
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.ILLNESS_INJURY_NUTRITION_SCREEN,
          records: [...buildPatientRecords(formFieldMap, FormField.ILLNESS_INJURY_NUTRITION_SCREEN, true)]
        },
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS,
          records: [
            ...buildPatientRecords(formFieldMap, FormField.FOOD_PREFERENCES_DIET_CONSIDERATIONS),
            buildPatientRecord(formFieldMap, FormField.OTHER_INPUT, LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS_OTHER_INPUT)
          ]
        },
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES,
          records: [buildPatientRecord(formFieldMap, FormField.FOOD_PREFERENCES)]
        },
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS,
          records: [buildPatientRecord(formFieldMap, FormField.DIET_SNACK)]
        },
        {
          sectionTitle: LanguageKeys.NUTRITION_SCREEN.FOOD_DISLIKES,
          records: [buildPatientRecord(formFieldMap, FormField.FOOD_DISLIKES)]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ fragmentId: this.state.fragment?.fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord };
  };

  resetForm = () => this.props.initState(this.buildDefaultFormFields(this.state.fragment));

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  render() {
    const { handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, patientContext, intl } = this.props;
    const { isLocked, fragment, statusFragment } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: NutritionScreenViewProps = {
      intl,
      isLocked,
      fragment,
      statusFragment,
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      patientContext
    };

    return <NutritionScreenView {...viewProps} />;
  }
}

export { NutritionScreen as BaseNutritionScreen };
export default compose(withChartLogic, withAdmissionHistory)(NutritionScreen);

import { ChartMetaFormField } from 'models/ui';
import { ChartAmountUnit } from 'constants/app.constant';
import { FormField } from './constant';
import { IntakeOutputType } from '../../summary/models';

export const handleIntakeType = (formFieldMap: Map<string, ChartMetaFormField>, field: ChartMetaFormField) => {
  const newAmnt = formFieldMap.get(FormField.INTAKE_AMOUNT).value;
  const newIOType = IntakeOutputType.INTAKE;
  const newUnitType = ChartAmountUnit.UNIT_ML;
  let newChoice;

  if (formFieldMap.get(FormField.INTAKE_TYPE_OTHER).value === '') {
    newChoice = field.value;
  } else {
    newChoice = formFieldMap.get(FormField.INTAKE_TYPE_OTHER).value;
  }

  return { newAmnt, newIOType, newUnitType, newChoice };
};

export const handleIntravenousFluidsType = (formFieldMap: Map<string, ChartMetaFormField>, field: ChartMetaFormField) => {
  const newAmnt = formFieldMap.get(FormField.INTRAVENOUS_FLUIDS_AMOUNT).value;
  const newIOType = IntakeOutputType.INTAKE;
  const newUnitType = ChartAmountUnit.UNIT_ML;
  let newChoice;

  if (formFieldMap.get(FormField.INTRAVENOUS_FLUIDS_TYPE_OTHER).value === '') {
    newChoice = field.value;
  } else {
    newChoice = formFieldMap.get(FormField.INTRAVENOUS_FLUIDS_TYPE_OTHER).value;
  }

  return { newAmnt, newIOType, newUnitType, newChoice };
};

export const handleMiscellaneousType = (formFieldMap: Map<string, ChartMetaFormField>, field: ChartMetaFormField) => {
  const newAmnt = formFieldMap.get(FormField.MISCELLANEOUS_AMOUNT).value;
  const newIOType = IntakeOutputType.INTAKE;
  const newUnitType = ChartAmountUnit.UNIT_ML;
  let newChoice;

  if (formFieldMap.get(FormField.MISCELLANEOUS_TYPE_OTHER).value === '') {
    newChoice = field.value;
  } else {
    newChoice = formFieldMap.get(FormField.MISCELLANEOUS_TYPE_OTHER).value;
  }

  return { newAmnt, newIOType, newUnitType, newChoice };
};

export const handleOutputMeasure = (formFieldMap: Map<string, ChartMetaFormField>, field: ChartMetaFormField) => {
  const newOutputType = field.value;
  const newIOType = IntakeOutputType.OUTPUT;
  let newAmnt;
  let newUnitType;
  let newChoice;

  if (field.value === 'frequency') {
    newAmnt = formFieldMap.get(FormField.OUTPUT_FREQUENCY_NUMBER).value;
    newUnitType = formFieldMap.get(FormField.OUTPUT_FREQUENCY_SIZE).value;
    if (formFieldMap.get(FormField.OUTPUT_FREQUENCY_TYPE_OTHER).value === '') {
      newChoice = formFieldMap.get(FormField.OUTPUT_FREQUENCY_TYPE).value;
    } else {
      newChoice = formFieldMap.get(FormField.OUTPUT_FREQUENCY_TYPE_OTHER).value;
    }
  } else if (field.value === 'volume') {
    if (formFieldMap.get(FormField.OUTPUT_VOLUME_TYPE_OTHER).value === '') {
      newChoice = formFieldMap.get(FormField.OUTPUT_VOLUME_TYPE).value;
    } else {
      newChoice = formFieldMap.get(FormField.OUTPUT_VOLUME_TYPE_OTHER).value;
    }
    newAmnt = formFieldMap.get(FormField.OUTPUT_VOLUME_AMOUNT).value;
    newUnitType = ChartAmountUnit.UNIT_ML;
  }

  return { newAmnt, newIOType, newUnitType, newChoice, newOutputType };
};

import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TherapyRecord } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import RespiratorySidebarView, { RespiratorySidebarViewProps } from './RespiratorySidebarView';
import { FormField, FormFieldLabel, OrderTypeValue } from '../shared/constants';
import { handleDisplayIntervention } from '../shared/helpers';

interface RespiratorySidebarProps extends ChartComponentProps {
  selectedNavId: string;
  therapy: TherapyRecord;
  onCloseClick: Function;
  onEditTherapyHandler: Function;
}

interface RespiratorySidebarState {
  isCompleted: boolean;
}

class RespiratorySidebar extends Component<RespiratorySidebarProps, RespiratorySidebarState> {
  static displayName = 'RespiratorySidebar';

  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false
    };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<RespiratorySidebarProps>) {
    if (prevProps.therapy !== this.props.therapy) {
      this.props.initState(this.buildFormFields());
      this.setState({
        isCompleted: false
      });
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      { name: FormField.FUNCTIONAL_ASSESSMENT, label: FormFieldLabel.FUNCTIONAL_ASSESSMENT },
      { name: FormField.OXYGEN_SATURATION_ROOM_AIR, label: FormFieldLabel.OXYGEN_SATURATION_ROOM_AIR },
      { name: FormField.OXYGEN_SATURATION_OXYGEN_SUPP, label: FormFieldLabel.OXYGEN_SATURATION_OXYGEN_SUPP },
      { name: FormField.OXYGEN_THERAPY_DETAIL, label: FormFieldLabel.OXYGEN_THERAPY_DETAIL },
      { name: FormField.ANTERIAL_BLOOD_DASES, label: FormFieldLabel.ANTERIAL_BLOOD_DASES },
      { name: FormField.AEROSOL_TREATMENT, label: FormFieldLabel.AEROSOL_TREATMENT },
      { name: FormField.BILEVEL_POSITIVE_AIRWAY_PRESSURE, label: FormFieldLabel.BILEVEL_POSITIVE_AIRWAY_PRESSURE },
      { name: FormField.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE, label: FormFieldLabel.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE },
      { name: FormField.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE, label: FormFieldLabel.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE },
      { name: FormField.INCENTIVE_SPIROMETER, label: FormFieldLabel.INCENTIVE_SPIROMETER },
      { name: FormField.INDUCED_SPUTUM, label: FormFieldLabel.INDUCED_SPUTUM },
      { name: FormField.INHALER, label: FormFieldLabel.INHALER },
      { name: FormField.POSITIVE_EXPIRATORY_PRESSURE_THERAPY, label: FormFieldLabel.POSITIVE_EXPIRATORY_PRESSURE_THERAPY },
      { name: FormField.FUNCTION_TEST, label: FormFieldLabel.FUNCTION_TEST },
      { name: FormField.RESPIRATORY_THERAPIST_NAME, label: FormFieldLabel.RESPIRATORY_THERAPIST_NAME },
      { name: FormField.RESPIRATORY_THERAPIST_SIGNATURE, label: FormFieldLabel.RESPIRATORY_THERAPIST_SIGNATURE }
    ];

    const textAreas = [
      { name: FormField.SUCTIONING, label: FormFieldLabel.SUCTIONING },
      { name: FormField.VENTILATOR, label: FormFieldLabel.VENTILATOR },
      { name: FormField.TREATMENT, label: FormFieldLabel.TREATMENT },
      { name: FormField.PATIENT_FAMILY_TEACHING, label: FormFieldLabel.PATIENT_FAMILY_TEACHING }
    ];

    dataMap.set(FormField.INTERVENTION_DATE, createFormField({ name: FormField.INTERVENTION_DATE, type: FormFieldInputType.DATE, label: FormFieldLabel.DATE_LABEL }));
    dataMap.set(FormField.INTERVENTION_TIME, createFormField({ name: FormField.INTERVENTION_TIME, type: FormFieldInputType.TIME, label: FormFieldLabel.TIME }));

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    textAreas.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_AREA })));
    return dataMap;
  };

  buildUpdatedFragment = () => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord } = chartService;
    return {
      [FormField.FUNCTIONAL_ASSESSMENT]: buildPatientRecord(formFieldMap, FormField.FUNCTIONAL_ASSESSMENT),
      [FormField.OXYGEN_SATURATION_ROOM_AIR]: buildPatientRecord(formFieldMap, FormField.OXYGEN_SATURATION_ROOM_AIR),
      [FormField.OXYGEN_SATURATION_OXYGEN_SUPP]: buildPatientRecord(formFieldMap, FormField.OXYGEN_SATURATION_OXYGEN_SUPP),
      [FormField.OXYGEN_THERAPY_DETAIL]: buildPatientRecord(formFieldMap, FormField.OXYGEN_THERAPY_DETAIL),
      [FormField.ANTERIAL_BLOOD_DASES]: buildPatientRecord(formFieldMap, FormField.ANTERIAL_BLOOD_DASES),
      [FormField.AEROSOL_TREATMENT]: buildPatientRecord(formFieldMap, FormField.AEROSOL_TREATMENT),
      [FormField.BILEVEL_POSITIVE_AIRWAY_PRESSURE]: buildPatientRecord(formFieldMap, FormField.BILEVEL_POSITIVE_AIRWAY_PRESSURE),
      [FormField.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE]: buildPatientRecord(formFieldMap, FormField.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE),
      [FormField.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE]: buildPatientRecord(formFieldMap, FormField.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE),
      [FormField.INCENTIVE_SPIROMETER]: buildPatientRecord(formFieldMap, FormField.INCENTIVE_SPIROMETER),
      [FormField.INDUCED_SPUTUM]: buildPatientRecord(formFieldMap, FormField.INDUCED_SPUTUM),
      [FormField.INHALER]: buildPatientRecord(formFieldMap, FormField.INHALER),
      [FormField.POSITIVE_EXPIRATORY_PRESSURE_THERAPY]: buildPatientRecord(formFieldMap, FormField.POSITIVE_EXPIRATORY_PRESSURE_THERAPY),
      [FormField.FUNCTION_TEST]: buildPatientRecord(formFieldMap, FormField.FUNCTION_TEST),
      [FormField.SUCTIONING]: buildPatientRecord(formFieldMap, FormField.SUCTIONING),
      [FormField.VENTILATOR]: buildPatientRecord(formFieldMap, FormField.VENTILATOR),
      [FormField.TREATMENT]: buildPatientRecord(formFieldMap, FormField.TREATMENT),
      [FormField.PATIENT_FAMILY_TEACHING]: buildPatientRecord(formFieldMap, FormField.PATIENT_FAMILY_TEACHING),
      [FormField.RESPIRATORY_THERAPIST_NAME]: buildPatientRecord(formFieldMap, FormField.RESPIRATORY_THERAPIST_NAME),
      [FormField.RESPIRATORY_THERAPIST_SIGNATURE]: buildPatientRecord(formFieldMap, FormField.RESPIRATORY_THERAPIST_SIGNATURE),
      [FormField.INTERVENTION_DATE]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_DATE),
      [FormField.INTERVENTION_TIME]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_TIME)
    };
  };

  handleSaveClick = () => {
    const { isCompleted } = this.state;
    const updatedTherapy = produce(this.props.therapy, (draft) => {
      const { chartData } = draft;
      const oldInterventionList = chartData[FormField.INTERVENTION_LIST] || [];
      draft.chartData = { ...chartData, [FormField.INTERVENTION_LIST]: [...oldInterventionList, this.buildUpdatedFragment()], isCompleted };
      draft.updatedAt = moment().toISOString();
    });
    this.props.handleSaveClick([updatedTherapy], { afterSave: this.props.onEditTherapyHandler });
  };

  onCheckCompleted = (event) => {
    const isCompleted = event.target.checked;
    this.setState({
      isCompleted
    });
  };

  render() {
    const { therapy, onCloseClick, formFieldMap, formSubmittedCount, sidebarProps } = this.props;
    const { isCompleted } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => {
        this.setState({
          isCompleted: false
        });
        this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' });
      }
    };
    const { chartHistory: intervention } = handleDisplayIntervention(therapy);
    const orderTypeValue = therapy?.chartData[FormField.ORDER_TYPE]?.value;
    const isTherapyCompleted = therapy?.chartData[FormField.IS_COMPLETED];
    const viewProps: RespiratorySidebarViewProps = {
      isOpen: !!therapy && orderTypeValue === OrderTypeValue.RESPIRATORY,
      intervention,
      onCloseClick,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      isCompleted,
      isTherapyCompleted,
      onCheckCompleted: this.onCheckCompleted,
      sidebarProps
    };
    return <RespiratorySidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state)
});

export { RespiratorySidebar as BaseRespiratorySidebar };
export default compose(connect(mapStateToProps), withChartLogic)(RespiratorySidebar);

export enum SectionTitle {
  GENITOURINARY_INTERVENTIONS = 'Genitourinary Interventions',
  URINARY_SYSTEM_CARE = 'Urinary System Care',
  DRAIN_TUBE_CARE = 'Drain or Tube Care',
  PATIENT_RESPONSE = 'Patient Response',
  GENITOURINARY_CARE_NOTES = 'Genitourinary Care Notes'
}

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  URINARY_SYSTEM_CARE = 'urinarySystemCare',
  DRAIN_TUBE_CARE = 'drainOrTubeCare',
  PATIENT_RESPONSE = 'patientResponse',
  GENITOURINARY_CARE_NOTES = 'genitourinaryCareNotes'
}

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { TEXT_AREAS } from './ReviewOfSystems';

export interface ReviewOfSystemsEditViewProps {
  selectedRecord: ChartFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const ReviewOfSystemsEditView = (props: ReviewOfSystemsEditViewProps) => {
  const formFieldTextAreas = TEXT_AREAS.map(({ name }) => (
    <ELSFlexItem w="1o2" md="1o1" key={name}>
      <ErrorFormField formField={props.formFieldMap.get(name)} formSubmittedCount={props.formSubmittedCount} />
    </ELSFlexItem>
  ));
  return (
    <>
      <Box mt2>
        <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
          {formFieldTextAreas}
        </ELSFlex>
      </Box>
      <ChartPermission fragmentType={props.selectedRecord?.fragmentType}>
        {({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}
      </ChartPermission>
    </>
  );
};
ReviewOfSystemsEditView.displayName = 'ReviewOfSystemsEditView';
export default ReviewOfSystemsEditView;

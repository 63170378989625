import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, Section } from './constants';
import HamiltonAnxietyRatingScaleView, { HamiltonAnxietyRatingScaleViewProps } from './HamiltonAnxietyRatingScaleView';

class HamiltonAnxietyRatingScale extends Component<ChartComponentProps> {
  static displayName = 'HamiltonAnxietyRatingScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.HAMILTON_ANXIETY_ASSESSMENT_SCALE,
      createFormField({ name: FormField.HAMILTON_ANXIETY_ASSESSMENT_SCALE, type: FormFieldInputType.SCALE, errorLabel: FormFieldLabel.HAMILTON_ANXIETY_SCALE })
    );

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.ANXIOUS_MOOD, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.TENSION, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.FEARS, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.INSOMNIA, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.INTELLECTUAL, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.DEPRESSED_MOOD, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.SOMATIC_MUSCULAR, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.SOMATIC_SENSORY, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.CARDIOVASCULAR_SYMPTOMS, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.RESPIRATORY_SYMPTOMS, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.GASTROINTESTINAL_SYMPTOMS, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.GENITOURINARY_SYMPTOMS, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.AUTONOMIC_SYMPTOMS, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.BEHAVIOR_AT_INTERVIEW, ScaleDirection.HORIZONTAL);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.HAMILTON_ANXIETY_RATING_SCALE,
      records: [
        {
          sectionTitle: Section.HAMILTON_ANXIETY_RATING_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.HAMILTON_ANXIETY_ASSESSMENT_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.HAMILTON_ANXIETY_ASSESSMENT_SCALE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.HAMILTON_ANXIETY_ASSESSMENT_SCALE))?.score?.value,
              linkedFormFieldIds: [
                FormField.ANXIOUS_MOOD,
                FormField.TENSION,
                FormField.FEARS,
                FormField.INSOMNIA,
                FormField.INTELLECTUAL,
                FormField.DEPRESSED_MOOD,
                FormField.SOMATIC_MUSCULAR,
                FormField.SOMATIC_SENSORY,
                FormField.CARDIOVASCULAR_SYMPTOMS,
                FormField.RESPIRATORY_SYMPTOMS,
                FormField.GASTROINTESTINAL_SYMPTOMS,
                FormField.GENITOURINARY_SYMPTOMS,
                FormField.AUTONOMIC_SYMPTOMS,
                FormField.BEHAVIOR_AT_INTERVIEW
              ]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: HamiltonAnxietyRatingScaleViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };

    return <HamiltonAnxietyRatingScaleView {...viewProps} />;
  }
}

export { HamiltonAnxietyRatingScale as BaseHamiltonAnxietyRatingScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(HamiltonAnxietyRatingScale);

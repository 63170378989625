export interface TextBlockProps {
  heading?: string;
  content: string;
  className?: string;
}

const TextBlock = (props: TextBlockProps) => {
  if (!props.content) {
    return <></>;
  }

  return (
    <div className={props.className}>
      {props.heading && <div className="u-els-bold">{props.heading}</div>}
      <p>{props.content}</p>
    </div>
  );
};

TextBlock.displayName = 'TextBlock';
export default TextBlock;

import { Component } from 'react';
import { connect } from 'react-redux';
import { AssignmentType } from 'models/enum';
import { studentSelectors } from 'redux/ducks/student';
import { ButtonLinkIcon, CaseStudyPanel, EmptyEHRInfo } from 'components/common';

export interface AssignmentInformationProps {
  assignmentType: AssignmentType;
  toggleFlyout: (content: JSX.Element | null) => void;
}

class AssignmentInformation extends Component<AssignmentInformationProps> {
  static displayName = 'AssignmentInformation';

  render() {
    const flyoutContent: JSX.Element = this.props.assignmentType === AssignmentType.CASE_STUDY ? <CaseStudyPanel /> : <EmptyEHRInfo />;
    return (
      <div>
        <ButtonLinkIcon iconPrefix="gizmo" iconName="information" text="Assignment Information" onClick={() => this.props.toggleFlyout(flyoutContent)} preventAutoPending />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assignmentType: studentSelectors.getAssignmentType(state)
});

export { AssignmentInformation as BaseAssignmentInformation };
export default connect(mapStateToProps)(AssignmentInformation);

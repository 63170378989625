import cx from 'classnames';
import { MedicationFragment } from 'models/api-response';
import { AccordionBadge, Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import DeleteTracker from './DeleteTracker';
import MultiLineWithEmpty from '../shared/MultiLineWithEmpty';

interface SectionMedicationProps {
  isFullReport: boolean;
  medicationRecords: MedicationFragment[];
}

const SectionMedication = (props: SectionMedicationProps) => {
  return (
    <Box mb4>
      <Box mb2>
        <h2>
          {'Medications '}
          <span className="c-els-badge c-els-badge--size-intro">{props.medicationRecords.length}</span>
        </h2>
      </Box>
      <div className="report__record">
        {props.medicationRecords.map((item) => (
          <div className="record-item" key={item.fragmentId}>
            <Box mb2>
              <h3 className={cx('u-els-display-inline-block u-els-margin-right-2x', { 'row--deleted': !item.active })}>
                {item.chartData.medicationOther || item.chartData.medication}
              </h3>
              {item.chartData.dateOrdered && (
                <AccordionBadge>
                  <span>{item.chartData.dateOrdered}</span>
                </AccordionBadge>
              )}
              {!item.active && <DeleteTracker className="u-els-margin-left-2x" firstName={item.modifier?.firstName} lastName={item.modifier?.lastName} dateTime={item.updatedAt} />}
            </Box>
            <ELSFlex className="u-els-margin-bottom">
              <ELSFlexItem w="1o4">
                <h4>Route</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.routeOther || item.chartData.route} />
              </ELSFlexItem>
              <ELSFlexItem w="1o4" className="u-els-padding-left-2x">
                <h4>Frequency</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.frequency} />
              </ELSFlexItem>
              <ELSFlexItem w="1o4">
                <h4>Classification</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.classificationOther || item.chartData.classification} />
              </ELSFlexItem>
              <ELSFlexItem w="1o4" className="u-els-padding-left-2x">
                <h4>Dose</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.dose} />
              </ELSFlexItem>
            </ELSFlex>
            {!props.isFullReport && (
              <Box mb>
                <h4>Comments and Additional Medication Info</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.commentsAndMedication} />
              </Box>
            )}
            {props.isFullReport && (
              <>
                <ELSFlex className="u-els-margin-bottom">
                  <ELSFlexItem w="1o2" className="u-els-padding-right">
                    <h4>Comments and Additional Medication Info</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.commentsAndMedication} />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2">
                    <h4>Therapeutic Effect</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.therapeuticEffect} />
                  </ELSFlexItem>
                </ELSFlex>
                <ELSFlex className="u-els-margin-bottom">
                  <ELSFlexItem w="1o2" className="u-els-padding-right">
                    <h4>Action</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.action} />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2">
                    <h4>Contraindications</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.contraindications} />
                  </ELSFlexItem>
                </ELSFlex>
                <ELSFlex className="u-els-margin-bottom">
                  <ELSFlexItem w="1o2" className="u-els-padding-right">
                    <h4>Side Effects or Adverse Reactions</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.effectsOrReactions} />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2">
                    <h4>Life Threatening Considerations</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.lifeThreateningConsiderations} />
                  </ELSFlexItem>
                </ELSFlex>
                <ELSFlex>
                  <ELSFlexItem w="1o2" className="u-els-padding-right">
                    <h4>Recommended Dose Ranges</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.recommendedDoseRanges} />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2">
                    <h4>Nursing Interventions</h4>
                    <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item.chartData.nursingInterventions} />
                  </ELSFlexItem>
                </ELSFlex>
              </>
            )}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default SectionMedication;

import cx from 'classnames';
import { Component } from 'react';
import { ChartValue } from 'constants/app.constant';
import { ELSButton, ELSIcon } from 'components/common/els';
import './label.field.scss';
import MultilineTooltip from '../multiline-tooltip/MultilineTooltip';

interface LabelFieldState {
  isShowTooltip: boolean;
}

interface LabelFieldProps {
  label?: string;
  labelValue?: string;
  color?: string;
  underline?: boolean;
  bold?: boolean;
  customClassLabel?: string[];
  labelFontSize?: string;
  labelType?: string;
  contents?: string[];
  onNavigate?: Function;
}

const defaultProps: LabelFieldProps = {
  color: 'n9',
  labelFontSize: 'meta'
};

class LabelField extends Component<LabelFieldProps, LabelFieldState> {
  static displayName = 'LabelField';
  static defaultProps = defaultProps;

  constructor(props) {
    super(props);
    this.state = {
      isShowTooltip: false
    };
  }

  handleOpenTooltipClick = () => this.setState({ isShowTooltip: true });

  handleCloseTooltipClick = () => this.setState({ isShowTooltip: false });

  render() {
    const noValue = [ChartValue.VALUE_NA, ChartValue.VALUE_NKA, ChartValue.VALUE_NONE];
    return (
      <div className="label-field">
        {this.props.labelType !== 'link' && (
          <div className={cx('u-els-color-n7', `u-els-font-size-${this.props.labelFontSize}`, this.props.customClassLabel)}>{this.props.label}</div>
        )}
        {this.props.labelType === 'link' && <ELSButton className="u-els-font-size-meta" type={this.props.labelType} text={this.props.label} onClick={this.props.onNavigate} />}
        <div
          className={cx(`u-els-color-${this.props.color}`, {
            'label-field--underline': this.props.underline,
            'u-els-font-family-bold': this.props.bold
          })}
        >
          {this.props.labelValue}
          {this.props.labelType === 'link' && !noValue.includes(this.props.labelValue) && (
            <ELSIcon name="chevron-down" size="1x" color="secondary" onClick={this.handleOpenTooltipClick} />
          )}
        </div>
        {this.state.isShowTooltip && !noValue.includes(this.props.labelValue) && <MultilineTooltip contents={this.props.contents} onClose={this.handleCloseTooltipClick} />}
      </div>
    );
  }
}

export default LabelField;

import { connect } from 'react-redux';
import { AssignmentType } from 'models/enum';
import { studentSelectors } from 'redux/ducks/student';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';

export interface NoticeProps {
  assignmentType: string;
}

const Notice = (props: NoticeProps) =>
  props.assignmentType === AssignmentType.EMPTY_EHR && (
    <Box p className="c-els-card c-els-card--border-primary">
      <ELSFlex>
        <ELSFlexItem className="u-els-margin-right">
          <ELSIcon prefix="gizmo" name="information" />
        </ELSFlexItem>
        <ELSFlexItem grow>
          <p>
            <strong>Notice: </strong>
            Orders entered on this screen are for practice only. The actual entry of orders for the patient in your care must be performed according to the policy and procedures
            dictated by your clinical site.
          </p>
        </ELSFlexItem>
      </ELSFlex>
    </Box>
  );

const mapStateToProps = (state) => ({
  assignmentType: studentSelectors.getAssignmentType(state)
});

Notice.displayName = 'Notice';
export { Notice as BaseNotice };
export default connect(mapStateToProps)(Notice);

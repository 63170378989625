/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Component } from 'react';
import TimeField from 'react-simple-timefield';
import { ELSModalServiceType } from 'models/els';
import { appHelper } from 'helpers';
import { Box, withFormUtilities } from 'components/common';
import { ELSButton, ELSFlex, ELSTextBox } from 'components/common/els';
import './case.study.scss';

interface CaseStudyProps {
  modalService: ELSModalServiceType;
}

interface Phase {
  phaseId: string;
  chartingTime: string;
  day: number;
  daysToLastPhase: number;
}

interface CaseStudyState {
  diagnosis: string;
  chapter: string;
  concept: string;
  totalTimeLimit: string;
  learningObjective: string;
  phaseTimes: Phase[];
  [x: string]: string | Phase[];
}

const mockPhaseId = ['719225', '719229', '719230', '719235'];

class CaseStudy extends Component<CaseStudyProps, CaseStudyState> {
  static displayName = 'CaseStudy';

  constructor(props) {
    super(props);
    this.state = {
      diagnosis: '',
      chapter: '',
      concept: '',
      totalTimeLimit: '',
      learningObjective: '',
      phaseTimes: [
        {
          phaseId: mockPhaseId[0],
          chartingTime: '00:00',
          day: null,
          daysToLastPhase: null
        },
        {
          phaseId: mockPhaseId[1],
          chartingTime: '00:00',
          day: null,
          daysToLastPhase: null
        },
        {
          phaseId: mockPhaseId[2],
          chartingTime: '00:00',
          day: null,
          daysToLastPhase: null
        },
        {
          phaseId: mockPhaseId[3],
          chartingTime: '00:00',
          day: null,
          daysToLastPhase: null
        }
      ]
    };
  }

  handleTextChange = (fieldName: string, value = '') => this.setState({ [fieldName]: value });
  handleTotalTimeChange = (fieldName: string, value = '', prevValue = '') => {
    if (+value) {
      this.handleTextChange(fieldName, value);
    } else {
      this.handleTextChange(fieldName, prevValue);
    }
  };
  handleTimeChange = (phaseId: string, value = '00:00') => {
    const { phaseTimes } = this.state;
    const newPhaseTimes = produce(phaseTimes, (draft) => {
      draft.forEach((item) => {
        if (item.phaseId === phaseId) {
          item.chartingTime = value;
        }
      });
    });

    this.setState({ phaseTimes: newPhaseTimes });
  };

  handleDayChange = (phaseId: string, value = '') => {
    if (Number.isNaN(Number(value))) {
      return;
    }

    const { phaseTimes } = this.state;
    let newPhaseTimes = produce(phaseTimes, (draft) => {
      draft.forEach((item) => {
        if (item.phaseId === phaseId) {
          item.day = Number(value);
        }
      });
    });

    const lastPhase = newPhaseTimes.filter((item) => item.day).pop();

    newPhaseTimes = produce(newPhaseTimes, (draft) => {
      draft.forEach((item) => {
        item.daysToLastPhase = lastPhase.day - item.day;
      });
    });

    this.setState({ phaseTimes: newPhaseTimes });
  };

  handleExportClick = () => {
    const { modalService } = this.props;
    const clearPhaseTimes = this.state.phaseTimes
      .filter((item) => item.chartingTime && item.day)
      .map((item) => ({
        phaseId: item.phaseId,
        chartingTime: item.chartingTime,
        daysToLastPhase: item.daysToLastPhase
      }));
    const content = {
      CaseStudyMeta: { ...this.state, phaseTimes: clearPhaseTimes }
    };
    const contentJson = JSON.stringify(content);
    const displayContentJson = JSON.stringify(content, null, 2);

    modalService.openModal({
      modalId: 'authoringDataModal',
      color: 'primary',
      content: (
        <div>
          <h3 className="u-els-color-primary">Authoring Data</h3>
          <Box mt className="c-els-card c-els-card--border-secondary">
            <pre className="u-white-space--pre-wrap">{displayContentJson}</pre>
          </Box>
          <Box mt2>
            <ELSButton type="primary" text="Copy to Clipboard" onClick={() => appHelper.copyToClipboard(contentJson)} />
          </Box>
        </div>
      )
    });
  };

  render() {
    return (
      <ELSFlex center>
        <Box className="case-study">
          <h2 className="u-els-color-primary">Case Study Authoring</h2>
          <Box mt2>
            <Box mt>
              <span>Diagnosis</span>
              <ELSTextBox value={this.state.diagnosis} changeHandler={({ target }) => this.handleTextChange('diagnosis', target?.value)} />
            </Box>
            <Box mt>
              <span>Chapter</span>
              <ELSTextBox value={this.state.chapter} changeHandler={({ target }) => this.handleTextChange('chapter', target?.value)} />
            </Box>
            <Box mt>
              <span>Concept</span>
              <ELSTextBox value={this.state.concept} changeHandler={({ target }) => this.handleTextChange('concept', target?.value)} />
            </Box>
            <Box mt>
              <span>Total time limit</span>
              <ELSTextBox
                value={this.state.totalTimeLimit}
                changeHandler={({ target }) => this.handleTotalTimeChange('totalTimeLimit', target?.value, this.state.totalTimeLimit)}
                placeholder="Enter number of total minutes"
              />
            </Box>
            <Box mt>
              <span>Learning objective</span>
              <ELSTextBox value={this.state.learningObjective} changeHandler={({ target }) => this.handleTextChange('learningObjective', target?.value)} />
            </Box>
            <Box mt>
              <span>Phase times</span>
              {this.state.phaseTimes.map((item, index) => (
                <Box key={`${item.phaseId}`} ml mt>
                  <span>{`Phase ${index + 1}:`}</span>
                  <TimeField className="datetime__time" value={item.chartingTime} onChange={(event, time) => this.handleTimeChange(item.phaseId, time)} />
                  <span className="u-els-margin-left">Day</span>
                  <span className="datetime__day">
                    <ELSTextBox value={item.day || ''} changeHandler={({ target }) => this.handleDayChange(item.phaseId, target.value)} />
                  </span>
                </Box>
              ))}
            </Box>
            <Box mt2>
              <ELSButton className="sng-button" onClick={this.handleExportClick}>
                Export
              </ELSButton>
            </Box>
          </Box>
        </Box>
      </ELSFlex>
    );
  }
}

export { CaseStudy as BaseCaseStudy };
export default withFormUtilities(CaseStudy);

import { LanguageKeys } from 'lang';
import { NutritionOrderEntryRecord } from 'models/api-response';
import { Box, ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import Notice from 'components/features/chart/order-entry/shared/Notice';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import NutritionOrderTable from './NutritionOrderTable';
import './nutrition.scss';
import { FormField as CommonFormField } from '../shared/constants';

export interface NutritionOrderEntryViewProps extends ChartViewProps {
  isDietTypeHide: boolean;
  isFluidRestrictionHide: boolean;
  isCalorieCountHide: boolean;
  totalHourValue: number;
  chartHistory: NutritionOrderEntryRecord[];
  deleteHistory: Function;
}

const NutritionOrderEntryView = (props: NutritionOrderEntryViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, isDietTypeHide, isFluidRestrictionHide, isCalorieCountHide, totalHourValue, chartHistory, deleteHistory } = props;
  const inputFluidRestrictionClassName = 'nutrition-order-entry__input-fluid-restriction';
  const customBottomMarginFormField = {
    useDefaultMargin: false,
    className: 'u-els-margin-bottom-1x1o2'
  };

  return (
    <div className="nutrition-order-entry">
      <h1>{LanguageKeys.NUTRITION_ORDER.NUTRITION}</h1>
      <Box mb2 mt>
        <Notice />
        <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      </Box>
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o3" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">{LanguageKeys.NUTRITION_ORDER.CHART_INPUTS}</h2>
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.NUTRITION_TYPE)} formSubmittedCount={formSubmittedCount} />

            <h3 className="u-els-margin-bottom u-els-margin-top-2x">{LanguageKeys.NUTRITION_ORDER.ORDER_START}</h3>
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.DATE_FIELD)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.TIME_FIELD)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
            {!isDietTypeHide && (
              <>
                <h3 className="u-els-margin-bottom u-els-margin-top-2x">{LanguageKeys.NUTRITION_ORDER.DIET}</h3>
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.DIET_TYPE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.FORMULA)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.CONSISTENCY)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.TUBE_FEEDING)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.NOTHING_BY_MOUNT)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.SPECIAL_DIET)} formSubmittedCount={formSubmittedCount} />
              </>
            )}

            {!isFluidRestrictionHide && (
              <>
                <h3 className="u-els-margin-bottom u-els-margin-top-2x">{LanguageKeys.NUTRITION_ORDER.FLUID_RESTRICTION_PARAMETERS}</h3>
                <ELSFlex left middle>
                  <ErrorFormField
                    useDefaultMargin={false}
                    className={`u-els-margin-bottom-1x1o2 ${inputFluidRestrictionClassName}`}
                    formField={formFieldMap.get(FormField.DAY_SHIFT)}
                    formSubmittedCount={formSubmittedCount}
                  />
                  <span className="u-els-margin-left-1o2">{LanguageKeys.NUTRITION_ORDER.ML}</span>
                </ELSFlex>
                <ELSFlex left middle>
                  <ErrorFormField
                    useDefaultMargin={false}
                    className={`u-els-margin-bottom-1x1o2 ${inputFluidRestrictionClassName}`}
                    formField={formFieldMap.get(FormField.EVENING_SHIFT)}
                    formSubmittedCount={formSubmittedCount}
                  />
                  <span className="u-els-margin-left-1o2">{LanguageKeys.NUTRITION_ORDER.ML}</span>
                </ELSFlex>
                <ELSFlex left middle>
                  <ErrorFormField
                    useDefaultMargin={false}
                    className={`u-els-margin-bottom-1x1o2 ${inputFluidRestrictionClassName}`}
                    formField={formFieldMap.get(FormField.NIGHT_SHIFT)}
                    formSubmittedCount={formSubmittedCount}
                  />
                  <span className="u-els-margin-left-1o2">{LanguageKeys.NUTRITION_ORDER.ML}</span>
                </ELSFlex>
                <ELSFlex left middle>
                  <ErrorFormField
                    useDefaultMargin={false}
                    className={`u-els-margin-bottom-1x1o2 ${inputFluidRestrictionClassName}`}
                    formField={formFieldMap.get(FormField.TOTAL_HOUR)}
                    formSubmittedCount={formSubmittedCount}
                    inputProps={{
                      value: totalHourValue || '',
                      isDisabled: true
                    }}
                  />
                  <span className="u-els-margin-left-1o2">{LanguageKeys.NUTRITION_ORDER.ML}</span>
                </ELSFlex>
              </>
            )}

            {!isCalorieCountHide && (
              <>
                <h3 className="u-els-margin-bottom u-els-margin-top-2x">{LanguageKeys.NUTRITION_ORDER.CALORIE_COUNT}</h3>
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.CALORIE_COUNT)} formSubmittedCount={formSubmittedCount} />
              </>
            )}
          </ELSFlexItem>
          <ELSFlexItem w="2o3" md="1o1" className="u-els-margin-top">
            <h2>{LanguageKeys.NUTRITION_ORDER.ORDERS}</h2>
            <NutritionOrderTable chartHistory={chartHistory} deleteHistory={deleteHistory} chartMetaFormFields={props.formFieldMap} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

NutritionOrderEntryView.displayName = 'NutritionOrderEntryView';
export default NutritionOrderEntryView;

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, sbarReportFormFieldBuilderItems } from './constants';
import SBARReportView, { SBARReportViewProps } from './SBARReportView';
import { Title } from '../shared/constants';

class SBARReport extends Component<ChartComponentProps> {
  static displayName = 'SBARReport';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { locale, intl } = this.props;
    const dataMap = new Map();

    sbarReportFormFieldBuilderItems(locale).forEach(({ name, type, label, isHidden }) => {
      if (!isHidden) {
        dataMap.set(name, chartHelper.createFormField({ name, type, label: label && intl.formatMessage({ id: label }) }));
      }
    });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap, intl } = this.props;
    return [
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.IDENTIFY }),
        fields: [FormField.IDENTIFY_INTRODUCE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.SITUATION }),
        fields: [FormField.SUMMARY_SITUATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.OBSERVATIONS }),
        fields: [FormField.OBSERVATIONS_MOST_RECENT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.BACKGROUND }),
        fields: [FormField.PERTINENT_BACKGROUND_SITUATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.ASSESSMENT }),
        fields: [FormField.ASSESSMENT_SITUATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.RECOMMENDATION }),
        fields: [FormField.REC_REQUEST_FOR_ORDERS],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    const { intl } = this.props;
    const record = {
      chartTitle: Title.SPECIAL_CHARTS,
      fragmentTitle: intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.SBAR_REPORT }),
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  render() {
    const { enableDisplayRecordsButton, hasUnsavedChanges, handleDiscardClick, displayAuthoringData, formFieldMap, formSubmittedCount, intl, locale } = this.props;

    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: SBARReportViewProps = {
      chartActionsProps,
      chartMetaFormFields: formFieldMap,
      formSubmittedCount,
      intl,
      locale
    };
    return <SBARReportView {...viewProps} />;
  }
}

export { SBARReport as BaseSBARReport };
export default compose(withSavedPatientChartsPage, withChartLogic)(SBARReport);

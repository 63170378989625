import { LanguageKeys } from 'lang';
import { ChartTable } from 'models/ui';
import { ChartAmountUnit, NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartHistory, ErrorCard, ErrorFormField, InfoText, RelatedCharts, SectionDivider, TableHeading } from 'components/common';
import { ELSButton, ELSDataTable, ELSFlex, ELSFlexItem } from 'components/common/els';
import { ButtonLabel, Title } from 'components/features/chart/system-assessment/shared/constants';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import './cardiovascular.assessment.scss';

export interface CardiovascularAssessmentViewProps extends ChartViewProps {
  addLocation: Function;
  addLocationEnabled: boolean;
  clearLocations: Function;
  clearLocationsEnabled: boolean;
  deletePulseLocation: Function;
  pulseLocationTableData: ChartTable;
}

const INTERPRETATION_DATA = [
  { value: 'Above 0.90', description: 'Normal' },
  { value: '0.71-0.90', description: 'Mild obstruction' },
  { value: '0.41-0.70', description: 'Moderate obstruction' },
  { value: '0.00-0.40', description: 'Severe obstruction' }
];

const CardiovascularAssessmentView = (props: CardiovascularAssessmentViewProps) => {
  const {
    addLocation,
    addLocationEnabled,
    chartActionsProps,
    clearLocations,
    clearLocationsEnabled,
    deletePulseLocation,
    formFieldMap,
    formSubmittedCount,
    pulseLocationTableData,
    resetAll,
    intl
  } = props;
  const pulsesLocationField = formFieldMap?.get(FormField.PULSES_LOCATION);
  const selectedLocationItem = pulsesLocationField?.chartContent?.find((item) => item.value === pulsesLocationField.value);

  return (
    <div className="cardiovascular-assessment">
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <h1>{intl.formatMessage({ id: LanguageKeys.CARDIOVASCULAR_ASSESSMENT })}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <Box mt2 className="cardiovascular-assessment__pulses">
        <h2>{intl.formatMessage({ id: LanguageKeys.PULSES })}</h2>
        <ELSFlex className="u-els-margin-top-2x" left wrap>
          <ELSFlexItem w="1o4" md="1o2">
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_APICAL)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          </ELSFlexItem>
          <ELSFlexItem w="1o4" md="1o2">
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_MURMUR_NOTED)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.PULSES_MURMUR_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <SectionDivider />

      <div className="cardiovascular-assessment__carotid-peripheral">
        <h2>{intl.formatMessage({ id: LanguageKeys.CAROTID_PERIPHERAL_PULSES })}</h2>
        <ELSFlex className="u-flex-gutter-2x" left wrap>
          <ELSFlexItem className="u-els-margin-top-1x1o2@tablet" w="6o10" md="1o1">
            <InfoText title={Title.INFO_TEXT_TITLE} text={intl.formatMessage({ id: LanguageKeys.INFO_TEXT_FILL_OUT_DATA })} />
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_LOCATION)} formSubmittedCount={formSubmittedCount} className="location-dropdown" />
            <p>{selectedLocationItem?.label}</p>
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_LOCATION_LEVEL)} formSubmittedCount={formSubmittedCount} className="u-els-margin-bottom-none" />
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_LOCATION_LEVEL_DOPPLER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_LOCATION_CAROTID_MURMUR_NOTED)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_LOCATION_PERIPHERAL_PULSE)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="sng-form-buttons" left wrap>
              <ELSButton type="secondary" text={ButtonLabel.ADD_LOCATION} isDisabled={!addLocationEnabled} onClick={addLocation} />
              <ELSButton type="secondary" text={ButtonLabel.CLEAR} isDisabled={!clearLocationsEnabled} onClick={clearLocations} />
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="4o10" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PULSES_LOCATION_IMG)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex left>
          <ELSFlexItem w="2o3">
            <ChartHistory dataSource={pulseLocationTableData} deleteRecord={deletePulseLocation} showNumEntries={false} />
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <SectionDivider />

      <div className="cardiovascular-assessment__ankle-brachial">
        <h2>{intl.formatMessage({ id: LanguageKeys.ANKLE_BRACHIAL_INDEX_TEST })}</h2>
        <ELSFlex className="u-flex-gutter-2x u-els-margin-top-2x" wrap>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_001)} formSubmittedCount={formSubmittedCount} />
            <span className="unit">{ChartAmountUnit.MM_HG}</span>
            <div className="u-els-margin-top-2x">
              <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_002)} formSubmittedCount={formSubmittedCount} />
              <span className="unit">{ChartAmountUnit.MM_HG}</span>
            </div>
            <div className="u-els-margin-top-2x">
              <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_003)} formSubmittedCount={formSubmittedCount} />
              <span className="unit">{ChartAmountUnit.MM_HG}</span>
            </div>
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <div className="u-els-margin-top-2x@tablet">
              <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_004)} formSubmittedCount={formSubmittedCount} />
              <span className="unit">{ChartAmountUnit.MM_HG}</span>
            </div>
            <div className="u-els-margin-top-2x">
              <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_005)} formSubmittedCount={formSubmittedCount} />
              <span className="unit">{ChartAmountUnit.MM_HG}</span>
            </div>
            <div className="u-els-margin-top-2x">
              <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_006)} formSubmittedCount={formSubmittedCount} />
              <span className="unit">{ChartAmountUnit.MM_HG}</span>
            </div>
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <h3 className="u-els-margin-top-2x@tablet">{intl.formatMessage({ id: LanguageKeys.CALCULATE_ANKLE_BRACHIAL_INDEX_TEST })}</h3>
            <p className="u-els-margin-top-2x">{intl.formatMessage({ id: LanguageKeys.INDEX_TEST_DIRECTIONS })}</p>
            <div className="u-els-margin-top-1o2">
              <ErrorFormField formField={formFieldMap.get(FormField.ANKLE_BRACHIAL_TEST_INDEX_007)} formSubmittedCount={formSubmittedCount} />
              <div className="u-els-margin-top-2x">
                <TableHeading>{intl.formatMessage({ id: LanguageKeys.INTERPRETATION })}</TableHeading>
                <ELSDataTable data={INTERPRETATION_DATA}>
                  <column field="value" />
                  <column field="description" />
                </ELSDataTable>
              </div>
            </div>
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <SectionDivider />

      <div>
        <h2 className="u-els-margin-bottom-2x">{intl.formatMessage({ id: LanguageKeys.PERFUSION_EDEMA })}</h2>
        <ELSFlex className="u-flex-gutter-2x" wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <h3>{intl.formatMessage({ id: LanguageKeys.TISSUE_PERFUSION })}</h3>
            <h4 className="u-els-margin-top-2x u-els-margin-bottom-2x">{intl.formatMessage({ id: LanguageKeys.PERIPHERAL_VASCULAR_GENERAL })}</h4>
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.PERIPHERAL_VASCULAR_MARK_ALL)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.PERIPHERAL_VASCULAR_OTHER)} formSubmittedCount={formSubmittedCount} />

            <h4 className="u-els-margin-top-2x">{intl.formatMessage({ id: LanguageKeys.CAPILLARY_REFILL })}</h4>
            <ELSFlex className="u-flex-gutter-2x u-els-margin-top-2x" wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_LEFT_HAND)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_RIGHT_HAND)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_MUCOUS_MEMBRANES_COLOR)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_LEFT_FOOT)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_RIGHT_FOOT)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_MUCOUS_MEMBRANES_MOISTURE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={formFieldMap.get(FormField.CAPILLARY_REFILL_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h3>{intl.formatMessage({ id: LanguageKeys.EDEMA })}</h3>
            <p className="u-els-margin-top-2x">{intl.formatMessage({ id: LanguageKeys.EDEMA })}</p>
            <ErrorFormField formField={formFieldMap.get(FormField.NO_EDEMA_NOTED)} formSubmittedCount={formSubmittedCount} className="u-els-margin-bottom-none" />
            <ErrorFormField formField={formFieldMap.get(FormField.GENERALIZED_NONPITTING_EDEMA)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.EDEMA_UPPER_EXTREMITIES)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.EDEMA_LOWER_EXTREMITIES)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.EDEMA_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <SectionDivider />

      <div className="cardiovascular-assessment__cardiac-assessment">
        <ELSFlex className="u-flex-gutter-2x" wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <h2 className="u-els-margin-bottom-2x">{intl.formatMessage({ id: LanguageKeys.CARDIAC_ASSESSMENT })}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.NO_CARDIAC_PROBLEMS_NOTED)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.CARDIAC_ASSESSMENT_MARK_ALL)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.CARDIAC_ASSESSMENT_ELECTROCARDIOGRAM)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.CARDIAC_ASSESSMENT_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h2 className="u-els-margin-top-2x@tablet u-els-margin-bottom-2x">{intl.formatMessage({ id: LanguageKeys.TELEMETRY })}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.CONTINUOUS_TELEMETRY)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ELSFlex className="u-flex-gutter-1x" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={formFieldMap.get(FormField.TELEMETRY_UPPER_LIMIT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={formFieldMap.get(FormField.TELEMETRY_LOWER_LIMIT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>

            <h4 className="u-els-margin-bottom-2x">{intl.formatMessage({ id: LanguageKeys.CARDIAC_RHYTHM })}</h4>
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.CARDIAC_RHYTHM_MARK_ALL)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.TELEMETRY_ALARMS_TESTED)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.TELEMETRY_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <SectionDivider />

      <div className="cardiovascular-assessment__cardiacMonitor">
        <ELSFlex className="u-flex-gutter-2x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <h2 className="u-els-margin-bottom-2x">{intl.formatMessage({ id: LanguageKeys.CARDIAC_MONITOR })}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.CARDIAC_MONITOR)} formSubmittedCount={formSubmittedCount} />

            <ELSFlex className="u-flex-gutter-1x" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={formFieldMap.get(FormField.CARDIAC_MONITOR_UPPER_LIMIT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={formFieldMap.get(FormField.CARDIAC_MONITOR_LOWER_LIMIT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={formFieldMap.get(FormField.CARDIAC_MONITOR_ALARMS_TESTED)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          </ELSFlexItem>
        </ELSFlex>
      </div>
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.CARDIOVASCULAR]} />
    </div>
  );
};

CardiovascularAssessmentView.displayName = 'CardiovascularAssessmentView';
export default CardiovascularAssessmentView;

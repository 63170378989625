import { MedicalHistoryFragment } from 'models/api-response';
import { GenderPatientContext } from 'models/enum';
import { PatientContext } from 'models/ui';
import { WOMAN_HEALTH_MIN_AGE_IN_DAYS } from 'constants/app.constant';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import ReadOnlyInput from 'components/features/chart/admission-history/shared/ReadOnlyInput';
import { FormField, FormFieldLabel, SectionTitle } from './constants';

export interface MedicalHistoryReadOnlyProps {
  selectedRecord: MedicalHistoryFragment;
  patientContext: PatientContext;
}
const MedicalHistoryReadOnly = (props: MedicalHistoryReadOnlyProps) => {
  const { patientContext } = props;
  const isWomenHealth = patientContext?.ageInDays >= WOMAN_HEALTH_MIN_AGE_IN_DAYS && patientContext?.gender === GenderPatientContext.FEMALE;
  return (
    <>
      <h2>Medical History</h2>
      <Box className="sng-article">
        {!props.selectedRecord && <h3>Chart Omitted</h3>}
        {props.selectedRecord && (
          <>
            <Box mb2>
              <h3 className="u-els-margin-bottom">{SectionTitle.PATIENT_INFORMATION}</h3>
              <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PATIENT_INFORMATION}
                    formFieldId={FormField.CHIEF_INFORMANT}
                    customTitle={FormFieldLabel.CHIEF_INFORMANT}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PATIENT_INFORMATION}
                    formFieldId={FormField.ALLERGIES}
                    customTitle={FormFieldLabel.ALLERGIES}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PATIENT_INFORMATION}
                    formFieldId={FormField.CHIEF_COMPLAINT}
                    customTitle={FormFieldLabel.CHIEF_COMPLAINT}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PATIENT_INFORMATION}
                    formFieldId={FormField.FAMILY_HISTORY}
                    customTitle={FormFieldLabel.FAMILY_HISTORY}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PATIENT_INFORMATION}
                    formFieldId={FormField.HISTORY_CURRENT_PROBLEM}
                    customTitle={FormFieldLabel.HISTORY_CURRENT_PROBLEM}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box mb2>
              <h3 className="u-els-margin-bottom">{SectionTitle.PAST_MEDICAL_HISTORY}</h3>
              <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.PREVIOUS_ILLNESSES}
                    customTitle={FormFieldLabel.PREVIOUS_ILLNESSES}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.CONTAGIOUS_DISEASES}
                    customTitle={FormFieldLabel.CONTAGIOUS_DISEASES}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.INJURIES_TRAUMA}
                    customTitle={FormFieldLabel.INJURIES_TRAUMA}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.SURGICAL_HISTORY}
                    customTitle={FormFieldLabel.SURGICAL_HISTORY}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.DIETARY_HISTORY}
                    customTitle={FormFieldLabel.DIETARY_HISTORY}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.SOCIAL_HISTORY}
                    customTitle={FormFieldLabel.SOCIAL_HISTORY}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.CURRENT_MEDICATIONS}
                    customTitle={FormFieldLabel.CURRENT_MEDICATIONS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.OTHER}
                    customTitle={FormFieldLabel.OTHER}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PAST_MEDICAL_HISTORY}
                    formFieldId={FormField.BIRTH_HISTORY}
                    customTitle={FormFieldLabel.BIRTH_HISTORY}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box mb2>
              <h3 className="u-els-margin-bottom">{SectionTitle.IMMUNIZATIONS}</h3>
              <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.IMMUNIZATION_STATUS_HEPATITIS_B}
                    customTitle={FormFieldLabel.IMMUNIZATION_STATUS_HEPATITIS_B}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.ROTAVIRUS}
                    customTitle={FormFieldLabel.ROTAVIRUS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES}
                    customTitle={FormFieldLabel.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER}
                    customTitle={FormFieldLabel.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS}
                    customTitle={FormFieldLabel.TETANUS_DIPHTHERIA_PERTUSSIS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B}
                    customTitle={FormFieldLabel.HAEMOPHILUS_INFLUENZAE_TYPE_B}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.INACTIVATED_POLIO_VIRUS}
                    customTitle={FormFieldLabel.INACTIVATED_POLIO_VIRUS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.VARICELLA}
                    customTitle={FormFieldLabel.VARICELLA}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS}
                    customTitle={FormFieldLabel.IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.IMMUNIZATION_STATUS_HEPATITIS_B}
                    customTitle={FormFieldLabel.IMMUNIZATION_STATUS_HEPATITIS_B}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.ZOSTER}
                    customTitle={FormFieldLabel.ZOSTER}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.MEASLES_MUMPS_RUBELLA}
                    customTitle={FormFieldLabel.MEASLES_MUMPS_RUBELLA}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.INFLUENZA}
                    customTitle={FormFieldLabel.INFLUENZA}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.PNEUMOCOCCAL}
                    customTitle={FormFieldLabel.PNEUMOCOCCAL}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.HEPATITIS_A}
                    customTitle={FormFieldLabel.HEPATITIS_A}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.MENINGOCOCCAL}
                    customTitle={FormFieldLabel.MENINGOCOCCAL}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.IMMUNIZATIONS}
                    formFieldId={FormField.OTHER_IMMUNIZATIONS}
                    customTitle={FormFieldLabel.OTHER_IMMUNIZATIONS}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            {isWomenHealth && (
              <>
                <Box mb2>
                  <h3 className="u-els-margin-bottom">{SectionTitle.GYNECOLOGICAL_HISTORY}</h3>
                  <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.GYNECOLOGICAL_HISTORY}
                        formFieldId={FormField.LAST_PAP_SMEAR}
                        customTitle={FormFieldLabel.LAST_PAP_SMEAR}
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.GYNECOLOGICAL_HISTORY}
                        formFieldId={FormField.HISTORY_OF_ABNORMAL_PAP}
                        customTitle={FormFieldLabel.HISTORY_OF_ABNORMAL_PAP}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.GYNECOLOGICAL_HISTORY}
                        formFieldId={FormField.HISTORY_OF_ABNORMAL_PAP_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.GYNECOLOGICAL_HISTORY}
                        formFieldId={FormField.UTERINE_ABNORMALITY_DES}
                        customTitle={FormFieldLabel.UTERINE_ABNORMALITY_DES}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.GYNECOLOGICAL_HISTORY}
                        formFieldId={FormField.UTERINE_ABNORMALITY_DES_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                  </ELSFlex>
                </Box>
                <Box mb2>
                  <h3 className="u-els-margin-bottom">{SectionTitle.MENSTRUAL}</h3>
                  <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.MENSTRUAL}
                        formFieldId={FormField.LAST_MENSTRUAL_PERIOD}
                        customTitle={FormFieldLabel.LAST_MENSTRUAL_PERIOD}
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.MENSTRUAL}
                        formFieldId={FormField.MENARCHE_AGE_OF_ONSET}
                        customTitle={FormFieldLabel.MENARCHE_AGE_OF_ONSET}
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.MENSTRUAL}
                        formFieldId={FormField.CONTRACEPTION_HISTORY}
                        customTitle={FormFieldLabel.CONTRACEPTION_HISTORY}
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.MENSTRUAL}
                        formFieldId={FormField.DATE_OF_CONCEPTION}
                        customTitle={FormFieldLabel.DATE_OF_CONCEPTION}
                      />
                    </ELSFlexItem>
                  </ELSFlex>
                </Box>
                <Box mb2>
                  <h3 className="u-els-margin-bottom">{SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}</h3>
                  <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.GONORRHEA}
                        customTitle={FormFieldLabel.GONORRHEA}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.GONORRHEA_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.CHLAMYDIA}
                        customTitle={FormFieldLabel.CHLAMYDIA}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.CHLAMYDIA_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.CONGENITAL_HELPERS}
                        customTitle={FormFieldLabel.CONGENITAL_HELPERS}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.CONGENITAL_HELPERS_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS}
                        customTitle={FormFieldLabel.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.SYPHILIS}
                        customTitle={FormFieldLabel.SYPHILIS}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.SYPHILIS_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.HUMAN_IMMUNODEFICIENCY_VIRUS}
                        customTitle={FormFieldLabel.HUMAN_IMMUNODEFICIENCY_VIRUS}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.HUMAN_IMMUNODEFICIENCY_VIRUS_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o3" md="1o1">
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B}
                        customTitle={FormFieldLabel.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B}
                      />
                      <ReadOnlyInput
                        chartFragment={props.selectedRecord}
                        sectionTitle={SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}
                        formFieldId={FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B_DESCRIBE}
                        customTitle=""
                      />
                    </ELSFlexItem>
                  </ELSFlex>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

MedicalHistoryReadOnly.displayName = 'MedicalHistoryReadOnly';
export default MedicalHistoryReadOnly;

/* eslint-disable class-methods-use-this */
import produce from 'immer';
import { ValidationActionType } from 'models/enum';
import { ChartMetaFormField, ErrorField } from 'models/ui';
import BaseHandler, { ExecuteParams } from './interface';

export default class RequireHandler implements BaseHandler {
  execute({ field, ruleId, ruleRegistry }: ExecuteParams): ChartMetaFormField {
    const rule = ruleRegistry.get(ruleId);

    return produce(field, (draft) => {
      if (rule.result) {
        draft.isRequired = true;

        const error = {
          message: rule.ruleInfo.message,
          field: rule.ruleInfo.formFieldId,
          priority: 0,
          id: rule.ruleInfo.id,
          type: rule.ruleInfo.validationActionType
        };
        draft.errors.push(error);
      } else {
        const newErrors = draft.errors.filter((item: ErrorField) => item.id !== rule.ruleInfo.id);
        draft.errors = newErrors.length !== draft.errors.length ? newErrors : draft.errors;

        // The field is still required if there are any required errors.
        draft.isRequired = newErrors.some((error) => {
          const errorRule = ruleRegistry.get(error.id).ruleInfo;
          return errorRule.validationActionType === ValidationActionType.REQUIRED;
        });
      }
    });
  }
}

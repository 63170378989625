import React, { Component } from 'react';
import { ChartFieldContentMeta, ChartFieldValidationMeta, PatientContextContent, PatientContextValidation } from './MetadataInput';
import OverrideSideBarView, { OverrideSideBarViewProps } from './OverrideSideBarView';
import { DeleteCell } from '../../common';

export interface OverrideSideBarProps {
  chartFieldContentData: ChartFieldContentMeta[];
  chartFieldValidations: ChartFieldValidationMeta[];
  isContentSideBarOpen: boolean;
  isValidationSideBarOpen: boolean;
  openContentSideBar: Function;
  closeContentSideBar: Function;
  openValidationSideBar: Function;
  closeValidationSideBar: Function;
  selectedContent?: ChartFieldContentMeta;
  selectedValidation?: ChartFieldValidationMeta;
  onContentContextSave: Function;
  onValidationContextSave: Function;
  onContentContextRemoveClick: Function;
  onValidationContextRemoveClick: Function;
}

export interface OverrideSideBarState {
  currentContextContent: PatientContextContent;
  currentContextValidation: PatientContextValidation;
}

class OverrideSideBar extends Component<OverrideSideBarProps, OverrideSideBarState> {
  constructor(props) {
    super(props);

    this.state = {
      currentContextContent: {
        condition: null,
        active: true,
        value: null,
        label: null,
        order: null,
        dataType: null
      },
      currentContextValidation: {
        condition: null,
        minRange: null,
        maxRange: null,
        message: null,
        validationActionType: null,
        validationType: null,
        regex: null
      }
    };
  }
  customRenderCondition = (row) => {
    return row ? <div>{row?.condition}</div> : '';
  };

  customRenderActive = (row) => {
    return row ? <div>{row?.active.toString()}</div> : '';
  };

  customRenderValue = (row) => {
    return row ? <div>{row?.value}</div> : '';
  };

  customRenderLabel = (row) => {
    return row ? <div>{row?.label}</div> : '';
  };

  customRenderOrder = (row) => {
    return row ? <div>{row?.order}</div> : '';
  };

  customRenderDataType = (row) => {
    return row ? <div>{row?.dataType}</div> : '';
  };

  customRenderValidationType = (row) => {
    return row ? <div>{row?.validationType}</div> : '';
  };

  customRenderValidationActionType = (row) => {
    return row ? <div>{row?.validationActionType}</div> : '';
  };

  customRenderMessage = (row) => {
    return row ? <div>{row?.message}</div> : '';
  };

  customRenderMinRange = (row) => {
    return row ? <div>{row?.minRange}</div> : '';
  };

  customRenderMaxRange = (row) => {
    return row ? <div>{row?.maxRange}</div> : '';
  };

  customRenderRegex = (row) => {
    return row ? <div>{row?.regex}</div> : '';
  };

  onContentConditionChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextContent: { ...state.currentContextContent, condition: event } }));
  };

  onContentActiveChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextContent: { ...state.currentContextContent, active: event } }));
  };

  onContentValueChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextContent: { ...state.currentContextContent, value: event } }));
  };

  onContentLabelChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextContent: { ...state.currentContextContent, label: event } }));
  };

  onContentOrderChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextContent: { ...state.currentContextContent, order: event } }));
  };

  onContentDataTypeChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextContent: { ...state.currentContextContent, dataType: event } }));
  };

  onValidationConditionChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, condition: event } }));
  };

  onValidationValidationTypeChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, validationType: event } }));
  };

  onValidationValidationActionTypeChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, validationActionType: event } }));
  };

  onValidationMessageChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, message: event } }));
  };

  onValidationMinRangeChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, minRange: event } }));
  };

  onValidationMaxRangeChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, maxRange: event } }));
  };

  onValidationRegexChange = (eventKey, event) => {
    this.setState((state) => ({ currentContextValidation: { ...state.currentContextValidation, regex: event } }));
  };

  onContentContextSave = () => {
    this.props.onContentContextSave(this.state.currentContextContent);
  };

  onValidationContextSave = () => {
    this.props.onValidationContextSave(this.state.currentContextValidation);
  };

  onContentContextRemoveClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onContentContextRemoveClick(row.condition);
  };

  onValidationContextRemoveClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onValidationContextRemoveClick(row.condition);
  };

  renderContentContextDelete = (row) => {
    return (
      <div>
        <DeleteCell onDeleteClick={(event) => this.onContentContextRemoveClick(event, row)} isActive />
      </div>
    );
  };

  renderValidationContextDelete = (row) => {
    return (
      <div>
        <DeleteCell onDeleteClick={(event) => this.onValidationContextRemoveClick(event, row)} isActive />
      </div>
    );
  };
  render() {
    const viewProps: OverrideSideBarViewProps = {
      ...this.props,
      customRenderCondition: this.customRenderCondition,
      customRenderActive: this.customRenderActive,
      customRenderValue: this.customRenderValue,
      customRenderLabel: this.customRenderLabel,
      customRenderOrder: this.customRenderOrder,
      customRenderDataType: this.customRenderDataType,
      customRenderValidationType: this.customRenderValidationType,
      customRenderValidationActionType: this.customRenderValidationActionType,
      customRenderMessage: this.customRenderMessage,
      customRenderMinRange: this.customRenderMinRange,
      customRenderMaxRange: this.customRenderMaxRange,
      customRenderRegex: this.customRenderRegex,
      selectedContent: this.props.selectedContent,
      selectedValidation: this.props.selectedValidation,
      onContentConditionChange: this.onContentConditionChange,
      onContentActiveChange: this.onContentActiveChange,
      onContentValueChange: this.onContentValueChange,
      onContentLabelChange: this.onContentLabelChange,
      onContentOrderType: this.onContentOrderChange,
      onContentDataType: this.onContentDataTypeChange,
      onValidationConditionChange: this.onValidationConditionChange,
      onValidationValidationTypeChange: this.onValidationValidationTypeChange,
      onValidationValidationActionTypeChange: this.onValidationValidationActionTypeChange,
      onValidationMessageChange: this.onValidationMessageChange,
      onValidationMinRangeChange: this.onValidationMinRangeChange,
      onValidationMaxRangeChange: this.onValidationMaxRangeChange,
      onValidationRegexChange: this.onValidationRegexChange,
      onContentContextSave: this.onContentContextSave,
      onValidationContextSave: this.onValidationContextSave,
      renderContentContextDelete: this.renderContentContextDelete,
      renderValidationContextDelete: this.renderValidationContextDelete
    };
    return (
      <div>
        <OverrideSideBarView {...viewProps} />
      </div>
    );
  }
}

export default OverrideSideBar;

import './laboratory.tooltip.scss';

export interface LaboratoryTooltipProps {
  ranges: string[];
}

const LaboratoryTooltip = (props: LaboratoryTooltipProps) => (
  <div className="laboratory-tooltip">
    Reference Range
    <ul className="laboratory-tooltip__list">
      {props.ranges.map((range) => (
        <li key={range}>{range}</li>
      ))}
    </ul>
  </div>
);

LaboratoryTooltip.displayName = 'LaboratoryTooltip';
export default LaboratoryTooltip;

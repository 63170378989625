import { Box } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';

export interface ConfirmationModalProps {
  header?: string;
  message: string;
  okButtonText?: string;
  cancelButtonText?: string;
  showIcon?: boolean;
  onOkClick: Function;
  onCancelClick?: Function;
}

const ConfirmationModal = (props: ConfirmationModalProps) => (
  <div role="dialog" aria-modal="true" aria-label={props.message}>
    <ELSFlex left>
      {props.showIcon && (
        <ELSFlexItem className="u-els-margin-right">
          <ELSIcon name="alert" prefix="gizmo" size="2x" color="primary" />
        </ELSFlexItem>
      )}
      <ELSFlexItem>
        {props.header && <h3>{props.header}</h3>}
        <p className="u-els-margin-top-1o2 u-white-space--pre-wrap">{props.message}</p>
        <Box mt3>
          <ELSButton text={props.okButtonText || 'OK'} className="sng-button" onClick={props.onOkClick} />
          <ELSButton text={props.cancelButtonText || 'Cancel'} type="secondary" className="u-els-margin-left sng-button" onClick={props.onCancelClick} />
        </Box>
      </ELSFlexItem>
    </ELSFlex>
  </div>
);

ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.UNSAVED_MESSAGE = 'Your data has not yet been saved. Do you want to save changes?';

export default ConfirmationModal;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import EliminationView from './EliminationView';
import { Title } from '../shared/constants';

class Elimination extends Component<ChartComponentProps> {
  static displayName = 'Elimination';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const multiselectDropdowns = [FormField.FUNCTIONAL_ABILITY, FormField.ELIMINATION_NURSING_ACTIONS];
    const textAreas = [FormField.FUNCTIONAL_ABILITY_NOTES, FormField.ELIMINATION_NURSING_ACTIONS_NOTES];

    dataMap.set(FormField.ASSESSMENT_NOT_REQUIRED, createFormField({ name: FormField.ASSESSMENT_NOT_REQUIRED, type: FormFieldInputType.CHECK_BOX }));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));
    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.ELIMINATION, fields: [FormField.ASSESSMENT_NOT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.FUNCTIONAL_ABILITY, fields: [FormField.FUNCTIONAL_ABILITY, FormField.FUNCTIONAL_ABILITY_NOTES], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ELIMINATION_NURSING_ACTIONS,
        fields: [FormField.ELIMINATION_NURSING_ACTIONS, FormField.ELIMINATION_NURSING_ACTIONS_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.BASIC_NURSING_CARE,
      fragmentTitle: SectionTitle.ELIMINATION,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <EliminationView {...viewProps} />;
  }
}

export { Elimination as BaseElimination };
export default compose(withSavedPatientChartsPage, withChartLogic)(Elimination);

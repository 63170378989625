import { Component } from 'react';
import { ELSModalServiceType } from 'models/els';
import { DropDownItem } from 'models/ui';
import { withFormUtilities } from 'components/common';
import { createOrderDropDownItems, dataTypeDropDownItems, dropDownItems, validationActionTypeOptions, validationTypeOptions } from './constants';
import MetadataInputView, { MetadataInputViewProps } from './MetadataInputView';

export interface ChartFieldMeta {
  id: string;
  navigationElement: NavigationElement;
  formFieldId: string;
  isDataPoint: boolean;
}

export interface ChartFieldContentMeta {
  id: string;
  chartField: ChartField;
  version: number;
  active: boolean;
  value: string;
  label: string;
  order: number;
  dataType: string;
  attrOverrides: PatientContextContent[];
}

export interface NavigationElement {
  id: string;
}

export interface ChartField {
  id: string;
}

export interface ChartFieldValidationMeta {
  id: string;
  chartField: ChartField;
  version: number;
  active: string;
  validationType: string;
  validationActionType: string;
  message: string;
  parentValidation: ParentValidationMeta;
  minRange: string;
  maxRange: string;
  regex: string;
  chartFieldContentSelection: ContentSelection;
  attrOverrides: PatientContextValidation[];
}

export interface ParentValidationMeta {
  id: string;
}

export interface ContentSelection {
  id: string;
}

export interface PatientContextContent {
  condition: string;
  active: boolean;
  value: string;
  label: string;
  order: number;
  dataType: string;
}

export interface PatientContextValidation {
  condition: string;
  validationType: string;
  validationActionType: string;
  message: string;
  minRange: string;
  maxRange: string;
  regex: string;
}

export interface MetadataInputProps {
  showConfirmationModal: Function;
  modalService: ELSModalServiceType;
  onSaveChartField: Function;
  onSaveChartContent: Function;
  onSaveChartValidation: Function;
  selectedChartField?: ChartFieldMeta;
  selectedChartContent?: ChartFieldContentMeta;
  selectedValidation?: ChartFieldValidationMeta;
  chartFieldList?: ChartFieldMeta[];
  parentValidationIdList?: ChartFieldValidationMeta[];
  contentSelectionIdList?: ChartFieldContentMeta[];
}

export interface MetadataInputState {
  currentChartField: ChartFieldMeta;
  currentChartFieldContent: ChartFieldContentMeta;
  currentChartFieldValidation: ChartFieldValidationMeta;
}

class MetadataInput extends Component<MetadataInputProps, MetadataInputState> {
  static defaultProps: { dropDownTrue: string; dropDownFalse: string };
  constructor(props) {
    super(props);
    this.state = {
      currentChartField: {
        id: '',
        navigationElement: {
          id: ''
        },
        formFieldId: '',
        isDataPoint: false
      },
      currentChartFieldContent: {
        id: '',
        chartField: {
          id: ''
        },
        version: 0,
        active: true,
        value: null,
        label: null,
        order: 0,
        dataType: null,
        attrOverrides: null
      },
      currentChartFieldValidation: {
        id: '',
        chartField: {
          id: null
        },
        version: 0,
        active: 'true',
        validationType: null,
        validationActionType: null,
        message: null,
        parentValidation: {
          id: null
        },
        minRange: null,
        maxRange: null,
        regex: null,
        chartFieldContentSelection: {
          id: null
        },
        attrOverrides: null
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.selectedChartField !== this.props.selectedChartField) {
      this.setState({ currentChartField: this.props.selectedChartField });
    }
    if (prevProps?.selectedChartContent !== this.props.selectedChartContent) {
      this.setState({ currentChartFieldContent: this.props.selectedChartContent });
    }
    if (prevProps?.selectedValidation !== this.props.selectedValidation) {
      this.setState({ currentChartFieldValidation: this.props.selectedValidation });
    }
  }

  onIdChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartField: { ...state.currentChartField, id: event } }));
  };

  onNavElementChange = (eventKey: string, event: string) => {
    const { currentChartField } = this.state;
    currentChartField.navigationElement.id = event;
    this.setState({ currentChartField });
  };

  onFormFieldChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartField: { ...state.currentChartField, formFieldId: event } }));
  };

  onDataPointChange = (eventKey: string, event: boolean) => {
    this.setState((state) => ({ ...state, currentChartField: { ...state.currentChartField, isDataPoint: event } }));
  };

  onChartFieldContentIdChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, id: event } }));
  };

  onChartFieldIdChange = (eventKey: string, event: string) => {
    const { currentChartFieldContent } = this.state;
    currentChartFieldContent.chartField.id = event;
    this.setState({ currentChartFieldContent });
  };

  onChartFieldVersionChange = (eventKey: string, event: number) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, version: event } }));
  };

  onChartFieldActiveChange = (eventKey: string, event: boolean) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, active: event } }));
  };

  onChartFieldValueChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, value: event } }));
  };

  onChartFieldLabelChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, label: event } }));
  };

  onChartFieldOrderChange = (eventKey: string, event: number) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, order: event } }));
  };

  onChartFieldDataTypeChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldContent: { ...state.currentChartFieldContent, dataType: event } }));
  };

  onChartFieldValidationIdChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, id: event } }));
  };
  onChartFieldIdValidationChange = (eventKey: string, event: string) => {
    const { currentChartFieldValidation } = this.state;
    currentChartFieldValidation.chartField.id = event;
    this.setState({ currentChartFieldValidation });
  };
  onChartFieldValidationVersionChange = (eventKey: string, event: number) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, version: event } }));
  };
  onChartFieldValidationActiveChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, active: event } }));
  };
  onValidationTypeChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, validationType: event } }));
  };
  onValidationActionTypeChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, validationActionType: event } }));
  };
  onMessageChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, message: event } }));
  };
  onParentValidationChange = (eventKey: string, event: string) => {
    const { currentChartFieldValidation } = this.state;
    currentChartFieldValidation.parentValidation.id = event;
    this.setState({ currentChartFieldValidation });
  };
  onMinRangeChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, minRange: event } }));
  };
  onMaxRangeChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, maxRange: event } }));
  };
  onRegexChange = (eventKey: string, event: string) => {
    this.setState((state) => ({ ...state, currentChartFieldValidation: { ...state.currentChartFieldValidation, regex: event } }));
  };

  onContentSelectionChange = (eventKey: string, event: string) => {
    const { currentChartFieldValidation } = this.state;
    currentChartFieldValidation.chartFieldContentSelection.id = event;
    this.setState({ currentChartFieldValidation });
  };

  isChartFieldEmpty = (): boolean => {
    const { currentChartField } = this.state;
    return currentChartField.id === '' || currentChartField.navigationElement.id === '' || currentChartField.formFieldId === '';
  };

  isChartFieldContentEmpty = (): boolean => {
    const { currentChartFieldContent } = this.state;
    return (
      currentChartFieldContent.id === '' ||
      currentChartFieldContent.chartField.id === '' ||
      currentChartFieldContent.value === '' ||
      currentChartFieldContent.label === '' ||
      !Number.isInteger(currentChartFieldContent.order) ||
      currentChartFieldContent.dataType === ''
    );
  };

  isChartFieldValidationEmpty = (): boolean => {
    const { currentChartFieldValidation } = this.state;
    return (
      currentChartFieldValidation.id === '' ||
      currentChartFieldValidation.chartField.id === '' ||
      currentChartFieldValidation.validationType === '' ||
      currentChartFieldValidation.validationActionType === ''
    );
  };

  onChartFieldSaveClick = () => {
    if (!this.isChartFieldEmpty()) {
      this.props.onSaveChartField(this.state.currentChartField);
    } else {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Validation',
        message: 'Missing fields for ChartField',
        onOkClick: () => this.props.modalService.closeModal('warningModal')
      });
    }
  };

  onChartFieldContentSaveClick = () => {
    if (!this.isChartFieldContentEmpty()) {
      this.props.onSaveChartContent(this.state.currentChartFieldContent);
    } else {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Validation',
        message: 'Missing fields for Content',
        onOkClick: () => this.props.modalService.closeModal('warningModal')
      });
    }
  };

  onChartFieldValidationSaveClick = () => {
    if (!this.isChartFieldValidationEmpty()) {
      this.props.onSaveChartValidation(this.state.currentChartFieldValidation);
    } else {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Validation',
        message: 'Missing fields for Validation',
        onOkClick: () => this.props.modalService.closeModal('warningModal')
      });
    }
  };
  setupChartFieldOptions = () => {
    const chartFieldOptions: DropDownItem[] = [];
    chartFieldOptions.push({ name: 'Select', value: '' });
    this.props.chartFieldList.forEach((chartField) => {
      chartFieldOptions.push({ name: chartField.id, value: chartField.id });
    });
    return chartFieldOptions;
  };
  setupParentIdOptions = () => {
    const parentValidationIdOptions: DropDownItem[] = [];
    parentValidationIdOptions.push({ name: 'Select', value: '' });
    this.props.parentValidationIdList.forEach((validation) => {
      parentValidationIdOptions.push({ name: validation.id, value: validation.id });
    });
    return parentValidationIdOptions;
  };
  setupContentSelectionIdOptions = () => {
    const contentSelectionIdOptions: DropDownItem[] = [];
    contentSelectionIdOptions.push({ name: 'Select', value: '' });
    this.props.contentSelectionIdList.forEach((content) => {
      contentSelectionIdOptions.push({ name: content.id, value: content.id });
    });
    return contentSelectionIdOptions;
  };

  render() {
    const viewProps: MetadataInputViewProps = {
      onIdChange: this.onIdChange,
      onNavElementChange: this.onNavElementChange,
      onFormFieldChange: this.onFormFieldChange,
      onDataPointChange: this.onDataPointChange,
      onChartFieldContentIdChange: this.onChartFieldContentIdChange,
      onChartFieldIdChange: this.onChartFieldIdChange,
      onChartFieldVersionChange: this.onChartFieldVersionChange,
      onChartFieldActiveChange: this.onChartFieldActiveChange,
      onChartFieldValueChange: this.onChartFieldValueChange,
      onChartFieldLabelChange: this.onChartFieldLabelChange,
      onChartFieldOrderChange: this.onChartFieldOrderChange,
      onChartFieldDataTypeChange: this.onChartFieldDataTypeChange,
      onChartFieldValidationIdChange: this.onChartFieldValidationIdChange,
      onChartFieldIdValidationChange: this.onChartFieldIdValidationChange,
      onChartFieldValidationVersionChange: this.onChartFieldValidationVersionChange,
      onChartFieldValidationActiveChange: this.onChartFieldValidationActiveChange,
      onContentSelectionChange: this.onContentSelectionChange,
      onValidationTypeChange: this.onValidationTypeChange,
      onValidationActionTypeChange: this.onValidationActionTypeChange,
      onMessageChange: this.onMessageChange,
      onParentValidationChange: this.onParentValidationChange,
      onMinRangeChange: this.onMinRangeChange,
      onMaxRangeChange: this.onMaxRangeChange,
      onRegexChange: this.onRegexChange,
      onChartFieldSaveClick: this.onChartFieldSaveClick,
      onChartFieldContentSaveClick: this.onChartFieldContentSaveClick,
      onChartFieldValidationSaveClick: this.onChartFieldValidationSaveClick,
      selectedChartField: this.state.currentChartField,
      selectedChartContent: this.state.currentChartFieldContent,
      selectedValidation: this.state.currentChartFieldValidation,
      chartFieldOptions: this.setupChartFieldOptions(),
      parentValidationIdOptions: this.setupParentIdOptions(),
      contentSelectionIdOptions: this.setupContentSelectionIdOptions(),
      dropDownItems,
      dataTypeDropDownItems,
      validationTypeOptions,
      validationActionTypeOptions,
      orderDropDownItems: createOrderDropDownItems()
    };
    return (
      <div>
        <MetadataInputView {...viewProps} />
      </div>
    );
  }
}
export { MetadataInput as BaseMetadataInput };
export default withFormUtilities(MetadataInput);

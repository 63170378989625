import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import MusculoskeletalInterventionsView from './MusculoskeletalInterventionsView';
import { Title } from '../shared/constants';

class MusculoskeletalInterventions extends Component<ChartComponentProps> {
  static displayName = 'MusculoskeletalInterventions';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkBoxes = [FormField.NO_CARE_REQUIRED];
    const multiselectDropdowns = [
      FormField.JOINT_REPAIR_REPLACEMENT_CARE,
      FormField.TRACTION_CARE,
      FormField.FRACTURE_CARE,
      FormField.PIN_TONG_CARE,
      FormField.DRAIN_TUBE_CARE,
      FormField.PATIENT_RESPONSE
    ];

    const textAreas = [FormField.CARE_NOTES];
    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));
    dataMap.set(FormField.EXTERNAL_FIXATOR, createFormField({ name: FormField.EXTERNAL_FIXATOR, type: FormFieldInputType.RADIO_CHOICE, label: 'External Fixator' }));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.MUSCULOSKELETAL_INTERVENTIONS, fields: [FormField.NO_CARE_REQUIRED], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.JOINT_REPAIR_REPLACEMENT_CARE,
        fields: [FormField.JOINT_REPAIR_REPLACEMENT_CARE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.FRACTURE_CARE,
        fields: [FormField.FRACTURE_CARE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.TRACTION_CARE,
        fields: [FormField.TRACTION_CARE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PIN_TONG_CARE,
        fields: [FormField.EXTERNAL_FIXATOR, FormField.PIN_TONG_CARE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.DRAIN_TUBE_CARE,
        fields: [FormField.DRAIN_TUBE_CARE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PATIENT_RESPONSE,
        fields: [FormField.PATIENT_RESPONSE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.CARE_NOTES,
        fields: [FormField.CARE_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SYSTEM_NURSING_INTERVENTIONS,
      fragmentTitle: SectionTitle.MUSCULOSKELETAL_INTERVENTIONS,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields())
    };
    const viewProps: ChartViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <MusculoskeletalInterventionsView {...viewProps} />;
  }
}

export { MusculoskeletalInterventions as BaseMusculoskeletalInterventions };
export default compose(withSavedPatientChartsPage, withChartLogic)(MusculoskeletalInterventions);

// eslint-disable-next-line import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires
const icon = require('!!raw-loader!assets/img/pass-grade.svg');

interface PassGradeIconProps {
  id?: string;
  className?: string;
}

const PassGradeIcon = (props: PassGradeIconProps) => (
  // eslint-disable-next-line react/no-danger
  <span id={props.id} className={props.className} dangerouslySetInnerHTML={{ __html: icon }} />
);
PassGradeIcon.displayName = 'PassGradeIcon';
export default PassGradeIcon;

import cx from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Locales } from 'constants/app.constant';
import { cssHelper } from 'helpers';
import { resetEmptyEhrDemoStorage } from 'helpers/assignment.helper';
import { assignmentService, logService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { Box } from 'components/common';
import AssignmentInstruction from 'components/common/assignment-instruction/AssignmentInstruction';
import AssignmentObjective from 'components/common/assignment-objective/AssignmentObjective';
import { ELSButton, ELSPageLoader } from 'components/common/els';
import BlankChartingCompetency from '../blank-charting-competency/BlankChartingCompetency';

interface MatchParams {
  assignmentId: string;
}

export interface BlankChartingInstructionProps {
  studentId: number;
  locale: Locales;
}

export interface BlankChartingInstructionState {
  assessmentId: number;
  objectives: string;
  instructions: string;
}

class BlankChartingInstruction extends Component<BlankChartingInstructionProps & RouteComponentProps<MatchParams>, BlankChartingInstructionState> {
  static displayName = 'BlankChartingInstruction';
  assignmentId: number;

  constructor(props) {
    super(props);
    this.state = {
      assessmentId: 0,
      objectives: '',
      instructions: ''
    };
    const { assignmentId } = this.props.match.params;
    this.assignmentId = Number(assignmentId);
  }

  componentDidMount() {
    resetEmptyEhrDemoStorage();
    this.getAndProcessAssessment().catch((err) => logService.log(`call api errors ${err}`));
  }

  getAndProcessAssessment = () => {
    return assignmentService
      .getAssessmentByUserIdAndAssignmentId(this.assignmentId, this.props.studentId)
      .then((res) => res.data)
      .then((assessment) => this.processAssessment(assessment));
  };

  processAssessment = (assessment): void => {
    if (assessment.startedAt) {
      navigationService.navigateToClinicalEntryPoint(this.assignmentId);
    } else {
      this.setState({ assessmentId: assessment.id, objectives: assessment.objectives, instructions: assessment.instructions });
    }
  };

  handleStartAssignmentClick = () => assignmentService.startAssessment(this.state.assessmentId).then(() => navigationService.navigateToClinicalEntryPoint(this.assignmentId));

  render() {
    if (!this.state.assessmentId) {
      return <ELSPageLoader />;
    }
    const responsiveClass = cssHelper.mapElsCss({
      els: 'padding-left-3x padding-right-3x',
      md: 'padding-left-1x1o2 padding-right-1x1o2',
      lg: 'padding-left-2x padding-right-2x'
    });

    return (
      <div>
        <Box className={responsiveClass} py2>
          <h1>{this.props.locale === Locales.EN_US ? 'Empty EHR Information' : 'Empty EMR Information'}</h1>
        </Box>
        <Box py2 className={cx('u-els-background-color-n0', responsiveClass)}>
          <Box p2 className="u-els-background-color-white">
            <h2>Assignment Objectives</h2>
            <AssignmentObjective objective={this.state.objectives} />
          </Box>
          <Box p2 className="u-els-background-color-white">
            <h2>Assignment Instructions</h2>
            <AssignmentInstruction instruction={this.state.instructions} />
          </Box>
        </Box>
        <BlankChartingCompetency />
        <Box className={responsiveClass} py2>
          <ELSButton text="Start Assignment" onClick={this.handleStartAssignmentClick} />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  studentId: appSelectors.getUserId(state),
  locale: appSelectors.getLocale(state)
});

export { BlankChartingInstruction as BaseBlankChartingInstruction };
export default compose(withRouter, connect(mapStateToProps))(BlankChartingInstruction);

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import PostpartumInterventionsView from './PostpartumInterventionsView';
import { Title } from '../shared/constants';

class PostpartumInterventions extends Component<ChartComponentProps> {
  static displayName = 'PostpartumInterventions';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const multiselectDropdowns = [FormField.POSTPARTUM_CARE, FormField.PATIENT_RESPONSE, FormField.NEWBORN_CARE, FormField.MOTHER_NEWBORN_RESPONSE];

    dataMap.set(FormField.NO_CARE_REQUIRED, createFormField({ name: FormField.NO_CARE_REQUIRED, type: FormFieldInputType.CHECK_BOX }));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    dataMap.set(FormField.POSTPARTUM_CARE_NOTES, createFormField({ name: FormField.POSTPARTUM_CARE_NOTES, type: FormFieldInputType.TEXT_AREA }));
    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.POSTPARTUM_INTERVENTIONS, fields: [FormField.NO_CARE_REQUIRED], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.POSTPARTUM_CARE, fields: [FormField.POSTPARTUM_CARE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.PATIENT_RESPONSE, fields: [FormField.PATIENT_RESPONSE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.NEWBORN_CARE, fields: [FormField.NEWBORN_CARE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.MOTHER_NEWBORN_RESPONSE, fields: [FormField.MOTHER_NEWBORN_RESPONSE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.POSTPARTUM_CARE_NOTES, fields: [FormField.POSTPARTUM_CARE_NOTES], formFieldMap })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SYSTEM_NURSING_INTERVENTIONS,
      fragmentTitle: SectionTitle.POSTPARTUM_INTERVENTIONS,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <PostpartumInterventionsView {...viewProps} />;
  }
}

export { PostpartumInterventions as BasePostpartumInterventions };
export default compose(withSavedPatientChartsPage, withChartLogic)(PostpartumInterventions);

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import SkinCareView from './SkinCareView';
import { Title } from '../shared/constants';

class SkinCare extends Component<ChartComponentProps> {
  static displayName = 'SkinCare';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkBoxes = [FormField.NO_ASSESSMENT_REQUIRED];
    const multiselectDropdowns = [FormField.SKIN_CARE_LIST, FormField.PRESSURE_ULCER_REDUCTION];
    const textAreas = [FormField.SKIN_CARE_LIST_NOTES, FormField.PRESSURE_ULCER_REDUCTION_NOTES];

    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;

    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SKIN_CARE,
        fields: [FormField.NO_ASSESSMENT_REQUIRED],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SKIN_CARE,
        fields: [FormField.SKIN_CARE_LIST, FormField.SKIN_CARE_LIST_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PRESSURE_INJURY_REDUCTION,
        fields: [FormField.PRESSURE_ULCER_REDUCTION, FormField.PRESSURE_ULCER_REDUCTION_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.BASIC_NURSING_CARE,
      fragmentTitle: SectionTitle.SKIN_CARE,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <SkinCareView {...viewProps} />;
  }
}
export { SkinCare as BaseSkinCare };
export default compose(withSavedPatientChartsPage, withChartLogic)(SkinCare);

import MultiLine from '../multi-line/MultiLine';

interface CaseStudyOverviewProps {
  overview: string;
}

const CaseStudyOverview = (props: CaseStudyOverviewProps) => <div>{props.overview && <MultiLine text={props.overview} />}</div>;

CaseStudyOverview.displayName = 'CaseStudyOverview';
export default CaseStudyOverview;

import { ChartFragment, MentalStatusFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, Headline } from 'components/common';
import MentalStatusEditView from './MentalStatusEditView';
import MentalStatusReadOnly from './MentalStatusReadOnly';

export interface MentalStatusViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: MentalStatusFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

export const MentalStatusView = (props: MentalStatusViewProps) => (
  <div className="mental-status">
    {!props.isLocked ? (
      <>
        <Box mt2>
          <MentalStatusEditView {...props} />
        </Box>
      </>
    ) : (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <MentalStatusReadOnly selectedRecord={props.selectedRecord} />
      </>
    )}
  </div>
);
MentalStatusView.displayName = 'MentalStatusView';
export default MentalStatusView;

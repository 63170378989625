import { withHandlers } from 'recompose';
import { ChartFragment, ChartFragmentRS } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { CancelOptions, ChartMetaFormField, ErrorField, SaveOptions } from 'models/ui';
import { appHelper } from 'helpers';
import { ConfirmationModal, DiscardConfirmationModal } from 'components/common';

interface WithSaveAndDiscardHandlersProps {
  isEmbeddedChart?: boolean;
  modalService: ELSModalServiceType;
  initState: Function;
  onDiscard?: Function;
  saveChartData: (payload: ChartFragment, navId?: string) => Promise<ChartFragmentRS>;
  showSaveSuccess: Function;
  updateFormSubmitted: Function;
  preventScroll?: boolean;
  formFieldMap: Map<string, ChartMetaFormField>;
  setDirty: Function;
}

const SIDEBAR_CLASSNAME = '.sidebar__container';

const STOP_SAVE = 'STOP_SAVE';

const defaultBeforeSave = (props: WithSaveAndDiscardHandlersProps, saveFormCallback: Function, formFieldMap?: Map<string, ChartMetaFormField>) => {
  let message = '';
  let canSave = true;

  const fieldMap = formFieldMap || props.formFieldMap;

  props.updateFormSubmitted();

  fieldMap.forEach((chartField: ChartMetaFormField) => {
    if (chartField.warning.length > 0) {
      chartField.warning.forEach((ef: ErrorField) => {
        message = message.concat('\n', ef.message);
      });
    }
    if (chartField.errors.length > 0) {
      canSave = false;
    }
  });

  if (!canSave && !props.preventScroll) {
    if (props.isEmbeddedChart) {
      appHelper.doChartScroll(0, SIDEBAR_CLASSNAME);
    } else {
      appHelper.scrollTo('#error-card');
    }
    return;
  }

  if (message === '') {
    saveFormCallback();
  } else {
    message = message.concat('\nIs it OK to continue saving?');
    props.modalService.openModal({
      modalId: 'validationWarningsModal',
      color: 'primary',
      content: (
        <ConfirmationModal
          showIcon
          header="Please confirm."
          message={message}
          onOkClick={() => {
            props.modalService.closeModal('validationWarningsModal');
            saveFormCallback();
          }}
          onCancelClick={() => props.modalService.closeModal('validationWarningsModal')}
        />
      )
    });
  }
};

const handleSaveClick = (props: WithSaveAndDiscardHandlersProps, fragments: Array<Partial<ChartFragment>>, options: SaveOptions = {}) => {
  let scrolling = appHelper.scrollTop;
  if (props.isEmbeddedChart) {
    scrolling = () => appHelper.doChartScroll(0, SIDEBAR_CLASSNAME);
  }

  const doSave = () => {
    // do save
    const saveDataPromises = fragments.map((fragment) => props.saveChartData(fragment, options.navId));
    const resultSavePromise = Promise.all(saveDataPromises).then((apiRes) => {
      props.setDirty(false);
      scrolling();
      return apiRes;
    });

    // do afterSave
    const finalPromise = resultSavePromise.then((apiRes) => (options.afterSave ? options.afterSave(apiRes) : Promise.resolve(apiRes)));

    return appHelper.useLoader(finalPromise, { errorMessage: 'can not save chart data' });
  };

  const saveCallback = () =>
    Promise.resolve()
      .then(() => options?.beforeSave?.())
      .catch(() => STOP_SAVE)
      .then((stopSave) => (stopSave === STOP_SAVE ? null : doSave()));

  defaultBeforeSave(props, saveCallback, options.formFieldMap);
};

const saveChart = (props: WithSaveAndDiscardHandlersProps, fragments: Array<Partial<ChartFragment>>, options: SaveOptions = {}) => {
  const originAfterSave = options.afterSave;
  const afterSave = (apiRes) => {
    if (!options.hideSaveMessage) {
      props.showSaveSuccess();
    }

    if (!props.isEmbeddedChart) {
      // reset form fields
      props.initState(options.defaultFormFields);
    }

    return originAfterSave ? originAfterSave(apiRes) : apiRes;
  };

  handleSaveClick(props, fragments, { ...options, afterSave });
};

const handleDiscardClick = (
  props: WithSaveAndDiscardHandlersProps,
  resetFormCallback: Function,
  defaultFormFields: Map<string, ChartMetaFormField>,
  options: CancelOptions = {}
) => {
  const modalId = 'discardConfirmationModal';
  props.modalService.openModal({
    modalId,
    content: (
      <DiscardConfirmationModal
        onOkClick={() => {
          props.modalService.closeModal(modalId);

          if (resetFormCallback) {
            resetFormCallback();
          }

          props.initState(defaultFormFields);

          let scrolling;
          if (options.selectorToScroll) {
            scrolling = () => appHelper.doChartScroll(0, options.selectorToScroll);
          } else if (props.isEmbeddedChart) {
            scrolling = () => appHelper.doChartScroll(0, SIDEBAR_CLASSNAME);
          } else {
            scrolling = appHelper.scrollTop;
          }
          scrolling();

          if (props.onDiscard) {
            props.onDiscard();
          }
        }}
        onCancelClick={() => props.modalService.closeModal(modalId)}
      />
    )
  });
};

const withSaveAndDiscardHandlers = withHandlers({
  handleSaveClick: (props: WithSaveAndDiscardHandlersProps) => (fragments: Array<Partial<ChartFragment>>, options?: Partial<SaveOptions>) =>
    handleSaveClick(props, fragments, options),
  handleDiscardClick:
    (props: WithSaveAndDiscardHandlersProps) => (resetFormCallback: Function, defaultFormFields: Map<string, ChartMetaFormField>, options?: Partial<CancelOptions>) =>
      handleDiscardClick(props, resetFormCallback, defaultFormFields, options),
  saveChart: (props: WithSaveAndDiscardHandlersProps) => (fragments: Array<Partial<ChartFragment>>, options?: Partial<SaveOptions>) => saveChart(props, fragments, options)
});

export default withSaveAndDiscardHandlers;

export enum SectionTitle {
  PROBLEM = 'Problems',
  QUESTION_PARENT = 'Questions for Parent',
  PLANNING_FOR_LABOR = 'Planning for Labor',
  PLANS_FOR_BIRTH = 'Plans for Birth',
  NEWBORN_PLANS = 'Newborn Plans'
}

export enum Labels {
  DURING_HOSPITALIZATION = 'Will family/others be staying with you during hospitalization?',
  DURING_HOSPITALIZATION_NAME = 'Family/Others Name',
  COMMUNICATE = 'With whom should we communicate while you are hospitalized?',
  PROBLEM_EXISTED = 'How long has the problem existed?',
  ADMITTED_HOSPITAL = 'Why were you admitted to the hospital?',
  CONTACT_PERSON = 'Do we have permission to contact this person?',
  CONTACT_PERSON_NAME = 'Contact Person Name',
  CONTACT_PERSON_PHONE = 'Contact Person Phone',
  HEALTH_PROBLEM_HOME = 'How have you dealt with this health problem at home?',
  CAN_READ = 'Can you read?',
  CAN_WRITE = 'Can you write?',
  BARRIERS_LEARNING = 'Do you have barriers to learning?',
  BARRIERS_LEARNING_PROBLEMS = 'Problems',
  PRIMARY_LANGUAGE = 'Primary Language',
  PRIMARY_LANGUAGE_NEED_TRANSLATOR = 'Do you need a translator?',
  HAVE_LIVING = 'Do you have a living will?',
  LIVING_AVAILABLE = 'Is a copy of your living will available?',
  PREFER_INSTRUCTIONS = 'How do you prefer instructions?',
  VISITOR_RESTRICTIONS = 'Visitor restrictions (if any)',
  EDUCATION_LEVEL = 'Education Level',
  OTHERS = 'Others',
  PROBLEMS = 'Problems',
  CIRCUMSTANCE_ADMITTED_HOSPITAL = 'Why now? What specific circumstance/event precipitated this admission?',
  PROFESSION_OCCUPATION = 'What is your current or previous profession or occupation?',
  ATTEND_PRENATAL_CLASSES = 'Did you attend prenatal classes?',
  NAMES_PERSONS_TO_BE_PRESENT = 'Name(s) of person(s) to be present',
  DOCUMENTED_BIRTH_PLAN = 'Documented birth plan',
  ANESTHESIA_DESIRED = 'Anesthesia desired',
  SPECIFIC_REQUESTS_DURING_LABOR = 'Specific requests during labor',
  TUBAL_LIGATION_PLANNED = 'Is a tubal ligation planned?',
  CONSENT_SIGNED = 'Consent signed?',
  PEDIATRICIAN_NAME = 'Pediatrician name',
  NEWBORN_NUTRITION = 'Newborn nutrition',
  CIRCUMCISION_PLANNED = 'Is a circumcision planned?'
}

export enum FormField {
  DURING_HOSPITALIZATION = 'duringHospitalization',
  DURING_HOSPITALIZATION_NAME = 'duringHospitalizationName',
  COMMUNICATE = 'communicate',
  COMMUNICATE_OTHER = 'communicateOther',
  PROBLEM_EXISTED = 'problemExisted',
  PROBLEM_EXISTED_OTHER = 'problemExistedOther',
  CONTACT_PERSON = 'contactPerson',
  CONTACT_PERSON_NAME = 'contactPersonName',
  CONTACT_PERSON_PHONE = 'contactPersonPhone',
  HEALTH_PROBLEM_HOME = 'healthProblemHome',
  CAN_READ = 'canRead',
  CAN_WRITE = 'canWrite',
  BARRIERS_LEARNING = 'barriersLearning',
  BARRIERS_LEARNING_PROBLEMS = 'barriersLearningProblems',
  PRIMARY_LANGUAGE = 'primaryLanguage',
  PRIMARY_LANGUAGE_OTHER = 'primaryLanguageOther',
  PRIMARY_LANGUAGE_NEED_TRANSLATOR = 'primaryLanguageNeedTranslator',
  HAVE_LIVING = 'haveLiving',
  LIVING_AVAILABLE = 'livingAvailable',
  PREFER_INSTRUCTIONS = 'preferInstructions',
  PREFER_INSTRUCTIONS_OTHER = 'preferInstructionsOther',
  EDUCATION_LEVEL = 'educationLevel',
  EDUCATION_LEVEL_OTHER = 'educationLevelOther',
  VISITOR_RESTRICTIONS = 'visitorRestrictions',
  ADMITTED_HOSPITAL = 'admittedHospital',
  CIRCUMSTANCE_ADMITTED_HOSPITAL = 'circumstanceAdmittedHospital',
  PROFESSION_OCCUPATION = 'professionOccupation',
  ATTEND_PRENATAL_CLASSES = 'attendPrenatalClasses',
  NAMES_PERSONS_TO_BE_PRESENT = 'namesPersonsToBePresent',
  DOCUMENTED_BIRTH_PLAN = 'documentedBirthPlan',
  ANESTHESIA_DESIRED = 'anesthesiaDesired',
  SPECIFIC_REQUESTS_DURING_LABOR = 'specificRequestsDuringLabor',
  TUBAL_LIGATION_PLANNED = 'tubalLigationPlanned',
  PLANS_FOR_BIRTH_CONSENT_SIGNED = 'plansForBirthConsentSigned',
  PEDIATRICIAN_NAME = 'pediatricianName',
  NEWBORN_NUTRITION = 'newbornNutrition',
  CIRCUMCISION_PLANNED = 'circumcisionPlanned',
  NEWBORN_PLANS_CONSENT_SIGNED = 'newbornPlansConsentSigned',
  QUESTION_PARENT_NEED_TRANSLATOR = 'questionParentNeedTranslator',
  QUESTION_PARENT_BARRIERS_LEARNING = 'questionParentBarriersLearning',
  QUESTION_PARENT_BARRIERS_LEARNING_PROBLEMS = 'questionParentBarriersLearningProblems',
  QUESTION_PARENT_PREFER_INSTRUCTIONS = 'questionParentPreferInstructions',
  QUESTION_PARENT_PREFER_INSTRUCTIONS_OTHER = 'questionParentPreferInstructionsOther',
  QUESTION_PARENT_EDUCATION_LEVEL = 'questionParentEducationLevel',
  QUESTION_PARENT_EDUCATION_LEVEL_OTHER = 'questionParentEducationLevelOther',
  CONTAINER_QUESTION_PARENT = 'containerQuestionParent',
  CONTAINER_WOMEN_HEALTH = 'containerWomenHealth'
}

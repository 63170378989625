export enum FormField {
  FUNDAL_HEIGHT = 'fundalHeight',
  MEMBRANES = 'membranes',
  MEMBRANES_TIME_OF_RUPTURE_DATE = 'membranesTimeOfRuptureDate',
  MEMBRANES_TIME_OF_RUPTURE_TIME = 'membranesTimeOfRuptureTime',
  MEMBRANES_COLOR_OF_FLUID = 'membranesColorOfFluid',
  MEMBRANES_VISCOSITY = 'membranesViscosity',
  MEMBRANES_ODOR = 'membranesOdor',
  MEMBRANES_FLUID_AMOUNT = 'membranesFluidAmount',
  NO_ASSESSMENT_REQUIRED = 'assessmentNotRequired',
  VAGINAL_DISCHARGE_RADIO = 'vaginalDischargeRadio',
  VAGINAL_DISCHARGE_COLOR = 'vaginalDischargeColor',
  VAGINAL_DISCHARGE_ODOR = 'vaginalDischargeOdor',
  VAGINAL_DISCHARGE_PH = 'vaginalDischargePH',
  VAGINAL_DISCHARGE_FERNING = 'vaginalDischargeFerning',
  VAGINAL_DISCHARGE_GROUP_BSTREPTOCOCCUS = 'vaginalDischargeGroupBStreptococcus',
  OTHER_TEXT_AREA = 'otherTextArea'
}

export enum SectionTitle {
  OBSTETRIC_ASSESSMENT = 'Obstetric Assessment',
  MEMBRANES = 'Membranes',
  VAGINAL_COLOR = 'Color',
  OTHER = 'Other'
}

export enum FormField {
  CHIEF_INFORMANT = 'chiefInformant',
  CHIEF_COMPLAINT = 'chiefComplaint',
  HISTORY_CURRENT_PROBLEM = 'historyCurrentProblem',
  ALLERGIES = 'allergies',
  UNABLE_ASSESS = 'unableAssess',
  PAST_PSYCHIATRIC_HISTORY = 'pastPsychiatricHistory',
  PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS = 'previousPsychiatricHospitalizations',
  SUICIDE_HISTORY = 'suicideHistory',
  OUTPATIENT_TREATMENT = 'outpatientTreatment',
  ALCOHOL_USE = 'alcoholUse',
  SUBSTANCE_USE = 'substanceUse',
  ELECTROCONVULSIVE_THERAPY = 'electroconvulsiveTherapy',
  FAMILY_HISTORY = 'familyHistory'
}

export enum FormFieldLabel {
  CHIEF_INFORMANT = 'Chief Informant',
  CHIEF_COMPLAINT = 'Chief Complaint',
  HISTORY_CURRENT_PROBLEM = 'History of Current Problem',
  ALLERGIES = 'Allergies',
  UNABLE_ASSESS = 'Unable to Assess',
  PAST_PSYCHIATRIC_HISTORY = 'Past Psychiatric History',
  PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS = 'Previous Psychiatric Hospitalizations',
  SUICIDE_HISTORY = 'Suicide History',
  OUTPATIENT_TREATMENT = 'Outpatient Treatment',
  ALCOHOL_USE = 'Alcohol Use',
  SUBSTANCE_USE = 'Substance Use',
  ELECTROCONVULSIVE_THERAPY = 'Electroconvulsive Therapy (ECT)',
  FAMILY_HISTORY = 'Family History'
}

export enum SectionTitle {
  PATIENT_INFORMATION = 'Patient Information',
  PSYCHIATRIC_HISTORY = 'Psychiatric History'
}

import cx from 'classnames';
import { Component } from 'react';
import { ELSButton } from 'components/common/els';
import './navigation.component.scss';

export interface NavigationComponentProps {
  title: string;
  current: number;
  total: number;
  className?: string;
  disablePrev?: boolean;
  disableNext?: boolean;
  prevText?: string;
  nextText?: string;
  onPrev: Function;
  onNext: Function;
}

class NavigationComponent extends Component<NavigationComponentProps> {
  static displayName = 'NavigationComponent';

  render() {
    const { current, total, disableNext, disablePrev, prevText, nextText } = this.props;
    const isNextButtonDisabled = disableNext || current >= total;
    const isPrevButtonDisabled = disablePrev || current <= 1;
    return (
      <div className={cx('navigate-component', this.props.className)}>
        <div className="navigate-text navigate-title">{this.props.title}</div>
        <div className="navigate-text navigate-stage">{`${current} of ${total}`}</div>
        {prevText && <ELSButton className="u-els-margin-left" text={prevText} onClick={this.props.onPrev} isDisabled={isPrevButtonDisabled} pendingTimeout={1000} />}
        {!prevText && (
          <ELSButton className="u-els-margin-left" iconSize="1x" iconName="chevron-left" iconPosition="left" onClick={this.props.onPrev} isDisabled={isPrevButtonDisabled} />
        )}
        {nextText && <ELSButton className="u-els-margin-left" text={nextText} onClick={this.props.onNext} isDisabled={isNextButtonDisabled} pendingTimeout={1000} />}
        {!nextText && (
          <ELSButton className="u-els-margin-left" iconSize="1x" iconName="chevron-right" iconPosition="left" onClick={this.props.onNext} isDisabled={isNextButtonDisabled} />
        )}
      </div>
    );
  }
}

export default NavigationComponent;

import { ChartFragmentRS, LaboratoryTestFragment, LaboratoryTestResult } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, ChartMetadata, ChartSharedProps } from 'models/ui';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import 'components/features/chart/pre-clinical-manager/pre.clinical.manager.common.scss';
import EditLaboratoryTestSidebar from './EditLaboratoryTestSidebar';
import { FormField } from './LaboratoryTest';
import LaboratoryTestHistoryView from './LaboratoryTestHistoryView';
import LaboratoryTestResultCard from './LaboratoryTestResultCard';

export interface LaboratoryTestProps extends ChartSharedProps {
  chartMetadata: ChartMetadata;
  errorCardKey: number;
  formSubmittedCount: number;
  selectedNavId: string;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartMetaFormFieldsDisplayError: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  selectedLaboratoryTest: LaboratoryTestFragment;
  labResults: LaboratoryTestResult[];
  chartHistory: LaboratoryTestFragment[];
  onLabTestResultChange: Function;
  onChartDelete: Function;
  onChartEdit: Function;
  onEditSidebarOpen: Function;
  onEditSidebarClose: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const LaboratoryTestView = (props: LaboratoryTestProps) => {
  const {
    chartMetadata,
    errorCardKey,
    formSubmittedCount,
    selectedNavId,
    chartMetaFormFields,
    chartMetaFormFieldsDisplayError,
    chartActionsProps,
    selectedLaboratoryTest,
    labResults,
    chartHistory,
    onLabTestResultChange,
    onChartDelete,
    onChartEdit,
    onEditSidebarOpen,
    onEditSidebarClose,
    saveChartData
  } = props;

  return (
    <div className="pre-clinical-manager__page">
      <ErrorCard key={errorCardKey} chartMetaFormFields={chartMetaFormFieldsDisplayError} showErrorCard={formSubmittedCount > 0} />
      <h1>Laboratory Tests</h1>
      <LaboratoryTestHistoryView chartHistory={chartHistory} onChartEdit={onEditSidebarOpen} onChartDelete={onChartDelete} />
      <EditLaboratoryTestSidebar
        selectedNavId={selectedNavId}
        laboratoryTest={selectedLaboratoryTest}
        onSaveClick={onChartEdit}
        onCloseClick={onEditSidebarClose}
        saveChartData={saveChartData}
        preventScroll
        {...appHelper.getChartSharedProps(props)}
      />
      <Box mt4>
        <h2 className="u-els-margin-bottom-2x">Add Laboratory Test</h2>
        <ErrorFormField formField={chartMetaFormFields.get(FormField.DATE_OF_TEST)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField className="location-dropdown" formField={chartMetaFormFields.get(FormField.CATEGORY)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={chartMetaFormFields.get(FormField.LAB_TEST)} formSubmittedCount={formSubmittedCount} className="location-dropdown" />
        <div className="u-els-width-2o3">
          <ErrorFormField formField={chartMetaFormFields.get(FormField.DEFINITION_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
        </div>
        <div className="u-els-width-2o3">
          <ErrorFormField formField={chartMetaFormFields.get(FormField.SIGNIFICANCE)} formSubmittedCount={formSubmittedCount} />
        </div>
      </Box>
      <>
        {labResults?.length > 0 && (
          <div>
            <h2 className="u-els-margin-top-4x">Laboratory Test Results</h2>
            {labResults.map((item) => (
              <LaboratoryTestResultCard
                key={item.id}
                labResult={item}
                onLabTestResultChange={onLabTestResultChange}
                existedChartMetadata={chartMetadata}
                showError={formSubmittedCount > 0}
              />
            ))}
          </div>
        )}
      </>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

LaboratoryTestView.displayName = 'LaboratoryTestView';
export default LaboratoryTestView;

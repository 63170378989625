import { get, isEmpty, orderBy } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { AssessmentRS } from 'models/api-response';
import { AssignmentType, PhaseProgress, PhaseType } from 'models/enum';
import { CaseStudy, CaseStudySequence, SkinnyAssignment, SkipLinkSection } from 'models/ui';
import { RouteParams, RoutePath } from 'constants/app.constant';
import { apiHelper } from 'helpers';
import { resetEmptyEhrDemoStorage } from 'helpers/assignment.helper';
import { assignmentService, caseStudyService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentActions, studentSelectors } from 'redux/ducks/student';
import { ELSPageLoader } from 'components/common/els';
import CaseStudyLandingView, { CaseStudyLandingViewProps } from './CaseStudyLandingView';

export interface MenuItem {
  label: string;
  active: boolean;
  completed: boolean;
}

interface MatchParams {
  assessmentId: string;
}

interface CaseStudyLandingProps {
  assignment: SkinnyAssignment;
  assessment: AssessmentRS;
  setAssignment: Function;
  setAssignmentType: Function;
  setSelectedNavId: Function;
  setSkipLinkSections: Function;
}

interface CaseStudyLandingStates {
  isLoading: boolean;
  caseStudy: CaseStudy;
  activeSequence: CaseStudySequence;
  menu: MenuItem[];
  activeMenu: string;
  phaseCompleted: number;
  totalPhase: number;
  chartCompleted: number;
  timeSpent: number;
}

class CaseStudyLanding extends Component<CaseStudyLandingProps & RouteComponentProps<MatchParams>, CaseStudyLandingStates> {
  static displayName = 'CaseStudyLanding';
  assessmentId: number;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      caseStudy: null,
      activeSequence: null,
      menu: [],
      activeMenu: '',
      phaseCompleted: 0,
      totalPhase: 0,
      chartCompleted: 0,
      timeSpent: 0
    };
    this.assessmentId = Number(this.props.match.params.assessmentId);
  }

  componentDidMount() {
    this.getAssignmentId();
    this.loadData();
    resetEmptyEhrDemoStorage();
  }

  getAssignmentId = async () => {
    const { assignment, setAssignment, assessment } = this.props;
    const assessmentResponse = isEmpty(assessment) ? await assignmentService.getAssessment(this.assessmentId) : assessment;
    const assessmentData = get(assessmentResponse, 'data', assessmentResponse);

    if (assignment?.assignmentId !== assessmentData.eolsAssignmentId) {
      setAssignment({ assignmentId: assessmentData.eolsAssignmentId });
    }
  };

  loadData = () => {
    this.setState({ isLoading: true });
    const { setSelectedNavId, setSkipLinkSections } = this.props;
    setSkipLinkSections();
    setSelectedNavId('');

    caseStudyService
      .getSequences(this.assessmentId)
      .then(({ data: caseStudySequence }) => {
        let sequences = caseStudySequence?.sequenceChunks?.length ? caseStudySequence.sequenceChunks : [];
        sequences = orderBy(sequences, 'displayOrder');

        const activeSequence = this.findActiveSequence(sequences);
        const menu = sequences.map((phase) => ({
          label: phase.title,
          active: activeSequence.id === phase.id,
          completed: phase.contentType !== PhaseType.OVERVIEW && phase.status === PhaseProgress.COMPLETED
        }));
        const phaseSequences = sequences.filter((sequence) => sequence.contentType === PhaseType.PHASE);
        const phaseCompleted = phaseSequences.filter((sequence) => sequence.status === PhaseProgress.COMPLETED).length;
        const totalPhase = phaseSequences.length;
        this.setState({ menu, activeSequence, activeMenu: activeSequence.content?.title, phaseCompleted, totalPhase, isLoading: false });

        return caseStudyService
          .getCaseStudy(caseStudySequence.caseStudyId)
          .then((res) => res.data)
          .then((caseStudy) => {
            this.setState({ caseStudy });
          });
      })
      .catch((err) => apiHelper.showApiError(`can not load case study sequences ${err}`))
      .finally(() => this.setState({ isLoading: false }));
  };

  findActiveSequence = (sequences) => {
    let activeSequence = sequences.find((sequence) => sequence.status === PhaseProgress.IN_PROGRESS);
    if (!activeSequence) {
      activeSequence = sequences.find((sequence) => sequence.status === PhaseProgress.NOT_STARTED);
    }
    if (!activeSequence) {
      [activeSequence] = sequences;
    }
    return activeSequence;
  };

  handleMenuClick = (menuItem: MenuItem) => {
    const newMenu = this.state.menu.map((item) => ({ label: item.label, completed: item.completed, active: item.label === menuItem.label }));
    this.setState({ menu: [...newMenu], activeMenu: menuItem.label });
  };

  handleStartPhaseClick = () => {
    caseStudyService
      .startPhase(this.assessmentId)
      .then(() => {
        if (this.state.activeSequence.contentType === PhaseType.OVERVIEW) {
          assignmentService.startAssessment(this.assessmentId).then(this.loadData);
        } else {
          const summaryPath = RoutePath.student.summary.replace(RouteParams.ASSESSMENT_ID, String(this.assessmentId));
          // assignment API does not return assignment type
          this.props.setAssignmentType(AssignmentType.CASE_STUDY);
          this.props.history.push(summaryPath);
        }
      })
      .catch((err) => apiHelper.showApiError(`can not update phase ${err}`));
  };

  render() {
    const { activeSequence, caseStudy, phaseCompleted, totalPhase, chartCompleted, timeSpent } = this.state;
    const viewProps: CaseStudyLandingViewProps = {
      caseStudy,
      sequence: activeSequence,
      menu: this.state.menu,
      activeMenu: this.state.activeMenu,
      phaseCompleted,
      totalPhase,
      chartCompleted,
      timeSpent,
      onMenuClick: this.handleMenuClick,
      onStartPhaseClick: this.handleStartPhaseClick
    };
    return this.state.isLoading ? <ELSPageLoader /> : <CaseStudyLandingView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAssignment: (assignment) => dispatch(studentActions.setAssignment(assignment)),
  setAssignmentType: (assignmentType) => dispatch(studentActions.setAssignmentType(assignmentType)),
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId)),
  setSkipLinkSections: (skipLinkSections: SkipLinkSection[]) => dispatch(appActions.setSkipLinkSections(skipLinkSections))
});

const mapStateToProps = (state) => ({
  assignment: studentSelectors.getAssignment(state),
  assessment: appSelectors.getAssessment(state)
});

export { CaseStudyLanding as BaseCaseStudyLanding };
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CaseStudyLanding);

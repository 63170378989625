import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { getFragmentContentIds, getFragmentValue } from 'helpers/chart.helper';

export enum FormField {
  ADMISSION_ADMITTED_FROM = 'admittedFrom',
  ADMISSION_ADMITTED_FROM_OTHER = 'admittedFromOther',
  ADMISSION_ARRIVED_BY = 'arrivedBy',
  ADMISSION_ARRIVED_BY_OTHER = 'arrivedByOther',
  VERBAL_ADMISSION_HISTORY = 'verbalAdmissionHistory',
  PAST_ADMISSION_HOSPITAL = 'lastHospitalAdmission',
  PAST_ADMISSION_REASON = 'pastAdmissionReason',
  PAST_ADMISSION_WHERE = 'pastAdmissionWhere',
  INFORMANT_HISTORIAN_INFORMATION_FROM = 'infoReceivedFrom',
  INFORMANT_HISTORIAN_NAME = 'informantName',

  PREGNANCY_RELATED = 'pregnancyRelated',
  PREGNANCY_RELATED_SELECT = 'pregnancyRelatedSelect',
  CONTRACTIONS_TIME_OF_ONSET = 'contractionsTimeOfOnset',
  CONTRACTIONS_FREQUENCY = 'contractionsFrequency',
  CONTRACTIONS_DURATION = 'contractionsDuration',
  CONTRACTIONS_QUALITY = 'contractionsQuality',
  CONTRACTIONS_FLUID = 'contractionsFluid',
  CONTRACTIONS_COLOR = 'contractionsColor',
  INDUCTION = 'induction',
  PREGNANCY_RELATED_SELECT_OTHER = 'pregnancyRelatedSelectOther',

  CONTAINER_PREGNANCY_RELATED = 'containerPregnancyRelated',
  CONTAINER_CONTRACTIONS = 'containerContractions',
  CONFIRM_IDENTIFICATION = 'confirmIdentification',

  ADMISSION_DATE = 'admissionDate',
  ESTIMATED_DISCHARGE_DATE = 'estimatedDischargeDate',
  NEXT_OF_KIN_NAME = 'nextOfKin-name',
  NEXT_OF_KIN_PHONE = 'nextOfKin-phone',
  NEXT_OF_KIN_REL_ORG = 'nextOfKin-relOrg',
  NEXT_OF_KIN_AWARE_OF_ADMISSION = 'nextOfKin-awareOfAdmission',
  NEXT_OF_KIN_PREFERRED_NAME = 'nextOfKin-preferredName',
  NEXT_OF_KIN_PREFERRED_PRONOUNS = 'nextOfKin-preferredPronouns',
  NEXT_OF_KIN_ADVANCED_CARE_PLANNING = 'nextOfKin-advancedCarePlanning',
  NEXT_OF_KIN_DOES_PATIENT_HAVE_A_CARER = 'nextOfKin-doesPatientHaveACarer',
  NEXT_OF_KIN_CARER_NAME = 'nextOfKin-carer-name',
  NEXT_OF_KIN_CARER_PHONE = 'nextOfKin-carer-phone',
  NEXT_OF_KIN_CARER_REL_ORG = 'nextOfKin-carer-relOrg',
  NEXT_OF_KIN_IS_PATIENT_ATSI = 'nextOfKin-isPatientATSI',
  NEXT_OF_KIN_IS_PATIENT_ATSI_IF_YES = 'nextOfKin-isPatientATSI-ifYes',
  NEXT_OF_KIN_IS_PATIENT_A_CARER = 'nextOfKin-isPatientACarer',
  NEXT_OF_KIN_PATIENT_CARE_FOR_NAME = 'nextOfKin-patientCareFor-name',
  NEXT_OF_KIN_PATIENT_CARE_FOR_PHONE = 'nextOfKin-patientCareFor-phone',
  NEXT_OF_KIN_PATIENT_CARE_FOR_REL_ORG = 'nextOfKin-patientCareFor-relOrg'
}

export const getAdmissionDataFormFields = ({ fragment, locale }: { fragment: ChartFragment; locale: Locales }): ChartMetaFormFieldParams[] => [
  {
    name: FormField.ADMISSION_ADMITTED_FROM,
    type: FormFieldInputType.DROPDOWN,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.THIS_ADMISSION, FormField.ADMISSION_ADMITTED_FROM),
    label: LanguageKeys.ADMISSION_DATA.ADMITTED_FROM
  },
  {
    name: FormField.ADMISSION_ADMITTED_FROM_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.THIS_ADMISSION, FormField.ADMISSION_ADMITTED_FROM_OTHER),
    label: LanguageKeys.ADMISSION_DATA.OTHER
  },
  {
    name: FormField.ADMISSION_ARRIVED_BY,
    type: FormFieldInputType.DROPDOWN,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.THIS_ADMISSION, FormField.ADMISSION_ARRIVED_BY),
    label: LanguageKeys.ADMISSION_DATA.ARRIVED_BY
  },
  {
    name: FormField.ADMISSION_ARRIVED_BY_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.THIS_ADMISSION, FormField.ADMISSION_ARRIVED_BY_OTHER),
    label: LanguageKeys.ADMISSION_DATA.OTHER
  },
  {
    name: FormField.PAST_ADMISSION_HOSPITAL,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PAST_ADMISSION, FormField.PAST_ADMISSION_HOSPITAL),
    label: LanguageKeys.ADMISSION_DATA.LAST_HOSPITAL_ADMISSION
  },
  {
    name: FormField.PAST_ADMISSION_REASON,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PAST_ADMISSION, FormField.PAST_ADMISSION_REASON),
    label: LanguageKeys.ADMISSION_DATA.REASON
  },
  {
    name: FormField.PAST_ADMISSION_WHERE,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PAST_ADMISSION, FormField.PAST_ADMISSION_WHERE),
    label: LanguageKeys.ADMISSION_DATA.WHERE
  },
  {
    name: FormField.INFORMANT_HISTORIAN_INFORMATION_FROM,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.INFORMANT_HISTORIAN, FormField.INFORMANT_HISTORIAN_INFORMATION_FROM),
    label: LanguageKeys.ADMISSION_DATA.INFORMATION_RECEIVED_FROM
  },
  {
    name: FormField.INFORMANT_HISTORIAN_NAME,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.INFORMANT_HISTORIAN, FormField.INFORMANT_HISTORIAN_NAME),
    label: LanguageKeys.ADMISSION_DATA.NAME
  },
  {
    name: FormField.VERBAL_ADMISSION_HISTORY,
    type: FormFieldInputType.CHECK_BOX,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.VERBAL_ADMISSION_HISTORY, FormField.VERBAL_ADMISSION_HISTORY)
  },
  {
    name: FormField.PREGNANCY_RELATED,
    type: FormFieldInputType.RADIO_CHOICE,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.PREGNANCY_RELATED)
  },
  {
    name: FormField.PREGNANCY_RELATED_SELECT,
    type: FormFieldInputType.CHECK_BOX,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.PREGNANCY_RELATED_SELECT)
  },
  {
    name: FormField.PREGNANCY_RELATED_SELECT_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.PREGNANCY_RELATED_SELECT_OTHER)
  },
  {
    name: FormField.CONTAINER_PREGNANCY_RELATED,
    type: FormFieldInputType.CONTAINER
  },
  {
    name: FormField.CONTRACTIONS_TIME_OF_ONSET,
    type: FormFieldInputType.TIME,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.CONTRACTIONS_TIME_OF_ONSET, '00:00'),
    label: LanguageKeys.ADMISSION_DATA.CONTRACTIONS_TIME_OF_ONSET
  },
  {
    name: FormField.CONTRACTIONS_FREQUENCY,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.CONTRACTIONS_FREQUENCY),
    label: LanguageKeys.ADMISSION_DATA.CONTRACTIONS_FREQUENCY
  },
  {
    name: FormField.CONTRACTIONS_DURATION,
    type: FormFieldInputType.TEXT_BOX,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.CONTRACTIONS_DURATION),
    label: LanguageKeys.ADMISSION_DATA.CONTRACTIONS_DURATION
  },
  {
    name: FormField.CONTRACTIONS_QUALITY,
    type: FormFieldInputType.RADIO_CHOICE,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.CONTRACTIONS_QUALITY),
    label: LanguageKeys.ADMISSION_DATA.CONTRACTIONS_QUALITY
  },
  {
    name: FormField.CONTRACTIONS_FLUID,
    type: FormFieldInputType.RADIO_CHOICE,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.CONTRACTIONS_FLUID),
    label: LanguageKeys.ADMISSION_DATA.CONTRACTIONS_FLUID
  },
  {
    name: FormField.CONTRACTIONS_COLOR,
    type: FormFieldInputType.RADIO_CHOICE,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.CONTRACTIONS_COLOR),
    label: LanguageKeys.ADMISSION_DATA.CONTRACTIONS_COLOR
  },
  {
    name: FormField.INDUCTION,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED, FormField.INDUCTION)
  },
  {
    name: FormField.CONTAINER_CONTRACTIONS,
    type: FormFieldInputType.CONTAINER
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.CONFIRM_IDENTIFICATION,
    type: FormFieldInputType.RADIO_CHOICE
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_NAME,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.NAME
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_PHONE,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.PHONE
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_REL_ORG,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.REL_ORG
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_AWARE_OF_ADMISSION,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ADMISSION_DATA.NOK_AWARE_OF_ADMISSION
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_PREFERRED_NAME,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.PREFERRED_NAME
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_PREFERRED_PRONOUNS,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.PREFERRED_PRONOUNS
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_ADVANCED_CARE_PLANNING,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.ADMISSION_DATA.ADVANCED_CARE_PLANNING
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_IS_PATIENT_ATSI,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ADMISSION_DATA.IS_PATIENT_OF_ATSI
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_IS_PATIENT_ATSI_IF_YES,
    type: FormFieldInputType.RADIO_CHOICE
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_DOES_PATIENT_HAVE_A_CARER,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_CARER_NAME,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.NAME
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_CARER_PHONE,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.PHONE
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_CARER_REL_ORG,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.REL_ORG
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_IS_PATIENT_A_CARER,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_NAME,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.NAME
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_PHONE,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.PHONE
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_REL_ORG,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.ADMISSION_DATA.REL_ORG
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.ADMISSION_DATE,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.ADMISSION_DATA.ADMISSION_DATE,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.ADMISSION_DATE, FormField.ADMISSION_DATE)
  },
  {
    isHidden: locale !== Locales.EN_AU,
    name: FormField.ESTIMATED_DISCHARGE_DATE,
    type: FormFieldInputType.DATE,
    label: LanguageKeys.ADMISSION_DATA.ESTIMATED_DISCHARGE_DATE,
    value: getFragmentValue(fragment, LanguageKeys.ADMISSION_DATA.ESTIMATED_DISCHARGE_DATE, FormField.ESTIMATED_DISCHARGE_DATE)
  }
];

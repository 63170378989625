import { orderBy } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { AssignmentSummary, Student } from 'models/api-response';
import { SortDirection } from 'models/enum';
import { AssessmentGrading, FilterConfig, TimeSpentState } from 'models/ui';
import { Locales, RouteParams, RoutePath } from 'constants/app.constant';
import { apiHelper, appHelper, assignmentHelper } from 'helpers';
import { assignmentService, courseService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { SortField } from './constants';
import GradingProgressView, { GradingProgressViewProps } from './GradingProgressView';
import { compareAssessmentGrading } from './GradingProgressViewHelper';

interface MatchParams {
  assignmentId: string;
}

export interface GradingProgressProps extends RouteComponentProps<MatchParams> {
  filterConfig: FilterConfig;
  setFilterConfig?: Function;
  courseId?: number;
  locale: Locales;
}

interface GradingProgressState extends TimeSpentState {
  nameSearchValue: string;
  statusFilterValue: string;
  assignmentSummary: AssignmentSummary & {
    studentAssessmentGradings: AssessmentGrading[];
  };
  filteredSubmissions: AssessmentGrading[];
  sortField: string;
  sortDirection: SortDirection;
}

class GradingProgress extends Component<GradingProgressProps, GradingProgressState> {
  static displayName = 'GradingProgress';
  assignmentIdParam: string;
  assignmentId: number;

  constructor(props) {
    super(props);
    const { match, filterConfig } = props;
    const isReloaded = (window.performance?.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type === 'reload';
    if (!isReloaded) {
      this.props.setFilterConfig({});
    }
    this.assignmentIdParam = match.params.assignmentId;
    this.assignmentId = Number(this.assignmentIdParam);
    this.state = {
      nameSearchValue: isReloaded ? filterConfig.searchName || '' : '',
      statusFilterValue: isReloaded ? filterConfig.status || '' : '',
      assignmentSummary: null,
      filteredSubmissions: [],
      assignmentTimeSpentList: [],
      sortField: SortField.NAME,
      sortDirection: SortDirection.ASC
    };
  }

  componentDidMount() {
    const { nameSearchValue, statusFilterValue } = this.state;
    appHelper.useLoader(
      Promise.all([
        assignmentService
          .getAssignmentSummary(this.assignmentId)
          .then((res) => res.data)
          .then((assignmentSummary) => {
            // any students in Assignment Summary List that are not in Course Sections List Remove them in Assignment Submission list
            // remove any instructors from course sections list
            courseService
              .getStudents(String(this.props.courseId))
              .then((res) => {
                const courseStudents: Student[] = res.data;
                return courseStudents.map((user) => user.id);
              })
              .then((courseStudentsList) => {
                this.setState({
                  assignmentSummary,
                  filteredSubmissions: this.filterSubmissions(
                    assignmentSummary?.studentAssessmentSubmissions.filter((student) => courseStudentsList.includes(student.userId)) || [],
                    nameSearchValue,
                    statusFilterValue
                  )
                });
              });
          })
          .catch(() => apiHelper.showApiError('can not load assignment summary')),
        assignmentService
          .getTimeSpent(this.assignmentId)
          .then((res) => {
            if (res.data.length !== 0) {
              this.setState({ assignmentTimeSpentList: res.data });
            } else {
              this.setState({ assignmentTimeSpentList: undefined });
            }
          })
          .catch(() => apiHelper.showApiError('can not load assignment total time'))
      ])
    );
  }

  sortSubmissions = (submissions: AssessmentGrading[], sortField: string, sortDirection: SortDirection): AssessmentGrading[] => {
    switch (sortField) {
      case SortField.NAME:
        return orderBy(submissions, 'lastName', sortDirection);
      case SortField.FEEDBACK: {
        // Null item records are submissions that have not been started or in progress
        // We need to sort the rest of the records and then concat the null item records to the end
        const nullItemRecords = [];
        const notNullItemRecords = submissions.filter((submission) => {
          if (!assignmentHelper.isAssessmentNotStartedOrInProgress(submission.status)) return submission;
          nullItemRecords.push(submission);
          return false;
        });

        const revertValue = sortDirection === SortDirection.DESC ? -1 : 1;
        const sortedRecords = [...notNullItemRecords].sort((current, next) => revertValue * compareAssessmentGrading(current, next));
        return [...sortedRecords, ...nullItemRecords];
      }
      default:
        return orderBy(submissions, sortField, sortDirection);
    }
  };

  handleSort = (sortField: SortField) => () => {
    let sortDirection: SortDirection;
    let { filteredSubmissions } = this.state;
    const { sortField: currentSortField, sortDirection: currentSortDirection } = this.state;
    if (sortField === currentSortField) {
      sortDirection = currentSortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    } else {
      sortDirection = SortDirection.ASC;
    }
    filteredSubmissions = this.sortSubmissions(filteredSubmissions, sortField, sortDirection);
    this.setState({
      filteredSubmissions,
      sortField,
      sortDirection
    });
  };

  handleSearchNameChange = ({ target }) => this.setState({ nameSearchValue: target.value || '' }, this.searchSubmissions);

  handleStatusChange = ({ target }) => this.setState({ statusFilterValue: target.value }, this.searchSubmissions);

  filterSubmissions = (studentAssessmentSubmissions: AssessmentGrading[], nameSearch: string, statusFilter: string): AssessmentGrading[] => {
    const { sortField, sortDirection } = this.state;
    const name = nameSearch.trim().toLowerCase();
    let filteredSubmission = studentAssessmentSubmissions;
    if (name) {
      filteredSubmission = filteredSubmission.filter((item) => {
        const studentName = `${item.firstName} ${item.lastName}`.trim().toLowerCase();
        return studentName.includes(name);
      });
    }
    if (statusFilter) {
      filteredSubmission = filteredSubmission.filter((item) => item.status === statusFilter);
    }
    filteredSubmission = this.sortSubmissions(filteredSubmission, sortField, sortDirection);
    return filteredSubmission;
  };

  searchSubmissions = () => {
    const { nameSearchValue, statusFilterValue } = this.state;
    this.setState((prevState) => {
      const dataTableGrading = prevState.assignmentSummary.studentAssessmentSubmissions as AssessmentGrading[];
      return { filteredSubmissions: this.filterSubmissions(dataTableGrading, nameSearchValue, statusFilterValue) };
    });
  };

  handleStudentClick = (row) =>
    this.props.history.push({
      pathname: RoutePath.instructor.studentAssessment.replace(RouteParams.ASSIGNMENT_ID, this.assignmentIdParam),
      search: `?student=${row.userId}`
    });

  render() {
    const { locale } = this.props;
    const { nameSearchValue, statusFilterValue, assignmentSummary, filteredSubmissions, assignmentTimeSpentList, sortField, sortDirection } = this.state;
    const viewProps: GradingProgressViewProps = {
      assignmentId: this.assignmentId,
      nameSearchValue,
      statusFilterValue,
      assignmentSummary,
      assessmentSubmissions: filteredSubmissions,
      onSearchNameChange: this.handleSearchNameChange,
      onStatusChange: this.handleStatusChange,
      onStudentClick: this.handleStudentClick,
      assignmentTimeSpentList,
      sortField,
      isSortDesc: sortDirection === SortDirection.DESC,
      handleSort: this.handleSort,
      locale
    };

    return <GradingProgressView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  filterConfig: appSelectors.getFilterConfig(state),
  courseId: appSelectors.getCourseId(state),
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  setFilterConfig: (filterConfig) => dispatch(appActions.setFilterConfig(filterConfig))
});

export { GradingProgress as BaseGradingProgress };
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(GradingProgress);

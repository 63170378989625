/* eslint-disable @typescript-eslint/interface-name-prefix */
import queryString from 'query-string';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { AppMessage, NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { compose } from 'redux';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import IVsView from './IVsView';

interface IVsProps extends RouteComponentProps {
  showConfirmationModal: Function;
  enableMultiStepsAuthoring: Function;
}

interface IVsState {
  loadedFragment: ChartFragment;
  fragments: ChartFragment[];
}

class IVs extends Component<IVsProps & ChartComponentProps, IVsState> {
  static displayName = 'IVs';

  constructor(props) {
    super(props);
    this.state = {
      loadedFragment: null,
      fragments: null
    };
  }

  componentDidMount() {
    const id = this.getIdFromParam(this.props);
    this.loadData(id);
    this.props.enableMultiStepsAuthoring(true);
  }

  componentDidUpdate(prevProps) {
    const prevId = this.getIdFromParam(prevProps);
    const id = this.getIdFromParam(this.props);
    // Prepare for creating a new IV entry when re-click on the left navigation
    if (id !== prevId && !id) {
      this.setState({ loadedFragment: null });
      this.props.initState(this.buildFormFields());
    }
  }

  getIdFromParam = (props: IVsProps) => {
    const parsedQuery = queryString.parse(props.location.search);
    return parsedQuery.id;
  };

  loadData = (id) =>
    appHelper.useLoader(
      this.props.loadChartData([FragmentType.AUTHORED, FragmentType.CHARTING]).then(({ data: fragments }) => {
        let chartFragment = fragments.find((fragment) => fragment.fragmentId === id && fragment.active);
        // Prevent update an IV Discontinued by accessing URL
        if (this.isIVDiscontinuedExisted(fragments, chartFragment)) {
          chartFragment = null;
          const ivEntryPage = RoutePath.student.ivs.ivs.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
          this.props.history.push(ivEntryPage);
        }
        this.setState({ loadedFragment: chartFragment, fragments });
        this.props.initState(this.buildFormFields(chartFragment));
      }),
      { errorMessage: AppMessage.NO_CHART_DATA }
    );

  isIVDiscontinuedExisted = (fragments: ChartFragment[], fragment: ChartFragment) => {
    if (!fragment) return false;
    const fragmentList = fragments.filter((frag) => frag.linkedFragmentId === (fragment.linkedFragmentId || fragment.fragmentId));
    return fragmentList.some((frag) => frag.active && chartHelper.getFragmentValue(frag, SectionTitle.IV, FormField.IV_DISCONTINUED));
  };

  buildFormFields = (fragment?: ChartFragment) => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkboxes = [
      { name: FormField.IV_DISCONTINUED, sectionTitle: SectionTitle.IV },
      { name: FormField.SITE_APPEARANCE, sectionTitle: SectionTitle.SITE_APPEARANCE, label: FormFieldLabel.SITE_APPEARANCE },
      { name: FormField.EXTERNAL_CATHETER_NOT_APPLICABLE, sectionTitle: SectionTitle.IV, parentLabel: FormFieldLabel.EXTERNAL_CATHETER_LENGTH },
      { name: FormField.PUMP_IN_USE, sectionTitle: SectionTitle.PUMP_IN_USE }
    ];
    const dropdowns = [
      { name: FormField.LOCATION, sectionTitle: SectionTitle.IV, label: FormFieldLabel.LOCATION },
      { name: FormField.CATHETER_SIZE, sectionTitle: SectionTitle.IV, label: FormFieldLabel.CATHETER_SIZE },
      { name: FormField.TYPE_OF_LINE, sectionTitle: SectionTitle.IV, label: FormFieldLabel.TYPE_OF_LINE },
      { name: FormField.NUMBER_OF_LUMENS, sectionTitle: SectionTitle.IV, label: FormFieldLabel.NUMBER_OF_LUMENS }
    ];
    const timeboxs = [
      { name: FormField.TIME_DISCONTINUED, sectionTitle: SectionTitle.IV, label: FormFieldLabel.TIME_DISCONTINUED },
      { name: FormField.INSERTION_TIME, sectionTitle: SectionTitle.IV, label: FormFieldLabel.INSERTION_TIME }
    ];
    const radioChoices = [
      { name: FormField.CONDITION_OF_CATHETER, sectionTitle: SectionTitle.IV, label: FormFieldLabel.CONDITION_OF_CATHETER },
      { name: FormField.TYPE_IV_ACCESS, sectionTitle: SectionTitle.IV, label: FormFieldLabel.TYPE_OF_IV_ACCESS, errorLabel: FormFieldLabel.TYPE_OF_IV_ACCESS },
      { name: FormField.CATHETER_TYPE, sectionTitle: SectionTitle.IV, label: FormFieldLabel.CATHETER_TYPE }
    ];
    const textBoxes = [
      { name: FormField.CONDITION_OF_CATHETER_DESCRIBE, sectionTitle: SectionTitle.IV, label: FormFieldLabel.CONDITION_OF_CATHETER_DESCRIBE },
      { name: FormField.NUMBER_INSERTION_ATTEMPTS, sectionTitle: SectionTitle.IV, label: FormFieldLabel.NUMBER_INSERTION_ATTEMPTS },
      { name: FormField.EXTERNAL_CATHETER_LENGTH, sectionTitle: SectionTitle.IV, label: FormFieldLabel.EXTERNAL_CATHETER_LENGTH }
    ];
    const multiselectDropdowns = [
      { name: FormField.SKIN_ASSESSMENT, sectionTitle: SectionTitle.SKIN_ASSESSMENT },
      { name: FormField.DRESSING_CARE, sectionTitle: SectionTitle.DRESSING_CARE },
      { name: FormField.PATENCY, sectionTitle: SectionTitle.PATENCY },
      { name: FormField.PATIENT_RESPONSE, sectionTitle: SectionTitle.PATIENT_RESPONSE }
    ];
    const textAreas1 = [{ name: FormField.NOTES, sectionTitle: SectionTitle.NOTES }];

    checkboxes.forEach(({ name, sectionTitle, label, parentLabel }) =>
      dataMap.set(
        name,
        createFormField({ name, type: FormFieldInputType.CHECK_BOX, contentIds: chartHelper.getFragmentContentIds(fragment, sectionTitle, name), label, parentLabel })
      )
    );
    dropdowns.forEach(({ name, sectionTitle, label }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.DROPDOWN, contentIds: chartHelper.getFragmentContentIds(fragment, sectionTitle, name), label }))
    );
    timeboxs.forEach(({ name, sectionTitle, label }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.TIME, value: chartHelper.getFragmentValue(fragment, sectionTitle, name), label }))
    );
    radioChoices.forEach(({ name, sectionTitle, label, errorLabel }) =>
      dataMap.set(
        name,
        createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, contentIds: chartHelper.getFragmentContentIds(fragment, sectionTitle, name), label, errorLabel })
      )
    );
    textBoxes.forEach(({ name, sectionTitle, label }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, value: chartHelper.getFragmentValue(fragment, sectionTitle, name), label }))
    );
    multiselectDropdowns.forEach(({ name, sectionTitle }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN, contentIds: chartHelper.getFragmentContentIds(fragment, sectionTitle, name) }))
    );
    textAreas1.forEach(({ name, sectionTitle }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA, value: chartHelper.getFragmentValue(fragment, sectionTitle, name) }))
    );

    const bodyRegionContent = chartHelper.getFragmentContentIds(fragment, SectionTitle.IV, FormField.BODY_REGION);
    if (!fragment || bodyRegionContent?.length) {
      dataMap.set(
        FormField.BODY_REGION,
        createFormField({ name: FormField.BODY_REGION, type: FormFieldInputType.DROPDOWN, contentIds: bodyRegionContent, label: FormFieldLabel.BODY_REGION })
      );
    }

    return dataMap;
  };

  buildFragment = () => {
    const { isAuthor } = this.props;
    const record = {
      chartTitle: 'IVs',
      fragmentTitle: 'IVs',
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = chartHelper.createFragmentId(NAV_ID.INTRAVENOUS);
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    let linkedFragmentId;
    const { loadedFragment } = this.state;
    if (!loadedFragment) {
      linkedFragmentId = fragmentId;
    } else {
      linkedFragmentId = loadedFragment.linkedFragmentId || loadedFragment.fragmentId;
    }
    return { ...basicInfo, linkedFragmentId, groupFragmentRef: isAuthor ? linkedFragmentId : undefined, chartData: cleanRecord } as ChartFragment;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.IV,
        fields: [
          FormField.IV_DISCONTINUED,
          FormField.BODY_REGION,
          FormField.LOCATION,
          FormField.TIME_DISCONTINUED,
          FormField.CONDITION_OF_CATHETER,
          FormField.CONDITION_OF_CATHETER_DESCRIBE,
          FormField.TYPE_IV_ACCESS,
          FormField.INSERTION_TIME,
          FormField.CATHETER_TYPE,
          FormField.CATHETER_SIZE,
          FormField.NUMBER_INSERTION_ATTEMPTS,
          FormField.TYPE_OF_LINE,
          FormField.NUMBER_OF_LUMENS,
          FormField.EXTERNAL_CATHETER_LENGTH,
          FormField.EXTERNAL_CATHETER_NOT_APPLICABLE
        ],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SITE_APPEARANCE,
        fields: [FormField.SITE_APPEARANCE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SKIN_ASSESSMENT,
        fields: [FormField.SKIN_ASSESSMENT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.DRESSING_CARE,
        fields: [FormField.DRESSING_CARE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PATENCY,
        fields: [FormField.PATENCY],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PATIENT_RESPONSE,
        fields: [FormField.PATIENT_RESPONSE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PUMP_IN_USE,
        fields: [FormField.PUMP_IN_USE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.NOTES,
        fields: [FormField.NOTES],
        formFieldMap
      })
    ];
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields(this.state.loadedFragment));

  handleSaveClick = () => {
    const { loadedFragment, fragments } = this.state;
    const { formFieldMap } = this.props;
    const curLocation = formFieldMap.get(FormField.LOCATION).value;
    const isSavingActiveIV = !formFieldMap.get(FormField.IV_DISCONTINUED).value;

    const needShowWarning = isSavingActiveIV && !loadedFragment && this.isLocationOfActiveIVsExisted(fragments, curLocation);
    if (needShowWarning) {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Please confirm.',
        message: 'You already have an active IV at this location. Are you sure you want to add another?',
        onOkClick: this.saveChart
      });
    } else {
      this.saveChart();
    }
  };

  isLocationOfActiveIVsExisted = (fragments, newLocation: string) => {
    const fragmentList = fragments.filter((frag) => {
      const location = chartHelper.getFragmentValue(frag, SectionTitle.IV, FormField.LOCATION);
      return frag.active && location === newLocation;
    });

    let result = false;
    const fragmentGroups = chartHelper.groupFragmentsById(fragmentList);
    fragmentGroups.forEach((fragmentGroup) => {
      const isIVDiscontinued = fragmentGroup.some((frag) => chartHelper.getFragmentValue(frag, SectionTitle.IV, FormField.IV_DISCONTINUED));
      if (!isIVDiscontinued) {
        result = true;
      }
    });

    return result;
  };

  saveChart = () => {
    const fragment = this.buildFragment();
    const defaultFormFields = this.buildFormFields(this.state.loadedFragment);
    return this.props.saveChart([fragment], { defaultFormFields, afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      fragment: this.state.loadedFragment
    };
    return <IVsView {...viewProps} />;
  }
}

const enhancers = [withRouter, withSavedPatientChartsPage, withChartLogic];

export { IVs as BaseIVs };
export default compose(...enhancers)(IVs);

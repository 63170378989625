import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  CONTAINER_MALE = 'containerMale',
  CONTAINER_FEMALE = 'containerFemale',
  URINATION_MODE = 'urinationMode',
  DIALYSIS_PERITONEAL = 'undergoingPeritonealDialysis',
  DIALYSIS_PERITONEAL_LAST_TREATMENT = 'peritonealDayOfLastTreatment',
  DIALYSIS_PERITONEAL_NEXT_TREATMENT = 'peritonealDayOfNextTreatment',
  DIALYSIS_RENAL = 'undergoingRenalDialysis',
  DIALYSIS_RENAL_LAST_TREATMENT = 'renalDayOfLastTreatment',
  DIALYSIS_RENAL_NEXT_TREATMENT = 'renalDayOfNextTreatment',
  URINE_COLOR = 'urineColor',
  URINE_CHARACTERISTICS = 'urineCharacteristics',
  URINE_ODOR = 'urineOdor',
  URINARY_NOTE = 'urinaryNotes',
  URINARY_NO_PROBLEMS = 'noUrinaryProblemsNoted',
  URINARY_ASSESSMENT = 'urinaryAssessment',
  HAS_URINARY_DIVERSION = 'urinaryDiversion',
  PENIS_ASSESSMENT_NO_PROBLEMS = 'penisAssessmentNoProblemsNoted',
  PENIS_ASSESSMENT = 'penisAssessment',
  SCROTUM_TESTES_ASSESSMENT_NO_PROBLEMS = 'scrotumAssessmentNoProblemsNoted',
  SCROTUM_TESTES_ASSESSMENT = 'scrotumAssessment',
  EXTERNAL_GENITALIA_NO_PROBLEMS = 'externalGenitaliaAssessmentNoProblemsNoted',
  EXTERNAL_GENITALIA = 'externalGenitaliaAssessment',
  EXTERNAL_GENITALIA_NOTE = 'genitaliaNotes'
}

export const getGenitourinaryAssessmentFormFields = (): ChartMetaFormFieldParams[] => [
  { name: FormField.NO_ASSESSMENT_REQUIRED, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CONTAINER_MALE, type: FormFieldInputType.CONTAINER },
  { name: FormField.CONTAINER_FEMALE, type: FormFieldInputType.CONTAINER },
  { name: FormField.URINATION_MODE, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.GENITOURINARY_ASSESSMENT.URINATION_MODE },
  { name: FormField.DIALYSIS_PERITONEAL, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.DIALYSIS_PERITONEAL_LAST_TREATMENT, type: FormFieldInputType.DATE, label: LanguageKeys.GENITOURINARY_ASSESSMENT.DAY_OF_LAST_TREATMENT },
  { name: FormField.DIALYSIS_PERITONEAL_NEXT_TREATMENT, type: FormFieldInputType.DATE, label: LanguageKeys.GENITOURINARY_ASSESSMENT.DAY_OF_NEXT_TREATMENT },
  { name: FormField.DIALYSIS_RENAL, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.DIALYSIS_RENAL_LAST_TREATMENT, type: FormFieldInputType.DATE, label: LanguageKeys.GENITOURINARY_ASSESSMENT.DAY_OF_LAST_TREATMENT },
  { name: FormField.DIALYSIS_RENAL_NEXT_TREATMENT, type: FormFieldInputType.DATE, label: LanguageKeys.GENITOURINARY_ASSESSMENT.DAY_OF_NEXT_TREATMENT },
  { name: FormField.URINE_COLOR, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.GENITOURINARY_ASSESSMENT.COLOR },
  { name: FormField.URINE_CHARACTERISTICS, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.GENITOURINARY_ASSESSMENT.CHARACTERISTICS },
  { name: FormField.URINE_ODOR, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.GENITOURINARY_ASSESSMENT.ODOR },
  { name: FormField.URINARY_NOTE, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.URINARY_NO_PROBLEMS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.URINARY_ASSESSMENT, type: FormFieldInputType.MULTISELECT_DROPDOWN, label: LanguageKeys.GENITOURINARY_ASSESSMENT.URINARY_ASSESSMENT },
  { name: FormField.HAS_URINARY_DIVERSION, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.PENIS_ASSESSMENT_NO_PROBLEMS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.PENIS_ASSESSMENT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.SCROTUM_TESTES_ASSESSMENT_NO_PROBLEMS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.SCROTUM_TESTES_ASSESSMENT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EXTERNAL_GENITALIA_NO_PROBLEMS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.EXTERNAL_GENITALIA, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EXTERNAL_GENITALIA_NOTE, type: FormFieldInputType.TEXT_AREA }
];

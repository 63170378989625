import { Component } from 'react';
import { SectionNavIdPatterns } from 'constants/app.constant';
import { SavedPatientCharting } from 'components/common';

class SavedSystemAssessments extends Component {
  static displayName = 'SavedSystemAssessments';

  render() {
    const viewProps = {
      chartTitle: 'Saved System Assessments',
      navIds: [SectionNavIdPatterns.SYSTEM_ASSESSMENTS]
    };
    return <SavedPatientCharting {...this.props} {...viewProps} />;
  }
}

export default SavedSystemAssessments;

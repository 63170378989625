import { Component, ErrorInfo } from 'react';
import { Redirect } from 'react-router-dom';
import { ELSCommonUIConstants } from '@els/els-ui-common-react';
import { errorService } from 'services';
import { Box } from 'components/common';
import { ELSIcon } from 'components/common/els';

interface ErrorBoundaryState {
  error: Error;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  static displayName = 'ErrorBoundary';

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error
    });
    errorService.ErrorService.log({ err: error, previousState: errorInfo, previousStateParams: null });
  }

  render() {
    if (this.state.error) {
      if (this.state.error.name === 'InvalidTokenError') {
        return <Redirect to={`/${ELSCommonUIConstants.security.States.NotAuthorized}`} />;
      }
      return (
        <Box p3 className="u-els-text-center">
          <div>
            <ELSIcon name="alert-unexpected-error" size="4x" />
          </div>
          <h2>Apologies, something went wrong.</h2>
          <div className="o-els-container o-els-container--2x">
            <div className="o-els-container u-els-font-size-h4">Please refresh the page and try again.</div>
            <div className="o-els-container">
              <button type="button" onClick={() => window.location.reload()} className="c-els-button c-els-button--primary c-els-button--small">
                Refresh page
              </button>
            </div>
            <div className="o-els-container u-els-font-size-h4">
              If refreshing the page does not resolve the issue, please try clearing your browser cache, closing this browser tab/window, and relaunching the application.
            </div>
          </div>
        </Box>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

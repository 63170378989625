import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { LastElement } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { appActions } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import withSaveAndDiscardHandlers from 'components/common/with-chart-logic/withSaveAndDiscardHandlers';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, Section } from './constants';
import MorseFallScaleView, { MorseFallScaleViewProps } from './MorseFallScaleView';

const { createFormField, getSubRecords } = chartHelper;

export interface MorseFallScaleProps extends ChartComponentProps {
  chartFragment: ChartFragment;
}

class MorseFallScale extends Component<MorseFallScaleProps> {
  static displayName = 'MorseFallScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    this.props.setLastFocusableElement(LastElement.ENABLED_SAVE_BUTTON);
  }

  componentDidUpdate(prevProps: Readonly<MorseFallScaleProps>) {
    const { initState, chartFragment } = this.props;
    if (chartFragment && prevProps.chartFragment !== chartFragment) {
      const defaultValue = getSubRecords({
        fragment: chartFragment,
        sectionTitle: Section.MORSE_FALL_SCALE
      });

      initState(this.buildDefaultFormFields(defaultValue));
    }
  }

  buildDefaultFormFields = (defaultValue?: any): Map<string, ChartMetaFormField> => {
    const dataMap = new Map<string, ChartMetaFormField>();

    dataMap.set(
      FormField.MORSE_FALL_SCALE,
      createFormField({
        name: FormField.MORSE_FALL_SCALE,
        type: FormFieldInputType.SCALE,
        errorLabel: 'Morse Fall Scale',
        value: defaultValue || null
      })
    );

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.HISTORY_OF_FALLING, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.SECONDARY_DIAGNOSIS, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.AMBULATORY_AID, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.IV_OR_IV_ACCESS, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.GAIT, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.MENTAL_STATUS, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    const { saveChart } = this.props;
    saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const totalScore = chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.MORSE_FALL_SCALE))?.score?.value;
    const chartContent = chartHelper.createRollUpContent(Number(totalScore));

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.MORSE_FALL_SCALE,
      records: [
        {
          sectionTitle: Section.MORSE_FALL_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.MORSE_FALL_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_MORSE_FALL_SCALE_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.MORSE_FALL_SCALE,
              title: 'Total Score',
              content: totalScore,
              linkedFormFieldIds: [
                FormField.HISTORY_OF_FALLING,
                FormField.SECONDARY_DIAGNOSIS,
                FormField.AMBULATORY_AID,
                FormField.IV_OR_IV_ACCESS,
                FormField.GAIT,
                FormField.MENTAL_STATUS
              ]
            }),
            buildPlainRecord({
              formField: FormField.RISK_LEVEL,
              content: chartContent.riskLevel,
              title: 'Risk Level'
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { formFieldMap } = this.props;

    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: MorseFallScaleViewProps = {
      formFieldMap,
      chartActionsProps,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };

    return <MorseFallScaleView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLastFocusableElement: (lastFocusableElement) => dispatch(appActions.setLastFocusableElement(lastFocusableElement))
});

export { MorseFallScale as BaseMorseFallScale };
export const MorseFallScaleStandalone = compose(withSavedPatientChartsPage, withChartLogic, connect(null, mapDispatchToProps))(MorseFallScale);
export default compose(withSaveAndDiscardHandlers, connect(null, mapDispatchToProps))(MorseFallScale);

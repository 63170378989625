/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { isEqual, last, orderBy } from 'lodash';
import { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Label, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { Locales } from 'constants/app.constant';
import { addDays, formatDate, toMoment } from 'helpers/datetime.helper';
import { chartConfig } from 'components/common/chart/config/chart.config';
import CustomBarTooltip from './CustomBarTooltip';

export interface HistoryBarChartProps {
  width?: number;
  height?: number;
  xLabel?: string;
  yLabel?: string;
  data: object & { date: string }[];
  dataKeys?: string[];
  interval?: number;
  yDomain?: number[] | string[] | Function[];
  locale?: Locales;
}

interface HistoryBarChartState {
  dataKey: string;
  data: object & { date: string }[];
}

class HistoryBarChart extends Component<HistoryBarChartProps, HistoryBarChartState> {
  isSameDay: boolean;

  static displayName = 'HistoryBarChart';

  constructor(props) {
    super(props);
    const data = this.addDateForXAxis(this.props.data);
    this.state = { data: this.toChartData(data), dataKey: null };
  }

  componentDidUpdate(prevProps: Readonly<HistoryBarChartProps>): void {
    if (!isEqual(prevProps.data, this.props.data)) {
      const data = this.addDateForXAxis(this.props.data);
      this.setState({ data: this.toChartData(data) });
    }
  }

  toChartData = (data: object[]) => [...orderBy(data, 'date')];

  addDateForXAxis = (data: object & { date: string }[]) => {
    const { locale } = this.props;
    this.isSameDay = this.isDataSetSameDay(data);
    if (this.isSameDay) {
      const appendDate = {
        date: formatDate({ date: addDays(data[0].date, 1).toDate(), locale }),
        generatedDataPoint: true
      };
      return [...data, appendDate];
    }

    return data;
  };

  isDataSetSameDay = (data: object & { date: string }[]): boolean => {
    if (data.length <= 1) {
      return true;
    }
    const orderedData = orderBy(data, 'date');
    const firstDay = toMoment(orderedData[0].date);
    const lastDay = toMoment(last(orderedData).date);
    return firstDay.isSame(lastDay, 'day');
  };

  render() {
    const { dataKeys, interval, width: originWidth, height: originHeight, xLabel, yLabel, yDomain: originYDomain } = this.props;
    const { data, dataKey } = this.state;
    const width = originWidth || chartConfig.bar.defaultWidth;
    const height = originHeight || chartConfig.bar.defaultHeight;
    const barMargin = chartConfig.bar.defaultMargin;
    const legendHeight = chartConfig.bar.defaultLegendHeight;
    const defaultYDomain = [(dataMin) => Math.floor(dataMin * 0.9), (dataMax) => Math.round(dataMax * 1.1)];
    const yDomain = originYDomain || defaultYDomain;
    return (
      <div>
        <BarChart width={width} height={height} data={data} margin={barMargin}>
          <Legend verticalAlign="top" height={legendHeight} wrapperStyle={{ textTransform: 'capitalize' }} />
          <CartesianGrid vertical={false} />
          <XAxis
            axisLine={false}
            dataKey="date"
            tickMargin={8}
            interval={this.isSameDay ? data.length - 2 : interval}
            padding={{ left: 50, right: 50, bottom: 80 }}
            stroke={chartConfig.labelColor}
          >
            {xLabel && <Label value={xLabel} offset={-20} position="insideBottom" />}
          </XAxis>
          <YAxis axisLine={false} tickLine={false} stroke={chartConfig.labelColor} domain={yDomain}>
            {yLabel && <Label value={yLabel} angle={-90} position="left" />}
          </YAxis>
          <Tooltip cursor={false} content={<CustomBarTooltip dataKey={dataKey} />} />
          {dataKeys.map((dk, index) => (
            <Bar
              onMouseOver={() => this.setState({ dataKey: dk })}
              onMouseLeave={() => this.setState({ dataKey: null })}
              key={dk}
              dataKey={dk}
              legendType="square"
              fill={index % 2 === 0 ? chartConfig.dataColors[0] : chartConfig.dataColors[1]}
            />
          ))}
        </BarChart>
      </div>
    );
  }
}

export default HistoryBarChart;

import { CaseStudySequence } from 'models/ui';
import CaseStudyInfo from '../case-study-info/CaseStudyInfo';
import CaseStudyQuiz from '../case-study-quiz/CaseStudyQuiz';

export interface CaseStudyPanelViewProps {
  assessmentId: number;
  disableClickOutside: boolean;
  sequenceChunk: CaseStudySequence;
  setDisableClickOutside: () => void;
  setShowQuiz: () => void;
  showQuiz: boolean;
}

const CaseStudyPanelView = (props: CaseStudyPanelViewProps) => (
  <section className="case-study-panel">
    {props.showQuiz ? (
      <CaseStudyQuiz assessmentId={props.assessmentId} sequenceChunk={props.sequenceChunk} setDisableClickOutside={props.setDisableClickOutside} />
    ) : (
      <CaseStudyInfo assessmentId={props.assessmentId} setShowQuiz={props.setShowQuiz} sequenceChunk={props.sequenceChunk} />
    )}
  </section>
);

CaseStudyPanelView.displayName = 'CaseStudyPanelView';
export default CaseStudyPanelView;

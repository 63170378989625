import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import HygieneDressingsComfortView from './HygieneDressingsComfortView';
import { Title } from '../shared/constants';

class HygieneDressingsComfort extends Component<ChartComponentProps> {
  static displayName = 'HygieneDressingsComfort';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const containers = [FormField.CONTAINER_NEWBORN_HYGIENE, FormField.CONTAINER_SHAVE];
    const checkBoxes = [FormField.ASSESSMENT_NOT_REQUIRED, FormField.SHAVE_NOT_APPLICATE];
    const multiSelectDropdowns = [
      FormField.NEWBORN_HYGIENE,
      FormField.BATH_SHOWER,
      FormField.COMFORT,
      FormField.DRESSING,
      FormField.HAIR_NAILS,
      FormField.LINENS_HOUSEKEEPING,
      FormField.MOUTH_CARE,
      FormField.SHAVE
    ];
    const textAreas = [
      FormField.NEWBORN_HYGIENE_NOTES,
      FormField.BATH_SHOWER_NOTES,
      FormField.COMFORT_NOTES,
      FormField.DRESSING_NOTES,
      FormField.HAIR_NAILS_NOTES,
      FormField.LINENS_HOUSEKEEPING_NOTES,
      FormField.MOUTH_CARE_NOTES,
      FormField.SHAVE_NOTES
    ];

    containers.forEach((container) => dataMap.set(container, createFormField({ name: container, type: FormFieldInputType.CONTAINER })));
    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    multiSelectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;

    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.HYGIENE_DRESSINGS_COMFORT, fields: [FormField.ASSESSMENT_NOT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.NEWBORN_HYGIENE, fields: [FormField.NEWBORN_HYGIENE, FormField.NEWBORN_HYGIENE_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.COMFORT, fields: [FormField.COMFORT, FormField.COMFORT_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.BATH_SHOWER, fields: [FormField.BATH_SHOWER, FormField.BATH_SHOWER_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.MOUTH_CARE, fields: [FormField.MOUTH_CARE, FormField.MOUTH_CARE_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.SHAVE, fields: [FormField.SHAVE_NOT_APPLICATE, FormField.SHAVE, FormField.SHAVE_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.HAIR_NAILS, fields: [FormField.HAIR_NAILS, FormField.HAIR_NAILS_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.DRESSING, fields: [FormField.DRESSING, FormField.DRESSING_NOTES], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.LINENS_HOUSEKEEPING, fields: [FormField.LINENS_HOUSEKEEPING, FormField.LINENS_HOUSEKEEPING_NOTES], formFieldMap })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.BASIC_NURSING_CARE,
      fragmentTitle: SectionTitle.HYGIENE_DRESSINGS_COMFORT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <HygieneDressingsComfortView {...viewProps} />;
  }
}
export { HygieneDressingsComfort as BaseHygieneDressingsComfort };
export default compose(withSavedPatientChartsPage, withChartLogic)(HygieneDressingsComfort);

import { ValidationRuleType } from 'models/enum';
import EmptyValidator from './EmptyValidator';
import FutureDateValidator from './FutureDateValidator';
import PastDateValidator from './PastDateValidator';
import RangeValidator from './RangeValidator';
import RegexValidator from './RegexValidator';
import SelectionValidator from './SelectionValidator';
import TimeRangeValidator from './TimeRangeValidator';
import { ExecuteParams } from './interface';

const MAPPING = {
  [ValidationRuleType.CHART_CONTENT_NOT_SELECTED]: new SelectionValidator(true),
  [ValidationRuleType.CHART_CONTENT_SELECTED]: new SelectionValidator(),
  [ValidationRuleType.RANGE]: new RangeValidator(),
  [ValidationRuleType.NOT_IN_RANGE]: new RangeValidator(true),
  [ValidationRuleType.REGEX_MATCH]: new RegexValidator(),
  [ValidationRuleType.REGEX_NOT_MATCH]: new RegexValidator(true),
  [ValidationRuleType.EMPTY]: new EmptyValidator(),
  [ValidationRuleType.NON_EMPTY]: new EmptyValidator(true),
  [ValidationRuleType.TIME_BEFORE]: new TimeRangeValidator(),
  [ValidationRuleType.TIME_AFTER]: new TimeRangeValidator(true),
  [ValidationRuleType.IS_FUTURE_DATE]: new FutureDateValidator(),
  [ValidationRuleType.IS_PAST_DATE]: new PastDateValidator(),
  [ValidationRuleType.NOT_FUTURE_DATE]: new FutureDateValidator(true)
};
const validatorManager = {
  getValidator(ruleType: string) {
    return { ruleType, validator: MAPPING[ruleType], execute: this.execute };
  },

  execute(executeParams: ExecuteParams) {
    if (this.ruleType === ValidationRuleType.ALWAYS_TRUE) return true;

    if (!this.validator) return false;

    return this.validator.execute(executeParams);
  }
};

export default validatorManager;

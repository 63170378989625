export enum FormField {
  APPEARANCE = 'mentalStatusExaminationAppearance',
  ATTENTION = 'attention',
  BEHAVIORAL_ACTIVITY = 'behavioralActivity',
  SPEECH = 'speech',
  THOUGHT_FORM = 'thoughtForm',
  THOUGHT_CONTENT = 'thoughtContent',
  SUICIDAL_IMPULSES = 'suicidalImpluses',
  HOMICIDAL_IMPULSES = 'homicidalImpulses',
  ORIENTATION = 'orientation',
  MEMORY = 'MEMORY',
  MOOD = 'mood',
  AFFECT = 'affect',
  JUDGMENT_INSIGHT = 'judgementInsight'
}

export enum FormFieldLabel {
  APPEARANCE = 'Appearance',
  ATTENTION = 'Attention',
  BEHAVIORAL_ACTIVITY = 'Behavioral Activity',
  SPEECH = 'Speech',
  THOUGHT_FORM = 'Thought Form',
  THOUGHT_CONTENT = 'Thought Content',
  SUICIDAL_IMPULSES = 'Suicidal Impulses',
  HOMICIDAL_IMPULSES = 'Homicidal Impulses',
  ORIENTATION = 'Orientation',
  MEMORY = 'Memory',
  MOOD = 'Mood',
  AFFECT = 'Affect',
  JUDGMENT_INSIGHT = 'Judgment and Insight'
}

export enum SectionTitle {
  MENTAL_STATUS_EXAMINATION = 'Mental Status Examination'
}

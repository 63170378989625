import cx from 'classnames';
import { isNil } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { TimeSpentRS } from 'models/api-response';
import { GradingOption, GradingResult } from 'models/enum';
import { DateTimeAmPmFormatByLocale, Locales } from 'constants/app.constant';
import { appHelper, assignmentHelper, dateTimeHelper } from 'helpers';
import { ELSFlex, ELSFlexItem, ELSIcon, ELSTooltip } from 'components/common/els';
import { Label } from './constants';
import AssessmentTile from './AssessmentTile';
import './assessment.statistic.scss';

export interface AssessmentStatisticProps {
  className?: string;
  gradeType?: GradingOption;
  gradeStatus?: GradingResult;
  score?: string;
  startedAt: string;
  completedAt: string;
  durationTime: number;
  completedChartsLength: number;
  assessmentId?: number;
  timeSpentData?: TimeSpentRS;
  locale?: Locales;
  isInProgressGradingEnabled?: boolean;
}

export const renderTimeSpent = (overallAssignmentTime: number | undefined, durationTime?: number) => {
  // getDuration Depreciated - Will use Time Spent API for newer assignments and getDuration for older assignments
  const legacyResult = appHelper.getDuration(durationTime, true);
  const formattedTimeSpent = isNil(overallAssignmentTime) ? overallAssignmentTime : appHelper.timeSpentFormatter(overallAssignmentTime);
  return !isNil(overallAssignmentTime) ? (
    <>
      <span className="u-els-font-size-h2">{formattedTimeSpent}</span>
    </>
  ) : (
    legacyResult?.detail.map((item) => (
      <Fragment key={item.unit}>
        <span className="u-els-font-size-display">{item.value}</span>
        <span>{item.unit}</span>
      </Fragment>
    ))
  );
};

const AssignmentStarted = ({ startedAt }: { startedAt: string }) => (
  <AssessmentTile>
    <div className="assessment-statistic__card-title">
      <span className="checkmark">
        <ELSIcon name="checkmark" size="1o2" />
      </span>
      {Label.ASSIGNMENT_STARTED}
    </div>
    <div className="assessment-statistic__card-value">{startedAt}</div>
  </AssessmentTile>
);

const AssignmentGrade = ({ grade }: { grade: string }) => (
  <AssessmentTile align="center">
    <div className="u-els-font-size-meta">
      <p>{Label.GRADE}</p>
    </div>
    <div className="u-els-font-size-display">{grade || assignmentHelper.getPrettyGradingResult(GradingResult.NA)}</div>
  </AssessmentTile>
);

const timeSpentTooltipRenderer = () => (
  <span className="u-els-margin-left-1o2">
    <ELSTooltip wrapperClassName="limit-max-width" content={Label.TOTAL_TIME_REPRESENTS} showClose showWhenHover>
      <ELSIcon prefix="gizmo" name="information" size="1x" />
    </ELSTooltip>
  </span>
);

const assignmentDateTimeStampRenderer = (timeString: string, locale: Locales): string =>
  dateTimeHelper.formatDate({
    date: timeString ? dateTimeHelper.toMomentWithParsers(timeString).toDate() : undefined,
    locale,
    includeTime: true,
    customFormat: DateTimeAmPmFormatByLocale[locale],
    defaultIfEmpty: 'na'
  });

export const AssessmentStatistic = (props: AssessmentStatisticProps) => {
  const { className, durationTime, startedAt, completedAt, completedChartsLength, assessmentId, timeSpentData, locale, isInProgressGradingEnabled } = props;
  const [overallAssignmentTime, setOverallAssignmentTime] = useState(0);
  const shouldShowAssignmentStartedTitle = isInProgressGradingEnabled && !completedAt && props.gradeType !== GradingOption.NOT_GRADED;

  useEffect(() => {
    if (assessmentId) {
      if (timeSpentData && timeSpentData.charts.length !== 0) {
        setOverallAssignmentTime(timeSpentData.assessmentTotalTime);
      } else {
        setOverallAssignmentTime(undefined);
      }
    }
  }, [assessmentId]);

  return (
    <div className={cx('assessment-statistic', className)}>
      <ELSFlex middle wrap>
        <ELSFlexItem className="u-els-padding-1o2">
          {props.gradeType === GradingOption.NOT_GRADED && <AssignmentStarted startedAt={assignmentDateTimeStampRenderer(startedAt, locale)} />}
          {props.gradeType === GradingOption.PASS_FAIL && <AssignmentGrade grade={assignmentHelper.getPrettyGradingResult(props.gradeStatus)} />}
          {props.gradeType === GradingOption.SCORED && <AssignmentGrade grade={props.score} />}
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-1o2">
          <AssessmentTile>
            <div className="assessment-statistic__card-title">
              <span className="checkmark">
                <ELSIcon name="checkmark" size="1o2" />
              </span>
              <p>{shouldShowAssignmentStartedTitle ? Label.ASSIGNMENT_STARTED : Label.ASSIGNMENT_SUBMITTED}</p>
            </div>
            <div className="assessment-statistic__card-value">
              {shouldShowAssignmentStartedTitle ? assignmentDateTimeStampRenderer(startedAt, locale) : assignmentDateTimeStampRenderer(completedAt, locale)}
            </div>
          </AssessmentTile>
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-1o2">
          <AssessmentTile align="center">
            <div>
              <ELSIcon name="clock" customClass="clock" size="1x" align="middle" />
              <span className="u-els-font-size-meta">{Label.TOTAL_TIME_SPENT}</span>
            </div>
            <div>
              {renderTimeSpent(overallAssignmentTime, durationTime)}
              {timeSpentTooltipRenderer()}
            </div>
          </AssessmentTile>
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-1o2">
          <AssessmentTile align="center">
            <div className="u-els-font-size-meta">{Label.CHARTS_COMPLETED}</div>
            <div>
              <span className="u-els-font-size-display">{completedChartsLength}</span>
              <span>{Label.CHARTS}</span>
            </div>
          </AssessmentTile>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  );
};

AssessmentStatistic.displayName = 'AssessmentStatistic';
export default AssessmentStatistic;

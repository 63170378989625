import { Component, ReactElement } from 'react';
import { InfoText } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';

interface SchedulingWrapperProps {
  children: ReactElement;
  title: string;
  infoText: string;
}

class SchedulingWrapper extends Component<SchedulingWrapperProps> {
  static defaultProps = {
    infoText: 'Once the assignment is visible, you can no longer hide it.'
  };

  render() {
    return (
      <div className="scheduling-wrapper">
        <ELSFlex className="u-els-margin-bottom" spaceBetween>
          <ELSFlexItem>
            <h3>{this.props.title}</h3>
          </ELSFlexItem>
          <InfoText text={this.props.infoText} />
        </ELSFlex>
        {this.props.children}
      </div>
    );
  }
}

export default SchedulingWrapper;

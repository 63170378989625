export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  PERCEPTION = 'perception',
  PERCEPTION_QUESTION = 'perceptionHealthSituationQuestion',
  SUPPORT = 'support',
  SUPPORT_QUESTION = 'supportWhomGiveSupportQuestion',
  COPING = 'coping',
  COPING_QUESTION = 'copingDealingWithHealthSituationQuestion',
  ANXIETY = 'anxiety',
  ANXIETY_QUESTION = 'anxietyHowHealthSituationAffect',
  EVALUATION_ANXIETY = 'objectiveEvaluationOfAnxiety',
  NOTES = 'notes',
  TOTAL_SCORE = 'totalScore'
}

export enum SectionTitle {
  ANXIETY = 'Anxiety',
  COPING = 'Coping',
  EVALUATION_ANXIETY = 'Objective Evaluation of anxiety',
  PERCEPTION = 'Perception',
  PSYCHOSOCIAL_ASSESSMENT = 'Psychosocial Assessment',
  SUPPORT = 'Support',
  TOTAL_SCORE = 'Total Score'
}

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { FormField, SectionLabel } from './constants';
import ChildRiskAssessmentReadOnly from './ChildRiskAssessmentReadOnly';
import './child.risk.assessment.scss';

interface ChildRiskAssessmentViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const ChildRiskAssessmentView = (props: ChildRiskAssessmentViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, formFieldMap } = props;
  return (
    <div className="child-risk-assessment">
      {isLocked ? (
        <ChildRiskAssessmentReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Child Risk Assessment</h1>
          <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />

          <h3>{`${SectionLabel.PRESENCE_OF_RISK}:`}</h3>
          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.DENTAL_CARE)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.DENTAL_CARE_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.CHILD_PASSENGER_SAFETY)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.CHILD_PASSENGER_SAFETY_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.ABUSE_MALTREATMENT)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.ABUSE_MALTREATMENT_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.SLEEP)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.SLEEP_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.PLAYGROUND_INJURIES)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.PLAYGROUND_INJURIES_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.POISONINGS)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.POISONINGS_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.WATER_SAFETY)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="chart-input__textarea--margin-top" formField={formFieldMap.get(FormField.WATER_SAFETY_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.RESIDENTIAL_FIRES)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.RESIDENTIAL_FIRES_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={formFieldMap.get(FormField.BICYCLE_RELATED_INJURIES)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField
              className="chart-input__textarea--margin-top"
              formField={formFieldMap.get(FormField.BICYCLE_RELATED_INJURIES_DESCRIBE)}
              formSubmittedCount={formSubmittedCount}
            />
          </Box>

          <SectionDivider />
          <p>{`${SectionLabel.APPROPRIATE_REFERRAL}.`}</p>

          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

ChildRiskAssessmentView.displayName = 'ChildRiskAssessmentView';
export default ChildRiskAssessmentView;

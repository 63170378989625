import { LanguageKeys } from 'lang';
import { PharmacyRecord } from 'models/api-response';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ClinicalKeyResourceCard, ErrorCard, ErrorFormField } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import PharmacyOrderTable from './PharmacyOrderTable';
import './pharmacy.scss';
import Notice from '../shared/Notice';
import { FormField as CommonFormField } from '../shared/constants';

export interface PharmacyViewProps extends ChartViewProps {
  chartHistory: PharmacyRecord[];
  isSpecialDoseSectionVisible: boolean;
  deleteHistory: Function;
  onMarLinkClick: Function;
}

const PharmacyView = (props: PharmacyViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, chartHistory, deleteHistory, isSpecialDoseSectionVisible, onMarLinkClick } = props;
  const dateFieldClassName = 'pharmacy-order-entry__input-date-field';
  const customDoseClassName = 'pharmacy-order-entry__input-dose-field';
  const customBottomMarginFormField = {
    useDefaultMargin: false,
    className: 'u-els-margin-bottom-1x1o2'
  };

  return (
    <div className="pharmacy-order-entry">
      <h1>{LanguageKeys.PHARMACY.PHARMACY}</h1>
      <Notice />
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem className="u-els-margin-top pharmacy-order-entry__chart-input">
            <h2 className="u-els-margin-bottom">Chart Inputs</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_ITEM)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            <div className="u-els-margin-bottom">
              <ClinicalKeyResourceCard
                drugName={
                  formFieldMap.get(FormField.ORDER_ITEM)?.value
                    ? chartHelper.getMedicationName(formFieldMap.get(FormField.ORDER_ITEM)?.value, formFieldMap.get(FormField.ORDER_ITEM)?.chartContent)
                    : undefined
                }
              />
            </div>
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.ORDER_ITEM_OTHER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.ORDER_TYPE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.ROUTE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.ROUTE_OTHER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.MEDICATION_DOSE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.MEDICATION_DOSE_TEXTBOX)} formSubmittedCount={formSubmittedCount} />
            {isSpecialDoseSectionVisible && (
              <>
                <h3 className="u-els-margin-bottom u-els-margin-top-2x">{LanguageKeys.PHARMACY.SPECIAL_DOSE}</h3>
                <ELSFlex left wrap>
                  <ELSFlexItem w="1o2" md="1o1">
                    <ErrorFormField
                      useDefaultMargin={false}
                      formField={formFieldMap.get(FormField.SPECIAL_DOSE_DOSE)}
                      formSubmittedCount={formSubmittedCount}
                      className={`u-els-margin-bottom-1x1o2 ${customDoseClassName}`}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2" md="1o1">
                    <ErrorFormField
                      useDefaultMargin={false}
                      formField={formFieldMap.get(FormField.SPECIAL_DOSE_UNIT)}
                      formSubmittedCount={formSubmittedCount}
                      className={`u-els-margin-bottom-1x1o2 ${customDoseClassName}`}
                    />
                  </ELSFlexItem>
                </ELSFlex>
              </>
            )}
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.MEDICATION_FORM)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.MEDICATION_FORM_OTHER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.FREQUENCY)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <h3 className="u-els-margin-bottom u-els-margin-top-1x">{LanguageKeys.PHARMACY.ORDER_START}</h3>
                <ErrorFormField
                  useDefaultMargin={false}
                  formField={formFieldMap.get(FormField.ORDER_START_DATE)}
                  formSubmittedCount={formSubmittedCount}
                  className={`u-els-margin-bottom-1x1o2 ${dateFieldClassName}`}
                />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.ORDER_START_TIME)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField
                  useDefaultMargin={false}
                  formField={formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-bottom-1x1o2 pharmacy-order-entry__input-offset-time"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <h3 className="u-els-margin-bottom u-els-margin-top-1x">{LanguageKeys.PHARMACY.ORDER_STOP}</h3>
                <ErrorFormField
                  useDefaultMargin={false}
                  formField={formFieldMap.get(FormField.ORDER_STOP_DATE)}
                  formSubmittedCount={formSubmittedCount}
                  className={`u-els-margin-bottom-1x1o2 ${dateFieldClassName}`}
                />
                <ErrorFormField {...customBottomMarginFormField} formField={formFieldMap.get(FormField.ORDER_STOP_TIME)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField
                  useDefaultMargin={false}
                  formField={formFieldMap.get(CommonFormField.ORDER_STOP_TIME_OFFSET)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-bottom-1x1o2 pharmacy-order-entry__input-offset-time"
                />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField
              {...customBottomMarginFormField}
              formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTIONS)}
              formSubmittedCount={formSubmittedCount}
              className="chart-input__textarea--fixed-max-width"
            />
          </ELSFlexItem>
          <ELSFlexItem className="o-els-flex-layout__item--grow u-els-margin-top chart-input__scroll-table-container">
            <h2>Orders</h2>
            <PharmacyOrderTable chartHistory={chartHistory} deleteHistory={deleteHistory} />
            <Box mt>
              <ELSFlex>
                <ELSFlexItem className="u-els-margin-right">
                  <ELSIcon prefix="gizmo" name="information" size="1x" />
                </ELSFlexItem>
                <ELSFlexItem grow>
                  <p>
                    <span>{'View a list of all pharmacy orders and their results in the '}</span>
                    <ELSButton type="link" text="MAR" onClick={onMarLinkClick} />
                    <span>.</span>
                  </p>
                </ELSFlexItem>
              </ELSFlex>
            </Box>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

export default PharmacyView;

import cx from 'classnames';
import { Component } from 'react';
import { defaultCustomRender } from 'helpers/chartRender.helper';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import { FormField, LaborProgressHistoryProps } from './constants';

export const fHRRenderer = (field) => (row) => <div className={cx({ 'row--deleted': !row.active })}>{`${row[field]} ${row.monitorMode || ''}`}</div>;
class LaborProgressFetalAssessmentHistoryTable extends Component<LaborProgressHistoryProps> {
  static displayName = 'LaborProgressFetalAssessmentHistoryTable';

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const actionHeader = deleteHistory ? { field: 'actionDelete' } : {};
    const dataSource = {
      headers: [
        { text: 'Chart Time', field: 'chartingAt', sortable: true },
        { text: 'FHR', field: 'baselineBpm', customRender: fHRRenderer(FormField.BASELINE_BPM) },
        { text: 'Variability', field: 'variability', customRender: defaultCustomRender(FormField.VARIABILITY) },
        { text: 'Accelerations', field: 'accelerations', customRender: defaultCustomRender(FormField.ACCELERATIONS) },
        { text: 'Decelerations', field: 'decelerations', customRender: defaultCustomRender(FormField.DECELERATIONS) },
        { text: 'Notes', field: 'notes', customRender: defaultCustomRender(FormField.NOTES) },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { ...actionHeader }
      ],
      css: ['column__chart-time', '', '', '', '', '', 'column__entry-by', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt className="labor-progress-history">
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

export default LaborProgressFetalAssessmentHistoryTable;

import cx from 'classnames';
import { LanguageKeys } from 'lang';
import { useEffect, useState } from 'react';
import { ChartFragment } from 'models/api-response';
import { NAV_ID, RoutePath } from 'constants/app.constant';
import { Box, HorizontalScrollableContainer } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import VitalSignsChart from 'components/features/chart/vital-signs/vital-signs/VitalSignsChart';
import { SummaryViewProps } from './models';
import AtoEAssessmentHistory from '../vital-signs/vital-signs/au/AtoEAssessmentHistory';
import { SavedVitalSignsReview } from '../vital-signs/vital-signs/au/SavedVitalSignsReview';

const SummaryViewAU = ({
  loadData,
  intakeOutputTotalPerDayLatest,
  bloodGlucoseLatest,
  painAssessmentLatest,
  onNavigation,
  chartHistory,
  intl,
  vitalSignsHistoryFragments
}: SummaryViewProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<ChartFragment>(null);
  const [selectedRecordId, setSelectedRecordId] = useState<string>('');
  const NO_DATA_ENTERED = 'No data entered';
  const { chartingAt: painChartingAt, numericPainLevel, numericPainLevelOnMovement } = painAssessmentLatest ?? {};
  const { chartingAt: bloodGlucoseChartingAt, glucoseReading } = bloodGlucoseLatest ?? {};

  useEffect(() => {
    if (selectedRecordId) {
      const record = vitalSignsHistoryFragments.find((fragment) => fragment.fragmentId === selectedRecordId);
      setSelectedRecord(record);
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
    }
  }, [selectedRecordId, chartHistory]);

  const handleOpenSidebar = (id: string) => {
    setSelectedRecordId(id);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedRecordId('');
  };

  const painContent =
    painChartingAt && (numericPainLevel || numericPainLevelOnMovement)
      ? intl.formatMessage(
          {
            id: LanguageKeys.SUMMARY.PAIN_SUMMARY
          },
          {
            chartingAt: painChartingAt,
            numericPainLevel,
            numericPainLevelOnMovement
          }
        )
      : NO_DATA_ENTERED;

  const intakeContent = intakeOutputTotalPerDayLatest?.intake ? `Daily Total — ${intakeOutputTotalPerDayLatest.intake} mL` : NO_DATA_ENTERED;
  const outputContent = intakeOutputTotalPerDayLatest?.output ? `Daily Total — ${intakeOutputTotalPerDayLatest.output} mL` : NO_DATA_ENTERED;
  const bloodGlucoseContent = bloodGlucoseLatest
    ? intl.formatMessage(
        {
          id: LanguageKeys.SUMMARY.BLOOD_GLUCOSE_SUMMARY
        },
        {
          chartingAt: bloodGlucoseChartingAt,
          glucoseReading,
          unit: intl.formatMessage({ id: LanguageKeys.BLOOD_GLUCOSE.GLUCOSE_READING_UNIT })
        }
      )
    : NO_DATA_ENTERED;

  const cardClassName = 'card card--border';
  return (
    <div className="summary">
      <h1>{LanguageKeys.SUMMARY.SUMMARY}</h1>
      <Box className="summary__patient-monitoring" mt2>
        <h2>{LanguageKeys.SUMMARY.PATIENT_MONITORING}</h2>
        <Box mt>
          <ELSFlex spaceBetween>
            <p>{intl.formatMessage({ id: LanguageKeys.SUMMARY.VITAL_SIGNS })}</p>
            <ELSButton type="link" text="+ add" onClick={() => onNavigation(RoutePath.student.vitalSigns.vitalSigns)} />
          </ELSFlex>
          <div className="u-els-margin-top-2x">
            <SavedVitalSignsReview loadData={loadData} isSidebarOpen={isSidebarOpen} onClose={handleCloseSidebar} selectedRecord={selectedRecord} navId={NAV_ID.VITALSIGNS} />

            <AtoEAssessmentHistory
              dataSource={{ data: chartHistory }}
              showDeleteColumn={false}
              sidebarProps={{
                onOpen: handleOpenSidebar
              }}
            />
          </div>

          <div className="u-els-margin-top-2x">
            <HorizontalScrollableContainer>
              {chartHistory && <VitalSignsChart chartHistory={chartHistory} isTitleHidden isUseNewGraphStyle={false} displaySingleChartOnSummaryPage />}
            </HorizontalScrollableContainer>
          </div>
        </Box>
        <ELSFlex className="u-els-margin-top-2x u-flex-gutter-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <Box className="u-els-margin-top">
              <ELSFlex spaceBetween>
                <p>{LanguageKeys.SUMMARY.PAIN}</p>
                <ELSButton type="link" text="+ add" onClick={() => onNavigation(RoutePath.student.systemAssessment.painAssessment)} />
              </ELSFlex>
              <p className={cx(cardClassName, { 'card--italic': painContent === NO_DATA_ENTERED })}>{painContent}</p>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box className="u-els-margin-top">
              <ELSFlex spaceBetween>
                <p>{LanguageKeys.SUMMARY.BLOOD_GLUCOSE}</p>
                <ELSButton type="link" text="+ add" onClick={() => onNavigation(RoutePath.student.vitalSigns.bloodGlucose)} />
              </ELSFlex>
              <p className={cx(cardClassName, { 'card--italic': bloodGlucoseContent === NO_DATA_ENTERED })}>{bloodGlucoseContent}</p>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box className="u-els-margin-top">
              <ELSFlex spaceBetween>
                <p>{LanguageKeys.SUMMARY.INTAKE}</p>
                <ELSButton type="link" text="+ add" onClick={() => onNavigation(intl.formatMessage({ id: LanguageKeys.SUMMARY.INTAKE_OUTPUT_ROUTE_PATH }))} />
              </ELSFlex>
              <p className={cx(cardClassName, { 'card--italic': intakeContent === NO_DATA_ENTERED })}>{intakeContent}</p>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box className="u-els-margin-top">
              <ELSFlex spaceBetween>
                <p>{LanguageKeys.SUMMARY.OUTPUT}</p>
                <ELSButton type="link" text="+ add" onClick={() => onNavigation(intl.formatMessage({ id: LanguageKeys.SUMMARY.INTAKE_OUTPUT_ROUTE_PATH }))} />
              </ELSFlex>
              <p className={cx(cardClassName, { 'card--italic': outputContent === NO_DATA_ENTERED })}>{outputContent}</p>
            </Box>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
    </div>
  );
};

SummaryViewAU.displayName = 'SummaryViewAU';
export default SummaryViewAU;

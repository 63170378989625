import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import InfantToddlerAssessmentView from './InfantToddlerAssessmentView';
import { FormField, SectionTitle } from './constant';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface InfantToddlerAssessmentProps extends ChartComponentProps {
  navigateToLandingPage: VoidFunction;
}

interface InfantToddlerAssessmentState {
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  isLocked: boolean;
}

class InfantToddlerAssessment extends Component<InfantToddlerAssessmentProps, InfantToddlerAssessmentState> {
  static displayName = 'InfantToddlerAssessment';

  constructor(props) {
    super(props);
    this.state = {
      fragment: null,
      statusFragment: null,
      isLocked: false
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.INFANT_TODDLER_ASSESSMENT, NAV_ID.ADMISSION_HISTORY);
        this.setState(
          {
            fragment: chartFragment,
            statusFragment,
            isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
          },
          () => {
            this.props.initState(this.buildDefaultFormFields());
          }
        );
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentContentIds, getFragmentValue } = chartHelper;
    const dataMap = new Map();
    const radioChoice = new Map();
    radioChoice.set(FormField.CHILD_PASSENGER_SAFETY_SELECT, 'Child passenger safety:');
    radioChoice.set(FormField.ABUSE_MALTREATMENT_SELECT, 'Abuse/maltreatment:');
    radioChoice.set(FormField.SLEEP_SELECT, 'Sleep:');
    radioChoice.forEach((label, name) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.RADIO_CHOICE,
          label,
          contentIds: getFragmentContentIds(fragment, SectionTitle.INDICATE_THE_PRESENCE_OF_RISK, name)
        })
      )
    );
    const describeTextArea = new Map();
    describeTextArea.set(FormField.CHILD_PASSENGER_SAFETY_DESCRIBE, 'Describe');
    describeTextArea.set(FormField.ABUSE_MALTREATMENT_DESCRIBE, 'Describe');
    describeTextArea.set(FormField.SLEEP_DESCRIBE, 'Describe');
    describeTextArea.forEach((label, name) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.TEXT_AREA,
          label,
          contentIds: getFragmentContentIds(fragment, SectionTitle.INDICATE_THE_PRESENCE_OF_RISK, name),
          value: getFragmentValue(fragment, SectionTitle.INDICATE_THE_PRESENCE_OF_RISK, name)
        })
      )
    );
    return dataMap;
  };

  handleSaveClick = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap, handleSaveClick } = this.props;
    const CHART_NAME = SectionTitle.INDICATE_THE_PRESENCE_OF_RISK;
    const records = [];
    formFieldMap.forEach((value, key, map) => {
      records.push(buildPatientRecord(map, key));
    });
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.INDICATE_THE_PRESENCE_OF_RISK,
          records
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    const fragmentData = { ...basicInfo, chartData: cleanRecord };
    handleSaveClick([fragmentData], { afterSave: this.props.afterSave });
  };

  render() {
    const { isLocked, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      cancelButtonText: 'Cancel',
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick,
      saveButtonHasIcon: true,
      saveButtonText: 'Save and Continue'
    };
    const viewProps = {
      isLocked,
      statusFragment,
      chartActionsProps,
      formSubmittedCount,
      fragment,
      formFieldMap
    };
    return <InfantToddlerAssessmentView {...viewProps} />;
  }
}

export { InfantToddlerAssessment as BaseInfantToddlerAssessment };
export default compose(withAdmissionHistory, withChartLogic)(InfantToddlerAssessment);

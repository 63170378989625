import { Box, ChartActions, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import './psychosocial.assessment.scss';

export interface PsychosocialAssessmentViewProps extends ChartViewProps {
  totalScore: number;
  onNoAssessmentRequiredChange: Function;
  isEmbeddedChart?: boolean;
}

const PsychosocialAssessmentView = (props: PsychosocialAssessmentViewProps) => {
  const { chartActionsProps, formSubmittedCount, formFieldMap, onNoAssessmentRequiredChange, totalScore, isEmbeddedChart } = props;

  return (
    <div className="psychosocial-assessment">
      <h1 className="u-els-margin-top">{SectionTitle.PSYCHOSOCIAL_ASSESSMENT}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} isEmbeddedChart={isEmbeddedChart} />
      <p>If patient is under the age of 6, direct questions to parent or guardian.</p>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} onChange={onNoAssessmentRequiredChange} />

      <Box mt2>
        <ErrorFormField
          formField={formFieldMap.get(FormField.PERCEPTION)}
          formFieldMap={formFieldMap}
          formSubmittedCount={formSubmittedCount}
          options={{ showScaleRollUp: false }}
        />

        <ErrorFormField formField={formFieldMap.get(FormField.SUPPORT)} formFieldMap={formFieldMap} formSubmittedCount={formSubmittedCount} options={{ showScaleRollUp: false }} />

        <ErrorFormField formField={formFieldMap.get(FormField.COPING)} formFieldMap={formFieldMap} formSubmittedCount={formSubmittedCount} options={{ showScaleRollUp: false }} />

        <ErrorFormField formField={formFieldMap.get(FormField.ANXIETY)} formFieldMap={formFieldMap} formSubmittedCount={formSubmittedCount} options={{ showScaleRollUp: false }} />

        <div>
          <h2>{SectionTitle.EVALUATION_ANXIETY}</h2>
          <ErrorFormField formField={formFieldMap.get(FormField.EVALUATION_ANXIETY)} formSubmittedCount={formSubmittedCount} />
        </div>

        <SectionDivider />

        <div>
          <ELSFlex spaceBetween>
            <ELSFlexItem middle>
              <h2>{SectionTitle.TOTAL_SCORE}</h2>
            </ELSFlexItem>
            <ELSFlexItem middle>
              <b className="score-tag">{`Total Score: ${totalScore}`}</b>
            </ELSFlexItem>
          </ELSFlex>
          <p className="u-els-margin-top-2x">
            <span className="special-character">•</span>
            <span>A low score is indicative of a healthy psychosocial state.</span>
          </p>
        </div>

        <ErrorFormField formField={formFieldMap.get(FormField.NOTES)} formSubmittedCount={formSubmittedCount} />
      </Box>
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

PsychosocialAssessmentView.displayName = 'PsychosocialAssessmentView';
export default PsychosocialAssessmentView;

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ELSSkipLinkGroup } from '@els/els-component-skip-link-group-react';
import { ELSModalServiceType } from 'models/els';
import { DemoSource } from 'models/enum';
import { SkipLinkSection } from 'models/ui';
import { AppConstant, Locales, RoutePath, assignmentIdType } from 'constants/app.constant';
import { apiHelper, appHelper, assignmentHelper } from 'helpers';
import { assignmentService, cookieService, featureService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { studentActions, studentSelectors } from 'redux/ducks/student';
import { LoseDataWarning } from 'components/common';
import { ELSIcon, ELSWithModalService } from 'components/common/els';
import './page.header.scss';

interface MatchParams {
  assessmentId: string;
}

interface PageHeaderProps {
  children?: ReactNode;
  title: string;
  showWarningOnClose?: boolean;
  modalService: ELSModalServiceType;
  assignmentDemoSource: string;
  assignmentDemoUrl: string;
  skipLinkSections: SkipLinkSection[];
  locale: Locales;
  setAssignmentDemoSource: Function;
  setAssignmentDemoUrl: Function;
}

interface PageHeaderState {
  showPendoHeader: boolean;
  cknTicURL: string;
}

class PageHeader extends Component<PageHeaderProps & RouteComponentProps<MatchParams>, PageHeaderState> {
  isDemo;
  pageTitle;
  ticUrlGeneratorId = 'tic-url-generator';

  constructor(props) {
    super(props);
    this.isDemo = appHelper.isDemoMode();
    if (this.isDemo) {
      this.setPageHeaderTitle();
    }
    this.state = {
      showPendoHeader: false,
      cknTicURL: ''
    };
  }

  componentDidMount() {
    const { locale } = this.props;
    return appHelper.useLoader(
      Promise.all([featureService.isShowPendoHeaderUS(), featureService.isShowPendoHeaderANZ()]).then(([isShowPendoHeaderUS, isShowPendoHeaderANZ]) => {
        this.setState({ showPendoHeader: (locale === Locales.EN_US && isShowPendoHeaderUS) || (locale === Locales.EN_AU && isShowPendoHeaderANZ) });
      })
    );
  }

  setPageHeaderTitle = () => {
    const { assignmentDemoSource } = this.props;
    switch (assignmentDemoSource) {
      case DemoSource.CASE_STUDY_DEMO:
        this.pageTitle = 'Back to Demo View';
        break;
      case DemoSource.ASSIGNMENT_CREATION_QUICK_VIEW:
        this.pageTitle = 'Back to Quick View';
        break;
      default:
        this.pageTitle = '';
    }
  };

  confirmToSherpath = () => {
    const { modalService, showWarningOnClose } = this.props;
    if (showWarningOnClose) {
      const modalId = 'loseDataModal';
      modalService.openModal({
        modalId,
        content: (
          <LoseDataWarning
            message="You are leaving SimChart, any unsaved changes will be lost. Do you wish to leave this page?"
            onLeaveClick={() => {
              modalService.closeModal(modalId);
              navigationService.navigateToAppLinkSource(undefined);
            }}
            onStayClick={() => modalService.closeModal(modalId)}
          />
        )
      });
    } else {
      navigationService.navigateToAppLinkSource(undefined);
    }
  };

  handleClose = () => {
    const { assignmentDemoUrl, history, match, setAssignmentDemoSource, setAssignmentDemoUrl } = this.props;
    const assignmentIdStatus = assignmentHelper.checkForFakeId(assignmentDemoUrl);
    // Ins Demo for Not Visible assignment has a fake assignmentId && Stu Demo View is Case Study Landing
    const notVisible = assignmentIdStatus === assignmentIdType.fakeAssignmentId && match.path === RoutePath.student.caseStudyLanding;
    // Ins Demo for Visible assignment has a real assignmentId && Stu Demo View is Case Study Landing
    const visible = assignmentIdStatus === assignmentIdType.realAssignmentId && match.path === RoutePath.student.caseStudyLanding;
    const isCreatingAssignment = assignmentDemoUrl === RoutePath.instructor.contentSelection;

    if (notVisible) {
      // Workaround to avoid 500 error on Courseware Not Visible Student Demo View
      history.goBack();
    } else if (visible || isCreatingAssignment) {
      cookieService.remove(AppConstant.COOKIE_IS_DEMO);
      setAssignmentDemoSource('');
      setAssignmentDemoUrl('');
      history.push(assignmentDemoUrl);
    } else {
      this.confirmToSherpath();
    }
  };

  handleTicUrlGenerator = () => {
    assignmentService
      .getCKNURL()
      .then((res) => {
        if (res.data) {
          this.setState({ cknTicURL: res.data });
          document.getElementById(this.ticUrlGeneratorId).dispatchEvent(new Event('change'));
        }
      })
      .catch((err) => apiHelper.showApiError(`can not load clinical nursing link ${err}`));
  };

  render() {
    const { title, children } = this.props;
    const { showPendoHeader, cknTicURL } = this.state;
    const finalTitle = this.isDemo ? this.pageTitle : title;
    return (
      <header className="page-header">
        {this.props.skipLinkSections?.length > 0 && <ELSSkipLinkGroup sections={this.props.skipLinkSections} tabIndex={0} />}
        <div className="page-header__header__content o-els-flex-layout o-els-flex-layout--middle">
          <div className="o-els-flex-layout__item">
            <button className="page-header__close-wrapper" type="button" aria-label="Exit page" onClick={this.handleClose}>
              <ELSIcon name="close" prefix="hmds" color="secondary" size="1x" align="sub" />
            </button>
          </div>
          <div className="o-els-flex-layout__item o-els-flex-layout__item--grow u-els-padding-left-2x u-els-font-size-h4">{finalTitle}</div>
          {children}
          {showPendoHeader && (
            <button type="button" className="u-els-debuttonize u-els-margin-left js-pendo-header" aria-label="Resources and Support">
              <ELSIcon prefix="gizmo" name="help-outline-circle" size="1x1o2" customClass="u-els-color-secondary" />
            </button>
          )}
          {/* This button is for Pendo usage only, hidden to user and screen reader */}
          <button className="u-els-hide-visually" type="button" tabIndex={-1} aria-hidden="true" id={this.ticUrlGeneratorId} onClick={this.handleTicUrlGenerator}>
            {cknTicURL}
          </button>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  assignmentDemoSource: studentSelectors.getAssignmentDemoSource(state),
  assignmentDemoUrl: studentSelectors.getAssignmentDemoUrl(state),
  skipLinkSections: appSelectors.getSkipLinkSections(state),
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  setAssignmentDemoSource: (source: string) => dispatch(studentActions.setAssignmentDemoSource(source)),
  setAssignmentDemoUrl: (url: string) => dispatch(studentActions.setAssignmentDemoUrl(url))
});

export { PageHeader as BasePageHeader };
export default compose(withRouter, ELSWithModalService, connect(mapStateToProps, mapDispatchToProps))(PageHeader);

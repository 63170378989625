export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'assessmentNotRequired',
  ORIENTATION = 'orientation',
  ORIENTATION_DESCRIBE = 'orientationDescribe',
  FALL_RISK = 'fallRiskScore',
  RESTRAINTS = 'restraints',
  BRACELET_CHECK = 'braceletCheck',
  BRACELET_CHECK_NOTE = 'braceletCheckNotes'
}

export enum SectionTitle {
  SAFETY_ASSESSMENT = 'Safety Assessment',
  ORIENTATION = 'Orientation',
  FALL_RISK = 'Fall Risk',
  RESTRAINTS = 'Restraints',
  BRACELET_CHECK = 'Bracelet Check'
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChartFragment } from 'models/api-response';
import { ChartSharedProps } from 'models/ui';
import { chartHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import { Sidebar, withChartLogic } from 'components/common';
import './with.embedded.chart.scss';

interface EmbeddedChartProps extends ChartSharedProps {
  isSidebarOpen: boolean;
  selectedNavId?: string;
  onClose: Function;
  onSave?: Function;
  onDiscard?: Function;
  lastFocusableElement?: number;
  sidebarProps: Function;
}

interface EmbeddedChartState {
  fragment: ChartFragment;
}

const withEmbeddedChart = (WrappedComponent) => {
  class EmbeddedChart extends Component<EmbeddedChartProps, EmbeddedChartState> {
    constructor(props) {
      super(props);
      this.state = {
        fragment: null
      };
    }

    customLoadChartData = () => {
      return Promise.resolve({
        data: this.state.fragment ? [this.state.fragment] : []
      });
    };

    customSaveChartData = (payload, navId) => {
      const { selectedNavId, onSave } = this.props;
      let newNavId = navId;
      if (!navId && selectedNavId) {
        newNavId = selectedNavId;
      }
      const fragmentId = payload.fragmentId || chartHelper.createFragmentId(newNavId);
      const fragment = { ...payload, fragmentId };
      this.setState({ fragment });

      if (onSave) {
        onSave(fragment, newNavId);
      }
      return Promise.resolve();
    };

    render() {
      const { isSidebarOpen, lastFocusableElement, onClose: onCloseClick, sidebarProps } = this.props;
      return (
        <Sidebar
          open={isSidebarOpen}
          onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
          lastEnabledElement={lastFocusableElement}
          sidebarProps={sidebarProps}
        >
          <WrappedComponent
            isEmbeddedChart
            isEmbededSidebarOpen={isSidebarOpen}
            loadChartData={this.customLoadChartData}
            saveChartData={this.customSaveChartData}
            {...this.props}
          />
        </Sidebar>
      );
    }
  }
  const mapStateToProps = (state) => ({
    lastFocusableElement: appSelectors.getLastFocusableElement(state)
  });

  return connect(mapStateToProps)(withChartLogic(EmbeddedChart));
};

export default withEmbeddedChart;

import { TimeSpentRS } from 'models/api-response';
import { AssessmentStatus, AssignmentType, GradingOption, GradingResult } from 'models/enum';
import { AnswerSubmission, CaseStudy, CaseStudySubmissionSummary, ChartComment } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper, assignmentHelper } from 'helpers';
import {
  AllowEvent,
  AssessmentStatistic,
  Badge,
  Box,
  ButtonLinkIcon,
  ChartCommentList,
  ELSTextarea,
  InfoText,
  NavigationComponent,
  OverlayBlock,
  WarningCard
} from 'components/common';
import { ELSButton, ELSDropDown, ELSFlex, ELSFlexItem, ELSIcon, ELSTab, ELSTabGroup, ELSTooltip } from 'components/common/els';
import ScoreGrade from 'components/features/assignment-submission/ScoreGrade';
import { FormFieldLabel } from './constants';
import { StudentSubmission, Tabs } from './AssessmentDetail';
import CaseStudySubmission from './CaseStudySubmission';
import './assessment.detail.scss';
import '../assignment/create-assignment-workflow/case-study-content/case.study.content.scss';
import { QuizQuestionsResult } from '../quiz-questions-result/QuizQuestionsResult';

export interface AssignmentDetailViewProps {
  title: string;
  assignmentType: AssignmentType;
  assignmentGradeType: GradingOption;
  submissions: StudentSubmission[];
  startedAt: string;
  completedAt: string;
  durationTime: number;
  score?: number;
  status: string;
  gradeStatus: GradingResult;
  selectedStudent: number;
  completedCharts: string[];
  chartComments: ChartComment[];
  overallComment: string;
  caseStudy: CaseStudy;
  caseStudySubmissionSummary: CaseStudySubmissionSummary;
  questions: AnswerSubmission[];
  activeTab: Tabs;
  studentNavigationIndex: number;
  warningMessage: string;
  onBackToDetail: VoidFunction;
  onViewChart: Function;
  onStudentChange: Function;
  onTabsClick: Function;
  onViewSpecificChart: Function;
  onOverallCommentChange: Function;
  onSaveClick: Function;
  onPrevStudentClick: Function;
  onNextStudentClick: Function;
  timeSpentData: TimeSpentRS;
  assessmentId: number;
  locale: Locales;
  isInProgressGradingEnabled: boolean;
}

export const AssessmentDetailView = (props: AssignmentDetailViewProps) => {
  const {
    assignmentType,
    assignmentGradeType,
    gradeStatus,
    score,
    title,
    startedAt,
    completedAt,
    durationTime,
    completedCharts,
    selectedStudent,
    submissions,
    chartComments,
    status,
    overallComment,
    questions,
    caseStudySubmissionSummary,
    caseStudy,
    studentNavigationIndex,
    warningMessage,
    assessmentId,
    locale,
    isInProgressGradingEnabled,
    onBackToDetail,
    onViewSpecificChart,
    onOverallCommentChange,
    onSaveClick,
    onStudentChange,
    onPrevStudentClick,
    onNextStudentClick,
    timeSpentData
  } = props;

  const quizQuestionsCorrect = caseStudySubmissionSummary?.totalCorrect;
  const totalQuizQuestions = caseStudySubmissionSummary?.totalQuestions;
  const studentList = submissions.map((item) => ({ value: String(item.studentId), name: item.displayName }));
  const showOverlayBlock = (isInProgressGradingEnabled && !startedAt) || (!isInProgressGradingEnabled && !assignmentHelper.isAssessmentSubmitted(status));

  return (
    <div className="assessment-detail">
      <div className="assessment-detail__header">
        <Box py>
          <ButtonLinkIcon className="u-els-font-size-meta" text="Back to Assignment Detail" iconName="chevron-left" iconSize="3o4" onClick={onBackToDetail} />
        </Box>
        <div className="jumbotron-name">
          <ELSFlex middle spaceBetween>
            <ELSFlexItem className="u-els-font-size-h1">{title}</ELSFlexItem>
            <ELSFlexItem>
              <ELSTooltip
                wrapperClassName={`tooltip-overlay-header ${completedCharts.length > 0 && 'u-els-display-none'}`}
                content="View EHR Charts is disabled because no charts were filled out"
                showWhenHover
                showClose
              >
                {completedCharts.length === 0 ? (
                  <AllowEvent>
                    <ELSButton text="View EHR Charts" isDisabled onClick={props.onViewChart} />
                  </AllowEvent>
                ) : (
                  <ELSButton text="View EHR Charts" onClick={props.onViewChart} />
                )}
              </ELSTooltip>
            </ELSFlexItem>
          </ELSFlex>
        </div>
        <ELSFlex className="student-selection" middle left>
          <ELSFlexItem className="u-els-padding-right">
            <ELSIcon name="users" align="middle" />
          </ELSFlexItem>
          <ELSFlexItem>
            <ELSDropDown id="student-options" name="student-options" value={`${selectedStudent}`} options={studentList} inline size="1x1o2" changeHandler={onStudentChange} />
          </ELSFlexItem>
          <ELSFlexItem>
            <Badge inline className="pending-grade">
              {assignmentHelper.getPrettyStatus(status as AssessmentStatus)}
            </Badge>
          </ELSFlexItem>
          {!assignmentHelper.isAssessmentSubmitted(status) && (
            <ELSFlexItem className="u-els-padding-left">
              <WarningCard message={warningMessage} />
            </ELSFlexItem>
          )}
        </ELSFlex>
      </div>
      <div className="assessment-detail__info">
        <div>
          <AssessmentStatistic
            gradeType={assignmentGradeType}
            gradeStatus={gradeStatus}
            score={assignmentHelper.getPrettyScore(score)}
            startedAt={startedAt}
            completedAt={completedAt}
            durationTime={durationTime}
            completedChartsLength={completedCharts.length}
            assessmentId={assessmentId}
            timeSpentData={timeSpentData}
            locale={locale}
            isInProgressGradingEnabled={isInProgressGradingEnabled}
          />
          <InfoText
            text={`Any EHR chart that the student has completed is listed here.
       You can leave comments on either individual charts, or make overall comments.
       Please note that a student can view any feedback you provide as soon as you save.`}
          />
          <Box ml3 mb2 className="u-text-nowrap u-els-margin-top-1x1o2">
            {AssignmentType.EMPTY_EHR === assignmentType && (
              <ELSTabGroup activeIndex={props.activeTab} onChangeActiveIndex={props.onTabsClick}>
                <ELSTab title="Chart Activity" />
              </ELSTabGroup>
            )}
            {AssignmentType.CASE_STUDY === assignmentType && (
              <ELSTabGroup activeIndex={props.activeTab} onChangeActiveIndex={props.onTabsClick}>
                <ELSTab title="Chart Activity" />
                <ELSTab title="Quiz Questions" isDisabled={isInProgressGradingEnabled && !completedAt} />
                <ELSTab title="Case Study Information" />
              </ELSTabGroup>
            )}
          </Box>
          {props.activeTab === Tabs.CHART_ACTIVITY && (
            <Box ml>
              <ChartCommentList
                className="u-els-margin-top-1x1o2 u-els-padding-left-2x u-els-padding-right-2x"
                chartComments={chartComments}
                onViewChartClick={onViewSpecificChart}
                timeSpent={timeSpentData?.charts}
                locale={locale}
              />
              <div className="assessment-detail__overall-comments">
                <ELSTextarea
                  textLabel={FormFieldLabel.OVERALL_COMMENTS}
                  name="overall-comments-textarea"
                  className="overall-comments__textarea"
                  rows={5}
                  placeholder={appHelper.getPlaceholder(FormFieldLabel.OVERALL_COMMENTS)}
                  value={overallComment}
                  onChange={onOverallCommentChange}
                />
              </div>
              <ELSFlex className="assessment-detail__footer-actions">
                <ELSFlexItem grow>
                  <ELSButton type="secondary" className="sng-button" text="Save" onClick={onSaveClick} />
                </ELSFlexItem>
                <ELSFlexItem className="assessment-detail__prev-next-student">
                  <NavigationComponent
                    title=""
                    current={studentNavigationIndex}
                    total={studentList.length}
                    prevText="Previous student"
                    onPrev={onPrevStudentClick}
                    nextText="Next student"
                    onNext={onNextStudentClick}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
          )}
          {props.activeTab === Tabs.QUIZ_QUESTIONS && (
            <>
              {assignmentGradeType === GradingOption.SCORED && (
                <Box py2 px3>
                  <ScoreGrade className="u-els-width-1o2 u-els-width-1o1@tablet" score={score} assessmentStatus={status} isCompleted />
                </Box>
              )}
              <QuizQuestionsResult questions={questions} totalCorrect={quizQuestionsCorrect} totalQuestions={totalQuizQuestions} />
            </>
          )}
          {props.activeTab === Tabs.CASE_STUDY_INFORMATION && (
            <Box className="case-study-content" px3 pb2>
              <CaseStudySubmission selectedCaseStudy={caseStudy} />
            </Box>
          )}
        </div>
        {showOverlayBlock && <OverlayBlock />}
      </div>
    </div>
  );
};

AssessmentDetailView.displayName = 'AssessmentDetailView';
export default AssessmentDetailView;

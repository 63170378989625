import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';

export interface PediatricGlasgowComaScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const PediatricGlasgowComaScaleView = (props: PediatricGlasgowComaScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="pediatric-glasgow-scale">
      <h1>Pediatric Glasgow Coma Scale</h1>
      <Box mt2>
        <h2>{SectionTitle.PUPILS}</h2>
        <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={formSubmittedCount > 0} />
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{SectionTitle.LEFT}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PUPILS_LEFT_SIZE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PUPILS_LEFT_REACTION)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h3>{SectionTitle.RIGHT}</h3>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PUPILS_RIGHT_SIZE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PUPILS_RIGHT_REACTION)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <SectionDivider />

      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.PEDIATRIC_GLASGOW_COMA_SCALE)}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: false }}
        formFieldMap={formFieldMap}
      />

      <SectionDivider />
      <p className="u-els-italic u-els-margin-bottom-3x u-els-margin-top-3x">
        Modified from Teasdale G, Jennett B: Assessment of coma and impaired consciousness: A practical scale, Lancet 2:81-84, 1974.
      </p>
      <SectionDivider />

      <Box mt2>
        <h2>{SectionTitle.MOTOR_RESPONSES}</h2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MOTOR_RESPONSES_HAND_GRIP)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MOTOR_RESPONSES_MUSCLE_TONE)} useDefaultMargin={false} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                formField={formFieldMap.get(FormField.MOTOR_RESPONSES_MUSCLE_TONE_OTHER)}
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MOTOR_RESPONSES_FONTANEL_WINDOW)} useDefaultMargin={false} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                formField={formFieldMap.get(FormField.MOTOR_RESPONSES_FONTANEL_WINDOW_OTHER)}
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MOTOR_RESPONSES_LEVEL_CONSCIOUSNESS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MOTOR_RESPONSES_EYE_MOVEMENT)} useDefaultMargin={false} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                formField={formFieldMap.get(FormField.MOTOR_RESPONSES_EYE_MOVEMENT_OTHER)}
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MOTOR_RESPONSES_MOOD_AFFECT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

export default PediatricGlasgowComaScaleView;

import { LanguageKeys } from 'lang';
import Helmet from 'react-helmet';
import { IntlShape } from 'react-intl';
import { IntakeOutputRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, IntakeOutputGraphItem, IntakeOutputTotalPerDayItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { cssHelper } from 'helpers';
import { Box, ChartActions, ErrorCard, ErrorFormField, GraphContainer, HistoryBarChart } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import IntakeOutputHistory from './IntakeOutputHistory';
import { FormField } from './constant';
import '../vital.signs.common.scss';

export interface IntakeOutputViewProps {
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  graphData: IntakeOutputGraphItem[];
  chartHistory: IntakeOutputRecord[];
  deleteHistory: Function;
  totalPerDayData: IntakeOutputTotalPerDayItem[];
  chartMetaContentMap: Map<string, string>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  intl: IntlShape;
  locale: Locales;
}

const IntakeOutputView = ({
  chartMetaFormFields,
  graphData,
  chartHistory,
  deleteHistory,
  totalPerDayData,
  chartMetaContentMap,
  formSubmittedCount,
  chartActionsProps,
  intl,
  locale
}: IntakeOutputViewProps) => (
  <>
    {/* TODO: We should make the title can be changed easily. (Found a solution for this) */}
    {locale === Locales.EN_AU && (
      <Helmet title="SimChart - Fluid Balance Chart">
        <html lang="en" />
      </Helmet>
    )}

    <div className="vital-signs-common chart-input">
      <h1 className="chart-input__header">{intl.formatMessage({ id: LanguageKeys.INTAKE_OUTPUT })}</h1>
      <ELSFlex left wrap>
        <ELSFlexItem>
          <div className={cssHelper.mapElsCss({ els: 'padding-right-3x', md: 'padding-right-1x1o2', lg: 'padding-right-2x' })}>
            <h2 className="u-els-font-size-h2 u-els-margin-top">{intl.formatMessage({ id: LanguageKeys.INTAKE_OUTPUT_CHART_INPUTS })}</h2>
            <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
            <div className="chart-input__section-title chart-input__section-title--first">
              <h4>{intl.formatMessage({ id: LanguageKeys.INTAKE })}</h4>
            </div>
            <div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_AMOUNT)} formSubmittedCount={formSubmittedCount} />
            </div>
            <div className="chart-input__section-title">
              <h4>{intl.formatMessage({ id: LanguageKeys.INTRAVENOUS_FLUIDS })}</h4>
            </div>
            <div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INTRAVENOUS_FLUIDS_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INTRAVENOUS_FLUIDS_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.INTRAVENOUS_FLUIDS_AMOUNT)} formSubmittedCount={formSubmittedCount} />
            </div>
            <div className="chart-input__section-title">
              <h4>{intl.formatMessage({ id: LanguageKeys.MISCELLANEOUS })}</h4>
            </div>
            <div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.MISCELLANEOUS_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.MISCELLANEOUS_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.MISCELLANEOUS_AMOUNT)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.MISCELLANEOUS_UNITS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </div>

            {locale === Locales.EN_AU && (
              <>
                <div className="chart-input__section-title">
                  <h4>{intl.formatMessage({ id: LanguageKeys.FEED_PRESCRIBED })}</h4>
                </div>
                <div>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_FEED_PRESCRIBED)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_FEED_PRESCRIBED_TYPE)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_FEED_PRESCRIBED_AMOUNT_ML)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.INTAKE_FEED_PRESCRIBED_AMOUNT_ML_PER_HOUR)} formSubmittedCount={formSubmittedCount} />
                </div>
              </>
            )}
            <div className="chart-input__section-title">
              <h4>{intl.formatMessage({ id: LanguageKeys.MEALS })}</h4>
            </div>
            <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
              <ELSFlexItem>
                <div className="chart-input__dropdown--pull-right">
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.MEALS_TYPE)} formSubmittedCount={formSubmittedCount} />
                </div>
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.MEALS_CONSUMED)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <fieldset>
              <div className="chart-input__section-title">
                <h4>{intl.formatMessage({ id: LanguageKeys.OUTPUT })}</h4>
              </div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_MEASURE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_VOLUME_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_VOLUME_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_VOLUME_AMOUNT)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_FREQUENCY_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_FREQUENCY_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_FREQUENCY_NUMBER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.OUTPUT_FREQUENCY_SIZE)} formSubmittedCount={formSubmittedCount} />
            </fieldset>
            <ErrorFormField
              className="u-els-margin-top-1x"
              formField={chartMetaFormFields.get(FormField.NOTE)}
              formSubmittedCount={formSubmittedCount}
              options={{
                labelCss: 'chart-input__note-section-title'
              }}
            />
            <ChartActions {...chartActionsProps} />
          </div>
        </ELSFlexItem>
        <ELSFlexItem className="o-els-flex-layout__item--grow chart-input__scroll-table-container">
          <div className="chart-input__table-graph-container">
            <IntakeOutputHistory
              deleteHistory={deleteHistory}
              chartHistory={chartHistory}
              totalPerDayData={totalPerDayData}
              chartMetaContentMap={chartMetaContentMap}
              locale={locale}
            />
            <Box mt2>
              <GraphContainer dataSize={graphData.length}>
                <HistoryBarChart
                  width={600}
                  yLabel={intl.formatMessage({ id: LanguageKeys.INTAKE_OUTPUT_IN_ML })}
                  data={graphData}
                  dataKeys={['intake', 'output']}
                  locale={locale}
                />
              </GraphContainer>
            </Box>
          </div>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  </>
);

IntakeOutputView.displayName = 'IntakeOutputView';
export default IntakeOutputView;

import { ReactElement } from 'react';
import { connect } from 'react-redux';
import { UserRole } from 'models/enum';
import { ChartActionsComponentProps } from 'models/ui';
import { assignmentHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { chartService } from '../../../services';
import { ButtonLinkIconProps } from '../button/ButtonLinkIcon';

const defaultProps = {
  saveButtonText: 'Save Changes',
  cancelButtonText: 'Discard Changes',
  saveButtonHasIcon: false,
  enableSaveButton: false,
  enableDisplayRecordsButton: false,
  isMultiStepsAuthoring: false
};

const getChartActionButtons = ({
  cancelButtonText,
  enableDisplayRecordsButton,
  saveButtonHasIcon,
  enableSaveButton,
  isAuthor,
  onCancelClick,
  onDisplayRecordsClick,
  onSaveClick,
  saveButtonText,
  userRole,
  assessmentStatus,
  isMultiStepsAuthoring,
  isDemoEmptyEhrClinicalSetup
}: ChartActionsComponentProps): ReactElement => {
  const saveIconProps: ButtonLinkIconProps | {} = saveButtonHasIcon
    ? {
        className: 'c-els-button--icon-1x',
        iconName: 'chevron-right',
        iconPosition: 'right',
        iconSize: '1x'
      }
    : {};

  // Student or Instructor in Clinical Setup
  if (isDemoEmptyEhrClinicalSetup || (userRole === UserRole.STUDENT && !assignmentHelper.isAssessmentSubmitted(assessmentStatus))) {
    return (
      <>
        {onSaveClick && (
          <ELSFlexItem>
            <ELSButton type="primary" text={saveButtonText} isDisabled={!enableSaveButton} onClick={onSaveClick} {...saveIconProps} />
          </ELSFlexItem>
        )}
        {onCancelClick && (
          <ELSFlexItem>
            <ELSButton type="secondary" text={cancelButtonText} onClick={onCancelClick} />
          </ELSFlexItem>
        )}
      </>
    );
  }

  if (isAuthor) {
    let saveRecordsLabel = 'Save Records';
    let displayRecordsLabel = 'Display Records';
    if (!chartService.displayAuthorRecord()) {
      displayRecordsLabel = 'Return with data';
    }

    if (isMultiStepsAuthoring) {
      saveRecordsLabel = 'Save and Continue';
      displayRecordsLabel = 'Display Records and Reset';
    }

    return (
      <>
        {onSaveClick && (
          <ELSFlexItem>
            <ELSButton text={saveRecordsLabel} isDisabled={!enableSaveButton} onClick={onSaveClick} />
          </ELSFlexItem>
        )}
        {onDisplayRecordsClick && (
          <ELSFlexItem>
            <ELSButton type="secondary" text={displayRecordsLabel} isDisabled={!enableDisplayRecordsButton} onClick={onDisplayRecordsClick} />
          </ELSFlexItem>
        )}
      </>
    );
  }

  return <></>;
};

const ChartActions = (props: ChartActionsComponentProps): ReactElement => {
  if (props.isLocked) return <></>;
  return (
    <ELSFlex className="sng-form-buttons" left wrap>
      {getChartActionButtons(props)}
    </ELSFlex>
  );
};

ChartActions.defaultProps = defaultProps;
ChartActions.displayName = 'ChartActions';

const mapStateToProps = (state) => ({
  isAuthor: appSelectors.getIsAuthor(state),
  assessmentStatus: appSelectors.getAssessment(state)?.status,
  userRole: appSelectors.getUserRole(state),
  isMultiStepsAuthoring: appSelectors.getIsMultiStepsAuthoring(state)
});

export { ChartActions as BaseChartActions };
export default connect(mapStateToProps)(ChartActions);

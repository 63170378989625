import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getGastrointestinalAssessmentFormFieldBuilderItems } from './constants';
import GastrointestinalAssessmentView from './GastrointestinalAssessmentView';
import { Title } from '../shared/constants';

export interface GastrointestinalAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

export interface GastrointestinalAssessmentState {
  resetAll: number;
}

class GastrointestinalAssessment extends Component<GastrointestinalAssessmentProps, GastrointestinalAssessmentState> {
  static displayName = 'GastrointestinalAssessment';

  constructor(props) {
    super(props);
    this.state = { resetAll: 0 };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getGastrointestinalAssessmentFormFieldBuilderItems().forEach(({ name, type, label }) => {
      dataMap.set(name, createFormField({ name, type, label }));
    });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap, intl } = this.props;

    return [
      chartHelper.buildSection({ sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.GASTROINTESTINAL_ASSESSMENT, fields: [FormField.NO_ASSESSMENT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.MOUTH_GUMS_TEETH,
        fields: [FormField.CLEFT_LIP, FormField.CLEFT_PALATE, FormField.GUMS_AND_MOUTH, FormField.TEETH_BRIDGES_DENTURES, FormField.MOUTH_GUMS_TEETH_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.ESOPHAGEAL_ASSESSMENT }),
        fields: [FormField.ESOPHAGEAL_ASSESSMENT, FormField.ESOPHAGEAL_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.ABDOMEN,
        fields: [FormField.ABDMONINAL_ASSESSMENT, FormField.TENDER_ON_PALPATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.GASTROINTESTINAL,
        fields: [FormField.GASTROINTESTINAL, FormField.NO_GASTRIC_PROBLEM_NOTED, FormField.GASTROINTESTINAL_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL,
        fields: [FormField.DATE_OF_LAST_BOWEL_MOVEMENT, FormField.CONTINENCE_OF_BOWEL],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL,
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.BOWEL_SOUNDS,
        fields: [FormField.BOWEL_SOUNDS, FormField.BOWEL_SOUNDS_OTHER],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL,
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL_ASSESSMENT,
        fields: [FormField.INTESTINAL_ASSESSMENT, FormField.NO_BOWEL_PROBLEMS_NOTED],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL,
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.RECTUM_CHARACTERISTICS,
        fields: [FormField.RECTUM, FormField.NO_REPORTED_RECTAL_PROBLEMS],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL,
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.STOOL_CHARACTERISTICS,
        fields: [FormField.STOOL_CHARACTERISTICS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL_DIVERSION,
        fields: [FormField.INTESTINAL_DIVERSION_PATIENT_HAS_OSTOMY, FormField.INTESTINAL_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.GASTROINTESTINAL_ASSESSMENT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => {
    this.props.initState(this.buildFormFields());
    this.setState(
      (prevState) => ({ resetAll: prevState.resetAll + 1 }),
      () => appHelper.scrollTop()
    );
  };

  render() {
    const { formFieldMap, formSubmittedCount, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, handleDiscardClick, intl } = this.props;
    const { resetAll } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData
    };
    const viewProps = {
      formFieldMap,
      formSubmittedCount,
      resetAll,
      chartActionsProps,
      intl
    };
    return <GastrointestinalAssessmentView {...viewProps} />;
  }
}

export { GastrointestinalAssessment as BaseGastrointestinalAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(GastrointestinalAssessment);

import { ChartFragment, HomeMedicationRecord } from 'models/api-response';
import { Locales } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import HomeMedicationHistory from 'components/features/chart/patient-data/home-medication/HomeMedicationHistory';

export interface HomeMedicationReadOnlyProps {
  chartFragments: HomeMedicationRecord[];
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  locale: Locales;
}

const HomeMedicationReadOnly = (props: HomeMedicationReadOnlyProps) => {
  const { chartFragments, statusFragment, hideHeadline, locale } = props;

  return (
    <Box className="home-medication-ready-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragments[0] as ChartFragment} />
        </>
      )}
      <h2>Home Medication Information</h2>
      <Box className="sng-article">
        {!chartFragments.length && <h3>Chart Omitted</h3>}
        {chartFragments.length > 0 && <HomeMedicationHistory chartHistory={props.chartFragments} locale={locale} />}
      </Box>
    </Box>
  );
};

HomeMedicationReadOnly.displayName = 'HomeMedicationReadOnly';
export default HomeMedicationReadOnly;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, SectionLabel } from './constants';
import AdolescentRiskAssessmentView from './AdolescentRiskAssessmentView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface AdolescentRiskAssessmentState {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class AdolescentRiskAssessment extends Component<ChartComponentProps, AdolescentRiskAssessmentState> {
  static displayName = 'AdolescentRiskAssessment';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.ADOLESCENT_RISK_ASSESSMENT, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentContentIds } = chartHelper;
    const dataMap = new Map();

    const radioButtons = new Map();
    radioButtons.set(FormField.DENTAL_CARE, `${SectionLabel.DENTAL_CARE}:`);
    radioButtons.set(FormField.SEAT_BELT_USE, `${SectionLabel.SEAT_BELT_USE}:`);
    radioButtons.set(FormField.RIDING_IN_CAR, `${SectionLabel.RIDING_IN_CAR}:`);
    radioButtons.set(FormField.SLEEP, `${SectionLabel.CARRYING_A_WEAPON}:`);
    radioButtons.set(FormField.CARRYING_A_WEAPON, `${SectionLabel.FEEL_UNSAFE_AT_SCHOOL}:`);
    radioButtons.set(FormField.FEEL_UNSAFE_AT_SCHOOL, `${SectionLabel.ATTEMPTED_SUICIDE}:`);
    radioButtons.set(FormField.ATTEMPTED_SUICIDE, `${SectionLabel.ABUSE_MALTREATMENT}:`);
    radioButtons.set(FormField.ABUSE_MALTREATMENT, `${SectionLabel.SLEEP}:`);
    radioButtons.set(FormField.AUTOMOBILE_CRASH, `${SectionLabel.AUTOMOBILE_CRASH}:`);

    const textBoxes = [
      { name: FormField.DENTAL_CARE_DESCRIBE },
      { name: FormField.SEAT_BELT_USE_DESCRIBE },
      { name: FormField.RIDING_IN_CAR_DESCRIBE },
      { name: FormField.SLEEP_DESCRIBE },
      { name: FormField.CARRYING_A_WEAPON_DESCRIBE },
      { name: FormField.FEEL_UNSAFE_AT_SCHOOL_DESCRIBE },
      { name: FormField.ATTEMPTED_SUICIDE_DESCRIBE },
      { name: FormField.ABUSE_MALTREATMENT_DESCRIBE },
      { name: FormField.AUTOMOBILE_CRASH_DESCRIBE }
    ];
    textBoxes.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.TEXT_AREA,
          label: SectionLabel.DESCRIBE,
          value: chartHelper.getFragmentValue(fragment, SectionLabel.PRESENCE_OF_RISK, item.name)
        })
      )
    );
    radioButtons.forEach((label, name) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.RADIO_CHOICE,
          label,
          contentIds: getFragmentContentIds(fragment, SectionLabel.PRESENCE_OF_RISK, name)
        })
      )
    );

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: SectionLabel.ADOLESCENT_RISK_ASSESSMENT,
      records: [
        {
          sectionTitle: SectionLabel.PRESENCE_OF_RISK,
          records: [
            buildPatientRecord(formFieldMap, FormField.DENTAL_CARE),
            buildPatientRecord(formFieldMap, FormField.DENTAL_CARE_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.SEAT_BELT_USE),
            buildPatientRecord(formFieldMap, FormField.SEAT_BELT_USE_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.RIDING_IN_CAR),
            buildPatientRecord(formFieldMap, FormField.RIDING_IN_CAR_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.SLEEP),
            buildPatientRecord(formFieldMap, FormField.SLEEP_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.CARRYING_A_WEAPON),
            buildPatientRecord(formFieldMap, FormField.CARRYING_A_WEAPON_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.FEEL_UNSAFE_AT_SCHOOL),
            buildPatientRecord(formFieldMap, FormField.FEEL_UNSAFE_AT_SCHOOL_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.ATTEMPTED_SUICIDE),
            buildPatientRecord(formFieldMap, FormField.ATTEMPTED_SUICIDE_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.ABUSE_MALTREATMENT),
            buildPatientRecord(formFieldMap, FormField.ABUSE_MALTREATMENT_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.AUTOMOBILE_CRASH),
            buildPatientRecord(formFieldMap, FormField.AUTOMOBILE_CRASH_DESCRIBE)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord };
  };

  render() {
    const { isLocked, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      chartActionsProps,
      formSubmittedCount,
      formFieldMap
    };
    return <AdolescentRiskAssessmentView {...viewProps} />;
  }
}

export { AdolescentRiskAssessment as BaseAdolescentRiskAssessment };
export default compose(withChartLogic, withAdmissionHistory)(AdolescentRiskAssessment);

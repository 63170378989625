import { PageHeader, withHTMLHeadSEO } from 'components/common';
import BlankChartingAssignment from 'components/features/blank-charting-assignment/BlankChartingAssignment';

const BlankChartingPage = () => (
  <>
    <PageHeader title="SimChart Assignment" />
    <BlankChartingAssignment />
  </>
);

export { BlankChartingPage as BaseBlankChartingPage };
export default withHTMLHeadSEO({ title: 'Blank Charting' })(BlankChartingPage);

export interface SubscribeInfo {
  actionType: string;
  fieldNode: FieldNode;
  key: string;
  needProcessRuleResult: Function;
  ruleIdToValidate?: string;
  ruleIdToProcessResult?: string;
}

export interface Subscriber {
  key: string;
  subInfos: SubscribeInfo[];
}

interface ConstructorParams {
  fieldId: string;
  doValidateField: Function;
  processRuleResult: Function;
}

export default class FieldNode {
  allowClearValue = true;
  fieldId = '';
  isReady = false;
  doValidateField: Function;
  processRuleResult: Function;
  sessionId = 0;
  subscribers: Subscriber[] = [];

  constructor({ fieldId, doValidateField, processRuleResult }: ConstructorParams) {
    this.fieldId = fieldId;
    this.doValidateField = doValidateField;
    this.processRuleResult = processRuleResult;
  }

  subscribe = (subInfo: SubscribeInfo) => {
    const found = this.subscribers.find((item) => item.key === subInfo.key);
    if (found) {
      found.subInfos.push(subInfo);
    } else {
      this.subscribers.push({ key: subInfo.key, subInfos: [subInfo] });
    }
  };

  notifySubscribers = (params) => {
    this.sessionId += 1;
    const curSessionId = this.sessionId;
    const subLength = this.subscribers.length;

    for (let index = 0; index < subLength; index += 1) {
      if (curSessionId !== this.sessionId) {
        return;
      }

      const subscriber = this.subscribers[index];
      this.doValidateField({ ...params, fieldId: this.fieldId, ruleIdToValidate: subscriber.key });
      this.processRuleResult({ ...params, subInfos: subscriber.subInfos });
    }
  };
}

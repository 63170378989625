import React, { CSSProperties, ChangeEvent, ReactElement } from 'react';
import { CalculateValueAndPosition, CalculateValueAndPositionProps, validateTimeAndCursor } from 'helpers/timefield.helper';

const DEFAULT_SEPERATOR = ':';
const DEFAULT_VALUE_SHORT = `00${DEFAULT_SEPERATOR}00`;
const DEFAULT_VALUE_FULL = `00${DEFAULT_SEPERATOR}00${DEFAULT_SEPERATOR}00`;

type onChangeType = (event: ChangeEvent<HTMLInputElement>, value: string) => void;

interface Props {
  value?: string;
  onChange?: onChangeType;
  isShowSeconds?: boolean;
  input: ReactElement | null;
  inputRef?: () => HTMLInputElement | null;
  seperator?: string;
  style?: CSSProperties | {};
  className?: string;
}

interface State {
  value: string;
  seperator: string;
  defaultValue: string;
  isShowSeconds: boolean;
  maxLength: number;
}

export default class TimeField extends React.Component<Props, State> {
  static defaultProps: Props = {
    isShowSeconds: false,
    input: null,
    style: {},
    seperator: DEFAULT_SEPERATOR
  };

  constructor(props: Props) {
    super(props);

    const isShowSeconds = Boolean(props.isShowSeconds);
    const defaultValue = isShowSeconds ? DEFAULT_VALUE_FULL : DEFAULT_VALUE_SHORT;
    const seperator = props.seperator && props.seperator.length === 1 ? props.seperator : DEFAULT_SEPERATOR;
    const [validatedTime] = validateTimeAndCursor(isShowSeconds, this.props.value, defaultValue, seperator);

    this.state = {
      value: validatedTime,
      seperator,
      isShowSeconds,
      defaultValue,
      maxLength: defaultValue.length
    };

    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.value !== prevProps.value) {
      const [validatedTime] = validateTimeAndCursor(this.state.isShowSeconds, this.props.value, this.state.defaultValue, this.state.seperator);
      this.setState({
        value: validatedTime
      });
    }
  }

  onInputChange(event: ChangeEvent<HTMLInputElement>, callback: onChangeType): void {
    const currentValue = this.state.value;
    const inputElement = event.target;
    const position = inputElement.selectionEnd || 0;
    const { seperator, maxLength } = this.state;

    const calculateValueAndPositionProps: CalculateValueAndPositionProps = {
      currentValue,
      inputElement,
      position,
      maxLength,
      seperator
    };
    const calculateValueAndPosition = new CalculateValueAndPosition(calculateValueAndPositionProps);
    const [newValue, newPosition] = calculateValueAndPosition.calculate();
    const [validatedTime, validatedCursorPosition] = validateTimeAndCursor(this.state.isShowSeconds, newValue, currentValue, seperator, newPosition);

    this.setState({ value: validatedTime }, () => {
      inputElement.selectionStart = validatedCursorPosition;
      inputElement.selectionEnd = validatedCursorPosition;
      callback(event, validatedTime);
    });

    event.persist();
  }

  render(): ReactElement {
    const { value } = this.state;
    const { onChange, style, input, inputRef, className, ...props } = this.props;
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => this.onInputChange(event, (e: ChangeEvent<HTMLInputElement>, val: string) => onChange && onChange(e, val));

    if (input) {
      return React.cloneElement(input, {
        ...props,
        value,
        style,
        className,
        onChange: onChangeHandler
      });
    }

    return <input type="text" className={className} {...props} ref={inputRef} value={value} onChange={onChangeHandler} style={{ ...style }} />;
  }
}

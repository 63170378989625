import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './DrugScreen';
import DrugScreenReadOnly from './DrugScreenReadOnly';

interface DrugScreenViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const DrugScreenView = (props: DrugScreenViewProps) => {
  const { isLocked, fragment, statusFragment, resetAll, formSubmittedCount, chartMetaFormFields } = props;
  return (
    <div className="drug-screen">
      {isLocked ? (
        <DrugScreenReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Drug History</h1>
          <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />

          <Box mt2>
            <h2 className="u-els-margin-bottom">Street/Recreational/Excessive Prescription Drug Use</h2>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.PRESCRIPTION_DRUG_USE)} formSubmittedCount={formSubmittedCount} />
          </Box>

          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">Using</h3>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.DRUGS_CURRENTLY_USING)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.DRUGS_CURRENTLY_USING_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.AMOUNT_STREET_DRUGS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.USED_DRUGS_PAST_24_HOURS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HOW_LONG_USED_DRUGS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PLAN_TO_QUIT)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField key={`quit-date-${resetAll}`} formField={chartMetaFormFields.get(FormField.QUIT_DATE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">Provider Notification</h3>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.PROVIDER_NOTIFIED)} formSubmittedCount={formSubmittedCount} />
              <Box ml2 mt>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.PROVIDER_NAME)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <h3 className="u-els-margin-bottom">Quit</h3>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HOW_LONG_QUIT)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

DrugScreenView.displayName = 'DrugScreenView';
export default DrugScreenView;

import yamljs from 'yamljs';
import { ChartFieldContentMeta, ChartFieldMeta, ChartFieldValidationMeta } from './MetadataInput';
import { JsonToYamlConverterService } from '../../../services/json-to-yaml-converter.service';

export interface MetaTypes {
  chartFields: ChartFieldMeta[];
  chartFieldContent: ChartFieldContentMeta[];
  chartFieldValidations: ChartFieldValidationMeta[];
}

const removeEmptyAttributeOverrideProperties = (metaDataObjects) => {
  // removes properties with null values
  metaDataObjects.forEach((data) => {
    if (data.attrOverrides === null) {
      const propertyToRemove = data;
      Object.keys(data).forEach((key) => data[key] === null && delete propertyToRemove[key]);
    } else if (data.attrOverrides) {
      data.attrOverrides.forEach((override) => {
        const attributeToRemove = override;
        Object.keys(override).forEach((key) => override[key] === null && delete attributeToRemove[key]);
      });
    }
  });
};

export const toYaml = (metaTypes: MetaTypes): string => {
  if (metaTypes === undefined) {
    return `No Data`;
  }
  removeEmptyAttributeOverrideProperties(metaTypes.chartFieldContent);
  removeEmptyAttributeOverrideProperties(metaTypes.chartFieldValidations);
  return JsonToYamlConverterService.json2yaml(metaTypes);
};

export const toObject = (yaml: string): MetaTypes => {
  // parse YAML string
  const metaTypes: MetaTypes = yamljs.parse(yaml);
  if (typeof metaTypes !== 'string') {
    return metaTypes;
  }
  this.props.showConfirmationModal({
    showIcon: true,
    header: 'Validation',
    message: 'Invalid Yaml format',
    onOkClick: () => this.props.modalService.closeModal('warningModal')
  });
  return {
    chartFields: [],
    chartFieldContent: [],
    chartFieldValidations: []
  };
};

import { camelCase, orderBy } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { v4 } from 'uuid';
import { LaboratoryTestFragment, LaboratoryTestResult } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { FillInTheBlankPattern } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import LaboratoryTestView, { LaboratoryTestProps } from 'components/features/chart/pre-clinical-manager/laboratory-test/LaboratoryTestView';
import { TestDateError } from './LaboratoryTestConstants';

export enum FormField {
  DATE_OF_TEST = 'dateOfTest',
  CATEGORY = 'category',
  LAB_TEST = 'laboratoryTest',
  DEFINITION_DESCRIPTION = 'definitionAndDescription',
  SIGNIFICANCE = 'significance',
  TEST_RESULT_NAME = 'name',
  TEST_RESULT = 'results',
  DATE_OF_TEST_RESULT = 'dateOfTestResult',
  TEST_RESULT_LEVEL = 'level',
  TEST_RESULT_SIGNIFICANCE = 'significance'
}

interface LaboratoryTestState {
  errorCardKey: number;
  chartHistory: LaboratoryTestFragment[];
  labResults: LaboratoryTestResult[];
  selectedLaboratoryTest: LaboratoryTestFragment;
}

export const buildFormFields = (laboratoryTest?: LaboratoryTestFragment, isBuildLabTestField = true): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();
  dataMap.set(
    FormField.DATE_OF_TEST,
    createFormField({
      name: FormField.DATE_OF_TEST,
      type: FormFieldInputType.DATE,
      label: 'Date of Test (mm-dd-yyyy)',
      value: laboratoryTest?.chartData.dateOfTest || ''
    })
  );
  dataMap.set(
    FormField.CATEGORY,
    createFormField({ name: FormField.CATEGORY, type: FormFieldInputType.MULTI_SELECT_RADIO, label: 'Category', value: laboratoryTest?.chartData.category })
  );
  if (isBuildLabTestField) {
    dataMap.set(
      FormField.LAB_TEST,
      createFormField({ name: FormField.LAB_TEST, type: FormFieldInputType.MULTI_SELECT_RADIO, label: 'Lab Test', value: laboratoryTest?.chartData.labTest })
    );
  }
  dataMap.set(
    FormField.DEFINITION_DESCRIPTION,
    createFormField({
      name: FormField.DEFINITION_DESCRIPTION,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Definition and Description',
      value: laboratoryTest?.chartData.definitionAndDescription || ''
    })
  );
  dataMap.set(
    FormField.SIGNIFICANCE,
    createFormField({
      name: FormField.SIGNIFICANCE,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Significance of the Test Being Ordered for this Patient',
      value: laboratoryTest?.chartData.significance || ''
    })
  );

  return dataMap;
};

class LaboratoryTest extends Component<ChartComponentProps, LaboratoryTestState> {
  static displayName = 'LaboratoryTest';

  formFieldMapDisplayError;

  constructor(props) {
    super(props);
    this.state = {
      errorCardKey: 0,
      chartHistory: [],
      labResults: [],
      selectedLaboratoryTest: null
    };
    this.formFieldMapDisplayError = new Map();
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory));
  }

  componentDidUpdate(prevProps, prevState) {
    const prevLabTest = prevProps.formFieldMap.get(FormField.LAB_TEST);
    const currentLabTest = this.props.formFieldMap.get(FormField.LAB_TEST);
    const prevTestDate = prevProps.formFieldMap.get(FormField.DATE_OF_TEST);
    const currentTestDate = this.props.formFieldMap.get(FormField.DATE_OF_TEST);
    if (prevLabTest !== currentLabTest) {
      const labResults = currentLabTest?.chartContent
        ?.find((item) => item.value === currentLabTest.value)
        ?.referenceFields?.map(
          (item) =>
            ({
              id: v4(),
              name: item.label,
              dateOfTestResult: '',
              results: '',
              level: '',
              significance: ''
            } as LaboratoryTestResult)
        );
      this.formFieldMapDisplayError = new Map(this.props.formFieldMap);
      this.setState({ labResults: labResults || [] });
    }

    if (prevTestDate !== currentTestDate || prevState.labResults !== this.state.labResults) {
      this.formFieldMapDisplayError = new Map(this.props.formFieldMap);
      this.state.labResults.forEach((labResult: LaboratoryTestResult) => {
        const errors = !labResult.dateOfTestResult ? TestDateError : [];

        this.formFieldMapDisplayError.set(labResult.name, {
          label: labResult.name,
          value: labResult.dateOfTestResult,
          name: camelCase(labResult.name),
          errorLabel: `${labResult.name} date of test result`,
          disabled: false,
          inputType: FormFieldInputType.DATE,
          errors,
          warning: []
        });
      });
      this.setState({ errorCardKey: prevState.errorCardKey + 1 });
    }
  }

  handleLabTestResultChange = (labResult: LaboratoryTestResult) => {
    this.setState((prevState) => {
      const labResults = prevState.labResults.map((item) => {
        if (item.id !== labResult.id) {
          return item;
        }
        return {
          ...item,
          ...labResult,
          updatedAt: moment().toISOString()
        };
      });
      return { labResults };
    });
  };

  handleChartSave = () => {
    const { formFieldMap, handleSaveClick } = this.props;
    const tests = this.state.labResults.map((item) => ({
      id: item.id,
      name: item.name,
      dateOfTestResult: item.dateOfTestResult,
      results: item.results,
      level: item.level,
      significance: item.significance
    }));
    const labReplaceRegex = new RegExp(`${FillInTheBlankPattern}.*`);
    const labTestField = formFieldMap.get(FormField.LAB_TEST);
    let labTestType = labTestField.chartContent.find((contentItem) => contentItem.value === labTestField.value)?.label ?? '';
    if (formFieldMap.get(FormField.LAB_TEST)) {
      labTestType = labTestType.replace(labReplaceRegex, formFieldMap.get(FormField.LAB_TEST).value);
    }
    const labTestCategory = labTestField.chartContent.find((contentItem) => contentItem.value === labTestField.value)?.label ?? '';
    const newRecord: LaboratoryTestFragment = {
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData: {
        dateOfTest: formFieldMap.get(FormField.DATE_OF_TEST)?.value,
        category: labTestCategory,
        labTest: labTestType,
        definitionAndDescription: formFieldMap.get(FormField.DEFINITION_DESCRIPTION)?.value,
        significance: formFieldMap.get(FormField.SIGNIFICANCE)?.value,
        tests
      }
    };
    handleSaveClick([newRecord], { formFieldMap: this.formFieldMapDisplayError, afterSave: () => this.afterSave(false) });
  };

  handleChartEdit = () => {
    this.afterSave(false);
    this.setState({ selectedLaboratoryTest: null });
  };

  handleChartDelete = (laboratoryTestRecord: LaboratoryTestFragment) => this.props.showDeleteConfirmation(() => this.confirmAndDelete(laboratoryTestRecord));

  confirmAndDelete = (laboratoryTestRecord: LaboratoryTestFragment) => {
    return appHelper.useLoader(
      this.props.saveChartData({ ...laboratoryTestRecord, active: false }).then(() => this.afterSave(true)),
      { errorMessage: 'can not save chart data' }
    );
  };

  bindChartHistory = ({ data: fragments }) => {
    const records = orderBy(fragments, ['createdAt'], ['asc']) as LaboratoryTestFragment[];
    this.setState({ chartHistory: records });
  };

  afterSave = (isDelete) =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
      .then(this.resetForm)
      .then(() => appHelper.scrollTop());

  resetForm = () => {
    this.props.initState(buildFormFields());
    this.setState({
      labResults: []
    });
  };

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  handleSaveClick = () => {
    this.setState((prevState) => {
      const newLabTestResults = prevState.labResults.map((labResult) => ({ ...labResult, hasTestDateError: !labResult.dateOfTestResult }));
      return { labResults: newLabTestResults };
    }, this.handleChartSave);
  };

  render() {
    const {
      chartMetadata,
      enableDisplayRecordsButton,
      hasUnsavedChanges,
      displayAuthoringData,
      handleDiscardClick,
      formFieldMap,
      formSubmittedCount,
      selectedNavId,
      saveChartData
    } = this.props;
    const { errorCardKey, labResults, selectedLaboratoryTest, chartHistory } = this.state;
    const chartActionsProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onSaveClick: this.handleSaveClick
    };
    const viewProps: LaboratoryTestProps = {
      chartMetadata,
      errorCardKey,
      chartMetaFormFields: formFieldMap,
      chartMetaFormFieldsDisplayError: this.formFieldMapDisplayError,
      formSubmittedCount,
      chartActionsProps,
      labResults,
      chartHistory,
      selectedNavId,
      selectedLaboratoryTest,
      onLabTestResultChange: this.handleLabTestResultChange,
      onChartEdit: this.handleChartEdit,
      onChartDelete: this.handleChartDelete,
      onEditSidebarOpen: (laboratoryTest) => this.setState({ selectedLaboratoryTest: laboratoryTest }),
      onEditSidebarClose: () => this.setState({ selectedLaboratoryTest: null }),
      saveChartData,
      ...appHelper.getChartSharedProps(this.props)
    };

    return <LaboratoryTestView {...viewProps} />;
  }
}

export { LaboratoryTest as BaseLaboratoryTest };
export default withChartLogic(LaboratoryTest);

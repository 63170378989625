import { ActivitiesDailyLivingFragment, ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, Headline } from 'components/common';
import { SectionTitle } from './constants';
import ActivitiesDailyLivingEditView from './ActivitiesDailyLivingEditView';
import ActivitiesDailyLivingReadonly from './ActivitiesDailyLivingReadonly';

export interface ActivitiesDailyLivingViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ActivitiesDailyLivingFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const ActivitiesDailyLivingView = (props: ActivitiesDailyLivingViewProps) => (
  <div className="activities-daily-living">
    {!props.isLocked ? (
      <>
        <h1>{SectionTitle.ACTIVITIES_DAILY_LIVING}</h1>
        <Box mt2>
          <ActivitiesDailyLivingEditView {...props} />
        </Box>
      </>
    ) : (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <ActivitiesDailyLivingReadonly selectedRecord={props.selectedRecord} />
      </>
    )}
  </div>
);

ActivitiesDailyLivingView.displayName = 'ActivitiesDailyLivingView';
export default ActivitiesDailyLivingView;

import { ChartFragment } from 'models/api-response';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './RoleRelationship';
import RoleRelationshipReadOnly from './RoleRelationshipReadOnly';
import { ChartViewProps } from '../../../shared/constants';

interface RoleRelationshipViewProps extends ChartViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
}

const RoleRelationshipView = (props: RoleRelationshipViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, formFieldMap } = props;
  return (
    <div className="role-relationship">
      {isLocked ? (
        <RoleRelationshipReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Role - Relationship</h1>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />

          <Box mt2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.MARTIAL_PARTNER_STATUS)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.FAMILY_PROCESSES)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.FAMILY_PROCESSES_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAREGIVER_ROLE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAREGIVER_ROLE_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.ROLE_PERFORMANCE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.ROLE_PERFORMANCE_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.READINESS_NEWBORN)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.FEELING_READINESS)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.SOCIAL_RESOURCES)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.SOCIAL_INTERACTIONS)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.SOCIAL_INTERACTIONS_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

RoleRelationshipView.displayName = 'RoleRelationshipView';
export default RoleRelationshipView;

import { ReactNode, ReactNodeArray } from 'react';
import './allow.event.scss';

interface AllowEventProps {
  children: ReactNode | ReactNodeArray;
}

const AllowEvent = (props: AllowEventProps) => (
  <div className="allow-event">
    {props.children}
    <div className="allow-event__overlay" />
  </div>
);

AllowEvent.displayName = 'AllowEvent';
export default AllowEvent;

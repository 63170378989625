import { LanguageKeys } from 'lang';
import { camelCase } from 'lodash';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams, ExternalReference } from 'models/ui';
import { FormField } from './constants';
import LaboratoryTooltip from './laboratory-tootip/LaboratoryTooltip';
import { FormField as CommonFormField, Title } from '../shared/constants';

export const getLaboratoryFormFieldBuilderItems = (isAuthor: boolean): ChartMetaFormFieldParams[] => [
  { name: FormField.CATEGORY, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.LABORATORY.CATEGORY },
  {
    name: FormField.ORDER_DESCRIPTION,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.LABORATORY.ORDER_DESCRIPTION,
    errorLabel: LanguageKeys.LABORATORY.ORDER_DESCRIPTION
  },
  { name: FormField.ORDER_PRIORITY, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.LABORATORY.ORDER_PRIORITY, errorLabel: LanguageKeys.LABORATORY.ORDER_PRIORITY },
  { name: FormField.ORDER_FREQUENCY, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.LABORATORY.ORDER_FREQUENCY, errorLabel: LanguageKeys.LABORATORY.ORDER_FREQUENCY },
  { name: FormField.SPECIMEN, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.LABORATORY.SPECIMEN },
  { name: FormField.SPECIAL_INSTRUCTIONS, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.LABORATORY.SPECIAL_INSTRUCTIONS },
  { name: CommonFormField.ORDER_START_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.START_TIME_OFFSET, value: '00:00', isHidden: !isAuthor },
  { name: FormField.ORDER_START_DATE, type: FormFieldInputType.DATE, label: LanguageKeys.DATE_LABEL, errorLabel: LanguageKeys.LABORATORY.ORDER_START_DATE, isHidden: isAuthor },
  {
    name: FormField.ORDER_START_TIME,
    type: FormFieldInputType.TIME,
    label: LanguageKeys.TIME_LABEL,
    errorLabel: LanguageKeys.LABORATORY.ORDER_START_TIME,
    defaultValue: '00:00',
    isHidden: isAuthor
  }
];

export const getLaboratorySidebarFormFieldBuilderItems = (isAuthor: boolean, referenceFields?: ExternalReference[]): ChartMetaFormFieldParams[] => {
  const referenceFieldParams: ChartMetaFormFieldParams[] = referenceFields.map((field) => {
    const fieldName = camelCase(field.label);
    const formFieldParam: ChartMetaFormFieldParams = { name: fieldName, type: FormFieldInputType.TEXT_BOX, label: field.label };
    formFieldParam.labelMultilineTooltip = field.range?.length > 0 && <LaboratoryTooltip ranges={field.range.split('|')} />;
    return formFieldParam;
  });

  const dateTimeFieldParams: ChartMetaFormFieldParams[] = [
    {
      name: FormField.RESULTS_RECEIVED_DATE,
      type: FormFieldInputType.DATE,
      label: LanguageKeys.DATE_LABEL,
      errorLabel: LanguageKeys.LABORATORY.RESULTS_RECEIVED_DATE,
      isHidden: isAuthor
    },
    {
      name: FormField.RESULTS_RECEIVED_TIME,
      type: FormFieldInputType.TIME,
      label: LanguageKeys.TIME_LABEL,
      errorLabel: LanguageKeys.LABORATORY.RESULTS_RECEIVED_TIME,
      value: '00:00',
      isHidden: isAuthor
    },
    { name: CommonFormField.RESULTS_RECEIVED_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.RESULTS_RECEIVED_TIME_OFFSET, value: '00:00', isHidden: !isAuthor }
  ];
  return [...referenceFieldParams, ...dateTimeFieldParams];
};

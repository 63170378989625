import { TherapyRecord } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { FormField, FormFieldLabel, OrderTypeValue, occupationalFormField, physicalFormField, respiratoryFormFields, speechFormField } from './constants';
import { FormField as CommonFormField, Title } from '../../shared/constants';

export const handleDisplayIntervention = (therapyRecords: TherapyRecord) => {
  const interventionList = therapyRecords?.chartData[FormField.INTERVENTION_LIST];
  const orderType = therapyRecords?.chartData[FormField.ORDER_TYPE]?.value;
  const formFields = {
    [OrderTypeValue.RESPIRATORY]: respiratoryFormFields,
    [OrderTypeValue.OCCUPATIONAL]: occupationalFormField,
    [OrderTypeValue.PHYSICAL]: physicalFormField,
    [OrderTypeValue.SPEECH]: speechFormField
  };
  const interventionRecord = interventionList?.map((intervention) => {
    const interventionTemp = { interventionDateTime: '' };
    (formFields[orderType] || []).forEach((formField) => {
      interventionTemp[formField] = intervention[formField]?.content;
    });
    const date = interventionTemp[FormField.INTERVENTION_DATE] || '';
    const time = interventionTemp[FormField.INTERVENTION_TIME] || '';
    interventionTemp.interventionDateTime = `${date} ${time}`;
    return interventionTemp;
  });
  return { chartHistory: interventionRecord || [] };
};

export const getTherapiesOrderEntryFormFieldBuilderItems = ({ isAuthor }: Readonly<{ isAuthor?: boolean }>): ChartMetaFormFieldParams[] => [
  { name: FormField.ORDER_TYPE, type: FormFieldInputType.DROPDOWN, label: FormFieldLabel.ORDER_TYPE },
  { name: FormField.ORDER_DESCRIPTION, type: FormFieldInputType.MULTI_SELECT_RADIO, label: FormFieldLabel.ORDER_DESCRIPTION },
  { name: FormField.ORDER_DESCRIPTION_TEXT, type: FormFieldInputType.TEXT_BOX, label: FormFieldLabel.ORDER_DESCRIPTION_TEXT },
  { name: FormField.ORDER_PRIORITY, type: FormFieldInputType.RADIO_CHOICE, label: FormFieldLabel.ORDER_PRIORITY },
  { name: FormField.TRANSPORTATION, type: FormFieldInputType.DROPDOWN, label: FormFieldLabel.TRANSPORTATION },
  { name: FormField.TIMES_PER_DAY, type: FormFieldInputType.TEXT_BOX, label: FormFieldLabel.TIMES_PER_DAY },
  { name: FormField.IV_REQUIRED, type: FormFieldInputType.RADIO_CHOICE, label: FormFieldLabel.IV_REQUIRED },
  { name: FormField.OXYGEN_REQUIRED, type: FormFieldInputType.RADIO_CHOICE, label: FormFieldLabel.OXYGEN_REQUIRED },
  { name: FormField.DATE_FIELD, type: FormFieldInputType.DATE, label: FormFieldLabel.DATE_LABEL, isHidden: isAuthor },
  { name: FormField.TIME_FIELD, type: FormFieldInputType.TIME, label: FormFieldLabel.TIME, defaultValue: '00:00' },
  { name: CommonFormField.ORDER_START_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.START_TIME_OFFSET, value: '00:00', isHidden: !isAuthor },
  { name: FormField.SPECIAL_INSTRUCTIONS, type: FormFieldInputType.TEXT_AREA, label: FormFieldLabel.SPECIAL_INSTRUCTIONS }
];

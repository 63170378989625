import { LanguageKeys } from 'lang';
import { Locales } from 'constants/app.constant';
import { Box, ChartActions, ErrorCard, ErrorFormField, SectionDivider, TitleTooltip } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import './neurological.assessment.scss';

const fieldOptions = { labelCss: 'u-els-font-size-h4' };

export interface NeurologicalAssessmentViewProps extends ChartViewProps {
  toolTips: { [name: string]: string };
  locale: Locales;
}

const NeurologicalAssessmentView = (props: NeurologicalAssessmentViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, resetAll, toolTips, locale } = props;

  return (
    <div className="neurological-assessment">
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <h1>{LanguageKeys.NEUROLOGICAL_ASSESSMENT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-2x u-els-margin-bottom-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1" className="column-left u-els-margin-top-2x">
            <h2>{LanguageKeys.LEVEL_CONSCIOUSNESS_ORIENTATION}</h2>
            <Box mt mb>
              <ErrorFormField formField={formFieldMap.get(FormField.ORIENTATION)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="column-right u-els-margin-top-2x">
            <h2>{LanguageKeys.EMOTIONAL_STATE}</h2>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="column-left">
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.CONSCIOUSNESS_LEVEL)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.CONSCIOUSNESS_LEVEL_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="column-right">
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.EMOTIONAL_STATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.EMOTIONAL_STATE_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        {locale === Locales.EN_AU && (
          <ELSFlex className="u-flex-grid-2x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2>{LanguageKeys.PUPILS}</h2>
              <Box mt mb2>
                <ELSFlex className="u-flex-grid-2x" wrap left>
                  <ELSFlexItem w="1o2">
                    <h4 className="u-els-margin-bottom-1o2">{LanguageKeys.LEFT}</h4>
                    <ErrorFormField formField={formFieldMap.get(FormField.PUPILS_LEFT_REACTIVITY)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={formFieldMap.get(FormField.PUPILS_LEFT_SIZE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2">
                    <h4 className="u-els-margin-bottom-1o2">{LanguageKeys.RIGHT}</h4>
                    <ErrorFormField formField={formFieldMap.get(FormField.PUPILS_RIGHT_REACTIVITY)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={formFieldMap.get(FormField.PUPILS_RIGHT_SIZE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
                  </ELSFlexItem>
                </ELSFlex>
              </Box>
              <ErrorFormField formField={formFieldMap.get(FormField.PUPILS_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </ELSFlexItem>
          </ELSFlex>
        )}

        <SectionDivider />

        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h2>{LanguageKeys.HAND_GRIP}</h2>
            <Box mt2>
              <ELSFlex className="u-flex-grid-2x" wrap left>
                <ELSFlexItem w="1o2">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.HAND_GRIP_BILATERAL_ASSESSMENT)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    options={fieldOptions}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.HAND_GRIP_LEFT_STRENGTH)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    options={fieldOptions}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.HAND_GRIP_RIGHT_STRENGTH)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    options={fieldOptions}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <ErrorFormField formField={formFieldMap.get(FormField.HAND_GRIP_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h2>{LanguageKeys.FOOT_PUMPS}</h2>
            <Box mt2>
              <ELSFlex className="u-flex-grid-2x" wrap left>
                <ELSFlexItem w="1o2">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.FOOT_PUMPS_BILATERAL_ASSESSMENT)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    options={fieldOptions}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.FOOT_PUMPS_LEFT_STRENGTH)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    options={fieldOptions}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.FOOT_PUMPS_RIGHT_STRENGTH)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    options={fieldOptions}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <ErrorFormField formField={formFieldMap.get(FormField.FOOT_PUMPS_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className=" u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o1">
            <h2>{LanguageKeys.CENTRAL_NERVOUS_SYSTEM_ASSESSMENT}</h2>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mb>
              <ErrorFormField formField={formFieldMap.get(FormField.CENTRAL_NERVOUS_NO_PROBLEMS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.CENTRAL_NERVOUS_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.CENTRAL_NERVOUS_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className=" u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o1">
            <h2>{LanguageKeys.EXTRAPYRAMIDAL_SIDE_EFFECTS}</h2>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <TitleTooltip className="u-els-margin-bottom" title={LanguageKeys.AKATHISIA} tooltip={toolTips[FormField.EPS_AKATHISIA]} />
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.EPS_AKATHISIA)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
            <ErrorFormField formField={formFieldMap.get(FormField.EPS_AKATHISIA_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <TitleTooltip className="u-els-margin-bottom" title={LanguageKeys.ACUTE_DYSTONIA} tooltip={toolTips[FormField.EPS_ACUTE_DYSTONIA]} />
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.EPS_ACUTE_DYSTONIA)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
            <ErrorFormField formField={formFieldMap.get(FormField.EPS_ACUTE_DYSTONIA_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <TitleTooltip className="u-els-margin-bottom" title={LanguageKeys.PSEUDOPARKINSONISM} tooltip={toolTips[FormField.EPS_PSEUDOPARKINSONISM]} />
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.EPS_PSEUDOPARKINSONISM)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
            <ErrorFormField formField={formFieldMap.get(FormField.EPS_PSEUDOPARKINSONISM_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <TitleTooltip className="u-els-margin-bottom" title={LanguageKeys.TARDIVE_DYSKINESIA} tooltip={toolTips[FormField.EPS_TARDIVE_DYSKINESIA]} />
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.EPS_TARDIVE_DYSKINESIA)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
            <ErrorFormField formField={formFieldMap.get(FormField.EPS_TARDIVE_DYSKINESIA_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className=" u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o1">
            <h2>{LanguageKeys.NEUROLEPTIC_MALIGNANT_SYNDROME}</h2>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.NEUROLEPTIC_MALIGNANT_SYNDROME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

NeurologicalAssessmentView.displayName = 'NeurologicalAssessmentView';
export default NeurologicalAssessmentView;

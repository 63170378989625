import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { BloodGlucoseRecord } from 'models/api-response';
import { BloodGlucoseGraphItem, ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { cssHelper } from 'helpers';
import { Box, ChartActions, ErrorCard, ErrorFormField, GraphContainer, HistoryLineChart } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import BloodGlucoseHistory from './BloodGlucoseHistory';
import '../vital.signs.common.scss';

export interface BloodGlucoseViewProps {
  chartMetaContentMap?: Map<string, string>;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  graphData: BloodGlucoseGraphItem[];
  deleteHistory: Function;
  chartHistory: BloodGlucoseRecord[];
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  intl?: IntlShape;
  locale?: Locales;
}

const BloodGlucoseView = ({
  chartMetaContentMap,
  chartMetaFormFields,
  graphData,
  deleteHistory,
  chartHistory,
  formSubmittedCount,
  chartActionsProps,
  intl,
  locale
}: BloodGlucoseViewProps) => (
  <>
    <div className="vital-signs-common chart-input">
      <h1 className="chart-input__header">Blood Glucose</h1>
      <ELSFlex left wrap>
        <ELSFlexItem>
          <div className={cssHelper.mapElsCss({ els: 'padding-right-3x', md: 'padding-right-1x1o2', lg: 'padding-right-2x' })}>
            <h2 className="u-els-font-size-h2 u-els-margin-top">Chart Inputs</h2>
            <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
            <div className="chart-input__section-title" />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.TIME_LAST_MEAL)} formSubmittedCount={formSubmittedCount} />
            <div className="u-els-margin-top">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.GLUCOSE_READING)} formSubmittedCount={formSubmittedCount} />
            </div>
            {chartMetaFormFields?.has(FormField.GLUCOSE_READING) &&
              chartMetaFormFields.get(FormField.GLUCOSE_READING).value.length > 0 &&
              chartMetaFormFields.get(FormField.GLUCOSE_READING).errors.length === 0 && (
                <div className="u-els-margin-top blood-glucose__nursing-actions">
                  <ErrorFormField
                    formField={chartMetaFormFields.has(FormField.NURSING_ACTIONS) && chartMetaFormFields.get(FormField.NURSING_ACTIONS)}
                    formSubmittedCount={formSubmittedCount}
                  />
                </div>
              )}
            {locale === Locales.EN_AU && <ErrorFormField formField={chartMetaFormFields.get(FormField.NURSING_ACTIONS_OTHER)} formSubmittedCount={formSubmittedCount} />}
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.NOTE)}
              formSubmittedCount={formSubmittedCount}
              options={{
                labelCss: 'chart-input__section-title'
              }}
            />
            <ChartActions {...chartActionsProps} />
          </div>
        </ELSFlexItem>
        <ELSFlexItem className="o-els-flex-layout__item--grow chart-input__scroll-table-container">
          <div className="chart-input__table-graph-container">
            <BloodGlucoseHistory chartHistory={chartHistory} deleteHistory={deleteHistory} chartMetaContentMap={chartMetaContentMap} intl={intl} locale={locale} />
            <Box mt2>
              <GraphContainer dataSize={graphData.length}>
                <HistoryLineChart
                  width={600}
                  yLabel={intl.formatMessage({ id: LanguageKeys.BLOOD_GLUCOSE.BLOOD_GLUCOSE_LEVEL_MG_DL_Y_AXIS_LABEL })}
                  data={graphData}
                  dataKeys={['glucose']}
                  locale={locale}
                />
              </GraphContainer>
            </Box>
          </div>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  </>
);

BloodGlucoseView.displayName = 'BloodGlucoseView';
export default BloodGlucoseView;

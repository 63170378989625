export enum SectionLabel {
  ADOLESCENT_RISK_ASSESSMENT = 'Adolescent Risk Assessment',
  PRESENCE_OF_RISK = 'Indicate the presence of risk in the following',
  APPROPRIATE_REFERRAL = 'If you select Yes to any of the above, make appropriate referral',
  DENTAL_CARE = 'Dental care',
  SEAT_BELT_USE = 'Seat belt use',
  RIDING_IN_CAR = 'Riding in car with a driver who has been drinking alcohol or using drugs',
  CARRYING_A_WEAPON = 'Carrying a weapon/gang involvement',
  FEEL_UNSAFE_AT_SCHOOL = 'Feel unsafe at school',
  ATTEMPTED_SUICIDE = 'Attempted suicide',
  ABUSE_MALTREATMENT = 'Abuse/maltreatment',
  SLEEP = 'Sleep',
  AUTOMOBILE_CRASH = 'Automobile crash',
  DESCRIBE = 'Describe'
}

export enum FormField {
  DENTAL_CARE = 'dentalCare',
  DENTAL_CARE_DESCRIBE = 'dentalCareDescribe',
  SEAT_BELT_USE = 'seatBeltUse',
  SEAT_BELT_USE_DESCRIBE = 'seatBeltUseDescribe',
  RIDING_IN_CAR = 'ridingInCar',
  RIDING_IN_CAR_DESCRIBE = 'ridingInCarDescribe',
  SLEEP = 'sleep',
  SLEEP_DESCRIBE = 'sleepDescribe',
  CARRYING_A_WEAPON = 'carryingAWeapon',
  CARRYING_A_WEAPON_DESCRIBE = 'carryingAWeaponDescribe',
  FEEL_UNSAFE_AT_SCHOOL = 'feelUnsafeAtSchool',
  FEEL_UNSAFE_AT_SCHOOL_DESCRIBE = 'feelUnsafeAtSchoolDescribe',
  ATTEMPTED_SUICIDE = 'attemptedSuicide',
  ATTEMPTED_SUICIDE_DESCRIBE = 'attemptedSuicideDescribe',
  ABUSE_MALTREATMENT = 'abuseMaltreatment',
  ABUSE_MALTREATMENT_DESCRIBE = 'abuseMaltreatmentDescribe',
  AUTOMOBILE_CRASH = 'automobileCrash',
  AUTOMOBILE_CRASH_DESCRIBE = 'automobileCrashDescribe'
}

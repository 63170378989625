import cx from 'classnames';
import { Component } from 'react';
import { ErrorField } from 'models/ui';
import { ErrorMessages } from 'components/common';
import { ELSIcon } from 'components/common/els';
import './error.tooltip.scss';

interface ErrorTooltipProps {
  errors: ErrorField[];
  formSubmittedCount: number;
  id?: string;
}

interface ErrorTooltipState {
  show: boolean;
}

class ErrorTooltip extends Component<ErrorTooltipProps, ErrorTooltipState> {
  constructor(props) {
    super(props);

    this.state = {
      show: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // if the state was toggled by the user, exit this method
    if (prevState.show !== this.state.show) {
      return;
    }

    // if the form was submitted again and we aren't currently showing the tooltip, show it
    if (this.props.formSubmittedCount > prevProps.formSubmittedCount && !this.state.show) this.onOpen();
  }

  onOpen = () => this.setState({ show: true });

  onClose = () => this.setState({ show: false });

  expandedView = () => (
    <>
      <div className="error-tooltip__arrow-wrapper">
        <div className="error-tooltip__arrow" />
      </div>
      <div className="error-tooltip__content">
        <button className="error-tooltip__close-btn sng-button-icon-wrapper" type="button" aria-label="Close" onClick={this.onClose}>
          <ELSIcon name="close" prefix="hmds" color="n10" size="1x" />
        </button>
        <ErrorMessages errors={this.props.errors} />
      </div>
    </>
  );

  collapsedView = () => <ELSIcon name="alert" prefix="gizmo" color="warn" size="1x" onClick={this.onOpen} title="Error" ariaProps={{ 'aria-hidden': 'false' }} />;

  render() {
    const view = this.state.show ? this.expandedView() : this.collapsedView();

    return (
      <div id={this.props.id || ''} className={cx('error-tooltip', { 'error-tooltip--expanded': this.state.show })}>
        {view}
      </div>
    );
  }
}

export default ErrorTooltip;

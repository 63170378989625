import cx from 'classnames';
import { DiagnosisFragment } from 'models/api-response';
import { AccordionBadge, Box } from 'components/common';
import DeleteTracker from './DeleteTracker';
import MultiLineWithEmpty from '../shared/MultiLineWithEmpty';

interface SectionDiagnosesProps {
  isFullReport: boolean;
  diagnosisRecords: DiagnosisFragment[];
}
const SectionDiagnoses = (props: SectionDiagnosesProps) => {
  return (
    <Box mb4>
      <Box mb2>
        <h2>
          {'Diagnoses '}
          <span className="c-els-badge c-els-badge--size-intro">{props.diagnosisRecords.length}</span>
        </h2>
      </Box>
      <div className="report__record">
        {props.diagnosisRecords.map((item) => (
          <div className="record-item" key={item.fragmentId}>
            <Box mb2>
              <h3 className={cx('u-els-display-inline-block u-els-margin-right-2x', { 'row--deleted': !item.active })}>{item?.chartData?.name}</h3>
              {item?.chartData?.typeLabel && (
                <AccordionBadge>
                  <span>{item?.chartData?.typeLabel}</span>
                </AccordionBadge>
              )}
              {!item.active && <DeleteTracker className="u-els-margin-left-2x" firstName={item.modifier?.firstName} lastName={item.modifier?.lastName} dateTime={item.updatedAt} />}
            </Box>
            {props.isFullReport && (
              <>
                <Box mb>
                  <h4>Pathophysiology</h4>
                  <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.pathophysiology} />
                </Box>
                <Box mb>
                  <h4>Therapeutic Regimen</h4>
                  <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.therapeuticRegimen} />
                </Box>
                <div>
                  <h4>Current Health Problems and Related Functional Changes</h4>
                  <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.problemsAndChanges} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default SectionDiagnoses;

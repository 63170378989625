import { DiagnosticTestsOrderEntryRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, Sidebar } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import DiagnosticTestsReadonly from './DiagnosticTestsReadonly';
import { FormField as CommonFormField } from '../shared/constants';

export interface DiagnosticTestSidebarViewProps {
  openSidebar: boolean;
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  isReadOnly: boolean;
  diagnosticTest: DiagnosticTestsOrderEntryRecord;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  sidebarProps: Function;
}
const DiagnosticTestsSidebarView = ({
  openSidebar,
  formSubmittedCount,
  formFieldMap,
  isReadOnly,
  diagnosticTest,
  chartActionsProps,
  onCloseClick,
  sidebarProps
}: DiagnosticTestSidebarViewProps) => (
  <Sidebar open={openSidebar} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
    <Box mt3>
      <h2>Diagnostic Test Results - Cystomotry</h2>
    </Box>
    <div className="sidebar__divider" />
    {isReadOnly ? (
      <DiagnosticTestsReadonly diagnosticTest={diagnosticTest} />
    ) : (
      <Box>
        <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} className="u-els-margin-bottom" />
        <ELSFlex className="u-flex-grid-2x" wrap>
          <ELSFlexItem w="1o1">
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.CATEGORY)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ isDisabled: true }}
            />
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.ORDER_DESCRIPTION)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ isDisabled: true }}
            />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EXAM_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.REPORT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.IMPRESSION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RECOMMENDATION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NAME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SIGNATURE)} formSubmittedCount={formSubmittedCount} />
            <Box>
              <h4 className="u-els-margin-bottom">Results Received</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.RESULTS_RECEIVED_DATE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.RESULTS_RECEIVED_TIME)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(CommonFormField.RESULTS_RECEIVED_TIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt4 mb>
              <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
            </Box>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
    )}
  </Sidebar>
);

DiagnosticTestsSidebarView.displayName = 'DiagnosticTestsSidebarView';
export default DiagnosticTestsSidebarView;

import { isEmpty, orderBy, startCase } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DiagnosisFragment, DiagnosticTestFragment, LaboratoryTestFragment, MedicationFragment, PatientRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartComponentProps } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withFormUtilities } from 'components/common';
import ReportView, { ReportViewProps } from './ReportView';

export enum Tabs {
  SUMMARY = 0,
  FULL_REPORT = 1
}

export interface ReportProps extends ChartComponentProps {
  patient: PatientRecord;
}

interface ReportState {
  activeTab: number;
  diagnosisRecords: DiagnosisFragment[];
  medicationRecords: MedicationFragment[];
  laboratoryTestRecords: LaboratoryTestFragment[];
  diagnosticTestsRecords: DiagnosticTestFragment[];
}

class Report extends Component<ReportProps, ReportState> {
  static displayName = 'Report';

  constructor(props) {
    super(props);
    this.state = {
      activeTab: Tabs.SUMMARY,
      diagnosisRecords: [],
      medicationRecords: [],
      laboratoryTestRecords: [],
      diagnosticTestsRecords: []
    };
  }

  componentDidMount() {
    appHelper.useLoader(this.loadChartData());
  }

  loadChartData = () => {
    const chartId = this.props.assessment.simChartId;
    const navIds = [NAV_ID.DIAGNOSIS, NAV_ID.MEDICATIONS, NAV_ID.LABORATORY_TESTS, NAV_ID.DIAGNOSTIC_TESTS];

    return chartService.loadFragments({ chartId, navIds, fragmentTypes: [FragmentType.CHARTING] }).then(this.bindRecords);
  };

  bindRecords = ({ data: fragments }) => {
    const diagnosisRecords = fragments.filter((fragment) => fragment.navElementId === NAV_ID.DIAGNOSIS);
    const medicationRecords = fragments.filter((fragment) => fragment.navElementId === NAV_ID.MEDICATIONS);
    const laboratoryTestRecords = fragments.filter((fragment) => fragment.navElementId === NAV_ID.LABORATORY_TESTS);
    const diagnosticTestsRecords = fragments.filter((fragment) => fragment.navElementId === NAV_ID.DIAGNOSTIC_TESTS);

    this.setState({
      diagnosisRecords: orderBy(diagnosisRecords, ['chartData.displayOrder', 'createdAt'], ['asc', 'desc']) as DiagnosisFragment[],
      medicationRecords: orderBy(medicationRecords, 'createdAt') as MedicationFragment[],
      laboratoryTestRecords: orderBy(laboratoryTestRecords, 'createdAt') as LaboratoryTestFragment[],
      diagnosticTestsRecords: orderBy(diagnosticTestsRecords, 'createdAt') as DiagnosticTestFragment[]
    });
  };

  handleTabGroupClick = (activeTab) => {
    if (activeTab === Tabs.SUMMARY) {
      this.setState({ activeTab: Tabs.FULL_REPORT });
    } else {
      this.setState({ activeTab: Tabs.SUMMARY });
    }
  };

  getPatientInfo = () => {
    const { patient, locale } = this.props;
    if (isEmpty(patient)) {
      return '';
    }
    const age = startCase(dateTimeHelper.dateToAgeString({ date: dateTimeHelper.toDate(patient.patientDateOfBirth), locale }));
    return `${patient.patientIdentifier}, a ${age} Old ${startCase(patient.patientSex)}`.trim();
  };

  render() {
    const viewProps: ReportViewProps = {
      activeTab: this.state.activeTab,
      patientInfo: this.getPatientInfo(),
      studentInfo: chartService.formatName(this.props.assessment),
      diagnosisRecords: this.state.diagnosisRecords,
      medicationRecords: this.state.medicationRecords,
      laboratoryTestRecords: this.state.laboratoryTestRecords,
      diagnosticTestsRecords: this.state.diagnosticTestsRecords,
      onTabGroupClick: this.handleTabGroupClick
    };
    return <ReportView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  patient: appSelectors.getPatient(state)
});

const enhancers = [connect(mapStateToProps), withFormUtilities];
export { Report as BaseReport };
export default compose(...enhancers)(Report);

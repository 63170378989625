import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { Locales, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, getAdmissionDataFormFields } from './constants';
import AdmissionDataView, { AdmissionDataViewProps } from './AdmissionDataView';
import AdmissionDataViewAu, { AdmissionDataViewAuProps } from './AdmissionDataViewAu';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export interface AdmissionDataProps extends ChartComponentProps {
  patientContext: PatientContext;
}
interface AdmissionDataState {
  isLocked: boolean;
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class AdmissionData extends Component<AdmissionDataProps, AdmissionDataState> {
  static displayName = 'AdmissionData';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.ADMISSION_DATA, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { fragment } = this.state;
    const { locale } = this.props;
    const dataMap = new Map();

    getAdmissionDataFormFields({ fragment, locale }).forEach(({ isHidden, name, ...item }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, ...item }));
      }
    });

    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords, buildRecordsFromField } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Admission Data';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.THIS_ADMISSION,
          records: [
            buildPatientRecord(formFieldMap, FormField.ADMISSION_DATE),
            buildPatientRecord(formFieldMap, FormField.ESTIMATED_DISCHARGE_DATE),
            buildPatientRecord(formFieldMap, FormField.ADMISSION_ADMITTED_FROM),
            buildPatientRecord(formFieldMap, FormField.ADMISSION_ADMITTED_FROM_OTHER, 'Admitted From Other'),
            buildPatientRecord(formFieldMap, FormField.ADMISSION_ARRIVED_BY),
            buildPatientRecord(formFieldMap, FormField.ADMISSION_ARRIVED_BY_OTHER, 'Arrived By Other')
          ]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.VERBAL_ADMISSION_HISTORY,
          records: [...buildPatientRecords(formFieldMap, FormField.VERBAL_ADMISSION_HISTORY)]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.PAST_ADMISSION,
          records: [
            buildPatientRecord(formFieldMap, FormField.PAST_ADMISSION_HOSPITAL),
            buildPatientRecord(formFieldMap, FormField.PAST_ADMISSION_REASON),
            buildPatientRecord(formFieldMap, FormField.PAST_ADMISSION_WHERE)
          ]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.INFORMANT_HISTORIAN,
          records: [buildPatientRecord(formFieldMap, FormField.INFORMANT_HISTORIAN_INFORMATION_FROM), buildPatientRecord(formFieldMap, FormField.INFORMANT_HISTORIAN_NAME)]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED,
          records: [
            buildPatientRecord(formFieldMap, FormField.PREGNANCY_RELATED),
            ...buildRecordsFromField(formFieldMap.get(FormField.PREGNANCY_RELATED_SELECT)),
            buildPatientRecord(formFieldMap, FormField.INDUCTION),
            buildPatientRecord(formFieldMap, FormField.PREGNANCY_RELATED_SELECT_OTHER),
            buildPatientRecord(formFieldMap, FormField.CONTRACTIONS_TIME_OF_ONSET),
            buildPatientRecord(formFieldMap, FormField.CONTRACTIONS_FREQUENCY),
            buildPatientRecord(formFieldMap, FormField.CONTRACTIONS_DURATION),
            buildPatientRecord(formFieldMap, FormField.CONTRACTIONS_QUALITY),
            buildPatientRecord(formFieldMap, FormField.CONTRACTIONS_FLUID),
            buildPatientRecord(formFieldMap, FormField.CONTRACTIONS_COLOR)
          ]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.CONFIRM_IDENTIFICATION,
          records: [buildPatientRecord(formFieldMap, FormField.CONFIRM_IDENTIFICATION)]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS,
          records: [
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_NAME),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_REL_ORG),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_PHONE),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_AWARE_OF_ADMISSION)
          ]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.PREFERRED_NAME,
          records: [buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_PREFERRED_NAME), buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_PREFERRED_PRONOUNS)]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.ADVANCED_CARE_PLANNING,
          records: [buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_ADVANCED_CARE_PLANNING)]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.IS_PATIENT_OF_ATSI,
          records: [buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_IS_PATIENT_ATSI), buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_IS_PATIENT_ATSI_IF_YES)]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER,
          records: [
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_DOES_PATIENT_HAVE_A_CARER),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_CARER_NAME),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_CARER_REL_ORG),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_CARER_PHONE)
          ]
        },
        {
          sectionTitle: LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER,
          records: [
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_IS_PATIENT_A_CARER),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_NAME),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_REL_ORG),
            buildPatientRecord(formFieldMap, FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_PHONE)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, patientContext, intl, locale } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: AdmissionDataViewProps = {
      isLocked,
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      patientContext,
      chartMetaFormFields: formFieldMap
    };
    const viewPropsAu: AdmissionDataViewAuProps = {
      ...viewProps,
      intl,
      locale
    };
    if (locale === Locales.EN_AU) {
      return <AdmissionDataViewAu {...viewPropsAu} />;
    }
    return <AdmissionDataView {...viewProps} />;
  }
}

export { AdmissionData as BaseAdmissionData };
export default compose(withChartLogic, withAdmissionHistory)(AdmissionData);

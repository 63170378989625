import { connect } from 'react-redux';
import { cssHelper } from 'helpers';
import { studentSelectors } from 'redux/ducks/student';
import AssignmentInformation from 'components/common/assignment-information/AssignmentInformation';
import Box from 'components/common/box/Box';
import ChartingActionBar from 'components/common/charting-date-time/ChartingActionBar';
import { ELSFlex } from 'components/common/els';
import './assignment.bar.scss';

interface AssignmentBarProps {
  assignmentTitle: string;
  toggleFlyout: (content: JSX.Element | null) => void;
}

export const AssignmentBar = (props: AssignmentBarProps) => (
  <ELSFlex
    className={cssHelper.mapElsCss({
      className: 'assignment-bar',
      els: 'padding-left-3x padding-right-3x',
      lg: 'padding-left-2x padding-right-2x',
      md: 'padding-left-1x1o2 padding-right-1x1o2'
    })}
    middle
    spaceBetween
  >
    <Box className="u-els-bold" mr>
      {props.assignmentTitle}
    </Box>
    <ChartingActionBar />
    <AssignmentInformation toggleFlyout={props.toggleFlyout} />
  </ELSFlex>
);

const mapStateToProps = (state) => ({
  assignmentTitle: studentSelectors.getAssignment(state)?.title || ''
});

AssignmentBar.displayName = 'AssignmentBar';
export { AssignmentBar as BaseAssignmentBar };
export default connect(mapStateToProps)(AssignmentBar);

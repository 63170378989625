import {
  ActivitiesDailyLivingFragment,
  ChartFragment,
  ImpressionFragment,
  MedicalHistoryFragment,
  MentalStatusFragment,
  ObstetricHistoryFragment,
  PhysicalExaminationFragment,
  PsychiatricHistoryFragment,
  ReviewOfSystemsFragment
} from 'models/api-response';
import { ChartActionsComponentProps, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, Headline, Tile } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { LandingChart } from './HistoryPhysical';
import HistoryPhysicalReadOnly from './HistoryPhysicalReadOnly';

export interface HistoryPhysicalViewProps {
  isChartLocked: boolean;
  charts: Map<string, LandingChart>;
  selectedRecord: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  onTileClick: Function;
  onCompleteClick: Function;
  patientContext: PatientContext;
}

const HistoryPhysicalView = (props: HistoryPhysicalViewProps) => {
  const { charts } = props;
  return (
    <div>
      <h1>History and Physical</h1>
      {!props.isChartLocked && (
        <>
          <Box mt3 mb3>
            <ol>
              <ELSFlex className="u-flex-grid-3x" left wrap>
                {Array.from(props.charts.values()).map((item) => (
                  <ELSFlexItem key={item.navId}>
                    <li>
                      <Tile title={item.name} active={item.hasData} onClick={() => props.onTileClick(item.routePath)} />
                    </li>
                  </ELSFlexItem>
                ))}
              </ELSFlex>
            </ol>
          </Box>
          <div>
            <h4>Complete History and Physical</h4>
            <div className="u-els-font-size-meta">Note: Once the History and Physical is completed, no further changes can be made.</div>
            <ChartActions {...props.chartActionsProps} />
          </div>
        </>
      )}
      {props.isChartLocked && (
        <>
          <Headline statusFragment={props.selectedRecord} hiddenCreatedBy />
          <HistoryPhysicalReadOnly
            medicalHistoryRecord={charts.get(NAV_ID.MEDICAL_HISTORY)?.fragment as MedicalHistoryFragment}
            obstetricHistoryRecord={charts.get(NAV_ID.OBSTETRIC_HISTORY)?.fragment as ObstetricHistoryFragment}
            physicalExaminationRecord={charts.get(NAV_ID.PHYSICAL_EXAMINATION)?.fragment as PhysicalExaminationFragment}
            impressionRecord={charts.get(NAV_ID.IMPRESSIONS)?.fragment as ImpressionFragment}
            activitiesDailyLivingRecord={charts.get(NAV_ID.ACTIVITIES_DAILY_LIVING)?.fragment as ActivitiesDailyLivingFragment}
            reviewOfSystemsRecord={charts.get(NAV_ID.REVIEW_OF_SYSTEMS)?.fragment as ReviewOfSystemsFragment}
            psychiatricHistory={charts.get(NAV_ID.PSYCHIATRIC_HISTORY)?.fragment as PsychiatricHistoryFragment}
            mentalStatus={charts.get(NAV_ID.MENTAL_STATUS)?.fragment as MentalStatusFragment}
            patientContext={props.patientContext}
          />
        </>
      )}
    </div>
  );
};

HistoryPhysicalView.displayName = 'HistoryPhysicalView';
export default HistoryPhysicalView;

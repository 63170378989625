import { LaborProgressRecord } from 'models/api-response';

export enum FormField {
  LABOR_ONSET_DATETIME_OFFSET = 'laborOnsetDateTimeOffset',
  LABOR_ONSET_DATE = 'laborOnsetDate',
  LABOR_ONSET_TIME = 'laborOnsetTime',
  IS_ONSET_SIDE_BAR = 'isOnsetSideBar',
  BASELINE_BPM = 'baselineBpm',
  MONITOR_MODE = 'monitorMode',
  VARIABILITY = 'variability',
  ACCELERATIONS = 'accelerations',
  DECELERATIONS = 'decelerations',
  MONITOR_MODE_DROPDOWN = 'monitorModeDropDown',
  INTENSITY = 'intensity',
  FREQUENCY = 'frequency',
  DURATION = 'duration',
  CERVICAL_DILATION = 'cervicalDilation',
  STATION = 'station',
  POSITION = 'position',
  EFFACEMENT = 'effacement',
  NOTES = 'notes'
}

export enum HistoryField {
  FETAL_ASSESSMENT = 'Fetal Assessment',
  UTERINE_ACTIVITY = 'Uterine Activity',
  VAGINAL_ASSESSMENT = 'Vaginal Assessment'
}

export enum FormFieldLabel {
  MONITOR_MODE_DROPDOWN = 'Monitor Mode',
  DATE = 'Date',
  TIME = 'Time',
  CERVICAL_DILATION = 'Cervical Dilation'
}

export interface LaborProgressHistoryProps {
  chartHistory: LaborProgressRecord[];
  deleteHistory?: Function;
}

export enum GRAPH_CHART_TYPE {
  FETAL_ASSESSMENT = 'fetalAssessment',
  PARTOGRAM = 'partogram'
}
export const GRAPH_TYPES = [
  { name: 'Fetal Assessment', value: GRAPH_CHART_TYPE.FETAL_ASSESSMENT },
  { name: 'Partogram', value: GRAPH_CHART_TYPE.PARTOGRAM }
];

import cx from 'classnames';
import { Box, ChartHistory } from 'components/common';
import { RadioRenderer } from './shared';

export interface PrescriptionMedicationsTableProps {
  chartHistory: object[];
  changeHandler: Function;
}

const PrescriptionMedicationsTable = (props: PrescriptionMedicationsTableProps) => {
  const { chartHistory, changeHandler } = props;
  const css = ['column__medication', '', '', 'column__frequency', 'column__last-dose', 'column__indication', 'column__admit', 'column__discharge'];

  const resumeOnAdmitRenderer = (row) => <RadioRenderer row={row} changeHandler={changeHandler} homeMedicationType="prescriptionMedicationsData" fieldChangeName="resumeOnAdmit" />;

  const resumeOnDischargeRenderer = (row) => (
    <RadioRenderer row={row} changeHandler={changeHandler} homeMedicationType="prescriptionMedicationsData" fieldChangeName="resumeOnDischarge" />
  );

  const customRenderer = (active, value) => <div className={cx({ 'row--deleted': !active })}>{value}</div>;

  const dataSource = {
    headers: [
      { field: 'medication', text: 'Medication', customRender: (row) => customRenderer(row.active, row.medication) },
      { field: 'doseMedications', text: 'Dose', customRender: (row) => customRenderer(row.active, row.doseMedications) },
      { field: 'formMedications', text: 'Form', customRender: (row) => customRenderer(row.active, row.formMedications) },
      { field: 'routeMedications', text: 'Route', customRender: (row) => customRenderer(row.active, row.routeMedications) },
      { field: 'frequencyMedications', text: 'Frequency', customRender: (row) => customRenderer(row.active, row.frequencyMedications) },
      { field: 'lastDoseDate', text: 'Last Dose', customRender: (row) => customRenderer(row.active, row.lastDoseDate) },
      { field: 'indication', text: 'Indication', customRender: (row) => customRenderer(row.active, row.indication) },
      { field: 'resumeOnAdmit', text: 'Resume on Admit', customRender: (row) => resumeOnAdmitRenderer(row) },
      { field: 'resumeOnDischarge', text: 'Resume on Discharge', customRender: (row) => resumeOnDischargeRenderer(row) }
    ],
    css,
    data: chartHistory
  };
  return (
    <Box mt className="prescription-medications-table">
      <ChartHistory dataSource={dataSource} showNumEntries={false} />
    </Box>
  );
};

PrescriptionMedicationsTable.displayName = 'PrescriptionMedicationsTable';
export default PrescriptionMedicationsTable;

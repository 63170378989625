import cx from 'classnames';
import Badge from 'components/common/badge/Badge';
import ErrorMessage from './ErrorMessage';
import Input from './Input';
import LabelContainer, { DefaultLabelContent } from './Label';
import Tooltip from './Tooltip';
import { ErrorFormFieldLayoutProps } from './types';

export default function ErrorFormFieldLayout({
  fieldId,
  formField,
  formSubmittedCount,
  formFieldMap,
  className,
  useDefaultMargin,
  labelProps,
  badgeProps,
  tooltipProps,
  inputProps,
  errors
}: ErrorFormFieldLayoutProps) {
  const { value: badgeValue, className: badgeClassName, forceShown: badgeForceShown, defaultValue: badgeDefaultValue } = badgeProps ?? {};
  const { isEnabledTooltip, tooltipLabel } = tooltipProps ?? {};
  const hasErrors = !!errors?.length;
  const isShowErrorMessage = hasErrors && errors.some((error) => !!error?.message);
  const formFieldValue = formFieldMap?.get(formField.name)?.value;

  return (
    <div
      id={fieldId}
      className={cx(`chart-input__field ${fieldId}`, className, {
        'u-els-margin-bottom-2x': useDefaultMargin,
        'chart-input__field--has-errors': hasErrors
      })}
    >
      <LabelContainer formField={formField} {...labelProps}>
        <DefaultLabelContent formField={formField} />
        {isEnabledTooltip && <Tooltip label={tooltipLabel} />}
        {((badgeValue && formFieldValue) || badgeForceShown) && (
          <Badge className={`u-els-font-size-meta u-els-padding-left-1x u-els-padding-right-1x c-els-label__badge ${badgeClassName}`}>{badgeValue ?? badgeDefaultValue}</Badge>
        )}
      </LabelContainer>
      <Input {...inputProps} />
      {isShowErrorMessage && <ErrorMessage errors={errors} formField={formField} formSubmittedCount={formSubmittedCount} />}
    </div>
  );
}

import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import { LoadableChartViewProps } from '../../shared/constants';

const OstomiesView = (props?: LoadableChartViewProps) => {
  const { fragment, formFieldMap, formSubmittedCount } = props;
  return (
    <div className="ostomies">
      <h1>Ostomies</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.DISCONTINUED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.LOCATION)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.OSTOMY_TYPE)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider mt />
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.PERI_STOMA_PERI_INSERTION}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PERI_STOMA_PERI_INSERTION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.STOMA_CONDITION}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.STOMA_CONDITION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.DRAINAGE_FROM_OSTOMY}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAINAGE_FROM_OSTOMY)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.OSTOMY_PATENCY}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.OSTOMY_PATENCY)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.PATIENT_RESPONSE}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h4>{SectionTitle.DRESSING_AND_OSTOMY_CARE}</h4>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRESSING_AND_OSTOMY_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

OstomiesView.displayName = 'OstomiesView';
export default OstomiesView;

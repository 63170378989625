import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import LaborInterventionsView from './LaborInterventionsView';
import { Title } from '../shared/constants';

class LaborInterventions extends Component<ChartComponentProps> {
  static displayName = 'LaborInterventions';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const multiselectDropdowns = [FormField.LABOR_CARE, FormField.DELIVERY_CARE, FormField.PATIENT_RESPONSE];

    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));

    dataMap.set(FormField.NO_CARE_REQUIRED, createFormField({ name: FormField.NO_CARE_REQUIRED, type: FormFieldInputType.CHECK_BOX }));
    dataMap.set(FormField.LABOR_CARE_NOTES, createFormField({ name: FormField.LABOR_CARE_NOTES, type: FormFieldInputType.TEXT_AREA }));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { buildSection } = chartHelper;
    return [
      buildSection({ sectionTitle: SectionTitle.LABOR_INTERVENTIONS, fields: [FormField.NO_CARE_REQUIRED], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.LABOR_CARE, fields: [FormField.LABOR_CARE], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.DELIVERY_CARE, fields: [FormField.DELIVERY_CARE], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.PATIENT_RESPONSE, fields: [FormField.PATIENT_RESPONSE], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.LABOR_CARE_NOTES, fields: [FormField.LABOR_CARE_NOTES], formFieldMap })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SYSTEM_NURSING_INTERVENTIONS,
      fragmentTitle: SectionTitle.LABOR_INTERVENTIONS,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields());

  render() {
    const { enableDisplayRecordsButton, hasUnsavedChanges, displayAuthoringData, formFieldMap, formSubmittedCount } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: this.handleCancelClick,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: ChartViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount
    };
    return <LaborInterventionsView {...viewProps} />;
  }
}

export { LaborInterventions as BaseLaborInterventions };
export default compose(withSavedPatientChartsPage, withChartLogic)(LaborInterventions);

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { IntlShape } from 'react-intl';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getPainAssessmentFormFieldBuilderItems } from './constants';
import PainAssessmentView from './PainAssessmentView';
import { Title } from '../shared/constants';

interface PainAssessmentState {
  resetAll: number;
}
export interface PainAssessmentProps extends ChartComponentProps {
  enableMultiStepsAuthoring: Function;
  intl: IntlShape;
}

class PainAssessment extends Component<PainAssessmentProps, PainAssessmentState> {
  static displayName = 'PainAssessment';
  customFieldTitleMap: Map<string, string>;

  constructor(props) {
    super(props);
    this.customFieldTitleMap = this.buildCustomTitle();
    this.state = {
      resetAll: 0
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    this.props.enableMultiStepsAuthoring(true);
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { intl, locale } = this.props;
    const dataMap = new Map();

    getPainAssessmentFormFieldBuilderItems(locale).forEach(({ isHidden, label, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ ...item, label: label && intl.formatMessage({ id: label }) }));
      }
    });

    return dataMap;
  };

  buildCustomTitle = (): Map<string, string> => {
    const { intl } = this.props;
    const MORE_INFORMATION = intl.formatMessage({ id: LanguageKeys.MORE_INFORMATION });

    const customFieldTitleMap = new Map();
    customFieldTitleMap.set(FormField.LOCATION_OF_PAIN, intl.formatMessage({ id: LanguageKeys.LOCATION_OF_PAIN }));
    customFieldTitleMap.set(FormField.QUALITY_OF_PAIN_INFO, MORE_INFORMATION);
    customFieldTitleMap.set(FormField.RELIEVING_FACTORS_INFO, MORE_INFORMATION);
    customFieldTitleMap.set(FormField.AGGRAVATING_FACTORS_INFO, MORE_INFORMATION);
    customFieldTitleMap.set(FormField.WONG_BAKER_SCALE, intl.formatMessage({ id: LanguageKeys.WONG_BAKER_SCALE }));
    customFieldTitleMap.set(FormField.WONG_BAKER_SCALE_INTENTSITY_OF_PAIN_ON_MOVEMENT, intl.formatMessage({ id: LanguageKeys.WONG_BAKER_INTENSITY_OF_PAIN_ON_MOVEMENT_SCALE }));
    return customFieldTitleMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap, intl } = this.props;
    const customFormFieldTitleMap = this.customFieldTitleMap;
    return [
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.PAIN_ASSESSMENT }),
        fields: [
          FormField.ASSESSMENT_NOT_REQUIRED,
          FormField.HAVE_PAIN,
          FormField.FREQUENCY_OF_PAIN,
          FormField.LOCATION_OF_PAIN,
          FormField.PCA_PUMP_IN_USE,
          FormField.INTENSITY_OF_PAIN,
          FormField.INTENSITY_OF_PAIN_ON_MOVEMENT
        ],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.BEHAVIORAL_ASSESSMENT_OF_PAIN_INTENSITY }),
        fields: [FormField.WONG_BAKER_SCALE, FormField.WONG_BAKER_SCALE_INTENTSITY_OF_PAIN_ON_MOVEMENT],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.NONVERBAL_CUES_OF_PAIN }),
        fields: [FormField.NONVERBAL_PAIN_CUES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.QUALITY_OF_PAIN }),
        fields: [FormField.QUALITY_OF_PAIN, FormField.QUALITY_OF_PAIN_INFO],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.RELIEVING_FACTORS }),
        fields: [FormField.RELIEVING_FACTORS, FormField.RELIEVING_FACTORS_INFO],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.PHYSIOLOGICAL_CUES_OF_PAIN }),
        fields: [FormField.PHYSIO_PAIN_CUES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.AGGRAVATING_FACTORS }),
        fields: [FormField.AGGRAVATING_FACTORS, FormField.AGGRAVATING_FACTORS_INFO],
        formFieldMap,
        customFormFieldTitleMap
      })
    ];
  };

  handleSaveClick = () => {
    const { intl } = this.props;

    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: intl.formatMessage({ id: LanguageKeys.PAIN_ASSESSMENT }),
      // think of this "records" as sections
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  render() {
    const { displayAuthoringData, enableDisplayRecordsButton, formSubmittedCount, formFieldMap, handleDiscardClick, hasUnsavedChanges, intl } = this.props;
    const { resetAll } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: ChartViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      resetAll,
      intl
    };
    return <PainAssessmentView {...viewProps} />;
  }
}

export { PainAssessment as BasePainAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(PainAssessment);

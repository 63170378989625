import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './DrugScreen';
import './drug.screen.read.only.scss';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface DrugScreenReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const DrugScreenReadOnly = (props: DrugScreenReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box className="drug-screen-read-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Drug Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.PRESCRIPTION_DRUG_USE}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.PRESCRIPTION_DRUG_USE} formFieldId={FormField.PRESCRIPTION_DRUG_USE} customTitle="" />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.USING}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.DRUGS_CURRENTLY_USING} customTitle="" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.USING}
                  formFieldId={FormField.DRUGS_CURRENTLY_USING_OTHER}
                  customTitle="Other"
                  notRenderBlankField
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.USING}
                  formFieldId={FormField.AMOUNT_STREET_DRUGS}
                  customTitle="What is the amount of street drugs used each day?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.USING}
                  formFieldId={FormField.USED_DRUGS_PAST_24_HOURS}
                  customTitle="Have you used any of these drugs within the past 24 hours?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.USING}
                  formFieldId={FormField.HOW_LONG_USED_DRUGS}
                  customTitle="How long have you used street drugs?"
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.PLAN_TO_QUIT} customTitle="Do you plan to quit?" />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.QUIT_DATE} customTitle="When is your quit date?" />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.PROVIDER_NOTIFICATION}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.PROVIDER_NOTIFICATION} formFieldId={FormField.PROVIDER_NOTIFIED} customTitle="" />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.PROVIDER_NOTIFICATION} formFieldId={FormField.PROVIDER_NAME} customTitle="Provider Name" />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.QUIT}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.QUIT} formFieldId={FormField.HOW_LONG_QUIT} customTitle="How long ago did you quit?" />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

DrugScreenReadOnly.displayName = 'DrugScreenReadOnly';
export default DrugScreenReadOnly;

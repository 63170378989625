export enum SectionTitle {
  INTEGUMENTARY_INTERVENTIONS = 'Integumentary Interventions',
  SKIN_CARE = 'Skin Care',
  WOUND_CARE = 'Wound Care',
  PATIENT_RESPONSE = 'Patient Response',
  INTEGUMENTARY_CARE_NOTES = 'Integumentary Care Notes'
}

export enum FormField {
  NO_CARE = 'noCareRequired',
  SKIN_CARE = 'skinCare',
  WOUND_CARE = 'woundCare',
  PATIENT_RESPONSE = 'patientResponse',
  INTEGUMENTARY_CARE_NOTES = 'integumentaryCareNotes'
}

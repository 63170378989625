import { PhysicalExaminationFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, Section } from './constants';

export interface PhysicalExaminationEditViewProps {
  selectedRecord: PhysicalExaminationFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const PhysicalExaminationEditView = (props: PhysicalExaminationEditViewProps) => (
  <>
    <Box mt2>
      <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.GENERAL)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VITAL_SIGNS)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.INTEGUMENT)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.HEENT)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CARDIOVASCULAR)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.RESPIRATORY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.GASTROINTESTINAL)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.GENITOURINARY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.MUSCULOSKELETAL)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.NEUROLOGIC)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DEVELOPMENTAL)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.ENDOCRINE)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.GENITALIA)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.LYMPHATIC)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
    </Box>
    <ErrorFormField className="u-els-margin-bottom" formField={props.chartMetaFormFields.get(FormField.OBSTETRIC_GENITALIA)} formSubmittedCount={props.formSubmittedCount}>
      <SectionDivider />
      <Box mb2>
        <h2>{Section.OBSTETRIC_GENITALIA}</h2>
      </Box>
      <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FUNDAL_HEIGHT)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VULVA)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.PELVIS_TYPE)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FETAL_HEART_RATE)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VAGINA)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SPINES)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.PRESENTATION)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CERVIX)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SACRUM)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FETAL_MOVEMENT)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.ADNEXA)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SP_ARCH)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DIAGONAL_CONJUGATE)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
    </ErrorFormField>
    <ErrorFormField className="u-els-margin-bottom" formField={props.chartMetaFormFields.get(FormField.CURRENT_PREGNANCY)} formSubmittedCount={props.formSubmittedCount}>
      <SectionDivider />
      <Box mb2>
        <h2>{Section.CURRENT_PREGNANCY}</h2>
      </Box>
      <Box mb>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.NAUSEA_VOMITING)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.NAUSEA_VOMITING_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VAGINAL_BLEEDING)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VAGINAL_BLEEDING_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField
          formField={props.chartMetaFormFields.get(FormField.VAGINAL_DISCHARGE)}
          formSubmittedCount={props.formSubmittedCount}
          options={{ displayHorizontal: true }}
        />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VAGINAL_DISCHARGE_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField
          formField={props.chartMetaFormFields.get(FormField.URINARY_SIGNS_SYMPTOMS)}
          formSubmittedCount={props.formSubmittedCount}
          options={{ displayHorizontal: true }}
        />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.URINARY_SIGNS_SYMPTOMS_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONSTIPATION)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONSTIPATION_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FEVER_RASH)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FEVER_RASH_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.INFECTION)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.INFECTION_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb>
        <ErrorFormField
          formField={props.chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_OTHER)}
          formSubmittedCount={props.formSubmittedCount}
          options={{ displayHorizontal: true }}
        />
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_OTHER_NOTES)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
    </ErrorFormField>
    <ErrorFormField className="u-els-margin-bottom" formField={props.chartMetaFormFields.get(FormField.ASSESSMENT_PLAN)} formSubmittedCount={props.formSubmittedCount}>
      <SectionDivider />
      <Box mb2>
        <h2>{Section.ASSESSMENT_PLAN}</h2>
      </Box>
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SUMMARY)} formSubmittedCount={props.formSubmittedCount} />
    </ErrorFormField>
    <ChartPermission fragmentType={props.selectedRecord?.fragmentType}>
      {({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}
    </ChartPermission>
  </>
);

PhysicalExaminationEditView.displayName = 'PhysicalExaminationEditView';
export default PhysicalExaminationEditView;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import PediatricGlasgowComaScaleView, { PediatricGlasgowComaScaleViewProps } from './PediatricGlasgowComaScaleView';
import { Title } from '../shared/constants';

class PediatricGlasgowComaScale extends Component<ChartComponentProps> {
  static displayName = 'PediatricGlasgowComaScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const textBoxes = [
      {
        name: FormField.PUPILS_LEFT_SIZE,
        label: FormFieldLabel.PUPILS_SIZE
      },
      {
        name: FormField.PUPILS_RIGHT_SIZE,
        label: FormFieldLabel.PUPILS_SIZE
      },
      {
        name: FormField.MOTOR_RESPONSES_MUSCLE_TONE_OTHER,
        label: FormFieldLabel.MOTOR_RESPONSES_MUSCLE_TONE_OTHER
      },
      {
        name: FormField.MOTOR_RESPONSES_FONTANEL_WINDOW_OTHER,
        label: FormFieldLabel.MOTOR_RESPONSES_FONTANEL_WINDOW_OTHER
      },
      {
        name: FormField.MOTOR_RESPONSES_EYE_MOVEMENT_OTHER,
        label: FormFieldLabel.MOTOR_RESPONSES_EYE_MOVEMENT_OTHER
      }
    ];

    const radioChoices = [
      {
        name: FormField.PUPILS_LEFT_REACTION,
        label: FormFieldLabel.PUPILS_REACTION
      },
      {
        name: FormField.PUPILS_RIGHT_REACTION,
        label: FormFieldLabel.PUPILS_REACTION
      },
      {
        name: FormField.MOTOR_RESPONSES_HAND_GRIP,
        label: FormFieldLabel.MOTOR_RESPONSES_HAND_GRIP
      },
      {
        name: FormField.MOTOR_RESPONSES_MUSCLE_TONE,
        label: FormFieldLabel.MOTOR_RESPONSES_MUSCLE_TONE
      },
      {
        name: FormField.MOTOR_RESPONSES_FONTANEL_WINDOW,
        label: FormFieldLabel.MOTOR_RESPONSES_FONTANEL_WINDOW
      },
      {
        name: FormField.MOTOR_RESPONSES_LEVEL_CONSCIOUSNESS,
        label: FormFieldLabel.MOTOR_RESPONSES_LEVEL_CONSCIOUSNESS
      },
      {
        name: FormField.MOTOR_RESPONSES_EYE_MOVEMENT,
        label: FormFieldLabel.MOTOR_RESPONSES_EYE_MOVEMENT
      },
      {
        name: FormField.MOTOR_RESPONSES_MOOD_AFFECT,
        label: FormFieldLabel.MOTOR_RESPONSES_MOOD_AFFECT
      }
    ];

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, label })));
    radioChoices.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label })));

    dataMap.set(FormField.PEDIATRIC_GLASGOW_COMA_SCALE, createFormField({ name: FormField.PEDIATRIC_GLASGOW_COMA_SCALE, type: FormFieldInputType.SCALE }));

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.EYES_OPEN, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.BEST_MOTOR_RESPONSE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.BEST_MOTOR_RESPONSE_AUDITORY_VISUAL_STIMULUS_QUESTION, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { buildPatientRecords, buildPlainRecord } = chartService;
    return [
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.PUPILS,
        sectionTitle: SectionTitle.LEFT,
        fields: [FormField.PUPILS_LEFT_SIZE, FormField.PUPILS_LEFT_REACTION],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.PUPILS,
        sectionTitle: SectionTitle.RIGHT,
        fields: [FormField.PUPILS_RIGHT_SIZE, FormField.PUPILS_RIGHT_REACTION],
        formFieldMap
      }),
      {
        sectionTitle: SectionTitle.PEDIATRIC_GLASGOW_COMA_SCALE,
        records: [...buildPatientRecords(formFieldMap, FormField.PEDIATRIC_GLASGOW_COMA_SCALE, false, true)]
      },
      {
        sectionTitle: SectionTitle.COMA_SCALE_TOTAL,
        records: [
          buildPlainRecord({
            formField: FormField.PEDIATRIC_GLASGOW_COMA_SCALE,
            title: 'Total Score',
            content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.PEDIATRIC_GLASGOW_COMA_SCALE))?.score?.value,
            linkedFormFieldIds: [FormField.EYES_OPEN, FormField.BEST_MOTOR_RESPONSE, FormField.BEST_MOTOR_RESPONSE_AUDITORY_VISUAL_STIMULUS_QUESTION]
          })
        ]
      },
      chartHelper.buildSection({
        sectionTitle: SectionTitle.MOTOR_RESPONSES,
        fields: [
          FormField.MOTOR_RESPONSES_HAND_GRIP,
          FormField.MOTOR_RESPONSES_MUSCLE_TONE,
          FormField.MOTOR_RESPONSES_MUSCLE_TONE_OTHER,
          FormField.MOTOR_RESPONSES_FONTANEL_WINDOW,
          FormField.MOTOR_RESPONSES_FONTANEL_WINDOW_OTHER,
          FormField.MOTOR_RESPONSES_LEVEL_CONSCIOUSNESS,
          FormField.MOTOR_RESPONSES_EYE_MOVEMENT,
          FormField.MOTOR_RESPONSES_EYE_MOVEMENT_OTHER,
          FormField.MOTOR_RESPONSES_MOOD_AFFECT
        ],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SPECIAL_CHARTS,
      fragmentTitle: SectionTitle.PEDIATRIC_GLASGOW_COMA_SCALE,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: PediatricGlasgowComaScaleViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };
    return <PediatricGlasgowComaScaleView {...viewProps} />;
  }
}

export { PediatricGlasgowComaScale as BasePediatricGlasgowComaScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(PediatricGlasgowComaScale);

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  ROM_MOVE_ALL_EXTREMITIES = 'romMoveAllExtremities',
  LEFT_ARM = 'leftArm',
  RIGHT_ARM = 'rightArm',
  LEFT_LEG = 'leftLeg',
  RIGHT_LEG = 'rightLeg',
  GAIT_OR_BALANCE = 'gaitOrBalance',
  GAIT_BALANCE_NOTES = 'gaitBalanceNotes',
  AMPUTATION_AMPUTEE = 'amputationAmputee',
  AMPUTATION_DESCRIBE = 'describeAmputation',
  POST_ORTHOPEDIC_SURGERY = 'postOrthopedicSurgery',
  POST_OPERATIVE_AREA_LEFT = 'postOperativeAreaLeft',
  POST_OPERATIVE_AREA_RIGHT = 'postOperativeAreaRight',
  POST_OPERATIVE_EA_TEMPERATURE = 'postOperativeEATemperature',
  POST_OPERATIVE_EA_CAPILLARY_REFILL = 'postOperativeEACapillaryRefill',
  POST_OPERATIVE_EA_COLOR = 'postOperativeEAColor',
  POST_OPERATIVE_EA_SENSATION = 'postOperativeEASensation',
  SPECIALIST_SIGNS_HOMAN_SIGN = 'specialTestSignsHomanSign',
  SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN = 'specialTestSignsPlantarFlexionPain',
  MOVEMENT_EVALUATION_FINGERS = 'movementEvaluationFingers',
  MOVEMENT_EVALUATION_TOES = 'movementEvaluationToes',
  POSTOPERATIVE_ORTHOPEDIC_NOTE = 'postoperativeOrthopedicNotes'
}

export enum FormFieldLabel {
  LEFT_ARM = 'Left arm',
  RIGHT_ARM = 'Right arm',
  LEFT_LEG = 'Left leg',
  RIGHT_LEG = 'Right leg',
  AMPUTATION_DESCRIBE = 'Describe Amputation',
  POST_OPERATIVE_AREA_LEFT = 'Left',
  POST_OPERATIVE_AREA_RIGHT = 'Right',
  POST_OPERATIVE_EA_TEMPERATURE = 'Temperature',
  POST_OPERATIVE_EA_CAPILLARY_REFILL = 'Capillary refill',
  POST_OPERATIVE_EA_COLOR = 'Color',
  POST_OPERATIVE_EA_SENSATION = 'Sensation',
  SPECIALIST_SIGNS_HOMAN_SIGN = "Homan's sign",
  SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN = 'Plantar flexion pain',
  MOVEMENT_EVALUATION_FINGERS = 'Able to move fingers?',
  MOVEMENT_EVALUATION_TOES = 'Able to move toes?'
}

export enum SectionTitle {
  MUSCULOSKELETAL_ASSESSMENT = 'Musculoskeletal Assessment',
  RANGE_OF_MOTION = 'Range of Motion (ROM)',
  GAIT_BALANCE = 'Gait/Balance',
  AMPUTATION = 'Amputation',
  POST_OPERATIVE_ORTHOPEDIC = 'Postoperative, Orthopedic',
  POST_OPERATIVE_AREA = 'Post Operative Area',
  POST_OPERATIVE_EXTREMITY_ASSESSMENT = 'Postoperative Extremity Assessment',
  SPECIAL_TEST_SIGNS = 'Special Test Signs',
  MOVEMENT_EVALUATION_OF_AFFECTED_LIMB = 'Movement Evaluation of Affected Limb'
}

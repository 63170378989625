import BaseValidator, { ExecuteParams } from './interface';

export default class RangeValidator implements BaseValidator {
  isReverse: boolean;

  constructor(isReverse = false) {
    this.isReverse = isReverse;
  }

  execute({ ruleInfo, field }: ExecuteParams): boolean {
    const { value } = field;
    if (!value) {
      return this.isReverse;
    }

    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue)) {
      return this.isReverse;
    }

    const { minRange, maxRange } = ruleInfo;
    let result = false;
    if (typeof minRange === 'number' && typeof maxRange === 'number') {
      result = !(minRange <= parsedValue && parsedValue <= maxRange);
      // the value is greater than or equal to the min value
    } else if (typeof minRange === 'number' && typeof maxRange !== 'number') {
      result = minRange > parsedValue;
      // the value is less than or equal to the max value
    } else if (typeof minRange !== 'number' && typeof maxRange === 'number') {
      result = parsedValue > maxRange;
    }

    if (this.isReverse) {
      result = !result;
    }
    return result;
  }
}

export enum FormField {
  MOOD_DISORDER_QUESTIONNAIRE = 'totalScore',
  FELT_GOOD_HYPER_NOT_NORMAL_SELF_HYPER_TROUBLE = 'feltGoodHyperNotNormalSelfHyperTrouble',
  IRRITABLE_SHOUTED_STARTED_FIGHTS_ARGUMENTS = 'irritableShoutedStartedFightsArguments',
  MORE_SELF_CONFIDENT_USUAL = 'moreSelfConfidentUsual',
  LESS_SLEEP_USUAL_MISS = 'lessSleepUsualMiss',
  MORE_TALKATIVE_SPOKE_FASTER_USUAL = 'moreTalkativeSpokeFasterUsual',
  THOUGHTS_RACED_THROUGH_HEAD_YOU_SLOW_DOWN_MIND = 'thoughtsRacedThroughHeadYouSlowDownMind',
  EASILY_DISTRACTED_THINGS_AROUND_TROUBLE_CONCENTRATING_STAYING_TRACK = 'easilyDistractedThingsAroundTroubleConcentratingStayingTrack',
  HAD_MORE_ENERGY_USUAL = 'hadMoreEnergyUsual',
  MORE_ACTIVE_DID_MANY_MORE_THINGS_USUAL = 'moreActiveDidManyMoreThingsUsual',
  MORE_SOCIAL_OUT_GOING_USUAL = 'moreSocialOutgoingUsual',
  MORE_INTERESTED_SEX_USUAL = 'moreInterestedSexUsual',
  DID_THING_UNUSUAL_THAT_OTHER_PEOPLE_THOUGHT_EXCESSIVE_FOOLISH_OR_RISKY = 'didThingUnusualThatOtherPeopleThoughtExcessiveFoolishOrRisky',
  SPENDING_MONEY_GOT_FAMILY_TROUBLE = 'spendingMoneyGotFamilyTrouble',
  SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME = 'severalTheseHappenedDuringSamePeriodTime',
  MUCH_PROBLEM_DID_ANY_THESE_CAUSE = 'muchProblemDidAnyTheseCause',
  HAVE_ANY_BLOOD_RELATIVES = 'haveAnyBloodRelatives',
  HEALTH_CARE_PROFESSIONAL_EVER_TOLD_HAVE_MANIC_DEPRESSIVE_ILLNESS_BIPOLAR_DISORDER = 'healthcareProfessionalEverToldHaveManicDepressiveIllnessBipolarDisorder'
}

export enum SectionTitle {
  MOOD_DISORDER_QUESTIONNAIRE = 'Mood Disorder Questionnaire',
  PERIOD_TIME_NOT_USUAL_SELF = 'Has there ever been a period of time when you were not your usual self and...',
  SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME = 'If you answered Yes to more than one of the above, have several of these ever happened during the same period of time?',
  MUCH_PROBLEM_DID_ANY_THESE_CAUSE = 'How much of a problem did any of these cause you — like being unable to work; having family, money, or legal troubles; or getting into arguments or fights?',
  HAVE_ANY_BLOOD_RELATIVES = 'Have any of your blood relatives (children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?',
  HEALTH_CARE_PROFESSIONAL_EVER_TOLD_HAVE_MANIC_DEPRESSIVE_ILLNESS_BIPOLAR_DISORDER = 'Has a healthcare professional ever told you that you have manic-depressive illness or bipolar disorder?'
}

export enum ErrorGroupId {
  QUESTION_1 = 'question1',
  QUESTION_2 = 'question2',
  QUESTION_3 = 'question3'
}

export enum ErrorGroupDisplayName {
  QUESTION_1 = 'Please answer question 1 in full',
  QUESTION_2 = 'Please answer question 2',
  QUESTION_3 = 'Please answer question 3'
}

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  TEMPERATURE_FAHRENHEIT = 'temperatureFahrenheit',
  TEMPERATURE_CELSIUS = 'temperatureCelsius',
  TEMPERATURE_PULSE = 'temperaturePulse',
  TEMPERATURE_RESPIRATION = 'temperatureRespiration',
  BLOOD_PRESSURE_SYSTOLIC = 'bloodPressureSystolic',
  BLOOD_PRESSURE_DIALOSTIC = 'bloodPressureDiastolic',
  MEASUREMENTS_WEIGHT_POUND = 'measurementsWeightPound',
  MEASUREMENTS_WEIGHT_OUNCE = 'measurementsWeightOunce',
  MEASUREMENTS_WEIGHT_GRAM = 'measurementsWeightGram',
  MEASUREMENTS_LENGTH_INCH = 'measurementsLengthInch',
  MEASUREMENTS_LENGTH_CENTIMETER = 'measurementsLengthCentimeter',
  MEASUREMENTS_HEAD = 'measurementsHead',
  MEASUREMENTS_CHEST = 'measurementsChest',
  CONTAINER_GESTATIONAL_AGE_NEWBORN = 'containerGestationAgeNewborn',
  CONTAINER_GESTATIONAL_AGE_FEMALE = 'containerGestationAgeFemale',
  NUTRITION_BREAST = 'nutritionBreast',
  NUTRITION_QUALITY = 'nutritionQuality',
  NUTRITION_BOTTLE = 'nutritionBottle',
  ELIMINATION_VOID = 'eliminationVoid',
  ELIMINATION_STOOL = 'eliminationStool',
  ELIMINATION_TYPE = 'eliminationType',
  NUTRITIONDELIVERYROOM = 'nutritionDeliveryRoom',
  NUTRITIONDELIVERYROOM_FORMULA_AMOUNT = 'nutritionDeliveryRoomFormulaAmount',
  NUTRITIONDELIVERYROOM_FORMULA_TYPE = 'nutritionDeliveryRoomFormulaType',
  ELIMINATION_FIRST_VOID = 'eliminationFirstVoid',
  ELIMINATION_FIRST_STOOL = 'eliminationFirstStool',
  INTEGUMENTARY_COLOR = 'integumentaryColor',
  INTEGUMENTARY_COLOR_OTHERBOX = 'integumentaryColorOtherBox',
  INTEGUMENTARY_BIRTHMARKS = 'integumentaryBirthmarks',
  INTEGUMENTARY_BIRTHMARKS_LOCATION = 'integumentaryBirthmarksLocation',
  INTEGUMENTARY_CHARACTERISTICS = 'integumentaryCharacteristics',
  INTEGUMENTARY_TURGOR = 'integumentaryTurgor',
  INTEGUMENTARY_MONGOLIAN_SPOTS = 'integumentaryMongolianSpots',
  INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION = 'integumentaryMongolianSpotsLocation',
  ACTIVITY_MOVEMENT = 'activityMovement',
  ACTIVITY_CRY = 'activityCry',
  ACTIVITY_TONE = 'activityTone',
  ACTIVITY_SLEEP_WAKE = 'activitySleepWake',
  HEADNECK_HEAD = 'headNeckHead',
  HEADNECK_HEAD_MOLDING1 = 'headNeckHead-molding1',
  HEADNECK_HEAD_MOLDING2 = 'headNeckHead-molding2',
  HEADNECK_HEAD_OTHERBOX = 'headNeckHeadOtherBox',
  HEADNECK_FACIAL_MOVEMENT = 'headNeckFacialMovement',
  EYES_POSITION_SPACING = 'eyesPositionSpacing',
  EYES_DISCHARGE = 'eyesDischarge',
  EYES_NARES = 'eyesNares',
  EYES_MUCOUS_MEMBRANES = 'eyesMucousMembranes',
  EYES_MUCOUS_MEMBRANES_OTHERBOX = 'eyesMucousMembranesOtherBox',
  EYES_PUPILS = 'eyesPupils',
  EYES_PUPILS_OTHERBOX = 'eyesPupilsOtherBox',
  EYES_POSITION_EARS = 'eyesPositionEars',
  EYES_MOUTH = 'eyesMouth',
  EYES_MOUTH_OTHERBOX = 'eyesMouthOtherBox',
  CHEST_SHAPE = 'chestShape',
  CHEST_SHAPE_DESCRIBE = 'chestShapeDescribe',
  CHEST_HEART_RHYTHM = 'chestHeartRhythm',
  CHEST_HEART_RHYTHM_OTHERBOX = 'chestHeartRhythmOtherBox',
  CHEST_NIPPLES = 'chestNipples',
  CHEST_NIPPLES_DESCRIBE = 'chestNipplesDescribe',
  CHEST_RESPIRATORY_EFFORT = 'chestRespiratoryEffort',
  ABDOMEN_GASTRO_SHAPE = 'abdomenGastroShape',
  ABDOMEN_GASTRO_BOWEL_SOUNDS = 'abdomenGastroBowelSounds',
  ABDOMEN_GASTRO_UMBILICALCORDVESSEL = 'abdomenGastroUmbilicalCordVessels',
  ABDOMEN_GASTRO_UMBILICALCORDVESSEL_OTHERBOX = 'abdomenGastroUmbilicalCordVessels-otherBox',
  ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS = 'abdomenGastroUmbilicalCordInterventions',
  ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS_OTHERBOX = 'abdomenGastroUmbilicalCordInterventions-otherBox',
  SPINE_NEURO = 'spineNeuro',
  SPINE_NEURO_OTHERBOX = 'spineNeuroOtherBox',
  GENITALIA_SEX = 'genitaliaSex',
  GENITALIA_TESTES_DESCENDED = 'genitaliaTestesDescended',
  GENITALIA_URETHRA_PENIS = 'genitaliaUrethraPenis',
  GENITALIA_VAGINAL_OPENING = 'genitaliaVaginalOpening',
  GENITALIA_DISCHARGE_PRESENT = 'genitaliaDischargePresent',
  GENITALIA_DISCHARGE_PRESENT_DESCRIBE = 'genitaliaDischargePresentDescribe',
  GENITALIA_ANUS = 'genitaliaAnus',
  GENITALIA_ANUSMECONIUM = 'genitaliaAnusMeconium',
  MUSCULOSKELETAL_UPPER_RANGE_MOTION = 'musculoskeletalUpperRangeMotion',
  MUSCULOSKELETAL_UPPER_RANGE_MOTION_DESCRIBE = 'musculoskeletalUpperRangeMotionDescribe',
  MUSCULOSKELETAL_UPPER_DIGITS = 'musculoskeletalUpperDigits',
  MUSCULOSKELETAL_UPPER_DIGITS_OTHERBOX = 'musculoskeletalUpperDigitsOtherBox',
  MUSCULOSKELETAL_UPPER_CLAVICLES = 'musculoskeletalUpperClavicles',
  MUSCULOSKELETAL_UPPER_CLAVICLES_CREPITUS = 'musculoskeletalUpperClavicles-crepitus',
  MUSCULOSKELETAL_LOWER_RANGE_MOTION = 'musculoskeletalLowerRangeMotion',
  MUSCULOSKELETAL_LOWER_RANGE_MOTION_DESCRIBE = 'musculoskeletalLowerRangeMotionDescribe',
  MUSCULOSKELETAL_LOWER_DIGITS = 'musculoskeletalLowerDigits',
  MUSCULOSKELETAL_LOWER_DIGITS_OTHERBOX = 'musculoskeletalLowerDigitsOtherBox',
  MUSCULOSKELETAL_LOWER_HIPS = 'musculoskeletalLowerHips',
  REFLEXES = 'reflexes',
  GENERAL_NOTES = 'generalNotes'
}

export enum SectionTitle {
  NEWBORN_ASSESSMENT = 'Newborn Assessment',
  GENERAL_ASSESSMENT = 'General Assessment',
  TEMPERATURE = 'Temperature',
  BLOOD_PRESSURE = 'Blood pressure',
  MEASUREMENTS = 'Measurements',
  GESTATIONAL_AGE = 'Gestational Age',
  NUTRITION = 'Nutrition',
  ELIMINATION = 'Elimination',
  INTEGUMENTARY = 'Integumentary',
  GENERAL_ACTIVITY = 'General Activity',
  HEAD_NECK = 'Head and Neck',
  EYES = 'Eyes',
  CHEST_RESPIRATORY = 'Chest and Respiratory',
  ABDOMEN_GASTROINTESTINAL = 'Abdomen and Gastrointestinal',
  SPINE_NEUROLOGICAL = 'Spine and Neurological',
  GENITALIA = 'Genitalia',
  MUSCULOSKELETAL = 'Musculoskeletal',
  UPPER_EXTREMITIES = 'Upper Extremities',
  LOWER_EXTREMITIES = 'Lower Extremities',
  REFLEXES = 'Reflexes'
}

export enum FormFieldLabel {
  OTHER = 'Other',
  TEMPERATURE_FAHRENHEIT = 'Fahrenheit',
  TEMPERATURE_CELSIUS = 'Celsius',
  TEMPERATURE_PULSE = 'Pulse',
  TEMPERATURE_RESPIRATION = 'Respiration',
  BLOOD_PRESSURE_SYSTOLIC = 'Systolic',
  BLOOD_PRESSURE_DIALOSTIC = 'Dialostic',
  MEASUREMENTS_WEIGHT_POUND = 'Weight (lb)',
  MEASUREMENTS_WEIGHT_OUNCE = '(oz)',
  MEASUREMENTS_WEIGHT_GRAM = '(g)',
  MEASUREMENTS_LENGTH_INCH = 'Length (in)',
  MEASUREMENTS_LENGTH_CENTIMETER = '(cm)',
  MEASUREMENTS_HEAD = 'Head circumference (cm)',
  MEASUREMENTS_CHEST = 'Chest circumference (cm)',
  NUTRITION_BREAST = 'Breast (minutes)',
  NUTRITION_QUALITY = 'Quality',
  NUTRITION_BOTTLE = 'Bottle (oz)',
  ELIMINATION_TYPE = 'Type',
  NUTRITIONDELIVERYROOM = 'Nutrition in Delivery Room',
  NUTRITIONDELIVERYROOM_FORMULA_AMOUNT = 'Amount',
  NUTRITIONDELIVERYROOM_FORMULA_TYPE = 'Type',
  ELIMINATION_FIRST_VOID = 'First void',
  ELIMINATION_FIRST_STOOL = 'First stool',
  ELIMINATION_VOID = 'Void',
  ELIMINATION_STOOL = 'Stool',
  INTEGUMENTARY_BIRTHMARKS_LOCATION = 'Location/characteristics',
  INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION = 'Location/characteristics',
  INTEGUMENTARY_COLOR = 'Color',
  INTEGUMENTARY_BIRTHMARKS = 'Birthmarks',
  INTEGUMENTARY_MONGOLIAN_SPOTS = 'Mongolian spots',
  INTEGUMENTARY_CHARACTERISTICS = 'Characteristics',
  INTEGUMENTARY_TURGOR = 'Turgor',
  ACTIVITY_MOVEMENT = 'Movement',
  ACTIVITY_CRY = 'Cry',
  ACTIVITY_TONE = 'Tone',
  ACTIVITY_SLEEP_WAKE = 'Sleep/wake',
  HEADNECK_HEAD = 'Head',
  HEADNECK_HEAD_OTHERBOX = 'Other lesions',
  HEADNECK_FACIAL_MOVEMENT = 'Facial movement',
  EYES_POSITION_SPACING = 'Position/Spacing',
  EYES_DISCHARGE = 'Discharge',
  EYES_NARES = 'Nares',
  EYES_PUPILS = 'Pupils',
  EYES_POSITION_EARS = 'Position of Ears',
  EYES_MOUTH = 'Mouth',
  EYES_MUCOUS_MEMBRANES = 'Mucous membranes',
  CHEST_SHAPE = 'Shape',
  CHEST_HEART_RHYTHM = 'Heart Rhythm',
  CHEST_NIPPLES = 'Nipples',
  CHEST_RESPIRATORY_EFFORT = 'Respiratory effort',
  CHEST_SHAPE_DESCRIBE = 'Describe',
  CHEST_NIPPLES_DESCRIBE = 'Describe',
  ABDOMEN_GASTRO_SHAPE = 'Shape',
  ABDOMEN_GASTRO_BOWEL_SOUNDS = 'Bowel sounds',
  ABDOMEN_GASTRO_UMBILICALCORDVESSEL = 'Vessels',
  ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS = 'Interventions',
  ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS_OTHERBOX = 'Other Interventions',
  SPINE_NEURO = 'Spine',
  GENITALIA_SEX = 'Sex',
  GENITALIA_TESTES_DESCENDED = 'Testes descended',
  GENITALIA_URETHRA_PENIS = 'Urethra at tip of penis',
  GENITALIA_DISCHARGE_PRESENT = 'Discharge present',
  GENITALIA_DISCHARGE_PRESENT_DESCRIBE = 'Describe',
  GENITALIA_ANUS = 'Anus',
  MUSCULOSKELETAL_UPPER_RANGE_MOTION = 'Range of motion',
  MUSCULOSKELETAL_UPPER_RANGE_MOTION_DESCRIBE = 'Describe',
  MUSCULOSKELETAL_UPPER_DIGITS = 'Digits',
  MUSCULOSKELETAL_UPPER_CLAVICLES = 'Clavicles',
  MUSCULOSKELETAL_LOWER_RANGE_MOTION = 'Range of motion',
  MUSCULOSKELETAL_LOWER_RANGE_MOTION_DESCRIBE = 'Describe',
  MUSCULOSKELETAL_LOWER_DIGITS = 'Digits',
  MUSCULOSKELETAL_LOWER_HIPS = 'Hips',
  REFLEXES = 'Reflexes present',
  GENERAL_NOTES = 'General Notes'
}

import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { AssessmentStatus, AssignmentType } from 'models/enum';
import { RouteParams, RoutePath } from 'constants/app.constant';
import { isDemoEmptyEhr } from 'helpers/assignment.helper';
import history from 'helpers/history';
import { assignmentService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import { AlertModal, ButtonLinkIcon, withFormUtilities } from 'components/common';
import { ELSPageLoader } from 'components/common/els';

export interface AssessmentSubmissionProps {
  loggedInUserId: number;
  assessment: AssessmentRS;
  assessmentId: number;
  assignmentType: AssignmentType;
  showConfirmationModal: Function;
  showSuccessModal: Function;
  modalService: ELSModalServiceType;
}

interface AssessmentSubmissionState {
  isLoading: boolean;
}

class AssessmentSubmission extends Component<AssessmentSubmissionProps, AssessmentSubmissionState> {
  static displayName = 'AssessmentSubmission';

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  handleSubmitClick = () =>
    this.props.showConfirmationModal({
      showIcon: true,
      header: 'Please confirm.',
      message: 'Are you sure that you want to submit? Once completed, no further changes can be made. You will lose any unsaved work.',
      onOkClick: this.handleConfirmSubmission
    });

  navigateToSubmission = () => history.push(RoutePath.student.assignmentSubmission.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessmentId)));

  handleConfirmSubmission = async () => {
    const { studentId, eolsAssignmentId } = this.props.assessment;
    const checkAssessmentRs: AssessmentRS = await assignmentService.getAssessmentByUserIdAndAssignmentId(eolsAssignmentId, studentId).then((res) => res.data);

    if (checkAssessmentRs.status === AssessmentStatus.COMPLETED) {
      this.props.modalService.openModal({
        modalId: 'alertAssessmentSubmitted',
        content: (
          <AlertModal
            header="Submission Error"
            message="This assignment has already been submitted. Please reload this page to access the saved assignment."
            onOkClick={() => this.props.modalService.closeModal('alertAssessmentSubmitted')}
          />
        )
      });
      return;
    }

    this.setState({ isLoading: true }, () => {
      assignmentService.submitAssessment(this.props.assessmentId).then(() =>
        this.setState({ isLoading: false }, () =>
          this.props.showSuccessModal({
            showClose: false,
            message: 'Assignment submitted for grading',
            actions: [{ text: 'Continue', onClick: this.navigateToSubmission }]
          })
        )
      );
    });
  };

  render() {
    if (this.props.loggedInUserId !== this.props.assessment.studentId || this.props.assignmentType !== AssignmentType.EMPTY_EHR) {
      return null;
    }
    const isInDemoEmptyEhr = isDemoEmptyEhr();
    return (
      <>
        {this.state.isLoading && <ELSPageLoader />}
        {!isInDemoEmptyEhr && <ButtonLinkIcon text="Submit" preventAutoPending iconPrefix="gizmo" iconName="send" onClick={this.handleSubmitClick} />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUserId: appSelectors.getUserId(state),
  assessment: appSelectors.getAssessment(state),
  assignmentType: studentSelectors.getAssignmentType(state)
});

export { AssessmentSubmission as BaseAssessmentSubmission };
export default compose(withFormUtilities, connect(mapStateToProps))(AssessmentSubmission);

import { ChartActionsComponentProps, ChartMetaFormField, MarDosageRecord } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider, Sidebar } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { DOSE_MODE, FormField } from './constants';

export interface MarSidebarViewProps {
  sidebarProps: Function;
  selectedDosageRecord: MarDosageRecord;
  doseFormMode: string;
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
}
const MarDosageSidebarView = (props: MarSidebarViewProps) => {
  const { onCloseClick, chartActionsProps, formFieldMap, formSubmittedCount, selectedDosageRecord, doseFormMode, sidebarProps } = props;
  const drugName = selectedDosageRecord?.drugName;
  const route = selectedDosageRecord?.route;
  const dose = selectedDosageRecord?.dose;
  const frequency = selectedDosageRecord?.frequency;
  const specialInstructions = selectedDosageRecord?.specialInstructions;
  const isAdministered = selectedDosageRecord?.isAdministered;

  return (
    <Sidebar
      open={doseFormMode === DOSE_MODE.ADMINISTER && !!selectedDosageRecord}
      onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
      sidebarProps={sidebarProps}
    >
      <Box mt3>
        <h2>Administer</h2>
        <h3 className="u-els-margin-top u-els-margin-bottom-3o4">{drugName}</h3>
        {[route, dose, frequency].filter(Boolean).length > 0 && <span className="u-els-bold u-els-margin-right">{[route, dose, frequency].filter(Boolean).join(' · ')}</span>}
        {specialInstructions && <span className="u-els-italic u-els-color-n7">{specialInstructions}</span>}
      </Box>
      <SectionDivider />
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} className="u-els-margin-bottom" />
      <Box className="mar-dosage">
        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.DOSAGE_DATE)}
              formSubmittedCount={formSubmittedCount}
              className="u-els-margin-bottom-1x1o2"
              inputProps={{ isDisabled: true }}
            />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.DOSAGE_TIME)}
              formSubmittedCount={formSubmittedCount}
              className="u-els-margin-bottom-1x1o2"
              inputProps={{ disabled: true }}
            />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.ADMINISTERED_DATE)}
              formSubmittedCount={formSubmittedCount}
              className="u-els-margin-bottom-1x1o2"
              inputProps={{ isDisabled: true }}
            />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.ADMINISTERED_TIME)}
              formSubmittedCount={formSubmittedCount}
              className="u-els-margin-bottom-1x1o2"
              inputProps={{ disabled: isAdministered }}
            />
          </ELSFlexItem>
        </ELSFlex>
        <ErrorFormField
          useDefaultMargin={false}
          formField={formFieldMap.get(FormField.ADMINISTERED_TIME_OFFSET)}
          formSubmittedCount={formSubmittedCount}
          className="u-els-margin-bottom-1x1o2 mar-dosage__input-offset-time"
          inputProps={{ isDisabled: isAdministered }}
        />
        <ErrorFormField
          formField={formFieldMap.get(FormField.COMMENTS)}
          formSubmittedCount={formSubmittedCount}
          useDefaultMargin={false}
          className="u-els-margin-bottom-1x1o2"
          inputProps={{ disabled: isAdministered }}
        />
        <ELSFlex className="o-els-flex-layout-gutters-1o2">
          <ELSFlexItem>
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.FIRST_INITIAL)}
              formSubmittedCount={formSubmittedCount}
              className="mar-dosage__initials u-els-margin-bottom-1x1o2 u-els-margin-right-2x"
              inputProps={{ isDisabled: isAdministered }}
            />
          </ELSFlexItem>
          <ELSFlexItem grow>
            <ErrorFormField
              useDefaultMargin={false}
              formField={formFieldMap.get(FormField.LAST_INITIAL)}
              formSubmittedCount={formSubmittedCount}
              className="mar-dosage__initials u-els-margin-bottom-1x1o2"
              inputProps={{ isDisabled: isAdministered }}
            />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && !isAdministered && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </Sidebar>
  );
};

MarDosageSidebarView.displayName = 'MarDosageSidebarView';
export default MarDosageSidebarView;

import cx from 'classnames';
import { isEmpty } from 'lodash';
import { Step } from 'models/ui';
import { ELSIcon } from 'components/common/els';
import './stepper.scss';

export interface StepperProps {
  steps: Step[];
}

const Stepper = ({ steps }: StepperProps) => {
  const stepElements = steps.map((step: Step, index: number) => {
    const { id, title, completedStepLinkText, route, isActive } = step;
    const isComplete = !isEmpty(completedStepLinkText);

    return (
      <div key={id} className={cx('els-stepper-item', { 'els-stepper-item--active': isActive, 'els-stepper-item--completed': isComplete })}>
        <div className="els-stepper-item-container">
          <div className="els-stepper-item-indicator-container">
            <span className="els-stepper-item-indicator">{index + 1}</span>
            {!isActive && isComplete && (
              <span className="els-stepper-item-completed-checkmark">
                <ELSIcon name="checkmark" size="1o2" color="n9" title="Completed Checkmark" ariaProps={{ 'aria-hidden': 'false' }} />
              </span>
            )}
          </div>
          <div className="els-stepper-item-content">
            <div className={cx('els-stepper-item-title', 'u-els-font-size-meta', { 'u-els-bold': isActive })}>{title}</div>
            {isComplete && (
              <a className="els-stepper-item-completed-step-link u-els-font-size-meta" href={`/#${route}`}>
                {completedStepLinkText}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  });

  return <div className="els-stepper">{stepElements}</div>;
};

Stepper.displayName = 'Stepper';
export default Stepper;

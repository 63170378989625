import React, { Component } from 'react';
import { ELSButton } from 'components/common/els';
import { tableHeadersChartContent, tableHeadersChartField, tableHeadersChartValidations } from './constants';
import { ChartFieldContentMeta, ChartFieldMeta, ChartFieldValidationMeta } from './MetadataInput';
import MetadataTableView, { MetadataTableViewProps } from './MetadataTableView';
import { DropDownItem } from '../../../models/ui';
import { DeleteCell } from '../../common';

export interface ChartFieldMetaTableProps {
  chartFields: ChartFieldMeta[];
  chartFieldContent: ChartFieldContentMeta[];
  chartFieldValidations: ChartFieldValidationMeta[];
  onContentRowClick: Function;
  onChartFieldRowClick: Function;
  onValidationRowClick: Function;
  onChartFieldRemoveClick: Function;
  onContentRemoveClick: Function;
  onValidationRemoveClick: Function;
  openContentSideBar: Function;
  openValidationSideBar: Function;
}

interface ChartFieldTableState {
  chartFieldFilterBy: string;
  currentChartFieldContentList: ChartFieldContentMeta[];
  currentChartFieldValidationList: ChartFieldValidationMeta[];
}

class MetadataTable extends Component<ChartFieldMetaTableProps, ChartFieldTableState> {
  static defaultProps: { dropDownTrue: string; dropDownFalse: string };
  constructor(props) {
    super(props);
    this.state = {
      chartFieldFilterBy: 'All',
      currentChartFieldContentList: this.props.chartFieldContent,
      currentChartFieldValidationList: this.props.chartFieldValidations
    };
  }

  componentDidUpdate(prevProp) {
    if (prevProp && prevProp.chartFieldContent !== this.props.chartFieldContent) {
      this.setState({ currentChartFieldContentList: this.props.chartFieldContent });
    }
    if (prevProp && prevProp.chartFieldValidations !== this.props.chartFieldValidations) {
      this.setState({ currentChartFieldValidationList: this.props.chartFieldValidations });
    }
  }

  onFilterChange = (event: Event, eventKey: string) => {
    if (eventKey === 'All') {
      this.setState({ chartFieldFilterBy: eventKey, currentChartFieldContentList: this.props.chartFieldContent });
    } else {
      const filteredContent = this.props.chartFieldContent.filter((content) => content.chartField.id === eventKey);
      this.setState({ chartFieldFilterBy: eventKey, currentChartFieldContentList: filteredContent });
    }
  };

  onValidationFilterChange = (event: Event, eventKey: string) => {
    if (eventKey === 'All') {
      this.setState({ chartFieldFilterBy: eventKey, currentChartFieldContentList: this.props.chartFieldContent });
    } else {
      const filteredValidations = this.props.chartFieldValidations.filter((validation) => validation.chartField.id === eventKey);
      this.setState({ chartFieldFilterBy: eventKey, currentChartFieldValidationList: filteredValidations });
    }
  };

  handleContentRowClick = (row) => {
    this.props.onContentRowClick(row.id);
  };

  handleChartFieldRowClick = (row) => {
    this.props.onChartFieldRowClick(row.id);
  };

  handleValidationRowClick = (row) => {
    this.props.onValidationRowClick(row.id);
  };

  handleChartFieldDeleteClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onChartFieldRemoveClick(row.id);
  };

  handleContentDeleteClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onContentRemoveClick(row.id);
  };

  handleValidationDeleteClick = (event, row) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onValidationRemoveClick(row.id);
  };

  renderNavElement = (row) => {
    const displayValue = row.navigationElement.id;
    return <div>{displayValue}</div>;
  };

  renderDataPoint = (row) => {
    return <div>{row.isDataPoint.toString()}</div>;
  };

  renderChartField = (row) => {
    return <div>{row.chartField.id}</div>;
  };

  renderParentId = (row) => {
    if (row.parentValidation !== undefined) {
      return <div>{row.parentValidation?.id}</div>;
    }
    return '';
  };

  renderContentSelectionId = (row) => {
    if (row.chartFieldContentSelection !== undefined) {
      return <div>{row?.chartFieldContentSelection?.id}</div>;
    }
    return '';
  };

  renderChartFieldDelete = (row) => {
    return (
      <div>
        <DeleteCell onDeleteClick={(event) => this.handleChartFieldDeleteClick(event, row)} isActive />
      </div>
    );
  };

  renderContentDelete = (row) => {
    return (
      <div>
        <DeleteCell onDeleteClick={(event) => this.handleContentDeleteClick(event, row)} isActive />
        <ELSButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            this.props.openContentSideBar(row.id);
          }}
          text={`Overrides(${row.attrOverrides?.length ? row.attrOverrides?.length : 0})`}
          preventAutoPending
        />
      </div>
    );
  };

  renderValidationDelete = (row) => {
    return (
      <div>
        <DeleteCell onDeleteClick={(event) => this.handleValidationDeleteClick(event, row)} isActive />
        <ELSButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            this.props.openValidationSideBar(row.id);
          }}
          text={`Overrides(${row.attrOverrides?.length ? row.attrOverrides?.length : 0})`}
          preventAutoPending
        />
      </div>
    );
  };

  render() {
    const chartFieldOptions: DropDownItem[] = [];
    chartFieldOptions.push({ name: 'All', value: 'All' });
    this.props.chartFields.forEach((chartField) => {
      chartFieldOptions.push({ name: chartField.id, value: chartField.id });
    });
    const viewProps: MetadataTableViewProps = {
      chartFieldDataSource: {
        headers: tableHeadersChartField,
        data: this.props.chartFields
      },
      chartFieldContentDataSource: {
        headers: tableHeadersChartContent,
        data: this.state.currentChartFieldContentList
      },
      chartFieldValidationDataSource: {
        headers: tableHeadersChartValidations,
        data: this.state.currentChartFieldValidationList
      },
      filterOptions: chartFieldOptions,
      onFilterChange: this.onFilterChange,
      onValidationFilterChange: this.onValidationFilterChange,
      chartFieldFilterBy: this.state.chartFieldFilterBy,
      handleContentRowClick: this.handleContentRowClick,
      handleChartFieldRowClick: this.handleChartFieldRowClick,
      handleValidationRowClick: this.handleValidationRowClick,
      customRenderNav: this.renderNavElement,
      customRenderDataPoint: this.renderDataPoint,
      customRenderChartField: this.renderChartField,
      customRenderParentId: this.renderParentId,
      customRenderContentId: this.renderContentSelectionId,
      customRenderChartFieldDelete: this.renderChartFieldDelete,
      customRenderContentDelete: this.renderContentDelete,
      customRenderValidationDelete: this.renderValidationDelete
    };

    return (
      <div>
        <MetadataTableView
          tableName={viewProps.tableName}
          chartFieldDataSource={viewProps.chartFieldDataSource}
          chartFieldContentDataSource={viewProps.chartFieldContentDataSource}
          chartFieldValidationDataSource={viewProps.chartFieldValidationDataSource}
          filterOptions={viewProps.filterOptions}
          onFilterChange={viewProps.onFilterChange}
          onValidationFilterChange={viewProps.onValidationFilterChange}
          chartFieldFilterBy={viewProps.chartFieldFilterBy}
          handleContentRowClick={viewProps.handleContentRowClick}
          handleChartFieldRowClick={viewProps.handleChartFieldRowClick}
          handleValidationRowClick={viewProps.handleValidationRowClick}
          customRenderNav={viewProps.customRenderNav}
          customRenderDataPoint={viewProps.customRenderDataPoint}
          customRenderChartField={viewProps.customRenderChartField}
          customRenderParentId={viewProps.customRenderParentId}
          customRenderContentId={viewProps.customRenderContentId}
          customRenderChartFieldDelete={viewProps.customRenderChartFieldDelete}
          customRenderContentDelete={viewProps.customRenderContentDelete}
          customRenderValidationDelete={viewProps.customRenderValidationDelete}
        />
      </div>
    );
  }
}

export default MetadataTable;

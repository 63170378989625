import ConfirmationModal from './ConfirmationModal';

interface LoseDataWarningProps {
  onLeaveClick: Function;
  onStayClick: Function;
  message?: string;
}

const LoseDataWarning = (props: LoseDataWarningProps) => {
  const message = props.message || 'This page contains unsaved changes. Do you wish to leave this page?';
  return (
    <ConfirmationModal
      header="Before you Leave..."
      message={message}
      okButtonText="Leave this Page"
      cancelButtonText="Stay on this Page"
      onOkClick={props.onLeaveClick}
      onCancelClick={props.onStayClick}
    />
  );
};
LoseDataWarning.displayName = 'LoseDataWarning';
export default LoseDataWarning;

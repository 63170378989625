/* eslint-disable no-unused-expressions */
import { ChartMetaFormField, ValidationRule } from 'models/ui';
import FieldNode, { SubscribeInfo } from './FieldNode';
import Rule from './Rule';
import { handleMappingRuleIdsByField } from './helper';
import resultHandlerManager, { VALIDATION_SET_ACTION } from './result-handler/resultHandlerManager';

interface BuildRuleParams {
  allFields: Map<string, ChartMetaFormField>;
  ruleInfo: ValidationRule;
}

interface ValidateFieldParams {
  allFields: Map<string, ChartMetaFormField>;
  fieldId: string;
  ruleIdToValidate: string;
}

interface ProcessRuleResultParams {
  allFields: Map<string, ChartMetaFormField>;
  subInfos: SubscribeInfo[];
  updatedFields: Map<string, ChartMetaFormField>;
}
export default class ValidationManager {
  // List of rules
  ruleRegistry: Map<string, Rule> = new Map();
  // List of fieldNodes
  fieldNodeRegistry: Map<string, FieldNode> = new Map();
  // Mapping betwwen ruleIds and fieldId
  ruleIdsByFieldMap: Map<string, string[]> = new Map();
  // Mapping between fieldId and value (used for Selection Validator)
  fieldIdByValueMap: Map<string, string> = new Map();
  // Mapping between content indexes and ruleId (used for Hide Content Handler)
  contentIndexesByRuleMap: Map<string, number[]> = new Map();

  init = (ruleInfos: ValidationRule[], allFields: Map<string, ChartMetaFormField>) => {
    this.ruleRegistry = new Map();
    this.fieldNodeRegistry = new Map();
    this.ruleIdsByFieldMap = new Map();
    this.fieldIdByValueMap = new Map();
    this.contentIndexesByRuleMap = new Map();

    this.buildRuleAndFieldNode(ruleInfos, allFields);
  };

  buildRuleAndFieldNode = (ruleInfos: ValidationRule[], allFields: Map<string, ChartMetaFormField>) => {
    ruleInfos.forEach((item) => {
      this.buildRule({ ruleInfo: item, allFields });
    });

    allFields.forEach((field) => {
      this.buildFieldNode(field.name, allFields);
    });
  };

  buildRule = ({ ruleInfo, allFields }: BuildRuleParams) => {
    let rule = this.ruleRegistry.get(ruleInfo.id);

    if (!rule) {
      rule = new Rule(ruleInfo);
      this.ruleRegistry.set(ruleInfo.id, rule);
    }

    // Prepare ruleIdsByFieldMap
    let fieldId = rule.ruleInfo.formFieldId;
    let ruleIdsByField = this.ruleIdsByFieldMap.get(rule.ruleInfo.formFieldId);
    if (!ruleIdsByField) {
      ruleIdsByField = [];
    }
    ruleIdsByField.push(rule.ruleInfo.id);
    this.ruleIdsByFieldMap.set(fieldId, ruleIdsByField);

    // Prepare fieldIdByValueMap
    if (ruleInfo.chartFieldSelectionValue) {
      fieldId = '';

      allFields.forEach((fieldItem) => {
        if (fieldId) {
          return;
        }
        const found = fieldItem.chartContent?.find((item) => item.value === ruleInfo.chartFieldSelectionValue);
        if (found) {
          fieldId = fieldItem.name;
        }
      });

      this.fieldIdByValueMap.set(ruleInfo.chartFieldSelectionValue, fieldId);
    }
  };

  buildFieldNode = (fieldId: string, allFields: Map<string, ChartMetaFormField>) => {
    const fieldNode = this.getFieldNode(fieldId);
    const field = allFields.get(fieldId);

    // Build node connections
    if (!fieldNode.isReady) {
      const ruleIdsByField = this.ruleIdsByFieldMap.get(fieldId) ?? [];
      handleMappingRuleIdsByField({
        ruleIdsByField,
        ruleRegistry: this.ruleRegistry,
        fieldNode,
        getFieldNode: this.getFieldNode,
        fieldIdByValueMap: this.fieldIdByValueMap,
        field
      });

      (field.chartContent ?? []).forEach((contentItem, index) => {
        (contentItem.validationSetIds ?? []).forEach((ruleId) => {
          const rule = this.ruleRegistry.get(ruleId);
          let fieldNodeToBeSubcribed = this.getFieldNode(rule.ruleInfo.formFieldId);
          const key = `${fieldId}_${ruleId}`;
          const needProcessRuleResult = () => rule.isResultChanged;

          if (rule.ruleInfo.chartFieldSelectionValue) {
            const fieldIdByValue = this.fieldIdByValueMap.get(rule.ruleInfo.chartFieldSelectionValue);
            fieldNodeToBeSubcribed = this.getFieldNode(fieldIdByValue);
          }

          // Prepare contentIndexesByRuleMap
          let contentIndexes = this.contentIndexesByRuleMap.get(key);
          if (!contentIndexes) {
            contentIndexes = [];

            // Avoid creating duplicated subscribers
            const subInfo = {
              key: ruleId,
              actionType: VALIDATION_SET_ACTION,
              fieldNode,
              needProcessRuleResult,
              ruleIdToValidate: '',
              ruleIdToProcessResult: ruleId
            };
            fieldNodeToBeSubcribed.subscribe(subInfo);
          }
          contentIndexes.push(index);
          this.contentIndexesByRuleMap.set(key, contentIndexes);
        });
      });

      fieldNode.isReady = true;
    }
    return fieldNode;
  };

  getFieldNode = (fieldId: string) => {
    const fieldNode = this.fieldNodeRegistry.get(fieldId) || new FieldNode({ fieldId, doValidateField: this.doValidateField, processRuleResult: this.processRuleResult });
    this.fieldNodeRegistry.set(fieldNode.fieldId, fieldNode);
    return fieldNode;
  };

  runAllRules = (allFields: Map<string, ChartMetaFormField>): Map<string, ChartMetaFormField> => {
    const cloneAllFields = new Map(allFields);
    cloneAllFields.forEach((field) => {
      if (field.chartContent) {
        const updatedFields = this.validateField(field, cloneAllFields);
        updatedFields.forEach((fieldItem) => {
          cloneAllFields.set(fieldItem.name, fieldItem);
        });
      }
    });
    return cloneAllFields;
  };

  validateField = (changingField: ChartMetaFormField, allFields: Map<string, ChartMetaFormField>): Map<string, ChartMetaFormField> => {
    const updatedFields = new Map();
    const fieldNode = this.fieldNodeRegistry.get(changingField.name);
    if (fieldNode) {
      fieldNode.notifySubscribers({ updatedFields, allFields });
    }
    return updatedFields;
  };

  doValidateField = ({ fieldId, ruleIdToValidate, allFields }: ValidateFieldParams) => {
    if (ruleIdToValidate) {
      const ruleToValidate = this.ruleRegistry.get(ruleIdToValidate);
      ruleToValidate.validateRule(fieldId, allFields);
      if (ruleToValidate.parentId) {
        ruleToValidate.parentResult = this.ruleRegistry.get(ruleToValidate.parentId).result;
      }
      ruleToValidate.childRuleIds.forEach((childRuleId) => {
        const childRule = this.ruleRegistry.get(childRuleId);
        const prevResult = childRule.result;
        childRule.parentResult = ruleToValidate.result;
        childRule.isResultChanged = childRule.result !== prevResult;
      });
    }
  };

  processRuleResult = ({ subInfos, updatedFields, allFields }: ProcessRuleResultParams) => {
    const newSubInfos = subInfos.filter((subInfo) => subInfo.needProcessRuleResult());
    newSubInfos.forEach((subInfo) => {
      const { actionType, fieldNode, ruleIdToProcessResult } = subInfo;
      const { fieldId, allowClearValue } = fieldNode;

      const field = allFields.get(fieldId);
      const resultHandler = resultHandlerManager.getResultHandler(actionType);
      const result = resultHandler.execute({
        allowClearValue,
        field,
        ruleId: ruleIdToProcessResult,
        ruleRegistry: this.ruleRegistry,
        ruleIdsByFieldMap: this.ruleIdsByFieldMap,
        contentIndexesByRuleMap: this.contentIndexesByRuleMap
      });

      if (result !== field) {
        updatedFields.set(result.name, result);
        allFields.set(result.name, result);
        fieldNode.notifySubscribers({ updatedFields, allFields });
      }
    });
  };
}

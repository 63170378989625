import { ChartFragment, HomeMedicationRecord } from 'models/api-response';
import { ChartActionsComponentProps } from 'models/ui';
import { Locales } from 'constants/app.constant';
import HomeMedicationPatientDataView from 'components/features/chart/patient-data/home-medication/HomeMedicationView';
import { ChartViewProps } from 'components/features/shared/constants';
import HomeMedicationReadOnly from './HomeMedicationReadOnly';
import './home-medication.scss';

export interface HomeMedicationViewProps extends ChartViewProps {
  isLocked: boolean;
  isAuthor: boolean;
  statusFragment: ChartFragment;
  chartHistory: HomeMedicationRecord[];
  deleteHistory: Function;
  homeMedicationActionProps: ChartActionsComponentProps;
  locale: Locales;
}

const HomeMedicationView = (props: HomeMedicationViewProps) => {
  const { chartHistory, isLocked, statusFragment, locale } = props;
  return (
    <div>
      {isLocked ? (
        <HomeMedicationReadOnly chartFragments={chartHistory} statusFragment={statusFragment} locale={locale} />
      ) : (
        <HomeMedicationPatientDataView {...props} isAdmissionHistoryChart />
      )}
    </div>
  );
};

export default HomeMedicationView;

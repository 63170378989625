import { connect } from 'react-redux';
import { ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import { AccordionBadge, Box, Sidebar } from 'components/common';
import ProviderOrderFormView from './ProviderOrderFormView';

export interface ProviderOrderSidebarViewProps {
  open: boolean;
  chartingAt: string;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  isDisabledSave: boolean;
  onClose: Function;
  onUpdate: Function;
  lastFocusableElement: number;
  sidebarProps: Function;
  locale?: Locales;
}

const ProviderOrderSidebarView = ({
  open,
  chartingAt,
  chartMetaFormFields,
  formSubmittedCount,
  isDisabledSave,
  onClose: onCloseClick,
  onUpdate,
  lastFocusableElement,
  sidebarProps,
  locale
}: ProviderOrderSidebarViewProps) => (
  <Sidebar open={open} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} lastEnabledElement={lastFocusableElement} sidebarProps={sidebarProps}>
    <Box mt3>
      <h2 className="u-els-margin-bottom">Edit Provider Order</h2>
      {chartingAt && <AccordionBadge>{dateTimeHelper.toDateTime(chartingAt, null, locale)}</AccordionBadge>}
    </Box>
    <div className="sidebar__divider" />
    <ProviderOrderFormView chartMetaFormFields={chartMetaFormFields} formSubmittedCount={formSubmittedCount} isDisabledSave={isDisabledSave} onSave={onUpdate} isSidebarView />
  </Sidebar>
);

ProviderOrderSidebarView.displayName = 'ProviderOrderSidebarView';
const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});
export default connect(mapStateToProps)(ProviderOrderSidebarView);

export enum FormField {
  NO_CARE_REQUIRED = 'noCareRequired',
  JOINT_REPAIR_REPLACEMENT_CARE = 'jointRepairReplacementCare',
  FRACTURE_CARE = 'fractureCare',
  TRACTION_CARE = 'tractionCare',
  PIN_TONG_CARE = 'pinTongCare',
  EXTERNAL_FIXATOR = 'externalFixator',
  DRAIN_TUBE_CARE = 'drainOrTubeCare',
  PATIENT_RESPONSE = 'patientResponse',
  CARE_NOTES = 'musculoskeletalCareNotes'
}

export enum SectionTitle {
  MUSCULOSKELETAL_INTERVENTIONS = 'Musculoskeletal Interventions',
  JOINT_REPAIR_REPLACEMENT_CARE = 'Joint Repair/Replacement Care',
  FRACTURE_CARE = 'Fracture Care',
  TRACTION_CARE = 'Traction Care',
  PIN_TONG_CARE = 'Pin/Tong Care',
  DRAIN_TUBE_CARE = 'Drain or Tube Care',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Notes'
}

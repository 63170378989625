/* eslint-disable jsx-a11y/label-has-associated-control */
import { LanguageKeys } from 'lang';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import './pain.assessment.scss';

const PainAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, intl } = props;

  return (
    <div className="pain-assessment">
      <h1>{intl.formatMessage({ id: LanguageKeys.PAIN_ASSESSMENT })}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt2>
        <ELSFlex className="u-flex-grid-1x u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.HAVE_PAIN)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.FREQUENCY_OF_PAIN)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-3x u-els-margin-top" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <ErrorFormField formField={formFieldMap.get(FormField.LOCATION_OF_PAIN)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <ErrorFormField formField={formFieldMap.get(FormField.PCA_PUMP_IN_USE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <Box mt2 className="pain-assessment__intensity-of-pain">
          <ErrorFormField className="horizontal" formField={formFieldMap.get(FormField.INTENSITY_OF_PAIN)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="horizontal" formField={formFieldMap.get(FormField.INTENSITY_OF_PAIN_ON_MOVEMENT)} formSubmittedCount={formSubmittedCount} />
        </Box>

        <SectionDivider />

        <Box mt2>
          <h2>{intl.formatMessage({ id: LanguageKeys.BEHAVIORAL_ASSESSMENT_OF_PAIN_INTENSITY })}</h2>
          <ErrorFormField className="horizontal" formField={formFieldMap.get(FormField.WONG_BAKER_SCALE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="horizontal" formField={formFieldMap.get(FormField.WONG_BAKER_SCALE_INTENTSITY_OF_PAIN_ON_MOVEMENT)} formSubmittedCount={formSubmittedCount} />

          <Box pt2>
            <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <div>
                  <h4 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.NONVERBAL_CUES_OF_PAIN })}</h4>
                  <ErrorFormField formField={formFieldMap.get(FormField.NONVERBAL_PAIN_CUES)} formSubmittedCount={formSubmittedCount} />
                </div>
                <Box mt2>
                  <h4 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.QUALITY_OF_PAIN })}</h4>
                  <ErrorFormField formField={formFieldMap.get(FormField.QUALITY_OF_PAIN)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt>
                  <div>
                    <h4>
                      <ErrorFormField formField={formFieldMap.get(FormField.QUALITY_OF_PAIN_INFO)} formSubmittedCount={formSubmittedCount} />
                    </h4>
                  </div>
                </Box>
                <Box mt2>
                  <h4 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.RELIEVING_FACTORS })}</h4>
                  <ErrorFormField formField={formFieldMap.get(FormField.RELIEVING_FACTORS)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt>
                  <div>
                    <h4>
                      <ErrorFormField formField={formFieldMap.get(FormField.RELIEVING_FACTORS_INFO)} formSubmittedCount={formSubmittedCount} />
                    </h4>
                  </div>
                </Box>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <div>
                  <h4>{intl.formatMessage({ id: LanguageKeys.PHYSIOLOGICAL_CUES_OF_PAIN })}</h4>
                  <Box mt>
                    <div>
                      <label htmlFor={intl.formatMessage({ id: LanguageKeys.PHYSIOLOGICAL_CUES_OF_PAIN })}>Mark All That Apply</label>
                    </div>
                    <ErrorFormField formField={formFieldMap.get(FormField.PHYSIO_PAIN_CUES)} formSubmittedCount={formSubmittedCount} />
                  </Box>
                </div>
                <Box mt2>
                  <h4 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.AGGRAVATING_FACTORS })}</h4>
                  <ErrorFormField formField={formFieldMap.get(FormField.AGGRAVATING_FACTORS)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt>
                  <div>
                    <h4>
                      <ErrorFormField formField={formFieldMap.get(FormField.AGGRAVATING_FACTORS_INFO)} formSubmittedCount={formSubmittedCount} />
                    </h4>
                  </div>
                </Box>
              </ELSFlexItem>
            </ELSFlex>
          </Box>
        </Box>
      </Box>
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.PAIN_INTERVENTIONS, NAV_ID.FLACC_SCALE]} note="If patient is cognitively impaired, use the FLACC Scale" />
      <div className="pain-assessment__credit">
        <span>Wong-Baker FACES Pain Rating Scale from Hockenberry MJ, et al: </span>
        <span className="u-els-italic">Wong’s nursing care of infants and children, </span>
        <span>ed 11, St Louis, 2019, Elsevier.</span>
      </div>
    </div>
  );
};

PainAssessmentView.displayName = 'PainAssessmentView';
export default PainAssessmentView;

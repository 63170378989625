import { Assignment } from 'models/api-response';
import { DropDownItem } from 'models/ui';
import { Box } from 'components/common';
import { ELSButton, ELSCheckBox, ELSDataTable, ELSDropDown, ELSFlex, ELSFlexItem, ELSPageLoader, ELSRadio } from 'components/common/els';
import './launch.scss';

export interface LaunchViewProps {
  isLoading: boolean;
  assignments: Assignment[];
  actionDropDownItems: DropDownItem[];
  selectedAction: string;
  includeBlankChart: boolean;
  includeCaseStudy: boolean;
  includePastDue: boolean;
  disableLaunch: boolean;
  onAssignmentFilterChange: Function;
  onActionSelect: Function;
  onAssignmentRowSelect: Function;
  onLaunchClick: Function;
}

const LaunchView = (props: LaunchViewProps) => {
  const { includeBlankChart, includeCaseStudy, includePastDue, onAssignmentFilterChange } = props;
  const eolsAssignmentIdRenderer = (row) => (
    <ELSRadio
      id={`${row.eolsAssignmentId}`}
      name={`${row.eolsAssignmentId}`}
      value={row.eolsAssignmentId}
      checked={row.selected}
      changeHandler={() => props.onAssignmentRowSelect(row)}
    >
      {row.eolsAssignmentId}
    </ELSRadio>
  );

  return (
    <>
      {props.isLoading && <ELSPageLoader />}
      <Box p3>
        <h2 className="u-els-color-primary">Configuration</h2>
        <Box mt pb2>
          <ELSFlex spaceBetween>
            <ELSFlexItem className="u-els-margin-right-2x">
              <div>Select action</div>
              <div>
                <ELSDropDown options={props.actionDropDownItems} value={props.selectedAction} changeHandler={props.onActionSelect} />
              </div>
            </ELSFlexItem>
            <ELSFlexItem>
              <div>Assignment Filter</div>
              <ELSFlex>
                <ELSCheckBox
                  id="launch__include-blank-chart"
                  checked={includeBlankChart}
                  labelText="Include Blank Chart"
                  changeHandler={(event, value) => onAssignmentFilterChange('includeBlankChart', value)}
                />
                <ELSCheckBox
                  id="launch__include-case-study"
                  className="u-els-margin-left"
                  checked={includeCaseStudy}
                  labelText="Include Case Study"
                  changeHandler={(event, value) => onAssignmentFilterChange('includeCaseStudy', value)}
                />
                <ELSCheckBox
                  id="launch__include-past-due"
                  className="u-els-margin-left"
                  checked={includePastDue}
                  labelText="Include Past Due"
                  changeHandler={(event, value) => onAssignmentFilterChange('includePastDue', value)}
                />
              </ELSFlex>
            </ELSFlexItem>
          </ELSFlex>

          <Box mt>Assignments</Box>
          <ELSDataTable data={props.assignments} cssModifier="c-els-table--row-highlight c-els-table--border-bottom">
            <column header="ID" field="eolsAssignmentId" sortable customRender={eolsAssignmentIdRenderer} />
            <column header="Title" field="title" sortable />
            <column header="Type" field="assignmentType" sortable />
            <column header="Grading Type" field="assignmentGradeType" sortable />
            <column header="Status" field="status" sortable />
          </ELSDataTable>
        </Box>
        <div className="launch__launch-button">
          <ELSButton text="Launch" onClick={props.onLaunchClick} isDisabled={props.disableLaunch} />
        </div>
      </Box>
    </>
  );
};

LaunchView.displayName = 'LaunchView';
export default LaunchView;

import { ChartMetaFormField } from 'models/ui';
import { LastElement } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ErrorFormField, Sidebar } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';

export interface EditDiagnosisSidebarViewProps {
  open: boolean;
  name: string;
  onCloseClick: Function;
  onSaveClick: Function;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  disableRadioContent: Function;
  sidebarProps: Function;
}

const EditDiagnosisSidebarView = ({
  open,
  name,
  onCloseClick,
  onSaveClick,
  formFieldMap,
  formSubmittedCount,
  disableRadioContent,
  sidebarProps
}: EditDiagnosisSidebarViewProps) => (
  <Sidebar
    open={open}
    onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
    lastEnabledElement={LastElement.ENABLED_SAVE_BUTTON}
    sidebarProps={sidebarProps}
  >
    <Box mt3>
      <h2>Edit Diagnosis</h2>
      <h3 className="u-els-margin-top">{name}</h3>
    </Box>
    <div className="sidebar__divider" />
    <ELSFlex className="u-flex-grid-2x" wrap>
      <ELSFlexItem w="1o1">
        <ErrorFormField
          formField={formFieldMap.get(FormField.DIAGNOSIS_TYPE)}
          formSubmittedCount={formSubmittedCount}
          useDefaultMargin={false}
          inputProps={{ hideRadioContent: disableRadioContent, disableRadioContent, radioPrefixName: 'editDiagnosis' }}
        />
      </ELSFlexItem>
      <ELSFlexItem w="1o1">
        <ErrorFormField formField={formFieldMap.get(FormField.PATHOPHYSIOLOGY)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
      </ELSFlexItem>
      <ELSFlexItem w="1o1">
        <ErrorFormField formField={formFieldMap.get(FormField.THERAPEUTIC_REGIMEN)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
      </ELSFlexItem>
      <ELSFlexItem w="1o1">
        <ErrorFormField formField={formFieldMap.get(FormField.PROBLEMS_AND_CHANGES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
      </ELSFlexItem>
    </ELSFlex>
    <Box mt3 mb>
      <ELSButton className="sng-button" text="Save" onClick={onSaveClick} />
    </Box>
  </Sidebar>
);

EditDiagnosisSidebarView.displayName = 'EditDiagnosisSidebarView';
export default EditDiagnosisSidebarView;

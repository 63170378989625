import { ReactNode } from 'react';
import { Box, TitleTooltip } from 'components/common';

export interface SectionHeaderProps {
  id?: string;
  children?: ReactNode;
  title?: string;
  tooltip?: string;
}

const SectionHeader = (props: SectionHeaderProps) => (
  <>
    <Box className="c-els-divider c-els-divider--1o2" mt2 />
    <Box id={props.id} className="u-els-font-size-h4" my>
      {props?.tooltip && props?.title ? <TitleTooltip titleClassname="u-els-font-size-h4" title={props.title} tooltip={props.tooltip} /> : props?.children || ''}
    </Box>
  </>
);

SectionHeader.displayName = 'SectionHeader';
export default SectionHeader;

import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { SkipLinkSection } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { compose } from 'redux';
import { appActions, appSelectors } from 'redux/ducks/app';
import { instructorSelectors } from 'redux/ducks/instructor';
import { Box } from 'components/common';
import AssignmentInstruction from 'components/common/assignment-instruction/AssignmentInstruction';
import AssignmentObjective from 'components/common/assignment-objective/AssignmentObjective';
import BlankChartingCompetency from 'components/features/blank-charting-competency/BlankChartingCompetency';
import './empty.ehr.info.scss';

export interface EmptyEHRInfoProps {
  objectives: string;
  instructions: string;
  locale: Locales;
  emptyEhrDemoInstructions: string;
  emptyEhrDemoObjectives: string;
  setSkipLinkSections: Function;
}

class EmptyEHRInfo extends Component<EmptyEHRInfoProps> {
  static displayName = 'EmptyEHRInfo';

  componentDidMount() {
    const { setSkipLinkSections } = this.props;
    setSkipLinkSections();
  }

  render() {
    const { objectives, instructions } = this.props;

    return (
      <section className="empty-ehr-info__sidebar">
        <Box mt>
          <Box p2>
            <h2>{this.props.locale === Locales.EN_US ? 'Empty EHR Information' : 'Empty EMR Information'}</h2>
          </Box>
          <Box className="empty-ehr-info__content">
            <Box p2>
              <h3>Assignment Objectives</h3>
              <AssignmentObjective objective={objectives || this.props.emptyEhrDemoObjectives} />
            </Box>
            <Box p2>
              <h3>Assignment Instructions</h3>
              <AssignmentInstruction instruction={instructions || this.props.emptyEhrDemoInstructions} />
            </Box>
          </Box>
          <BlankChartingCompetency />
        </Box>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  objectives: appSelectors.getAssessment(state)?.objectives,
  instructions: appSelectors.getAssessment(state)?.instructions,
  locale: appSelectors.getLocale(state),
  emptyEhrDemoInstructions: instructorSelectors.getEmptyEhrDemoInstructions(state),
  emptyEhrDemoObjectives: instructorSelectors.getEmptyEhrDemoObjectives(state)
});

const mapDispatchToProps = (dispatch) => ({
  setSkipLinkSections: (skipLinkSections: SkipLinkSection[]) => dispatch(appActions.setSkipLinkSections(skipLinkSections))
});

export { EmptyEHRInfo as BaseEmptyEHRInfo };
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EmptyEHRInfo);

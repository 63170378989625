// eslint-disable-next-line import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires
const icon = require('!!raw-loader!assets/img/not-graded-grade.svg');

interface NotGradedIconProps {
  id?: string;
  className?: string;
}

const NotGradedIcon = (props: NotGradedIconProps) => (
  // eslint-disable-next-line react/no-danger
  <span id={props.id} className={props.className} dangerouslySetInnerHTML={{ __html: icon }} />
);

NotGradedIcon.displayName = 'NotGradedIcon';
export default NotGradedIcon;

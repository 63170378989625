import { ChartFragmentRS, TherapyRecord } from 'models/api-response';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import Notice from 'components/features/chart/order-entry/shared/Notice';
import { ChartViewProps } from 'components/features/shared/constants';
import TherapiesHistory from './TherapiesHistory';
import OccupationalSidebar from './occupational-sidebar/OccupationalSidebar';
import PhysicalSidebar from './physical-sidebar/PhysicalSidebar';
import RespiratorySidebar from './respiratory-sidebar/RespiratorySidebar';
import { FormField } from './shared/constants';
import SpeechSidebar from './speech-sidebar/SpeechSidebar';
import './therapies.scss';
import { FormField as CommonFormField } from '../shared/constants';

export interface TherapiesViewProps extends ChartViewProps {
  therapy: TherapyRecord;
  chartHistory: TherapyRecord[];
  deleteHistory: Function;
  openUpdatedSidebar: Function;
  closeUpdatedSidebar: Function;
  onEditTherapyHandler: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const TherapiesView = (props: TherapiesViewProps) => {
  const {
    therapy,
    formFieldMap,
    chartHistory,
    formSubmittedCount,
    chartActionsProps,
    deleteHistory,
    onEditTherapyHandler,
    saveChartData,
    openUpdatedSidebar,
    closeUpdatedSidebar,
    locale
  } = props;
  return (
    <div className="therapies">
      <h1>Therapies</h1>
      <Box mt>
        <Notice />
        <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      </Box>
      <Box mt3>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o3" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">Chart Inputs</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_TYPE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="therapies__order-description-text" formField={formFieldMap.get(FormField.ORDER_DESCRIPTION_TEXT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_PRIORITY)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            <div className="therapies__times-per-day">
              <ErrorFormField formField={formFieldMap.get(FormField.TIMES_PER_DAY)} formSubmittedCount={formSubmittedCount} />
            </div>
            <ErrorFormField formField={formFieldMap.get(FormField.TRANSPORTATION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.IV_REQUIRED)} options={{ displayHorizontal: true }} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.OXYGEN_REQUIRED)} options={{ displayHorizontal: true }} formSubmittedCount={formSubmittedCount} />
            <h3 className="u-els-margin-bottom u-els-margin-top-2x">Order Start</h3>
            <ErrorFormField formField={formFieldMap.get(FormField.DATE_FIELD)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.TIME_FIELD)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SPECIAL_INSTRUCTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="2o3" md="1o1" className="u-els-margin-top">
            <h2>Orders</h2>
            <TherapiesHistory chartHistory={chartHistory} deleteHistory={deleteHistory} openUpdatedSidebar={openUpdatedSidebar} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <RespiratorySidebar
        therapy={therapy}
        onCloseClick={closeUpdatedSidebar}
        saveChartData={saveChartData}
        onEditTherapyHandler={onEditTherapyHandler}
        {...appHelper.getChartSharedProps(props)}
      />
      <OccupationalSidebar
        therapy={therapy}
        onCloseClick={closeUpdatedSidebar}
        saveChartData={saveChartData}
        onEditTherapyHandler={onEditTherapyHandler}
        {...appHelper.getChartSharedProps(props)}
      />
      <PhysicalSidebar
        therapy={therapy}
        onCloseClick={closeUpdatedSidebar}
        saveChartData={saveChartData}
        onEditTherapyHandler={onEditTherapyHandler}
        {...appHelper.getChartSharedProps(props)}
      />
      <SpeechSidebar
        therapy={therapy}
        onCloseClick={closeUpdatedSidebar}
        saveChartData={saveChartData}
        onEditTherapyHandler={onEditTherapyHandler}
        {...appHelper.getChartSharedProps(props)}
      />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

TherapiesView.displayName = 'TherapiesView';
export default TherapiesView;

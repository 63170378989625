import cx from 'classnames';
import { FormFieldDataType, ScaleDirection } from 'models/enum';
import { ContentItem } from 'models/ui';
import { Box, GenericImage, MultiLine } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSRadio } from 'components/common/els';
import './scale.scss';

export interface ScaleProps {
  id: string;
  formFieldId: string;
  questionId: string;
  title?: string;
  score?: string;
  question: string;
  options: ContentItem[];
  contexts?: string[];
  contextDirection: ScaleDirection;
  onClick: Function;
  disabled: boolean;
  showScoreInAnswer?: boolean;
  isAriaRequired?: boolean;
  renderNestedField?: Function;
  isNotSaveChildValueInScore: boolean;
}

const Scale = ({
  title,
  options,
  score,
  question,
  questionId,
  contextDirection,
  formFieldId,
  isAriaRequired,
  disabled,
  id,
  onClick,
  showScoreInAnswer,
  renderNestedField,
  isNotSaveChildValueInScore,
  contexts
}: ScaleProps) => {
  const optionsContainNestedFieldId = options?.filter((item) => item.nestedFieldId);

  const renderTitle = () =>
    title && (
      <ELSFlexItem className="scale__title" middle>
        <h2>{title}</h2>
      </ELSFlexItem>
    );

  const renderScore = () =>
    score && (
      <ELSFlexItem className="scale__score" middle>
        <span>
          <span>Score: </span>
          <strong>{score}</strong>
        </span>
      </ELSFlexItem>
    );

  const renderQuestion = () =>
    question && (
      <ELSFlexItem className="scale__question" left>
        <MultiLine text={question} className="scale__question__content" />
      </ELSFlexItem>
    );

  const renderOptions = () => (
    <>
      <ELSFlexItem className={`scale__${contextDirection}`}>
        <fieldset aria-required={isAriaRequired}>
          {options?.map((option, index) => (
            <div className={cx({ 'u-els-margin-left-2x': option.dataType === FormFieldDataType.NESTED_CHOICE })} key={`context-name-${title}${index + 1}`}>
              {option.resourceUri && <GenericImage id={option.resourceUri} className="u-els-margin-right-2x" altText={option.resourceUri} src={`${option.resourceUri}`} />}
              <ELSRadio
                id={`${id}-${questionId}-${option.value}`}
                isDisabled={disabled}
                name={`${id}-${questionId}${option.dataType === FormFieldDataType.NESTED_CHOICE ? '-nested' : ''}`}
                value={option.value}
                checked={option.selected}
                changeHandler={(event) => onClick(event, option)}
              >
                <p className="scale__radio-label u-els-padding-right">
                  <label htmlFor={`${id}-${questionId}-${option.value}`}>
                    {option.label}
                    {showScoreInAnswer && score && ` = ${option.value}`}
                  </label>
                </p>
              </ELSRadio>
              {contextDirection === ScaleDirection.VERTICALLY &&
                option.nestedFieldId &&
                renderNestedField(option.nestedFieldId, () => ({
                  id,
                  formFieldId,
                  option,
                  isNotSaveChildValueInScore
                }))}
            </div>
          ))}
        </fieldset>
      </ELSFlexItem>
      {contextDirection === ScaleDirection.HORIZONTAL && optionsContainNestedFieldId.length > 0 && (
        <ELSFlex left>
          {optionsContainNestedFieldId.map((option) => renderNestedField(option.nestedFieldId, () => ({ id, formFieldId, option, isNotSaveChildValueInScore })))}
        </ELSFlex>
      )}
    </>
  );

  return (
    <Box id={`chart-field-${formFieldId}`} pb className="scale">
      <ELSFlex className="u-els-padding-bottom" gutters>
        {renderTitle()}
        {renderScore()}
      </ELSFlex>
      <ELSFlex gutters className={cx({ 'u-els-padding-bottom': contextDirection === ScaleDirection.HORIZONTAL })}>
        <ELSFlexItem grow>
          {renderQuestion()}
          {renderOptions()}
          {contexts?.map((context, index) => (
            <ELSFlexItem left key={`context-name-${title}${index + 1}`}>
              <span>{context}</span>
            </ELSFlexItem>
          ))}
        </ELSFlexItem>
      </ELSFlex>
    </Box>
  );
};

Scale.displayName = 'Scale';
Scale.defaultProps = {
  contextDirection: ScaleDirection.HORIZONTAL,
  disabled: false
};
export default Scale;

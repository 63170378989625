import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { ADDSPointMap, ADDSRangeMap, ADDSScoringAction, AVPUAssessmentKeyMap, VitalSignsDataSuperKey, VitalSignsDataType, VitalSignsSuperKeyValueMap } from './types';

export enum FormField {
  TEMPERATURE_FAHRENHEIT = 'temperatureFahrenheit',
  TEMPERATURE_CELSIUS = 'temperatureCelsius',
  TEMPERATURE_SITE = 'temperatureSite',
  PULSE = 'pulse',
  PULSE_SITE = 'pulseSite',
  PULSE_STRENGTH = 'pulseStrength',
  PULSE_RHYTHM = 'pulseRhythm',
  PULSE_EQUALITY = 'pulseEquality',
  RESPIRATION = 'respiration',
  RESPIRATION_PATTERN = 'respirationPattern',
  RESPIRATION_DEPTH = 'respirationDepth',
  BLOOD_PRESSURE_SYSTOLIC = 'bloodPressureSystolic',
  BLOOD_PRESSURE_SITE = 'bloodPressureSite',
  BLOOD_PRESSURE_DIASTOLIC = 'bloodPressureDiastolic',
  BLOOD_PRESSURE_POSITION = 'bloodPressurePosition',
  BLOOD_PRESSURE_MODE = 'bloodPressureMode',
  OXYGEN_SATURATION = 'oxygenSaturation',
  OXYGEN_SITE = 'oxygenSite',
  OXYGEN_SITE_OTHER = 'oxygenSiteOther',
  OXYGEN_DELIVERY = 'oxygenDelivery',
  OXYGEN_AMOUNT_LITER = 'oxygenAmountLiter',
  OXYGEN_DELIVERY_METHOD_LITER = 'oxygenDeliveryMethodLiter',
  OXYGEN_DELIVERY_METHOD_LITER_OTHER = 'oxygenDeliveryMethodLiterOther',
  OXYGEN_AMOUNT_PERCENT = 'oxygenAmountPercent',
  OXYGEN_DELIVERY_METHOD_PERCENT = 'oxygenDeliveryMethodPercent',
  OXYGEN_DELIVERY_METHOD_PERCENT_OTHER = 'oxygenDeliveryMethodPercentOther',
  AIRWAY_PATENT_OBSTRUCTED = 'airwayPatentObstructed',
  AIRWAY_PARTIAL_OBSTRUCTION_COMPLETE_OBSTRUCTION = 'airwayPartialObstructionCompleteObstruction',
  AIRWAY_OBSTRUCTED_CHILD_TICKBOX = 'airwayObstructedChildTickBox',
  AIRWAY_PARTIAL_OBSTRUCTION_CHILD_TICKBOX = 'airwayPartialObstructionChildTickBox',
  AUSCULTATION_LEFT_LUNG = 'auscultationLeftLung',
  AUSCULTATION_RIGHT_LUNG = 'auscultationRightLung',
  AUSCULTATION_LEFT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES = 'auscultationLeftLungAdventitiousLungSoundsCracklesWheezes',
  AUSCULTATION_RIGHT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES = 'auscultationRightLungAdventitiousLungSoundsCracklesWheezes',
  DISABILITY_PAIN_SCORE_REST = 'disabilityPainScoreRest',
  DISABILITY_PAIN_SCORE_ON_MOVEMENT = 'disabilityPainScoreOnMovement',
  DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT = 'disabilityLevelOfResponseAvpuAssessment',
  EXPOSURE_COMMENTS = 'exposureComments',
  NOTE = 'note',
  NURSING_ACTIONS = 'nursingActions',
  NURSING_ACTIONS_COMMENT = 'nursingActionsComment',
  ADDS_TOTAL_SCORE = 'addsTotalScore'
}

export const vitalSignsFormFieldBuilderItems = (locale: Locales, temperatureGroupChange: Function): ChartMetaFormFieldParams[] => [
  {
    name: FormField.TEMPERATURE_FAHRENHEIT,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.VITAL_SIGNS.FAHRENHEIT,
    disabled: false,
    onGroupChange: (chartField) => temperatureGroupChange(chartField)
  },
  {
    name: FormField.TEMPERATURE_CELSIUS,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.VITAL_SIGNS.CELSIUS,
    onGroupChange: (chartField) => temperatureGroupChange(chartField)
  },
  { name: FormField.TEMPERATURE_SITE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.SITE },
  { name: FormField.PULSE, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.PULSE_TEXTBOX_LABEL },
  { name: FormField.PULSE_SITE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.SITE },
  { name: FormField.PULSE_STRENGTH, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.STRENGTH },
  { name: FormField.PULSE_RHYTHM, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.RHYTHM },
  { name: FormField.PULSE_EQUALITY, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.EQUALITY },
  { name: FormField.RESPIRATION, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.RESPIRATION },
  { name: FormField.RESPIRATION_PATTERN, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.PATTERN },
  { name: FormField.RESPIRATION_DEPTH, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.DEPTH },
  { name: FormField.BLOOD_PRESSURE_SYSTOLIC, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.SYSTOLIC },
  { name: FormField.BLOOD_PRESSURE_SITE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.SITE },
  { name: FormField.BLOOD_PRESSURE_DIASTOLIC, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.DIASTOLIC },
  { name: FormField.BLOOD_PRESSURE_POSITION, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.POSITION },
  { name: FormField.BLOOD_PRESSURE_MODE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.MODE },
  { name: FormField.OXYGEN_SATURATION, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.SATURATION_TEXTBOX_LABEL },
  { name: FormField.OXYGEN_SITE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.SITE },
  { name: FormField.OXYGEN_SITE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.OTHER },
  { name: FormField.OXYGEN_DELIVERY, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.OXYGEN_DELIVERY },
  { name: FormField.OXYGEN_AMOUNT_LITER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.AMOUNT_LMIN_TEXTBOX_LABEL },
  { name: FormField.OXYGEN_DELIVERY_METHOD_LITER, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.DELIVERY_METHOD },
  { name: FormField.OXYGEN_DELIVERY_METHOD_LITER_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.OTHER },
  { name: FormField.OXYGEN_AMOUNT_PERCENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.AMOUNT_PER_TEXTBOX_LABEL },
  { name: FormField.OXYGEN_DELIVERY_METHOD_PERCENT, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.DELIVERY_METHOD },
  { name: FormField.OXYGEN_DELIVERY_METHOD_PERCENT_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.OTHER },
  { name: FormField.AIRWAY_PATENT_OBSTRUCTED, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AIRWAY_PARTIAL_OBSTRUCTION_COMPLETE_OBSTRUCTION, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AIRWAY_OBSTRUCTED_CHILD_TICKBOX, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AIRWAY_PARTIAL_OBSTRUCTION_CHILD_TICKBOX, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AUSCULTATION_LEFT_LUNG, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.LEFT_LUNG, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AUSCULTATION_RIGHT_LUNG, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.VITAL_SIGNS.RIGHT_LUNG, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AUSCULTATION_LEFT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.AUSCULTATION_RIGHT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.DISABILITY_PAIN_SCORE_REST, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.REST, isHidden: locale !== Locales.EN_AU },
  { name: FormField.DISABILITY_PAIN_SCORE_ON_MOVEMENT, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.VITAL_SIGNS.ON_MOVEMENT, isHidden: locale !== Locales.EN_AU },
  {
    name: FormField.DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT,
    type: FormFieldInputType.DROPDOWN,
    label: LanguageKeys.VITAL_SIGNS.AVPU_ASSESSMENT,
    isHidden: locale !== Locales.EN_AU
  },
  { name: FormField.EXPOSURE_COMMENTS, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.VITAL_SIGNS.COMMENTS, isHidden: locale !== Locales.EN_AU },
  { name: FormField.NOTE, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.VITAL_SIGNS.NOTES },
  { name: FormField.NURSING_ACTIONS, type: FormFieldInputType.CHECK_BOX, isHidden: locale !== Locales.EN_AU },
  {
    name: FormField.NURSING_ACTIONS_COMMENT,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.FREQUENCY,
    isHidden: locale !== Locales.EN_AU
  },
  { name: FormField.ADDS_TOTAL_SCORE, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.VITAL_SIGNS.SCORE, isHidden: locale !== Locales.EN_AU }
];

export const respirationPointRanges: ADDSRangeMap = {
  [ADDSPointMap.EMERGENCY_CALL]: [
    {
      min: undefined,
      max: 4
    },
    {
      min: 35,
      max: undefined
    }
  ],
  [ADDSPointMap.ZERO]: [
    {
      min: 10,
      max: 19
    }
  ],
  [ADDSPointMap.ONE]: [
    {
      min: 20,
      max: 24
    }
  ],
  [ADDSPointMap.TWO]: [
    {
      min: 25,
      max: 29
    }
  ],
  [ADDSPointMap.THREE]: [
    {
      min: 5,
      max: 9
    },
    {
      min: 30,
      max: 34
    }
  ]
};

export const saturationPointRanges: ADDSRangeMap = {
  [ADDSPointMap.EMERGENCY_CALL]: [
    {
      min: undefined,
      max: 84
    }
  ],
  [ADDSPointMap.ZERO]: [
    {
      min: 95,
      max: undefined
    }
  ],
  [ADDSPointMap.ONE]: [
    {
      min: 93,
      max: 94
    }
  ],
  [ADDSPointMap.TWO]: [
    {
      min: 90,
      max: 92
    }
  ],
  [ADDSPointMap.THREE]: [
    {
      min: 85,
      max: 89
    }
  ]
};

export const bloodPressurePointRanges: ADDSRangeMap = {
  [ADDSPointMap.EMERGENCY_CALL]: [
    {
      min: undefined,
      max: 89
    }
  ],
  [ADDSPointMap.ZERO]: [
    {
      min: 110,
      max: 159
    }
  ],
  [ADDSPointMap.ONE]: [
    {
      min: 160,
      max: 169
    }
  ],
  [ADDSPointMap.TWO]: [
    {
      min: 100,
      max: 109
    },
    {
      min: 170,
      max: 199
    }
  ],
  [ADDSPointMap.THREE]: [
    {
      min: 90,
      max: 99
    },
    {
      min: 200,
      max: undefined
    }
  ]
};

export const pulsePointRanges: ADDSRangeMap = {
  [ADDSPointMap.EMERGENCY_CALL]: [
    {
      min: undefined,
      max: 39
    },
    {
      min: 140,
      max: undefined
    }
  ],
  [ADDSPointMap.ZERO]: [
    {
      min: 50,
      max: 99
    }
  ],
  [ADDSPointMap.ONE]: [
    {
      min: 40,
      max: 49
    },
    {
      min: 100,
      max: 109
    }
  ],
  [ADDSPointMap.TWO]: [
    {
      min: 110,
      max: 129
    }
  ],
  [ADDSPointMap.THREE]: [
    {
      min: 130,
      max: 139
    }
  ]
};

export const temperaturePointRanges: ADDSRangeMap = {
  [ADDSPointMap.ZERO]: [
    {
      min: 35.5,
      max: 37.9
    }
  ],
  [ADDSPointMap.ONE]: [
    {
      min: 38,
      max: 38.4
    }
  ],
  [ADDSPointMap.TWO]: [
    {
      min: 38.5,
      max: undefined
    }
  ],
  [ADDSPointMap.THREE]: [
    {
      min: undefined,
      max: 35.4
    }
  ]
};

export const avpuAssessmentValueMap: Record<AVPUAssessmentKeyMap, string> = {
  [AVPUAssessmentKeyMap.UNRESPONSIVE]: 'Unresponsive',
  [AVPUAssessmentKeyMap.ALERT]: 'Alert',
  [AVPUAssessmentKeyMap.RESPONSIVE_TO_VOICE]: 'Responsive to voice',
  [AVPUAssessmentKeyMap.RESPONSIVE_TO_PAIN]: 'Responsive to pain'
};

export const avpuAssessmentPointRanges: ADDSRangeMap = {
  [ADDSPointMap.EMERGENCY_CALL]: [
    {
      match: AVPUAssessmentKeyMap.UNRESPONSIVE
    }
  ],
  [ADDSPointMap.ZERO]: [
    {
      match: AVPUAssessmentKeyMap.ALERT
    }
  ],
  [ADDSPointMap.TWO]: [
    {
      match: AVPUAssessmentKeyMap.RESPONSIVE_TO_VOICE
    }
  ],
  [ADDSPointMap.THREE]: [
    {
      match: AVPUAssessmentKeyMap.RESPONSIVE_TO_PAIN
    }
  ]
};

export const pointingBadgeColorMapping: { [key in ADDSPointMap]: string } = {
  [ADDSPointMap.EMERGENCY_CALL]: 'c-vital-signs-adds__badge c-vital-signs-adds--level-emergency-call',
  [ADDSPointMap.ZERO]: 'c-vital-signs-adds__badge c-vital-signs-adds--level-0',
  [ADDSPointMap.ONE]: 'c-vital-signs-adds__badge c-vital-signs-adds--level-1',
  [ADDSPointMap.TWO]: 'c-vital-signs-adds__badge c-vital-signs-adds--level-2',
  [ADDSPointMap.THREE]: 'c-vital-signs-adds__badge c-vital-signs-adds--level-3'
};

export const addsScoringActions: ADDSScoringAction[] = [
  {
    title: 'Score 1-3',
    scoreRanges: [
      {
        min: 1,
        max: 3
      }
    ],
    actions: [
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.INFORM_SENIOR_NURSE_AND_OR_TEAM_LEADER,
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.INCREASE_FREQUENCY_OF_OBSERVATIONS_SPECIFY_FREQUENCY
    ]
  },
  {
    title: 'Score 4-5',
    scoreRanges: [
      {
        min: 4,
        max: 5
      }
    ],
    actions: [
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.SENIOR_NURSE_AND_OR_JUNIOR_MEDICAL_OFFICER_REVIEW_WITHIN_30_MINS,
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.INCREASE_FREQUENCY_OF_OBSERVATIONS_SPECIFY_FREQUENCY
    ]
  },
  {
    title: 'Score 6-7',
    scoreRanges: [
      {
        min: 6,
        max: 7
      }
    ],
    actions: [
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.SENIOR_MEDICAL_OFFICER_REVIEW_REGISTRAR_OR_ABOVE_WITHIN_30_MINS,
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.REQUEST_REVIEW_AND_NOTE_ON_THE_BACK_OF_THIS_FORM,
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.INCREASE_FREQUENCY_OF_OBSERVATIONS_SPECIFY_FREQUENCY
    ]
  },
  {
    title: 'Score 8-10',
    scoreRanges: [
      {
        min: 8,
        max: undefined,
        match: ADDSPointMap.EMERGENCY_CALL.toString()
      }
    ],
    actions: [
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.PLACE_EMERGENCY_CALL,
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.BEGIN_INITIAL_LIFE_SUPPORT_INTERVENTIONS_SUPPORTS_AIRWAY_BREATHING_CIRCULATION,
      LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.ADVANCED_LIFE_SUPPORT_PROVIDER_TO_ATTEND_PATIENT_IMMEDIATELY
    ]
  }
];

export const addsEmergencyCallConditions = {
  title: LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.EMERGENCY_ALL_IF_WITH_COLON,
  conditions: [
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.ANY_OBSERVATION_ID_IN_A_PURPLE_AREA,
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.AIRWAY_THREAT,
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.RESPIRATORY_OR_CARDIAC_ARREST,
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.NEW_DROP_IN_02_SATURATION_LESS_THAN_90_PERCENT,
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.SUDDEN_FALL_IN_LEVEL_OF_CONSCIOUSNESS,
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.SEIZURE,
    LanguageKeys.VITAL_SIGNS.ADDS_SCORING_ACTIONS.YOU_ARE_SERIOUSLY_WORRIED_ABOUT_THE_PATIENT_BUT_THEY_DO_NOT_FIT_THE_ABOVE_CRITERIA
  ]
};

export const dataColorByADDSPoint = {
  [ADDSPointMap.ZERO]: '#cecece',
  [ADDSPointMap.ONE]: '#ffec84',
  [ADDSPointMap.TWO]: '#f3af62',
  [ADDSPointMap.THREE]: '#fda39f',
  [ADDSPointMap.EMERGENCY_CALL]: '#d7b0ff'
};

/*
 * Change according to the AC.
 * Currently, the Emergency Call Score is same as the range of 8-10.
 * So the score should be out of the range.
 * */
export const EMERGENCY_CALL_SCORE = 11;
export const ADDS_HISTORY_TABLE_LIMIT = 7;

export const headersMap: VitalSignsSuperKeyValueMap = {
  ID: { value: 'ID', isHidden: true },
  RESPIRATION: { value: 'Resp' },
  SATURATION: { value: 'Sat%' },
  BLOOD_PRESSURE: { value: 'BP' },
  PULSE: { value: 'Pulse' },
  TEMPERATURE: { value: 'Temp' },
  AVPU: { value: 'AVPU' },
  PAIN: { value: 'Pain' },
  ADDS_SCORE: { value: 'ADDS Score' },
  CHARTING_AT: { value: 'Charting At' },
  DELETE: { value: '' },
  ACTIVE: { value: '', isHidden: true },
  CREATOR: { value: '', isHidden: true }
};

export const vitalSignsDataPaths: Omit<
  VitalSignsDataType,
  VitalSignsDataSuperKey.ADDS_SCORE | VitalSignsDataSuperKey.CHARTING_AT | VitalSignsDataSuperKey.DELETE | VitalSignsDataSuperKey.CREATOR | VitalSignsDataSuperKey.ACTIVE
> = {
  ID: 'id',
  RESPIRATION: 'respiration.value',
  SATURATION: 'oxygenation.saturation.value',
  BLOOD_PRESSURE: {
    DIASTOLIC: 'bloodPressure.diastolic.value',
    SYSTOLIC: 'bloodPressure.systolic.value'
  },
  PULSE: 'pulse.value',
  TEMPERATURE: 'temperature.value',
  AVPU: 'disability.levelOnResponse.avpuAssessment',
  PAIN: {
    REST: 'disability.painScore.rest.value',
    ON_MOVEMENT: 'disability.painScore.onMovement.value',
    AVPU: 'disability.levelOnResponse.avpuAssessment.value'
  }
};

export const A11y = {
  name: 'Search Results Paginator',
  pagingUnitName: 'Page',
  morePagingUnit: 'Pages'
};

/* eslint-disable no-param-reassign */
import produce from 'immer';
import { cloneDeep, delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, ObstetricHistoryFragment, Record } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic, withFormUtilities } from 'components/common';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import ObstetricHistoryView, { ObstetricHistoryViewProps } from './ObstetricHistoryView';

interface ObstetricHistoryStates {
  isLocked: boolean;
  isOpenSidebar: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ObstetricHistoryFragment;
  pregnanciesInformation: Record;
  pregnanciesInformationRecords: Record[];
  previousPregnanciesHistory: ChartFragment[];
}

class ObstetricHistory extends Component<ChartComponentProps, ObstetricHistoryStates> {
  static displayName = 'ObstetricHistory';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      isOpenSidebar: false,
      statusFragment: null,
      selectedRecord: null,
      pregnanciesInformation: null,
      pregnanciesInformationRecords: [],
      previousPregnanciesHistory: []
    };
  }

  componentDidMount() {
    const { assessment } = this.props;
    const navIds = [NAV_ID.HISTORY_PHYSICAL, NAV_ID.OBSTETRIC_HISTORY];
    const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];

    appHelper.useLoader(this.props.loadChartData(fragmentTypes, navIds, assessment.simChartId).then(this.bindChartHistory), {
      errorMessage: 'can not load chart fragment'
    });

    this.props.initState(this.buildFormFields());
  }

  bindChartHistory = ({ data: fragments }) => {
    const selectedRecord = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const pregnanciesInformationRecords = selectedRecord?.chartData?.records?.find((record) => record.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES)?.records || [];
    const previousPregnanciesHistory = pregnanciesInformationRecords?.map((record) => ({
      id: record.id,
      active: record.active,
      [FormField.DAY_MONTH_YEARS_AGO]: record.records?.find((item) => item?.formField === FormField.DAY_MONTH_YEARS_AGO)?.value,
      [FormField.DURATION_OF_LABOR]: record.records?.find((item) => item?.formField === FormField.DURATION_OF_LABOR)?.value,
      [FormField.GESTATIONAL_WEEKS]: record.records?.find((item) => item?.formField === FormField.GESTATIONAL_WEEKS)?.value,
      [FormField.BIRTH_WEIGHT]: record.records?.find((item) => item?.formField === FormField.BIRTH_WEIGHT)?.value,
      [FormField.GENDER]: record.records?.find((item) => item?.formField === FormField.GENDER)?.value,
      [FormField.TYPE_OF_DELIVERY]: record.records?.find((item) => item?.formField === FormField.TYPE_OF_DELIVERY)?.value,
      [FormField.ANESTHESIA]: record.records?.find((item) => item?.formField === FormField.ANESTHESIA)?.value,
      [FormField.PRETERM_LABOR]: record.records?.find((item) => item?.formField === FormField.PRETERM_LABOR)?.value,
      [FormField.COMPLICATIONS]: record.records?.find((item) => item?.formField === FormField.COMPLICATIONS)?.value,
      [FormField.COMMENTS]: record.records?.find((item) => item?.formField === FormField.COMMENTS)?.value
    }));
    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.OBSTETRIC_HISTORY, NAV_ID.HISTORY_PHYSICAL);

    this.setState({
      selectedRecord,
      statusFragment,
      pregnanciesInformationRecords,
      previousPregnanciesHistory,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(this.buildFormFields(selectedRecord));
  };

  buildFormFields = (fragment?: ObstetricHistoryFragment): Map<string, ChartMetaFormField> => {
    const { createFormField, getFragmentContentIds, getFragmentValue } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      {
        name: FormField.TOTAL_PREGNANCIES,
        label: FormFieldLabel.TOTAL_PREGNANCIES,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.TOTAL_PREGNANCIES)
      },
      {
        name: FormField.TERM,
        label: FormFieldLabel.TERM,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.TERM)
      },
      {
        name: FormField.MATURE,
        label: FormFieldLabel.MATURE,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.MATURE)
      },
      {
        name: FormField.ABORTION_INDUCED,
        label: FormFieldLabel.ABORTION_INDUCED,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.ABORTION_INDUCED)
      },
      {
        name: FormField.ABORTION_SPONTANEOUS,
        label: FormFieldLabel.ABORTION_SPONTANEOUS,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.ABORTION_SPONTANEOUS)
      },
      {
        name: FormField.ECTOPIC,
        label: FormFieldLabel.ECTOPIC,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.ECTOPIC)
      },
      {
        name: FormField.MULTIPLE_BIRTHS,
        label: FormFieldLabel.MULTIPLE_BIRTHS,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.MULTIPLE_BIRTHS)
      },
      {
        name: FormField.LIVING_BIRTH,
        label: FormFieldLabel.LIVING_BIRTH,
        value: getFragmentValue(fragment, SectionTitle.PREGNANT_HISTORY, FormField.LIVING_BIRTH)
      },
      {
        name: FormField.GENETIC_SCREENING_PERFORMED_OTHER,
        label: FormFieldLabel.OTHER,
        value: getFragmentValue(fragment, SectionTitle.GENETIC_SCREENING_PERFORMED, FormField.GENETIC_SCREENING_PERFORMED_OTHER)
      }
    ];
    const textAreas = [
      {
        name: FormField.INFERTILITY,
        label: FormFieldLabel.INFERTILITY,
        value: getFragmentValue(fragment, SectionTitle.OBSTETRIC_HISTORY, FormField.INFERTILITY)
      },
      {
        name: FormField.DESCRIBE_CURRENT_PROBLEMS,
        label: FormFieldLabel.DESCRIBE_CURRENT_PROBLEMS,
        value: getFragmentValue(fragment, SectionTitle.CURRENT_PREGNANCY_RELATED_PROBLEMS, FormField.DESCRIBE_CURRENT_PROBLEMS)
      },
      {
        name: FormField.NOTES_FROM_PRENATAL_VISITS,
        label: FormFieldLabel.NOTES_FROM_PRENATAL_VISITS,
        value: getFragmentValue(fragment, SectionTitle.CURRENT_PREGNANCY_PRENATAL_NOTES, FormField.NOTES_FROM_PRENATAL_VISITS)
      }
    ];

    textBoxes.forEach(({ name, label, value }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, label, value })));
    textAreas.forEach(({ name, label, value }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA, label, value })));
    dataMap.set(
      FormField.GENETIC_SCREENING_PERFORMED,
      createFormField({
        name: FormField.GENETIC_SCREENING_PERFORMED,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: getFragmentContentIds(fragment, SectionTitle.GENETIC_SCREENING_PERFORMED, FormField.GENETIC_SCREENING_PERFORMED)
      })
    );

    return dataMap;
  };

  handleSaveClick = (pregnanciesInformationRecord?, hideSaveMessage = false) => {
    if (pregnanciesInformationRecord.records) {
      return this.props.saveChart([this.buildPregnanciesInformationRecord(pregnanciesInformationRecord)], {
        defaultFormFields: this.buildFormFields(),
        afterSave: () => {
          this.afterSave(false);
          this.handleCloseSidebarClick();
          if (hideSaveMessage) {
            this.props.showDeleteSuccess();
          }
        },
        hideSaveMessage
      });
    }

    return this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildFormFields(),
      afterSave: this.afterSave
    });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const pregnanciesInformationRecords = this.state.pregnanciesInformationRecords.length > 0 ? this.state.pregnanciesInformationRecords : [];
    const record = {
      chartTitle: 'History and Physical',
      fragmentTitle: SectionTitle.OBSTETRIC_HISTORY,
      records: [
        {
          sectionTitle: SectionTitle.OBSTETRIC_HISTORY,
          records: [buildPatientRecord(formFieldMap, FormField.INFERTILITY)]
        },
        {
          sectionTitle: SectionTitle.PREGNANT_HISTORY,
          records: [
            buildPatientRecord(formFieldMap, FormField.TOTAL_PREGNANCIES),
            buildPatientRecord(formFieldMap, FormField.MATURE),
            buildPatientRecord(formFieldMap, FormField.ABORTION_INDUCED),
            buildPatientRecord(formFieldMap, FormField.MULTIPLE_BIRTHS),
            buildPatientRecord(formFieldMap, FormField.TERM),
            buildPatientRecord(formFieldMap, FormField.ABORTION_SPONTANEOUS),
            buildPatientRecord(formFieldMap, FormField.ECTOPIC),
            buildPatientRecord(formFieldMap, FormField.LIVING_BIRTH)
          ]
        },
        {
          sectionTitle: SectionTitle.GENETIC_SCREENING_PERFORMED,
          records: [...buildPatientRecords(formFieldMap, FormField.GENETIC_SCREENING_PERFORMED), buildPatientRecord(formFieldMap, FormField.GENETIC_SCREENING_PERFORMED_OTHER)]
        },
        {
          sectionTitle: SectionTitle.CURRENT_PREGNANCY_RELATED_PROBLEMS,
          records: [buildPatientRecord(formFieldMap, FormField.DESCRIBE_CURRENT_PROBLEMS)]
        },
        {
          sectionTitle: SectionTitle.CURRENT_PREGNANCY_PRENATAL_NOTES,
          records: [buildPatientRecord(formFieldMap, FormField.NOTES_FROM_PRENATAL_VISITS)]
        },
        {
          sectionTitle: SectionTitle.PREVIOUS_PREGNANCIES,
          records: pregnanciesInformationRecords
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.selectedRecord?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  buildPregnanciesInformationRecord = (fragment): ChartFragment => {
    const { selectedRecord } = this.state;

    if (selectedRecord) {
      return this.updatePregnanciesInformationRecord(selectedRecord, fragment);
    }

    return this.buildDefaultPregnanciesInformationRecord(fragment);
  };

  updatePregnanciesInformationRecord = (selectedRecord, newRecord) => {
    let hasPregnanciesInformationRecords = false;
    let isUpdated = false;
    const draftSelectedRecord = cloneDeep(selectedRecord);

    return produce(draftSelectedRecord, (draft) => {
      draft.chartData.records.forEach((record) => {
        if (record.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES) {
          record.records.forEach((item) => {
            if (item.id === newRecord.id) {
              item.records = newRecord.records;
              item.active = newRecord.active;
              isUpdated = true;
            }
          });

          if (!isUpdated) {
            record.records.push(newRecord);
          }
          hasPregnanciesInformationRecords = true;
        }
      });

      if (!hasPregnanciesInformationRecords) {
        draft.chartData.records.push({
          sectionTitle: SectionTitle.PREVIOUS_PREGNANCIES,
          records: [{ ...newRecord }]
        });
      }
    }) as ChartFragment;
  };

  buildDefaultPregnanciesInformationRecord = (newRecord) => {
    const { selectedRecord } = this.state;
    const record = {
      chartTitle: 'History and Physical',
      fragmentTitle: SectionTitle.OBSTETRIC_HISTORY,
      records: [
        {
          sectionTitle: SectionTitle.PREVIOUS_PREGNANCIES,
          records: [{ ...newRecord }]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = selectedRecord?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  afterSave = (hasNavigation = true) =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => (hasNavigation ? delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME) : null));

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  handleDeleteClick = (id: string) =>
    this.props.showDeleteConfirmation(() => {
      const { pregnanciesInformationRecords } = this.state;
      const fragment = pregnanciesInformationRecords.find((item) => item.id === id);
      const deletedFragment = cloneDeep(fragment);

      deletedFragment.records.forEach((item) => {
        if (item) {
          item.active = false;
        }
      });
      deletedFragment.active = false;

      this.handleSaveClick(deletedFragment, true);
    });

  handleUpdateClick = (id: string) => {
    const { pregnanciesInformationRecords } = this.state;
    const pregnanciesInformation = pregnanciesInformationRecords.find((item) => item.id === id);
    this.setState({ pregnanciesInformation, isOpenSidebar: true });
  };

  handleCloseSidebarClick = () =>
    this.setState({
      isOpenSidebar: false,
      pregnanciesInformation: null
    });

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: ObstetricHistoryViewProps = {
      isLocked: this.state.isLocked,
      isOpenSidebar: this.state.isOpenSidebar,
      selectedRecord: this.state.selectedRecord,
      statusFragment: this.state.statusFragment,
      pregnanciesInformation: this.state.pregnanciesInformation,
      pregnanciesInformationHistory: this.state.previousPregnanciesHistory,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      onCloseSidebarClick: this.handleCloseSidebarClick,
      onSaveClick: this.handleSaveClick,
      onDeleteClick: this.handleDeleteClick,
      onUpdateClick: this.handleUpdateClick,
      onAddPregnancyInformationClick: () => this.setState({ isOpenSidebar: true }),
      chartActionsProps,
      ...appHelper.getChartSharedProps(this.props)
    };

    return <ObstetricHistoryView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

const enhancers = [connect(mapStateToProps), withFormUtilities, withChartLogic];
export { ObstetricHistory as BaseObstetricHistory };
export default compose(...enhancers)(ObstetricHistory);

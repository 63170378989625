import { orderBy } from 'lodash';
import { Component } from 'react';
import { ProviderOrderFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import ProviderOrderView, { ProviderOrderViewProps } from './ProviderOrderView';

export enum FormField {
  PROVIDER_ORDERS = 'providerOrders',
  SIGNATURE = 'signature'
}

export const buildFormFields = (fragment?: ProviderOrderFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();
  dataMap.set(
    FormField.PROVIDER_ORDERS,
    createFormField({
      name: FormField.PROVIDER_ORDERS,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Provider Orders',
      value: fragment?.chartData?.[FormField.PROVIDER_ORDERS] || ''
    })
  );
  dataMap.set(
    FormField.SIGNATURE,
    createFormField({
      name: FormField.SIGNATURE,
      type: FormFieldInputType.TEXT_BOX,
      label: 'Provider Signature',
      value: fragment?.chartData?.[FormField.SIGNATURE] || ''
    })
  );

  return dataMap;
};

interface ProviderOrderStates {
  chartHistory: ProviderOrderFragment[];
  selectedRecord: ProviderOrderFragment;
}

class ProviderOrder extends Component<ChartComponentProps, ProviderOrderStates> {
  static displayName = 'ProviderOrder';

  constructor(props) {
    super(props);
    this.state = {
      selectedRecord: null,
      chartHistory: []
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory), { errorMessage: 'can not load chart data' });
  }

  bindChartHistory = ({ data: fragments }) => {
    const records = orderBy(fragments, ['chartingAt'], ['asc']) as ProviderOrderFragment[];
    this.setState({ chartHistory: records });
  };

  handleDelete = (fragment: ProviderOrderFragment) => this.props.showDeleteConfirmation(() => this.handleConfirmDelete(fragment));

  handleConfirmDelete = (fragment: ProviderOrderFragment) => {
    return appHelper.useLoader(
      this.props.saveChartData({ ...fragment, active: false }).then(() => this.afterSave(true)),
      { errorMessage: 'can not save chart data' }
    );
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    return {
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData: {
        [FormField.PROVIDER_ORDERS]: formFieldMap.get(FormField.PROVIDER_ORDERS).value,
        [FormField.SIGNATURE]: formFieldMap.get(FormField.SIGNATURE).value
      }
    };
  };

  handleUpdate = () => {
    this.afterSave(false);
    this.setState({ selectedRecord: null });
  };

  afterSave = (isDelete) =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
      .then(this.resetForm)
      .then(() => appHelper.scrollTop());

  resetForm = () => this.props.initState(buildFormFields());

  handleSaveClick = () => {
    const fragment = this.buildFragment();
    this.props.handleSaveClick([fragment], { afterSave: () => this.afterSave(false) });
  };

  handleEdit = (selectedRecord: ProviderOrderFragment) => this.setState({ selectedRecord });

  handleClose = () => this.setState({ selectedRecord: null });

  render() {
    const { selectedRecord, chartHistory } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      isLocked: false,
      onSaveClick: this.handleSaveClick,
      onCancelClick: null,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: ProviderOrderViewProps = {
      chartHistory,
      selectedRecord,
      chartMetaFormFields: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      selectedNavId: this.props.selectedNavId,
      onEdit: this.handleEdit,
      onDelete: this.handleDelete,
      onUpdate: this.handleUpdate,
      onClose: this.handleClose,
      saveChartData: this.props.saveChartData,
      chartActionsProps,
      ...appHelper.getChartSharedProps(this.props)
    };

    return <ProviderOrderView {...viewProps} />;
  }
}

export { ProviderOrder as BaseProviderOrder };
export default withChartLogic(ProviderOrder);

import { HomeMedicationRecord } from 'models/api-response';
import { ELSFlex, ELSFlexItem, ELSRadio } from 'components/common/els';

export interface RadioRenderProps {
  row: HomeMedicationRecord;
  changeHandler: Function;
  fieldChangeName: string;
  homeMedicationType: string;
}

export const RadioRenderer = (props: RadioRenderProps) => {
  const { row, changeHandler, fieldChangeName, homeMedicationType } = props;
  return (
    <div>
      <form>
        <ELSFlex left className="u-els-margin-top-1o2">
          <ELSFlexItem className="u-els-margin-right">
            <ELSRadio
              name={`${homeMedicationType}-${fieldChangeName}-${row.fragmentId}-yes`}
              value="yes"
              checked={row[fieldChangeName] === true}
              changeHandler={() => changeHandler({ rowRecord: row, homeMedicationType, fieldChangeName, value: true })}
            >
              Yes
            </ELSRadio>
          </ELSFlexItem>
          <ELSFlexItem className="u-els-margin-right">
            <ELSRadio
              name={`${homeMedicationType}-${fieldChangeName}-${row.fragmentId}-no`}
              value="no"
              checked={row[fieldChangeName] === false}
              changeHandler={() => changeHandler({ rowRecord: row, homeMedicationType, fieldChangeName, value: false })}
            >
              No
            </ELSRadio>
          </ELSFlexItem>
        </ELSFlex>
      </form>
    </div>
  );
};

import { isEmpty } from 'lodash';
import { FormFieldDataType, FormFieldInputType } from 'models/enum';
import { ChartMetaFormField } from 'models/ui';
import { chartService } from 'services';

export const handleForKeepScaleStructure = (formFieldMap: Map<string, ChartMetaFormField>, formField: ChartMetaFormField, preventDelete?: boolean) => {
  const { buildPlainRecord } = chartService;
  return formField.chartContent
    .filter((content) => content.dataType !== FormFieldDataType.SCALE_ROLL_UP && content.dataType !== FormFieldDataType.SCALE_ROLL_UP_HIGHLIGHT)
    .map((content) => {
      const field = formFieldMap.get(content.formFieldId);
      if (field) {
        const nestedFieldId = field.chartContent.find((item) => item.value === field.extraData?.score?.value)?.nestedFieldId;
        return buildPlainRecord({
          formField: content.formFieldId,
          title: content.label,
          content: field?.extraData?.score,
          linkedFormFieldIds: nestedFieldId ? [nestedFieldId] : null,
          preventDelete
        });
      }
      return buildPlainRecord({
        formField: content.formFieldId,
        title: content.label,
        content: field?.extraData?.score,
        preventDelete
      });
    });
};

export const handleForNotKeepScaleStructure = (
  formFieldMap: Map<string, ChartMetaFormField>,
  formField: ChartMetaFormField,
  preventDelete?: boolean,
  removeEmptyRecords?: boolean
) => {
  const { buildPlainRecord } = chartService;
  return formField.chartContent
    .filter((content) => {
      const scoreValue = formFieldMap.get(content.formFieldId)?.extraData?.score?.value;
      const isKept = removeEmptyRecords ? !isEmpty(scoreValue) : true;
      return content.dataType === FormFieldDataType.SCALE_QUESTION && isKept;
    })
    .map((content) => {
      const field = formFieldMap.get(content.formFieldId);
      if (field) {
        const nestedFieldId = field.chartContent.find((item) => item.value === field.extraData?.score?.value)?.nestedFieldId;
        return buildPlainRecord({
          formField: content.formFieldId,
          title: content.label || content.fieldLabel,
          content: field?.extraData?.score?.value,
          linkedFormFieldIds: nestedFieldId ? [nestedFieldId] : null,
          preventDelete
        });
      }
      return buildPlainRecord({
        formField: content.formFieldId,
        title: content.label || content.fieldLabel,
        content: field?.extraData?.score?.value,
        preventDelete
      });
    });
};

export const buildPatientRecordsWithLinkedNestedFormFieldId = (
  formFieldMap: Map<string, ChartMetaFormField>,
  formFieldId: string,
  shouldKeepScaleStructure?: boolean,
  preventDelete?: boolean,
  removeEmptyRecords?: boolean
) => {
  const { buildRecordsFromField } = chartService;

  const formField = formFieldMap.get(formFieldId);
  if (formField?.inputType === FormFieldInputType.SCALE) {
    if (shouldKeepScaleStructure) {
      return handleForKeepScaleStructure(formFieldMap, formField, preventDelete);
    }

    return handleForNotKeepScaleStructure(formFieldMap, formField, preventDelete, removeEmptyRecords);
  }

  return buildRecordsFromField(formField, shouldKeepScaleStructure, preventDelete);
};

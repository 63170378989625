import { delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ActivitiesDailyLivingFragment, AssessmentRS, ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { buildPatientRecord } from 'services/chart.service';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel } from './constants';
import ActivitiesDailyLivingView, { ActivitiesDailyLivingViewProps } from './ActivitiesDailyLivingView';

export const buildFormFields = (record?: ChartFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();

  const textAreas = [
    {
      name: FormField.SLEEP,
      label: FormFieldLabel.SLEEP
    },
    {
      name: FormField.EXERCISE_ACTIVITIES,
      label: FormFieldLabel.EXERCISE_ACTIVITIES
    },
    {
      name: FormField.ELIMINATION_STOOL_URINE,
      label: FormFieldLabel.ELIMINATION_STOOL_URINE
    },
    {
      name: FormField.SAFETY_ASSESSMENT_ISSUES,
      label: FormFieldLabel.SAFETY_ASSESSMENT_ISSUES
    },
    {
      name: FormField.OTHER,
      label: FormFieldLabel.OTHER
    }
  ];

  textAreas.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA, label, value: record?.chartData?.[name]?.content || '' })));

  return dataMap;
};
export interface ActivitiesDailyLivingProps extends ChartComponentProps {
  assessment: AssessmentRS;
}

export interface ActivitiesDailyLivingState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ActivitiesDailyLivingFragment;
}
class ActivitiesDailyLiving extends Component<ActivitiesDailyLivingProps, ActivitiesDailyLivingState> {
  static displayName = 'ActivitiesDailyLiving';

  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      statusFragment: null,
      selectedRecord: null
    };
  }
  componentDidMount() {
    const { assessment, isAuthor } = this.props;

    if (!isAuthor) {
      const navIds = [NAV_ID.HISTORY_PHYSICAL, NAV_ID.ACTIVITIES_DAILY_LIVING];
      const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];

      appHelper.useLoader(chartService.loadFragments({ chartId: assessment.simChartId, navIds, fragmentTypes }).then(this.bindChartHistory), {
        errorMessage: 'can not load chart fragment'
      });
    }

    this.props.initState(buildFormFields());
  }

  bindChartHistory = ({ data: fragments }) => {
    const selectedRecord = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.ACTIVITIES_DAILY_LIVING, NAV_ID.HISTORY_PHYSICAL);
    this.setState({
      selectedRecord,
      statusFragment,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(buildFormFields(selectedRecord));
  };

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  buildFragment = () => {
    const { formFieldMap, chartingTime } = this.props;
    return {
      ...this.state.selectedRecord,
      ...chartService.createBaseFragment({ chartingTime }),
      chartData: {
        [FormField.SLEEP]: buildPatientRecord(formFieldMap, FormField.SLEEP),
        [FormField.EXERCISE_ACTIVITIES]: buildPatientRecord(formFieldMap, FormField.EXERCISE_ACTIVITIES),
        [FormField.ELIMINATION_STOOL_URINE]: buildPatientRecord(formFieldMap, FormField.ELIMINATION_STOOL_URINE),
        [FormField.SAFETY_ASSESSMENT_ISSUES]: buildPatientRecord(formFieldMap, FormField.SAFETY_ASSESSMENT_ISSUES),
        [FormField.OTHER]: buildPatientRecord(formFieldMap, FormField.OTHER)
      }
    };
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: buildFormFields(), afterSave: this.afterSave });
  };

  afterSave = () =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.state.isLocked,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: ActivitiesDailyLivingViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartActionsProps
    };
    return <ActivitiesDailyLivingView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];
export { ActivitiesDailyLiving as BaseActivitiesDailyLiving };
export default compose(...enhancers)(ActivitiesDailyLiving);

import { ChartTableHeader, DropDownItem } from '../../../models/ui';

export const tableHeadersChartField: ChartTableHeader[] = [
  {
    field: 'id',
    text: 'Chart Field'
  },
  {
    field: 'navigationElement',
    text: 'Navigation Element'
  },
  {
    field: 'formFieldId',
    text: 'Form Field'
  },
  {
    field: 'isDataPoint',
    text: 'Data Point'
  },
  {
    field: 'remove',
    text: 'Remove'
  }
];

export const tableHeadersChartContent: ChartTableHeader[] = [
  {
    field: 'id',
    text: 'Chart Field Content Id'
  },
  {
    field: 'chartField',
    text: 'Chart Field Reference Id'
  },
  {
    field: 'value',
    text: 'Value'
  },
  {
    field: 'label',
    text: 'Label'
  },
  {
    field: 'order',
    text: 'Order'
  },
  {
    field: 'dataType',
    text: 'Data Type'
  },
  {
    field: 'remove',
    text: 'Remove'
  }
];

export const tableHeadersChartValidations: ChartTableHeader[] = [
  {
    field: 'id',
    text: 'Chart Field Validation Id'
  },
  {
    field: 'chartField',
    text: 'Chart Field Reference Id'
  },
  {
    field: 'validationType',
    text: 'Validation Type'
  },
  {
    field: 'validationActionType',
    text: 'Validation Action Type'
  },
  {
    field: 'message',
    text: 'Message'
  },
  {
    field: 'parentValidation',
    text: 'Parent Validation'
  },
  {
    field: 'minRange',
    text: 'Min Range'
  },
  {
    field: 'maxRange',
    text: 'Max Range'
  },
  {
    field: 'regex',
    text: 'Regex'
  },
  {
    field: 'chartFieldContentSelection',
    text: 'Chart Field Content Selection'
  },
  {
    field: 'remove',
    text: 'Remove'
  }
];

export const tableHeadersContentOverrides: ChartTableHeader[] = [
  {
    field: 'condition',
    text: 'Condition'
  },
  {
    field: 'active',
    text: 'Active'
  },
  {
    field: 'value',
    text: 'Value'
  },
  {
    field: 'label',
    text: 'Label'
  },
  {
    field: 'order',
    text: 'Order'
  },
  {
    field: 'dataType',
    text: 'Data Type'
  },
  {
    field: 'remove',
    text: 'Remove'
  }
];

export const tableHeadersValidationOverrides: ChartTableHeader[] = [
  {
    field: 'condition',
    text: 'Condition'
  },
  {
    field: 'validationType',
    text: 'Validation Type'
  },
  {
    field: 'validationActionType',
    text: 'Validation Action Type'
  },
  {
    field: 'message',
    text: 'Message'
  },
  {
    field: 'minRange',
    text: 'minRange'
  },
  {
    field: 'maxRange',
    text: 'maxRange'
  },
  {
    field: 'regex',
    text: 'regex'
  },
  {
    field: 'remove',
    text: 'Remove'
  }
];

export const dataTypeDropDownItems = [
  { name: 'Select', value: '' },
  { name: 'CHOICE', value: 'CHOICE' },
  { name: 'INFO', value: 'INFO' },
  { name: 'DECORATOR', value: 'DECORATOR' },
  { name: 'SCALE', value: 'SCALE' },
  { name: 'SCALE_QUESTION', value: 'SCALE_QUESTION' },
  { name: 'SCALE_ROLL_UP', value: 'SCALE_ROLL_UP' },
  { name: 'SCALE_ROLL_UP_HIGHLIGHT', value: 'SCALE_ROLL_UP_HIGHLIGHT' },
  { name: 'SCALE_QUESTION_CONTEXT', value: 'SCALE_QUESTION_CONTEXT' },
  { name: 'SCALE_FOOTER', value: 'SCALE_FOOTER' }
];

export const validationTypeOptions = [
  { name: 'Select', value: '' },
  { name: 'RANGE', value: 'RANGE' },
  { name: 'NOT_IN_RANGE', value: 'NOT_IN_RANGE' },
  { name: 'REQUIRED', value: 'REQUIRED' },
  { name: 'REGEX_MATCH', value: 'REGEX_MATCH' },
  { name: 'CHART_CONTENT_SELECTED', value: 'CHART_CONTENT_SELECTED' },
  { name: 'CHART_CONTENT_NOT_SELECTED', value: 'CHART_CONTENT_NOT_SELECTED' },
  { name: 'EMPTY', value: 'EMPTY' },
  { name: 'NON_EMPTY', value: 'NON_EMPTY' },
  { name: 'ALWAYS_TRUE', value: 'ALWAYS_TRUE' },
  { name: 'ALWAYS_FALSE', value: 'ALWAYS_FALSE' }
];

export const validationActionTypeOptions = [
  { name: 'Select', value: '' },
  { name: 'WARNING', value: 'WARNING' },
  { name: 'ERROR', value: 'ERROR' },
  { name: 'ALERT', value: 'ALERT' },
  { name: 'VALIDATION', value: 'VALIDATION' },
  { name: 'DISABLE', value: 'DISABLE' },
  { name: 'HIDE', value: 'HIDE' },
  { name: 'EMPTY', value: 'EMPTY' },
  { name: 'REQUIRED', value: 'REQUIRED' },
  { name: 'PREFILL', value: 'PREFILL' }
];

export const dropDownItems = [
  { name: 'Select', value: '' },
  { name: 'false', value: 'false' },
  { name: 'true', value: 'true' }
];

export const createOrderDropDownItems = (): DropDownItem[] => {
  const orderList: DropDownItem[] = [];
  orderList.push({ name: 'Select', value: '' });
  for (let i = 1; i <= 100; i += 1) {
    orderList.push({ name: i.toString(), value: i.toString() });
  }
  return orderList;
};

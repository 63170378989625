import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';

export enum FormField {
  INTAKE_TYPE = 'intakeType',
  INTAKE_TYPE_OTHER = 'intakeTypeOther',
  INTAKE_AMOUNT = 'intakeAmount',
  INTAKE_FEED_PRESCRIBED = 'intakeFeedPrescribed',
  INTAKE_FEED_PRESCRIBED_TYPE = 'intakeFeedPrescribedType',
  INTAKE_FEED_PRESCRIBED_AMOUNT_ML = 'intakeFeedPrescribedAmountML',
  INTAKE_FEED_PRESCRIBED_AMOUNT_ML_PER_HOUR = 'intakeFeedPrescribedAmountMLPerHour',
  INTRAVENOUS_FLUIDS_TYPE = 'intravenousFluidsType',
  INTRAVENOUS_FLUIDS_TYPE_OTHER = 'intravenousFluidsTypeOther',
  INTRAVENOUS_FLUIDS_AMOUNT = 'intravenousFluidsAmount',
  MISCELLANEOUS_TYPE = 'miscellaneousType',
  MISCELLANEOUS_TYPE_OTHER = 'miscellaneousTypeOther',
  MISCELLANEOUS_AMOUNT = 'miscellaneousAmount',
  MISCELLANEOUS_UNITS = 'miscellaneousUnits',
  MEALS_TYPE = 'mealsType',
  MEALS_CONSUMED = 'mealsConsumed',
  OUTPUT_MEASURE = 'outputMeasure',
  OUTPUT_VOLUME_TYPE = 'outputVolumeType',
  OUTPUT_VOLUME_TYPE_OTHER = 'outputVolumeTypeOther',
  OUTPUT_VOLUME_AMOUNT = 'outputVolumeAmount',
  OUTPUT_FREQUENCY_TYPE = 'outputFrequencyType',
  OUTPUT_FREQUENCY_TYPE_OTHER = 'outputFrequencyTypeOther',
  OUTPUT_FREQUENCY_NUMBER = 'outputFrequencyNumber',
  OUTPUT_FREQUENCY_SIZE = 'outputFrequencySize',
  NOTE = 'note'
}

export const getIntakeOutputFields = (locale: Locales): ChartMetaFormFieldParams[] => [
  { name: FormField.INTAKE_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.TYPE },
  { name: FormField.INTRAVENOUS_FLUIDS_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.TYPE },
  { name: FormField.MISCELLANEOUS_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.MISCELLANEOUS_TYPE },
  { name: FormField.MEALS_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.TYPE },
  { name: FormField.OUTPUT_VOLUME_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.TYPE },
  { name: FormField.OUTPUT_FREQUENCY_TYPE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.TYPE },
  { name: FormField.OUTPUT_FREQUENCY_NUMBER, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.NUMBER },
  { name: FormField.OUTPUT_FREQUENCY_SIZE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.SIZE },
  { name: FormField.INTAKE_TYPE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER },
  { name: FormField.INTAKE_AMOUNT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.AMOUNT_ML },
  { name: FormField.INTRAVENOUS_FLUIDS_TYPE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER },
  { name: FormField.INTRAVENOUS_FLUIDS_AMOUNT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.AMOUNT_ML },
  { name: FormField.MISCELLANEOUS_TYPE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER },
  { name: FormField.MISCELLANEOUS_AMOUNT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.AMOUNT_ML },
  { name: FormField.MISCELLANEOUS_UNITS, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.UNITS, isHidden: locale !== Locales.EN_AU },
  { name: FormField.MEALS_CONSUMED, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.PERCENT_CONSUMED },
  { name: FormField.OUTPUT_VOLUME_TYPE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER },
  { name: FormField.OUTPUT_VOLUME_AMOUNT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.AMOUNT_ML },
  { name: FormField.OUTPUT_FREQUENCY_TYPE_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER },
  { name: FormField.INTAKE_FEED_PRESCRIBED, type: FormFieldInputType.TEXT_BOX, isHidden: locale !== Locales.EN_AU },
  { name: FormField.INTAKE_FEED_PRESCRIBED_TYPE, type: FormFieldInputType.RADIO_CHOICE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.INTAKE_FEED_PRESCRIBED_AMOUNT_ML, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.AMOUNT_ML, isHidden: locale !== Locales.EN_AU },
  { name: FormField.INTAKE_FEED_PRESCRIBED_AMOUNT_ML_PER_HOUR, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.AMOUNT_ML_PER_HOUR, isHidden: locale !== Locales.EN_AU },
  { name: FormField.OUTPUT_MEASURE, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.NOTE, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.NOTES }
];

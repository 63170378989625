import cx from 'classnames';
import { ChartFragment } from 'models/api-response';
import { AppMessage } from 'constants/app.constant';
import { defaultCustomRender } from 'helpers/chartRender.helper';
import { ChartHistory } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { FormField, FormFieldLabel, SectionTitle } from './constants';

interface PreviousPregnanciesHistoryProps {
  isLocked?: boolean;
  chartHistory: ChartFragment[];
  onDeleteClick?: Function;
  onUpdateClick?: Function;
}

const PreviousPregnanciesHistory = (props: PreviousPregnanciesHistoryProps) => {
  const { chartHistory } = props;

  const dayMonthYearsAgoRenderer = ({ id, active, dayMonthYearsAgo }) => {
    const hasAction = active && !props.isLocked;
    return (
      <div className="u-els-display-flex">
        <div className={cx({ 'row--deleted': !active })}>{dayMonthYearsAgo || ''}</div>
        {hasAction && (
          <>
            <div className={cx('u-els-display-inline-block', { 'u-cursor-pointer': active })}>
              <button className="sng-button-icon-wrapper" type="button" aria-label="Remove" onClick={() => props.onDeleteClick(id)}>
                <ELSIcon name="trash" prefix="gizmo" color="secondary" />
              </button>
            </div>
            <div className={cx('u-els-display-inline-block', { 'u-cursor-pointer': active })}>
              <button className="sng-button-icon-wrapper" type="button" aria-label="Remove" onClick={() => props.onUpdateClick(id)}>
                <ELSIcon name="edit" prefix="gizmo" color="secondary" />
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const dataSource = {
    headers: [
      {
        text: FormFieldLabel.DAY_MONTH_YEARS_AGO,
        field: FormField.DAY_MONTH_YEARS_AGO,
        section: SectionTitle.NO_SECTION,
        customRender: dayMonthYearsAgoRenderer
      },
      {
        text: FormFieldLabel.GESTATIONAL_WEEKS,
        field: FormField.GESTATIONAL_WEEKS,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.GESTATIONAL_WEEKS)
      },
      {
        text: FormFieldLabel.DURATION_OF_LABOR,
        field: FormField.DURATION_OF_LABOR,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.DURATION_OF_LABOR)
      },
      {
        text: FormFieldLabel.BIRTH_WEIGHT,
        field: FormField.BIRTH_WEIGHT,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.BIRTH_WEIGHT)
      },
      {
        text: FormFieldLabel.GENDER,
        field: FormField.GENDER,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.GENDER)
      },
      {
        text: FormFieldLabel.ANESTHESIA,
        field: FormField.ANESTHESIA,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.ANESTHESIA)
      },
      {
        text: FormFieldLabel.PRETERM_LABOR,
        field: FormField.PRETERM_LABOR,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.PRETERM_LABOR)
      },
      {
        text: FormFieldLabel.COMPLICATIONS,
        field: FormField.COMPLICATIONS,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.COMPLICATIONS)
      },
      {
        text: FormFieldLabel.COMMENTS,
        field: FormField.COMMENTS,
        section: SectionTitle.PREGNANCY_INFORMATION,
        customRender: defaultCustomRender(FormField.COMMENTS)
      }
    ],
    data: chartHistory
  };

  return (
    <div className="previous-pregnancies-history">
      <ELSFlex left wrap>
        <ELSFlexItem w={chartHistory.length === 0 ? '1o2' : '1o1'}>
          <ChartHistory noTableDataMessage=" " renderVertical tableTitleContents={[SectionTitle.NO_SECTION, SectionTitle.PREGNANCY_INFORMATION]} dataSource={dataSource} />
        </ELSFlexItem>
        {chartHistory.length === 0 && (
          <ELSFlexItem w="1o2">
            <div className="margin-top-4x1o2 u-els-margin-left table-message">{AppMessage.NO_TABLE_DATA}</div>
          </ELSFlexItem>
        )}
      </ELSFlex>
    </div>
  );
};

PreviousPregnanciesHistory.displayName = 'PreviousPregnanciesHistory';
export default PreviousPregnanciesHistory;

/* eslint-disable import/no-dynamic-require */

/* eslint-disable global-require */

/* eslint-disable @typescript-eslint/no-var-requires */
import cx from 'classnames';

interface GenericImageProps {
  id: string;
  altText: string;
  className?: string;
  src: string;
}

const isExternalSrc = (src: string) => !!src.match(/^(http(s?)):\/\/\S+/);

const GenericImage = (props: GenericImageProps) => {
  const { id, altText, className, src } = props;
  const localSrc = require(`assets/img/content/${src}`);
  const source = isExternalSrc(src) ? src : localSrc;
  return <img id={id} alt={altText} className={cx('generic-image', className)} src={source} />;
};
GenericImage.displayName = 'GenericImage';
export default GenericImage;

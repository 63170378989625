import { History } from 'history';
import queryString from 'query-string';
import { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { AssignmentType } from 'models/enum';
import { AssignmentTile, AssignmentWorkflowComponentProps } from 'models/ui';
import { appHelper, caseStudyHelper } from 'helpers';
import { ELSFlex } from 'components/common/els';
import { LanguageKeys } from 'lang';
import AssignmentSelectionTile from './AssignmentSelectionTile';
import './assignment.selection.scss';

interface AssignmentSelectionProps extends AssignmentWorkflowComponentProps {
  history: History;
}

class AssignmentSelection extends Component<AssignmentSelectionProps> {
  caseStudyId: string;
  constructor(props) {
    super(props);
    const parsedQuery = queryString.parse(props.history.location.search);
    const { caseStudyId } = parsedQuery;
    if (caseStudyId) {
      this.caseStudyId = caseStudyId.toString();
      this.handleClick({ currentTarget: { value: AssignmentType.CASE_STUDY } });
    }
  }

  handleClick = ({ currentTarget }) => {
    const { nextStep } = appHelper.getStepInfo(this.props.steps);
    const selectedAssignmentType = currentTarget.value;
    if (selectedAssignmentType === AssignmentType.CASE_STUDY) {
      const topic = { vtwId: '', text: '' };
      this.props.setNewAssignment({ assignmentTopics: [topic], caseStudyId: '', title: '' });
      caseStudyHelper.resetNewAssignmentStorage({ assignmentTopics: [topic], caseStudyId: '', title: '' });
    }
    if (this.props.newAssignment.assignmentType !== selectedAssignmentType) {
      this.props.resetNewAssignment();
      this.props.setNewAssignment({ assignmentType: selectedAssignmentType });
    }
    if (this.caseStudyId) {
      this.props.history.push({
        pathname: nextStep.route,
        search: `?caseStudyId=${this.caseStudyId}`
      });
    } else {
      this.props.history.push(nextStep.route);
    }
  };

  render() {
    const { newAssignment, intl } = this.props;
    const selectedAssignmentType = newAssignment.assignmentType;

    const tileData: AssignmentTile[] = [
      {
        id: 'assignment-selection-tile-case-study',
        assignmentType: AssignmentType.CASE_STUDY,
        title: 'Case Study (SimChart)',
        description: 'Students are assigned scenario-driven simulation with patient',
        isSelected: selectedAssignmentType === AssignmentType.CASE_STUDY,
        isDisabled: false
      },
      {
        id: 'assignment-selection-tile-empty-ehr',
        assignmentType: AssignmentType.EMPTY_EHR,
        title: intl.formatMessage({ id: LanguageKeys.ASSIGNMENT_SELECTION.EMPTY_EHR_SIMSCHART }),
        description: intl.formatMessage({ id: LanguageKeys.ASSIGNMENT_SELECTION.EMPTY_EHR_SIMSCHART_DESCRIPTION }),
        isSelected: selectedAssignmentType === AssignmentType.EMPTY_EHR,
        isDisabled: false
      },
      {
        id: 'assignment-selection-tile-simulation',
        assignmentType: AssignmentType.SIMULATION,
        title: 'Simulation',
        description: 'Students are assigned a patient scenario to use in the simulation lab',
        isSelected: selectedAssignmentType === AssignmentType.SIMULATION,
        isDisabled: true
      }
    ];

    return (
      <div className="assignment-selection">
        <ELSFlex center className="assignment-selection-tiles">
          {tileData.map((tile: AssignmentTile) => (
            <AssignmentSelectionTile key={tile.id} {...tile} handleClick={this.handleClick} />
          ))}
        </ELSFlex>
      </div>
    );
  }
}

export { AssignmentSelection as BaseAssignmentSelection };
export default compose(
  withProps(() => ({
    history: useHistory()
  }))
)(AssignmentSelection);

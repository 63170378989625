import { PhaseType } from 'models/enum';
import { CaseStudy } from 'models/ui';
import { assetHelper } from 'helpers';
import { Avatar, Box, ChartsInvolved } from 'components/common';
import { ELSTab, ELSTabGroup } from 'components/common/els';
import CaseStudyOverview, { CaseStudyOverviewProps } from './CaseStudyOverview';
import CaseStudyPhases from './CaseStudyPhases';
import { Tabs } from './CaseStudyQuickView';

export interface CaseStudyInfoViewProps {
  activeTab: number;
  selectedCaseStudy: CaseStudy;
  onTabGroupClick: Function;
  isBookOrganized: boolean;
}

const CaseStudyInfoView = (props: CaseStudyInfoViewProps) => {
  const { id, patient, diagnosis, sherpathModule, totalTimeLimit, concept, learningObjective, phases } = props.selectedCaseStudy || {};
  if (!id) {
    return null;
  }
  const filteredPhases = phases?.length ? phases.filter((phase) => phase.type !== PhaseType.OVERVIEW) : [];
  const overviewViewProps: CaseStudyOverviewProps = {
    diagnosis,
    chapter: sherpathModule,
    totalTimeLimit,
    concept,
    learningObjective,
    totalPhases: filteredPhases.length,
    isBookOrganized: props.isBookOrganized
  };
  const chartsInvolved = [];

  filteredPhases.forEach((phase) => {
    if (phase.chartsInvolved?.length) {
      phase.chartsInvolved.forEach((chart) => !chartsInvolved.includes(chart) && chartsInvolved.push(chart));
    }
  });

  const avatarUrl = assetHelper.getStaticPatientAvatar(patient.avatarUrl);

  return (
    <>
      <Box mt2 className="quick-view__tab-group">
        <ELSTabGroup activeIndex={props.activeTab} onChangeActiveIndex={props.onTabGroupClick}>
          <ELSTab title="Overview" />
          <ELSTab title="Charts involved" />
          <ELSTab title="Phases" />
        </ELSTabGroup>
        {patient.avatarUrl && <Avatar name="AA" image={avatarUrl} className="quick-view__avatar" />}
      </Box>
      {props.activeTab === Tabs.OVERVIEW && <CaseStudyOverview {...overviewViewProps} />}
      {props.activeTab === Tabs.CHARTS_INVOLVED && <ChartsInvolved charts={chartsInvolved} />}
      {props.activeTab === Tabs.PHASES && <CaseStudyPhases phases={filteredPhases} />}
    </>
  );
};

CaseStudyInfoView.displayName = 'CaseStudyTabsView';
export default CaseStudyInfoView;

import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { appActions } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import { AllergiesChartHistory } from './AllergiesHistory';
import AllergiesView from './AllergiesView';

interface AllergiesProps extends ChartComponentProps {
  updatePatient: Function;
}

interface AllergiesState {
  chartHistory: AllergiesChartHistory[];
  fragments: ChartFragment[];
}

class Allergies extends Component<AllergiesProps, AllergiesState> {
  static displayName = 'Allergies';

  constructor(props) {
    super(props);
    this.state = {
      chartHistory: [],
      fragments: []
    };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.updateChartHistory));
  }

  buildFormFields = () => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const radioChoices = [
      { name: FormField.ALLERGY_TYPE, label: FormFieldLabel.ALLERGY_TYPE },
      { name: FormField.SEVERITY, label: FormFieldLabel.SEVERITY },
      { name: FormField.CONFIDENCE_LEVEL, label: FormFieldLabel.CONFIDENCE_LEVEL }
    ];
    const multiselectRadios = [
      { name: FormField.ALLERGEN, label: FormFieldLabel.ALLERGEN },
      { name: FormField.ALLERGEN_MEDICATION, label: FormFieldLabel.ALLERGEN },
      { name: FormField.INFORMANT, label: FormFieldLabel.INFORMANT }
    ];
    const textBoxes = [
      { name: FormField.ALLERGEN_OTHER, label: FormFieldLabel.OTHER },
      { name: FormField.ALLERGEN_MEDICATION_OTHER, label: FormFieldLabel.OTHER },
      { name: FormField.REACTIONS_OTHER, label: FormFieldLabel.OTHER },
      { name: FormField.INFORMANT_OTHER, label: FormFieldLabel.OTHER }
    ];

    dataMap.set(FormField.NO_KNOWN_ALLERGIES, createFormField({ name: FormField.NO_KNOWN_ALLERGIES, type: FormFieldInputType.CHECK_BOX }));
    dataMap.set(FormField.REACTIONS, createFormField({ name: FormField.REACTIONS, type: FormFieldInputType.MULTISELECT_DROPDOWN }));
    radioChoices.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label })));
    multiselectRadios.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTI_SELECT_RADIO, label })));
    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, label })));

    return dataMap;
  };

  updateChartHistory = (apiRes) => {
    const chartHistory = apiRes?.data.map((fragment) => {
      return {
        active: fragment.active,
        id: fragment.fragmentId,
        chartingAt: fragment.chartingAt,
        createdAt: fragment.createdAt,
        creator: fragment.creator,
        modifier: fragment.modifier,
        [FormField.NO_KNOWN_ALLERGIES]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.NO_KNOWN_ALLERGIES)[0]?.title,
        [FormField.ALLERGY_TYPE]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGY_TYPE)[0]?.content,
        [FormField.ALLERGEN]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN)[0]?.content,
        [FormField.ALLERGEN_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_OTHER)[0]?.content,
        [FormField.ALLERGEN_MEDICATION]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_MEDICATION)[0]?.content,
        [FormField.ALLERGEN_MEDICATION_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_MEDICATION_OTHER)[0]?.content,
        [FormField.REACTIONS]: chartHelper
          .getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.REACTIONS)
          .map((item) => item.title)
          .filter((item) => item !== 'Other'),
        [FormField.REACTIONS_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.REACTIONS_OTHER)[0]?.content,
        [FormField.SEVERITY]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.SEVERITY)[0]?.content,
        [FormField.INFORMANT]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.INFORMANT)[0]?.content,
        [FormField.INFORMANT_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.INFORMANT_OTHER)[0]?.content,
        [FormField.CONFIDENCE_LEVEL]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.CONFIDENCE_LEVEL)[0]?.content
      };
    });
    this.setState({ chartHistory, fragments: apiRes?.data });

    let noKnownAllergiesLatest = null;
    chartHistory
      .filter((record) => record.noKnownAllergies && record.active)
      .forEach((record) => {
        if (!noKnownAllergiesLatest) {
          noKnownAllergiesLatest = record;
        }
        if (moment(record.createdAt).isAfter(noKnownAllergiesLatest.createdAt)) {
          noKnownAllergiesLatest = record;
        }
      });
    const allergiesForPatient = chartHistory
      ?.filter((record) => {
        const isAfterNoKnownAllergiesLatest = noKnownAllergiesLatest ? moment(record.createdAt).isAfter(noKnownAllergiesLatest?.chartingAt) : true;
        return record.active && !record.noKnownAllergies && isAfterNoKnownAllergiesLatest;
      })
      .map((record) => ({
        [FormField.ALLERGY_TYPE]: record.allergyType,
        [FormField.ALLERGEN]: record.allergen,
        [FormField.ALLERGEN_OTHER]: record.allergenOther,
        [FormField.ALLERGEN_MEDICATION]: record.medication,
        [FormField.ALLERGEN_MEDICATION_OTHER]: record.allergenMedicationOther
      }));
    this.props.updatePatient({
      allergyRecords: allergiesForPatient
    });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildFormFields(), afterSave: this.afterSave });
  };

  afterSave = () => {
    this.props
      .loadChartData()
      .then(this.updateChartHistory)
      .then(() => {
        if (this.props.backToSourceLocation) {
          this.props.backToSourceLocation();
        }
      });
  };

  buildFragment = () => {
    const record = {
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ALLERGIES,
        fields: [
          FormField.NO_KNOWN_ALLERGIES,
          FormField.ALLERGY_TYPE,
          FormField.ALLERGEN,
          FormField.ALLERGEN_OTHER,
          FormField.ALLERGEN_MEDICATION,
          FormField.ALLERGEN_MEDICATION_OTHER,
          FormField.REACTIONS,
          FormField.REACTIONS_OTHER,
          FormField.SEVERITY,
          FormField.INFORMANT,
          FormField.INFORMANT_OTHER,
          FormField.CONFIDENCE_LEVEL
        ],
        formFieldMap
      })
    ];
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(() => this.props.loadChartData())
        .then(this.updateChartHistory)
        .then(this.props.showDeleteSuccess)
    );
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <AllergiesView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (newPatientData) => dispatch(appActions.updatePatient(newPatientData))
});

const enhancers = [withChartLogic, connect(null, mapDispatchToProps)];

export { Allergies as BaseAllergies };
export default compose(...enhancers)(Allergies);

import { orderBy, times as timesCount, uniq } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { v4 } from 'uuid';
import { ELSDropDownOption } from '@els/els-component-form-field-react';
import { ChartFragment } from 'models/api-response';
import { AssignmentType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, DosageTime, MarDosageRecord, MarRecord } from 'models/ui';
import { DateFormatByLocale, DateTimeFormatByLocale, ISO_FORMAT, MOMENT_INCLUSIVITY_ALL, MOMENT_UNIT_DAYS, MOMENT_UNIT_MINUTES, NAV_ID, TIME_FORMAT } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { nowDate, toMomentWithFormat } from 'helpers/datetime.helper';
import { studentSelectors } from 'redux/ducks/student';
import { withChartLogic } from 'components/common';
import { FormField as PharmacyFormField } from 'components/features/chart/order-entry/pharmacy/constants';
import { DOSE_MODE, EXPIRED_DAYS, FormField, INACTIVE_TYPE, ORDER_TYPES, SPECIAL_DOSAGE_MAPPING_RULES } from './constants';
import MarView, { MarViewProps } from './MarView';
import dosageTimesMapping from './dosageTimesMapping';
import {
  getPharmacyDataAndAdministeredDosageTimes,
  handleAfterFirstDose,
  handleAfterStartTime,
  handleNormal,
  handleOnceThenDropOff,
  handleTimesInAWeek,
  reorganizePharmacyDataToRecords
} from './helper';

interface MarState {
  statSelected: boolean;
  scheduledSelected: boolean;
  prnSelected: boolean;
  continuousIVSelected: boolean;
  inactiveOrderSelected: boolean;
  viewDate: string;
  viewDateOptions: ELSDropDownOption[];
  statRecords: MarRecord[];
  scheduledRecords: MarRecord[];
  prnRecords: MarRecord[];
  continuousIVRecords: MarRecord[];
  inactiveOrderRecords: MarRecord[];
  fragments: ChartFragment[];
  selectedDosageRecord: MarDosageRecord;
  doseFormMode: string;
}

interface InactiveOrdersType {
  inactiveType?: string;
  status?: string;
}
export interface MarProps extends ChartComponentProps {
  enableMultiStepsAuthoring: Function;
  phaseIndex: number;
}

class Mar extends Component<MarProps, MarState> {
  static displayName = 'Mar';

  constructor(props) {
    super(props);
    this.state = {
      statSelected: true,
      scheduledSelected: true,
      prnSelected: true,
      continuousIVSelected: true,
      inactiveOrderSelected: true,
      viewDate: '',
      viewDateOptions: [],
      statRecords: [],
      scheduledRecords: [],
      prnRecords: [],
      continuousIVRecords: [],
      inactiveOrderRecords: [],
      fragments: [],
      selectedDosageRecord: null,
      doseFormMode: DOSE_MODE.ADMINISTER
    };
  }

  componentDidMount() {
    this.loadChartData();
    this.props.enableMultiStepsAuthoring(true);
  }

  getDefaultDosageTime = (dateStr, timeStr) => [this.getDosageTimeObject(dateStr, timeStr)];

  getDosageTimeByFrequency = (existedDosageTimes, frequency: string, dateStr: string, timeStr: string, isInValidRange: Function) => {
    const timeEntries = dosageTimesMapping.get(frequency).timeMapping;
    if (timeEntries) {
      const dosageTimes = [];
      timeEntries.forEach((item) => {
        const frequencyDateTime = toMomentWithFormat(`${dateStr} ${item}`, Object.values(DateTimeFormatByLocale));
        const administeredDosageTime = existedDosageTimes?.find((dosageTime) => {
          const itemDateTime = toMomentWithFormat(`${dosageTime.dosageDate} ${dosageTime.dosageTime}`, Object.values(DateTimeFormatByLocale));
          return itemDateTime.isSame(frequencyDateTime) && dosageTime.isAdministered;
        });
        if (isInValidRange(frequencyDateTime)) {
          dosageTimes.push(administeredDosageTime ?? this.getDosageTimeObject(dateStr, item));
        }
      });
      return dosageTimes;
    }
    const existedDosageTime = existedDosageTimes?.filter((dosageTime) => dosageTime.dosageDate === dateStr);
    return existedDosageTime ? [...existedDosageTime] : this.getDefaultDosageTime(dateStr, timeStr);
  };

  getDosageTimeByAfterStartTime = (existedDosageTimes, firstOffset, frequency: string, isInValidRange: Function) => {
    const { locale } = this.props;
    const { repeatTimes, minutesApart } = dosageTimesMapping.get(frequency);

    const dosageTimeList = [];
    const repeatTimesWithFirstOffsetSize = repeatTimes + 1;
    timesCount(repeatTimesWithFirstOffsetSize, (index) => {
      const frequencyDateTime = toMomentWithFormat(firstOffset, Object.values(DateTimeFormatByLocale)).add(index * minutesApart, MOMENT_UNIT_MINUTES);
      const administeredDosageTime = existedDosageTimes?.find((dosageTime) => {
        const itemDateTime = toMomentWithFormat(`${dosageTime.dosageDate} ${dosageTime.dosageTime}`, Object.values(DateTimeFormatByLocale));
        return itemDateTime.isSame(frequencyDateTime) && dosageTime.isAdministered;
      });
      if (isInValidRange(frequencyDateTime)) {
        dosageTimeList.push(administeredDosageTime ?? this.getDosageTimeObject(frequencyDateTime.format(DateFormatByLocale[locale]), frequencyDateTime.format(TIME_FORMAT)));
      }
    });
    return dosageTimeList;
  };

  getDosageTimeByTimesInAWeek = (existedDosageTimes, frequency: string, dateStr: string, timeStr: string, isInValidRange: Function) => {
    const { locale } = this.props;
    const { days, times } = dosageTimesMapping.get(frequency);

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dosageTimeList = [];
    const dayIndex = new Date(`${dateStr}`).getDay();
    if (days.includes(dayNames[dayIndex])) {
      timesCount(times.length, (index: number) => {
        const frequencyDateTime = toMomentWithFormat(`${dateStr} ${times[index]}`, Object.values(DateTimeFormatByLocale));
        const administeredDosageTime = existedDosageTimes?.find((dosageTime) => {
          const itemDateTime = toMomentWithFormat(`${dosageTime.dosageDate} ${dosageTime.dosageTime}`, Object.values(DateTimeFormatByLocale));
          return itemDateTime.isSame(frequencyDateTime) && dosageTime.isAdministered;
        });
        if (isInValidRange(frequencyDateTime)) {
          dosageTimeList.push(
            administeredDosageTime ?? this.getDosageTimeObject(frequencyDateTime.format(DateFormatByLocale[locale]).toString(), frequencyDateTime.format(TIME_FORMAT).toString())
          );
        }
      });
    }
    return dosageTimeList;
  };

  getDosageTimeByAfterFirstDose = (timeEntries, existedDosageTimes, isInValidRange: Function) => {
    const { locale } = this.props;
    const dosageItemList = [];
    timesCount(timeEntries.length, (row) => {
      const frequencyDateTime = toMomentWithFormat(`${timeEntries[row]}`, Object.values(DateTimeFormatByLocale));
      if (isInValidRange(frequencyDateTime)) {
        const administeredDosageTime = existedDosageTimes?.find((dosageTime) => {
          const itemDateTime = toMomentWithFormat(`${dosageTime.dosageDate} ${dosageTime.dosageTime}`, Object.values(DateTimeFormatByLocale));
          const itemDateFirstTime = toMomentWithFormat(`${dosageTime.dosageDate} ${dosageTime.administeredTime}`, Object.values(DateTimeFormatByLocale));
          return (itemDateTime.isSame(frequencyDateTime) || itemDateFirstTime.isSame(frequencyDateTime)) && dosageTime.isAdministered;
        });
        dosageItemList.push(administeredDosageTime ?? this.getDosageTimeObject(frequencyDateTime.format(DateFormatByLocale[locale]), frequencyDateTime.format(TIME_FORMAT)));
      }
    });
    return dosageItemList.filter((dosageItem) => dosageItem.dosageTime !== 'Administer');
  };

  getStartDateDosageTime = (format, dosageTimes = []) => {
    if (dosageTimes.length === 0) return undefined;
    let result = dosageTimes[0];
    dosageTimes.forEach((dosage) => {
      const prevDosageDate = toMomentWithFormat(result.dosageDate, format);
      const currentDosageDate = toMomentWithFormat(dosage.dosageDate, format);
      if (currentDosageDate.isBefore(prevDosageDate)) {
        result = dosage;
      }
    });
    return toMomentWithFormat(result.dosageDate, format);
  };

  getDosageTime = (dosageTimes, frequency, startDateStr: string, endDateStr: string) => {
    const { locale } = this.props;
    const dateTimeBetween = [];
    const mapping = dosageTimesMapping.get(frequency);
    if (mapping === undefined) {
      return dosageTimes || dateTimeBetween;
    }
    const startDateDosageTime = this.getStartDateDosageTime(Object.values(DateTimeFormatByLocale), dosageTimes);
    // if no dosage times
    const orderStartDate = toMomentWithFormat(startDateStr, Object.values(DateTimeFormatByLocale));
    const startDate = startDateDosageTime?.isBefore(orderStartDate) ? startDateDosageTime : orderStartDate;
    const hardEndDateTime = '23:59';
    const endDateTime = toMomentWithFormat(`${endDateStr} ${hardEndDateTime}`, Object.values(DateTimeFormatByLocale));
    const isInValidRange = (dateTime) => dateTime.isBetween(startDate, endDateTime, MOMENT_UNIT_MINUTES, MOMENT_INCLUSIVITY_ALL);

    const { minutesAfterStartTime, isTopOfTheHour, isStop, isIgnored, repeatTimes, minutesApart } = mapping;
    switch (dosageTimesMapping.get(frequency)?.type) {
      case SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME:
        handleAfterStartTime({
          startDate,
          isStop,
          isTopOfTheHour,
          frequency,
          isInValidRange,
          endDateTime,
          dosageTimes,
          minutesAfterStartTime,
          repeatTimes,
          dateTimeBetween,
          locale,
          getDosageTimeByAfterStartTime: this.getDosageTimeByAfterStartTime
        });
        break;
      case SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE: {
        handleAfterFirstDose({
          dosageTimes,
          dateTimeBetween,
          startDate,
          repeatTimes,
          minutesApart,
          locale,
          isStop,
          isInValidRange,
          endDateTime,
          getDosageTimeObject: this.getDosageTimeObject,
          getAdministeredDosageTimes: this.getAdministeredDosageTimes,
          getDosageTimeByAfterFirstDose: this.getDosageTimeByAfterFirstDose
        });
        break;
      }
      case SPECIAL_DOSAGE_MAPPING_RULES.TIMES_IN_A_WEEK:
        handleTimesInAWeek({
          startDate,
          endDateTime,
          dosageTimes,
          frequency,
          isInValidRange,
          dateTimeBetween,
          locale,
          getDosageTimeByTimesInAWeek: this.getDosageTimeByTimesInAWeek
        });
        break;
      case SPECIAL_DOSAGE_MAPPING_RULES.NORMAL:
        handleNormal({ startDate, endDateTime, dosageTimes, frequency, isInValidRange, dateTimeBetween, locale, getDosageTimeByFrequency: this.getDosageTimeByFrequency });
        break;
      case SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF: {
        handleOnceThenDropOff({
          startDate,
          dosageTimes,
          dateTimeBetween,
          endDateTime,
          frequency,
          isInValidRange,
          isIgnored,
          locale,
          getAdministeredDosageTimes: this.getAdministeredDosageTimes,
          getDosageTimeByFrequency: this.getDosageTimeByFrequency
        });
        break;
      }
      default:
        break;
    }
    return uniq(dateTimeBetween);
  };

  getAdministeredDosageTimes = (dosageTimes) => {
    return dosageTimes?.filter((dosageTime) => dosageTime.isAdministered);
  };

  getDosageTimeObject = (dosageDate: string, dosageTime: string) => ({
    id: v4(),
    dosageDate,
    dosageTime,
    administeredDate: dosageDate,
    administeredTime: '00:00',
    comments: '',
    firstInitial: '',
    lastInitial: '',
    isAdministered: false
  });

  handleStatChange = () => this.setState((state) => ({ statSelected: !state.statSelected }));

  handleScheduledChange = () => this.setState((state) => ({ scheduledSelected: !state.scheduledSelected }));

  handlePrnChange = () => this.setState((state) => ({ prnSelected: !state.prnSelected }));

  handleContinuousIVChange = () => this.setState((state) => ({ continuousIVSelected: !state.continuousIVSelected }));

  handleInactiveOrderChange = () => this.setState((state) => ({ inactiveOrderSelected: !state.inactiveOrderSelected }));

  handleEditDosageRecord = (marRecord: MarRecord, dosageTime: DosageTime) => {
    const marDosageRecord: MarDosageRecord = {
      ...marRecord,
      ...dosageTime
    };
    this.setState({ selectedDosageRecord: marDosageRecord, doseFormMode: DOSE_MODE.ADMINISTER });
  };

  handleHoldMedication = (marRecord: MarRecord, dosageTime: DosageTime) => {
    const marDosageRecord: MarDosageRecord = {
      ...marRecord,
      ...dosageTime
    };
    this.setState({ selectedDosageRecord: marDosageRecord, doseFormMode: DOSE_MODE.HOLD });
  };

  handleUnHoldMedication = (mar: MarRecord) => {
    const { fragments } = this.state;
    const { saveChartData } = this.props;
    const fragment = fragments.find((item) => item.fragmentId === mar.fragmentId);
    const updatedChartData = {
      ...fragment.chartData,
      [FormField.HOLD_DATE]: mar.orderStartDate,
      [FormField.HOLD_TIME]: '00:00',
      [FormField.HOLD_COMMENTS]: '',
      [FormField.HOLD_FIRST_INITIAL]: '',
      [FormField.HOLD_LAST_INITIAL]: '',
      [FormField.IS_HOLD]: false
    };
    const updatedFragment = {
      ...this.presaveFragment(fragment),
      chartData: updatedChartData
    };

    saveChartData(updatedFragment, NAV_ID.PHARMACY).then(() => this.loadChartData());
  };

  handleDiscontinueMedication = (marRecord: MarRecord, dosageTime: DosageTime) => {
    const marDosageRecord: MarDosageRecord = {
      ...marRecord,
      ...dosageTime
    };
    this.setState({ selectedDosageRecord: marDosageRecord, doseFormMode: DOSE_MODE.DISCONTINUE });
  };

  handleRecordUpdate = () => {
    this.setState({ selectedDosageRecord: null });
    this.loadChartData();
  };

  handleSidebarClose = () => this.setState({ selectedDosageRecord: null });

  getInactiveOrdersType = (item): InactiveOrdersType => {
    const { chartingTime, assessment } = this.props;
    const { assignmentType } = assessment;

    const { orderStop, dosageTimes } = item;
    const todayMoment = assignmentType === AssignmentType.CASE_STUDY ? moment(chartingTime) : moment();
    const endMoment = toMomentWithFormat(orderStop, Object.values(DateTimeFormatByLocale));
    const isFullyAdministered = dosageTimes?.every((dosageTime) => dosageTime.isAdministered);
    const result = { inactiveType: '', status: '' };
    if (item.isDiscontinued) {
      // Catch Discontinue status
      const { discontinueDate, discontinueTime, discontinueFirstInitial, discontinueLastInitial } = item;
      result.inactiveType = INACTIVE_TYPE.DISCONTINUED;
      result.status = `${discontinueDate} ${discontinueTime} - ${discontinueFirstInitial}${discontinueLastInitial} Discontinued`;
    } else if ((todayMoment.isAfter(endMoment) && isFullyAdministered) || this.isMaxAdministrationTimes(item)) {
      // Catch Completed status
      result.inactiveType = INACTIVE_TYPE.COMPLETED;
      result.status = INACTIVE_TYPE.COMPLETED;
    } else if (item.orderType === ORDER_TYPES.STAT && dosageTimes?.some((time) => time.isAdministered)) {
      result.inactiveType = INACTIVE_TYPE.STAT_ADMINISTERED;
      result.status = INACTIVE_TYPE.STAT_ADMINISTERED;
    } else if (item[PharmacyFormField.HAS_STOP_DATE_TIME_DEFAULT]) {
      const expiredDateTime = toMomentWithFormat(item[PharmacyFormField.ORDER_START_DATE], Object.values(DateTimeFormatByLocale)).add(EXPIRED_DAYS, MOMENT_UNIT_DAYS);
      // Catch Expired status
      if (todayMoment.isAfter(expiredDateTime)) {
        result.inactiveType = INACTIVE_TYPE.EXPIRED;
        result.status = INACTIVE_TYPE.EXPIRED;
        // Catch Past due status
      } else if (todayMoment.isAfter(endMoment)) {
        result.inactiveType = INACTIVE_TYPE.PAST_DUE;
        result.status = INACTIVE_TYPE.PAST_DUE;
      }
    } else if (todayMoment.isAfter(endMoment) && !isFullyAdministered) {
      // Catch Past due status
      result.inactiveType = INACTIVE_TYPE.PAST_DUE;
      result.status = INACTIVE_TYPE.PAST_DUE;
    }
    return result.inactiveType || result.status ? result : {};
  };

  onSelectViewDate = (event) => {
    const { value } = event.target;
    this.setState({
      viewDate: value
    });
  };

  getDaysBetween = (administeredDosageTimes, startDate, stopDate) => {
    const { locale } = this.props;
    const startDateDosageTime = this.getStartDateDosageTime(DateFormatByLocale[locale], administeredDosageTimes);
    const dateArray = [];
    let indexDateMoment = startDateDosageTime?.isBefore(startDate) ? startDateDosageTime : startDate;
    while (indexDateMoment <= stopDate) {
      const indexDateString = indexDateMoment.format(DateFormatByLocale[locale]);
      dateArray.push({ name: indexDateString, value: indexDateString });
      indexDateMoment = indexDateMoment.add(1, 'days');
    }
    return dateArray;
  };

  groupFragments = (fragments: ChartFragment[]): Map<string, ChartFragment[]> => {
    const fragmentGroupMap = new Map();
    fragments.forEach((fragment) => {
      const id = fragment.groupFragmentRef || fragment.linkedFragmentId || fragment.fragmentId;
      const fragmentGroup = fragmentGroupMap.get(id) ?? [];
      fragmentGroup.push(fragment);
      fragmentGroupMap.set(id, fragmentGroup);
    });
    return fragmentGroupMap;
  };

  // Prevent overwrite an authored record
  presaveFragment = (fragment: ChartFragment): ChartFragment => {
    if (fragment.fragmentType === FragmentType.AUTHORED && !this.props.isAuthor) {
      return {
        ...fragment,
        fragmentType: FragmentType.CHARTING,
        fragmentId: chartHelper.createFragmentId(NAV_ID.PHARMACY)
      };
    }
    return fragment;
  };

  isMaxAdministrationTimes = (item): boolean => {
    const mapping = dosageTimesMapping.get(item.frequency);
    if (!mapping?.administrationTimes || !item.dosageTimes) return false;
    const numberOfAdminister = item.dosageTimes.filter((dosageTime) => dosageTime.isAdministered).length;
    return numberOfAdminister === mapping.administrationTimes;
  };

  loadChartData() {
    const { locale } = this.props;
    const chartId = this.props.assessment.simChartId;
    const navIds = [NAV_ID.PHARMACY];
    appHelper.useLoader(
      this.props
        .loadChartData([FragmentType.CHARTING, FragmentType.AUTHORED], navIds, chartId)
        .then((res) => res.data)
        .then((data) => {
          let fragments = orderBy(data, ['chartingAt', 'createdAt'], ['desc', 'desc']);
          const fragmentGroups = this.groupFragments(fragments);
          if (this.props.isAuthor) {
            // The first element of fragmentGroup is the latest one
            fragments = Array.from(fragmentGroups.values()).map((fragGroup) => fragGroup[0]);
          } else {
            fragments = Array.from(fragmentGroups.values()).map((fragGroup) => {
              // Prefer CHARTING records
              const studentFragment = fragGroup.find((frag) => frag.fragmentType === FragmentType.CHARTING);
              return studentFragment || fragGroup.find((frag) => frag.phaseIndex === this.props.phaseIndex) || fragGroup[0];
            });
          }
          const { pharmacyData, administeredDosageTimes } = getPharmacyDataAndAdministeredDosageTimes(fragments, locale, this.props.isAuthor);
          const { statRecords, continuousIVRecords, scheduledRecords, prnRecords, inactiveOrderRecords, viewDateStart, viewDateStop } = reorganizePharmacyDataToRecords(
            pharmacyData,
            this.getDosageTime,
            this.getInactiveOrdersType
          );
          const { assignmentType } = this.props.assessment;
          let viewDateList = this.getDaysBetween(administeredDosageTimes, viewDateStart, viewDateStop);
          let viewDate = nowDate(DateFormatByLocale[locale]);
          if (assignmentType === AssignmentType.CASE_STUDY && this.props.chartingTime) {
            viewDate = toMomentWithFormat(this.props.chartingTime, ISO_FORMAT).format(DateFormatByLocale[locale]);
          }
          const foundDuplicate = viewDateList.find((item) => item.value === viewDate);
          if (!foundDuplicate) {
            viewDateList.push({ name: viewDate, value: viewDate });
          }
          viewDateList = viewDateList.sort((date1, date2) =>
            toMomentWithFormat(date1.value, Object.values(DateTimeFormatByLocale)).diff(toMomentWithFormat(date2.value, Object.values(DateTimeFormatByLocale)))
          );
          this.setState((prevState) => ({
            fragments,
            viewDateOptions: viewDateList,
            viewDate: prevState.viewDate === '' ? viewDate : prevState.viewDate,
            statRecords,
            scheduledRecords,
            prnRecords,
            continuousIVRecords,
            inactiveOrderRecords
          }));
        }),
      { errorMessage: 'Can not load data for MAR' }
    );
  }

  render() {
    const { locale } = this.props;
    const { statSelected, scheduledSelected, prnSelected, continuousIVSelected, inactiveOrderSelected, viewDateOptions, viewDate } = this.state;
    const { statRecords, scheduledRecords, prnRecords, continuousIVRecords, inactiveOrderRecords } = this.state;
    const { selectedDosageRecord, doseFormMode, fragments } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      onDisplayRecordsClick: this.props.displayAuthoringData
    };
    const viewProps: MarViewProps = {
      isAuthor: this.props.isAuthor,
      statSelected,
      scheduledSelected,
      prnSelected,
      continuousIVSelected,
      inactiveOrderSelected,
      viewDate,
      statRecords,
      scheduledRecords,
      prnRecords,
      continuousIVRecords,
      inactiveOrderRecords,
      selectedDosageRecord,
      doseFormMode,
      fragments,
      locale,
      presaveFragment: this.presaveFragment,
      onStatChange: this.handleStatChange,
      onScheduledChange: this.handleScheduledChange,
      onPrnChange: this.handlePrnChange,
      onContinuousIVChange: this.handleContinuousIVChange,
      onInactiveOrderChange: this.handleInactiveOrderChange,
      onEditDosageRecord: this.handleEditDosageRecord,
      onRecordUpdate: this.handleRecordUpdate,
      onSidebarClose: this.handleSidebarClose,
      saveChartData: this.props.saveChartData,
      handleHoldMedication: this.handleHoldMedication,
      handleUnHoldMedication: this.handleUnHoldMedication,
      handleDiscontinueMedication: this.handleDiscontinueMedication,
      viewDateOptions,
      onSelectViewDate: this.onSelectViewDate,
      chartActionsProps,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <MarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  phaseIndex: studentSelectors.getPhaseIndex(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];

export { Mar as BaseMar };
export default compose(...enhancers)(Mar);

import convert from 'convert-units';
import produce from 'immer';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField, EmbeddedChart } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper, unitConverter } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { Section as SectionBallard, buildScaleRollUps } from 'components/features/chart/special-charts/ballard-maturational-assessment/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import NewbornAssessmentView from './NewbornAssessmentView';
import { Title } from '../shared/constants';

export interface NewbornAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
  enableMultiStepsAuthoring: Function;
}

interface NewbornAssessmentState {
  isSidebarOpen: boolean;
  embeddedChart: EmbeddedChart;
  sidebarKey: number;
  totalNeuromuscularMaturityScore: string;
  totalPhysicalMaturityScore: string;
  totalScore: string;
}

class NewbornAssessment extends Component<NewbornAssessmentProps, NewbornAssessmentState> {
  static displayName = 'NewbornAssessment';
  customFormFieldTitleMap: Map<string, string>;

  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
      sidebarKey: 0,
      embeddedChart: null,
      totalNeuromuscularMaturityScore: '0',
      totalPhysicalMaturityScore: '0',
      totalScore: '0'
    };
  }

  componentDidMount() {
    this.customFormFieldTitleMap = this.buildCustomTitle();
    this.props.initState(this.buildFormFields());
    this.props.enableMultiStepsAuthoring(true);
  }

  buildCustomTitle = (): Map<string, string> => {
    const customFormFieldTitleMap = new Map();
    customFormFieldTitleMap.set(FormField.INTEGUMENTARY_BIRTHMARKS_LOCATION, 'Birthmarks location/characteristics');
    customFormFieldTitleMap.set(FormField.INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION, 'Mongolian spots location/characteristics');
    customFormFieldTitleMap.set(FormField.NUTRITIONDELIVERYROOM_FORMULA_AMOUNT, 'Nutrition amount');
    customFormFieldTitleMap.set(FormField.HEADNECK_HEAD_MOLDING1, 'Head molding');
    customFormFieldTitleMap.set(FormField.EYES_PUPILS_OTHERBOX, 'Pupils other');
    customFormFieldTitleMap.set(FormField.EYES_MOUTH_OTHERBOX, 'Mouth other');
    customFormFieldTitleMap.set(FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL_OTHERBOX, 'Vessels other');

    return customFormFieldTitleMap;
  };

  temperatureGroupChange = (chartField: ChartMetaFormField): ChartMetaFormField[] => {
    if (chartField.value === '') {
      return [];
    }
    const { formFieldMap } = this.props;
    const fieldIds = [FormField.TEMPERATURE_FAHRENHEIT, FormField.TEMPERATURE_CELSIUS];
    return fieldIds.map((fieldId) => {
      if (chartField.name === fieldId) {
        return chartField;
      }

      let field = formFieldMap.get(fieldId);
      const num = parseFloat(chartField.value);
      if (!Number.isNaN(Number(num))) {
        field = produce(field, (draft) => {
          if (chartField.name === FormField.TEMPERATURE_FAHRENHEIT) {
            draft.value = unitConverter.convertFahrenheitToCelsius(chartField.value);
          } else {
            draft.value = unitConverter.convertCelsiusToFahrenheit(chartField.value);
          }
        });
      }

      return field;
    });
  };

  measurementGroupChange =
    (fieldIds: string[], measurementMapping: { [x: string]: string }) =>
    (chartField: ChartMetaFormField): ChartMetaFormField[] => {
      if (chartField.value === '') {
        return [];
      }
      const { formFieldMap } = this.props;
      return fieldIds.map((fieldId) => {
        if (chartField.name === fieldId) {
          return chartField;
        }

        let field = formFieldMap.get(fieldId);
        const num = parseFloat(chartField.value);
        if (!Number.isNaN(Number(num))) {
          field = produce(field, (draft) => {
            const value = convert(chartField.value).from(measurementMapping[chartField.name]).to(measurementMapping[field.name]);
            draft.value = appHelper.formatNumber(value).toString();
          });
        }

        return field;
      });
    };

  /* eslint-disable sonarjs/cognitive-complexity */
  weightGroupChange = (chartField: ChartMetaFormField): ChartMetaFormField[] => {
    const { formFieldMap } = this.props;
    let pound: number = formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_POUND).value ? Number(formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_POUND).value) : 0;
    let ounce: number = formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_OUNCE).value ? Number(formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_OUNCE).value) : 0;
    let gram: number = formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_GRAM).value ? Number(formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_GRAM).value) : 0;
    switch (chartField.name) {
      case FormField.MEASUREMENTS_WEIGHT_POUND:
      case FormField.MEASUREMENTS_WEIGHT_OUNCE: {
        if (pound || ounce) {
          let totalOunce = 0;
          if (pound) {
            totalOunce = convert(pound).from('lb').to('oz');
          }
          if (ounce) {
            totalOunce += ounce;
          }

          ounce = totalOunce % 16;
          pound = Math.floor(totalOunce / 16);
          gram = convert(totalOunce).from('oz').to('g');
        }
        break;
      }
      case FormField.MEASUREMENTS_WEIGHT_GRAM: {
        if (gram) {
          const totalOunce = convert(gram).from('g').to('oz');
          pound = Math.floor(totalOunce / 16);
          ounce = totalOunce % 16;
        }
        break;
      }
      default:
        break;
    }

    this.updateMapValue(formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_POUND), pound.toString());
    this.updateMapValue(formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_OUNCE), ounce.toFixed().toString());
    this.updateMapValue(formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_GRAM), gram.toFixed().toString());

    return [formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_POUND), formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_OUNCE), formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_GRAM)];
  };

  updateMapValue = (chartField: ChartMetaFormField, value: string) => {
    const { formFieldMap } = this.props;
    const newChartField = { ...chartField, value };
    formFieldMap.set(chartField.name, newChartField);
  };

  lengthGroupChange = () => {
    const fieldIds = [FormField.MEASUREMENTS_LENGTH_INCH, FormField.MEASUREMENTS_LENGTH_CENTIMETER];
    const mapping = {
      [FormField.MEASUREMENTS_LENGTH_INCH]: 'in',
      [FormField.MEASUREMENTS_LENGTH_CENTIMETER]: 'cm'
    };
    return this.measurementGroupChange(fieldIds, mapping);
  };

  buildFormFields = () => {
    const dataMap = new Map();
    const containers = [FormField.CONTAINER_GESTATIONAL_AGE_NEWBORN, FormField.CONTAINER_GESTATIONAL_AGE_FEMALE];
    const textBoxes = [
      { name: FormField.TEMPERATURE_FAHRENHEIT, label: FormFieldLabel.TEMPERATURE_FAHRENHEIT, onGroupChange: this.temperatureGroupChange },
      { name: FormField.TEMPERATURE_CELSIUS, label: FormFieldLabel.TEMPERATURE_CELSIUS, onGroupChange: this.temperatureGroupChange },
      { name: FormField.TEMPERATURE_PULSE, label: FormFieldLabel.TEMPERATURE_PULSE },
      { name: FormField.TEMPERATURE_RESPIRATION, label: FormFieldLabel.TEMPERATURE_RESPIRATION },
      { name: FormField.BLOOD_PRESSURE_SYSTOLIC, label: FormFieldLabel.BLOOD_PRESSURE_SYSTOLIC },
      { name: FormField.BLOOD_PRESSURE_DIALOSTIC, label: FormFieldLabel.BLOOD_PRESSURE_DIALOSTIC },
      { name: FormField.MEASUREMENTS_WEIGHT_POUND, label: FormFieldLabel.MEASUREMENTS_WEIGHT_POUND, onGroupChange: this.weightGroupChange },
      { name: FormField.MEASUREMENTS_WEIGHT_OUNCE, label: FormFieldLabel.MEASUREMENTS_WEIGHT_OUNCE, onGroupChange: this.weightGroupChange },
      { name: FormField.MEASUREMENTS_WEIGHT_GRAM, label: FormFieldLabel.MEASUREMENTS_WEIGHT_GRAM, onGroupChange: this.weightGroupChange },
      { name: FormField.MEASUREMENTS_LENGTH_INCH, label: FormFieldLabel.MEASUREMENTS_LENGTH_INCH, onGroupChange: this.lengthGroupChange() },
      { name: FormField.MEASUREMENTS_LENGTH_CENTIMETER, label: FormFieldLabel.MEASUREMENTS_LENGTH_CENTIMETER, onGroupChange: this.lengthGroupChange() },
      { name: FormField.MEASUREMENTS_HEAD, label: FormFieldLabel.MEASUREMENTS_HEAD },
      { name: FormField.MEASUREMENTS_CHEST, label: FormFieldLabel.MEASUREMENTS_CHEST },
      { name: FormField.NUTRITION_BREAST, label: FormFieldLabel.NUTRITION_BREAST },
      { name: FormField.NUTRITION_BOTTLE, label: FormFieldLabel.NUTRITION_BOTTLE },
      { name: FormField.NUTRITIONDELIVERYROOM_FORMULA_AMOUNT, label: FormFieldLabel.NUTRITIONDELIVERYROOM_FORMULA_AMOUNT },
      { name: FormField.INTEGUMENTARY_BIRTHMARKS_LOCATION, label: FormFieldLabel.INTEGUMENTARY_BIRTHMARKS_LOCATION },
      { name: FormField.INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION, label: FormFieldLabel.INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION },
      { name: FormField.INTEGUMENTARY_COLOR_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.HEADNECK_HEAD_OTHERBOX, label: FormFieldLabel.HEADNECK_HEAD_OTHERBOX },
      { name: FormField.EYES_MUCOUS_MEMBRANES_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.EYES_PUPILS_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.EYES_MOUTH_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.CHEST_SHAPE_DESCRIBE, label: FormFieldLabel.CHEST_SHAPE_DESCRIBE },
      { name: FormField.CHEST_HEART_RHYTHM_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.CHEST_NIPPLES_DESCRIBE, label: FormFieldLabel.CHEST_NIPPLES_DESCRIBE },
      { name: FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS_OTHERBOX, label: FormFieldLabel.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS_OTHERBOX },
      { name: FormField.SPINE_NEURO_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.GENITALIA_DISCHARGE_PRESENT_DESCRIBE, label: FormFieldLabel.GENITALIA_DISCHARGE_PRESENT_DESCRIBE },
      { name: FormField.MUSCULOSKELETAL_UPPER_RANGE_MOTION_DESCRIBE, label: FormFieldLabel.MUSCULOSKELETAL_UPPER_RANGE_MOTION_DESCRIBE },
      { name: FormField.MUSCULOSKELETAL_UPPER_DIGITS_OTHERBOX, label: FormFieldLabel.OTHER },
      { name: FormField.MUSCULOSKELETAL_LOWER_RANGE_MOTION_DESCRIBE, label: FormFieldLabel.MUSCULOSKELETAL_LOWER_RANGE_MOTION_DESCRIBE },
      { name: FormField.MUSCULOSKELETAL_LOWER_DIGITS_OTHERBOX, label: FormFieldLabel.OTHER }
    ];
    const radios = [
      { name: FormField.NUTRITION_QUALITY, label: FormFieldLabel.NUTRITION_QUALITY },
      { name: FormField.NUTRITIONDELIVERYROOM, label: FormFieldLabel.NUTRITIONDELIVERYROOM },
      { name: FormField.ELIMINATION_TYPE, label: FormFieldLabel.ELIMINATION_TYPE },
      { name: FormField.ELIMINATION_FIRST_VOID, label: FormFieldLabel.ELIMINATION_FIRST_VOID },
      { name: FormField.ELIMINATION_FIRST_STOOL, label: FormFieldLabel.ELIMINATION_FIRST_STOOL },
      { name: FormField.INTEGUMENTARY_BIRTHMARKS, label: FormFieldLabel.INTEGUMENTARY_BIRTHMARKS },
      { name: FormField.INTEGUMENTARY_MONGOLIAN_SPOTS, label: FormFieldLabel.INTEGUMENTARY_MONGOLIAN_SPOTS },
      { name: FormField.ACTIVITY_CRY, label: FormFieldLabel.ACTIVITY_CRY },
      { name: FormField.ACTIVITY_TONE, label: FormFieldLabel.ACTIVITY_TONE },
      { name: FormField.ACTIVITY_SLEEP_WAKE, label: FormFieldLabel.ACTIVITY_SLEEP_WAKE },
      { name: FormField.HEADNECK_HEAD_MOLDING1, label: '' },
      { name: FormField.HEADNECK_FACIAL_MOVEMENT, label: FormFieldLabel.HEADNECK_FACIAL_MOVEMENT },
      { name: FormField.EYES_DISCHARGE, label: FormFieldLabel.EYES_DISCHARGE },
      { name: FormField.EYES_NARES, label: FormFieldLabel.EYES_NARES },
      { name: FormField.EYES_PUPILS, label: FormFieldLabel.EYES_PUPILS },
      { name: FormField.EYES_POSITION_EARS, label: FormFieldLabel.EYES_POSITION_EARS },
      { name: FormField.EYES_MOUTH, label: FormFieldLabel.EYES_MOUTH },
      { name: FormField.CHEST_SHAPE, label: FormFieldLabel.CHEST_SHAPE },
      { name: FormField.ABDOMEN_GASTRO_BOWEL_SOUNDS, label: FormFieldLabel.ABDOMEN_GASTRO_BOWEL_SOUNDS },
      { name: FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL, label: FormFieldLabel.ABDOMEN_GASTRO_UMBILICALCORDVESSEL },
      { name: FormField.GENITALIA_SEX, label: FormFieldLabel.GENITALIA_SEX },
      { name: FormField.GENITALIA_TESTES_DESCENDED, label: FormFieldLabel.GENITALIA_TESTES_DESCENDED },
      { name: FormField.GENITALIA_URETHRA_PENIS, label: FormFieldLabel.GENITALIA_URETHRA_PENIS },
      { name: FormField.GENITALIA_VAGINAL_OPENING, label: '' },
      { name: FormField.GENITALIA_DISCHARGE_PRESENT, label: FormFieldLabel.GENITALIA_DISCHARGE_PRESENT },
      { name: FormField.GENITALIA_ANUS, label: FormFieldLabel.GENITALIA_ANUS },
      { name: FormField.MUSCULOSKELETAL_UPPER_RANGE_MOTION, label: FormFieldLabel.MUSCULOSKELETAL_UPPER_RANGE_MOTION },
      { name: FormField.MUSCULOSKELETAL_UPPER_CLAVICLES, label: FormFieldLabel.MUSCULOSKELETAL_UPPER_CLAVICLES },
      { name: FormField.MUSCULOSKELETAL_LOWER_RANGE_MOTION, label: FormFieldLabel.MUSCULOSKELETAL_LOWER_RANGE_MOTION },
      { name: FormField.MUSCULOSKELETAL_LOWER_HIPS, label: FormFieldLabel.MUSCULOSKELETAL_LOWER_HIPS }
    ];
    const checkboxes = [
      { name: FormField.NO_ASSESSMENT_REQUIRED, label: '' },
      { name: FormField.ELIMINATION_VOID, label: '', parentLabel: FormFieldLabel.ELIMINATION_VOID },
      { name: FormField.ELIMINATION_STOOL, label: '', parentLabel: FormFieldLabel.ELIMINATION_STOOL },
      { name: FormField.INTEGUMENTARY_CHARACTERISTICS, label: FormFieldLabel.INTEGUMENTARY_CHARACTERISTICS },
      { name: FormField.INTEGUMENTARY_TURGOR, label: FormFieldLabel.INTEGUMENTARY_TURGOR },
      { name: FormField.ACTIVITY_MOVEMENT, label: FormFieldLabel.ACTIVITY_MOVEMENT },
      { name: FormField.HEADNECK_HEAD_MOLDING2, label: '' },
      { name: FormField.EYES_POSITION_SPACING, label: FormFieldLabel.EYES_POSITION_SPACING },
      { name: FormField.EYES_MUCOUS_MEMBRANES, label: FormFieldLabel.EYES_MUCOUS_MEMBRANES },
      { name: FormField.CHEST_HEART_RHYTHM, label: FormFieldLabel.CHEST_HEART_RHYTHM },
      { name: FormField.CHEST_NIPPLES, label: FormFieldLabel.CHEST_NIPPLES },
      { name: FormField.CHEST_RESPIRATORY_EFFORT, label: FormFieldLabel.CHEST_RESPIRATORY_EFFORT },
      { name: FormField.ABDOMEN_GASTRO_SHAPE, label: FormFieldLabel.ABDOMEN_GASTRO_SHAPE },
      { name: FormField.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS, label: FormFieldLabel.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS },
      { name: FormField.SPINE_NEURO, label: FormFieldLabel.SPINE_NEURO },
      { name: FormField.GENITALIA_ANUSMECONIUM, label: '' },
      { name: FormField.MUSCULOSKELETAL_UPPER_DIGITS, label: FormFieldLabel.MUSCULOSKELETAL_UPPER_DIGITS },
      { name: FormField.MUSCULOSKELETAL_UPPER_CLAVICLES_CREPITUS, label: '' },
      { name: FormField.MUSCULOSKELETAL_LOWER_DIGITS, label: FormFieldLabel.MUSCULOSKELETAL_LOWER_DIGITS }
    ];
    const multiselectDropdowns = [
      { name: FormField.NUTRITIONDELIVERYROOM_FORMULA_TYPE, label: FormFieldLabel.NUTRITIONDELIVERYROOM_FORMULA_TYPE },
      { name: FormField.INTEGUMENTARY_COLOR, label: FormFieldLabel.INTEGUMENTARY_COLOR },
      { name: FormField.HEADNECK_HEAD, label: FormFieldLabel.HEADNECK_HEAD },
      { name: FormField.REFLEXES, label: FormFieldLabel.REFLEXES }
    ];

    containers.forEach((name) => dataMap.set(name, chartHelper.createFormField({ name, type: FormFieldInputType.CONTAINER })));
    textBoxes.forEach((item) => {
      dataMap.set(item.name, chartHelper.createFormField({ name: item.name, type: FormFieldInputType.TEXT_BOX, label: item.label, onGroupChange: item.onGroupChange }));
    });
    radios.forEach((item) => {
      dataMap.set(item.name, chartHelper.createFormField({ name: item.name, type: FormFieldInputType.RADIO_CHOICE, label: item.label }));
    });
    checkboxes.forEach((item) => {
      dataMap.set(item.name, chartHelper.createFormField({ name: item.name, type: FormFieldInputType.CHECK_BOX, label: item.label, parentLabel: item.parentLabel }));
    });
    multiselectDropdowns.forEach((item) => {
      dataMap.set(item.name, chartHelper.createFormField({ name: item.name, type: FormFieldInputType.MULTISELECT_DROPDOWN, label: item.label }));
    });

    dataMap.set(FormField.GENERAL_NOTES, chartHelper.createFormField({ name: FormField.GENERAL_NOTES, type: FormFieldInputType.TEXT_AREA, label: FormFieldLabel.GENERAL_NOTES }));

    return dataMap;
  };

  handleSaveClick = () => {
    // Save Ballard firstly and then Newborn
    let beforeSave = null;
    if (this.state.embeddedChart) {
      const { payload, navId } = this.state.embeddedChart;
      beforeSave = () => this.props.saveChartData(payload, navId);
    }
    this.props.saveChart([this.buildFragment()], { beforeSave, afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { selectedNavId } = this.props;
    const record = {
      chartTitle: selectedNavId === NAV_ID.NEWBORN_ASSESSMENT ? Title.SYSTEM_ASSESSMENT : Title.SPECIAL_CHARTS,
      fragmentTitle: SectionTitle.NEWBORN_ASSESSMENT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    let linkedFragmentId = this.state.embeddedChart?.payload?.fragmentId;
    // To avoid grouping Newborn with Ballard
    linkedFragmentId = `${selectedNavId}_${linkedFragmentId}`;
    return { ...basicInfo, linkedFragmentId, chartData: cleanRecord } as ChartFragment;
  };

  createSections = (): Section[] => {
    const { customFormFieldTitleMap } = this;
    const { formFieldMap } = this.props;
    const payload = this.state.embeddedChart?.payload;
    const sectionTotalNeuromuscularMaturityScore = payload?.chartData.records.find((item) => item.sectionTitle === SectionBallard.TOTAL_NEUROMUSCULAR_MATURITY_SCORE);
    const sectionTotalPhysicalMaturityScore = payload?.chartData.records.find((item) => item.sectionTitle === SectionBallard.TOTAL_PHYSICAL_MATURITY_SCORE);

    let result = [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.NEWBORN_ASSESSMENT,
        fields: [FormField.NO_ASSESSMENT_REQUIRED],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.TEMPERATURE,
        fields: [FormField.TEMPERATURE_FAHRENHEIT, FormField.TEMPERATURE_CELSIUS, FormField.TEMPERATURE_PULSE, FormField.TEMPERATURE_RESPIRATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.BLOOD_PRESSURE,
        fields: [FormField.BLOOD_PRESSURE_SYSTOLIC, FormField.BLOOD_PRESSURE_DIALOSTIC],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.MEASUREMENTS,
        fields: [
          FormField.MEASUREMENTS_WEIGHT_POUND,
          FormField.MEASUREMENTS_WEIGHT_OUNCE,
          FormField.MEASUREMENTS_WEIGHT_GRAM,
          FormField.MEASUREMENTS_LENGTH_INCH,
          FormField.MEASUREMENTS_LENGTH_CENTIMETER,
          FormField.MEASUREMENTS_HEAD,
          FormField.MEASUREMENTS_CHEST
        ],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.GESTATIONAL_AGE,
        sectionTitle: SectionTitle.NUTRITION,
        fields: [
          FormField.NUTRITION_BREAST,
          FormField.NUTRITION_QUALITY,
          FormField.NUTRITION_BOTTLE,
          FormField.NUTRITIONDELIVERYROOM,
          FormField.NUTRITIONDELIVERYROOM_FORMULA_AMOUNT,
          FormField.NUTRITIONDELIVERYROOM_FORMULA_TYPE
        ],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.GESTATIONAL_AGE,
        sectionTitle: SectionTitle.ELIMINATION,
        fields: [FormField.ELIMINATION_VOID, FormField.ELIMINATION_STOOL, FormField.ELIMINATION_FIRST_VOID, FormField.ELIMINATION_FIRST_STOOL, FormField.ELIMINATION_TYPE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.INTEGUMENTARY,
        fields: [FormField.INTEGUMENTARY_COLOR, FormField.INTEGUMENTARY_COLOR_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.INTEGUMENTARY,
        fields: [
          FormField.INTEGUMENTARY_BIRTHMARKS,
          FormField.INTEGUMENTARY_BIRTHMARKS_LOCATION,
          FormField.INTEGUMENTARY_MONGOLIAN_SPOTS,
          FormField.INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION
        ],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.INTEGUMENTARY,
        sectionTitle: FormFieldLabel.INTEGUMENTARY_CHARACTERISTICS,
        fields: [FormField.INTEGUMENTARY_CHARACTERISTICS],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.INTEGUMENTARY,
        sectionTitle: FormFieldLabel.INTEGUMENTARY_TURGOR,
        fields: [FormField.INTEGUMENTARY_TURGOR],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.GENERAL_ACTIVITY,
        sectionTitle: FormFieldLabel.ACTIVITY_MOVEMENT,
        fields: [FormField.ACTIVITY_MOVEMENT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.GENERAL_ACTIVITY,
        fields: [FormField.ACTIVITY_TONE, FormField.ACTIVITY_CRY, FormField.ACTIVITY_SLEEP_WAKE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.HEAD_NECK,
        fields: [FormField.HEADNECK_HEAD, FormField.HEADNECK_HEAD_OTHERBOX, FormField.HEADNECK_FACIAL_MOVEMENT],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.HEAD_NECK,
        sectionTitle: 'Molding',
        fields: [FormField.HEADNECK_HEAD_MOLDING1, FormField.HEADNECK_HEAD_MOLDING2],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.EYES,
        fields: [
          FormField.EYES_DISCHARGE,
          FormField.EYES_NARES,
          FormField.EYES_PUPILS,
          FormField.EYES_PUPILS_OTHERBOX,
          FormField.EYES_POSITION_EARS,
          FormField.EYES_MOUTH,
          FormField.EYES_MOUTH_OTHERBOX
        ],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.EYES,
        sectionTitle: FormFieldLabel.EYES_POSITION_SPACING,
        fields: [FormField.EYES_POSITION_SPACING],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.EYES,
        sectionTitle: FormFieldLabel.EYES_MUCOUS_MEMBRANES,
        fields: [FormField.EYES_MUCOUS_MEMBRANES, FormField.EYES_MUCOUS_MEMBRANES_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.CHEST_RESPIRATORY,
        sectionTitle: FormFieldLabel.CHEST_SHAPE,
        fields: [FormField.CHEST_SHAPE, FormField.CHEST_SHAPE_DESCRIBE],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.CHEST_RESPIRATORY,
        sectionTitle: FormFieldLabel.CHEST_HEART_RHYTHM,
        fields: [FormField.CHEST_HEART_RHYTHM, FormField.CHEST_HEART_RHYTHM_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.CHEST_RESPIRATORY,
        sectionTitle: FormFieldLabel.CHEST_NIPPLES,
        fields: [FormField.CHEST_NIPPLES, FormField.CHEST_NIPPLES_DESCRIBE],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.CHEST_RESPIRATORY,
        sectionTitle: FormFieldLabel.CHEST_RESPIRATORY_EFFORT,
        fields: [FormField.CHEST_RESPIRATORY_EFFORT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ABDOMEN_GASTROINTESTINAL,
        fields: [FormField.ABDOMEN_GASTRO_BOWEL_SOUNDS, FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL, FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL_OTHERBOX],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.ABDOMEN_GASTROINTESTINAL,
        sectionTitle: FormFieldLabel.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS,
        fields: [FormField.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS, FormField.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.ABDOMEN_GASTROINTESTINAL,
        sectionTitle: FormFieldLabel.ABDOMEN_GASTRO_SHAPE,
        fields: [FormField.ABDOMEN_GASTRO_SHAPE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SPINE_NEUROLOGICAL,
        fields: [FormField.SPINE_NEURO, FormField.SPINE_NEURO_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.GENITALIA,
        fields: [
          FormField.GENITALIA_SEX,
          FormField.GENITALIA_TESTES_DESCENDED,
          FormField.GENITALIA_URETHRA_PENIS,
          FormField.GENITALIA_VAGINAL_OPENING,
          FormField.GENITALIA_DISCHARGE_PRESENT,
          FormField.GENITALIA_DISCHARGE_PRESENT_DESCRIBE,
          FormField.GENITALIA_ANUS,
          FormField.GENITALIA_ANUSMECONIUM
        ],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.MUSCULOSKELETAL,
        sectionTitle: SectionTitle.UPPER_EXTREMITIES,
        fields: [FormField.MUSCULOSKELETAL_UPPER_RANGE_MOTION, FormField.MUSCULOSKELETAL_UPPER_RANGE_MOTION_DESCRIBE],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.MUSCULOSKELETAL,
        sectionTitle: `${SectionTitle.UPPER_EXTREMITIES} Clavicles`,
        fields: [FormField.MUSCULOSKELETAL_UPPER_CLAVICLES, FormField.MUSCULOSKELETAL_UPPER_CLAVICLES_CREPITUS],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.MUSCULOSKELETAL,
        sectionTitle: `${SectionTitle.UPPER_EXTREMITIES} Digits`,
        fields: [FormField.MUSCULOSKELETAL_UPPER_DIGITS, FormField.MUSCULOSKELETAL_UPPER_DIGITS_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.MUSCULOSKELETAL,
        sectionTitle: SectionTitle.LOWER_EXTREMITIES,
        fields: [FormField.MUSCULOSKELETAL_LOWER_RANGE_MOTION, FormField.MUSCULOSKELETAL_LOWER_RANGE_MOTION_DESCRIBE, FormField.MUSCULOSKELETAL_LOWER_HIPS],
        formFieldMap
      }),
      chartHelper.buildSection({
        parentSectionTitle: SectionTitle.MUSCULOSKELETAL,
        sectionTitle: `${SectionTitle.LOWER_EXTREMITIES} Digits`,
        fields: [FormField.MUSCULOSKELETAL_LOWER_DIGITS, FormField.MUSCULOSKELETAL_LOWER_DIGITS_OTHERBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.REFLEXES,
        fields: [FormField.REFLEXES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: FormFieldLabel.GENERAL_NOTES,
        fields: [FormField.GENERAL_NOTES],
        formFieldMap
      })
    ];
    if (sectionTotalNeuromuscularMaturityScore && sectionTotalPhysicalMaturityScore) {
      result = [...result, sectionTotalNeuromuscularMaturityScore, sectionTotalPhysicalMaturityScore];
    }
    return result;
  };

  handleBallardClick = () => {
    if (!this.props.formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED).value) {
      this.setState({ isSidebarOpen: true });
    }
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebarOpen: false });
  };

  handleDiscardEmbeddedChart = () => {
    this.setState((prevState) => ({
      sidebarKey: prevState.sidebarKey,
      embeddedChart: null,
      totalNeuromuscularMaturityScore: '0',
      totalPhysicalMaturityScore: '0',
      totalScore: '0'
    }));
  };

  handleSaveEmbeddedChart = (payload: ChartFragment, navId: string) => {
    const totalNeuromuscularMaturityScore = chartHelper.getScaleContent(payload, SectionBallard.TOTAL_NEUROMUSCULAR_MATURITY_SCORE)[0].score;
    const totalPhysicalMaturityScore = chartHelper.getScaleContent(payload, SectionBallard.TOTAL_PHYSICAL_MATURITY_SCORE)[0].score;
    const total = totalNeuromuscularMaturityScore || totalPhysicalMaturityScore ? Number(totalNeuromuscularMaturityScore) + Number(totalPhysicalMaturityScore) : NaN;
    const scaleRollUps = buildScaleRollUps(total);

    this.setState({
      embeddedChart: {
        payload,
        navId
      },
      totalNeuromuscularMaturityScore,
      totalPhysicalMaturityScore,
      totalScore: scaleRollUps.find((item) => item.highlight)?.content || '0'
    });
    this.setState({ isSidebarOpen: false });
  };

  handleNoAssessmentRequired = (_, curOpt) => {
    if (!curOpt.selected) {
      // If no assessment required checkbox is going to be ticked
      this.handleDiscardEmbeddedChart();
    }
  };

  render() {
    const chartActionsProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(this.handleDiscardEmbeddedChart, this.buildFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      isSidebarOpen: this.state.isSidebarOpen,
      sidebarKey: this.state.sidebarKey,
      totalNeuromuscularMaturityScore: this.state.totalNeuromuscularMaturityScore,
      totalPhysicalMaturityScore: this.state.totalPhysicalMaturityScore,
      totalScore: this.state.totalScore,
      handleBallardClick: this.handleBallardClick,
      handleCloseSidebar: this.handleCloseSidebar,
      onSaveEmbeddedChart: this.handleSaveEmbeddedChart,
      onDiscardEmbeddedChart: this.handleDiscardEmbeddedChart,
      onNoAssessmentRequiredChange: this.handleNoAssessmentRequired,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <NewbornAssessmentView {...viewProps} />;
  }
}

export { NewbornAssessment as BaseNewbornAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(NewbornAssessment);

import cx from 'classnames';
import { Component } from 'react';
import { VitalSignsRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { convertFahrenheitToCelsius } from 'helpers/unit.converter';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import './vital.signs.history.scss';

export interface VitalSignsHistoryProps {
  chartHistory: VitalSignsRecord[];
  deleteHistory?: Function;
  title?: string;
  showDeleteColumn?: boolean;
  locale?: Locales;
}

class VitalSignsHistory extends Component<VitalSignsHistoryProps> {
  lookupDiastolicPosition = (value: string): string => {
    const diastolicPositionContent = this.props.chartHistory.find((content) => content.bloodPressure?.diastolic.choice === value);
    return diastolicPositionContent?.bloodPressure.diastolic.choice || '';
  };

  tempRenderer = ({ temperature, active }) => {
    const { value = null } = temperature || {};
    return <div className={cx({ 'row--deleted': !active })}>{value === null ? '' : `${value}F / ${convertFahrenheitToCelsius(value.toString())}C`}</div>;
  };

  respirationRenderer = ({ respiration, active }) => {
    const { value } = respiration || {};
    return <div className={cx({ 'row--deleted': !active })}>{value}</div>;
  };

  pulseRenderer = ({ pulse, active }) => {
    const { value } = pulse || {};
    return <div className={cx({ 'row--deleted': !active })}>{value}</div>;
  };

  bpRenderer = ({ bloodPressure, active }) => {
    const { systolic = {}, diastolic = {} } = bloodPressure || {};
    const value = systolic.value && diastolic.value ? `${systolic.value}/${diastolic.value}` : '';
    return (
      <div className={cx({ 'row--deleted': !active })}>
        {value}
        {diastolic.choice && (
          <>
            <br />
            {`(${this.lookupDiastolicPosition(diastolic.choice)})`}
          </>
        )}
      </div>
    );
  };

  saturationRenderer = ({ oxygenation, active }) => {
    const { value } = oxygenation?.saturation || {};
    return <div className={cx({ 'row--deleted': !active })}>{value ? `${value}%` : ''}</div>;
  };

  entryByRenderer = (record) => <EntryBy record={record} />;

  tableHeader = () => {
    const { locale } = this.props;
    const enUSTableHeaderOrder = [
      { text: 'Temp', field: 'temperature', align: ColumnAlign.Right, customRender: this.tempRenderer },
      { text: 'Resp', field: 'respiration', align: ColumnAlign.Right, customRender: this.respirationRenderer },
      { text: 'Pulse', field: 'pulse', align: ColumnAlign.Right, customRender: this.pulseRenderer },
      { text: 'BP', field: 'bloodPressure', align: ColumnAlign.Right, customRender: this.bpRenderer },
      { text: 'Sat%', field: 'saturation', align: ColumnAlign.Right, customRender: this.saturationRenderer }
    ];
    const enAUTableHeaderOrder = [
      { text: 'Resp', field: 'respiration', align: ColumnAlign.Right, customRender: this.respirationRenderer },
      { text: 'Sat%', field: 'saturation', align: ColumnAlign.Right, customRender: this.saturationRenderer },
      { text: 'Pulse', field: 'pulse', align: ColumnAlign.Right, customRender: this.pulseRenderer },
      { text: 'BP', field: 'bloodPressure', align: ColumnAlign.Right, customRender: this.bpRenderer },
      { text: 'Temp', field: 'temperature', align: ColumnAlign.Right, customRender: this.tempRenderer }
    ];
    return [
      { text: 'Chart Time', field: 'chartingAt' },
      ...(locale === Locales.EN_AU ? enAUTableHeaderOrder : enUSTableHeaderOrder),
      { text: 'Notes', field: 'notes' },
      { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
      { field: 'actionDelete' }
    ];
  };

  render() {
    const dataSource = {
      title: this.props.title ?? 'Chart History',
      headers: this.tableHeader(),
      css: ['column__chart-time', '', '', '', '', '', 'column__note', 'column__entry-by', 'column__delete'],
      data: this.props.chartHistory
    };
    return (
      <Box mt className="vital-signs-history">
        <ChartHistory dataSource={dataSource} deleteRecord={this.props.deleteHistory} showDeleteColumn={this.props.showDeleteColumn ?? true} locale={this.props.locale} />
      </Box>
    );
  }
}

export default VitalSignsHistory;

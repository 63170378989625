import { useEffect, useState } from 'react';
import { apiHelper, chartHelper } from 'helpers';
import { assignmentService } from 'services';
import { ELSIcon, ELSInlineLoader } from 'components/common/els';
import './clinical.key.scss';

export interface ClinicalKeyResourceCardProps {
  drugName: string;
}

export enum Label {
  TITLE = 'ClinicalKey for Nursing Resource'
}

const ClinicalKeyResourceCard = (props: ClinicalKeyResourceCardProps) => {
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.drugName) {
      setIsLoading(true);
      assignmentService
        .getDrugInfoURL(chartHelper.filterDrugByFirstName(props.drugName))
        .then((res) => {
          if (res.data) {
            setLink(res.data);
            setIsLoading(false);
          }
        })
        .catch((err) => apiHelper.showApiError(`can not load clinical pharmacology link ${err}`));
    }
  }, [props.drugName]);

  return (
    <div>
      {props.drugName && (
        <div className="c-els-card--no-padding c-els-card--background-standard c-els-card--size-x-small u-els-margin-top-1o2">
          <div className="c-els-card__content">
            <div className="c-els-card__content-title">
              <p>{Label.TITLE}</p>
            </div>
            <div className="u-els-margin-top-1o2">
              {isLoading ? (
                <ELSInlineLoader />
              ) : (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <span className="u-els-hide-visually">Open a new tab</span>
                  <span>{props.drugName}</span>
                  <div className="u-els-display-inline-block u-els-margin-left-1o2">
                    <ELSIcon prefix="hmds" name="new-window" color="secondary" title="" size="1x" />
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ClinicalKeyResourceCard.displayName = 'ClinicalKeyResourceCard';
export default ClinicalKeyResourceCard;

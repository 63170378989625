import cx from 'classnames';
import { Component } from 'react';
import { User } from 'models/ui';
import { defaultCustomRender } from 'helpers/chartRender.helper';
import { ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import { FormField } from './constants';

export interface AllergiesChartHistory {
  active: boolean;
  id: string;
  chartingAt: string;
  creator: User;
  modifier: User;
  [x: string]: string | any;
}

interface AllergiesHistoryProps {
  chartHistory: AllergiesChartHistory[];
  deleteHistory: Function;
}

class AllergiesHistory extends Component<AllergiesHistoryProps> {
  static displayName = 'AllergiesHistory';

  renderType = (row) => {
    const displayValue = row[FormField.NO_KNOWN_ALLERGIES] || row[FormField.ALLERGY_TYPE];
    return <div className={cx({ 'row--deleted': !row.active, 'column__content--overflowed': row[FormField.NO_KNOWN_ALLERGIES] })}>{displayValue}</div>;
  };

  renderAllergen = (row) => {
    const displayValue = row[FormField.ALLERGEN_OTHER] || row[FormField.ALLERGEN] || row[FormField.ALLERGEN_MEDICATION_OTHER] || row[FormField.ALLERGEN_MEDICATION];
    return <div className={cx({ 'row--deleted': !row.active })}>{displayValue}</div>;
  };

  renderReactions = (row) => {
    const displayValue = [...row[FormField.REACTIONS], row[FormField.REACTIONS_OTHER]].filter((item) => item).join(', ');
    return <div className={cx({ 'row--deleted': !row.active })}>{displayValue}</div>;
  };

  renderInformant = (row) => {
    const displayValue = row[FormField.INFORMANT_OTHER] || row[FormField.INFORMANT];
    return <div className={cx({ 'row--deleted': !row.active })}>{displayValue}</div>;
  };

  renderEntryBy = (record) => <EntryBy record={record} />;

  render() {
    const dataSource = {
      title: 'Chart History',
      headers: [
        { text: 'Type', field: FormField.ALLERGY_TYPE, sortable: true, customRender: this.renderType },
        { text: 'Allergen', field: FormField.ALLERGEN, customRender: this.renderAllergen },
        { text: 'Reactions', field: FormField.REACTIONS, customRender: this.renderReactions },
        { text: 'Severity', field: FormField.SEVERITY, customRender: defaultCustomRender(FormField.SEVERITY) },
        { text: 'Informant', field: FormField.INFORMANT, customRender: this.renderInformant },
        { text: 'Confidence Level', field: FormField.CONFIDENCE_LEVEL, customRender: defaultCustomRender(FormField.CONFIDENCE_LEVEL) },
        { text: 'Entry By', field: 'entryBy', customRender: this.renderEntryBy },
        { field: 'actionDelete' }
      ],
      data: this.props.chartHistory
    };
    return <ChartHistory dataSource={dataSource} deleteRecord={this.props.deleteHistory} noTableDataMessage="No Known Allergies" />;
  }
}

export default AllergiesHistory;

import { HomeMedicationRecord } from 'models/api-response';
import { ChartActionsComponentProps } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import HomeMedicationHistory from './HomeMedicationHistory';
import './home-medication.scss';

export interface HomeMedicationViewProps extends ChartViewProps {
  chartHistory: HomeMedicationRecord[];
  deleteHistory: Function;
  isAdmissionHistoryChart?: boolean;
  homeMedicationActionProps?: ChartActionsComponentProps;
  locale: Locales;
}

const HomeMedicationView = (props: HomeMedicationViewProps) => {
  const { formFieldMap, formSubmittedCount, chartHistory, deleteHistory, isAdmissionHistoryChart, homeMedicationActionProps, locale } = props;
  return (
    <div className="home-medication">
      <h1>{SectionTitle.HOME_MEDICATION_INFORMATION}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ELSFlex className="u-flex-gutter-3x u-els-margin-top-2x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <h2 className="u-els-margin-bottom-2x">Chart Inputs</h2>
          <h3 className="u-els-margin-bottom">{SectionTitle.INFORMATION_RECEIVED_FROM}</h3>
          <ErrorFormField formField={formFieldMap.get(FormField.INFORMATION_RECEIVED_FROM)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.INFORMATION_RECEIVED_FROM_ORDER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.HOME_MEDICATION_TYPE)} formSubmittedCount={formSubmittedCount} />
          <h4 className="u-els-margin-bottom">{SectionTitle.PRODUCT_DETAILS}</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.MEDICATION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.DOSE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.FORM)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ROUTE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.FREQUENCY)} formSubmittedCount={formSubmittedCount} />
          <ELSFlex className="u-flex-gutter-3x" left wrap>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <ErrorFormField formField={formFieldMap.get(FormField.LAST_DOSE_DATE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <ErrorFormField formField={formFieldMap.get(FormField.LAST_DOSE_TIME)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ErrorFormField formField={formFieldMap.get(FormField.INDICATION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.BRAND_NAME)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.GENERIC_NAME)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.PRODUCT_TYPE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.OVER_THE_COUNTER_PRODUCT_FORM)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.OVER_THE_COUNTER_PRODUCT_DOSE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField
            formField={formFieldMap.get(FormField.OVER_THE_COUNTER_PRODUCT_TAKEN_IN_LAST_24HOURS)}
            options={{ displayHorizontal: true }}
            formSubmittedCount={formSubmittedCount}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.OVER_THE_COUNTER_PRODUCT_REASON_FOR_TAKING_PRODUCT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.OVER_THE_COUNTER_PRODUCT_RESULT_FOLLOWING_USE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.PRODUCT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.PREPARATION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_DOSE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_ROUTE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.STRENGTH)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField
            formField={formFieldMap.get(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_TAKEN_IN_LAST_24HOURS)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_REASON_FOR_TAKING_PRODUCT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_RESULT_FOLLOWING_USE)} formSubmittedCount={formSubmittedCount} />
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <ErrorFormField
              useDefaultMargin={false}
              className="input-time-field u-els-margin-bottom-1x1o2"
              formField={formFieldMap.get(FormField.MEDICATION_TIME_OFFSET)}
              formSubmittedCount={formSubmittedCount}
            />
          </ELSFlexItem>
        </ELSFlexItem>
        <ELSFlexItem w="2o3" md="1o1">
          <h2 className="u-els-margin-bottom-2x">Chart History</h2>
          <HomeMedicationHistory chartHistory={chartHistory} deleteHistory={deleteHistory} locale={locale} />
        </ELSFlexItem>
      </ELSFlex>
      {isAdmissionHistoryChart && (
        <>
          <ChartPermission key="add-home-medication">{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...homeMedicationActionProps} />}</ChartPermission>
          <SectionDivider />
        </>
      )}
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

export default HomeMedicationView;

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import PlanningHospitalStayReadOnly from './PlanningHospitalStayReadOnly';
import './planning.hospital.stay.scss';

interface PlanningHospitalStayViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  resetAll?: number;
  patientContext: PatientContext;
}
const fieldOptions = { displayHorizontal: true };
const PlanningHospitalStayView = (props: PlanningHospitalStayViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, formFieldMap, patientContext } = props;
  return (
    <div className="planning-hospital-stay">
      {isLocked && <PlanningHospitalStayReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={patientContext} />}
      {!isLocked && (
        <>
          <h1>Planning for Hospital Stay</h1>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />

          <Box mt2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.DURING_HOSPITALIZATION)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                <ErrorFormField formField={formFieldMap.get(FormField.DURING_HOSPITALIZATION_NAME)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.COMMUNICATE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.COMMUNICATE_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.PROFESSION_OCCUPATION)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.PROBLEM_EXISTED)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.PROBLEM_EXISTED_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.ADMITTED_HOSPITAL)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CIRCUMSTANCE_ADMITTED_HOSPITAL)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.CONTACT_PERSON)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                <ErrorFormField formField={formFieldMap.get(FormField.CONTACT_PERSON_NAME)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.CONTACT_PERSON_PHONE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.HEALTH_PROBLEM_HOME)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex className="u-flex-gutter-3x planning-hospital-stay__sections-container" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.BARRIERS_LEARNING)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                <h4>{SectionTitle.PROBLEM}</h4>
                <ErrorFormField key={props.resetAll} formField={formFieldMap.get(FormField.BARRIERS_LEARNING_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>

              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.CAN_READ)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                <ErrorFormField formField={formFieldMap.get(FormField.CAN_WRITE)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.PRIMARY_LANGUAGE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.PRIMARY_LANGUAGE_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.PRIMARY_LANGUAGE_NEED_TRANSLATOR)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.HAVE_LIVING)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                <ErrorFormField formField={formFieldMap.get(FormField.LIVING_AVAILABLE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.PREFER_INSTRUCTIONS)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.PREFER_INSTRUCTIONS_OTHER)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.VISITOR_RESTRICTIONS)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.EDUCATION_LEVEL)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={formFieldMap.get(FormField.EDUCATION_LEVEL_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>

            <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_QUESTION_PARENT)} formSubmittedCount={formSubmittedCount}>
              <SectionDivider />
              <Box mb2>
                <h2>{SectionTitle.QUESTION_PARENT}</h2>
              </Box>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_NEED_TRANSLATOR)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_PREFER_INSTRUCTIONS_OTHER)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_EDUCATION_LEVEL)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_EDUCATION_LEVEL_OTHER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_BARRIERS_LEARNING)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                  <h4>{SectionTitle.PROBLEM}</h4>
                  <ErrorFormField formField={formFieldMap.get(FormField.QUESTION_PARENT_BARRIERS_LEARNING_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </ErrorFormField>

            <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_WOMEN_HEALTH)} formSubmittedCount={formSubmittedCount}>
              <SectionDivider />
              <Box mb2>
                <h2>{SectionTitle.PLANNING_FOR_LABOR}</h2>
              </Box>
              <ErrorFormField formField={formFieldMap.get(FormField.ATTEND_PRENATAL_CLASSES)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
              <SectionDivider />
              <Box mb2>
                <h2>{SectionTitle.PLANS_FOR_BIRTH}</h2>
              </Box>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.NAMES_PERSONS_TO_BE_PRESENT)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.DOCUMENTED_BIRTH_PLAN)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                  <ErrorFormField formField={formFieldMap.get(FormField.TUBAL_LIGATION_PLANNED)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                  <ErrorFormField formField={formFieldMap.get(FormField.PLANS_FOR_BIRTH_CONSENT_SIGNED)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.ANESTHESIA_DESIRED)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.SPECIFIC_REQUESTS_DURING_LABOR)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <SectionDivider />
              <Box mb2>
                <h2>{SectionTitle.NEWBORN_PLANS}</h2>
              </Box>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.PEDIATRICIAN_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.CIRCUMCISION_PLANNED)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                  <ErrorFormField formField={formFieldMap.get(FormField.NEWBORN_PLANS_CONSENT_SIGNED)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.NEWBORN_NUTRITION)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </ErrorFormField>

            <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
          </Box>
        </>
      )}
    </div>
  );
};
export default PlanningHospitalStayView;

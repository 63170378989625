export enum SectionTitle {
  TIME_SPENT = 'Time Spent',
  STUDENT_GRADING_PROGRESS = 'Student grading progress'
}

export enum Label {
  TOTAL_TIME_REPRESENTS = 'Total time represents the time from when student started the assignment to time when the student completed the assignment.',
  START_DATE = 'Start Date',
  DUE_DATE = 'Due Date',
  GRADING = 'Grading',
  STUDENTS_SUBMITTED = 'Students Submitted'
}

export enum FormFieldLabel {
  OVERALL_COMMENTS = 'Overall Comments'
}

export const PaginatorA11y = {
  name: 'Search Results Paginator',
  pagingUnitName: 'Page',
  morePagingUnit: 'Pages'
};

export enum SortField {
  NAME = 'name',
  FEEDBACK = 'feedback'
}

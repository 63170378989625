export enum SectionTitle {
  PEDIATRIC_GLASGOW_COMA_SCALE = 'Pediatric Glasgow Coma Scale',
  COMA_SCALE_TOTAL = 'Coma scale total',
  PUPILS = 'Pupils',
  LEFT = 'Left',
  RIGHT = 'Right',
  EYES_OPEN = 'Eyes open',
  BEST_MOTOR_RESPONSE = 'Best Motor Response',
  BEST_RESPONSE_AUDITORY_VISUAL_STIMULUS = 'Best Response to Auditory and/or Visual Stimulus',
  SCORING = 'Scoring',
  MOTOR_RESPONSES = 'Motor responses'
}

export enum FormField {
  PUPILS_LEFT_SIZE = 'pupilsLeftSize',
  PUPILS_RIGHT_SIZE = 'pupilsRightSize',
  PUPILS_LEFT_REACTION = 'pupilsLeftReaction',
  PUPILS_RIGHT_REACTION = 'pupilsRightReaction',

  PEDIATRIC_GLASGOW_COMA_SCALE = 'comaScaleTotal',
  EYES_OPEN = 'eyesOpen',
  BEST_MOTOR_RESPONSE = 'bestMotorResponse',
  BEST_MOTOR_RESPONSE_AUDITORY_VISUAL_STIMULUS_QUESTION = 'bestResponseAuditoryVisualStimulusQuestion',

  MOTOR_RESPONSES_HAND_GRIP = 'motorResponsesHandGrip',
  MOTOR_RESPONSES_MUSCLE_TONE = 'motorResponsesMuscleTone',
  MOTOR_RESPONSES_MUSCLE_TONE_OTHER = 'motorResponsesMuscleToneOther',
  MOTOR_RESPONSES_FONTANEL_WINDOW = 'motorResponsesFontanelWindow',
  MOTOR_RESPONSES_FONTANEL_WINDOW_OTHER = 'motorResponsesFontanelWindowOther',
  MOTOR_RESPONSES_LEVEL_CONSCIOUSNESS = 'motorResponsesLevelConsciousness',
  MOTOR_RESPONSES_EYE_MOVEMENT = 'motorResponsesEyeMovement',
  MOTOR_RESPONSES_EYE_MOVEMENT_OTHER = 'motorResponsesEyeMovementOther',
  MOTOR_RESPONSES_MOOD_AFFECT = 'motorResponsesMoodAffect'
}

export enum FormFieldLabel {
  PUPILS_SIZE = 'Size:',
  PUPILS_REACTION = 'Reaction:',
  MOTOR_RESPONSES_HAND_GRIP = 'Hand grip:',
  MOTOR_RESPONSES_MUSCLE_TONE = 'Muscle tone:',
  MOTOR_RESPONSES_FONTANEL_WINDOW = 'Fontanel/window:',
  MOTOR_RESPONSES_LEVEL_CONSCIOUSNESS = 'Level of consciousness:',
  MOTOR_RESPONSES_EYE_MOVEMENT = 'Eye movement:',
  MOTOR_RESPONSES_MOOD_AFFECT = 'Mood/affect:',
  BEST_MOTOR_RESPONSE_AUDITORY_VISUAL_STIMULUS_QUESTION = 'Best Response to Auditory and/or Visual Stimulus',
  MOTOR_RESPONSES_MUSCLE_TONE_OTHER = '',
  MOTOR_RESPONSES_FONTANEL_WINDOW_OTHER = ' ',
  MOTOR_RESPONSES_EYE_MOVEMENT_OTHER = '  '
}

import { ReactNode } from 'react';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import './horizontal.scrollable.container.scss';

/**
 * some components having big width can not be scrollable because it can not determine parent width and upper
 * then it causes issue on small screen, e.g. on Ipad, the left navigation is hidden a part
 * this component make those component scrollable in horizontal
 */
interface HorizontalScrollableContainerProps {
  children: ReactNode;
}

const HorizontalScrollableContainer = (props: HorizontalScrollableContainerProps) => (
  <div className="h-scroll-container">
    <ELSFlex wrap>
      <ELSFlexItem grow className="h-scroll-container__outer">
        <div className="h-scroll-container__content">{props.children}</div>
      </ELSFlexItem>
    </ELSFlex>
  </div>
);

HorizontalScrollableContainer.displayName = 'HorizontalScrollableContainer';
export default HorizontalScrollableContainer;

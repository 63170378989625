import cx from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment } from 'models/api-response';
import { UserRole } from 'models/enum';
import { RoutePath } from 'constants/app.constant';
import { appSelectors } from 'redux/ducks/app';
import { Box, ButtonLinkIcon } from 'components/common';
import ChartComment from 'components/common/chart-actions/ChartComment';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import './grading.chart.scss';

export interface GradingChartProps {
  userRole: string;
  assessment: AssessmentRS;
  chartTitle: string;
  selectedFragment?: ChartFragment;
  selectedRecordChartPathName?: string;
  isInProgressGradingEnabled?: boolean;
}

export interface GradingChartState {
  showComment: boolean;
  hasChartComment: boolean;
  isFirstLoadChartComment: boolean;
}

class GradingChart extends Component<GradingChartProps & RouteComponentProps, GradingChartState> {
  static displayName = 'GradingChart';
  isInClinicalSetup: boolean;

  constructor(props) {
    super(props);
    this.state = {
      showComment: true,
      hasChartComment: false,
      isFirstLoadChartComment: true
    };
    this.isInClinicalSetup = this.props.location.pathname.toLowerCase().endsWith('/clinical-setup');
  }

  handleBackToSubmission = () => {
    const { eolsAssignmentId, studentId } = this.props.assessment;
    const path = `/instructor/assignment/${eolsAssignmentId}/grade/overview?student=${studentId}`;
    this.props.history.push(path);
  };

  handleBackToStudentSubmission = () => {
    const { eolsAssessmentId } = this.props.assessment;
    const path = RoutePath.student.assignmentSubmission.replace(':assessmentId', eolsAssessmentId.toString());
    this.props.history.push(path);
  };

  handleToggleChartComments = () => this.setState((prevState) => ({ showComment: !prevState.showComment }));

  isExcludedChart = () => {
    const excludedChartsList = [
      'patient-charting/system-assessment/',
      'patient-charting/system-nursing-intervention/',
      'patient-charting/special-charts/',
      'patient-charting/basic-nursing-care/',
      'patient-charting/wounds/',
      'patient-charting/ivs/',
      'patient-charting/drains-tubes/',
      'patient-charting/ostomies/',
      'patient-charting/injections/'
    ];
    return excludedChartsList.some((chart) => this.props.location.pathname.includes(chart));
  };

  shouldShowComment = () => !this.isExcludedChart() || !!this.props.selectedFragment;

  getHasChartComment = (hasChartComment: boolean, isFirstLoadChartComment: boolean) => {
    this.setState({ hasChartComment, isFirstLoadChartComment });
  };

  render() {
    const studentName = this.props.assessment?.eolsAssessmentId ? `${this.props.assessment?.firstName} ${this.props.assessment?.lastName}` : '';
    const { showComment, hasChartComment, isFirstLoadChartComment } = this.state;
    const { userRole, isInProgressGradingEnabled } = this.props;

    if (isInProgressGradingEnabled && UserRole.STUDENT === userRole && !hasChartComment && !isFirstLoadChartComment) {
      return null;
    }

    return (
      <div className="grading-chart">
        <div className="grading-chart__body">
          <ELSFlex>
            <ELSFlexItem className="u-els-width-1o3 u-els-padding-left-1o2" middle>
              {userRole === UserRole.INSTRUCTOR && (
                <ButtonLinkIcon
                  iconName="chevron-left"
                  iconSize="3o4"
                  className="u-els-font-size-meta"
                  text={`Back to Submission • ${studentName}`}
                  onClick={this.handleBackToSubmission}
                />
              )}
              {userRole === UserRole.STUDENT && (
                <ButtonLinkIcon iconName="chevron-left" iconSize="3o4" className="u-els-font-size-meta" text="Back to Submission" onClick={this.handleBackToStudentSubmission} />
              )}
            </ELSFlexItem>
            <ELSFlexItem className="u-els-width-1o3 u-els-text-center u-els-font-size-meta">
              {this.isInClinicalSetup ? 'Clinical Setup' : this.props.selectedRecordChartPathName || this.props.chartTitle}
            </ELSFlexItem>
            <ELSFlexItem className="u-els-width-1o3 u-els-padding-right-1o2 u-els-text-right">
              {this.shouldShowComment() && (
                <div className="u-els-display-inline-block">
                  <ButtonLinkIcon
                    className="u-els-font-size-meta"
                    preventAutoPending
                    text="Instructor Chart Comments"
                    iconName={showComment ? 'chevron-up' : 'chevron-down'}
                    iconSize="1x"
                    iconPosition="right"
                    onClick={this.handleToggleChartComments}
                  />
                </div>
              )}
            </ELSFlexItem>
          </ELSFlex>
          {this.shouldShowComment() && (
            <div className={cx('u-els-padding-left-1o2 u-els-padding-right-1o2', { 'u-els-display-none': !showComment })}>
              <Box mt mb className="c-els-divider" />
              <ChartComment selectedFragment={this.props.selectedFragment} getHasChartComment={this.getHasChartComment} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: appSelectors.getUserRole(state),
  assessment: appSelectors.getAssessment(state),
  chartTitle: appSelectors.getCurrentChartPathName(state)
});

export { GradingChart as BaseGradingChart };
export default compose(withRouter, connect(mapStateToProps))(GradingChart);

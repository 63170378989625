import { PageHeader, PageLoader, withHTMLHeadSEO } from 'components/common';
import AssignmentSubmission from 'components/features/assignment-submission/AssignmentSubmission';

const AssignmentSubmissionPage = () => (
  <div>
    <PageLoader />
    <PageHeader title="SimChart Assignment" />
    <AssignmentSubmission />
  </div>
);

export { AssignmentSubmissionPage as BaseAssignmentSubmissionPage };
export default withHTMLHeadSEO({ title: 'Assignment Submission' })(AssignmentSubmissionPage);

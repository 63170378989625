import cx from 'classnames';
import { ProgressNoteFragment } from 'models/api-response';
import { Locales } from 'constants/app.constant';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { AccordionBadge, AccordionTitle, Box, ChartPermission } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';

export interface ProgressNotesHistoryViewProps {
  chartHistory: ProgressNoteFragment[];
  onEdit: Function;
  onDelete: Function;
  locale: Locales;
}

const ProgressNotesHistoryView = (props: ProgressNotesHistoryViewProps) => (
  <div className="sng-accordion">
    {props.chartHistory?.length === 0 && <NothingToSee>Nothing to see, yet! Get started adding notes.</NothingToSee>}
    {props.chartHistory?.length > 0 && (
      <ELSAccordion id="progressNotesAccordion">
        {props.chartHistory.map((item) => (
          <ELSAccordionItem
            key={item.fragmentId}
            id={item.fragmentId}
            leftIcon="chevron-down"
            customHeader={
              <>
                <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !item.active })}>{item?.chartData?.progressNotes}</AccordionTitle>
                <AccordionBadge>{formatDate({ date: toMomentWithParsers(item.chartingAt).toDate(), locale: props.locale, includeTime: true })}</AccordionBadge>
              </>
            }
          >
            <Box py pr>
              <div>
                <MultiLineWithEmpty text={item?.chartData?.progressNotes} />
              </div>
              <div className="u-els-margin-top-1x1o2">
                <h4>Provider Signature</h4>
                <MultiLineWithEmpty text={item?.chartData?.signature} />
              </div>
              {item.active && (
                <ChartPermission fragmentCreatorId={item.creator?.id}>
                  {({ allowEditFragment, allowDeleteFragment }) => (
                    <Box mt2>
                      {allowEditFragment && <ELSButton className="sng-button u-els-margin-right" text="Edit" onClick={() => props.onEdit(item)} />}
                      {allowDeleteFragment && <ELSButton className="sng-button c-els-button--secondary" text="Delete" onClick={() => props.onDelete(item)} />}
                    </Box>
                  )}
                </ChartPermission>
              )}
            </Box>
          </ELSAccordionItem>
        ))}
      </ELSAccordion>
    )}
  </div>
);

ProgressNotesHistoryView.displayName = 'ProgressNotesHistoryView';
export default ProgressNotesHistoryView;

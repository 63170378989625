import { get } from 'lodash';
import { Fragment } from 'react';
import { AnswerSubmission } from 'models/ui';
import { Box, SectionDivider } from 'components/common';
import { ELSQuestionWrapper } from 'components/common/case-study-quiz/ELSQuestionWrapper';
import { ELSTab, ELSTabGroup } from 'components/common/els';
import { QuestionTabs } from './QuizQuestionsResult';
import './quiz.questions.result.scss';

interface QuizQuestionsResultProps {
  totalCorrect: number;
  totalQuestions: number;
  activeQuestionTab: number;
  questions: AnswerSubmission[];
  getAnswerEvaluationMessage: Function;
  onQuestionTabsClick: Function;
}

export const QuizQuestionsResultView = (props: QuizQuestionsResultProps) => {
  const { totalCorrect, totalQuestions, questions, onQuestionTabsClick, activeQuestionTab, getAnswerEvaluationMessage } = props;
  const totalIncorrect = totalQuestions - totalCorrect;
  const filteredQuestions = questions?.filter((question) => (activeQuestionTab === QuestionTabs.INCORRECT_QUESTION ? !question.correct : question.correct)) || [];
  return (
    <Box className="quiz-questions-result" px3 pb2>
      <h4 className="quiz-questions-result__title u-els-padding-bottom">Question Answer Key</h4>
      <ELSTabGroup onChangeActiveIndex={onQuestionTabsClick} activeIndex={activeQuestionTab}>
        <ELSTab title={`Incorrect Questions (${totalIncorrect})`} />
        <ELSTab title={`Correct Questions (${totalCorrect})`} />
      </ELSTabGroup>
      {filteredQuestions.map((question) => (
        <Fragment key={question.vtwId}>
          <ELSQuestionWrapper
            questionPrompt={question.prompt}
            questionChoices={question.responseChoices}
            questionFeedbacks={question.feedback}
            selectedAnswer={get(question, 'selectedAnswers', '')}
            correctAnswer={get(question, 'correctResponse', '')}
            answerCorrectnessText={getAnswerEvaluationMessage(question)}
            textReference={get(question, 'textReference[0]', '')}
            correctAnswers={get(question, 'correctResponse', [])}
            selectedAnswers={get(question, 'selectedAnswers', [])}
            questionVtwId={question.vtwId}
            questionType={question.questionType}
            isShowSuccessFlagAllCorrectAnswer
          />
          <SectionDivider />
        </Fragment>
      ))}
    </Box>
  );
};

export enum FormField {
  NUTRITION_TYPE = 'nutritionType',
  DIET_TYPE = 'dietType',
  CONSISTENCY = 'consistency',
  TUBE_FEEDING = 'tubeFeeding',
  NOTHING_BY_MOUNT = 'nothingByMouth',
  CALORIE_COUNT = 'calorieCount',
  DAY_SHIFT = 'dayShift',
  EVENING_SHIFT = 'eveningShift',
  NIGHT_SHIFT = 'nightShift',
  SPECIAL_DIET = 'specialDiet',
  DATE_FIELD = 'orderStartDate',
  TIME_FIELD = 'orderStartTime',
  TOTAL_HOUR = '24HourTotal',
  FORMULA = 'formula'
}

export enum SectionTitle {
  NUTRITION = 'Nutrition',
  DIET_TYPE = 'Diet Type',
  CONSISTENCY = 'Consistency',
  TUBE_FEEDING = 'Tube Feeding',
  NOTHING_BY_MOUNT = 'Nothing By Mouth',
  FLUID_RESTRICTION = 'Fluid Restriction',
  CALORIE_COUNT = 'Calorie Count',
  TYPE_MODEL = 'Type / Model',
  DAY_SHIFT = 'Day Shift',
  EVENING_SHIFT = 'Evening Shift',
  NIGHT_SHIFT = 'Night Shift',
  SPECIAL_DIET = 'Special Diet',
  DATE_TIME_FIELD = 'Order Start',
  TOTAL_HOUR = '24 Hour Total',
  FORMULA = 'Formula'
}

import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragmentRS } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import { withChartLogic } from 'components/common';
import AlertModal from 'components/common/modal/AlertModal';
import { FormField, FormFieldLabel } from './constants';
import LaborProgressOnsetSidebarView, { LaborProgressOnsetSidebarViewProps } from './LaborProgressOnsetSidebarView';

export interface LaborProgressOnsetSidebarProps extends ChartComponentProps {
  onSavedData?: Function;
  isOnsetSidebarOpen: boolean;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  isAuthor: boolean;
  modalService: ELSModalServiceType;
}

class LaborProgressOnsetSidebar extends Component<LaborProgressOnsetSidebarProps> {
  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    if (this.props.isAuthor) {
      dataMap.set(
        FormField.LABOR_ONSET_DATETIME_OFFSET,
        createFormField({
          name: FormField.LABOR_ONSET_DATETIME_OFFSET,
          type: FormFieldInputType.TEXT_BOX,
          label: 'Time Offset',
          value: '00:00'
        })
      );
    } else {
      dataMap.set(
        FormField.LABOR_ONSET_DATE,
        createFormField({
          name: FormField.LABOR_ONSET_DATE,
          type: FormFieldInputType.DATE,
          label: FormFieldLabel.DATE
        })
      );
      dataMap.set(
        FormField.LABOR_ONSET_TIME,
        createFormField({
          name: FormField.LABOR_ONSET_TIME,
          type: FormFieldInputType.TIME,
          label: FormFieldLabel.TIME,
          value: '00:00'
        })
      );
    }

    return dataMap;
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const { buildRecordFromField, createBaseFragment } = chartService;
    const basicInfo = createBaseFragment({ chartingTime: this.props.chartingTime });

    const chartData = {
      [FormField.LABOR_ONSET_DATE]: buildRecordFromField(formFieldMap.get(FormField.LABOR_ONSET_DATE))?.content ?? '',
      [FormField.LABOR_ONSET_TIME]: buildRecordFromField(formFieldMap.get(FormField.LABOR_ONSET_TIME))?.content ?? '',
      [FormField.LABOR_ONSET_DATETIME_OFFSET]: buildRecordFromField(formFieldMap.get(FormField.LABOR_ONSET_DATETIME_OFFSET))?.content ?? '',
      [FormField.IS_ONSET_SIDE_BAR]: true
    };

    return { ...basicInfo, chartData };
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      navId: NAV_ID.LABOR_PROGRESS,
      afterSave: this.props.onSavedData
    });
  };

  handleClickOutside = () => {
    const { modalService } = this.props;
    const modalId = 'alertUnsavedCommentModalId';
    modalService.openModal({
      modalId,
      content: (
        <AlertModal
          header="Before you move into the Labor Progress chart..."
          message="Please enter the Active Labor Onset Date and Time."
          onOkClick={() => modalService.closeModal(modalId)}
        />
      )
    });
  };

  render() {
    const { formFieldMap, formSubmittedCount, hasUnsavedChanges, isOnsetSidebarOpen, sidebarProps } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' })
    };
    const viewProps: LaborProgressOnsetSidebarViewProps = {
      chartActionsProps,
      isOnsetSidebarOpen,
      formFieldMap,
      formSubmittedCount,
      requireSave: true,
      onClickOutside: this.handleClickOutside,
      sidebarProps
    };
    return (
      <>
        <LaborProgressOnsetSidebarView {...viewProps} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state),
  chartingTime: studentSelectors.getChartingTime(state)
});

export { LaborProgressOnsetSidebar as BaseLaborProgressOnsetSidebar };
export default compose(connect(mapStateToProps), withChartLogic)(LaborProgressOnsetSidebar);

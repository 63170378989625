import cx from 'classnames';
import { AssessmentStatus } from 'models/enum';
import PercentageGauge from 'components/common/percentage-gauge/PercentageGauge';
import IncompletedAssessmentStatusBadge from './IncompletedAssessmentStatusBadge';
import './base-grade.scss';

export interface ScoreGradeProps {
  className?: string;
  score: number;
  isCompleted: boolean;
  formattedDueDate?: string;
  assessmentStatus: string;
}

const ScoreGrade = (props: ScoreGradeProps) => {
  const { className, score, isCompleted, formattedDueDate, assessmentStatus } = props;
  let incompletedStatusExplain = assessmentStatus === AssessmentStatus.COMPLETED_LATE ? 'You submitted late' : 'You didn’t submit on time';

  let headerMessage = 'Case Study Quiz Score';
  if (assessmentStatus === AssessmentStatus.IN_PROGRESS) {
    headerMessage = 'You have not yet submitted';
    incompletedStatusExplain = 'You have not yet submitted';
  }

  return (
    <div className={cx('grade', className)}>
      <PercentageGauge label="quiz score" percent={score} />
      <div className="legend">
        <span className="u-els-font-size-h3">{headerMessage}</span>
        <IncompletedAssessmentStatusBadge assessmentStatus={assessmentStatus} />
        <p className="legend__text">
          <span className="legend__dot u-els-background-color-extended-blue-7" />
          {isCompleted ? 'Correct' : `Assignment is due ${formattedDueDate}`}
        </p>

        <p className="legend__text">
          <span className="legend__dot u-els-background-color-extended-blue-4" />
          {isCompleted ? 'Incorrect' : incompletedStatusExplain}
        </p>
      </div>
    </div>
  );
};

export default ScoreGrade;

import { AssessmentStatus } from 'models/enum';
import { Badge } from 'components/common';
import './assessment-status-badge.scss';

const IncompletedAssessmentStatusBadge = ({ assessmentStatus }: { assessmentStatus: string }) => {
  if (assessmentStatus === AssessmentStatus.COMPLETED_LATE) {
    return (
      <Badge inline className="assessment-status-badge">
        Completed Late
      </Badge>
    );
  }

  if (assessmentStatus === AssessmentStatus.PAST_DUE) {
    return (
      <Badge inline className="assessment-status-badge">
        Past Due
      </Badge>
    );
  }

  if (assessmentStatus === AssessmentStatus.IN_PROGRESS) {
    return (
      <Badge inline className="assessment-status-badge">
        In Progress
      </Badge>
    );
  }

  return null;
};

export default IncompletedAssessmentStatusBadge;

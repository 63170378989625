import { ChartFragmentRS, MedicationFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { Box, ChartActions, ClinicalKeyResourceCard, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, Label } from './constants';
import EditMedicationSidebar, { EditMedicationSidebarProps } from './EditMedicationSidebar';
import MedicationsHistoryView from './MedicationsHistoryView';
import '../pre.clinical.manager.common.scss';

export interface MedicationsViewProps {
  resetAll: number;
  formSubmittedCount: number;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  chartHistory: MedicationFragment[];
  selectedMedication: MedicationFragment;
  onMedicationEdit: Function;
  onMedicationDelete: Function;
  onEditSidebarOpen: Function;
  onEditSidebarClose: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const MedicationsView = (props: MedicationsViewProps) => {
  const { resetAll, formSubmittedCount, chartMetaFormFields } = props;
  const editSideBarProps: Partial<EditMedicationSidebarProps> = {
    medication: props.selectedMedication,
    onSaveClick: props.onMedicationEdit,
    onCloseClick: props.onEditSidebarClose,
    selectedNavId: NAV_ID.MEDICATIONS,
    saveChartData: props.saveChartData,
    ...appHelper.getChartSharedProps(props)
  };
  return (
    <div className="pre-clinical-manager__page">
      <h1>{Label.MEDICATIONS}</h1>
      <MedicationsHistoryView chartHistory={props.chartHistory} onEditSidebarOpen={props.onEditSidebarOpen} onMedicationDelete={props.onMedicationDelete} />
      <Box mt4>
        <h2 className="u-els-margin-bottom-2x">{Label.ADD_MEDICATION}</h2>
        <Box mb2>
          <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
        </Box>
        <ELSFlex column>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.MEDICATION)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          <div className="u-els-margin-bottom">
            <ClinicalKeyResourceCard
              drugName={
                props.chartMetaFormFields.get(FormField.MEDICATION)?.value
                  ? chartHelper.getMedicationName(props.chartMetaFormFields.get(FormField.MEDICATION)?.value, props.chartMetaFormFields.get(FormField.MEDICATION)?.chartContent)
                  : undefined
              }
            />
          </div>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.MEDICATION_OTHER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.CLASSIFICATION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.CLASSIFICATION_OTHER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.ROUTE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.ROUTE_OTHER)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.DOSE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.FREQUENCY)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField key={`date-ordered-${resetAll}`} formField={chartMetaFormFields.get(FormField.DATE_ORDERED)} formSubmittedCount={formSubmittedCount} />
        </ELSFlex>
        <ELSFlex className="u-flex-grid-2x" wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.COMMENT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.THERAPEUTIC_EFFECT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.ACTION)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.CONTRAINDICATIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.SIDE_EFFECT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.LIFE_THREATENING)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.RECOMMENDED)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.NURSING_INTERVENTIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <EditMedicationSidebar {...editSideBarProps} />
      <ChartActions {...props.chartActionsProps} />
    </div>
  );
};

MedicationsView.displayName = 'MedicationsView';
export default MedicationsView;

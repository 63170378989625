import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import FLACCScaleView, { FLACCScaleViewProps } from './FLACCScaleView';

export enum Section {
  FLACC_SCALE = 'FLACC Scale',
  TOTAL_FLACC_SCORE = 'Total FLACC Score'
}

export enum FormField {
  FLACC_PAIN_ASSESSMENT_SCALE = 'painAssessmentScale',
  FACE_QUESTION = 'faceQuestion',
  LEGS_QUESTION = 'legsQuestion',
  ACTIVITY_QUESTION = 'activityQuestion',
  CRY_QUESTION = 'cryQuestion',
  CONSOLABILITY_QUESTION = 'consolabilityQuestion'
}

class FLACCScale extends Component<ChartComponentProps> {
  static displayName = 'FLACCScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(FormField.FLACC_PAIN_ASSESSMENT_SCALE, createFormField({ name: FormField.FLACC_PAIN_ASSESSMENT_SCALE, type: FormFieldInputType.SCALE, errorLabel: 'FLACC Scale' }));

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.FACE_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.LEGS_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.ACTIVITY_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.CRY_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.CONSOLABILITY_QUESTION, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.FLACC_SCALE,
      records: [
        {
          sectionTitle: Section.FLACC_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.FLACC_PAIN_ASSESSMENT_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_FLACC_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.FLACC_PAIN_ASSESSMENT_SCALE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.FLACC_PAIN_ASSESSMENT_SCALE))?.score?.value,
              linkedFormFieldIds: [FormField.FACE_QUESTION, FormField.LEGS_QUESTION, FormField.ACTIVITY_QUESTION, FormField.CRY_QUESTION, FormField.CONSOLABILITY_QUESTION]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: FLACCScaleViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };

    return <FLACCScaleView {...viewProps} />;
  }
}

export { FLACCScale as BaseFLACCScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(FLACCScale);

import cx from 'classnames';
import { Component } from 'react';
import { IntakeOutputRecord } from 'models/api-response';
import { ColumnAlign, IntakeOutputTotalPerDayItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import './intake.output.history.scss';

interface IntakeOutputHistoryProps {
  chartMetaContentMap?: Map<string, string>;
  deleteHistory: Function;
  chartHistory: IntakeOutputRecord[];
  totalPerDayData: IntakeOutputTotalPerDayItem[];
  locale: Locales;
}

class IntakeOutputHistory extends Component<IntakeOutputHistoryProps> {
  static displayName = 'IntakeOutputHistory';

  typeRenderer = (record) => <div className={cx('u-text-capitalize', { 'row--deleted': !record.active })}>{record.type}</div>;

  descriptionRenderer = (record) => <div className={cx({ 'row--deleted': !record.active })}>{chartHelper.findContentFromMap(this.props.chartMetaContentMap, record.choice)}</div>;

  amountRenderer = (record) => <div className={cx({ 'row--deleted': !record.active })}>{this.getAmountValue(record)}</div>;

  getAmountValue = (record) => {
    const unit = chartHelper.findContentFromMap(this.props.chartMetaContentMap, record.unit);
    if (!record.value && !unit) {
      return '';
    }
    return `${record.value} ${chartHelper.findContentFromMap(this.props.chartMetaContentMap, record.unit)}`;
  };

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { chartHistory, deleteHistory, totalPerDayData, locale } = this.props;

    const chartHistoryDataSource = {
      title: 'Chart History',
      headers: [
        { text: 'Chart Time', field: 'chartingAt' },
        { text: 'Type', field: 'type', sortable: true, customRender: this.typeRenderer },
        { text: 'Description', field: 'choice', customRender: this.descriptionRenderer },
        { text: 'Amount', field: 'value', align: ColumnAlign.Right, customRender: this.amountRenderer },
        { text: 'Notes', field: 'notes' },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { field: 'actionDelete' }
      ],
      data: chartHistory,
      css: ['column__chart-time', 'column__type', 'column__description', 'column__amount', 'column__note', 'column__entry-by', 'column__delete']
    };

    const totalPerDayDataSource = {
      title: 'Total Per Day',
      headers: [
        { text: 'Day', field: 'date' },
        { text: 'Intake (mL)', field: 'intake', align: ColumnAlign.Right },
        { text: 'Output (mL)', field: 'output', align: ColumnAlign.Right }
      ],
      data: totalPerDayData,
      css: ['column__day', 'column__intake', 'column__output']
    };

    return (
      <>
        <Box mt>
          <ChartHistory dataSource={chartHistoryDataSource} deleteRecord={deleteHistory} locale={locale} />
        </Box>
        <Box mt2>
          <ChartHistory dataSource={totalPerDayDataSource} />
        </Box>
      </>
    );
  }
}

export default IntakeOutputHistory;

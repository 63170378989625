import { Box, ChartActions, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

export interface SafetyAssessmentViewProps extends ChartViewProps {
  relatedChartIds: string[];
}

const SafetyAssessmentView = (props: SafetyAssessmentViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, relatedChartIds } = props;

  return (
    <div className="safety-assessment">
      <h1>{SectionTitle.SAFETY_ASSESSMENT}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <Box mt>
          <h2>{SectionTitle.ORIENTATION}</h2>
          <ELSFlex className="u-flex-gutter-3x" left wrap>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.ORIENTATION)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ORIENTATION_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>

        <SectionDivider />

        <Box my2>
          <h2>{SectionTitle.FALL_RISK}</h2>
          <ELSFlex className="u-flex-gutter-3x u-els-margin-top" left wrap>
            <ELSFlexItem>
              <Box>
                <p>Complete a fall risk assessment using the Morse Fall Scale on every patient upon admission, then reassess once every shift.</p>
              </Box>
              <Box mt className="u-els-width-1o3">
                <ErrorFormField formField={formFieldMap.get(FormField.FALL_RISK)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </ELSFlexItem>
          </ELSFlex>
        </Box>

        <SectionDivider />

        <Box mt2>
          <h2>{SectionTitle.RESTRAINTS}</h2>
          <ELSFlex className="u-flex-gutter-3x u-els-margin-top" left wrap>
            <ELSFlexItem>
              <p>If restraints in use, complete a special precautions / restraints chart.</p>
              <ErrorFormField formField={formFieldMap.get(FormField.RESTRAINTS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>

        <SectionDivider />

        <Box mt2>
          <h2>{SectionTitle.BRACELET_CHECK}</h2>
          <ELSFlex className="u-flex-gutter-3x u-els-margin-top" left wrap>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.BRACELET_CHECK)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.BRACELET_CHECK_NOTE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>
      </Box>
      <ChartActions {...chartActionsProps} />
      {relatedChartIds?.length > 0 && <RelatedCharts key={relatedChartIds.toString()} chartIds={relatedChartIds} />}
    </div>
  );
};

SafetyAssessmentView.displayName = 'SafetyAssessment';
export default SafetyAssessmentView;

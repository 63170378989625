import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorFormField, Sidebar } from 'components/common';
import { ELSCheckBox, ELSFlex, ELSFlexItem } from 'components/common/els';
import InterventionHistory from './InterventionHistory';
import NoteShortcutKey from '../shared/NoteShortcutKey';
import { FormField, FormFieldLabel, SectionTitle } from '../shared/constants';

export interface SpeechSidebarViewProps {
  isOpen: boolean;
  intervention: object[];
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  isCompleted: boolean;
  isTherapyCompleted: boolean;
  onCheckCompleted: Function;
  sidebarProps: Function;
}

const SpeechSidebarView = (props: SpeechSidebarViewProps) => {
  const { isOpen, chartActionsProps, intervention, formFieldMap, formSubmittedCount, isCompleted, isTherapyCompleted, onCheckCompleted, sidebarProps, onCloseClick } = props;
  const interventionChartHistory = intervention || [];
  return (
    <Sidebar open={isOpen} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
      <Box mt3>
        <h2>Speech Therapy Consultation</h2>
      </Box>
      <div className="sidebar__divider" />
      <ELSFlex className="u-flex-grid-2x" wrap>
        {!isTherapyCompleted && (
          <ELSFlexItem w="1o3">
            <h3>Chart Inputs</h3>
            <ELSCheckBox id="isCompleted" checked={isCompleted} labelText={FormFieldLabel.COMPLETE} changeHandler={onCheckCompleted} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CONSULTATION_TYPE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.REASON_CONSULTATION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.REFERRAL_SOURCE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HISTORY_PRESENT_ILLNESS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FINDINGS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.IMPRESSIONS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PLAN)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SPEECH_THERAPIST_NAME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SPEECH_THERAPIST_SIGNATURE)} formSubmittedCount={formSubmittedCount} />
            <Box mt>
              <h4>{SectionTitle.INTERVENTION}</h4>
            </Box>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INTERVENTION_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INTERVENTION_TIME)} formSubmittedCount={formSubmittedCount} />
            <Box mt3 mb>
              <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
            </Box>
          </ELSFlexItem>
        )}
        <ELSFlexItem w={isTherapyCompleted ? '1o1' : '2o3'}>
          <h3>Interventions</h3>
          <InterventionHistory chartHistory={interventionChartHistory} />
        </ELSFlexItem>
      </ELSFlex>
      <NoteShortcutKey />
    </Sidebar>
  );
};

SpeechSidebarView.displayName = 'SpeechSidebarView';
export default SpeechSidebarView;

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getGenitourinaryAssessmentFormFields } from './constants';
import GenitourinaryAssessmentView from './GenitourinaryAssessmentView';

interface GenitourinaryAssessmentState {
  resetAll: number;
}

class GenitourinaryAssessment extends Component<ChartComponentProps, GenitourinaryAssessmentState> {
  static displayName = 'GenitourinaryAssessment';

  constructor(props: Readonly<ChartComponentProps>) {
    super(props);
    this.state = {
      resetAll: 0
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getGenitourinaryAssessmentFormFields().forEach(({ isHidden, label, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });

    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap, intl } = this.props;
    const CHART_NAME = LanguageKeys.GENITOURINARY_ASSESSMENT.GENITOURINARY_ASSESSMENT;
    const record = {
      chartTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.SYSTEM_ASSESSMENT,
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: CHART_NAME,
          records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
        },
        {
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.URINARY_SYSTEM_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.URINATION_MODE)]
        },
        {
          parentSectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.DIALYSIS,
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.PERITONEAL_DIALYSIS,
          records: [
            buildPatientRecord(formFieldMap, FormField.DIALYSIS_PERITONEAL),
            buildPatientRecord(formFieldMap, FormField.DIALYSIS_PERITONEAL_LAST_TREATMENT),
            buildPatientRecord(formFieldMap, FormField.DIALYSIS_PERITONEAL_NEXT_TREATMENT)
          ]
        },
        {
          parentSectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.DIALYSIS,
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.RENAL_DIALYSIS,
          records: [
            buildPatientRecord(formFieldMap, FormField.DIALYSIS_RENAL),
            buildPatientRecord(formFieldMap, FormField.DIALYSIS_RENAL_LAST_TREATMENT),
            buildPatientRecord(formFieldMap, FormField.DIALYSIS_RENAL_NEXT_TREATMENT)
          ]
        },
        {
          sectionTitle: intl.formatMessage({ id: LanguageKeys.GENITOURINARY_ASSESSMENT.URINE_COLOR_CHARACTERISTICS }),
          records: [
            buildPatientRecord(formFieldMap, FormField.URINE_COLOR),
            buildPatientRecord(formFieldMap, FormField.URINE_CHARACTERISTICS),
            buildPatientRecord(formFieldMap, FormField.URINE_ODOR)
          ]
        },
        {
          parentSectionTitle: intl.formatMessage({ id: LanguageKeys.GENITOURINARY_ASSESSMENT.URINE_COLOR_CHARACTERISTICS }),
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.URINARY_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.URINARY_NO_PROBLEMS), ...buildPatientRecords(formFieldMap, FormField.URINARY_ASSESSMENT)]
        },
        {
          parentSectionTitle: intl.formatMessage({ id: LanguageKeys.GENITOURINARY_ASSESSMENT.URINE_COLOR_CHARACTERISTICS }),
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.URINARY_DIVERSION,
          records: [buildPatientRecord(formFieldMap, FormField.HAS_URINARY_DIVERSION), buildPatientRecord(formFieldMap, FormField.URINARY_NOTE, 'Notes')]
        },
        {
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.PENIS_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.PENIS_ASSESSMENT_NO_PROBLEMS), ...buildPatientRecords(formFieldMap, FormField.PENIS_ASSESSMENT)]
        },
        {
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.SCROTUM_TESTES_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.SCROTUM_TESTES_ASSESSMENT_NO_PROBLEMS), ...buildPatientRecords(formFieldMap, FormField.SCROTUM_TESTES_ASSESSMENT)]
        },
        {
          sectionTitle: LanguageKeys.GENITOURINARY_ASSESSMENT.FEMALE_EXTERNAL_GENITALIA_ASSESSMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.EXTERNAL_GENITALIA_NO_PROBLEMS),
            ...buildPatientRecords(formFieldMap, FormField.EXTERNAL_GENITALIA),
            buildPatientRecord(formFieldMap, FormField.EXTERNAL_GENITALIA_NOTE, 'Notes')
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { resetAll } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, intl } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: ChartViewProps = {
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      formFieldMap,
      intl
    };
    return <GenitourinaryAssessmentView {...viewProps} />;
  }
}

export { GenitourinaryAssessment as BaseGenitourinaryAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(GenitourinaryAssessment);

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { FormField, SectionTitle } from './constants';
import DevelopmentalScreenReadOnly from './DevelopmentalScreenReadOnly';

interface DevelopmentalScreenViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const DevelopmentalScreenView = (props: DevelopmentalScreenViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, formFieldMap } = props;
  return (
    <div className="developmental-screen">
      {isLocked ? (
        <DevelopmentalScreenReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>{SectionTitle.DEVELOPMENT_SCREEN}</h1>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />

          <Box mt2>
            <h2>Indicate a developmental age and a chronological age for the child:</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.DEVELOPMENT_AGE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.CHRONOLOGICAL_AGE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />

            <h2>For each item below, indicate if the child demonstrates age-appropriate development in that category</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.GROSS_MOTOR_SKILLS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.FINE_MOTOR_SKILLS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.SENSORY_SKILLS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.VOCALIZATION_SKILLS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={formFieldMap.get(FormField.SOCIALIZATION_COGNITION)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <p>If child shows a developmental delay, refer for Child Life consultation</p>
          </Box>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

DevelopmentalScreenView.displayName = 'DevelopmentalScreenView';
export default DevelopmentalScreenView;

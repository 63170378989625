import { ObstetricHistoryFragment, Record } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import ReadOnlyInput from 'components/features/chart/admission-history/shared/ReadOnlyInput';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import PreviousPregnanciesHistory from './PreviousPregnanciesHistory';

export interface ObstetricHistoryReadOnlyProps {
  selectedRecord: ObstetricHistoryFragment;
}

const ObstetricHistoryReadOnly = (props: ObstetricHistoryReadOnlyProps) => {
  const { selectedRecord } = props;
  const pregnanciesInformationRecords =
    (selectedRecord?.chartData?.records?.find((record) => record.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES)?.records as Record[]) || [];
  const previousPregnanciesHistory = pregnanciesInformationRecords?.map((record) => ({
    id: record.id,
    active: record.active,
    [FormField.DAY_MONTH_YEARS_AGO]: record.records?.find((item) => item?.formField === FormField.DAY_MONTH_YEARS_AGO)?.value,
    [FormField.DURATION_OF_LABOR]: record.records?.find((item) => item?.formField === FormField.DURATION_OF_LABOR)?.value,
    [FormField.GESTATIONAL_WEEKS]: record.records?.find((item) => item?.formField === FormField.GESTATIONAL_WEEKS)?.value,
    [FormField.BIRTH_WEIGHT]: record.records?.find((item) => item?.formField === FormField.BIRTH_WEIGHT)?.value,
    [FormField.GENDER]: record.records?.find((item) => item?.formField === FormField.GENDER)?.value,
    [FormField.TYPE_OF_DELIVERY]: record.records?.find((item) => item?.formField === FormField.TYPE_OF_DELIVERY)?.value,
    [FormField.ANESTHESIA]: record.records?.find((item) => item?.formField === FormField.ANESTHESIA)?.value,
    [FormField.PRETERM_LABOR]: record.records?.find((item) => item?.formField === FormField.PRETERM_LABOR)?.value,
    [FormField.COMPLICATIONS]: record.records?.find((item) => item?.formField === FormField.COMPLICATIONS)?.value,
    [FormField.COMMENTS]: record.records?.find((item) => item?.formField === FormField.COMMENTS)?.value
  }));
  return (
    <>
      <h2>{SectionTitle.OBSTETRIC_HISTORY}</h2>
      <Box className="sng-article">
        {!selectedRecord && <h3>Chart Omitted</h3>}
        {selectedRecord && (
          <>
            <Box mb2>
              <ReadOnlyInput
                chartFragment={props.selectedRecord}
                sectionTitle={SectionTitle.OBSTETRIC_HISTORY}
                formFieldId={FormField.INFERTILITY}
                customTitle={FormFieldLabel.INFERTILITY}
              />
            </Box>
            <Box mb2>
              <h3 className="u-els-margin-bottom">{SectionTitle.PREGNANT_HISTORY}</h3>
              <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.TOTAL_PREGNANCIES}
                    customTitle={FormFieldLabel.TOTAL_PREGNANCIES}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.MATURE}
                    customTitle={FormFieldLabel.MATURE}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.ABORTION_SPONTANEOUS}
                    customTitle={FormFieldLabel.ABORTION_SPONTANEOUS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.MULTIPLE_BIRTHS}
                    customTitle={FormFieldLabel.MULTIPLE_BIRTHS}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput chartFragment={props.selectedRecord} sectionTitle={SectionTitle.PREGNANT_HISTORY} formFieldId={FormField.TERM} customTitle={FormFieldLabel.TERM} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.ABORTION_INDUCED}
                    customTitle={FormFieldLabel.ABORTION_INDUCED}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.ECTOPIC}
                    customTitle={FormFieldLabel.ECTOPIC}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.PREGNANT_HISTORY}
                    formFieldId={FormField.LIVING_BIRTH}
                    customTitle={FormFieldLabel.LIVING_BIRTH}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box mb2>
              <h3 className="u-els-margin-bottom">{SectionTitle.PREVIOUS_PREGNANCIES}</h3>
              <PreviousPregnanciesHistory isLocked chartHistory={previousPregnanciesHistory} />
            </Box>
            <Box mb2>
              <h3>{SectionTitle.GENETIC_SCREENING_PERFORMED}</h3>
              <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.GENETIC_SCREENING_PERFORMED}
                    formFieldId={FormField.GENETIC_SCREENING_PERFORMED}
                    customTitle={FormFieldLabel.GENETIC_SCREENING_PERFORMED}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <ReadOnlyInput
                    chartFragment={props.selectedRecord}
                    sectionTitle={SectionTitle.GENETIC_SCREENING_PERFORMED}
                    formFieldId={FormField.GENETIC_SCREENING_PERFORMED_OTHER}
                    customTitle={FormFieldLabel.OTHER}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box mb2>
              <h3>{SectionTitle.CURRENT_PREGNANCY_RELATED_PROBLEMS}</h3>
              <ReadOnlyInput
                chartFragment={props.selectedRecord}
                sectionTitle={SectionTitle.CURRENT_PREGNANCY_RELATED_PROBLEMS}
                formFieldId={FormField.DESCRIBE_CURRENT_PROBLEMS}
                customTitle={FormFieldLabel.DESCRIBE_CURRENT_PROBLEMS}
              />
            </Box>
            <Box mb2>
              <h3>{SectionTitle.CURRENT_PREGNANCY_PRENATAL_NOTES}</h3>
              <ReadOnlyInput
                chartFragment={props.selectedRecord}
                sectionTitle={SectionTitle.CURRENT_PREGNANCY_PRENATAL_NOTES}
                formFieldId={FormField.NOTES_FROM_PRENATAL_VISITS}
                customTitle={FormFieldLabel.NOTES_FROM_PRENATAL_VISITS}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

ObstetricHistoryReadOnly.displayName = 'ObstetricHistoryReadOnly';
export default ObstetricHistoryReadOnly;

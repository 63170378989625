import { ReactElement } from 'react';
import { connect } from 'react-redux';
import { AssessmentStatus, FragmentType } from 'models/enum';
import { isDemoEmptyEhr } from 'helpers/assignment.helper';
import { appSelectors } from 'redux/ducks/app';

export interface ChartPermissionProps {
  fragmentCreatorId?: number;
  fragmentType?: FragmentType;
  children(arg: { allowSaveFragment?: boolean; allowEditFragment?: boolean; allowDeleteFragment?: boolean }): ReactElement;
  isAssessmentOwner: boolean;
  assessmentOwnerId: number;
  assessmentStatus: AssessmentStatus;
  isAuthor: boolean;
}

const ChartPermission = (props: ChartPermissionProps) => {
  const { children, isAssessmentOwner, assessmentStatus, assessmentOwnerId, isAuthor, fragmentCreatorId, fragmentType } = props;
  const isInDemoEmptyEhr = isDemoEmptyEhr();
  const isAssessmentChangeable = [AssessmentStatus.IN_PROGRESS, AssessmentStatus.PAST_DUE].includes(assessmentStatus);

  const allowEditFragment = isAssessmentOwner && isAssessmentChangeable && assessmentOwnerId === fragmentCreatorId;
  const allowSaveFragment = isAuthor || (isAssessmentOwner && isAssessmentChangeable && fragmentType !== FragmentType.AUTHORED) || isInDemoEmptyEhr;
  return <>{children({ allowSaveFragment, allowEditFragment, allowDeleteFragment: allowEditFragment })}</>;
};

const mapStateToProps = (state) => ({
  isAssessmentOwner: appSelectors.getUserId(state) === appSelectors.getAssessment(state)?.studentId,
  assessmentOwnerId: appSelectors.getAssessment(state)?.studentId,
  assessmentStatus: appSelectors.getAssessment(state)?.status,
  isAuthor: appSelectors.getIsAuthor(state)
});

ChartPermission.displayName = 'ChartPermission';
export { ChartPermission as BaseChartPermission };
export default connect(mapStateToProps)(ChartPermission);

/* eslint-disable no-param-reassign */
import cx from 'classnames';
import { isUndefined } from 'lodash';
import { Fragment } from 'react';
import TimeField from 'react-simple-timefield';
import { ELSCheckBox, ELSDropDown, ELSRadio, ELSTextBox } from '@els/els-component-form-field-react';
import { ELSFlex, ELSFlexItem } from '@els/els-ui-common-react';
import { FormFieldDataType, MultiSelectType } from 'models/enum';
import { ChartMetaFormField, ContentItem } from 'models/ui';
import { ELSTextarea, GenericImage, SNGDate, ScaleWrapper, TextBlock, ToggleNotesField } from 'components/common';
import { ErrorFormFieldOptions } from './ErrorFormField';
import MultiselectDropdownEF from '../multi-select/MultiselectDropdownEF';

type HandleInput = {
  name?: string;
  chartContent?: ContentItem[];
  value?: any;
  disabled?: boolean;
  isAriaRequired?: boolean | null;
  formField?: ChartMetaFormField;
  inputProps?: { [x: string]: any };
  displayVertical?: boolean;
  onChange?: Function;
  onBlur?: Function;
  transformInputProps?: Function;
  renderNestedField?: Function;
  buildOnChange?: Function;
  options?: ErrorFormFieldOptions;
  datePreviousValue?: string;
  dateComponentKey?: number;
  formFieldMap?: Map<string, ChartMetaFormField>;
};

export const handleDropdownFormFieldInputType = ({ name, chartContent, value, disabled, isAriaRequired, formField, inputProps, onChange, onBlur }: HandleInput): JSX.Element => (
  <div className="chart-input__dropdown">
    <ELSDropDown
      id={name}
      options={chartContent}
      value={value}
      changeHandler={(e) => onChange(formField, e)}
      blurHandler={() => onBlur(formField)}
      isDisabled={disabled}
      {...inputProps}
      ariaLabel={formField.label}
      aria-required={isAriaRequired}
    />
  </div>
);

export const handleMultiselectDropDownFormFieldInputType = ({ isAriaRequired, formField, value, inputProps }: HandleInput): JSX.Element => (
  <div className="chart-input__multi-select-dropdown">
    <MultiselectDropdownEF ariaRequired={isAriaRequired} chartFormField={formField} value={value} {...inputProps} />
  </div>
);

export const handleTextBoxFormFieldInputType = ({
  name,
  value,
  onChange,
  onBlur,
  disabled,
  formField,
  inputProps,
  transformInputProps,
  isAriaRequired
}: HandleInput): JSX.Element => (
  <ELSTextBox
    id={name}
    cssClasses="chart-input__textbox chart-input__input--pull-right"
    value={value}
    changeHandler={(e) => onChange(formField, e)}
    blurHandler={() => onBlur(formField)}
    isDisabled={disabled}
    {...transformInputProps(inputProps, formField.label)}
    ariaRequired={isAriaRequired}
  />
);

export const handleCheckBoxFormFieldInputType = ({ chartContent, inputProps, displayVertical, name, disabled, renderNestedField, buildOnChange }: HandleInput): JSX.Element => {
  if (!chartContent?.length) {
    return <></>;
  }

  const { containerClassName } = inputProps ?? {};

  return (
    <ELSFlex left column={displayVertical} className={cx(containerClassName)}>
      {chartContent.map((item) => (
        <Fragment key={item.id}>
          <ELSFlexItem className={cx({ 'u-els-margin-right': !displayVertical })}>
            <ELSCheckBox
              className={item.dataType === FormFieldDataType.NESTED_CHOICE ? 'u-els-margin-left-2x' : undefined}
              id={`${name}-${item.value}`}
              value={item.value}
              checked={item.selected}
              changeHandler={buildOnChange(item)}
              isDisabled={disabled}
            >
              <label htmlFor={`field-input-${name}-${item.value}`}>{item.label}</label>
            </ELSCheckBox>
          </ELSFlexItem>
          {renderNestedField(item.nestedFieldId)}
        </Fragment>
      ))}
    </ELSFlex>
  );
};

export const handleTextAreaFormFieldInputType = ({ chartContent, name, value, disabled, formField, inputProps, onChange, transformInputProps }: HandleInput): JSX.Element => {
  if (chartContent?.length && chartContent[0].expandedLabel && chartContent[0].collapsedLabel) {
    const { label, expandedLabel, collapsedLabel } = chartContent[0];
    return (
      <ToggleNotesField
        className="chart-input__toggle"
        id={name}
        value={value}
        label={label}
        showLabel={collapsedLabel}
        hideLabel={expandedLabel}
        isDisabled={disabled}
        onChange={(e) => onChange(formField, e)}
        {...transformInputProps(inputProps, formField.label)}
      />
    );
  }
  return (
    <ELSTextarea
      id={name}
      rows={4}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        onChange(formField, e);
      }}
      {...transformInputProps(inputProps, formField.label)}
    />
  );
};

export const handleRadioChoiceFormFieldInputType = ({ chartContent, displayVertical, name, buildOnChange, inputProps, disabled, renderNestedField }: HandleInput): JSX.Element => {
  if (isUndefined(chartContent) || chartContent.length === 0) {
    return <></>;
  }

  const { hideRadioContent, disableRadioContent, radioPrefixName, containerClassName } = inputProps ?? {};
  const prefix = radioPrefixName ? `${radioPrefixName}-` : '';
  const nestedFieldIds = chartContent.map((item) => item.nestedFieldId);

  return (
    <>
      <ELSFlex left wrap className={cx('u-els-margin-top-1o2', containerClassName)} column={displayVertical}>
        {chartContent.map(
          (item) =>
            !hideRadioContent?.(item) && (
              <Fragment key={item.id}>
                <ELSFlexItem className={cx({ 'u-els-margin-right': !displayVertical })}>
                  {item.resourceUri && <GenericImage id={item.id} altText={item.label} src={`${item.resourceUri}`} />}
                  <ELSRadio
                    id={`${prefix}-${name}-${item.value}`}
                    name={`${prefix}${name}`}
                    value={item.value}
                    checked={item.selected}
                    changeHandler={buildOnChange(item)}
                    isDisabled={disableRadioContent ? disableRadioContent(item) : item.disabled || disabled}
                  >
                    <label htmlFor={`field-input-${prefix}-${name}-${item.value}`}>{item.label}</label>
                  </ELSRadio>
                </ELSFlexItem>
                {displayVertical && renderNestedField(item.nestedFieldId)}
              </Fragment>
            )
        )}
      </ELSFlex>
      {nestedFieldIds.length > 0 && !displayVertical && (
        <ELSFlex left className="u-els-margin-left-2x">
          <ELSFlexItem>{nestedFieldIds.map((nestedFieldId) => renderNestedField(nestedFieldId))}</ELSFlexItem>
        </ELSFlex>
      )}
    </>
  );
};

export const handleDateFormFieldInputType = ({
  datePreviousValue,
  dateComponentKey,
  value,
  name,
  options,
  onChange,
  onBlur,
  formField,
  disabled,
  inputProps,
  isAriaRequired
}: HandleInput): JSX.Element => {
  // if date value change to blank, reset component
  if (datePreviousValue && !value) {
    dateComponentKey += 1;
  }
  return (
    <SNGDate
      key={`${dateComponentKey}`}
      id={name}
      value={value}
      disableBefore={options?.disableBefore}
      onDateChange={(e) => onChange(formField, e)}
      onBlur={() => onBlur(formField)}
      usePropValue
      isDisabled={disabled}
      ariaRequired={isAriaRequired}
      {...inputProps}
    />
  );
};

export const handleTimeFormFieldInputType = ({ name, value, disabled, formField, isAriaRequired, onChange, onBlur, inputProps }: HandleInput): JSX.Element => {
  const timeFieldId = name ? `field-input-${name}` : undefined;
  return (
    <div className="time-field-container u-els-display-inline-block">
      <TimeField
        id={timeFieldId}
        className="c-els-field__input"
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(formField, e)}
        onBlur={() => onBlur(formField)}
        style={{ width: '100%' }}
        aria-required={isAriaRequired}
        {...inputProps}
      />
    </div>
  );
};

export const handleMultiselectRadioFormFieldInputType = ({ chartContent, isAriaRequired, formField, value, inputProps }: HandleInput): JSX.Element => {
  if (!chartContent) {
    return null;
  }
  // eslint-disable-next-line no-case-declarations
  return (
    <div className="chart-input__multi-select-dropdown">
      <MultiselectDropdownEF ariaRequired={isAriaRequired} chartFormField={formField} value={value} selectType={MultiSelectType.SINGLE} {...inputProps} />
    </div>
  );
};

export const handleScaleFormFieldInputType = ({
  name,
  chartContent,
  options,
  disabled,
  formField,
  onChange,
  isAriaRequired,
  formFieldMap,
  renderNestedField
}: HandleInput): JSX.Element => {
  if (!chartContent?.length) {
    return null;
  }

  // show roll up if this option is not explicitly provided
  const shouldShowScaleRollup = options?.showScaleRollUp ?? true;
  const contextDirectionMap = options?.contextDirectionMap ?? new Map();
  const groupScaleQuestion = options?.groupScaleQuestion;
  return (
    <ScaleWrapper
      id={name}
      onChange={(event, option, question) => onChange(formField, event, { option, question })}
      chartContent={chartContent}
      showRollUp={shouldShowScaleRollup}
      disabled={disabled}
      contextDirectionMap={contextDirectionMap}
      groupScaleQuestion={groupScaleQuestion}
      showScoreInAnswer={options?.showScoreInAnswer}
      isAriaRequired={isAriaRequired}
      formFieldMap={formFieldMap}
      renderNestedField={renderNestedField}
      isHiddenScore={options?.isHiddenScore}
      isNotSaveChildValueInScore={options?.isNotSaveChildValueInScore}
    />
  );
};

export const handleImageFormFieldInputType = ({ chartContent, name }: HandleInput): JSX.Element => {
  if (!chartContent?.length) {
    return null;
  }

  const [content] = chartContent;
  return <GenericImage id={name} altText={name} src={`${content.resourceUri}`} />;
};

export const handleTextBlockFormFieldInputType = ({ chartContent }: HandleInput): JSX.Element => {
  if (!chartContent?.length) {
    return null;
  }

  const [content] = chartContent;
  return <TextBlock content={content.label} />;
};

import { ChartMetaFormField } from 'models/ui';
import { ErrorFormField } from 'components/common';

interface ImmunizationsSectionViewProps {
  resetAll: number;
  title: string;
  frequencyNote: string;
  options: ChartMetaFormField;
  dateReceived: ChartMetaFormField;
  formSubmittedCount: number;
}

const ImmunizationsSectionView = (props: ImmunizationsSectionViewProps) => (
  <>
    <h2>{props.title}</h2>
    <p>{props.frequencyNote}</p>
    <ErrorFormField formField={props.options} formSubmittedCount={props.formSubmittedCount} />
    <ErrorFormField key={props.resetAll} formField={props.dateReceived} formSubmittedCount={props.formSubmittedCount} />
  </>
);

ImmunizationsSectionView.displayName = 'ImmunizationsSectionView';
export default ImmunizationsSectionView;

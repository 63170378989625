import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentStatus } from 'models/enum';
import { RouteParams, RoutePath, SectionNavIdPatterns } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { SavedPatientWrapper } from 'components/common';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';
import { FormField, SectionTitle } from '../constants';

interface MatchParams {
  assessmentId: string;
}

interface SavedIVsProps {
  enableMultiStepsAuthoring: Function;
}

class SavedIVs extends Component<SavedIVsProps & RouteComponentProps<MatchParams>> {
  static displayName = 'SavedIVs';

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
  }

  isIVDiscontinued = (fragment: ChartFragment) => {
    return chartHelper.getFragmentValue(fragment, SectionTitle.IV, FormField.IV_DISCONTINUED);
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: dateTimeHelper.toDateTime(fragment.chartingAt),
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      groupFragmentRef: fragment.groupFragmentRef,
      location: chartHelper.getFragmentRecords(fragment, SectionTitle.IV, FormField.LOCATION)[0]?.content,
      navElementId: fragment.navElementId,
      ivType: chartHelper.getFragmentRecords(fragment, SectionTitle.IV, FormField.TYPE_IV_ACCESS)[0]?.content,
      ivStatus: this.isIVDiscontinued(fragment) ? FragmentStatus.DISCONTINUED : FragmentStatus.ACTIVE
    }));
  };

  handleIVUpdate = (row) => {
    const { assessmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.student.ivs.ivs.replace(RouteParams.ASSESSMENT_ID, assessmentId),
      search: `?id=${encodeURIComponent(row.id)}`
    });
  };

  buildStatusBadge = (fragment: MultiSectionsFragment) => {
    const status = this.isIVDiscontinued(fragment) ? FragmentStatus.DISCONTINUED : FragmentStatus.ACTIVE;
    const isStatusBadgeActive = status === FragmentStatus.ACTIVE;
    return { status, isStatusBadgeActive };
  };

  render() {
    const columns = {
      location: {
        field: 'location',
        header: 'Location'
      },
      status: {
        field: 'ivStatus',
        header: 'IVs Status',
        active: FragmentStatus.ACTIVE
      },
      type: {
        field: 'ivType',
        header: 'IV Type'
      }
    };
    const deleteMessage = `Are you sure you want to delete this entry? Note that the most recent IV update will be deleted.
      If there is another record associated with this IV location, the next most recent record will be displayed.`;

    const viewProps = {
      buildStatusBadge: this.buildStatusBadge,
      columns,
      chartTitle: 'Saved IVs Charting',
      deleteMessage,
      hideDelete: true,
      navIds: [SectionNavIdPatterns.INTRAVENOUS],
      onRecordUpdate: this.handleIVUpdate,
      toTableData: this.toTableData,
      ...this.props
    };

    return <SavedPatientWrapper {...viewProps} />;
  }
}

export { SavedIVs as BaseSavedIVs };
export default withRouter(SavedIVs);

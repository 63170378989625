import { HomeMedicationRecord } from 'models/api-response';
import { Locales } from 'constants/app.constant';

export enum FormField {
  INFORMATION_RECEIVED_FROM = 'informationReceivedFrom',
  INFORMATION_RECEIVED_FROM_ORDER = 'informationReceivedFromOther',
  HOME_MEDICATION_TYPE = 'homeMedicationType',
  MEDICATION = 'medication',
  DOSE = 'doseMedications',
  FORM = 'formMedications',
  ROUTE = 'routeMedications',
  FREQUENCY = 'frequencyMedications',
  LAST_DOSE_DATE = 'lastDoseDate',
  LAST_DOSE_TIME = 'lastDoseTime',
  INDICATION = 'indication',
  BRAND_NAME = 'brandName',
  GENERIC_NAME = 'genericName',
  PRODUCT_TYPE = 'productType',
  OVER_THE_COUNTER_PRODUCT_FORM = 'productDetailsFormValue',
  OVER_THE_COUNTER_PRODUCT_DOSE = 'overTheCounterProductsDose',
  OVER_THE_COUNTER_PRODUCT_TAKEN_IN_LAST_24HOURS = 'overTheCounterProductsProductTakenInLast24Hours',
  OVER_THE_COUNTER_PRODUCT_REASON_FOR_TAKING_PRODUCT = 'overTheCounterProductsReasonForTakingProduct',
  OVER_THE_COUNTER_PRODUCT_RESULT_FOLLOWING_USE = 'overTheCounterProductsResultsFollowingUse',
  PRODUCT = 'product',
  PREPARATION = 'preparation',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS_DOSE = 'herbalAndNaturalRemedyProductsDose',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS_ROUTE = 'herbalAndNaturalRemedyProductsRoute',
  STRENGTH = 'strength',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS_TAKEN_IN_LAST_24HOURS = 'herbalAndNaturalRemedyProductsProductTakenInLast24Hours',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS_REASON_FOR_TAKING_PRODUCT = 'herbalAndNaturalRemedyProductsReasonForTakingProduct',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS_RESULT_FOLLOWING_USE = 'herbalAndNaturalRemedyProductsResultsFollowingUse',
  MEDICATION_TIME_OFFSET = 'medicationTimeOffset'
}

export enum FormFieldLabel {
  HOME_MEDICATION_TYPE = 'Home Medication Type',
  MEDICATION = 'Medication',
  DOSE = 'Dose',
  FORM = 'Form',
  ROUTE = 'Route',
  FREQUENCY = 'Frequency',
  LAST_DOSE_DATE = 'Last Dose Date',
  LAST_DOSE_TIME = 'Last Dose Time',
  INDICATION = 'Indication',
  BRAND_NAME = 'Brand Name',
  GENERIC_NAME = 'Generic Name',
  PRODUCT_TYPE = 'Product Type',
  REASON_FOR_TAKING_PRODUCT = 'Reason for taking product',
  RESULT_FOLLOWING_USE = 'Results following use',
  PRODUCT = 'Product',
  PREPARATION = 'Preparation',
  STRENGTH = 'Strength',
  TAKEN_IN_LAST_24HOURS = 'Product taken in last 24 hours?',
  MEDICATION_TIME_OFFSET = 'Medication Time Offset'
}

export enum SectionTitle {
  HOME_MEDICATION_INFORMATION = 'Home Medication Information',
  INFORMATION_RECEIVED_FROM = 'Information Received From',
  HOME_MEDICATION = 'Home Medication',
  PRODUCT_DETAILS = 'Product Details',
  PRESCRIPTION_MEDICATIONS = 'Prescription Medications',
  OVER_THE_COUNTER_PRODUCT = 'Over-the-counter Products',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS = 'Herbal and Natural Remedy Products'
}

export enum HistoryField {
  PRESCRIPTION_MEDICATIONS = 'Prescription Medications',
  OVER_THE_COUNTER_PRODUCTS = 'Over-the-counter Products',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS = 'Herbal and Natural Remedy Products'
}

export interface HomeMedicationHistoryProps {
  chartHistory: HomeMedicationRecord[];
  deleteHistory?: Function;
  locale?: Locales;
}

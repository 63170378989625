export enum FormField {
  IV_DISCONTINUED = 'discontinued',
  LOCATION = 'location',
  BODY_REGION = 'bodyRegion',
  TIME_DISCONTINUED = 'timeDiscontinued',
  CONDITION_OF_CATHETER = 'conditionOfCatheter',
  CONDITION_OF_CATHETER_DESCRIBE = 'conditionOfCatheterDescribe',
  SITE_APPEARANCE = 'siteAppearance',
  TYPE_IV_ACCESS = 'typeIvAccess',
  INSERTION_TIME = 'insertionTime',
  CATHETER_TYPE = 'catheterType',
  CATHETER_SIZE = 'catheterSize',
  NUMBER_INSERTION_ATTEMPTS = 'numberInsertionAttempts',
  TYPE_OF_LINE = 'typeOfLine',
  NUMBER_OF_LUMENS = 'numberOfLumens',
  EXTERNAL_CATHETER_LENGTH = 'externalCatheterLength',
  EXTERNAL_CATHETER_NOT_APPLICABLE = 'externalCatheterNotApplicable',
  SKIN_ASSESSMENT = 'skinAssessment',
  DRESSING_CARE = 'dressingCare',
  PATENCY = 'patency',
  PATIENT_RESPONSE = 'patientResponse',
  PUMP_IN_USE = 'pumpInUse',
  NOTES = 'notes'
}

export enum FormFieldLabel {
  BODY_REGION = 'Body Region',
  LOCATION = 'Location',
  TIME_DISCONTINUED = 'Time Discontinued',
  CONDITION_OF_CATHETER = 'Condition of Catheter',
  CONDITION_OF_CATHETER_DESCRIBE = 'Describe Condition of Catheter',
  SITE_APPEARANCE = 'Site Appearance',
  TYPE_OF_IV_ACCESS = 'Type of IV Access',
  INSERTION_TIME = 'Insertion Time',
  CATHETER_TYPE = 'Catheter Type',
  CATHETER_SIZE = 'Catheter Size',
  NUMBER_INSERTION_ATTEMPTS = 'Number of Insertion Attempts',
  TYPE_OF_LINE = 'Type of Line',
  NUMBER_OF_LUMENS = 'Number of Lumens',
  EXTERNAL_CATHETER_LENGTH = 'External Catheter Length'
}

export enum SectionTitle {
  IV = 'IV',
  SITE_APPEARANCE = 'Site Appearance',
  SKIN_ASSESSMENT = 'Skin Assessment',
  DRESSING_CARE = 'Dressing Care',
  PATENCY = 'Patency',
  PATIENT_RESPONSE = 'Patient Response',
  PUMP_IN_USE = 'Pump in Use',
  NOTES = 'Notes'
}

import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentStatus } from 'models/enum';
import { RouteParams, RoutePath, SectionNavIdPatterns } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { SavedPatientWrapper } from 'components/common';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';
import { FormField, SectionTitle } from '../constants';

interface MatchParams {
  assessmentId: string;
}

interface SavedOstomiessProps {
  enableMultiStepsAuthoring: Function;
}

class SavedOstomies extends Component<SavedOstomiessProps & RouteComponentProps<MatchParams>> {
  static displayName = 'SavedOstomies';

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
  }

  isDiscontinued = (fragment: ChartFragment) => {
    return chartHelper.getFragmentValue(fragment, SectionTitle.OSTOMIES, FormField.DISCONTINUED);
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: dateTimeHelper.toDateTime(fragment.chartingAt),
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      groupFragmentRef: fragment.groupFragmentRef,
      location: chartHelper.getFragmentRecords(fragment, SectionTitle.OSTOMIES, FormField.LOCATION)[0]?.content,
      navElementId: fragment.navElementId,
      ostomyType: chartHelper.getFragmentRecords(fragment, SectionTitle.OSTOMIES, FormField.OSTOMY_TYPE)[0]?.content,
      ostomyStatus: this.isDiscontinued(fragment) ? FragmentStatus.DISCONTINUED : FragmentStatus.ACTIVE
    }));
  };

  handleUpdate = (row) => {
    const { assessmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.student.ostomies.ostomies.replace(RouteParams.ASSESSMENT_ID, assessmentId),
      search: `?id=${encodeURIComponent(row.id)}`
    });
  };

  buildStatusBadge = (fragment: MultiSectionsFragment) => {
    const status = this.isDiscontinued(fragment) ? FragmentStatus.DISCONTINUED : FragmentStatus.ACTIVE;
    const isStatusBadgeActive = status === FragmentStatus.ACTIVE;
    return { status, isStatusBadgeActive };
  };

  render() {
    const columns = {
      location: {
        field: 'location',
        header: 'Location'
      },
      status: {
        field: 'ostomyStatus',
        header: 'Ostomy Status',
        active: FragmentStatus.ACTIVE
      },
      type: {
        field: 'ostomyType',
        header: 'Ostomy Type'
      }
    };
    const deleteMessage = `Are you sure you want to delete this entry? Note that the most recent Ostomies update will be deleted.
      If there is another record associated with this Ostomies location, the next most recent record will be displayed.`;

    const viewProps = {
      buildStatusBadge: this.buildStatusBadge,
      columns,
      chartTitle: 'Saved Ostomies Charting',
      deleteMessage,
      hideDelete: true,
      navIds: [SectionNavIdPatterns.OSTOMIES],
      onRecordUpdate: this.handleUpdate,
      toTableData: this.toTableData,
      ...this.props
    };
    return <SavedPatientWrapper {...viewProps} />;
  }
}

export { SavedOstomies as BaseSavedOstomies };
export default withRouter(SavedOstomies);

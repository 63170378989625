/* eslint-disable-next-line jsx-a11y/label-has-associated-control */
import { LanguageKeys } from 'lang';
import { ChartTable } from 'models/ui';
import { ChartLabel, NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartHistory, ErrorCard, ErrorFormField, InfoText, RelatedCharts, SectionDivider } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { ButtonLabel, Title } from 'components/features/chart/system-assessment/shared/constants';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import './respiratory.assessment.scss';

export interface RespiratoryAssessmentAUViewProps extends ChartViewProps {
  addLocation: Function;
  addLocationEnabled: boolean;
  clearLocations: Function;
  clearLocationsEnabled: boolean;
  deleteLungAuscultationLocation: Function;
  lungAuscultationLocationTableData: ChartTable;
  lungAuscultationLabel: string;
}

const RespiratoryAssessmentAUView = (props: RespiratoryAssessmentAUViewProps) => {
  const {
    addLocation,
    addLocationEnabled,
    chartActionsProps,
    clearLocations,
    clearLocationsEnabled,
    deleteLungAuscultationLocation,
    formSubmittedCount,
    formFieldMap,
    lungAuscultationLabel,
    lungAuscultationLocationTableData,
    resetAll,
    intl
  } = props;

  return (
    <div className="respiratory-assessment">
      <h1>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_ASSESSMENT })}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt3>
        <h2>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.ARTIFICIAL_AIRWAY })}</h2>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.AIRWAY_IN_USE)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.AIRWAY_TYPE)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.AIRWAY_TYPE_OTHER)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.AIRWAY_TYPE_CUFFED_UNCUFFED)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <p className="u-els-color-n6">{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.DOCUMENT_ASSESSMENT_AND_CARE_ON_GRAPHICAL_BODY_DRAINS_TUBES })}</p>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt2>
              <ErrorFormField formField={formFieldMap.get(FormField.AIRWAY_SIZE)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt2>
              <ErrorFormField formField={formFieldMap.get(FormField.AIRWAY_COMMENTS)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.INSPECTION })}</h2>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o5" md="1o1">
            <Box mt2>
              <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_RATE })}</h3>
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.RESPIRATORY_RATE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </Box>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="2o5" md="1o1">
            <Box mt2>
              <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_PATTERN })}</h3>
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.RESPIRATORY_PATTERN)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </Box>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="2o5" md="1o1">
            <Box mt2>
              <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_EFFORT })}</h3>
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.RESPIRATORY_EFFORT)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </Box>
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o1" md="1o1">
            <Box mt>
              <h3>Colour</h3>
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.COLOUR)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </Box>
          </ELSFlexItem>
        </ELSFlex>

        <Box mt>
          <ELSFlex className="u-flex-grid-2x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.OXYGENATION })}</h3>
              <Box mt>
                <ELSFlex wrap left>
                  <ELSFlexItem>
                    <ErrorFormField formField={formFieldMap.get(FormField.OXYGEN_SATURATION_SPO2)} formSubmittedCount={formSubmittedCount} />
                  </ELSFlexItem>
                </ELSFlex>
                <ErrorFormField formField={formFieldMap.get(FormField.OXYGENATION_EVALUATION)} formSubmittedCount={formSubmittedCount} />
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.OXYGEN_IN_USE)} formSubmittedCount={formSubmittedCount} />
                  <ELSFlex wrap left>
                    <ELSFlexItem className="amount-oxygen" w="1o2">
                      <ErrorFormField formField={formFieldMap.get(FormField.OXYGEN_AMOUNT)} formSubmittedCount={formSubmittedCount} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2">
                      <div className="text-box">
                        <ErrorFormField formField={formFieldMap.get(FormField.OXYGEN_LMIN)} formSubmittedCount={formSubmittedCount} />
                      </div>
                    </ELSFlexItem>
                  </ELSFlex>
                </Box>

                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.OXYGEN_DELIVERY_SYSTEM)} formSubmittedCount={formSubmittedCount} />

                <div>
                  <ErrorFormField formField={formFieldMap.get(FormField.OXYGENATION_NOTE)} formSubmittedCount={formSubmittedCount} />
                </div>

                <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_SUPPORT })}</h3>
                <Box mt>
                  <div>
                    <label htmlFor={intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_SUPPORT })}>{ChartLabel.MARK_ALL_THAT_APPLY}</label>
                  </div>
                  <ErrorFormField formField={formFieldMap.get(FormField.OXYGENATION_SUPPORT)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt>
                  <div className="text-box">
                    <ErrorFormField formField={formFieldMap.get(FormField.OXYGENATION_SUPPORT_INCENTIVE_ML)} formSubmittedCount={formSubmittedCount} />
                  </div>
                </Box>
              </Box>
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.SUCTIONING })}</h3>
              <Box mt2>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_AMOUNT)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_AMOUNT_OTHER)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_COLOR)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_COLOR_OTHER)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_CONSISTENCY)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_CONSISTENCY_OTHER)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_ODOR)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <Box mt2>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUCTIONING_ODOR_OTHER)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </Box>
            </ELSFlexItem>
          </ELSFlex>
        </Box>

        <SectionDivider />

        <h2>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.LUNG_AUSCULTATION })}</h2>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <InfoText title={Title.INFO_TEXT_TITLE} text={intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.INFO_TEXT })} />
            <Box mt2>
              <Box mt2>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.LOCATION)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </Box>
            <Box mt2>
              <p className="chart-input__label u-els-margin-bottom">{lungAuscultationLabel}</p>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.SOUNDS)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt2>
              <ErrorFormField formField={formFieldMap.get(FormField.WHEEZE_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <ErrorFormField formField={formFieldMap.get(FormField.LUNG_NOTE)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="sng-form-buttons" left wrap>
              <ELSButton type="secondary" text={ButtonLabel.ADD_LOCATION} isDisabled={!addLocationEnabled} onClick={addLocation} />
              <ELSButton type="secondary" text={ButtonLabel.CLEAR} isDisabled={!clearLocationsEnabled} onClick={clearLocations} />
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-grid-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.LUNG_ANTERIOR_IMG)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.LUNG_LATERAL_LEFT_IMG)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.LUNG_LATERAL_RIGHT_IMG)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.LUNG_POSTERIOR_IMG)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex left>
          <ELSFlexItem w="2o3">
            <ChartHistory dataSource={lungAuscultationLocationTableData} deleteRecord={deleteLungAuscultationLocation} showNumEntries={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <Box mt>
              <h2>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.CHEST_TUBE })}</h2>
              <Box mt>
                <ErrorFormField formField={formFieldMap.get(FormField.CHEST_TUBE)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <p className="u-els-color-n6">{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.DOCUMENT_ASSESSMENT_AND_CARE_ON_GRAPHICAL_BODY_DRAINS_TUBES })}</p>
            </Box>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h2>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.COUGH })}</h2>
            <Box mt2>
              <ErrorFormField formField={formFieldMap.get(FormField.COUGH_TYPE)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt2>
              <ErrorFormField formField={formFieldMap.get(FormField.COUGH_STRENGTH)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box>
              <ErrorFormField formField={formFieldMap.get(FormField.COUGH_NOTE)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt2>
              <h3>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.PRODUCTIVE_COUGH_ASSESSMENT })}</h3>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_AMOUNT)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_AMOUNT_OTHER)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_COLOR)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_COLOR_OTHER)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_CONSISTENCY)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_CONSISTENCY_OTHER)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_SECRETION_ODOR)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt2>
                <ErrorFormField formField={formFieldMap.get(FormField.PROD_COUGH_SECRETION_ODOR_OTHER)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </Box>
          </ELSFlexItem>

          <ELSFlexItem w="1o1" md="1o1">
            <Box mt>
              <h2>{intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.GAG_REFLEX })}</h2>
              <Box mt>
                <ErrorFormField formField={formFieldMap.get(FormField.GAG_REFLEX)} formSubmittedCount={formSubmittedCount} />
              </Box>
              <Box mt>
                <ErrorFormField formField={formFieldMap.get(FormField.GAG_REFLEX_NOT_PRESENT_OPTIONS)} formSubmittedCount={formSubmittedCount} />
              </Box>

              <Box mt>
                <ErrorFormField formField={formFieldMap.get(FormField.GAG_REFLEX_NOT_PRESENT_OTHER)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </Box>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.RESPIRATORY_INTERVENTIONS]} />
    </div>
  );
};

RespiratoryAssessmentAUView.displayName = 'RespiratoryAssessmentAUView';
export default RespiratoryAssessmentAUView;

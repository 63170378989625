import { sumBy } from 'lodash';
import { ChartMetaFormField, ColumnAlign } from 'models/ui';
import { ELSDataTable } from 'components/common/els';

export interface TotalScoreTableProps {
  scaleQuestions: ChartMetaFormField[];
}
enum EToTalScoreType {
  OneMinutes = '1Minutes',
  FiveMinutes = '5Minutes',
  TenMinutes = '10Minutes'
}

const TotalScoreTable = (props: TotalScoreTableProps) => {
  const headers = [
    { text: '1 minute', field: EToTalScoreType.OneMinutes, align: ColumnAlign.Left },
    { text: '5 minute', field: EToTalScoreType.FiveMinutes, align: ColumnAlign.Left },
    { text: '10 minute', field: EToTalScoreType.TenMinutes, align: ColumnAlign.Left }
  ];

  const getTotalScoreByType = (type: EToTalScoreType) =>
    sumBy(
      props?.scaleQuestions.filter((item) => item.name.includes(type)),
      (question) => Number(question.extraData.score?.value) || 0
    );

  const totalScore = {
    [EToTalScoreType.OneMinutes]: getTotalScoreByType(EToTalScoreType.OneMinutes),
    [EToTalScoreType.FiveMinutes]: getTotalScoreByType(EToTalScoreType.FiveMinutes),
    [EToTalScoreType.TenMinutes]: getTotalScoreByType(EToTalScoreType.TenMinutes)
  };

  return (
    <ELSDataTable data={[totalScore]}>
      {headers.map((col) => (
        <column key={`${col.field}`} field={col.field} header={col.text} align={col.align} />
      ))}
    </ELSDataTable>
  );
};

TotalScoreTable.displayName = 'TotalScoreTable';
export default TotalScoreTable;

import { ReactNode } from 'react';
import './nothing.to.see.scss';

interface NothingToSeeProps {
  children: ReactNode;
}

const NothingToSee = (props: NothingToSeeProps) => (
  <div className="pre-clinical-manager__nothing-to-see">
    <h3>{props.children}</h3>
  </div>
);

NothingToSee.displayName = 'NothingToSee';
export default NothingToSee;

import { ChartFragment } from 'models/api-response';
import { dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { ELSFlex, ELSFlexItem } from 'components/common/els';

export interface HeadlineProps {
  chartingFragment?: ChartFragment;
  statusFragment: ChartFragment;
  hiddenCreatedBy?: boolean;
}

const Headline = (props: HeadlineProps) => {
  const { creator, chartingAt } = props.chartingFragment || /* istanbul ignore next */ {};
  const { modifier: completedBy, completedAt } = props.statusFragment;

  return (
    <div className="sng-headline">
      <ELSFlex left gutters className="o-els-flex-layout--gutters-3x">
        {!props.hiddenCreatedBy && (
          <ELSFlexItem>
            {'Created By: '}
            <b>{creator && chartingAt ? `${chartService.formatName(creator)} ${dateTimeHelper.toDateTime(chartingAt)}` : 'N/A'}</b>
          </ELSFlexItem>
        )}
        <ELSFlexItem>
          {'Completed By: '}
          <b>{completedBy && completedAt ? `${chartService.formatName(completedBy)} ${dateTimeHelper.toDateTime(completedAt)}` : 'N/A'}</b>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  );
};

Headline.displayName = 'Headline';
export default Headline;

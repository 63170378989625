import cx from 'classnames';
import { ReactNode, createElement } from 'react';
import { Badge } from 'components/common';

export interface SectionHeaderWithBadgeProps {
  value: string | ReactNode;
  className?: string | { [key: string]: boolean };
  isShowBadge?: boolean;
  badgeValue?: string | number | ReactNode;
  badgeClassName?: string | { [key: string]: boolean };
  tag?: keyof HTMLElementTagNameMap;
}

export default function SectionHeaderWithBadge({ isShowBadge, className, badgeClassName, badgeValue, value, tag = 'h4' }: SectionHeaderWithBadgeProps) {
  const headingElement = createElement(tag, { className: cx('u-els-display-inline', className) }, value);

  return (
    <div className="chart-input__section-title">
      {headingElement}
      {isShowBadge && <Badge className={cx('u-els-font-size-meta u-els-padding-left-1x u-els-padding-right-1x c-els-label__badge', badgeClassName)}>{badgeValue}</Badge>}
    </div>
  );
}

import { Component } from 'react';
import { ChartFragment } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Box, ChartHistory } from 'components/common';

export interface GeneralOrdersTableProps {
  chartHistory: ChartFragment[];
  deleteHistory: Function;
}

class GeneralOrdersTable extends Component<GeneralOrdersTableProps> {
  static displayName = 'GeneralOrdersTable';

  orderTimeRenderer = ({ orderTime }) => {
    return <div className="general-orders-table__order-time-field">{orderTime || ''}</div>;
  };

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true, customRender: this.orderTimeRenderer },
        { text: 'Order Description', field: 'orderDescription', align: ColumnAlign.Left },
        { text: 'Order Details', field: 'orderDetails', align: ColumnAlign.Left },
        { field: 'actionDelete' }
      ],
      css: ['column__chart-time', '', '', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt className="general-orders-table">
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

export default GeneralOrdersTable;

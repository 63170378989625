import { Component } from 'react';
import { LaborProgressRecord } from 'models/api-response';
import { ChartMetaFormField, DropDownItem } from 'models/ui';
import { HistoryField } from './constants';
import LaborProgressHistoryView, { LaborProgressHistoryViewProps } from './LaborProgressHistoryView';
import './labor.progress.history.scss';

export interface LaborProgressHistoryProps {
  chartHistory: LaborProgressRecord[];
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  deleteHistory: Function;
}

interface LaborProgress {
  selectedHistory: string;
}

class LaborProgressHistory extends Component<LaborProgressHistoryProps, LaborProgress> {
  charts: DropDownItem[] = [];

  constructor(props) {
    super(props);
    this.state = {
      selectedHistory: HistoryField.FETAL_ASSESSMENT
    };
    this.charts = [
      { name: 'Fetal Assessment', value: HistoryField.FETAL_ASSESSMENT },
      { name: 'Uterine Activity', value: HistoryField.UTERINE_ACTIVITY },
      { name: 'Vaginal Assessment', value: HistoryField.VAGINAL_ASSESSMENT }
    ];
  }

  onHistoryChange = ({ target: { value } }) => {
    this.setState({ selectedHistory: value });
  };

  render() {
    const { selectedHistory } = this.state;
    const viewProps: LaborProgressHistoryViewProps = {
      charts: this.charts,
      chartHistory: this.props.chartHistory,
      deleteHistory: this.props.deleteHistory,
      selectedHistory,
      onHistoryChange: this.onHistoryChange
    };
    return <LaborProgressHistoryView {...viewProps} />;
  }
}

export default LaborProgressHistory;

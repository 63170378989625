/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cx from 'classnames';
import React, { Component, ReactNode, RefObject } from 'react';
import { accessibilityHelper } from 'helpers';
import { Box, ButtonClose } from 'components/common';
import { isNil } from 'lodash/fp';
import './sidebar.scss';

export const SIDEBAR_CONTAINER_CLASSNAME = 'sidebar__container';

export interface SidebarProps {
  open: boolean;
  children: ReactNode;
  className?: string;
  onClose: Function;
  onAfterClose?: Function;
  stickyElement?: ReactNode;
  requireSave?: boolean;
  lastEnabledElement?: number;
  sidebarProps?: Function;
  hideCloseButton?: boolean;
  preventAutoPending?: boolean;
}

interface SidebarState {
  isInitial: boolean;
  focusableElements: NodeListOf<HTMLElement> | null;
  sidebarFocused: boolean;
  saveEnabled: boolean;
}

class Sidebar extends Component<SidebarProps, SidebarState> {
  sidebarRef: RefObject<HTMLDivElement>;
  static displayName = 'Sidebar';

  constructor(props) {
    super(props);
    this.state = { isInitial: true, focusableElements: null, sidebarFocused: false, saveEnabled: false };
    this.sidebarRef = React.createRef();
  }

  componentDidMount(): void {
    this.sidebarRef.current.onanimationend = (event) => {
      if (event.animationName === 'sidebarSlideOut' && this.props.onAfterClose) {
        this.props.onAfterClose();
      }
      if (this.props.open) {
        this.setFocusTop();
        this.setState({ sidebarFocused: false });
      }
    };
  }

  componentDidUpdate(prevProps: Readonly<SidebarProps>) {
    if (prevProps.open !== this.props.open) {
      document.body.style.overflowY = this.props.open ? 'hidden' : '';
      if (this.props.sidebarProps) {
        this.props.sidebarProps().setIsChartSidebarOpen(this.props.open);
      }

      if (this.state.isInitial) {
        this.setState({ isInitial: false });
      }

      const animationDuration = 1000;
      setTimeout(() => {
        document.querySelector('.sidebar__container').scrollTop = 0;
      }, animationDuration);
      this.setState({ focusableElements: this.props.open ? this.sidebarRef.current.querySelector('.sidebar__container').querySelectorAll('*') : null });
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = '';
    document.body.classList.remove('scroll-bounce-custom');
  }

  handleOutsideClick = (event) => {
    if (this.props.onClose) {
      this.props.onClose(event);
    }
    this.setState({ sidebarFocused: false });
  };

  handleContainerClick = (event) => event.stopPropagation();

  // Set focus to top of sidebar immediately when using tab
  setFocusTop = () => {
    const { focusableElements, sidebarFocused } = this.state;
    // 2 is the first focusable element in the sidebar
    // example: Close Button
    const firstFocusableElement = focusableElements[2];
    this.setState({ saveEnabled: false });

    if (!sidebarFocused) {
      setTimeout(() => {
        firstFocusableElement.focus();
      }, 500);
      this.setState({ sidebarFocused: true });
    }
  };

  // Lock focus inside sidebar when using tab
  handleKeyboardFocus = (event) => {
    const { onClose, open } = this.props;
    const { saveEnabled } = this.state;

    accessibilityHelper.handleEscapeKey(event, onClose);

    // Must explicitly check that save is enabled, execute code, and break
    // Otherwise logic will switch back and forth between enabled and disabled multiple times
    if (open && saveEnabled) {
      this.setFocus(event);
    }
    if (open && !saveEnabled) {
      this.setFocus(event);
    }
  };

  setFocus = (event) => {
    const { focusableElements } = this.state;
    const lastFocusableElement = this.getLastFocusableElement();
    const tab = event.key === 'Tab';
    // 2 is the first focusable element in the sidebar,
    // example: Close Button
    const firstFocusableElement = focusableElements[2];
    const currentFocusedElement = document.activeElement;

    if (tab && currentFocusedElement === lastFocusableElement) {
      event.preventDefault();
      firstFocusableElement.focus();
    }

    // Block exiting sidebar on tab + shift combination
    if (tab && event.shiftKey && currentFocusedElement === firstFocusableElement) {
      lastFocusableElement.focus();
    }
  };

  getLastFocusableElement = (): HTMLElement => {
    const { lastEnabledElement } = this.props;
    const { focusableElements } = this.state;
    // length is subtracted by the last focusable element in the sidebar
    // example: when save is enabled - 2 (save element), when save is not enabled - 4 (which is an input element)
    const saveEnabledElement = isNil(focusableElements) ? null : focusableElements[focusableElements.length - 2];
    const lastFocusableElement = isNil(focusableElements) ? null : focusableElements[focusableElements.length - lastEnabledElement];

    // when lastEnabledElement is saveEnabledElement
    if (saveEnabledElement && lastEnabledElement === 2) {
      this.setState({ saveEnabled: true });
      return saveEnabledElement;
    }
    return lastFocusableElement;
  };

  render() {
    const { open, onClose, className, children, stickyElement, requireSave, hideCloseButton = false, preventAutoPending = false } = this.props;

    return (
      <div
        ref={this.sidebarRef}
        onClick={this.handleOutsideClick}
        onKeyDown={this.handleKeyboardFocus}
        className={cx('sidebar', open ? 'show' : 'hide', {
          animated: !this.state.isInitial,
          clickable: !!onClose,
          'require-save-sidebar': requireSave
        })}
      >
        <div onClick={this.handleContainerClick} className={cx(`${SIDEBAR_CONTAINER_CLASSNAME} animated`, className)}>
          <div className="sidebar__content-wrapper" role="dialog" aria-modal="true" aria-label="Edit Screen">
            {!hideCloseButton && (
              <Box mt>
                <ButtonClose preventAutoPending={preventAutoPending} onClick={onClose} />
              </Box>
            )}
            {children}
          </div>
          {stickyElement}
        </div>
      </div>
    );
  }
}

export default Sidebar;

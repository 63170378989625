import { PageHeader, withHTMLHeadSEO } from 'components/common';
import BlankChartingInstruction from 'components/features/blank-charting-instruction/BlankChartingInstruction';

const BlankChartingInstructionPage = () => (
  <>
    <PageHeader title="SimChart Assignment" />
    <BlankChartingInstruction />
  </>
);

export { BlankChartingInstructionPage as BaseBlankChartingInstructionPage };
export default withHTMLHeadSEO({ title: 'Blank Charting Instruction' })(BlankChartingInstructionPage);

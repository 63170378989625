/* eslint-disable @typescript-eslint/no-unused-vars */
import { SPECIAL_DOSAGE_MAPPING_RULES } from './constants';

type DosageTime = {
  timeMapping: string[];
  type: number;
  isTopOfTheHour?: boolean;
  minutesAfterStartTime?: number;
  isStop?: Function;
  repeatTimes?: number;
  minutesApart?: number;
  days?: string[];
  times?: string[];
  administrationTimes?: number;
  isIgnored?: Function;
};

export type DosageTimeMap = Map<string, DosageTime>;

const MAX_REPEATS = 9999;
const dosageTimesMapping: DosageTimeMap = new Map();
dosageTimesMapping.set('1 Time/Day After Meal', { timeMapping: ['13:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose May Repeat Every 6 Hours', { timeMapping: ['09:00', '15:00', '21:00', '03:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose Repeat in 7 days', { timeMapping: ['09:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose 60 Min Before Sexual Activity', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose After Each Loose Stool', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose Bedtime PRN', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose Before Each UV Treatment', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose Daily PRN', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('1 Dose Each Year', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('2 Times/Day', { timeMapping: ['08:00', '21:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('2 Times/Day After Meals', { timeMapping: ['08:00', '21:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('2 Times/Day Before Meals', { timeMapping: ['07:00', '17:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('3 Times/Day', { timeMapping: ['08:00', '14:00', '21:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('3 Times/Day Before Meals', { timeMapping: ['07:00', '11:00', '16:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('3 Times/Day After Meals', { timeMapping: ['09:00', '13:00', '18:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('3 Times/Day with Meals', { timeMapping: ['08:00', '12:00', '17:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('3 Times/Day Before Meals PRN', { timeMapping: ['07:00', '11:00', '16:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('3 Times/Day PRN', { timeMapping: ['08:00', '14:00', '21:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('4 Times/Day with Meals', { timeMapping: ['08:00', '12:00', '16:00', '21:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('4 Times/Day', { timeMapping: ['08:00', '13:00', '17:00', '21:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('5 Times/Day', { timeMapping: ['07:00', '11:00', '15:00', '19:00', '23;00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('6 Times/Day', { timeMapping: ['06:00', '09:00', '12:00', '16:00', '19;00', '2300'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('After Meals and at Bedtime', { timeMapping: ['09:00', '13:00', '18:00', '22:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Before Meals and at Bedtime', { timeMapping: ['07:00', '11:00', '16:00', '22:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily AM', { timeMapping: ['10:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily PRN', { timeMapping: ['Administer'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily Before Breakfast', { timeMapping: ['06:30'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily after Lunch', { timeMapping: ['12:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily Upon Awakening', { timeMapping: ['06:30'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily at Bedtime', { timeMapping: ['22:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily at Mid-Morning', { timeMapping: ['10:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily in the Evening', { timeMapping: ['19:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily with Breakfast', { timeMapping: ['07:30'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily with Dinner', { timeMapping: ['17:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Daily', { timeMapping: ['09:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 12 Hours', { timeMapping: ['08:00', '20:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 4 Hours while Awake', { timeMapping: ['12:00', '16:00', '20:00', '08:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 4 Hours', { timeMapping: ['04:00', '08:00', '12:00', '16:00', '20:00', '00:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 5 Hours', { timeMapping: ['09:00', '14:00', '19:00', '00:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 6 Hours', { timeMapping: ['06:00', '12:00', '18:00', '00:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 7 Hours', { timeMapping: ['09:00', '16:00', '23:00', '00:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every 8 Hours', { timeMapping: ['00:00', '08:00', '16:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every AM', { timeMapping: ['09:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Every Bedtime', { timeMapping: ['22:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Once daily', { timeMapping: ['09:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });
dosageTimesMapping.set('Twice Daily', { timeMapping: ['08:00', '20:00'], type: SPECIAL_DOSAGE_MAPPING_RULES.NORMAL });

// AFTER_START_TIME
dosageTimesMapping.set('1 Dose May Repeat in 1 Hour if Needed', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  minutesApart: 60,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('1 Dose May Repeat in 1 Hour in Other Nostril if Needed', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  minutesApart: 60,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('2 Dose', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 2,
  minutesApart: 0,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('1 Dose May Repeat in 2 Hours in Other Nostril if Needed', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  minutesApart: 120,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('1 Dose', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === 1,
  repeatTimes: 0,
  minutesApart: 0,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('1 Dose (dilute to 10% solution)', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === 1,
  repeatTimes: 0,
  minutesApart: 0,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('1 Dose (dilute to 25% solution)', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 0,
  isStop: (repeatTimes, index) => false,
  repeatTimes: 0,
  minutesApart: 60,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('Every 15 Minutes x 2 Doses', {
  timeMapping: [],
  isTopOfTheHour: false,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === 2,
  repeatTimes: 0,
  minutesApart: 15,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('Every 1 Hour', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === 2,
  repeatTimes: 0,
  minutesApart: 0,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('Every 2 Hours', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 60,
  isStop: (repeatTimes, index) => false,
  repeatTimes: 0,
  minutesApart: 120,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('Every 2 Minutes x 3 Doses', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 60,
  isStop: (repeatTimes, index) => index === 3,
  repeatTimes: 0,
  minutesApart: 2,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

dosageTimesMapping.set('Every 3 Hours', {
  timeMapping: [],
  isTopOfTheHour: true,
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => false,
  repeatTimes: 0,
  minutesApart: 180,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_START_TIME
});

// TIMES_IN_A_WEEK
dosageTimesMapping.set('5 Times/Week at Bedtime', {
  timeMapping: [],
  days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Saturday', 'Sunday'],
  times: ['22:00'],
  type: SPECIAL_DOSAGE_MAPPING_RULES.TIMES_IN_A_WEEK
});

// AFTER_FIRST_DOSE
dosageTimesMapping.set('1 Dose May Repeat Every 1 Hour for x 1', {
  timeMapping: [],
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 0,
  minutesApart: 60,
  administrationTimes: 2,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat Every 1 Hour for x 2', {
  timeMapping: [],
  minutesApart: 60,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 0,
  administrationTimes: 3,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat in 2 Hours if Needed', {
  timeMapping: [],
  minutesAfterStartTime: 180,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  minutesApart: 120,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat Every 12 Hours', {
  timeMapping: [],
  minutesApart: 720,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose PRN; May Repeat in 15 Minutes for Total Dose of 2 Units', {
  timeMapping: [],
  minutesApart: 15,
  isStop: (repeatTimes, index) => index === 2,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat Every 2 Hours if needed', {
  timeMapping: [],
  minutesApart: 120,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose Repeat Every 90 Minutes', {
  timeMapping: [],
  minutesApart: 90,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat Every 3 Hours if needed', {
  timeMapping: [],
  minutesApart: 180,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat Every 4 Hours if needed', {
  timeMapping: [],
  minutesApart: 240,
  isStop: (repeatTimes, index) => false,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat in 30 Mins if Needed', {
  timeMapping: [],
  minutesApart: 30,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat in 5 Min if Needed', {
  timeMapping: [],
  minutesApart: 5,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat Every 6 Hours PRN for 2 More Doses', {
  timeMapping: [],
  minutesApart: 360,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 2,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat in 8-12 Hours', {
  timeMapping: [],
  minutesApart: 8,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat in 10-15 Mins if Needed', {
  timeMapping: [],
  minutesApart: 10,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat x 1 in 2 Hours', {
  timeMapping: [],
  minutesApart: 0,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose May Repeat x 1 in 4 Hours if Needed', {
  timeMapping: [],
  minutesApart: 0,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('1 Dose Repeat in 24 Hours', {
  timeMapping: [],
  minutesApart: 1440,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('15 minutes before and 2 hours after chemotherapy', {
  timeMapping: [],
  minutesApart: 120,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('Every 1 Hour PRN', {
  timeMapping: [],
  minutesApart: 60,
  isStop: (repeatTimes, index) => false,
  repeatTimes: 1,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 10 Hours PRN', {
  timeMapping: [],
  minutesApart: 600,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});
dosageTimesMapping.set('Every 11 Hours PRN', {
  timeMapping: [],
  minutesApart: 660,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});
dosageTimesMapping.set('Every 12 Hours PRN', {
  timeMapping: [],
  minutesApart: 720,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 2 Hours PRN', {
  timeMapping: [],
  minutesApart: 120,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 20 Minutes x 3 PRN', {
  timeMapping: [],
  minutesApart: 20,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 3,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 3 Hours PRN', {
  timeMapping: [],
  minutesApart: 180,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 3 Min PRN', {
  timeMapping: [],
  minutesApart: 3,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 15min for 2 Doses PRN', {
  timeMapping: [],
  minutesApart: 15,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 2,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 3 Minutes PRN', {
  timeMapping: [],
  minutesApart: 3,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 4 Hours PRN', {
  timeMapping: [],
  minutesApart: 240,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 48 Hours', {
  timeMapping: [],
  minutesApart: 2880,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 5 Hours PRN', {
  timeMapping: [],
  minutesApart: 300,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 5 Minutes PRN', {
  timeMapping: [],
  minutesApart: 5,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 5 Minutes up to 3 Doses PRN', {
  timeMapping: [],
  minutesApart: 5,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 3,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 5 Minutes up to 3 Doses', {
  timeMapping: [],
  minutesApart: 5,
  isStop: (repeatTimes, index) => index === repeatTimes,
  repeatTimes: 3,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 6 Hours PRN', {
  timeMapping: [],
  minutesApart: 600,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 7 Hours PRN', {
  timeMapping: [],
  minutesApart: 660,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 8 Hours PRN', {
  timeMapping: [],
  minutesApart: 480,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every 9 Hours PRN', {
  timeMapping: [],
  minutesApart: 540,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every Bedtime PRN', {
  timeMapping: [],
  minutesApart: 1440,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('Every other day PRN', {
  timeMapping: [],
  minutesApart: 2880,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF
});

dosageTimesMapping.set('May Repeat Every 6 Hours', {
  timeMapping: [],
  minutesApart: 360,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('May Repeat in 2 Hours', {
  timeMapping: [],
  minutesApart: 120,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('May Repeat in 4 Hours', {
  timeMapping: [],
  minutesApart: 240,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

dosageTimesMapping.set('Repeat Every 4 Hours PRN', {
  timeMapping: [],
  minutesApart: 240,
  isStop: (repeatTimes, index) => false,
  repeatTimes: MAX_REPEATS,
  type: SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE
});

// ONCE_THEN_DROP_OFF
dosageTimesMapping.set('Once', {
  timeMapping: ['09:00'],
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF,
  isIgnored: (administerDosageTimes, dosageDate) => administerDosageTimes?.find((dosageTime) => dosageTime.administeredDate === dosageDate)
});

dosageTimesMapping.set('1 Dose PRN', {
  timeMapping: ['Administer'],
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF,
  isIgnored: (administerDosageTimes, dosageDate) => administerDosageTimes?.find((dosageTime) => dosageTime.administeredDate === dosageDate)
});

dosageTimesMapping.set('Infuse over 16 hours', {
  timeMapping: ['Administer'],
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF,
  isIgnored: (administerDosageTimes, dosageDate) => administerDosageTimes?.find((dosageTime) => dosageTime.administeredDate === dosageDate)
});

dosageTimesMapping.set('Infuse over 4 hours', {
  timeMapping: ['Administer'],
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF,
  isIgnored: (administerDosageTimes, dosageDate) => administerDosageTimes?.find((dosageTime) => dosageTime.administeredDate === dosageDate)
});

dosageTimesMapping.set('Infuse over 60 minutes', {
  timeMapping: ['Administer'],
  type: SPECIAL_DOSAGE_MAPPING_RULES.ONCE_THEN_DROP_OFF,
  isIgnored: (administerDosageTimes, dosageDate) => administerDosageTimes?.find((dosageTime) => dosageTime.administeredDate === dosageDate)
});

export default dosageTimesMapping;

import { uniqBy } from 'lodash';
import { v4 } from 'uuid';
import { ErrorField } from 'models/ui';
import ErrorMessage from './ErrorMessage';

interface ErrorMessagesProps {
  errors: ErrorField[];
  errorClassName?: string;
}

const ErrorMessages = (props: ErrorMessagesProps) => {
  const { errors, errorClassName } = props;

  if (!errors.length) {
    return null;
  }

  return (
    <>
      {uniqBy(errors, 'message').map((error: ErrorField) => (
        <ErrorMessage key={`error-message-${error.field}-${v4()}`} hasError className={errorClassName} message={error.message} />
      ))}
    </>
  );
};

ErrorMessages.displayName = 'ErrorMessages';
export default ErrorMessages;

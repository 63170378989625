import cx from 'classnames';
import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { IntlShape } from 'react-intl';
import { HeightWeightRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ChartHistory } from 'components/common';
import { ELSDropDown } from 'components/common/els';
import EntryBy from 'components/common/entry-by/EntryBy';
import { CHARTS_OPTIONS, HEIGHT_WEIGHT_CHARTS } from './constants';
import '../vital.signs.common.scss';

interface HeightWeightHistoryProps {
  chartHistory: HeightWeightRecord[];
  chartMetaContentMap?: Map<string, string>;
  deleteHistory: Function;
  infantContainer: Function;
  intl: IntlShape;
  locale: Locales;
}

interface HeightWeightHistoryState {
  selectedChart: string;
}

class HeightWeightHistory extends Component<HeightWeightHistoryProps, HeightWeightHistoryState> {
  constructor(props) {
    super(props);
    this.state = {
      selectedChart: HEIGHT_WEIGHT_CHARTS.HEIGHT_LENGTH_WEIGHT
    };
  }

  heightRenderer = ({ height, active }) => {
    if (height) {
      const { centimeters, feet, inches } = height;
      const displayFeet = feet && `${feet}' `;
      const displayInches = inches && `${inches}" `;
      const heightDisplay = displayFeet || displayInches ? `${displayFeet}${displayInches}/ ${centimeters} cm` : `${centimeters} cm`;

      return <div className={cx({ 'row--deleted': !active })}>{heightDisplay}</div>;
    }
    return '';
  };

  weightRenderer = ({ weight, active }) => {
    const { intl } = this.props;
    if (weight) {
      const { pounds, ounces, kilograms, grams, weightAdmission } = weight;
      const displayPounds = pounds && `${pounds} lb `;
      const displayOunces = ounces && `${ounces} oz `;
      const displayKilograms = kilograms && `${kilograms} kg `;
      const displayGrams = grams && `${grams} g`;
      const weightDisplay = intl.formatMessage(
        { id: LanguageKeys.HEIGHT_WEIGHT.WEIGHT_DISPLAY },
        { poundsAndOunces: `${displayPounds}${displayOunces}`, kilogramsAndGrams: `${displayKilograms}${displayGrams}` }
      );

      return (
        <div className={cx({ 'row--deleted': !active })}>
          {weightDisplay}
          {weightAdmission && ' Adm'}
        </div>
      );
    }
    return '';
  };

  methodRenderer = ({ weight, active }) => {
    if (!weight) {
      return '';
    }
    const { choice } = weight;
    return <div className={cx({ 'row--deleted': !active })}>{chartHelper.findContentFromMap(this.props.chartMetaContentMap, choice)}</div>;
  };

  entryByRenderer = (record) => <EntryBy record={record} />;

  abdominalGirthRenderer = ({ abdominalGirth, active }) => {
    if (abdominalGirth) {
      const { abdominalGirthCm, abdominalGirthInch } = abdominalGirth;
      const heightDisplay = `${abdominalGirthInch}"/ ${abdominalGirthCm} cm`;
      return <div className={cx({ 'row--deleted': !active })}>{heightDisplay}</div>;
    }
    return '';
  };

  headCircumferenceRenderer = ({ headCircumference, active }) => {
    if (headCircumference) {
      const { headCircumferenceCm, headCircumferenceInch } = headCircumference;
      const display = `${headCircumferenceCm}"/ ${headCircumferenceInch} cm`;
      return <div className={cx({ 'row--deleted': !active })}>{display}</div>;
    }
    return '';
  };

  chestCircumferenceRenderer = ({ chestCircumference, active }) => {
    if (chestCircumference) {
      const { chestCircumferenceCm, chestCircumferenceInch } = chestCircumference;
      const display = `${chestCircumferenceInch}"/ ${chestCircumferenceCm} cm`;
      return <div className={cx({ 'row--deleted': !active })}>{display}</div>;
    }
    return '';
  };

  anteriorFontanelRenderer = ({ anteriorFontanel, active }) => <div className={cx({ 'row--deleted': !active })}>{anteriorFontanel?.content}</div>;

  posteriorFontanelRenderer = ({ posteriorFontanel, active }) => <div className={cx({ 'row--deleted': !active })}>{posteriorFontanel?.content}</div>;

  onSelectChart = ({ target: { value } }) => {
    this.setState({ selectedChart: value });
  };

  render() {
    const { locale } = this.props;
    const heightWeightDataSource = {
      headers: [
        { text: 'Chart Time', field: 'chartingAt' },
        { text: 'Height', field: 'height', align: ColumnAlign.Right, customRender: this.heightRenderer },
        { text: 'Weight', field: 'weight', align: ColumnAlign.Right, customRender: this.weightRenderer },
        { text: 'Method', field: 'method', customRender: this.methodRenderer },
        { text: 'Notes', field: 'notes' },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { field: 'actionDelete' }
      ],
      data: this.props.chartHistory
    };
    const infantDataSource = {
      headers: [
        { text: 'Chart Time', field: 'chartingAt' },
        {
          text: 'Abdominal Girth',
          field: 'abdominalGirth',
          align: ColumnAlign.Right,
          customRender: this.abdominalGirthRenderer
        },
        { text: 'Anterior Fontanel', field: 'anteriorFontanel', customRender: this.anteriorFontanelRenderer },
        { text: 'Posterior Fontanel', field: 'posteriorFontanel', customRender: this.posteriorFontanelRenderer },
        {
          text: 'Head Circum',
          field: 'headCircumference',
          align: ColumnAlign.Right,
          customRender: this.headCircumferenceRenderer
        },
        {
          text: 'Chest Circum.',
          field: 'chestCircumference',
          align: ColumnAlign.Right,
          customRender: this.chestCircumferenceRenderer
        },
        { text: 'Notes', field: 'notes' },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { field: 'actionDelete' }
      ],
      data: this.props.chartHistory
    };
    const selectChartBox = (
      <Box mt className="chart-input__dropdown">
        <ELSDropDown inline options={CHARTS_OPTIONS} value={this.state.selectedChart} changeHandler={this.onSelectChart} />
      </Box>
    );
    return (
      <Box mt>
        <h2>Chart History</h2>
        {this.props.infantContainer(selectChartBox)}
        {this.state.selectedChart === HEIGHT_WEIGHT_CHARTS.HEIGHT_LENGTH_WEIGHT && (
          <ChartHistory dataSource={heightWeightDataSource} deleteRecord={this.props.deleteHistory} locale={locale} />
        )}
        {this.state.selectedChart === HEIGHT_WEIGHT_CHARTS.INFANT_MEASUREMENTS && (
          <ChartHistory dataSource={infantDataSource} deleteRecord={this.props.deleteHistory} locale={locale} />
        )}
      </Box>
    );
  }
}

export default HeightWeightHistory;

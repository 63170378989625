import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ChartFragment, FragmentInputRecord, Section } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { appHelper } from 'helpers';
import { chartService } from 'services';
import { BadgeDateTime, Box, ChartPermission, DeleteCell, withFormUtilities } from 'components/common';
import { ELSDataTable } from 'components/common/els';
import { VitalSignsFragmentRecords } from '../types';

export interface DataReviewSidebarProps {
  fragment: ChartFragment;
  navId: string;
  showDeleteConfirmation: (handleConfirmClick: Function, message?: string) => void;
  showDeleteSuccess: () => void;
  loadData: () => Promise<void>;
}

const deactivateRecord = (data: Section[], id: string) => {
  return data.map((section) => ({
    ...section,
    records: section.records.map((record) => (record.id === id ? { ...record, active: false } : record))
  }));
};

const handleDeleteRecord = async ({
  recordId,
  navId,
  fragment,
  showDeleteConfirmation,
  showDeleteSuccess,
  loadData
}: Partial<DataReviewSidebarProps> & {
  recordId: string;
  fragment: ChartFragment;
}) => {
  const { chartData } = fragment;
  const updatedRecords = deactivateRecord(chartData.records, recordId);
  const updatedFragment = {
    ...fragment,
    chartData: {
      ...chartData,
      records: updatedRecords
    }
  };
  showDeleteConfirmation(async () => {
    await appHelper
      .useLoader(chartService.saveFragment(fragment.chartId, navId, updatedFragment))
      .then(() => appHelper.useLoader(loadData()))
      .then(() => {
        showDeleteSuccess();
      });
  });
};

const renderTitle = (row: FragmentInputRecord) => <div className={cx({ 'row--deleted': !row.active })}>{row.title}</div>;

const renderContent = (row: FragmentInputRecord) => <div className={cx({ 'row--deleted': !row.active })}>{typeof row.content !== 'boolean' && <p>{row.content}</p>}</div>;

const DataReviewSidebar = (props: DataReviewSidebarProps) => {
  const { fragment, navId, showDeleteConfirmation, showDeleteSuccess, loadData } = props;
  const [currentFragment, setCurrentFragment] = useState<ChartFragment>(fragment);

  const { chartData, chartingAt, creator } = currentFragment || {};
  const { records } = (chartData as VitalSignsFragmentRecords) || {};

  useEffect(() => {
    setCurrentFragment(fragment);
  }, [fragment]);

  if (!fragment) return null;

  const handleDeleteClick = async ({ id }: FragmentInputRecord) => {
    await handleDeleteRecord({ recordId: id, navId, fragment: currentFragment, showDeleteConfirmation, showDeleteSuccess, loadData });
  };

  const renderDelete = ({ preventDelete, active, ...rest }: FragmentInputRecord) => (
    <ChartPermission fragmentCreatorId={creator.id}>
      {({ allowDeleteFragment }) => <DeleteCell isActive={allowDeleteFragment && !preventDelete && active} onDeleteClick={() => handleDeleteClick(rest)} />}
    </ChartPermission>
  );

  return (
    <>
      <Box mt3>
        <h2>View Chart</h2>
      </Box>

      <div className="edit-chart-section">
        <div>
          <h3 className="u-els-margin-top">Vital Signs/A to E Assessment</h3>
          <BadgeDateTime className="u-els-margin-top" isoTime={chartingAt} />
          <p className="u-els-color-n7 u-els-italic">{`Entered by: ${creator?.firstName} ${creator?.lastName}`}</p>
        </div>

        <div className="sidebar__divider" />
        <Box pb2>
          {records
            ? records.map((record, index) => {
                const tableHeading = `${record.parentSectionTitle || ''} ${record.sectionTitle || ''}`.trim();
                const elementKey = record.sectionTitle || index;
                return (
                  <div key={elementKey} className="edit-chart-section__table">
                    <ELSDataTable data={record.records}>
                      <column header={<div className="header-title">{tableHeading}</div>} field="title" customRender={renderTitle} />
                      <column header={<div className="u-els-hide-visually">Content</div>} field="content" align={ColumnAlign.Left} customRender={renderContent} />
                      <column header={<div className="u-els-hide-visually">Delete</div>} customRender={renderDelete} />
                    </ELSDataTable>
                  </div>
                );
              })
            : null}
        </Box>
      </div>
    </>
  );
};

export default withFormUtilities(DataReviewSidebar);

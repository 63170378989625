import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getRestraintsFormFieldBuilderItems } from './constants';
import RestraintsView from './RestraintsView';
import { Title } from '../shared/constants';

class Restraints extends Component<ChartComponentProps> {
  static displayName = 'Restraints';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getRestraintsFormFieldBuilderItems().forEach(({ label, ...item }) => {
      dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
    });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;

    return [
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.INITIATION,
        fields: [FormField.INITIATION_DAY, FormField.INITIATION_TIME],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.DISCONTINUATION,
        fields: [FormField.DISCONTINUATION_DAY, FormField.DISCONTINUATION_TIME],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.SPECIAL_PRECAUTIONS,
        fields: [FormField.SPECIAL_PRECAUTIONS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.CRITERIA_MET_FOR_RESTRAINT,
        fields: [FormField.CRITERIA_MET_FOR_RESTRAINT_RADIO, FormField.CRITERIA_MET_FOR_RESTRAINT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.PROVIDER_NOTIFICATION,
        fields: [FormField.PROVIDER_NOTIFICATION_RADIO, FormField.PROVIDER_NOTIFICATION_DAY, FormField.PROVIDER_NOTIFICATION_TIME],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.ALTERNATIVE_METHODS_UTILIZED_PRIOR,
        fields: [FormField.ALTERNATIVE_METHODS_UTILIZED_PRIOR],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.TYPE_OF_RESTRAINT,
        fields: [FormField.TYPE_OF_RESTRAINT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.RESTRAINT_NOTES,
        fields: [FormField.RESTRAINT_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.CONTINUED_NEED_FOR_RESTRAINTS,
        fields: [FormField.CONTINUED_NEED_FOR_RESTRAINTS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.SKIN_CIRCULATION,
        fields: [FormField.SKIN_CIRCULATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.FLUID_NUTRITION,
        fields: [FormField.FLUID_NUTRITION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.ELIMINATION,
        fields: [FormField.ELIMINATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.BEHAVIORAL_MENTAL_STATUS,
        fields: [FormField.BEHAVIORAL_MENTAL_STATUS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.RESTRAINTS.SPECIAL_PRECAUTIONS_RESTRAINTS_NOTES,
        fields: [FormField.SPECIAL_PRECAUTIONS_RESTRAINTS_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SPECIAL_CHARTS,
      fragmentTitle: LanguageKeys.RESTRAINTS.RESTRAINTS,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <RestraintsView {...viewProps} />;
  }
}

export { Restraints as BaseRestraints };
export default compose(withSavedPatientChartsPage, withChartLogic)(Restraints);

import cx from 'classnames';
import { MedicationFragment } from 'models/api-response';
import { dateTimeHelper } from 'helpers';
import { AccordionBadge, AccordionTitle, Box, ChartPermission, ClinicalKeyDrugInformation } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';
import { Label } from './constants';

export interface MedicationsHistoryViewProps {
  chartHistory: MedicationFragment[];
  onEditSidebarOpen: Function;
  onMedicationDelete: Function;
}
const MedicationsHistoryView = (props: MedicationsHistoryViewProps) => (
  <Box mt2>
    <h2 className="u-els-margin-bottom-2x">{Label.SAVED_MEDICATIONS}</h2>
    <div className="sng-accordion">
      {props.chartHistory?.length === 0 && <NothingToSee>{Label.NOTHING_TO_SEE}</NothingToSee>}
      {props.chartHistory?.length > 0 && (
        <ELSAccordion id="medicationAccordion">
          {props.chartHistory.map((medication) => {
            const route = medication.chartData.routeOther || medication.chartData.route;
            const classification = medication.chartData.classificationOther || medication.chartData.classification;
            return (
              <ELSAccordionItem
                key={medication.fragmentId}
                id={medication.fragmentId}
                leftIcon="chevron-down"
                customHeader={
                  <>
                    <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !medication.active })}>
                      {medication.chartData.medicationOther || medication.chartData.medication}
                      <ClinicalKeyDrugInformation areaName={Label.CLINICAL_KEY_NAME} showLink drugName={medication.chartData.medication} />
                    </AccordionTitle>
                    {medication.chartData.dateOrdered && <AccordionBadge>{dateTimeHelper.toDate(medication.chartData.dateOrdered)}</AccordionBadge>}
                  </>
                }
              >
                <Box pb>
                  <Box mb2>
                    {[route, medication.chartData.dose, medication.chartData.frequency].filter(Boolean).length > 0 && (
                      <span className="u-els-bold u-els-margin-right">{[route, medication.chartData.dose, medication.chartData.frequency].filter(Boolean).join(' · ')}</span>
                    )}
                    {classification && <span className="u-els-italic u-els-color-n7">{classification}</span>}
                  </Box>
                  {!classification && (
                    <Box mb2>
                      <h4>{Label.CLASSIFICATION}</h4>
                      <MultiLineWithEmpty text={classification} />
                    </Box>
                  )}
                  {!route && (
                    <Box mb2>
                      <h4>{Label.ROUTE}</h4>
                      <MultiLineWithEmpty text={route} />
                    </Box>
                  )}
                  {!medication.chartData.dose && (
                    <Box mb2>
                      <h4>{Label.DOSE}</h4>
                      <MultiLineWithEmpty text={medication.chartData.dose} />
                    </Box>
                  )}
                  {!medication.chartData.frequency && (
                    <Box mb2>
                      <h4>{Label.FREQUENCY}</h4>
                      <MultiLineWithEmpty text={medication.chartData.frequency} />
                    </Box>
                  )}
                  {!medication.chartData.dateOrdered && (
                    <Box mb2>
                      <h4>{Label.DATE_ORDERED}</h4>
                      <MultiLineWithEmpty text={medication.chartData.dateOrdered} />
                    </Box>
                  )}
                  <ELSFlex className="u-flex-grid-2x" wrap>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.COMMENTS_ADDITIONAL_MEDICATION_INFO}</h4>
                      <MultiLineWithEmpty text={medication.chartData.commentsAndMedication} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.THERAPEUTIC_EFFECT}</h4>
                      <MultiLineWithEmpty text={medication.chartData.therapeuticEffect} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.ACTION}</h4>
                      <MultiLineWithEmpty text={medication.chartData.action} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.CONTRAINDICATIONS}</h4>
                      <MultiLineWithEmpty text={medication.chartData.contraindications} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.SIDE_EFFECTS_ADVERSE_REACTIONS}</h4>
                      <MultiLineWithEmpty text={medication.chartData.effectsOrReactions} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.LIFE_THREATENING_CONSIDERATIONS}</h4>
                      <MultiLineWithEmpty text={medication.chartData.lifeThreateningConsiderations} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.RECOMMENDED_DOSE_RANGES}</h4>
                      <MultiLineWithEmpty text={medication.chartData.recommendedDoseRanges} />
                    </ELSFlexItem>
                    <ELSFlexItem w="1o2" md="1o1">
                      <h4>{Label.NURSING_INTERVENTIONS}</h4>
                      <MultiLineWithEmpty text={medication.chartData.nursingInterventions} />
                    </ELSFlexItem>
                  </ELSFlex>
                  {medication.active && (
                    <ChartPermission fragmentCreatorId={medication.creator?.id}>
                      {({ allowEditFragment, allowDeleteFragment }) => (
                        <Box mt2>
                          {allowEditFragment && <ELSButton className="sng-button u-els-margin-right" text="Edit" onClick={() => props.onEditSidebarOpen(medication)} />}
                          {allowDeleteFragment && <ELSButton className="sng-button c-els-button--secondary" text="Delete" onClick={() => props.onMedicationDelete(medication)} />}
                        </Box>
                      )}
                    </ChartPermission>
                  )}
                </Box>
              </ELSAccordionItem>
            );
          })}
        </ELSAccordion>
      )}
    </div>
  </Box>
);

MedicationsHistoryView.displayName = 'MedicationsHistoryView';
export default MedicationsHistoryView;

import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { DemoSource } from 'models/enum';
import { CaseStudy } from 'models/ui';
import { RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { caseStudyService } from 'services';
import { instructorActions } from 'redux/ducks/instructor';
import { studentActions } from 'redux/ducks/student';
import CaseStudyPreviewContent from 'components/common/case-study-preview-content/CaseStudyPreviewContent';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';

interface CaseStudyPreviewProp {
  caseStudy: CaseStudy;
  onBack: Function;
  setCaseStudyDemo: Function;
  setAssignmentDemoSource: Function;
  setAssignmentDemoUrl: Function;
}

export class CaseStudyPreview extends Component<CaseStudyPreviewProp & RouteComponentProps> {
  handleSwitchToStudentView = () => {
    const { history, caseStudy, setCaseStudyDemo } = this.props;
    const payload = { caseStudyId: caseStudy.id };
    setCaseStudyDemo(caseStudy);
    return appHelper.useLoader(
      caseStudyService.prepareDemoCaseStudy(payload).then(({ data }) => {
        const { demoAssessmentId } = data;
        const path = RoutePath.student.caseStudyLanding.replace(RouteParams.ASSESSMENT_ID, demoAssessmentId);
        this.props.setAssignmentDemoSource(DemoSource.ASSIGNMENT_CREATION_QUICK_VIEW);
        this.props.setAssignmentDemoUrl(this.props.location.pathname);
        history.push(path);
      })
    );
  };

  render() {
    const { onBack, caseStudy } = this.props;

    return (
      <div>
        <ELSFlex className="u-els-margin-top u-els-margin-bottom-2x" middle spaceBetween>
          <ELSFlexItem>
            <ELSButton text="Back" type="secondary" onClick={onBack} />
          </ELSFlexItem>
          <ELSFlexItem>
            <ELSButton text="Switch to Student View" type="primary" onClick={this.handleSwitchToStudentView} />
          </ELSFlexItem>
        </ELSFlex>

        <CaseStudyPreviewContent caseStudy={caseStudy} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCaseStudyDemo: (data) => dispatch(instructorActions.setCaseStudyDemo(data)),
  setAssignmentDemoSource: (source: string) => dispatch(studentActions.setAssignmentDemoSource(source)),
  setAssignmentDemoUrl: (url: string) => dispatch(studentActions.setAssignmentDemoUrl(url))
});

export default compose(withRouter, connect(null, mapDispatchToProps))(CaseStudyPreview);

import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, ChartFragmentRS } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, MarRecord } from 'models/ui';
import { DateFormatByLocale, NAV_ID } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { formatDate } from 'helpers/datetime.helper';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { DOSE_MODE, FormField, FormFieldLabel } from './constants';
import { MarSidebarProps } from './MarDosageSidebar';
import MarHoldAndDiscontinueFormSidebarView, { MarHoldAndDiscontinueFormSidebarViewProps } from './MarHoldAndDiscontinueFormSidebarView';

export interface MarHoldAndDiscontinueFormSidebarProps extends ChartComponentProps {
  selectedDosageRecord: MarRecord;
  doseFormMode: string;
  fragments: ChartFragment[];
  onUpdate: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  onClose: Function;
  presaveFragment: Function;
}

class MarHoldAndDiscontinueFormSidebar extends Component<MarHoldAndDiscontinueFormSidebarProps> {
  componentDidUpdate(prevProps: Readonly<MarSidebarProps>) {
    if (this.props.selectedDosageRecord && prevProps.selectedDosageRecord !== this.props.selectedDosageRecord) {
      this.props.initState(this.buildFormFields());
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { selectedDosageRecord, doseFormMode, locale } = this.props;
    const formFieldMapData = new Map();

    if (doseFormMode === DOSE_MODE.HOLD) {
      formFieldMapData.set(
        FormField.HOLD_DATE,
        createFormField({
          name: FormField.HOLD_DATE,
          type: FormFieldInputType.DATE,
          label: FormFieldLabel.HOLD_DATE,
          value: formatDate({ date: moment(selectedDosageRecord[FormField.HOLD_DATE], Object.values(DateFormatByLocale)).toDate(), locale })
        })
      );
      formFieldMapData.set(
        FormField.HOLD_TIME,
        createFormField({
          name: FormField.HOLD_TIME,
          type: FormFieldInputType.TIME,
          label: FormFieldLabel.HOLD_TIME,
          value: selectedDosageRecord[FormField.HOLD_TIME]
        })
      );
      formFieldMapData.set(
        FormField.HOLD_COMMENTS,
        createFormField({
          name: FormField.HOLD_COMMENTS,
          type: FormFieldInputType.TEXT_AREA,
          label: FormFieldLabel.HOLD_COMMENTS,
          value: selectedDosageRecord[FormField.HOLD_COMMENTS]
        })
      );
      formFieldMapData.set(
        FormField.HOLD_FIRST_INITIAL,
        createFormField({
          name: FormField.HOLD_FIRST_INITIAL,
          type: FormFieldInputType.TEXT_BOX,
          label: FormFieldLabel.FIRST_INITIAL,
          value: selectedDosageRecord[FormField.HOLD_FIRST_INITIAL]
        })
      );
      formFieldMapData.set(
        FormField.HOLD_LAST_INITIAL,
        createFormField({
          name: FormField.HOLD_LAST_INITIAL,
          type: FormFieldInputType.TEXT_BOX,
          label: FormFieldLabel.LAST_INITIAL,
          value: selectedDosageRecord[FormField.HOLD_LAST_INITIAL]
        })
      );
    } else if (doseFormMode === DOSE_MODE.DISCONTINUE) {
      formFieldMapData.set(
        FormField.DISCONTINUE_DATE,
        createFormField({
          name: FormField.DISCONTINUE_DATE,
          type: FormFieldInputType.DATE,
          label: FormFieldLabel.DISCONTINUE_DATE,
          value: formatDate({ date: moment(selectedDosageRecord[FormField.DISCONTINUE_DATE], Object.values(DateFormatByLocale)).toDate(), locale })
        })
      );
      if (this.props.isAuthor) {
        formFieldMapData.set(
          FormField.DISCONTINUE_TIME_OFFSET,
          createFormField({
            name: FormField.DISCONTINUE_TIME_OFFSET,
            type: FormFieldInputType.TEXT_BOX,
            label: FormFieldLabel.DISCONTINUE_TIME_OFFSET,
            value: selectedDosageRecord[FormField.DISCONTINUE_TIME_OFFSET] || '00:00'
          })
        );
      } else {
        formFieldMapData.set(
          FormField.DISCONTINUE_TIME,
          createFormField({
            name: FormField.DISCONTINUE_TIME,
            type: FormFieldInputType.TIME,
            label: FormFieldLabel.DISCONTINUE_TIME,
            value: selectedDosageRecord[FormField.DISCONTINUE_TIME]
          })
        );
      }
      formFieldMapData.set(
        FormField.DISCONTINUE_COMMENTS,
        createFormField({
          name: FormField.DISCONTINUE_COMMENTS,
          type: FormFieldInputType.TEXT_AREA,
          label: FormFieldLabel.DISCONTINUE_COMMENTS,
          value: selectedDosageRecord[FormField.DISCONTINUE_COMMENTS]
        })
      );
      formFieldMapData.set(
        FormField.DISCONTINUE_FIRST_INITIAL,
        createFormField({
          name: FormField.DISCONTINUE_FIRST_INITIAL,
          type: FormFieldInputType.TEXT_BOX,
          label: FormFieldLabel.FIRST_INITIAL,
          value: selectedDosageRecord[FormField.DISCONTINUE_FIRST_INITIAL]
        })
      );
      formFieldMapData.set(
        FormField.DISCONTINUE_LAST_INITIAL,
        createFormField({
          name: FormField.DISCONTINUE_LAST_INITIAL,
          type: FormFieldInputType.TEXT_BOX,
          label: FormFieldLabel.LAST_INITIAL,
          value: selectedDosageRecord[FormField.DISCONTINUE_LAST_INITIAL]
        })
      );
    }
    return formFieldMapData;
  };

  onClickSave = () => {
    const { fragments, selectedDosageRecord, doseFormMode, formFieldMap, handleSaveClick, onUpdate, presaveFragment, isAuthor, locale } = this.props;
    const fragment = fragments.find((item) => item.fragmentId === selectedDosageRecord.fragmentId);
    const holdDiscontinueFields = {};
    if (doseFormMode === DOSE_MODE.HOLD) {
      holdDiscontinueFields[FormField.HOLD_DATE] = formatDate({ date: moment(formFieldMap.get(FormField.HOLD_DATE).value, Object.values(DateFormatByLocale)).toDate(), locale });
      holdDiscontinueFields[FormField.HOLD_TIME] = formFieldMap.get(FormField.HOLD_TIME).value;
      holdDiscontinueFields[FormField.HOLD_COMMENTS] = formFieldMap.get(FormField.HOLD_COMMENTS).value;
      holdDiscontinueFields[FormField.HOLD_FIRST_INITIAL] = formFieldMap.get(FormField.HOLD_FIRST_INITIAL).value.toUpperCase();
      holdDiscontinueFields[FormField.HOLD_LAST_INITIAL] = formFieldMap.get(FormField.HOLD_LAST_INITIAL).value.toUpperCase();
      holdDiscontinueFields[FormField.IS_HOLD] = true;
    } else if (doseFormMode === DOSE_MODE.DISCONTINUE) {
      holdDiscontinueFields[FormField.DISCONTINUE_DATE] = formatDate({
        date: moment(formFieldMap.get(FormField.DISCONTINUE_DATE).value, Object.values(DateFormatByLocale)).toDate(),
        locale
      });
      holdDiscontinueFields[FormField.DISCONTINUE_COMMENTS] = formFieldMap.get(FormField.DISCONTINUE_COMMENTS).value;
      holdDiscontinueFields[FormField.DISCONTINUE_FIRST_INITIAL] = formFieldMap.get(FormField.DISCONTINUE_FIRST_INITIAL).value.toUpperCase();
      holdDiscontinueFields[FormField.DISCONTINUE_LAST_INITIAL] = formFieldMap.get(FormField.DISCONTINUE_LAST_INITIAL).value.toUpperCase();
      holdDiscontinueFields[FormField.IS_DISCONTINUED] = true;
      if (isAuthor) {
        holdDiscontinueFields[FormField.DISCONTINUE_TIME_OFFSET] = formFieldMap.get(FormField.DISCONTINUE_TIME_OFFSET).value;
      } else {
        holdDiscontinueFields[FormField.DISCONTINUE_TIME] = formFieldMap.get(FormField.DISCONTINUE_TIME).value;
      }
    }

    const updatedChartData = {
      ...fragment.chartData,
      ...holdDiscontinueFields
    };
    const updatedFragment: ChartFragment = {
      ...presaveFragment(fragment),
      chartData: updatedChartData,
      linkedFragmentId: fragment.linkedFragmentId || fragment.fragmentId
    };

    handleSaveClick([updatedFragment], { navId: NAV_ID.PHARMACY, afterSave: onUpdate });
  };

  render() {
    const { formFieldMap, selectedDosageRecord, doseFormMode, formSubmittedCount, hasUnsavedChanges, handleDiscardClick, onClose, isAuthor, sidebarProps } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: hasUnsavedChanges,
      onSaveClick: this.onClickSave,
      onCancelClick: () => handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' })
    };
    const viewProps: MarHoldAndDiscontinueFormSidebarViewProps = {
      formFieldMap,
      selectedDosageRecord,
      doseFormMode,
      formSubmittedCount,
      chartActionsProps,
      onCloseClick: onClose,
      isAuthor,
      sidebarProps
    };
    return <MarHoldAndDiscontinueFormSidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state)
});

export { MarHoldAndDiscontinueFormSidebar as BaseMarHoldAndDiscontinueFormSidebar };
export default compose(connect(mapStateToProps), withChartLogic)(MarHoldAndDiscontinueFormSidebar);

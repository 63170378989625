import queryString from 'query-string';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { AppMessage, NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import PatientTeachingView from './PatientTeachingView';
import { LoadableChartViewProps } from '../../shared/constants';

interface PatientTeachingProps extends RouteComponentProps {
  showConfirmationModal: Function;
}

interface PatientTeachingState {
  loadedFragment: ChartFragment;
  fragments: ChartFragment[];
}

class PatientTeaching extends Component<PatientTeachingProps & ChartComponentProps, PatientTeachingState> {
  static displayName = 'PatientTeaching';
  constructor(props) {
    super(props);
    this.state = {
      loadedFragment: null,
      fragments: null
    };
  }

  componentDidMount() {
    const id = this.getIdFromParam(this.props);
    this.loadData(id);
  }

  componentDidUpdate(prevProps) {
    const prevId = this.getIdFromParam(prevProps);
    const id = this.getIdFromParam(this.props);
    // Prepare for creating a new Patientt Teaching entry when re-click on the left navigation
    if (id !== prevId && !id) {
      this.setState({ loadedFragment: null });
      this.props.initState(this.buildDefaultFormFields());
    }
  }

  getIdFromParam = (props: PatientTeachingProps) => {
    const parsedQuery = queryString.parse(props.location.search);
    return parsedQuery.id;
  };

  buildDefaultFormFields = (fragment?: ChartFragment): Map<string, ChartMetaFormField> => {
    const { createFormField, getFragmentContentIds, getFragmentValue } = chartHelper;
    const dataMap = new Map();

    const dropdowns = [
      { name: FormField.CATEGORY, sectionTitle: SectionTitle.PATIENT_TEACHING, label: SectionTitle.CATEGORY },
      { name: FormField.SUB_CATEGORY, sectionTitle: SectionTitle.PATIENT_TEACHING, label: SectionTitle.SUB_CATEGORY },
      { name: FormField.TEACHING_TOPICS, sectionTitle: SectionTitle.PATIENT_TEACHING, label: SectionTitle.TEACHING_TOPICS }
    ];

    const multiselectDropdowns = [
      { name: FormField.TOPICS_TAUGHT, sectionTitle: SectionTitle.PATIENT_TEACHING },
      { name: FormField.PERSONS_TAUGHT, sectionTitle: SectionTitle.PERSONS_TAUGHT },
      { name: FormField.TEACHING_METHODS, sectionTitle: SectionTitle.TEACHING_METHODS },
      { name: FormField.LEARNING_BARRIERS, sectionTitle: SectionTitle.LEARNING_BARRIERS },
      { name: FormField.OUTCOME, sectionTitle: SectionTitle.OUTCOME }
    ];

    dataMap.set(
      FormField.TEACHING_TOPICS_OTHER,
      createFormField({
        name: FormField.TEACHING_TOPICS_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_TOPICS_OTHER)
      })
    );
    dataMap.set(
      FormField.TOPICS_TAUGHT_OTHER,
      createFormField({
        name: FormField.TOPICS_TAUGHT_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.PATIENT_TEACHING, FormField.TOPICS_TAUGHT_OTHER)
      })
    );

    dataMap.set(FormField.TEACHING_IS_COMPLETE, createFormField({ name: FormField.TEACHING_IS_COMPLETE, type: FormFieldInputType.CHECK_BOX }));

    dropdowns.forEach(({ name, sectionTitle, label }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          type: FormFieldInputType.DROPDOWN,
          contentIds: getFragmentContentIds(fragment, sectionTitle, name)
        })
      )
    );

    multiselectDropdowns.forEach(({ name, sectionTitle }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN, contentIds: getFragmentContentIds(fragment, sectionTitle, name) }))
    );

    dataMap.set(
      FormField.ADDITIONAL_COMMENTS,
      createFormField({
        name: FormField.ADDITIONAL_COMMENTS,
        type: FormFieldInputType.TEXT_AREA,
        value: getFragmentValue(fragment, SectionTitle.OUTCOME, FormField.ADDITIONAL_COMMENTS)
      })
    );

    return dataMap;
  };

  isTeachingCompleteFieldExisted = (fragments: ChartFragment[], fragment: ChartFragment) => {
    if (!fragment) return false;
    const fragmentList = fragments.filter((frag) => frag.linkedFragmentId === (fragment.linkedFragmentId || fragment.fragmentId));
    return fragmentList.some((frag) => frag.active && chartHelper.getFragmentValue(frag, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_IS_COMPLETE));
  };

  loadData = (id) =>
    appHelper.useLoader(
      this.props.loadChartData([FragmentType.AUTHORED, FragmentType.CHARTING]).then(({ data: fragments }) => {
        let chartFragment = fragments.find((fragment) => fragment.fragmentId === id && fragment.active);

        if (this.isTeachingCompleteFieldExisted(fragments, chartFragment)) {
          chartFragment = null;
          const teachingPatientEntryPage = RoutePath.student.patientTeaching.patientTeaching.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
          this.props.history.push(teachingPatientEntryPage);
        }
        this.setState({ loadedFragment: chartFragment, fragments });
        this.props.initState(this.buildDefaultFormFields(chartFragment));
      }),
      { errorMessage: AppMessage.NO_CHART_DATA }
    );

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PATIENT_TEACHING,
        fields: [
          FormField.TEACHING_IS_COMPLETE,
          FormField.CATEGORY,
          FormField.SUB_CATEGORY,
          FormField.TEACHING_TOPICS,
          FormField.TEACHING_TOPICS_OTHER,
          FormField.TOPICS_TAUGHT,
          FormField.TOPICS_TAUGHT_OTHER
        ],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PERSONS_TAUGHT,
        fields: [FormField.PERSONS_TAUGHT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.TEACHING_METHODS,
        fields: [FormField.TEACHING_METHODS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.LEARNING_BARRIERS,
        fields: [FormField.LEARNING_BARRIERS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.OUTCOME,
        fields: [FormField.OUTCOME, FormField.ADDITIONAL_COMMENTS],
        formFieldMap
      })
    ];
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields(this.state.loadedFragment));

  buildFragment = () => {
    const record = {
      chartTitle: SectionTitle.PATIENT_TEACHING,
      fragmentTitle: SectionTitle.PATIENT_TEACHING,
      records: this.createSections(),
      statusUpdate: this.getIdFromParam(this.props) ? 'Reinforced' : 'Initial'
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = chartHelper.createFragmentId(NAV_ID.PATIENT_TEACHING);
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    let linkedFragmentId;
    const { loadedFragment } = this.state;
    if (!loadedFragment) {
      linkedFragmentId = fragmentId;
    } else {
      linkedFragmentId = loadedFragment.linkedFragmentId || loadedFragment.fragmentId;
    }
    return { ...basicInfo, linkedFragmentId, chartData: cleanRecord } as ChartFragment;
  };

  isCategoryOfActiveExisted = (fragments, newCategory: string, newSubcategory: string, newTeachingTopic: string, newTeachingTopicOther: string) => {
    const fragmentList = fragments.filter((frag) => {
      const category = chartHelper.getFragmentValue(frag, SectionTitle.PATIENT_TEACHING, FormField.CATEGORY);
      const subCategory = chartHelper.getFragmentValue(frag, SectionTitle.PATIENT_TEACHING, FormField.SUB_CATEGORY);
      const teachingTopic = chartHelper.getFragmentValue(frag, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_TOPICS);
      const teachingTopicOther = chartHelper.getFragmentValue(frag, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_TOPICS_OTHER);
      return frag.active && category === newCategory && subCategory === newSubcategory && teachingTopic === newTeachingTopic && teachingTopicOther === newTeachingTopicOther;
    });

    let result = false;
    const fragmentGroups = chartHelper.groupFragmentsById(fragmentList);
    fragmentGroups.forEach((fragmentGroup) => {
      const isTeachingComplete = fragmentGroup.some((frag) => chartHelper.getFragmentValue(frag, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_IS_COMPLETE));
      if (!isTeachingComplete) {
        result = true;
      }
    });

    return result;
  };

  handleSaveClick = () => {
    const { loadedFragment, fragments } = this.state;
    const { formFieldMap } = this.props;
    const currentCategory = formFieldMap.get(FormField.CATEGORY).value;
    const currentSubcategory = formFieldMap.get(FormField.SUB_CATEGORY).value;
    const currentTeachingTopic = formFieldMap.get(FormField.TEACHING_TOPICS).value;
    const currentTeachingTopicOther = formFieldMap.get(FormField.TEACHING_TOPICS_OTHER).value;
    const teachingIsNotComplete = !formFieldMap.get(FormField.TEACHING_IS_COMPLETE).value;

    const needShowWarning =
      teachingIsNotComplete && !loadedFragment && this.isCategoryOfActiveExisted(fragments, currentCategory, currentSubcategory, currentTeachingTopic, currentTeachingTopicOther);
    if (needShowWarning) {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Please confirm.',
        message: 'You already have an active Patient Teaching at this category. Are you sure you want to add another?',
        onOkClick: this.saveChart
      });
    } else {
      this.saveChart();
    }
  };

  saveChart = () => {
    const fragment = this.buildFragment();
    const defaultFormFields = this.buildDefaultFormFields(this.state.loadedFragment);
    this.props.saveChart([fragment], { defaultFormFields, afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.handleCancelClick(),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: LoadableChartViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      fragment: this.state.loadedFragment
    };
    return <PatientTeachingView {...viewProps} />;
  }
}

export { PatientTeaching as BasePatientTeaching };
export default compose(withSavedPatientChartsPage, withChartLogic, withRouter)(PatientTeaching);

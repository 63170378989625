/* eslint-disable class-methods-use-this */

/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ChartMetaFormField, ValidationRule } from 'models/ui';
import BaseHandler, { ExecuteParams } from './interface';

export default class PrefillHandler implements BaseHandler {
  execute({ field, ruleId, ruleRegistry }: ExecuteParams): ChartMetaFormField {
    return produce(field, (draft) => {
      const rule = ruleRegistry.get(ruleId);

      const getFieldValueIfExistChartFieldContentId = (targets: ValidationRule['targets']): string => {
        let fieldValue = '';
        // unselect all options of field
        if (draft.chartContent) {
          draft.chartContent.forEach((content) => {
            content.selected = false;
          });
        }
        // prefill contents
        targets.forEach((target) => {
          const contentItem = draft.chartContent?.find((content) => content.id === target.chartFieldContentId);
          if (contentItem) {
            contentItem.selected = true;
            fieldValue = fieldValue ? fieldValue.concat(`|${contentItem.value}`) : contentItem.value;
          }
        });
        return fieldValue;
      };

      if (rule.result) {
        const targets = rule.ruleInfo.targets.filter((target) => target.formFieldId === draft.name);
        const sampleTarget = targets[0];

        if (sampleTarget.value) {
          draft.value = sampleTarget.value;
        } else if (sampleTarget.chartFieldContentId) {
          draft.value = getFieldValueIfExistChartFieldContentId(targets);
        }
      }
    });
  }
}

import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { NAV_ID } from 'constants/app.constant';
import { appActions } from 'redux/ducks/app';
import { ChartWrapper, PageLoader, withHTMLHeadSEO } from 'components/common';
import { PageWrapper } from 'components/common/layout';
import ClinicalSetup from 'components/features/chart/clinical-setup/ClinicalSetup';

interface ClinicalSetupPageProps {
  setSelectedNavId: Function;
}

class ClinicalSetupPage extends Component<ClinicalSetupPageProps> {
  constructor(props) {
    super(props);
    this.props.setSelectedNavId(NAV_ID.PATIENT);
  }

  render() {
    return (
      <>
        <PageLoader />
        <PageWrapper showPatientRibbon={false} showNavigation={false} showSubmitAssessmentButton={false}>
          {({ loggedInUser }) => (
            <ChartWrapper>
              {({ selectedNavId, assessment, handleNavigationAttempt, loadChartData, saveChartData, displayAuthoringData, isAuthor, enableDisplayRecordsButton }) => (
                <ClinicalSetup
                  student={loggedInUser}
                  selectedNavId={selectedNavId}
                  handleNavigationAttempt={handleNavigationAttempt}
                  assessment={assessment}
                  saveChartData={saveChartData}
                  loadChartData={loadChartData}
                  displayAuthoringData={displayAuthoringData}
                  isAuthor={isAuthor}
                  enableDisplayRecordsButton={enableDisplayRecordsButton}
                />
              )}
            </ChartWrapper>
          )}
        </PageWrapper>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

const enhancers = [withHTMLHeadSEO({ title: 'Clinical Setup' }), connect(null, mapDispatchToProps)];
export { ClinicalSetupPage as BaseClinicalSetupPage };
export default compose(...enhancers)(ClinicalSetupPage);

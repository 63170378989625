import { MultiLine } from 'components/common';

interface MultiLineWithEmptyProps extends React.HTMLAttributes<HTMLElement> {
  text: string;
}

const MultiLineWithEmpty = (props: MultiLineWithEmptyProps) => {
  const { text, ...restProps } = props;
  return <MultiLine {...restProps} text={text || '- -'} />;
};

MultiLineWithEmpty.displayName = 'MultiLineWithEmpty';
export default MultiLineWithEmpty;

import { ChartFragmentRS, ProviderOrderFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper } from 'helpers';
import { Box, ErrorCard } from 'components/common';
import ProviderOrderFormView from './ProviderOrderFormView';
import ProviderOrderHistoryView from './ProviderOrderHistoryView';
import ProviderOrderSidebar from './ProviderOrderSidebar';
import './provider.order.scss';

export interface ProviderOrderViewProps {
  chartHistory: ProviderOrderFragment[];
  selectedRecord: ProviderOrderFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  selectedNavId: string;
  onEdit: Function;
  onDelete: Function;
  onUpdate: Function;
  onClose: Function;
  chartActionsProps: ChartActionsComponentProps;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const ProviderOrderView = (props: ProviderOrderViewProps) => (
  <div className="provider-order__page">
    <h1>Provider Orders</h1>
    <ErrorCard chartMetaFormFields={props.chartMetaFormFields} showErrorCard={props.formSubmittedCount > 0} />
    <Box mt2>
      <Box mb2>
        <h2 className="u-els-margin-bottom-2x">Saved Provider Orders</h2>
        <ProviderOrderHistoryView chartHistory={props.chartHistory} onEdit={props.onEdit} onDelete={props.onDelete} />
      </Box>
      <Box mb2>
        <h2 className="u-els-margin-bottom-2x">Add Provider Order</h2>
        <ProviderOrderFormView chartMetaFormFields={props.chartMetaFormFields} formSubmittedCount={props.formSubmittedCount} chartActionsProps={props.chartActionsProps} />
      </Box>
      <ProviderOrderSidebar
        selectedRecord={props.selectedRecord}
        selectedNavId={props.selectedNavId}
        onUpdate={props.onUpdate}
        onClose={props.onClose}
        saveChartData={props.saveChartData}
        {...appHelper.getChartSharedProps(props)}
      />
    </Box>
  </div>
);

ProviderOrderView.displayName = 'ProviderOrderView';
export default ProviderOrderView;

import { ScaleDirection } from 'models/enum';
import { ChartActions, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, Label, SectionTitle } from './constants';
import './edinburgh.postnatal.depression.scale.scss';

export interface EdinburghPostnatalDepressionScaleViewProps extends ChartViewProps {
  contextDirectionMap?: Map<string, ScaleDirection>;
  isEmbeddedChart?: boolean;
}

export const EdinburghPostnatalDepressionScaleView = (props: EdinburghPostnatalDepressionScaleViewProps) => {
  const { formSubmittedCount, contextDirectionMap, chartActionsProps, formFieldMap, isEmbeddedChart } = props;
  return (
    <div className="edinburgh-postnatal-depression-scale">
      <h1 className="u-els-margin-top">{SectionTitle.EDINBURGH_POSTNATAL_DEPRESSION_SCALE_SECTION}</h1>
      <p>
        {Label.INSTRUCTIONS_FOR_USING}
        <ol className="instruction-using-edinburgh">
          <li>{Label.MOTHER_FEELING_7_DAYS}</li>
          <li>{Label.ALL_ITEMS_MUST_BE_COMPLETED}</li>
          <li>{Label.AVOID_DISCUSSING_ANSWERS}</li>
          <li>{Label.MOTHER_ANSWER_IN_FIRST_PERSON}</li>
        </ol>
      </p>
      <SectionDivider />
      <h2>{`${SectionTitle.IN_THE_PAST_7_DAYS}:`}</h2>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={props.formSubmittedCount > 0} isEmbeddedChart={isEmbeddedChart} />
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.EDINBURGH_POSTNATAL_DEPRESSION_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: true }}
      />
      <ChartActions {...chartActionsProps} />
      <div className="related-charts__references">
        <p>{Label.MODIFIED_FROM}</p>
        <p>{Label.COPYRIGHT}</p>
        <p>
          {Label.TRANSLATIONS_OF_THE_SCALE}
          &nbsp;
          <a href={Label.RCPSYCH_LINK}>{Label.RCPSYCH_LINK}</a>
        </p>
      </div>
    </div>
  );
};

EdinburghPostnatalDepressionScaleView.displayName = 'EdinburghPostnatalDepressionScaleView';
export default EdinburghPostnatalDepressionScaleView;

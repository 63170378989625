import { LanguageKeys } from 'lang';
import moment from 'moment';
import { Component } from 'react';
import { BloodGlucoseRecord, ChartFragment } from 'models/api-response';
import { BloodGlucoseGraphItem, ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, getBloodGlucoseFormFields } from './constants';
import BloodGlucoseView, { BloodGlucoseViewProps } from './BloodGlucoseView';

interface BloodGlucoseState {
  chartHistory: BloodGlucoseRecord[];
  graphData: BloodGlucoseGraphItem[];
  fragments: ChartFragment[];
  chartMetaFormFields: Map<string, ChartMetaFormField>;
}

class BloodGlucose extends Component<ChartComponentProps, BloodGlucoseState> {
  constructor(props) {
    super(props);
    this.state = this.buildDefaultState();
  }

  async componentDidMount(): Promise<void> {
    this.props.initState(this.buildDefaultFormFields());
    await appHelper.useLoader(this.props.loadChartData().then(this.updateChartHistoryAndGraph));
  }

  buildDefaultState = (): BloodGlucoseState => ({
    chartHistory: [],
    chartMetaFormFields: this.buildDefaultFormFields(),
    graphData: [],
    fragments: []
  });

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { intl, locale } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getBloodGlucoseFormFields(locale).forEach(({ isHidden, name, label, ...item }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });

    return dataMap;
  };

  transformGraphData = (dataRes): BloodGlucoseGraphItem[] => {
    const { intl, locale } = this.props;
    return dataRes
      .filter((item) => item.active)
      .map((item) => ({
        date: dateTimeHelper.formatDate({ date: moment(item.chartingAt).toDate(), locale }),
        dateTime: dateTimeHelper.toMoment(item.chartingAt).format(intl.formatMessage({ id: LanguageKeys.GRAPH_DATE_TIME_FORMAT })),
        glucose: item.glucoseReading,
        glucoseDisplay: `${item.glucoseReading} ${intl.formatMessage({ id: LanguageKeys.BLOOD_GLUCOSE.GLUCOSE_READING_UNIT })}`
      }));
  };

  buildFragment = () => {
    const { chartingTime, formFieldMap, locale } = this.props;
    const basicInfo = chartService.createBaseFragment({ chartingTime });

    const chartDataNursingActionsOther = formFieldMap.get(FormField.NURSING_ACTIONS_OTHER)?.value;

    const chartData = {
      glucoseReading: locale === Locales.EN_US ? parseInt(formFieldMap.get(FormField.GLUCOSE_READING).value, 10) : parseFloat(formFieldMap.get(FormField.GLUCOSE_READING).value),
      timeSinceLastMeal: formFieldMap.get(FormField.TIME_LAST_MEAL).value,
      nursingActions: {
        choices: formFieldMap
          .get(FormField.NURSING_ACTIONS)
          .chartContent.filter((item) => item.selected)
          .map((item) => item.value),
        ...(chartDataNursingActionsOther && {
          other: chartDataNursingActionsOther
        })
      },
      notes: formFieldMap.get(FormField.NOTE).value
    };

    return { ...basicInfo, chartData };
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(() => this.props.loadChartData())
        .then(this.updateChartHistoryAndGraph)
        .then(this.props.showDeleteSuccess)
    );
  };

  updateChartHistoryAndGraph = (apiRes) => {
    const records = apiRes?.data.map((fragment) => {
      const { fragmentId: id, active, chartingAt, createdAt, creator, modifier } = fragment;
      return { id, active, chartingAt, createdAt, creator, modifier, ...fragment.chartData };
    });
    const graphData = this.transformGraphData(records);
    this.setState({ fragments: apiRes?.data, chartHistory: records as BloodGlucoseRecord[], graphData });
  };

  handleSaveClick = async () => {
    await this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.afterSave });
  };

  afterSave = () =>
    Promise.resolve()
      .then(() => this.props.loadChartData())
      .then(this.updateChartHistoryAndGraph);

  render() {
    const { handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, getContentMap, intl, locale } = this.props;
    const { chartHistory, graphData } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: BloodGlucoseViewProps = {
      chartHistory,
      chartMetaFormFields: formFieldMap,
      deleteHistory: this.deleteHistory,
      graphData,
      formSubmittedCount,
      chartMetaContentMap: getContentMap(),
      chartActionsProps,
      intl,
      locale
    };

    return <BloodGlucoseView {...viewProps} />;
  }
}

export { BloodGlucose as BaseBloodGlucose };
export default withChartLogic(BloodGlucose);

import { MentalStatusFragment } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import ReadOnlyInput from 'components/features/chart/admission-history/shared/ReadOnlyInput';
import { FormField, FormFieldLabel, SectionTitle } from './constant';

export interface MentalStatusReadOnlyProps {
  selectedRecord: MentalStatusFragment;
}

const MentalStatusReadOnly = (props: MentalStatusReadOnlyProps) => {
  const record = props.selectedRecord;
  return (
    <>
      <h2>{SectionTitle.MENTAL_STATUS_EXAMINATION}</h2>
      <Box className="sng-article">
        {!record ? (
          <h3>Chart Omitted</h3>
        ) : (
          <>
            <ELSFlex wrap left className="u-flex-gutter-3x u-flex-row-gap-2x">
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.APPEARANCE}
                  customTitle={FormFieldLabel.APPEARANCE}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.MEMORY}
                  customTitle={FormFieldLabel.MEMORY}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.BEHAVIORAL_ACTIVITY}
                  customTitle={FormFieldLabel.BEHAVIORAL_ACTIVITY}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex wrap left className="u-flex-gutter-3x u-flex-row-gap-2x">
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.SUICIDAL_IMPULSES}
                  customTitle={FormFieldLabel.SUICIDAL_IMPULSES}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.JUDGMENT_INSIGHT}
                  customTitle={FormFieldLabel.JUDGMENT_INSIGHT}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.HOMICIDAL_IMPULSES}
                  customTitle={FormFieldLabel.HOMICIDAL_IMPULSES}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.ORIENTATION}
                  customTitle={FormFieldLabel.ORIENTATION}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.ATTENTION}
                  customTitle={FormFieldLabel.ATTENTION}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.THOUGHT_CONTENT}
                  customTitle={FormFieldLabel.THOUGHT_CONTENT}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.MOOD}
                  customTitle={FormFieldLabel.MOOD}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.AFFECT}
                  customTitle={FormFieldLabel.AFFECT}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.SPEECH}
                  customTitle={FormFieldLabel.SPEECH}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={props.selectedRecord}
                  sectionTitle={SectionTitle.MENTAL_STATUS_EXAMINATION}
                  formFieldId={FormField.THOUGHT_FORM}
                  customTitle={FormFieldLabel.THOUGHT_FORM}
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </>
  );
};
export default MentalStatusReadOnly;

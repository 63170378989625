import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { PatientContext } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import MobilityPhysicalTherapyReadOnly from './MobilityPhysicalTherapyReadOnly';
import { ChartViewProps } from '../../../shared/constants';

export interface MobilityPhysicalTherapyViewProps extends ChartViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  patientContext: PatientContext;
}

const MobilityPhysicalTherapyView = (props: MobilityPhysicalTherapyViewProps) => {
  const { isLocked, statusFragment, formFieldMap, chartActionsProps, formSubmittedCount, fragment, resetAll, patientContext } = props;
  return (
    <div className="mobility-physical-therapy">
      {isLocked ? (
        <MobilityPhysicalTherapyReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={patientContext} />
      ) : (
        <>
          <h1>{LanguageKeys.MOBILITY_PHYSICAL_THERAPY.MOBILITY_PHYSICAL_THERAPY_SCREEN}</h1>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />

          <Box mt2>
            {patientContext.locale !== Locales.EN_AU && (
              <>
                <ErrorFormField
                  className="error-field--no-bottom"
                  formField={formFieldMap.get(FormField.ORDERS_PHYSICAL_THERAPY)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <SectionDivider />
              </>
            )}

            {patientContext.locale === Locales.EN_AU && (
              <>
                <h2>{LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH}</h2>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH)} formSubmittedCount={formSubmittedCount} />
                </Box>

                <SectionDivider />

                <h2>{LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK}</h2>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.FALLS_RISK_PATIENT)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.FALLS_RISK_ADDITIONAL_COMMENTS)} formSubmittedCount={formSubmittedCount} />
                </Box>

                <SectionDivider />

                <h2>{LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION}</h2>
                <Box mt>
                  <ErrorFormField formFieldMap={formFieldMap} formField={formFieldMap.get(FormField.ADDITIONAL_INFORMATION)} formSubmittedCount={formSubmittedCount} />
                </Box>

                <SectionDivider />
              </>
            )}
          </Box>

          <h2 className="u-els-margin-bottom">{LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS}</h2>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField
                useDefaultMargin={false}
                className="u-els-margin-bottom-1x"
                formField={formFieldMap.get(FormField.PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED)}
                formSubmittedCount={formSubmittedCount}
              />
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.PHYSICAL_THERAPY_PROBLEMS_SELECT)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField
                formField={formFieldMap.get(FormField.PHYSICAL_THERAPY_PROBLEMS_CONDITION)}
                className="chart-input__dropdown"
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
          </ELSFlex>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

MobilityPhysicalTherapyView.displayName = 'MobilityPhysicalTherapyView';
export default MobilityPhysicalTherapyView;

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, GroupScaleQuestion } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, getDeliveryRoomSummaryFormFieldBuilderItems } from './constants';
import DeliveryRoomSummaryView, { DeliveryRoomSummaryViewProps } from './DeliveryRoomSummaryView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface DeliveryRoomSummaryState {
  isLocked: boolean;
  fragment: ChartFragment;
}

class DeliveryRoomSummary extends Component<ChartComponentProps, DeliveryRoomSummaryState> {
  static displayName = 'DeliveryRoomSummary';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        this.setState({ fragment: chartFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { intl, locale } = this.props;
    const { fragment } = this.state;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getDeliveryRoomSummaryFormFieldBuilderItems(fragment, locale).forEach(({ isHidden, label, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });

    return dataMap;
  };

  getGroupScaleQuestion = (): GroupScaleQuestion[] => {
    return [
      {
        label: LanguageKeys.DELIVERY_ROOM_SUMMARY.HEART_RATE,
        formFields: [FormField.HEART_RATE_1_MINUTES, FormField.HEART_RATE_5_MINUTES, FormField.HEART_RATE_10_MINUTES]
      },
      {
        label: LanguageKeys.DELIVERY_ROOM_SUMMARY.RESPIRATORY_RATE,
        formFields: [FormField.RESPIRATORY_RATE_1_MINUTES, FormField.RESPIRATORY_RATE_5_MINUTES, FormField.RESPIRATORY_RATE_10_MINUTES]
      },
      {
        label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MUSCLE_TONE,
        formFields: [FormField.MUSCLE_TONE_1_MINUTES, FormField.MUSCLE_TONE_5_MINUTES, FormField.MUSCLE_TONE_10_MINUTES]
      },
      {
        label: LanguageKeys.DELIVERY_ROOM_SUMMARY.REFLEX_IRRITABILITY,
        formFields: [FormField.REFLEX_IRRITABILITY_1_MINUTES, FormField.REFLEX_IRRITABILITY_5_MINUTES, FormField.REFLEX_IRRITABILITY_10_MINUTES]
      },
      {
        label: LanguageKeys.DELIVERY_ROOM_SUMMARY.COLOR,
        formFields: [FormField.COLOR_1_MINUTES, FormField.COLOR_5_MINUTES, FormField.COLOR_10_MINUTES]
      }
    ];
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Delivery Room Summary';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY,
          records: [
            buildPatientRecord(formFieldMap, FormField.DAY),
            buildPatientRecord(formFieldMap, FormField.SEX),
            buildPatientRecord(formFieldMap, FormField.DELIVERY_PLACENTA),
            buildPatientRecord(formFieldMap, FormField.TIME),
            buildPatientRecord(formFieldMap, FormField.PROVIDER),
            buildPatientRecord(formFieldMap, FormField.TYPE),
            buildPatientRecord(formFieldMap, FormField.CORD),
            buildPatientRecord(formFieldMap, FormField.TYPE_DELIVERY_OTHER),
            ...buildPatientRecords(formFieldMap, FormField.TYPE_DELIVERY)
          ]
        },
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR,
          records: [
            buildPatientRecord(formFieldMap, FormField.FIRST_STAGE_HOURS),
            buildPatientRecord(formFieldMap, FormField.SECOND_STAGE_HOURS),
            buildPatientRecord(formFieldMap, FormField.SECOND_STAGE_MINUTES),
            buildPatientRecord(formFieldMap, FormField.THIRD_STAGE_MINUTES),
            buildPatientRecord(formFieldMap, FormField.COMPLICATIONS_DURING_DELIVERY),
            buildPatientRecord(formFieldMap, FormField.COMPLICATIONS_DURING_DELIVERY_TEXT)
          ]
        },
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN,
          records: [
            buildPatientRecord(formFieldMap, FormField.COMMENTS),
            buildPatientRecord(formFieldMap, FormField.EYE_PROPHYLAXIS_TIME),
            buildPatientRecord(formFieldMap, FormField.EYE_PROPHYLAXIS_SITE),
            buildPatientRecord(formFieldMap, FormField.EYE_PROPHYLAXIS_INITIALS),
            buildPatientRecord(formFieldMap, FormField.VITAMIN_K_TIME),
            buildPatientRecord(formFieldMap, FormField.VITAMIN_K_SITE),
            buildPatientRecord(formFieldMap, FormField.VITAMIN_K_INITIALS),
            buildPatientRecord(formFieldMap, FormField.NALOXONE_TIME),
            buildPatientRecord(formFieldMap, FormField.NALOXONE_SITE),
            buildPatientRecord(formFieldMap, FormField.NALOXONE_INITIALS),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_VACCINE_TIME),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_VACCINE_SITE),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_VACCINE_INITIALS),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS),
            buildPatientRecord(formFieldMap, FormField.OTHER_MEDICATIONS_TIME),
            buildPatientRecord(formFieldMap, FormField.OTHER_MEDICATIONS_SITE),
            buildPatientRecord(formFieldMap, FormField.OTHER_MEDICATIONS_INITIALS),
            ...buildPatientRecords(formFieldMap, FormField.RESUSCITATION)
          ]
        },
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT,
          records: [
            buildPatientRecord(formFieldMap, FormField.NUTRITION),
            buildPatientRecord(formFieldMap, FormField.BREAST_MINUTES),
            buildPatientRecord(formFieldMap, FormField.FORMULA_AMOUNT),
            buildPatientRecord(formFieldMap, FormField.FORMULA_TYPE),
            buildPatientRecord(formFieldMap, FormField.GLUCOSE_AMOUNT),
            buildPatientRecord(formFieldMap, FormField.FIRST_WEEK),
            buildPatientRecord(formFieldMap, FormField.FIRST_STOOL),
            buildPatientRecord(formFieldMap, FormField.DESCRIBE)
          ]
        },
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.GENERAL_NOTES,
          records: [buildPatientRecord(formFieldMap, FormField.GENERAL_NOTES)]
        },
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.APGAR_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.APGAR_SCORE, true, true)]
        },
        {
          sectionTitle: LanguageKeys.DELIVERY_ROOM_SUMMARY.TOTAL_APGAR_SCALE,
          records: [
            buildPlainRecord({
              formField: FormField.APGAR_SCORE,
              title: LanguageKeys.DELIVERY_ROOM_SUMMARY.TOTAL_SCORE,
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.APGAR_SCORE))?.score?.value,
              linkedFormFieldIds: [
                FormField.HEART_RATE_1_MINUTES,
                FormField.HEART_RATE_5_MINUTES,
                FormField.HEART_RATE_10_MINUTES,
                FormField.RESPIRATORY_RATE_1_MINUTES,
                FormField.RESPIRATORY_RATE_5_MINUTES,
                FormField.RESPIRATORY_RATE_10_MINUTES,
                FormField.MUSCLE_TONE_1_MINUTES,
                FormField.MUSCLE_TONE_5_MINUTES,
                FormField.MUSCLE_TONE_10_MINUTES,
                FormField.REFLEX_IRRITABILITY_1_MINUTES,
                FormField.REFLEX_IRRITABILITY_5_MINUTES,
                FormField.REFLEX_IRRITABILITY_10_MINUTES,
                FormField.COLOR_1_MINUTES,
                FormField.COLOR_5_MINUTES,
                FormField.COLOR_10_MINUTES
              ]
            })
          ]
        }
      ]
    };
    const fragmentId = this.state.fragment?.fragmentId;
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  render() {
    const { isLocked } = this.state;
    const { formFieldMap, formSubmittedCount, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, locale, intl } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: LanguageKeys.SAVE_AND_CONTINUE,
      cancelButtonText: LanguageKeys.CANCEL,
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: DeliveryRoomSummaryViewProps = {
      chartActionsProps,
      formSubmittedCount,
      formFieldMap,
      locale,
      intl,
      groupScaleQuestion: this.getGroupScaleQuestion()
    };
    return <DeliveryRoomSummaryView {...viewProps} />;
  }
}

export { DeliveryRoomSummary as BaseDeliveryRoomSummary };
export default compose(withChartLogic, withAdmissionHistory)(DeliveryRoomSummary);

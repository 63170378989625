import { ChartFragment } from 'models/api-response';
import { AppMessage } from 'constants/app.constant';
import { ChartHistory } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, FormFieldLabel, SectionTitle } from '../shared/constants';

interface InterventionHistoryProps {
  chartHistory: ChartFragment[];
}

const InterventionHistory = (props: InterventionHistoryProps) => {
  const { chartHistory } = props;

  const dataSource = {
    headers: [
      { text: FormFieldLabel.TIME, field: FormField.INTERVENTION_DATE_TIME, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.FUNCTIONAL_ASSESSMENT, field: FormField.FUNCTIONAL_ASSESSMENT, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.OXYGEN_SATURATION_ROOM_AIR, field: FormField.OXYGEN_SATURATION_ROOM_AIR, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.OXYGEN_SATURATION_OXYGEN_SUPP, field: FormField.OXYGEN_SATURATION_OXYGEN_SUPP, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.OXYGEN_THERAPY_DETAIL, field: FormField.OXYGEN_THERAPY_DETAIL, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.ANTERIAL_BLOOD_DASES, field: FormField.ANTERIAL_BLOOD_DASES, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.AEROSOL_TREATMENT, field: FormField.AEROSOL_TREATMENT, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.BILEVEL_POSITIVE_AIRWAY_PRESSURE, field: FormField.BILEVEL_POSITIVE_AIRWAY_PRESSURE, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE, field: FormField.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE, field: FormField.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.INCENTIVE_SPIROMETER, field: FormField.INCENTIVE_SPIROMETER, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.INDUCED_SPUTUM, field: FormField.INDUCED_SPUTUM, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.INHALER, field: FormField.INHALER, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.POSITIVE_EXPIRATORY_PRESSURE_THERAPY, field: FormField.POSITIVE_EXPIRATORY_PRESSURE_THERAPY, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.FUNCTION_TEST, field: FormField.FUNCTION_TEST, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.SUCTIONING, field: FormField.SUCTIONING, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.VENTILATOR, field: FormField.VENTILATOR, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.TREATMENT, field: FormField.TREATMENT, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.PATIENT_FAMILY_TEACHING, field: FormField.PATIENT_FAMILY_TEACHING, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.RESPIRATORY_THERAPIST_NAME, field: FormField.RESPIRATORY_THERAPIST_NAME, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.RESPIRATORY_THERAPIST_SIGNATURE, field: FormField.RESPIRATORY_THERAPIST_SIGNATURE, section: SectionTitle.NO_SECTION }
    ],
    data: chartHistory
  };

  return (
    <div className="intervention">
      <ELSFlex left wrap>
        <ELSFlexItem w={chartHistory.length === 0 ? '1o2' : '1o1'}>
          <ChartHistory noTableDataMessage=" " renderVertical tableTitleContents={[SectionTitle.NO_SECTION]} dataSource={dataSource} />
        </ELSFlexItem>
        {chartHistory.length === 0 && (
          <ELSFlexItem w="1o2">
            <div className="margin-top-4x1o2 u-els-margin-left table-message">{AppMessage.NO_TABLE_DATA}</div>
          </ELSFlexItem>
        )}
      </ELSFlex>
    </div>
  );
};

InterventionHistory.displayName = 'InterventionHistory';
export default InterventionHistory;

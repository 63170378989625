import { LanguageKeys } from 'lang';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

const GastrointestinalAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, resetAll, intl } = props;

  return (
    <div className="gastrointestinal-assessment">
      <h1>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.GASTROINTESTINAL_ASSESSMENT}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <Box mt2>
        <h2>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.MOUTH_GUMS_TEETH}</h2>
        <Box mt2>
          <ELSFlex className="u-flex-gutter-3x" left wrap>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.CLEFT_LIP)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.CLEFT_PALATE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ELSFlex className="u-flex-gutter-3x" left wrap>
            <ELSFlexItem w="1o2" md="1o1" className="column-left">
              <div>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.GUMS_AND_MOUTH)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </div>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.MOUTH_GUMS_TEETH_NOTES)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="column-right">
              <ErrorFormField formField={formFieldMap.get(FormField.TEETH_BRIDGES_DENTURES)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>
        <Box>
          <h2>{intl.formatMessage({ id: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.ESOPHAGEAL_ASSESSMENT })}</h2>
          <Box mt>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.ESOPHAGEAL_ASSESSMENT)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.ESOPHAGEAL_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>
        </Box>

        <SectionDivider />

        <ELSFlex className="u-flex-gutter-3x u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h2>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.ABDOMEN}</h2>
            <Box mt>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.ABDMONINAL_ASSESSMENT)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
            <Box mt>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.TENDER_ON_PALPATION)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h2>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.GASTROINTESTINAL}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.NO_GASTRIC_PROBLEM_NOTED)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            <Box mt>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.GASTROINTESTINAL)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
            <Box mt>
              <ErrorFormField formField={formFieldMap.get(FormField.GASTROINTESTINAL_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <div>
          <h2>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL}</h2>
          <Box mt>
            <ErrorFormField formField={formFieldMap.get(FormField.DATE_OF_LAST_BOWEL_MOVEMENT)} formSubmittedCount={formSubmittedCount} />
          </Box>
          <Box mt2>
            <ErrorFormField formField={formFieldMap.get(FormField.CONTINENCE_OF_BOWEL)} formSubmittedCount={formSubmittedCount} />
          </Box>
          <ELSFlex className="u-flex-gutter-3x u-els-margin-top-2x" left wrap>
            <ELSFlexItem w="1o2" md="1o1">
              <h4>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.BOWEL_SOUNDS}</h4>
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.BOWEL_SOUNDS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.BOWEL_SOUNDS_OTHER)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </Box>
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h4>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL_ASSESSMENT}</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.NO_BOWEL_PROBLEMS_NOTED)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.INTESTINAL_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </ELSFlexItem>
            <ELSFlexItem className="u-els-margin-bottom-2x" w="1o2" md="1o1">
              <h4>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.RECTUM_CHARACTERISTICS}</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.NO_REPORTED_RECTAL_PROBLEMS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.RECTUM)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </Box>
            </ELSFlexItem>
            <ELSFlexItem className="u-els-margin-bottom-2x" w="1o2" md="1o1">
              <h4>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.STOOL_CHARACTERISTICS}</h4>
              <Box mt>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.STOOL_CHARACTERISTICS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </Box>
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h4>{LanguageKeys.GASTROINTESTINAL_ASSESSMENT.INTESTINAL_DIVERSION}</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.INTESTINAL_DIVERSION_PATIENT_HAS_OSTOMY)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              <Box mt>
                <ErrorFormField formField={formFieldMap.get(FormField.INTESTINAL_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </Box>
            </ELSFlexItem>
          </ELSFlex>
        </div>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      <RelatedCharts chartIds={[NAV_ID.GASTROINTESTINAL_INTERVENTIONS]} />
    </div>
  );
};

GastrointestinalAssessmentView.displayName = 'GastrointestinalAssessmentView';
export default GastrointestinalAssessmentView;

import { isEqual } from 'lodash';
import { Component } from 'react';
import { ChartFragmentRS, ProgressNoteFragment } from 'models/api-response';
import { ChartComponentProps } from 'models/ui';
import { accessibilityHelper } from 'helpers';
import { withChartLogic } from 'components/common';
import { FormField, buildFormFields } from './ProgressNotes';
import ProgressNotesSidebarView, { ProgressNotesSidebarViewProps } from './ProgressNotesSidebarView';

export interface ProgressNotesSidebarProps extends ChartComponentProps {
  selectedRecord: ProgressNoteFragment;
  selectedNavId: string;
  onClose: Function;
  onUpdate: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  sidebarProps: Function;
}

interface ProgressNotesSidebarState {
  chartingAt: string;
}

class ProgressNotesSidebar extends Component<ProgressNotesSidebarProps, ProgressNotesSidebarState> {
  static displayName = 'ProgressNotesSidebar';

  constructor(props) {
    super(props);
    this.state = {
      chartingAt: ''
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<ProgressNotesSidebarProps>) {
    if (this.props.selectedRecord && !isEqual(prevProps.selectedRecord, this.props.selectedRecord)) {
      this.setState({
        chartingAt: this.props.selectedRecord.chartingAt
      });
      this.props.initState(buildFormFields(this.props.selectedRecord));
    }
  }

  handleUpdate = () => {
    const { formFieldMap, selectedRecord, onUpdate } = this.props;
    const fragment = {
      ...selectedRecord,
      chartData: {
        ...selectedRecord.chartData,
        [FormField.PROGRESS_NOTES]: formFieldMap.get(FormField.PROGRESS_NOTES)?.value,
        [FormField.SIGNATURE]: formFieldMap.get(FormField.SIGNATURE)?.value
      }
    } as ProgressNoteFragment;

    this.props.handleSaveClick([fragment], { afterSave: onUpdate });
  };

  render() {
    const { selectedRecord, formFieldMap, formSubmittedCount, hasUnsavedChanges, onClose, sidebarProps, locale } = this.props;
    const { chartingAt } = this.state;
    const viewProps: ProgressNotesSidebarViewProps = {
      open: !!selectedRecord,
      chartingAt,
      chartMetaFormFields: formFieldMap,
      formSubmittedCount,
      isDisabledSave: !hasUnsavedChanges,
      lastFocusableElement: accessibilityHelper.updateLastFocusableElement(hasUnsavedChanges),
      onClose,
      onUpdate: this.handleUpdate,
      sidebarProps,
      locale
    };
    return <ProgressNotesSidebarView {...viewProps} />;
  }
}

export { ProgressNotesSidebar as BaseProgressNotesSidebar };
export default withChartLogic(ProgressNotesSidebar);

import { Component, ReactNode } from 'react';
import { Box, ChartsInvolved, MultiLine } from 'components/common';
import { ELSTab, ELSTabGroup } from 'components/common/els';

export enum TABS {
  INSTRUCTIONS = 0,
  CHARTS_INVOLVED = 1
}

interface SectionPhaseProps {
  sectionLength: number;
  sectionIndex: number;
  sectionPhaseIndex: number;
  title?: string;
  instructions?: string | ReactNode;
  chartsInvolved: string[];
}

interface SectionPhaseState {
  activeIndex: number;
}

export default class SectionPhase extends Component<SectionPhaseProps, SectionPhaseState> {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: TABS.INSTRUCTIONS
    };
  }

  handleTabChange = (index: number) => {
    this.setState({ activeIndex: index });
  };

  render() {
    const { sectionIndex, sectionLength, sectionPhaseIndex, title, instructions, chartsInvolved } = this.props;
    const { activeIndex } = this.state;
    return (
      <div className="section-phase section-box">
        <Box mb>{`${sectionIndex} of ${sectionLength} — Phase ${sectionPhaseIndex} Case Study Information`}</Box>
        <Box mb2 className="u-els-font-size-display">
          {title}
        </Box>
        <ELSTabGroup onChangeActiveIndex={this.handleTabChange} activeIndex={activeIndex}>
          <ELSTab title="Instructions" />
          <ELSTab title="Charts involved" />
        </ELSTabGroup>
        {activeIndex === TABS.INSTRUCTIONS && <MultiLine text={instructions} />}
        {activeIndex === TABS.CHARTS_INVOLVED && chartsInvolved.length > 0 && <ChartsInvolved charts={chartsInvolved} />}
      </div>
    );
  }
}

import moment from 'moment';
import { Component } from 'react';
import { ELSDateTime, ELSDateTimeProps, ELSWithValidatorProps } from '@els/els-component-form-field-react';
import { dateTimeHelper } from 'helpers';

interface ELSDateTimeWrapperProps extends ELSDateTimeProps, ELSWithValidatorProps {
  value: string;
}
interface ELSDateTimeWrapperState {
  value: Date;
  resetKey: number;
}

class ELSDateTimeWrapper extends Component<ELSDateTimeWrapperProps, ELSDateTimeWrapperState> {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(this.getComputedDateOrDefault(props.value)),
      resetKey: 0
    };
  }

  componentDidUpdate(prevProps: ELSDateTimeWrapperProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      const val = this.getComputedDateOrDefault(value);
      this.setState((prevState) => ({
        value: new Date(val),
        // If we update ELSDateTime by passing props into it instead of changing it directly in the UI,
        // it may not render the date correctly, so we need to reset it
        // (check componentWillReceiveProps in DateComponent in els-component-form-field-react)
        // When date is invalid (ex: user enters "abc"), if we reset the component, it will have a display error
        resetKey: moment(val).isValid() ? prevState.resetKey + 1 : prevState.resetKey
      }));
    }
  }

  getComputedDateOrDefault = (date: string): string => {
    if (!date) return '';

    if (moment(date).isValid()) return date;

    if (moment(date.replace(/-/g, '/')).isValid()) return date.replace(/-/g, '/');

    return moment().seconds(0).toISOString();
  };

  handleChange = (event, value, originalInput) => {
    const { originalDateInput } = originalInput;
    const isDateValid = moment(value).isValid() && (typeof originalDateInput === 'string' ? dateTimeHelper.isDateCorrectFormat(originalDateInput) : true);
    const val = isDateValid ? moment(value).toISOString() : '';

    this.setState((prevState) => ({
      value: new Date(val),
      // If we update ELSDateTime by passing props into it instead of changing it directly in the UI,
      // it may not render the date correctly, so we need to reset it
      // (check componentWillReceiveProps in DateComponent in els-component-form-field-react)
      // When date is invalid (ex: user enters "abc"), if we reset the component, it will have a display error
      resetKey: isDateValid ? prevState.resetKey + 1 : prevState.resetKey
    }));

    this.props.changeHandler(event, val, originalInput);
  };

  render() {
    return <ELSDateTime key={this.state.resetKey} {...this.props} changeHandler={this.handleChange} value={this.state.value} parseDate={dateTimeHelper.datePickerParser} />;
  }
}

export default ELSDateTimeWrapper;

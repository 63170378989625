import cx from 'classnames';
import { connect } from 'react-redux';
import { ProviderOrderFragment } from 'models/api-response';
import { Locales } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import { AccordionBadge, AccordionTitle, Box, ChartPermission } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';

export interface ProviderOrderHistoryViewProps {
  chartHistory: ProviderOrderFragment[];
  onEdit: Function;
  onDelete: Function;
  locale: Locales;
}

const ProviderOrderHistoryView = (props: ProviderOrderHistoryViewProps) => (
  <div className="sng-accordion">
    {props.chartHistory.length === 0 && <NothingToSee>Nothing to see, yet! Get started adding provider orders.</NothingToSee>}
    {props.chartHistory.length > 0 && (
      <ELSAccordion id="ProviderOrderAccordion">
        {props.chartHistory.map((providerOrder) => (
          <ELSAccordionItem
            key={providerOrder.fragmentId}
            id={providerOrder.fragmentId}
            leftIcon="chevron-down"
            customHeader={
              <>
                <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !providerOrder.active })}>
                  {providerOrder?.chartData?.providerOrders.slice(0, 100)}
                  {providerOrder?.chartData?.providerOrders.length > 100 && '...'}
                </AccordionTitle>
                {providerOrder.chartingAt && <AccordionBadge>{dateTimeHelper.toDateTime(providerOrder.chartingAt, null, props.locale)}</AccordionBadge>}
              </>
            }
          >
            <Box mt mb2>
              <MultiLineWithEmpty text={providerOrder?.chartData?.providerOrders} />
            </Box>
            <div>
              <Box mr2 className="u-els-display-inline-block">
                <h4>Provider Signature</h4>
                <MultiLineWithEmpty text={providerOrder?.chartData?.signature} />
              </Box>
              <div className="provider-order__sub-title">
                <h4>Verified By / Initials</h4>
                <MultiLineWithEmpty text="" />
              </div>
            </div>
            {providerOrder.active && (
              <ChartPermission fragmentCreatorId={providerOrder.creator?.id}>
                {({ allowEditFragment, allowDeleteFragment }) => (
                  <Box mt2 mb>
                    {allowEditFragment && <ELSButton className="sng-button u-els-margin-right" text="Edit" onClick={() => props.onEdit(providerOrder)} />}
                    {allowDeleteFragment && <ELSButton className="sng-button c-els-button--secondary" text="Delete" onClick={() => props.onDelete(providerOrder)} />}
                  </Box>
                )}
              </ChartPermission>
            )}
          </ELSAccordionItem>
        ))}
      </ELSAccordion>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});
ProviderOrderHistoryView.displayName = 'ProviderOrderHistoryView';

export default connect(mapStateToProps)(ProviderOrderHistoryView);

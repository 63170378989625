export enum FormField {
  NO_CARE_REQUIRED = 'noCareRequired',
  SAFETY_ISSUES = 'safetyIssues',
  PATIENT_RESPONSE = 'patientResponse',
  CARE_NOTES = 'safetyCareNotes'
}

export enum SectionTitle {
  SAFETY_INTERVENTIONS = 'Safety Interventions',
  SAFETY_ISSUES = 'Safety Issues',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Notes'
}

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID, PEDIATRIC_MAX_AGE_IN_DAYS } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import SafetyAssessmentView, { SafetyAssessmentViewProps } from './SafetyAssessmentView';
import { Title } from '../shared/constants';

interface SafetyAssessmentProps extends ChartComponentProps {
  patientContext: PatientContext;
}

interface SafetyAssessmentState {
  relatedChartIds: string[];
}

class SafetyAssessment extends Component<SafetyAssessmentProps, SafetyAssessmentState> {
  static displayName = 'SafetyAssessment';
  customFormFieldTitleMap: Map<string, string>;

  constructor(props) {
    super(props);
    this.state = {
      relatedChartIds: []
    };
    this.customFormFieldTitleMap = new Map();
    this.customFormFieldTitleMap.set(FormField.ORIENTATION, SectionTitle.ORIENTATION);
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    this.buildRelatedCharts();
  }

  componentDidUpdate(prevProps: Readonly<SafetyAssessmentProps>) {
    const isRestraintChanged = prevProps?.formFieldMap && prevProps.formFieldMap.get(FormField.RESTRAINTS)?.value !== this.props.formFieldMap?.get(FormField.RESTRAINTS)?.value;
    if (isRestraintChanged) {
      const isRestraintChecked = !!this.props.formFieldMap.get(FormField.RESTRAINTS).value;
      if (isRestraintChecked) {
        this.setState((state) => ({ relatedChartIds: state.relatedChartIds.concat(NAV_ID.RESTRAINTS) }));
      } else {
        this.setState((state) => ({ relatedChartIds: state.relatedChartIds.filter((item) => item !== NAV_ID.RESTRAINTS) }));
      }
    }
  }

  buildRelatedCharts = () => {
    const ageInDays = this.props.patientContext?.ageInDays;
    let relatedChartIds = [NAV_ID.SAFETY, NAV_ID.MORSE_FALL_SCALE];
    if (ageInDays < PEDIATRIC_MAX_AGE_IN_DAYS) {
      relatedChartIds = [NAV_ID.SAFETY, NAV_ID.HUMPTY_DUMPTY_FALLS_SCALE];
    }
    this.setState({ relatedChartIds });
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkBoxes = [FormField.ASSESSMENT_NOT_REQUIRED, FormField.RESTRAINTS];

    const radioChoices = [FormField.ORIENTATION];

    const multiselect = [FormField.BRACELET_CHECK];

    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    radioChoices.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE })));
    multiselect.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));

    dataMap.set(FormField.BRACELET_CHECK_NOTE, createFormField({ name: FormField.BRACELET_CHECK_NOTE, type: FormFieldInputType.TEXT_AREA }));
    dataMap.set(
      FormField.ORIENTATION_DESCRIBE,
      createFormField({ name: FormField.ORIENTATION_DESCRIBE, type: FormFieldInputType.TEXT_AREA, label: 'Describe Limited Orientation' })
    );
    dataMap.set(FormField.FALL_RISK, createFormField({ name: FormField.FALL_RISK, type: FormFieldInputType.TEXT_BOX, label: 'Fall Risk Score' }));

    return dataMap;
  };

  handleSaveClick = () => {
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: SectionTitle.SAFETY_ASSESSMENT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { customFormFieldTitleMap } = this;

    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SAFETY_ASSESSMENT,
        fields: [FormField.ASSESSMENT_NOT_REQUIRED],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ORIENTATION,
        fields: [FormField.ORIENTATION, FormField.ORIENTATION_DESCRIBE],
        formFieldMap,
        customFormFieldTitleMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.FALL_RISK,
        fields: [FormField.FALL_RISK],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.RESTRAINTS,
        fields: [FormField.RESTRAINTS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.BRACELET_CHECK,
        fields: [FormField.BRACELET_CHECK, FormField.BRACELET_CHECK_NOTE],
        formFieldMap
      })
    ];
  };

  render() {
    const { relatedChartIds } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: SafetyAssessmentViewProps = {
      relatedChartIds,
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <SafetyAssessmentView {...viewProps} />;
  }
}

export { SafetyAssessment as BaseSafetyAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(SafetyAssessment);

/* eslint-disable sonarjs/cognitive-complexity */
import cx from 'classnames';
import moment from 'moment';
import { Component, ReactChild } from 'react';
import { ELSModalServiceType } from 'models/els';
import { ChartTable, ColumnAlign } from 'models/ui';
import { AppMessage, Locales } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';
import { Box, ChartPermission, DeleteCell, HorizontalScrollableContainer, MultiLine, withFormUtilities } from 'components/common';
import { ELSButton, ELSDataTable, ELSIcon } from 'components/common/els';
import './chart.history.scss';

/* eslint-disable @typescript-eslint/no-namespace */
declare global {
  namespace JSX {
    interface IntrinsicElements {
      column: ColumnProps;
    }
  }
}

interface ColumnProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  field?: string;
  header?: string | ReactChild;
  align?: string;
  customRender?: Function;
  customSort?: Function;
  sortable?: boolean;
  section?: string;
}

export interface ChartHistoryProps {
  modalService: ELSModalServiceType;
  className: string;
  minWidth: number;
  maxWidth: number;
  dataSource: ChartTable;
  showNumEntries?: boolean;
  deleteRecord: Function;
  showDeleteConfirmation(onConfirmClick: Function): Function;
  noTableDataMessage?: string;
  tableTitleContents?: Array<string>;
  renderVertical?: boolean;
  showDeleteColumn?: boolean;
  locale?: Locales;
}

class ChartHistory extends Component<ChartHistoryProps> {
  static displayName = 'ChartHistory';

  chartTimeRenderer = (row) => (
    <div className={cx('column__content-chart-time', { 'row--deleted': !row.active })}>
      {dateTimeHelper.formatDate({ date: moment(row.chartingAt).toDate(), locale: this.props.locale, includeTime: true })}
    </div>
  );

  compareChartTime = (t1, t2): number => moment(t1.chartingAt).diff(moment(t2.chartingAt)) || moment(t1.createdAt).diff(moment(t2.createdAt));

  deleteRenderer = (row) => (
    <ChartPermission fragmentCreatorId={row.creator?.id}>
      {({ allowDeleteFragment }) => allowDeleteFragment && <DeleteCell isActive={row.active} onDeleteClick={() => this.handleDeleteClick(row)} />}
    </ChartPermission>
  );

  chartTimeDeleteRender = (row) => (
    <div className="u-els-display-flex">
      <div className="u-els-padding-top-1o2">{this.chartTimeRenderer(row)}</div>
      {this.deleteRenderer(row)}
    </div>
  );

  noteRenderer = (row) => (
    <div>
      {row.notes && (
        <div className="u-cursor-pointer u-els-display-inline-block">
          <button className="sng-button-icon-wrapper" type="button" aria-label={row.notesTitle || 'Notes'} onClick={() => this.handleNoteClick(row.notes, row.notesTitle)}>
            <ELSIcon name="notebook" prefix="gizmo" color="secondary" />
          </button>
        </div>
      )}
    </div>
  );

  handleNoteClick = (note: string, noteTitle?: string) => {
    this.props.modalService.openModal({
      modalId: 'noteModal',
      color: 'primary',
      dialogAriaLabelledBy: 'dialog-label',
      closeAriaLabel: 'Close Navigation',
      content: (
        <div>
          <h2 id="dialog-label">{noteTitle || 'Note'}</h2>
          <MultiLine text={note} />
          <Box mt2>
            <ELSButton className="sng-button" text="Close" ariaLabel="Close Navigation" onClick={() => this.props.modalService.closeModal('noteModal')} />
          </Box>
        </div>
      )
    });
  };

  handleDeleteClick = (row) => this.props.showDeleteConfirmation(() => this.props.deleteRecord(row));

  render() {
    const { title, headers, data, tableCss, css } = this.props.dataSource;
    const { minWidth, maxWidth, noTableDataMessage, showNumEntries, tableTitleContents, renderVertical } = this.props;
    const showMessage = data?.length === 1 ? `Showing ${data.length} entry` : `Showing ${data.length} entries`;
    return (
      <div className={cx(this.props.className)} style={{ minWidth }}>
        {title && <h2>{title}</h2>}
        {showNumEntries !== false && (
          <div className="chart-history-table__entries">
            <p>{showMessage}</p>
          </div>
        )}
        <div className={cx('chart-history-table', { 'chart-history-table--vertical': renderVertical }, tableCss)} style={{ maxWidth }}>
          <HorizontalScrollableContainer>
            <ELSDataTable
              renderVertical={renderVertical}
              tableTitleContents={tableTitleContents}
              data={data}
              defaultSortField="chartingAt"
              defaultSortDirection="desc"
              columnHeaderCss={css}
            >
              {headers.map((col, index) => {
                if (col.field === 'chartingAt') {
                  return (
                    <column
                      key={col.field}
                      field={col.field}
                      customRender={this.chartTimeRenderer}
                      header={col.text || ' '}
                      sortable
                      section={col.section}
                      customSort={this.compareChartTime}
                    />
                  );
                }
                if (col.field === 'notes') {
                  return <column key={col.field} field={col.field} customRender={this.noteRenderer} header={col.text || ' '} section={col.section} />;
                }
                if (col.field === 'actionDelete' && this.props.showDeleteColumn) {
                  return (
                    <column
                      key={col.field}
                      field={col.field}
                      customRender={this.deleteRenderer}
                      header={col.text || <div className="u-els-hide-visually">Delete</div>}
                      align={ColumnAlign.Center}
                      section={col.section}
                    />
                  );
                }
                if (col.field === 'chartTimeActionDelete') {
                  return (
                    <column key={col.field} field={col.field} customRender={this.chartTimeDeleteRender} header={col.text || ''} align={ColumnAlign.Left} section={col.section} />
                  );
                }

                return (
                  <column
                    key={col.field}
                    field={col.field}
                    header={col.customHeader || col.text || ' '}
                    align={col.align}
                    customRender={col.customRender}
                    sortable={col.sortable}
                    section={col.section}
                  />
                );
              })}
            </ELSDataTable>
          </HorizontalScrollableContainer>
          {data.length === 0 && (
            <Box id="qa-table__no-data" mt mb2>
              {noTableDataMessage || AppMessage.NO_TABLE_DATA}
            </Box>
          )}
        </div>
      </div>
    );
  }
}

export { ChartHistory as BaseChartHistory };
export default withFormUtilities(ChartHistory);

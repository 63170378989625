import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import NutritionScreenReadOnly from './NutritionScreenReadOnly';

export interface NutritionScreenViewProps {
  intl: IntlShape;
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  patientContext: PatientContext;
}

const NutritionScreenView = (props: NutritionScreenViewProps) => (
  <div className="nutrition-screen">
    {props.isLocked ? (
      <NutritionScreenReadOnly chartFragment={props.fragment} statusFragment={props.statusFragment} patientContext={props.patientContext} intl={props.intl} />
    ) : (
      <>
        <h1>Nutrition Screen</h1>
        <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
        <ErrorFormField
          formField={props.formFieldMap.get(FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY_CONTAINER)}
          formFieldMap={props.formFieldMap}
          formSubmittedCount={props.formSubmittedCount}
        >
          <Box mt2>
            <h2>{LanguageKeys.NUTRITION_SCREEN.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY}</h2>
            <ELSFlex className="u-flex-grid-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField
                  className="u-els-margin-top-2x"
                  formField={props.formFieldMap.get(FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY)}
                  formSubmittedCount={props.formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
          </Box>
        </ErrorFormField>
        <Box mt2>
          <ErrorFormField
            formField={props.formFieldMap.get(FormField.GENERA_NUTRITION_SCREEN)}
            formFieldMap={props.formFieldMap}
            formSubmittedCount={props.formSubmittedCount}
            options={{ isNotSaveChildValueInScore: true }}
          />
        </Box>
        <SectionDivider />
        <Box>
          <ErrorFormField
            formField={props.formFieldMap.get(FormField.ILLNESS_INJURY_NUTRITION_SCREEN)}
            formFieldMap={props.formFieldMap}
            formSubmittedCount={props.formSubmittedCount}
          />
        </Box>
        <SectionDivider />
        <h2>Food Preferences/Diet Considerations</h2>
        <Box mt2>
          <ELSFlex className="u-flex-grid-2x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={props.formFieldMap.get(FormField.FOOD_PREFERENCES_DIET_CONSIDERATIONS)} formSubmittedCount={props.formSubmittedCount} />
              <ErrorFormField formField={props.formFieldMap.get(FormField.OTHER_INPUT)} formSubmittedCount={props.formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={props.formFieldMap.get(FormField.DIET_SNACK)} formSubmittedCount={props.formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>
        <Box mt2>
          <ELSFlex className="u-flex-grid-2x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={props.formFieldMap.get(FormField.FOOD_PREFERENCES)} formSubmittedCount={props.formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={props.formFieldMap.get(FormField.FOOD_DISLIKES)} formSubmittedCount={props.formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>
        <ChartPermission fragmentType={props.fragment?.fragmentType}>
          {({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}
        </ChartPermission>
      </>
    )}
  </div>
);

NutritionScreenView.displayName = 'NutritionScreenView';
export default NutritionScreenView;

import React, { Component } from 'react';
import { ChartFragmentRS, DiagnosticTestFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { withChartLogic } from 'components/common';
import { FormField } from './DiagnosticTest';
import EditDiagnosticTestSidebarView, { EditDiagnosticTestSidebarViewProps } from './EditDiagnosticTestSidebarView';

export interface EditDiagnosticTestSidebarProps extends ChartComponentProps {
  diagnosticTest: DiagnosticTestFragment;
  onCloseClick?: Function;
  onSaveClick?: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}
class EditDiagnosticTestSidebar extends Component<EditDiagnosticTestSidebarProps> {
  static displayName = 'EditDiagnosticTestSidebar';

  componentDidUpdate(prevProps: Readonly<EditDiagnosticTestSidebarProps>) {
    if (this.props.diagnosticTest && prevProps.diagnosticTest !== this.props.diagnosticTest) {
      this.props.initState(this.buildFormFields());
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const {
      diagnosticTest: { chartData }
    } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.DATE_OF_TEST,
      createFormField({
        name: FormField.DATE_OF_TEST,
        type: FormFieldInputType.DATE,
        value: chartData[FormField.DATE_OF_TEST],
        label: 'Date of Test (mm-dd-yyyy)'
      })
    );
    dataMap.set(
      FormField.DEFINITION_OF_TEST,
      createFormField({
        name: FormField.DEFINITION_OF_TEST,
        type: FormFieldInputType.TEXT_AREA,
        value: chartData[FormField.DEFINITION_OF_TEST],
        label: 'Definition and Description of the Test'
      })
    );
    dataMap.set(
      FormField.TEST_ORDERED_DESCRIPTION,
      createFormField({
        name: FormField.TEST_ORDERED_DESCRIPTION,
        type: FormFieldInputType.TEXT_AREA,
        value: chartData[FormField.TEST_ORDERED_DESCRIPTION],
        label: 'Significance of the Test Being Ordered for this Patient'
      })
    );
    dataMap.set(
      FormField.FINDINGS_AND_RESULTS,
      createFormField({
        name: FormField.FINDINGS_AND_RESULTS,
        type: FormFieldInputType.TEXT_AREA,
        value: chartData[FormField.FINDINGS_AND_RESULTS],
        label: 'Significant Findings and Results'
      })
    );
    return dataMap;
  };

  handleSaveClick = () => {
    const { formFieldMap, diagnosticTest, onSaveClick } = this.props;
    const chartData = {
      [FormField.DATE_OF_TEST]: formFieldMap.get(FormField.DATE_OF_TEST).value,
      [FormField.CATEGORY]: diagnosticTest.chartData.category,
      [FormField.DIAGNOSTIC_TEST]: diagnosticTest.chartData.diagnosticTest,
      [FormField.DEFINITION_OF_TEST]: formFieldMap.get(FormField.DEFINITION_OF_TEST).value,
      [FormField.TEST_ORDERED_DESCRIPTION]: formFieldMap.get(FormField.TEST_ORDERED_DESCRIPTION).value,
      [FormField.FINDINGS_AND_RESULTS]: formFieldMap.get(FormField.FINDINGS_AND_RESULTS).value
    };
    const fragment: DiagnosticTestFragment = {
      ...diagnosticTest,
      chartData
    };
    this.props.handleSaveClick([fragment], { afterSave: onSaveClick });
  };

  render() {
    const { formFieldMap, formSubmittedCount, sidebarProps } = this.props;

    const viewProps: EditDiagnosticTestSidebarViewProps = {
      open: !!this.props.diagnosticTest,
      onSaveClick: this.handleSaveClick,
      onCloseClick: this.props.onCloseClick,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap,
      diagnosticTest: this.props.diagnosticTest,
      sidebarProps
    };
    return <EditDiagnosticTestSidebarView {...viewProps} />;
  }
}

export { EditDiagnosticTestSidebar as BaseEditDiagnosisTestSidebar };
export default withChartLogic(EditDiagnosticTestSidebar);

import produce from 'immer';
import moment, { max } from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AssignmentType, GradingOption } from 'models/enum';
import { AssignmentWorkflowComponentProps, GradingRadioButton, SkipLinkSection } from 'models/ui';
import { Locales, RoutePath } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { getDateFormatByLocale } from 'helpers/datetime.helper';
import { courseService, featureService } from 'services';
import { compose } from 'redux';
import { appActions, appSelectors } from 'redux/ducks/app';
import { instructorActions } from 'redux/ducks/instructor';
import { Box, StudentList } from 'components/common';
import DisplayName from 'components/common/assignment-setting/DisplayName';
import SchedulingWrapper from 'components/common/assignment-setting/SchedulingWrapper';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import Scheduling from 'components/common/scheduling/Scheduling';
import { setDateFormatConfig } from 'config/app.config';
import Grading from './Grading';
import './configure.assignment.scss';

interface ConfigureAssignmentProps extends AssignmentWorkflowComponentProps {
  courseId: string;
  locale: Locales;
  setSkipLinkSections: Function;
  setPrevCreateAssignmentStep: Function;
}
interface ConfigureAssignmentState {
  showStudentList: boolean;
  courseStartDate?: string;
}

class ConfigureAssignment extends Component<ConfigureAssignmentProps, ConfigureAssignmentState> {
  static displayName = 'ConfigureAssignment';

  constructor(props) {
    super(props);
    this.initialize();
    this.state = {
      courseStartDate: null,
      showStudentList: false
    };
  }

  componentDidMount() {
    const { newAssignment, setPrevCreateAssignmentStep, setDueDateError, setSkipLinkSections } = this.props;
    setSkipLinkSections();
    setPrevCreateAssignmentStep(RoutePath.instructor.configureAssignment);
    return appHelper.useLoader(
      Promise.all([courseService.getCourseSectionById(this.props.courseId).then((res) => res.data), featureService.isShowAssignmentStudents()]).then(
        ([course, showStudentList]) => {
          this.props.setNewAssignment(
            produce(newAssignment, (draft) => {
              const { availableDate, dueDate } = newAssignment;
              if (!availableDate) {
                const availDate = course.startDate ? max(moment(course.startDate), moment().seconds(0)) : moment().seconds(0);
                draft.availableDate = availDate.toISOString();
              }
              if (!dueDate) {
                setDueDateError('');
                draft.dueDate = moment(draft.availableDate).add(1, 'd').toISOString();
              }
            })
          );

          this.setState({ courseStartDate: course.startDate ? moment(course.startDate).toISOString() : null, showStudentList });
        }
      )
    );
  }

  initialize = () => {
    setDateFormatConfig({ primary: getDateFormatByLocale(this.props.locale) });
  };

  handleInputChange = ({ target }) => {
    const { newAssignment } = this.props;

    this.props.setNewAssignment({
      [target.name]: target.type === 'checkbox' ? !newAssignment[target.name] : target.value
    });
  };

  handleDateTimeChange = (field: string, event: Event, value: string) => {
    const { newAssignment, setDueDateError } = this.props;
    const { courseStartDate } = this.state;
    const newState = produce(newAssignment, (draft) => {
      draft[field] = value;

      if (field === 'availableDate' && moment(value).isValid() && moment(value).isSameOrAfter(newAssignment.dueDate, 'minute')) {
        draft.dueDate = moment(value).add(1, 'd').toISOString();
      }
    });

    this.props.setNewAssignment(newState);

    if (moment(newState.dueDate).isSameOrBefore(newState.availableDate, 'minute')) {
      setDueDateError('Assignment due date and time must be after available date');
    } else if (moment(newState.dueDate).isSameOrBefore(moment(), 'minute')) {
      setDueDateError('Assignment due date and time must be after current time');
    } else if (courseStartDate && moment(newState.availableDate).isBefore(moment(courseStartDate), 'minute')) {
      setDueDateError('Assignment start date cannot be earlier than the Sherpath course start date');
    } else {
      setDueDateError('');
    }
  };

  getGradingOptions = (): GradingRadioButton[] => {
    const gradingOptions: GradingRadioButton[] = [
      {
        id: 'grading-not-graded',
        value: GradingOption.NOT_GRADED,
        primaryText: 'Not Graded',
        helperText: 'Students will not receive a score for the assignment.'
      },
      {
        id: 'grading-pass-fail',
        value: GradingOption.PASS_FAIL,
        primaryText: 'Pass / Fail',
        helperText: 'Students will receive a Pass if they complete the assignment by the due date and a Fail if they do not.'
      },
      {
        id: 'grading-scored',
        value: GradingOption.SCORED,
        primaryText: 'Scored',
        helperText: 'Students will be graded based on quiz questions.'
      }
    ];

    if (this.props.newAssignment.assignmentType === AssignmentType.EMPTY_EHR) {
      return gradingOptions.filter((option) => option.id !== 'grading-scored');
    }

    return gradingOptions;
  };

  render() {
    const { newAssignment, dueDateError } = this.props;
    const { courseStartDate, showStudentList } = this.state;

    return (
      <ELSFlex className="configure-assignment" center column>
        <ELSFlexItem className="els-grading-content u-els-width-3o4">
          <DisplayName title={newAssignment.title} onChange={this.handleInputChange} />
          <Box my2 className="c-els-divider" />
          <SchedulingWrapper title="Student Activity">
            <>
              <Box mb2>
                <h4 className="u-els-bold">Scheduling</h4>
                <p>The following settings are applied only when this assignment is posted to students</p>
              </Box>
              <Scheduling
                availableDate={newAssignment.availableDate}
                courseStartDate={courseStartDate}
                dueDate={newAssignment.dueDate}
                dueDateError={dueDateError}
                onDateTimeChange={this.handleDateTimeChange}
              />
            </>
          </SchedulingWrapper>
          {showStudentList && (
            <Box pb2>
              <StudentList />
            </Box>
          )}
        </ELSFlexItem>
        <ELSFlexItem>
          <Grading assignmentGradeType={newAssignment.assignmentGradeType} onChange={this.handleInputChange} gradingOptions={this.getGradingOptions()} />
        </ELSFlexItem>
      </ELSFlex>
    );
  }
}

const mapStateToProps = (state) => ({
  courseId: appSelectors.getCourseId(state),
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  setSkipLinkSections: (skipLinkSections: SkipLinkSection[]) => dispatch(appActions.setSkipLinkSections(skipLinkSections)),
  setPrevCreateAssignmentStep: (payload) => dispatch(instructorActions.setPrevCreateAssignmentStep(payload))
});

export { ConfigureAssignment as BaseConfigureAssignment };
export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConfigureAssignment);

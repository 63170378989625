import produce from 'immer';
import { merge } from 'lodash';
import { Component } from 'react';
import { ChartFragment, VitalSignsRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper, chartHelper, unitConverter } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { EMERGENCY_CALL_SCORE, FormField, vitalSignsFormFieldBuilderItems } from './constants';
import VitalSignsView, { VitalSignsViewProps } from './VitalSignsView';
import AtoEAssessmentView, { AtoEAssessmentViewProps } from './au/AtoEAssessmentView';
import { buildVitalSignsFragmentRecords, getADDSScores } from './helpers';
import { ADDSPointMap } from './types';

interface VitalSignsState {
  fragments: ChartFragment[];
  chartHistory: VitalSignsRecord[];
  totalScore: number; // This totalScore to be used for field validation, not for display or save to database.
}

class VitalSigns extends Component<ChartComponentProps, VitalSignsState> {
  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: [],
      totalScore: 0
    };
  }

  async componentDidMount(): Promise<void> {
    await this.loadVitalSignsData();
  }

  componentDidUpdate(prevProps: Readonly<ChartComponentProps>, prevState: Readonly<VitalSignsState>) {
    const { totalScore: prevTotalScore } = prevState;
    const { formFieldMap, updateFieldValue, locale } = this.props;

    if (locale === Locales.EN_AU) {
      const totalScore = getADDSScores(formFieldMap)?.totalScore;
      const transformedTotalScore = totalScore === ADDSPointMap.EMERGENCY_CALL ? EMERGENCY_CALL_SCORE : Number(totalScore);

      if (prevTotalScore !== transformedTotalScore) {
        updateFieldValue(formFieldMap.get(FormField.ADDS_TOTAL_SCORE), transformedTotalScore);
        this.setState({
          totalScore: transformedTotalScore
        });
      }
    }
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { locale, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    vitalSignsFormFieldBuilderItems(locale, this.temperatureGroupChange).forEach(({ name, type, label, disabled, onGroupChange, isHidden }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, type, label: label && intl.formatMessage({ id: label }), disabled, onGroupChange }));
      }
    });

    return dataMap;
  };

  temperatureGroupChange = (chartField: ChartMetaFormField): ChartMetaFormField[] => {
    if (chartField.value === '') {
      return [];
    }
    const { formFieldMap } = this.props;
    const result = [
      chartField.name === FormField.TEMPERATURE_FAHRENHEIT ? chartField : formFieldMap.get(FormField.TEMPERATURE_FAHRENHEIT),
      chartField.name === FormField.TEMPERATURE_CELSIUS ? chartField : formFieldMap.get(FormField.TEMPERATURE_CELSIUS)
    ];

    return produce(result, (draft) => {
      const [temperatureFahrenheitField, temperatureCelsiusField] = draft;
      const num = parseFloat(chartField.value);
      if (!Number.isNaN(Number(num))) {
        switch (chartField.name) {
          case FormField.TEMPERATURE_FAHRENHEIT: {
            temperatureCelsiusField.value = unitConverter.convertFahrenheitToCelsius(chartField.value);
            break;
          }
          case FormField.TEMPERATURE_CELSIUS: {
            temperatureFahrenheitField.value = unitConverter.convertCelsiusToFahrenheit(chartField.value);
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  };

  valueChoiceMaker = (value: string, choice: string): { value: string; choice: string } => {
    const tempData = { value: null, choice: null };
    if (value !== '') {
      tempData.value = value;
      tempData.choice = choice;
    }
    return tempData;
  };

  loadVitalSignsData = async () => {
    const { initState, loadChartData } = this.props;

    initState(this.buildDefaultFormFields());
    await appHelper.useLoader(
      loadChartData().then(({ data }) => {
        const records = data.map(({ fragmentId: id, active, chartingAt, createdAt, creator, modifier, ...rest }) => {
          return { id, active, chartingAt, createdAt, creator, modifier, ...rest.chartData };
        });
        this.setState({ fragments: data, chartHistory: records as VitalSignsRecord[] });
      })
    );
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(this.loadVitalSignsData)
        .then(this.props.showDeleteSuccess)
    );
  };

  buildFragment = () => {
    const { formFieldMap, chartingTime, locale, intl } = this.props;
    const { buildPatientRecords } = chartService;
    const basicInfo = chartService.createBaseFragment({ chartingTime });

    let delivery = { value: null, unit: null, choice: null, method: { choice: null } };
    if (formFieldMap.get(FormField.OXYGEN_AMOUNT_LITER).value !== '') {
      delivery = {
        ...delivery,
        value: formFieldMap.get(FormField.OXYGEN_AMOUNT_LITER).value,
        method: {
          choice:
            formFieldMap.get(FormField.OXYGEN_DELIVERY_METHOD_LITER_OTHER).value === ''
              ? formFieldMap.get(FormField.OXYGEN_DELIVERY_METHOD_LITER).value
              : formFieldMap.get(FormField.OXYGEN_DELIVERY_METHOD_LITER_OTHER).value
        }
      };
    } else if (formFieldMap.get(FormField.OXYGEN_AMOUNT_PERCENT).value !== '') {
      delivery = {
        ...delivery,
        value: formFieldMap.get(FormField.OXYGEN_AMOUNT_PERCENT).value,
        method: {
          choice:
            formFieldMap.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT_OTHER).value === ''
              ? formFieldMap.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT).value
              : formFieldMap.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT_OTHER).value
        }
      };
    }
    delivery = { ...delivery, choice: formFieldMap.get(FormField.OXYGEN_DELIVERY).value };
    delivery = { ...delivery, unit: formFieldMap.get(FormField.OXYGEN_DELIVERY).value };

    const chartData = {
      temperature: this.valueChoiceMaker(formFieldMap.get(FormField.TEMPERATURE_FAHRENHEIT).value, formFieldMap.get(FormField.TEMPERATURE_SITE).value),
      temperatureSite: this.valueChoiceMaker(formFieldMap.get(FormField.TEMPERATURE_SITE).name, formFieldMap.get(FormField.TEMPERATURE_SITE).value),
      pulse: this.valueChoiceMaker(formFieldMap.get(FormField.PULSE).value, formFieldMap.get(FormField.PULSE_SITE).value),
      respiration: this.valueChoiceMaker(formFieldMap.get(FormField.RESPIRATION).value, formFieldMap.get(FormField.TEMPERATURE_SITE).value),

      bloodPressure: {
        systolic: this.valueChoiceMaker(formFieldMap.get(FormField.BLOOD_PRESSURE_SYSTOLIC).value, formFieldMap.get(FormField.BLOOD_PRESSURE_SITE).value),
        diastolic: this.valueChoiceMaker(formFieldMap.get(FormField.BLOOD_PRESSURE_DIASTOLIC).value, formFieldMap.get(FormField.BLOOD_PRESSURE_POSITION).value)
      },
      oxygenation: {
        saturation: this.valueChoiceMaker(
          formFieldMap.get(FormField.OXYGEN_SATURATION).value,
          formFieldMap.get(FormField.OXYGEN_SITE_OTHER).value === '' ? formFieldMap.get(FormField.OXYGEN_SITE).value : formFieldMap.get(FormField.OXYGEN_SITE_OTHER).value
        ),
        delivery
      },
      notes: formFieldMap.get(FormField.NOTE).value
    };

    let chartDataAU = {};

    if (locale === Locales.EN_AU) {
      chartDataAU = {
        pulseStrength: this.valueChoiceMaker(formFieldMap.get(FormField.PULSE_STRENGTH).value, formFieldMap.get(FormField.PULSE_STRENGTH).value),
        pulseRhythm: this.valueChoiceMaker(formFieldMap.get(FormField.PULSE_RHYTHM).value, formFieldMap.get(FormField.PULSE_RHYTHM).value),
        pulseEquality: this.valueChoiceMaker(formFieldMap.get(FormField.PULSE_EQUALITY).value, formFieldMap.get(FormField.PULSE_EQUALITY).value),
        respirationPattern: this.valueChoiceMaker(formFieldMap.get(FormField.RESPIRATION_PATTERN).value, formFieldMap.get(FormField.RESPIRATION_PATTERN).value),
        respirationDepth: this.valueChoiceMaker(formFieldMap.get(FormField.RESPIRATION_DEPTH).value, formFieldMap.get(FormField.RESPIRATION_DEPTH).value),
        bloodPressure: {
          mode: this.valueChoiceMaker(formFieldMap.get(FormField.BLOOD_PRESSURE_MODE).value, formFieldMap.get(FormField.BLOOD_PRESSURE_SITE).value)
        },
        airway: {
          airwayPatentObstructed: [
            this.valueChoiceMaker(formFieldMap.get(FormField.AIRWAY_PATENT_OBSTRUCTED).value, formFieldMap.get(FormField.AIRWAY_PATENT_OBSTRUCTED).value),
            this.valueChoiceMaker(formFieldMap.get(FormField.AIRWAY_OBSTRUCTED_CHILD_TICKBOX).value, formFieldMap.get(FormField.AIRWAY_OBSTRUCTED_CHILD_TICKBOX).value)
          ],
          airwayPartialObstructionCompleteObstruction: [
            this.valueChoiceMaker(
              formFieldMap.get(FormField.AIRWAY_PARTIAL_OBSTRUCTION_COMPLETE_OBSTRUCTION).value,
              formFieldMap.get(FormField.AIRWAY_PARTIAL_OBSTRUCTION_COMPLETE_OBSTRUCTION).value
            ),
            this.valueChoiceMaker(
              formFieldMap.get(FormField.AIRWAY_PARTIAL_OBSTRUCTION_CHILD_TICKBOX).value,
              formFieldMap.get(FormField.AIRWAY_PARTIAL_OBSTRUCTION_CHILD_TICKBOX).value
            )
          ]
        },
        auscultation: {
          leftLung: [
            this.valueChoiceMaker(formFieldMap.get(FormField.AUSCULTATION_LEFT_LUNG).value, formFieldMap.get(FormField.AUSCULTATION_LEFT_LUNG).value),
            this.valueChoiceMaker(
              formFieldMap.get(FormField.AUSCULTATION_LEFT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES).value,
              formFieldMap.get(FormField.AUSCULTATION_LEFT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES).value
            )
          ],
          rightLung: [
            this.valueChoiceMaker(formFieldMap.get(FormField.AUSCULTATION_RIGHT_LUNG).value, formFieldMap.get(FormField.AUSCULTATION_RIGHT_LUNG).value),
            this.valueChoiceMaker(
              formFieldMap.get(FormField.AUSCULTATION_RIGHT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES).value,
              formFieldMap.get(FormField.AUSCULTATION_RIGHT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES).value
            )
          ]
        },
        disability: {
          painScore: {
            rest: this.valueChoiceMaker(formFieldMap.get(FormField.DISABILITY_PAIN_SCORE_REST).value, formFieldMap.get(FormField.DISABILITY_PAIN_SCORE_REST).value),
            onMovement: this.valueChoiceMaker(
              formFieldMap.get(FormField.DISABILITY_PAIN_SCORE_ON_MOVEMENT).value,
              formFieldMap.get(FormField.DISABILITY_PAIN_SCORE_ON_MOVEMENT).value
            )
          },
          levelOnResponse: {
            avpuAssessment: this.valueChoiceMaker(
              formFieldMap.get(FormField.DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT).value,
              formFieldMap.get(FormField.DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT).value
            )
          }
        },
        exposureComments: formFieldMap.get(FormField.EXPOSURE_COMMENTS).value,
        nursingActions: [...buildPatientRecords(formFieldMap, FormField.NURSING_ACTIONS)]
      };
    }

    const vitalSignsRecords = buildVitalSignsFragmentRecords({ chartingTime, formFieldMap, intl });
    const chartDataMerged = merge(chartData, chartDataAU, vitalSignsRecords.chartData);
    return { ...basicInfo, chartData: chartDataMerged };
  };

  handleSaveClick = async () => {
    await this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.loadVitalSignsData });
  };

  render() {
    const { displayAuthoringData, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, hasUnsavedChanges, handleDiscardClick, intl, locale, selectedNavId } = this.props;
    const { chartHistory, fragments } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    if (locale === Locales.EN_AU) {
      const atoEAssessmentViewProps: AtoEAssessmentViewProps = {
        chartHistoryFragments: fragments,
        chartMetaFormFields: formFieldMap,
        chartHistory,
        deleteHistory: this.deleteHistory,
        formSubmittedCount,
        chartActionsProps,
        intl,
        locale,
        selectedNavId,
        loadData: this.loadVitalSignsData,
        scores: getADDSScores(formFieldMap)
      };
      return <AtoEAssessmentView {...atoEAssessmentViewProps} />;
    }

    const vitalSignsViewProps: VitalSignsViewProps = {
      chartMetaFormFields: formFieldMap,
      chartHistory,
      deleteHistory: this.deleteHistory,
      formSubmittedCount,
      chartActionsProps,
      intl
    };

    return <VitalSignsView {...vitalSignsViewProps} />;
  }
}

export { VitalSigns as BaseVitalSigns };
export default withChartLogic(VitalSigns);

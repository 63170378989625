import cx from 'classnames';
import { Record } from 'models/api-response';
import { formatName } from 'services/chart.service';

interface EntryByProps {
  record: Record;
}

const EntryBy = (props: EntryByProps) => {
  const firstName = props.record?.creator?.firstName || '';
  const lastName = props.record?.creator?.lastName || '';
  return <div className={cx({ 'row--deleted': !props.record.active })}>{formatName({ firstName, lastName })}</div>;
};
EntryBy.displayName = 'EntryBy';
export default EntryBy;

/* eslint-disable no-param-reassign */
import produce from 'immer';
import { isEmpty, isNil } from 'lodash';
import { FormFieldDataType, FormFieldInputType } from 'models/enum';
import { ChartFieldContent, ChartMetaFormField, ContentItem } from 'models/ui';
import { chartHelper } from 'helpers';

const getScaleScore = (field: ChartMetaFormField, fieldContent: ChartFieldContent): { label: string; value: string } => {
  let savedContent;
  let score;
  if (field.value?.length && isEmpty(field.scaleContent)) {
    savedContent = field.value?.find((item) => item.formField === fieldContent.formFieldId);
    score = {
      label: savedContent?.title,
      value: savedContent?.content
    };
  } else {
    savedContent = field.scaleContent?.find((item) => item.formFieldId === fieldContent.formFieldId);
    score = {
      label: savedContent?.score?.label,
      value: savedContent?.score?.value
    };
  }
  return score;
};

export const getCurrentQuestionFieldForUpdateScale = (
  question: ChartMetaFormField,
  option: ContentItem,
  additionalScore: string | number,
  isNotSaveChildValueInScore: boolean | undefined
): ChartMetaFormField =>
  produce(question, (draft) => {
    const scoreLabel = option.label + (additionalScore || '');
    const scoreValue = option.value + (additionalScore || '');
    draft.extraData.score = isNotSaveChildValueInScore ? { label: option.label, value: option.value } : { label: scoreLabel, value: scoreValue };
    draft.value = '';
    draft.chartContent.forEach((item: ContentItem) => {
      const isChoiceField = option.dataType === FormFieldDataType.CHOICE || item.dataType === FormFieldDataType.NESTED_CHOICE;
      if (isChoiceField && option.id === item.id) {
        item.selected = true;
        draft.value = item.value;
      } else {
        item.selected = false;
      }
    });
  });

export const clearQuestionDataIfScaleDataIsBlank = (allFieldsMap: Map<string, ChartMetaFormField>, updatedItem: ChartMetaFormField): void => {
  if (updatedItem.inputType === FormFieldInputType.SCALE && updatedItem.value === '') {
    updatedItem.chartContent.forEach((item) => {
      if (item.dataType === FormFieldDataType.SCALE_QUESTION) {
        const questionField = produce(allFieldsMap.get(item.formFieldId), (draft) => {
          draft.value = '';
          draft.chartContent = draft.chartContent.map((content) => ({ ...content, selected: false }));
          draft.extraData.score = null;
        });
        allFieldsMap.set(questionField.name, questionField);
      }
    });
  }
};

export const handleForCheckboxDropdownMultiselectDropDownRadioChoiceMultiselectRadio = (
  chartContent: ContentItem[],
  field: ChartMetaFormField,
  chartFieldContentSet: ChartFieldContent[],
  handleEnhanceCheckBox: Function
): void => {
  const getIsHidden = (fieldContent) => (fieldContent.isShown === null ? false : !fieldContent.isShown);

  chartFieldContentSet.forEach((fieldContent) => {
    let selected = false;
    if ([FormFieldInputType.CHECK_BOX, FormFieldInputType.MULTISELECT_DROPDOWN].includes(field.inputType)) {
      // contentIds is the ids of the selected options of saved user input.
      // For multiselect, user can select multiple options
      // so we check if the saved user input includes this fieldContent
      if (field.contentIds?.some((id) => id === fieldContent.id)) {
        selected = true;
        field.value = field.value?.concat(`|${fieldContent.value}`) || fieldContent.value;
        field.defaultValue = field.defaultValue || field.value;
      }
      // if select field is single select
      // then we take first contentId which is the selected value
      // and compares it with fieldContent id
    } else if (field.contentIds[0] === fieldContent.id) {
      selected = true;
      field.value = fieldContent.value;
      field.defaultValue = field.defaultValue || field.value;
    }

    if (
      fieldContent.formFieldId === field.name &&
      fieldContent.active &&
      (fieldContent.dataType === FormFieldDataType.CHOICE || fieldContent.dataType === FormFieldDataType.NESTED_CHOICE)
    ) {
      let nestedFieldId: string;
      if (field.inputType === FormFieldInputType.CHECK_BOX && fieldContent.label.includes(':_')) {
        nestedFieldId = chartHelper.getNestedFieldId(fieldContent.value);
      } else {
        nestedFieldId = fieldContent.nestedFieldId;
      }
      chartContent.push({
        id: fieldContent.id,
        name: fieldContent.label,
        dataType: fieldContent.dataType,
        label: fieldContent.label,
        value: fieldContent.value,
        displayOrder: fieldContent.order,
        validationSetIds: fieldContent.validationSetIds,
        resourceUri: fieldContent.resourceUri,
        isActive: fieldContent.active,
        selected,
        referenceFields: fieldContent.externalReferences,
        onChange: handleEnhanceCheckBox,
        nestedFieldId,
        // The old approach is show all items,
        // and the isShown will be null if we not defined in the metadata.
        // So we need to show item if it null.
        isHidden: getIsHidden(fieldContent)
      });
    }
  });
};

export const handleForTextBoxAndTextArea = (chartContent: ContentItem[], field: ChartMetaFormField, chartFieldContentSet: ChartFieldContent[]): void => {
  chartFieldContentSet.forEach((fieldContent) => {
    if (fieldContent.formFieldId === field.name && fieldContent.dataType === FormFieldDataType.DECORATOR) {
      chartContent.push({
        id: fieldContent.id,
        name: fieldContent.label,
        label: fieldContent.label || 'Notes',
        value: fieldContent.value,
        selected: false,
        onChange: null,
        displayOrder: fieldContent.order,
        expandedLabel: fieldContent.expandedLabel,
        collapsedLabel: fieldContent.collapsedLabel,
        validationSetIds: fieldContent.validationSetIds,
        isActive: fieldContent.active
      });
    }
  });
};

export const handleForTextBlock = (chartContent: ContentItem[], field: ChartMetaFormField, chartFieldContentSet: ChartFieldContent[]): void => {
  chartFieldContentSet.forEach((fieldContent) => {
    if (fieldContent.formFieldId === field.name && fieldContent.dataType === FormFieldDataType.CHOICE) {
      chartContent.push({
        id: fieldContent.id,
        name: fieldContent.label,
        label: fieldContent.label,
        value: fieldContent.value,
        selected: false,
        onChange: null,
        displayOrder: fieldContent.order,
        validationSetIds: fieldContent.validationSetIds,
        isActive: fieldContent.active
      });
    }
  });
};

export const handleForScale = ({
  field,
  chartFieldContentSet,
  checkQuestionInScale,
  formFieldMap,
  getScaleOption,
  getQuestionContexts,
  newFieldMap
}: {
  field: ChartMetaFormField;
  chartFieldContentSet: ChartFieldContent[];
  checkQuestionInScale: Function;
  formFieldMap: Map<string, ChartMetaFormField>;
  getScaleOption: Function;
  getQuestionContexts: Function;
  newFieldMap: Map<string, ChartMetaFormField>;
}): ContentItem[] => {
  const scores = [];

  const scale = chartFieldContentSet.find((fieldContent) => fieldContent.dataType === FormFieldDataType.SCALE && field.name === fieldContent.formFieldId && fieldContent.active);
  const scaleQuestions = chartFieldContentSet
    .filter((fieldContent) => fieldContent.dataType === FormFieldDataType.SCALE_QUESTION && checkQuestionInScale(fieldContent.formFieldId, scale.questionFormFieldIds))
    .sort((firstItem, secondItem) => firstItem.order - secondItem.order)
    .map((fieldContent) => {
      const score = getScaleScore(field, fieldContent);
      const options = getScaleOption(fieldContent.formFieldId, chartFieldContentSet, score);
      const questionContexts = getQuestionContexts(fieldContent.formFieldId, chartFieldContentSet);
      scores.push(score);

      // Put computed data into question fields
      const questionField =
        formFieldMap.get(fieldContent.formFieldId) || chartHelper.createFormField({ name: fieldContent.formFieldId, type: FormFieldInputType.RADIO_CHOICE, label: '' });
      const newQuestionField = produce(questionField, (item) => {
        item.chartContent = options;
        item.label = fieldContent.label;
        item.extraData = { questionTitle: fieldContent.fieldLabel, questionId: fieldContent.id, score, questionContexts };
      });
      newFieldMap.set(newQuestionField.name, newQuestionField);

      return fieldContent;
    });
  const scaleRollUpWrapperFormFieldId = chartHelper.getScaleRollUpWrapperId(field.name);

  // workaround to not highlight scale rollUp with score 0 when user has not answered any question and there is no preloaded user input
  // if totalScore is '0' then any rollUp with score of 0 will be highlighted
  // so the value is changed to empty
  const totalScore =
    field.value?.length || field.scaleContent?.length ? scores.reduce((sum, curScore) => (!isNil(curScore?.value) ? sum + Number(curScore.value) : sum), 0).toString() : '';
  const scaleRollUp = chartFieldContentSet
    .filter(
      (fieldContent) =>
        (fieldContent.dataType === FormFieldDataType.SCALE_ROLL_UP || fieldContent.dataType === FormFieldDataType.SCALE_ROLL_UP_HIGHLIGHT) &&
        fieldContent.formFieldId === scale.formFieldId
    )
    .sort((firstItem, secondItem) => firstItem.order - secondItem.order);
  const chartContent = [
    ...scaleQuestions,
    ...scaleRollUp,
    {
      formFieldId: scaleRollUpWrapperFormFieldId,
      label: scale?.rollUpTitle,
      score: {
        label: totalScore,
        value: totalScore
      }
    }
  ] as ContentItem[];
  field.value = totalScore || '-1';
  field.defaultValue = totalScore || '-1';

  return chartContent;
};

export const handleForImage = (chartContent: ContentItem[], field: ChartMetaFormField, chartFieldContentSet: ChartFieldContent[]): void => {
  chartFieldContentSet.forEach((fieldContent) => {
    if (fieldContent.formFieldId === field.name && fieldContent.dataType === FormFieldDataType.CHOICE) {
      chartContent.push({
        id: fieldContent.id,
        name: fieldContent.formFieldId,
        onChange: null,
        label: fieldContent.label,
        value: fieldContent.value,
        displayOrder: fieldContent.order,
        resourceUri: fieldContent.resourceUri,
        isActive: fieldContent.active,
        selected: false,
        isHidden: true,
        validationSetIds: fieldContent.validationSetIds
      });
    }
  });
};

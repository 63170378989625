import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ErrorCard, ErrorFormField, RelatedCharts } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { FormField } from './FLACCScale';
import './flacc.scale.scss';

export interface FLACCScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const FLACCScaleView = (props: FLACCScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="flacc-scale">
      <h1>FLACC Behavioral Pain Assessment Scale</h1>
      <Box p mb2 mt className="flacc-scale__notes">
        <ELSFlex className="u-els-margin-bottom-1o2">
          <ELSFlexItem className="u-els-margin-right">
            <ELSIcon prefix="gizmo" name="information" className="o-els-icon-svg--1x1o2" />
          </ELSFlexItem>
          <ELSFlexItem grow>
            <p>
              <strong>NOTE: </strong>
              Each of the 5 categories - (F) Face, (L) Leg, (A) Activity, (C) Cry, and (C) Consolability - is scored from 0 to 2, resulting in a total score between 0 and 10.
            </p>
          </ELSFlexItem>
        </ELSFlex>
        <p className="u-els-margin-bottom">
          <strong>NOTE: </strong>
          The FLACC scale should not be used with patients who are paralyzed.
        </p>
        <p className="u-els-margin-bottom">
          <strong>Patients Who Are Awake: </strong>
          Observe patient for at least 2 to 5 minutes. Observe legs and body uncovered. Reposition patient or observe activity and assess body for tenseness and tone. Initiate
          consoling interventions if needed.
        </p>
        <p>
          <strong>Patients Who Are Asleep: </strong>
          Observe patients for at least 5 minutes. Observe legs and body uncovered. If possible, reposition the patient. Touch the body and assess for tenseness and tone.
        </p>
      </Box>
      <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.FLACC_PAIN_ASSESSMENT_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: true }}
      />
      <ChartActions {...chartActionsProps} />
      <RelatedCharts
        chartIds={[NAV_ID.PAIN_INTERVENTIONS]}
        references="From Merkel, S et al: The FLACC: A behavioral scale for scoring postoperative pain in young children. Pediatric Nursing, 23(3):293–297, 1997. Modified with permission of Jannetti Publications, Inc., and the University of Michigan Health System."
      />
    </div>
  );
};

export default FLACCScaleView;

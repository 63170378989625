import { matchPath } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ELSIcon } from '@els/els-component-form-field-react';
import { NavigationItem } from 'models/ui';
import './breadcrumb.scss';

export interface BreadcrumbViewProps {
  items: NavigationItem[];
  currentPath: string;
}

const BreadcrumbView = ({ items = [], currentPath = '' }: BreadcrumbViewProps) => (
  <nav aria-label="Breadcrumb" className="u-els-margin-left breadcrumbs">
    <ol>
      {items.map((item, index) => (
        <li key={`${item.path}${item.text}`}>
          {index > 0 && <ELSIcon name="navigate-right" size="1x" prefix="gizmo" color="n3" />}
          {item.landing && !matchPath(currentPath, { path: item.path, exact: true, strict: true }) ? (
            <NavLink className="breadcrumbs__item" activeClassName="breadcrumbs__item--active" to={item.path} exact>
              {item.text}
            </NavLink>
          ) : (
            <span className="breadcrumbs__item u-els-color-n7">{item.text}</span>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

BreadcrumbView.displayName = 'BreadcrumbView';
export default BreadcrumbView;

import { Component, ReactNode } from 'react';
import { Box, ButtonLinkIcon } from 'components/common';

export interface ToggleViewProps {
  showLabel: string;
  hideLabel: string;
  children: ReactNode;
  className?: string;
}

interface ToggleViewState {
  show: boolean;
}

class ToggleView extends Component<ToggleViewProps, ToggleViewState> {
  static displayName = 'ToggleView';
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  handleToggleClick = () => this.setState((prevState) => ({ show: !prevState.show }));

  render() {
    return (
      <div className={this.props.className}>
        <ButtonLinkIcon
          preventAutoPending
          iconPrefix="gizmo"
          iconName={this.state.show ? 'navigate-up' : 'navigate-down'}
          text={this.state.show ? this.props.hideLabel : this.props.showLabel}
          onClick={this.handleToggleClick}
        />
        {this.state.show && <Box mt>{this.props.children}</Box>}
      </div>
    );
  }
}

export default ToggleView;

import { ChartFragment, ImpressionFragment } from 'models/api-response';
import { appHelper } from 'helpers';
import { Box, Headline } from 'components/common';
import ImpressionsEditForm from './ImpressionsEditForm';
import ImpressionsReadOnly from './ImpressionsReadOnly';

export interface ImpressionsViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: ImpressionFragment;
  selectedNavId: string;
  enableDisplayRecordsButton: boolean;
  onSave: Function;
  onCancel: Function;
  onNavigate: Function;
  displayAuthoringData: Function;
}

const ImpressionsView = (props: ImpressionsViewProps) => (
  <>
    {!props.isLocked && (
      <>
        <h1>Impressions</h1>
        <Box mt2>
          <ImpressionsEditForm
            selectedRecord={props.selectedRecord}
            onNavigate={props.onNavigate}
            selectedNavId={props.selectedNavId}
            onSave={props.onSave}
            onCancel={props.onCancel}
            isLocked={props.isLocked}
            displayAuthoringData={props.displayAuthoringData}
            enableDisplayRecordsButton={props.enableDisplayRecordsButton}
            {...appHelper.getChartSharedProps(props)}
          />
        </Box>
      </>
    )}
    {props.isLocked && (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <ImpressionsReadOnly selectedRecord={props.selectedRecord} />
      </>
    )}
  </>
);

ImpressionsView.displayName = 'ImpressionsView';
export default ImpressionsView;

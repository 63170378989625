export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'noAssessmentRequired',
  STANDARD_PRECAUTIONS = 'standardPrecautions',
  CONTACT_PRECAUTIONS = 'contactPrecautions',
  RESPIRATORY_PRECAUTIONS = 'respiratoryPrecautions',
  DROPLET_PRECAUTIONS = 'dropletPrecautions',
  SPECIAL_PRECAUTIONS_NOTES = 'notes'
}

export enum SectionTitle {
  SPECIAL_PRECAUTIONS_ISOLATION_ASSESSMENT = 'Special Precautions - Isolation Assessment',
  STANDARD_PRECAUTIONS = 'Standard Precautions',
  CONTACT_PRECAUTIONS = 'Contact Precautions',
  RESPIRATORY_PRECAUTIONS = 'Respiratory Precautions',
  DROPLET_PRECAUTIONS = 'Droplet Precautions'
}

export enum Label {
  MEDICATIONS = 'Medications',
  ADD_MEDICATION = 'Add Medication',
  SAVED_MEDICATIONS = 'Saved Medications',
  NOTHING_TO_SEE = 'Nothing to see, yet! Get started adding medications.',
  CLINICAL_KEY_NAME = 'medications',
  CLASSIFICATION = 'Classification',
  ROUTE = 'Route',
  DOSE = 'Dose',
  FREQUENCY = 'Frequency',
  DATE_ORDERED = 'Date Ordered (mm-dd-yyyy)',
  COMMENTS_ADDITIONAL_MEDICATION_INFO = 'Comments and Additional Medication Info',
  THERAPEUTIC_EFFECT = 'Therapeutic Effect',
  ACTION = 'Action',
  CONTRAINDICATIONS = 'Contraindications',
  SIDE_EFFECTS_ADVERSE_REACTIONS = 'Side Effects or Adverse Reactions',
  LIFE_THREATENING_CONSIDERATIONS = 'Life Threatening Considerations',
  RECOMMENDED_DOSE_RANGES = 'Recommended Dose Ranges',
  NURSING_INTERVENTIONS = 'Nursing Interventions'
}

export enum FormField {
  MEDICATION = 'medication',
  MEDICATION_OTHER = 'medicationOther',
  CLASSIFICATION = 'classification',
  CLASSIFICATION_OTHER = 'classificationOther',
  ROUTE = 'route',
  ROUTE_OTHER = 'routeOther',
  DOSE = 'dose',
  FREQUENCY = 'frequency',
  DATE_ORDERED = 'dateOrdered',
  COMMENT = 'commentsAndMedication',
  ACTION = 'action',
  SIDE_EFFECT = 'effectsOrReactions',
  RECOMMENDED = 'recommendedDoseRanges',
  THERAPEUTIC_EFFECT = 'therapeuticEffect',
  CONTRAINDICATIONS = 'contraindications',
  LIFE_THREATENING = 'lifeThreateningConsiderations',
  NURSING_INTERVENTIONS = 'nursingInterventions'
}

import { groupBy, map, orderBy, sumBy } from 'lodash';
import { Component } from 'react';
import { ChartFragment, IntakeOutputRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, IntakeOutputGraphItem, IntakeOutputTotalPerDayItem } from 'models/ui';
import { ChartAmountUnit } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { formatDate, toDate, toMoment } from 'helpers/datetime.helper';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import IntakeOutputView, { IntakeOutputViewProps } from './IntakeOutputView';
import { FormField, getIntakeOutputFields } from './constant';
import { handleIntakeType, handleIntravenousFluidsType, handleMiscellaneousType, handleOutputMeasure } from './helper';

enum IntakeOutputType {
  INTAKE = 'intake',
  OUTPUT = 'output'
}

interface IntakeOutputState {
  chartHistory: IntakeOutputRecord[];
  graphData: IntakeOutputGraphItem[];
  fragments: ChartFragment[];
}

class IntakeOutput extends Component<ChartComponentProps, IntakeOutputState> {
  constructor(props) {
    super(props);
    this.state = {
      chartHistory: [],
      graphData: [],
      fragments: []
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.updateChartHistoryAndGraph));
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { locale, intl } = this.props;
    const dataMap = new Map();

    getIntakeOutputFields(locale).forEach(({ name, label, isHidden, ...item }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });

    return dataMap;
  };

  calculateTotalPerDayData = (chartHistory: IntakeOutputRecord[]): IntakeOutputTotalPerDayItem[] => {
    const groupByDate = groupBy(
      orderBy(chartHistory, ['chartingAt'], ['desc']).filter((item) => item.active && item.unit === ChartAmountUnit.UNIT_ML),
      (item: IntakeOutputRecord) => toDate(item.chartingAt)
    );

    return map(groupByDate, (itemsPerDate: IntakeOutputRecord[], date: string) => ({
      date,
      ...Object.assign(
        {},
        ...map(groupBy(itemsPerDate, 'type'), (itemsPerType: IntakeOutputRecord[], type: IntakeOutputType.INTAKE | IntakeOutputType.OUTPUT) => ({
          [type]: Math.round(sumBy(itemsPerType, ({ value }) => Number(value)))
        }))
      )
    }));
  };

  transformGraphData = (totalPerDayData: IntakeOutputTotalPerDayItem[]): IntakeOutputGraphItem[] =>
    totalPerDayData.map((item) => ({
      ...item,
      dateTime: formatDate({ date: toMoment(item.date).toDate(), locale: this.props.locale }), // Use in chart time of Chart History, tooltip of Chart Graph
      date: formatDate({ date: toMoment(item.date).toDate(), locale: this.props.locale }), // Use in x-axis label of Chart Graph
      intakeDisplay: `${item.intake} ${ChartAmountUnit.UNIT_ML}`,
      outputDisplay: `${item.output} ${ChartAmountUnit.UNIT_ML}`
    }));

  buildFragments = () => {
    const { formFieldMap } = this.props;
    const fragments = [];
    const note = formFieldMap.get(FormField.NOTE)?.value;

    // loop over all fields
    formFieldMap.forEach((field: ChartMetaFormField) => {
      if (field.value === '') {
        return;
      }

      const v: FormField = Object.values(FormField).find((formField) => formField === field.name);
      let amnt = '';
      let ioType = '';
      let unitType = '';
      let choice = '';
      let outputType = '';

      switch (v) {
        case FormField.INTAKE_TYPE: {
          const { newAmnt, newChoice, newIOType, newUnitType } = handleIntakeType(formFieldMap, field);
          amnt = newAmnt;
          choice = newChoice;
          ioType = newIOType;
          unitType = newUnitType;
          break;
        }
        case FormField.INTRAVENOUS_FLUIDS_TYPE: {
          const { newAmnt, newChoice, newIOType, newUnitType } = handleIntravenousFluidsType(formFieldMap, field);
          amnt = newAmnt;
          choice = newChoice;
          ioType = newIOType;
          unitType = newUnitType;
          break;
        }
        case FormField.MISCELLANEOUS_TYPE: {
          const { newAmnt, newChoice, newIOType, newUnitType } = handleMiscellaneousType(formFieldMap, field);
          amnt = newAmnt;
          choice = newChoice;
          ioType = newIOType;
          unitType = newUnitType;
          break;
        }
        case FormField.MEALS_TYPE: {
          amnt = formFieldMap.get(FormField.MEALS_CONSUMED).value;
          ioType = IntakeOutputType.INTAKE;
          unitType = ChartAmountUnit.UNIT_PERCENT;
          choice = field.value;
          break;
        }
        // user output measures and they can't be simultaneous
        case FormField.OUTPUT_MEASURE: {
          const { newAmnt, newChoice, newIOType, newUnitType, newOutputType } = handleOutputMeasure(formFieldMap, field);
          amnt = newAmnt;
          choice = newChoice;
          ioType = newIOType;
          unitType = newUnitType;
          outputType = newOutputType;
          break;
        }
        case FormField.INTAKE_FEED_PRESCRIBED_AMOUNT_ML: {
          ioType = IntakeOutputType.INTAKE;
          unitType = ChartAmountUnit.UNIT_ML;
          choice = formFieldMap.get(FormField.INTAKE_FEED_PRESCRIBED_TYPE).value;
          amnt = field.value;
          break;
        }
        case FormField.INTAKE_FEED_PRESCRIBED_AMOUNT_ML_PER_HOUR: {
          ioType = IntakeOutputType.INTAKE;
          unitType = ChartAmountUnit.UNIT_ML_PER_HOUR;
          choice = formFieldMap.get(FormField.INTAKE_FEED_PRESCRIBED_TYPE).value;
          amnt = field.value;
          break;
        }
        default:
          return;
      }
      fragments.push(this.createRecord(choice, ioType, unitType, amnt, note, outputType));
    });

    if (fragments.length === 0 && !!note) {
      fragments.push(this.createRecord('', '', '', '', note, ''));
    }

    return fragments;
  };

  updateChartHistoryAndGraph = (apiRes) => {
    const records = apiRes?.data.map((fragment) => {
      const { fragmentId: id, active, chartingAt, createdAt, creator, modifier } = fragment;
      return { id, active, chartingAt, createdAt, creator, modifier, ...fragment.chartData };
    });
    const totalPerDayData = this.calculateTotalPerDayData(records);
    const graphData = this.transformGraphData(totalPerDayData);

    this.setState({
      chartHistory: records,
      graphData,
      fragments: apiRes?.data
    });
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(() => this.props.loadChartData())
        .then(this.updateChartHistoryAndGraph)
        .then(this.props.showDeleteSuccess)
    );
  };

  createRecord = (choiceParam: string, typeParam: string, unitParam: string, valueParam: string, noteParam: string, outputTypeParam: string) => {
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });

    let numberVal = 0;

    if (valueParam !== '') {
      const parsedValue = Number(valueParam);

      if (!Number.isNaN(parsedValue)) {
        numberVal = parsedValue;
      }
    }

    const chartData = {
      choice: choiceParam,
      type: typeParam,
      unit: unitParam,
      value: numberVal,
      notes: noteParam,
      outputType: outputTypeParam
    };

    return { ...basicInfo, chartData };
  };

  handleSaveClick = () => {
    this.props.saveChart(this.buildFragments(), { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.afterSave });
  };

  afterSave = () =>
    Promise.resolve()
      .then(() => this.props.loadChartData())
      .then(this.updateChartHistoryAndGraph);

  render() {
    const { locale } = this.props;

    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: IntakeOutputViewProps = {
      chartMetaFormFields: this.props.formFieldMap,
      graphData: this.state.graphData,
      totalPerDayData: this.state.graphData,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory,
      chartMetaContentMap: this.props.getContentMap(),
      formSubmittedCount: this.props.formSubmittedCount,
      intl: this.props.intl,
      chartActionsProps,
      locale
    };

    return <IntakeOutputView {...viewProps} />;
  }
}

export { IntakeOutput as BaseIntakeOutput };
export default withChartLogic(IntakeOutput);

import { ChartFragment, PhysicalExaminationFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, Headline } from 'components/common';
import PhysicalExaminationEditView from './PhysicalExaminationEditView';
import PhysicalExaminationReadOnly from './PhysicalExaminationReadOnly';

export interface PhysicalExaminationViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: PhysicalExaminationFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const PhysicalExaminationView = (props: PhysicalExaminationViewProps) => (
  <>
    {!props.isLocked && (
      <>
        <h1>Physical Examination</h1>
        <Box mt2>
          <PhysicalExaminationEditView {...props} />
        </Box>
      </>
    )}
    {props.isLocked && (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <PhysicalExaminationReadOnly selectedRecord={props.selectedRecord} />
      </>
    )}
  </>
);

PhysicalExaminationView.displayName = 'PhysicalExaminationView';
export default PhysicalExaminationView;

import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { HeightWeightRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, HeightWeightGraphItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { cssHelper } from 'helpers';
import { Box, ChartActions, ErrorCard, ErrorFormField, GraphContainer, HistoryLineChart } from 'components/common';
import { ELSDropDown, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, GRAPH_TYPES } from './constants';
import HeightWeightHistory from './HeightWeightHistory';
import '../vital.signs.common.scss';

export interface HeightWeightViewProps {
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  graphData: HeightWeightGraphItem[];
  chartHistory: HeightWeightRecord[];
  deleteHistory: Function;
  chartMetaContentMap: Map<string, string>;
  chartActionsProps: ChartActionsComponentProps;
  graphChartType: string;
  yGraphLabel: string;
  onSelectGraph: Function;
  infantContainer: Function;
  intl: IntlShape;
  locale: Locales;
}

const HeightWeightView = ({
  chartMetaFormFields,
  formSubmittedCount,
  graphData,
  chartHistory,
  deleteHistory,
  chartMetaContentMap,
  chartActionsProps,
  graphChartType,
  yGraphLabel,
  onSelectGraph,
  infantContainer,
  intl,
  locale
}: HeightWeightViewProps) => (
  <>
    <div className="vital-signs-common chart-input">
      <h1 className="chart-input__header">{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.HEIGHT_WEIGHT })}</h1>
      <ELSFlex left wrap>
        <ELSFlexItem>
          <div className={cssHelper.mapElsCss({ els: 'padding-right-3x', md: 'padding-right-1x1o2', lg: 'padding-right-2x' })}>
            <h2 className="u-els-font-size-h2 u-els-margin-top">{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.CHART_INPUTS })}</h2>
            <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
            <div className="chart-input__section-title chart-input__section-title--first">
              <h4>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.HEIGHT })}</h4>
            </div>
            <ELSFlex left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.HEIGHT_FEET)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.HEIGHT_INCH)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <Box mt>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.OR })}</Box>
            <div className="u-els-margin-top-1o2">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HEIGHT_CM)} formSubmittedCount={formSubmittedCount} />
            </div>
            <div className="chart-input__section-title">
              <h4>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.WEIGHT })}</h4>
            </div>
            <div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.WEIGHT_ADMISSION)} formSubmittedCount={formSubmittedCount} />
            </div>
            <ELSFlex className="u-els-margin-top" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.WEIGHT_POUND)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.WEIGHT_OUNCE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="u-els-margin-top" left wrap>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.WEIGHT_KG)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.WEIGHT_GRAM)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.WEIGHT_METHOD)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_INFANT)} formSubmittedCount={formSubmittedCount}>
              <div className="chart-input__section-title chart-input__section-title--first">
                <h2>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.INFANT_MEASUREMENTS })}</h2>
              </div>
              <div className="chart-input__section-title">
                <h4>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.ABDOMINAL_GIRTH })}</h4>
              </div>
              <ELSFlex left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.ABDOMINAL_GIRTH_CM)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <Box mt>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.OR })}</Box>
              <div className="u-els-margin-top-1o2">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ABDOMINAL_GIRTH_INCH)} formSubmittedCount={formSubmittedCount} />
              </div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.ANTERIOR_FONTANEL)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.POSTERIOR_FONTANEL)} formSubmittedCount={formSubmittedCount} />
              <div className="chart-input__section-title">
                <h4>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.HEAD_CIRCUMFERENCE })}</h4>
              </div>
              <ELSFlex left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.HEAD_CIRCUMFERENCE_CM)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <Box mt>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.OR })}</Box>
              <div className="u-els-margin-top-1o2">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.HEAD_CIRCUMFERENCE_INCH)} formSubmittedCount={formSubmittedCount} />
              </div>
              <div className="chart-input__section-title">
                <h4>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.CHEST_CIRCUMFERENCE })}</h4>
              </div>
              <ELSFlex left wrap>
                <ELSFlexItem>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.CHEST_CIRCUMFERENCE_CM)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <Box mt>{intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.OR })}</Box>
              <div className="u-els-margin-top-1o2">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CHEST_CIRCUMFERENCE_INCH)} formSubmittedCount={formSubmittedCount} />
              </div>
            </ErrorFormField>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.NOTE)}
              formSubmittedCount={formSubmittedCount}
              options={{
                labelCss: 'chart-input__section-title'
              }}
            />
            <ChartActions {...chartActionsProps} />
          </div>
        </ELSFlexItem>
        <ELSFlexItem className="o-els-flex-layout__item--grow chart-input__scroll-table-container">
          <div className="chart-input__table-graph-container">
            <HeightWeightHistory
              infantContainer={infantContainer}
              chartHistory={chartHistory}
              deleteHistory={deleteHistory}
              chartMetaContentMap={chartMetaContentMap}
              intl={intl}
              locale={locale}
            />
            <Box mt2>
              <GraphContainer dataSize={graphData.length}>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_INFANT)} formSubmittedCount={formSubmittedCount}>
                  <Box mt className="chart-input__dropdown">
                    <ELSDropDown inline options={GRAPH_TYPES} value={graphChartType} changeHandler={onSelectGraph} />
                  </Box>
                </ErrorFormField>
                <HistoryLineChart width={600} yLabel={yGraphLabel} data={graphData} dataKeys={[graphChartType]} locale={locale} />
              </GraphContainer>
            </Box>
          </div>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  </>
);

HeightWeightView.displayName = 'HeightWeightView';
export default HeightWeightView;

import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TherapyRecord } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import SpeechSidebarView, { SpeechSidebarViewProps } from './SpeechSidebarView';
import { FormField, FormFieldLabel, OrderTypeValue } from '../shared/constants';
import { handleDisplayIntervention } from '../shared/helpers';

interface SpeechSidebarProps extends ChartComponentProps {
  selectedNavId: string;
  therapy: TherapyRecord;
  onCloseClick: Function;
  onEditTherapyHandler: Function;
}

interface SpeechSidebarState {
  isCompleted: boolean;
}

class SpeechSidebar extends Component<SpeechSidebarProps, SpeechSidebarState> {
  static displayName = 'SpeechSidebar';

  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false
    };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<SpeechSidebarProps>) {
    if (prevProps.therapy !== this.props.therapy) {
      this.props.initState(this.buildFormFields());
      this.setState({
        isCompleted: false
      });
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      { name: FormField.CONSULTATION_TYPE, label: FormFieldLabel.CONSULTATION_TYPE },
      { name: FormField.SPEECH_THERAPIST_NAME, label: FormFieldLabel.SPEECH_THERAPIST_NAME },
      { name: FormField.SPEECH_THERAPIST_SIGNATURE, label: FormFieldLabel.SPEECH_THERAPIST_SIGNATURE }
    ];

    const textAreas = [
      { name: FormField.REASON_CONSULTATION, label: FormFieldLabel.REASON_CONSULTATION },
      { name: FormField.REFERRAL_SOURCE, label: FormFieldLabel.REFERRAL_SOURCE },
      { name: FormField.HISTORY_PRESENT_ILLNESS, label: FormFieldLabel.HISTORY_PRESENT_ILLNESS },
      { name: FormField.FINDINGS, label: FormFieldLabel.FINDINGS },
      { name: FormField.IMPRESSIONS, label: FormFieldLabel.IMPRESSIONS },
      { name: FormField.PLAN, label: FormFieldLabel.PLAN }
    ];

    dataMap.set(FormField.INTERVENTION_DATE, createFormField({ name: FormField.INTERVENTION_DATE, type: FormFieldInputType.DATE, label: FormFieldLabel.DATE_LABEL }));
    dataMap.set(FormField.INTERVENTION_TIME, createFormField({ name: FormField.INTERVENTION_TIME, type: FormFieldInputType.TIME, label: FormFieldLabel.TIME }));

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    textAreas.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_AREA })));
    return dataMap;
  };

  buildUpdatedFragment = () => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord } = chartService;
    return {
      [FormField.CONSULTATION_TYPE]: buildPatientRecord(formFieldMap, FormField.CONSULTATION_TYPE),
      [FormField.REASON_CONSULTATION]: buildPatientRecord(formFieldMap, FormField.REASON_CONSULTATION),
      [FormField.REFERRAL_SOURCE]: buildPatientRecord(formFieldMap, FormField.REFERRAL_SOURCE),
      [FormField.HISTORY_PRESENT_ILLNESS]: buildPatientRecord(formFieldMap, FormField.HISTORY_PRESENT_ILLNESS),
      [FormField.FINDINGS]: buildPatientRecord(formFieldMap, FormField.FINDINGS),
      [FormField.IMPRESSIONS]: buildPatientRecord(formFieldMap, FormField.IMPRESSIONS),
      [FormField.PLAN]: buildPatientRecord(formFieldMap, FormField.PLAN),
      [FormField.SPEECH_THERAPIST_NAME]: buildPatientRecord(formFieldMap, FormField.SPEECH_THERAPIST_NAME),
      [FormField.SPEECH_THERAPIST_SIGNATURE]: buildPatientRecord(formFieldMap, FormField.SPEECH_THERAPIST_SIGNATURE),
      [FormField.INTERVENTION_DATE]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_DATE),
      [FormField.INTERVENTION_TIME]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_TIME)
    };
  };

  handleSaveClick = () => {
    const { isCompleted } = this.state;
    const updatedTherapy = produce(this.props.therapy, (draft) => {
      const { chartData } = draft;
      const oldInterventionList = chartData[FormField.INTERVENTION_LIST] || [];
      draft.chartData = { ...chartData, [FormField.INTERVENTION_LIST]: [...oldInterventionList, this.buildUpdatedFragment()], isCompleted };
      draft.updatedAt = moment().toISOString();
    });
    this.props.handleSaveClick([updatedTherapy], { afterSave: this.props.onEditTherapyHandler });
  };

  onCheckCompleted = (event) => {
    const isCompleted = event.target.checked;
    this.setState({
      isCompleted
    });
  };

  render() {
    const { therapy, onCloseClick, formFieldMap, formSubmittedCount, sidebarProps } = this.props;
    const { isCompleted } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => {
        this.setState({
          isCompleted: false
        });
        this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' });
      }
    };
    const { chartHistory: intervention } = handleDisplayIntervention(therapy);
    const orderTypeValue = therapy?.chartData[FormField.ORDER_TYPE]?.value;
    const isTherapyCompleted = therapy?.chartData[FormField.IS_COMPLETED];
    const viewProps: SpeechSidebarViewProps = {
      isOpen: !!therapy && orderTypeValue === OrderTypeValue.SPEECH,
      intervention,
      onCloseClick,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      isCompleted,
      isTherapyCompleted,
      onCheckCompleted: this.onCheckCompleted,
      sidebarProps
    };
    return <SpeechSidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state)
});

export { SpeechSidebar as BaseSpeechSidebar };
export default compose(connect(mapStateToProps), withChartLogic)(SpeechSidebar);

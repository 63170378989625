import { DiagnosisFragment, DiagnosticTestFragment, LaboratoryTestFragment, MedicationFragment } from 'models/api-response';
import PatientInfo from './PatientInfo';
import SectionDiagnoses from './SectionDiagnoses';
import SectionDiagnosticTests from './SectionDiagnosticTests';
import SectionLaboratoryTests from './SectionLaboratoryTests';
import SectionMedication from './SectionMedication';
import '../pre.clinical.manager.common.scss';

export interface SummaryReportProps {
  patientInfo: string;
  studentInfo: string;
  diagnosisRecords: DiagnosisFragment[];
  medicationRecords: MedicationFragment[];
  laboratoryTestRecords: LaboratoryTestFragment[];
  diagnosticTestsRecords: DiagnosticTestFragment[];
}

const SummaryReport = (props: SummaryReportProps) => (
  <>
    <PatientInfo patientInfo={props.patientInfo} studentInfo={props.studentInfo} />
    <SectionDiagnoses isFullReport={false} diagnosisRecords={props.diagnosisRecords} />
    <SectionMedication isFullReport={false} medicationRecords={props.medicationRecords} />
    <SectionLaboratoryTests laboratoryTestRecords={props.laboratoryTestRecords} />
    <SectionDiagnosticTests isFullReport={false} diagnosticTestsRecords={props.diagnosticTestsRecords} />
  </>
);

SummaryReport.displayName = 'SummaryReport';
export default SummaryReport;

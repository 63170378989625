import cx from 'classnames';
import { isEmpty } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { compose, withProps } from 'recompose';
import { SimChartAssignment } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { cssHelper } from 'helpers';
import { resetEmptyEhrDemoStorage } from 'helpers/assignment.helper';
import { assignmentService, caseStudyService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { instructorActions } from 'redux/ducks/instructor';
import { studentSelectors } from 'redux/ducks/student';
import { Box } from 'components/common';
import AssignmentInstruction from 'components/common/assignment-instruction/AssignmentInstruction';
import AssignmentObjective from 'components/common/assignment-objective/AssignmentObjective';
import { ELSButton, ELSPageLoader } from 'components/common/els';
import BlankChartingCompetency from '../blank-charting-competency/BlankChartingCompetency';

export interface BlankChartingAssignmentProps {
  params: { assignmentId: string };
  locale: Locales;
  fullAssignment: SimChartAssignment & {
    instructions: string;
  };
  setEmptyEhrDemo: Function;
  setEmptyEhrDemoInstructions: Function;
  setEmptyEhrDemoObjectives: Function;
}

interface BlankChartingAssignmentState {
  isLoading: boolean;
  objectives: string;
  instructions: string;
}

class BlankChartingAssignment extends Component<BlankChartingAssignmentProps, BlankChartingAssignmentState> {
  static displayName = 'BlankChartingAssignment';

  assignmentId: number;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      objectives: '',
      instructions: ''
    };

    const { assignmentId } = this.props.params;
    this.assignmentId = Number(assignmentId);
  }

  componentDidMount() {
    resetEmptyEhrDemoStorage();
    if (isEmpty(this.props.fullAssignment)) {
      assignmentService.getSimChartAssignment(this.assignmentId).then(({ data }) => this.updateDataEhrDemo(data));
    } else {
      this.updateDataEhrDemo(this.props.fullAssignment);
    }
  }

  updateDataEhrDemo = (fullAssignment) => {
    this.props.setEmptyEhrDemoInstructions(fullAssignment?.instructions);
    this.props.setEmptyEhrDemoObjectives(fullAssignment?.objectives);
    this.setState({ objectives: fullAssignment.objectives, instructions: fullAssignment.instructions, isLoading: false });
  };

  handleStartDemoClick = async () => {
    const assessment = await caseStudyService.prepareDemoCaseStudy({ caseStudyId: null }).then((res) => res.data);
    if (assessment) {
      this.props.setEmptyEhrDemo({ assessment, patientInfo: null });
      navigationService.navigateToClinicalEntryPoint(assessment.demoAssignmentId);
    }
  };

  render() {
    const responsiveClass = cssHelper.mapElsCss({
      els: 'padding-left-3x padding-right-3x',
      md: 'padding-left-1x1o2 padding-right-1x1o2',
      lg: 'padding-left-2x padding-right-2x'
    });

    return (
      <>
        {this.state.isLoading && <ELSPageLoader />}
        <Box px3 py2>
          <h1>{this.props.locale === Locales.EN_US ? 'Empty EHR Information' : 'Empty EMR Information'}</h1>
        </Box>
        <Box py2 className={cx('u-els-background-color-n0', responsiveClass)}>
          <Box p2 className="u-els-background-color-white">
            <h2>Assignment Objectives</h2>
            <AssignmentObjective objective={this.state.objectives} />
          </Box>
          <Box p2 className="u-els-background-color-white">
            <h2>Assignment Instructions</h2>
            <AssignmentInstruction instruction={this.state.instructions} />
          </Box>
        </Box>
        <BlankChartingCompetency />
        <Box className={responsiveClass} py2>
          <ELSButton text="Start Demo" onClick={this.handleStartDemoClick} />
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state),
  fullAssignment: studentSelectors.getFullAssignment(state)
});

const mapDispatchToProps = (dispatch) => ({
  setEmptyEhrDemo: (payload) => dispatch(instructorActions.setEmptyEhrDemo(payload)),
  setEmptyEhrDemoInstructions: (instructions) => dispatch(instructorActions.setEmptyEhrDemoInstructions(instructions)),
  setEmptyEhrDemoObjectives: (objectives) => dispatch(instructorActions.setEmptyEhrDemoObjectives(objectives))
});

export { BlankChartingAssignment as BaseBlankChartingAssignment };
export default compose(
  withProps(() => ({
    params: useParams()
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(BlankChartingAssignment);

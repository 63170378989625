import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import './sensory.assessment.scss';

const SensoryAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;

  return (
    <div className="sensory-assessment">
      <h1>{SectionTitle.SENSORY_ASSESSMENT}</h1>
      <p>Note: Assess on admission. Reassess only if noted change.</p>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.VISION_ASSESSMENT}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.VISION_ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.VISION_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
            <div className="u-els-margin-top">
              <ErrorFormField formField={formFieldMap.get(FormField.GLASSES_OR_CONTACTS)} formSubmittedCount={formSubmittedCount} />
            </div>
            <ErrorFormField formField={formFieldMap.get(FormField.VISION_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.HEARING_ASSESSMENT}</h2>

            <ErrorFormField formField={formFieldMap.get(FormField.HEARING_ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.HEARING_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.HEARING_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <div>
          <h2>{SectionTitle.PUPILS}</h2>
          <ELSFlex className="u-els-margin-top u-flex-gutter-3x" left wrap>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h4>{SectionTitle.BILATERAL_ASSESSMENT}</h4>
              <ELSFlex className="u-els-margin-top" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.BILATERAL_ASSESSMENT_EQUAL)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField
                    className="sensory-assessment__text-field"
                    formField={formFieldMap.get(FormField.BILATERAL_ASSESSMENT_EQUAL_REASON)}
                    formSubmittedCount={formSubmittedCount}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.BILATERAL_ASSESSMENT_ROUND)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField
                    className="sensory-assessment__text-field"
                    formField={formFieldMap.get(FormField.BILATERAL_ASSESSMENT_ROUND_REASON)}
                    formSubmittedCount={formSubmittedCount}
                  />
                </ELSFlexItem>
              </ELSFlex>
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h4>{SectionTitle.SIZE}</h4>
              <ELSFlex className="u-els-margin-top" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <div className="u-els-display-inline">
                    <div className="sensory-assessment__size-field u-els-display-inline-block">
                      <ErrorFormField formField={formFieldMap.get(FormField.SIZE_LEFT)} formSubmittedCount={formSubmittedCount} />
                    </div>
                    <span className="size-field-unit">mm</span>
                  </div>
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <div className="u-els-display-inline">
                    <div className="sensory-assessment__size-field u-els-display-inline-block">
                      <ErrorFormField formField={formFieldMap.get(FormField.SIZE_RIGHT)} formSubmittedCount={formSubmittedCount} />
                    </div>
                    <span className="size-field-unit">mm</span>
                  </div>
                </ELSFlexItem>
              </ELSFlex>
            </ELSFlexItem>
          </ELSFlex>
        </div>

        <SectionDivider />

        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.PUPILLARY_REACTION}</h2>
            <ELSFlex className="u-els-margin-top" left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.PUPILLARY_REACTION_LEFT_EYE_RADIO)} formSubmittedCount={formSubmittedCount} />
                <div className="u-els-margin-top-2x">
                  <ErrorFormField formField={formFieldMap.get(FormField.PUPILLARY_REACTION_LEFT_EYE_CHECK)} formSubmittedCount={formSubmittedCount} />
                </div>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.PUPILLARY_REACTION_RIGHT_EYE_RADIO)} formSubmittedCount={formSubmittedCount} />
                <div className="u-els-margin-top-2x">
                  <ErrorFormField formField={formFieldMap.get(FormField.PUPILLARY_REACTION_RIGHT_EYE_CHECK)} formSubmittedCount={formSubmittedCount} />
                </div>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.PUPILLARY_ACCOMODATION}</h2>
            <Box mt>
              <p>Positive evaluation requires two findings: bilateral pupillary constriction and convergency of axis of eyes.</p>
            </Box>
            <ErrorFormField formField={formFieldMap.get(FormField.ACCOMMODATION_PRESENT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.ACCOMMODATION_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.NEUROLOGICAL_INTERVENTIONS]} />
    </div>
  );
};

SensoryAssessmentView.displayName = 'SensoryAssessmentView';
export default SensoryAssessmentView;

import { ChartFragment } from 'models/api-response';
import { AppMessage } from 'constants/app.constant';
import { ChartHistory } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, FormFieldLabel, SectionTitle } from '../shared/constants';

interface InterventionHistoryProps {
  chartHistory: ChartFragment[];
}

const InterventionHistory = (props: InterventionHistoryProps) => {
  const { chartHistory } = props;

  const dataSource = {
    headers: [
      { text: FormFieldLabel.TIME, field: FormField.INTERVENTION_DATE_TIME, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.CONSULTATION_TYPE, field: FormField.CONSULTATION_TYPE, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.REASON_CONSULTATION, field: FormField.REASON_CONSULTATION, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.REFERRAL_SOURCE, field: FormField.REFERRAL_SOURCE, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.HISTORY_PRESENT_ILLNESS, field: FormField.HISTORY_PRESENT_ILLNESS, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.FINDINGS, field: FormField.FINDINGS, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.IMPRESSIONS, field: FormField.IMPRESSIONS, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.PLAN, field: FormField.PLAN, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.SPEECH_THERAPIST_NAME, field: FormField.SPEECH_THERAPIST_NAME, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.SPEECH_THERAPIST_SIGNATURE, field: FormField.SPEECH_THERAPIST_SIGNATURE, section: SectionTitle.NO_SECTION }
    ],
    data: chartHistory
  };

  return (
    <div className="intervention">
      <ELSFlex left wrap>
        <ELSFlexItem w={chartHistory.length === 0 ? '1o2' : '1o1'}>
          <ChartHistory noTableDataMessage=" " renderVertical tableTitleContents={[SectionTitle.NO_SECTION]} dataSource={dataSource} />
        </ELSFlexItem>
        {chartHistory.length === 0 && (
          <ELSFlexItem w="1o2">
            <div className="margin-top-4x1o2 u-els-margin-left table-message">{AppMessage.NO_TABLE_DATA}</div>
          </ELSFlexItem>
        )}
      </ELSFlex>
    </div>
  );
};

InterventionHistory.displayName = 'InterventionHistory';
export default InterventionHistory;

export enum SectionTitle {
  LABOR_INTERVENTIONS = 'Labor Interventions',
  LABOR_CARE = 'Labor Care',
  DELIVERY_CARE = 'Delivery Care',
  PATIENT_RESPONSE = 'Patient Response',
  LABOR_CARE_NOTES = 'Notes'
}

export enum FormField {
  NO_CARE_REQUIRED = 'noCareRequired',
  LABOR_CARE = 'laborCare',
  DELIVERY_CARE = 'deliveryCare',
  PATIENT_RESPONSE = 'patientResponse',
  LABOR_CARE_NOTES = 'laborCareNotes'
}

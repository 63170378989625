import cx from 'classnames';
import { delay } from 'lodash';
import { Component } from 'react';
import { Validation } from 'constants/app.constant';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';

interface ErrorMessageProps {
  className?: string;
  message?: string;
  hasError: boolean;
}

interface ErrorMessageState {
  hasError?: boolean;
  message?: string;
}

class ErrorMessage extends Component<ErrorMessageProps, ErrorMessageState> {
  static displayName = 'ErrorMessage';

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.updateError();
  }

  componentDidUpdate(prevProps: Readonly<ErrorMessageProps>): void {
    if (this.props.hasError !== prevProps.hasError || this.props.message !== prevProps.message) {
      this.updateError();
    }
  }

  updateError = () => delay(() => this.setState({ hasError: this.props.hasError, message: this.props.message }), Validation.ERROR_DELAY_TIME);

  render() {
    return this.state.hasError ? (
      <div className={cx('error-message', this.props.className)}>
        <ELSFlex left>
          <ELSFlexItem>
            <ELSIcon name="alert" prefix="gizmo" color="warn" size="1x" align="sub" title="Error" ariaProps={{ 'aria-hidden': 'false' }} />
          </ELSFlexItem>
          <ELSFlexItem className="u-els-margin-left-1o2 u-overflow--hidden u-word-wrap--break-word">
            <div className="u-els-color-error">{this.state.message}</div>
          </ELSFlexItem>
        </ELSFlex>
      </div>
    ) : null;
  }
}

export default ErrorMessage;

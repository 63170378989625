import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { ChartLabel, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { getScaleContent } from 'helpers/chart.helper';
import { chartService } from 'services';
import { buildPlainRecord } from 'services/chart.service';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import PsychosocialScreenView from './PsychosocialScreenView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface PsychosocialScreenState {
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  isLocked: boolean;
}

interface PsychosocialScreenProps extends ChartComponentProps {
  patientContext: PatientContext;
}

const getSelectedContent = (existingDataMap, formFieldId): string => existingDataMap.get(formFieldId)?.content || '';
const getRecordContent = (fragment: ChartFragment, sectionTitle: string, formField: string) => chartHelper.getFragmentRecords(fragment, sectionTitle, formField)[0] || '';

class PsychosocialScreen extends Component<PsychosocialScreenProps, PsychosocialScreenState> {
  static displayName = 'PsychosocialScreen';

  constructor(props) {
    super(props);
    this.state = {
      fragment: null,
      statusFragment: null,
      isLocked: false
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.PSYCHOSOCIAL_SCREEN, NAV_ID.ADMISSION_HISTORY);

        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  transformFragmentData = (fragment: ChartFragment) => {
    const fieldMap = new Map();
    const formFieldRecordContents = [
      { name: FormField.BIRTH_ORDER, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.GROW_UP, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_NAME_1, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_NAME_2, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_NAME_3, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_NAME_4, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_NAME_5, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_1, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_2, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_3, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_4, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_5, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_1, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_2, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_3, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_4, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_5, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.PREVIOUS_OCCUPATIONS, sectionTitle: SectionTitle.FAMILY_ORIGIN },
      { name: FormField.CURRENT_PSYCHOTHERAPIST_NAME, sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST },
      { name: FormField.CURRENT_PSYCHOTHERAPIST_ADDRESS, sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST },
      { name: FormField.CURRENT_PSYCHOTHERAPIST_PHONE_NUMBER, sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST },
      { name: FormField.CURRENT_PRIMARY_CARE_PROVIDER_NAME, sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER },
      { name: FormField.CURRENT_PRIMARY_CARE_PROVIDER_ADDRESS, sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER },
      { name: FormField.CURRENT_PRIMARY_CARE_PROVIDER_PHONE, sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER }
    ];

    formFieldRecordContents.forEach(({ name, sectionTitle }) => fieldMap.set(name, getRecordContent(fragment, sectionTitle, name)));

    return fieldMap;
  };
  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentValue, getFragmentContentIds } = chartHelper;
    const dataMap = new Map<string, ChartMetaFormField>();
    const existingDataMap = this.transformFragmentData(fragment);

    const dropdowns = [
      { name: FormField.APPEARANCE_GROOMING, label: FormFieldLabel.APPEARANCE_GROOMING, sectionTitle: SectionTitle.APPEARANCE },
      { name: FormField.EMOTIONAL_STATUS_BODY_LANGUAGE, label: FormFieldLabel.EMOTIONAL_STATUS_BODY_LANGUAGE, sectionTitle: SectionTitle.EMOTIONAL_STATUS }
    ];
    const multiSelectDropdowns = [
      { name: FormField.EMOTIONAL_STATUS_FEEL_RIGHT_NOW, label: ChartLabel.MARK_ALL_THAT_APPLY, sectionTitle: SectionTitle.EMOTIONAL_STATUS },
      { name: FormField.SPEECH_LANGUAGE_ARTICULATION, label: ChartLabel.MARK_ALL_THAT_APPLY, sectionTitle: SectionTitle.SPEECH_LANGUAGE_ARTICULATION },
      { name: FormField.SPEECH_LANGUAGE_COHERENCE, label: ChartLabel.MARK_ALL_THAT_APPLY, sectionTitle: SectionTitle.SPEECH_LANGUAGE_COHERENCE },
      { name: FormField.PATIENT_ASSETS, label: ChartLabel.MARK_ALL_THAT_APPLY, sectionTitle: SectionTitle.PATIENT_ASSETS },
      { name: FormField.PROBLEM_AREAS, label: ChartLabel.MARK_ALL_THAT_APPLY, sectionTitle: SectionTitle.PROBLEM_AREAS },
      {
        name: FormField.TREATMENT_PLAN_RECOMMENDATIONS,
        label: ChartLabel.MARK_ALL_THAT_APPLY,
        sectionTitle: SectionTitle.TREATMENT_PLAN_RECOMMENDATIONS
      },
      { name: FormField.PSYCHIATRIC_HISTORY, label: '', sectionTitle: SectionTitle.PSYCHIATRIC_HISTORY },
      { name: FormField.LIFE_STRESSORS, label: FormFieldLabel.LIFE_STRESSORS, sectionTitle: SectionTitle.LIFE_STRESSORS }
    ];
    const radioChoices = [
      {
        name: FormField.PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS,
        label: FormFieldLabel.PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS,
        sectionTitle: SectionTitle.EMOTIONAL_STATUS
      },
      {
        name: FormField.PAST_2_WEEK_INTEREST_THINGS,
        label: FormFieldLabel.PAST_2_WEEK_INTEREST_THINGS,
        sectionTitle: SectionTitle.EMOTIONAL_STATUS
      },
      {
        name: FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME,
        label: FormFieldLabel.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME,
        sectionTitle: SectionTitle.ORIENTATION
      },
      {
        name: FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON,
        label: FormFieldLabel.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON,
        sectionTitle: SectionTitle.ORIENTATION
      },
      {
        name: FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE,
        label: FormFieldLabel.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE,
        sectionTitle: SectionTitle.ORIENTATION
      },
      {
        name: FormField.COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE,
        label: FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE,
        sectionTitle: SectionTitle.MEMORY
      },
      {
        name: FormField.COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH,
        label: FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH,
        sectionTitle: SectionTitle.MEMORY
      },
      {
        name: FormField.COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN,
        label: FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN,
        sectionTitle: SectionTitle.MEMORY
      },
      {
        name: FormField.COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME,
        label: FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME,
        sectionTitle: SectionTitle.MEMORY
      },
      {
        name: FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH,
        label: FormFieldLabel.COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH,
        sectionTitle: SectionTitle.ABSTRACT_REASONING
      },
      {
        name: FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS,
        label: FormFieldLabel.COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS,
        sectionTitle: SectionTitle.ABSTRACT_REASONING
      },
      {
        name: FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK,
        label: FormFieldLabel.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK,
        sectionTitle: SectionTitle.ATTENTION_SPAN
      },
      {
        name: FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD,
        label: FormFieldLabel.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD,
        sectionTitle: SectionTitle.ATTENTION_SPAN
      },
      {
        name: FormField.JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS,
        label: FormFieldLabel.JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS,
        sectionTitle: SectionTitle.JUDGMENT
      },
      {
        name: FormField.JUDGMENT_PLANS_FUTURE,
        label: FormFieldLabel.JUDGMENT_PLANS_FUTURE,
        sectionTitle: SectionTitle.JUDGMENT
      },
      {
        name: FormField.SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS,
        label: FormFieldLabel.SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS,
        sectionTitle: SectionTitle.COMPREHENSION
      },
      {
        name: FormField.SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS,
        label: FormFieldLabel.SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS,
        sectionTitle: SectionTitle.COMPREHENSION
      },
      {
        name: FormField.SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY,
        label: FormFieldLabel.SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY,
        sectionTitle: SectionTitle.APHASIA
      },
      {
        name: FormField.THOUGHT_FORMULATION,
        sectionTitle: SectionTitle.THOUGHT_FORMULATION
      },
      {
        name: FormField.THOUGHT_CONTENT,
        sectionTitle: SectionTitle.THOUGHT_CONTENT
      },
      {
        name: FormField.DECISION_MAKING_RATING,
        sectionTitle: SectionTitle.DECISION_MAKING_RATING
      },
      {
        name: FormField.CURRENT_SAFETY_ISSUES,
        sectionTitle: SectionTitle.CURRENT_SAFETY_ISSUES
      },
      {
        name: FormField.SUICIDE_HISTORY,
        label: FormFieldLabel.SUICIDE_HISTORY,
        sectionTitle: SectionTitle.SUICIDE_HISTORY
      },
      {
        name: FormField.SUICIDE_HISTORY_THOUGHTS,
        label: FormFieldLabel.SUICIDE_HISTORY,
        sectionTitle: SectionTitle.SUICIDE_HISTORY
      },
      {
        name: FormField.SAFETY_EVALUATIONS_SUICIDE_HOMICIDE_IMPULSES,
        label: FormFieldLabel.SAFETY_EVALUATION,
        sectionTitle: SectionTitle.SAFETY_EVALUATION
      },
      {
        name: FormField.CURRENT_LIVING_SITUATION,
        label: FormFieldLabel.CURRENT_LIVING_SITUATION,
        sectionTitle: SectionTitle.FAMILY_ORIGIN
      },
      {
        name: FormField.CURRENT_EMPLOYMENT_STATUS,
        label: FormFieldLabel.CURRENT_EMPLOYMENT_STATUS,
        sectionTitle: SectionTitle.FAMILY_ORIGIN
      },
      {
        name: FormField.BEHAVIORAL_STATUS,
        sectionTitle: SectionTitle.BEHAVIORAL_STATUS
      }
    ];

    const textBoxes = [
      {
        name: FormField.BIRTH_ORDER,
        label: FormFieldLabel.BIRTH_ORDER_IN_FAMILY,
        sectionTitle: SectionTitle.FAMILY_ORIGIN
      },
      {
        name: FormField.GROW_UP,
        label: FormFieldLabel.TOWN_STATE_COUNTRY,
        sectionTitle: SectionTitle.FAMILY_ORIGIN
      },
      {
        name: FormField.PREVIOUS_OCCUPATIONS,
        label: FormFieldLabel.PREVIOUS_OCCUPATIONS,
        sectionTitle: SectionTitle.FAMILY_ORIGIN
      },
      {
        name: FormField.CURRENT_PSYCHOTHERAPIST_NAME,
        label: FormFieldLabel.NAME,
        sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST
      },
      {
        name: FormField.CURRENT_PSYCHOTHERAPIST_ADDRESS,
        label: FormFieldLabel.ADDRESS,
        sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST
      },
      {
        name: FormField.CURRENT_PSYCHOTHERAPIST_PHONE_NUMBER,
        label: FormFieldLabel.PHONE,
        sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST
      },
      {
        name: FormField.CURRENT_PRIMARY_CARE_PROVIDER_NAME,
        label: FormFieldLabel.NAME,
        sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER
      },
      {
        name: FormField.CURRENT_PRIMARY_CARE_PROVIDER_ADDRESS,
        label: FormFieldLabel.ADDRESS,
        sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER
      },
      {
        name: FormField.CURRENT_PRIMARY_CARE_PROVIDER_PHONE,
        label: FormFieldLabel.PHONE,
        sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_NAME_1,
        label: FormFieldLabel.NAME,
        sectionTitle: FormFieldLabel.NAME
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_NAME_2
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_NAME_3
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_NAME_4
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_NAME_5
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_1,
        label: FormFieldLabel.LIVING_DECEASED,
        sectionTitle: FormFieldLabel.LIVING_DECEASED
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_2
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_3
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_4
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_5
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_1,
        label: FormFieldLabel.PSYCHIATRIC_DIAGNOSIS,
        sectionTitle: FormFieldLabel.PSYCHIATRIC_DIAGNOSIS
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_2
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_3
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_4
      },
      {
        name: FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_5
      }
    ];

    const scales = [
      {
        name: FormField.PATIENT_HEALTH_QUESTIONNAIRE,
        label: SectionTitle.PATIENT_HEALTH_QUESTIONNAIRE,
        sectionTitle: SectionTitle.PATIENT_HEALTH_QUESTIONNAIRE
      }
    ];

    scales.forEach(({ name, label, sectionTitle }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.SCALE, label, scaleContent: getScaleContent(fragment, sectionTitle) }))
    );

    dropdowns.forEach(({ name, label, sectionTitle }) => {
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.DROPDOWN, label, contentIds: getFragmentContentIds(fragment, sectionTitle, name) }));
    });
    multiSelectDropdowns.forEach(({ name, label, sectionTitle }) => {
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN, label, contentIds: getFragmentContentIds(fragment, sectionTitle, name) }));
    });
    radioChoices.forEach(({ name, label, sectionTitle }) => {
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label, contentIds: getFragmentContentIds(fragment, sectionTitle, name) }));
    });
    textBoxes.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.TEXT_BOX,
          label: item.label,
          value: getSelectedContent(existingDataMap, item.name)
        })
      )
    );
    dataMap.set(
      FormField.SPEECH_LANGUAGE_ARTICULATION_OTHER,
      createFormField({
        name: FormField.SPEECH_LANGUAGE_ARTICULATION_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: FormFieldLabel.SPEECH_LANGUAGE_ARTICULATION_OTHER,
        value: getFragmentValue(fragment, SectionTitle.SPEECH_LANGUAGE_ARTICULATION, FormField.SPEECH_LANGUAGE_ARTICULATION_OTHER)
      })
    );
    return dataMap;
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };
  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Psychosocial Screen';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.APPEARANCE,
          records: [buildPatientRecord(formFieldMap, FormField.APPEARANCE_GROOMING)]
        },
        {
          sectionTitle: SectionTitle.BEHAVIORAL_STATUS,
          records: [buildPatientRecord(formFieldMap, FormField.BEHAVIORAL_STATUS)]
        },
        {
          sectionTitle: SectionTitle.EMOTIONAL_STATUS,
          records: [
            ...buildPatientRecords(formFieldMap, FormField.EMOTIONAL_STATUS_FEEL_RIGHT_NOW),
            buildPatientRecord(formFieldMap, FormField.EMOTIONAL_STATUS_BODY_LANGUAGE),
            buildPatientRecord(formFieldMap, FormField.PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS),
            buildPatientRecord(formFieldMap, FormField.PAST_2_WEEK_INTEREST_THINGS)
          ]
        },
        {
          parentSectionTitle: SectionTitle.COGNITIVE_ABILITY,
          sectionTitle: SectionTitle.ORIENTATION,
          records: [
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE)
          ]
        },
        {
          parentSectionTitle: SectionTitle.COGNITIVE_ABILITY,
          sectionTitle: SectionTitle.MEMORY,
          records: [
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME)
          ]
        },
        {
          parentSectionTitle: SectionTitle.COGNITIVE_ABILITY,
          sectionTitle: SectionTitle.ABSTRACT_REASONING,
          records: [
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS)
          ]
        },
        {
          parentSectionTitle: SectionTitle.COGNITIVE_ABILITY,
          sectionTitle: SectionTitle.ATTENTION_SPAN,
          records: [
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK),
            buildPatientRecord(formFieldMap, FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD)
          ]
        },
        {
          sectionTitle: SectionTitle.JUDGMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS),
            buildPatientRecord(formFieldMap, FormField.JUDGMENT_PLANS_FUTURE)
          ]
        },
        {
          sectionTitle: SectionTitle.THOUGHT_FORMULATION,
          records: [buildPatientRecord(formFieldMap, FormField.THOUGHT_FORMULATION)]
        },
        {
          sectionTitle: SectionTitle.THOUGHT_CONTENT,
          records: [buildPatientRecord(formFieldMap, FormField.THOUGHT_CONTENT)]
        },
        {
          sectionTitle: SectionTitle.PATIENT_ASSETS,
          records: [...buildPatientRecords(formFieldMap, FormField.PATIENT_ASSETS)]
        },
        {
          sectionTitle: SectionTitle.PROBLEM_AREAS,
          records: [...buildPatientRecords(formFieldMap, FormField.PROBLEM_AREAS)]
        },
        {
          sectionTitle: SectionTitle.DECISION_MAKING_RATING,
          records: [buildPatientRecord(formFieldMap, FormField.DECISION_MAKING_RATING)]
        },
        {
          sectionTitle: SectionTitle.TREATMENT_PLAN_RECOMMENDATIONS,
          records: [...buildPatientRecords(formFieldMap, FormField.TREATMENT_PLAN_RECOMMENDATIONS)]
        },
        {
          sectionTitle: SectionTitle.CURRENT_SAFETY_ISSUES,
          records: [buildPatientRecord(formFieldMap, FormField.CURRENT_SAFETY_ISSUES)]
        },
        {
          sectionTitle: SectionTitle.PSYCHIATRIC_HISTORY,
          records: [...buildPatientRecords(formFieldMap, FormField.PSYCHIATRIC_HISTORY)]
        },
        {
          sectionTitle: SectionTitle.SUICIDE_HISTORY,
          records: [buildPatientRecord(formFieldMap, FormField.SUICIDE_HISTORY)]
        },
        {
          sectionTitle: SectionTitle.LIFE_STRESSORS,
          records: [...buildPatientRecords(formFieldMap, FormField.LIFE_STRESSORS)]
        },
        {
          sectionTitle: SectionTitle.SAFETY_EVALUATION,
          records: [buildPatientRecord(formFieldMap, FormField.SAFETY_EVALUATIONS_SUICIDE_HOMICIDE_IMPULSES)]
        },
        {
          sectionTitle: SectionTitle.FAMILY_ORIGIN,
          records: [
            buildPatientRecord(formFieldMap, FormField.BIRTH_ORDER),
            buildPatientRecord(formFieldMap, FormField.GROW_UP),
            buildPatientRecord(formFieldMap, FormField.PREVIOUS_OCCUPATIONS),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_NAME_1),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_NAME_2),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_NAME_3),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_NAME_4),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_NAME_5),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_1),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_2),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_3),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_4),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_5),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_1),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_2),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_3),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_4),
            buildPatientRecord(formFieldMap, FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_5),
            buildPatientRecord(formFieldMap, FormField.CURRENT_LIVING_SITUATION),
            buildPatientRecord(formFieldMap, FormField.CURRENT_EMPLOYMENT_STATUS)
          ]
        },
        {
          sectionTitle: SectionTitle.CURRENT_PSYCHOTHERAPIST,
          records: [
            buildPatientRecord(formFieldMap, FormField.CURRENT_PSYCHOTHERAPIST_NAME),
            buildPatientRecord(formFieldMap, FormField.CURRENT_PSYCHOTHERAPIST_ADDRESS),
            buildPatientRecord(formFieldMap, FormField.CURRENT_PSYCHOTHERAPIST_PHONE_NUMBER)
          ]
        },
        {
          sectionTitle: SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER,
          records: [
            buildPatientRecord(formFieldMap, FormField.CURRENT_PRIMARY_CARE_PROVIDER_NAME),
            buildPatientRecord(formFieldMap, FormField.CURRENT_PRIMARY_CARE_PROVIDER_ADDRESS),
            buildPatientRecord(formFieldMap, FormField.CURRENT_PRIMARY_CARE_PROVIDER_PHONE)
          ]
        },
        {
          parentSectionTitle: SectionTitle.SPEECH_LANGUAGE,
          sectionTitle: SectionTitle.SPEECH_LANGUAGE_ARTICULATION,
          records: [...buildPatientRecords(formFieldMap, FormField.SPEECH_LANGUAGE_ARTICULATION), buildPatientRecord(formFieldMap, FormField.SPEECH_LANGUAGE_ARTICULATION_OTHER)]
        },
        {
          parentSectionTitle: SectionTitle.SPEECH_LANGUAGE,
          sectionTitle: SectionTitle.SPEECH_LANGUAGE_COHERENCE,
          records: [...buildPatientRecords(formFieldMap, FormField.SPEECH_LANGUAGE_COHERENCE)]
        },
        {
          parentSectionTitle: SectionTitle.SPEECH_LANGUAGE,
          sectionTitle: SectionTitle.COMPREHENSION,
          records: [
            buildPatientRecord(formFieldMap, FormField.SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS),
            buildPatientRecord(formFieldMap, FormField.SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS)
          ]
        },
        {
          parentSectionTitle: SectionTitle.SPEECH_LANGUAGE,
          sectionTitle: SectionTitle.APHASIA,
          records: [buildPatientRecord(formFieldMap, FormField.SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY)]
        },
        {
          sectionTitle: SectionTitle.PATIENT_HEALTH_QUESTIONNAIRE,
          records: [...buildPatientRecords(formFieldMap, FormField.PATIENT_HEALTH_QUESTIONNAIRE, true, true)]
        },
        {
          sectionTitle: SectionTitle.PATIENT_HEALTH_QUESTIONNAIRE,
          records: [
            buildPlainRecord({
              formField: FormField.PATIENT_HEALTH_QUESTIONNAIRE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.PATIENT_HEALTH_QUESTIONNAIRE))?.score?.value
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord };
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, patientContext } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      chartActionsProps,
      formSubmittedCount,
      formFieldMap,
      patientContext
    };
    return <PsychosocialScreenView {...viewProps} />;
  }
}

export { PsychosocialScreen as BasePsychosocialScreen };
export default compose(withChartLogic, withAdmissionHistory)(PsychosocialScreen);

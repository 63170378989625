import { Box, MultiLine } from 'components/common';
import { ELSIcon } from 'components/common/els';

interface AssignmentObjectiveProps {
  objective: string;
}

const AssignmentObjective = (props: AssignmentObjectiveProps) => {
  const { objective } = props;
  return (
    <Box mt>
      {objective ? (
        <MultiLine text={objective} />
      ) : (
        <>
          <ELSIcon prefix="gizmo" name="information" title="" size="1x" align="sub" />
          <span id="qa-no-objective" className="u-els-margin-left-1o2">
            Your instructor did not include any assignment objectives.
          </span>
        </>
      )}
    </Box>
  );
};

AssignmentObjective.displayName = 'AssignmentObjective';
export default AssignmentObjective;

export interface ChartsInvolvedProps {
  className?: string;
  charts: string[];
}

const ChartsInvolved = (props: ChartsInvolvedProps) => (
  <div className={props.className}>
    <p>There are several charts involved with this case study:</p>
    <p className="u-els-margin-top u-els-margin-bottom">
      <b>Chart Section</b>
    </p>
    <div data-qa="case-study-charts-involved">{props.charts?.length > 0 && props.charts.map((chart) => <p key={chart}>{`• ${chart}`}</p>)}</div>
  </div>
);

ChartsInvolved.displayName = 'ChartsInvolved';
export default ChartsInvolved;

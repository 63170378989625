export enum FormField {
  DEVELOPMENT_AGE = 'developmentalAge',
  CHRONOLOGICAL_AGE = 'chronologicalAge',
  GROSS_MOTOR_SKILLS = 'grossMotorSkills',
  FINE_MOTOR_SKILLS = 'fineMotorSkills',
  SENSORY_SKILLS = 'sensorySkills',
  VOCALIZATION_SKILLS = 'vocalizationSkills',
  SOCIALIZATION_COGNITION = 'chronologicalAgeYesNo'
}

export enum SectionTitle {
  DEVELOPMENT_SCREEN = 'Developmental Screen',
  DEVELOPMENT_AGE = 'Developmental Age',
  CHRONOLOGICAL_AGE = 'Chronological Age',
  GROSS_MOTOR_SKILLS = 'Gross Motor Skills',
  FINE_MOTOR_SKILLS = 'Fine Motor Skills',
  SENSORY_SKILLS = 'Sensory Skills',
  VOCALIZATION_SKILLS = 'Vocalization Skills',
  SOCIALIZATION_COGNITION = 'Socialization & cognition Skills'
}

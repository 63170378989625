import cx from 'classnames';
import { ELSFlex, ELSFlexItem, ELSIcon, ELSTooltip } from 'components/common/els';
import './title-tooltip.scss';

export interface TitleTooltipProps {
  className?: string;
  title: string;
  titleClassname?: string;
  tooltip: string;
}

const TitleTooltip = (props: TitleTooltipProps) => (
  <ELSFlex className={props.className} left middle>
    <ELSFlexItem>
      <span className={cx('title-tooltip__text', props.titleClassname)}>{props.title}</span>
    </ELSFlexItem>
    <ELSFlexItem className="title-tooltip__icon-wrapper">
      <ELSTooltip wrapperClassName="chart-input__label-tooltip" content={props.tooltip} showClose>
        <ELSIcon prefix="gizmo" name="information" size="1x" title="Information" ariaProps={{ 'aria-hidden': 'false' }} />
      </ELSTooltip>
    </ELSFlexItem>
  </ELSFlex>
);

TitleTooltip.displayName = 'TitleTooltip';
export default TitleTooltip;

export enum SectionTitle {
  HYGIENE_DRESSINGS_COMFORT = 'Hygiene - Dressings - Comfort',
  NEWBORN_HYGIENE = 'Newborn Hygiene',
  COMFORT = 'Comfort',
  BATH_SHOWER = 'Bath/Shower',
  MOUTH_CARE = 'Mouth Care',
  SHAVE = 'Shave',
  HAIR_NAILS = 'Hair/Nails',
  DRESSING = 'Dressing',
  LINENS_HOUSEKEEPING = 'Linens/Housekeeping'
}

export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'noAssessmentRequired',
  CONTAINER_NEWBORN_HYGIENE = 'containerNewbornHygiene',
  CONTAINER_SHAVE = 'containerShave',
  NEWBORN_HYGIENE = 'newbornHygiene',
  NEWBORN_HYGIENE_NOTES = 'newbornHygieneNotes',
  COMFORT = 'comfortCare',
  COMFORT_NOTES = 'comfortCareNotes',
  BATH_SHOWER = 'bathShowerCare',
  BATH_SHOWER_NOTES = 'bathShowerCareNotes',
  MOUTH_CARE = 'mouthCare',
  MOUTH_CARE_NOTES = 'mouthCareNotes',
  SHAVE_NOT_APPLICATE = 'shaveCareNotApplicable',
  SHAVE = 'shaveCare',
  SHAVE_NOTES = 'shaveCareNotes',
  HAIR_NAILS = 'hairNailsCare',
  HAIR_NAILS_NOTES = 'hairNailsCareNotes',
  DRESSING = 'dressingCare',
  DRESSING_NOTES = 'dressingCareNotes',
  LINENS_HOUSEKEEPING = 'linensHousekeepingCare',
  LINENS_HOUSEKEEPING_NOTES = 'linensHousekeepingCareNotes'
}

import cx from 'classnames';
import { History } from 'history';
import { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { DropDownItem, NavigationItem } from 'models/ui';
import { appActions } from 'redux/ducks/app';
import { ELSDropDownSearch } from 'components/common/els';
import './navigation.search.bar.scss';

export interface NavigationSearchBarProps {
  value?: string;
  items: NavigationItem[];
  history: History;
  setSelectedNavId: Function;
}

interface NavigationSearchBarState {
  search: string;
}

class NavigationSearchBar extends Component<NavigationSearchBarProps, NavigationSearchBarState> {
  static displayName = 'NavigationSearchBar';

  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };
  }

  getFilteredOptions = (): DropDownItem[] => {
    const filteredNavigationItems = this.props.items?.filter((item) => item.text.toLowerCase().includes(this.state.search.toLowerCase())) ?? [];

    return filteredNavigationItems.map((navItem) => {
      const option: DropDownItem = { id: '', name: '', value: '' };
      option.id = navItem.id;
      option.name = navItem.parentText ? `${navItem.parentText} / ${navItem.text}` : navItem.text;
      option.value = navItem.path;
      return option;
    });
  };

  handleSearchChange = (input: string) => {
    this.setState(() => ({
      search: input
    }));
  };

  handleOptionSelect = (option: DropDownItem) => {
    document.getElementById('navigationsearchbar').blur();
    if (option?.name) {
      this.setState({ search: '' });
      this.props.setSelectedNavId(option.id);
      return this.props.history.push(option.value);
    }
    return new Error('Not a valid selection');
  };

  render() {
    return (
      <div className={cx('nav__header')}>
        <ELSDropDownSearch
          disableAutoComplete
          disableEnterFocus
          id="navigationsearchbar"
          name="navigationsearchbar"
          onOptionSelected={this.handleOptionSelect}
          onSearchChangeHandler={this.handleSearchChange}
          options={this.getFilteredOptions()}
          placeholder="Search Charts"
          showSearchIcon
          type="normal"
          value={this.state.search}
          ariaLabel="Search Charts"
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

export { NavigationSearchBar as BaseNavigationSearchBar };
export default compose(
  withProps(() => ({
    history: useHistory()
  })),
  connect(null, mapDispatchToProps)
)(NavigationSearchBar);

import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const CompletePhysicalAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;

  return (
    <div>
      <h1>{SectionTitle.COMPLETE_PHYSICAL_ASSESSMENT_NARRATIVE}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <Box mt2>
        <ELSFlex className="u-flex-grid-2x o-els-flex-layout--gutters-2x" wrap gutters>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.GENERAL)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.HEAD_NECK)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.EARS_NOSE_THROAT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.EYES_VISION)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.RESPIRATORY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.CARDIOVASCULAR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PERIPHERAL_VASCULAR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.LYMPHATIC)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.BREASTS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.NEUROLOGIC)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PELVIC_GENITALIA)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PROSTATE_GENITALIA)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.RECTAL)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_SUMMARY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PLAN)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

CompletePhysicalAssessmentView.displayName = 'CompletePhysicalAssessmentView';
export default CompletePhysicalAssessmentView;

import { LanguageKeys } from 'lang';
import { ELSFlex, ELSFlexItem } from '@els/els-ui-common-react';
import { Box, ChartActions, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

const marginLeft1x = 'u-els-margin-left-1x';

export type NeurovascularAssessmentViewProps = ChartViewProps;

export default function NeurovascularAssessmentView({ chartActionsProps, formFieldMap, formSubmittedCount }: NeurovascularAssessmentViewProps) {
  return (
    <div className="neurovascular-assessment">
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <h1>{LanguageKeys.NEUROVASCULAR_ASSESSMENT.NEUROVASCULAR_ASSESSMENT}</h1>

      <div className="upper-limb">
        <Box mb2>
          <h2 className="u-els-margin-top-2x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.UPPER_LIMB}</h2>
          <ErrorFormField
            formField={formFieldMap.get(FormField.UPPER_LIMB_SIDE)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
            useDefaultMargin={false}
            className="u-els-margin-bottom-1o2"
          />
        </Box>

        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.UPPER_LIMB_PAIN}</h3>

            <div className="u-els-margin-bottom-2x">
              <ErrorFormField
                formField={formFieldMap.get(FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
                useDefaultMargin={false}
                className="u-els-margin-bottom-1o2"
              />
              <div className="u-els-margin-left-4x">
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1x"
                  inputProps={{ containerClassName: marginLeft1x }}
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-width-1o4"
                />
              </div>
            </div>

            <div className="u-els-margin-bottom-2x">
              <ErrorFormField
                formField={formFieldMap.get(FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
                useDefaultMargin={false}
                className="u-els-margin-bottom-1o2"
              />
              <div className="u-els-margin-left-4x">
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1x"
                  inputProps={{ containerClassName: marginLeft1x }}
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-width-1o4"
                />
              </div>
            </div>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN}</h3>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SKIN_COLOUR)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SKIN_COLOUR_OTHER)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SKIN_TEMPERATURE)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SKIN_TEMPERATURE_OTHER)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.MOVEMENT}</h3>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_MOVEMENT_RADIAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_MOVEMENT_RADIAL_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_MOVEMENT_MEDIAN)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_MOVEMENT_MEDIAN_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_MOVEMENT_ULNAR)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_MOVEMENT_ULNAR_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.SENSATION}</h3>

            <ELSFlex left wrap className="u-els-margin-bottom-1x">
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SENSATION_RADIAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SENSATION_MEDIAN)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_SENSATION_ULNAR)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex left wrap className="u-els-margin-bottom-2x">
          <ELSFlexItem w="1o2" wrap>
            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.CAPILLARY_REFILL}</h3>
                <ErrorFormField formField={formFieldMap.get(FormField.UPPER_LIMB_CAPILLARY_REFILL)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>

              <ELSFlexItem w="1o2" wrap>
                <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.SWELLING}</h3>
                <ErrorFormField formField={formFieldMap.get(FormField.UPPER_LIMB_SWELLING)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.PULSES}</h3>

            <ELSFlex left wrap gutters className="u-els-margin-bottom-2x">
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.UPPER_LIMB_PULSES)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <div className="u-els-margin-left-2x">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.UPPER_LIMB_PULSES_ACTIONS)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-margin-bottom-1x"
                    inputProps={{ containerClassName: marginLeft1x }}
                  />
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.UPPER_LIMB_PULSES_COMMENT)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-width-1o2"
                  />
                </div>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </div>

      <div className="lower-limb">
        <Box mb2>
          <h2 className="u-els-margin-top-2x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.LOWER_LIMB}</h2>
          <ErrorFormField
            formField={formFieldMap.get(FormField.LOWER_LIMB_SIDE)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
            useDefaultMargin={false}
            className="u-els-margin-bottom-1o2"
          />
        </Box>

        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.LOWER_LIMB_PAIN}</h3>

            <div className="u-els-margin-bottom-2x">
              <ErrorFormField
                formField={formFieldMap.get(FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
                useDefaultMargin={false}
                className="u-els-margin-bottom-1o2"
              />
              <div className="u-els-margin-left-4x">
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1x"
                  inputProps={{ containerClassName: marginLeft1x }}
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-width-1o4"
                />
              </div>
            </div>

            <div className="u-els-margin-bottom-2x">
              <ErrorFormField
                formField={formFieldMap.get(FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
                useDefaultMargin={false}
                className="u-els-margin-bottom-1o2"
              />
              <div className="u-els-margin-left-4x">
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1x"
                  inputProps={{ containerClassName: marginLeft1x }}
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-width-1o4"
                />
              </div>
            </div>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN}</h3>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_SKIN_COLOUR)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_SKIN_COLOUR_OTHER)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_SKIN_TEMPERATURE)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_SKIN_TEMPERATURE_OTHER)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex left wrap>
          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.MOVEMENT}</h3>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_MOVEMENT_PERONEAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_MOVEMENT_PERONEAL_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>

              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_MOVEMENT_TIBIAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_MOVEMENT_TIBIAL_COMMENT)}
                  formSubmittedCount={formSubmittedCount}
                  className="u-els-margin-left-2x u-els-width-1o2"
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.SENSATION}</h3>

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField formField={formFieldMap.get(FormField.LOWER_LIMB_SENSATION_PERONEAL)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>

              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField formField={formFieldMap.get(FormField.LOWER_LIMB_SENSATION_TIBIAL)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex left wrap className="u-els-margin-bottom-2x">
          <ELSFlexItem w="1o2" wrap>
            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" wrap>
                <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.CAPILLARY_REFILL}</h3>
                <ErrorFormField formField={formFieldMap.get(FormField.LOWER_LIMB_CAPILLARY_REFILL)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>

              <ELSFlexItem w="1o2" wrap>
                <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.SWELLING}</h3>
                <ErrorFormField formField={formFieldMap.get(FormField.LOWER_LIMB_SWELLING)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>

          <ELSFlexItem w="1o2" wrap>
            <h3 className="u-els-margin-bottom-1x">{LanguageKeys.NEUROVASCULAR_ASSESSMENT.PULSES}</h3>

            <ELSFlex left wrap gutters className="u-els-margin-bottom-2x">
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <div className="u-els-margin-left-2x">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_ACTIONS)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-margin-bottom-1x"
                    inputProps={{ containerClassName: marginLeft1x }}
                  />
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_COMMENT)}
                    formSubmittedCount={formSubmittedCount}
                    className="u-els-width-1o2"
                  />
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_PRESENT_SUB_OPTIONS_1)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-margin-bottom-1x"
                    inputProps={{ containerClassName: 'u-els-margin-top-none' }}
                  />
                </div>
              </ELSFlexItem>

              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <div className="u-els-margin-left-2x">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_ACTIONS)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-margin-bottom-1x"
                    inputProps={{ containerClassName: marginLeft1x }}
                  />
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_COMMENT)}
                    formSubmittedCount={formSubmittedCount}
                    className="u-els-width-1o2"
                  />
                </div>
              </ELSFlexItem>
            </ELSFlex>

            <ELSFlex left wrap gutters>
              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POPLITEAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <div className="u-els-margin-left-2x">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POPLITEAL_ABSENT_ACTIONS)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-margin-bottom-1x"
                    inputProps={{ containerClassName: marginLeft1x }}
                  />
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_POPLITEAL_ABSENT_COMMENT)}
                    formSubmittedCount={formSubmittedCount}
                    className="u-els-width-1o2"
                  />
                </div>
              </ELSFlexItem>

              <ELSFlexItem w="1o2" wrap>
                <ErrorFormField
                  formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_FEMORAL)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                  className="u-els-margin-bottom-1o2"
                />
                <div className="u-els-margin-left-2x">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_FEMORAL_ABSENT_ACTIONS)}
                    formSubmittedCount={formSubmittedCount}
                    useDefaultMargin={false}
                    className="u-els-margin-bottom-1x"
                    inputProps={{ containerClassName: marginLeft1x }}
                  />
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.LOWER_LIMB_PULSES_FEMORAL_ABSENT_COMMENT)}
                    formSubmittedCount={formSubmittedCount}
                    className="u-els-width-1o2"
                  />
                </div>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ErrorFormField formField={formFieldMap.get(FormField.NEUROVASCULAR_ASSESSMENT_NOTE)} formSubmittedCount={formSubmittedCount} />
      </div>

      <ChartActions {...chartActionsProps} />
    </div>
  );
}

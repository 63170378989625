export enum SectionTitle {
  GASTROINTESTINAL_INTERVENTIONS = 'Gastrointestinal Interventions',
  GASTROINTESTINAL_CARE = 'Gastrointestinal Care',
  DRAIN_OR_TUBE_CARE = 'Drain or Tube Care',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Gastrointestinal Care Notes'
}

export enum FormField {
  NO_CARE = 'noCareRequired',
  GASTROINTESTINAL_CARE = 'gastrointestinalCare',
  DRAIN_OR_TUBE_CARE = 'drainOrTubeCare',
  PATIENT_RESPONSE = 'patientResponse',
  CARE_NOTES = 'gastrointestinalCareNotes'
}

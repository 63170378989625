import { ChartMetaFormField, ErrorField } from 'models/ui';
import ErrorTooltip from '../../error-form-field/ErrorTooltip';

interface ErrorMessageProps {
  errors: ErrorField[];
  formField: ChartMetaFormField;
  formSubmittedCount: number;
}

export default function ErrorMessage({ errors, formField, formSubmittedCount }: ErrorMessageProps) {
  return <ErrorTooltip id={`chart-field-errors-${formField.name}`} errors={errors} formSubmittedCount={formSubmittedCount} />;
}

import cx from 'classnames';
import { ReactNode, ReactNodeArray } from 'react';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import './assessment.tile.scss';

interface AssessmentTileProps {
  children: ReactNode | ReactNodeArray;
  align?: 'left' | 'center';
}

const AssessmentTile = (props: AssessmentTileProps) => (
  <ELSFlex className="assessment-tile c-els-card" column center middle>
    <ELSFlexItem>
      <div className={cx({ 'u-els-text-center': props.align === 'center' })}>{props.children}</div>
    </ELSFlexItem>
  </ELSFlex>
);

AssessmentTile.defaultProps = {
  align: 'left'
};
AssessmentTile.displayName = 'AssessmentTile';
export default AssessmentTile;

import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorFormField, Sidebar } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';

export interface PregnancyInformationSidebarViewProps {
  isOpen: boolean;
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  sidebarProps: Function;
}

const PregnancyInformationSidebarView = (props: PregnancyInformationSidebarViewProps) => {
  const { isOpen, chartActionsProps, formFieldMap, formSubmittedCount, sidebarProps, onCloseClick } = props;
  return (
    <Sidebar open={isOpen} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
      <Box mt3>
        <h2>Previous Pregnancies</h2>
      </Box>
      <div className="sidebar__divider" />
      <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DAY_MONTH_YEARS_AGO)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DURATION_OF_LABOR)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-grid-2x" wrap left>
        <ELSFlexItem w="1o3">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.GESTATIONAL_WEEKS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BIRTH_WEIGHT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.GENDER)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
      <ELSFlex className="u-flex-grid-2x" wrap left>
        <ELSFlexItem w="1o3">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE_OF_DELIVERY)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ANESTHESIA)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField
        className="u-els-margin-top"
        formField={formFieldMap.get(FormField.PRETERM_LABOR)}
        formSubmittedCount={formSubmittedCount}
        options={{ displayHorizontal: true }}
      />
      <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.COMPLICATIONS)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.COMMENTS)} formSubmittedCount={formSubmittedCount} />
      <Box mt3 mb>
        <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      </Box>
    </Sidebar>
  );
};

PregnancyInformationSidebarView.displayName = 'PregnancyInformationSidebarView';
export default PregnancyInformationSidebarView;

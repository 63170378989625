import cx from 'classnames';
import { InterventionItem } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ButtonLinkIcon, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSCheckBox, ELSFlex, ELSFlexItem, ELSRadio } from 'components/common/els';
import { FormField, FormFieldLabel, OutcomeEvaluationStatus, SectionTitle } from './constants';
import InterventionItemsView, { InterventionItemsViewProps } from './InterventionItemsView';

export interface CarePlanFormViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  planResolved?: boolean;
  isSidebarView?: boolean;
  interventionItems: InterventionItem[];
  outcomeEvaluationStatus: string;
  isDisplayDocument: boolean;
  onFormFieldChange: Function;
  onPlanResolvedClick?: Function;
  onDisplayDocument: Function;
  onAddInterventionItem: Function;
  onAddInterventionAction?: Function;
  focusedInterventionFieldId: string;
  resetFocusedInterventionFieldId: Function;
}

const CarePlanFormView = (props: CarePlanFormViewProps) => {
  const {
    focusedInterventionFieldId,
    planResolved,
    outcomeEvaluationStatus,
    interventionItems,
    onFormFieldChange,
    onDisplayDocument,
    onAddInterventionItem,
    onAddInterventionAction,
    isSidebarView,
    isDisplayDocument,
    formFieldMap,
    formSubmittedCount,
    chartActionsProps,
    resetFocusedInterventionFieldId
  } = props;

  const interventionItemsViewProps: InterventionItemsViewProps = {
    interventionItems,
    onFormFieldChange,
    onAddInterventionItem,
    onAddInterventionAction,
    isSidebarView,
    focusedInterventionFieldId,
    resetFocusedInterventionFieldId
  };
  const previousName = isSidebarView ? 'Sidebar-' : '';
  return (
    <>
      {isSidebarView && (
        <ELSFlex className="u-flex-gutter-3x u-els-margin-bottom" wrap left>
          <ELSFlexItem>
            <ELSCheckBox labelText="Plan resolved" checked={planResolved} changeHandler={(event) => onFormFieldChange(FormField.PLAN_RESOLVED, event)} />
          </ELSFlexItem>
        </ELSFlex>
      )}
      <ErrorFormField formField={formFieldMap.get(FormField.INTERPROFESSIONAL_PROBLEM)} formSubmittedCount={formSubmittedCount} />
      <Box className="c-els-card c-els-card--border-primary">
        <Box>
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o3" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.NURSING_PROBLEM)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.EXPECTED_OUTCOME)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.INTERVENTION_PLAN)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </Box>
        <ButtonLinkIcon
          iconName={isDisplayDocument ? `chevron-up` : `chevron-down`}
          iconSize="3o4"
          className="u-els-margin-bottom-2x"
          text="Document Care Plan"
          iconPosition="right"
          onClick={onDisplayDocument}
          preventAutoPending
        />
        <div className={cx('care-plan-document', { 'care-plan-document__display-none': !isDisplayDocument })}>
          <Box>
            <h3 className="u-els-margin-bottom">{SectionTitle.ASSESSMENT_CUES}</h3>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.OBJECTIVE_DATA)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.SUBJECTIVE_DATA)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>
          <InterventionItemsView {...interventionItemsViewProps} />
          <Box>
            <h3 className="u-els-margin-bottom">{SectionTitle.OUTCOME_EVALUATION}</h3>
            <ELSFlex className="u-flex-gutter-3x u-els-margin-bottom-2x" wrap left>
              <ELSFlexItem>
                <ELSRadio
                  checked={outcomeEvaluationStatus === OutcomeEvaluationStatus.COMPLETELY_MET}
                  changeHandler={(event) => onFormFieldChange(FormField.OUTCOME_EVALUATION_STATUS, event)}
                  name={`${previousName}${FormField.OUTCOME_EVALUATION_STATUS_COMPLETELY_MET}`}
                  value={OutcomeEvaluationStatus.COMPLETELY_MET}
                >
                  {FormFieldLabel.OUTCOME_EVALUATION_STATUS_COMPLETELY_MET}
                </ELSRadio>
              </ELSFlexItem>
              <ELSFlexItem>
                <ELSRadio
                  checked={outcomeEvaluationStatus === OutcomeEvaluationStatus.PARTIALLY_MET}
                  changeHandler={(event) => onFormFieldChange(FormField.OUTCOME_EVALUATION_STATUS, event)}
                  name={`${previousName}${FormField.OUTCOME_EVALUATION_STATUS_PARTIALLY_MET}`}
                  value={OutcomeEvaluationStatus.PARTIALLY_MET}
                >
                  {FormFieldLabel.OUTCOME_EVALUATION_STATUS_PARTIALLY_MET}
                </ELSRadio>
              </ELSFlexItem>
              <ELSFlexItem>
                <ELSRadio
                  checked={outcomeEvaluationStatus === OutcomeEvaluationStatus.NOT_MET}
                  changeHandler={(event) => onFormFieldChange(FormField.OUTCOME_EVALUATION_STATUS, event)}
                  name={`${previousName}${FormField.OUTCOME_EVALUATION_STATUS_NOT_MET}`}
                  value={OutcomeEvaluationStatus.NOT_MET}
                >
                  {FormFieldLabel.OUTCOME_EVALUATION_STATUS_NOT_MET}
                </ELSRadio>
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem>
                <ErrorFormField formField={formFieldMap.get(FormField.EVAL_DATE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>
        </div>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </>
  );
};

CarePlanFormView.displayName = 'CarePlanFormView';

export default CarePlanFormView;

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import './wounds.scss';

export interface WoundsViewProps {
  fragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
}

const WoundsView = (props: WoundsViewProps) => {
  const { fragment, formSubmittedCount, chartMetaFormFields } = props;
  return (
    <div className="wounds">
      <h1>Wounds</h1>
      <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={chartMetaFormFields.get(FormField.WOUND_HEALED)} formSubmittedCount={formSubmittedCount} />

      <Box mt2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={chartMetaFormFields.get(FormField.BODY_REGION)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.LOCATION)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
            <div className="wounds__category">
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.WOUND_CATEGORY)}
                formSubmittedCount={formSubmittedCount}
                inputProps={fragment && { isDisabled: !!fragment }}
                useDefaultMargin={false}
              />
            </div>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.MEDICAL_DEVICE)} formSubmittedCount={formSubmittedCount} />
            <div className="wounds__type">
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.WOUND_TYPE)}
                formSubmittedCount={formSubmittedCount}
                inputProps={fragment && { isDisabled: !!fragment }}
                useDefaultMargin={false}
              />
            </div>
            <div className="u-els-margin-top-1o2">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.FOOT_NOTE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </div>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex left middle>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.LENGTH)} formSubmittedCount={formSubmittedCount} />
              <span className="u-els-margin-left-1o2">cm</span>
            </ELSFlex>
            <ELSFlex left middle>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.WIDTH)} formSubmittedCount={formSubmittedCount} />
              <span className="u-els-margin-left-1o2">cm</span>
            </ELSFlex>
            <ELSFlex left middle>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.DEPTH)} formSubmittedCount={formSubmittedCount} />
              <span className="u-els-margin-left-1o2">cm</span>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x wounds__sections-container" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h4>{SectionTitle.WOUND_AREA}</h4>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.WOUND_AREA)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
            <h4>{SectionTitle.PERIWOUND_AREA}</h4>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.PERIWOUND_AREA)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
            <h4>{SectionTitle.SOLUTION_USED}</h4>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.SOLUTION_USED)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.SOLUTION_USED_OTHER)} formSubmittedCount={formSubmittedCount} />
            <h4>{SectionTitle.PATIENT_RESPONSE}</h4>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h4>{SectionTitle.WOUND_DRAINAGE_EXUDATE}</h4>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.WOUND_DRAINAGE_EXUDATE)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ disableEmptyDropdown: true }}
            />
            <h4>{SectionTitle.DRESSING_WOUND_CARE}</h4>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.DRESSING_WOUND_CARE)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ disableEmptyDropdown: true }}
            />
            <h4>{SectionTitle.INTERVENTIONS}</h4>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.INTERVENTIONS)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={chartMetaFormFields.get(FormField.NOTES)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

WoundsView.displayName = 'WoundsView';
export default WoundsView;

import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { GenderPatientContext } from 'models/enum';
import { PatientContext } from 'models/ui';
import { Locales, WOMAN_HEALTH_MIN_AGE_IN_DAYS } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface AdmissionDataReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
}

const AdmissionDataReadOnly = (props: AdmissionDataReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext } = props;
  const isWomenHealth = patientContext?.ageInDays >= WOMAN_HEALTH_MIN_AGE_IN_DAYS && patientContext?.gender === GenderPatientContext.FEMALE;
  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>{LanguageKeys.ADMISSION_DATA.ADMISSION_DATA}</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>{LanguageKeys.ADMISSION_DATA.CHART_OMITTED}</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              {patientContext?.locale === Locales.EN_AU && (
                <>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS}
                      formFieldId={FormField.CONFIRM_IDENTIFICATION}
                      customTitle={LanguageKeys.ADMISSION_DATA.CONFIRM_IDENTIFICATION}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS}
                      formFieldId={FormField.NEXT_OF_KIN_NAME}
                      customTitle={LanguageKeys.ADMISSION_DATA.NAME}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS}
                      formFieldId={FormField.NEXT_OF_KIN_REL_ORG}
                      customTitle={LanguageKeys.ADMISSION_DATA.REL_ORG}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS}
                      formFieldId={FormField.NEXT_OF_KIN_PHONE}
                      customTitle={LanguageKeys.ADMISSION_DATA.PHONE}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.NEXT_OF_KIN_DETAILS}
                      formFieldId={FormField.NEXT_OF_KIN_AWARE_OF_ADMISSION}
                      customTitle={LanguageKeys.ADMISSION_DATA.NOK_AWARE_OF_ADMISSION}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{LanguageKeys.ADMISSION_DATA.PREFERRED_NAME}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.PREFERRED_NAME}
                      formFieldId={FormField.NEXT_OF_KIN_PREFERRED_NAME}
                      customTitle={LanguageKeys.ADMISSION_DATA.PREFERRED_NAME}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.PREFERRED_NAME}
                      formFieldId={FormField.NEXT_OF_KIN_PREFERRED_PRONOUNS}
                      customTitle={LanguageKeys.ADMISSION_DATA.PREFERRED_PRONOUNS}
                    />
                  </ELSFlexItem>

                  <ELSFlexItem w="1o3" md="1o1">
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.ADVANCED_CARE_PLANNING}
                      formFieldId={FormField.NEXT_OF_KIN_ADVANCED_CARE_PLANNING}
                      customTitle={LanguageKeys.ADMISSION_DATA.ADVANCED_CARE_PLANNING}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_OF_ATSI}
                      formFieldId={FormField.NEXT_OF_KIN_IS_PATIENT_ATSI}
                      customTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_OF_ATSI}
                    />
                  </ELSFlexItem>

                  <ELSFlexItem w="1o3" md="1o1">
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_DOES_PATIENT_HAVE_A_CARER}
                      customTitle={LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_CARER_NAME}
                      customTitle={LanguageKeys.ADMISSION_DATA.NAME}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_CARER_REL_ORG}
                      customTitle={LanguageKeys.ADMISSION_DATA.REL_ORG}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.DOES_PATIENT_HAVE_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_CARER_PHONE}
                      customTitle={LanguageKeys.ADMISSION_DATA.PHONE}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_IS_PATIENT_A_CARER}
                      customTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_NAME}
                      customTitle={LanguageKeys.ADMISSION_DATA.NAME}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_REL_ORG}
                      customTitle={LanguageKeys.ADMISSION_DATA.REL_ORG}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.IS_PATIENT_A_CARER}
                      formFieldId={FormField.NEXT_OF_KIN_PATIENT_CARE_FOR_PHONE}
                      customTitle={LanguageKeys.ADMISSION_DATA.PHONE}
                    />
                  </ELSFlexItem>
                </>
              )}
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}</h3>
                {patientContext?.locale === Locales.EN_AU && (
                  <>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}
                      formFieldId={FormField.ADMISSION_DATE}
                      customTitle={LanguageKeys.ADMISSION_DATA.ADMISSION_DATE}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}
                      formFieldId={FormField.ESTIMATED_DISCHARGE_DATE}
                      customTitle={LanguageKeys.ADMISSION_DATA.ESTIMATED_DISCHARGE_DATE}
                    />
                  </>
                )}
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}
                  formFieldId={FormField.ADMISSION_ADMITTED_FROM}
                  customTitle={LanguageKeys.ADMISSION_DATA.ADMITTED_FROM}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}
                  formFieldId={FormField.ADMISSION_ADMITTED_FROM_OTHER}
                  customTitle={LanguageKeys.ADMISSION_DATA.OTHER}
                  notRenderBlankField
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}
                  formFieldId={FormField.ADMISSION_ARRIVED_BY}
                  customTitle={LanguageKeys.ADMISSION_DATA.ARRIVED_BY}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.THIS_ADMISSION}
                  formFieldId={FormField.ADMISSION_ARRIVED_BY_OTHER}
                  customTitle={LanguageKeys.ADMISSION_DATA.OTHER}
                  notRenderBlankField
                />
              </ELSFlexItem>
              {isWomenHealth && (
                <ELSFlexItem w="1o3" md="1o1">
                  <h3>{LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}</h3>
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.PREGNANCY_RELATED}
                    customTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.PREGNANCY_RELATED_SELECT}
                    customTitle=""
                  />
                  <ReadOnlyInput chartFragment={chartFragment} sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED} formFieldId={FormField.INDUCTION} customTitle="" />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.PREGNANCY_RELATED_SELECT_OTHER}
                    customTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED_SELECT_OTHER}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.CONTRACTIONS_TIME_OF_ONSET}
                    customTitle={LanguageKeys.ADMISSION_DATA.CONTRACTIONS_TIME_OF_ONSET}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.CONTRACTIONS_FREQUENCY}
                    customTitle={LanguageKeys.ADMISSION_DATA.CONTRACTIONS_FREQUENCY}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.CONTRACTIONS_DURATION}
                    customTitle={LanguageKeys.ADMISSION_DATA.CONTRACTIONS_DURATION}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.CONTRACTIONS_QUALITY}
                    customTitle={LanguageKeys.ADMISSION_DATA.CONTRACTIONS_QUALITY}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.CONTRACTIONS_FLUID}
                    customTitle={LanguageKeys.ADMISSION_DATA.CONTRACTIONS_FLUID}
                  />
                  <ReadOnlyInput
                    chartFragment={chartFragment}
                    sectionTitle={LanguageKeys.ADMISSION_DATA.PREGNANCY_RELATED}
                    formFieldId={FormField.CONTRACTIONS_COLOR}
                    customTitle={LanguageKeys.ADMISSION_DATA.CONTRACTIONS_COLOR}
                  />
                </ELSFlexItem>
              )}

              <ELSFlexItem w="1o3" md="1o1">
                <h3>{LanguageKeys.ADMISSION_DATA.VERBAL_ADMISSION_HISTORY}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.VERBAL_ADMISSION_HISTORY}
                  formFieldId={FormField.VERBAL_ADMISSION_HISTORY}
                  customTitle=""
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{LanguageKeys.ADMISSION_DATA.PAST_ADMISSION}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.PAST_ADMISSION}
                  formFieldId={FormField.PAST_ADMISSION_HOSPITAL}
                  customTitle={LanguageKeys.ADMISSION_DATA.LAST_HOSPITAL_ADMISSION}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.PAST_ADMISSION}
                  formFieldId={FormField.PAST_ADMISSION_REASON}
                  customTitle={LanguageKeys.ADMISSION_DATA.REASON}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.PAST_ADMISSION}
                  formFieldId={FormField.PAST_ADMISSION_WHERE}
                  customTitle={LanguageKeys.ADMISSION_DATA.WHERE}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{LanguageKeys.ADMISSION_DATA.INFORMANT_HISTORIAN}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.INFORMANT_HISTORIAN}
                  formFieldId={FormField.INFORMANT_HISTORIAN_INFORMATION_FROM}
                  customTitle={LanguageKeys.ADMISSION_DATA.INFORMATION_RECEIVED_FROM}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={LanguageKeys.ADMISSION_DATA.INFORMANT_HISTORIAN}
                  formFieldId={FormField.INFORMANT_HISTORIAN_NAME}
                  customTitle={LanguageKeys.ADMISSION_DATA.NAME}
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

AdmissionDataReadOnly.displayName = 'AdmissionDataReadOnly';
export default AdmissionDataReadOnly;

import moment, { Moment } from 'moment';
import { ChartFragment } from 'models/api-response';
import { MarDosageRecord } from 'models/ui';
import { DateFormatByLocale, DateTimeFormatByLocale, Locales, MOMENT_UNIT_DAYS, MOMENT_UNIT_MINUTES, TIME_FORMAT } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { toMomentWithFormat } from 'helpers/datetime.helper';
import { FormField as PharmacyFormField } from 'components/features/chart/order-entry/pharmacy/constants';
import { FormField, ORDER_TYPES, SPECIAL_DOSAGE_MAPPING_RULES } from './constants';
import { DosageTimeMap } from './dosageTimesMapping';

type HandleFuncInput = {
  startDate: Moment;
  endDateTime: Moment;
  dosageTimes: MarDosageRecord['dosageTimes'];
  isInValidRange: Function;
  dateTimeBetween: string[];
  locale: Locales;
  repeatTimes?: number;
  minutesApart?: number;
  isTopOfTheHour?: boolean;
  frequency?: string;
  minutesAfterStartTime?: number;
  isIgnored?: Function;
  getDosageTimeByFrequency?: Function;
  isStop?: Function;
  getDosageTimeByAfterStartTime?: Function;
  getDosageTimeObject?: Function;
  getAdministeredDosageTimes?: Function;
  getDosageTimeByAfterFirstDose?: Function;
  getDosageTimeByTimesInAWeek?: Function;
};

export const handleAfterStartTime = ({
  startDate,
  isTopOfTheHour,
  frequency,
  isInValidRange,
  endDateTime,
  dosageTimes,
  minutesAfterStartTime,
  repeatTimes,
  dateTimeBetween,
  locale,
  isStop,
  getDosageTimeByAfterStartTime
}: HandleFuncInput): void => {
  for (let indexDateTime = startDate.clone(), index = 0; indexDateTime <= endDateTime; indexDateTime.add(1, MOMENT_UNIT_DAYS), index += 1) {
    if (isStop(repeatTimes, index)) {
      break;
    }
    const dosageDate = indexDateTime.format(DateFormatByLocale[locale]);
    const dosageTime = isTopOfTheHour ? '00:00' : indexDateTime.format(TIME_FORMAT);
    const firstOffset = toMomentWithFormat(`${dosageDate} ${dosageTime}`, Object.values(DateTimeFormatByLocale))
      .add(minutesAfterStartTime, MOMENT_UNIT_MINUTES)
      .format(DateTimeFormatByLocale[locale]);
    const dosageTimeObject = getDosageTimeByAfterStartTime(dosageTimes, firstOffset, frequency, isInValidRange);
    dateTimeBetween.push(...dosageTimeObject);
  }
};

export const handleAfterFirstDose = ({
  dosageTimes,
  dateTimeBetween,
  startDate,
  repeatTimes,
  locale,
  isStop,
  isInValidRange,
  endDateTime,
  minutesApart,
  getDosageTimeObject,
  getAdministeredDosageTimes,
  getDosageTimeByAfterFirstDose
}: HandleFuncInput): void => {
  const allAdministeredDosages = getAdministeredDosageTimes(dosageTimes) ?? [];
  for (let indexDateTime = startDate.clone(); indexDateTime <= endDateTime; indexDateTime.add(1, MOMENT_UNIT_DAYS)) {
    dateTimeBetween.push(getDosageTimeObject(indexDateTime.format(DateFormatByLocale[locale]), 'Administer'));
  }
  if (!dosageTimes || dosageTimes.length === 0) {
    return;
  }

  const firstDosage = allAdministeredDosages[0];
  allAdministeredDosages.forEach((t) => dateTimeBetween.push(t));
  let offset = 0;

  for (
    let indexDateTime = toMomentWithFormat(firstDosage.administeredDate, Object.values(DateTimeFormatByLocale)).clone(), index = 0;
    indexDateTime <= endDateTime;
    indexDateTime.add(1, MOMENT_UNIT_DAYS), index += 1
  ) {
    if (isStop(repeatTimes, index)) {
      break;
    }
    const timeEntries = [];
    const dosageDate = indexDateTime.format(DateFormatByLocale[locale]);

    const latestTimeOfDay = toMomentWithFormat(`${dosageDate} 00:00`, Object.values(DateTimeFormatByLocale)).add(1, MOMENT_UNIT_DAYS);
    let dosageItem;

    do {
      dosageItem = toMomentWithFormat(`${firstDosage.administeredDate} ${firstDosage.administeredTime}`, Object.values(DateTimeFormatByLocale))
        .add(offset * minutesApart, MOMENT_UNIT_MINUTES)
        .format(DateTimeFormatByLocale[locale]);
      offset += 1;
      if (toMomentWithFormat(dosageItem, Object.values(DateTimeFormatByLocale)).isSameOrBefore(latestTimeOfDay)) {
        timeEntries.push(dosageItem);
      } else {
        offset -= 1;
      }
    } while (toMomentWithFormat(dosageItem, Object.values(DateTimeFormatByLocale)).isSameOrBefore(latestTimeOfDay) && offset <= repeatTimes);
    const dosageTimeObject = getDosageTimeByAfterFirstDose(timeEntries, dosageTimes, isInValidRange);
    dateTimeBetween.push(...dosageTimeObject);
  }
};

export const handleTimesInAWeek = ({
  startDate,
  endDateTime,
  dosageTimes,
  frequency,
  isInValidRange,
  dateTimeBetween,
  locale,
  getDosageTimeByTimesInAWeek
}: HandleFuncInput): void => {
  for (let indexDateTime = startDate.clone(); indexDateTime <= endDateTime; indexDateTime.add(1, MOMENT_UNIT_DAYS)) {
    const dosageDate = indexDateTime.format(DateFormatByLocale[locale]);
    const dosageTime = indexDateTime.format(TIME_FORMAT);
    const dosageTimeObject = getDosageTimeByTimesInAWeek(dosageTimes, frequency, dosageDate, dosageTime, isInValidRange);
    dateTimeBetween.push(...dosageTimeObject);
  }
};

export const handleNormal = ({ startDate, endDateTime, dosageTimes, frequency, isInValidRange, dateTimeBetween, locale, getDosageTimeByFrequency }: HandleFuncInput): void => {
  for (let indexDateTime = startDate.clone(); indexDateTime <= endDateTime; indexDateTime.add(1, MOMENT_UNIT_DAYS)) {
    const dosageDate = indexDateTime.format(DateFormatByLocale[locale]);
    const dosageTime = indexDateTime.format(TIME_FORMAT);
    const dosageTimeObject = getDosageTimeByFrequency(dosageTimes, frequency, dosageDate, dosageTime, isInValidRange);
    dateTimeBetween.push(...dosageTimeObject);
  }
};

export const handleOnceThenDropOff = ({
  startDate,
  dosageTimes,
  dateTimeBetween,
  endDateTime,
  frequency,
  isInValidRange,
  locale,
  getAdministeredDosageTimes,
  isIgnored,
  getDosageTimeByFrequency
}: HandleFuncInput): void => {
  const administerDosageTimes = getAdministeredDosageTimes(dosageTimes);
  // if we have an administered dose then we are done.
  if (administerDosageTimes.length > 0) {
    dateTimeBetween.push(...administerDosageTimes);
  } else {
    for (let indexDateTime = startDate.clone(), index = 1; indexDateTime <= endDateTime; indexDateTime.add(1, MOMENT_UNIT_DAYS), index += 1) {
      const dosageDate = indexDateTime.format(DateFormatByLocale[locale]);
      const dosageTime = indexDateTime.format(TIME_FORMAT);
      if (administerDosageTimes.length === 0 || isIgnored(administerDosageTimes, dosageDate) !== undefined) {
        const dosageTimeObject = getDosageTimeByFrequency(dosageTimes, frequency, dosageDate, dosageTime, isInValidRange);
        dateTimeBetween.push(...dosageTimeObject);
      }
    }
  }
};

export const getPharmacyDataAndAdministeredDosageTimes = (fragments: ChartFragment[], locale: Locales, isAuthor: boolean) => {
  const { getOrderDescription } = chartHelper;
  const administeredDosageTimes = [];
  const pharmacyData = fragments.map((item) => {
    const { chartData } = item;
    const dose = chartData.specialDoseDose ? `${chartData.specialDoseDose} ${chartData.specialDoseUnit}` : chartData.medicationDoseTextbox || chartData.medicationDose;
    let { orderStartDate, orderStartTime, orderStopDate, orderStopTime } = chartData;
    const { dosageTimes = [] } = chartData;
    if (isAuthor) {
      // create a dummy start/stop date/time for author flow
      const currentTime = moment();
      orderStartDate = currentTime.format(DateFormatByLocale[locale]);
      orderStartTime = '00:00';
      orderStopDate = currentTime.format(DateFormatByLocale[locale]);
      orderStopTime = '23:59';
    }
    administeredDosageTimes.push(...dosageTimes);
    return {
      fragmentId: item.fragmentId,
      drugName: getOrderDescription(item),
      orderType: chartData.orderType,
      orderStartDate,
      orderStartTime,
      orderStopDate,
      orderStopTime,
      [PharmacyFormField.HAS_STOP_DATE_TIME_DEFAULT]: chartData[PharmacyFormField.HAS_STOP_DATE_TIME_DEFAULT],
      holdDate: chartData[FormField.HOLD_DATE] || orderStartDate,
      holdTime: chartData[FormField.HOLD_TIME] || '00:00',
      holdComments: chartData.holdComments,
      holdFirstInitial: chartData.holdFirstInitial,
      holdLastInitial: chartData.holdLastInitial,
      isHold: chartData.isHold,
      discontinueDate: chartData[FormField.DISCONTINUE_DATE] || orderStartDate,
      discontinueTime: chartData[FormField.DISCONTINUE_TIME] || '00:00',
      discontinueComments: chartData.discontinueComments,
      discontinueFirstInitial: chartData.discontinueFirstInitial,
      discontinueLastInitial: chartData.discontinueLastInitial,
      isDiscontinued: chartData.isDiscontinued,
      orderStart: `${orderStartDate} ${orderStartTime}`.trim(),
      orderStop: `${orderStopDate} ${orderStopTime}`.trim(),
      dose,
      route: chartData.routeForAdministration,
      frequency: chartData.frequencyOfAdministration,
      specialInstructions: chartData.specialInstructions,
      dosageTimes: chartData.dosageTimes === undefined ? [] : chartData.dosageTimes
    };
  });

  return { pharmacyData, administeredDosageTimes };
};

export const reorganizePharmacyDataToRecords = (pharmacyData, getDosageTime: Function, getInactiveOrdersType: Function) => {
  const statRecords = [];
  const continuousIVRecords = [];
  const scheduledRecords = [];
  const prnRecords = [];
  const inactiveOrderRecords = [];
  const firstMed = pharmacyData?.[0];
  let viewDateStart = toMomentWithFormat(firstMed?.orderStartDate, Object.values(DateTimeFormatByLocale));
  let viewDateStop = toMomentWithFormat(firstMed?.orderStopDate, Object.values(DateTimeFormatByLocale));
  pharmacyData.forEach((item) => {
    if (toMomentWithFormat(item.orderStartDate, Object.values(DateTimeFormatByLocale)).isBefore(viewDateStart)) {
      viewDateStart = toMomentWithFormat(item.orderStartDate, Object.values(DateTimeFormatByLocale));
    }
    if (toMomentWithFormat(item.orderStopDate, Object.values(DateTimeFormatByLocale)).isAfter(viewDateStop)) {
      viewDateStop = toMomentWithFormat(item.orderStopDate, Object.values(DateTimeFormatByLocale));
    }
    const defaultDosageTime = getDosageTime(item.dosageTimes, item.frequency, item.orderStartDate, item.orderStopDate);
    const inActiveResult = getInactiveOrdersType(item);
    const newItem = { ...item, ...inActiveResult, dosageTimes: defaultDosageTime };
    if (newItem.inactiveType || newItem.status) {
      inactiveOrderRecords.push(newItem);
    } else if (newItem.route === 'IV Infusion') {
      continuousIVRecords.push(newItem);
    } else if (newItem.orderType === ORDER_TYPES.STAT) {
      statRecords.push(newItem);
    } else if (newItem.orderType === 'Routine' || newItem.orderType === 'Custom') {
      scheduledRecords.push(newItem);
    } else if (newItem.orderType === ORDER_TYPES.PRN) {
      prnRecords.push(newItem);
    }
  });

  return {
    statRecords,
    continuousIVRecords,
    scheduledRecords,
    prnRecords,
    inactiveOrderRecords,
    viewDateStart,
    viewDateStop
  };
};

export const getIsShowAdmin = (
  dosageTimesMapping: DosageTimeMap,
  frequency: string,
  dosageTimes,
  hideAdministration: boolean,
  isInactiveOrder: boolean,
  isAnyAdministered: boolean
): boolean => {
  let showAdmin = false;
  const mapping = dosageTimesMapping.get(frequency);
  if (mapping) {
    // mapping, but not times (usually continuous IV)
    if (dosageTimes.length === 0) {
      showAdmin = true;
    } else {
      // if there any dosageTimes that are administer, show it
      dosageTimes.forEach((dt) => {
        if (dt.dosageTime === 'Administer') {
          showAdmin = true;
        }
      });
    }
    // if we dont have a mapping, show it
  } else {
    showAdmin = true;
  }
  // if the props say show it , show it
  showAdmin = showAdmin || !hideAdministration;
  // hide it if it is an inactive order
  showAdmin = showAdmin && !isInactiveOrder;
  if (isAnyAdministered && mapping?.type === SPECIAL_DOSAGE_MAPPING_RULES.AFTER_FIRST_DOSE && !mapping?.administrationTimes) showAdmin = false;
  return showAdmin;
};

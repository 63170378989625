import { camelCase } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { ChartFragmentRS, LaboratoryTestFragment, LaboratoryTestResult } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps } from 'models/ui';
import { withChartLogic } from 'components/common';
import EditLaboratoryTestSidebarView, { EditLaboratoryTestSidebarViewProps } from './EditLaboratoryTestSidebarView';
import { FormField, buildFormFields } from './LaboratoryTest';
import { TestDateError } from './LaboratoryTestConstants';

export interface EditLaboratoryTestSidebarProps extends ChartComponentProps {
  selectedNavId: string;
  laboratoryTest: LaboratoryTestFragment;
  onCloseClick: Function;
  onSaveClick: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}
interface EditLaboratoryTestSidebarState {
  [FormField.LAB_TEST]: string;
  labResults: LaboratoryTestResult[];
}

class EditLaboratoryTestSidebar extends Component<EditLaboratoryTestSidebarProps, EditLaboratoryTestSidebarState> {
  static displayName = 'EditLaboratoryTestSidebar';

  formFieldMapDisplayError;

  constructor(props) {
    super(props);
    this.state = {
      [FormField.LAB_TEST]: '',
      labResults: []
    };
    this.formFieldMapDisplayError = new Map();
  }

  componentDidUpdate(prevProps: Readonly<EditLaboratoryTestSidebarProps>, prevState: Readonly<EditLaboratoryTestSidebarState>) {
    if (this.props.laboratoryTest && prevProps.laboratoryTest !== this.props.laboratoryTest) {
      const { chartData } = this.props.laboratoryTest;
      this.props.initState(buildFormFields(this.props.laboratoryTest, false));
      this.formFieldMapDisplayError = new Map(this.props.formFieldMap);
      this.setState(
        {
          [FormField.LAB_TEST]: chartData[FormField.LAB_TEST],
          labResults: chartData?.tests
        },
        () => this.handleTestDateError()
      );
    }

    if (prevState.labResults.length > 0 && prevState.labResults !== this.state.labResults) {
      this.formFieldMapDisplayError = new Map(this.props.formFieldMap);
      this.state.labResults.forEach((labResult: LaboratoryTestResult) => {
        const errors = !labResult.dateOfTestResult ? TestDateError : [];

        this.formFieldMapDisplayError.set(labResult.name, {
          label: labResult.name,
          value: labResult.dateOfTestResult,
          name: camelCase(labResult.name),
          errorLabel: `${labResult.name} date of test result`,
          disabled: false,
          inputType: FormFieldInputType.DATE,
          errors,
          warning: []
        });
      });
    }
  }

  handleLabTestResultChange = (labResult: LaboratoryTestResult) => {
    this.setState((prevState) => {
      const labResults = prevState.labResults.map((item) => {
        if (item.id !== labResult.id) {
          return item;
        }
        return {
          ...item,
          ...labResult,
          updatedAt: moment().toISOString()
        };
      });
      return { labResults };
    });
  };

  handleTestDateError = () => {
    this.setState((prevState) => {
      const newLabTestResults = prevState.labResults.map((labResult) => ({ ...labResult, hasTestDateError: !labResult.dateOfTestResult }));
      return { labResults: newLabTestResults };
    });
  };

  handleChartSave = () => {
    const { formFieldMap, onSaveClick, handleSaveClick } = this.props;
    const updatedLaboratoryTest: LaboratoryTestFragment = {
      ...this.props.laboratoryTest,
      chartData: {
        ...this.props.laboratoryTest.chartData,
        [FormField.DATE_OF_TEST]: formFieldMap.get(FormField.DATE_OF_TEST).value,
        [FormField.DEFINITION_DESCRIPTION]: formFieldMap.get(FormField.DEFINITION_DESCRIPTION).value,
        [FormField.SIGNIFICANCE]: formFieldMap.get(FormField.SIGNIFICANCE).value,
        tests: this.state.labResults
      }
    };
    handleSaveClick([updatedLaboratoryTest], { formFieldMap: this.formFieldMapDisplayError, afterSave: onSaveClick });
  };

  handleSaveClick = () => {
    this.setState((prevState) => {
      const newLabTestResults = prevState.labResults.map((labResult) => ({ ...labResult, hasTestDateError: !labResult.dateOfTestResult }));
      return { labResults: newLabTestResults };
    }, this.handleChartSave);
  };

  render() {
    const { formFieldMap, formSubmittedCount, sidebarProps, chartMetadata } = this.props;
    const viewProps: EditLaboratoryTestSidebarViewProps = {
      open: !!this.props.laboratoryTest,
      formSubmittedCount,
      [FormField.LAB_TEST]: this.state[FormField.LAB_TEST],
      chartMetaFormFields: formFieldMap,
      labResults: this.state.labResults,
      onSaveClick: this.handleSaveClick,
      onCloseClick: this.props.onCloseClick,
      onLabTestResultChange: this.handleLabTestResultChange,
      sidebarProps,
      chartMetadata
    };
    return <EditLaboratoryTestSidebarView {...viewProps} />;
  }
}

export { EditLaboratoryTestSidebar as BaseEditLaboratoryTestSidebar };
export default withChartLogic(EditLaboratoryTestSidebar);

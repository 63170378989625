import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';

export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'assessmentNotRequired',
  HAVE_PAIN = 'havePain',
  LOCATION_OF_PAIN = 'locationOfPain',
  FREQUENCY_OF_PAIN = 'frequencyOfPain',
  PCA_PUMP_IN_USE = 'pcaPumpInUse',
  INTENSITY_OF_PAIN = 'intensityOfPain',
  INTENSITY_OF_PAIN_ON_MOVEMENT = 'intensityOfPainOnMovement',
  WONG_BAKER_SCALE = 'wongBakerScale',
  WONG_BAKER_SCALE_INTENTSITY_OF_PAIN_ON_MOVEMENT = 'wongBakerScaleIntensityOfPainOnMovement',
  NONVERBAL_PAIN_CUES = 'nonverbalPainCues',
  QUALITY_OF_PAIN = 'qualityOfPain',
  QUALITY_OF_PAIN_INFO = 'qualityOfPainInfo',
  RELIEVING_FACTORS = 'relievingFactors',
  RELIEVING_FACTORS_INFO = 'relievingFactorsInfo',
  PHYSIO_PAIN_CUES = 'physioPainCues',
  AGGRAVATING_FACTORS = 'aggravatingFactors',
  AGGRAVATING_FACTORS_INFO = 'aggravatingFactorsInfo'
}

export const getPainAssessmentFormFieldBuilderItems = (locale: Locales): ChartMetaFormFieldParams[] => [
  {
    name: FormField.ASSESSMENT_NOT_REQUIRED,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.PCA_PUMP_IN_USE,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.PHYSIO_PAIN_CUES,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.NONVERBAL_PAIN_CUES,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.QUALITY_OF_PAIN,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.RELIEVING_FACTORS,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.AGGRAVATING_FACTORS,
    type: FormFieldInputType.MULTISELECT_DROPDOWN
  },
  {
    name: FormField.HAVE_PAIN,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.DO_YOU_HAVE_PAIN_NOW
  },
  {
    name: FormField.FREQUENCY_OF_PAIN,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.FREQUENCY_OF_PAIN
  },
  {
    name: FormField.INTENSITY_OF_PAIN,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTENSITY_OF_PAIN
  },
  {
    name: FormField.INTENSITY_OF_PAIN_ON_MOVEMENT,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTENSITY_OF_PAIN_ON_MOVEMENT,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.WONG_BAKER_SCALE,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTENSITY_OF_PAIN
  },
  {
    name: FormField.WONG_BAKER_SCALE_INTENTSITY_OF_PAIN_ON_MOVEMENT,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTENSITY_OF_PAIN_ON_MOVEMENT,
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.LOCATION_OF_PAIN,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.LOCATION_OF_PAIN_W_COLON
  },
  {
    name: FormField.QUALITY_OF_PAIN_INFO,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.MORE_INFORMATION
  },
  {
    name: FormField.RELIEVING_FACTORS_INFO,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.MORE_INFORMATION
  },
  {
    name: FormField.AGGRAVATING_FACTORS_INFO,
    type: FormFieldInputType.TEXT_AREA,
    label: LanguageKeys.MORE_INFORMATION
  }
];

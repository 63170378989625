import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, SectionTitle } from './constants';
import AlcoholScreenView from './AlcoholScreenView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface AlcoholScreenProps extends ChartComponentProps {
  patientContext: PatientContext;
}
interface AlcoholScreenState {
  isLocked: boolean;
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class AlcoholScreen extends Component<AlcoholScreenProps, AlcoholScreenState> {
  static displayName = 'AlcoholScreen';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.ALCOHOL_SCREEN, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentValue, getFragmentContentIds } = chartHelper;
    const dataMap = new Map();
    dataMap.set(
      FormField.DRINK_ALCOHOL,
      createFormField({
        name: FormField.DRINK_ALCOHOL,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Do you drink alcohol?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.ALCOHOL_SCREEN, FormField.DRINK_ALCOHOL)
      })
    );
    dataMap.set(
      FormField.HOW_OFTEN_DRINK,
      createFormField({
        name: FormField.HOW_OFTEN_DRINK,
        type: FormFieldInputType.DROPDOWN,
        label: 'How often do you drink?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.DRINKING, FormField.HOW_OFTEN_DRINK)
      })
    );
    dataMap.set(
      FormField.HOW_OFTEN_DRINK_OTHER,
      createFormField({
        name: FormField.HOW_OFTEN_DRINK_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.DRINKING, FormField.HOW_OFTEN_DRINK_OTHER)
      })
    );
    dataMap.set(
      FormField.HOW_LONG_DRINK,
      createFormField({
        name: FormField.HOW_LONG_DRINK,
        type: FormFieldInputType.DROPDOWN,
        label: 'How long have you been drinking?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.DRINKING, FormField.HOW_LONG_DRINK)
      })
    );
    dataMap.set(
      FormField.HOW_LONG_DRINK_OTHER,
      createFormField({
        name: FormField.HOW_LONG_DRINK_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.DRINKING, FormField.HOW_LONG_DRINK_OTHER)
      })
    );
    dataMap.set(
      FormField.WHEN_LAST_DRINK,
      createFormField({
        name: FormField.WHEN_LAST_DRINK,
        type: FormFieldInputType.DROPDOWN,
        label: 'When was your last drink?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.DRINKING, FormField.WHEN_LAST_DRINK)
      })
    );
    dataMap.set(
      FormField.WHEN_LAST_DRINK_OTHER,
      createFormField({
        name: FormField.WHEN_LAST_DRINK_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.DRINKING, FormField.WHEN_LAST_DRINK_OTHER)
      })
    );
    dataMap.set(
      FormField.REASON_BREAKING,
      createFormField({
        name: FormField.REASON_BREAKING,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Possible reasons for breaking sober period',
        value: getFragmentValue(fragment, SectionTitle.SOBRIETY, FormField.REASON_BREAKING)
      })
    );
    dataMap.set(
      FormField.BEGINNING_DATE_LONGEST_PERIOD,
      createFormField({
        name: FormField.BEGINNING_DATE_LONGEST_PERIOD,
        type: FormFieldInputType.DATE,
        value: getFragmentValue(fragment, SectionTitle.SOBRIETY, FormField.BEGINNING_DATE_LONGEST_PERIOD)
      })
    );
    dataMap.set(
      FormField.END_DATE_LONGEST_PERIOD,
      createFormField({
        name: FormField.END_DATE_LONGEST_PERIOD,
        type: FormFieldInputType.DATE,
        value: getFragmentValue(fragment, SectionTitle.SOBRIETY, FormField.END_DATE_LONGEST_PERIOD)
      })
    );
    dataMap.set(
      FormField.BEGINNING_DATE_MOST_RECENT,
      createFormField({
        name: FormField.BEGINNING_DATE_MOST_RECENT,
        type: FormFieldInputType.DATE,
        value: getFragmentValue(fragment, SectionTitle.SOBRIETY, FormField.BEGINNING_DATE_MOST_RECENT)
      })
    );
    dataMap.set(
      FormField.END_DATE_MOST_RECENT,
      createFormField({
        name: FormField.END_DATE_MOST_RECENT,
        type: FormFieldInputType.DATE,
        value: getFragmentValue(fragment, SectionTitle.SOBRIETY, FormField.END_DATE_MOST_RECENT)
      })
    );
    dataMap.set(
      FormField.HELP_MAINTAIN_SOBRIETY,
      createFormField({
        name: FormField.HELP_MAINTAIN_SOBRIETY,
        type: FormFieldInputType.CHECK_BOX,
        label: 'What helped you maintain sobriety?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.SOBRIETY, FormField.HELP_MAINTAIN_SOBRIETY)
      })
    );
    dataMap.set(
      FormField.HELP_MAINTAIN_SOBRIETY_OTHER,
      createFormField({
        name: FormField.HELP_MAINTAIN_SOBRIETY_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.SOBRIETY, FormField.HELP_MAINTAIN_SOBRIETY_OTHER)
      })
    );
    dataMap.set(
      FormField.HISTORY_ALCOHOL_WITHDRAWAL,
      createFormField({
        name: FormField.HISTORY_ALCOHOL_WITHDRAWAL,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Do you have a history of alcohol withdrawal?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.WITHDRAWAL, FormField.HISTORY_ALCOHOL_WITHDRAWAL)
      })
    );
    dataMap.set(
      FormField.CAGE_QUESTIONNAIRE,
      createFormField({
        name: FormField.CAGE_QUESTIONNAIRE,
        type: FormFieldInputType.MULTISELECT_DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.CAGE_QUESTIONNAIRE, FormField.CAGE_QUESTIONNAIRE)
      })
    );
    dataMap.set(
      FormField.CAGE_QUESTIONNAIRE_CONTAINER,
      createFormField({
        name: FormField.CAGE_QUESTIONNAIRE_CONTAINER,
        type: FormFieldInputType.CONTAINER
      })
    );
    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Alcohol Screen';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          parentSectionTitle: SectionTitle.ALCOHOL_SCREEN,
          sectionTitle: SectionTitle.ALCOHOL_SCREEN,
          records: [buildPatientRecord(formFieldMap, FormField.DRINK_ALCOHOL)]
        },
        {
          parentSectionTitle: SectionTitle.ALCOHOL_CONSUMPTION_HISTORY,
          sectionTitle: SectionTitle.DRINKING,
          records: [
            buildPatientRecord(formFieldMap, FormField.HOW_OFTEN_DRINK),
            buildPatientRecord(formFieldMap, FormField.HOW_OFTEN_DRINK_OTHER),
            buildPatientRecord(formFieldMap, FormField.HOW_LONG_DRINK),
            buildPatientRecord(formFieldMap, FormField.HOW_LONG_DRINK_OTHER),
            buildPatientRecord(formFieldMap, FormField.WHEN_LAST_DRINK),
            buildPatientRecord(formFieldMap, FormField.WHEN_LAST_DRINK_OTHER)
          ]
        },
        {
          parentSectionTitle: SectionTitle.ALCOHOL_CONSUMPTION_HISTORY,
          sectionTitle: SectionTitle.SOBRIETY,
          records: [
            buildPatientRecord(formFieldMap, FormField.REASON_BREAKING),
            buildPatientRecord(formFieldMap, FormField.BEGINNING_DATE_LONGEST_PERIOD),
            buildPatientRecord(formFieldMap, FormField.END_DATE_LONGEST_PERIOD),
            buildPatientRecord(formFieldMap, FormField.BEGINNING_DATE_MOST_RECENT),
            buildPatientRecord(formFieldMap, FormField.END_DATE_MOST_RECENT),
            ...buildPatientRecords(formFieldMap, FormField.HELP_MAINTAIN_SOBRIETY),
            buildPatientRecord(formFieldMap, FormField.HELP_MAINTAIN_SOBRIETY_OTHER)
          ]
        },
        {
          parentSectionTitle: SectionTitle.ALCOHOL_CONSUMPTION_HISTORY,
          sectionTitle: SectionTitle.WITHDRAWAL,
          records: [buildPatientRecord(formFieldMap, FormField.HISTORY_ALCOHOL_WITHDRAWAL)]
        },
        {
          parentSectionTitle: SectionTitle.CAGE_QUESTIONNAIRE,
          sectionTitle: SectionTitle.CAGE_QUESTIONNAIRE,
          records: [...buildPatientRecords(formFieldMap, FormField.CAGE_QUESTIONNAIRE)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord };
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, patientContext } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      patientContext,
      chartMetaFormFields: formFieldMap
    };
    return <AlcoholScreenView {...viewProps} />;
  }
}

export { AlcoholScreen as BaseAlcoholScreen };
export default compose(withChartLogic, withAdmissionHistory)(AlcoholScreen);

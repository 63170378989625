import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import ActivityView from './ActivityView';
import { Title } from '../shared/constants';

class Activity extends Component<ChartComponentProps> {
  static displayName = 'Activity';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkBoxes = [FormField.NO_ASSESSMENT_REQUIRED];
    const multiselectDropdowns = [FormField.ACTIVITY_LIST, FormField.AMBULATION_LOCOMOTION_LIST, FormField.TURNING_RANGE_MOTION_LIST];
    const textAreas = [FormField.ACTIVITY_NOTES, FormField.AMBULATION_LOCOMOTION_NOTES, FormField.TURNING_RANGE_MOTION_NOTES];

    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;

    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ACTIVITY,
        fields: [FormField.NO_ASSESSMENT_REQUIRED],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.ACTIVITY,
        fields: [FormField.ACTIVITY_LIST, FormField.ACTIVITY_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.AMBULATION_LOCOMOTION,
        fields: [FormField.AMBULATION_LOCOMOTION_LIST, FormField.AMBULATION_LOCOMOTION_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.TURNING_RANGE_MOTION,
        fields: [FormField.TURNING_RANGE_MOTION_LIST, FormField.TURNING_RANGE_MOTION_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.BASIC_NURSING_CARE,
      fragmentTitle: SectionTitle.ACTIVITY,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as unknown as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <ActivityView {...viewProps} />;
  }
}
export { Activity as BaseActivity };
export default compose(withSavedPatientChartsPage, withChartLogic)(Activity);

import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import './musculoskeletal.assessment.scss';

const MusculoskeletalAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, resetAll, intl } = props;

  const htmlTagRenderer = (msg: string[]) => <h4 className="u-els-margin-top-2x">{msg}</h4>;

  return (
    <div className="musculoskeletal-assessment">
      <h1>{SectionTitle.MUSCULOSKELETAL_ASSESSMENT}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <Box mt>
        <h2 className="u-els-margin-top-2x">{SectionTitle.RANGE_OF_MOTION}</h2>
        <div className="u-els-margin-top-1o2">
          <ErrorFormField formField={formFieldMap.get(FormField.ROM_MOVE_ALL_EXTREMITIES)} formSubmittedCount={formSubmittedCount} />
        </div>

        <ELSFlex className="u-flex-gutter-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.LEFT_ARM)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.RIGHT_ARM)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.LEFT_LEG)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.RIGHT_LEG)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{SectionTitle.GAIT_BALANCE}</h2>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt2>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.GAIT_OR_BALANCE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
            <ErrorFormField formField={formFieldMap.get(FormField.GAIT_BALANCE_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{SectionTitle.AMPUTATION}</h2>
        <ELSFlex wrap left>
          <ELSFlexItem w="1o1" className="u-els-margin-top-1o2">
            <ErrorFormField formField={formFieldMap.get(FormField.AMPUTATION_AMPUTEE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o1" className="u-els-margin-top-1o2">
            <ErrorFormField formField={formFieldMap.get(FormField.AMPUTATION_DESCRIBE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{SectionTitle.POST_OPERATIVE_ORTHOPEDIC}</h2>
        <div className="u-els-margin-top-1o2">
          <ErrorFormField formField={formFieldMap.get(FormField.POST_ORTHOPEDIC_SURGERY)} formSubmittedCount={formSubmittedCount} />
        </div>
        <h4 className="u-els-margin-top-1o2">{SectionTitle.POST_OPERATIVE_AREA}</h4>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.POST_OPERATIVE_AREA_LEFT)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.POST_OPERATIVE_AREA_RIGHT)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </Box>
          </ELSFlexItem>
        </ELSFlex>
        <h4 className="u-els-margin-top-2x">{SectionTitle.POST_OPERATIVE_EXTREMITY_ASSESSMENT}</h4>
        <ELSFlex className="u-flex-gutter-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.POST_OPERATIVE_EA_TEMPERATURE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.POST_OPERATIVE_EA_CAPILLARY_REFILL)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.POST_OPERATIVE_EA_COLOR)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.POST_OPERATIVE_EA_SENSATION)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1" className="column-left">
            {intl.formatMessage({ id: SectionTitle.SPECIAL_TEST_SIGNS }, { htmlTag: (msg) => htmlTagRenderer(msg) })}
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="column-right">
            <h4 className="u-els-margin-top-2x">{SectionTitle.MOVEMENT_EVALUATION_OF_AFFECTED_LIMB}</h4>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="column-left">
            <ELSFlex className="u-flex-gutter-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.SPECIALIST_SIGNS_HOMAN_SIGN)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField
                  formField={formFieldMap.get(FormField.SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN)}
                  formSubmittedCount={formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="column-right">
            <ELSFlex className="u-flex-gutter-2x" wrap left>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.MOVEMENT_EVALUATION_FINGERS)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <ErrorFormField formField={formFieldMap.get(FormField.MOVEMENT_EVALUATION_TOES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.POSTOPERATIVE_ORTHOPEDIC_NOTE)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      <RelatedCharts chartIds={[NAV_ID.MUSCULOSKELETAL_INTERVENTIONS]} />
    </div>
  );
};

MusculoskeletalAssessmentView.displayName = 'MusculoskeletalAssessmentView';
export default MusculoskeletalAssessmentView;

import { orderBy } from 'lodash';
import { Component } from 'react';
import { ChartFragment, DiagnosisFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField, ContentItem } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { DIAGNOSIS_LIST_OTHER, DIAGNOSIS_TYPES, FormField } from './constants';
import DiagnosisView, { DiagnosisViewProps } from './DiagnosisView';

interface DiagnosisState {
  diagnosis: DiagnosisFragment;
  chartHistory: DiagnosisFragment[];
  hasPrimary: boolean;
  hasSecondary: boolean;
  hasTertiary: boolean;
}

export const buildFormFields = (diagnosis?: DiagnosisFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();

  dataMap.set(
    FormField.DIAGNOSIS_LIST,
    createFormField({
      name: FormField.DIAGNOSIS_LIST,
      type: FormFieldInputType.MULTI_SELECT_RADIO,
      label: 'Diagnosis'
    })
  );
  dataMap.set(
    FormField.DIAGNOSIS_OTHER,
    createFormField({
      name: FormField.DIAGNOSIS_OTHER,
      type: FormFieldInputType.TEXT_BOX,
      label: 'Other'
    })
  );
  dataMap.set(
    FormField.DIAGNOSIS_TYPE,
    createFormField({
      name: FormField.DIAGNOSIS_TYPE,
      type: FormFieldInputType.RADIO_CHOICE,
      label: 'Diagnosis Type',
      contentIds: diagnosis ? [diagnosis.chartData.typeId] : []
    })
  );
  dataMap.set(
    FormField.PATHOPHYSIOLOGY,
    createFormField({
      name: FormField.PATHOPHYSIOLOGY,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Pathophysiology',
      value: diagnosis?.chartData.pathophysiology ?? ''
    })
  );
  dataMap.set(
    FormField.THERAPEUTIC_REGIMEN,
    createFormField({
      name: FormField.THERAPEUTIC_REGIMEN,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Therapeutic Regimen',
      value: diagnosis?.chartData.therapeuticRegimen ?? ''
    })
  );
  dataMap.set(
    FormField.PROBLEMS_AND_CHANGES,
    createFormField({
      name: FormField.PROBLEMS_AND_CHANGES,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Current Health Problems and Related Functional Changes',
      value: diagnosis?.chartData.problemsAndChanges ?? ''
    })
  );
  return dataMap;
};
class Diagnosis extends Component<ChartComponentProps, DiagnosisState> {
  static displayName = 'Diagnosis';

  constructor(props) {
    super(props);
    this.state = {
      diagnosis: null,
      chartHistory: [],
      hasPrimary: false,
      hasSecondary: false,
      hasTertiary: false
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory), {
      errorMessage: 'can not load chart fragment'
    });
  }

  bindChartHistory = ({ data: fragments }) => {
    const records = orderBy(fragments, ['chartData.displayOrder', 'createdAt'], ['asc', 'desc']) as DiagnosisFragment[];
    this.setState({
      chartHistory: records,
      hasPrimary: this.findActiveRecordByType(records, DIAGNOSIS_TYPES.PRIMARY),
      hasSecondary: this.findActiveRecordByType(records, DIAGNOSIS_TYPES.SECONDARY),
      hasTertiary: this.findActiveRecordByType(records, DIAGNOSIS_TYPES.TERTIARY)
    });
  };

  findActiveRecordByType = (records: DiagnosisFragment[], type: string) => records.findIndex((record) => record.chartData.typeValue === type && record.active) !== -1;

  handleChartDelete = (diagnosis: DiagnosisFragment) => this.props.showDeleteConfirmation(() => this.confirmAndDelete(diagnosis));

  confirmAndDelete = (diagnosis: DiagnosisFragment) => {
    return appHelper.useLoader(
      this.props.saveChartData({ ...diagnosis, active: false }).then(() => this.afterSave(true)),
      { errorMessage: 'can not save chart data' }
    );
  };

  handleChartEdit = () => {
    this.afterSave(false);
    this.setState({ diagnosis: null });
  };

  handleChartSave = () => {
    const { formFieldMap, chartingTime } = this.props;
    const diagnosisListField = formFieldMap.get(FormField.DIAGNOSIS_LIST);
    const selectedNameContent = diagnosisListField.chartContent.find((contentItem) => contentItem.value === diagnosisListField.value);
    const diagnosisOtherField = formFieldMap.get(FormField.DIAGNOSIS_OTHER);
    const diagnosisTypeField = formFieldMap.get(FormField.DIAGNOSIS_TYPE);
    const selectedTypeContent = diagnosisTypeField.chartContent.find((contentItem) => contentItem.value === diagnosisTypeField.value);
    const fragment: ChartFragment = {
      ...chartService.createBaseFragment({ chartingTime }),
      chartData: {
        name: selectedNameContent?.value === DIAGNOSIS_LIST_OTHER ? diagnosisOtherField.value : selectedNameContent?.label,
        typeId: selectedTypeContent?.id,
        typeLabel: selectedTypeContent?.label,
        typeValue: selectedTypeContent?.value,
        displayOrder: selectedTypeContent?.displayOrder,
        pathophysiology: formFieldMap.get(FormField.PATHOPHYSIOLOGY).value,
        therapeuticRegimen: formFieldMap.get(FormField.THERAPEUTIC_REGIMEN).value,
        problemsAndChanges: formFieldMap.get(FormField.PROBLEMS_AND_CHANGES).value
      }
    };
    this.props.handleSaveClick([fragment], { afterSave: () => this.afterSave(false) });
  };

  afterSave = (isDelete) => {
    return this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
      .then(this.resetForm)
      .then(() => appHelper.scrollTop());
  };

  resetForm = () => {
    this.props.initState(buildFormFields());
  };

  closeEditDiagnosisSidebar = () => {
    this.setState({ diagnosis: null });
  };

  openEditDiagnosisSidebar = (editingDiagnosis) => {
    this.setState({ diagnosis: editingDiagnosis });
  };

  disableRadioContent = (radioContent: ContentItem) => {
    const { hasPrimary, hasSecondary, hasTertiary } = this.state;
    const mapping = {
      [DIAGNOSIS_TYPES.PRIMARY]: hasPrimary,
      [DIAGNOSIS_TYPES.SECONDARY]: hasSecondary,
      [DIAGNOSIS_TYPES.TERTIARY]: hasTertiary
    };
    return mapping[radioContent.value];
  };

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  render() {
    const { enableDisplayRecordsButton, hasUnsavedChanges, displayAuthoringData, handleDiscardClick, formFieldMap, formSubmittedCount, selectedNavId, saveChartData } = this.props;
    const { chartHistory, diagnosis } = this.state;

    const chartActionsProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onSaveClick: this.handleChartSave
    };
    const viewProps: DiagnosisViewProps = {
      diagnosis,
      chartActionsProps,
      chartHistory,
      chartMetaFormFields: formFieldMap,
      formSubmittedCount,
      selectedNavId,
      saveChartData,
      onDeleteDiagnosisHandler: this.handleChartDelete,
      onEditDiagnosisHandler: this.handleChartEdit,
      openEditDiagnosisSidebar: this.openEditDiagnosisSidebar,
      closeEditDiagnosisSidebar: this.closeEditDiagnosisSidebar,
      disableRadioContent: this.disableRadioContent,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <DiagnosisView {...viewProps} />;
  }
}

export { Diagnosis as BaseDiagnosis };
export default withChartLogic(Diagnosis);

import { ChartFragment, ObstetricHistoryFragment, Record } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, Headline, SectionDivider } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import ObstetricHistoryReadOnly from './ObstetricHistoryReadOnly';
import PregnancyInformationSidebar from './PregnancyInformationSidebar';
import PreviousPregnanciesHistory from './PreviousPregnanciesHistory';
import './obstetric.history.scss';

export interface ObstetricHistoryViewProps {
  isLocked: boolean;
  isOpenSidebar: boolean;
  formSubmittedCount: number;
  selectedRecord: ObstetricHistoryFragment;
  statusFragment: ChartFragment;
  pregnanciesInformation: Record;
  formFieldMap: Map<string, ChartMetaFormField>;
  pregnanciesInformationHistory: ChartFragment[];
  chartActionsProps: ChartActionsComponentProps;
  onSaveClick: Function;
  onDeleteClick: Function;
  onUpdateClick: Function;
  onCloseSidebarClick: Function;
  onAddPregnancyInformationClick: Function;
}

const ObstetricHistoryView = (props: ObstetricHistoryViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="obstetric-history">
      {!props.isLocked && (
        <>
          <Box mb2>
            <h1>{SectionTitle.OBSTETRIC_HISTORY}</h1>
          </Box>
          <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INFERTILITY)} formSubmittedCount={formSubmittedCount} />
          <SectionDivider />
          <h2>{SectionTitle.PREGNANT_HISTORY}</h2>
          <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TOTAL_PREGNANCIES)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.MATURE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ABORTION_SPONTANEOUS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.MULTIPLE_BIRTHS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TERM)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ABORTION_INDUCED)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ECTOPIC)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.LIVING_BIRTH)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <SectionDivider />
          <h2>{SectionTitle.PREVIOUS_PREGNANCIES}</h2>
          <PreviousPregnanciesHistory chartHistory={props.pregnanciesInformationHistory} onDeleteClick={props.onDeleteClick} onUpdateClick={props.onUpdateClick} />
          <ELSButton className="u-els-margin-top-2x" type="secondary" text="Add Pregnancy Information" onClick={props.onAddPregnancyInformationClick} />
          <SectionDivider />
          <h2>{SectionTitle.GENETIC_SCREENING_PERFORMED}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.GENETIC_SCREENING_PERFORMED)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.GENETIC_SCREENING_PERFORMED_OTHER)} formSubmittedCount={formSubmittedCount} />
          <SectionDivider />
          <h2>{SectionTitle.CURRENT_PREGNANCY_RELATED_PROBLEMS}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DESCRIBE_CURRENT_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
          <h2>{SectionTitle.CURRENT_PREGNANCY_PRENATAL_NOTES}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NOTES_FROM_PRENATAL_VISITS)} formSubmittedCount={formSubmittedCount} />

          <ChartPermission fragmentType={props.selectedRecord?.fragmentType}>
            {({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}
          </ChartPermission>
          <PregnancyInformationSidebar
            selectedNavId={NAV_ID.OBSTETRIC_HISTORY}
            pregnanciesInformation={props.pregnanciesInformation}
            isOpen={props.isOpenSidebar}
            onCloseClick={props.onCloseSidebarClick}
            onSaveClick={props.onSaveClick}
            {...appHelper.getChartSharedProps(props)}
          />
        </>
      )}
      {props.isLocked && (
        <>
          <h1>History and Physical</h1>
          <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
          <ObstetricHistoryReadOnly selectedRecord={props.selectedRecord} />
        </>
      )}
    </div>
  );
};

ObstetricHistoryView.displayName = 'ObstetricHistoryView';
export default ObstetricHistoryView;

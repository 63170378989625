import { difference, isNil, sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, EmbeddedChart, ScaleRollUpContent } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField as MorseFormField, Section as MorseSectionTitle } from 'components/features/chart/special-charts/morse-fall-scale/constants';
import { Title } from 'components/features/chart/system-assessment/shared/constants';
import { FormField, SectionTitle } from './constants';
import FallRiskAssessmentView, { FallRiskAssessmentViewProps } from './FallRiskAssessmentView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

const { createRollUpContent, findStatusFragment, getRecordContent, isChartLocked, createFormField, getFragmentContentIds, buildSection } = chartHelper;

interface FallRiskAssessmentProps extends ChartComponentProps {
  handleMorseClick: Function;
  assessment: AssessmentRS;
}

interface FallRiskAssessmentState {
  isLocked: boolean;
  isMorseSidebarOpen: boolean;
  embeddedMorseChart: EmbeddedChart;
  chartContent: ScaleRollUpContent;
  sidebarKey: number;
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
}

class FallRiskAssessment extends Component<FallRiskAssessmentProps, FallRiskAssessmentState> {
  static displayName = 'FallRiskAssessment';
  customFormFieldTitleMap: Map<string, string>;

  constructor(props) {
    super(props);
    this.state = {
      isMorseSidebarOpen: false,
      embeddedMorseChart: null,
      chartContent: null,
      sidebarKey: 0,
      isLocked: false,
      chartFragment: null,
      statusFragment: null
    };
    this.customFormFieldTitleMap = new Map();
    this.customFormFieldTitleMap.set(FormField.PROTOCOL_IN_EFFECT, SectionTitle.FALL_PREVENTION_PROTOCOL);
  }

  componentDidMount() {
    return this.fetchChartData();
  }

  fetchChartData = () => {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const sortedFragments = fragments && fragments.length > 0 ? sortBy(fragments, (fragment) => fragment.createdAt).reverse() : [];
        const chartFragment = sortedFragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = findStatusFragment(sortedFragments, NAV_ID.FALL_RISK_ASSESSMENT, NAV_ID.ADMISSION_HISTORY);

        const totalScore = getRecordContent<number>({
          fragment: chartFragment,
          sectionTitle: MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE,
          formField: MorseFormField.MORSE_FALL_SCALE
        });
        const embeddedMorseChartRecords =
          chartFragment && !isNil(totalScore) ? chartFragment.chartData?.records?.filter((record) => record.sectionTitle !== SectionTitle.FALL_PREVENTION_PROTOCOL) : null;
        const embeddedMorseChart = embeddedMorseChartRecords
          ? {
              payload: {
                ...chartFragment,
                chartData: { records: embeddedMorseChartRecords }
              },
              navId: NAV_ID.MORSE_FALL_SCALE
            }
          : null;

        this.setState(
          {
            chartFragment,
            statusFragment,
            chartContent: createRollUpContent(totalScore),
            isLocked: isChartLocked(fragments, this.props.selectedNavId),
            embeddedMorseChart
          },
          () => {
            this.props.initState(this.buildFormFields());
          }
        );
      }),
      { errorMessage: 'can not load chart data' }
    );
  };

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { chartFragment } = this.state;
    const dataMap = new Map<string, ChartMetaFormField>();
    dataMap.set(
      FormField.PROTOCOL_IN_EFFECT,
      createFormField({
        name: FormField.PROTOCOL_IN_EFFECT,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: getFragmentContentIds(chartFragment, SectionTitle.FALL_PREVENTION_PROTOCOL, FormField.PROTOCOL_IN_EFFECT)
      })
    );
    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { embeddedMorseChart } = this.state;
    const { customFormFieldTitleMap } = this;

    const records = embeddedMorseChart?.payload?.chartData?.records;
    let result = [buildSection({ sectionTitle: SectionTitle.FALL_PREVENTION_PROTOCOL, fields: [FormField.PROTOCOL_IN_EFFECT], formFieldMap, customFormFieldTitleMap })];

    if (!isNil(records) && records.length > 0) {
      result = [...result, ...records];
    }

    return result;
  };

  handleMorseClick = () => {
    this.setState({ isMorseSidebarOpen: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isMorseSidebarOpen: false });
  };

  handleSaveClick = () => {
    // Save Morse first and then Fall Risk
    let beforeSave = null;
    if (this.state.embeddedMorseChart) {
      const { payload, navId } = this.state.embeddedMorseChart;
      beforeSave = () => this.props.saveChartData(payload, navId);
    }
    this.props.saveChart([this.buildFragment()], { beforeSave, afterSave: this.props.afterSave, hideSaveMessage: true });
  };

  handleSaveEmbeddedChart = (payload: ChartFragment, navId: string) => {
    let totalScore;
    const totalScoreField = payload.chartData?.records?.filter((item) => item.sectionTitle === MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE);
    if (!isNil(totalScoreField[0].records[0])) {
      totalScore = totalScoreField[0].records[0].content;
    }
    this.setState({
      isMorseSidebarOpen: false,
      chartContent: createRollUpContent(totalScore),
      embeddedMorseChart: {
        payload,
        navId
      }
    });
  };

  // TODO: move this to chart helper
  getFragmentRecords = (fragment: ChartFragment, sectionTitle: string): any[] => {
    return fragment?.chartData?.records?.find((item) => item.sectionTitle === sectionTitle)?.records || [];
  };

  enableSaveButton = (): boolean => {
    const { chartFragment, embeddedMorseChart } = this.state;
    const totalScore = getRecordContent<number>({
      fragment: chartFragment,
      sectionTitle: MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE,
      formField: MorseFormField.MORSE_FALL_SCALE
    });

    const persistedRecords = this.getFragmentRecords(chartFragment, MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE);
    const newRecords = this.getFragmentRecords(embeddedMorseChart?.payload, MorseSectionTitle.TOTAL_MORSE_FALL_SCALE_SCORE);
    const recordsDiff = difference(persistedRecords, newRecords);

    return this.props.hasUnsavedChanges || (!isNil(this.state.chartContent?.total) && this.state.chartContent.total !== totalScore) || recordsDiff.length > 0;
  };

  buildFragment = () => {
    const { selectedNavId } = this.props;
    const record = {
      chartTitle: selectedNavId === NAV_ID.FALL_RISK_ASSESSMENT ? Title.SYSTEM_ASSESSMENT : Title.SPECIAL_CHARTS,
      fragmentTitle: SectionTitle.FALL_RISK_ASSESSMENT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    let linkedFragmentId = this.state.embeddedMorseChart?.payload?.fragmentId;
    // To avoid grouping Fall Risk with Morse
    linkedFragmentId = `${selectedNavId}_${linkedFragmentId}`;
    return { ...basicInfo, linkedFragmentId, chartData: cleanRecord } as ChartFragment;
  };

  handleDiscardEmbeddedChart = () => {
    this.setState((prevState) => ({
      sidebarKey: prevState.sidebarKey,
      embeddedMorseChart: null,
      chartContent: createRollUpContent(undefined)
    }));
  };

  handleCancelClick = () => {
    this.props.initState(this.buildFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { displayAuthoringData, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, handleDiscardClick } = this.props;
    const { chartContent, isMorseSidebarOpen, sidebarKey, isLocked, chartFragment, statusFragment, embeddedMorseChart } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      enableSaveButton: this.enableSaveButton(),
      enableDisplayRecordsButton,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleDiscardEmbeddedChart, this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData
    };
    const viewProps: FallRiskAssessmentViewProps = {
      handleMorseClick: this.handleMorseClick,
      isMorseSidebarOpen,
      handleCloseSidebar: this.handleCloseSidebar,
      handleDiscardSidebar: this.handleDiscardEmbeddedChart,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      onEmbeddedChartSave: this.handleSaveEmbeddedChart,
      chartContent,
      sidebarKey,
      isLocked,
      chartFragment,
      statusFragment,
      morseChartFragment: embeddedMorseChart?.payload,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <FallRiskAssessmentView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

export { FallRiskAssessment as BaseFallRiskAssessment };
export default compose(withAdmissionHistory, withChartLogic, connect(mapStateToProps))(FallRiskAssessment);

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getIntegumentaryAssessmentFormFieldBuilderItems } from './constants';
import IntegumentaryAssessmentView from './IntegumentaryAssessmentView';
import { Title } from '../shared/constants';

export interface IntegumentaryAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

class IntegumentaryAssessment extends Component<IntegumentaryAssessmentProps, null> {
  static displayName = 'IntegumentaryAssessment';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    getIntegumentaryAssessmentFormFieldBuilderItems().forEach(({ name, label, ...item }) => {
      dataMap.set(name, createFormField({ name, label: label && intl.formatMessage({ id: label }), ...item }));
    });
    return dataMap;
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: LanguageKeys.INTEGUMENTARY_ASSESSMENT.INTEGUMENTARY_ASSESSMENT,
      records: [
        {
          sectionTitle: LanguageKeys.INTEGUMENTARY_ASSESSMENT.INTEGUMENTARY_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
        },
        {
          sectionTitle: LanguageKeys.INTEGUMENTARY_ASSESSMENT.SKIN_ASSESSMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.SKIN_COLOR),
            buildPatientRecord(formFieldMap, FormField.SKIN_TURGOR),
            buildPatientRecord(formFieldMap, FormField.TEMPERATURE),
            buildPatientRecord(formFieldMap, FormField.INTEGRITY),
            buildPatientRecord(formFieldMap, FormField.MOISTURE),
            buildPatientRecord(formFieldMap, FormField.SKIN_NOTES)
          ]
        },
        {
          sectionTitle: LanguageKeys.INTEGUMENTARY_ASSESSMENT.HAIR_ASSESSMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.DISTRIBUTION),
            buildPatientRecord(formFieldMap, FormField.CHARACTERISTICS),
            buildPatientRecord(formFieldMap, FormField.HAIR_NOTES)
          ]
        },
        {
          parentSectionTitle: LanguageKeys.INTEGUMENTARY_ASSESSMENT.HAIR_ASSESSMENT,
          sectionTitle: 'Health',
          records: [...buildPatientRecords(formFieldMap, FormField.HEALTH)]
        },
        {
          sectionTitle: LanguageKeys.INTEGUMENTARY_ASSESSMENT.NAIL_ASSESSMENT,
          records: [
            buildPatientRecord(formFieldMap, FormField.CONFIGURATION),
            buildPatientRecord(formFieldMap, FormField.CONDITION),
            buildPatientRecord(formFieldMap, FormField.CONDITION_OTHER),
            buildPatientRecord(formFieldMap, FormField.NAIL_COLOR),
            buildPatientRecord(formFieldMap, FormField.NAIL_BASE),
            buildPatientRecord(formFieldMap, FormField.NAIL_BASE_OTHER),
            buildPatientRecord(formFieldMap, FormField.NAIL_NOTES)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildDefaultFormFields(),
      afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting
    });
  };

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  resetForm = () => {
    this.props.initState(this.buildDefaultFormFields());
  };

  render() {
    const { formFieldMap, formSubmittedCount, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };
    const viewProps: ChartViewProps = {
      formFieldMap,
      formSubmittedCount,
      chartActionsProps
    };
    return <IntegumentaryAssessmentView {...viewProps} />;
  }
}

export { IntegumentaryAssessment as BaseIntegumentaryAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(IntegumentaryAssessment);

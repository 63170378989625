export enum FormField {
  AIRWAY_COMMENTS = 'artificialTypeAirwayComments',
  AIRWAY_IN_USE = 'artificialAirwayInUse',
  AIRWAY_POSITION = 'artificialTypeAirwayPosition',
  AIRWAY_SIZE = 'artificialTypeAirwaySize',
  AIRWAY_TYPE = 'artificialTypeAirway',
  AIRWAY_TYPE_CUFFED_UNCUFFED = 'artificialTypeAirwayCuffedUncuffed',
  AIRWAY_TYPE_OTHER = 'artificialTypeAirwayOther',
  CHEST_TUBE = 'chestTube',
  COUGH_NOTE = 'coughNote',
  COUGH_STRENGTH = 'coughStrength',
  COUGH_TYPE = 'coughType',
  GAG_REFLEX = 'gagReflex',
  GAG_REFLEX_NOT_PRESENT_OPTIONS = 'gagReflexNotPresentOptions',
  GAG_REFLEX_NOT_PRESENT_OTHER = 'gagReflexNotPresentOther',
  LOCATION = 'lungAuscultationTitle',
  LUNG_ANTERIOR_IMG = 'lungAnteriorImg',
  LUNG_LATERAL_LEFT_IMG = 'lungLateralLeftImg',
  LUNG_LATERAL_RIGHT_IMG = 'lungLateralRightImg',
  LUNG_NOTE = 'lungNote',
  LUNG_POSTERIOR_IMG = 'lungPosteriorImg',
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  OXYGENATION_EVALUATION = 'oxygenationEvaluation',
  OXYGENATION_NOTE = 'oxygenationNote',
  OXYGENATION_SUPPORT = 'oxigenRespiratoryBreathingSupport',
  OXYGENATION_SUPPORT_INCENTIVE_ML = 'oxigenIncentiveSpirometer',
  OXYGEN_AMOUNT = 'amountOxygen',
  OXYGEN_DELIVERY_SYSTEM = 'oxygenDeliverySystem',
  OXYGEN_IN_USE = 'oxygenInUse',
  OXYGEN_LMIN = 'amountOxygenLMin',
  OXYGEN_PERCENT = 'amountOxygenPercent',
  PROD_COUGH_AMOUNT = 'productiveCoughSecretionsAssessmentAmount',
  PROD_COUGH_AMOUNT_OTHER = 'productiveCoughSecretionsAssessmentAmountOther',
  PROD_COUGH_COLOR = 'productiveCoughSecretionsAssessmentColor',
  PROD_COUGH_COLOR_OTHER = 'productiveCoughSecretionsAssessmentColorOther',
  PROD_COUGH_CONSISTENCY = 'productiveCoughSecretionsAssessmentConsistency',
  PROD_COUGH_CONSISTENCY_OTHER = 'productiveCoughSecretionsAssessmentConsistencyOther',
  PROD_COUGH_SECRETION_ODOR = 'productiveCoughSecretionsAssessmentSecretionOdor',
  PROD_COUGH_SECRETION_ODOR_OTHER = 'productiveCoughSecretionsAssessmentSecretionOdorOther',
  RESPIRATORY_EFFORT = 'respiratoryEffort',
  RESPIRATORY_PATTERN = 'respiratoryPattern',
  RESPIRATORY_RATE = 'respiratoryRate',
  SOUNDS = 'lungAuscultationSelection',
  SUCTIONING = 'oxygenationSuctioning',
  SUCTIONING_AMOUNT = 'oxygenationSuctioningAmount',
  SUCTIONING_AMOUNT_OTHER = 'oxygenationSuctioningAmountOther',
  SUCTIONING_COLOR = 'oxygenationSuctioningColor',
  SUCTIONING_COLOR_OTHER = 'oxygenationSuctioningColorOther',
  SUCTIONING_CONSISTENCY = 'oxygenationSuctioningConsistency',
  SUCTIONING_CONSISTENCY_OTHER = 'oxygenationSuctioningConsistencyOther',
  SUCTIONING_ODOR = 'oxygenationSuctioningOdor',
  SUCTIONING_ODOR_OTHER = 'oxygenationSuctioningOdorOther',
  WHEEZE_DESCRIPTION = 'wheezeDescription',
  COLOUR = 'inspectionColour',
  OXYGEN_SATURATION_SPO2 = 'inspectionOxygenSaturationSpO2'
}

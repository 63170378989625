import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';

export enum FormField {
  INITIATION_DAY = 'initiationDay',
  INITIATION_TIME = 'initiationTime',
  DISCONTINUATION_DAY = 'discontinuationDay',
  DISCONTINUATION_TIME = 'discontinuationTime',
  SPECIAL_PRECAUTIONS = 'specialPrecautions',
  CRITERIA_MET_FOR_RESTRAINT_RADIO = 'criteriaMetForRestraintRadio',
  CRITERIA_MET_FOR_RESTRAINT = 'criteriaMetForRestraint',
  PROVIDER_NOTIFICATION_RADIO = 'providerNotificationRadio',
  PROVIDER_NOTIFICATION_DAY = 'providerNotificationDay',
  PROVIDER_NOTIFICATION_TIME = 'providerNotificationTime',
  ALTERNATIVE_METHODS_UTILIZED_PRIOR = 'alternativeMethodsUtilizedPrior',
  TYPE_OF_RESTRAINT = 'typeOfRestraint',
  RESTRAINT_NOTES = 'restraintNotes',
  CONTINUED_NEED_FOR_RESTRAINTS = 'continuedNeedForRestraints',
  SKIN_CIRCULATION = 'skinCirculation',
  FLUID_NUTRITION = 'fluidNutrition',
  ELIMINATION = 'elimination',
  BEHAVIORAL_MENTAL_STATUS = 'behavioralMentalStatus',
  SPECIAL_PRECAUTIONS_RESTRAINTS_NOTES = 'specialPrecautionsRestraintsNotes'
}

export const getRestraintsFormFieldBuilderItems = (): ChartMetaFormFieldParams[] => [
  { name: FormField.INITIATION_DAY, label: LanguageKeys.DATE_LABEL, type: FormFieldInputType.DATE },
  { name: FormField.DISCONTINUATION_DAY, label: LanguageKeys.DATE_LABEL, type: FormFieldInputType.DATE },
  { name: FormField.PROVIDER_NOTIFICATION_DAY, label: LanguageKeys.DATE_LABEL, type: FormFieldInputType.DATE },
  { name: FormField.INITIATION_TIME, label: LanguageKeys.TIME_LABEL, type: FormFieldInputType.TIME },
  { name: FormField.DISCONTINUATION_TIME, label: LanguageKeys.TIME_LABEL, type: FormFieldInputType.TIME },
  { name: FormField.PROVIDER_NOTIFICATION_TIME, label: LanguageKeys.TIME_LABEL, type: FormFieldInputType.TIME },
  { name: FormField.SPECIAL_PRECAUTIONS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CRITERIA_MET_FOR_RESTRAINT_RADIO, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.PROVIDER_NOTIFICATION_RADIO, label: LanguageKeys.RESTRAINTS.PROVIDER_NOTIFICATION_RADIO, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.ALTERNATIVE_METHODS_UTILIZED_PRIOR, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.TYPE_OF_RESTRAINT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.CRITERIA_MET_FOR_RESTRAINT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.CONTINUED_NEED_FOR_RESTRAINTS, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.SKIN_CIRCULATION, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.FLUID_NUTRITION, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.ELIMINATION, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.BEHAVIORAL_MENTAL_STATUS, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.RESTRAINT_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.SPECIAL_PRECAUTIONS_RESTRAINTS_NOTES, type: FormFieldInputType.TEXT_AREA }
];

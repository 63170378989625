export enum FormField {
  INTERPROFESSIONAL_PROBLEM = 'interprofessionalProblem',
  NURSING_PROBLEM = 'nursingProblem',
  EXPECTED_OUTCOME = 'expectedOutcome',
  INTERVENTION_PLAN = 'interventionPlan',
  OBJECTIVE_DATA = 'objectiveData',
  SUBJECTIVE_DATA = 'subjectiveData',
  INTERVENTIONS = 'interventions',
  OUTCOME_EVALUATION_STATUS = 'outcomeEvaluationStatus',
  EVAL_DATE = 'evalDate',
  PLAN_RESOLVED = 'planResolved',
  INTERVENTION_STATUS_COMPLETE = 'complete',
  INTERVENTION_STATUS_ONGOING = 'ongoing',
  INTERVENTION_STATUS_DISCONTINUED = 'discontinued',
  OUTCOME_EVALUATION_STATUS_COMPLETELY_MET = 'completelyMet',
  OUTCOME_EVALUATION_STATUS_PARTIALLY_MET = 'partiallyMet',
  OUTCOME_EVALUATION_STATUS_NOT_MET = 'notMet',
  PRIORITY = 'priority'
}

export enum SectionTitle {
  CARE_PLAN = 'Care Plan',
  SAVED_CARE_PLAN = 'Saved Care Plan',
  ADD_CARE_PLAN = 'Add Care Plan',
  ASSESSMENT_CUES = 'Assessment Cues',
  INTERVENTIONS = 'Interventions',
  OUTCOME_EVALUATION = 'Outcome Evaluation',
  QUIT = 'Quit'
}

export enum FormFieldLabel {
  INTERPROFESSIONAL_PROBLEM = 'Interprofessional Problem',
  NURSING_PROBLEM = 'Nursing Problem',
  EXPECTED_OUTCOME = 'Expected Outcome',
  INTERVENTION_PLAN = 'Intervention Plan',
  OBJECTIVE_DATA = 'Objective Data',
  SUBJECTIVE_DATA = 'Subjective Data',
  INTERVENTION = 'Intervention',
  RATIONALE = 'Rationale',
  INTERVENTION_ACTION = 'Intervention Action',
  EVAL_DATE = 'Eval Date (mm-dd-yyyy)',
  INTERVENTION_STATUS_COMPLETE = 'Complete',
  INTERVENTION_STATUS_ONGOING = 'Ongoing',
  INTERVENTION_STATUS_DISCONTINUED = 'Discontinued',
  OUTCOME_EVALUATION_STATUS_COMPLETELY_MET = 'Completely Met',
  OUTCOME_EVALUATION_STATUS_PARTIALLY_MET = 'Partially Met',
  OUTCOME_EVALUATION_STATUS_NOT_MET = 'Not Met'
}

export enum InterventionStatus {
  COMPLETE = 'Complete',
  ONGOING = 'Ongoing',
  DISCONTINUED = 'Discontinued'
}

export enum OutcomeEvaluationStatus {
  COMPLETELY_MET = 'Completely Met',
  PARTIALLY_MET = 'Partially Met',
  NOT_MET = 'Not Met'
}

export enum InterventionField {
  NAME = 'name',
  STATUS = 'status',
  RATIONALE = 'rationale',
  ACTIONS = 'actions'
}

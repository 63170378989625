import { Component, ReactElement, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { NavigationItem } from 'models/ui';
import { navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import './related.charts.scss';

export interface RelatedChartsProps {
  chartIds: string[];
  menuItems: NavigationItem[];
  note?: string;
  references?: string;
}

export interface RelatedChartsState {
  menuItems: NavigationItem[];
}

class RelatedCharts extends Component<RelatedChartsProps & RouteComponentProps, RelatedChartsState> {
  static displayName = 'RelatedCharts';

  constructor(props) {
    super(props);

    this.state = {
      menuItems: this.getMenuItems()
    };
  }

  getMenuItems = (): NavigationItem[] => {
    const { chartIds } = this.props;
    const navigationMap = navigationService.mapTreeToMap(this.props.menuItems, null);

    return chartIds.reduce((acc, cur) => {
      const menuItem = navigationMap.get(cur);

      if (menuItem && menuItem.shown) acc.push(menuItem);

      return acc;
    }, []);
  };

  note = (): ReactElement => {
    const { note } = this.props;

    if (!note) return <></>;

    return (
      <p className="related-charts__note">
        {'Note: '}
        {note}
      </p>
    );
  };

  handleChartLinkClick = (event: SyntheticEvent, path: string) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.props.history.push(path);
  };

  chartLinks = (): ReactElement => (
    <ELSFlex className="related-charts__chart-links" left wrap>
      {this.state.menuItems.map(
        ({ id, path, text }: NavigationItem): ReactElement => (
          <ELSFlexItem key={id} className="related-charts__chart-link-wrapper" grow>
            <a className="related-charts__chart-link" href={`/#${path}`} onClick={(event) => this.handleChartLinkClick(event, path)}>
              {text}
            </a>
          </ELSFlexItem>
        )
      )}
    </ELSFlex>
  );

  render() {
    if (!this.state.menuItems.length) return <></>;

    return (
      <div>
        <div className="related-charts">
          <div className="related-charts__header">
            <h4 className="related-charts__heading">Related Charts</h4>
            {this.note()}
          </div>
          {this.chartLinks()}
        </div>
        <div className="related-charts__references">
          <p>{this.props.references}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: appSelectors.getMenuItems(state)
});

export { RelatedCharts as BaseRelatedCharts };
export default compose(
  withProps(() => ({
    history: useHistory()
  })),
  connect(mapStateToProps, null)
)(RelatedCharts);

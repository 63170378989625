import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { PEDIATRIC_AGE_UNDER_16 } from 'constants/app.constant';
import { Box, ButtonLinkIcon, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import PsychosocialScreenReadOnly from './PsychosocialScreenReadOnly';

interface PsychosocialScreenViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  patientContext: PatientContext;
}

const PsychosocialScreenView = (props: PsychosocialScreenViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, formFieldMap, patientContext } = props;
  const isAdultOrAuthor = patientContext?.ageInDays >= PEDIATRIC_AGE_UNDER_16 || patientContext.isAuthor;

  return (
    <div className="alcohol-screen">
      {isLocked ? (
        <PsychosocialScreenReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={props.patientContext} />
      ) : (
        <>
          <h1>Psychosocial Screen</h1>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={props.formSubmittedCount > 0} />

          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">{SectionTitle.APPEARANCE}</h2>
              <ErrorFormField formField={formFieldMap.get(FormField.APPEARANCE_GROOMING)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              {isAdultOrAuthor && (
                <>
                  <h2 className="u-els-margin-bottom">{SectionTitle.BEHAVIORAL_STATUS}</h2>
                  <ErrorFormField formField={formFieldMap.get(FormField.BEHAVIORAL_STATUS)} formSubmittedCount={formSubmittedCount} />
                </>
              )}
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />

          <h2 className="u-els-margin-bottom">{SectionTitle.EMOTIONAL_STATUS}</h2>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <Box mb>{FormFieldLabel.EMOTIONAL_STATUS_FEEL_RIGHT_NOW}</Box>
              <ErrorFormField formField={formFieldMap.get(FormField.EMOTIONAL_STATUS_FEEL_RIGHT_NOW)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.EMOTIONAL_STATUS_BODY_LANGUAGE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField
                formField={formFieldMap.get(FormField.PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField formField={formFieldMap.get(FormField.PAST_2_WEEK_INTEREST_THINGS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />

          <h2 className="u-els-margin-bottom">{SectionTitle.COGNITIVE_ABILITY}</h2>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.ORIENTATION}</h3>
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.MEMORY}</h3>
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
          </ELSFlex>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.ABSTRACT_REASONING}</h3>
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.ATTENTION_SPAN}</h3>
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />

          {isAdultOrAuthor && (
            <>
              <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.THOUGHT_FORMULATION}</h3>
                  <ErrorFormField formField={formFieldMap.get(FormField.THOUGHT_FORMULATION)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.THOUGHT_CONTENT}</h3>
                  <ErrorFormField formField={formFieldMap.get(FormField.THOUGHT_CONTENT)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                </ELSFlexItem>
                <SectionDivider />
              </ELSFlex>
            </>
          )}

          <h2 className="u-els-margin-bottom">{SectionTitle.JUDGMENT}</h2>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField
                formField={formFieldMap.get(FormField.JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.JUDGMENT_PLANS_FUTURE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            </ELSFlexItem>
          </ELSFlex>
          <h2 className="u-els-margin-bottom">{SectionTitle.SPEECH_LANGUAGE}</h2>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.SPEECH_LANGUAGE_ARTICULATION}</h3>
              <ErrorFormField formField={formFieldMap.get(FormField.SPEECH_LANGUAGE_ARTICULATION)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.SPEECH_LANGUAGE_ARTICULATION_OTHER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.SPEECH_LANGUAGE_COHERENCE}</h3>
              <ErrorFormField formField={formFieldMap.get(FormField.SPEECH_LANGUAGE_COHERENCE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.COMPREHENSION}</h3>
              <ErrorFormField
                formField={formFieldMap.get(FormField.SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField
                formField={formFieldMap.get(FormField.SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h3 className="u-els-margin-bottom">{SectionTitle.APHASIA}</h3>
              <ErrorFormField
                formField={formFieldMap.get(FormField.SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />
          {isAdultOrAuthor && (
            <>
              <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.PATIENT_ASSETS}</h3>
                  <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_ASSETS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.PROBLEM_AREAS}</h3>
                  <ErrorFormField formField={formFieldMap.get(FormField.PROBLEM_AREAS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1" />
              </ELSFlex>
              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.DECISION_MAKING_RATING}</h3>
                  <ErrorFormField formField={formFieldMap.get(FormField.DECISION_MAKING_RATING)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <SectionDivider />
              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.TREATMENT_PLAN_RECOMMENDATIONS}</h3>
                  <Box mb>{FormFieldLabel.IMMEDIATE_NEED_FOR_ANY}</Box>
                  <ErrorFormField formField={formFieldMap.get(FormField.TREATMENT_PLAN_RECOMMENDATIONS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <SectionDivider />
                  <h3 className="u-els-margin-bottom">{SectionTitle.CURRENT_SAFETY_ISSUES}</h3>
                  <Box mb>{FormFieldLabel.CURRENT_SAFETY_ISSUES_QUESTION}</Box>
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_SAFETY_ISSUES)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                </ELSFlexItem>
              </ELSFlex>

              <h3 className="u-els-margin-bottom">{SectionTitle.PSYCHIATRIC_HISTORY}</h3>
              <SectionDivider />

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.PSYCHIATRIC_HISTORY)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>

                <ELSFlexItem w="1o2" md="1o1" wrap left>
                  <ErrorFormField formField={formFieldMap.get(FormField.SUICIDE_HISTORY)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>

                <ELSFlexItem w="1o2" md="1o1" wrap left>
                  <ErrorFormField formField={formFieldMap.get(FormField.LIFE_STRESSORS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <h3 className="u-els-margin-bottom">{SectionTitle.SAFETY_EVALUATION}</h3>
              <SectionDivider />

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.SAFETY_EVALUATIONS_SUICIDE_HOMICIDE_IMPULSES)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <h3 className="u-els-margin-bottom">{SectionTitle.FAMILY_ORIGIN}</h3>
              <SectionDivider />

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.BIRTH_ORDER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.GROW_UP)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_NAME_1)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_1)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_1)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_NAME_2)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_2)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_2)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_NAME_3)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_3)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_3)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_NAME_4)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_4)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_4)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_NAME_5)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_5)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField
                    formField={formFieldMap.get(FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_5)}
                    formSubmittedCount={formSubmittedCount}
                    options={{ displayHorizontal: true }}
                  />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_LIVING_SITUATION)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_EMPLOYMENT_STATUS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.PREVIOUS_OCCUPATIONS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.CURRENT_PSYCHOTHERAPIST}</h3>
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <h3 className="u-els-margin-bottom">{SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}</h3>
                </ELSFlexItem>
              </ELSFlex>

              <SectionDivider />

              <ELSFlex className="u-flex-grid-3x u-flex-gutter-3x" left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_PSYCHOTHERAPIST_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_PSYCHOTHERAPIST_ADDRESS)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_PSYCHOTHERAPIST_PHONE_NUMBER)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>

                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_PRIMARY_CARE_PROVIDER_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_PRIMARY_CARE_PROVIDER_ADDRESS)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.CURRENT_PRIMARY_CARE_PROVIDER_PHONE)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>

              <h3 className="u-els-margin-bottom">{SectionTitle.DEPRESSION_EVALUATION}</h3>

              <SectionDivider />

              <b className="u-els-margin-bottom">{FormFieldLabel.PHQ_9_SCORING}</b>

              <Box mt2>
                <ErrorFormField
                  formField={props.formFieldMap.get(FormField.PATIENT_HEALTH_QUESTIONNAIRE)}
                  formFieldMap={props.formFieldMap}
                  formSubmittedCount={props.formSubmittedCount}
                  options={{ showScaleRollUp: true, showScoreInAnswer: true }}
                />
              </Box>

              <p>Copyright 2005 Pfizer Inc. Developed by Drs. Robert L. Spitzer, Janet B. Williams, and Kurt Kroenke and colleagues.</p>
            </>
          )}

          <ButtonLinkIcon
            iconName="enlarge"
            iconSize="1x"
            text="Daily Psychosocial Assessment"
            onClick={() => {
              // TODO: Embedded chart
            }}
            preventAutoPending
          />

          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

PsychosocialScreenView.displayName = 'PsychosocialScreenView';
export default PsychosocialScreenView;

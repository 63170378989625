import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';

export interface ActivitiesDailyLivingEditViewProps {
  selectedRecord: ChartFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const ActivitiesDailyLivingEditView = (props: ActivitiesDailyLivingEditViewProps) => (
  <>
    <Box mt2>
      <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.formFieldMap.get(FormField.SLEEP)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.formFieldMap.get(FormField.EXERCISE_ACTIVITIES)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.formFieldMap.get(FormField.ELIMINATION_STOOL_URINE)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.formFieldMap.get(FormField.SAFETY_ASSESSMENT_ISSUES)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.formFieldMap.get(FormField.OTHER)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
    </Box>
    <ChartPermission fragmentType={props.selectedRecord?.fragmentType}>
      {({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}
    </ChartPermission>
  </>
);
ActivitiesDailyLivingEditView.displayName = 'ActivitiesDailyLivingEditView';
export default ActivitiesDailyLivingEditView;

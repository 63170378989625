import { ImpressionFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './Impressions';

export interface ImpressionsEditFormViewProps {
  fragment: ImpressionFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const ImpressionsEditFormView = (props: ImpressionsEditFormViewProps) => (
  <>
    <div className="u-els-width-2o3">
      <ErrorFormField
        formField={props.chartMetaFormFields.get(FormField.IMPRESSIONS)}
        formSubmittedCount={props.formSubmittedCount}
        inputProps={{ className: 'sng-textarea-12x', placeholder: true, rows: 8 }}
      />
    </div>
    <div className="u-els-width-2o3">
      <ErrorFormField
        formField={props.chartMetaFormFields.get(FormField.PLAN)}
        formSubmittedCount={props.formSubmittedCount}
        inputProps={{ className: 'sng-textarea-12x', placeholder: true, rows: 8 }}
      />
    </div>
    <div className="u-els-width-1o3">
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SIGNATURE)} formSubmittedCount={props.formSubmittedCount} inputProps={{ placeholder: true }} />
    </div>
    <Box mt2 className="u-els-width-1o3">
      <ELSFlex left gutters>
        <ELSFlexItem>
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DAY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem>
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.TIME)}
            formSubmittedCount={props.formSubmittedCount}
            inputProps={{ className: 'sng-datetime__time c-els-field__input' }}
          />
        </ELSFlexItem>
      </ELSFlex>
    </Box>
    <ChartPermission fragmentType={props.fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
  </>
);

ImpressionsEditFormView.displayName = 'ImpressionsEditFormView';
export default ImpressionsEditFormView;

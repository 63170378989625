// eslint-disable-next-line import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires
const icon = require('!!raw-loader!assets/img/ellipse.svg');

interface EllipseIconProps {
  id?: string;
  className?: string;
}

const EllipseIcon = (props: EllipseIconProps) => (
  // eslint-disable-next-line react/no-danger
  <span id={props.id} className={props.className} dangerouslySetInnerHTML={{ __html: icon }} />
);
EllipseIcon.displayName = 'EllipseIcon';

export default EllipseIcon;

export enum SectionTitle {
  MEDICATION_RECONCILIATION = 'Medication Reconciliation'
}

export enum FormField {
  MEDICATION_ONE = 'medicationOne',
  MEDICATION_TWO = 'medicationTwo',
  MEDICATION_THREE = 'medicationThree',
  MEDICATION_FOUR = 'medicationFour',
  DIRECTION_ONE = 'directionsOne',
  DIRECTION_TWO = 'directionsTwo',
  DIRECTION_THREE = 'directionsThree',
  DIRECTION_FOUR = 'directionsFour',
  INDICATION_ONE = 'indicationsOne',
  INDICATION_TWO = 'indicationsTwo',
  INDICATION_THREE = 'indicationsThree',
  INDICATION_FOUR = 'indicationsFour',
  SPECIAL_INSTRUCTION_ONE = 'specialInstructionsOne',
  SPECIAL_INSTRUCTION_TWO = 'specialInstructionsTwo',
  SPECIAL_INSTRUCTION_THREE = 'specialInstructionsThree',
  SPECIAL_INSTRUCTION_FOUR = 'specialInstructionsFour',
  REACTIONS = 'reactions',
  PROVIDER_SIGNATURE_ID = 'providerSignatureId',
  PROVIDER_SIGNATURE_CREATOR = 'providerSignatureCreator',
  PROVIDER_SIGNATURE_TIME = 'providerSignatureTimeOffset',
  MEDICATIONS_REVIEWER_ID = 'medicationsReviewerId',
  MEDICATIONS_REVIEWED_CREATOR = 'reviewedByCreator',
  MEDICATIONS_REVIEWED_TIME = 'reviewedByTimeOffset'
}

export enum HomeMedicationType {
  PRESCRIPTION_MEDICATIONS = 'Prescription Medications',
  OVER_THE_COUNTER_PRODUCTS = 'Over-the-counter Products',
  HERBAL_AND_NATURAL_REMEDY_PRODUCTS = 'Herbal and Natural Remedy Products'
}

import { Component } from 'react';

export interface CircleDotProps {
  cx?: number;
  cy?: number;
  payload?: {
    generatedDataPoint?: boolean;
    score?: string;
  };
  onGetCoordinate?: Function;
  stroke?: string;
  strokeWidth?: number;
  customColor?: (score: string) => string;
}

export class CircleDot extends Component<CircleDotProps> {
  componentDidMount() {
    const { cx, cy, onGetCoordinate } = this.props;
    if (cx && cy && onGetCoordinate) {
      onGetCoordinate(cx + 15, cy - 15);
    }
  }

  render() {
    const { cx, cy, payload, strokeWidth, stroke, customColor } = this.props;
    if (payload?.generatedDataPoint) {
      return null;
    }
    return <circle cx={cx} cy={cy} r="3" fill={customColor?.(payload?.score) || stroke} stroke={customColor?.(payload?.score) || stroke} strokeWidth={strokeWidth} />;
  }
}

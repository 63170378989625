import { chartHelper } from 'helpers';
import { Box, ChartActions, ErrorFormField, Sidebar } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

interface PreviousPregnanciesSidebarViewProps extends Partial<ChartViewProps> {
  isSidebarOpen: boolean;
  onCloseClick: Function;
  sidebarProps: Function;
}
const PreviousPregnanciesSidebarView = (props: PreviousPregnanciesSidebarViewProps) => {
  const { formFieldMap, formSubmittedCount, isSidebarOpen, onCloseClick, sidebarProps } = props;
  return (
    <Sidebar open={isSidebarOpen} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
      <Box mt3>
        <h2>Add pregnancy information</h2>
      </Box>
      <ErrorFormField formField={formFieldMap.get(FormField.HOW_LONG_AGO)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.GESTATIONAL_WEEKS)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.TYPE_OF_DELIVERY)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.DURATION_OF_LABOR)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.COMPLICATIONS)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.OUTCOME)} formSubmittedCount={formSubmittedCount} />
      <ChartActions {...props.chartActionsProps} />
    </Sidebar>
  );
};

export default PreviousPregnanciesSidebarView;

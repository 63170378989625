import cx from 'classnames';
import { LaboratoryTestFragment } from 'models/api-response';
import { dateTimeHelper } from 'helpers';
import { AccordionBadge, AccordionTitle, Box, ChartPermission } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';
import LaboratoryTestResultTableView from './LaboratoryTestResultTableView';

export interface LaboratoryTestHistoryViewProps {
  chartHistory: LaboratoryTestFragment[];
  onChartEdit: Function;
  onChartDelete: Function;
}

const LaboratoryTestHistoryView = (props: LaboratoryTestHistoryViewProps) => (
  <Box mt2>
    <h2 className="u-els-margin-bottom-2x">Saved Laboratory Tests</h2>
    <div className="sng-accordion">
      {props.chartHistory?.length === 0 && <NothingToSee>Nothing to see, yet! Get started adding lab tests.</NothingToSee>}
      {props.chartHistory?.length > 0 && (
        <ELSAccordion id="laboratory-test-history__history-accordion">
          {props.chartHistory.map((item) => (
            <ELSAccordionItem
              key={item.fragmentId}
              id={item.fragmentId}
              leftIcon="chevron-down"
              customHeader={
                <>
                  <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !item.active })}>{item?.chartData?.labTest}</AccordionTitle>
                  {item?.chartData?.dateOfTest && <AccordionBadge>{dateTimeHelper.toDate(item?.chartData?.dateOfTest)}</AccordionBadge>}
                </>
              }
            >
              <Box pr pb>
                <div className="u-els-italic">{item?.chartData?.category}</div>
                <div className="u-els-margin-top-1x1o2">
                  <h4>Definition and Description of the Test</h4>
                  <MultiLineWithEmpty text={item?.chartData?.definitionAndDescription} />
                </div>
                <div className="u-els-margin-top-1x1o2">
                  <h4>Significance of the Test Being Ordered for this Patient</h4>
                  <MultiLineWithEmpty text={item?.chartData?.significance} />
                </div>
                <div className="u-els-margin-top-1x1o2">
                  <h4>Laboratory Test Results</h4>
                </div>

                <LaboratoryTestResultTableView labResults={item?.chartData?.tests} />
                {item.active && (
                  <ChartPermission fragmentCreatorId={item.creator?.id}>
                    {({ allowEditFragment, allowDeleteFragment }) => (
                      <Box mt2 className="sng-form-buttons">
                        {allowEditFragment && <ELSButton text="Edit" onClick={() => props.onChartEdit(item)} />}
                        {allowDeleteFragment && <ELSButton type="secondary" text="Delete" onClick={() => props.onChartDelete(item)} />}
                      </Box>
                    )}
                  </ChartPermission>
                )}
              </Box>
            </ELSAccordionItem>
          ))}
        </ELSAccordion>
      )}
    </div>
  </Box>
);

LaboratoryTestHistoryView.displayName = 'LaboratoryTestHistoryView';
export default LaboratoryTestHistoryView;

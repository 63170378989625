import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  CLEFT_LIP = 'cleftLip',
  CLEFT_PALATE = 'cleftPalate',
  GUMS_AND_MOUTH = 'gumsAndMouth',
  TEETH_BRIDGES_DENTURES = 'teethBridgesDentures',
  MOUTH_GUMS_TEETH_NOTES = 'mouthGumsTeethNotes',
  ESOPHAGEAL_ASSESSMENT = 'esophagealAssessment',
  ESOPHAGEAL_NOTES = 'esophagealNotes',
  ABDMONINAL_ASSESSMENT = 'abdominalAssessment',
  TENDER_ON_PALPATION = 'tenderOnPalpation',
  NO_GASTRIC_PROBLEM_NOTED = 'noGastricProblemsNoted',
  GASTROINTESTINAL = 'gastrointestinal',
  GASTROINTESTINAL_NOTES = 'gastrointestinalNotes',
  DATE_OF_LAST_BOWEL_MOVEMENT = 'dateOfLastBowelMovement',
  CONTINENCE_OF_BOWEL = 'continenceOfBowel',
  BOWEL_SOUNDS = 'bowelSounds',
  BOWEL_SOUNDS_OTHER = 'bowelSoundsOther',
  NO_REPORTED_RECTAL_PROBLEMS = 'noReportedRectalProblems',
  RECTUM = 'rectum',
  NO_BOWEL_PROBLEMS_NOTED = 'noBowelProblemsNoted',
  INTESTINAL_ASSESSMENT = 'intestinalAssessment',
  STOOL_CHARACTERISTICS = 'stoolCharacteristics',
  INTESTINAL_DIVERSION_PATIENT_HAS_OSTOMY = 'intestinalDiversionPatientHasOstomy',
  INTESTINAL_NOTES = 'intestinalNotes'
}

export const getGastrointestinalAssessmentFormFieldBuilderItems = (): ChartMetaFormFieldParams[] => [
  { name: FormField.NO_ASSESSMENT_REQUIRED, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CLEFT_LIP, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CLEFT_PALATE, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.NO_GASTRIC_PROBLEM_NOTED, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.NO_REPORTED_RECTAL_PROBLEMS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.INTESTINAL_DIVERSION_PATIENT_HAS_OSTOMY, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.NO_BOWEL_PROBLEMS_NOTED, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CONTINENCE_OF_BOWEL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.CONTINENCE_OF_BOWEL },
  { name: FormField.GUMS_AND_MOUTH, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.GUMS_AND_MOUTH },
  { name: FormField.TEETH_BRIDGES_DENTURES, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.TEETH },
  { name: FormField.TENDER_ON_PALPATION, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.TENDER_ON_PALPATION },
  { name: FormField.ESOPHAGEAL_ASSESSMENT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.ABDMONINAL_ASSESSMENT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.GASTROINTESTINAL, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.BOWEL_SOUNDS, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.RECTUM, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.INTESTINAL_ASSESSMENT, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.STOOL_CHARACTERISTICS, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.BOWEL_SOUNDS_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.BOWEL_SOUNDS_OTHER },
  { name: FormField.MOUTH_GUMS_TEETH_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.ESOPHAGEAL_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.GASTROINTESTINAL_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.INTESTINAL_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.DATE_OF_LAST_BOWEL_MOVEMENT, type: FormFieldInputType.DATE, label: LanguageKeys.GASTROINTESTINAL_ASSESSMENT.DATE_OF_LAST_BOWEL_MOVEMENT }
];

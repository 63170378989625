import cx from 'classnames';
import { AssessmentStatus } from 'models/enum';
import { FailGradeIcon, PassGradeIcon } from 'components/common';
import AssessmentStatusDetails from './AssessmentStatusDetails';
import IncompletedAssessmentStatusBadge from './IncompletedAssessmentStatusBadge';
import './base-grade.scss';

export interface PassFailGradeProps {
  className?: string;
  formattedDueDate: string;
  isPassed?: boolean;
  assessmentStatus: string;
}

const PassFailGrade = (props: PassFailGradeProps) => {
  const { className, formattedDueDate, isPassed, assessmentStatus } = props;

  let headerMessage = 'You Failed';
  if (assessmentStatus === AssessmentStatus.IN_PROGRESS) {
    headerMessage = 'You have not yet submitted';
  } else if (isPassed) {
    headerMessage = 'You Passed!';
  }

  return (
    <div className={cx('grade', className)}>
      {isPassed ? <PassGradeIcon /> : <FailGradeIcon />}
      <div className="legend">
        <span className="u-els-font-size-h3">{headerMessage}</span>
        <IncompletedAssessmentStatusBadge assessmentStatus={assessmentStatus} />
        <AssessmentStatusDetails assessmentStatus={assessmentStatus} formattedDueDate={formattedDueDate} />
      </div>
    </div>
  );
};

export default PassFailGrade;

import cx from 'classnames';
import { useEffect, useState } from 'react';
import { ContentItem } from 'models/ui';
import { caseStudyHelper } from 'helpers';
import { ButtonLinkIcon } from 'components/common';
import { ELSDropDownSearch, ELSTagGroup, ELSTooltip } from 'components/common/els';
import './product.filter.scss';

interface ProductFilterProps {
  setProductFilters: Function;
  products?: Record<string, string>[];
}

const ProductFilter = (props: ProductFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const items = caseStudyHelper.getStandaloneProductItems(props.products);

  const [filtersState, setFilterState] = useState({
    search: '',
    options: items,
    isMounted: false,
    selectedOptionNames: []
  });

  const { setProductFilters } = props;
  const [showProductListIcon, setShowProductListIcon] = useState('chevron-up');

  const handleOnClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const getSelectedOptionLabels = (options): string[] => options?.filter((item) => item.selected).map((item) => item.name) ?? [];

  useEffect(() => {
    setFilterState({
      ...filtersState,
      isMounted: true,
      options: items,
      selectedOptionNames: []
    });
  }, []);

  useEffect(() => {
    setShowProductListIcon(isOpen ? 'chevron-down' : 'chevron-up');
  }, [isOpen]);

  const handleOptionSelect = (newSelectedOption: ContentItem) => {
    const newOptions = filtersState.options.map((option) => {
      return { ...option, selected: option.id === newSelectedOption.id ? !option.selected : option.selected };
    });
    setProductFilters(getSelectedOptionLabels(newOptions));
    setFilterState({ search: '', options: newOptions, isMounted: true, selectedOptionNames: getSelectedOptionLabels(newOptions) });
  };

  const handleSearchChange = (value: string) => {
    const options = items.filter((item) => item.isActive && !item.isHidden && String(item.name).toLowerCase().includes(value.toLowerCase())) ?? [];
    setFilterState({
      search: value,
      options,
      isMounted: false,
      selectedOptionNames: []
    });
  };

  const handleTagDelete = (index: number) => {
    const changedOption = filtersState?.options?.filter((option) => option.selected)[index];
    const newOptions = filtersState.options.map((option) => {
      return { ...option, selected: option.id === changedOption.id ? false : option.selected };
    });
    setProductFilters(getSelectedOptionLabels(newOptions));
    setFilterState({ search: '', options: newOptions, isMounted: true, selectedOptionNames: getSelectedOptionLabels(newOptions) });
  };

  const handleResetOnClick = () => {
    setProductFilters([]);
    setFilterState({ search: '', options: items, isMounted: true, selectedOptionNames: getSelectedOptionLabels([]) });
  };

  return (
    <div className="sng-product-filter-search">
      <div className="sng-product-filter-search__content">
        <ELSTagGroup cssModifier="sng-product-filter-search__tag" isLargerChipText tags={filtersState.selectedOptionNames} onDeleteTag={handleTagDelete} isTagDeletable />
        <span className="sng-product-filter-search__content">Filters</span>
        <ELSTooltip
          wrapperClassName="sng-product-filter-search__input"
          content={
            <div className={cx('sng-multi-select-search', 'product-filter' || '')}>
              <ELSDropDownSearch
                className="sng-product-filter-search__input"
                disableAutoComplete
                id="product-filter"
                key={0}
                name="Product Filter"
                onOptionSelected={handleOptionSelect}
                onSearchChangeHandler={handleSearchChange}
                options={filtersState.options}
                placeholder="Search products"
                showSearchIcon
                type="checkbox"
                ariaRequired
              />
            </div>
          }
          showClose={false}
          onCloseToolTip={() => setIsOpen(false)}
          onOpenToolTip={() => setIsOpen(true)}
        >
          <div className="u-els-display-inline-block sng-product-filter-search__content">
            <ButtonLinkIcon text="Products" iconName={showProductListIcon} iconPosition="right" iconSize="3o4" onClick={() => handleOnClick()} preventAutoPending />
          </div>
        </ELSTooltip>
        <div className="c-els-divider u-els-transform-rotate-90 sng-product-filter-search__divider" />
        <div className="u-els-display-inline-block sng-product-filter-search__reset">
          <ButtonLinkIcon text="Reset" iconName={null} iconPosition="right" iconSize="3o4" onClick={() => handleResetOnClick()} preventAutoPending />
        </div>
      </div>
    </div>
  );
};

ProductFilter.defaultProps = {
  products: []
};

ProductFilter.displayName = 'ProductFilter';
export default ProductFilter;

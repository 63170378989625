import queryString from 'query-string';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { AppMessage, NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import DrainsTubesView from './DrainsTubesView';

interface DrainsTubesProps extends RouteComponentProps {
  showConfirmationModal: Function;
  enableMultiStepsAuthoring: Function;
}
interface DrainsTubesState {
  loadedFragment: ChartFragment;
  fragments: ChartFragment[];
}

class DrainsTubes extends Component<DrainsTubesProps & ChartComponentProps, DrainsTubesState> {
  static displayName = 'DrainsTubes';

  constructor(props) {
    super(props);
    this.state = {
      loadedFragment: null,
      fragments: null
    };
  }

  componentDidMount() {
    const id = this.getIdFromParam(this.props);
    this.loadData(id);
    this.props.enableMultiStepsAuthoring(true);
  }

  componentDidUpdate(prevProps) {
    const prevId = this.getIdFromParam(prevProps);
    const id = this.getIdFromParam(this.props);
    // Prepare for creating a new DrainTube entry when re-click on the left navigation
    if (id !== prevId && !id) {
      this.setState({ loadedFragment: null });
      this.props.initState(this.buildFormFields());
    }
  }

  isDiscontinuedExisted = (fragments: ChartFragment[], fragment: ChartFragment) => {
    if (!fragment) return false;
    const fragmentList = fragments.filter((frag) => frag.linkedFragmentId === (fragment.linkedFragmentId || fragment.fragmentId));
    return fragmentList.some((frag) => frag.active && chartHelper.getFragmentValue(frag, SectionTitle.DRAINS_TUBES, FormField.DISCONTINUED));
  };

  loadData = (id) =>
    appHelper.useLoader(
      this.props.loadChartData([FragmentType.AUTHORED, FragmentType.CHARTING]).then(({ data: fragments }) => {
        let chartFragment = fragments.find((fragment) => fragment.fragmentId === id && fragment.active);
        if (this.isDiscontinuedExisted(fragments, chartFragment)) {
          chartFragment = null;
          const drainsTubesEntryPage = RoutePath.student.drainsTubes.drainsTubes.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
          this.props.history.push(drainsTubesEntryPage);
        }
        this.setState({ loadedFragment: chartFragment, fragments });
        this.props.initState(this.buildFormFields(chartFragment));
      }),
      { errorMessage: AppMessage.NO_CHART_DATA }
    );

  getIdFromParam = (props: DrainsTubesProps) => {
    const parsedQuery = queryString.parse(props.location.search);
    return parsedQuery.id;
  };

  buildFormFields = (fragment?: ChartFragment) => {
    const { createFormField, getFragmentContentIds, getFragmentValue } = chartHelper;
    const dataMap = new Map();

    const dropdowns = [
      { name: FormField.LOCATION, sectionTitle: SectionTitle.DRAINS_TUBES, label: FormFieldLabel.LOCATION },
      { name: FormField.DRAIN_TUBE_TYPE, sectionTitle: SectionTitle.DRAINS_TUBES, label: FormFieldLabel.DRAIN_TUBE_TYPE }
    ];

    const multiselectDropdowns = [
      { name: FormField.TUBE_PLACEMENT, sectionTitle: SectionTitle.TUBE_PLACEMENT },
      { name: FormField.SUCTION, sectionTitle: SectionTitle.SUCTION },
      { name: FormField.DRAIN_TUBE_AREA, sectionTitle: SectionTitle.DRAIN_TUBE_AREA },
      { name: FormField.DRAINAGE, sectionTitle: SectionTitle.DRAINAGE },
      { name: FormField.DRAIN_TUBE_PATENCY, sectionTitle: SectionTitle.DRAIN_TUBE_PATENCY },
      { name: FormField.PATIENT_RESPONSE, sectionTitle: SectionTitle.PATIENT_RESPONSE },
      { name: FormField.DRAIN_TUBE_CARE, sectionTitle: SectionTitle.DRAIN_TUBE_CARE }
    ];

    dataMap.set(
      FormField.DISCONTINUED,
      createFormField({
        name: FormField.DISCONTINUED,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DRAINS_TUBES, FormField.DISCONTINUED)
      })
    );

    dataMap.set(
      FormField.TRACHEOSTOMY_TYPE,
      createFormField({
        name: FormField.TRACHEOSTOMY_TYPE,
        type: FormFieldInputType.CHECK_BOX,
        label: FormFieldLabel.TRACHEOSTOMY_TYPE,
        contentIds: getFragmentContentIds(fragment, SectionTitle.TRACHEOSTOMY, FormField.TRACHEOSTOMY_TYPE)
      })
    );

    dataMap.set(
      FormField.TRACHEOSTOMY_SIZE,
      createFormField({
        name: FormField.TRACHEOSTOMY_SIZE,
        type: FormFieldInputType.TEXT_BOX,
        label: FormFieldLabel.TRACHEOSTOMY_SIZE,
        value: getFragmentValue(fragment, SectionTitle.TRACHEOSTOMY, FormField.TRACHEOSTOMY_SIZE)
      })
    );

    dataMap.set(
      FormField.TRACHEOSTOMY_CUFF,
      createFormField({
        name: FormField.TRACHEOSTOMY_CUFF,
        type: FormFieldInputType.RADIO_CHOICE,
        contentIds: getFragmentContentIds(fragment, SectionTitle.TRACHEOSTOMY, FormField.TRACHEOSTOMY_CUFF)
      })
    );

    dataMap.set(
      FormField.DRAIN_TUBE_NOTES,
      createFormField({
        name: FormField.DRAIN_TUBE_NOTES,
        type: FormFieldInputType.TEXT_AREA,
        value: getFragmentValue(fragment, SectionTitle.DRAIN_TUBE_NOTES, FormField.DRAIN_TUBE_NOTES)
      })
    );

    dropdowns.forEach(({ name, sectionTitle, label }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          type: FormFieldInputType.DROPDOWN,
          contentIds: getFragmentContentIds(fragment, sectionTitle, name)
        })
      )
    );

    multiselectDropdowns.forEach(({ name, sectionTitle }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.MULTISELECT_DROPDOWN,
          contentIds: getFragmentContentIds(fragment, sectionTitle, name)
        })
      )
    );
    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { buildSection } = chartHelper;
    const { buildPatientRecord, buildPatientRecords } = chartService;
    return [
      buildSection({
        sectionTitle: SectionTitle.DRAINS_TUBES,
        fields: [FormField.DISCONTINUED, FormField.LOCATION, FormField.DRAIN_TUBE_TYPE],
        formFieldMap
      }),
      {
        sectionTitle: SectionTitle.TRACHEOSTOMY,
        records: [
          ...buildPatientRecords(formFieldMap, FormField.TRACHEOSTOMY_TYPE),
          buildPatientRecord(formFieldMap, FormField.TRACHEOSTOMY_SIZE, null, `${formFieldMap.get(FormField.TRACHEOSTOMY_SIZE).value} mm`),
          buildPatientRecord(formFieldMap, FormField.TRACHEOSTOMY_CUFF, 'Tracheostomy Cuff')
        ]
      },
      buildSection({ sectionTitle: SectionTitle.TUBE_PLACEMENT, fields: [FormField.TUBE_PLACEMENT], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.SUCTION, fields: [FormField.SUCTION], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.DRAIN_TUBE_AREA, fields: [FormField.DRAIN_TUBE_AREA], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.DRAINAGE, fields: [FormField.DRAINAGE], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.DRAIN_TUBE_PATENCY, fields: [FormField.DRAIN_TUBE_PATENCY], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.PATIENT_RESPONSE, fields: [FormField.PATIENT_RESPONSE], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.DRAIN_TUBE_CARE, fields: [FormField.DRAIN_TUBE_CARE], formFieldMap }),
      buildSection({ sectionTitle: SectionTitle.DRAIN_TUBE_NOTES, fields: [FormField.DRAIN_TUBE_NOTES], formFieldMap })
    ];
  };

  buildFragment = () => {
    const { isAuthor } = this.props;
    const record = {
      chartTitle: SectionTitle.DRAINS_TUBES,
      fragmentTitle: SectionTitle.DRAINS_TUBES,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = chartHelper.createFragmentId(NAV_ID.DRAINS_TUBES);
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    let linkedFragmentId;
    const { loadedFragment } = this.state;
    if (!loadedFragment) {
      linkedFragmentId = fragmentId;
    } else {
      linkedFragmentId = loadedFragment.linkedFragmentId || loadedFragment.fragmentId;
    }
    return { ...basicInfo, linkedFragmentId, groupFragmentRef: isAuthor ? linkedFragmentId : undefined, chartData: cleanRecord } as ChartFragment;
  };

  saveChart = () => {
    const fragment = this.buildFragment();
    const defaultFormFields = this.buildFormFields(this.state.loadedFragment);
    return this.props.saveChart([fragment], { defaultFormFields, afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  handleSaveClick = () => {
    const { loadedFragment, fragments } = this.state;
    const { formFieldMap } = this.props;
    const curLocation = formFieldMap.get(FormField.LOCATION).value;
    const isSavingActive = !formFieldMap.get(FormField.DISCONTINUED).value;

    const needShowWarning = isSavingActive && !loadedFragment && this.isLocationOfActiveExisted(fragments, curLocation);
    if (needShowWarning) {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Please confirm.',
        message: 'You already have an active Drains - Tubes at this location. Are you sure you want to add another?',
        onOkClick: this.saveChart
      });
    } else {
      this.saveChart();
    }
  };

  isLocationOfActiveExisted = (fragments, newLocation: string) => {
    const fragmentList = fragments.filter((frag) => {
      const location = chartHelper.getFragmentValue(frag, SectionTitle.DRAINS_TUBES, FormField.LOCATION);
      return frag.active && location === newLocation;
    });

    let result = false;
    const fragmentGroups = chartHelper.groupFragmentsById(fragmentList);
    fragmentGroups.forEach((fragmentGroup) => {
      const isDiscontinued = fragmentGroup.some((frag) => chartHelper.getFragmentValue(frag, SectionTitle.DRAINS_TUBES, FormField.DISCONTINUED));
      if (!isDiscontinued) {
        result = true;
      }
    });

    return result;
  };

  isTracheostomySelected = () => {
    return this.props.formFieldMap.get(FormField.DRAIN_TUBE_TYPE)?.chartContent?.find((content) => content.value === 'drainTubeTypeTracheostomy')?.selected;
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields(this.state.loadedFragment));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData
    };

    const viewProps = {
      fragment: this.state.loadedFragment,
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      isTracheostomyVisible: this.isTracheostomySelected()
    };
    return <DrainsTubesView {...viewProps} />;
  }
}

export { DrainsTubes as BaseDrainsTubes };
export default compose(withRouter, withChartLogic, withSavedPatientChartsPage)(DrainsTubes);

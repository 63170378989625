import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldDataType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, ContentItem } from 'models/ui';
import { Locales, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper, patientHelper } from 'helpers';
import { formatDate, toISO, toMoment } from 'helpers/datetime.helper';
import { buildRecordFromField, createBaseFragment } from 'services/chart.service';
import { appActions } from 'redux/ducks/app';
import { studentActions, studentSelectors } from 'redux/ducks/student';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getGeneralOrderFormFieldBuilderItems } from './constants';
import GeneralOrdersView, { GeneralOrdersViewProps } from './GeneralOrdersView';
import { FormField as CommonFormField } from '../shared/constants';

interface GeneralOrdersState {
  chartHistory: ChartFragment[];
}

interface GeneralOrdersProps extends ChartComponentProps {
  orderDescription: string;
  updatePatient: Function;
  setOrderDescription: Function;
  enableMultiStepsAuthoring: Function;
}

class GeneralOrders extends Component<GeneralOrdersProps, GeneralOrdersState> {
  static displayName = 'GeneralOrders';

  constructor(props) {
    super(props);
    this.state = {
      chartHistory: []
    };
  }

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
    appHelper.useLoader(Promise.all([this.props.initState(this.buildDefaultFormFields()), this.loadGeneralOrdersData()]), { errorMessage: 'can not load general orders data' });
  }

  componentDidUpdate(prevProps: Readonly<GeneralOrdersProps>) {
    const prevDescriptionContents = prevProps.formFieldMap.get(FormField.ORDER_DESCRIPTION)?.chartContent || [];
    const descriptionContents = this.props.formFieldMap.get(FormField.ORDER_DESCRIPTION)?.chartContent || [];
    const isContentReadyOrDescriptionChanged = (!prevDescriptionContents.length && descriptionContents.length) || prevProps.orderDescription !== this.props.orderDescription;
    if (isContentReadyOrDescriptionChanged) {
      this.prefillOrderDescription();
    }
  }

  componentWillUnmount() {
    this.props.setOrderDescription('');
  }

  getOrderDescriptionContent = (description: string): ContentItem => {
    const contents = this.props.formFieldMap.get(FormField.ORDER_DESCRIPTION).chartContent || [];
    return contents.find((item) => item.label === description);
  };

  prefillOrderDescription = () => {
    const content = this.getOrderDescriptionContent(this.props.orderDescription);
    if (content) {
      const formField = { ...this.props.formFieldMap.get(FormField.ORDER_DESCRIPTION), contentIds: [content.id] };
      content.onChange(formField, content);
    }
  };

  loadGeneralOrdersData = () => {
    const { locale } = this.props;
    return this.props.loadChartData().then(({ data }) => {
      const records = data.map((fragment) => {
        const { fragmentId: id, active, chartingAt, creator, modifier } = fragment;
        const orderDetails = fragment.chartData[FormField.ORDER_DETAILS];
        const isoOrderDate = fragment.chartData[FormField.ORDER_DATE];
        const formattedOrderDate = formatDate({
          date: toMoment(isoOrderDate).toDate(),
          locale
        });

        return {
          id,
          active,
          chartingAt,
          creator,
          modifier,
          ...fragment.chartData,
          orderTime: `${formattedOrderDate} ${fragment.chartData[FormField.ORDER_TIME]}`,
          orderDetails: orderDetails.value
        };
      });

      this.setState({ chartHistory: records });
      this.props.updatePatient({ generalOrderRecords: patientHelper.buildGeneralOrderRecords(data) });
    });
  };

  // lazily add nested textbox field to avoid performance issue
  addNestedTextField = (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => {
    chartField.chartContent
      .filter((content) => content.selected && content.label.includes(':_'))
      .forEach((content) => {
        this.props.addNestedTextField(content, formFieldMap);
      });
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { isAuthor, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getGeneralOrderFormFieldBuilderItems({ isAuthor, addNestedTextField: this.addNestedTextField }).forEach(({ isHidden, name, label, ...item }) => {
      if (!isHidden) {
        const translatedLabel = label && intl.formatMessage({ id: label });
        dataMap.set(name, createFormField({ name, label: translatedLabel, ...item }));
      }
    });
    dataMap.get(FormField.ORDER_DESCRIPTION).changeCallback = this.handleOrderDescriptionChange;

    return dataMap;
  };

  handleOrderDescriptionChange = ({ option }) => this.props.setOrderDescription(option?.label);

  beforeSave = (formFieldMap: Map<string, ChartMetaFormField>, locale: Locales): void => {
    const transformedOrderDateField = formFieldMap.get(FormField.ORDER_DATE);
    const orderDateValue = transformedOrderDateField?.value;
    if (orderDateValue) {
      const isoOrderDate = toISO(orderDateValue, locale);
      formFieldMap.set(FormField.ORDER_DATE, { ...transformedOrderDateField, value: isoOrderDate });
    }
  };

  handleSaveClick = () => {
    const { formFieldMap, chartingTime, setOrderDescription, saveChart, locale } = this.props;
    const orderDetails = [];
    const { chartContent } = formFieldMap.get(FormField.ORDER_DETAILS);
    chartContent.forEach((content, index) => {
      if (!content.selected) {
        return;
      }
      if (content.dataType === FormFieldDataType.NESTED_CHOICE) {
        let parentLabel;
        // find the nearest item with dataType CHOICE, it will be the parent item of the nested item
        for (let i = index; i >= 0; i -= 1) {
          if (chartContent[i].dataType === FormFieldDataType.CHOICE) {
            parentLabel = chartContent[i].label;
            break;
          }
        }
        orderDetails.push({ value: `${parentLabel} - ${content.label}`, content: parentLabel });
        return;
      }
      const nestedField = formFieldMap.get(chartHelper.getNestedFieldId(content.value));
      orderDetails.push({ value: nestedField?.value || content.label, content: content.label });
    });

    this.beforeSave(formFieldMap, locale);

    const savedRecords = [];
    orderDetails.forEach((orderDetail) => {
      const chartData = {
        isHold: false,
        isDiscontinued: false,
        [FormField.ORDER_DESCRIPTION]: buildRecordFromField(formFieldMap.get(FormField.ORDER_DESCRIPTION))?.content ?? '',
        [FormField.ORDER_DETAILS]: orderDetail,
        [FormField.ORDER_DATE]: buildRecordFromField(formFieldMap.get(FormField.ORDER_DATE))?.content ?? '',
        [FormField.ORDER_TIME]: buildRecordFromField(formFieldMap.get(FormField.ORDER_TIME))?.content ?? '',
        [CommonFormField.ORDER_START_TIME_OFFSET]: buildRecordFromField(formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET))?.content ?? ''
      };
      const newRecord = {
        ...createBaseFragment({ chartingTime }),
        chartData
      };

      savedRecords.push(newRecord);
    });

    setOrderDescription('');
    saveChart(savedRecords, { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.loadGeneralOrdersData });
  };

  deleteHistory = (record) => {
    appHelper.useLoader(this.props.deleteChartData({ fragmentId: record.id }).then(this.loadGeneralOrdersData).then(this.props.showDeleteSuccess));
  };

  handlePatientCardLinkClick = () => {
    const patientCardUrl = RoutePath.student.patientCard.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
    this.props.history.push(patientCardUrl);
  };

  handleCancelClick = () => {
    this.props.setOrderDescription('');
    this.props.handleDiscardClick(undefined, this.buildDefaultFormFields());
  };

  render() {
    const { formFieldMap } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: GeneralOrdersViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory,
      onPatientCardLinkClick: this.handlePatientCardLinkClick
    };
    return <GeneralOrdersView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  orderDescription: studentSelectors.getSelectedOrderDescription(state)
});

const mapDispatchToProps = (dispatch) => ({
  updatePatient: (newPatientData) => dispatch(appActions.updatePatient(newPatientData)),
  setOrderDescription: (description) => dispatch(studentActions.setSelectedOrderDescription(description))
});

const enhancers = [withSavedPatientChartsPage, withChartLogic, connect(mapStateToProps, mapDispatchToProps)];

export { GeneralOrders as BaseGeneralOrders };
export default compose(...enhancers)(GeneralOrders);

import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, HomeMedicationRecord } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel } from 'components/features/chart/patient-data/home-medication/constants';
import HomeMedicationView, { HomeMedicationViewProps } from './HomeMedicationView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

interface HomeMedicationState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  fragments: ChartFragment[];
  chartHistory: HomeMedicationRecord[];
}

enum SaveType {
  ADD_HOME_MEDICATION = 'addHomeMedication',
  SAVE_CONTINUE = 'saveContinue'
}
class HomeMedication extends Component<ChartComponentProps, HomeMedicationState> {
  static displayName = 'HomeMedication';

  navIds: string[];

  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: [],
      isLocked: false,
      statusFragment: null
    };
    this.navIds = [NAV_ID.PATIENT_DATA_HOME_MEDICATION];
  }

  componentDidMount() {
    this.loadHomeMedicationData();
  }

  loadHomeMedicationData = () => {
    this.props.initState(this.buildFormFields());
    const loadAdmissionHistoryHomeMedication = this.props.loadChartData();
    const loadPatientDataHomeMedication = this.props.loadChartData(undefined, this.navIds);
    appHelper.useLoader(Promise.all([loadAdmissionHistoryHomeMedication, loadPatientDataHomeMedication])).then(this.handleLoadChartData);
  };

  handleLoadChartData = (ApiResponse) => {
    const [loadAdmissionHistoryHomeMedication, loadPatientDataHomeMedication] = ApiResponse;
    const data = [...loadAdmissionHistoryHomeMedication.data, ...loadPatientDataHomeMedication.data];

    const statusFragment = chartHelper.findStatusFragment(data, NAV_ID.HOME_MEDICATION, NAV_ID.ADMISSION_HISTORY);
    const isLocked = chartHelper.isChartLocked(data, this.props.selectedNavId);

    const { isAuthor } = this.props;
    const records = data
      .filter((fragment) => isAuthor || moment(fragment.createdAt).isBefore(statusFragment?.completedAt))
      .map((fragment) => {
        const { fragmentId: id, chartingAt, creator, modifier } = fragment;
        const active = (!isAuthor && !fragment.active && moment(fragment.updatedAt).isAfter(statusFragment?.completedAt)) || fragment.active;
        return { id, active, chartingAt, creator, modifier, ...fragment.chartData };
      });
    const chartFragments = data.filter((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    this.setState({ fragments: chartFragments, chartHistory: records, isLocked, statusFragment });
  };

  buildFormFields = () => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const multiselectRadios = [
      { name: FormField.HOME_MEDICATION_TYPE, label: FormFieldLabel.HOME_MEDICATION_TYPE },
      { name: FormField.MEDICATION, label: FormFieldLabel.MEDICATION },
      { name: FormField.DOSE, label: FormFieldLabel.DOSE },
      { name: FormField.FORM, label: FormFieldLabel.FORM },
      { name: FormField.ROUTE, label: FormFieldLabel.ROUTE },
      { name: FormField.FREQUENCY, label: FormFieldLabel.FREQUENCY },
      { name: FormField.BRAND_NAME, label: FormFieldLabel.BRAND_NAME },
      { name: FormField.GENERIC_NAME, label: FormFieldLabel.GENERIC_NAME },
      { name: FormField.PRODUCT_TYPE, label: FormFieldLabel.PRODUCT_TYPE },
      { name: FormField.PRODUCT, label: FormFieldLabel.PRODUCT },
      { name: FormField.PREPARATION, label: FormFieldLabel.PREPARATION },
      { name: FormField.STRENGTH, label: FormFieldLabel.STRENGTH },
      { name: FormField.OVER_THE_COUNTER_PRODUCT_FORM, label: FormFieldLabel.FORM },
      { name: FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_ROUTE, label: FormFieldLabel.ROUTE }
    ];
    const textBoxes = [
      { name: FormField.INDICATION, label: FormFieldLabel.INDICATION },
      { name: FormField.OVER_THE_COUNTER_PRODUCT_REASON_FOR_TAKING_PRODUCT, label: FormFieldLabel.REASON_FOR_TAKING_PRODUCT },
      { name: FormField.OVER_THE_COUNTER_PRODUCT_RESULT_FOLLOWING_USE, label: FormFieldLabel.RESULT_FOLLOWING_USE },
      { name: FormField.OVER_THE_COUNTER_PRODUCT_DOSE, label: FormFieldLabel.DOSE },
      { name: FormField.STRENGTH, label: FormFieldLabel.STRENGTH },
      { name: FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_REASON_FOR_TAKING_PRODUCT, label: FormFieldLabel.REASON_FOR_TAKING_PRODUCT },
      { name: FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_RESULT_FOLLOWING_USE, label: FormFieldLabel.RESULT_FOLLOWING_USE },
      { name: FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_DOSE, label: FormFieldLabel.DOSE },
      { name: FormField.INFORMATION_RECEIVED_FROM_ORDER, label: 'Other' }
    ];

    const radioChoice = [
      { name: FormField.OVER_THE_COUNTER_PRODUCT_TAKEN_IN_LAST_24HOURS, label: FormFieldLabel.TAKEN_IN_LAST_24HOURS },
      { name: FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_TAKEN_IN_LAST_24HOURS, label: FormFieldLabel.TAKEN_IN_LAST_24HOURS }
    ];

    dataMap.set(FormField.INFORMATION_RECEIVED_FROM, createFormField({ name: FormField.INFORMATION_RECEIVED_FROM, type: FormFieldInputType.MULTISELECT_DROPDOWN }));
    multiselectRadios.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTI_SELECT_RADIO, label })));
    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, label })));
    if (!this.props.isAuthor) {
      const timeTextBox = [{ name: FormField.LAST_DOSE_TIME, label: FormFieldLabel.LAST_DOSE_TIME }];
      const dateTextBox = [{ name: FormField.LAST_DOSE_DATE, label: FormFieldLabel.LAST_DOSE_DATE }];
      timeTextBox.forEach(({ name, label }) =>
        dataMap.set(
          name,
          createFormField({
            name,
            type: FormFieldInputType.TIME,
            label,
            value: '00:00'
          })
        )
      );
      dateTextBox.forEach(({ name, label }) =>
        dataMap.set(
          name,
          createFormField({
            name,
            type: FormFieldInputType.DATE,
            label
          })
        )
      );
    }
    if (this.props.isAuthor) {
      dataMap.set(
        FormField.MEDICATION_TIME_OFFSET,
        createFormField({
          name: FormField.MEDICATION_TIME_OFFSET,
          type: FormFieldInputType.TEXT_BOX,
          label: FormFieldLabel.MEDICATION_TIME_OFFSET,
          value: '00:00'
        })
      );
    }
    radioChoice.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label })));

    return dataMap;
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  handleSaveClick = (type: string) => {
    const saveOptions = {
      [SaveType.ADD_HOME_MEDICATION]: { defaultFormFields: this.buildFormFields(), afterSave: this.loadHomeMedicationData },
      [SaveType.SAVE_CONTINUE]: { defaultFormFields: this.buildFormFields(), afterSave: this.props.afterSave }
    };
    this.props.saveChart([this.buildFragment()], saveOptions[type]);
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const { buildRecordFromField } = chartService;

    const chartData = {};
    Object.keys(FormField).forEach((key) => {
      chartData[FormField[key]] = buildRecordFromField(formFieldMap.get(FormField[key]))?.content ?? '';
    });

    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData } as ChartFragment;
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false }, updateFragment.navElementId)
        .then(this.loadHomeMedicationData)
        .then(this.props.showDeleteSuccess)
    );
  };

  render() {
    const { isLocked, chartHistory, statusFragment } = this.state;
    const { isAuthor, formFieldMap, formSubmittedCount, locale } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: () => this.handleSaveClick(SaveType.SAVE_CONTINUE)
    };
    const homeMedicationActionProps: ChartActionsComponentProps = {
      saveButtonType: 'secondary',
      saveButtonText: 'Add Home Medication',
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: () => this.handleSaveClick(SaveType.ADD_HOME_MEDICATION)
    };
    const viewProps: HomeMedicationViewProps = {
      isLocked,
      statusFragment,
      isAuthor,
      chartActionsProps,
      homeMedicationActionProps,
      formFieldMap,
      formSubmittedCount,
      chartHistory,
      deleteHistory: this.deleteHistory,
      locale
    };
    return <HomeMedicationView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  isAuthor: appSelectors.getIsAuthor(state)
});

const enhancers = [withAdmissionHistory, withChartLogic, connect(mapStateToProps)];

export { HomeMedication as BaseHomeMedication };
export default compose(...enhancers)(HomeMedication);

import { delay } from 'lodash';
import { Component } from 'react';
import { AssessmentRS } from 'models/api-response';
import { REDIRECT_DELAY_TIME } from 'constants/app.constant';
import { navigationService } from 'services';
import { withFormUtilities } from 'components/common';

interface WithAdmissionHistoryProps {
  assessment: AssessmentRS;
  isAuthor: boolean;
  showSaveSuccess: VoidFunction;
  navigateToLandingPage: VoidFunction;
}

const withAdmissionHistory = (WrappedComponent) => {
  class WithAdmissionHistory extends Component<WithAdmissionHistoryProps> {
    navigateToLandingPageWithDelay = () => delay(this.navigateToLandingPage, REDIRECT_DELAY_TIME);

    navigateToLandingPage = () => {
      if (!this.props.isAuthor) {
        navigationService.navigateToAdmissionHistory(this.props.assessment.eolsAssessmentId);
      }
    };

    afterSave = () => Promise.resolve().then(this.props.showSaveSuccess).then(this.navigateToLandingPageWithDelay);

    render() {
      return <WrappedComponent navigateToLandingPage={this.navigateToLandingPageWithDelay} afterSave={this.afterSave} {...this.props} />;
    }
  }

  return withFormUtilities(WithAdmissionHistory);
};

export default withAdmissionHistory;

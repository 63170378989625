import { Component } from 'react';
import { ChartFragmentRS, MedicationFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartFieldContent, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField } from './constants';
import EditMedicationSidebarView, { EditMedicationSidebarViewProps } from './EditMedicationSidebarView';

export interface EditMedicationSidebarProps extends ChartComponentProps {
  medication: MedicationFragment;
  onCloseClick: Function;
  onSaveClick: Function;
  preventScroll?: boolean;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  setIsActiveRrp: Function;
}

class EditMedicationSidebar extends Component<EditMedicationSidebarProps, null> {
  classifications: ChartFieldContent[] = [];
  routes: ChartFieldContent[] = [];

  static displayName = 'EditMedicationSidebar';

  componentDidMount() {
    appHelper.useLoader(this.loadMetadata(), {
      errorMessage: 'Cannot load metadata'
    });
  }

  componentDidUpdate(prevProps: Readonly<EditMedicationSidebarProps>) {
    if (this.props.medication && prevProps.medication !== this.props.medication) {
      this.props.initState(this.buildDefaultFormFields());
    }
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { medication } = this.props;
    const dataMap = new Map();
    dataMap.set(
      FormField.CLASSIFICATION,
      createFormField({
        name: FormField.CLASSIFICATION,
        type: FormFieldInputType.MULTI_SELECT_RADIO,
        contentIds: this.getContentIdsFromLabel(this.classifications, medication.chartData[FormField.CLASSIFICATION]),
        label: 'Classification'
      })
    );
    dataMap.set(
      FormField.CLASSIFICATION_OTHER,
      createFormField({
        name: FormField.CLASSIFICATION_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: this.getFieldValueFromFragment(medication, FormField.CLASSIFICATION_OTHER),
        label: 'Other'
      })
    );
    dataMap.set(
      FormField.ROUTE,
      createFormField({
        name: FormField.ROUTE,
        type: FormFieldInputType.DROPDOWN,
        contentIds: this.getContentIdsFromLabel(this.routes, medication.chartData[FormField.ROUTE]),
        label: 'Route'
      })
    );
    dataMap.set(
      FormField.ROUTE_OTHER,
      createFormField({
        name: FormField.ROUTE_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: this.getFieldValueFromFragment(medication, FormField.ROUTE_OTHER),
        label: 'Other'
      })
    );
    dataMap.set(
      FormField.DOSE,
      createFormField({
        name: FormField.DOSE,
        type: FormFieldInputType.TEXT_BOX,
        value: this.getFieldValueFromFragment(medication, FormField.DOSE),
        label: 'Dose'
      })
    );
    dataMap.set(
      FormField.FREQUENCY,
      createFormField({
        name: FormField.FREQUENCY,
        type: FormFieldInputType.TEXT_BOX,
        value: this.getFieldValueFromFragment(medication, FormField.FREQUENCY),
        label: 'Frequency'
      })
    );
    dataMap.set(
      FormField.DATE_ORDERED,
      createFormField({
        name: FormField.DATE_ORDERED,
        type: FormFieldInputType.DATE,
        value: this.getFieldValueFromFragment(medication, FormField.DATE_ORDERED),
        label: 'Date Ordered (mm-dd-yyyy)'
      })
    );
    dataMap.set(
      FormField.COMMENT,
      createFormField({
        name: FormField.COMMENT,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.COMMENT),
        label: 'Comments and Additional Medication Info'
      })
    );
    dataMap.set(
      FormField.ACTION,
      createFormField({
        name: FormField.ACTION,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.ACTION),
        label: 'Action'
      })
    );
    dataMap.set(
      FormField.SIDE_EFFECT,
      createFormField({
        name: FormField.SIDE_EFFECT,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.SIDE_EFFECT),
        label: 'Side Effects or Adverse Reactions'
      })
    );
    dataMap.set(
      FormField.RECOMMENDED,
      createFormField({
        name: FormField.RECOMMENDED,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.RECOMMENDED),
        label: 'Recommended Dose Ranges'
      })
    );
    dataMap.set(
      FormField.THERAPEUTIC_EFFECT,
      createFormField({
        name: FormField.THERAPEUTIC_EFFECT,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.THERAPEUTIC_EFFECT),
        label: 'Therapeutic Effect'
      })
    );
    dataMap.set(
      FormField.CONTRAINDICATIONS,
      createFormField({
        name: FormField.CONTRAINDICATIONS,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.CONTRAINDICATIONS),
        label: 'Contraindications'
      })
    );
    dataMap.set(
      FormField.LIFE_THREATENING,
      createFormField({
        name: FormField.LIFE_THREATENING,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.LIFE_THREATENING),
        label: 'Life Threatening Considerations'
      })
    );
    dataMap.set(
      FormField.NURSING_INTERVENTIONS,
      createFormField({
        name: FormField.NURSING_INTERVENTIONS,
        type: FormFieldInputType.TEXT_AREA,
        value: this.getFieldValueFromFragment(medication, FormField.NURSING_INTERVENTIONS),
        label: 'Nursing Interventions'
      })
    );
    return dataMap;
  };

  // passing null to patient context because patient context not yet apply to this chart.
  loadMetadata = () =>
    chartService.fetchChartMetadata(NAV_ID.MEDICATIONS, null).then(({ data }) => {
      const { chartFieldContentSet } = data;
      this.classifications = chartFieldContentSet.filter((chartFieldContent) => chartFieldContent.formFieldId === FormField.CLASSIFICATION);
      this.routes = chartFieldContentSet.filter((chartFieldContent) => chartFieldContent.formFieldId === FormField.ROUTE);
    });

  getContentIdsFromLabel = (chartFieldContents: ChartFieldContent[], label: string): string[] => {
    return chartFieldContents.filter((content) => content.label === label).map((content) => content.id) || [];
  };

  getFieldValueFromFragment = (medication: MedicationFragment, id: FormField): string => {
    return medication.chartData[id] || '';
  };

  handleSaveClick = () => {
    const { formFieldMap, medication, onSaveClick } = this.props;
    const { buildRecordFromField } = chartService;
    const chartData = {
      ...medication.chartData,
      [FormField.MEDICATION]: medication.chartData[FormField.MEDICATION],
      [FormField.MEDICATION_OTHER]: medication.chartData[FormField.MEDICATION_OTHER],
      [FormField.CLASSIFICATION]: buildRecordFromField(formFieldMap.get(FormField.CLASSIFICATION))?.content ?? '',
      [FormField.CLASSIFICATION_OTHER]: formFieldMap.get(FormField.CLASSIFICATION_OTHER).value,
      [FormField.ROUTE]: buildRecordFromField(formFieldMap.get(FormField.ROUTE))?.content ?? '',
      [FormField.ROUTE_OTHER]: formFieldMap.get(FormField.ROUTE_OTHER).value,
      [FormField.DOSE]: formFieldMap.get(FormField.DOSE).value,
      [FormField.FREQUENCY]: formFieldMap.get(FormField.FREQUENCY).value,
      [FormField.DATE_ORDERED]: formFieldMap.get(FormField.DATE_ORDERED).value,
      [FormField.COMMENT]: formFieldMap.get(FormField.COMMENT).value,
      [FormField.ACTION]: formFieldMap.get(FormField.ACTION).value,
      [FormField.SIDE_EFFECT]: formFieldMap.get(FormField.SIDE_EFFECT).value,
      [FormField.RECOMMENDED]: formFieldMap.get(FormField.RECOMMENDED).value,
      [FormField.THERAPEUTIC_EFFECT]: formFieldMap.get(FormField.THERAPEUTIC_EFFECT).value,
      [FormField.CONTRAINDICATIONS]: formFieldMap.get(FormField.CONTRAINDICATIONS).value,
      [FormField.LIFE_THREATENING]: formFieldMap.get(FormField.LIFE_THREATENING).value,
      [FormField.NURSING_INTERVENTIONS]: formFieldMap.get(FormField.NURSING_INTERVENTIONS).value
    };
    const updatedMedication: MedicationFragment = {
      ...medication,
      chartData
    };
    this.props.handleSaveClick([updatedMedication], { afterSave: onSaveClick });
  };

  render() {
    const { medication, formFieldMap, formSubmittedCount, onCloseClick, sidebarProps } = this.props;
    const viewProps: EditMedicationSidebarViewProps = {
      open: !!medication,
      medication,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap,
      onSaveClick: this.handleSaveClick,
      onCloseClick,
      sidebarProps
    };
    return <EditMedicationSidebarView {...viewProps} />;
  }
}

export { EditMedicationSidebar as BaseEditMedicationSidebar };
export default withChartLogic(EditMedicationSidebar);

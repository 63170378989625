export enum FormField {
  NEUROVASCULAR_ASSESSMENT_NOTE = 'neurovascularAssessmentNote',
  LOWER_LIMB_SIDE = 'lowerLimbSide',
  LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST = 'lowerLimbIntensityOfPainAtRest',
  LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS = 'lowerLimbIntensityOfPainAtRestActions',
  LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT = 'lowerLimbIntensityOfPainAtRestComment',
  LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT = 'lowerLimbIntensityOfPainOnMovement',
  LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS = 'lowerLimbIntensityOfPainOnMovementActions',
  LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT = 'lowerLimbIntensityOfPainOnMovementComment',
  LOWER_LIMB_SKIN_COLOUR = 'lowerLimbSkinColour',
  LOWER_LIMB_SKIN_COLOUR_OTHER = 'lowerLimbSkinColourOther',
  LOWER_LIMB_SKIN_TEMPERATURE = 'lowerLimbSkinTemperature',
  LOWER_LIMB_SKIN_TEMPERATURE_OTHER = 'lowerLimbSkinTemperatureOther',
  LOWER_LIMB_MOVEMENT_PERONEAL = 'lowerLimbMovementPeroneal',
  LOWER_LIMB_MOVEMENT_PERONEAL_COMMENT = 'lowerLimbMovementPeronealComment',
  LOWER_LIMB_MOVEMENT_TIBIAL = 'lowerLimbMovementTibial',
  LOWER_LIMB_MOVEMENT_TIBIAL_COMMENT = 'lowerLimbMovementTibialComment',
  LOWER_LIMB_SENSATION_PERONEAL = 'lowerLimbSensationPeroneal',
  LOWER_LIMB_SENSATION_TIBIAL = 'lowerLimbSensationTibial',
  LOWER_LIMB_CAPILLARY_REFILL = 'lowerLimbCapillaryRefill',
  LOWER_LIMB_PULSES_POSTERIOR_TIBIAL = 'lowerLimbPulsesPosteriorTibial',
  LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_ACTIONS = 'lowerLimbPulsesPosteriorTibialAbsentActions',
  LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_COMMENT = 'lowerLimbPulsesPosteriorTibialAbsentComment',
  LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_PRESENT_SUB_OPTIONS_1 = 'lowerLimbPulsesPosteriorTibialPresentSubOptions1',
  LOWER_LIMB_PULSES_DORSALIS_PEDIS = 'lowerLimbPulsesDorsalisPedis',
  LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_ACTIONS = 'lowerLimbPulsesDorsalisPedisAbsentActions',
  LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_COMMENT = 'lowerLimbPulsesDorsalisPedisAbsentComment',
  LOWER_LIMB_PULSES_POPLITEAL = 'lowerLimbPulsesPopliteal',
  LOWER_LIMB_PULSES_POPLITEAL_ABSENT_ACTIONS = 'lowerLimbPulsesPoplitealAbsentActions',
  LOWER_LIMB_PULSES_POPLITEAL_ABSENT_COMMENT = 'lowerLimbPulsesPoplitealAbsentComment',
  LOWER_LIMB_PULSES_FEMORAL = 'lowerLimbPulsesFemoral',
  LOWER_LIMB_PULSES_FEMORAL_ABSENT_ACTIONS = 'lowerLimbPulsesFemoralAbsentActions',
  LOWER_LIMB_PULSES_FEMORAL_ABSENT_COMMENT = 'lowerLimbPulsesFemoralAbsentComment',
  LOWER_LIMB_SWELLING = 'lowerLimbSwelling',
  UPPER_LIMB_SIDE = 'upperLimbSide',
  UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST = 'upperLimbIntensityOfPainAtRest',
  UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS = 'upperLimbIntensityOfPainAtRestActions',
  UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT = 'upperLimbIntensityOfPainAtRestComment',
  UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT = 'upperLimbIntensityOfPainOnMovement',
  UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS = 'upperLimbIntensityOfPainOnMovementActions',
  UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT = 'upperLimbIntensityOfPainOnMovementComment',
  UPPER_LIMB_SKIN_COLOUR = 'upperLimbSkinColour',
  UPPER_LIMB_SKIN_COLOUR_OTHER = 'upperLimbSkinColourOther',
  UPPER_LIMB_SKIN_TEMPERATURE = 'upperLimbSkinTemperature',
  UPPER_LIMB_SKIN_TEMPERATURE_OTHER = 'upperLimbSkinTemperatureOther',
  UPPER_LIMB_MOVEMENT_RADIAL = 'upperLimbMovementRadial',
  UPPER_LIMB_MOVEMENT_RADIAL_COMMENT = 'upperLimbMovementRadialComment',
  UPPER_LIMB_MOVEMENT_MEDIAN = 'upperLimbMovementMedian',
  UPPER_LIMB_MOVEMENT_MEDIAN_COMMENT = 'upperLimbMovementMedianComment',
  UPPER_LIMB_MOVEMENT_ULNAR = 'upperLimbMovementUlnar',
  UPPER_LIMB_MOVEMENT_ULNAR_COMMENT = 'upperLimbMovementUlnarComment',
  UPPER_LIMB_SENSATION_RADIAL = 'upperLimbSensationRadial',
  UPPER_LIMB_SENSATION_MEDIAN = 'upperLimbSensationMedian',
  UPPER_LIMB_SENSATION_ULNAR = 'upperLimbSensationUlnar',
  UPPER_LIMB_CAPILLARY_REFILL = 'upperLimbCapillaryRefill',
  UPPER_LIMB_PULSES = 'upperLimbPulses',
  UPPER_LIMB_PULSES_ACTIONS = 'upperLimbPulsesActions',
  UPPER_LIMB_PULSES_COMMENT = 'upperLimbPulsesComment',
  UPPER_LIMB_SWELLING = 'upperLimbSwelling'
}

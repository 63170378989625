export enum FormField {
  HAMILTON_ANXIETY_ASSESSMENT_SCALE = 'ratingScale',
  ANXIOUS_MOOD = 'anxiousMood',
  TENSION = 'tension',
  FEARS = 'fears',
  INSOMNIA = 'insomnia',
  INTELLECTUAL = 'intellectual',
  DEPRESSED_MOOD = 'depressedMood',
  SOMATIC_MUSCULAR = 'somaticMuscular',
  SOMATIC_SENSORY = 'somaticSensory',
  CARDIOVASCULAR_SYMPTOMS = 'cardiovascularSymptoms',
  RESPIRATORY_SYMPTOMS = 'respiratorySymptoms',
  GASTROINTESTINAL_SYMPTOMS = 'gastrointestinalSymptoms',
  GENITOURINARY_SYMPTOMS = 'genitourinarySymptoms',
  AUTONOMIC_SYMPTOMS = 'autonomicSymptoms',
  BEHAVIOR_AT_INTERVIEW = 'behaviorAtInterview'
}

export enum Section {
  HAMILTON_ANXIETY_RATING_SCALE = 'Hamilton Anxiety Rating Scale',
  TOTAL_SCORE = 'Total Score'
}

export enum FormFieldLabel {
  HAMILTON_ANXIETY_SCALE = 'Hamilton Anxiety Scale'
}

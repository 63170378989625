import { Component } from 'react';
import { compose } from 'recompose';
import { v4 } from 'uuid';
import { ChartFragment, Record } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import SymptomAnalysisView from './SymptomAnalysisView';
import { Title } from '../shared/constants';

export interface SymptomAnalysisProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

class SymptomAnalysis extends Component<SymptomAnalysisProps> {
  static displayName = 'SymptomAnalysis';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(FormField.CHIEF_COMPLAINT, createFormField({ name: FormField.CHIEF_COMPLAINT, type: FormFieldInputType.TEXT_AREA, label: 'Chief Complaint' }));
    dataMap.set(FormField.LOCATION, createFormField({ name: FormField.LOCATION, type: FormFieldInputType.TEXT_AREA, label: 'Location' }));
    dataMap.set(FormField.TIMING, createFormField({ name: FormField.TIMING, type: FormFieldInputType.TEXT_AREA, label: 'Timing' }));
    dataMap.set(FormField.QUALITY, createFormField({ name: FormField.QUALITY, type: FormFieldInputType.TEXT_AREA, label: 'Quality' }));
    dataMap.set(FormField.CONTEXT, createFormField({ name: FormField.CONTEXT, type: FormFieldInputType.TEXT_AREA, label: 'Context' }));
    dataMap.set(FormField.SEVERITY, createFormField({ name: FormField.SEVERITY, type: FormFieldInputType.TEXT_AREA, label: 'Severity' }));
    dataMap.set(
      FormField.MODIFYING_ALLEVIATING_FACTORS,
      createFormField({ name: FormField.MODIFYING_ALLEVIATING_FACTORS, type: FormFieldInputType.TEXT_AREA, label: 'Modifying / Alleviating Factors' })
    );
    dataMap.set(
      FormField.ASSOCIATED_SIGNS_AND_SYMPTOM,
      createFormField({ name: FormField.ASSOCIATED_SIGNS_AND_SYMPTOM, type: FormFieldInputType.TEXT_AREA, label: 'Associated Signs and Symptoms' })
    );
    dataMap.set(
      FormField.INTERVENTIONS_AND_RESPONSE,
      createFormField({ name: FormField.INTERVENTIONS_AND_RESPONSE, type: FormFieldInputType.TEXT_AREA, label: 'Interventions and Response' })
    );
    dataMap.set(FormField.PATIENT_PERCEPTION, createFormField({ name: FormField.PATIENT_PERCEPTION, type: FormFieldInputType.TEXT_AREA, label: "Patient's Perception" }));

    return dataMap;
  };

  resetForm = () => this.props.initState(this.buildFormFields());

  buildRecord = (fieldName: string) => {
    const { formFieldMap } = this.props;
    if (!formFieldMap.get(fieldName)?.value) {
      return null;
    }
    return {
      id: v4(),
      active: true,
      formField: fieldName,
      title: formFieldMap.get(fieldName).label,
      content: formFieldMap.get(fieldName).value
    };
  };

  buildInputData = (): Record[] => {
    const newRecords = [];
    newRecords.push(this.buildRecord(FormField.CHIEF_COMPLAINT));
    newRecords.push(this.buildRecord(FormField.LOCATION));
    newRecords.push(this.buildRecord(FormField.TIMING));
    newRecords.push(this.buildRecord(FormField.QUALITY));
    newRecords.push(this.buildRecord(FormField.CONTEXT));
    newRecords.push(this.buildRecord(FormField.SEVERITY));
    newRecords.push(this.buildRecord(FormField.MODIFYING_ALLEVIATING_FACTORS));
    newRecords.push(this.buildRecord(FormField.ASSOCIATED_SIGNS_AND_SYMPTOM));
    newRecords.push(this.buildRecord(FormField.INTERVENTIONS_AND_RESPONSE));
    newRecords.push(this.buildRecord(FormField.PATIENT_PERCEPTION));

    return newRecords.filter((record) => !!record);
  };

  handleSaveClick = () => {
    const inputRecords = this.buildInputData();
    const sectionRecord = {
      sectionTitle: SectionTitle.SYMPTOM_ANALYSIS,
      records: inputRecords
    };
    const record = {
      fragmentTitle: SectionTitle.SYMPTOM_ANALYSIS,
      chartTitle: Title.SYSTEM_ASSESSMENT,
      records: [sectionRecord]
    };

    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: record } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  render() {
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: ChartViewProps = {
      formFieldMap,
      formSubmittedCount,
      chartActionsProps
    };
    return <SymptomAnalysisView {...viewProps} />;
  }
}

const enhancers = [withSavedPatientChartsPage, withChartLogic];
export { SymptomAnalysis as BaseSymptomAnalysis };
export default compose(...enhancers)(SymptomAnalysis);

import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TherapyRecord } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import PhysicalSidebarView, { PhysicalSidebarViewProps } from './PhysicalSidebarView';
import { FormField, FormFieldLabel, OrderTypeValue } from '../shared/constants';
import { handleDisplayIntervention } from '../shared/helpers';

interface PhysicalSidebarProps extends ChartComponentProps {
  selectedNavId: string;
  therapy: TherapyRecord;
  onCloseClick: Function;
  onEditTherapyHandler: Function;
}

interface PhysicalSidebarState {
  isCompleted: boolean;
}

class PhysicalSidebar extends Component<PhysicalSidebarProps, PhysicalSidebarState> {
  static displayName = 'PhysicalSidebar';

  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false
    };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<PhysicalSidebarProps>) {
    if (prevProps.therapy !== this.props.therapy) {
      this.props.initState(this.buildFormFields());
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      { name: FormField.FUNCTIONAL_MOBILITY, label: FormFieldLabel.FUNCTIONAL_MOBILITY },
      { name: FormField.LEFT, label: FormFieldLabel.LEFT },
      { name: FormField.RIGHT, label: FormFieldLabel.RIGHT },
      { name: FormField.SIT_SUPINE, label: FormFieldLabel.SIT_SUPINE },
      { name: FormField.SIT_STAND, label: FormFieldLabel.SIT_STAND },
      { name: FormField.PIVOL, label: FormFieldLabel.PIVOL },
      { name: FormField.DEVIATIONS, label: FormFieldLabel.DEVIATIONS },
      { name: FormField.STAIRS, label: FormFieldLabel.STAIRS },
      { name: FormField.DEVICE, label: FormFieldLabel.DEVICE },
      { name: FormField.ASSISTANCE, label: FormFieldLabel.ASSISTANCE },
      { name: FormField.WEIGHT_BEARING, label: FormFieldLabel.WEIGHT_BEARING },
      { name: FormField.DISTANCE, label: FormFieldLabel.DISTANCE },
      { name: FormField.PHYSICAL_THERAPIST_NAME, label: FormFieldLabel.PHYSICAL_THERAPIST_NAME },
      { name: FormField.PHYSICAL_THERAPIST_SIGNATURE, label: FormFieldLabel.PHYSICAL_THERAPIST_SIGNATURE }
    ];

    const textAreas = [
      { name: FormField.LOCATION, label: FormFieldLabel.LOCATION },
      { name: FormField.THERAPUTIC_EXERCISES, label: FormFieldLabel.THERAPUTIC_EXERCISES },
      { name: FormField.TREATMENT, label: FormFieldLabel.TREATMENT },
      { name: FormField.PATIENT_FAMILY_TEACHING, label: FormFieldLabel.PATIENT_FAMILY_TEACHING }
    ];

    dataMap.set(FormField.INTERVENTION_DATE, createFormField({ name: FormField.INTERVENTION_DATE, type: FormFieldInputType.DATE, label: FormFieldLabel.DATE_LABEL }));
    dataMap.set(FormField.INTERVENTION_TIME, createFormField({ name: FormField.INTERVENTION_TIME, type: FormFieldInputType.TIME, label: FormFieldLabel.TIME }));

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    textAreas.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_AREA })));
    return dataMap;
  };

  buildUpdatedFragment = () => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord } = chartService;
    return {
      [FormField.FUNCTIONAL_MOBILITY]: buildPatientRecord(formFieldMap, FormField.FUNCTIONAL_MOBILITY),
      [FormField.LEFT]: buildPatientRecord(formFieldMap, FormField.LEFT),
      [FormField.RIGHT]: buildPatientRecord(formFieldMap, FormField.RIGHT),
      [FormField.SIT_SUPINE]: buildPatientRecord(formFieldMap, FormField.SIT_SUPINE),
      [FormField.SIT_STAND]: buildPatientRecord(formFieldMap, FormField.SIT_STAND),
      [FormField.PIVOL]: buildPatientRecord(formFieldMap, FormField.PIVOL),
      [FormField.DEVIATIONS]: buildPatientRecord(formFieldMap, FormField.DEVIATIONS),
      [FormField.STAIRS]: buildPatientRecord(formFieldMap, FormField.STAIRS),
      [FormField.DEVICE]: buildPatientRecord(formFieldMap, FormField.DEVICE),
      [FormField.ASSISTANCE]: buildPatientRecord(formFieldMap, FormField.ASSISTANCE),
      [FormField.WEIGHT_BEARING]: buildPatientRecord(formFieldMap, FormField.WEIGHT_BEARING),
      [FormField.DISTANCE]: buildPatientRecord(formFieldMap, FormField.DISTANCE),
      [FormField.LOCATION]: buildPatientRecord(formFieldMap, FormField.LOCATION),
      [FormField.THERAPUTIC_EXERCISES]: buildPatientRecord(formFieldMap, FormField.THERAPUTIC_EXERCISES),
      [FormField.TREATMENT]: buildPatientRecord(formFieldMap, FormField.TREATMENT),
      [FormField.PATIENT_FAMILY_TEACHING]: buildPatientRecord(formFieldMap, FormField.PATIENT_FAMILY_TEACHING),
      [FormField.PHYSICAL_THERAPIST_NAME]: buildPatientRecord(formFieldMap, FormField.PHYSICAL_THERAPIST_NAME),
      [FormField.PHYSICAL_THERAPIST_SIGNATURE]: buildPatientRecord(formFieldMap, FormField.PHYSICAL_THERAPIST_SIGNATURE),
      [FormField.INTERVENTION_DATE]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_DATE),
      [FormField.INTERVENTION_TIME]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_TIME)
    };
  };

  handleSaveClick = () => {
    const { isCompleted } = this.state;
    const updatedTherapy = produce(this.props.therapy, (draft) => {
      const { chartData } = draft;
      const oldInterventionList = chartData[FormField.INTERVENTION_LIST] || [];
      draft.chartData = { ...chartData, [FormField.INTERVENTION_LIST]: [...oldInterventionList, this.buildUpdatedFragment()], isCompleted };
      draft.updatedAt = moment().toISOString();
    });
    this.props.handleSaveClick([updatedTherapy], { afterSave: this.props.onEditTherapyHandler });
  };

  onCheckCompleted = (event) => {
    const isCompleted = event.target.checked;
    this.setState({
      isCompleted
    });
  };

  render() {
    const { therapy, onCloseClick, formFieldMap, formSubmittedCount, sidebarProps } = this.props;
    const { isCompleted } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => {
        this.setState({
          isCompleted: false
        });
        this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' });
      }
    };
    const { chartHistory: intervention } = handleDisplayIntervention(therapy);
    const orderTypeValue = therapy?.chartData[FormField.ORDER_TYPE]?.value;
    const isTherapyCompleted = therapy?.chartData[FormField.IS_COMPLETED];
    const viewProps: PhysicalSidebarViewProps = {
      isOpen: !!therapy && orderTypeValue === OrderTypeValue.PHYSICAL,
      intervention,
      onCloseClick,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      isCompleted,
      isTherapyCompleted,
      onCheckCompleted: this.onCheckCompleted,
      sidebarProps
    };
    return <PhysicalSidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state)
});

export { PhysicalSidebar as BasePhysicalSidebar };
export default compose(connect(mapStateToProps), withChartLogic)(PhysicalSidebar);

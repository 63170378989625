import { orderBy } from 'lodash';
import { Component } from 'react';
import { ProgressNoteFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import ProgressNotesView, { ProgressNotesViewProps } from './ProgressNotesView';

export enum FormField {
  PROGRESS_NOTES = 'progressNotes',
  SIGNATURE = 'signature'
}

export const buildFormFields = (record?: ProgressNoteFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();
  dataMap.set(
    FormField.PROGRESS_NOTES,
    createFormField({
      name: FormField.PROGRESS_NOTES,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Progress Notes',
      value: record?.chartData?.[FormField.PROGRESS_NOTES] || ''
    })
  );
  dataMap.set(
    FormField.SIGNATURE,
    createFormField({
      name: FormField.SIGNATURE,
      type: FormFieldInputType.TEXT_BOX,
      label: 'Provider Signature',
      value: record?.chartData?.[FormField.SIGNATURE] || ''
    })
  );

  return dataMap;
};

interface ProgressNotesState {
  chartHistory: ProgressNoteFragment[];
  selectedRecord: ProgressNoteFragment;
}

class ProgressNotes extends Component<ChartComponentProps, ProgressNotesState> {
  static displayName = 'ProgressNotes';

  constructor(props) {
    super(props);
    this.state = {
      chartHistory: [],
      selectedRecord: null
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory));
  }

  bindChartHistory = ({ data: fragments }) => {
    const records = orderBy(fragments, ['chartingAt'], ['asc']) as ProgressNoteFragment[];
    this.setState({ chartHistory: records });
  };

  afterSave = (isDelete) =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
      .then(this.resetForm)
      .then(() => appHelper.scrollTop());

  resetForm = () => this.props.initState(buildFormFields());

  buildFragment = () => {
    const { formFieldMap } = this.props;
    return {
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData: {
        [FormField.PROGRESS_NOTES]: formFieldMap.get(FormField.PROGRESS_NOTES).value,
        [FormField.SIGNATURE]: formFieldMap.get(FormField.SIGNATURE).value
      }
    };
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: () => this.afterSave(false) });
  };

  handleUpdate = () => {
    this.afterSave(false);
    this.setState({ selectedRecord: null });
  };

  handleDelete = (fragment: ProgressNoteFragment) => this.props.showDeleteConfirmation(() => this.handleConfirmDelete(fragment));

  handleConfirmDelete = (fragment: ProgressNoteFragment) => {
    return appHelper.useLoader(
      this.props.saveChartData({ ...fragment, active: false }).then(() => this.afterSave(true)),
      { errorMessage: 'can not save chart data' }
    );
  };

  handleEdit = (fragment: ProgressNoteFragment) => this.setState({ selectedRecord: fragment });

  handleClose = () => this.setState({ selectedRecord: null });

  render() {
    const { displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, selectedNavId, saveChartData, locale } = this.props;

    const chartActionsProps: ChartActionsComponentProps = {
      isLocked: false,
      onSaveClick: this.handleSaveClick,
      onCancelClick: null,
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: ProgressNotesViewProps = {
      chartHistory: this.state.chartHistory,
      selectedRecord: this.state.selectedRecord,
      chartMetaFormFields: formFieldMap,
      formSubmittedCount,
      selectedNavId,
      onUpdate: this.handleUpdate,
      onDelete: this.handleDelete,
      onEdit: this.handleEdit,
      onClose: this.handleClose,
      saveChartData,
      chartActionsProps,
      locale,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <ProgressNotesView {...viewProps} />;
  }
}

export { ProgressNotes as BaseProgressNotes };
export default withChartLogic(ProgressNotes);

import cx from 'classnames';
import { DiagnosticTestFragment } from 'models/api-response';
import { AccordionBadge, Box } from 'components/common';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import DeleteTracker from './DeleteTracker';

interface SectionDiagnosticTestssProps {
  isFullReport: boolean;
  diagnosticTestsRecords: DiagnosticTestFragment[];
}

const SectionDiagnosticTests = (props: SectionDiagnosticTestssProps) => {
  return (
    <Box mb4>
      <Box mb2>
        <h2>
          {'Diagnostic Tests '}
          <span className="c-els-badge c-els-badge--size-intro">{props.diagnosticTestsRecords.length}</span>
        </h2>
      </Box>
      <div className="report__record">
        {props.diagnosticTestsRecords.map((item) => (
          <div className="record-item" key={item.fragmentId}>
            <Box mb2>
              <h3 className={cx('u-els-margin-right-2x u-els-display-inline-block', { 'row--deleted': !item.active })}>{item?.chartData?.diagnosticTest}</h3>
              {item?.chartData?.dateOfTest && (
                <AccordionBadge>
                  <span>{item?.chartData?.dateOfTest}</span>
                </AccordionBadge>
              )}
              {!item.active && (
                <DeleteTracker className="u-els-margin-left-2x" firstName={item?.modifier?.firstName} lastName={item?.modifier?.lastName} dateTime={item.updatedAt} />
              )}
            </Box>
            <Box mb2>
              <h4>Definition and Description of the Test</h4>
              <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.definitionOfTest} />
            </Box>
            {props.isFullReport && (
              <>
                <Box mb2>
                  <h4>Significance of the Test Being Ordered for this Patient</h4>
                  <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.testOrderedDescription} />
                </Box>
                <div>
                  <h4>Significant Findings and Results</h4>
                  <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.findingsAndResults} />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default SectionDiagnosticTests;

import cx from 'classnames';
import { v4 } from 'uuid';
import { AllergyRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Box, ChartHistory } from 'components/common';

export interface AllergyInformationTableProps {
  chartHistory: AllergyRecord[];
}

const AllergyInformationTable = (props: AllergyInformationTableProps) => {
  const { chartHistory } = props;

  const allergyRenderer = ({ active, allergen }) => {
    return <div className={cx({ 'row--deleted': !active })}>{allergen}</div>;
  };

  const reactionRenderer = ({ active, reactions }) => {
    return (
      <div>
        {reactions?.map((reaction, index) => (
          <div key={`${reaction}-${v4()}`} className={cx({ 'row--deleted': !active })}>{`${index + 1}. ${reaction}`}</div>
        ))}
      </div>
    );
  };

  const dataSource = {
    headers: [
      { text: 'Allergy', field: 'allergen', sortable: true, customRender: allergyRenderer },
      { text: 'Reaction', field: 'reactions', align: ColumnAlign.Left, customRender: reactionRenderer }
    ],
    css: ['column__allergy', ''],
    data: chartHistory,
    defaultSortField: 'allergen',
    defaultSortDirection: 'desc'
  };
  return (
    <Box mt className="allergy-information-table">
      <ChartHistory dataSource={dataSource} showNumEntries={false} />
    </Box>
  );
};

AllergyInformationTable.displayName = 'AllergyInformationTable';
export default AllergyInformationTable;

export enum SectionTitle {
  PSYCHOSOCIAL_INTERVENTIONS = 'Psychosocial Interventions',
  NURSE_PATIENT_RELATIONSHIP = 'Nurse-Patient Relationship',
  PSYCHOPHYSIOLOGIC_FUNCTIONING = 'Psychophysiologic Functioning',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Psychosocial Care Notes'
}

export enum FormField {
  NO_CARE = 'noCareRequired',
  NURSE_PATIENT_RELATIONSHIP = 'nursePatientRelationship',
  PSYCHOPHYSIOLOGIC_FUNCTIONING = 'psychophysiologicFunctioning',
  PATIENT_RESPONSE = 'patientResponse',
  CARE_NOTES = 'psychosocialCareNotes'
}

export enum SectionTitle {
  ALCOHOL_SCREEN = 'Alcohol Screen',
  ALCOHOL_CONSUMPTION_HISTORY = 'Alcohol Consumption History',
  DRINKING = 'Drinking',
  SOBRIETY = 'Sobriety',
  WITHDRAWAL = 'Withdrawal',
  CAGE_QUESTIONNAIRE = 'Cage Questionnaire'
}

export enum FormField {
  DRINK_ALCOHOL = 'youDrinkAlcohol',
  HOW_OFTEN_DRINK = 'consumptionHistoryHowOftenYouDrink',
  HOW_OFTEN_DRINK_OTHER = 'consumptionHistoryHowOftenYouDrinkOther',
  HOW_LONG_DRINK = 'consumptionHistoryHowLongHaveYouBeenDrinking',
  HOW_LONG_DRINK_OTHER = 'consumptionHistoryHowLongHaveYouBeenDrinkingOther',
  WHEN_LAST_DRINK = 'consumptionHistoryWhenYourLastDrink',
  WHEN_LAST_DRINK_OTHER = 'consumptionHistoryWhenYourLastDrinkOther',
  REASON_BREAKING = 'reasonBreaking',
  BEGINNING_DATE_LONGEST_PERIOD = 'consumptionHistoryBeginningDatesLongestPeriodSobriety',
  END_DATE_LONGEST_PERIOD = 'consumptionHistoryEndDatesLongestPeriodSobriety',
  BEGINNING_DATE_MOST_RECENT = 'consumptionHistoryBeginningDatesMostRecentPeriodSobriety',
  END_DATE_MOST_RECENT = 'consumptionHistoryEndDatesMostRecentPeriodSobriety',
  HELP_MAINTAIN_SOBRIETY = 'consumptionHistoryWhatHelpedYouMaintainSobriety',
  HELP_MAINTAIN_SOBRIETY_OTHER = 'historyWhatHelpedYouMaintainSobrietOther',
  HISTORY_ALCOHOL_WITHDRAWAL = 'consumptionHistoryYouHaveHistoryAlcoholWithdrawal',
  CAGE_QUESTIONNAIRE = 'cageQuestionnaire',
  CAGE_QUESTIONNAIRE_CONTAINER = 'cageQuestionnaireContainer'
}

import { ChartFragment, MedicalHistoryFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { Box, Headline } from 'components/common';
import MedicalHistoryEditView from './MedicalHistoryEditView';
import MedicalHistoryReadOnly from './MedicalHistoryReadOnly';

export interface MedicalHistoryViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: MedicalHistoryFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  patientContext: PatientContext;
}

const MedicalHistoryView = (props: MedicalHistoryViewProps) => (
  <>
    {!props.isLocked && (
      <>
        <h1>Medical History</h1>
        <Box mt2>
          <MedicalHistoryEditView {...props} />
        </Box>
      </>
    )}
    {props.isLocked && (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <MedicalHistoryReadOnly selectedRecord={props.selectedRecord} patientContext={props.patientContext} />
      </>
    )}
  </>
);

MedicalHistoryView.displayName = 'MedicalHistoryView';
export default MedicalHistoryView;

import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorFormField, Sidebar } from 'components/common';
import { ELSCheckBox, ELSFlex, ELSFlexItem } from 'components/common/els';
import InterventionHistory from './InterventionHistory';
import NoteShortcutKey from '../shared/NoteShortcutKey';
import { FormField, FormFieldLabel } from '../shared/constants';

export interface RespiratorySidebarViewProps {
  isOpen: boolean;
  intervention: object[];
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  onCloseClick: Function;
  isCompleted: boolean;
  isTherapyCompleted: boolean;
  onCheckCompleted: Function;
  sidebarProps: Function;
}

const RespiratorySidebarView = (props: RespiratorySidebarViewProps) => {
  const { isOpen, chartActionsProps, intervention, formFieldMap, formSubmittedCount, isCompleted, isTherapyCompleted, onCheckCompleted, sidebarProps, onCloseClick } = props;
  const interventionChartHistory = intervention || [];
  return (
    <Sidebar open={isOpen} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps}>
      <Box mt3>
        <h2>Respiratory Therapy Consultation</h2>
      </Box>
      <div className="sidebar__divider" />
      <ELSFlex className="u-flex-grid-2x" wrap>
        {!isTherapyCompleted && (
          <ELSFlexItem w="1o3">
            <h3>Chart Inputs</h3>
            <ELSCheckBox id="isCompleted" checked={isCompleted} labelText={FormFieldLabel.COMPLETE} changeHandler={onCheckCompleted} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FUNCTIONAL_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OXYGEN_SATURATION_ROOM_AIR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OXYGEN_SATURATION_OXYGEN_SUPP)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OXYGEN_THERAPY_DETAIL)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ANTERIAL_BLOOD_DASES)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.AEROSOL_TREATMENT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BILEVEL_POSITIVE_AIRWAY_PRESSURE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INCENTIVE_SPIROMETER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INDUCED_SPUTUM)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INHALER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.POSITIVE_EXPIRATORY_PRESSURE_THERAPY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FUNCTION_TEST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SUCTIONING)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VENTILATOR)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TREATMENT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PATIENT_FAMILY_TEACHING)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RESPIRATORY_THERAPIST_NAME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RESPIRATORY_THERAPIST_SIGNATURE)} formSubmittedCount={formSubmittedCount} />
            <Box mt>
              <h4>Intervention</h4>
            </Box>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INTERVENTION_DATE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.INTERVENTION_TIME)} formSubmittedCount={formSubmittedCount} />
            <Box mt3 mb>
              <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
            </Box>
          </ELSFlexItem>
        )}
        <ELSFlexItem w={isTherapyCompleted ? '1o1' : '2o3'}>
          <h3>Interventions</h3>
          <InterventionHistory chartHistory={interventionChartHistory} />
        </ELSFlexItem>
      </ELSFlex>
      <NoteShortcutKey />
    </Sidebar>
  );
};

RespiratorySidebarView.displayName = 'RespiratorySidebarView';
export default RespiratorySidebarView;

import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { chartHelper } from 'helpers';

export enum FormField {
  DAY = 'day',
  TIME = 'time',
  SEX = 'sex',
  TYPE_DELIVERY = 'typeDelivery',
  TYPE_DELIVERY_OTHER = 'typeDeliveryOther',
  COMPLICATIONS_DURING_DELIVERY = 'complicationsDuringDelivery',
  COMPLICATIONS_DURING_DELIVERY_TEXT = 'complicationsDuringDeliveryText',
  APGAR_SCORE = 'apgarScore',
  RESUSCITATION = 'resuscitation',
  COMMENTS = 'comments',
  EYE_PROPHYLAXIS_TIME = 'eyeProphylaxisTime',
  EYE_PROPHYLAXIS_SITE = 'eyeProphylaxisSite',
  EYE_PROPHYLAXIS_INITIALS = 'eyeProphylaxisInitials',
  VITAMIN_K_TIME = 'vitaminKTime',
  VITAMIN_K_SITE = 'vitaminKSite',
  VITAMIN_K_INITIALS = 'vitaminKInitials',
  NALOXONE_TIME = 'naloxoneTime',
  NALOXONE_SITE = 'naloxoneSite',
  NALOXONE_INITIALS = 'naloxoneInitials',
  HEPATITIS_B_VACCINE_TIME = 'hepatitisBVaccineTime',
  HEPATITIS_B_VACCINE_SITE = 'hepatitisBVaccineSite',
  HEPATITIS_B_VACCINE_INITIALS = 'hepatitisBVaccineInitials',
  HEPATITIS_B_IMMUNE_GLOBULIN_TIME = 'hepatitisBImmuneGlobulinTime',
  HEPATITIS_B_IMMUNE_GLOBULIN_SITE = 'hepatitisBImmuneGlobulinSite',
  HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS = 'hepatitisBImmuneGlobulinInitials',
  OTHER_MEDICATIONS_TIME = 'otherMedicationsTime',
  OTHER_MEDICATIONS_SITE = 'otherMedicationsSite',
  OTHER_MEDICATIONS_INITIALS = 'otherMedicationsInitials',
  NUTRITION = 'nutrition',
  BREAST_MINUTES = 'breastMinutes',
  FORMULA_AMOUNT = 'formulaAmount',
  FORMULA_TYPE = 'formulaType',
  GLUCOSE_AMOUNT = 'glucoseAmount',
  FIRST_WEEK = 'firstVoid',
  FIRST_STOOL = 'firstStool',
  DESCRIBE = 'describe',
  GENERAL_NOTES = 'generalNotes',
  DELIVERY_PLACENTA = 'deliveryPlacenta',
  PROVIDER = 'provider',
  TYPE = 'type',
  CORD = 'cord',
  FIRST_STAGE_HOURS = 'firstStageHours',
  SECOND_STAGE_HOURS = 'secondStageHours',
  SECOND_STAGE_MINUTES = 'secondStageMinutes',
  THIRD_STAGE_MINUTES = 'thirdStageMinutes',
  HEART_RATE_1_MINUTES = 'heartRate1Minutes',
  HEART_RATE_5_MINUTES = 'heartRate5Minutes',
  HEART_RATE_10_MINUTES = 'heartRate10Minutes',
  RESPIRATORY_RATE_1_MINUTES = 'respiratoryRate1Minutes',
  RESPIRATORY_RATE_5_MINUTES = 'respiratoryRate5Minutes',
  RESPIRATORY_RATE_10_MINUTES = 'respiratoryRate10Minutes',
  MUSCLE_TONE_1_MINUTES = 'muscleTone1Minutes',
  MUSCLE_TONE_5_MINUTES = 'muscleTone5Minutes',
  MUSCLE_TONE_10_MINUTES = 'muscleTone10Minutes',
  REFLEX_IRRITABILITY_1_MINUTES = 'reflexIrritability1Minutes',
  REFLEX_IRRITABILITY_5_MINUTES = 'reflexIrritability5Minutes',
  REFLEX_IRRITABILITY_10_MINUTES = 'reflexIrritability10Minutes',
  COLOR_1_MINUTES = 'color1Minutes',
  COLOR_5_MINUTES = 'color5Minutes',
  COLOR_10_MINUTES = 'color10Minutes'
}

export const getDeliveryRoomSummaryFormFieldBuilderItems = (fragment: ChartFragment, locale: Locales): ChartMetaFormFieldParams[] => {
  const { getFragmentContentIds, getFragmentValue, getScaleContent } = chartHelper;
  return [
    {
      name: FormField.SEX,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.SEX,
      type: FormFieldInputType.RADIO_CHOICE,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.SEX),
      isHidden: locale === Locales.EN_AU
    },
    {
      name: FormField.COMPLICATIONS_DURING_DELIVERY,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.COMPLICATIONS_DURING_DELIVERY,
      type: FormFieldInputType.RADIO_CHOICE,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR, FormField.COMPLICATIONS_DURING_DELIVERY)
    },
    {
      name: FormField.FIRST_WEEK,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.FIRST_WEEK,
      type: FormFieldInputType.RADIO_CHOICE,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.FIRST_WEEK)
    },
    {
      name: FormField.FIRST_STOOL,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.FIRST_STOOL,
      type: FormFieldInputType.RADIO_CHOICE,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.FIRST_STOOL)
    },
    {
      name: FormField.NUTRITION,
      type: FormFieldInputType.RADIO_CHOICE,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.NUTRITION)
    },
    {
      name: FormField.TYPE_DELIVERY_OTHER,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.TYPE_DELIVERY_OTHER,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.TYPE_DELIVERY_OTHER)
    },
    {
      name: FormField.EYE_PROPHYLAXIS_SITE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_SITE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.EYE_PROPHYLAXIS_SITE)
    },
    {
      name: FormField.EYE_PROPHYLAXIS_INITIALS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_INITIALS,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.EYE_PROPHYLAXIS_INITIALS)
    },
    {
      name: FormField.VITAMIN_K_SITE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_SITE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.VITAMIN_K_SITE)
    },
    {
      name: FormField.VITAMIN_K_INITIALS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_INITIALS,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.VITAMIN_K_INITIALS)
    },
    {
      name: FormField.NALOXONE_SITE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_SITE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.NALOXONE_SITE)
    },
    {
      name: FormField.NALOXONE_INITIALS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_INITIALS,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.NALOXONE_INITIALS)
    },
    {
      name: FormField.HEPATITIS_B_VACCINE_SITE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_SITE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_VACCINE_SITE)
    },
    {
      name: FormField.HEPATITIS_B_VACCINE_INITIALS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_INITIALS,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_VACCINE_INITIALS)
    },
    {
      name: FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_SITE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE)
    },
    {
      name: FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_INITIALS,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS)
    },
    {
      name: FormField.OTHER_MEDICATIONS_SITE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_SITE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.OTHER_MEDICATIONS_SITE)
    },
    {
      name: FormField.OTHER_MEDICATIONS_INITIALS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_INITIALS,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.OTHER_MEDICATIONS_INITIALS)
    },
    {
      name: FormField.BREAST_MINUTES,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MINUTES,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.BREAST_MINUTES)
    },
    {
      name: FormField.FORMULA_AMOUNT,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.AMOUNT,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.FORMULA_AMOUNT)
    },
    {
      name: FormField.GLUCOSE_AMOUNT,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.AMOUNT,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.GLUCOSE_AMOUNT)
    },
    {
      name: FormField.DELIVERY_PLACENTA,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY_PLACENTA,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.DELIVERY_PLACENTA)
    },
    {
      name: FormField.PROVIDER,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.PROVIDER,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.PROVIDER)
    },
    {
      name: FormField.TYPE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.TYPE,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.TYPE)
    },
    {
      name: FormField.CORD,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.CORD,
      type: FormFieldInputType.TEXT_BOX,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.CORD)
    },
    {
      name: FormField.TYPE_DELIVERY,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.TYPE_DELIVERY,
      type: FormFieldInputType.CHECK_BOX,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.TYPE_DELIVERY)
    },
    {
      name: FormField.RESUSCITATION,
      type: FormFieldInputType.CHECK_BOX,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.RESUSCITATION)
    },
    {
      name: FormField.DAY,
      type: FormFieldInputType.TEXT_BOX,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.DAY,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.DAY)
    },
    {
      name: FormField.COMPLICATIONS_DURING_DELIVERY_TEXT,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.COMPLICATIONS_DURING_DELIVERY_TEXT,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR, FormField.COMPLICATIONS_DURING_DELIVERY_TEXT)
    },
    {
      name: FormField.COMMENTS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.COMMENTS,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.COMMENTS)
    },
    {
      name: FormField.GENERAL_NOTES,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.GENERAL_NOTES,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.GENERAL_NOTES, FormField.GENERAL_NOTES)
    },
    {
      name: FormField.DESCRIBE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.DESCRIBE,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.DESCRIBE)
    },
    {
      name: FormField.TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DELIVERY, FormField.TIME) || '00:00'
    },
    {
      name: FormField.EYE_PROPHYLAXIS_TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.EYE_PROPHYLAXIS_TIME) || '00:00'
    },
    {
      name: FormField.VITAMIN_K_TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.VITAMIN_K_TIME) || '00:00'
    },
    {
      name: FormField.NALOXONE_TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.NALOXONE_TIME) || '00:00'
    },
    {
      name: FormField.HEPATITIS_B_VACCINE_TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_VACCINE_TIME) || '00:00'
    },
    {
      name: FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME) || '00:00'
    },
    {
      name: FormField.OTHER_MEDICATIONS_TIME,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MEDICATIONS_TIME,
      type: FormFieldInputType.TIME,
      value: getFragmentValue(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INITIAL_CARE_NEW_BORN, FormField.OTHER_MEDICATIONS_TIME) || '00:00'
    },
    {
      name: FormField.FORMULA_TYPE,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.FORMULA_TYPE,
      type: FormFieldInputType.DROPDOWN,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.INTAKE_OUTPUT, FormField.FORMULA_TYPE)
    },
    {
      name: FormField.FIRST_STAGE_HOURS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.HOURS,
      type: FormFieldInputType.DROPDOWN,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR, FormField.FIRST_STAGE_HOURS)
    },
    {
      name: FormField.SECOND_STAGE_HOURS,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.HOURS,
      type: FormFieldInputType.DROPDOWN,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR, FormField.SECOND_STAGE_HOURS)
    },
    {
      name: FormField.SECOND_STAGE_MINUTES,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MINUTES,
      type: FormFieldInputType.DROPDOWN,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR, FormField.SECOND_STAGE_MINUTES)
    },
    {
      name: FormField.THIRD_STAGE_MINUTES,
      label: LanguageKeys.DELIVERY_ROOM_SUMMARY.MINUTES,
      type: FormFieldInputType.DROPDOWN,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.DURATION_OF_STAGE_OF_LABOR, FormField.THIRD_STAGE_MINUTES)
    },
    {
      name: FormField.APGAR_SCORE,
      type: FormFieldInputType.SCALE,
      scaleContent: getScaleContent(fragment, LanguageKeys.DELIVERY_ROOM_SUMMARY.APGAR_SCALE)
    }
  ];
};

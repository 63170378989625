import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constant';

export interface PsychiatricHistoryEditViewProps {
  selectedRecord: ChartFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const PsychiatricHistoryEditView = (props: PsychiatricHistoryEditViewProps) => {
  const { formFieldMap, formSubmittedCount, selectedRecord, chartActionsProps } = props;
  return (
    <>
      <Box mt2>
        <h2 className="u-els-margin-bottom-2x">{SectionTitle.PATIENT_INFORMATION}</h2>
        <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.CHIEF_INFORMANT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.CHIEF_COMPLAINT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.HISTORY_CURRENT_PROBLEM)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.ALLERGIES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <SectionDivider />
        <h2 className="u-els-margin-bottom-2x">{SectionTitle.PSYCHIATRIC_HISTORY}</h2>
        <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.UNABLE_ASSESS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-3x u-flex-row-gap-2x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PAST_PSYCHIATRIC_HISTORY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.SUICIDE_HISTORY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.OUTPATIENT_TREATMENT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.ALCOHOL_USE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.SUBSTANCE_USE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.ELECTROCONVULSIVE_THERAPY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField formField={formFieldMap.get(FormField.FAMILY_HISTORY)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission fragmentType={selectedRecord?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </>
  );
};

PsychiatricHistoryEditView.displayName = 'PsychiatricHistoryEditView';
export default PsychiatricHistoryEditView;

import cx from 'classnames';
import { startCase } from 'lodash';
import { SyntheticEvent } from 'react';
import { Gender, PhaseType } from 'models/enum';
import { CaseStudy, CaseStudySequence } from 'models/ui';
import { assetHelper } from 'helpers';
import { chartService } from 'services';
import { Avatar, Box } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { MenuItem } from './CaseStudyLanding';
import CaseStudyOverviewView from './CaseStudyOverviewView';
import PhasesDetailView from './PhasesDetailView';
import './case.study.landing.scss';
import ageIcon from '../../../assets/img/age-icon.png';

const CASE_STUDY_OVERVIEW = 'Case Study Overview';

interface MenuItemProps {
  item: MenuItem;
  onClick?(event: SyntheticEvent): void;
}

const Item = ({ item: { label, active, completed }, onClick }: MenuItemProps) => (
  <div
    onClick={active ? onClick : null}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
    className={cx('menu__item', { 'menu__item--active': active }, { 'menu__item--completed': completed || label === CASE_STUDY_OVERVIEW })}
    aria-disabled={active ? 'false' : 'true'}
  >
    <div className="menu__item-label">{label}</div>
    {completed && <ELSIcon name="checkmark" prefix="HMDS" color="positive" size="1x" align="sub" />}
  </div>
);
export interface CaseStudyLandingViewProps {
  caseStudy: CaseStudy;
  sequence: CaseStudySequence;
  menu: MenuItem[];
  activeMenu: string;
  phaseCompleted: number;
  totalPhase: number;
  chartCompleted: number;
  timeSpent: number;
  onMenuClick: Function;
  onStartPhaseClick: Function;
}

const CaseStudyLandingView = (props: CaseStudyLandingViewProps) => {
  if (!props.sequence || !props.caseStudy) return null;
  const { patient } = props.caseStudy;
  const patientShortName = chartService.formatShortName({ firstName: patient.firstName, lastName: patient.lastName });
  const gender = startCase(patient.gender.toLowerCase());
  const isOverviewPhase = props.sequence.contentType === PhaseType.OVERVIEW;

  return (
    <div className="case-study-landing">
      <ELSFlex spaceBetween>
        <ELSFlexItem className="u-els-background-color-n0 menu">
          <div className="menu__wrapper">
            {props.menu.map((item) => (
              <Item key={item.label} item={item} onClick={() => props.onMenuClick(item)} />
            ))}
          </div>
        </ELSFlexItem>
        <ELSFlexItem className="case-study-phases" grow>
          <Box p2>
            <p className="case-study__diagnosis">{`${props.caseStudy.title}`}</p>
            {props.sequence.contentType === PhaseType.OVERVIEW && <CaseStudyOverviewView sequence={props.sequence} />}
            {props.sequence.contentType === PhaseType.PHASE && <PhasesDetailView sequence={props.sequence} />}
          </Box>
        </ELSFlexItem>
        <ELSFlexItem>
          <div className="info">
            <Avatar className="u-els-margin-top-3x" name={patientShortName} image={assetHelper.getStaticPatientAvatar(patient.avatarUrl)} />
            <div className="info__patient-name">{`${patient.lastName}, ${patient.firstName}`}</div>
            <Box mt2 className="u-els-display-flex">
              <div className="info__patient-age">
                <span className="icon">
                  <img src={ageIcon} alt="age" />
                </span>
                <span>{patient.age}</span>
              </div>
              <div className="info__patient-gender">
                <span className="icon">
                  {patient.gender === Gender.MALE && <ELSIcon name="man" prefix="gizmo" size="2x" align="sub" />}
                  {patient.gender === Gender.FEMALE && <ELSIcon name="woman" prefix="gizmo" size="2x" align="sub" />}
                </span>
                <span>{gender}</span>
              </div>
            </Box>
            <Box mt2>
              {isOverviewPhase && <ELSButton text="Start Case Study" onClick={props.onStartPhaseClick} />}
              {!isOverviewPhase && <ELSButton text="Start Phase" isDisabled={props.phaseCompleted === props.totalPhase} onClick={props.onStartPhaseClick} />}
            </Box>

            <p className="info__progress-title u-els-display-none">Progress</p>
            <div className="info__progress-phases u-els-display-none">
              <p className="info__progress-label">Phases Completed</p>
              <p className="info__progress-content">{`${props.phaseCompleted}/${props.totalPhase}`}</p>
            </div>
            <ELSFlex className="info__progress u-els-display-none">
              <ELSFlexItem className="info__progress-chart">
                <p className="info__progress-label">Charts Completed</p>
                <p className="info__progress-content">
                  <span>{props.chartCompleted}</span>
                  <span className="info__progress-content--small">charts</span>
                </p>
              </ELSFlexItem>
              <ELSFlexItem className="info__progress-time">
                <p className="info__progress-label">Total Time Spent</p>
                <p className="info__progress-content">
                  <span>{props.timeSpent}</span>
                  <span className="info__progress-content--small">min</span>
                </p>
              </ELSFlexItem>
            </ELSFlex>
          </div>
        </ELSFlexItem>
      </ELSFlex>
    </div>
  );
};

CaseStudyLandingView.displayName = 'CaseStudyLandingView';
export default CaseStudyLandingView;

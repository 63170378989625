import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentStatus } from 'models/enum';
import { RouteParams, RoutePath, SectionNavIdPatterns } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';
import SavedPatientTeachingView from './SavedPatientTeachingView';
import { FormField, SectionTitle } from '../constants';

interface MatchParams {
  assessmentId: string;
}

class SavedPatientTeaching extends Component<RouteComponentProps<MatchParams>> {
  static displayName = 'SavedPatientTeaching';

  isCompleted = (fragment: ChartFragment) => {
    return chartHelper.getFragmentValue(fragment, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_IS_COMPLETE);
  };

  renderTeachingTopicColumn = (fragment: ChartFragment) => {
    let teachingTopic = chartHelper.getFragmentRecords(fragment, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_TOPICS)[0]?.content;
    const subCategory = chartHelper.getFragmentRecords(fragment, SectionTitle.PATIENT_TEACHING, FormField.SUB_CATEGORY)[0]?.content;
    if (teachingTopic === 'Other') {
      teachingTopic = chartHelper.getFragmentRecords(fragment, SectionTitle.PATIENT_TEACHING, FormField.TEACHING_TOPICS_OTHER)[0]?.content;
    }
    return `${subCategory} : ${teachingTopic}`;
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: dateTimeHelper.toDateTime(fragment.chartingAt),
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      teachingTopic: this.renderTeachingTopicColumn(fragment),
      navElementId: fragment.navElementId,
      category: chartHelper.getFragmentRecords(fragment, SectionTitle.PATIENT_TEACHING, FormField.CATEGORY)[0]?.content,
      status: this.isCompleted(fragment) ? FragmentStatus.COMPLETED : fragment.chartData.statusUpdate
    }));
  };

  handleUpdate = (row) => {
    const { assessmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.student.patientTeaching.patientTeaching.replace(RouteParams.ASSESSMENT_ID, assessmentId),
      search: `?id=${encodeURIComponent(row.id)}`
    });
  };

  buildStatusBadge = (fragment: MultiSectionsFragment) => {
    const status = this.isCompleted(fragment) ? FragmentStatus.COMPLETED : fragment.chartData.statusUpdate;
    const isStatusBadgeActive = status === FragmentStatus.REINFORCED || status === FragmentStatus.INITIAL;
    return { status, isStatusBadgeActive };
  };

  render() {
    const deleteMessage = `Are you sure you want to delete this entry? Note that the most recent Patient Teaching update will be deleted.
      If there is another record associated with this Patient Teaching, the next most recent record will be displayed.`;

    const viewProps = {
      buildStatusBadge: this.buildStatusBadge,
      chartTitle: 'Saved Patient Teaching',
      deleteMessage,
      hideDelete: true,
      navIds: [SectionNavIdPatterns.PATIENT_TEACHING],
      onUpdate: this.handleUpdate,
      toTableData: this.toTableData,
      ...this.props
    };
    return <SavedPatientTeachingView {...viewProps} />;
  }
}

export { SavedPatientTeaching as BaseSavedPatientTeaching };
export default withRouter(SavedPatientTeaching);

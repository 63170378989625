import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentStatus } from 'models/enum';
import { Locales, RouteParams, RoutePath, SectionNavIdPatterns } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { SavedPatientWrapper } from 'components/common';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';
import { FormField } from '../constants';

interface MatchParams {
  assessmentId: string;
}

interface SavedWoundsProps {
  enableMultiStepsAuthoring: Function;
  hasUnsavedChanges: boolean;
  locale: Locales;
}

class SavedWounds extends Component<SavedWoundsProps & RouteComponentProps<MatchParams>> {
  static displayName = 'SavedWounds';

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
  }

  isHealedFragment = (fragment: ChartFragment) => {
    return chartHelper.getWoundSavedValue(fragment, FormField.WOUND_HEALED, 'content')[0];
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: dateTimeHelper.formatDate({ date: dateTimeHelper.toMomentWithParsers(fragment.chartingAt).toDate(), locale: this.props.locale, includeTime: true }),
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      groupFragmentRef: fragment.groupFragmentRef,
      location: chartHelper.getWoundSavedValue(fragment, FormField.LOCATION, 'content')[0],
      navElementId: fragment.navElementId,
      woundCategory: chartHelper.getWoundSavedValue(fragment, FormField.WOUND_CATEGORY, 'content')[0],
      woundStatus: this.isHealedFragment(fragment) ? FragmentStatus.HEALED : FragmentStatus.ACTIVE
    }));
  };

  handleWoundUpdate = (row) => {
    const { assessmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.student.wounds.wounds.replace(RouteParams.ASSESSMENT_ID, assessmentId),
      search: `?id=${encodeURIComponent(row.id)}`
    });
  };

  buildStatusBadge = (fragment: MultiSectionsFragment) => {
    const status = this.isHealedFragment(fragment) ? FragmentStatus.HEALED : FragmentStatus.ACTIVE;
    const isStatusBadgeActive = status === FragmentStatus.ACTIVE;
    return { status, isStatusBadgeActive };
  };

  render() {
    const columns = {
      location: {
        field: 'location',
        header: 'Location'
      },
      status: {
        field: 'woundStatus',
        header: 'Wound Status',
        active: FragmentStatus.ACTIVE
      },
      type: {
        field: 'woundCategory',
        header: 'Wound Category'
      }
    };
    const deleteMessage = `Are you sure you want to delete this entry? Note that the most recent wound update will be deleted.
      If there is another record associated with this wound location, the next most recent record will be displayed.`;

    const viewProps = {
      buildStatusBadge: this.buildStatusBadge,
      chartTitle: 'Saved Wounds Charting',
      columns,
      deleteMessage,
      hideDelete: true,
      navIds: [SectionNavIdPatterns.WOUNDS],
      onRecordUpdate: this.handleWoundUpdate,
      toTableData: this.toTableData,
      ...this.props
    };

    return <SavedPatientWrapper {...viewProps} />;
  }
}

export { SavedWounds as BaseSavedWounds };
export default withRouter(SavedWounds);

import { ColumnAlign } from 'models/ui';
import { Box, Sidebar } from 'components/common';
import {
  createOrderDropDownItems,
  dataTypeDropDownItems,
  dropDownItems,
  tableHeadersContentOverrides,
  tableHeadersValidationOverrides,
  validationActionTypeOptions,
  validationTypeOptions
} from './constants';
import { ChartFieldContentMeta, ChartFieldValidationMeta } from './MetadataInput';
import { ELSButton, ELSDataTable, ELSDropDown, ELSFlexItem, ELSTextBox } from '../../common/els';

export interface OverrideSideBarViewProps {
  chartFieldContentData: ChartFieldContentMeta[];
  chartFieldValidations: ChartFieldValidationMeta[];
  onContentConditionChange: Function;
  onContentActiveChange: Function;
  onContentValueChange: Function;
  onContentLabelChange: Function;
  onContentOrderType: Function;
  onContentDataType: Function;
  onValidationConditionChange: Function;
  onValidationValidationTypeChange: Function;
  onValidationValidationActionTypeChange: Function;
  onValidationMessageChange: Function;
  onValidationMinRangeChange: Function;
  onValidationMaxRangeChange: Function;
  onValidationRegexChange: Function;
  onContentContextSave: Function;
  onValidationContextSave: Function;
  isContentSideBarOpen: boolean;
  isValidationSideBarOpen: boolean;
  openContentSideBar: Function;
  closeContentSideBar: Function;
  openValidationSideBar: Function;
  closeValidationSideBar: Function;
  customRenderCondition: Function;
  customRenderValue: Function;
  customRenderActive: Function;
  customRenderLabel: Function;
  customRenderOrder: Function;
  customRenderDataType: Function;
  customRenderValidationType: Function;
  customRenderValidationActionType: Function;
  customRenderMessage: Function;
  customRenderMinRange: Function;
  customRenderMaxRange: Function;
  customRenderRegex: Function;
  selectedContent?: ChartFieldContentMeta;
  selectedValidation?: ChartFieldValidationMeta;
  renderContentContextDelete: Function;
  renderValidationContextDelete: Function;
}

/* eslint-disable react/no-array-index-key */
/* eslint-disable-next-line sonarjs/cognitive-complexity */
const OverrideSideBarView = (props: OverrideSideBarViewProps) => (
  <Box p3>
    <Sidebar open={props.isContentSideBarOpen} onClose={props.closeContentSideBar}>
      <div>
        <ELSButton onClick={props.closeContentSideBar} text="Close" preventAutoPending />
        <h2>Content Attribute Overrides</h2>

        <div className="flex-container">
          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Condition</h4>
            <ELSTextBox changeHandler={props.onContentConditionChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Active</h4>
            <ELSDropDown changeHandler={props.onContentActiveChange} options={dropDownItems} size="50" />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Value</h4>
            <ELSTextBox changeHandler={props.onContentValueChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Label</h4>
            <ELSTextBox changeHandler={props.onContentLabelChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Order</h4>
            <ELSDropDown changeHandler={props.onContentOrderType} options={createOrderDropDownItems()} size="50" />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Data Type</h4>
            <ELSDropDown changeHandler={props.onContentDataType} options={dataTypeDropDownItems} size="50" />
          </ELSFlexItem>

          <div className="u-els-color-primary u-els-margin-top-2x">
            <ELSButton onClick={props.onContentContextSave} text="Save" />
          </div>
        </div>

        <ELSDataTable
          data={props.chartFieldContentData.filter((item) => item.id === props?.selectedContent?.id).flatMap((content) => content.attrOverrides)}
          defaultSortDirection="desc"
        >
          {/* eslint-disable-next-line sonarjs/cognitive-complexity,array-callback-return,consistent-return */}
          {tableHeadersContentOverrides.map((col) => {
            if (col.field === 'condition') {
              return <column key={`${col.field}`} customRender={props.customRenderCondition} field={col.field} header={col.text} />;
            }
            if (col.field === 'active') {
              return <column key={`${col.field}`} customRender={props.customRenderActive} field={col.field} header={col.text} />;
            }
            if (col.field === 'value') {
              return <column key={`${col.field}`} customRender={props.customRenderValue} field={col.field} header={col.text} />;
            }
            if (col.field === 'label') {
              return <column key={`${col.field}`} customRender={props.customRenderLabel} field={col.field} header={col.text} />;
            }
            if (col.field === 'order') {
              return <column key={`${col.field}`} customRender={props.customRenderOrder} field={col.field} header={col.text} />;
            }
            if (col.field === 'dataType') {
              return <column key={`${col.field}`} customRender={props.customRenderDataType} field={col.field} header={col.text} />;
            }
            return <column key={`${col.field}`} customRender={props.renderContentContextDelete} align={ColumnAlign.Left} section={col.section} />;
          })}
        </ELSDataTable>
      </div>
    </Sidebar>

    <Sidebar open={props.isValidationSideBarOpen} onClose={props.closeValidationSideBar}>
      <div>
        <ELSButton onClick={props.closeValidationSideBar} text="Close" preventAutoPending />
        <h2>Validation Attribute Overrides</h2>

        <div className="flex-container">
          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Condition</h4>
            <ELSTextBox changeHandler={props.onValidationConditionChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Validation Type</h4>
            <ELSDropDown changeHandler={props.onValidationValidationTypeChange} options={validationTypeOptions} size="50" />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Validation Action Type</h4>
            <ELSDropDown changeHandler={props.onValidationValidationActionTypeChange} options={validationActionTypeOptions} size="50" />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Message</h4>
            <ELSTextBox changeHandler={props.onValidationMessageChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Min Range</h4>
            <ELSTextBox changeHandler={props.onValidationMinRangeChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <ELSFlexItem className="u-els-margin-1x">
            <h4 className="u-els-color-primary">Max Range</h4>
            <ELSTextBox changeHandler={props.onValidationMaxRangeChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
          </ELSFlexItem>

          <div className="u-els-color-primary u-els-margin-top-2x">
            <ELSButton onClick={props.onValidationContextSave} text="Save" />
          </div>
        </div>
        <ELSDataTable
          data={props.chartFieldValidations.filter((validation) => validation.id === props?.selectedValidation?.id).flatMap((overrides) => overrides.attrOverrides)}
          defaultSortDirection="desc"
        >
          {/* eslint-disable-next-line array-callback-return,consistent-return */}
          {tableHeadersValidationOverrides.map((col) => {
            if (col.field === 'condition') {
              return <column key={`${col.field}`} customRender={props.customRenderCondition} field={col.field} header={col.text} />;
            }
            if (col.field === 'validationType') {
              return <column key={`${col.field}`} customRender={props.customRenderValidationType} field={col.field} header={col.text} />;
            }
            if (col.field === 'validationActionType') {
              return <column key={`${col.field}`} customRender={props.customRenderValidationActionType} field={col.field} header={col.text} />;
            }
            if (col.field === 'message') {
              return <column key={`${col.field}`} customRender={props.customRenderMessage} field={col.field} header={col.text} />;
            }
            if (col.field === 'minRange') {
              return <column key={`${col.field}`} customRender={props.customRenderMinRange} field={col.field} header={col.text} />;
            }
            if (col.field === 'maxRange') {
              return <column key={`${col.field}`} customRender={props.customRenderMaxRange} field={col.field} header={col.text} />;
            }
            if (col.field === 'regex') {
              return <column key={`${col.field}`} customRender={props.customRenderRegex} field={col.field} header={col.text} />;
            }
            return <column key={`${col.field}`} customRender={props.renderValidationContextDelete} align={ColumnAlign.Left} section={col.section} />;
          })}
        </ELSDataTable>
      </div>
    </Sidebar>
  </Box>
);
export default OverrideSideBarView;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartLabel, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import SmokingScreenView from './SmokingScreenView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export enum SectionTitle {
  SMOKING_SCREEN = 'Smoking Screen',
  SMOKER_STATUS = 'Smoker Status',
  USING = 'Using',
  ALREADY_QUIT = 'Already Quit',
  PLAN_QUIT = 'Plan to Quit'
}

export enum FormField {
  LIVE_WITH_SMOKER = 'liveWithSmoker',
  IS_SMOKER = 'liveWithSmokerYesType',
  SMOKER_STATUS = 'smokerStatus',
  TOBACCO_TYPE = 'smokingHistoryTypeOfTobacco',
  TOBACCO_TYPE_OTHER = 'smokingHistoryTypeOfTobaccoOther',
  TOBACCO_HOW_MUCH = 'smokingHistoryHowMuchTobacco',
  TOBACCO_HOW_LONG = 'smokingHistoryHowLongSmoking',
  TOBACCO_QUIT = 'smokingHistoryPlanToQuit',
  TOBACCO_QUIT_OTHER = 'smokingHistoryPlanToQuitOther',
  TOBACCO_QUIT_WHEN = 'smokingHistoryWhenYouQuitTobacco',
  TEMPTATION_TRIGGER = 'temptationTriggers',
  QUIT_MOTIVATION = 'smokingHistoryWhatYourMotivation',
  QUIT_CONCERN = 'smokingHistoryAnyConcernsQuitting',
  QUIT_START_AGAIN = 'smokingHistoryWhyYouStartUsingTobaccoAgain',
  QUIT_DATE = 'smokingHistoryWhatYourQuitDate',
  USE_NICOTINE_REPLACEMENT = 'smokingHistoryUseNicotineReplacement'
}

interface SmokingScreenState {
  isLocked: boolean;
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class SmokingScreen extends Component<ChartComponentProps, SmokingScreenState> {
  static displayName = 'SmokingScreen';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.SMOKING_SCREEN, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentValue, getFragmentContentIds } = chartHelper;
    const dataMap = new Map();
    dataMap.set(
      FormField.LIVE_WITH_SMOKER,
      createFormField({
        name: FormField.LIVE_WITH_SMOKER,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Do you live with a smoker?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.SMOKING_SCREEN, FormField.LIVE_WITH_SMOKER)
      })
    );
    dataMap.set(
      FormField.IS_SMOKER,
      createFormField({
        name: FormField.IS_SMOKER,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Where is the smoker?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.SMOKING_SCREEN, FormField.IS_SMOKER)
      })
    );
    dataMap.set(
      FormField.SMOKER_STATUS,
      createFormField({
        name: FormField.SMOKER_STATUS,
        type: FormFieldInputType.RADIO_CHOICE,
        contentIds: getFragmentContentIds(fragment, SectionTitle.SMOKER_STATUS, FormField.SMOKER_STATUS)
      })
    );
    dataMap.set(
      FormField.TOBACCO_TYPE,
      createFormField({
        name: FormField.TOBACCO_TYPE,
        type: FormFieldInputType.DROPDOWN,
        label: 'What type of tobacco?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.TOBACCO_TYPE)
      })
    );
    dataMap.set(
      FormField.TOBACCO_TYPE_OTHER,
      createFormField({
        name: FormField.TOBACCO_TYPE_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.USING, FormField.TOBACCO_TYPE_OTHER)
      })
    );
    dataMap.set(
      FormField.TOBACCO_HOW_MUCH,
      createFormField({
        name: FormField.TOBACCO_HOW_MUCH,
        type: FormFieldInputType.DROPDOWN,
        label: 'How much tobacco do you use?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.TOBACCO_HOW_MUCH)
      })
    );
    dataMap.set(
      FormField.TOBACCO_HOW_LONG,
      createFormField({
        name: FormField.TOBACCO_HOW_LONG,
        type: FormFieldInputType.DROPDOWN,
        label: 'How long have you been smoking?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.TOBACCO_HOW_LONG)
      })
    );
    dataMap.set(
      FormField.TOBACCO_QUIT,
      createFormField({
        name: FormField.TOBACCO_QUIT,
        type: FormFieldInputType.DROPDOWN,
        label: 'Do you plan to quit?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.USING, FormField.TOBACCO_QUIT)
      })
    );
    dataMap.set(
      FormField.TOBACCO_QUIT_OTHER,
      createFormField({
        name: FormField.TOBACCO_QUIT_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other',
        value: getFragmentValue(fragment, SectionTitle.USING, FormField.TOBACCO_QUIT_OTHER)
      })
    );
    dataMap.set(
      FormField.TOBACCO_QUIT_WHEN,
      createFormField({
        name: FormField.TOBACCO_QUIT_WHEN,
        type: FormFieldInputType.DROPDOWN,
        label: 'When did you quit using tobacco?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.ALREADY_QUIT, FormField.TOBACCO_QUIT_WHEN)
      })
    );
    dataMap.set(
      FormField.TEMPTATION_TRIGGER,
      createFormField({
        name: FormField.TEMPTATION_TRIGGER,
        type: FormFieldInputType.DROPDOWN,
        label: 'Temptation Triggers',
        contentIds: getFragmentContentIds(fragment, SectionTitle.ALREADY_QUIT, FormField.TEMPTATION_TRIGGER)
      })
    );
    dataMap.set(
      FormField.QUIT_MOTIVATION,
      createFormField({
        name: FormField.QUIT_MOTIVATION,
        type: FormFieldInputType.CHECK_BOX,
        label: ChartLabel.MARK_ALL_THAT_APPLY,
        contentIds: getFragmentContentIds(fragment, SectionTitle.PLAN_QUIT, FormField.QUIT_MOTIVATION)
      })
    );
    dataMap.set(
      FormField.QUIT_CONCERN,
      createFormField({
        name: FormField.QUIT_CONCERN,
        type: FormFieldInputType.MULTISELECT_DROPDOWN,
        label: ChartLabel.MARK_ALL_THAT_APPLY,
        contentIds: getFragmentContentIds(fragment, SectionTitle.PLAN_QUIT, FormField.QUIT_CONCERN)
      })
    );
    dataMap.set(
      FormField.QUIT_START_AGAIN,
      createFormField({
        name: FormField.QUIT_START_AGAIN,
        type: FormFieldInputType.MULTISELECT_DROPDOWN,
        label: ChartLabel.MARK_ALL_THAT_APPLY,
        contentIds: getFragmentContentIds(fragment, SectionTitle.PLAN_QUIT, FormField.QUIT_START_AGAIN)
      })
    );
    dataMap.set(
      FormField.QUIT_DATE,
      createFormField({
        name: FormField.QUIT_DATE,
        type: FormFieldInputType.DATE,
        label: 'When is your quit date?',
        value: getFragmentValue(fragment, SectionTitle.PLAN_QUIT, FormField.QUIT_DATE)
      })
    );
    dataMap.set(
      FormField.USE_NICOTINE_REPLACEMENT,
      createFormField({
        name: FormField.USE_NICOTINE_REPLACEMENT,
        type: FormFieldInputType.DROPDOWN,
        label: 'Do you use a nicotine replacement product?',
        contentIds: getFragmentContentIds(fragment, SectionTitle.PLAN_QUIT, FormField.USE_NICOTINE_REPLACEMENT)
      })
    );
    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Smoking Screen';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: CHART_NAME,
          records: [buildPatientRecord(formFieldMap, FormField.LIVE_WITH_SMOKER), buildPatientRecord(formFieldMap, FormField.IS_SMOKER)]
        },
        {
          sectionTitle: SectionTitle.SMOKER_STATUS,
          records: [buildPatientRecord(formFieldMap, FormField.SMOKER_STATUS, 'Smoker Status')]
        },
        {
          parentSectionTitle: SectionTitle.SMOKER_STATUS,
          sectionTitle: SectionTitle.USING,
          records: [
            buildPatientRecord(formFieldMap, FormField.TOBACCO_TYPE),
            buildPatientRecord(formFieldMap, FormField.TOBACCO_TYPE_OTHER),
            buildPatientRecord(formFieldMap, FormField.TOBACCO_HOW_MUCH),
            buildPatientRecord(formFieldMap, FormField.TOBACCO_HOW_LONG),
            buildPatientRecord(formFieldMap, FormField.TOBACCO_QUIT),
            buildPatientRecord(formFieldMap, FormField.TOBACCO_QUIT_OTHER)
          ]
        },
        {
          parentSectionTitle: SectionTitle.SMOKER_STATUS,
          sectionTitle: SectionTitle.ALREADY_QUIT,
          records: [buildPatientRecord(formFieldMap, FormField.TOBACCO_QUIT_WHEN), buildPatientRecord(formFieldMap, FormField.TEMPTATION_TRIGGER)]
        },
        {
          parentSectionTitle: SectionTitle.SMOKER_STATUS,
          sectionTitle: SectionTitle.PLAN_QUIT,
          records: [
            ...buildPatientRecords(formFieldMap, FormField.QUIT_MOTIVATION),
            ...buildPatientRecords(formFieldMap, FormField.QUIT_CONCERN),
            ...buildPatientRecords(formFieldMap, FormField.QUIT_START_AGAIN),
            buildPatientRecord(formFieldMap, FormField.QUIT_DATE),
            buildPatientRecord(formFieldMap, FormField.USE_NICOTINE_REPLACEMENT)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord };
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap
    };
    return <SmokingScreenView {...viewProps} />;
  }
}

export { SmokingScreen as BaseSmokingScreen };
export default compose(withChartLogic, withAdmissionHistory)(SmokingScreen);

import { orderBy } from 'lodash';
import { Component } from 'react';
import { MedicationFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField } from './constants';
import MedicationsView, { MedicationsViewProps } from './MedicationsView';

interface MedicationsState {
  resetAll: number;
  chartHistory: MedicationFragment[];
  selectedMedication: MedicationFragment;
}
class Medications extends Component<ChartComponentProps, MedicationsState> {
  static displayName = 'Medications';

  constructor(props) {
    super(props);

    this.state = {
      resetAll: 0,
      chartHistory: [],
      selectedMedication: null
    };
  }

  componentDidMount() {
    appHelper.useLoader(Promise.all([this.props.initState(this.buildDefaultFormFields()), this.props.loadChartData().then(this.bindChartHistory)]));
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    dataMap.set(
      FormField.MEDICATION,
      createFormField({
        name: FormField.MEDICATION,
        type: FormFieldInputType.MULTI_SELECT_RADIO,
        label: 'Medication'
      })
    );
    dataMap.set(
      FormField.MEDICATION_OTHER,
      createFormField({
        name: FormField.MEDICATION_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other'
      })
    );
    dataMap.set(
      FormField.CLASSIFICATION,
      createFormField({
        name: FormField.CLASSIFICATION,
        type: FormFieldInputType.MULTI_SELECT_RADIO,
        label: 'Classification'
      })
    );
    dataMap.set(
      FormField.CLASSIFICATION_OTHER,
      createFormField({
        name: FormField.CLASSIFICATION_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other'
      })
    );
    dataMap.set(
      FormField.ROUTE,
      createFormField({
        name: FormField.ROUTE,
        type: FormFieldInputType.DROPDOWN,
        label: 'Route'
      })
    );
    dataMap.set(
      FormField.ROUTE_OTHER,
      createFormField({
        name: FormField.ROUTE_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Other'
      })
    );
    dataMap.set(
      FormField.DOSE,
      createFormField({
        name: FormField.DOSE,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Dose'
      })
    );
    dataMap.set(
      FormField.FREQUENCY,
      createFormField({
        name: FormField.FREQUENCY,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Frequency'
      })
    );
    dataMap.set(
      FormField.DATE_ORDERED,
      createFormField({
        name: FormField.DATE_ORDERED,
        type: FormFieldInputType.DATE,
        label: 'Date Ordered (mm-dd-yyyy)'
      })
    );
    dataMap.set(
      FormField.COMMENT,
      createFormField({
        name: FormField.COMMENT,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Comments and Additional Medication Info'
      })
    );
    dataMap.set(
      FormField.ACTION,
      createFormField({
        name: FormField.ACTION,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Action'
      })
    );
    dataMap.set(
      FormField.SIDE_EFFECT,
      createFormField({
        name: FormField.SIDE_EFFECT,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Side Effects or Adverse Reactions'
      })
    );
    dataMap.set(
      FormField.RECOMMENDED,
      createFormField({
        name: FormField.RECOMMENDED,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Recommended Dose Ranges'
      })
    );
    dataMap.set(
      FormField.THERAPEUTIC_EFFECT,
      createFormField({
        name: FormField.THERAPEUTIC_EFFECT,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Therapeutic Effect'
      })
    );
    dataMap.set(
      FormField.CONTRAINDICATIONS,
      createFormField({
        name: FormField.CONTRAINDICATIONS,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Contraindications'
      })
    );
    dataMap.set(
      FormField.LIFE_THREATENING,
      createFormField({
        name: FormField.LIFE_THREATENING,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Life Threatening Considerations'
      })
    );
    dataMap.set(
      FormField.NURSING_INTERVENTIONS,
      createFormField({
        name: FormField.NURSING_INTERVENTIONS,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Nursing Interventions'
      })
    );
    return dataMap;
  };

  bindChartHistory = ({ data: fragments }) => {
    const records = orderBy(fragments, 'createdAt') as MedicationFragment[];
    this.setState({ chartHistory: records });
  };

  handleChartEdit = () => {
    this.afterSave(false);
    this.setState({ selectedMedication: null });
  };

  handleChartSave = () => {
    const { formFieldMap } = this.props;
    const { buildRecordFromField } = chartService;

    const getValue = (value) => value ?? '';

    const chartData = {
      [FormField.MEDICATION]: getValue(buildRecordFromField(formFieldMap.get(FormField.MEDICATION))?.content),
      [FormField.MEDICATION_OTHER]: getValue(formFieldMap.get(FormField.MEDICATION_OTHER).value),
      [FormField.CLASSIFICATION]: getValue(buildRecordFromField(formFieldMap.get(FormField.CLASSIFICATION))?.content),
      [FormField.CLASSIFICATION_OTHER]: getValue(formFieldMap.get(FormField.CLASSIFICATION_OTHER).value),
      [FormField.ROUTE]: getValue(buildRecordFromField(formFieldMap.get(FormField.ROUTE))?.content),
      [FormField.ROUTE_OTHER]: getValue(formFieldMap.get(FormField.ROUTE_OTHER).value),
      [FormField.DOSE]: getValue(formFieldMap.get(FormField.DOSE).value),
      [FormField.FREQUENCY]: getValue(formFieldMap.get(FormField.FREQUENCY).value),
      [FormField.DATE_ORDERED]: getValue(formFieldMap.get(FormField.DATE_ORDERED).value),
      [FormField.COMMENT]: getValue(formFieldMap.get(FormField.COMMENT).value),
      [FormField.ACTION]: getValue(formFieldMap.get(FormField.ACTION).value),
      [FormField.SIDE_EFFECT]: getValue(formFieldMap.get(FormField.SIDE_EFFECT).value),
      [FormField.RECOMMENDED]: getValue(formFieldMap.get(FormField.RECOMMENDED).value),
      [FormField.THERAPEUTIC_EFFECT]: getValue(formFieldMap.get(FormField.THERAPEUTIC_EFFECT).value),
      [FormField.CONTRAINDICATIONS]: getValue(formFieldMap.get(FormField.CONTRAINDICATIONS).value),
      [FormField.LIFE_THREATENING]: getValue(formFieldMap.get(FormField.LIFE_THREATENING).value),
      [FormField.NURSING_INTERVENTIONS]: getValue(formFieldMap.get(FormField.NURSING_INTERVENTIONS).value)
    };
    const newRecord: MedicationFragment = {
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData
    };
    this.props.handleSaveClick([newRecord], { afterSave: () => this.afterSave(false) });
  };

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  resetForm = () => {
    this.setState((prevState) => ({
      resetAll: prevState.resetAll + 1
    }));
    this.props.initState(this.buildDefaultFormFields());
  };

  handleChartDelete = (medication: MedicationFragment) => this.props.showDeleteConfirmation(() => this.confirmAndDelete(medication));

  confirmAndDelete = (medication: MedicationFragment) => {
    return appHelper.useLoader(
      this.props.saveChartData({ ...medication, active: false }).then(() => this.afterSave(true)),
      { errorMessage: 'can not save chart data' }
    );
  };

  afterSave = (isDelete) =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
      .then(this.resetForm)
      .then(() => appHelper.scrollTop());

  render() {
    const { resetAll, chartHistory, selectedMedication } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, saveChartData } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleChartSave,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: MedicationsViewProps = {
      resetAll,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap,
      chartActionsProps,
      chartHistory,
      selectedMedication,
      onMedicationEdit: this.handleChartEdit,
      onMedicationDelete: this.handleChartDelete,
      onEditSidebarOpen: (medication) => this.setState({ selectedMedication: medication }),
      onEditSidebarClose: () => this.setState({ selectedMedication: null }),
      saveChartData,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <MedicationsView {...viewProps} />;
  }
}

export { Medications as BaseMedications };
export default withChartLogic(Medications);

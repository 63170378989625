import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { ChartComponentProps, ChartMetaFormField, ChartTable, PulseLocation } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { CardiovascularAssessmentFormFieldBuilderItems, FormField, PulseLocationTableHeaders } from './constants';
import CardiovascularAssessmentView, { CardiovascularAssessmentViewProps } from './CardiovascularAssessmentView';

interface CardiovascularAssessmentState {
  resetAll: number;
  pulseLocationTableData: ChartTable;
}

export interface CardiovascularAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

const IRREGULAR_PULSE_LABEL = 'pulsesLocationPeripheralPulse-Irregular';

class CardiovascularAssessment extends Component<CardiovascularAssessmentProps, CardiovascularAssessmentState> {
  static displayName = 'CardiovascularAssessment';
  pulseFormFields: ChartMetaFormField[] = [];

  constructor(props) {
    super(props);
    this.state = {
      resetAll: 0,
      pulseLocationTableData: {
        headers: PulseLocationTableHeaders,
        css: ['', '', '', '', 'column__delete'],
        data: []
      }
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    this.props.setCustomUnsavedChanges(() => this.props.hasUnsavedChanges || this.tableHasLocationData());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const { intl } = this.props;

    CardiovascularAssessmentFormFieldBuilderItems.forEach(({ label, ...item }) => {
      dataMap.set(item.name, createFormField({ ...item, label: label && intl.formatMessage({ id: label }) }));
    });

    this.pulseFormFields = [dataMap.get(FormField.PULSES_LOCATION)];

    return dataMap;
  };

  resetForm = () =>
    this.setState((prevState) => ({
      pulseLocationTableData: { ...prevState.pulseLocationTableData, data: [] },
      resetAll: prevState.resetAll + 1
    }));

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.afterSave });
  };

  afterSave = () =>
    Promise.resolve()
      .then(() => {
        this.setState((prevState) => ({
          pulseLocationTableData: { ...prevState.pulseLocationTableData, data: [] }
        }));
      })
      .then(() => {
        if (this.props.backToSourceLocation) {
          this.props.backToSourceLocation();
        } else {
          this.props.navigateToSavedPatientCharting();
        }
      });

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap, intl } = this.props;
    const CHART_NAME = intl.formatMessage({ id: LanguageKeys.CARDIAC_ASSESSMENT });
    const ANKLE_BRACHIAL_INDEX_TEST = intl.formatMessage({ id: LanguageKeys.ANKLE_BRACHIAL_INDEX_TEST });
    const TISSUE_PERFUSION = intl.formatMessage({ id: LanguageKeys.TISSUE_PERFUSION });
    const CAPILLARY_REFILL = intl.formatMessage({ id: LanguageKeys.CAPILLARY_REFILL });
    const record = {
      chartTitle: 'System Assessment',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: CHART_NAME,
          records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
        },
        {
          sectionTitle: intl.formatMessage({ id: LanguageKeys.PULSES }),
          records: [
            buildPatientRecord(formFieldMap, FormField.PULSES_APICAL),
            buildPatientRecord(formFieldMap, FormField.PULSES_MURMUR_NOTED),
            buildPatientRecord(formFieldMap, FormField.PULSES_MURMUR_DESCRIPTION)
          ]
        },
        {
          sectionTitle: ANKLE_BRACHIAL_INDEX_TEST,
          records: [
            buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_001, intl.formatMessage({ id: LanguageKeys.RIGHT_ARM_SYSTOLIC_BLOOD_PRESSURE })),
            buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_002, intl.formatMessage({ id: LanguageKeys.LEFT_ARM_SYSTOLIC_BLOOD_PRESSURE })),
            buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_003, intl.formatMessage({ id: LanguageKeys.RIGHT_ANKLE_SYSTOLIC_PRESSURE_POSTERIOR_TIBIAL })),
            buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_004, intl.formatMessage({ id: LanguageKeys.RIGHT_ANKLE_SYSTOLIC_PRESSURE_DORSALIS_PEDIS })),
            buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_005, intl.formatMessage({ id: LanguageKeys.LEFT_ANKLE_SYSTOLIC_PRESSURE_POSTERIOR_TIBIAL })),
            buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_006, intl.formatMessage({ id: LanguageKeys.LEFT_ANKLE_SYSTOLIC_PRESSURE_DORSALIS_PEDIS }))
          ]
        },
        {
          parentSectionTitle: ANKLE_BRACHIAL_INDEX_TEST,
          sectionTitle: intl.formatMessage({ id: LanguageKeys.CALCULATE_ANKLE_BRACHIAL_INDEX_TEST }),
          records: [buildPatientRecord(formFieldMap, FormField.ANKLE_BRACHIAL_TEST_INDEX_007, intl.formatMessage({ id: LanguageKeys.INDEX_SCORE }))]
        },
        {
          parentSectionTitle: TISSUE_PERFUSION,
          sectionTitle: intl.formatMessage({ id: LanguageKeys.PERIPHERAL_VASCULAR_GENERAL }),
          records: [...buildPatientRecords(formFieldMap, FormField.PERIPHERAL_VASCULAR_MARK_ALL), buildPatientRecord(formFieldMap, FormField.PERIPHERAL_VASCULAR_OTHER)]
        },
        {
          parentSectionTitle: TISSUE_PERFUSION,
          sectionTitle: CAPILLARY_REFILL,
          records: [
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_LEFT_HAND),
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_RIGHT_HAND),
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_MUCOUS_MEMBRANES_COLOR),
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_LEFT_FOOT),
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_RIGHT_FOOT),
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_MUCOUS_MEMBRANES_MOISTURE),
            buildPatientRecord(formFieldMap, FormField.CAPILLARY_REFILL_NOTES)
          ]
        },
        {
          sectionTitle: intl.formatMessage({ id: LanguageKeys.EDEMA }),
          records: [
            buildPatientRecord(formFieldMap, FormField.NO_EDEMA_NOTED),
            buildPatientRecord(formFieldMap, FormField.GENERALIZED_NONPITTING_EDEMA),
            buildPatientRecord(formFieldMap, FormField.EDEMA_UPPER_EXTREMITIES),
            buildPatientRecord(formFieldMap, FormField.EDEMA_LOWER_EXTREMITIES),
            buildPatientRecord(formFieldMap, FormField.EDEMA_NOTES)
          ]
        },
        {
          sectionTitle: intl.formatMessage({ id: LanguageKeys.CARDIAC_ASSESSMENT }),
          records: [
            buildPatientRecord(formFieldMap, FormField.NO_CARDIAC_PROBLEMS_NOTED),
            ...buildPatientRecords(formFieldMap, FormField.CARDIAC_ASSESSMENT_MARK_ALL),
            buildPatientRecord(formFieldMap, FormField.CARDIAC_ASSESSMENT_ELECTROCARDIOGRAM),
            buildPatientRecord(formFieldMap, FormField.CARDIAC_ASSESSMENT_NOTES)
          ]
        },
        {
          sectionTitle: intl.formatMessage({ id: LanguageKeys.TELEMETRY }),
          records: [
            buildPatientRecord(formFieldMap, FormField.CONTINUOUS_TELEMETRY),
            buildPatientRecord(formFieldMap, FormField.TELEMETRY_UPPER_LIMIT),
            buildPatientRecord(formFieldMap, FormField.TELEMETRY_LOWER_LIMIT),
            ...buildPatientRecords(formFieldMap, FormField.CARDIAC_RHYTHM_MARK_ALL),
            buildPatientRecord(formFieldMap, FormField.TELEMETRY_ALARMS_TESTED),
            buildPatientRecord(formFieldMap, FormField.TELEMETRY_NOTES)
          ]
        },
        {
          sectionTitle: intl.formatMessage({ id: LanguageKeys.CARDIAC_MONITOR }),
          records: [
            buildPatientRecord(formFieldMap, FormField.CARDIAC_MONITOR),
            buildPatientRecord(formFieldMap, FormField.CARDIAC_MONITOR_UPPER_LIMIT),
            buildPatientRecord(formFieldMap, FormField.CARDIAC_MONITOR_LOWER_LIMIT),
            buildPatientRecord(formFieldMap, FormField.CARDIAC_MONITOR_ALARMS_TESTED)
          ]
        }
      ]
    };
    // to appease Typescript, we need to specify which of the possible types `data` is here
    const pulseTableData = this.state.pulseLocationTableData.data as PulseLocation[];
    // for each row in the lung auscultations table, we need to add a record
    pulseTableData.forEach((pulseLocation) => {
      record.records.push({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.CAROTID_PERIPHERAL_PULSES }),
        records: pulseLocation.records
      });
    });

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  handleAddLocation = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;

    // determine what string to display in the table for each column
    const pulseLabel = formFieldMap.get(FormField.PULSES_LOCATION_LEVEL).chartContent.find((item) => item.value === formFieldMap.get(FormField.PULSES_LOCATION_LEVEL).value)?.label;
    const dopplerLabel = formFieldMap
      .get(FormField.PULSES_LOCATION_LEVEL_DOPPLER)
      .chartContent.find((item) => item.value === formFieldMap.get(FormField.PULSES_LOCATION_LEVEL_DOPPLER).value)?.label;
    const pulseAndDopplerLabel = pulseLabel && dopplerLabel ? `${pulseLabel}, ${dopplerLabel}` : pulseLabel ?? dopplerLabel;
    const murmurLabel = formFieldMap
      .get(FormField.PULSES_LOCATION_CAROTID_MURMUR_NOTED)
      .chartContent.find((item) => item.value === formFieldMap.get(FormField.PULSES_LOCATION_CAROTID_MURMUR_NOTED).value)?.label;
    const irregularLabel = formFieldMap.get(FormField.PULSES_LOCATION_PERIPHERAL_PULSE).value === IRREGULAR_PULSE_LABEL ? 'Yes' : 'No';

    // update table rows
    const allCurrentRows: PulseLocation[] = this.state.pulseLocationTableData.data as PulseLocation[];
    const newRow: PulseLocation = {
      active: true,
      location: formFieldMap.get(FormField.PULSES_LOCATION).chartContent.find((item) => item.selected).name,
      pulseAndDoppler: pulseAndDopplerLabel,
      murmur: murmurLabel,
      irregular: formFieldMap.get(FormField.PULSES_LOCATION_PERIPHERAL_PULSE).value && irregularLabel,
      records: [
        buildPatientRecord(formFieldMap, FormField.PULSES_LOCATION),
        buildPatientRecord(formFieldMap, FormField.PULSES_LOCATION_LEVEL),
        buildPatientRecord(formFieldMap, FormField.PULSES_LOCATION_LEVEL_DOPPLER, '', 'Doppler used'),
        buildPatientRecord(formFieldMap, FormField.PULSES_LOCATION_CAROTID_MURMUR_NOTED),
        buildPatientRecord(formFieldMap, FormField.PULSES_LOCATION_PERIPHERAL_PULSE)
      ]
    };
    // replace the old location with the new location if it has already been added to the table
    const nonMatchingCurrentRows: PulseLocation[] = allCurrentRows.filter((row: PulseLocation) => row.location !== newRow.location);
    this.setState((previousState) => ({
      ...previousState,
      pulseLocationTableData: { ...previousState.pulseLocationTableData, data: [...nonMatchingCurrentRows, newRow] }
    }));

    // clear the input fields for carotid/peripheral pulse
    this.handleClearLocations();
  };

  handleClearLocations = () => {
    this.props.resetSubsetOfFormFields(this.pulseFormFields);
  };

  // determine if there is location data entered that has not been saved to the location table
  hasUnsavedLocationData = (): boolean => {
    const locationFieldNames = this.pulseFormFields.map((formField) => formField.name);
    const fieldsToWatch = [];

    // gather current form fields that we want to watch - location fields
    this.props.formFieldMap.forEach((formField) => {
      if (locationFieldNames.includes(formField.name)) fieldsToWatch.push(formField);
    });
    return this.props.hasChangesInTargetFields(fieldsToWatch);
  };

  // determine if there is data in the location table
  tableHasLocationData = (): boolean => this.state.pulseLocationTableData.data.length > 0;

  handleEnableAddLocationButton = (): boolean => {
    const locationHasBeenSelected = this.props.hasChangesInTargetFields([this.props.formFieldMap.get(FormField.PULSES_LOCATION)]);
    const currentLocationDetailFields = [
      this.props.formFieldMap.get(FormField.PULSES_LOCATION_LEVEL),
      this.props.formFieldMap.get(FormField.PULSES_LOCATION_LEVEL_DOPPLER),
      this.props.formFieldMap.get(FormField.PULSES_LOCATION_CAROTID_MURMUR_NOTED),
      this.props.formFieldMap.get(FormField.PULSES_LOCATION_PERIPHERAL_PULSE)
    ];
    return locationHasBeenSelected && this.props.hasChangesInTargetFields(currentLocationDetailFields);
  };

  hasValidUnsavedChanges = (): boolean => {
    const namesOfFieldsToExclude = this.pulseFormFields.map((formField) => formField.name);
    const fieldsToWatch = [];

    // gather current form fields that we want to watch - all fields except location fields
    this.props.formFieldMap.forEach((formField) => {
      if (!namesOfFieldsToExclude.includes(formField.name)) fieldsToWatch.push(formField);
    });
    return this.props.hasChangesInTargetFields(fieldsToWatch) || this.tableHasLocationData();
  };

  handleDeleteLocation = (row) => {
    const newLocationList = this.state.pulseLocationTableData.data.filter((item: PulseLocation) => item.location !== row.location);
    this.setState((prevState) => ({
      pulseLocationTableData: { ...prevState.pulseLocationTableData, data: newLocationList }
    }));
  };

  render() {
    const { enableDisplayRecordsButton, handleDiscardClick, displayAuthoringData, formFieldMap, formSubmittedCount, intl } = this.props;
    const { resetAll, pulseLocationTableData } = this.state;

    const chartActionsProps = {
      enableDisplayRecordsButton,
      enableSaveButton: this.hasValidUnsavedChanges(),
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: CardiovascularAssessmentViewProps = {
      addLocation: this.handleAddLocation,
      addLocationEnabled: this.handleEnableAddLocationButton(),
      chartActionsProps,
      formFieldMap,
      clearLocations: this.handleClearLocations,
      clearLocationsEnabled: this.hasUnsavedLocationData(),
      deletePulseLocation: this.handleDeleteLocation,
      formSubmittedCount,
      pulseLocationTableData,
      resetAll,
      intl
    };

    return <CardiovascularAssessmentView {...viewProps} />;
  }
}

export { CardiovascularAssessment as BaseCardiovascularAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(CardiovascularAssessment);

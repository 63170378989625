import { LanguageKeys } from 'lang';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, IntlShape } from 'react-intl';
import { ChartFragment, VitalSignsRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { cssHelper } from 'helpers';
import { Box, ChartActions, ErrorCard, ErrorFormField, ErrorFormFieldV2, HorizontalScrollableContainer, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import AToEAssessmentADDS from './AtoEAssessmentADDS';
import AtoEAssessmentHistory from './AtoEAssessmentHistory';
import { SavedVitalSignsReview } from './SavedVitalSignsReview';
import '../../vital.signs.common.scss';
import SectionHeaderWithBadge from '../SectionHeaderWithBadge';
import VitalSignsChart from '../VitalSignsChart';
import { FormField, pointingBadgeColorMapping } from '../constants';
import { ADDSGetScores } from '../types';
import '../vitalSigns.scss';

export interface AtoEAssessmentViewProps {
  chartHistoryFragments: ChartFragment[];
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartHistory: VitalSignsRecord[];
  deleteHistory: Function;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  intl: IntlShape;
  locale: Locales;
  scores: ADDSGetScores;
  selectedNavId: string;
  loadData: () => Promise<void>;
}

const labelCss = 'chart-input__section-title';

const AtoEAssessmentView = ({
  chartHistoryFragments,
  chartMetaFormFields,
  chartHistory,
  deleteHistory,
  formSubmittedCount,
  chartActionsProps,
  intl,
  locale,
  scores,
  selectedNavId,
  loadData
}: AtoEAssessmentViewProps) => {
  const { partialScores } = scores;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [selectedRecordId, setSelectedRecordId] = useState<string>('');
  const [selectedRecord, setSelectedRecord] = useState<ChartFragment>(null);

  useEffect(() => {
    if (selectedRecordId) {
      const selectedFragment = chartHistoryFragments.find((fragment) => fragment.fragmentId === selectedRecordId);
      setSelectedRecord(selectedFragment);
      setIsSidebarOpen(true);
    } else {
      setSelectedRecord(null);
      setIsSidebarOpen(false);
    }
  }, [selectedRecordId, chartHistoryFragments]);

  const handleOpenSidebar = (id: string) => {
    setSelectedRecordId(id);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedRecordId('');
  };

  return (
    <>
      {/* TODO: We should make the title can be changed easily. */}
      <Helmet title="SimChart - Vital Signs / A to E Assessment">
        <html lang="en" />
      </Helmet>

      <SavedVitalSignsReview loadData={loadData} isSidebarOpen={isSidebarOpen} onClose={handleCloseSidebar} selectedRecord={selectedRecord} navId={selectedNavId} />

      <div className="vital-signs-common chart-input">
        <h1 className="chart-input__header">{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS })}</h1>

        <div className="u-els-margin-top">
          <h2>Chart History</h2>
          <HorizontalScrollableContainer>
            <AtoEAssessmentHistory
              dataSource={{ data: chartHistory }}
              deleteRecord={deleteHistory}
              showDeleteColumn
              sidebarProps={{
                onOpen: handleOpenSidebar
              }}
            />
          </HorizontalScrollableContainer>
          <Box mt2>
            <VitalSignsChart chartHistory={chartHistory} locale={locale} isUseNewGraphStyle />
          </Box>
        </div>

        <SectionDivider />

        <ELSFlex left className="o-els-flex-layout--wrap@mobile o-els-flex-layout--wrap@tablet">
          <ELSFlexItem w="1o1">
            <div
              className={cssHelper.mapElsCss({
                els: 'padding-right-3x',
                md: 'padding-right-1x1o2',
                lg: 'padding-right-2x'
              })}
            >
              <h2 className="u-els-margin-top">{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.CHART_INPUTS })}</h2>
              <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
              <h2 className="u-els-margin-top-2x">{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS_AIRWAY })}</h2>
              <ErrorFormField
                formFieldMap={chartMetaFormFields}
                formField={chartMetaFormFields.get(FormField.AIRWAY_PATENT_OBSTRUCTED)}
                formSubmittedCount={formSubmittedCount}
                useDefaultMargin={false}
              />
              <ErrorFormField
                formFieldMap={chartMetaFormFields}
                formField={chartMetaFormFields.get(FormField.AIRWAY_PARTIAL_OBSTRUCTION_COMPLETE_OBSTRUCTION)}
                formSubmittedCount={formSubmittedCount}
              />
              <SectionDivider />
              <h2>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.BREATHING })}</h2>
              <ELSFlex left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <ErrorFormFieldV2
                    formFieldMap={chartMetaFormFields}
                    formField={chartMetaFormFields.get(FormField.RESPIRATION)}
                    formSubmittedCount={formSubmittedCount}
                    labelProps={{ className: labelCss }}
                    badgeProps={{
                      value: partialScores.respiration.score,
                      className: pointingBadgeColorMapping[partialScores.respiration.score]
                    }}
                  />
                  <ELSFlex className="o-els-flex-layout--row-gap o-els-flex-layout--gutters-2x" left wrap>
                    <ELSFlexItem md="1o1">
                      <ErrorFormField formField={chartMetaFormFields.get(FormField.RESPIRATION_PATTERN)} formSubmittedCount={formSubmittedCount} />
                    </ELSFlexItem>
                    <ELSFlexItem md="1o1">
                      <ErrorFormField formField={chartMetaFormFields.get(FormField.RESPIRATION_DEPTH)} formSubmittedCount={formSubmittedCount} />
                    </ELSFlexItem>
                  </ELSFlex>
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <div className="chart-input__section-title">
                    <h4>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.OXYGENATION })}</h4>
                  </div>
                  <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                    <ELSFlexItem>
                      <ErrorFormFieldV2
                        formFieldMap={chartMetaFormFields}
                        formField={chartMetaFormFields.get(FormField.OXYGEN_SATURATION)}
                        formSubmittedCount={formSubmittedCount}
                        badgeProps={{
                          value: partialScores.saturation.score,
                          className: pointingBadgeColorMapping[partialScores.saturation.score]
                        }}
                      />
                    </ELSFlexItem>
                    <ELSFlexItem>
                      <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_SITE)} formSubmittedCount={formSubmittedCount} />
                    </ELSFlexItem>
                  </ELSFlex>
                  <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                    <ELSFlexItem>
                      <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_SITE_OTHER)} formSubmittedCount={formSubmittedCount} />
                    </ELSFlexItem>
                  </ELSFlex>
                  <Box mt2>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_AMOUNT_PERCENT)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_PERCENT_OTHER)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_AMOUNT_LITER)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_LITER)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.OXYGEN_DELIVERY_METHOD_LITER_OTHER)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                  </Box>
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <div className="chart-input__section-title">
                    <h4>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.AUSCULTATION })}</h4>
                  </div>
                  <Box mt2>
                    <ELSFlex wrap left>
                      <ELSFlexItem w="1o1" md="1o1">
                        <ErrorFormField
                          formFieldMap={chartMetaFormFields}
                          formField={chartMetaFormFields.get(FormField.AUSCULTATION_LEFT_LUNG)}
                          formSubmittedCount={formSubmittedCount}
                        />
                      </ELSFlexItem>
                      <ELSFlexItem w="1o1" md="1o1">
                        <ErrorFormField
                          formFieldMap={chartMetaFormFields}
                          formField={chartMetaFormFields.get(FormField.AUSCULTATION_RIGHT_LUNG)}
                          formSubmittedCount={formSubmittedCount}
                        />
                      </ELSFlexItem>
                    </ELSFlex>
                  </Box>
                </ELSFlexItem>
              </ELSFlex>
              <SectionDivider />
              <h2>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.CIRCULATION })}</h2>
              <Box>
                <ELSFlex left wrap>
                  <ELSFlexItem w="1o2" md="1o1">
                    <SectionHeaderWithBadge
                      value={intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.PULSE })}
                      badgeValue={partialScores.pulse.score}
                      badgeClassName={pointingBadgeColorMapping[partialScores.pulse.score]}
                      isShowBadge={!!(partialScores.pulse.score && partialScores.pulse.value)}
                    />
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormFieldV2 formFieldMap={chartMetaFormFields} formField={chartMetaFormFields.get(FormField.PULSE)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.PULSE_STRENGTH)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.PULSE_SITE)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap o-els-flex-layout--gutters-2x" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.PULSE_RHYTHM)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.PULSE_EQUALITY)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2" md="1o1">
                    <SectionHeaderWithBadge
                      value={intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.BLOOD_PRESSURE })}
                      badgeValue={partialScores.bloodPressure.score}
                      badgeClassName={pointingBadgeColorMapping[partialScores.bloodPressure.score]}
                      isShowBadge={!!(partialScores.bloodPressure.score && partialScores.bloodPressure.value)}
                    />
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_SYSTOLIC)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_DIASTOLIC)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_SITE)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem className="u-els-margin-right">
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_MODE)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.BLOOD_PRESSURE_POSITION)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                  </ELSFlexItem>
                </ELSFlex>

                <SectionDivider />

                <h2>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.DISABILITY })}</h2>
                <ELSFlex className="o-els-flex-layout--row-gap" wrap left>
                  <ELSFlexItem className="u-els-margin-right">
                    <div className="chart-input__section-title">
                      <h4 className="u-els-margin-bottom-1o2">{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.PAIN_SCORE })}</h4>
                    </div>
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.DISABILITY_PAIN_SCORE_REST)} formSubmittedCount={formSubmittedCount} />
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.DISABILITY_PAIN_SCORE_ON_MOVEMENT)} formSubmittedCount={formSubmittedCount} />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <SectionHeaderWithBadge
                      value={intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.LEVEL_OF_RESPONSE })}
                      badgeValue={partialScores.avpuAssessment.score}
                      badgeClassName={pointingBadgeColorMapping[partialScores.avpuAssessment.score]}
                      isShowBadge={!!(partialScores.avpuAssessment.score && partialScores.avpuAssessment.value)}
                    />
                    <ErrorFormFieldV2
                      formFieldMap={chartMetaFormFields}
                      formField={chartMetaFormFields.get(FormField.DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT)}
                      formSubmittedCount={formSubmittedCount}
                    />
                  </ELSFlexItem>
                </ELSFlex>

                <SectionDivider />

                <h2>{intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.EXPOSURE })}</h2>
                <ELSFlex className="u-flex-gutter-3x" wrap left>
                  <ELSFlexItem w="1o2" md="1o1">
                    <Box className="u-els-width-2o3">
                      <ErrorFormField
                        formField={chartMetaFormFields.get(FormField.EXPOSURE_COMMENTS)}
                        formSubmittedCount={formSubmittedCount}
                        options={{
                          labelCss
                        }}
                      />
                    </Box>
                  </ELSFlexItem>
                  <ELSFlexItem w="1o2" md="1o1">
                    <SectionHeaderWithBadge
                      value={intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.TEMPERATURE })}
                      badgeValue={partialScores.temperature.score}
                      badgeClassName={pointingBadgeColorMapping[partialScores.temperature.score]}
                      isShowBadge={!!(partialScores.temperature.score && partialScores.temperature.value)}
                    />
                    <ELSFlex className="o-els-flex-layout--row-gap" left wrap>
                      <ELSFlexItem>
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.TEMPERATURE_FAHRENHEIT)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
                      </ELSFlexItem>
                      <ELSFlexItem>
                        <ErrorFormFieldV2
                          formFieldMap={chartMetaFormFields}
                          formField={chartMetaFormFields.get(FormField.TEMPERATURE_CELSIUS)}
                          formSubmittedCount={formSubmittedCount}
                          useDefaultMargin={false}
                        />
                      </ELSFlexItem>
                      <ELSFlexItem w="1o1">
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.TEMPERATURE_SITE)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                    </ELSFlex>
                  </ELSFlexItem>
                </ELSFlex>

                <SectionDivider />

                <AToEAssessmentADDS totalScore={scores.totalScore} formFieldMap={chartMetaFormFields} intl={intl} />
              </Box>
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.NOTE)}
                formSubmittedCount={formSubmittedCount}
                options={{
                  labelCss
                }}
              />
              <ChartActions {...chartActionsProps} />
              <p className="chart-input__adds--note">
                <FormattedMessage id={LanguageKeys.VITAL_SIGNS.ADDS_NOTE} />
              </p>
            </div>
          </ELSFlexItem>
        </ELSFlex>
      </div>
    </>
  );
};

AtoEAssessmentView.displayName = 'AtoEAssessmentView';
export default AtoEAssessmentView;

export enum SectionTitle {
  NEUROLOGICAL_INTERVENTIONS = 'Neurological Interventions',
  NEURO_INTERVENTIONS_CARE = 'Neurological Care',
  SEIZURE_PRECAUTIONS = 'Seizure Precautions',
  EXTRAPYRAMIDAL_SIDE_EFFECTS = 'Extrapyramidal Side Effects (EPS)',
  NEUROLEPTIC_MALIGNANT_SYNDROME = 'Neuroleptic Malignant Syndrome (NMS)',
  PATIENT_RESPONSE = 'Patient Response',
  CARE_NOTES = 'Neurological Care Notes'
}

export enum FormField {
  NO_CARE = 'neuroInterventionsNoCare',
  NEURO_INTERVENTIONS_CARE = 'neuroInterventionsCare',
  SEIZURE_PRECAUTIONS = 'neuroInterventionsSeizurePrecautions',
  EXTRAPYRAMIDAL_SIDE_EFFECTS = 'neuroInterventionsExtrapyramidalSideEffects',
  NEUROLEPTIC_MALIGNANT_SYNDROME = 'neuroInterventionsNeurolepticMalignantSyndrome',
  PATIENT_RESPONSE = 'neuroInterventionsPatientResponse',
  CARE_NOTES = 'neuroInterventionsCareNotes'
}

import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { DiagnosticTestsOrderEntryRecord } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField, Status, getDiagnosticTestsSidebarFormFieldBuilderItems } from './constants';
import DiagnosticTestsSidebarView from './DiagnosticTestsSidebarView';
import { FormField as CommonFormField } from '../shared/constants';

export interface DiagnosticTestsSidebarProps extends ChartComponentProps {
  diagnosticTest: DiagnosticTestsOrderEntryRecord;
  onDiagnosticTestUpdate?: Function;
  onCloseClick?: Function;
  modalService: ELSModalServiceType;
}
export interface DiagnosticTestsSidebarState {
  isReadOnlySidebar: boolean;
}

class DiagnosticTestsSidebar extends Component<DiagnosticTestsSidebarProps, DiagnosticTestsSidebarState> {
  static displayName = 'DiagnosticTestsSidebar';

  constructor(props) {
    super(props);
    this.state = {
      isReadOnlySidebar: false
    };
  }

  componentDidUpdate(prevProps: Readonly<DiagnosticTestsSidebarProps>) {
    if (this.props.diagnosticTest && prevProps.diagnosticTest !== this.props.diagnosticTest) {
      this.props.initState(this.buildFormFields());
      this.setState({ isReadOnlySidebar: this.props.diagnosticTest.chartData[FormField.STATUS] === Status.COMPLETED });
    }
  }
  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const {
      diagnosticTest: { chartData },
      isAuthor,
      intl
    } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getDiagnosticTestsSidebarFormFieldBuilderItems(isAuthor, chartData).forEach(({ label, isHidden, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });
    return dataMap;
  };
  handleSaveDataClick = () => {
    const { formFieldMap, diagnosticTest, onDiagnosticTestUpdate, handleSaveClick } = this.props;
    const { buildPatientRecord } = chartService;
    const updatedData = {
      [FormField.EXAM_DATE]: buildPatientRecord(formFieldMap, FormField.EXAM_DATE),
      [FormField.REPORT]: buildPatientRecord(formFieldMap, FormField.REPORT),
      [FormField.IMPRESSION]: buildPatientRecord(formFieldMap, FormField.IMPRESSION),
      [FormField.RECOMMENDATION]: buildPatientRecord(formFieldMap, FormField.RECOMMENDATION),
      [FormField.NAME]: buildPatientRecord(formFieldMap, FormField.NAME),
      [FormField.SIGNATURE]: buildPatientRecord(formFieldMap, FormField.SIGNATURE),
      [FormField.STATUS]: Status.COMPLETED,
      [FormField.RESULTS_RECEIVED_DATE]: buildPatientRecord(formFieldMap, FormField.RESULTS_RECEIVED_DATE),
      [FormField.RESULTS_RECEIVED_TIME]: buildPatientRecord(formFieldMap, FormField.RESULTS_RECEIVED_TIME),
      [CommonFormField.RESULTS_RECEIVED_TIME_OFFSET]: buildPatientRecord(formFieldMap, CommonFormField.RESULTS_RECEIVED_TIME_OFFSET)
    };
    const updatedFragment: DiagnosticTestsOrderEntryRecord = produce(diagnosticTest, (draft) => {
      draft.chartData = { ...draft.chartData, ...updatedData };
      draft.updatedAt = moment().toISOString();
    });
    handleSaveClick([updatedFragment], { afterSave: onDiagnosticTestUpdate });
  };

  render() {
    const { formFieldMap, onCloseClick, diagnosticTest, formSubmittedCount, sidebarProps, hasUnsavedChanges, handleDiscardClick, intl } = this.props;
    const { isReadOnlySidebar } = this.state;

    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: hasUnsavedChanges,
      onSaveClick: this.handleSaveDataClick,
      onCancelClick: () => handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' })
    };

    const viewProps = {
      openSidebar: !!diagnosticTest,
      formSubmittedCount,
      formFieldMap,
      isReadOnly: isReadOnlySidebar,
      diagnosticTest,
      chartActionsProps,
      onCloseClick,
      sidebarProps,
      intl
    };
    return <DiagnosticTestsSidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state),
  isAuthor: appSelectors.getIsAuthor(state)
});

export { DiagnosticTestsSidebar as BaseDiagnosticTestsSidebar };
export default compose(connect(mapStateToProps), withChartLogic, injectIntl)(DiagnosticTestsSidebar);

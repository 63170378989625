import { ReactElement, ReactNode } from 'react';
import { ELSIcon, ELSTooltip } from 'components/common/els';
import '../../error-form-field/error.tooltip.scss';

export interface TooltipProps {
  label: string | ReactElement | ReactNode;
}

export default function Tooltip({ label }: TooltipProps) {
  return (
    <>
      <span className="u-els-margin-left-1o2">
        <ELSTooltip wrapperClassName="chart-input__label-tooltip" content={label} showClose>
          <ELSIcon prefix="gizmo" name="information" size="1x" title="Information" ariaProps={{ 'aria-hidden': 'false' }} />
        </ELSTooltip>
      </span>
    </>
  );
}

import '../chart.scss';

export interface CustomBarTooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  dataKey: string;
}

const CustomBarTooltip = (props?: CustomBarTooltipProps) => {
  const { dataKey, payload } = props;
  let dataObj = null;
  let displayValue = '';
  if (dataKey && payload) {
    dataObj = payload.find((item) => item.dataKey === dataKey);
    if (!dataObj) return null;
    displayValue = dataObj.payload[`${dataKey}Display`];
  }

  return !dataObj ? null : (
    <div className="u-els-padding chart-tooltip__wrapper">
      <div className="u-els-bold">{displayValue}</div>
      <div className="qa-tooltip-datetime">{dataObj.payload.dateTime}</div>
    </div>
  );
};

CustomBarTooltip.displayName = 'CustomBarTooltip';
export default CustomBarTooltip;

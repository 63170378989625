import { orderBy } from 'lodash';
import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartComponentProps, NavigationItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper, assignmentHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withFormUtilities } from 'components/common';
import SavedPatientChartingView, { SavedPatientChartingViewProps } from './SavedPatientChartingView';

export interface TableItem {
  active: boolean;
  chart?: string;
  chartSection?: string;
  enteredBy: string;
  entryTime: string;
  id: string;
  linkedFragmentId: string;
  location?: string;
  navElementId: string;
}

interface SavedPatientChartingProps extends ChartComponentProps {
  buildStatusBadge?: Function;
  chartPathName: string;
  chartTitle?: string;
  children?: () => ReactNode[];
  className?: string;
  deleteMessage?: string;
  hideDelete?: boolean;
  menuItems: NavigationItem[];
  navIds: string[];
  toTableData?: Function;
  enableMultiStepsAuthoring: Function;
  locale?: Locales;
}

interface SavedPatientChartingState {
  tableData: TableItem[];
  hasDataChanged: boolean;
  openSideBar: boolean;
  selectedFragment: MultiSectionsFragment;
  linkedFragments: MultiSectionsFragment[];
  fragmentGroupMap: Map<string, ChartFragment[]>;
}

class SavedPatientCharting extends Component<SavedPatientChartingProps, SavedPatientChartingState> {
  static displayName = 'SavedPatientCharting';
  static defaultProps = {
    hideDelete: false
  };
  navigationItemMap;

  constructor(props) {
    super(props);
    this.navigationItemMap = navigationService.mapTreeToMap(this.props.menuItems, null);
    this.state = {
      hasDataChanged: false,
      openSideBar: false,
      tableData: [],
      selectedFragment: null,
      linkedFragments: [],
      fragmentGroupMap: new Map()
    };
  }

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
    return appHelper.useLoader(this.loadChartData());
  }

  loadChartData = () => {
    const chartId = this.props.assessment.simChartId;
    const { navIds } = this.props;
    const isAssessmentCompleted = assignmentHelper.isAssessmentSubmitted(this.props.assessment.status);

    return this.props.loadChartData([FragmentType.CHARTING, FragmentType.AUTHORED], navIds, chartId).then(({ data: fragments }) => {
      const filteredFragments = fragments.filter(
        (fragment) =>
          fragment.fragmentType === FragmentType.CHARTING ||
          // hide authored data if assessment is completed
          (fragment.fragmentType === FragmentType.AUTHORED && !isAssessmentCompleted)
      );
      const sortedFragments = orderBy(filteredFragments, ['chartingAt', 'createdAt'], ['desc', 'desc']);
      const fragmentGroupMap = chartHelper.groupFragmentsById(sortedFragments);
      const tableData = this.buildTableData(fragmentGroupMap);

      this.setState({ fragmentGroupMap, tableData });
    });
  };

  buildTableData = (fragmentGroupMap: Map<string, ChartFragment[]>) => {
    const fragmentGroups = Array.from(fragmentGroupMap.values());
    const fragments = fragmentGroups.map((fragmentGroup) => {
      const { length } = fragmentGroup;
      if (length >= 1) {
        let index = 0;
        // Get the latest active fragment
        while (index < length) {
          if (fragmentGroup[index].active) {
            return fragmentGroup[index];
          }
          index += 1;
        }
        // If there're not any active fragments, get the oldest inactive fragment
        return fragmentGroup[length - 1];
      }
      return fragmentGroup[0];
    });
    return this.props.toTableData?.(fragments) ?? this.toTableData(fragments);
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      chart: fragment.chartData.fragmentTitle,
      chartSection: fragment.chartData.chartTitle,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: fragment.chartingAt,
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      navElementId: fragment.navElementId
    }));
  };

  handleViewClick = (row) => {
    document.body.classList.add('scroll-bounce-custom');
    this.setState((prevState) => {
      const linkedFragments = prevState.fragmentGroupMap.get(row.groupFragmentRef || row.linkedFragmentId || row.id) as MultiSectionsFragment[];
      const selectedFragment = linkedFragments.find((item) => item.fragmentId === row.id);

      return { selectedFragment, linkedFragments, openSideBar: true };
    });
  };

  handleAfterSideBarClose = () => {
    this.setState({ selectedFragment: null });
    if (this.state.hasDataChanged) {
      appHelper.useLoader(this.loadChartData().then(() => this.setState({ hasDataChanged: false })));
    }
  };

  handleDeleteClick = (row) => {
    const { assessment, showDeleteSuccess } = this.props;
    const fragmentGroupMap = new Map(this.state.fragmentGroupMap);
    let fragmentGroup = fragmentGroupMap.get(row.linkedFragmentId || row.id);
    let selectedFragment;
    fragmentGroup = fragmentGroup.map((item) => {
      if (item.fragmentId === row.id) {
        selectedFragment = { ...item, active: false };
        return selectedFragment;
      }
      return item;
    });
    fragmentGroupMap.set(row.linkedFragmentId || row.id, fragmentGroup);
    const tableData = this.buildTableData(fragmentGroupMap);

    return appHelper.useLoader(
      chartService.saveFragment(assessment.simChartId, row.navElementId, selectedFragment).then(() => {
        showDeleteSuccess();
        this.setState({ fragmentGroupMap, tableData });
      })
    );
  };

  handleCloseSideBar = (hasDataChanged: boolean) => {
    document.body.classList.remove('scroll-bounce-custom');
    this.setState({ openSideBar: false, hasDataChanged: !!hasDataChanged });
  };

  render() {
    const { linkedFragments, openSideBar, selectedFragment, tableData } = this.state;
    const { assessment, buildStatusBadge, chartTitle, children, className, deleteMessage, isAuthor, hideDelete, showDeleteConfirmation, locale } = this.props;
    const selectedRecordChartPathName = selectedFragment
      ? chartHelper.buildRecordChartPathName(this.props.chartPathName, this.navigationItemMap.get(selectedFragment.navElementId).text)
      : null;
    const isShowGrading = selectedFragment && !isAuthor && assignmentHelper.isAssessmentSubmitted(assessment?.status);

    const viewProps: SavedPatientChartingViewProps = {
      chartTitle,
      children,
      className,
      buildStatusBadge,
      data: tableData,
      hideDelete,
      isShowGrading,
      onAfterSideBarClose: this.handleAfterSideBarClose,
      onCloseSideBar: this.handleCloseSideBar,
      onDeleteClick: (row) => showDeleteConfirmation(() => this.handleDeleteClick(row), deleteMessage),
      onViewClick: this.handleViewClick,
      openSideBar,
      selectedFragment,
      linkedFragments,
      selectedRecordChartPathName,
      simChartId: this.props.assessment.simChartId,
      locale
    };
    return <SavedPatientChartingView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  menuItems: appSelectors.getMenuItems(state),
  chartPathName: appSelectors.getCurrentChartPathName(state),
  locale: appSelectors.getLocale(state)
});

export { SavedPatientCharting as BaseSavedPatientCharting };
export default compose(connect(mapStateToProps, null), withFormUtilities)(SavedPatientCharting);

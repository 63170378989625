import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, Section } from './constants';
import ManiaQuestionnaireView, { ManiaQuestionnaireViewProps } from './ManiaQuestionnaireView';

class ManiaQuestionnaire extends Component<ChartComponentProps> {
  static displayName = 'ManiaQuestionnaire';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.MANIA_QUESTIONNAIRE_SCALE,
      createFormField({ name: FormField.MANIA_QUESTIONNAIRE_SCALE, type: FormFieldInputType.SCALE, errorLabel: Section.MANIA_QUESTIONNAIRE_SCALE })
    );

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION1, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION2, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION3, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION4, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION5, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION6, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION7, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION8, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION9, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION10, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION11, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION12, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION13, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION14, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION15, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION16, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION17, ScaleDirection.HORIZONTAL);
    contextDirectionMap.set(FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION18, ScaleDirection.HORIZONTAL);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.MANIA_QUESTIONNAIRE_SCALE,
      records: [
        {
          sectionTitle: Section.MANIA_QUESTIONNAIRE_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.MANIA_QUESTIONNAIRE_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.MANIA_QUESTIONNAIRE_SCALE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.MANIA_QUESTIONNAIRE_SCALE))?.score?.value,
              linkedFormFieldIds: [
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION1,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION2,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION3,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION4,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION5,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION6,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION7,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION8,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION9,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION10,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION11,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION12,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION13,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION14,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION15,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION16,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION17,
                FormField.MANIA_QUESTIONNAIRE_SCALE_QUESTION18
              ]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields());

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: ManiaQuestionnaireViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };

    return <ManiaQuestionnaireView {...viewProps} />;
  }
}

export { ManiaQuestionnaire as BaseManiaQuestionnaire };
export default compose(withSavedPatientChartsPage, withChartLogic)(ManiaQuestionnaire);

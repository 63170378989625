import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const IntegumentaryInterventionsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="integumentary-interventions">
      <h1>Integumentary Interventions</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.SKIN_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.SKIN_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.WOUND_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.WOUND_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

IntegumentaryInterventionsView.displayName = 'Integumentary Interventions';
export default IntegumentaryInterventionsView;

import { orderBy } from 'lodash';
import React, { Component, RefObject } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment } from 'models/api-response';
import { ELSDayPickerType } from 'models/els';
import { AssignmentType } from 'models/enum';
import { DropDownItem } from 'models/ui';
import { AuthoringPhaseIndexes, Locales, NAV_ID, ParamKeys, ParamValues } from 'constants/app.constant';
import { apiHelper, assignmentHelper, dateTimeHelper } from 'helpers';
import { getParamByKeys } from 'helpers/app.helper';
import { formatDate, toDateTime, toISO, toMoment, toTime } from 'helpers/datetime.helper';
import { watchLocaleChange } from 'helpers/intl.helper';
import { chartService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentActions, studentSelectors } from 'redux/ducks/student';
import { withFormUtilities } from 'components/common';
import set from 'lodash/fp/set';
import ChartingActionBarView, { ChartingActionBarViewProps } from './ChartingActionBarView';

export interface ChartingActionBarProps extends RouteComponentProps<ParamValues> {
  chartingTime: string;
  isAuthor: boolean;
  phaseIndex: string;
  isMultiStepsAuthoring: boolean;
  assessment: AssessmentRS;
  assignmentType: AssignmentType;
  setChartingTime: Function;
  setAuthorId: Function;
  setOffset: Function;
  setPhaseIndex: Function;
  showSaveSuccess: Function;
  setLocale: (locale: Locales) => void;
  locale: Locales;
}

interface ChartingActionBarState {
  isReadOnly: boolean;
  isEdit: boolean;
  dateString: string;
  timeString: string;
  entryByOptions: DropDownItem[];
  fragment: ChartFragment;
  offset: string;
}

class ChartingActionBar extends Component<ChartingActionBarProps, ChartingActionBarState> {
  static displayName = 'ChartingActionBar';
  datePickerRef: RefObject<ELSDayPickerType>;
  authoringPhaseOptions: DropDownItem[];

  constructor(props) {
    super(props);

    this.state = {
      isReadOnly: this.props.assignmentType === AssignmentType.CASE_STUDY || assignmentHelper.isAssessmentSubmitted(this.props.assessment?.status),
      isEdit: false,
      dateString: '',
      timeString: '',
      entryByOptions: [],
      fragment: null,
      offset: '0'
    };

    this.authoringPhaseOptions = AuthoringPhaseIndexes.map((item) => ({ name: String(item), value: String(item) }));
    this.datePickerRef = React.createRef();
  }

  componentDidMount() {
    const { isAuthor, history, locale, setOffset, setPhaseIndex } = this.props;

    const fetchChartingTime = this.fetchChartingTime();
    const searchLocale = this.getLocaleParam(this.props);
    let fetchEntryBy = Promise.resolve();

    if (isAuthor) {
      fetchEntryBy = this.fetchEntryBy();
      setOffset(0);
      setPhaseIndex(AuthoringPhaseIndexes[0]); // Set first phase as default one
      watchLocaleChange({ history, locale, searchParamLocale: searchLocale, changeLocaleCb: this.handleChangeLocale });
    }

    return Promise.all([fetchChartingTime, fetchEntryBy]).catch((err) => apiHelper.showApiError(`can not load charting time and author ${err}`));
  }

  componentDidUpdate(prevProps: Readonly<ChartingActionBarProps>): void {
    const { locale, history, isAuthor } = this.props;
    const searchLocale = this.getLocaleParam(this.props);

    if (isAuthor) {
      watchLocaleChange<ChartingActionBarProps>({ history, locale, searchParamLocale: searchLocale, changeLocaleCb: this.handleChangeLocale, prevProps });
    }
  }

  fetchChartingTime = () => {
    const { assessment, setChartingTime, locale } = this.props;
    return chartService
      .loadFragments({ chartId: assessment?.simChartId, navIds: [NAV_ID.TIME] })
      .then((response) => response.data[0])
      .then((fragment) => {
        const chartingAt = fragment?.chartData?.chartingAt;
        let dateTime = '';
        let chartingTime = '';
        if (chartingAt) {
          dateTime = toDateTime(chartingAt);
          chartingTime = toISO(dateTime);
        }
        this.setState({
          dateString: formatDate({ date: toMoment(chartingAt).toDate(), locale, checkFormat: true, defaultIfEmpty: toMoment(new Date().toISOString()).toString() }),
          timeString: toTime(chartingAt),
          fragment
        });
        return setChartingTime(chartingTime);
      });
  };

  fetchEntryBy = () =>
    chartService.loadAuthors().then(({ data }) => {
      let entryByOptions = data.map((item) => ({ name: `${item.firstName} ${item.lastName}`, value: item.id }));
      entryByOptions = orderBy(entryByOptions, ['name'], ['asc']);
      this.props.setAuthorId(entryByOptions[0].value);
      this.setState({ entryByOptions });
    });

  saveChartingTime = (chartingAt: string) => {
    const { assessment, showSaveSuccess } = this.props;
    const { fragment } = this.state;
    const newFragment = {
      ...fragment,
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      ...set('chartData.chartingAt', chartingAt)(fragment)
    };
    return chartService.saveFragment(assessment.simChartId, NAV_ID.TIME, newFragment).then(() => showSaveSuccess());
  };

  handleEditClick = () => {
    const { locale } = this.props;
    const dateTimeNow = dateTimeHelper.now();
    const newDateString = formatDate({ date: toMoment(dateTimeNow).toDate(), locale, checkFormat: true, defaultIfEmpty: toMoment(new Date().toISOString()).toString() });
    const newTimeString = dateTimeHelper.toTime(dateTimeNow);
    this.setState((prevState) => ({
      isEdit: true,
      dateString: prevState.dateString || newDateString,
      timeString: prevState.timeString || newTimeString
    }));
  };

  handleEditSubmitClick = () => {
    const dateTime = dateTimeHelper.toISO(`${this.state.dateString} ${this.state.timeString}`);
    this.props.setChartingTime(dateTime);
    this.setState({ isEdit: false });
    this.saveChartingTime(dateTime);
  };

  handleEditCancelClick = () => {
    this.setState({
      isEdit: false,
      dateString: this.props.chartingTime ? dateTimeHelper.toDate(this.props.chartingTime) : '',
      timeString: this.props.chartingTime ? dateTimeHelper.toTime(this.props.chartingTime) : ''
    });
  };

  handleDateChange = (dateString) => this.setState({ dateString });

  handleTimeChange = (timeString) => this.setState({ timeString });

  handleOffsetChange = (time: string) => {
    // change to seconds
    this.setState({ offset: time });
    const splitTime = time.split(':');
    const hours = Number(splitTime[0]) * 60 * 60;
    const minutes = splitTime[1] ? Number(splitTime[1]) * 60 : 0;
    const total = Math.abs(hours) + minutes;

    this.props.setOffset(total);
  };

  handleSetToCurrentClick = () => {
    this.props.setChartingTime('');
    this.setState({ dateString: '', timeString: '' });
    this.saveChartingTime('');
  };

  handleEntryByClick = (authorId: string) => this.props.setAuthorId(Number(authorId));

  handleAuthoringPhaseChange = (phaseIndex: string) => this.props.setPhaseIndex(Number(phaseIndex));

  getLocaleParam = (props: ChartingActionBarProps): Locales => {
    return getParamByKeys<ParamValues>(props.location.search, [ParamKeys.LOCALE]).locale;
  };

  handleChangeLocale = (locale: Locales) => {
    const { setLocale } = this.props;
    setLocale(locale);
  };

  render() {
    const { isReadOnly, isEdit, dateString, timeString, entryByOptions, offset } = this.state;
    const { isAuthor, assignmentType, isMultiStepsAuthoring, locale, phaseIndex } = this.props;
    const viewProps: ChartingActionBarViewProps = {
      isReadOnly,
      isEdit,
      dateString,
      timeString,
      entryByOptions,
      isAuthor,
      datePickerRef: this.datePickerRef,
      assignmentType,
      authoringPhaseOptions: this.authoringPhaseOptions,
      onEditClick: this.handleEditClick,
      onEditSubmitClick: this.handleEditSubmitClick,
      onEditCancelClick: this.handleEditCancelClick,
      onOffsetChange: this.handleOffsetChange,
      onDateChange: this.handleDateChange,
      onTimeChange: this.handleTimeChange,
      onAuthoringPhaseChange: this.handleAuthoringPhaseChange,
      onSetToCurrentClick: this.handleSetToCurrentClick,
      onEntryByClick: this.handleEntryByClick,
      onLocaleChange: this.handleChangeLocale,
      offset,
      phaseIndex,
      isShowAuthoringPhase: isMultiStepsAuthoring,
      locale
    };
    return <ChartingActionBarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  chartingTime: studentSelectors.getChartingTime(state),
  assignmentType: studentSelectors.getAssignmentType(state),
  phaseIndex: studentSelectors.getPhaseIndex(state),
  assessment: appSelectors.getAssessment(state),
  isAuthor: appSelectors.getIsAuthor(state),
  isMultiStepsAuthoring: appSelectors.getIsMultiStepsAuthoring(state),
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  setChartingTime: (dateTime) => dispatch(studentActions.setChartingTime(dateTime)),
  setAuthorId: (authorId) => dispatch(studentActions.setAuthorId(authorId)),
  setOffset: (offset) => dispatch(studentActions.setOffset(offset)),
  setPhaseIndex: (phaseIndex) => dispatch(studentActions.setPhaseIndex(phaseIndex)),
  setLocale: (locale: Locales) => dispatch(appActions.setLocale(locale))
});

export { ChartingActionBar as BaseChartingActionBar };
export default compose(connect(mapStateToProps, mapDispatchToProps), withFormUtilities)(ChartingActionBar);

import cx from 'classnames';
import { cloneDeep, isUndefined } from 'lodash';
import { Component, Fragment, ReactElement } from 'react';
import { FormFieldInputType, ScaleDirection, ValidationActionType } from 'models/enum';
import { ChartMetaFormField, ErrorField, GroupScaleQuestion } from 'models/ui';
import { appHelper } from 'helpers';
import { ELSFlexItem, ELSIcon, ELSTooltip } from 'components/common/els';
import ErrorTooltip from './ErrorTooltip';
import './error.form.field.scss';
import {
  handleCheckBoxFormFieldInputType,
  handleDateFormFieldInputType,
  handleDropdownFormFieldInputType,
  handleImageFormFieldInputType,
  handleMultiselectDropDownFormFieldInputType,
  handleMultiselectRadioFormFieldInputType,
  handleRadioChoiceFormFieldInputType,
  handleScaleFormFieldInputType,
  handleTextAreaFormFieldInputType,
  handleTextBlockFormFieldInputType,
  handleTextBoxFormFieldInputType,
  handleTimeFormFieldInputType
} from './helper';

export interface ErrorFormFieldOptions {
  labelCss?: string;
  displayHorizontal?: boolean;
  showScaleRollUp?: boolean;
  contextDirectionMap?: Map<string, ScaleDirection>;
  groupScaleQuestion?: GroupScaleQuestion[];
  isHiddenScore?: boolean;
  showScoreInAnswer?: boolean;
  disableBefore?: Date;
  isNotSaveChildValueInScore?: boolean;
}

interface ErrorFormFieldProps {
  className?: string;
  useDefaultMargin: boolean;
  formField: ChartMetaFormField;
  formSubmittedCount: number;
  options?: ErrorFormFieldOptions;
  onChange?: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: { [x: string]: any };
  enableTooltip?: boolean;
  hideAsterisk?: boolean;
  formFieldMap?: Map<string, ChartMetaFormField>;
}

class ErrorFormField extends Component<ErrorFormFieldProps> {
  static displayName = 'ErrorFormField';
  static defaultProps = {
    useDefaultMargin: true,
    enableTooltip: true
  };
  datePreviousValue = '';
  dateComponentKey = 0;

  renderLabelContent = () => {
    const { enableTooltip, formField, hideAsterisk } = this.props;
    const asterisk = formField.isRequired && !hideAsterisk ? <span className="chart-input__label-asterisk">* </span> : <></>;

    const tooltip =
      enableTooltip && (formField.labelTooltip?.length > 0 || formField.labelMultilineTooltip) ? (
        <span className="u-els-margin-left-1o2">
          <ELSTooltip wrapperClassName="chart-input__label-tooltip" content={formField.labelTooltip || formField.labelMultilineTooltip} showClose>
            <ELSIcon prefix="gizmo" name="information" size="1x" title="Information" ariaProps={{ 'aria-hidden': 'false' }} />
          </ELSTooltip>
        </span>
      ) : (
        <></>
      );

    let formFieldId = null;
    switch (formField.inputType) {
      case FormFieldInputType.TEXT_BOX:
      case FormFieldInputType.TEXT_AREA:
      case FormFieldInputType.TIME:
      case FormFieldInputType.DROPDOWN:
        formFieldId = `field-input-${formField.name}`;
        break;
      default:
        formFieldId = formField.name;
        break;
    }

    const scaleLabel = (
      <h2 className="chart-input__label-scale">
        {asterisk}
        {formField.label}
      </h2>
    );
    const radioOrCheckBoxLabel = (
      <p>
        {asterisk}
        {formField.label}
      </p>
    );
    const normalLabel = (
      <label htmlFor={formFieldId}>
        {asterisk}
        {formField.label}
      </label>
    );

    let label = null;
    switch (formField.inputType) {
      case FormFieldInputType.SCALE:
        label = scaleLabel;
        break;
      case FormFieldInputType.RADIO_CHOICE:
      case FormFieldInputType.CHECK_BOX:
        label = radioOrCheckBoxLabel;
        break;
      default:
        label = normalLabel;
        break;
    }

    return (
      <>
        {label}
        {tooltip}
      </>
    );
  };

  transformInputProps = (inputProps: { [x: string]: any }, label: string): { [x: string]: any } => {
    if (!inputProps) return inputProps;

    const transformedInputProps = cloneDeep(inputProps);
    const { placeholder } = transformedInputProps;
    if (typeof placeholder === 'boolean' && placeholder) {
      transformedInputProps.placeholder = appHelper.getPlaceholder(label);
    }

    return transformedInputProps;
  };

  label = (): ReactElement => {
    const { formField, options } = this.props;

    return formField.inputType !== FormFieldInputType.RADIO_CHOICE ? (
      <div id={`chart-field-label-${formField.name}`} className={cx('chart-input__label', options?.labelCss)}>
        {this.renderLabelContent()}
      </div>
    ) : (
      <legend id={`chart-field-label-${formField.name}`} className={cx('chart-input__label', options?.labelCss)}>
        {this.renderLabelContent()}
      </legend>
    );
  };

  input = (): ReactElement => {
    const { options, formField, onChange: baseOnChange, inputProps, formFieldMap } = this.props;
    const { inputType, name, onBlur, onChange: originalOnChange, value, disabled } = formField;
    const isAriaRequired = formField.isRequired || null;

    const onChange = (field, event, extraParam?) => {
      if (baseOnChange) {
        baseOnChange(event);
      }

      originalOnChange(field, event, extraParam);
    };

    const buildOnChange = (item) => {
      return () => {
        if (baseOnChange) {
          const extraParam = baseOnChange(formField, item);
          item.onChange(formField, item, extraParam, extraParam?.isNotSaveChildValueInScore);
        } else {
          item.onChange(formField, item);
        }
      };
    };

    // To make sure isActive compatible with current metadata, default value isActive is true.
    const chartContent = formField.chartContent?.filter((item) => !item.isHidden && (item.isActive || item.isActive === undefined));

    // this variable is used to control the display of checkbox and radio options, by default we display options vertically
    const displayVertical = !options?.displayHorizontal;

    switch (inputType) {
      case FormFieldInputType.DROPDOWN:
        return handleDropdownFormFieldInputType({ name, chartContent, value, disabled, isAriaRequired, formField, inputProps, onChange, onBlur });
      case FormFieldInputType.MULTISELECT_DROPDOWN:
        return handleMultiselectDropDownFormFieldInputType({ isAriaRequired, formField, value, inputProps });
      case FormFieldInputType.TEXT_BOX:
        return handleTextBoxFormFieldInputType({ name, value, onChange, onBlur, disabled, formField, inputProps, transformInputProps: this.transformInputProps, isAriaRequired });
      case FormFieldInputType.CHECK_BOX:
        return handleCheckBoxFormFieldInputType({ chartContent, inputProps, displayVertical, name, disabled, renderNestedField: this.renderNestedField, buildOnChange });
      case FormFieldInputType.TEXT_AREA:
        return handleTextAreaFormFieldInputType({ chartContent, name, value, disabled, formField, inputProps, onChange, transformInputProps: this.transformInputProps });
      case FormFieldInputType.RADIO_CHOICE:
        return handleRadioChoiceFormFieldInputType({ chartContent, displayVertical, name, buildOnChange, inputProps, disabled, renderNestedField: this.renderNestedField });
      case FormFieldInputType.DATE:
        return handleDateFormFieldInputType({
          datePreviousValue: this.datePreviousValue,
          dateComponentKey: this.dateComponentKey,
          value,
          name,
          options,
          onChange,
          onBlur,
          formField,
          disabled,
          inputProps,
          isAriaRequired
        });
      case FormFieldInputType.TIME:
        return handleTimeFormFieldInputType({ name, value, disabled, formField, isAriaRequired, onChange, onBlur, inputProps });
      case FormFieldInputType.MULTI_SELECT_RADIO:
        return handleMultiselectRadioFormFieldInputType({ chartContent, isAriaRequired, formField, value, inputProps });
      case FormFieldInputType.SCALE:
        return handleScaleFormFieldInputType({
          name,
          chartContent,
          options,
          disabled,
          formField,
          onChange,
          isAriaRequired,
          formFieldMap,
          renderNestedField: this.renderNestedField
        });
      case FormFieldInputType.IMAGE:
        return handleImageFormFieldInputType({ chartContent, name });
      case FormFieldInputType.TEXT_BLOCK:
        return handleTextBlockFormFieldInputType({ chartContent });
      default:
        return <>{this.props.children}</>;
    }
  };

  renderNestedField = (nestedFieldId: string, onChange?: Function) => {
    if (nestedFieldId) {
      const { options } = this.props;
      const fieldIds = nestedFieldId.split('|');
      return fieldIds.map((id) => (
        <Fragment key={id}>
          <ELSFlexItem>
            <ErrorFormField
              useDefaultMargin={false}
              className={cx('u-els-margin-bottom-1x', {
                'u-els-margin-left-2x u-els-margin-top-1o2': !options?.displayHorizontal
              })}
              hideAsterisk
              formSubmittedCount={this.props.formSubmittedCount}
              formField={this.props.formFieldMap.get(id)}
              onChange={onChange}
            />
          </ELSFlexItem>
        </Fragment>
      ));
    }
    return null;
  };

  errorTooltip = (): ReactElement => (
    <ErrorTooltip id={`chart-field-errors-${this.props.formField.name}`} errors={this.errors()} formSubmittedCount={this.props.formSubmittedCount} />
  );

  errors = (): ErrorField[] => {
    const { errors } = this.props.formField;

    // if the form wasn't submitted show any errors excluding required ones
    if (this.props.formSubmittedCount === 0) {
      return errors.filter((error) => error?.type !== ValidationActionType.REQUIRED);
    }

    // if the form was submitted show all errors
    return errors;
  };

  shouldShowTooltip = (errors): boolean => {
    return errors.length > 0 && errors.some((err) => !!err.message);
  };

  renderErrorFormFieldContent = (errors: ErrorField[]) => (
    <>
      {this.label()}
      {this.input()}
      {this.shouldShowTooltip(errors) && this.errorTooltip()}
    </>
  );

  isHiddenRadioGroup = (): boolean => {
    const chartContent = this.props.formField.chartContent?.filter((item) => !item.isHidden && (item.isActive || item.isActive === undefined));
    const { hideRadioContent } = this.props.inputProps ?? {};
    let isHidden = true;

    if (!hideRadioContent) {
      isHidden = false;
    } else {
      chartContent.forEach((item) => {
        if (!hideRadioContent(item)) isHidden = false;
      });
    }

    return isHidden;
  };

  render() {
    const { children, formField, inputProps, className, useDefaultMargin } = this.props;

    if (isUndefined(formField)) {
      return <></>;
    }

    const { hide, name, inputType } = formField;
    const fieldId = `chart-field-${name}`;

    if (hide) {
      return <div id={fieldId} />;
    }

    if (inputType === FormFieldInputType.CONTAINER) {
      const flexItemProps = inputProps?.flexItemProps;
      if (flexItemProps) {
        return (
          <ELSFlexItem id={fieldId} {...flexItemProps}>
            {children}
          </ELSFlexItem>
        );
      }
      return (
        <div id={fieldId} className={className}>
          {children}
        </div>
      );
    }

    const errors = this.errors();
    const hasErrors = errors.length > 0;

    return (
      <div
        id={fieldId}
        className={cx(`chart-input__field ${fieldId}`, className, {
          'u-els-margin-bottom-2x': useDefaultMargin,
          'chart-input__field--has-errors': hasErrors
        })}
      >
        {inputType !== FormFieldInputType.RADIO_CHOICE
          ? this.renderErrorFormFieldContent(errors)
          : !this.isHiddenRadioGroup() && <fieldset aria-required={formField.isRequired || null}>{this.renderErrorFormFieldContent(errors)}</fieldset>}
      </div>
    );
  }
}

export default ErrorFormField;

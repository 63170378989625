import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  SKIN_COLOR = 'integumentaryColor',
  SKIN_TURGOR = 'integumentarySkinTurgor',
  TEMPERATURE = 'integumentaryTemperature',
  INTEGRITY = 'integumentaryIntegrity',
  MOISTURE = 'integumentaryMoisture',
  SKIN_NOTES = 'skinNotes',
  DISTRIBUTION = 'distribution',
  HEALTH = 'healthMarkAllApply',
  CHARACTERISTICS = 'characteristics',
  HAIR_NOTES = 'hairNotes',
  CONFIGURATION = 'nailConfiguration',
  CONDITION = 'nailCondition',
  CONDITION_OTHER = 'nailConditionOther',
  NAIL_COLOR = 'nailColor',
  NAIL_BASE = 'nailBase',
  NAIL_BASE_OTHER = 'nailBaseOther',
  NAIL_NOTES = 'nailAssessmentNotes'
}

export const getIntegumentaryAssessmentFormFieldBuilderItems = (): ChartMetaFormFieldParams[] => [
  {
    name: FormField.NO_ASSESSMENT_REQUIRED,
    type: FormFieldInputType.CHECK_BOX
  },
  {
    name: FormField.SKIN_COLOR,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.COLOR
  },
  {
    name: FormField.SKIN_TURGOR,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.SKIN_TURGOR
  },
  {
    name: FormField.TEMPERATURE,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.TEMPERATURE
  },
  {
    name: FormField.INTEGRITY,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.INTEGRITY
  },
  {
    name: FormField.MOISTURE,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.MOISTURE
  },
  {
    name: FormField.SKIN_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.DISTRIBUTION,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.DISTRIBUTION
  },
  {
    name: FormField.HEALTH,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.MARK_ALL_THAT_APPLY
  },
  {
    name: FormField.CHARACTERISTICS,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.CHARACTERISTICS
  },
  {
    name: FormField.HAIR_NOTES,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    name: FormField.CONFIGURATION,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.CONFIGURATION
  },
  {
    name: FormField.CONDITION,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.CONDITION
  },
  {
    name: FormField.CONDITION_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.OTHER_CONDITION
  },
  {
    name: FormField.NAIL_COLOR,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.COLOR
  },
  {
    name: FormField.NAIL_BASE,
    type: FormFieldInputType.MULTI_SELECT_RADIO,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.NAIL_BASE
  },
  {
    name: FormField.NAIL_BASE_OTHER,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.INTEGUMENTARY_ASSESSMENT.OTHER_NAIL_BASE
  },
  {
    name: FormField.NAIL_NOTES,
    type: FormFieldInputType.TEXT_AREA
  }
];

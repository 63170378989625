import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import SpecialPrecautionsIsolationAssessmentView from './SpecialPrecautionsIsolationAssessmentView';
import { Title } from '../shared/constants';

class SpecialPrecautionsIsolationAssessment extends Component<ChartComponentProps> {
  static displayName = 'SpecialPrecautionsIsolationAssessment';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    // set map items
    const checkBoxes = [FormField.ASSESSMENT_NOT_REQUIRED];
    const multiselectDropdowns = [FormField.STANDARD_PRECAUTIONS, FormField.CONTACT_PRECAUTIONS, FormField.RESPIRATORY_PRECAUTIONS, FormField.DROPLET_PRECAUTIONS];

    const textAreas = [FormField.SPECIAL_PRECAUTIONS_NOTES];
    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.SPECIAL_PRECAUTIONS_ISOLATION_ASSESSMENT, fields: [FormField.ASSESSMENT_NOT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.STANDARD_PRECAUTIONS,
        fields: [FormField.STANDARD_PRECAUTIONS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.RESPIRATORY_PRECAUTIONS,
        fields: [FormField.RESPIRATORY_PRECAUTIONS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.CONTACT_PRECAUTIONS,
        fields: [FormField.CONTACT_PRECAUTIONS, FormField.SPECIAL_PRECAUTIONS_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.DROPLET_PRECAUTIONS,
        fields: [FormField.DROPLET_PRECAUTIONS],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: SectionTitle.SPECIAL_PRECAUTIONS_ISOLATION_ASSESSMENT,
      // think of this "records" as sections
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: ChartViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <SpecialPrecautionsIsolationAssessmentView {...viewProps} />;
  }
}

export { SpecialPrecautionsIsolationAssessment as BaseSpecialPrecautionsIsolationAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(SpecialPrecautionsIsolationAssessment);

import { Component } from 'react';
import { CaseStudy } from 'models/ui';
import { caseStudyService } from 'services';
import { Sidebar } from 'components/common';
import CaseStudyQuickViewView, { CaseStudyQuickViewViewProps } from './CaseStudyQuickViewView';
import CaseStudyPreview from './case-study-preview/CaseStudyPreview';

export enum Tabs {
  OVERVIEW = 0,
  CHARTS_INVOLVED = 1,
  PHASES = 2
}

export interface CaseStudyQuickViewProps {
  initialShowPreview?: boolean;
  selectedCaseStudy: CaseStudy;
  onCloseSideBar?: VoidFunction;
  onSelectClick?: Function;
  isBookOrganized: boolean;
}

interface CaseStudyQuickViewStates {
  activeTab: number;
  showPreview: boolean;
  caseStudy: CaseStudy;
}

class CaseStudyQuickView extends Component<CaseStudyQuickViewProps, CaseStudyQuickViewStates> {
  static displayName = 'CaseStudyQuickView';

  constructor(props) {
    super(props);
    this.state = {
      activeTab: Tabs.OVERVIEW,
      showPreview: props.initialShowPreview || false,
      caseStudy: null
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedCaseStudy } = this.props;
    if (prevProps.selectedCaseStudy !== selectedCaseStudy && !!selectedCaseStudy) {
      caseStudyService.getCaseStudy(selectedCaseStudy.id).then(({ data }) => {
        this.setState({ caseStudy: data });
      });
    }
  }

  handleTabGroupClick = (tabIndex) => this.setState({ activeTab: tabIndex });

  handleCloseSideBar = () => this.setState({ activeTab: Tabs.OVERVIEW, showPreview: false }, this.props.onCloseSideBar);

  handlePreviewCaseStudy = () => this.setState({ showPreview: true });

  handleBackToQuickView = () => this.setState({ showPreview: false });

  render() {
    const { showPreview, activeTab, caseStudy } = this.state;
    const { selectedCaseStudy, onSelectClick } = this.props;
    const quickViewProps: CaseStudyQuickViewViewProps = {
      activeTab,
      selectedCaseStudy,
      onSelectClick,
      onCloseSideBar: this.handleCloseSideBar,
      onTabGroupClick: this.handleTabGroupClick,
      onPreviewCaseStudy: this.handlePreviewCaseStudy,
      isBookOrganized: this.props.isBookOrganized
    };
    const previewProps = {
      caseStudy,
      onBack: this.handleBackToQuickView
    };

    const previewContent = caseStudy ? <CaseStudyPreview {...previewProps} /> : null;
    return (
      <Sidebar open={!!selectedCaseStudy} onClose={this.handleCloseSideBar} hideCloseButton>
        {showPreview ? previewContent : <CaseStudyQuickViewView {...quickViewProps} />}
      </Sidebar>
    );
  }
}

export default CaseStudyQuickView;

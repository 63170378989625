import { Component } from 'react';
import ELSTextareaView from './ELSTextareaView';

export interface ELSTextareaProps {
  textLabel?: string;
  rows?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [restProps: string]: any;
}

class ELSTextarea extends Component<ELSTextareaProps> {
  render() {
    return <ELSTextareaView {...this.props} />;
  }
}

export default ELSTextarea;

import produce from 'immer';
import { orderBy } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Student } from 'models/api-response';
import { SortDirection } from 'models/enum';
import { apiHelper, appHelper } from 'helpers';
import { courseService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { instructorActions } from 'redux/ducks/instructor';
import StudentListView from './StudentListView';

interface StudentListProps {
  editMode: boolean;
  isPastDue?: boolean;
  courseId: string;
  setNewAssignment: Function;
  disabledAllOption: boolean;
  assignedStudentIds: number[];
  disabledIds?: number[];
  onStudentSelectionChange?: Function;
}

interface StudentListState {
  allStudents: Student[];
  isAllStudentsChecked: boolean;
  showStudentList: boolean;
  nameSearchValue: string;
  courseName: string;
  sortDirection: SortDirection;
}

class StudentList extends Component<StudentListProps, StudentListState> {
  static displayName = 'StudentList';
  static defaultProps = {
    editMode: false,
    assignedStudentIds: [],
    disabledIds: []
  };

  constructor(props) {
    super(props);
    this.state = {
      allStudents: [],
      isAllStudentsChecked: true,
      showStudentList: false,
      nameSearchValue: '',
      courseName: '',
      sortDirection: SortDirection.ASC
    };
  }

  componentDidMount() {
    appHelper.useLoader(
      Promise.all([
        courseService
          .getCourse(this.props.courseId)
          .then((res) => res.data)
          .then((course) => this.setState({ courseName: course.courseName }))
          .catch((err) => apiHelper.showApiError(`can not get course ${err}`)),
        courseService
          .getStudents(this.props.courseId)
          .then((res) => res.data)
          .then((students) => {
            const processedStudents = orderBy(students, ['firstName', 'lastName'], ['asc', 'asc']).map((item: Student) => ({
              ...item,
              selected: this.props.editMode ? this.props.assignedStudentIds.includes(item.id) : true,
              isShown: true,
              isDisabledSelect: this.props.isPastDue || this.props.disabledIds.includes(item.id)
            }));
            this.setState({ allStudents: processedStudents, isAllStudentsChecked: this.props.editMode ? this.props.assignedStudentIds.length === students.length : true });
          })
          .catch((err) => apiHelper.showApiError(`can not get student list of course ${this.props.courseId} ${err}`))
      ]).then(this.saveStudentIds)
    );
  }

  handleShowStudentList = () => {
    this.setState(
      produce((state: StudentListState) => {
        state.showStudentList = !state.showStudentList;
      })
    );
  };

  handleSearchNameChange = (event) => {
    const nameSearchValue = event.target.value || '';
    const searchName = nameSearchValue.trim().toLowerCase();
    this.setState(
      produce((state: StudentListState) => {
        state.nameSearchValue = nameSearchValue;
        state.allStudents = state.allStudents.map((item) => {
          const isShown = item.firstName.toLowerCase().includes(searchName) || item.lastName.toLowerCase().includes(searchName);
          return { ...item, isShown };
        });
      })
    );
  };

  handleAllStudentsChange = (event, isChecked) => {
    this.setState(
      produce((state: StudentListState) => {
        state.allStudents = state.allStudents.map((item) => ({ ...item, selected: isChecked }));
        state.isAllStudentsChecked = isChecked;
      }),
      this.saveStudentIds
    );
    if (this.props.onStudentSelectionChange) {
      this.props.onStudentSelectionChange();
    }
  };

  handleStudentChange = (studentId: number, selected: boolean) => {
    this.setState(
      produce((state: StudentListState) => {
        state.allStudents.find((item) => item.id === studentId).selected = selected;
        state.isAllStudentsChecked = state.allStudents.every((item) => item.selected);
      }),
      this.saveStudentIds
    );
    if (this.props.onStudentSelectionChange) {
      this.props.onStudentSelectionChange();
    }
  };

  saveStudentIds = () =>
    this.props.setNewAssignment({ students: this.state.allStudents.filter((item) => item.selected).map((item) => item.id), isAllStudents: this.state.isAllStudentsChecked });

  handleStudentSort = () => {
    this.setState(
      produce((state: StudentListState) => {
        state.sortDirection = state.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
        if (state.sortDirection === SortDirection.ASC) {
          state.allStudents = orderBy(state.allStudents, ['firstName', 'lastName'], ['asc', 'asc']);
        } else {
          state.allStudents = [...state.allStudents].reverse();
        }
      })
    );
  };

  render() {
    const { courseName, allStudents, sortDirection, showStudentList, isAllStudentsChecked, nameSearchValue } = this.state;
    const viewProps = {
      courseName,
      allStudents,
      isAllStudentsChecked,
      nameSearchValue,
      showStudentList,
      sortDirection,
      onShowStudentList: this.handleShowStudentList,
      onSearchNameChange: this.handleSearchNameChange,
      onAllStudentsChange: this.handleAllStudentsChange,
      onStudentChange: this.handleStudentChange,
      onStudentSort: this.handleStudentSort
    };
    return <StudentListView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  courseId: appSelectors.getCourseId(state)
});

const mapDispatchToProps = (dispatch) => ({
  setNewAssignment: (payload) => dispatch(instructorActions.setNewAssignment(payload))
});

export { StudentList as BaseStudentList };
export default connect(mapStateToProps, mapDispatchToProps)(StudentList);

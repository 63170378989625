export enum FormField {
  ORDER_MAINTENANCE_IV_RATE = 'orderMaintenanceIVRate',
  ORDER_MAINTENANCE_IV_ML = 'orderMaintenanceIVmL',
  ORDER_ANALGESIA = 'orderAnalgesia',
  ORDER_ANALGESIA_OTHER = 'orderAnalgesiaOther',
  ORDER_STARTING_DOSE_VOLUME = 'orderStartingDoseVolume',
  ORDER_STARTING_DOSE_VOLUME_OTHER = 'orderStartingDoseVolumeOther',
  ORDER_DELAY_INTERVAL = 'orderDelayInterval',
  ORDER_DELAY_INTERVAL_OTHER = 'orderDelayIntervalOther',
  ORDER_BASAL_RATE = 'orderBasalRate',
  ORDER_STARTING1_HOUR_LIMIT = 'orderStarting1HourLimit',
  ORDER_STARTING1_HOUR_LIMIT_OTHER = 'orderStarting1HourLimitOther',
  ORDER_BOLUS_LOADING = 'orderBolusLoading',
  ORDER_BOLUS_LOADING_OTHER = 'orderBolusLoadingOther',
  ORDER_UNRELIEVED_PAIN = 'orderUnrelievedPain',
  MONITORING_PAIN_ASSESSMENT_SCORE = 'monitoringPainAssessmentScore',
  MONITORING_RESPIRATORY_RATE = 'monitoringRespiratoryRate',
  MONITORING_OXYGEN_SATURATION = 'monitoringOxygenSaturation',
  MONITORING_OXYGEN_SATURATION_O2 = 'monitoringOxygenSaturationO2',
  MONITORING_EXCESSIVE_SEDATION = 'monitoringExcessiveSedation',
  MONITORING_PERSISTENT_PAIN = 'monitoringPersistentPain',
  MONITORING_NAUSEA = 'monitoringNausea',
  MONITORING_VOMITING = 'monitoringVomiting',
  MONITORING_ITCHING = 'monitoringItching',
  MONITORING_PROVIDER_NOTIFIED_OF_PAIN = 'monitoringProviderNotifiedOfPain',
  MONITORING_BRIEF_NOTES = 'monitoringBriefNotes'
}

export enum SectionTitle {
  PCA_FLOWSHEET = 'PCA Flowsheet',
  ORDER_MAINTENANCE_IV_RATE = 'Maintenance IV Rate',
  ORDER_MAINTENANCE_IV_ML = 'Maintenance IV mL/hour',
  ORDER_ANALGESIA = 'Analgesia',
  ORDER_ANALGESIA_OTHER = 'Analgesia Other',
  ORDER_STARTING_DOSE_VOLUME = 'Starting dose volume',
  ORDER_STARTING_DOSE_VOLUME_OTHER = 'Starting dose volume other',
  ORDER_DELAY_INTERVAL = 'Delay interval',
  ORDER_DELAY_INTERVAL_OTHER = 'Delay Interval Other',
  ORDER_BASAL_RATE = 'Basal rate',
  ORDER_STARTING1_HOUR_LIMIT = 'Starting 1 hour limit',
  ORDER_STARTING1_HOUR_LIMIT_OTHER = 'Starting 1 hour limit other',
  ORDER_BOLUS_LOADING = 'Bolus loading',
  ORDER_BOLUS_LOADING_OTHER = 'Bolus loading other',
  ORDER_UNRELIEVED_PAIN = 'For unrelieved pain',
  MONITORING_PAIN_ASSESSMENT_SCORE = 'Pain assessment score',
  MONITORING_RESPIRATORY_RATE = 'Respiratory rate',
  MONITORING_OXYGEN_SATURATION = 'Oxygen saturation',
  MONITORING_OXYGEN_SATURATION_O2 = 'Oxygen saturation 02',
  MONITORING_EXCESSIVE_SEDATION = 'Excessive sedation',
  MONITORING_PERSISTENT_PAIN = 'Persistent pain',
  MONITORING_NAUSEA = 'Nausea',
  MONITORING_VOMITING = 'Vomiting',
  MONITORING_ITCHING = 'Itching',
  MONITORING_PROVIDER_NOTIFIED_OF_PAIN = 'Provider notified of pain',
  MONITORING_BRIEF_NOTES = 'Brief notes'
}

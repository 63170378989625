import cx from 'classnames';
import { LaboratoryTestFragment } from 'models/api-response';
import { AccordionBadge, Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import LaboratoryTestResultTableView from 'components/features/chart/pre-clinical-manager/laboratory-test/LaboratoryTestResultTableView';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import DeleteTracker from './DeleteTracker';

interface SectionLaboratoryTestsProps {
  laboratoryTestRecords: LaboratoryTestFragment[];
}

const SectionLaboratoryTests = (props: SectionLaboratoryTestsProps) => {
  return (
    <Box mb4>
      <Box mb2>
        <h2>
          {'Laboratory Tests '}
          <span className="c-els-badge c-els-badge--size-intro">{props.laboratoryTestRecords.length}</span>
        </h2>
      </Box>
      <div className="report__record">
        {props.laboratoryTestRecords.map((item) => (
          <div className="record-item" key={item.fragmentId}>
            <Box mb2>
              <h3 className={cx('u-els-margin-right-2x u-els-display-inline-block', { 'row--deleted': !item.active })}>{item?.chartData?.labTest}</h3>
              {item?.chartData?.dateOfTest && (
                <AccordionBadge>
                  <span>{item?.chartData?.dateOfTest}</span>
                </AccordionBadge>
              )}
              {!item.active && (
                <DeleteTracker className="u-els-margin-left-2x" firstName={item?.modifier?.firstName} lastName={item?.modifier?.lastName} dateTime={item.updatedAt} />
              )}
            </Box>
            <ELSFlex className="u-els-margin-bottom">
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <h4>Definition and Description</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.definitionAndDescription} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2">
                <h4>Significance of the Test Being Ordered for this Patient</h4>
                <MultiLineWithEmpty className={cx({ 'row--deleted': !item.active })} text={item?.chartData?.significance} />
              </ELSFlexItem>
            </ELSFlex>
            <h4>Laboratory Test Results</h4>
            <Box px>
              <LaboratoryTestResultTableView isDeleted={!item.active} labResults={item?.chartData?.tests} />
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default SectionLaboratoryTests;

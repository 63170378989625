import { orderBy } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { ChartFragment, LaborProgressRecord } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, LaborGraphItem } from 'models/ui';
import { GRAPH_DATE_TIME_FORMAT, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { toMomentDateTime, toMomentWithFormat } from 'helpers/datetime.helper';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { chartConfig } from 'components/common/chart/config/chart.config';
import { LEFT_Y_AXIS_ID, RIGHT_Y_AXIS_ID } from 'components/common/chart/line/HistoryLineChart';
import { FormField, FormFieldLabel, GRAPH_CHART_TYPE } from './constants';
import LaborProgressView, { LaborProgressViewProps } from './LaborProgressView';

interface LaborProgressState {
  fragments: ChartFragment[];
  chartHistory: LaborProgressRecord[];
  graphData: LaborGraphItem[];
  dataKeys: string[];
  rightDataKeys: string[];
  graphChartType: string;
  isOnsetSidebarOpen: boolean;
  onsetDateTime: string;
  laborOnsetDateTimeOffset: string;
}

const ONSET_DATE_TIME_FORMAT = 'ddd MM-DD-YYYY, HH:mm';

class LaborProgress extends Component<ChartComponentProps, LaborProgressState> {
  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: [],
      graphData: [],
      dataKeys: [FormField.BASELINE_BPM],
      rightDataKeys: undefined,
      isOnsetSidebarOpen: false,
      onsetDateTime: '',
      laborOnsetDateTimeOffset: '',
      graphChartType: GRAPH_CHART_TYPE.FETAL_ASSESSMENT
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    this.loadLaborProgressData();
  }

  componentDidUpdate(prevProps: Readonly<ChartComponentProps>, prevState: Readonly<LaborProgressState>) {
    if (this.state.graphChartType !== prevState.graphChartType) {
      this.setState((state) => ({
        graphData: this.transformGraphData(state.chartHistory)
      }));
    }
  }

  transformGraphData = (laborData: LaborProgressRecord[]): LaborGraphItem[] => {
    const result = [];
    laborData.forEach((item: LaborProgressRecord) => {
      if (!item.active) return;
      if (this.state.graphChartType === GRAPH_CHART_TYPE.FETAL_ASSESSMENT && item.baselineBpm) {
        result.push({
          baselineBpm: Number(item.baselineBpm),
          baselineBpmDisplay: item.baselineBpm,
          date: dateTimeHelper.toDate(item.chartingAt),
          dateTime: dateTimeHelper.toMoment(item.chartingAt).format(GRAPH_DATE_TIME_FORMAT)
        });
      } else if (this.state.graphChartType === GRAPH_CHART_TYPE.PARTOGRAM && (item.cervicalDilation || item.station)) {
        const onsetDateTime = toMomentWithFormat(this.state.onsetDateTime, ONSET_DATE_TIME_FORMAT);
        const chartingAt = dateTimeHelper.toMoment(item.chartingAt);
        if (chartingAt.isAfter(onsetDateTime, 'm') && chartingAt.isBefore(onsetDateTime.clone().add(24, 'h'), 'm')) {
          const diff = chartingAt.diff(onsetDateTime);
          const duration = Math.round(moment.duration(diff).asHours());
          result.push({
            cervicalDilation: Number(item.cervicalDilation),
            cervicalDilationDisplay: item.cervicalDilation,
            station: Number(item.station),
            stationDisplay: item.station,
            duration,
            date: dateTimeHelper.toDate(item.chartingAt),
            dateTime: dateTimeHelper.toMoment(item.chartingAt).format(GRAPH_DATE_TIME_FORMAT)
          });
        }
      }
    });
    return result;
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    dataMap.set(
      FormField.BASELINE_BPM,
      createFormField({
        name: FormField.BASELINE_BPM,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Baseline (bpm)'
      })
    );
    dataMap.set(
      FormField.MONITOR_MODE,
      createFormField({
        name: FormField.MONITOR_MODE,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Monitor Mode'
      })
    );
    dataMap.set(
      FormField.VARIABILITY,
      createFormField({
        name: FormField.VARIABILITY,
        type: FormFieldInputType.DROPDOWN,
        label: 'Variability'
      })
    );
    dataMap.set(
      FormField.ACCELERATIONS,
      createFormField({
        name: FormField.ACCELERATIONS,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Accelerations'
      })
    );
    dataMap.set(
      FormField.DECELERATIONS,
      createFormField({
        name: FormField.DECELERATIONS,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Decelerations'
      })
    );
    dataMap.set(
      FormField.FREQUENCY,
      createFormField({
        name: FormField.FREQUENCY,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Frequency (min)'
      })
    );
    dataMap.set(
      FormField.MONITOR_MODE_DROPDOWN,
      createFormField({
        name: FormField.MONITOR_MODE_DROPDOWN,
        type: FormFieldInputType.DROPDOWN,
        label: 'Monitor Mode',
        errorLabel: FormFieldLabel.MONITOR_MODE_DROPDOWN
      })
    );
    dataMap.set(
      FormField.DURATION,
      createFormField({
        name: FormField.DURATION,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Duration (sec)'
      })
    );
    dataMap.set(
      FormField.INTENSITY,
      createFormField({
        name: FormField.INTENSITY,
        type: FormFieldInputType.DROPDOWN,
        label: 'Intensity'
      })
    );
    dataMap.set(
      FormField.CERVICAL_DILATION,
      createFormField({
        name: FormField.CERVICAL_DILATION,
        type: FormFieldInputType.DROPDOWN,
        label: FormFieldLabel.CERVICAL_DILATION
      })
    );
    dataMap.set(
      FormField.EFFACEMENT,
      createFormField({
        name: FormField.EFFACEMENT,
        type: FormFieldInputType.TEXT_BOX,
        label: 'Effacement (%)'
      })
    );
    dataMap.set(
      FormField.STATION,
      createFormField({
        name: FormField.STATION,
        type: FormFieldInputType.DROPDOWN,
        label: 'Station'
      })
    );
    dataMap.set(
      FormField.POSITION,
      createFormField({
        name: FormField.POSITION,
        type: FormFieldInputType.DROPDOWN,
        label: 'Position'
      })
    );
    dataMap.set(
      FormField.NOTES,
      createFormField({
        name: FormField.NOTES,
        type: FormFieldInputType.TEXT_AREA,
        label: 'Notes'
      })
    );

    return dataMap;
  };

  loadLaborProgressData = () => {
    const chartId = this.props.assessment.simChartId;
    const navIds = [NAV_ID.LABOR_PROGRESS];
    appHelper.useLoader(
      this.props.loadChartData([FragmentType.CHARTING, FragmentType.AUTHORED], navIds, chartId).then(({ data }) => {
        const records = data
          .filter((fragment) => !fragment.chartData[FormField.IS_ONSET_SIDE_BAR])
          .map((fragment) => {
            const { fragmentId: id, active, chartingAt, createdAt, creator, modifier, chartData } = fragment;
            return {
              id,
              active,
              chartingAt,
              createdAt,
              creator,
              modifier,
              laborOnsetDate: chartData[FormField.LABOR_ONSET_DATE]?.content,
              laborOnsetTime: chartData[FormField.LABOR_ONSET_TIME]?.content,
              baselineBpm: chartData[FormField.BASELINE_BPM]?.content || '',
              monitorMode: chartData[FormField.MONITOR_MODE]?.content,
              variability: chartData[FormField.VARIABILITY]?.content,
              accelerations: chartData[FormField.ACCELERATIONS]?.content,
              decelerations: chartData[FormField.DECELERATIONS]?.content,
              monitorModeDropDown: chartData[FormField.MONITOR_MODE_DROPDOWN]?.content,
              intensity: chartData[FormField.INTENSITY]?.content,
              frequency: chartData[FormField.FREQUENCY]?.content,
              duration: chartData[FormField.DURATION]?.content,
              cervicalDilation: chartData[FormField.CERVICAL_DILATION]?.content,
              station: chartData[FormField.STATION]?.content,
              position: chartData[FormField.POSITION]?.content,
              effacement: chartData[FormField.EFFACEMENT]?.content,
              notes: chartData[FormField.NOTES]?.content
            };
          });
        const graphData = this.transformGraphData(records as LaborProgressRecord[]);
        this.setState({ fragments: data, chartHistory: records as LaborProgressRecord[], graphData });

        const sortedFragments = orderBy(data, ['chartingAt', 'createdAt'], ['desc', 'desc']);
        const onsetDateTimeFragment = sortedFragments.find(
          (fragment) => fragment.active && fragment.chartData[FormField.LABOR_ONSET_DATE] && fragment.chartData[FormField.LABOR_ONSET_TIME]
        );
        const onsetTimeOffsetFragment = sortedFragments.find((fragment) => fragment.active && fragment.chartData[FormField.LABOR_ONSET_DATETIME_OFFSET]);
        this.setLaborOnsetTime(onsetDateTimeFragment);
        this.setLaborOnsetDateTimeOffset(onsetTimeOffsetFragment);
        this.setSideBarOpen((onsetTimeOffsetFragment && onsetDateTimeFragment) || (!onsetTimeOffsetFragment && !onsetDateTimeFragment));
      })
    );
  };

  setLaborOnsetTime = (onsetDateTimeFragment) => {
    if (onsetDateTimeFragment) {
      const { chartData } = onsetDateTimeFragment;
      const onsetDateTime = toMomentDateTime(`${chartData[FormField.LABOR_ONSET_DATE]} ${chartData[FormField.LABOR_ONSET_TIME]}`).format(ONSET_DATE_TIME_FORMAT);
      this.setState({ onsetDateTime });
    }
  };

  setLaborOnsetDateTimeOffset = (onsetTimeOffsetFragment) => {
    if (onsetTimeOffsetFragment) {
      this.setState({
        [FormField.LABOR_ONSET_DATETIME_OFFSET]: onsetTimeOffsetFragment.chartData[FormField.LABOR_ONSET_DATETIME_OFFSET]
      });
    }
  };

  setSideBarOpen = (isOpen) => {
    this.setState({
      isOnsetSidebarOpen: isOpen
    });
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(this.loadLaborProgressData)
        .then(this.props.showDeleteSuccess)
    );
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const { laborOnsetDateTimeOffset } = this.state;
    const { buildPatientRecord, createBaseFragment } = chartService;
    const basicInfo = createBaseFragment({ chartingTime: this.props.chartingTime });

    const chartData = {
      baselineBpm: buildPatientRecord(formFieldMap, FormField.BASELINE_BPM),
      monitorMode: buildPatientRecord(formFieldMap, FormField.MONITOR_MODE),
      variability: buildPatientRecord(formFieldMap, FormField.VARIABILITY),
      accelerations: buildPatientRecord(formFieldMap, FormField.ACCELERATIONS),
      decelerations: buildPatientRecord(formFieldMap, FormField.DECELERATIONS),
      monitorModeDropDown: buildPatientRecord(formFieldMap, FormField.MONITOR_MODE_DROPDOWN),
      intensity: buildPatientRecord(formFieldMap, FormField.INTENSITY),
      frequency: buildPatientRecord(formFieldMap, FormField.FREQUENCY),
      duration: buildPatientRecord(formFieldMap, FormField.DURATION),
      cervicalDilation: buildPatientRecord(formFieldMap, FormField.CERVICAL_DILATION),
      station: buildPatientRecord(formFieldMap, FormField.STATION),
      position: buildPatientRecord(formFieldMap, FormField.POSITION),
      effacement: buildPatientRecord(formFieldMap, FormField.EFFACEMENT),
      notes: buildPatientRecord(formFieldMap, FormField.NOTES),
      laborOnsetDateTimeOffset
    };

    return { ...basicInfo, chartData };
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildDefaultFormFields(),
      afterSave: this.props.backToSourceLocation || this.loadLaborProgressData
    });
  };

  onSelectGraph = ({ target: { value } }) => {
    this.setState(() => {
      const dataKeys =
        value === GRAPH_CHART_TYPE.FETAL_ASSESSMENT
          ? {
              dataKeys: [FormField.BASELINE_BPM],
              rightDataKeys: undefined
            }
          : { dataKeys: [FormField.CERVICAL_DILATION], rightDataKeys: [FormField.STATION] };
      return {
        graphChartType: value,
        ...dataKeys
      };
    });
  };

  onCloseSidebar = () =>
    this.setState({
      isOnsetSidebarOpen: false
    });

  onSavedData = () => {
    this.loadLaborProgressData();
    this.onCloseSidebar();
  };

  enableSave = () => (this.props.isAuthor ? this.props.hasUnsavedChanges : this.state.onsetDateTime && this.props.hasUnsavedChanges);

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.enableSave(),
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const fetalAssessmentProps =
      this.state.graphChartType === GRAPH_CHART_TYPE.FETAL_ASSESSMENT
        ? {
            yAxisName: { [LEFT_Y_AXIS_ID]: 'Baseline (bpm)' },
            legendType: 'circle',
            strokeWidth: 0,
            dotFormat: {
              stroke: chartConfig.dataColors[1],
              strokeWidth: 10
            }
          }
        : {
            yAxisName: { [LEFT_Y_AXIS_ID]: FormFieldLabel.CERVICAL_DILATION, [RIGHT_Y_AXIS_ID]: 'Station' },
            legendType: null,
            strokeWidth: null,
            dotFormat: null
          };
    const viewProps: LaborProgressViewProps = {
      formFieldMap: this.props.formFieldMap,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory,
      formSubmittedCount: this.props.formSubmittedCount,
      graphData: this.state.graphData,
      dataKeys: this.state.dataKeys,
      rightDataKeys: this.state.rightDataKeys,
      leftYGraphLabel: this.state.dataKeys.includes(FormField.BASELINE_BPM) ? 'Heart Rate (BPM)' : FormFieldLabel.CERVICAL_DILATION,
      rightYGraphLabel: 'Station',
      onSelectGraph: this.onSelectGraph,
      graphChartType: this.state.graphChartType,
      xAxisDataKey: this.state.graphChartType === GRAPH_CHART_TYPE.PARTOGRAM ? 'duration' : null,
      isOnsetSidebarOpen: this.state.isOnsetSidebarOpen,
      onsetDateTime: this.state.onsetDateTime,
      saveChartData: this.props.saveChartData,
      onSavedData: this.onSavedData,
      chartActionsProps,
      ...fetalAssessmentProps,
      isAuthor: this.props.isAuthor,
      ...appHelper.getChartSharedProps(this.props)
    };

    return <LaborProgressView {...viewProps} />;
  }
}

export { LaborProgress as BaseLaborProgress };
export default withChartLogic(LaborProgress);

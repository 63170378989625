import { produce } from 'immer';
import { isEmpty, orderBy } from 'lodash';
import React, { Component } from 'react';
import { CarePlanRecord, ChartFragment, InterventionItem } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField, DropDownItem } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel, InterventionField } from './constants';
import CarePlanView from './CarePlanView';

interface CarePlanState {
  selectedCarePlan: CarePlanRecord;
  fragments: ChartFragment[];
  chartHistory: CarePlanRecord[];
  priorityItems: DropDownItem[];
  interventionItems?: InterventionItem[];
  outcomeEvaluationStatus?: string;
  isDisplayDocument: boolean;
  focusedInterventionFieldId: string;
}

export const buildFormFields = (fragment?: ChartFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();

  const textAreas = [
    { name: FormField.NURSING_PROBLEM, label: FormFieldLabel.NURSING_PROBLEM },
    { name: FormField.EXPECTED_OUTCOME, label: FormFieldLabel.EXPECTED_OUTCOME },
    { name: FormField.INTERVENTION_PLAN, label: FormFieldLabel.INTERVENTION_PLAN },
    { name: FormField.OBJECTIVE_DATA, label: FormFieldLabel.OBJECTIVE_DATA },
    { name: FormField.SUBJECTIVE_DATA, label: FormFieldLabel.SUBJECTIVE_DATA }
  ];
  textAreas.forEach(({ name, label }) =>
    dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_AREA, value: fragment?.chartData?.[name]?.content || '' }))
  );
  dataMap.set(
    FormField.INTERPROFESSIONAL_PROBLEM,
    createFormField({
      name: FormField.INTERPROFESSIONAL_PROBLEM,
      label: FormFieldLabel.INTERPROFESSIONAL_PROBLEM,
      type: FormFieldInputType.TEXT_BOX,
      value: fragment?.chartData?.[FormField.INTERPROFESSIONAL_PROBLEM]?.content || ''
    })
  );

  dataMap.set(
    FormField.EVAL_DATE,
    createFormField({
      name: FormField.EVAL_DATE,
      type: FormFieldInputType.DATE,
      label: FormFieldLabel.EVAL_DATE,
      value: fragment?.chartData?.[FormField.EVAL_DATE]?.content || ''
    })
  );

  return dataMap;
};

class CarePlan extends Component<ChartComponentProps, CarePlanState> {
  static displayName = 'CarePlan';

  constructor(props) {
    super(props);
    this.state = {
      selectedCarePlan: null,
      fragments: [],
      chartHistory: [],
      priorityItems: [],
      interventionItems: [],
      outcomeEvaluationStatus: '',
      isDisplayDocument: false,
      focusedInterventionFieldId: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  setDefaultField = () => {
    this.setState({
      isDisplayDocument: false,
      outcomeEvaluationStatus: '',
      interventionItems: [
        {
          name: '',
          rationale: '',
          status: '',
          actions: ['']
        }
      ]
    });
  };
  hasAnyFieldValueChanged = (item: InterventionItem): boolean =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') return !isEmpty(value);
      return value.some((action: string) => !isEmpty(action));
    });

  buildFragment = () => {
    const { formFieldMap, chartingTime } = this.props;
    const { outcomeEvaluationStatus, fragments, interventionItems } = this.state;
    const { buildPatientRecord } = chartService;
    const validInterventionItems = interventionItems.filter(this.hasAnyFieldValueChanged);
    const chartData = {
      [FormField.INTERPROFESSIONAL_PROBLEM]: buildPatientRecord(formFieldMap, FormField.INTERPROFESSIONAL_PROBLEM),
      [FormField.NURSING_PROBLEM]: buildPatientRecord(formFieldMap, FormField.NURSING_PROBLEM),
      [FormField.EXPECTED_OUTCOME]: buildPatientRecord(formFieldMap, FormField.EXPECTED_OUTCOME),
      [FormField.INTERVENTION_PLAN]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_PLAN),
      [FormField.OBJECTIVE_DATA]: buildPatientRecord(formFieldMap, FormField.OBJECTIVE_DATA),
      [FormField.SUBJECTIVE_DATA]: buildPatientRecord(formFieldMap, FormField.SUBJECTIVE_DATA),
      [FormField.INTERVENTIONS]: validInterventionItems,
      [FormField.EVAL_DATE]: buildPatientRecord(formFieldMap, FormField.EVAL_DATE),
      [FormField.OUTCOME_EVALUATION_STATUS]: outcomeEvaluationStatus,
      [FormField.PLAN_RESOLVED]: false,
      [FormField.PRIORITY]: this.buildPriority(fragments)
    };
    const basicInfo = chartService.createBaseFragment({ chartingTime });
    return { ...basicInfo, chartData } as ChartFragment;
  };

  createPriorityItems = (itemCount: number) => {
    const items = [{ name: '', value: '', hidden: true }];
    for (let index = 1; index <= itemCount; index += 1) {
      items.push({ name: `P${index}`, value: `${index}`, hidden: false });
    }
    this.setState({ priorityItems: items });
  };

  buildPriority = (fragments: ChartFragment[]) => {
    return fragments.filter((frag) => frag.active).length + 1;
  };

  handlePriorityChange = (record: CarePlanRecord, event: React.ChangeEvent<HTMLInputElement>) => {
    const { handleSaveClick } = this.props;
    const { value } = event.currentTarget;
    let updatedFragment = this.state.fragments.find((frag) => frag.fragmentId === record.fragmentId);
    updatedFragment = produce(updatedFragment, (draft) => {
      draft.chartData[FormField.PRIORITY] = Number(value);
    });
    handleSaveClick([updatedFragment], { afterSave: this.loadData });
  };

  createTitle = (interprofessionalProblem: string, nursingProblem: string) =>
    interprofessionalProblem && nursingProblem ? [interprofessionalProblem, nursingProblem].join(' - ') : [interprofessionalProblem, nursingProblem].join('');

  transformFragmentToHistoryRecord = (fragment: ChartFragment): CarePlanRecord => {
    const title = this.createTitle(fragment.chartData[FormField.INTERPROFESSIONAL_PROBLEM]?.content, fragment.chartData[FormField.NURSING_PROBLEM]?.content);
    const chartTime = fragment.chartingAt;
    const fullName = chartService.formatName(fragment.creator);
    const subjectiveData = fragment.chartData[FormField.SUBJECTIVE_DATA]?.content;
    const objectiveData = fragment.chartData[FormField.OBJECTIVE_DATA]?.content;
    const outcome = {
      expectedOutcome: fragment.chartData[FormField.EXPECTED_OUTCOME]?.content,
      status: fragment.chartData[FormField.OUTCOME_EVALUATION_STATUS]
    };
    const interventionPlan = fragment.chartData[FormField.INTERVENTION_PLAN]?.content;
    const interventionItems = fragment.chartData[FormField.INTERVENTIONS];
    const evalDate = fragment.chartData[FormField.EVAL_DATE]?.content;
    const priority = fragment.chartData[FormField.PRIORITY];
    return {
      ...fragment,
      id: fragment.fragmentId,
      title,
      chartTime,
      fullName,
      subjectiveData,
      objectiveData,
      outcome,
      interventionPlan,
      interventionItems,
      evalDate,
      priority
    } as CarePlanRecord;
  };

  loadData = () => {
    this.props.initState(buildFormFields());
    this.setDefaultField();
    appHelper.useLoader(
      this.props.loadChartData().then(({ data }) => {
        const records = data.map((fragment: ChartFragment) => this.transformFragmentToHistoryRecord(fragment));
        const orderRecord = orderBy(records, ['priority', 'chartingAt'], ['asc', 'desc']) as CarePlanRecord[];
        this.setState({ fragments: data, chartHistory: orderRecord });
        this.createPriorityItems(data.filter((fragment) => fragment.active).length);
      }),
      { errorMessage: 'can not load chart fragment' }
    );
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: buildFormFields(), afterSave: this.loadData });
  };

  handleEditClick = (selectedCarePlan: CarePlanRecord) => {
    this.setState({ selectedCarePlan });
  };

  handleChartUpdate = () => {
    this.loadData();
    this.setState({ selectedCarePlan: null });
    this.props.showSaveSuccess();
  };

  handleSidebarClose = () => {
    this.setState({ selectedCarePlan: null });
  };

  handleDeleteClick = (record) => this.props.showDeleteConfirmation(() => this.handleConfirmDelete(record));

  handleConfirmDelete = (record) => {
    let deleteFragment = this.state.fragments.find((frag) => frag.fragmentId === record.id);
    let impactedFragments = this.state.fragments.filter(
      (frag) => frag.active && frag.fragmentId !== deleteFragment.fragmentId && frag.chartData[FormField.PRIORITY] > deleteFragment.chartData[FormField.PRIORITY]
    );
    impactedFragments = impactedFragments.map((frag) =>
      produce(frag, (draft) => {
        draft.chartData[FormField.PRIORITY] -= 1;
      })
    );
    deleteFragment = produce(deleteFragment, (draft) => {
      draft.chartData[FormField.PRIORITY] = undefined;
      draft.active = false;
    });

    const saveDataPromises = [deleteFragment, ...impactedFragments].map((frag) => this.props.saveChartData(frag));
    appHelper.useLoader(
      Promise.all(saveDataPromises)
        .then(this.loadData)
        .then(this.props.showDeleteSuccess)
        .then(() => appHelper.scrollTop()),
      { errorMessage: 'can not delete chart history' }
    );
  };

  resetFocusedInterventionFieldId = () => {
    this.setState({ focusedInterventionFieldId: null });
  };

  handleFormFieldChange = (fieldId: string, event: React.ChangeEvent<HTMLInputElement>, interventionItemIndex?: number) => {
    const { value } = event.currentTarget;

    switch (fieldId) {
      case InterventionField.NAME:
      case InterventionField.STATUS:
      case InterventionField.RATIONALE: {
        this.setState({ focusedInterventionFieldId: `${fieldId}-${interventionItemIndex}` });
        this.setState(
          produce((state) => {
            state.interventionItems[interventionItemIndex][fieldId] = value;
          })
        );
        break;
      }
      case FormField.OUTCOME_EVALUATION_STATUS: {
        this.setState({ outcomeEvaluationStatus: value });
        break;
      }
      default:
        break;
    }
  };

  handleDiscard = () => {
    this.props.handleDiscardClick(undefined, buildFormFields());
    this.setDefaultField();
  };

  handleDisplayDocument = () => {
    this.setState((state) => ({ isDisplayDocument: !state.isDisplayDocument }));
  };

  handleAddInterventionItem = () => {
    this.setState(
      produce((state) => {
        state.interventionItems.push({ name: '', rationale: '', status: '', actions: [''] });
      })
    );
  };

  enableSave = (): boolean => {
    const { hasUnsavedChanges } = this.props;
    const { outcomeEvaluationStatus, interventionItems } = this.state;
    const isOutcomeEvaluationStatusChanged = outcomeEvaluationStatus !== '';
    const isInterventionItemsChanged = interventionItems.some(this.hasAnyFieldValueChanged);
    return hasUnsavedChanges || isOutcomeEvaluationStatusChanged || isInterventionItemsChanged;
  };

  render() {
    const { enableDisplayRecordsButton, displayAuthoringData, formFieldMap, formSubmittedCount, saveChartData } = this.props;
    const { focusedInterventionFieldId, selectedCarePlan, isDisplayDocument, chartHistory, priorityItems, outcomeEvaluationStatus, interventionItems } = this.state;
    const chartActionsProps = {
      enableDisplayRecordsButton,
      enableSaveButton: this.enableSave(),
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: this.handleDiscard,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      saveChartData,
      formFieldMap,
      formSubmittedCount,
      chartHistory,
      priorityItems,
      interventionItems,
      selectedCarePlan,
      isDisplayDocument,
      outcomeEvaluationStatus,
      focusedInterventionFieldId,
      resetFocusedInterventionFieldId: this.resetFocusedInterventionFieldId,
      onFormFieldChange: this.handleFormFieldChange,
      onEditClick: this.handleEditClick,
      onSidebarClose: this.handleSidebarClose,
      onDeleteClick: this.handleDeleteClick,
      onCarePlanUpdate: this.handleChartUpdate,
      onDisplayDocument: this.handleDisplayDocument,
      onPriorityChange: this.handlePriorityChange,
      onAddInterventionItem: this.handleAddInterventionItem,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <CarePlanView {...viewProps} />;
  }
}

export { CarePlan as BaseCarePlan };
export default withChartLogic(CarePlan);

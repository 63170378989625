export enum SectionTitle {
  APPEARANCE = 'Appearance',
  EMOTIONAL_STATUS = 'Emotional Status',
  COGNITIVE_ABILITY = 'Cognitive Ability',
  ORIENTATION = 'Orientation',
  MEMORY = 'Memory',
  THOUGHT_FORMULATION = 'Thought Formulation',
  THOUGHT_CONTENT = 'Thought Content',
  ABSTRACT_REASONING = 'Abstract Reasoning (Able to Report Logical Response)',
  ATTENTION_SPAN = 'Attention Span',
  JUDGMENT = 'Judgment',
  SPEECH_LANGUAGE = 'Speech and Language',
  SPEECH_LANGUAGE_ARTICULATION = 'Articulation',
  SPEECH_LANGUAGE_COHERENCE = 'Coherence',
  COMPREHENSION = 'Comprehension',
  APHASIA = 'Aphasia',
  PATIENT_ASSETS = 'Patient Assets',
  PROBLEM_AREAS = 'Problem Areas',
  DECISION_MAKING_RATING = 'Decision Making Rating',
  TREATMENT_PLAN_RECOMMENDATIONS = 'Treatment Plan Recommendations',
  BEHAVIORAL_STATUS = 'Behavioral status',
  CURRENT_SAFETY_ISSUES = 'Current Safety Issues',
  PSYCHIATRIC_HISTORY = 'Psychiatric History',
  SUICIDE_HISTORY = 'Suicide History',
  LIFE_STRESSORS = 'Life Stressors',
  SAFETY_EVALUATION = 'Safety Evaluation',
  FAMILY_ORIGIN = 'Family Origin',
  CURRENT_PSYCHOTHERAPIST = 'Current Psychotherapist',
  CURRENT_PRIMARY_CARE_PROVIDER = 'Current Primary Care Provider',
  DEPRESSION_EVALUATION = 'Depression Evaluation',
  PATIENT_HEALTH_QUESTIONNAIRE = 'Patient Health Questionnaire'
}
export enum FormField {
  APPEARANCE_GROOMING = 'appearanceGrooming',
  EMOTIONAL_STATUS_FEEL_RIGHT_NOW = 'emotionalStatusFeelRightNow',
  EMOTIONAL_STATUS_BODY_LANGUAGE = 'emotionalStatusBodyLanguage',
  PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS = 'past2WeekDownDepressedHopeless',
  PAST_2_WEEK_INTEREST_THINGS = 'past2WeekInterestThings',
  COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME = 'cognitiveAbilityOrientationOrientedToTime',
  COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON = 'cognitiveAbilityOrientationOrientedToPerson',
  COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE = 'cognitiveAbilityOrientationOrientedToPlace',
  COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH = 'cognitiveAbilityAbstractReasoningBirdHandWorthTwoBrush',
  COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS = 'cognitiveAbilityAbstractReasoningRollingStoneGatherNoMoss',
  COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE = 'cognitiveAbilityMemoryRecall5ObjectName3Minute',
  COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH = 'cognitiveAbilityMemoryRecallPlaceOfBirth',
  COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN = 'cognitiveAbilityMemoryRecallYearBorn',
  COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME = 'cognitiveAbilityMemoryRecallMotherMaidenName',
  COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK = 'cognitiveAbilityAttentionSpanCorrectlyDayOfWeek',
  COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD = 'cognitiveAbilityAttentionSpanCorrectlySpellWorldBackward',
  JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS = 'judgmentPatientMeetingSocialFamilyObligations',
  JUDGMENT_PLANS_FUTURE = 'judgmentPlansFuture',
  SPEECH_LANGUAGE_ARTICULATION = 'speechLanguageArticulation',
  SPEECH_LANGUAGE_ARTICULATION_OTHER = 'speechLanguageArticulationOther',
  SPEECH_LANGUAGE_COHERENCE = 'speechLanguageCoherence',
  SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS = 'speechLanguageComprehensionSimpleDirections',
  SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS = 'speechLanguageComprehensionComplexDirections',
  SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY = 'speechLanguageAphasiaCommunicateVerbally',
  THOUGHT_FORMULATION = 'thoughtFormulation',
  THOUGHT_CONTENT = 'thoughtContent',
  PATIENT_ASSETS = 'patientAssets',
  PROBLEM_AREAS = 'problemAreas',
  DECISION_MAKING_RATING = 'decisionMakingRating',
  TREATMENT_PLAN_RECOMMENDATIONS = 'treatmentPlanRecommendations',
  BEHAVIORAL_STATUS = 'behavioralStatus',
  CURRENT_SAFETY_ISSUES = 'currentSafetyIssues',
  PSYCHIATRIC_HISTORY = 'psychiatricHistory',
  SUICIDE_HISTORY_THOUGHTS = 'suicideHistoryThoughts',
  SUICIDE_HISTORY = 'suicideHistory',
  LIFE_STRESSORS = 'lifeStressors',
  SAFETY_EVALUATIONS_SUICIDE_HOMICIDE_IMPULSES = 'safetyEvaluationSuicideHomicideImpulses',
  BIRTH_ORDER = 'birthOrder',
  GROW_UP = 'growUp',
  CURRENT_LIVING_SITUATION = 'currentLivingSituation',
  CURRENT_EMPLOYMENT_STATUS = 'currentEmploymentStatus',
  PREVIOUS_OCCUPATIONS = 'previousOccupations',
  CURRENT_PSYCHOTHERAPIST_NAME = 'currentPsychotherapistName',
  CURRENT_PSYCHOTHERAPIST_ADDRESS = 'currentPsychotherapistAddress',
  CURRENT_PSYCHOTHERAPIST_PHONE_NUMBER = 'currentPsychotherapistPhoneNumber',
  CURRENT_PRIMARY_CARE_PROVIDER_NAME = 'currentPrimaryCareProviderName',
  CURRENT_PRIMARY_CARE_PROVIDER_ADDRESS = 'currentPrimaryCareProviderNameAddress',
  CURRENT_PRIMARY_CARE_PROVIDER_PHONE = 'currentPrimaryCareProviderNamePhone',
  PARENT_SIBLINGS_INFO_NAME_1 = 'parentsSiblingsName1',
  PARENT_SIBLINGS_INFO_LIVING_DECEASED_1 = 'parentsSiblingsLivingDeceased1',
  PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_1 = 'parentsSiblingsPsychiatricDiagnosis1',
  PARENT_SIBLINGS_INFO_NAME_2 = 'parentsSiblingsName2',
  PARENT_SIBLINGS_INFO_LIVING_DECEASED_2 = 'parentsSiblingsLivingDeceased2',
  PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_2 = 'parentsSiblingsPsychiatricDiagnosis2',
  PARENT_SIBLINGS_INFO_NAME_3 = 'parentsSiblingsName3',
  PARENT_SIBLINGS_INFO_LIVING_DECEASED_3 = 'parentsSiblingsLivingDeceased3',
  PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_3 = 'parentsSiblingsPsychiatricDiagnosis3',
  PARENT_SIBLINGS_INFO_NAME_4 = 'parentsSiblingsName4',
  PARENT_SIBLINGS_INFO_LIVING_DECEASED_4 = 'parentsSiblingsLivingDeceased4',
  PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_4 = 'parentsSiblingsPsychiatricDiagnosis4',
  PARENT_SIBLINGS_INFO_NAME_5 = 'parentsSiblingsName5',
  PARENT_SIBLINGS_INFO_LIVING_DECEASED_5 = 'parentsSiblingsLivingDeceased5',
  PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_5 = 'parentsSiblingsPsychiatricDiagnosis5',
  PATIENT_HEALTH_QUESTIONNAIRE = 'patientHealthQuestionnaire'
}
export enum FormFieldLabel {
  APPEARANCE_GROOMING = 'Grooming',
  EMOTIONAL_STATUS_FEEL_RIGHT_NOW = 'How do you feel right now?',
  EMOTIONAL_STATUS_BODY_LANGUAGE = 'Body language',
  PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS = 'Over the past 2 weeks, have you felt down, depressed, or hopeless?',
  PAST_2_WEEK_INTEREST_THINGS = 'Over the past 2 weeks, have you had little interest in doing things?',
  COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME = 'Oriented to time',
  COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON = 'Oriented to person',
  COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE = 'Oriented to place',
  COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH = 'A bird in the hand is worth two in the bush',
  COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS = 'A rolling stone gathers no moss',
  COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE = 'Recall 5 object names 3 minutes after mention?',
  COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH = 'Can recall place of birth?',
  COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN = 'Can recall year born?',
  COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME = "Can recall mother's maiden name?",
  COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK = 'Can correctly say the days of the week?',
  COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD = 'Can correctly spell "world" backwards?',
  JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS = 'How well is patient meeting social and family obligations?',
  JUDGMENT_PLANS_FUTURE = 'What are your plans for the future?',
  SPEECH_LANGUAGE_ARTICULATION_OTHER = 'Others',
  SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS = 'Can follow simple directions?',
  SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS = 'Can follow complex directions?',
  SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY = 'Can communicate verbally?',
  IMMEDIATE_NEED_FOR_ANY = 'Immediate need for any of the following:',
  SUICIDE_HISTORY = 'Suicide History:',
  LIFE_STRESSORS = 'Life Stressors in the Past Year:',
  SAFETY_EVALUATION = 'Suicidal/homicidal impulses:',
  BIRTH_ORDER_IN_FAMILY = 'What is your birth order in your family?',
  TOWN_STATE_COUNTRY = 'In what town/state/country did you grow up?',
  CURRENT_LIVING_SITUATION = 'Current living situation:',
  CURRENT_EMPLOYMENT_STATUS = 'Current employment status:',
  PREVIOUS_OCCUPATIONS = 'Previous occupations:',
  NAME = 'Name:',
  PHONE = 'Phone Number:',
  ADDRESS = 'Address:',
  LIVING_DECEASED = 'Living/Deceased',
  PSYCHIATRIC_DIAGNOSIS = 'Psychiatric diagnosis',
  BEHAVIORAL_STATUS = 'Behavioral Status',
  PATIENT_QUESTIONNAIRE = 'Patient Health Questionnaire (PHQ-9)',
  CURRENT_SAFETY_ISSUES_QUESTION = 'Are you concerned about harming yourself or anyone else?',
  PHQ_9_SCORING = 'PHQ-9 Scoring for Severity Determination'
}

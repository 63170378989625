import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { FormField, Section } from './constants';

export interface ModifiedBradenQScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const ModifiedBradenQScaleView = (props: ModifiedBradenQScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="hamilton-anxiety-rating-scale">
      <h1>{Section.MODIFIED_BRADEN_Q_SCALE}</h1>
      <p>
        <span className="u-els-color-error">* </span>
        All fields in this chart are required
      </p>
      <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.MODIFIED_BRADEN_Q_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: false }}
      />
      <ChartActions {...chartActionsProps} />
      <p className="u-els-margin-bottom">
        Modified from Curley MAQ, Razmus IS, Roberts KE, & Wypij D: Predicting pressure ulcer risk in pediatric patients: The Braden Q Scale. Nursing Research 52, 22–33, 2003.
      </p>
    </div>
  );
};

export default ModifiedBradenQScaleView;

export enum SectionLabel {
  CHILD_RISK_ASSESSMENT = 'Child Risk Assessment',
  PRESENCE_OF_RISK = 'Indicate the presence of risk in the following',
  APPROPRIATE_REFERRAL = 'If you select Yes to any of the above, make appropriate referral',
  DENTAL_CARE = 'Dental care',
  CHILD_PASSENGER_SAFETY = 'Child passenger safety',
  ABUSE_MALTREATMENT = 'Abuse/maltreatment',
  SLEEP = 'Sleep',
  PLAYGROUND_INJURIES = 'Playground injuries',
  POISONINGS = 'Poisonings',
  WATER_SAFETY = 'Water safety',
  RESIDENTIAL_FIRES = 'Residential fires',
  BICYCLE_RELATED_INJURIES = 'Bicycle-related injuries',
  DESCRIBE = 'Describe'
}

export enum FormField {
  DENTAL_CARE = 'dentalCare',
  DENTAL_CARE_DESCRIBE = 'dentalCareDescribe',
  CHILD_PASSENGER_SAFETY = 'childPassengerSafety',
  CHILD_PASSENGER_SAFETY_DESCRIBE = 'childPassengerSafetyDescribe',
  ABUSE_MALTREATMENT = 'abuseMaltreatment',
  ABUSE_MALTREATMENT_DESCRIBE = 'abuseMaltreatmentDescribe',
  SLEEP = 'sleep',
  SLEEP_DESCRIBE = 'sleepDescribe',
  PLAYGROUND_INJURIES = 'playgroundInjuries',
  PLAYGROUND_INJURIES_DESCRIBE = 'playgroundInjuriesDescribe',
  POISONINGS = 'poisonings',
  POISONINGS_DESCRIBE = 'poisoningsDescribe',
  WATER_SAFETY = 'waterSafety',
  WATER_SAFETY_DESCRIBE = 'waterSafetyDescribe',
  RESIDENTIAL_FIRES = 'residentialFires',
  RESIDENTIAL_FIRES_DESCRIBE = 'residentialFiresDescribe',
  BICYCLE_RELATED_INJURIES = 'bicycleRelatedInjuries',
  BICYCLE_RELATED_INJURIES_DESCRIBE = 'bicycleRelatedInjuriesDescribe'
}

import { LanguageKeys } from 'lang';
import { DiagnosticTestsOrderEntryRecord } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface DiagnosticTestsReadonlyProps {
  diagnosticTest: DiagnosticTestsOrderEntryRecord;
}
const DiagnosticTestsReadonly = (props: DiagnosticTestsReadonlyProps) => {
  return (
    <Box className="Diagnostic-test-read-only">
      <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
        <ELSFlexItem>
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.CATEGORY} customTitle="Category" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.ORDER_DESCRIPTION} customTitle="Order Description" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.EXAM_DATE} customTitle="Exam Date" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.REPORT} customTitle="Report" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.IMPRESSION} customTitle="Impression" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.RECOMMENDATION} customTitle="Recommendation" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.NAME} customTitle="Name" />
          <ReadOnlyInput chartFragment={props.diagnosticTest} formFieldId={FormField.SIGNATURE} customTitle="Signature" />
          <ReadOnlyInput
            chartFragment={props.diagnosticTest}
            formFieldId={FormField.RESULTS_RECEIVED_DATE}
            customTitle={LanguageKeys.ORDER_DIAGNOSTIC_TESTS.RESULTS_RECEIVED_DATE}
          />
          <ReadOnlyInput
            chartFragment={props.diagnosticTest}
            formFieldId={FormField.RESULTS_RECEIVED_TIME}
            customTitle={LanguageKeys.ORDER_DIAGNOSTIC_TESTS.RESULTS_RECEIVED_TIME}
          />
        </ELSFlexItem>
      </ELSFlex>
    </Box>
  );
};

DiagnosticTestsReadonly.displayName = 'DiagnosticTestsReadonly';
export default DiagnosticTestsReadonly;

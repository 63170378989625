/* eslint-disable no-param-reassign */
import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, PharmacyRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { DateFormatByLocale, DateTimeFormatByLocale, NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { appActions } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getPharmacyFormFieldBuilderItems } from './constants';
import PharmacyView, { PharmacyViewProps } from './PharmacyView';
import { FormField as CommonFormField } from '../shared/constants';

export interface PharmacyProps extends ChartComponentProps {
  setSelectedNavId: Function;
  enableMultiStepsAuthoring: Function;
}

interface PharmacyState {
  fragments: ChartFragment[];
  chartHistory: PharmacyRecord[];
}

class Pharmacy extends Component<PharmacyProps, PharmacyState> {
  static displayName = 'Pharmacy';

  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: []
    };
  }

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
    appHelper.useLoader(Promise.all([this.props.initState(this.buildDefaultFormFields()), this.loadPharmacyData()]));
  }

  loadPharmacyData = () => {
    const { locale, loadChartData } = this.props;
    const { formatDate } = dateTimeHelper;
    return loadChartData().then(({ data }) => {
      const records = data.map((fragment) => {
        const { fragmentId: id, active, chartingAt, creator, modifier } = fragment;
        const orderDescription = chartHelper.getOrderDescription(fragment);
        const route = fragment.chartData[FormField.ROUTE_OTHER] || fragment.chartData[FormField.ROUTE];
        let medicationDose = fragment.chartData[FormField.MEDICATION_DOSE_TEXTBOX] || fragment.chartData[FormField.MEDICATION_DOSE];
        medicationDose =
          fragment.chartData[FormField.MEDICATION_DOSE] !== 'Special Dose'
            ? medicationDose
            : `${fragment.chartData[FormField.SPECIAL_DOSE_DOSE]} ${fragment.chartData[FormField.SPECIAL_DOSE_UNIT]}`;
        const orderTime = formatDate({
          date: moment(`${fragment.chartData[FormField.ORDER_START_DATE]} ${fragment.chartData[FormField.ORDER_START_TIME]}`, Object.values(DateTimeFormatByLocale)).toDate(),
          locale,
          includeTime: true
        });
        return {
          id,
          active,
          chartingAt,
          creator,
          modifier,
          ...fragment.chartData,
          orderTime,
          orderDescription,
          orderDetails: `${route}, ${medicationDose}`,
          notes: fragment.chartData[FormField.SPECIAL_INSTRUCTIONS],
          notesTitle: 'Special Instructions'
        };
      });
      this.setState({ fragments: data, chartHistory: records });
    });
  };

  // currently when we use validation rule to disable a field, it will also remove any user input
  // but here we need to disable the field without removing user input
  // so we have to manually implement this in FE
  // TODO: when we have validation rule for this, we can remove this code and use validation rule instead
  disableMedicationForm = (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => {
    const medicationFormField = formFieldMap.get(FormField.MEDICATION_FORM);

    formFieldMap.set(FormField.MEDICATION_FORM, {
      ...medicationFormField,
      disabled: !['medicationOther'].includes(chartField.value)
    });
  };

  // currently when we use validation rule to disable a field, it will also remove any user input
  // but here we need to disable the field without removing user input
  // so we have to manually implement this in FE
  // TODO: when we have validation rule for this, we can remove this code and use validation rule instead
  disableFrequency = (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => {
    const frequencyField = formFieldMap.get(FormField.FREQUENCY);
    const orderItemField = formFieldMap.get(FormField.ORDER_ITEM);
    const orderTypeField = chartField.name === FormField.ORDER_TYPE ? chartField : formFieldMap.get(FormField.ORDER_TYPE);
    const newFrequencyField = produce(frequencyField, (draft) => {
      if (orderTypeField.value === 'orderTypeStat' || orderTypeField.value === 'orderTypeCustom') {
        draft.disabled = true;
      } else if (orderItemField.value) {
        draft.disabled = false;
      }
    });
    formFieldMap.set(FormField.FREQUENCY, newFrequencyField);
  };

  handleChangeOrderItemPostValidation = (chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>) => {
    this.disableMedicationForm(chartField, formFieldMap);
    this.disableFrequency(chartField, formFieldMap);
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { intl, isAuthor } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getPharmacyFormFieldBuilderItems(isAuthor, this.handleChangeOrderItemPostValidation, this.disableFrequency).forEach(
      ({ name, type, label, fieldIdsToClearValue, onChangePostValidation, defaultValue, isHidden }) => {
        if (!isHidden) {
          dataMap.set(name, createFormField({ name, type, label: label && intl.formatMessage({ id: label }), fieldIdsToClearValue, onChangePostValidation, defaultValue }));
        }
      }
    );
    return dataMap;
  };

  handleSaveClick = () => {
    const { formFieldMap, isAuthor, chartingTime, backToSourceLocation, locale } = this.props;
    const { buildRecordFromField } = chartService;
    const { formatDate } = dateTimeHelper;

    const chartData = {};
    Object.keys(FormField).forEach((key) => {
      chartData[FormField[key]] = buildRecordFromField(formFieldMap.get(FormField[key]))?.content ?? '';
    });
    chartData[FormField.ORDER_START_DATE] = formatDate({ date: buildRecordFromField(formFieldMap.get(FormField.ORDER_START_DATE))?.content, locale });
    chartData[FormField.ORDER_STOP_DATE] = formatDate({ date: buildRecordFromField(formFieldMap.get(FormField.ORDER_STOP_DATE))?.content, locale });
    // Flag to check empty stop date input for author/student user
    chartData[FormField.HAS_STOP_DATE_TIME_DEFAULT] = !chartData[FormField.ORDER_STOP_DATE] && !chartData[CommonFormField.ORDER_STOP_TIME_OFFSET];
    if (!chartData[FormField.ORDER_STOP_DATE]) {
      chartData[FormField.ORDER_STOP_DATE] = formatDate({ date: moment(chartData[FormField.ORDER_START_DATE], Object.values(DateFormatByLocale)).add(6, 'days').toDate(), locale });
      chartData[FormField.ORDER_STOP_TIME] = '23:59';
    }
    chartData[CommonFormField.ORDER_START_TIME_OFFSET] = buildRecordFromField(formFieldMap.get(CommonFormField.ORDER_START_TIME_OFFSET))?.content ?? '';
    chartData[CommonFormField.ORDER_STOP_TIME_OFFSET] = buildRecordFromField(formFieldMap.get(CommonFormField.ORDER_STOP_TIME_OFFSET))?.content ?? '';

    const newRecord = {
      ...chartService.createBaseFragment({ chartingTime }),
      groupFragmentRef: isAuthor ? chartHelper.createFragmentId(NAV_ID.PHARMACY) : undefined,
      chartData
    };
    this.props.saveChart([newRecord], { defaultFormFields: this.buildDefaultFormFields(), afterSave: backToSourceLocation || this.loadPharmacyData });
  };

  deleteHistory = (record) => {
    const deleteFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(this.props.deleteChartData(deleteFragment).then(this.loadPharmacyData).then(this.props.showDeleteSuccess));
  };

  isMedicationDoseSectionVisible = () => {
    return this.props.formFieldMap.get(FormField.MEDICATION_DOSE)?.value === 'medicationDoseSpecialDose';
  };

  handleMarLinkClick = () => {
    const marChartUrl = RoutePath.student.mar.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
    // since we check navId to remove author data in ChartWrapper componentWillUnmount
    // we manually set nav id to keep author data
    this.props.setSelectedNavId(NAV_ID.MAR);
    this.props.history.push(marChartUrl);
  };

  render() {
    const { formFieldMap } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: PharmacyViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory,
      isSpecialDoseSectionVisible: this.isMedicationDoseSectionVisible(),
      onMarLinkClick: this.handleMarLinkClick
    };
    return <PharmacyView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

export { Pharmacy as BasePharmacy };
export default compose(withSavedPatientChartsPage, withChartLogic, connect(null, mapDispatchToProps))(Pharmacy);

export enum FormFieldLabel {
  INJECTION_TYPE = 'Injection Type',
  LOCATION = 'Location',
  FINDINGS_ACTION_TAKEN = 'Findings and Action Taken'
}

export enum FormField {
  COMPLETE = 'complete',
  INJECTION_TYPE = 'type',
  LOCATION = 'location',
  INJECTION_SITE_ASSESSMENT = 'siteAssessment',
  FINDINGS_ACTION_TAKEN = 'actionsTaken',
  NOTES = 'notes'
}

export enum SectionTitle {
  INJECTION = 'Injection',
  INJECTION_SITE_ASSESSMENT = 'Injection Site Assessment',
  FINDINGS_ACTION_TAKEN = 'Findings and Action Taken',
  NOTES = 'Notes'
}

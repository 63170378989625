import { CreateAssignmentWrapper, InstructorPageWrapper, withHTMLHeadSEO } from 'components/common';
import ConfigureAssignment from 'components/features/assignment/create-assignment-workflow/configure-assignment/ConfigureAssignment';

const ConfigureAssignmentPage = () => (
  <InstructorPageWrapper>
    {({ loggedInUser }) => <CreateAssignmentWrapper>{(props) => <ConfigureAssignment instructor={loggedInUser} {...props} />}</CreateAssignmentWrapper>}
  </InstructorPageWrapper>
);

export { ConfigureAssignmentPage as BaseConfigureAssignmentPage };
export default withHTMLHeadSEO({ title: 'Add a SimChart item' })(ConfigureAssignmentPage);

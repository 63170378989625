import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { ELSModalServiceType, ELSToastServiceType } from 'models/els';
import { assignmentHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';
import { ConfirmationModal, DeletionConfirmationModal } from 'components/common';
import { ELSWithModalService, ELSWithToastService } from 'components/common/els';
import { ConfirmationModalProps } from 'components/common/modal/ConfirmationModal';
import SuccessModal, { SuccessModalProps } from 'components/common/modal/SuccessModal';

interface WithFormUtilitiesProps {
  toastService: ELSToastServiceType;
  modalService: ELSModalServiceType;
}

const withFormUtilities = (BaseComponent) => {
  class WithFormUtilities extends Component<WithFormUtilitiesProps & RouteComponentProps> {
    showSaveSuccess = () => {
      this.props.toastService.openToast({
        component: <div>Saved successfully</div>,
        type: ELSWithToastService.types.POSITIVE,
        autoClose: true,
        autoCloseDelay: 6000,
        center: true
      });
    };

    showDeleteSuccess = () => {
      this.props.toastService.openToast({
        component: <div>Deleted successfully</div>,
        type: ELSWithToastService.types.POSITIVE,
        autoClose: true,
        autoCloseDelay: 6000,
        center: true
      });
    };

    showDeleteConfirmation = (handleConfirmClick: Function, message?: string) => {
      this.props.modalService.openModal({
        modalId: 'deleteConfirmModal',
        color: 'primary',
        closeAriaLabel: 'Close Navigation',
        content: (
          <DeletionConfirmationModal
            message={message}
            onConfirmClick={() => {
              this.hideDeleteConfirmation();
              setTimeout(handleConfirmClick);
            }}
            onCancelClick={this.hideDeleteConfirmation}
          />
        )
      });
    };

    hideDeleteConfirmation = () => this.props.modalService.closeModal('deleteConfirmModal');

    showConfirmationModal = (option: ConfirmationModalProps) => {
      this.props.modalService.openModal({
        modalId: 'confirmationModal',
        color: 'primary',
        content: (
          <ConfirmationModal
            {...option}
            onOkClick={() => {
              this.hideConfirmationModal();
              setTimeout(option.onOkClick);
            }}
            onCancelClick={this.hideConfirmationModal}
          />
        )
      });
    };

    hideConfirmationModal = () => this.props.modalService.closeModal('confirmationModal');

    showSuccessModal = (option: SuccessModalProps) => {
      const { showClose = true } = option;
      this.props.modalService.openModal({
        showClose,
        modalId: 'successModal',
        color: 'primary',
        content: <SuccessModal {...option} />
      });
    };

    hideSuccessModal = () => this.props.modalService.closeModal('successModal');

    render() {
      return (
        <BaseComponent
          {...this.props}
          showSaveSuccess={this.showSaveSuccess}
          showDeleteSuccess={this.showDeleteSuccess}
          showDeleteConfirmation={this.showDeleteConfirmation}
          showSuccessModal={this.showSuccessModal}
          hideSuccessModal={this.hideSuccessModal}
          showConfirmationModal={this.showConfirmationModal}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    isAssessmentOwner: appSelectors.getUserId(state) === appSelectors.getAssessment(state)?.studentId,
    isAssessmentSubmitted: assignmentHelper.isAssessmentSubmitted(appSelectors.getAssessment(state)?.status)
  });

  const enhancers = [withProps(() => ({ history: useHistory(), location: useLocation() })), ELSWithToastService, ELSWithModalService, connect(mapStateToProps)];
  return compose(...enhancers)(WithFormUtilities);
};

export default withFormUtilities;

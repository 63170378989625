import { orderBy } from 'lodash';
import React, { Component } from 'react';
import { DiagnosticTestFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { FillInTheBlankPattern } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import DiagnosticTestView, { DiagnosticTestViewProps } from './DiagnosticTestView';

export enum FormField {
  DATE_OF_TEST = 'dateOfTest',
  CATEGORY = 'category',
  DIAGNOSTIC_TEST = 'diagnosticTest',
  DIAGNOSTIC_TEST_DETAIL = 'diagnosticTestDetail',
  DEFINITION_OF_TEST = 'definitionOfTest',
  TEST_ORDERED_DESCRIPTION = 'testOrderedDescription',
  FINDINGS_AND_RESULTS = 'findingsAndResults'
}

interface DiagnosticTestState {
  chartHistory: DiagnosticTestFragment[];
  selectedDiagnosticTest?: DiagnosticTestFragment;
}

export const buildFormFields = (): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();

  dataMap.set(
    FormField.DATE_OF_TEST,
    createFormField({
      name: FormField.DATE_OF_TEST,
      type: FormFieldInputType.DATE,
      label: 'Date of Test (mm-dd-yyyy)'
    })
  );
  dataMap.set(
    FormField.CATEGORY,
    createFormField({
      name: FormField.CATEGORY,
      type: FormFieldInputType.MULTI_SELECT_RADIO,
      label: 'Category'
    })
  );
  dataMap.set(
    FormField.DIAGNOSTIC_TEST,
    createFormField({
      name: FormField.DIAGNOSTIC_TEST,
      type: FormFieldInputType.MULTI_SELECT_RADIO,
      label: 'Diagnostic Test'
    })
  );
  dataMap.set(
    FormField.DIAGNOSTIC_TEST_DETAIL,
    createFormField({
      name: FormField.DIAGNOSTIC_TEST_DETAIL,
      type: FormFieldInputType.TEXT_BOX,
      label: 'Diagnostic Test Detail'
    })
  );
  dataMap.set(
    FormField.DEFINITION_OF_TEST,
    createFormField({
      name: FormField.DEFINITION_OF_TEST,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Definition and Description of the Test'
    })
  );
  dataMap.set(
    FormField.TEST_ORDERED_DESCRIPTION,
    createFormField({
      name: FormField.TEST_ORDERED_DESCRIPTION,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Significance of the Test Being Ordered for this Patient'
    })
  );
  dataMap.set(
    FormField.FINDINGS_AND_RESULTS,
    createFormField({
      name: FormField.FINDINGS_AND_RESULTS,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Significant Findings and Results'
    })
  );
  return dataMap;
};

class DiagnosticTest extends Component<ChartComponentProps, DiagnosticTestState> {
  static displayName = 'DiagnosticTest';

  constructor(props) {
    super(props);
    this.state = {
      chartHistory: []
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory));
  }

  bindChartHistory = ({ data: fragments }) => {
    const records = orderBy(fragments, ['createdAt'], ['desc']) as DiagnosticTestFragment[];
    this.setState({ chartHistory: records });
  };

  handleChartDelete = (diagnosticTest: DiagnosticTestFragment) => this.props.showDeleteConfirmation(() => this.confirmAndDelete(diagnosticTest));

  confirmAndDelete = (diagnostic: DiagnosticTestFragment) => {
    return appHelper.useLoader(
      this.props.saveChartData({ ...diagnostic, active: false }).then(() => this.afterSave(true)),
      { errorMessage: 'can not save chart data' }
    );
  };

  handleChartEdit = () => {
    this.afterSave(false);
    this.setState({ selectedDiagnosticTest: null });
  };

  handleChartSave = () => {
    const { formFieldMap } = this.props;
    const diagnosticReplaceRegex = new RegExp(`${FillInTheBlankPattern}.*`);
    const dianosticTestField = formFieldMap.get(FormField.DIAGNOSTIC_TEST);
    let dianosticTestType = dianosticTestField.chartContent.find((contentItem) => contentItem.value === dianosticTestField.value)?.label ?? '';
    if (formFieldMap.get(FormField.DIAGNOSTIC_TEST_DETAIL).value) {
      dianosticTestType = dianosticTestType.replace(diagnosticReplaceRegex, formFieldMap.get(FormField.DIAGNOSTIC_TEST_DETAIL).value);
    }

    const chartData = {
      [FormField.DATE_OF_TEST]: formFieldMap.get(FormField.DATE_OF_TEST).value,
      [FormField.CATEGORY]: formFieldMap.get(FormField.CATEGORY).label,
      [FormField.DIAGNOSTIC_TEST]: dianosticTestType,
      [FormField.DEFINITION_OF_TEST]: formFieldMap.get(FormField.DEFINITION_OF_TEST).value,
      [FormField.TEST_ORDERED_DESCRIPTION]: formFieldMap.get(FormField.TEST_ORDERED_DESCRIPTION).value,
      [FormField.FINDINGS_AND_RESULTS]: formFieldMap.get(FormField.FINDINGS_AND_RESULTS).value
    };
    const fragment: DiagnosticTestFragment = {
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData
    };
    this.props.handleSaveClick([fragment], { afterSave: () => this.afterSave(false) });
  };

  afterSave = (isDelete) =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(isDelete ? this.props.showDeleteSuccess : this.props.showSaveSuccess)
      .then(this.resetForm)
      .then(() => appHelper.scrollTop());

  resetForm = () => {
    this.props.initState(buildFormFields());
  };

  handleCancelClick = () => {
    this.resetForm();
    appHelper.scrollTop();
  };

  render() {
    const { chartHistory } = this.state;

    const { enableDisplayRecordsButton, hasUnsavedChanges, displayAuthoringData, handleDiscardClick, formFieldMap, formSubmittedCount, selectedNavId, saveChartData } = this.props;

    const chartActionsProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onSaveClick: this.handleChartSave
    };

    const viewProps: DiagnosticTestViewProps = {
      onDeleteDiagnosticTest: this.handleChartDelete,
      onDiagnosticTestEdit: this.handleChartEdit,
      onEditSidebarOpen: (selectedDiagnosticTest) => this.setState({ selectedDiagnosticTest }),
      onEditSidebarClose: () => this.setState({ selectedDiagnosticTest: null }),
      selectedDiagnosticTest: this.state.selectedDiagnosticTest,
      chartHistory,
      chartActionsProps,
      formSubmittedCount,
      selectedNavId,
      chartMetaFormFields: formFieldMap,
      saveChartData,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <DiagnosticTestView {...viewProps} />;
  }
}

export { DiagnosticTest as BaseDiagnosticTest };
export default withChartLogic(DiagnosticTest);

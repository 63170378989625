// eslint-disable-next-line import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires
const icon = require('!!raw-loader!assets/img/fail-grade.svg');

interface FailGradeIconProps {
  id?: string;
  className?: string;
}

const FailGradeIcon = (props: FailGradeIconProps) => (
  // eslint-disable-next-line react/no-danger
  <span id={props.id} className={props.className} dangerouslySetInnerHTML={{ __html: icon }} />
);
FailGradeIcon.displayName = 'FailGradeIcon';
export default FailGradeIcon;

import cx from 'classnames';
import { ELSButton } from 'components/common/els';

interface FlyoutProps {
  isOpen: boolean;
  content: JSX.Element | null;
  closeFlyout: () => void;
}

const Flyout = (props: FlyoutProps) => {
  const { content, isOpen, closeFlyout } = props;
  const showContent = isOpen ? 'show' : 'hide';
  return (
    <section className={cx('flyout flyout__content', showContent)}>
      <div className="sticky-flex-container">
        <div className="u-els-padding-2x u-els-padding-1x@desktop u-els-padding-top-1o2">
          <ELSButton type="secondary" text="Close" onClick={() => closeFlyout()} preventAutoPending />
        </div>
        <div className="sticky-flex-container__content u-els-padding-top-1o2">{content}</div>
      </div>
    </section>
  );
};

export default Flyout;

import { LanguageKeys } from 'lang';
import { ChartFragment, Record } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ChartMetaFormFieldParams } from 'models/ui';
import { buildFragmentRecords, formFieldsBuilder } from 'helpers/chart.helper';
import { buildPatientRecord, buildPatientRecords } from 'services/chart.service';
import { FormField } from './constants';

export const getNeurovascularAssessmentFormFieldBuilderItems = (): ChartMetaFormFieldParams[] => [
  { name: FormField.NEUROVASCULAR_ASSESSMENT_NOTE, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.NOTES },
  { name: FormField.LOWER_LIMB_SIDE, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_AT_REST },
  { name: FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_ON_MOVEMENT },
  { name: FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_SKIN_COLOUR, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN_COLOUR },
  { name: FormField.LOWER_LIMB_SKIN_COLOUR_OTHER, type: FormFieldInputType.TEXT_BOX },
  { name: FormField.LOWER_LIMB_SKIN_TEMPERATURE, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN_TEMPERATURE },
  { name: FormField.LOWER_LIMB_SKIN_TEMPERATURE_OTHER, type: FormFieldInputType.TEXT_BOX },
  { name: FormField.LOWER_LIMB_MOVEMENT_PERONEAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.PERONEAL },
  { name: FormField.LOWER_LIMB_MOVEMENT_PERONEAL_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_MOVEMENT_TIBIAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.TIBIAL },
  { name: FormField.LOWER_LIMB_MOVEMENT_TIBIAL_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_SENSATION_PERONEAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.PERONEAL },
  { name: FormField.LOWER_LIMB_SENSATION_TIBIAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.TIBIAL },
  { name: FormField.LOWER_LIMB_CAPILLARY_REFILL, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.POSTERIOR_TIBIAL },
  { name: FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_PRESENT_SUB_OPTIONS_1, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.DORSALIS_PEDIS },
  { name: FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_PULSES_POPLITEAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.POPLITEAL },
  { name: FormField.LOWER_LIMB_PULSES_POPLITEAL_ABSENT_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.LOWER_LIMB_PULSES_POPLITEAL_ABSENT_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_PULSES_FEMORAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.FEMORAL },
  { name: FormField.LOWER_LIMB_PULSES_FEMORAL_ABSENT_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.LOWER_LIMB_PULSES_FEMORAL_ABSENT_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.LOWER_LIMB_SWELLING, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.UPPER_LIMB_SIDE, type: FormFieldInputType.RADIO_CHOICE },
  { name: FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_AT_REST },
  { name: FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_ON_MOVEMENT },
  { name: FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.UPPER_LIMB_SKIN_COLOUR, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN_COLOUR },
  { name: FormField.UPPER_LIMB_SKIN_COLOUR_OTHER, type: FormFieldInputType.TEXT_BOX },
  { name: FormField.UPPER_LIMB_SKIN_TEMPERATURE, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN_TEMPERATURE },
  { name: FormField.UPPER_LIMB_SKIN_TEMPERATURE_OTHER, type: FormFieldInputType.TEXT_BOX },
  { name: FormField.UPPER_LIMB_MOVEMENT_RADIAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.RADIAL },
  { name: FormField.UPPER_LIMB_MOVEMENT_RADIAL_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.UPPER_LIMB_MOVEMENT_MEDIAN, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.MEDIAN },
  { name: FormField.UPPER_LIMB_MOVEMENT_MEDIAN_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.UPPER_LIMB_MOVEMENT_ULNAR, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ULNAR },
  { name: FormField.UPPER_LIMB_MOVEMENT_ULNAR_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.UPPER_LIMB_SENSATION_RADIAL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.RADIAL },
  { name: FormField.UPPER_LIMB_SENSATION_MEDIAN, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.MEDIAN },
  { name: FormField.UPPER_LIMB_SENSATION_ULNAR, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ULNAR },
  { name: FormField.UPPER_LIMB_CAPILLARY_REFILL, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.CAPILLARY_REFILL },
  { name: FormField.UPPER_LIMB_PULSES, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.PULSES },
  { name: FormField.UPPER_LIMB_PULSES_ACTIONS, type: FormFieldInputType.CHECK_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS_WITH_COLON },
  { name: FormField.UPPER_LIMB_PULSES_COMMENT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS },
  { name: FormField.UPPER_LIMB_SWELLING, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NEUROVASCULAR_ASSESSMENT.SWELLING }
];

// In case the build needs to be dynamic and customizable
export const buildDefaultNeurovascularAssessmentFormFieldItems = (): Map<string, ChartMetaFormField> =>
  formFieldsBuilder({
    getFormFieldItems: getNeurovascularAssessmentFormFieldBuilderItems
  });

export const upperLimbParentSectionTitle = LanguageKeys.NEUROVASCULAR_ASSESSMENT.UPPER_LIMB;
export const lowerLimbParentSectionTitle = LanguageKeys.NEUROVASCULAR_ASSESSMENT.LOWER_LIMB;

export const getSectionTitle = (parentSectionTitle: string, sectionTitle: string, formFieldMap: Map<string, ChartMetaFormField>) => {
  let limbSide;
  if (parentSectionTitle === upperLimbParentSectionTitle) {
    limbSide = buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SIDE)?.content;
  }
  if (parentSectionTitle === lowerLimbParentSectionTitle) {
    limbSide = buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SIDE)?.content;
  }

  return limbSide ? `${sectionTitle} (${limbSide})` : sectionTitle;
};

const getNestedTitle = (parentTitle: string, title: string): string => `${parentTitle ?? ''} ${title ?? ''}`.trim();

const getNestedActionValue = (formFieldMap: Map<string, ChartMetaFormField>, formField: FormField): string => {
  const chartContent = formFieldMap.get(formField)?.chartContent;
  const selectedActions = chartContent?.filter((content) => content?.selected);
  const values = selectedActions.map((value) => value?.name);
  return values.join(', ');
};

const customRemoveEmptyRecords = (rawRecord: Record): Record => {
  const cleanedChildrenRecords = rawRecord?.records.map((record) => {
    if (record?.records?.length) {
      const childRecords = record?.records;
      const cleanedChildRecords = childRecords?.filter((childRecord) => !!(childRecord?.value || childRecord?.content));
      return { ...record, records: cleanedChildRecords };
    }
    return record;
  });
  const cleanedRecords = cleanedChildrenRecords.filter((childRecord) => childRecord?.records?.length > 0);
  return { ...rawRecord, records: cleanedRecords };
};

export const getNeurovascularAssessmentRecords = (formFieldMap: Map<string, ChartMetaFormField>) => ({
  chartTitle: 'System Assessment',
  fragmentTitle: LanguageKeys.NEUROVASCULAR_ASSESSMENT.NEUROVASCULAR_ASSESSMENT,
  records: [
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.PAIN, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST),
        ...buildPatientRecords(formFieldMap, FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.UPPER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_AT_REST, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        ),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT),
        ...buildPatientRecords(formFieldMap, FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.UPPER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_ON_MOVEMENT, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        )
      ]
    },
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SKIN_COLOUR),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SKIN_COLOUR_OTHER, LanguageKeys.NEUROVASCULAR_ASSESSMENT.OTHER),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SKIN_TEMPERATURE),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SKIN_TEMPERATURE_OTHER, LanguageKeys.NEUROVASCULAR_ASSESSMENT.OTHER)
      ]
    },
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.MOVEMENT, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_MOVEMENT_RADIAL),
        buildPatientRecord(
          formFieldMap,
          FormField.UPPER_LIMB_MOVEMENT_RADIAL_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.RADIAL, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS),
          null,
          { preventDelete: true }
        ),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_MOVEMENT_MEDIAN),
        buildPatientRecord(
          formFieldMap,
          FormField.UPPER_LIMB_MOVEMENT_MEDIAN_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.MEDIAN, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS),
          null,
          { preventDelete: true }
        ),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_MOVEMENT_ULNAR),
        buildPatientRecord(
          formFieldMap,
          FormField.UPPER_LIMB_MOVEMENT_ULNAR_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.ULNAR, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS),
          null,
          { preventDelete: true }
        )
      ]
    },
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.SENSATION, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SENSATION_RADIAL),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SENSATION_MEDIAN),
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SENSATION_ULNAR)
      ]
    },
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.CAPILLARY_REFILL, formFieldMap),
      records: [buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_CAPILLARY_REFILL)]
    },
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.PULSES, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_PULSES),
        ...buildPatientRecords(formFieldMap, FormField.UPPER_LIMB_PULSES_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.UPPER_LIMB_PULSES_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.PULSES, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        )
      ]
    },
    {
      parentSectionTitle: upperLimbParentSectionTitle,
      sectionTitle: getSectionTitle(upperLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.SWELLING, formFieldMap),
      records: [buildPatientRecord(formFieldMap, FormField.UPPER_LIMB_SWELLING)]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.PAIN, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST),
        ...buildPatientRecords(formFieldMap, FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_INTENSITY_OF_PAIN_AT_REST_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_AT_REST, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        ),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT),
        ...buildPatientRecords(formFieldMap, FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_INTENSITY_OF_PAIN_ON_MOVEMENT_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.INTENSITY_OF_PAIN_ON_MOVEMENT, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        )
      ]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.SKIN, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SKIN_COLOUR),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SKIN_COLOUR_OTHER, LanguageKeys.NEUROVASCULAR_ASSESSMENT.OTHER),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SKIN_TEMPERATURE),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SKIN_TEMPERATURE_OTHER, LanguageKeys.NEUROVASCULAR_ASSESSMENT.OTHER)
      ]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.MOVEMENT, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_MOVEMENT_PERONEAL),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_MOVEMENT_PERONEAL_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.PERONEAL, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        ),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_MOVEMENT_TIBIAL),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_MOVEMENT_TIBIAL_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.TIBIAL, LanguageKeys.NEUROVASCULAR_ASSESSMENT.COMMENTS)
        )
      ]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.SENSATION, formFieldMap),
      records: [buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SENSATION_PERONEAL), buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SENSATION_TIBIAL)]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.CAPILLARY_REFILL, formFieldMap),
      records: [buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_CAPILLARY_REFILL)]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.PULSES, formFieldMap),
      records: [
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL),
        ...buildPatientRecords(formFieldMap, FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_ABSENT_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.TIBIAL, LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS)
        ),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_PULSES_POSTERIOR_TIBIAL_PRESENT_SUB_OPTIONS_1),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS),
        ...buildPatientRecords(formFieldMap, FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_PULSES_DORSALIS_PEDIS_ABSENT_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.DORSALIS_PEDIS, LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS)
        ),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_PULSES_POPLITEAL),
        ...buildPatientRecords(formFieldMap, FormField.LOWER_LIMB_PULSES_POPLITEAL_ABSENT_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_PULSES_POPLITEAL_ABSENT_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.POPLITEAL, LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS)
        ),
        buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_PULSES_FEMORAL),
        ...buildPatientRecords(formFieldMap, FormField.LOWER_LIMB_PULSES_FEMORAL_ABSENT_ACTIONS),
        buildPatientRecord(
          formFieldMap,
          FormField.LOWER_LIMB_PULSES_FEMORAL_ABSENT_COMMENT,
          getNestedTitle(LanguageKeys.NEUROVASCULAR_ASSESSMENT.FEMORAL, LanguageKeys.NEUROVASCULAR_ASSESSMENT.ACTIONS)
        )
      ]
    },
    {
      parentSectionTitle: lowerLimbParentSectionTitle,
      sectionTitle: getSectionTitle(lowerLimbParentSectionTitle, LanguageKeys.NEUROVASCULAR_ASSESSMENT.SWELLING, formFieldMap),
      records: [buildPatientRecord(formFieldMap, FormField.LOWER_LIMB_SWELLING)]
    },
    {
      sectionTitle: LanguageKeys.NEUROVASCULAR_ASSESSMENT.NOTES,
      records: [buildPatientRecord(formFieldMap, FormField.NEUROVASCULAR_ASSESSMENT_NOTE)]
    }
  ]
});

// In case the build needs to be dynamic and customizable
export const buildNeurovascularAssessmentFragmentRecords = ({
  chartingTime,
  formFieldMap
}: {
  chartingTime: string;
  formFieldMap: Map<string, ChartMetaFormField>;
}): ChartFragment => buildFragmentRecords({ chartingTime, formFieldMap, getRecords: getNeurovascularAssessmentRecords, customRecordsCleaner: customRemoveEmptyRecords });

export const helperFnsForUT = {
  getNestedTitle,
  getNestedActionValue,
  customRemoveEmptyRecords
};

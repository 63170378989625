import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment, DiagnosticTestsOrderEntryRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { FillInTheBlankPattern, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { studentSelectors } from 'redux/ducks/student';
import { withChartLogic } from 'components/common';
import { FormField, Status, getDiagnosticTestsFormFieldBuilderItems } from './constants';
import DiagnosticTestsView, { DiagnosticTestsViewProps } from './DiagnosticTestsView';
import { FormField as CommonFormField } from '../shared/constants';

interface DiagnosticTestsState {
  fragments: ChartFragment[];
  chartHistory: DiagnosticTestsOrderEntryRecord[];
  selectedDiagnosticTest?: DiagnosticTestsOrderEntryRecord;
}

export interface DiagnosticTestsProps extends ChartComponentProps {
  enableMultiStepsAuthoring: Function;
  phaseIndex: number;
}

class DiagnosticTests extends Component<DiagnosticTestsProps, DiagnosticTestsState> {
  static displayName = 'DiagnosticTests';

  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: [],
      selectedDiagnosticTest: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { isAuthor, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getDiagnosticTestsFormFieldBuilderItems(isAuthor).forEach(({ label, isHidden, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });

    dataMap.get(FormField.ORDER_DESCRIPTION).changeCallback = this.handleOrderDescriptionChange;

    return dataMap;
  };

  buildFragment = (): ChartFragment => {
    const { formFieldMap, isAuthor } = this.props;
    const { buildPatientRecord } = chartService;
    const customRegex = new RegExp(`${FillInTheBlankPattern}.*`);
    const orderDescriptionField = formFieldMap.get(FormField.ORDER_DESCRIPTION);
    let orderDescriptionText = orderDescriptionField.chartContent.find((contentItem) => contentItem.value === orderDescriptionField.value)?.label ?? '';
    if (formFieldMap.get(FormField.ORDER_DESCRIPTION_DETAIL).value) {
      orderDescriptionText = orderDescriptionText.replace(customRegex, formFieldMap.get(FormField.ORDER_DESCRIPTION_DETAIL).value);
    }
    const chartData = {
      [FormField.CATEGORY]: buildPatientRecord(formFieldMap, FormField.CATEGORY),
      [FormField.ORDER_DESCRIPTION]: { ...buildPatientRecord(formFieldMap, FormField.ORDER_DESCRIPTION), content: orderDescriptionText },
      [FormField.ORDER_PRIORITY]: buildPatientRecord(formFieldMap, FormField.ORDER_PRIORITY),
      [FormField.TRANSPORTATION]: buildPatientRecord(formFieldMap, FormField.TRANSPORTATION),
      [FormField.IV_REQUIRED]: buildPatientRecord(formFieldMap, FormField.IV_REQUIRED),
      [FormField.OXYGEN_REQUIRED]: buildPatientRecord(formFieldMap, FormField.OXYGEN_REQUIRED),
      [FormField.ORDER_START_DATE]: buildPatientRecord(formFieldMap, FormField.ORDER_START_DATE),
      [FormField.ORDER_START_TIME]: buildPatientRecord(formFieldMap, FormField.ORDER_START_TIME),
      [CommonFormField.ORDER_START_TIME_OFFSET]: buildPatientRecord(formFieldMap, CommonFormField.ORDER_START_TIME_OFFSET),
      [FormField.SPECIAL_INSTRUCTIONS]: buildPatientRecord(formFieldMap, FormField.SPECIAL_INSTRUCTIONS),
      [FormField.STATUS]: Status.PENDING
    };
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, groupFragmentRef: isAuthor ? chartHelper.createFragmentId(NAV_ID.DIAGNOSTIC_TESTS_ORDER_ENTRY) : undefined, chartData } as ChartFragment;
  };

  handleOrderDescriptionChange = ({ option }) => {
    if (option?.label?.includes(FillInTheBlankPattern)) {
      const newLabel = option.label.substring(0, option.label.indexOf(FillInTheBlankPattern)).trim();
      this.props.setFormFieldLabel(FormField.ORDER_DESCRIPTION_DETAIL, newLabel);
    }
  };

  transformFragmentToTableRecord = (fragment: ChartFragment): DiagnosticTestsOrderEntryRecord => {
    const { locale } = this.props;
    let orderTime = '';
    if (fragment.chartData[FormField.ORDER_START_DATE]?.content) {
      orderTime = `${dateTimeHelper.formatDate({ date: dateTimeHelper.toMomentWithParsers(fragment.chartData[FormField.ORDER_START_DATE]?.content).toDate(), locale })} ${
        fragment.chartData[FormField.ORDER_START_TIME]?.content
      }`;
    }
    const orderDescription = fragment.chartData[FormField.ORDER_DESCRIPTION]?.content;
    const orderPriority = fragment.chartData[FormField.ORDER_PRIORITY]?.content;
    const transportation = fragment.chartData[FormField.TRANSPORTATION]?.content;
    const iv = fragment.chartData[FormField.IV_REQUIRED]?.content;
    const oxygen = fragment.chartData[FormField.OXYGEN_REQUIRED]?.content;
    const orderDetails = `${orderPriority}, ${transportation}, IV: ${iv}, O2: ${oxygen}`;
    const notes = fragment.chartData[FormField.SPECIAL_INSTRUCTIONS]?.content;
    const status = fragment.chartData[FormField.STATUS];
    return {
      ...fragment,
      id: fragment.fragmentId,
      orderTime,
      orderDescription,
      orderDetails,
      notes,
      notesTitle: 'Special Instructions',
      status
    } as DiagnosticTestsOrderEntryRecord;
  };

  loadData = () => {
    this.props.initState(this.buildDefaultFormFields());
    this.props.enableMultiStepsAuthoring(true);
    appHelper.useLoader(
      this.props.loadChartData().then(({ data }) => {
        let fragments;
        const fragmentGroups = chartHelper.groupFragmentsById(data);
        if (this.props.isAuthor) {
          // The first element of fragmentGroup is the latest one
          fragments = Array.from(fragmentGroups.values()).map((fragGroup) => fragGroup[0]);
        } else {
          fragments = Array.from(fragmentGroups.values()).map((fragGroup) => {
            // Prefer CHARTING records
            const studentFragment = fragGroup.find((frag) => frag.fragmentType === FragmentType.CHARTING);
            return studentFragment || fragGroup.find((frag) => frag.phaseIndex === this.props.phaseIndex) || fragGroup[0];
          });
        }
        const records = fragments.map((fragment: ChartFragment) => this.transformFragmentToTableRecord(fragment));
        this.setState({ fragments: data, chartHistory: records });
      }),
      { errorMessage: 'can not load chart fragment' }
    );
  };

  deleteHistory = (record) => {
    const deleteFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(this.props.deleteChartData(deleteFragment).then(this.loadData).then(this.props.showDeleteSuccess), { errorMessage: 'can not delete chart history' });
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.loadData });
  };

  handleStatusClick = (selectedDiagnosticTest: DiagnosticTestsOrderEntryRecord) => {
    this.setState({ selectedDiagnosticTest });
  };

  handleChartUpdate = () => {
    this.loadData();
    this.props.showSaveSuccess();
    this.setState({ selectedDiagnosticTest: null });
  };

  handleSidebarClose = () => {
    this.setState({ selectedDiagnosticTest: null });
  };

  render() {
    const { formFieldMap, formSubmittedCount, saveChartData, enableDisplayRecordsButton, displayAuthoringData, handleDiscardClick, hasUnsavedChanges } = this.props;
    const { chartHistory, selectedDiagnosticTest } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: DiagnosticTestsViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      chartHistory,
      selectedDiagnosticTest,
      saveChartData,
      deleteHistory: this.deleteHistory,
      onDiagnosticTestUpdate: this.handleChartUpdate,
      onStatusClick: this.handleStatusClick,
      onSidebarClose: this.handleSidebarClose,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <DiagnosticTestsView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  phaseIndex: studentSelectors.getPhaseIndex(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];
export { DiagnosticTests as BaseDiagnosticTests };
export default compose(...enhancers)(DiagnosticTests);

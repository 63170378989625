import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constant';
import ReadOnlyInput from '../shared/ReadOnlyInput';

interface InfantToddlerAssessmentReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const InfantToddlerAssessmentReadOnly = (props: InfantToddlerAssessmentReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;
  return (
    <Box className="mobility-physical-therapy-read-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Infant - Toddler Risk Assessment</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.INDICATE_THE_PRESENCE_OF_RISK}
                  formFieldId={FormField.CHILD_PASSENGER_SAFETY_SELECT}
                  customTitle="Child passenger safety"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.INDICATE_THE_PRESENCE_OF_RISK}
                  formFieldId={FormField.CHILD_PASSENGER_SAFETY_DESCRIBE}
                  customTitle="Describe"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.INDICATE_THE_PRESENCE_OF_RISK}
                  formFieldId={FormField.ABUSE_MALTREATMENT_SELECT}
                  customTitle="Abuse/maltreatment"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.INDICATE_THE_PRESENCE_OF_RISK}
                  formFieldId={FormField.ABUSE_MALTREATMENT_DESCRIBE}
                  customTitle="Describe"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.INDICATE_THE_PRESENCE_OF_RISK} formFieldId={FormField.SLEEP_SELECT} customTitle="Sleep" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.INDICATE_THE_PRESENCE_OF_RISK}
                  formFieldId={FormField.SLEEP_DESCRIBE}
                  customTitle="Describe"
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

InfantToddlerAssessmentReadOnly.displayName = 'InfantToddlerAssessmentReadOnly';
export default InfantToddlerAssessmentReadOnly;

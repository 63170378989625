import { AssessmentStatus } from 'models/enum';

const AssessmentStatusDetails = (props: { assessmentStatus: string; formattedDueDate: string }) => {
  let statusDetails: string;
  let dueMessage = `Assignment is due ${props.formattedDueDate}`;
  switch (props.assessmentStatus) {
    case AssessmentStatus.COMPLETED:
      statusDetails = 'Your submission was on time';
      dueMessage = `Assignment was due ${props.formattedDueDate}`;
      break;
    case AssessmentStatus.COMPLETED_LATE:
      statusDetails = 'You submitted late';
      break;
    case AssessmentStatus.IN_PROGRESS:
      statusDetails = 'You have not yet submitted';
      break;
    default:
      statusDetails = 'You didn’t submit on time';
  }

  return (
    <>
      <p className="legend__text">
        <span className="legend__dot u-els-background-color-extended-blue-7" />
        {dueMessage}
      </p>
      <p className="legend__text">
        <span className="legend__dot u-els-background-color-extended-blue-4" />
        {statusDetails}
      </p>
    </>
  );
};

export default AssessmentStatusDetails;

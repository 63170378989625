export const LaboratoryTest = {
  TestResult: {
    ResultLevel: {
      LOW: 'Low',
      WITHIN_NORMAL_LIMITS: 'Within Normal Limits',
      HIGH: 'High'
    }
  }
};

export const TestDateError = [
  {
    message: 'Please provide a test date',
    field: 'dateOfTestResult',
    priority: 0,
    type: 'REQUIRED'
  }
];

import { ReactNode } from 'react';
import { AppMessage } from 'constants/app.constant';
import { Box } from 'components/common';
import '../chart.scss';

export interface GraphContainerProps {
  dataSize: number;
  children?: ReactNode;
  renderGraphTypeSelect?: Function;
}

const GraphContainer = (props: GraphContainerProps) => (
  <div aria-hidden>
    <h2>Chart Graph</h2>
    {props.renderGraphTypeSelect?.()}
    {props.dataSize === 0 && <Box mb2>{AppMessage.NO_GRAPH_DATA}</Box>}
    {props.dataSize > 0 && <div className="graph-wrapper">{props.children}</div>}
  </div>
);

GraphContainer.displayName = 'GraphContainer';
export default GraphContainer;

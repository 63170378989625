import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ChartMetaFormFieldParams } from 'models/ui';

export enum HEIGHT_WEIGHT_CHARTS {
  HEIGHT_LENGTH_WEIGHT = 'Height/Length/Weight',
  INFANT_MEASUREMENTS = 'Infant Measurements'
}

export const CHARTS_OPTIONS = [
  { name: HEIGHT_WEIGHT_CHARTS.HEIGHT_LENGTH_WEIGHT, value: HEIGHT_WEIGHT_CHARTS.HEIGHT_LENGTH_WEIGHT },
  { name: HEIGHT_WEIGHT_CHARTS.INFANT_MEASUREMENTS, value: HEIGHT_WEIGHT_CHARTS.INFANT_MEASUREMENTS }
];

export enum GRAPH_CHART_TYPE {
  ABDOMINAL_GIRTH = 'abdominalGirth',
  WEIGHT = 'weight'
}

export const GRAPH_TYPES = [
  { name: 'Weight', value: GRAPH_CHART_TYPE.WEIGHT },
  { name: 'Abdominal Girth', value: GRAPH_CHART_TYPE.ABDOMINAL_GIRTH }
];

export enum FormField {
  HEIGHT_FEET = 'heightFeet',
  HEIGHT_INCH = 'heightInch',
  HEIGHT_CM = 'heightCm',
  WEIGHT_ADMISSION = 'weightAdmission',
  WEIGHT_POUND = 'weightPound',
  WEIGHT_OUNCE = 'weightOunce',
  WEIGHT_KG = 'weightKg',
  WEIGHT_GRAM = 'weightGram',
  WEIGHT_METHOD = 'weightMethod',
  NOTE = 'note',
  TOTAL_WEIGHT = 'weightTotalInOunces',
  TOTAL_HEIGHT = 'heightTotalInInches',
  CONTAINER_INFANT = 'containerInfant',
  ABDOMINAL_GIRTH_CM = 'abdominalGirthCm',
  ABDOMINAL_GIRTH_INCH = 'abdominalGirthInch',
  ANTERIOR_FONTANEL = 'anteriorFontanel',
  POSTERIOR_FONTANEL = 'posteriorFontanel',
  HEAD_CIRCUMFERENCE_CM = 'headCircumferenceCm',
  HEAD_CIRCUMFERENCE_INCH = 'headCircumferenceInch',
  CHEST_CIRCUMFERENCE_CM = 'chestCircumferenceCm',
  CHEST_CIRCUMFERENCE_INCH = 'chestCircumferenceInch'
}

export const getHeightWeightFormFieldBuilderItems = (heightOrWeightChange: (chartField: ChartMetaFormField) => ChartMetaFormField[]): ChartMetaFormFieldParams[] => [
  { name: FormField.HEIGHT_FEET, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.FEET, onGroupChange: heightOrWeightChange },
  { name: FormField.HEIGHT_INCH, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.INCHES, onGroupChange: heightOrWeightChange },
  { name: FormField.HEIGHT_CM, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.CM, onGroupChange: heightOrWeightChange },
  { name: FormField.WEIGHT_ADMISSION, type: FormFieldInputType.CHECK_BOX },
  {
    name: FormField.WEIGHT_POUND,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.HEIGHT_WEIGHT.POUNDS,
    onGroupChange: heightOrWeightChange,
    errorLabel: LanguageKeys.HEIGHT_WEIGHT.WEIGHT
  },
  { name: FormField.WEIGHT_OUNCE, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.OUNCES, onGroupChange: heightOrWeightChange },
  {
    name: FormField.WEIGHT_KG,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.HEIGHT_WEIGHT.KGS,
    onGroupChange: heightOrWeightChange,
    errorLabel: LanguageKeys.HEIGHT_WEIGHT.WEIGHT
  },
  { name: FormField.WEIGHT_GRAM, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.GRAMS, onGroupChange: heightOrWeightChange },
  { name: FormField.WEIGHT_METHOD, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.HEIGHT_WEIGHT.METHOD, onGroupChange: heightOrWeightChange },
  { name: FormField.CONTAINER_INFANT, type: FormFieldInputType.CONTAINER },
  { name: FormField.ABDOMINAL_GIRTH_CM, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.CM, onGroupChange: heightOrWeightChange },
  { name: FormField.ABDOMINAL_GIRTH_INCH, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.INCHES, onGroupChange: heightOrWeightChange },
  { name: FormField.ANTERIOR_FONTANEL, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.HEIGHT_WEIGHT.ANTERIOR_FONTANEL, onGroupChange: heightOrWeightChange },
  { name: FormField.POSTERIOR_FONTANEL, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.HEIGHT_WEIGHT.POSTERIOR_FONTANEL, onGroupChange: heightOrWeightChange },
  { name: FormField.HEAD_CIRCUMFERENCE_CM, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.CM, onGroupChange: heightOrWeightChange },
  { name: FormField.HEAD_CIRCUMFERENCE_INCH, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.INCHES, onGroupChange: heightOrWeightChange },
  { name: FormField.CHEST_CIRCUMFERENCE_CM, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.CM, onGroupChange: heightOrWeightChange },
  { name: FormField.CHEST_CIRCUMFERENCE_INCH, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.HEIGHT_WEIGHT.INCHES, onGroupChange: heightOrWeightChange },
  { name: FormField.NOTE, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.HEIGHT_WEIGHT.NOTES },
  { name: FormField.TOTAL_HEIGHT, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.HEIGHT_WEIGHT.TOTAL_HEIGHT },
  { name: FormField.TOTAL_WEIGHT, type: FormFieldInputType.TEXT_AREA, label: LanguageKeys.HEIGHT_WEIGHT.TOTAL_WEIGHT }
];

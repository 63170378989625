import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { FormField } from './constants';
import './humpty.dumpty.falls.scale.scss';

export interface HumptyDumptyFallsScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const HumptyDumptyFallsScaleView = (props: HumptyDumptyFallsScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="humpty-dumpty-falls-scale">
      <h1>Humpty Dumpty Falls Scale</h1>
      <p>
        <span className="u-els-color-error">* </span>
        All fields in this chart are required
      </p>
      <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
      <ErrorFormField
        className="u-els-margin-top-1x"
        formField={formFieldMap.get(FormField.HUMPTY_DUMPTY_FALLS_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap }}
      />
      <ChartActions {...chartActionsProps} />
      <p className="u-els-italic u-els-margin-bottom-3x u-els-margin-top-3x">
        Modified from Hill-Rodriguez, et al. The Humpty Dumpty Falls Scale: A case-control study. J Soc Pediatr Nurs 14(1), 22-32, 2009.
      </p>
    </div>
  );
};

HumptyDumptyFallsScaleView.displayName = 'HumptyDumptyFallsScaleView';
export default HumptyDumptyFallsScaleView;

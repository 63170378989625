import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './ActivityOccupationalTherapy';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface ActivityOccupationalTherapyReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const ActivityOccupationalTherapyReadOnly = (props: ActivityOccupationalTherapyReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Activity - Occupational Therapy Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.NEW_OCCUPATIONAL_THERAPY}
                  listFormFieldId={[FormField.NO_NEW_OCCUPATIONAL, FormField.NEW_OCCUPATIONAL_THERAPY]}
                  customTitle="New occupational therapy problems"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.IMPAIRMENT_ENVIDENCE}
                  formFieldId={FormField.IMPAIRMENT_ENVIDENCE}
                  customTitle="Impairment envidence"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ACTIVITY_OCCUPATIONAL_THERAPY}
                  formFieldId={FormField.OCCUPATIONAL_THERAPY_RESTRICTION}
                  customTitle="Occupational therapy restriction"
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

ActivityOccupationalTherapyReadOnly.displayName = 'ActivityOccupationalTherapyReadOnly';
export default ActivityOccupationalTherapyReadOnly;

import cx from 'classnames';
import { ChartFragmentRS, DiagnosisFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import {
  AccordionBadge,
  AccordionTitle,
  Box,
  ChartActions,
  ChartPermission,
  ClinicalKeyDrugInformation,
  ClinicalKeyResourceCard,
  ErrorCard,
  ErrorFormField
} from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';
import { FormField, Label } from './constants';
import EditDiagnosisSidebar from './EditDiagnosisSidebar';
import '../pre.clinical.manager.common.scss';

export interface DiagnosisViewProps {
  formSubmittedCount: number;
  selectedNavId: string;
  diagnosis: DiagnosisFragment;
  chartHistory: DiagnosisFragment[];
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  openEditDiagnosisSidebar: Function;
  closeEditDiagnosisSidebar: Function;
  onDeleteDiagnosisHandler: Function;
  onEditDiagnosisHandler: Function;
  disableRadioContent: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const fieldOptions = { labelCss: 'u-els-font-size-h4' };

const DiagnosisView = (props: DiagnosisViewProps) => {
  const {
    formSubmittedCount,
    selectedNavId,
    diagnosis,
    chartHistory,
    chartMetaFormFields,
    chartActionsProps,
    openEditDiagnosisSidebar,
    closeEditDiagnosisSidebar,
    onDeleteDiagnosisHandler,
    onEditDiagnosisHandler,
    disableRadioContent,
    saveChartData
  } = props;

  return (
    <div className="pre-clinical-manager__page">
      <h1>{Label.DIAGNOSIS}</h1>
      <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
      <Box mt2 className="diagnosis__save-section">
        <h2 className="u-els-margin-bottom-2x">{Label.SAVED_DIAGNOSIS}</h2>
        <div className="sng-accordion">
          {chartHistory?.length === 0 && <NothingToSee>{Label.NOTHING_TO_SEE}</NothingToSee>}
          {chartHistory?.length > 0 && (
            <ELSAccordion id="diagnosisAccordion">
              {chartHistory.map((item) => (
                <ELSAccordionItem
                  key={item.fragmentId}
                  id={item.fragmentId}
                  leftIcon="chevron-down"
                  customHeader={
                    <>
                      <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !item.active })}>
                        {item.chartData.name}
                        <ClinicalKeyDrugInformation areaName={Label.CLINICAL_KEY_NAME} />
                      </AccordionTitle>
                      <AccordionBadge>{item.chartData.typeLabel}</AccordionBadge>
                    </>
                  }
                >
                  <Box py pr>
                    <div>
                      <h4>{Label.PATHOPHYSIOLOGY}</h4>
                      <MultiLineWithEmpty text={item.chartData.pathophysiology} />
                    </div>
                    <div className="u-els-margin-top-1x1o2">
                      <h4>{Label.THERAPEUTIC_REGIMEN}</h4>
                      <MultiLineWithEmpty text={item.chartData.therapeuticRegimen} />
                    </div>
                    <div className="u-els-margin-top-1x1o2">
                      <h4>{Label.CURRENT_HEALTH_PROBLEMS_RELATED_FUNCTIONAL_CHANGES}</h4>
                      <MultiLineWithEmpty text={item.chartData.problemsAndChanges} />
                    </div>
                    {item.active && (
                      <ChartPermission fragmentCreatorId={item.creator?.id}>
                        {({ allowEditFragment, allowDeleteFragment }) => (
                          <Box mt2>
                            {allowEditFragment && <ELSButton className="sng-button u-els-margin-right" text="Edit" onClick={() => openEditDiagnosisSidebar(item)} />}
                            {allowDeleteFragment && <ELSButton className="sng-button c-els-button--secondary" text="Delete" onClick={() => onDeleteDiagnosisHandler(item)} />}
                          </Box>
                        )}
                      </ChartPermission>
                    )}
                  </Box>
                </ELSAccordionItem>
              ))}
            </ELSAccordion>
          )}
        </div>
      </Box>
      <Box mt4>
        <h2 className="u-els-margin-bottom-3x">{Label.ADD_DIAGNOSIS}</h2>
        <ErrorFormField formField={chartMetaFormFields.get(FormField.DIAGNOSIS_LIST)} formSubmittedCount={formSubmittedCount} options={fieldOptions} useDefaultMargin={false} />
        <ClinicalKeyResourceCard
          drugName={
            chartMetaFormFields.get(FormField.DIAGNOSIS_LIST)?.value
              ? chartHelper.getMedicationName(chartMetaFormFields.get(FormField.DIAGNOSIS_LIST)?.value, chartMetaFormFields.get(FormField.DIAGNOSIS_LIST)?.chartContent)
              : undefined
          }
        />
        <Box mt>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.DIAGNOSIS_OTHER)} formSubmittedCount={formSubmittedCount} options={fieldOptions} />
        </Box>
        <div className="u-els-width-1o2 u-els-margin-top">
          <ErrorFormField
            formField={chartMetaFormFields.get(FormField.DIAGNOSIS_TYPE)}
            formSubmittedCount={formSubmittedCount}
            useDefaultMargin={false}
            inputProps={{ hideRadioContent: disableRadioContent, disableRadioContent }}
            options={fieldOptions}
          />
        </div>
        <div className="u-els-width-2o3 u-els-margin-top-3x">
          <ErrorFormField
            formField={chartMetaFormFields.get(FormField.PATHOPHYSIOLOGY)}
            formSubmittedCount={formSubmittedCount}
            inputProps={{ placeholder: true }}
            options={fieldOptions}
            useDefaultMargin={false}
          />
        </div>
        <div className="u-els-width-2o3 u-els-margin-top-3x">
          <ErrorFormField
            formField={chartMetaFormFields.get(FormField.THERAPEUTIC_REGIMEN)}
            formSubmittedCount={formSubmittedCount}
            inputProps={{ placeholder: true }}
            options={fieldOptions}
            useDefaultMargin={false}
          />
        </div>
        <div className="u-els-width-2o3 u-els-margin-top-3x">
          <ErrorFormField
            formField={chartMetaFormFields.get(FormField.PROBLEMS_AND_CHANGES)}
            formSubmittedCount={formSubmittedCount}
            inputProps={{ placeholder: true }}
            options={fieldOptions}
            useDefaultMargin={false}
          />
        </div>

        <EditDiagnosisSidebar
          diagnosis={diagnosis}
          onSaveClick={onEditDiagnosisHandler}
          onCloseClick={closeEditDiagnosisSidebar}
          selectedNavId={selectedNavId}
          disableRadioContent={disableRadioContent}
          saveChartData={saveChartData}
          {...appHelper.getChartSharedProps(props)}
        />
      </Box>
      <ChartActions {...chartActionsProps} />
    </div>
  );
};

DiagnosisView.displayName = 'DiagnosisView';
export default DiagnosisView;

import { History, Location } from 'history';
import { delay, isEqual } from 'lodash';
import { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { NavigationItem } from 'models/ui';
import { Event } from 'constants/app.constant';
import { eventHelper } from 'helpers';
import { logService, navigationService } from 'services';
import { appActions } from 'redux/ducks/app';
import NavigationView from './NavigationView';
import './navigation.scss';

interface NavigationProps {
  items: NavigationItem[];
  show?: boolean;
  setSelectedNavId: Function;
  history: History;
  location: Location & { timeout: boolean };
}

interface NavigationState {
  topNavItem: NavigationItem;
  navItems: NavigationItem[];
  backItem: NavigationItem;
}

class Navigation extends Component<NavigationProps, NavigationState> {
  isInitial: boolean;
  animationElement: Element;
  matchedLocation: NavigationItem;
  navigationMap: Map<string, NavigationItem>;

  static displayName = 'Navigation';
  static defaultProps = {
    history: {},
    location: {},
    show: true
  };

  constructor(props: NavigationProps) {
    super(props);
    this.isInitial = true;
    this.animationElement = null;
    this.navigationMap = navigationService.mapTreeToMap(this.props.items, null);
    this.matchedLocation = this.findNavigationMatchedCurrentLocation();
    const topNavItem = this.matchedLocation?.landing ? this.matchedLocation : this.findParentNavigation(this.matchedLocation);
    this.state = {
      topNavItem,
      navItems: topNavItem?.children?.length > 0 ? topNavItem.children : [...this.props.items],
      backItem: this.findParentNavigation(topNavItem)
    };
    this.props.setSelectedNavId(this.matchedLocation?.id);
  }

  componentDidMount() {
    eventHelper.eventEmitter.on(Event.Breadcrumbs.CATEGORY_ITEM_CLICK, this.onBreadcrumbItemClick);
    eventHelper.eventEmitter.on(Event.Navigation.OVERLAY_CLICK, () => {
      const topNavItem = this.findParentNavigation(this.matchedLocation);
      this.setState({
        topNavItem,
        navItems: topNavItem?.children?.length > 0 ? topNavItem.children : [...this.props.items],
        backItem: this.findParentNavigation(topNavItem)
      });
    });

    const navWrapper = this.getNavWrapper();
    if (navWrapper) {
      if (this.props.location.timeout) {
        setTimeout(() => {
          navWrapper.style.visibility = 'visible';
        }, 200);
      } else {
        navWrapper.style.visibility = 'visible';
      }
    }
    // eslint-disable-next-line prefer-destructuring
    this.animationElement = document.getElementsByClassName('js-animation-container')[0];
    this.clearAnimationOnEnd();
  }

  componentDidUpdate(prevProps: Readonly<NavigationProps>): void {
    if (prevProps.show !== this.props.show) {
      this.isInitial = false;
    }
    if (!isEqual(prevProps.items, this.props.items)) {
      this.navigationMap = navigationService.mapTreeToMap(this.props.items, null);
      this.matchedLocation = this.findNavigationMatchedCurrentLocation();
      const topNavItem = this.matchedLocation?.landing ? this.matchedLocation : this.findParentNavigation(this.matchedLocation);
      this.setState({
        topNavItem,
        navItems: topNavItem?.children?.length > 0 ? topNavItem.children : [...this.props.items],
        backItem: this.findParentNavigation(topNavItem)
      });
      this.props.setSelectedNavId(this.matchedLocation?.id);
    }
  }

  componentWillUnmount() {
    eventHelper.eventEmitter.removeListener(Event.Breadcrumbs.CATEGORY_ITEM_CLICK, this.onBreadcrumbItemClick);
    eventHelper.eventEmitter.removeListener(Event.Navigation.OVERLAY_CLICK, this.goBack);
  }

  clearAnimationOnEnd = () => {
    if (this.animationElement) {
      const navWrapper = this.getNavWrapper();
      this.animationElement.addEventListener('animationend', () => navWrapper.classList.remove('slideInLeft', 'slideInRight'));
    }
  };

  onBreadcrumbItemClick = (item: NavigationItem) => logService.log(`${item.text} clicked`);

  getNavWrapper = (): HTMLElement => document.querySelector('.nav__wrapper');

  findNavigationMatchedCurrentLocation = (): NavigationItem => {
    let matchedNavigationItem = null;
    this.navigationMap.forEach((item) => {
      if (item.path === this.props.location.pathname) {
        matchedNavigationItem = item;
      }
    });
    return matchedNavigationItem;
  };

  findParentNavigation = (targetNavigation) => this.navigationMap.get(targetNavigation?.parentId);

  goBack = () => {
    eventHelper.eventEmitter.emit(Event.Navigation.TOGGLE_OVERLAY, true);
    this.setState(
      (prevState) => ({
        topNavItem: prevState.backItem,
        navItems: prevState.backItem?.children || [...this.props.items],
        backItem: this.findParentNavigation(prevState.backItem)
      }),
      () => this.setAnimation(true)
    );
  };

  setAnimation = (leftToRight: boolean, timeout?: boolean) => {
    if (timeout) {
      setTimeout(() => {
        const navWrapper = this.getNavWrapper();
        if (navWrapper) {
          navWrapper.style.visibility = 'visible';
          navWrapper.className = 'nav__wrapper';
          navWrapper.classList.add('animated', leftToRight ? 'slideInLeft' : 'slideInRight');
        }
      }, 200);
    } else {
      const navWrapper = this.getNavWrapper();
      navWrapper.className = 'nav__wrapper';
      navWrapper.classList.add('animated', leftToRight ? 'slideInLeft' : 'slideInRight');
    }
  };

  handleNavItemClick = (event: SyntheticEvent, navItem: NavigationItem) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (navItem.children?.length) {
      const isParentOfCurrentURL = this.props.location.pathname.startsWith(navItem.path);
      eventHelper.eventEmitter.emit(Event.Navigation.TOGGLE_OVERLAY, !isParentOfCurrentURL);
      this.setState(
        (prevState) => ({
          topNavItem: navItem,
          navItems: navItem?.children?.length > 0 ? navItem.children : [...this.props.items],
          backItem: prevState.topNavItem
        }),
        () => {
          this.setAnimation(false);
          if (navItem.landing) {
            delay(() => this.props.history.push(navItem.path), 1000);
            this.props.setSelectedNavId(navItem.id);
          }
        }
      );
    } else {
      this.props.setSelectedNavId(navItem.id);
      this.props.history.push(navItem.path);
    }
  };

  render() {
    const { show, location } = this.props;
    const navItems = this.state.navItems.filter((item) => item.shown);
    const navTitle = this.state.topNavItem?.text || '';
    // allItems is an array of all NavigationItems from this.navigationMap
    const allItems: NavigationItem[] = Array.from(this.navigationMap, (item) => item[1]);
    // allCharts is an array of all charts with navigable paths
    const allCharts: NavigationItem[] = allItems.filter((item) => !!item.leaf || !!item.landing);

    const viewProps = {
      allCharts,
      currentPath: location.pathname,
      navTitle,
      navItems,
      goBack: this.goBack,
      onNavItemClick: this.handleNavItemClick,
      ...(!this.isInitial && { show })
    };

    if (this.isInitial && !show) return null;

    return <NavigationView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

export { Navigation as BaseNavigation };
export default compose(
  withProps(() => ({
    history: useHistory(),
    location: useLocation()
  })),
  connect(null, mapDispatchToProps)
)(Navigation);

import { PhysicalExaminationFragment } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import { FormField, FormFieldLabel, Section } from './constants';

export interface PhysicalExaminationReadOnlyProps {
  selectedRecord: PhysicalExaminationFragment;
}

const PhysicalExaminationReadOnly = (props: PhysicalExaminationReadOnlyProps) => {
  const record = props.selectedRecord;
  return (
    <>
      <h2>Physical Examination</h2>
      <Box className="sng-article">
        {!record && <h3>Chart Omitted</h3>}
        {record && (
          <>
            <h3>Physical Exam</h3>
            <Box mb className="u-els-margin-top-1x1o2">
              <ELSFlex className="u-flex-gutter-2x u-flex-row-gap-1x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.GENERAL}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.GENERAL]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.VITAL_SIGNS}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VITAL_SIGNS]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.INTEGUMENT}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.INTEGUMENT]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.HEENT}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.HEENT]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.CARDIOVASCULAR}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.CARDIOVASCULAR]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.RESPIRATORY}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.RESPIRATORY]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.GASTROINTESTINAL}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.GASTROINTESTINAL]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.GENITOURINARY}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.GENITOURINARY]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.MUSCULOSKELETAL}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.MUSCULOSKELETAL]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.NEUROLOGIC}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.NEUROLOGIC]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.DEVELOPMENTAL}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.DEVELOPMENTAL]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.ENDOCRINE}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.ENDOCRINE]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.GENITALIA}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.GENITALIA]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.LYMPHATIC}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.LYMPHATIC]} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <h3>{Section.OBSTETRIC_GENITALIA}</h3>
            <Box mb className="u-els-margin-top-1x1o2">
              <ELSFlex className="u-flex-gutter-2x u-flex-row-gap-1x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.FUNDAL_HEIGHT}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.FUNDAL_HEIGHT]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.PELVIS_TYPE}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.PELVIS_TYPE]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.VAGINA}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VAGINA]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.PRESENTATION}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.PRESENTATION]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.SACRUM}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.SACRUM]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.ADNEXA}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.ADNEXA]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.DIAGONAL_CONJUGATE}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.DIAGONAL_CONJUGATE]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.VULVA}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VULVA]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.FETAL_HEART_RATE}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.FETAL_HEART_RATE]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.SPINES}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.SPINES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.CERVIX}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.CERVIX]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.FETAL_MOVEMENT}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.FETAL_MOVEMENT]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.SP_ARCH}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.SP_ARCH]} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <h3>{Section.CURRENT_PREGNANCY}</h3>
            <Box mb className="u-els-margin-top-1x1o2">
              <ELSFlex className="u-flex-gutter-2x u-flex-row-gap-1x" left wrap>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.NAUSEA_VOMITING}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.NAUSEA_VOMITING]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.NAUSEA_VOMITING_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.VAGINAL_BLEEDING}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VAGINAL_BLEEDING]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VAGINAL_BLEEDING_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.VAGINAL_DISCHARGE}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VAGINAL_DISCHARGE]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.VAGINAL_DISCHARGE_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.URINARY_SIGNS_SYMPTOMS}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.URINARY_SIGNS_SYMPTOMS]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.URINARY_SIGNS_SYMPTOMS_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.CONSTIPATION}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.CONSTIPATION]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.CONSTIPATION_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.FEVER_RASH}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.FEVER_RASH]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.FEVER_RASH_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.INFECTION}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.INFECTION]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.INFECTION_NOTES]} />
                </ELSFlexItem>
                <ELSFlexItem w="1o3" md="1o1">
                  <h4>{FormFieldLabel.OTHER}</h4>
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.CURRENT_PREGNANCY_OTHER]?.content} />
                  <MultiLineWithEmpty text={record?.chartData?.[FormField.CURRENT_PREGNANCY_OTHER_NOTES]} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <h3>{Section.ASSESSMENT_PLAN}</h3>
            <Box mb className="u-els-margin-top-1x1o2">
              <h4>{FormFieldLabel.SUMMARY}</h4>
              <MultiLineWithEmpty text={record?.chartData?.[FormField.SUMMARY]} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

PhysicalExaminationReadOnly.displayName = 'PhysicalExaminationReadOnly';
export default PhysicalExaminationReadOnly;

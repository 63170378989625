import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, ContentItem, EmbeddedChart } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { SectionTitle as SectionEdinburgh } from 'components/features/chart/special-charts/edinburgh-postnatal-depression-scale/constants';
import { SectionTitle as SectionPsychosocial } from 'components/features/chart/system-assessment/psychosocial-assessment/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, ScaleFormField, SectionTitle } from './constants';
import PostpartumAssessmentView, { PostpartumAssessmentViewProps } from './PostpartumAssessmentView';
import { Title } from '../shared/constants';

interface PostpartumAssessmentState {
  isEdinburghSidebarOpen: boolean;
  isPsychosocialSidebarOpen: boolean;
  enableEmbeddedChart: boolean;
  embeddedEdinburghChart: EmbeddedChart;
  embeddedPsychosocialChart: EmbeddedChart;
  edinburghSidebarKey: number;
  psychosocialSidebarKey: number;
  totalPsychosocialScore: string;
  totalEdinburghDepressionScore: string;
  totalScore: string;
}

class PostpartumAssessment extends Component<ChartComponentProps, PostpartumAssessmentState> {
  static displayName = 'PostpartumAssessment';

  constructor(props) {
    super(props);
    this.state = {
      enableEmbeddedChart: false,
      isEdinburghSidebarOpen: false,
      isPsychosocialSidebarOpen: false,
      edinburghSidebarKey: 0,
      psychosocialSidebarKey: 1,
      embeddedEdinburghChart: null,
      embeddedPsychosocialChart: null,
      totalPsychosocialScore: '0',
      totalEdinburghDepressionScore: '0',
      totalScore: '0'
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const checkboxes = [FormField.ASSESSMENT_NOT_REQUIRED, FormField.BREAST_FEEDING_CHECKBOX, FormField.PERINEUM_COLOR, FormField.PERINEUM_OTHER];
    const radioChoices = [
      { name: FormField.ROOMING_STATUS, label: FormFieldLabel.ROOMING_STATUS },
      { name: FormField.SIGNS_DEPRESSION_SADNESS, label: FormFieldLabel.SIGN_DEPRESSION_SADNESS },
      { name: FormField.BREAST_FEEDING_RADIO, label: FormFieldLabel.BREAST_FEEDING_RADIO },
      { name: FormField.FUNDUS_CHARACTERISTICS, label: FormFieldLabel.FUNDUS_CHARACTERISTICS },
      { name: FormField.FUNDUS_POSITION, label: FormFieldLabel.FUNDUS_POSITION },
      { name: FormField.LOCHIA_COLOR, label: FormFieldLabel.LOCHIA_COLOR },
      { name: FormField.LOCHIA_QUANTITY, label: FormFieldLabel.LOCHIA_QUANTITY },
      { name: FormField.PERINEUM_EDEMA, label: FormFieldLabel.PERINEUM_EDEMA },
      { name: FormField.PERINEUM_INCISION_LACERATION, label: FormFieldLabel.PERINEUM_INCISION_LACERATION },
      { name: FormField.RECTUM_HEMORRHOIDS, label: FormFieldLabel.RECTUM_HEMORRHOIDS }
    ];

    checkboxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    radioChoices.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label })));

    dataMap.set(FormField.FUNDUS_POSITION_DEVIATED, createFormField({ name: FormField.FUNDUS_POSITION_DEVIATED, type: FormFieldInputType.RADIO_CHOICE }));
    dataMap.set(FormField.FUNDUS_HEIGHT, createFormField({ name: FormField.FUNDUS_HEIGHT, type: FormFieldInputType.DROPDOWN, label: FormFieldLabel.FUNDUS_HEIGHT }));
    dataMap.set(FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE, createFormField({ name: FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE, type: FormFieldInputType.SCALE }));
    dataMap.set(
      FormField.PERINEUM_OTHER_DRAINAGE_DESCRIBE,
      createFormField({ name: FormField.PERINEUM_OTHER_DRAINAGE_DESCRIBE, type: FormFieldInputType.TEXT_BOX, label: FormFieldLabel.PERINEUM_OTHER_DRAINAGE_DESCRIBE })
    );
    dataMap.set(FormField.LOCHIA_QUANTITY_OTHER, createFormField({ name: FormField.LOCHIA_QUANTITY_OTHER, type: FormFieldInputType.TEXT_BOX }));
    dataMap.set(
      FormField.CONTAINER_LATCH_BREAST_FEEDING_ASSESSMENT_SCALE,
      createFormField({ name: FormField.CONTAINER_LATCH_BREAST_FEEDING_ASSESSMENT_SCALE, type: FormFieldInputType.CONTAINER })
    );

    dataMap.set(FormField.PERINEUM_COLOR, { ...dataMap.get(FormField.PERINEUM_COLOR), label: FormFieldLabel.PERINEUM_COLOR });
    dataMap.set(FormField.PERINEUM_OTHER, { ...dataMap.get(FormField.PERINEUM_OTHER), label: FormFieldLabel.PERINEUM_OTHER });
    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(ScaleFormField.LATCH, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(ScaleFormField.AUDIBLE_SWALLOWING, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(ScaleFormField.TYPE_NIPPLE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(ScaleFormField.COMFORT_BREAST_NIPPLE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(ScaleFormField.HOLD_POSITIONING, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { buildPlainRecord, buildPatientRecords } = chartService;

    const totalScoreContent = chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE))?.score?.value;
    const totalScoreRecord = buildPlainRecord({
      formField: FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE,
      title: 'Total Score',
      content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE))?.score?.value,
      linkedFormFieldIds: [
        ScaleFormField.LATCH,
        ScaleFormField.AUDIBLE_SWALLOWING,
        ScaleFormField.TYPE_NIPPLE,
        ScaleFormField.COMFORT_BREAST_NIPPLE,
        ScaleFormField.HOLD_POSITIONING
      ]
    });
    const totalScoreRecords = [];
    if (totalScoreContent !== '') totalScoreRecords.push(totalScoreRecord);
    const sectionPsychosocialTotalScore = this.state.embeddedPsychosocialChart?.payload?.chartData.records.find((item) => item.sectionTitle === SectionPsychosocial.TOTAL_SCORE);
    const sectionEdinburghScore = this.state.embeddedEdinburghChart?.payload?.chartData.records.find((item) => item.sectionTitle === SectionEdinburgh.SCORING);

    let result = [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.POSTPARTUM_ASSESSMENT,
        fields: [FormField.ASSESSMENT_NOT_REQUIRED],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.POSTPARTUM,
        fields: [FormField.ROOMING_STATUS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.BEHAVIORAL,
        fields: [FormField.SIGNS_DEPRESSION_SADNESS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.BREASTS,
        fields: [FormField.BREAST_FEEDING_RADIO, FormField.BREAST_FEEDING_CHECKBOX],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.LATCH_BREAST_FEEDING_ASSESSMENT,
        fields: [FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE],
        formFieldMap
      }),
      {
        sectionTitle: SectionTitle.LATCH_BREAST_FEEDING_ASSESSMENT,
        records: [...buildPatientRecords(formFieldMap, FormField.LATCH_BREAST_FEEDING_ASSESSMENT_SCALE, false, true, true)]
      },
      {
        sectionTitle: SectionTitle.LATCH_SCORE,
        records: totalScoreRecords
      },
      chartHelper.buildSection({
        sectionTitle: SectionTitle.FUNDUS,
        fields: [FormField.FUNDUS_CHARACTERISTICS, FormField.FUNDUS_POSITION, FormField.FUNDUS_POSITION_DEVIATED, FormField.FUNDUS_HEIGHT],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.LOCHIA,
        fields: [FormField.LOCHIA_COLOR, FormField.LOCHIA_QUANTITY, FormField.LOCHIA_QUANTITY_OTHER],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PERINEUM,
        fields: [FormField.PERINEUM_EDEMA, FormField.PERINEUM_INCISION_LACERATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PERINEUM_COLOR,
        fields: [FormField.PERINEUM_COLOR],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PERINEUM_OTHER,
        fields: [FormField.PERINEUM_OTHER, FormField.PERINEUM_OTHER_DRAINAGE_DESCRIBE],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.RECTUM,
        fields: [FormField.RECTUM_HEMORRHOIDS],
        formFieldMap
      })
    ];
    if (sectionPsychosocialTotalScore) {
      result = [
        ...result,
        {
          sectionTitle: SectionTitle.TOTAL_PSYCHOSOCIAL_SCORE,
          records: sectionPsychosocialTotalScore.records.filter(({ title }) => title === SectionPsychosocial.TOTAL_SCORE)
        }
      ];
    }
    if (sectionEdinburghScore) {
      result = [
        ...result,
        {
          sectionTitle: SectionTitle.TOTAL_EDINBURGH_POSTNATAL_DEPRESSION_SCORE,
          records: sectionEdinburghScore.records
        }
      ];
    }
    return result;
  };

  handleSaveClick = () => {
    // Save sidebar assessment first
    let beforeSave = null;
    let edinburghPayload = null;
    let edinburghNavId = null;
    let psychosocialPayload = null;
    let psychosocialNavId = null;
    if (this.state.embeddedEdinburghChart) {
      edinburghPayload = this.state.embeddedEdinburghChart.payload;
      edinburghNavId = this.state.embeddedEdinburghChart.navId;
    }
    if (this.state.embeddedPsychosocialChart) {
      psychosocialPayload = this.state.embeddedPsychosocialChart.payload;
      psychosocialNavId = this.state.embeddedPsychosocialChart.navId;
    }

    if (edinburghPayload && psychosocialPayload) {
      beforeSave = () => {
        this.props.saveChartData(edinburghPayload, edinburghNavId);
        this.props.saveChartData(psychosocialPayload, psychosocialNavId);
      };
    } else if (edinburghPayload) {
      beforeSave = () => this.props.saveChartData(edinburghPayload, edinburghNavId);
    } else if (psychosocialPayload) {
      beforeSave = () => this.props.saveChartData(psychosocialPayload, psychosocialNavId);
    }

    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), beforeSave, afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { selectedNavId } = this.props;
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: SectionTitle.POSTPARTUM_ASSESSMENT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    let linkedEdinburghFragmentId = this.state.embeddedEdinburghChart?.payload?.fragmentId;
    let linkedPsychosocialFragmentId = this.state.embeddedPsychosocialChart?.payload?.fragmentId;
    linkedEdinburghFragmentId = `${selectedNavId}_${linkedEdinburghFragmentId}`;
    linkedPsychosocialFragmentId = `${selectedNavId}_${linkedPsychosocialFragmentId}`;
    return { ...basicInfo, linkedEdinburghFragmentId, linkedPsychosocialFragmentId, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  handlePsychosocialClick = () => {
    this.setState({ isPsychosocialSidebarOpen: true });
  };

  handleEdinburghClick = () => {
    this.setState({ isEdinburghSidebarOpen: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isPsychosocialSidebarOpen: false, isEdinburghSidebarOpen: false });
  };

  handleSaveEdinburghEmbeddedChart = (payload: ChartFragment, navId: string) => {
    let totalScore = Number(this.state.totalScore);
    const totalEdinburghDepressionScore = chartHelper.getScaleContent(payload, SectionEdinburgh.SCORING)[0].score;
    totalScore = totalEdinburghDepressionScore ? Number(totalEdinburghDepressionScore) + totalScore : totalScore;

    this.setState({
      embeddedEdinburghChart: {
        payload,
        navId
      },
      totalEdinburghDepressionScore,
      totalScore: String(totalScore)
    });
    this.setState({ isEdinburghSidebarOpen: false });
  };

  handleSavePsychosocialEmbeddedChart = (payload: ChartFragment, navId: string) => {
    let totalScore = Number(this.state.totalScore);
    const totalPsychosocialScore = chartHelper.getScaleContent(payload, SectionPsychosocial.TOTAL_SCORE)[0].score;
    totalScore = totalPsychosocialScore ? Number(totalPsychosocialScore) + totalScore : totalScore;

    this.setState({
      embeddedPsychosocialChart: {
        payload,
        navId
      },
      totalPsychosocialScore,
      totalScore: String(totalScore)
    });
    this.setState({ isPsychosocialSidebarOpen: false });
  };

  onDepressionRadioChange = (formField: ChartMetaFormField, item: ContentItem) => {
    if (item.label === 'Yes') {
      this.setState({
        enableEmbeddedChart: true
      });
    } else if (item.label === 'No') {
      this.setState({
        enableEmbeddedChart: false
      });
    }
  };

  render() {
    const { handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: PostpartumAssessmentViewProps = {
      chartActionsProps,
      formFieldMap,
      handlePsychosocialClick: this.handlePsychosocialClick,
      handleEdinburghClick: this.handleEdinburghClick,
      formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap(),
      isEdinburghSidebarOpen: this.state.isEdinburghSidebarOpen,
      isPsychosocialSidebarOpen: this.state.isPsychosocialSidebarOpen,
      edinburghSidebarKey: this.state.edinburghSidebarKey,
      psychosocialSidebarKey: this.state.psychosocialSidebarKey,
      handleCloseSidebar: this.handleCloseSidebar,
      enableEmbeddedChart: this.state.enableEmbeddedChart,
      onSaveEdinburghEmbeddedChart: this.handleSaveEdinburghEmbeddedChart,
      onSavePsychosocialEmbeddedChart: this.handleSavePsychosocialEmbeddedChart,
      onDepressionRadioChange: this.onDepressionRadioChange,
      totalPsychosocialScore: this.state.totalPsychosocialScore,
      totalEdinburghDepressionScore: this.state.totalEdinburghDepressionScore,
      totalScore: this.state.totalScore
    };

    return <PostpartumAssessmentView {...viewProps} />;
  }
}

export { PostpartumAssessment as BasePostpartumAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(PostpartumAssessment);

import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { ChartActions, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, FormFieldLabel, Section } from './constants';

export interface ClinicalInstituteScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const ClinicalInstituteScaleView = (props: ClinicalInstituteScaleViewProps) => {
  const { chartActionsProps, contextDirectionMap, formSubmittedCount, formFieldMap } = props;

  return (
    <div className="clinical-institute">
      <h1>{Section.CLINICAL_INSTITUTE_SCALE}</h1>
      <p>
        <span className="u-els-color-error">* </span>
        {FormFieldLabel.ALL_FIELDS_REQUIRED}
      </p>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ELSFlex className="u-flex-gutter-3x" left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PULSE_HEART_RATE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.BLOOD_PRESSURE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>

      <SectionDivider />

      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.CLINICAL_INSTITUTE_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: true }}
      />

      <p className="u-els-italic">{FormFieldLabel.MAXIMUM_SCORE}</p>
      <p>{FormFieldLabel.NOTE}</p>
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.ALCOHOL_SCREEN]} />
      <p>{FormFieldLabel.MODIFIED_FROM}</p>
    </div>
  );
};

ClinicalInstituteScaleView.displayName = 'ClinicalInstituteScaleView';
export default ClinicalInstituteScaleView;

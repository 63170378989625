import classNames from 'classnames';
import { ReactElement } from 'react';
import { AssessmentSubmission } from 'models/api-response';
import { AssessmentStatus } from 'models/enum';
import { AssessmentGrading } from 'models/ui';
import { isAssessmentNotStartedOrInProgress, isAssessmentSubmitted } from 'helpers/assignment.helper';
import { RoundCheckmarkIcon } from 'components/common';
import { ELSIcon } from 'components/common/els';

export enum FeedbackRanking {
  ROUND_CHECKMARK = 1,
  NONE = 2,
  NA = 3,
  NULL = 4
}

// Return 'na' if status is NOT_STARTED or IN_PROGRESS
// Return 'None' if status is COMPLETED or COMPLETED_LATE or PAST_DUE and has no feedback
// Return RoundCheckmarkIcon if status is COMPLETED or COMPLETED_LATE or PAST_DUE and has feedback
// Return null if status inappropriate, for exception handler
const feedbackRenderer = (row: AssessmentGrading): ReactElement => {
  if (isAssessmentNotStartedOrInProgress(row.status)) {
    return <>na</>;
  }
  if (isAssessmentSubmitted(row.status) || row.status === AssessmentStatus.PAST_DUE) {
    return row.feedback ? <RoundCheckmarkIcon /> : <>None</>;
  }
  return null;
};

// See above function for result of rendering
// If sorted ASC, the ranking is RoundCheckmarkIcon, None, na
// If sorted DESC, the ranking is None, RoundCheckmarkIcon, na
// The na and null value doesn't matter because they are already filtered out and for exception handler
const feedbackRanking = (row: AssessmentGrading): number => {
  if (isAssessmentNotStartedOrInProgress(row.status)) {
    return FeedbackRanking.NA;
  }
  if (isAssessmentSubmitted(row.status) || row.status === AssessmentStatus.PAST_DUE) {
    return row.feedback ? FeedbackRanking.ROUND_CHECKMARK : FeedbackRanking.NONE;
  }
  return FeedbackRanking.NULL;
};

const compareAssessmentGrading = (objectA: AssessmentSubmission, objectB: AssessmentSubmission) => {
  const a = feedbackRanking(objectA as unknown as AssessmentGrading);
  const b = feedbackRanking(objectB as unknown as AssessmentGrading);
  return Number(a > b) - Number(a < b);
};

const renderSortIcon = (isSortDesc: boolean): ReactElement => {
  const sortIconClass = classNames('c-els-table__sort-icon', 'u-els-display-inline-block', {
    'c-els-table__sort-icon--desc': isSortDesc
  });
  return <ELSIcon customClass={sortIconClass} name="arrow-right" size="3o4" />;
};

export { feedbackRenderer, feedbackRanking, compareAssessmentGrading, renderSortIcon };

import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const HygieneDressingsComfortView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div>
      <h1>{SectionTitle.HYGIENE_DRESSINGS_COMFORT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_NEWBORN_HYGIENE)} formSubmittedCount={formSubmittedCount}>
          <ELSFlex className="u-flex-gutter-3x" left wrap>
            <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
              <h2 className="u-els-margin-bottom">{SectionTitle.NEWBORN_HYGIENE}</h2>
              <ErrorFormField formField={formFieldMap.get(FormField.NEWBORN_HYGIENE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.NEWBORN_HYGIENE_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </ELSFlexItem>
          </ELSFlex>
          <SectionDivider />
        </ErrorFormField>

        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">{SectionTitle.COMFORT}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.COMFORT)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.COMFORT_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">{SectionTitle.BATH_SHOWER}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.BATH_SHOWER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.BATH_SHOWER_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">{SectionTitle.MOUTH_CARE}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.MOUTH_CARE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.MOUTH_CARE_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_SHAVE)} formSubmittedCount={formSubmittedCount}>
              <h2 className="u-els-margin-bottom">{SectionTitle.SHAVE}</h2>
              <ErrorFormField formField={formFieldMap.get(FormField.SHAVE_NOT_APPLICATE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.SHAVE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.SHAVE_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
            </ErrorFormField>
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">{SectionTitle.HAIR_NAILS}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.HAIR_NAILS)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.HAIR_NAILS_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2 className="u-els-margin-bottom">{SectionTitle.DRESSING}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.DRESSING)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.DRESSING_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.LINENS_HOUSEKEEPING}</h2>
            <ErrorFormField formField={formFieldMap.get(FormField.LINENS_HOUSEKEEPING)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.LINENS_HOUSEKEEPING_NOTES)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

HygieneDressingsComfortView.displayName = 'HygieneDressingsComfortView';
export default HygieneDressingsComfortView;

import { camelCase } from 'lodash';
import { ReactElement } from 'react';
import { LaboratoryTestResult } from 'models/api-response';
import { ChartMetaFormField } from 'models/ui';
import { appHelper } from 'helpers';
import { Box, ELSTextarea, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSRadio, ELSTextBox } from 'components/common/els';
import { FormFieldLabel } from './constants';
import { FormField } from './LaboratoryTest';
import { LaboratoryTest } from './LaboratoryTestConstants';

export interface LaboratoryTestResultCardViewProps {
  labResult: LaboratoryTestResult;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  showError: boolean;
  [FormField.TEST_RESULT]: string;
  [FormField.DATE_OF_TEST_RESULT]: string;
  [FormField.TEST_RESULT_LEVEL]: string;
  [FormField.TEST_RESULT_SIGNIFICANCE]: string;
  onInputChange: Function;
  onDateChange: Function;
}

const LaboratoryTestResultCardView = (props: LaboratoryTestResultCardViewProps): ReactElement => {
  const { labResult, chartMetaFormFields, showError, onDateChange, onInputChange } = props;
  return (
    <Box mt2 className="c-els-card c-els-card--border-primary u-els-width-3o4 u-els-width-1o1@tablet">
      <h3 className="u-els-color-secondary u-els-margin-bottom-2x">{labResult.name}</h3>
      <ELSFlex className="u-flex-grid-2x" wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <div>{FormFieldLabel.TEST_RESULTS}</div>
          <ELSTextBox
            id={`laboratory-test-result__test-result-${labResult.id}`}
            placeholder={appHelper.getPlaceholder(FormFieldLabel.TEST_RESULTS)}
            value={props[FormField.TEST_RESULT]}
            changeHandler={(value) => onInputChange(FormField.TEST_RESULT, value)}
          />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <div id={`chart-field-${camelCase(labResult.name)}`}>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.DATE_OF_TEST_RESULT)}
              formSubmittedCount={+showError}
              onChange={(value) => onDateChange(FormField.DATE_OF_TEST_RESULT, value)}
            />
          </div>
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <div>Result Level</div>
          <Box mt>
            <Box mb>
              <ELSRadio
                id={`result-level-selection__low-${labResult.id}`}
                name={`result-level-selection-${labResult.id}`}
                value={LaboratoryTest.TestResult.ResultLevel.LOW}
                checked={props[FormField.TEST_RESULT_LEVEL] === LaboratoryTest.TestResult.ResultLevel.LOW}
                changeHandler={(value) => onInputChange(FormField.TEST_RESULT_LEVEL, value)}
              >
                Low
              </ELSRadio>
            </Box>
            <Box mb>
              <ELSRadio
                id={`result-level-selection__within-normal-limits-${labResult.id}`}
                name={`result-level-selection-${labResult.id}`}
                value={LaboratoryTest.TestResult.ResultLevel.WITHIN_NORMAL_LIMITS}
                checked={props[FormField.TEST_RESULT_LEVEL] === LaboratoryTest.TestResult.ResultLevel.WITHIN_NORMAL_LIMITS}
                changeHandler={(value) => onInputChange(FormField.TEST_RESULT_LEVEL, value)}
              >
                Within Normal Limits
              </ELSRadio>
            </Box>
            <div>
              <ELSRadio
                id={`result-level-selection__high-${labResult.id}`}
                name={`result-level-selection-${labResult.id}`}
                value={LaboratoryTest.TestResult.ResultLevel.HIGH}
                checked={props[FormField.TEST_RESULT_LEVEL] === LaboratoryTest.TestResult.ResultLevel.HIGH}
                changeHandler={(value) => onInputChange(FormField.TEST_RESULT_LEVEL, value)}
              >
                High
              </ELSRadio>
            </div>
          </Box>
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <div>{FormFieldLabel.RESULT_SIGNIFICANCE}</div>
          <ELSTextarea
            id={`laboratory-test-result__test-significance-${labResult.id}`}
            placeholder={appHelper.getPlaceholder(FormFieldLabel.RESULT_SIGNIFICANCE)}
            value={props[FormField.TEST_RESULT_SIGNIFICANCE]}
            onChange={(value) => onInputChange(FormField.TEST_RESULT_SIGNIFICANCE, value)}
          />
        </ELSFlexItem>
      </ELSFlex>
    </Box>
  );
};

LaboratoryTestResultCardView.displayName = 'LaboratoryTestResultCardView';
export default LaboratoryTestResultCardView;

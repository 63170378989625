export enum FormField {
  CHILD_PASSENGER_SAFETY_SELECT = 'childPassengerSafetySelect',
  ABUSE_MALTREATMENT_SELECT = 'abuseMaltreatmentSelect',
  SLEEP_SELECT = 'sleepSelect',
  CHILD_PASSENGER_SAFETY_DESCRIBE = 'childPassengerSafetyDescribe',
  ABUSE_MALTREATMENT_DESCRIBE = 'abuseMaltreatmentDescribe',
  SLEEP_DESCRIBE = 'sleepDescribe'
}

export enum SectionTitle {
  INDICATE_THE_PRESENCE_OF_RISK = 'Indicate the presence of risk in the following:'
}

import produce from 'immer';
import { Component } from 'react';
import { Event } from 'constants/app.constant';
import { eventHelper } from 'helpers';
import { ELSPageLoader } from '../els';

interface PageLoaderState {
  actionsInProgress: [];
}

class PageLoader extends Component<{}, PageLoaderState> {
  static displayName = 'PageLoader';

  constructor(props) {
    super(props);
    this.state = {
      actionsInProgress: []
    };
  }

  componentDidMount() {
    eventHelper.eventEmitter.on(Event.BusyAction.ADD, (action: string) => {
      this.setState(
        produce((state) => {
          state.actionsInProgress.push(action);
        })
      );
    });
    eventHelper.eventEmitter.on(Event.BusyAction.REMOVE, (action: string) => {
      this.setState(
        produce((state) => {
          state.actionsInProgress = state.actionsInProgress.filter((item) => item !== action);
        })
      );
    });
  }

  componentWillUnmount() {
    eventHelper.eventEmitter.removeListener(Event.BusyAction.ADD);
    eventHelper.eventEmitter.removeListener(Event.BusyAction.REMOVE);
  }

  render() {
    const { actionsInProgress } = this.state;
    if (actionsInProgress?.length) {
      return <ELSPageLoader />;
    }
    return null;
  }
}

export default PageLoader;

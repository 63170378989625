import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { chartHelper } from 'helpers';

export enum FormField {
  GENERA_NUTRITION_SCREEN = 'generalNutritionScreen',
  ILLNESS_INJURY_NUTRITION_SCREEN = 'illnessInjuryNutritionScreen',
  FOOD_PREFERENCES_DIET_CONSIDERATIONS = 'foodPreferencesDietConsiderationsMarkAllApply',
  OTHER_INPUT = 'foodPreferencesDietConsiderationsOtherInput',
  FOOD_PREFERENCES = 'foodPreferences',
  DIET_SNACK = 'dietSnack',
  FOOD_DISLIKES = 'foodDislike',
  INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY_CONTAINER = 'indicatorsOfNutritionalRiskDuringPregnancyContainer',
  INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY = 'indicatorsOfNutritionalRiskDuringPregnancyMarkAllApply',
  UNEXPLAINED_WEIGHT_LOSS_GREATER_WEIGHT = 'unexplainedWeightLossGreaterWeight'
}

const getNestedFieldContentIds = (fragment: ChartFragment, sectionTitle: string, formField: string, defaultValue = []): string[] => {
  const section = fragment?.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  const subRecords = section?.nestedFieldRecords?.filter((item) => item?.formField === formField);
  return subRecords?.map((subRecord) => subRecord.contentId) || defaultValue;
};

export const getNutritionScreenFormFieldBuilderItems = (fragment: ChartFragment): ChartMetaFormFieldParams[] => {
  const { getFragmentValue, getFragmentContentIds, getScaleContent } = chartHelper;
  return [
    {
      name: FormField.GENERA_NUTRITION_SCREEN,
      label: LanguageKeys.NUTRITION_SCREEN.GENERA_NUTRITION_SCREEN,
      type: FormFieldInputType.SCALE,
      scaleContent: getScaleContent(fragment, LanguageKeys.NUTRITION_SCREEN.GENERA_NUTRITION_SCREEN)
    },
    {
      name: FormField.ILLNESS_INJURY_NUTRITION_SCREEN,
      label: LanguageKeys.NUTRITION_SCREEN.ILLNESS_INJURY_NUTRITION_SCREEN,
      type: FormFieldInputType.SCALE,
      scaleContent: getScaleContent(fragment, LanguageKeys.NUTRITION_SCREEN.ILLNESS_INJURY_NUTRITION_SCREEN)
    },
    {
      name: FormField.FOOD_PREFERENCES_DIET_CONSIDERATIONS,
      label: LanguageKeys.MARK_ALL_THAT_APPLY,
      type: FormFieldInputType.CHECK_BOX,
      contentIds: getFragmentContentIds(fragment, LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS, FormField.FOOD_PREFERENCES_DIET_CONSIDERATIONS)
    },
    {
      name: FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY,
      label: LanguageKeys.MARK_ALL_THAT_APPLY,
      type: FormFieldInputType.CHECK_BOX,
      contentIds: getFragmentContentIds(
        fragment,
        LanguageKeys.NUTRITION_SCREEN.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY,
        FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY
      )
    },
    {
      name: FormField.UNEXPLAINED_WEIGHT_LOSS_GREATER_WEIGHT,
      type: FormFieldInputType.RADIO_CHOICE,
      contentIds: getNestedFieldContentIds(fragment, LanguageKeys.NUTRITION_SCREEN.GENERA_NUTRITION_SCREEN, FormField.UNEXPLAINED_WEIGHT_LOSS_GREATER_WEIGHT)
    },
    {
      name: FormField.FOOD_PREFERENCES,
      label: LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES, FormField.FOOD_PREFERENCES)
    },
    {
      name: FormField.DIET_SNACK,
      label: LanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.NUTRITION_SCREEN.DIET_BEFORE_HOSPITALIZATION_INCLUDING_SNACKS, FormField.DIET_SNACK)
    },
    {
      name: FormField.FOOD_DISLIKES,
      label: LanguageKeys.NUTRITION_SCREEN.FOOD_DISLIKES,
      type: FormFieldInputType.TEXT_AREA,
      value: getFragmentValue(fragment, LanguageKeys.NUTRITION_SCREEN.FOOD_DISLIKES, FormField.FOOD_DISLIKES)
    },
    {
      name: FormField.OTHER_INPUT,
      type: FormFieldInputType.TEXT_BOX,
      label: LanguageKeys.OTHER,
      value: getFragmentValue(fragment, LanguageKeys.NUTRITION_SCREEN.FOOD_PREFERENCES_DIET_CONSIDERATIONS, FormField.OTHER_INPUT)
    },
    {
      name: FormField.INDICATORS_NUTRITIONAL_RISK_DURING_PREGNANCY_CONTAINER,
      type: FormFieldInputType.CONTAINER
    }
  ];
};

import { CreateAssignmentWrapper, InstructorPageWrapper, withHTMLHeadSEO } from 'components/common';
import AssignmentSelection from 'components/features/assignment/create-assignment-workflow/assignment-selection/AssignmentSelection';

const AssignmentSelectionPage = () => (
  <InstructorPageWrapper>
    {({ loggedInUser, intl }) => <CreateAssignmentWrapper intl={intl}>{(props) => <AssignmentSelection instructor={loggedInUser} {...props} />}</CreateAssignmentWrapper>}
  </InstructorPageWrapper>
);

export { AssignmentSelectionPage as BaseAssignmentSelectionPage };
export default withHTMLHeadSEO({ title: 'Add a SimChart item' })(AssignmentSelectionPage);

export enum FormField {
  HUMPTY_DUMPTY_FALLS_SCALE = 'humptyDumptyFallsScale',
  AGE = 'age',
  SEX = 'sex',
  DIAGNOSIS = 'diagnosis',
  COGNITIVE_IMPAIRMENTS = 'cognitiveImpairments',
  ENVIRONMENTAL_FACTORS = 'environmentalFactors',
  RESPONSE_TO_SURGERY = 'responseToSurgery',
  MEDICATION_USAGE = 'medicationUsage'
}

export enum SectionTitle {
  HUMPTY_DUMPTY_FALLS_SCALE = 'Humpty Dumpty Falls Scale',
  TOTAL_SCORE = 'Total Humpty Dumpty Falls Scale Score'
}

import { Component } from 'react';
import { HomeMedicationRecord } from 'models/api-response';
import { DropDownItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { Box } from 'components/common';
import { ELSDropDown } from 'components/common/els';
import { HistoryField } from './constants';
import HomeMedicationHerbalAndNaturalHistoryTable from './HomeMedicationHerbalAndNaturalHistoryTable';
import HomeMedicationOverTheCounterHistoryTable from './HomeMedicationOverTheCounterHistoryTable';
import HomeMedicationPrescriptionHistoryTable from './HomeMedicationPrescriptionHistoryTable';

export interface HomeMedicationHistoryViewProps {
  chartHistory: HomeMedicationRecord[];
  deleteHistory: Function;
  charts: DropDownItem[];
  selectedHistory: string;
  onHistoryChange: Function;
  locale: Locales;
}

class HomeMedicationHistoryView extends Component<HomeMedicationHistoryViewProps> {
  static displayName = 'HomeMedicationHistoryView';

  render() {
    const { charts, selectedHistory, onHistoryChange, chartHistory, deleteHistory, locale } = this.props;
    return (
      <div>
        <Box mt className="chart-input__dropdown">
          <ELSDropDown inline options={charts} value={selectedHistory} changeHandler={onHistoryChange} />
        </Box>
        {selectedHistory === HistoryField.PRESCRIPTION_MEDICATIONS && (
          <Box mt>
            <HomeMedicationPrescriptionHistoryTable chartHistory={chartHistory} deleteHistory={deleteHistory} locale={locale} />
          </Box>
        )}
        {selectedHistory === HistoryField.OVER_THE_COUNTER_PRODUCTS && (
          <Box mt>
            <HomeMedicationOverTheCounterHistoryTable chartHistory={chartHistory} deleteHistory={deleteHistory} />
          </Box>
        )}
        {selectedHistory === HistoryField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS && (
          <Box mt>
            <HomeMedicationHerbalAndNaturalHistoryTable chartHistory={chartHistory} deleteHistory={deleteHistory} />
          </Box>
        )}
      </div>
    );
  }
}

export default HomeMedicationHistoryView;

import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { Assignment } from 'models/api-response';
import { AssessmentStatus, AssignmentTargetType, AssignmentType } from 'models/enum';
import { apiHelper, appHelper, dateTimeHelper } from 'helpers';
import { assignmentService, courseService, featureService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { instructorActions, instructorSelectors } from 'redux/ducks/instructor';
import { Box, SectionDivider, StudentList, withFormUtilities } from 'components/common';
import DisplayName from 'components/common/assignment-setting/DisplayName';
import SchedulingWrapper from 'components/common/assignment-setting/SchedulingWrapper';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import SaveCancelFooter from 'components/common/save-cancel-footer/SaveCancelFooter';
import Scheduling from 'components/common/scheduling/Scheduling';
import './edit.assignment.scss';
import InstructionInput from '../instruction-input/InstructionInput';
import ObjectiveInput from '../objective-input/ObjectiveInput';

interface MatchParams {
  assignmentId: string;
}

interface AssignmentUpdateRequest {
  objectives?: string;
  instructions?: string;
  title: string;
  availableDate: string;
  dueDate: string;
  students: number[];
  targetType: AssignmentTargetType;
}

export interface EditAssignmentProps {
  courseId: string;
  isAllStudents: boolean;
  newSelectedStudentList: number[];
  showSaveSuccess: VoidFunction;
  setIsEdit: Function;
}

interface EditAssignmentState {
  initialAssignment: Assignment;
  assignment: Assignment;
  courseStartDate: string;
  isDirty: boolean;
  showStudentList: boolean;
  assignedStudentIds: number[];
  disabledIds: number[];
  isPastDue: boolean;
}

class EditAssignment extends Component<EditAssignmentProps & RouteComponentProps<MatchParams>, EditAssignmentState> {
  static displayName = 'EditAssignment';
  assignmentId: number;

  constructor(props) {
    super(props);
    this.state = {
      initialAssignment: {} as Assignment,
      assignment: {} as Assignment,
      courseStartDate: null,
      isDirty: false,
      showStudentList: false,
      assignedStudentIds: [],
      disabledIds: [],
      isPastDue: false
    };
    this.assignmentId = Number(this.props.match.params.assignmentId);
  }

  componentDidMount() {
    return appHelper.useLoader(
      Promise.all([
        courseService.getCourseSectionById(this.props.courseId).then((res) => res.data),
        assignmentService.getSimChartAssignment(this.assignmentId).then((res) => res.data),
        featureService.isShowAssignmentStudents()
      ]).then(([course, assignment, showStudentList]) => {
        const availDate = this.getAvailableDate(assignment.availableDate);
        const newDueDate = assignment.dueDate === null ? moment(availDate).add(1, 'd').toISOString() : assignment.dueDate;

        let submissionsPromise = Promise.resolve(null);
        if (assignment.students?.length) {
          submissionsPromise = assignmentService.getAssignmentSummary(this.assignmentId).then((res) => res.data);
        }
        return submissionsPromise.then((data) => {
          const submissions = data?.studentAssessmentSubmissions || [];
          this.setState(
            {
              courseStartDate: course.startDate ? moment(course.startDate).toISOString() : null,
              initialAssignment: assignment,
              assignment: { ...assignment, availableDate: availDate, dueDate: newDueDate },
              showStudentList
            },
            () => {
              const assignedStudentIds = submissions.map((item) => item.userId);
              const readonlyStudentIds = this.isAssignmentPastDue()
                ? submissions.map((item) => item.userId)
                : submissions.filter((item) => item.status !== AssessmentStatus.NOT_STARTED).map((item) => item.userId);
              this.setState({ assignedStudentIds, disabledIds: readonlyStudentIds, isPastDue: this.isAssignmentPastDue() });
            }
          );
        });
      }),
      { errorMessage: 'can not load data' }
    );
  }

  getAvailableDate = (value: string | null) => {
    let availDate: string;

    if (value === null) {
      availDate = moment(dateTimeHelper.now()).toISOString();
    } else {
      availDate = value;
    }
    return availDate;
  };

  updateAssignment = () => {
    const { eolsAssignmentId, title, objectives, instructions, availableDate, dueDate, assignmentType } = this.state.assignment;
    const updatePayload: AssignmentUpdateRequest = {
      title,
      availableDate,
      dueDate,
      students: this.props.newSelectedStudentList,
      targetType: this.props.isAllStudents ? AssignmentTargetType.COURSE : AssignmentTargetType.STUDENT
    };
    if (assignmentType === AssignmentType.EMPTY_EHR) {
      updatePayload.objectives = objectives;
      updatePayload.instructions = instructions;
    }
    assignmentService
      .updateSimChartAssignment(eolsAssignmentId, updatePayload)
      .then(() => {
        this.setState(
          produce((state) => {
            state.initialAssignment = state.assignment;
            state.isDirty = false;
          })
        );
      })
      .then(this.props.showSaveSuccess)
      .then(() => this.props.setIsEdit(true))
      .then(() => navigationService.navigateToAppLinkSource(undefined))
      .catch((err) => apiHelper.showApiError(`can not update assignment ${err.message}`));
  };

  getDueDateError = () => {
    const { availableDate, dueDate } = this.state.assignment;
    const { courseStartDate } = this.state;
    if (!moment(dueDate).isAfter(availableDate, 'minute')) {
      return 'Assignment due date and time must be after available date';
    }
    if (!this.isAssignmentPastDue() && !moment().isBefore(dueDate, 'minute')) {
      return 'Assignment due date and time must be after current time';
    }
    if (courseStartDate && moment(availableDate).isBefore(moment(courseStartDate), 'minute')) {
      return 'Assignment start date cannot be earlier than the Sherpath course start date';
    }
    return null;
  };

  handleDateTimeChange = (field: string, event: Event, value: string) => {
    const { assignment } = this.state;

    const newAssignment = produce(assignment, (draft) => {
      draft[field] = value;

      if (field === 'availableDate' && moment(value).isValid() && moment(value).isSameOrAfter(assignment.dueDate, 'minute')) {
        draft.dueDate = moment(value).add(1, 'd').toISOString();
      }
    });

    this.setState({
      assignment: newAssignment,
      isDirty: true
    });
  };

  handleTitleChange = ({ target }) => {
    this.setState((prevState) => ({
      assignment: {
        ...prevState.assignment,
        title: target.value
      },
      isDirty: true
    }));
  };

  isAssignmentVisible = () => moment().isSameOrAfter(this.state.initialAssignment.availableDate);

  isAssignmentPastDue = () => moment().isSameOrAfter(this.state.initialAssignment.dueDate);

  isDisableSaveButton = () => !this.state.isDirty || !!this.getDueDateError() || !this.state.assignment.title?.trim();

  getInfoText = () => (this.isAssignmentVisible() ? 'The assignment is visible, so you can no longer hide it.' : 'Once the assignment is visible, you can no longer hide it.');

  handleStudentSelectionChange = () => this.setState({ isDirty: true });

  handleObjectiveChange = (event) => {
    const objectiveInput = event.target.value;
    this.setState(
      produce((state: EditAssignmentState) => {
        state.assignment.objectives = objectiveInput;
        state.isDirty = true;
      })
    );
  };

  handleInstructionChange = (event) => {
    const instructionInput = event.target.value;
    this.setState(
      produce((state: EditAssignmentState) => {
        state.assignment.instructions = instructionInput;
        state.isDirty = true;
      })
    );
  };

  render() {
    const { assignment, courseStartDate, showStudentList } = this.state;
    return (
      <Box className="edit-assignment">
        <h2 className="edit-assignment__page-heading u-els-margin-top-2x u-els-margin-bottom-3x u-els-margin-left-1x1o2 u-els-margin-right-1x1o2 u-els-text-center">
          Edit Assignment
        </h2>
        <ELSFlex center column>
          <ELSFlexItem className="edit-assignment__content u-els-width-3o4">
            {assignment?.assignmentType === AssignmentType.EMPTY_EHR && (
              <>
                <Box className="u-els-margin-bottom">
                  <ObjectiveInput objective={assignment.objectives} onObjectiveChange={this.handleObjectiveChange} />
                </Box>
                <Box className="u-els-margin-top-2x">
                  <InstructionInput instruction={assignment.instructions} onInstructionChange={this.handleInstructionChange} />
                </Box>
              </>
            )}
            <SectionDivider />
            <DisplayName title={assignment.title} onChange={this.handleTitleChange} />
            <Box my2 className="c-els-divider" />
            <SchedulingWrapper title="Access and Visibility" infoText={this.getInfoText()}>
              <Box mt2>
                <Scheduling
                  availableDate={assignment.availableDate}
                  courseStartDate={courseStartDate}
                  dueDate={assignment.dueDate}
                  dueDateError={this.getDueDateError()}
                  onDateTimeChange={this.handleDateTimeChange}
                  availableDateLabel="Set a visibility date"
                  disableAvailableDate={this.isAssignmentVisible()}
                  disableDueDate={this.isAssignmentPastDue()}
                  isBoldLabel={false}
                />
              </Box>
            </SchedulingWrapper>
            {showStudentList && (
              <Box pb2>
                <StudentList
                  editMode
                  isPastDue={this.state.isPastDue}
                  assignedStudentIds={this.state.assignedStudentIds}
                  disabledIds={this.state.disabledIds}
                  onStudentSelectionChange={this.handleStudentSelectionChange}
                />
              </Box>
            )}
          </ELSFlexItem>
        </ELSFlex>
        <SaveCancelFooter onSave={this.updateAssignment} onCancel={() => navigationService.navigateToAppLinkSource(undefined)} disableSaveButton={this.isDisableSaveButton()} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  courseId: appSelectors.getCourseId(state),
  newSelectedStudentList: instructorSelectors.getNewAssignment(state).students,
  isAllStudents: instructorSelectors.getNewAssignment(state).isAllStudents
});

const mapDispatchToProps = (dispatch) => ({
  setIsEdit: (payload) => dispatch(instructorActions.setIsEdit(payload))
});

export { EditAssignment as BaseEditAssignment };
export default compose(withRouter, withFormUtilities, connect(mapStateToProps, mapDispatchToProps))(EditAssignment);

import { Component, ReactElement } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { User } from 'models/ui';
import { AppConstant } from 'constants/app.constant';
import { cookieService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { PageHeader, PageLoader } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import './instructor.page.wrapper.scss';

export interface InstructorPageWrapperProps {
  children(arg: { loggedInUser: User; userRole: string; intl: IntlShape }): ReactElement;
  loggedInUser: User;
  userRole: string;
  title: string;
  intl: IntlShape;
}

class InstructorPageWrapper extends Component<InstructorPageWrapperProps> {
  static displayName = 'InstructorPageWrapper';
  static defaultProps = {
    title: 'SimChart Assignment'
  };

  constructor(props) {
    super(props);
    cookieService.remove(AppConstant.COOKIE_IS_DEMO);
  }

  render() {
    const { title, loggedInUser, userRole, children, intl } = this.props;

    return (
      <>
        <PageLoader />
        <div className="instructor-page-wrapper">
          <PageHeader title={title} />
          <ELSFlex>
            <ELSFlexItem grow className="instructor-page-wrapper__content-wrapper">
              {children({ loggedInUser, userRole, intl })}
            </ELSFlexItem>
          </ELSFlex>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: appSelectors.getLoggedInUser(state),
  userRole: appSelectors.getUserRole(state)
});

const enhancers = [injectIntl, connect(mapStateToProps)];

export { InstructorPageWrapper as BaseInstructorPageWrapper };
export default compose(...enhancers)(InstructorPageWrapper);

import { ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const SafetyInterventionsView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;
  return (
    <div className="safety-interventions">
      <h1>{SectionTitle.SAFETY_INTERVENTIONS}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <h2>{SectionTitle.SAFETY_ISSUES}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SAFETY_ISSUES)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

SafetyInterventionsView.displayName = 'SafetyInterventionsView';
export default SafetyInterventionsView;

import { Component } from 'react';
import { defaultCustomRender } from 'helpers/chartRender.helper';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import { FormField, LaborProgressHistoryProps } from './constants';

class LaborProgressVaginalAssessmentHistoryTable extends Component<LaborProgressHistoryProps> {
  static displayName = 'LaborProgressVaginalAssessmentHistoryTable';

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const actionHeader = deleteHistory ? { field: 'actionDelete' } : {};
    const dataSource = {
      headers: [
        { text: 'Chart Time', field: 'chartingAt', sortable: true },
        { text: 'Cervical Dilation (cm)', field: 'cervicalDilation', customRender: defaultCustomRender(FormField.CERVICAL_DILATION) },
        { text: 'Effacement %', field: 'effacement', customRender: defaultCustomRender(FormField.EFFACEMENT) },
        { text: 'Station', field: 'station', customRender: defaultCustomRender(FormField.STATION) },
        { text: 'Position', field: 'position', customRender: defaultCustomRender(FormField.POSITION) },
        { text: 'Notes', field: 'notes', customRender: defaultCustomRender(FormField.NOTES) },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { ...actionHeader }
      ],
      css: ['column__chart-time', '', '', '', '', '', 'column__entry-by', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt className="labor-progress-history">
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

export default LaborProgressVaginalAssessmentHistoryTable;

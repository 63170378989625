export enum FormField {
  DISCONTINUED = 'discontinued',
  LOCATION = 'location',
  OSTOMY_TYPE = 'ostomyType',
  PATIENT_RESPONSE = 'patientResponse',
  DRESSING_AND_OSTOMY_CARE = 'dressingAndOstomyCare',
  DRAINAGE_FROM_OSTOMY = 'drainageFromOstomy',
  OSTOMY_PATENCY = 'ostomyPatency',
  PERI_STOMA_PERI_INSERTION = 'periStomaPeriInsertion',
  STOMA_CONDITION = 'stomaCondition',
  NOTES = 'notes'
}

export enum SectionTitle {
  OSTOMIES = 'Ostomies',
  PATIENT_RESPONSE = 'Patient Response',
  DRESSING_AND_OSTOMY_CARE = 'Dressing and Ostomy Care',
  DRAINAGE_FROM_OSTOMY = 'Drainage from Ostomy',
  OSTOMY_PATENCY = 'Ostomy Patency',
  PERI_STOMA_PERI_INSERTION = 'Peri-stoma - Peri-Insertion',
  STOMA_CONDITION = 'Stoma Condition',
  NOTES = 'Notes'
}

export enum FormFieldLabel {
  LOCATION = 'Location',
  OSTOMY_TYPE = 'Ostomy Type'
}

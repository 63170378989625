import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { Box, ChartActions, ErrorFormField, withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';

export const MISC_NURSING_NOTES = 'miscellaneousNursingNotes';
export const MISC_NURSING_NOTES_TITLE = 'Miscellaneous Nursing Notes';

class MiscellaneousNursingNotes extends Component<ChartComponentProps> {
  static displayName = MISC_NURSING_NOTES;

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const dataMap = new Map();
    dataMap.set(MISC_NURSING_NOTES, chartHelper.createFormField({ name: MISC_NURSING_NOTES, type: FormFieldInputType.TEXT_AREA, label: MISC_NURSING_NOTES_TITLE }));
    return dataMap;
  };

  handleSaveClick = () => {
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: MISC_NURSING_NOTES_TITLE,
      records: [
        chartHelper.buildSection({
          sectionTitle: MISC_NURSING_NOTES_TITLE,
          fields: [MISC_NURSING_NOTES],
          formFieldMap
        })
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: () => this.handleSaveClick()
    };
    return (
      <>
        <h1>{MISC_NURSING_NOTES_TITLE}</h1>

        <Box mt2>
          <Box mb2>
            <ErrorFormField formField={this.props.formFieldMap.get(MISC_NURSING_NOTES)} formSubmittedCount={this.props.formSubmittedCount} />
          </Box>
        </Box>
        <ChartActions {...chartActionsProps} />
      </>
    );
  }
}

export { MiscellaneousNursingNotes as BaseMiscellaneousNursingNotes };
export default compose(withSavedPatientChartsPage, withChartLogic)(MiscellaneousNursingNotes);

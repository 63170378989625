import { MedicalHistoryFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import './medical.history.scss';

export interface MedicalHistoryEditViewProps {
  selectedRecord: MedicalHistoryFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

const MedicalHistoryEditView = (props: MedicalHistoryEditViewProps) => (
  <div>
    <ErrorCard chartMetaFormFields={props.chartMetaFormFields} showErrorCard={props.formSubmittedCount > 0} />
    <Box mb2>
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.PATIENT_INFORMATION}</h2>
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CHIEF_INFORMANT)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.ALLERGIES)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CHIEF_COMPLAINT)} formSubmittedCount={props.formSubmittedCount} />
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.HISTORY_CURRENT_PROBLEM)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem className="family-history" w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FAMILY_HISTORY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
    </Box>

    <SectionDivider />

    <Box mb2>
      <h2 className="u-els-margin-bottom">{SectionTitle.PAST_MEDICAL_HISTORY}</h2>
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.PREVIOUS_ILLNESSES)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONTAGIOUS_DISEASES)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.INJURIES_TRAUMA)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SURGICAL_HISTORY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DIETARY_HISTORY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SOCIAL_HISTORY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CURRENT_MEDICATIONS)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.OTHER)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.BIRTH_HISTORY)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
    </Box>

    <SectionDivider />

    <Box>
      <h2 className="u-els-margin-bottom">{SectionTitle.IMMUNIZATIONS}</h2>
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem>
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.IMMUNIZATION_STATUS_HEPATITIS_B)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.ROTAVIRUS)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            className="special-field"
            formField={props.chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.INACTIVATED_POLIO_VIRUS)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.VARICELLA)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            className="special-field"
            formField={props.chartMetaFormFields.get(FormField.ZOSTER)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            formField={props.chartMetaFormFields.get(FormField.MEASLES_MUMPS_RUBELLA)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField
            className="special-field"
            formField={props.chartMetaFormFields.get(FormField.INFLUENZA)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.PNEUMOCOCCAL)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.HEPATITIS_A)} formSubmittedCount={props.formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField
            className="special-field"
            formField={props.chartMetaFormFields.get(FormField.MENINGOCOCCAL)}
            formSubmittedCount={props.formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.OTHER_IMMUNIZATIONS)} formSubmittedCount={props.formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
    </Box>

    <Box>
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONTAINER_GYNECOLOGICAL_HISTORY)} formSubmittedCount={props.formSubmittedCount}>
        <SectionDivider />
        <h2 className="u-els-margin-bottom">{SectionTitle.GYNECOLOGICAL_HISTORY}</h2>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField formField={props.chartMetaFormFields.get(FormField.LAST_PAP_SMEAR)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.HISTORY_OF_ABNORMAL_PAP)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.HISTORY_OF_ABNORMAL_PAP_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.UTERINE_ABNORMALITY_DES)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />

            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.UTERINE_ABNORMALITY_DES_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </ErrorFormField>
    </Box>

    <Box>
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONTAINER_MENSTRUAL)} formSubmittedCount={props.formSubmittedCount}>
        <SectionDivider />
        <h2 className="u-els-margin-bottom">{SectionTitle.MENSTRUAL}</h2>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.chartMetaFormFields.get(FormField.LAST_MENSTRUAL_PERIOD)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.chartMetaFormFields.get(FormField.MENARCHE_AGE_OF_ONSET)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONTRACEPTION_HISTORY)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DATE_OF_CONCEPTION)} formSubmittedCount={props.formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </ErrorFormField>
    </Box>

    <Box>
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CONTAINER_SEXUALLY_TRANSMITTED_DISEASE_HISTORY)} formSubmittedCount={props.formSubmittedCount}>
        <SectionDivider />
        <h2 className="u-els-margin-bottom">{SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY}</h2>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.GONORRHEA)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.GONORRHEA_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.CHLAMYDIA)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.CHLAMYDIA_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.CONGENITAL_HELPERS)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.CONGENITAL_HELPERS_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.SYPHILIS)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.SYPHILIS_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.HUMAN_IMMUNODEFICIENCY_VIRUS)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.HUMAN_IMMUNODEFICIENCY_VIRUS_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>

        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o1" md="1o1">
            <ErrorFormField
              formField={props.chartMetaFormFields.get(FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B)}
              formSubmittedCount={props.formSubmittedCount}
              options={{ displayHorizontal: true }}
              useDefaultMargin={false}
            />

            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ELSFlexItem w="1o1" md="1o1">
                <ErrorFormField
                  className="u-els-padding-top"
                  formField={props.chartMetaFormFields.get(FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B_DESCRIBE)}
                  formSubmittedCount={props.formSubmittedCount}
                  useDefaultMargin={false}
                />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </ErrorFormField>
    </Box>
    <ChartPermission fragmentType={props.selectedRecord?.fragmentType}>
      {({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}
    </ChartPermission>
  </div>
);

MedicalHistoryEditView.displayName = 'MedicalHistoryEditView';
export default MedicalHistoryEditView;

import { connect } from 'react-redux';
import { compose } from 'recompose';
import { SkinnyAssignment } from 'models/ui';
import { studentSelectors } from 'redux/ducks/student';
import { withHTMLHeadSEO } from 'components/common';
import StudentHome from './StudentHome';

export interface StudentHomePageProps {
  selectedAssignment: SkinnyAssignment;
}

const StudentHomePage = (props: StudentHomePageProps) => <StudentHome {...props} />;

const mapStateToProps = (state) => ({
  selectedAssignment: studentSelectors.getAssignment(state)
});

export { StudentHomePage as BaseStudentHomePage };
export default compose(withHTMLHeadSEO({ title: 'Student Homepage' }), connect(mapStateToProps))(StudentHomePage);

import { LanguageKeys } from 'lang';
import Helmet from 'react-helmet';
import { IntlShape } from 'react-intl';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { Box, ChartActions, ErrorFormField } from 'components/common';
import { FormField } from './constants';

export interface SBARReportViewProps {
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  intl: IntlShape;
  locale: Locales;
}

const SBARReportView = (props: SBARReportViewProps) => (
  <>
    {/* TODO: We should make the title can be changed easily. */}
    <Helmet title={props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.SBAR_REPORT })}>
      <html lang="en" />
    </Helmet>

    <h1>{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.SBAR_REPORT })}</h1>

    <Box mt2>
      {props.locale === Locales.EN_AU && (
        <Box mb2>
          <h2 className="u-els-margin-bottom-2x">{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.IDENTIFY })}</h2>
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.IDENTIFY_INTRODUCE)} formSubmittedCount={props.formSubmittedCount} />
        </Box>
      )}
      <Box mb2>
        <h2 className="u-els-margin-bottom-2x">{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.SITUATION })}</h2>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SUMMARY_SITUATION)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      {props.locale === Locales.EN_AU && (
        <Box mb2>
          <h2 className="u-els-margin-bottom-2x">{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.OBSERVATIONS })}</h2>
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.OBSERVATIONS_MOST_RECENT)} formSubmittedCount={props.formSubmittedCount} />
        </Box>
      )}
      <Box mb2>
        <h2 className="u-els-margin-bottom-2x">{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.BACKGROUND })}</h2>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.PERTINENT_BACKGROUND_SITUATION)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb2>
        <h2 className="u-els-margin-bottom-2x">{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.ASSESSMENT })}</h2>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.ASSESSMENT_SITUATION)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
      <Box mb2>
        <h2 className="u-els-margin-bottom-2x">{props.intl.formatMessage({ id: LanguageKeys.SBAR_REPORT.RECOMMENDATION })}</h2>
        <ErrorFormField formField={props.chartMetaFormFields.get(FormField.REC_REQUEST_FOR_ORDERS)} formSubmittedCount={props.formSubmittedCount} />
      </Box>
    </Box>
    <ChartActions {...props.chartActionsProps} />
  </>
);

SBARReportView.displayName = 'SBARReportView';
export default SBARReportView;

import produce from 'immer';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { AllergyRecord, ChartFragment, HomeMedicationRecord, Section } from 'models/api-response';
import { EventType, FormFieldInputType, FragmentType } from 'models/enum';
import { Author, ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, DropDownItem, TimeSpentPartial } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, assignmentHelper, chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { compose } from 'redux';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { SectionTitle as AllergySectionTitle } from 'components/features/chart/patient-data/constants';
import { FormField, HomeMedicationType, SectionTitle } from './constants';
import MedicationReconciliationView, { MedicationReconciliationViewProps, UserData } from './MedicationReconciliationView';

interface MedicationReconciliationState {
  hasEdit: boolean;
  hasDiscardChange: boolean;
  loadedFragment: ChartFragment;
  allergyData: AllergyRecord[];
  showAllergyInformation: boolean;
  showHomeMedicationsInformation: boolean;
  prescriptionMedicationsData: HomeMedicationRecord[];
  remedyProductsData: HomeMedicationRecord[];
  counterProductsData: HomeMedicationRecord[];
  fragmentChangedValue: ChartFragment[];
  fragments: ChartFragment[];
  authors: Author[];
  authorOptions: DropDownItem[];
  [FormField.PROVIDER_SIGNATURE_ID]: number;
  [FormField.MEDICATIONS_REVIEWER_ID]: number;
}

interface MedicationReconciliationProps extends ChartComponentProps {
  showConfirmationModal: Function;
  timeSpentData: TimeSpentPartial;
}

export const buildFormFields = (fragment?: ChartFragment, isAuthor?: boolean): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();
  const textBoxes = [
    { name: FormField.MEDICATION_ONE },
    { name: FormField.MEDICATION_TWO },
    { name: FormField.MEDICATION_THREE },
    { name: FormField.MEDICATION_FOUR },
    { name: FormField.DIRECTION_ONE },
    { name: FormField.DIRECTION_TWO },
    { name: FormField.DIRECTION_THREE },
    { name: FormField.DIRECTION_FOUR },
    { name: FormField.INDICATION_ONE },
    { name: FormField.INDICATION_TWO },
    { name: FormField.INDICATION_THREE },
    { name: FormField.INDICATION_FOUR },
    { name: FormField.SPECIAL_INSTRUCTION_ONE },
    { name: FormField.SPECIAL_INSTRUCTION_TWO },
    { name: FormField.SPECIAL_INSTRUCTION_THREE },
    { name: FormField.SPECIAL_INSTRUCTION_FOUR }
  ];
  textBoxes.forEach((item) =>
    dataMap.set(
      item.name,
      createFormField({
        name: item.name,
        type: FormFieldInputType.TEXT_BOX,
        value: chartHelper.getFragmentValue(fragment, SectionTitle.MEDICATION_RECONCILIATION, item.name, '')
      })
    )
  );

  if (isAuthor) {
    dataMap.set(
      FormField.MEDICATIONS_REVIEWED_TIME,
      createFormField({
        name: FormField.MEDICATIONS_REVIEWED_TIME,
        type: FormFieldInputType.TEXT_BOX,
        value: '00:00'
      })
    );
    dataMap.set(
      FormField.PROVIDER_SIGNATURE_TIME,
      createFormField({
        name: FormField.PROVIDER_SIGNATURE_TIME,
        type: FormFieldInputType.TEXT_BOX,
        value: '00:00'
      })
    );
  }

  return dataMap;
};

class MedicationReconciliation extends Component<MedicationReconciliationProps, MedicationReconciliationState> {
  static displayName = 'MedicationReconciliation';

  constructor(props) {
    super(props);
    this.state = {
      hasEdit: false,
      hasDiscardChange: false,
      loadedFragment: null,
      allergyData: [],
      showAllergyInformation: true,
      showHomeMedicationsInformation: true,
      prescriptionMedicationsData: [],
      counterProductsData: [],
      remedyProductsData: [],
      fragmentChangedValue: [],
      fragments: [],
      authors: [],
      authorOptions: [],
      [FormField.PROVIDER_SIGNATURE_ID]: 0,
      [FormField.MEDICATIONS_REVIEWER_ID]: 0
    };
  }

  componentDidMount() {
    this.loadChartData();
    this.props.setCustomUnsavedChanges(() => {
      return this.state.hasEdit || !isEmpty(this.state.fragmentChangedValue);
    });
  }

  componentDidUpdate(prevProps: Readonly<MedicationReconciliationProps>) {
    const hasEdit = !this.state.hasEdit && prevProps.formFieldMap.size > 0 && !isEqual(this.props.formFieldMap, prevProps.formFieldMap);
    if (hasEdit && !this.state.hasDiscardChange) {
      this.setState({ hasEdit });
    }
    if (hasEdit && this.state.hasDiscardChange) {
      this.setState({ hasDiscardChange: false });
    }
  }

  loadChartData = () => {
    const fetchAuthors = this.props.isAuthor ? this.fetchAuthors() : Promise.resolve();
    appHelper.useLoader(Promise.all([this.loadFragmentData(), this.loadHomeMedicationData(), fetchAuthors]), {
      errorMessage: 'Can not load medication reconciliation data'
    });
  };

  checkResumeValue = (fragmentChangedValue, fragments): boolean => {
    return (
      fragmentChangedValue.chartData.resumeOnDischarge === fragments.chartData.resumeOnDischarge &&
      fragmentChangedValue.chartData.resumeOnAdmit === fragments.chartData.resumeOnAdmit
    );
  };

  checkFragmentHaveChange = ({ fragmentChangedValue, fragments, rowRecord, fieldChangeName, value }) => {
    const cloneFragmentChangedValue = [...fragmentChangedValue];
    const fragmentChangeIndex = fragmentChangedValue.findIndex((fragment) => fragment.fragmentId === rowRecord.fragmentId);
    const fragmentIndex = fragments.findIndex((fragment) => fragment.fragmentId === rowRecord.fragmentId);
    if (fragmentChangeIndex === -1) {
      cloneFragmentChangedValue.push({ ...fragments[fragmentIndex], chartData: { ...fragments[fragmentIndex].chartData, [fieldChangeName]: value } });
    } else {
      cloneFragmentChangedValue[fragmentChangeIndex].chartData[fieldChangeName] = value;
      if (this.checkResumeValue(cloneFragmentChangedValue[fragmentChangeIndex], fragments[fragmentIndex])) {
        cloneFragmentChangedValue.splice(fragmentChangeIndex, 1);
      }
    }
    return cloneFragmentChangedValue;
  };

  changeHandler = ({ rowRecord, homeMedicationType, fieldChangeName, value }) => {
    this.setState(
      produce((state) => {
        const { fragments } = state;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of state[homeMedicationType]) {
          if (item.fragmentId === rowRecord.fragmentId) {
            state.fragmentChangedValue = this.checkFragmentHaveChange({ fragmentChangedValue: state.fragmentChangedValue, fragments, rowRecord, fieldChangeName, value });
            // eslint-disable-next-line no-param-reassign
            item[fieldChangeName] = value;
            break;
          }
        }
      })
    );
  };

  loadHomeMedicationData = () => {
    return this.props.loadChartData(undefined, [NAV_ID.PATIENT_DATA_HOME_MEDICATION, NAV_ID.HOME_MEDICATION]).then(({ data }) => {
      const orderFragments = orderBy(data, ['chartingAt'], ['asc']);
      const { prescriptionMedicationsData, counterProductsData, remedyProductsData } = this.prepareHomeMedicationData(orderFragments);
      this.setState({ prescriptionMedicationsData, counterProductsData, remedyProductsData, fragments: orderFragments });
    });
  };

  prepareHomeMedicationData = (fragments: ChartFragment[]) => {
    const prescriptionMedicationsData = [];
    const counterProductsData = [];
    const remedyProductsData = [];

    fragments.forEach((fragment: HomeMedicationRecord) => {
      const { fragmentId, active, chartingAt, creator, modifier, chartData } = fragment;
      if (chartData.homeMedicationType === HomeMedicationType.PRESCRIPTION_MEDICATIONS) {
        prescriptionMedicationsData.push({ fragmentId, active, chartingAt, creator, modifier, ...chartData });
      } else if (chartData.homeMedicationType === HomeMedicationType.OVER_THE_COUNTER_PRODUCTS) {
        counterProductsData.push({ fragmentId, active, chartingAt, creator, modifier, ...chartData });
      } else if (chartData.homeMedicationType === HomeMedicationType.HERBAL_AND_NATURAL_REMEDY_PRODUCTS) {
        remedyProductsData.push({ fragmentId, active, chartingAt, creator, modifier, ...chartData });
      }
    });

    return { prescriptionMedicationsData, counterProductsData, remedyProductsData };
  };

  loadFragmentData = () => {
    return this.props.loadChartData([FragmentType.AUTHORED, FragmentType.CHARTING], [NAV_ID.MEDICATION_RECONCILIATION, NAV_ID.ALLERGIES]).then(({ data: fragments }) => {
      const medicationFragments = fragments.filter((fragment) => fragment.active && fragment.navElementId === NAV_ID.MEDICATION_RECONCILIATION);
      const allergyFragments = fragments
        .filter((fragment) => fragment.navElementId === NAV_ID.ALLERGIES)
        .map((fragment) => {
          const allergen = chartHelper.getFragmentRecords(fragment, AllergySectionTitle.ALLERGIES, 'allergen')[0]?.content;
          const allergenOther = chartHelper.getFragmentRecords(fragment, AllergySectionTitle.ALLERGIES, 'allergenOther')[0]?.content;
          const medication = chartHelper.getFragmentRecords(fragment, AllergySectionTitle.ALLERGIES, 'medication')[0]?.content;
          const allergenMedicationOther = chartHelper.getFragmentRecords(fragment, AllergySectionTitle.ALLERGIES, 'allergenMedicationOther')[0]?.content;
          const finalAllergen = allergenOther || allergen || allergenMedicationOther || medication;
          return {
            allergen: finalAllergen,
            active: fragment.active,
            reactions: chartHelper
              .getFragmentRecords(fragment, AllergySectionTitle.ALLERGIES, FormField.REACTIONS)
              .filter((item) => item.title !== 'Other')
              .map((item) => item.title)
          };
        });

      let latestFragment = null;
      if (medicationFragments.length !== 0) {
        [latestFragment] = medicationFragments;
      }
      this.setState({ loadedFragment: latestFragment, allergyData: allergyFragments, fragmentChangedValue: [] });
      this.props.initState(buildFormFields(latestFragment, this.props.isAuthor));
    });
  };

  buildFragment = () => {
    const { buildRecordFromField, createBaseFragment, getChartingTime } = chartService;
    const { formFieldMap } = this.props;
    const fragment = this.state.loadedFragment
      ? { ...this.state.loadedFragment, chartingAt: getChartingTime(this.props.chartingTime) }
      : createBaseFragment({ chartingTime: this.props.chartingTime });
    const getCreator = (authorId: number) => this.state.authors.find((author) => author.id === authorId);
    const record = {
      chartTitle: MedicationReconciliation.displayName,
      fragmentTitle: MedicationReconciliation.displayName,
      records: this.createSections(),
      [FormField.MEDICATIONS_REVIEWED_CREATOR]: getCreator(Number(this.state[FormField.MEDICATIONS_REVIEWER_ID])),
      [FormField.MEDICATIONS_REVIEWED_TIME]: buildRecordFromField(formFieldMap.get(FormField.MEDICATIONS_REVIEWED_TIME))?.content,
      [FormField.PROVIDER_SIGNATURE_CREATOR]: getCreator(Number(this.state[FormField.PROVIDER_SIGNATURE_ID])),
      [FormField.PROVIDER_SIGNATURE_TIME]: buildRecordFromField(formFieldMap.get(FormField.PROVIDER_SIGNATURE_TIME))?.content
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    return { ...fragment, chartData: cleanRecord } as ChartFragment;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({
        sectionTitle: SectionTitle.MEDICATION_RECONCILIATION,
        fields: [
          FormField.MEDICATION_ONE,
          FormField.MEDICATION_TWO,
          FormField.MEDICATION_THREE,
          FormField.MEDICATION_FOUR,
          FormField.DIRECTION_ONE,
          FormField.DIRECTION_TWO,
          FormField.DIRECTION_THREE,
          FormField.DIRECTION_FOUR,
          FormField.INDICATION_ONE,
          FormField.INDICATION_TWO,
          FormField.INDICATION_THREE,
          FormField.INDICATION_FOUR,
          FormField.SPECIAL_INSTRUCTION_ONE,
          FormField.SPECIAL_INSTRUCTION_TWO,
          FormField.SPECIAL_INSTRUCTION_THREE,
          FormField.SPECIAL_INSTRUCTION_FOUR
        ],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    const { fragments, fragmentChangedValue } = this.state;
    const { isAuthor, saveMultiFragments, selectedNavId, timeSpentData } = this.props;
    const medicationReconciliationFragment = this.buildFragment();
    if (!isAuthor) {
      assignmentHelper.recordTimeSpent(timeSpentData, EventType.END);
    }
    medicationReconciliationFragment.navElementId = selectedNavId;
    let saveHomeMedicationFragments = fragmentChangedValue.map((fragment) => ({ ...fragment }));
    if (isAuthor) {
      // in author flow when we save fragments it will overwrite previous data
      // so when we save we have to save all fragments at once instead of just changed fragment
      // so we create an allFragments array which is the combination of changed and unchanged fragments
      const allFragments = [];
      fragments.forEach((fragment) => {
        let saveFragment;
        const updatedFragment = fragmentChangedValue.find((changedFragment) => changedFragment.fragmentId === fragment.fragmentId);
        if (updatedFragment) {
          saveFragment = updatedFragment;
        } else {
          saveFragment = fragment;
        }
        allFragments.push(saveFragment);
      });
      saveHomeMedicationFragments = allFragments;
    }
    return this.setState({ hasEdit: false }, () =>
      appHelper
        .useLoader(
          saveMultiFragments([...saveHomeMedicationFragments, medicationReconciliationFragment]).then(() => {
            this.props.showSaveSuccess();
            appHelper.scrollTop();
          }),
          {
            errorMessage: 'Can not save medication reconciliation data'
          }
        )
        .then(this.loadChartData)
    );
  };

  getUserData = (): UserData => {
    const { loadedFragment, fragments } = this.state;
    let medicationReviewBy = '';
    let informationObtainedBy = '';
    let individualProvidingInformation = '';
    let providerSignature = '';
    if (loadedFragment !== null) {
      const getMedicationReviewName = (creator) => chartService.formatName(creator || this.state.loadedFragment.creator);
      const getProviderSignatureName = (creator) => chartService.formatName(creator || '');
      const getDateTime = (dateTime) => dateTimeHelper.toDateTime(dateTime || this.state.loadedFragment.chartingAt);
      const providerSignatureDateTime = this.state.loadedFragment.chartData.providerSignatureTime
        ? `${this.state.loadedFragment.chartData.providerSignatureDate} ${this.state.loadedFragment.chartData.providerSignatureTime}`
        : '';
      medicationReviewBy = `${getMedicationReviewName(this.state.loadedFragment.chartData.reviewedByCreator)} ${getDateTime(
        this.state.loadedFragment.chartData.reviewedByDateTime
      )}`;
      providerSignature = `${getProviderSignatureName(this.state.loadedFragment.chartData.providerSignatureCreator)} ${providerSignatureDateTime}`;
    }
    const latestFragment = fragments[fragments.length - 1];

    if (latestFragment) {
      individualProvidingInformation = isEmpty(latestFragment.chartData.informationReceivedFromOther)
        ? latestFragment.chartData.informationReceivedFrom.split('|').join(', ')
        : latestFragment.chartData.informationReceivedFromOther;
      let { creator } = latestFragment;
      if (this.props.isAuthor) {
        creator = this.state.authors.find((author) => author.id === latestFragment.authorId) ?? latestFragment.creator;
      }
      informationObtainedBy = chartService.formatName(creator);
    }

    return {
      individualProvidingInformation,
      informationObtainedBy,
      medicationReviewBy,
      providerSignature
    };
  };

  handleCancelClick = () => {
    const { prescriptionMedicationsData, counterProductsData, remedyProductsData } = this.prepareHomeMedicationData(this.state.fragments);
    this.setState({ prescriptionMedicationsData, counterProductsData, remedyProductsData, hasEdit: false, hasDiscardChange: true, fragmentChangedValue: [] });
  };

  fetchAuthors = () =>
    chartService.loadAuthors().then(({ data }) => {
      let authorOptions = data.map((item) => ({ name: `${item.firstName} ${item.lastName}`, value: item.id }));
      authorOptions = orderBy(authorOptions, ['name'], ['asc']);
      this.setState({ authors: data, [FormField.MEDICATIONS_REVIEWER_ID]: authorOptions[0].value, [FormField.PROVIDER_SIGNATURE_ID]: authorOptions[0].value, authorOptions });
    });

  handleFieldChange = (formFieldId: string, value: string) =>
    this.setState(
      produce((state) => {
        state[formFieldId] = value;
      })
    );

  render() {
    const { showAllergyInformation, showHomeMedicationsInformation, authorOptions } = this.state;
    const enableSave =
      this.state.hasEdit || !isEmpty(this.state.fragmentChangedValue) || this.state[FormField.MEDICATIONS_REVIEWER_ID] !== 0 || this.state[FormField.PROVIDER_SIGNATURE_ID] !== 0;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick, buildFormFields(this.state.loadedFragment, this.props.isAuthor)),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: enableSave,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };
    const viewProps: MedicationReconciliationViewProps = {
      isAuthor: this.props.isAuthor,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      allergyData: this.state.allergyData,
      showAllergyInformation,
      prescriptionMedicationsData: this.state.prescriptionMedicationsData,
      counterProductsData: this.state.counterProductsData,
      remedyProductsData: this.state.remedyProductsData,
      userData: this.getUserData(),
      showHomeMedicationsInformation,
      chartActionsProps,
      changeHandler: this.changeHandler,
      authorOptions,
      onFieldChange: this.handleFieldChange
    };
    return <MedicationReconciliationView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  timeSpentData: appSelectors.getTimeSpentData(state)
});

export { MedicationReconciliation as BaseMedicationReconciliation };
export default compose(withChartLogic, connect(mapStateToProps))(MedicationReconciliation);

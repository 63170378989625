import { delay } from 'lodash';
import { Component } from 'react';
import { AssessmentRS } from 'models/api-response';
import { REDIRECT_DELAY_TIME } from 'constants/app.constant';
import { navigationService } from 'services';
import { withFormUtilities } from 'components/common';

interface WithSavedPatientChartsPageProps {
  assessment: AssessmentRS;
  isAuthor: boolean;
  isEmbeddedChart?: boolean;
  selectedNavId: string;
}

const withSavedPatientChartsPage = (WrappedComponent) => {
  class WithSavedPatientChartsPage extends Component<WithSavedPatientChartsPageProps> {
    navigateToSavedPatientChartingWithDelay = () => delay(this.navigateToSavedPatientCharting, REDIRECT_DELAY_TIME);

    navigateToSavedPatientCharting = () => {
      if (!this.props.isAuthor && !this.props.isEmbeddedChart) {
        navigationService.navigateToSavedPatientCharting(this.props.assessment.eolsAssessmentId, this.props.selectedNavId);
      }
    };

    render() {
      return <WrappedComponent navigateToSavedPatientCharting={this.navigateToSavedPatientChartingWithDelay} {...this.props} />;
    }
  }

  return withFormUtilities(WithSavedPatientChartsPage);
};

export default withSavedPatientChartsPage;

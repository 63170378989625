import { Box, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import './blank.charting.content.view.scss';
import InstructionInput from '../../instruction-input/InstructionInput';
import ObjectiveInput from '../../objective-input/ObjectiveInput';

export interface BlankChartingContentViewProps {
  objectives: string;
  instructions: string;
  onObjectiveChange: Function;
  onInstructionChange: Function;
}

const BlankChartingContentView = (props: BlankChartingContentViewProps) => (
  <ELSFlex column center className="step2-section-content">
    <ELSFlexItem w="3o4" middle>
      <Box className="u-els-margin-bottom">
        <ObjectiveInput objective={props.objectives} onObjectiveChange={props.onObjectiveChange} />
      </Box>
      <Box className="u-els-margin-top-2x">
        <InstructionInput instruction={props.instructions} onInstructionChange={props.onInstructionChange} />
      </Box>
    </ELSFlexItem>
    <ELSFlexItem w="3o4" middle>
      <SectionDivider />
    </ELSFlexItem>
  </ELSFlex>
);

BlankChartingContentView.displayName = 'BlankChartingContentView';
export default BlankChartingContentView;

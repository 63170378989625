/* eslint-disable import/no-webpack-loader-syntax */

/* eslint-disable @typescript-eslint/no-var-requires */
import cx from 'classnames';
import { SuccessIcon } from 'components/common';
import { ELSButton, ELSFlex } from 'components/common/els';
import './modal.scss';

export interface SuccessModalAction {
  id?: string;
  text: string;
  type?: 'primary' | 'secondary' | 'tertiary' | 'info' | 'warn' | 'confirm' | 'link' | 'debuttonize';
  onClick: Function;
}
export interface SuccessModalProps {
  showClose: boolean;
  message: string;
  actions: SuccessModalAction[];
  className?: string;
}

const SuccessModal = (props: SuccessModalProps) => (
  <div className={cx('success-modal', props.className)}>
    <h1>{props.message}</h1>
    <ELSFlex className="u-els-margin-top-2x" middle>
      <SuccessIcon className="success-modal__icon" />
    </ELSFlex>
    <ELSFlex className="u-els-margin-top-3x" column middle>
      {props.actions.map((action: SuccessModalAction, index) => (
        <ELSButton
          className={cx('success-modal__button', { 'u-els-margin-top': index > 0 })}
          id={action.id}
          key={action.id || action.text}
          text={action.text}
          type={action.type}
          onClick={action.onClick}
        />
      ))}
    </ELSFlex>
  </div>
);

SuccessModal.displayName = 'SuccessModal';
export default SuccessModal;

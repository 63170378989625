import produce from 'immer';
import { LanguageKeys } from 'lang';
import { camelCase } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { LaboratoryHistoryRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, ExternalReference } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField } from './constants';
import LaboratorySidebarView from './LaboratorySidebarView';
import { getLaboratorySidebarFormFieldBuilderItems } from './helper';
import { FormField as CommonFormField } from '../shared/constants';

export interface LaboratorySidebarProps extends ChartComponentProps {
  laboratoryRecord: LaboratoryHistoryRecord;
  onCloseClick: Function;
  orderDescriptionField: ChartMetaFormField;
  onLaboratoryUpdate: Function;
}
export interface LaboratorySidebarState {
  referenceFields: ExternalReference[];
  sidebarName: string;
  isReadOnly: boolean;
}

class LaboratorySidebar extends Component<LaboratorySidebarProps, LaboratorySidebarState> {
  static displayName = 'LaboratorySidebar';

  constructor(props) {
    super(props);
    this.state = {
      referenceFields: [],
      sidebarName: '',
      isReadOnly: false
    };
  }

  componentDidUpdate(prevProps: Readonly<LaboratorySidebarProps>) {
    if (this.props.laboratoryRecord && prevProps.laboratoryRecord !== this.props.laboratoryRecord) {
      const labContentId = this.props.laboratoryRecord?.chartData?.laboratoryTest.contentId;
      const labContent = this.props.orderDescriptionField.chartContent.find((item) => item.id === labContentId);
      const sidebarName = this.props.laboratoryRecord?.chartData.laboratoryTest.content;
      const isReadOnly = this.props.laboratoryRecord?.chartData[FormField.STATUS] === LanguageKeys.LABORATORY.COMPLETED;
      this.props.initState(this.buildFormFields(labContent.referenceFields));
      this.setState({
        referenceFields: labContent.referenceFields,
        sidebarName,
        isReadOnly
      });
    }
  }

  buildFormFields = (referenceFields?: ExternalReference[]): Map<string, ChartMetaFormField> => {
    const { intl, isAuthor } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getLaboratorySidebarFormFieldBuilderItems(isAuthor, referenceFields).forEach(({ label, isHidden, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ label: label && intl.formatMessage({ id: label }), ...item }));
      }
    });

    return dataMap;
  };

  handleSaveDataClick = () => {
    const { formFieldMap, laboratoryRecord, handleSaveClick, onLaboratoryUpdate } = this.props;
    const { buildPatientRecord } = chartService;
    const updatedData = {};
    this.state.referenceFields.forEach((field) => {
      const fieldName = camelCase(field.label);
      updatedData[fieldName] = buildPatientRecord(formFieldMap, fieldName);
    });
    updatedData[FormField.STATUS] = LanguageKeys.LABORATORY.COMPLETED;
    updatedData[FormField.RESULTS_RECEIVED_DATE] = buildPatientRecord(formFieldMap, FormField.RESULTS_RECEIVED_DATE);
    updatedData[FormField.RESULTS_RECEIVED_TIME] = buildPatientRecord(formFieldMap, FormField.RESULTS_RECEIVED_TIME);
    updatedData[CommonFormField.RESULTS_RECEIVED_TIME_OFFSET] = buildPatientRecord(formFieldMap, CommonFormField.RESULTS_RECEIVED_TIME_OFFSET);
    const updatedFragment: LaboratoryHistoryRecord = produce(laboratoryRecord, (draft) => {
      draft.chartData = { ...draft.chartData, ...updatedData };
      draft.updatedAt = moment().toISOString();
    });
    handleSaveClick([updatedFragment], { afterSave: onLaboratoryUpdate });
  };

  render() {
    const { formFieldMap, onCloseClick, laboratoryRecord, formSubmittedCount, sidebarProps } = this.props;
    const { referenceFields, sidebarName, isReadOnly } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveDataClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildFormFields(referenceFields), { selectorToScroll: '.sidebar__container' })
    };
    const viewProps = {
      openSidebar: !!laboratoryRecord,
      formSubmittedCount,
      formFieldMap,
      isReadOnly,
      laboratoryRecord,
      chartActionsProps,
      onCloseClick,
      referenceFields,
      sidebarName,
      sidebarProps
    };
    return <LaboratorySidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state),
  isAuthor: appSelectors.getIsAuthor(state)
});

export { LaboratorySidebar as BaseLaboratorySidebar };
export default compose(connect(mapStateToProps), withChartLogic)(LaboratorySidebar);

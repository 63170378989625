import { ValidationActionType } from 'models/enum';
import DisableHandler from './DisableHandler';
import ErrorHandler from './ErrorHandler';
import HideContentHandler from './HideContentHandler';
import HideHandler from './HideHandler';
import PrefillHandler from './PrefillHandler';
import RequireHandler from './RequireHandler';
import WarningHandler from './WarningHandler';
import { ExecuteParams } from './interface';

export const VALIDATION_SET_ACTION = 'VALIDATION_SET_ACTION';
export const VALIDATION_PREFILL = 'VALIDATION_PREFILL';

const MAPPING = {
  [ValidationActionType.HIDE]: new HideHandler(),
  [ValidationActionType.DISABLE]: new DisableHandler(),
  [ValidationActionType.ERROR]: new ErrorHandler(),
  [ValidationActionType.WARNING]: new WarningHandler(),
  [ValidationActionType.REQUIRED]: new RequireHandler(),
  [ValidationActionType.PREFILL]: new PrefillHandler(),
  [VALIDATION_SET_ACTION]: new HideContentHandler(true)
};
const resultHandlerManager = {
  getResultHandler(actionType: string) {
    return { handler: MAPPING[actionType], execute: this.execute };
  },

  execute(executeParams: ExecuteParams) {
    if (!this.handler) return executeParams.field;

    return this.handler.execute(executeParams);
  }
};

export default resultHandlerManager;

import { IntlShape } from 'react-intl';
import { BloodGlucoseRecord, ChartFragment, VitalSignsRecord } from 'models/api-response';
import { DropDownItem, IntakeOutputTotalPerDayItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { HistoryLineChartProps } from 'components/common/chart/line/HistoryLineChart';

export enum VitalSignChartField {
  TEMPERATURE = 'temperature',
  PULSE = 'pulse',
  RESPIRATION = 'respiration',
  BLOODPRESSURE = 'bloodPressure',
  OXYGENATION = 'oxygenation'
}

export enum IntakeOutputType {
  INTAKE = 'intake',
  OUTPUT = 'output'
}

export enum ChartHistoryTitle {
  SUMMARY_HISTORY = ''
}

export interface TemperatureChartData {
  date: string;
  dateTime: string;
  temperature: number;
  temperatureUnit: string;
}
export interface PulseChartData {
  date: string;
  dateTime: string;
  pulse: number;
  pulseUnit: string;
}
export interface RespirationChartData {
  date: string;
  dateTime: string;
  respiration: number;
  respirationUnit: string;
}
export interface BloodPressureChartData {
  date: string;
  dateTime: string;
  systolic: number;
  diastolic: number;
  systolicUnit: string;
  diastolicUnit: string;
}
export interface OxygenationChartData {
  date: string;
  dateTime: string;
  saturation: number;
  saturationUnit: string;
}

export interface TemperatureChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: TemperatureChartData[];
}
export interface PulseChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: PulseChartData[];
}
export interface RespirationChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: RespirationChartData[];
}
export interface BloodPressureChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: BloodPressureChartData[];
}
export interface OxygenationChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: OxygenationChartData[];
}

export interface VitalSignChartConfig {
  [VitalSignChartField.TEMPERATURE]: TemperatureChartProps;
  [VitalSignChartField.PULSE]: PulseChartProps;
  [VitalSignChartField.RESPIRATION]: RespirationChartProps;
  [VitalSignChartField.BLOODPRESSURE]: BloodPressureChartProps;
  [VitalSignChartField.OXYGENATION]: OxygenationChartProps;
}

export interface PainAssessmentCustomRecord {
  numericPainLevelOnMovement: string;
  numericPainLevel: string;
  chartingAt: string;
}

export interface SummaryState {
  selectedVitalSignChart: string;
  vitalSignsHistory: VitalSignsRecord[];
  intakeOutputTotalPerDayLatest: IntakeOutputTotalPerDayItem;
  bloodGlucoseLatest: BloodGlucoseRecord;
  painAssessmentLatest: PainAssessmentCustomRecord;
}

export interface SummaryViewProps {
  loadData: () => Promise<void>;
  vitalSignChartConfig: TemperatureChartProps | PulseChartProps | RespirationChartProps | BloodPressureChartProps | OxygenationChartProps;
  vitalSignCharts: DropDownItem[];
  vitalSignsHistoryFragments: ChartFragment[];
  selectedVitalSignChart: string;
  intakeOutputTotalPerDayLatest: IntakeOutputTotalPerDayItem;
  bloodGlucoseLatest: BloodGlucoseRecord;
  painAssessmentLatest: PainAssessmentCustomRecord;
  onChartChange: Function;
  onNavigation: Function;
  chartHistory: VitalSignsRecord[];
  tableTitle: string;
  locale: Locales;
  intl: IntlShape;
}

export enum SectionTitle {
  POSTPARTUM_INTERVENTIONS = 'Postpartum Interventions',
  POSTPARTUM_CARE = 'Postpartum Care',
  PATIENT_RESPONSE = 'Patient Response',
  NEWBORN_CARE = 'Newborn Care',
  MOTHER_NEWBORN_RESPONSE = 'Mother/Newborn Response',
  POSTPARTUM_CARE_NOTES = 'Postpartum Care Notes'
}

export enum FormField {
  NO_CARE_REQUIRED = 'noCareRequired',
  POSTPARTUM_CARE = 'postpartumCare',
  PATIENT_RESPONSE = 'patientResponse',
  NEWBORN_CARE = 'newbornCare',
  MOTHER_NEWBORN_RESPONSE = 'motherNewbornResponse',
  POSTPARTUM_CARE_NOTES = 'postpartumCareNotes'
}

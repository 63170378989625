import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { NavigationItem, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withFormUtilities } from 'components/common';
import AdmissionHistoryView from './AdmissionHistoryView';
import { FormField, SectionTitle } from '../allergy-information/constants';

export interface LandingChart {
  navId: string;
  name: string;
  hasData: boolean;
  shown: boolean;
  routePath: string;
  fragment?: ChartFragment;
  chartHistory?: ChartFragment[];
}

interface AdmissionHistoryProps {
  assessment: AssessmentRS;
  chartingTime: string;
  isAssessmentOwner: boolean;
  isAssessmentSubmitted: boolean;
  showConfirmationModal: Function;
  showSuccessModal: Function;
  hideSuccessModal: Function;
  menuItems: NavigationItem[];
  hasUnsavedChanges: boolean;
  patientContext: PatientContext;
  intl: IntlShape;
}

interface AdmissionHistoryState {
  isChartLocked: boolean;
  inViewMode: boolean;
  landingFragment: ChartFragment;
  charts: LandingChart[];
}

class AdmissionHistory extends Component<AdmissionHistoryProps, AdmissionHistoryState> {
  static displayName = 'AdmissionHistory';

  constructor(props) {
    super(props);
    this.state = {
      isChartLocked: false,
      inViewMode: false,
      landingFragment: null,
      charts: navigationService.buildChartFromNavigationItems(this.props.menuItems, NAV_ID.PATIENT_CHARTING, NAV_ID.ADMISSION_HISTORY)
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { assessment } = this.props;
    const navIds = [NAV_ID.ADMISSION_HISTORY, NAV_ID.ALLERGIES, ...this.state.charts.map((chart) => chart.navId)];
    const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];
    appHelper.useLoader(
      chartService.loadFragments({ chartId: assessment.simChartId, navIds, fragmentTypes }).then(({ data: fragments }) => {
        this.setState(
          produce((state) => {
            const isChartLocked = chartHelper.isChartLocked(fragments, NAV_ID.ADMISSION_HISTORY);
            state.isChartLocked = isChartLocked;
            state.inViewMode = isChartLocked;
            state.landingFragment = chartHelper.findFragmentByNavId(fragments, NAV_ID.ADMISSION_HISTORY, FragmentType.STATUS);
            state.charts = state.charts.map((navElement) => {
              const navFragment = fragments.find((fragment) => fragment.navElementId === navElement.navId && fragment.fragmentType !== FragmentType.STATUS) || null;
              let hasData = !!navFragment;
              let chartHistory: ChartFragment[];
              if (navElement.navId === NAV_ID.ALLERGY_INFORMATION) {
                const allergyInfoStatusFragment = fragments.find(
                  (fragment) => fragment.navElementId === NAV_ID.ALLERGY_INFORMATION && fragment.fragmentType === FragmentType.STATUS
                );
                const allergiesFragments = fragments
                  .filter((fragment) => fragment.navElementId === NAV_ID.ALLERGIES && moment(fragment.createdAt).isBefore(allergyInfoStatusFragment?.completedAt))
                  .map((fragment) => {
                    const active = (!fragment.active && moment(fragment.updatedAt).isAfter(allergyInfoStatusFragment?.completedAt)) || fragment.active;
                    return {
                      active,
                      id: fragment.fragmentId,
                      chartingAt: fragment.chartingAt,
                      createdAt: fragment.createdAt,
                      creator: fragment.creator,
                      modifier: fragment.modifier,
                      [FormField.NO_KNOWN_ALLERGIES]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.NO_KNOWN_ALLERGIES)[0]?.title,
                      [FormField.ALLERGY_TYPE]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGY_TYPE)[0]?.content,
                      [FormField.ALLERGEN]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN)[0]?.content,
                      [FormField.ALLERGEN_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_OTHER)[0]?.content,
                      [FormField.ALLERGEN_MEDICATION]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_MEDICATION)[0]?.content,
                      [FormField.ALLERGEN_MEDICATION_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.ALLERGEN_MEDICATION_OTHER)[0]?.content,
                      [FormField.REACTIONS]: chartHelper
                        .getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.REACTIONS)
                        .map((item) => item.title)
                        .filter((item) => item !== 'Other'),
                      [FormField.REACTIONS_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.REACTIONS_OTHER)[0]?.content,
                      [FormField.SEVERITY]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.SEVERITY)[0]?.content,
                      [FormField.INFORMANT]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.INFORMANT)[0]?.content,
                      [FormField.INFORMANT_OTHER]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.INFORMANT_OTHER)[0]?.content,
                      [FormField.CONFIDENCE_LEVEL]: chartHelper.getFragmentRecords(fragment, SectionTitle.ALLERGIES, FormField.CONFIDENCE_LEVEL)[0]?.content
                    };
                  });
                hasData = allergiesFragments.length > 0;
                chartHistory = allergiesFragments;
              }
              return { ...navElement, hasData, fragment: navFragment, chartHistory };
            });
          })
        );
      }),
      { errorMessage: 'can not load fragments' }
    );
  };

  handleCompleteClick = () => {
    this.props.showConfirmationModal({
      showIcon: true,
      header: 'Please confirm.',
      message: 'Are you sure that you want to complete the Admission History? Once completed, no further changes can be made.',
      onOkClick: this.handleConfirmCompleteClick
    });
  };

  handleConfirmCompleteClick = () => {
    const { assessment, chartingTime } = this.props;
    const navIds = [NAV_ID.ADMISSION_HISTORY, ...this.state.charts.map((chart) => chart.navId)];
    const chartingAt = chartService.getChartingTime(chartingTime);
    appHelper.useLoader(
      chartService.lockFragments({ chartId: assessment.simChartId, navIds, chartingAt }).then(() =>
        this.props.showSuccessModal({
          message: 'Admission History was successfully completed.',
          actions: [{ text: 'View Completed Admission History', onClick: this.handleViewCompleted }]
        })
      ),
      { errorMessage: 'can not lock chart' }
    );
  };

  handleViewCompleted = () => {
    this.props.hideSuccessModal();
    this.loadData();
  };

  handleReviewClick = () => this.setState({ inViewMode: true });

  handleBackClick = () => this.setState({ inViewMode: false });

  handleTileClick = (routePath: string) => navigationService.navigateToChart(routePath, this.props.assessment.eolsAssessmentId);

  render() {
    const { isAssessmentOwner, isAssessmentSubmitted, patientContext, intl } = this.props;
    const { isChartLocked, inViewMode, landingFragment, charts } = this.state;
    const viewProps = {
      isAssessmentOwner,
      isAssessmentSubmitted,
      isChartLocked,
      inViewMode,
      landingFragment,
      charts,
      patientContext,
      intl,
      onCompleteClick: this.handleCompleteClick,
      onReviewClick: this.handleReviewClick,
      onBackClick: this.handleBackClick,
      onTileClick: this.handleTileClick
    };
    return <AdmissionHistoryView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  menuItems: appSelectors.getMenuItems(state)
});

export { AdmissionHistory as BaseAdmissionHistory };
export default compose(connect(mapStateToProps, null), withFormUtilities)(AdmissionHistory);

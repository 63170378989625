import { Component } from 'react';
import { CaseStudySequence } from 'models/ui';
import { ChartsInvolved, MultiLine } from 'components/common';
import { ELSTab, ELSTabGroup } from 'components/common/els';

export enum Tabs {
  INSTRUCTIONS = 0,
  CHARTS_INVOLVED = 1
}

interface PhasesDetailViewProps {
  sequence: CaseStudySequence;
}

interface PhasesDetailViewStates {
  activeTab: number;
}

class PhasesDetailView extends Component<PhasesDetailViewProps, PhasesDetailViewStates> {
  static displayName = 'PhasesDetailView';

  constructor(props) {
    super(props);
    this.state = {
      activeTab: Tabs.INSTRUCTIONS
    };
  }

  handleTabGroupClick = (tabIndex) => this.setState({ activeTab: tabIndex });

  render() {
    return (
      <div>
        <h1 className="u-els-margin-top-3x u-els-margin-bottom-2x">{this.props.sequence.content.title}</h1>
        <ELSTabGroup onChangeActiveIndex={this.handleTabGroupClick} activeIndex={this.state.activeTab}>
          <ELSTab title="Instructions" />
          <ELSTab title="Charts involved" />
        </ELSTabGroup>
        {this.state.activeTab === Tabs.INSTRUCTIONS && (
          <div data-qa="qa-instruction-tab">
            <MultiLine text={this.props.sequence.content?.instruction} />
          </div>
        )}
        {this.state.activeTab === Tabs.CHARTS_INVOLVED && <ChartsInvolved charts={this.props.sequence.content?.chartsInvolved} />}
      </div>
    );
  }
}

export default PhasesDetailView;

import produce from 'immer';
import { cloneDeep } from 'lodash';
import { Component } from 'react';
import { FragmentInputRecord, MultiSectionsFragment, SectionRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { appHelper } from 'helpers';
import { chartService } from 'services';
import { withFormUtilities } from 'components/common';
import { EditChartSectionProps } from './EditChartSection';
import EditChartView, { EditChartViewProps } from './EditChartView';

export interface EditChartProps {
  buildStatusBadge?: Function;
  simChartId: string;
  hideDelete?: boolean;
  selectedFragment: MultiSectionsFragment;
  onCloseClick: Function;
  showDeleteConfirmation: Function;
  showDeleteSuccess: Function;
  linkedFragmentRecords?: MultiSectionsFragment[];
}

interface EditChartState {
  deleteStatusDictionary: Map<string, boolean>;
  hasDataChanged: boolean;
  selectedFragment: MultiSectionsFragment;
}

class EditChart extends Component<EditChartProps, EditChartState> {
  static displayName = 'EditChart';

  constructor(props) {
    super(props);
    const deleteStatusDictionary = new Map<string, boolean>();

    this.buildDeleteStatusDictionary(deleteStatusDictionary, this.props.selectedFragment);
    props.linkedFragmentRecords.forEach((linkedFragmentRecord) => this.buildDeleteStatusDictionary(deleteStatusDictionary, linkedFragmentRecord));

    this.state = {
      deleteStatusDictionary,
      hasDataChanged: false,
      selectedFragment: this.props.selectedFragment
    };
  }

  buildDeleteStatusDictionary = (deleteStatusDictionary, fragment: MultiSectionsFragment) => {
    (fragment?.chartData?.records || []).forEach((sectionRecord) => {
      sectionRecord.records.forEach((formFieldRecord) => deleteStatusDictionary.set(formFieldRecord.id, fragment.active && formFieldRecord.active));
    });
  };

  loadFragment = (chartId, navId, fragmentId) => {
    return chartService.loadFragments({ chartId, navIds: [navId], fragmentTypes: [FragmentType.CHARTING, FragmentType.AUTHORED] }).then(({ data: fragments }) => {
      return fragments.find((frag) => frag.fragmentId === fragmentId);
    });
  };

  handleRecordDelete = (deleteRecord: FragmentInputRecord) => this.props.showDeleteConfirmation(() => this.deleteChart(deleteRecord.id, deleteRecord.linkedFormFieldIds));

  deleteChart = (deleteId: string, linkedFormFieldIds?: string[]) => {
    const chartId = this.props.simChartId;
    const { navElementId } = this.props.selectedFragment;
    const selectedFragment = cloneDeep(this.state.selectedFragment);
    // since we don't have types to lean on, we need to be more strict
    // - require `records` and skip any `records` that are empty arrays
    if (selectedFragment.chartData.records?.length > 0) {
      selectedFragment.chartData.records.forEach((sectionRecord) => {
        if (sectionRecord.records?.length > 0) {
          sectionRecord.records.forEach((formFieldRecord) => {
            if (formFieldRecord.id === deleteId || (linkedFormFieldIds && linkedFormFieldIds.includes(formFieldRecord.formField))) {
              // eslint-disable-next-line no-param-reassign
              formFieldRecord.active = false;
              this.setState((prevState) => {
                const status = prevState.deleteStatusDictionary;
                status.set(formFieldRecord.id, false);
                return { deleteStatusDictionary: status };
              });
            }
          });
        }
      });

      appHelper.useLoader(
        chartService
          .saveFragment(chartId, navElementId, selectedFragment)
          .then(() => this.loadFragment(chartId, navElementId, selectedFragment.fragmentId))
          .then((fragment) => {
            this.props.showDeleteSuccess();
            this.setState({ hasDataChanged: true, selectedFragment: fragment as MultiSectionsFragment });
          })
      );
    }
  };

  handleCloseClick = () => this.props.onCloseClick(this.state.hasDataChanged);

  cookSectionRecords = (sections: SectionRecord[]): SectionRecord[] =>
    produce(sections, (draft) => {
      draft.forEach((section) => {
        // Use Map to remove duplication while keeping the order
        const titleMap = new Map();
        section.records.forEach((record) => {
          // Prefer to use parent label if any
          if (record.parentLabel) {
            return titleMap.set(record.parentLabel, { ...record, title: record.parentLabel, content: record.title });
          }
          return titleMap.set(record.title, record);
        });

        // eslint-disable-next-line no-param-reassign
        section.records = Array.from(titleMap.values());
      });
    });

  convertFragmentToChartSection = (fragment: MultiSectionsFragment): EditChartSectionProps => {
    let status = '';
    let isStatusBadgeActive = false;

    if (this.props.buildStatusBadge) {
      const result = this.props.buildStatusBadge(fragment);
      status = result.status;
      isStatusBadgeActive = result.isStatusBadgeActive;
    }

    // records can be getting from redux store which is wrapped
    // by seamless immutable. It will raise exception when using
    // it with immer js, so we need to clone.
    const records = cloneDeep(fragment.chartData?.records) || [];
    return {
      chartName: fragment.chartData.fragmentTitle,
      chartTime: fragment.chartingAt,
      deleteStatusDictionary: this.state.deleteStatusDictionary,
      fragmentCreatorId: fragment.creator?.id,
      fullName: chartService.formatName(fragment.creator),
      id: fragment.fragmentId,
      onRecordDelete: this.handleRecordDelete,
      records: this.cookSectionRecords(records),
      status,
      isStatusBadgeActive,
      useBlueBackground: status === 'Reinforced'
    };
  };

  render() {
    const { selectedFragment, linkedFragmentRecords, hideDelete } = this.props;
    const selectedFragmentSection = this.convertFragmentToChartSection(selectedFragment);
    const linkedFragmentSections = linkedFragmentRecords?.map((linkedFragmentRecord) => this.convertFragmentToChartSection(linkedFragmentRecord));

    const viewProps: EditChartViewProps = {
      selectedFragmentSection,
      linkedFragmentSections,
      hideDelete,
      onCloseClick: this.handleCloseClick
    };
    return <EditChartView {...viewProps} />;
  }
}

export { EditChart as BaseEditChart };
export default withFormUtilities(EditChart);

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, Section, Title } from './constants';
import ClinicalInstituteScaleView, { ClinicalInstituteScaleViewProps } from './ClinicalInstituteScaleView';

class ClinicalInstituteScale extends Component<ChartComponentProps> {
  static displayName = 'ClinicalInstituteScale';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    const textBoxes = [
      {
        name: FormField.PULSE_HEART_RATE,
        label: FormFieldLabel.PULSE_HEART_RATE
      },
      {
        name: FormField.BLOOD_PRESSURE,
        label: FormFieldLabel.BLOOD_PRESSURE
      }
    ];

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    dataMap.set(
      FormField.CLINICAL_INSTITUTE_SCALE,
      createFormField({ name: FormField.CLINICAL_INSTITUTE_SCALE, type: FormFieldInputType.SCALE, errorLabel: Section.CLINICAL_INSTITUTE_SCALE })
    );

    return dataMap;
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords, buildPlainRecord } = chartService;
    const { chartingTime, formFieldMap } = this.props;

    const record = {
      chartTitle: Title.SPECIAL_CHARTS,
      fragmentTitle: Section.CLINICAL_INSTITUTE_SCALE,
      records: [
        {
          sectionTitle: Section.CLINICAL_INSTITUTE_SCALE,
          records: [buildPatientRecord(formFieldMap, FormField.PULSE_HEART_RATE), buildPatientRecord(formFieldMap, FormField.BLOOD_PRESSURE)]
        },
        {
          records: [...buildPatientRecords(formFieldMap, FormField.CLINICAL_INSTITUTE_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_CIWA_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.CLINICAL_INSTITUTE_SCALE,
              title: FormFieldLabel.TOTAL_SCORE,
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.CLINICAL_INSTITUTE_SCALE))?.score?.value,
              linkedFormFieldIds: [
                FormField.NAUSEA_VOMITING,
                FormField.TREMOR,
                FormField.PAROXYSMAL_SWEATS,
                FormField.ANXIETY,
                FormField.AGITATION,
                FormField.TACTILE_DISTURBANCES,
                FormField.AUDITORY_DISTURBANCES,
                FormField.VISUAL_DISTURBANCES,
                FormField.HEADACHE_FULLNESS_IN_HEAD,
                FormField.ORIENTATION_CLOUDING_SENSORIUM
              ]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.NAUSEA_VOMITING, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.TREMOR, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.PAROXYSMAL_SWEATS, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.ANXIETY, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.AGITATION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.TACTILE_DISTURBANCES, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.AUDITORY_DISTURBANCES, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.VISUAL_DISTURBANCES, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.HEADACHE_FULLNESS_IN_HEAD, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.ORIENTATION_CLOUDING_SENSORIUM, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => {
    this.props.initState(this.buildFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { displayAuthoringData, enableDisplayRecordsButton, formFieldMap, formSubmittedCount, handleDiscardClick, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: ClinicalInstituteScaleViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };

    return <ClinicalInstituteScaleView {...viewProps} />;
  }
}

export { ClinicalInstituteScale as BaseClinicalInstituteScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(ClinicalInstituteScale);

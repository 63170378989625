import cx from 'classnames';
import moment, { max } from 'moment';
import { PureComponent } from 'react';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import ELSDateTimeWrapper from 'components/common/els-wrapper/ELSDateTime/ELSDateTimeWrapper';
import './scheduling.scss';

interface SchedulingProps {
  availableDate: string;
  courseStartDate?: string;
  dueDate: string;
  onDateTimeChange: Function;
  dueDateError: string;
  availableDateLabel?: string;
  dueDateLabel?: string;
  isBoldLabel?: boolean;
  disableAvailableDate?: boolean;
  disableDueDate?: boolean;
}

interface SchedulingState {
  disableAvailableDateBefore: Date;
  disableDueDateBefore: Date;
}

class Scheduling extends PureComponent<SchedulingProps, SchedulingState> {
  static displayName = 'Scheduling';

  static defaultProps = {
    availableDateLabel: 'Set when students can start viewing this assignment',
    dueDateLabel: 'Set a due date and time',
    isBoldLabel: true,
    disableAvailableDate: false,
    disableDueDate: false
  };

  constructor(props) {
    super(props);
    this.state = {
      disableAvailableDateBefore: props.courseStartDate ? max(moment(), moment(props.courseStartDate)).toDate() : moment().toDate(),
      disableDueDateBefore: max(moment(props.availableDate).add(1, 'minute'), moment()).toDate()
    };
  }

  componentDidUpdate(prevProps: SchedulingProps) {
    const { availableDate } = this.props;
    if (availableDate !== prevProps.availableDate) {
      this.setState({ disableDueDateBefore: max(moment(availableDate).add(1, 'minute'), moment()).toDate() });
    }
  }

  changeAvailableDateHandler = (event, value) => this.props.onDateTimeChange('availableDate', event, value);

  changeDueDateHandler = (event, value) => this.props.onDateTimeChange('dueDate', event, value);

  render() {
    return (
      <div className="els-scheduling">
        <Box mb2>
          <ELSFlexItem>
            <ELSFlex left middle>
              <ELSFlexItem className="u-els-margin-right" middle>
                <ELSFlex middle>
                  <ELSIcon name="eye-visible" size="1x1o2" />
                </ELSFlex>
              </ELSFlexItem>
              <ELSFlexItem>
                <h4 className={cx('u-els-font-size-h4', { 'u-els-bold': this.props.isBoldLabel })}>{this.props.availableDateLabel}</h4>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <div className="els-scheduling-date-picker-container">
            <ELSDateTimeWrapper
              id="availableDate"
              name="availableDate"
              changeHandler={this.changeAvailableDateHandler}
              value={this.props.availableDate}
              disableBefore={this.state.disableAvailableDateBefore}
              isDisabled={this.props.disableAvailableDate}
              timeZoneType="abbr"
              showTimeZone
            />
          </div>
        </Box>
        <Box mb2>
          <ELSFlexItem>
            <ELSFlex left middle>
              <ELSFlexItem className="u-els-margin-right" middle>
                <ELSFlex middle>
                  <ELSIcon name="alerts" size="1x1o2" />
                </ELSFlex>
              </ELSFlexItem>
              <ELSFlexItem>
                <h4 className={cx('u-els-font-size-h4', { 'u-els-bold': this.props.isBoldLabel })}>{this.props.dueDateLabel}</h4>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <Box mb className="els-scheduling-date-picker-container">
            <ELSDateTimeWrapper
              id="dueDate"
              name="dueDate"
              changeHandler={this.changeDueDateHandler}
              value={this.props.dueDate}
              disableBefore={this.state.disableDueDateBefore}
              isDisabled={this.props.disableDueDate}
              timeZoneType="abbr"
              showTimeZone
            />
            {this.props.dueDateError && <div className="u-els-color-warn u-els-margin-top">{this.props.dueDateError}</div>}
          </Box>
        </Box>
      </div>
    );
  }
}

export default Scheduling;

import { SNGDateProps } from 'components/common/date/SNGDate';
import { FlexItemProps, InputOptions, InputProps } from './types';

export const dumpDateProps: SNGDateProps = {
  value: '',
  onDateChange: undefined,
  componentRef: null,
  disableAfter: null,
  disableBefore: null,
  id: '',
  onBlur: undefined,
  selectOnly: false,
  isDisabled: false,
  inputAriaLabel: '',
  ariaRequired: false,
  usePropValue: false
};

export const dumpInputOptions: InputOptions = {
  labelCss: '',
  displayHorizontal: false,
  showScaleRollUp: true,
  contextDirectionMap: new Map(),
  groupScaleQuestion: [],
  isHiddenScore: false,
  placeholder: '',
  showScoreInAnswer: false,
  disableBefore: undefined,
  isNotSaveChildValueInScore: false,
  radioPrefixName: undefined,
  innerClassName: undefined,
  isDisabled: false,
  disableRadioContent: undefined,
  hideRadioContent: undefined,
  renderNestedField: undefined
};

export const dumpFlexItemProps: FlexItemProps = {
  id: '',
  className: '',
  grow: false,
  top: '',
  middle: '',
  bottom: '',
  w: '',
  xs: '',
  md: '',
  lg: '',
  xl: ''
};

export const dumpInputProps: InputProps = {
  fieldId: '',
  formField: undefined,
  formFieldMap: undefined,
  children: undefined,
  onChange: undefined,
  ...dumpDateProps,
  ...dumpInputOptions,
  ...dumpFlexItemProps
};

/* eslint-disable import/no-webpack-loader-syntax */

/* eslint-disable @typescript-eslint/no-var-requires */
import cx from 'classnames';
import { Locales } from 'constants/app.constant';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { ConstructiveFeedbackIcon } from 'components/common';

export interface FeedbackProps {
  className?: string;
  submittedTime: string;
  commentCount: number;
  isFeedback: boolean;
  isPastDue: boolean;
  locale: Locales;
  isInProgressGradingEnabledFlag: boolean;
  hasOverallComment: boolean;
}

const Feedback = (props: FeedbackProps) => {
  const { className, submittedTime, commentCount, isFeedback, isPastDue, isInProgressGradingEnabledFlag, locale, hasOverallComment } = props;
  const hasInProgressComment = isInProgressGradingEnabledFlag && (commentCount > 0 || hasOverallComment);
  const isShownCommentCounter = (!isPastDue && isFeedback) || hasInProgressComment;

  const time = formatDate({
    date: toMomentWithParsers(submittedTime).toDate(),
    locale,
    includeTime: true
  });

  let firstLine = 'Awaiting feedback';

  if (isPastDue && !isInProgressGradingEnabledFlag) {
    firstLine = 'na';
  } else if (isFeedback || (isInProgressGradingEnabledFlag && (commentCount > 0 || hasOverallComment))) {
    firstLine = `Submitted at ${time}`;
  }

  return (
    <div className={cx('feedback', className)}>
      <ConstructiveFeedbackIcon />
      <div className="legend">
        <h4 className="u-els-font-size-h3">Instructor Feedback</h4>
        <p className="legend__text">
          <span className="legend__dot u-els-background-color-extended-orange-7" />
          {firstLine}
        </p>
        {isShownCommentCounter && (
          <p className="legend__text">
            <span className="legend__dot u-els-background-color-extended-orange-4" />
            {commentCount ? `Comments on ${commentCount} Charts` : 'No Instructor Chart Comments'}
          </p>
        )}
      </div>
    </div>
  );
};

export default Feedback;

import { DiagnosisFragment, DiagnosticTestFragment, LaboratoryTestFragment, MedicationFragment } from 'models/api-response';
import PatientInfo from './PatientInfo';
import SectionDiagnoses from './SectionDiagnoses';
import SectionDiagnosticTests from './SectionDiagnosticTests';
import SectionLaboratoryTests from './SectionLaboratoryTests';
import SectionMedication from './SectionMedication';
import '../pre.clinical.manager.common.scss';

export interface FullReportProps {
  patientInfo: string;
  studentInfo: string;
  diagnosisRecords: DiagnosisFragment[];
  medicationRecords: MedicationFragment[];
  laboratoryTestRecords: LaboratoryTestFragment[];
  diagnosticTestsRecords: DiagnosticTestFragment[];
}

const FullReport = (props: FullReportProps) => (
  <>
    <PatientInfo patientInfo={props.patientInfo} studentInfo={props.studentInfo} />
    <SectionDiagnoses isFullReport diagnosisRecords={props.diagnosisRecords} />
    <SectionMedication isFullReport medicationRecords={props.medicationRecords} />
    <SectionLaboratoryTests laboratoryTestRecords={props.laboratoryTestRecords} />
    <SectionDiagnosticTests isFullReport diagnosticTestsRecords={props.diagnosticTestsRecords} />
  </>
);

FullReport.displayName = 'FullReport';
export default FullReport;

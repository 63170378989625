import cx from 'classnames';
import { delay, identity, isNil } from 'lodash';
import { Component } from 'react';
import { MultiSelectType } from 'models/enum';
import { ChartMetaFormField, ContentItem } from 'models/ui';
import { ELSDropDownSearch, ELSTagGroup } from 'components/common/els';
import './multi.select.scss';

export interface MultiselectDropdownEFProps {
  chartFormField: ChartMetaFormField;
  disableEmptyDropdown?: boolean;
  isDisabled?: boolean;
  value?: string;
  selectType?: MultiSelectType;
  ariaRequired?: boolean;
}

interface MultiselectDropdownEFState {
  disableEmptyDropdown: boolean;
  displayValue: string;
  options: ContentItem[];
  reset: number;
  search: string;
  selectedOptionNames: string[];
}
class MultiselectDropdownEF extends Component<MultiselectDropdownEFProps, MultiselectDropdownEFState> {
  static displayName = 'MultiselectDropdownEF';
  static defaultProps = {
    disableEmptyDropdown: false
  };

  isUnmount = false;

  constructor(props) {
    super(props);

    this.state = {
      disableEmptyDropdown: false,
      displayValue: this.getSelectedLabel(),
      options: this.getFilteredOptions(''),
      reset: 0,
      search: '',
      selectedOptionNames: this.getSelectedOptionNames()
    };
  }

  componentDidUpdate(prevProps) {
    const { chartFormField, selectType } = this.props;
    if (chartFormField !== prevProps.chartFormField) {
      if (chartFormField.chartContent) {
        this.setState((prevState) => {
          let { search, reset } = prevState;
          let options = this.getFilteredOptions(search);
          if (
            this.isChartContentChangedByVisibility(chartFormField.chartContent, prevProps.chartFormField.chartContent) ||
            selectType === MultiSelectType.SINGLE ||
            chartFormField.disabled
          ) {
            reset += 1;
            search = '';
            options = this.getFilteredOptions(search);
          }

          return {
            disableEmptyDropdown: this.checkToDisableEmptyDropdown(),
            displayValue: this.getSelectedLabel(),
            options,
            reset,
            search,
            selectedOptionNames: this.getSelectedOptionNames()
          };
        });
      } else {
        // In case the chartFormField is changed by reset form action (invoking initState function)
        this.setState((prevState) => ({ disableEmptyDropdown: true, options: [], reset: prevState.reset + 1, search: '', selectedOptionNames: [] }));
      }
    }
  }

  componentWillUnmount() {
    this.isUnmount = true;
  }

  isChartContentChangedByVisibility = (chartContent: ContentItem[], otherChartContent: ContentItem[]) => {
    if (isNil(chartContent) || isNil(otherChartContent)) {
      return false;
    }
    if (chartContent.length !== otherChartContent.length) {
      return true;
    }
    const found = chartContent.find((item) => {
      return !otherChartContent.find((otherItem) => item.value === otherItem.value && item.isHidden === otherItem.isHidden);
    });

    return !!found;
  };

  handleOptionSelect = (newSelectedOption: ContentItem) => {
    const onChange = this.props.chartFormField.chartContent?.[0]?.onChange ?? identity;

    // Work-around to detect whether this is triggered by blur event
    if (isNil(newSelectedOption.id ?? newSelectedOption.selected)) {
      // 1. Since onSearchChangeHandler is delayed 300ms (core component),
      // there is a possibility of displayValue is not the latest so we need to delay here also
      // 2. In this case, do not use object destructuring for this.props.chartFormField
      delay(() => {
        if (!this.isUnmount) {
          if (this.state.displayValue === '') {
            // Clear selection
            onChange(this.props.chartFormField, { id: '' });
          } else {
            // Restore displayValue to the current selection
            this.setState({ search: '', displayValue: this.getSelectedLabel(), options: this.getFilteredOptions('') });
          }
        }
      }, 300);
    } else {
      onChange(this.props.chartFormField, newSelectedOption);
    }
    const { changeCallback } = this.props.chartFormField;
    if (changeCallback) {
      changeCallback({ formField: this.props.chartFormField, option: newSelectedOption });
    }
  };

  handleSearchChange = (value: string) => {
    const options = this.getFilteredOptions(value);
    this.setState({
      displayValue: value,
      search: value,
      options
    });
  };

  handleTagDelete = (index: number) => {
    const changedOption = this.props.chartFormField.chartContent.filter((option) => option.selected)[index];
    changedOption.onChange(this.props.chartFormField, changedOption);
  };

  getSelectedOptionNames = (): string[] => {
    return this.props.chartFormField.chartContent?.filter((option) => option.selected && !option.isHidden).map((option) => option.name) ?? [];
  };

  getSelectedOptionLabels = (): string[] => this.props.chartFormField.chartContent?.filter((option) => option.selected).map((option) => option.label) ?? [];

  getFilteredOptions = (search: string): ContentItem[] => {
    return this.props.chartFormField.chartContent?.filter((item) => !item.isHidden && item.name.toLowerCase().includes(search.toLowerCase())) ?? [];
  };

  getSelectedLabel = (): string => {
    const data: string[] = this.getSelectedOptionLabels();
    if (data.length === 0) {
      return '';
    }
    return data[0];
  };

  checkToDisableEmptyDropdown = () => {
    return this.props.disableEmptyDropdown && this.props.chartFormField.chartContent?.filter((item) => !item.isHidden).length === 0;
  };

  render() {
    const { disabled, name } = this.props.chartFormField;
    const { isDisabled, ariaRequired } = this.props;
    const { disableEmptyDropdown, options, reset, displayValue, selectedOptionNames } = this.state;
    const shouldDisable = isDisabled || disabled || disableEmptyDropdown;
    if (this.props.selectType === MultiSelectType.SINGLE) {
      return (
        <div className={cx('sng-multi-select-search', name)}>
          <ELSDropDownSearch
            className="sng-multi-select-search__dropdown"
            disableAutoComplete
            id={name}
            isDisabled={shouldDisable}
            name={name}
            onOptionSelected={this.handleOptionSelect}
            onSearchChangeHandler={this.handleSearchChange}
            options={options}
            placeholder="Search or Select"
            showSearchIcon
            type="normal"
            value={displayValue}
            ariaLabel={this.props.chartFormField.label}
            ariaRequired={ariaRequired}
          />
        </div>
      );
    }
    return (
      <div className={cx('sng-multi-select-search', name)}>
        <span className="u-els-hide-visually" aria-live="polite">{`${options.length} items in list`}</span>
        <ELSDropDownSearch
          className="sng-multi-select-search__dropdown"
          disableAutoComplete
          id={name}
          isDisabled={shouldDisable}
          key={reset}
          name={name}
          onOptionSelected={this.handleOptionSelect}
          onSearchChangeHandler={this.handleSearchChange}
          options={options}
          placeholder="Search or Select"
          showSearchIcon
          type="checkbox"
          ariaRequired={ariaRequired}
        />
        {!!selectedOptionNames.length && (
          <div className="u-els-margin-top">
            <ELSTagGroup isLargerChipText isTagDeletable cssModifier="sng-multi-select-search__tag-name-group" tags={selectedOptionNames} onDeleteTag={this.handleTagDelete} />
          </div>
        )}
      </div>
    );
  }
}

export default MultiselectDropdownEF;

import { delay } from 'lodash';
import moment from 'moment';
import { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import Immutable from 'seamless-immutable';
import { v4 } from 'uuid';
import { AssessmentRS, ChartFragment, ChartFragmentRS, ChartFragmentsRS } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { EventType, FragmentType } from 'models/enum';
import { PatientContext, TimeSpentPartial } from 'models/ui';
import { AuthoringPhaseIndexes, NAV_ID, REDIRECT_DELAY_TIME, multiChartAuthorDataNavMapping } from 'constants/app.constant';
import { appHelper, assignmentHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import { LoseDataWarning } from 'components/common';
import { ELSButton, ELSPageLoader, ELSWithModalService } from 'components/common/els';
import './chart.wrapper.scss';
import UnsavedChangesHandler from '../unsaved-changes-handler/UnsavedChangesHandler';

interface MatchParams {
  assessmentId: string;
}

interface LocationState {
  from?: string;
  id: string;
  navId: string;
  lastPerformed?: string;
}

export interface ChartWrapperPropsOnly {
  assessment: AssessmentRS | null;
  chartingTime: string;
  authorId: number;
  authoringData: Immutable<ChartFragment[]>;
  children(arg: {
    selectedNavId: string;
    assessment: AssessmentRS;
    chartingTime: string;
    loadChartData?: Function;
    saveChartData?: Function;
    deleteChartData?: Function;
    saveMultiFragments?: Function;
    handleNavigationAttempt: Function;
    isAuthor: boolean;
  }): ReactElement;
  isAuthor: boolean;
  modalService: ELSModalServiceType;
  offset: number;
  phaseIndex: number;
  selectedNavId: string;
  isMultiStepsAuthoring: boolean;
  patientContext: PatientContext;
  setSelectedNavId: Function;
  setAuthoringData: Function;
  setIsMultistepAuthoring: Function;
  enableMultiStepsAuthoring: Function;
  timeSpentData: TimeSpentPartial;
}

interface ChartWrapperState {
  isEnableDisplayRecords: boolean;
  unsavedChangesList: Record<string, Function>;
}

export type ChartWrapperProps = ChartWrapperPropsOnly & RouteComponentProps<MatchParams, undefined, LocationState>;

class ChartWrapper extends Component<ChartWrapperProps, ChartWrapperState> {
  static MULTIPLE_FRAGMENTS_DISPLAY_LIST = [NAV_ID.MEDICATION_RECONCILIATION];
  prevSelectedNavId: string;
  skipPauseNavigation: boolean;
  static displayName = 'ChartWrapper';

  constructor(props) {
    super(props);
    this.state = {
      isEnableDisplayRecords: false,
      unsavedChangesList: {}
    };
  }

  componentDidUpdate(prevProps: Readonly<ChartWrapperProps>) {
    if (prevProps.selectedNavId && prevProps.selectedNavId !== this.props.selectedNavId && this.prevSelectedNavId !== this.props.selectedNavId) {
      this.prevSelectedNavId = prevProps.selectedNavId;
    }
  }

  componentWillUnmount() {
    const { isAuthor, isMultiStepsAuthoring, setIsMultistepAuthoring } = this.props;
    if (isAuthor) {
      if (isMultiStepsAuthoring) {
        // Keep authoring data but reset isMultistepAuthoring.
        setIsMultistepAuthoring(false);
      } else if (!this.prevSelectedNavId || this.props.selectedNavId !== multiChartAuthorDataNavMapping[this.prevSelectedNavId]) {
        // Reset authoring data so data won't show unexpectedly.
        //
        // An example for case that data show unexpectedly if won't reset.
        // Save Authoring Data in respiratory chart -> go to Saved System Assessment
        // if not reset, data just saved will shown.
        // Not sure the the old issue still exists, but it causes the Immutable data error.
        // Need to investigate more.
        // this.props.setAuthoringData([]);
      }
    }
  }

  handleNavigationAttempt = (isDataChanged: Function) =>
    new Promise((resolve) => {
      const { modalService } = this.props;

      if (!isDataChanged() || this.skipPauseNavigation) {
        resolve(true);
      } else {
        const modalId = 'loseDataModal';
        modalService.openModal({
          modalId,
          content: (
            <LoseDataWarning
              onLeaveClick={() => {
                modalService.closeModal(modalId);
                resolve(true);
              }}
              onStayClick={() => {
                modalService.closeModal(modalId);
                if (this.prevSelectedNavId && this.prevSelectedNavId !== this.props.selectedNavId) {
                  this.props.setSelectedNavId(this.prevSelectedNavId);
                }
                resolve(false);
              }}
            />
          )
        });
      }
    });

  loadChartData = (fragmentTypes = [FragmentType.AUTHORED, FragmentType.CHARTING, FragmentType.STATUS], navIds = [], chartId = null): Promise<ChartFragmentsRS> => {
    const { assessment, selectedNavId, isAuthor } = this.props;
    const { authoringData } = this.props;
    const loadedNavIds = navIds?.length > 0 ? navIds : [selectedNavId];
    const loadedChartId = chartId || assessment.simChartId;

    /**
     * if user is an author
     * return a mock API response with either no data or the authoring data
     */
    if (isAuthor) {
      const fragments = authoringData?.asMutable().filter((data) => {
        const navMatched = loadedNavIds.some((navId) => new RegExp(navId, 'i').test(data.navElementId));
        return navMatched && fragmentTypes.includes(FragmentType.AUTHORED);
      });

      const newFragments = fragments?.map((fragment) => ({ ...fragment, creator: { firstName: '', lastName: '' } }));

      return Promise.resolve({
        data: newFragments || []
      });
    }

    return chartService.loadFragments({ chartId: loadedChartId, navIds: loadedNavIds, fragmentTypes });
  };

  saveChartData = (payload: ChartFragment, navId?: string): Promise<ChartFragmentRS> => {
    const { assessment, authoringData = [], isAuthor, selectedNavId, authorId, offset, isMultiStepsAuthoring, phaseIndex, timeSpentData } = this.props;
    const savedNavId = navId || selectedNavId;

    // Set default value for authorId and offset during authoring Clinical Setup
    const isInClinicalSetup = String(this.props.location.pathname).toLowerCase().endsWith('/clinical-setup');
    const finalAuthorId = authorId || (isInClinicalSetup ? 0 : null);
    const finalOffset = offset || (isInClinicalSetup ? 0 : null);

    if (!isAuthor) {
      assignmentHelper.recordTimeSpent(timeSpentData, EventType.END);
    }

    // if user is an author, save fragment into redux store in order to display on modal UI
    if (isAuthor) {
      const fragment = {
        fragmentId: chartHelper.createFragmentId(savedNavId),
        createdAt: moment().toISOString(),
        ...payload,
        navElementId: savedNavId,
        active: true,
        phaseIndex,
        authorId: finalAuthorId,
        chartingAtOffsetInSeconds: finalOffset
      };

      if (isMultiStepsAuthoring) {
        const newAuthoringData = [...authoringData];
        const index = newAuthoringData.findIndex((item) => item.fragmentId === payload.fragmentId);

        if (index === -1) {
          newAuthoringData.push(fragment);
        } else if (newAuthoringData[index].phaseIndex !== phaseIndex) {
          fragment.fragmentId = chartHelper.createFragmentId(savedNavId);
          fragment.createdAt = moment().toISOString();
          newAuthoringData.push(fragment);
        } else {
          newAuthoringData[index] = fragment;
        }
        this.props.setAuthoringData(newAuthoringData);
      } else {
        this.props.setAuthoringData([fragment]);
      }

      return Promise.resolve({ data: fragment });
    }

    return chartService.saveFragment(assessment.simChartId, savedNavId, payload);
  };

  deleteChartData = (payload: ChartFragment): Promise<void> => {
    const { assessment } = this.props;
    return chartService.deleteFragment(assessment.simChartId, payload.fragmentId);
  };

  saveMultiFragments = (fragments: ChartFragment[]): Promise<void> => {
    const { assessment, isAuthor, selectedNavId, authorId, offset, phaseIndex } = this.props;
    if (isAuthor) {
      const saveFragments = fragments.map((fragment) => ({
        fragmentId: chartHelper.createFragmentId(fragment.navElementId),
        createdAt: moment().toISOString(),
        ...fragment,
        active: true,
        phaseIndex,
        authorId: !fragment.authorId || fragment.navElementId === selectedNavId ? authorId : fragment.authorId,
        chartingAtOffsetInSeconds: offset
      }));
      // currently this does not support multi step authoring
      this.props.setAuthoringData(saveFragments);

      return Promise.resolve();
    }
    return chartService.saveMultiFragments(assessment.simChartId, fragments);
  };

  buildBasedAuthoringData = (data): any => ({
    navId: data.navElementId,
    authorId: data.authorId,
    creator: data.creator,
    createdAt: data.createdAt,
    chartingAtOffsetInSeconds: data.chartingAtOffsetInSeconds,
    chartData: data.chartData,
    lockedNavIds: data.lockedNavIds,
    linkedFragmentRef: data.linkedFragmentId,
    ref: data.fragmentId,
    phaseIndex: data.phaseIndex,
    groupFragmentRef: data.groupFragmentRef
  });

  getPhaseToData = (): Map<number, object[]> => {
    const { authoringData } = this.props;
    const phaseToData = new Map<number, object[]>();
    authoringData.forEach((data) => {
      const basedData = this.buildBasedAuthoringData(data);
      let authoringPhases = phaseToData.get(data.phaseIndex);
      if (!authoringPhases) {
        authoringPhases = [];
        phaseToData.set(data.phaseIndex, authoringPhases);
      }
      authoringPhases.push(basedData);
    });
    return phaseToData;
  };

  buildAuthoredData = (): string => {
    const { authoringData, isMultiStepsAuthoring } = this.props;
    let content = '';
    const items: any[] = [];
    if (isMultiStepsAuthoring) {
      const phaseToData = this.getPhaseToData();
      AuthoringPhaseIndexes.forEach((phase) => {
        const phaseData = phaseToData.get(Number(phase));
        if (phaseData) {
          phaseData.forEach((item) => {
            items.push(item);
          });
        }
      });
    } else {
      authoringData.forEach((item) => items.push(this.buildBasedAuthoringData(item)));
    }

    if (items.length === 1) {
      content = `${JSON.stringify(items.pop())}`;
    } else {
      content = `${JSON.stringify(items)}`;
    }

    return content;
  };

  displayAuthoringData = () => {
    const content = this.buildAuthoredData();
    // reset authoring data
    this.props.setAuthoringData([]);
    if (chartService.displayAuthorRecord()) {
      this.displayAuthoringDataModal(content);
    } else {
      navigationService.navigateToAppLinkSource(content);
    }
  };

  displayAuthoringDataModal = (content: string) => {
    const { modalService, history } = this.props;

    modalService.openModal({
      modalId: 'authoringDataModal',
      color: 'primary',
      content: (
        <div className="authoring-data-modal">
          <h2 className="u-els-font-size-h2 u-els-margin-bottom">Authoring Data</h2>
          <p className="u-els-margin-bottom-2x">
            {content.split('\n').map((line) => (
              <div key={v4()}>
                {line}
                <br />
              </div>
            ))}
          </p>
          <ELSButton type="primary" text="Copy to Clipboard" onClick={() => appHelper.copyToClipboard(content)} />
        </div>
      ),
      closeHandler: (modalId) => () => {
        modalService.closeModal(modalId);

        // For multi-steps authoring charts, chart can contains stale state after displaying records.
        // Refresh to reinit the chart state.

        // Refresh steps:
        // - Set skipPauseNavigation, so it won't show Warning popup if user make some change but not saving.
        // - Replace history path with current path + '/'. The end '/' is to trigger LOCATION_CHANGE event,
        // so it will persist current state. After refreshing, this state will be restored.
        // - Call reload to refresh the page.
        this.skipPauseNavigation = true;
        history.replace(`${new URL(window.location.href).hash.replace('#', '')}/`);

        window.location.reload();
      }
    });
  };

  enableMultiStepsAuthoring = (isSupportMultiStepsAuthoring) => {
    if (this.props.isAuthor) {
      // authoring has only used by author
      // so only updating change of isSupportMultiStepsAuthoring for author
      this.props.setIsMultistepAuthoring(isSupportMultiStepsAuthoring);
    }
  };

  backToSourceLocation = () => {
    const { state } = this.props.location;
    const { from, id, navId } = state || {};
    if (from && id && navId) {
      delay(() => {
        this.props.history.push({
          pathname: from,
          state: { id, navId, lastPerformed: chartService.getChartingTime(this.props.chartingTime) }
        });
      }, REDIRECT_DELAY_TIME);
    }
  };

  hasUnsavedChanges = (): boolean => Object.values(this.state.unsavedChangesList).some((unsavedChanges) => unsavedChanges());

  handleEnableDisplayRecords = (isEnableDisplayRecords) => this.setState({ isEnableDisplayRecords });

  pushUnsavedChangesList = (unsavedChanges: Record<string, Function>) => {
    this.setState((prevState) => ({
      unsavedChangesList: { ...prevState.unsavedChangesList, ...unsavedChanges }
    }));
  };

  isMultiChartAuthorData = (authorFragments: ChartFragment[], selectedNavId: string) =>
    !!authorFragments?.length && authorFragments.some((authorFragment) => multiChartAuthorDataNavMapping[authorFragment.navElementId] === selectedNavId);

  render() {
    const { assessment, isAuthor, selectedNavId, patientContext, isMultiStepsAuthoring, authoringData, location } = this.props;

    /**
     * if user is not an author (meaning we should be loading an assessment)
     * and there is no assessment
     * display the loader component
     */
    if (!isAuthor && !assessment) {
      return <ELSPageLoader />;
    }

    let enableDisplayRecordsButton = false;
    if (authoringData?.length > 0) {
      // Even that authoringData is not emtpy, only enable DisplayRecords button when authoringData belongs
      // to current chart or isMultiStepsAuthoring is enable. In case isMultiStepsAuthoring enabled,
      // the authoring process can involve multiple charts.
      enableDisplayRecordsButton =
        this.state.isEnableDisplayRecords || authoringData[0].navElementId === selectedNavId || isMultiStepsAuthoring || this.isMultiChartAuthorData(authoringData, selectedNavId);
    }

    const chartProps = {
      assessment,
      chartingTime: this.props.chartingTime,
      selectedNavId,
      handleNavigationAttempt: this.handleNavigationAttempt,
      loadChartData: this.loadChartData,
      saveChartData: this.saveChartData,
      deleteChartData: this.deleteChartData,
      saveMultiFragments: this.saveMultiFragments,
      enableMultiStepsAuthoring: this.enableMultiStepsAuthoring,
      isAuthor,
      patientContext,
      enableDisplayRecordsButton,
      displayAuthoringData: this.displayAuthoringData,
      onEnableDisplayRecords: this.handleEnableDisplayRecords,
      backToSourceLocation: location.state?.from ? this.backToSourceLocation : undefined,
      pushUnsavedChangesList: this.pushUnsavedChangesList
    };

    return (
      <div className="chart-wrapper">
        {this.props.children(chartProps)}
        <UnsavedChangesHandler handler={() => this.handleNavigationAttempt((): boolean => this.hasUnsavedChanges())} hasUnsavedChanges={this.hasUnsavedChanges} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state),
  chartingTime: studentSelectors.getChartingTime(state),
  authorId: studentSelectors.getAuthorId(state),
  offset: studentSelectors.getOffset(state),
  phaseIndex: studentSelectors.getPhaseIndex(state),
  isAuthor: appSelectors.getIsAuthor(state),
  selectedNavId: appSelectors.getNavId(state),
  patientContext: appSelectors.getPatientContext(state),
  authoringData: appSelectors.getAuthoringData(state),
  isMultiStepsAuthoring: appSelectors.getIsMultiStepsAuthoring(state),
  timeSpentData: appSelectors.getTimeSpentData(state)
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId)),
  setAuthoringData: (authorData) => dispatch(appActions.setAuthoringData(authorData)),
  setIsMultistepAuthoring: (isMultiStepsAuthoring) => dispatch(appActions.setIsMultiStepsAuthoring(isMultiStepsAuthoring))
});

const enhancers = [ELSWithModalService, withRouter, connect(mapStateToProps, mapDispatchToProps)];
export { ChartWrapper as BaseChartWrapper };
export default compose(...enhancers)(ChartWrapper);

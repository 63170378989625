import ReactRouterPause from '@allpro/react-router-pause';
import { Component } from 'react';

export interface CustomReactRouterPauseProps {
  handler: Function;
  hasUnsavedChanges: Function;
}

class UnsavedChangesHandler extends Component<CustomReactRouterPauseProps> {
  static displayName = 'CustomReactRouterPause';

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.onConfirmRefresh);
    return null;
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onConfirmRefresh);
  }

  onConfirmRefresh = (event: BeforeUnloadEvent) => {
    const beforeUnloadEvent = event;
    if (this.props.hasUnsavedChanges()) {
      beforeUnloadEvent.preventDefault();
      beforeUnloadEvent.returnValue = 'You are currently in edit mode. Are you sure you want to refresh the page?';
    }
  };

  render() {
    return <ReactRouterPause handler={this.props.handler} />;
  }
}

export { UnsavedChangesHandler as BaseUnsavedChangesHandler };
export default UnsavedChangesHandler;

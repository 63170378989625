import { ChartTable } from 'models/ui';
import { AppMessage } from 'constants/app.constant';
import { Box, withFormUtilities } from 'components/common';
import DataTable, { AtoEAssessmentHistoryDataTableProps } from './AtoEAssessmentHistoryDataTable';
import { getStyledVitalSignsSavedData } from '../helpers';

export interface AToEAssessmentHistoryProps {
  dataSource: ChartTable;
  deleteRecord?: Function;
  showDeleteConfirmation(onConfirmClick: Function): Function;
  showDeleteColumn?: boolean;
  sidebarProps?: AtoEAssessmentHistoryDataTableProps['sidebarProps'];
}

export function AToEAssessmentHistory({ dataSource, ...props }: AToEAssessmentHistoryProps) {
  const { data } = dataSource;

  if (data && data.length > 0) {
    const { keys: transformedKeys, data: transformedData } = getStyledVitalSignsSavedData(data);
    return <DataTable dataSource={transformedData} keys={transformedKeys} {...props} />;
  }
  return (
    <Box id="qa-table__no-data" mt mb2>
      {AppMessage.NO_TABLE_DATA}
    </Box>
  );
}

export default withFormUtilities(AToEAssessmentHistory);

import { Component } from 'react';
import { defaultCustomRender } from 'helpers/chartRender.helper';
import { Box, ChartHistory } from 'components/common';
import EntryBy from 'components/common/entry-by/EntryBy';
import { FormField, HomeMedicationHistoryProps } from './constants';

class HomeMedicationHerbalAndNaturalHistoryTable extends Component<HomeMedicationHistoryProps> {
  static displayName = 'HomeMedicationHerbalAndNaturalHistoryTable';

  entryByRenderer = (record) => <EntryBy record={record} />;

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const actionHeader = deleteHistory ? { field: 'actionDelete' } : {};
    const dataSource = {
      headers: [
        { text: 'Product', field: 'product', sortable: true, customRender: defaultCustomRender(FormField.PRODUCT) },
        { text: 'Preparation', field: 'preparation', customRender: defaultCustomRender(FormField.PREPARATION) },
        { text: 'Dose', field: 'herbalAndNaturalRemedyProductsDose', customRender: defaultCustomRender(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_DOSE) },
        { text: 'Route', field: 'herbalAndNaturalRemedyProductsRoute', customRender: defaultCustomRender(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_ROUTE) },
        { text: 'Strength', field: 'strength', customRender: defaultCustomRender(FormField.STRENGTH) },
        {
          text: 'Taken In Last 24h',
          field: 'herbalAndNaturalRemedyProductsProductTakenInLast24Hours',
          customRender: defaultCustomRender(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_TAKEN_IN_LAST_24HOURS)
        },
        {
          text: 'Reason',
          field: 'herbalAndNaturalRemedyProductsReasonForTakingProduct',
          customRender: defaultCustomRender(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_REASON_FOR_TAKING_PRODUCT)
        },
        {
          text: 'Result',
          field: 'herbalAndNaturalRemedyProductsResultsFollowingUse',
          customRender: defaultCustomRender(FormField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS_RESULT_FOLLOWING_USE)
        },
        { text: 'Entry By', field: 'entryBy', customRender: this.entryByRenderer },
        { ...actionHeader }
      ],
      css: ['column__chart-time', '', '', '', '', '', '', '', 'column__entry-by', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt>
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} showDeleteColumn={!!deleteHistory} />
      </Box>
    );
  }
}

export default HomeMedicationHerbalAndNaturalHistoryTable;

import cx from 'classnames';
import { Component } from 'react';
import { NutritionOrderEntryRecord } from 'models/api-response';
import { ChartMetaFormField, ColumnAlign } from 'models/ui';
import { Box, ChartHistory } from 'components/common';

export interface NutritionOrderTableProps {
  chartHistory: NutritionOrderEntryRecord[];
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  deleteHistory: Function;
}

class NutritionOrderTable extends Component<NutritionOrderTableProps> {
  orderTimeRenderer = ({ orderTime }) => {
    return <div className="nutrition-order-table__order-time-field">{orderTime || ''}</div>;
  };

  descriptionRenderer = ({ orderDescription, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderDescription || ''}</div>;
  };

  detailRenderer = ({ orderDetail, active }) => {
    return <div className={cx({ 'row--deleted': !active }, 'nutrition-order-table__detail-field')}>{orderDetail || ''}</div>;
  };
  render() {
    const { chartHistory, deleteHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true, customRender: this.orderTimeRenderer },
        { text: 'Order Description', field: 'orderDescription', align: ColumnAlign.Left, customRender: this.descriptionRenderer },
        { text: 'Order Detail', field: 'orderDetail', align: ColumnAlign.Left, customRender: this.detailRenderer },
        { field: 'actionDelete' }
      ],
      css: ['column__chart-time', '', '', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt className="nutrition-order-table">
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

export default NutritionOrderTable;

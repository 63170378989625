import { Box } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';

export interface AlertModalProps {
  header?: string;
  message: string;
  okButtonText?: string;
  onOkClick: Function;
}

const AlertModal = (props: AlertModalProps) => (
  <div role="dialog" aria-modal="true" aria-label={props.message}>
    <ELSFlex left>
      <ELSFlexItem>
        {props.header && <h3>{props.header}</h3>}
        <p className="u-els-margin-top-1o2 u-white-space--pre-wrap">{props.message}</p>
        <Box mt3>
          <ELSButton text={props.okButtonText || 'OK'} className="sng-button" onClick={props.onOkClick} />
        </Box>
      </ELSFlexItem>
    </ELSFlex>
  </div>
);

AlertModal.displayName = 'AlertModal';

export default AlertModal;

import { Component } from 'react';
import { DiagnosticTestsOrderEntryRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Box, ChartHistory } from 'components/common';
import { ELSButton } from 'components/common/els';

export interface DiagnosticTestsTableProps {
  chartHistory: DiagnosticTestsOrderEntryRecord[];
  deleteHistory: Function;
  onStatusClick: Function;
}

class DiagnosticTestsTable extends Component<DiagnosticTestsTableProps> {
  statusRender = (row) => {
    return <ELSButton type="link" text={row.status} onClick={() => this.props.onStatusClick(row)} />;
  };
  render() {
    const { chartHistory, deleteHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true },
        { text: 'Order Description', field: 'orderDescription', align: ColumnAlign.Left },
        { text: 'Order Details', field: 'orderDetails', align: ColumnAlign.Left },
        { text: 'Special Instructions', field: 'notes', align: ColumnAlign.Left },
        { text: 'Status', field: 'status', customRender: this.statusRender },
        { field: 'actionDelete' }
      ],
      css: ['column__chart-time', '', '', '', '', 'column__delete'],
      data: chartHistory
    };
    return (
      <Box mt>
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

export default DiagnosticTestsTable;

import moment from 'moment';
import { ChartActionsComponentProps, ChartMetaFormField, GroupScaleQuestion } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import './delivery.room.record.scss';

export interface DeliveryRoomRecordViewProps {
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  groupScaleQuestion: GroupScaleQuestion[];
  formFieldMap: Map<string, ChartMetaFormField>;
}

const DeliveryRoomRecordView = (props: DeliveryRoomRecordViewProps) => {
  const { formFieldMap, formSubmittedCount, groupScaleQuestion } = props;
  return (
    <div className="delivery-room-record">
      <h1>Delivery Room Record</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <Box mt2>
        <h2>{SectionTitle.DELIVERY}</h2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.DAY)}
              formSubmittedCount={formSubmittedCount}
              options={{ disableBefore: moment().toDate() }}
            />
            <ErrorFormField
              className="u-els-margin-top"
              formField={formFieldMap.get(FormField.SEX)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.DELIVERY_PLACENTA)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE_DELIVERY)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE_DELIVERY_OTHER)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TIME)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PROVIDER)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.TYPE)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.CORD)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <Box>
          <h2>{SectionTitle.DURATION_OF_STAGE_OF_LABOR}</h2>
          <div className="stage">
            <Box mt>
              <h4>First Stage</h4>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.FIRST_STAGE_HOURS)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt>
              <h4>Second Stage</h4>
              <ELSFlex className="u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SECOND_STAGE_HOURS)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SECOND_STAGE_MINUTES)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box mt>
              <h4>Third Stage</h4>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.THIRD_STAGE_MINUTES)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </div>
          <ErrorFormField
            className="u-els-margin-top"
            formField={formFieldMap.get(FormField.COMPLICATIONS_DURING_DELIVERY)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.COMPLICATIONS_DURING_DELIVERY_TEXT)} formSubmittedCount={formSubmittedCount} />
        </Box>
      </Box>
      <SectionDivider />
      <h2>{SectionTitle.INITIAL_CARE_NEW_BORN}</h2>
      <h3>Apgar Scale</h3>
      <ErrorFormField formField={formFieldMap.get(FormField.APGAR_SCORE)} formFieldMap={formFieldMap} formSubmittedCount={formSubmittedCount} options={{ groupScaleQuestion }} />
      <h3>Resuscitation</h3>
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.RESUSCITATION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.COMMENTS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <h3>Medications</h3>
      <Box mt>
        <h4>Eye Prophylactic</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EYE_PROPHYLAXIS_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EYE_PROPHYLAXIS_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.EYE_PROPHYLAXIS_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>Vitamin K</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VITAMIN_K_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VITAMIN_K_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.VITAMIN_K_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>Naloxone</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NALOXONE_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NALOXONE_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.NALOXONE_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>Hepatitis B Vaccine</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_VACCINE_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_VACCINE_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_VACCINE_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>Hepatitis B Immune Globulin (HBIG)</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <h4>Other</h4>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OTHER_MEDICATIONS_TIME)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OTHER_MEDICATIONS_SITE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.OTHER_MEDICATIONS_INITIALS)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <SectionDivider />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
      <RelatedCharts chartIds={[NAV_ID.NEWBORN_ASSESSMENT]} />
    </div>
  );
};

DeliveryRoomRecordView.displayName = 'DeliveryRoomRecordView';
export default DeliveryRoomRecordView;

import { appHelper } from 'helpers';
import { ELSTextarea, TitleTooltip } from 'components/common';
import { FormFieldLabel } from './constants';

export interface InstructionInputProps {
  instruction: string;
  onInstructionChange: Function;
}

const InstructionInput = (props: InstructionInputProps) => (
  <div>
    <TitleTooltip
      className="u-els-margin-bottom"
      titleClassname="u-els-font-size-h3 title-tooltip__line-height-h3"
      title="Instructions"
      tooltip="Add instructions for your students on what they should chart during this assignment. Students will see the instructions when they launch the assignment."
    />
    <ELSTextarea
      id="assignment-instruction"
      textLabel={FormFieldLabel.ASSIGNMENT_INSTRUCTIONS}
      placeholder={appHelper.getPlaceholder(FormFieldLabel.ASSIGNMENT_INSTRUCTIONS)}
      value={props.instruction}
      onChange={props.onInstructionChange}
    />
  </div>
);

InstructionInput.displayName = 'InstructionInput';
export default InstructionInput;

import { isEqual } from 'lodash';
import { Component } from 'react';
import { ChartFragmentRS, ProviderOrderFragment } from 'models/api-response';
import { ChartComponentProps } from 'models/ui';
import { accessibilityHelper } from 'helpers';
import { withChartLogic } from 'components/common';
import { FormField, buildFormFields } from './ProviderOrder';
import ProviderOrderSidebarView, { ProviderOrderSidebarViewProps } from './ProviderOrderSidebarView';

export interface ProviderOrderSidebarProps extends ChartComponentProps {
  selectedRecord: ProviderOrderFragment;
  selectedNavId: string;
  onClose: Function;
  onUpdate: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

interface ProviderOrderSidebarState {
  chartingAt: string;
}

class ProviderOrderSidebar extends Component<ProviderOrderSidebarProps, ProviderOrderSidebarState> {
  static displayName = 'ProviderOrderSidebar';

  constructor(props) {
    super(props);
    this.state = {
      chartingAt: ''
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<ProviderOrderSidebarProps>) {
    if (this.props.selectedRecord && !isEqual(prevProps.selectedRecord, this.props.selectedRecord)) {
      this.setState({ chartingAt: this.props.selectedRecord.chartingAt });
      this.props.initState(buildFormFields(this.props.selectedRecord));
    }
  }

  handleUpdate = () => {
    const { formFieldMap, selectedRecord, onUpdate } = this.props;
    const fragment = {
      ...selectedRecord,
      chartData: {
        ...selectedRecord.chartData,
        [FormField.PROVIDER_ORDERS]: formFieldMap.get(FormField.PROVIDER_ORDERS)?.value,
        [FormField.SIGNATURE]: formFieldMap.get(FormField.SIGNATURE)?.value
      }
    };

    this.props.handleSaveClick([fragment], { afterSave: onUpdate });
  };

  render() {
    const viewProps: ProviderOrderSidebarViewProps = {
      open: !!this.props.selectedRecord,
      chartingAt: this.state.chartingAt,
      chartMetaFormFields: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      isDisabledSave: !this.props.hasUnsavedChanges,
      lastFocusableElement: accessibilityHelper.updateLastFocusableElement(this.props.hasUnsavedChanges),
      onClose: this.props.onClose,
      onUpdate: this.handleUpdate,
      sidebarProps: this.props.sidebarProps
    };
    return <ProviderOrderSidebarView {...viewProps} />;
  }
}

export { ProviderOrderSidebar as BaseProviderOrderSidebar };
export default withChartLogic(ProviderOrderSidebar);

import { DiagnosticTestFragment } from 'models/api-response';
import { ChartMetaFormField } from 'models/ui';
import { LastElement } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ErrorFormField, Sidebar } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './DiagnosticTest';

export interface EditDiagnosticTestSidebarViewProps {
  open: boolean;
  diagnosticTest: DiagnosticTestFragment;
  formSubmittedCount: number;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  onSaveClick: Function;
  onCloseClick: Function;
  sidebarProps: Function;
}

const EditDiagnosticTestSidebarView = ({
  open,
  diagnosticTest,
  formSubmittedCount,
  chartMetaFormFields,
  onSaveClick,
  onCloseClick,
  sidebarProps
}: EditDiagnosticTestSidebarViewProps) => (
  <Sidebar
    open={open}
    onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
    lastEnabledElement={LastElement.ENABLED_SAVE_BUTTON}
    sidebarProps={sidebarProps}
  >
    <Box mt3>
      <h2>Edit Diagnostic Test</h2>
      <h3 className="u-els-margin-top">{diagnosticTest?.chartData.diagnosticTest}</h3>
    </Box>
    <div className="sidebar__divider" />
    <ELSFlex className="u-flex-grid-2x" wrap>
      <ELSFlexItem w="1o1">
        <div className="form-input__date">
          <ErrorFormField formField={chartMetaFormFields.get(FormField.DATE_OF_TEST)} formSubmittedCount={formSubmittedCount} />
        </div>
      </ELSFlexItem>
      <ELSFlexItem w="1o1">
        <ErrorFormField formField={chartMetaFormFields.get(FormField.DEFINITION_OF_TEST)} formSubmittedCount={formSubmittedCount} />
      </ELSFlexItem>
      <ELSFlexItem w="1o1">
        <ErrorFormField formField={chartMetaFormFields.get(FormField.TEST_ORDERED_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
      </ELSFlexItem>
      <ELSFlexItem w="1o1">
        <ErrorFormField formField={chartMetaFormFields.get(FormField.FINDINGS_AND_RESULTS)} formSubmittedCount={formSubmittedCount} />
      </ELSFlexItem>
    </ELSFlex>
    <Box mt3 mb>
      <ELSButton className="sng-button" text="Save" onClick={onSaveClick} />
    </Box>
  </Sidebar>
);

EditDiagnosticTestSidebarView.displayName = 'EditDiagnosticTestSidebarView';
export default EditDiagnosticTestSidebarView;

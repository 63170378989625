import { Component } from 'react';
import { SkinnyAssignment } from 'models/ui';
import { resetEmptyEhrDemoStorage } from 'helpers/assignment.helper';
import { assignmentService, logService } from 'services';
import { Box } from 'components/common';
import { ELSPageLoader, ELSTokenHelper } from 'components/common/els';

export interface StudentHomeProps {
  selectedAssignment: SkinnyAssignment;
}

export interface StudentHomeViewState {
  assessmentId: number;
}

class StudentHome extends Component<StudentHomeProps, StudentHomeViewState> {
  static displayName = 'StudentHome';

  componentDidMount() {
    const { assignmentId } = this.props.selectedAssignment;
    const { userId } = ELSTokenHelper.getUser();
    assignmentService
      .getAssessmentByUserIdAndAssignmentId(assignmentId, userId)
      .then((res) => res.data)
      .then((assessment) => this.setState({ assessmentId: assessment.id }))
      .catch((err) => logService.log(err.message));
    resetEmptyEhrDemoStorage();
  }

  render() {
    const { selectedAssignment } = this.props;
    const assessmentId = this.state?.assessmentId;
    if (!assessmentId) {
      return <ELSPageLoader />;
    }

    return (
      <Box p3>
        <h2 className="u-els-color-primary">Student Homepage</h2>
        {selectedAssignment && (
          <Box mt2>
            <div className="u-els-bold">Selected Assignment</div>
            <div>
              <span className="u-els-bold">ID: </span>
              <span>{selectedAssignment.assignmentId}</span>
            </div>
            <div>
              <span className="u-els-bold">Title: </span>
              <span>{selectedAssignment.title}</span>
            </div>
          </Box>
        )}
        {selectedAssignment?.assignmentId ? (
          <ul className="u-els-margin-top-2x">
            <li>
              <a href={`/#/clinical/${assessmentId}/clinical-setup`} className="c-els-link">
                Clinical Setup
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/vital-signs/vital-signs`} className="c-els-link">
                Vital Signs
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/pre-clinical-manager/diagnosis`} className="c-els-link">
                Pre-Clinical Manager # Diagnosis
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/provider-chart/provider-order`} className="c-els-link">
                Provider Charts # Provider Orders
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/patient-charting/admission-history/admission-data`} className="c-els-link">
                Patient Charting # Admission History # Admission Data
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/patient-charting/system-assessment/symptom-analysis`} className="c-els-link">
                Patient Charting # System Assessment # Symptom Analysis
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/patient-charting/system-nursing-intervention/neurological-intervention`} className="c-els-link">
                Patient Charting # System Nursing Interventions # Neurological Interventions
              </a>
            </li>
            <li>
              <a href={`/#/clinical/${assessmentId}/patient-charting/special-charts/miscellaneous-nursing-notes`} className="c-els-link">
                Patient Charting # Special Charts # Miscellaneous Nursing Notes
              </a>
            </li>
          </ul>
        ) : null}
      </Box>
    );
  }
}

export default StudentHome;

import './form.field.divider.scss';

const FormFieldDivider = () => (
  <div className="form-field-divider">
    <div className="c-els-divider c-els-divider--1o2 u-els-color-n9" />
  </div>
);

FormFieldDivider.displayName = 'FormFieldDivider';
export default FormFieldDivider;

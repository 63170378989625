import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './SmokingScreen';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface SmokingScreenReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const SmokingScreenReadOnly = (props: SmokingScreenReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Smoking Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.SMOKING_SCREEN}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SMOKING_SCREEN}
                  formFieldId={FormField.LIVE_WITH_SMOKER}
                  customTitle="Do you live with a smoker?"
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SMOKING_SCREEN} formFieldId={FormField.IS_SMOKER} customTitle="Where is the smoker?" />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.SMOKER_STATUS}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SMOKER_STATUS} formFieldId={FormField.SMOKER_STATUS} customTitle="" />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.USING}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.TOBACCO_TYPE} customTitle="What type of tobacco?" />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.TOBACCO_TYPE_OTHER} customTitle="Other" notRenderBlankField />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.USING}
                  formFieldId={FormField.TOBACCO_HOW_MUCH}
                  customTitle="How much tobacco do you use?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.USING}
                  formFieldId={FormField.TOBACCO_HOW_LONG}
                  customTitle="How long have you been smoking?"
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.TOBACCO_QUIT} customTitle="Do you plan to quit?" />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.USING} formFieldId={FormField.TOBACCO_QUIT_OTHER} customTitle="Other" notRenderBlankField />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.ALREADY_QUIT}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ALREADY_QUIT}
                  formFieldId={FormField.TOBACCO_QUIT_WHEN}
                  customTitle="When did you quit using tobacco?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ALREADY_QUIT}
                  formFieldId={FormField.TEMPTATION_TRIGGER}
                  customTitle="Temptation Triggers"
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.PLAN_QUIT}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.PLAN_QUIT}
                  formFieldId={FormField.QUIT_MOTIVATION}
                  customTitle="What is your motivation for quitting now?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.PLAN_QUIT}
                  formFieldId={FormField.QUIT_CONCERN}
                  customTitle="Do you have any concerns about quitting?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.PLAN_QUIT}
                  formFieldId={FormField.QUIT_START_AGAIN}
                  customTitle="If quit before, why did you start using tobacco again?"
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.PLAN_QUIT} formFieldId={FormField.QUIT_DATE} customTitle="When is your quit date?" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.PLAN_QUIT}
                  formFieldId={FormField.USE_NICOTINE_REPLACEMENT}
                  customTitle="Do you use a nicotine replacement product?"
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

SmokingScreenReadOnly.displayName = 'SmokingScreenReadOnly';
export default SmokingScreenReadOnly;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, GroupScaleQuestion } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import DeliveryRoomRecordView, { DeliveryRoomRecordViewProps } from './DeliveryRoomRecordView';

interface DeliveryRoomRecordState {
  isLocked: boolean;
  fragment: ChartFragment;
}

class DeliveryRoomRecord extends Component<ChartComponentProps, DeliveryRoomRecordState> {
  static displayName = 'DeliveryRoomRecord';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null
    };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField, getFragmentValue, getFragmentContentIds, getScaleContent } = chartHelper;
    const { fragment } = this.state;
    const dataMap = new Map();
    const radioChoices = [
      {
        name: FormField.SEX,
        label: FormFieldLabel.SEX,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DELIVERY, FormField.SEX)
      },
      {
        name: FormField.COMPLICATIONS_DURING_DELIVERY,
        label: FormFieldLabel.COMPLICATIONS_DURING_DELIVERY,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DURATION_OF_STAGE_OF_LABOR, FormField.COMPLICATIONS_DURING_DELIVERY)
      }
    ];
    const textBoxes = [
      {
        name: FormField.TYPE_DELIVERY_OTHER,
        label: FormFieldLabel.TYPE_DELIVERY_OTHER,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.TYPE_DELIVERY_OTHER)
      },
      {
        name: FormField.EYE_PROPHYLAXIS_SITE,
        label: FormFieldLabel.MEDICATIONS_SITE,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.EYE_PROPHYLAXIS_SITE)
      },
      {
        name: FormField.EYE_PROPHYLAXIS_INITIALS,
        label: FormFieldLabel.MEDICATIONS_INITIALS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.EYE_PROPHYLAXIS_INITIALS)
      },
      {
        name: FormField.VITAMIN_K_SITE,
        label: FormFieldLabel.MEDICATIONS_SITE,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.VITAMIN_K_SITE)
      },
      {
        name: FormField.VITAMIN_K_INITIALS,
        label: FormFieldLabel.MEDICATIONS_INITIALS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.VITAMIN_K_INITIALS)
      },
      {
        name: FormField.NALOXONE_SITE,
        label: FormFieldLabel.MEDICATIONS_SITE,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.NALOXONE_SITE)
      },
      {
        name: FormField.NALOXONE_INITIALS,
        label: FormFieldLabel.MEDICATIONS_INITIALS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.NALOXONE_INITIALS)
      },
      {
        name: FormField.HEPATITIS_B_VACCINE_SITE,
        label: FormFieldLabel.MEDICATIONS_SITE,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_VACCINE_SITE)
      },
      {
        name: FormField.HEPATITIS_B_VACCINE_INITIALS,
        label: FormFieldLabel.MEDICATIONS_INITIALS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_VACCINE_INITIALS)
      },
      {
        name: FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE,
        label: FormFieldLabel.MEDICATIONS_SITE,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE)
      },
      {
        name: FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS,
        label: FormFieldLabel.MEDICATIONS_INITIALS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS)
      },
      {
        name: FormField.OTHER_MEDICATIONS_SITE,
        label: FormFieldLabel.MEDICATIONS_SITE,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.OTHER_MEDICATIONS_SITE)
      },
      {
        name: FormField.OTHER_MEDICATIONS_INITIALS,
        label: FormFieldLabel.MEDICATIONS_INITIALS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.OTHER_MEDICATIONS_INITIALS)
      },
      {
        name: FormField.DELIVERY_PLACENTA,
        label: FormFieldLabel.DELIVERY_PLACENTA,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.DELIVERY_PLACENTA)
      },
      {
        name: FormField.PROVIDER,
        label: FormFieldLabel.PROVIDER,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.PROVIDER)
      },
      {
        name: FormField.TYPE,
        label: FormFieldLabel.TYPE,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.TYPE)
      },
      {
        name: FormField.CORD,
        label: FormFieldLabel.CORD,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.CORD)
      }
    ];
    const checkBoxes = [
      {
        name: FormField.TYPE_DELIVERY,
        label: FormFieldLabel.TYPE_DELIVERY,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DELIVERY, FormField.TYPE_DELIVERY)
      },
      {
        name: FormField.RESUSCITATION,
        contentIds: getFragmentContentIds(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.RESUSCITATION)
      }
    ];
    const textAreas = [
      {
        name: FormField.COMPLICATIONS_DURING_DELIVERY_TEXT,
        label: FormFieldLabel.COMPLICATIONS_DURING_DELIVERY_TEXT,
        value: getFragmentValue(fragment, SectionTitle.DURATION_OF_STAGE_OF_LABOR, FormField.COMPLICATIONS_DURING_DELIVERY_TEXT)
      },
      {
        name: FormField.COMMENTS,
        label: FormFieldLabel.COMMENTS,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.COMMENTS)
      }
    ];

    const timeTextBoxes = [
      {
        name: FormField.TIME,
        label: FormFieldLabel.TIME,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.TIME) || '00:00'
      },
      {
        name: FormField.EYE_PROPHYLAXIS_TIME,
        label: FormFieldLabel.MEDICATIONS_TIME,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.EYE_PROPHYLAXIS_TIME) || '00:00'
      },
      {
        name: FormField.VITAMIN_K_TIME,
        label: FormFieldLabel.MEDICATIONS_TIME,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.VITAMIN_K_TIME) || '00:00'
      },
      {
        name: FormField.NALOXONE_TIME,
        label: FormFieldLabel.MEDICATIONS_TIME,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.NALOXONE_TIME) || '00:00'
      },
      {
        name: FormField.HEPATITIS_B_VACCINE_TIME,
        label: FormFieldLabel.MEDICATIONS_TIME,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_VACCINE_TIME) || '00:00'
      },
      {
        name: FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME,
        label: FormFieldLabel.MEDICATIONS_TIME,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME) || '00:00'
      },
      {
        name: FormField.OTHER_MEDICATIONS_TIME,
        label: FormFieldLabel.MEDICATIONS_TIME,
        value: getFragmentValue(fragment, SectionTitle.INITIAL_CARE_NEW_BORN, FormField.OTHER_MEDICATIONS_TIME) || '00:00'
      }
    ];

    const dropdowns = [
      {
        name: FormField.FIRST_STAGE_HOURS,
        label: FormFieldLabel.HOURS,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DURATION_OF_STAGE_OF_LABOR, FormField.FIRST_STAGE_HOURS)
      },
      {
        name: FormField.SECOND_STAGE_HOURS,
        label: FormFieldLabel.HOURS,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DURATION_OF_STAGE_OF_LABOR, FormField.SECOND_STAGE_HOURS)
      },
      {
        name: FormField.SECOND_STAGE_MINUTES,
        label: FormFieldLabel.MINUTES,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DURATION_OF_STAGE_OF_LABOR, FormField.SECOND_STAGE_MINUTES)
      },
      {
        name: FormField.THIRD_STAGE_MINUTES,
        label: FormFieldLabel.MINUTES,
        contentIds: getFragmentContentIds(fragment, SectionTitle.DURATION_OF_STAGE_OF_LABOR, FormField.THIRD_STAGE_MINUTES)
      }
    ];

    dropdowns.forEach(({ name, label, contentIds }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          contentIds,
          type: FormFieldInputType.DROPDOWN
        })
      )
    );

    radioChoices.forEach(({ name, label, contentIds }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          contentIds,
          type: FormFieldInputType.RADIO_CHOICE
        })
      )
    );

    textBoxes.forEach(({ name, label, value }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          value,
          type: FormFieldInputType.TEXT_BOX
        })
      )
    );

    checkBoxes.forEach(({ name, label, contentIds }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          contentIds,
          type: FormFieldInputType.CHECK_BOX
        })
      )
    );

    textAreas.forEach(({ name, label, value }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          value,
          type: FormFieldInputType.TEXT_AREA
        })
      )
    );

    timeTextBoxes.forEach(({ name, label, value }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          label,
          value,
          type: FormFieldInputType.TIME
        })
      )
    );

    dataMap.set(
      FormField.APGAR_SCORE,
      createFormField({
        name: FormField.APGAR_SCORE,
        type: FormFieldInputType.SCALE,
        scaleContent: getScaleContent(fragment, SectionTitle.APGAR_SCALE)
      })
    );

    dataMap.set(
      FormField.DAY,
      createFormField({
        name: FormField.DAY,
        type: FormFieldInputType.TEXT_BOX,
        label: FormFieldLabel.DAY,
        value: getFragmentValue(fragment, SectionTitle.DELIVERY, FormField.DAY)
      })
    );

    return dataMap;
  };

  getGroupScaleQuestion = (): GroupScaleQuestion[] => {
    return [
      {
        label: 'Heart Rate',
        formFields: [FormField.HEART_RATE_1_MINUTES, FormField.HEART_RATE_5_MINUTES, FormField.HEART_RATE_10_MINUTES]
      },
      {
        label: 'Respiratory Rate',
        formFields: [FormField.RESPIRATORY_RATE_1_MINUTES, FormField.RESPIRATORY_RATE_5_MINUTES, FormField.RESPIRATORY_RATE_10_MINUTES]
      },
      {
        label: 'Muscle tone',
        formFields: [FormField.MUSCLE_TONE_1_MINUTES, FormField.MUSCLE_TONE_5_MINUTES, FormField.MUSCLE_TONE_10_MINUTES]
      },
      {
        label: 'Reflex irritability',
        formFields: [FormField.REFLEX_IRRITABILITY_1_MINUTES, FormField.REFLEX_IRRITABILITY_5_MINUTES, FormField.REFLEX_IRRITABILITY_10_MINUTES]
      },
      {
        label: 'Color',
        formFields: [FormField.COLOR_1_MINUTES, FormField.COLOR_5_MINUTES, FormField.COLOR_10_MINUTES]
      }
    ];
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: SectionTitle.DELIVERY_ROOM_RECORD,
      records: [
        {
          sectionTitle: SectionTitle.DELIVERY,
          records: [
            buildPatientRecord(formFieldMap, FormField.DAY),
            buildPatientRecord(formFieldMap, FormField.SEX),
            buildPatientRecord(formFieldMap, FormField.DELIVERY_PLACENTA),
            buildPatientRecord(formFieldMap, FormField.TIME),
            buildPatientRecord(formFieldMap, FormField.PROVIDER),
            buildPatientRecord(formFieldMap, FormField.TYPE),
            buildPatientRecord(formFieldMap, FormField.CORD)
          ]
        },
        {
          sectionTitle: SectionTitle.TYPE_OF_DELIVERY,
          records: [...buildPatientRecords(formFieldMap, FormField.TYPE_DELIVERY), buildPatientRecord(formFieldMap, FormField.TYPE_DELIVERY_OTHER)]
        },
        {
          sectionTitle: SectionTitle.DURATION_OF_STAGE_OF_LABOR,
          records: [
            buildPatientRecord(formFieldMap, FormField.FIRST_STAGE_HOURS),
            buildPatientRecord(formFieldMap, FormField.SECOND_STAGE_HOURS),
            buildPatientRecord(formFieldMap, FormField.SECOND_STAGE_MINUTES),
            buildPatientRecord(formFieldMap, FormField.THIRD_STAGE_MINUTES),
            buildPatientRecord(formFieldMap, FormField.COMPLICATIONS_DURING_DELIVERY),
            buildPatientRecord(formFieldMap, FormField.COMPLICATIONS_DURING_DELIVERY_TEXT)
          ]
        },
        {
          sectionTitle: SectionTitle.INITIAL_CARE_NEW_BORN,
          records: [buildPatientRecord(formFieldMap, FormField.COMMENTS)]
        },
        {
          sectionTitle: SectionTitle.EYE_PROPHYLACTIC,
          records: [
            buildPatientRecord(formFieldMap, FormField.EYE_PROPHYLAXIS_TIME),
            buildPatientRecord(formFieldMap, FormField.EYE_PROPHYLAXIS_SITE),
            buildPatientRecord(formFieldMap, FormField.EYE_PROPHYLAXIS_INITIALS)
          ]
        },
        {
          sectionTitle: SectionTitle.VITAMIN_K,
          records: [
            buildPatientRecord(formFieldMap, FormField.VITAMIN_K_TIME),
            buildPatientRecord(formFieldMap, FormField.VITAMIN_K_SITE),
            buildPatientRecord(formFieldMap, FormField.VITAMIN_K_INITIALS)
          ]
        },
        {
          sectionTitle: SectionTitle.NALOXONE,
          records: [
            buildPatientRecord(formFieldMap, FormField.NALOXONE_TIME),
            buildPatientRecord(formFieldMap, FormField.NALOXONE_SITE),
            buildPatientRecord(formFieldMap, FormField.NALOXONE_INITIALS)
          ]
        },
        {
          sectionTitle: SectionTitle.HBV,
          records: [
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_VACCINE_TIME),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_VACCINE_SITE),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_VACCINE_INITIALS)
          ]
        },
        {
          sectionTitle: SectionTitle.HBIG,
          records: [
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_TIME),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_SITE),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_IMMUNE_GLOBULIN_INITIALS)
          ]
        },
        {
          sectionTitle: SectionTitle.Other,
          records: [
            buildPatientRecord(formFieldMap, FormField.OTHER_MEDICATIONS_TIME),
            buildPatientRecord(formFieldMap, FormField.OTHER_MEDICATIONS_SITE),
            buildPatientRecord(formFieldMap, FormField.OTHER_MEDICATIONS_INITIALS)
          ]
        },
        {
          sectionTitle: SectionTitle.RESUSCITATION,
          records: [...buildPatientRecords(formFieldMap, FormField.RESUSCITATION)]
        },
        {
          sectionTitle: SectionTitle.HEART_RATE,
          records: [
            buildPatientRecord(formFieldMap, FormField.HEART_RATE_1_MINUTES),
            buildPatientRecord(formFieldMap, FormField.HEART_RATE_5_MINUTES),
            buildPatientRecord(formFieldMap, FormField.HEART_RATE_10_MINUTES)
          ]
        },
        {
          sectionTitle: SectionTitle.RESPIRATORY_RATE,
          records: [
            buildPatientRecord(formFieldMap, FormField.RESPIRATORY_RATE_1_MINUTES),
            buildPatientRecord(formFieldMap, FormField.RESPIRATORY_RATE_5_MINUTES),
            buildPatientRecord(formFieldMap, FormField.RESPIRATORY_RATE_10_MINUTES)
          ]
        },
        {
          sectionTitle: SectionTitle.MUSCLE_TONE,
          records: [
            buildPatientRecord(formFieldMap, FormField.MUSCLE_TONE_1_MINUTES),
            buildPatientRecord(formFieldMap, FormField.MUSCLE_TONE_5_MINUTES),
            buildPatientRecord(formFieldMap, FormField.MUSCLE_TONE_10_MINUTES)
          ]
        },
        {
          sectionTitle: SectionTitle.REFLEX_IRRITABILITY,
          records: [
            buildPatientRecord(formFieldMap, FormField.REFLEX_IRRITABILITY_1_MINUTES),
            buildPatientRecord(formFieldMap, FormField.REFLEX_IRRITABILITY_5_MINUTES),
            buildPatientRecord(formFieldMap, FormField.REFLEX_IRRITABILITY_10_MINUTES)
          ]
        },
        {
          sectionTitle: SectionTitle.COLOR,
          records: [
            buildPatientRecord(formFieldMap, FormField.COLOR_1_MINUTES),
            buildPatientRecord(formFieldMap, FormField.COLOR_5_MINUTES),
            buildPatientRecord(formFieldMap, FormField.COLOR_10_MINUTES)
          ]
        },
        {
          sectionTitle: 'Total Apgar Scale',
          records: [
            buildPlainRecord({
              formField: FormField.APGAR_SCORE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.APGAR_SCORE))?.score?.value,
              linkedFormFieldIds: [
                FormField.HEART_RATE_1_MINUTES,
                FormField.HEART_RATE_5_MINUTES,
                FormField.HEART_RATE_10_MINUTES,
                FormField.RESPIRATORY_RATE_1_MINUTES,
                FormField.RESPIRATORY_RATE_5_MINUTES,
                FormField.RESPIRATORY_RATE_10_MINUTES,
                FormField.MUSCLE_TONE_1_MINUTES,
                FormField.MUSCLE_TONE_5_MINUTES,
                FormField.MUSCLE_TONE_10_MINUTES,
                FormField.REFLEX_IRRITABILITY_1_MINUTES,
                FormField.REFLEX_IRRITABILITY_5_MINUTES,
                FormField.REFLEX_IRRITABILITY_10_MINUTES,
                FormField.COLOR_1_MINUTES,
                FormField.COLOR_5_MINUTES,
                FormField.COLOR_10_MINUTES
              ]
            })
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildFormFields(),
      afterSave: this.props.navigateToSavedPatientCharting
    });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildFormFields());

  render() {
    const { isLocked } = this.state;
    const { formFieldMap, formSubmittedCount, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps: DeliveryRoomRecordViewProps = {
      chartActionsProps,
      formSubmittedCount,
      formFieldMap,
      groupScaleQuestion: this.getGroupScaleQuestion()
    };
    return <DeliveryRoomRecordView {...viewProps} />;
  }
}

export { DeliveryRoomRecord as BaseDeliveryRoomRecord };
export default compose(withSavedPatientChartsPage, withChartLogic)(DeliveryRoomRecord);

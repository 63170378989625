import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ErrorFormField } from 'components/common';
import { ELSButton } from 'components/common/els';
import { FormField } from './ProviderOrder';

export interface ProviderOrderFormViewProps {
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  isDisabledSave?: boolean;
  onSave?: Function;
  chartActionsProps?: ChartActionsComponentProps;
  isSidebarView: boolean;
}

export const defaultProps = {
  isSidebarView: false
};

const ProviderOrderFormView = (props: ProviderOrderFormViewProps) => (
  <>
    <div className="u-els-width-2o3">
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.PROVIDER_ORDERS)} formSubmittedCount={props.formSubmittedCount} />
    </div>
    <div className="u-els-width-1o3">
      <ErrorFormField formField={props.chartMetaFormFields.get(FormField.SIGNATURE)} formSubmittedCount={props.formSubmittedCount} />
    </div>
    {props.isSidebarView ? (
      <Box mb className="sng-form-buttons">
        <ELSButton attrType="submit" text="Save" isDisabled={props.isDisabledSave} onClick={props.onSave} />
      </Box>
    ) : (
      <ChartActions {...props.chartActionsProps} />
    )}
  </>
);

ProviderOrderFormView.displayName = 'ProviderOrderFormView';
ProviderOrderFormView.defaultProps = defaultProps;

export default ProviderOrderFormView;

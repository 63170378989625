import { useEffect, useState } from 'react';
import { ClinicalKeyLink } from 'models/ui';
import { apiHelper, chartHelper } from 'helpers';
import { assignmentService } from 'services';
import { ELSIcon, ELSTooltip } from 'components/common/els';

export interface ClinicalKeyDrugInformationProps {
  areaName?: string;
  showLink?: boolean;
  drugName?: string;
}

const ClinicalKeyDrugInformation = (props: ClinicalKeyDrugInformationProps) => {
  const [link, setLink] = useState<ClinicalKeyLink[]>();

  useEffect(() => {
    if (props.drugName && props.showLink) {
      const linksArr = [];
      assignmentService
        .getDrugInfoURL(chartHelper.filterDrugByFirstName(props.drugName))
        .then((res) => {
          if (res.data) {
            linksArr.push({ name: props.drugName, url: res.data });
            setLink(linksArr);
          }
        })
        .catch((err) => apiHelper.showApiError(`can not load clinical pharmacology link ${err}`));
    }
  }, [props.drugName, props.showLink]);

  const tooltipRenderer = () => {
    const findDrugLink = link?.find((obj) => props.drugName === obj.name);

    return (
      <div>
        <p>{`Find more information about ${props.areaName || props.drugName} in ClinicalKey for Nursing, a resource that will help you with clinical concepts.`}</p>
        {props.showLink && (
          <div className="u-els-margin-top-1o2">
            <a href={findDrugLink?.url || ''} target="_blank" rel="noopener noreferrer">
              <span className="u-els-hide-visually">Open a new tab</span>
              <span>{props.drugName}</span>
              <div className="u-els-display-inline-block u-els-margin-left-1o2">
                <ELSIcon prefix="hmds" name="new-window" color="secondary" title="" size="1x" />
              </div>
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="u-els-display-inline-block u-els-margin-left-1o8">
      <ELSTooltip wrapperClassName="chart-input__label-tooltip" content={tooltipRenderer()} showClose>
        <ELSIcon prefix="gizmo" name="information" color="secondary" title="" size="1x" />
      </ELSTooltip>
    </div>
  );
};

ClinicalKeyDrugInformation.displayName = 'ClinicalKeyDrugInformation';
export default ClinicalKeyDrugInformation;

import { isEmpty } from 'lodash';
import { ChartFragment } from 'models/api-response';
import { PatientContext } from 'models/ui';
import { ChartLabel, PEDIATRIC_AGE_UNDER_16 } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

interface PsychosocialScreenReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
}

interface Params {
  chartFragment: ChartFragment;
  sectionTitle: string;
}

export const renderReadOnlyScale = ({ chartFragment, sectionTitle }: Params) => {
  const section = chartFragment.chartData?.records?.find((item) => item.sectionTitle === sectionTitle);
  return section?.records
    ?.filter((item) => !isEmpty(item.content))
    .map((item) => (
      <Box key={item.id} pt pb>
        <h4>{item.title}</h4>
        <MultiLineWithEmpty text={item.content?.label} />
      </Box>
    ));
};

const PsychosocialScreenReadOnly = (props: PsychosocialScreenReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext } = props;
  const isAdultOrAuthor = patientContext?.ageInDays >= PEDIATRIC_AGE_UNDER_16 || patientContext.isAuthor;
  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Psychosocial Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.APPEARANCE}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.APPEARANCE}
                  formFieldId={FormField.APPEARANCE_GROOMING}
                  customTitle={FormFieldLabel.APPEARANCE_GROOMING}
                />
                {isAdultOrAuthor && (
                  <>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.BEHAVIORAL_STATUS}
                      formFieldId={FormField.BEHAVIORAL_STATUS}
                      customTitle={FormFieldLabel.BEHAVIORAL_STATUS}
                    />
                  </>
                )}
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.EMOTIONAL_STATUS}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.EMOTIONAL_STATUS}
                  formFieldId={FormField.EMOTIONAL_STATUS_FEEL_RIGHT_NOW}
                  customTitle={FormFieldLabel.EMOTIONAL_STATUS_FEEL_RIGHT_NOW}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.EMOTIONAL_STATUS}
                  formFieldId={FormField.EMOTIONAL_STATUS_BODY_LANGUAGE}
                  customTitle={FormFieldLabel.EMOTIONAL_STATUS_BODY_LANGUAGE}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.EMOTIONAL_STATUS}
                  formFieldId={FormField.PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS}
                  customTitle={FormFieldLabel.PAST_2_WEEK_DOWN_DEPRESSED_HOPELESS}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.EMOTIONAL_STATUS}
                  formFieldId={FormField.PAST_2_WEEK_INTEREST_THINGS}
                  customTitle={FormFieldLabel.PAST_2_WEEK_INTEREST_THINGS}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.COGNITIVE_ABILITY}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ORIENTATION}
                  formFieldId={FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_TIME}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ORIENTATION}
                  formFieldId={FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PERSON}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ORIENTATION}
                  formFieldId={FormField.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ORIENTATION_ORIENTED_TO_PLACE}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex>
              <ELSFlexItem w="1o3" md="1o1">
                {isAdultOrAuthor && (
                  <>
                    <h3>{SectionTitle.THOUGHT_FORMULATION}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.THOUGHT_FORMULATION}
                      formFieldId={FormField.THOUGHT_FORMULATION}
                      customTitle={SectionTitle.THOUGHT_FORMULATION}
                    />
                    <h3>{SectionTitle.THOUGHT_CONTENT}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.THOUGHT_CONTENT}
                      formFieldId={FormField.THOUGHT_CONTENT}
                      customTitle={SectionTitle.THOUGHT_CONTENT}
                    />
                  </>
                )}
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.MEMORY}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.MEMORY}
                  formFieldId={FormField.COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL5_OBJECT_NAME_3MINUTE}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.MEMORY}
                  formFieldId={FormField.COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL_PLACE_OF_BIRTH}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.MEMORY}
                  formFieldId={FormField.COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL_YEAR_BORN}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.MEMORY}
                  formFieldId={FormField.COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_MEMORY_RECALL_MOTHER_MAIDEN_NAME}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.ABSTRACT_REASONING}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ABSTRACT_REASONING}
                  formFieldId={FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ABSTRACT_REASONING_BIRD_HAND_WORTH_TWO_BRUSH}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ABSTRACT_REASONING}
                  formFieldId={FormField.COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ABSTRACT_REASONING_ROLLING_STONE_GATHER_NO_MOSS}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.ATTENTION_SPAN}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ATTENTION_SPAN}
                  formFieldId={FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_DAY_OF_WEEK}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.ATTENTION_SPAN}
                  formFieldId={FormField.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD}
                  customTitle={FormFieldLabel.COGNITIVE_ABILITY_ATTENTION_SPAN_CORRECTLY_SPELL_WORLD_BACKWARD}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.JUDGMENT}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.JUDGMENT}
                  formFieldId={FormField.JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS}
                  customTitle={FormFieldLabel.JUDGMENT_PATIENT_MEETING_SOCIAL_FAMILY_OBLIGATIONS}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.JUDGMENT}
                  formFieldId={FormField.JUDGMENT_PLANS_FUTURE}
                  customTitle={FormFieldLabel.JUDGMENT_PLANS_FUTURE}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.SPEECH_LANGUAGE_ARTICULATION}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SPEECH_LANGUAGE_ARTICULATION}
                  formFieldId={FormField.SPEECH_LANGUAGE_ARTICULATION}
                  customTitle={ChartLabel.MARK_ALL_THAT_APPLY}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SPEECH_LANGUAGE_ARTICULATION}
                  formFieldId={FormField.SPEECH_LANGUAGE_ARTICULATION_OTHER}
                  customTitle={FormFieldLabel.SPEECH_LANGUAGE_ARTICULATION_OTHER}
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.SPEECH_LANGUAGE_COHERENCE}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SPEECH_LANGUAGE_COHERENCE}
                  formFieldId={FormField.SPEECH_LANGUAGE_COHERENCE}
                  customTitle={ChartLabel.MARK_ALL_THAT_APPLY}
                />
              </ELSFlexItem>
            </ELSFlex>

            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                {isAdultOrAuthor && (
                  <>
                    <h3>{SectionTitle.PATIENT_ASSETS}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.PATIENT_ASSETS}
                      formFieldId={FormField.PATIENT_ASSETS}
                      customTitle={SectionTitle.PATIENT_ASSETS}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.PROBLEM_AREAS}
                      formFieldId={FormField.PROBLEM_AREAS}
                      customTitle={SectionTitle.PROBLEM_AREAS}
                    />
                  </>
                )}
              </ELSFlexItem>
            </ELSFlex>

            {isAdultOrAuthor && (
              <>
                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{SectionTitle.DECISION_MAKING_RATING}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.DECISION_MAKING_RATING}
                      formFieldId={FormField.DECISION_MAKING_RATING}
                      customTitle={SectionTitle.DECISION_MAKING_RATING}
                    />
                  </ELSFlexItem>
                </ELSFlex>

                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{SectionTitle.TREATMENT_PLAN_RECOMMENDATIONS}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.TREATMENT_PLAN_RECOMMENDATIONS}
                      formFieldId={FormField.TREATMENT_PLAN_RECOMMENDATIONS}
                      customTitle={SectionTitle.TREATMENT_PLAN_RECOMMENDATIONS}
                    />
                  </ELSFlexItem>
                </ELSFlex>

                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{SectionTitle.CURRENT_SAFETY_ISSUES}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_SAFETY_ISSUES}
                      formFieldId={FormField.CURRENT_SAFETY_ISSUES}
                      customTitle={SectionTitle.CURRENT_SAFETY_ISSUES}
                    />
                  </ELSFlexItem>
                </ELSFlex>

                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{SectionTitle.PSYCHIATRIC_HISTORY}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                      formFieldId={FormField.PSYCHIATRIC_HISTORY}
                      customTitle={SectionTitle.PSYCHIATRIC_HISTORY}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.SUICIDE_HISTORY}
                      formFieldId={FormField.SUICIDE_HISTORY}
                      customTitle={SectionTitle.SUICIDE_HISTORY}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.LIFE_STRESSORS}
                      formFieldId={FormField.LIFE_STRESSORS}
                      customTitle={SectionTitle.LIFE_STRESSORS}
                    />
                  </ELSFlexItem>
                </ELSFlex>

                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{SectionTitle.SAFETY_EVALUATION}</h3>
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.SAFETY_EVALUATION}
                      formFieldId={FormField.SAFETY_EVALUATIONS_SUICIDE_HOMICIDE_IMPULSES}
                      customTitle={SectionTitle.SAFETY_EVALUATION}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.BIRTH_ORDER}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.GROW_UP}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_NAME_1}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_1}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_1}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_NAME_2}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_2}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_2}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_NAME_3}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_3}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_3}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_NAME_4}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_4}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_4}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_NAME_5}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_LIVING_DECEASED_5}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PARENT_SIBLINGS_INFO_PSYCHIATRIC_DIAGNOSIS_5}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.CURRENT_LIVING_SITUATION}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.CURRENT_EMPLOYMENT_STATUS}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.FAMILY_ORIGIN}
                      formFieldId={FormField.PREVIOUS_OCCUPATIONS}
                      customTitle={SectionTitle.FAMILY_ORIGIN}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_PSYCHOTHERAPIST}
                      formFieldId={FormField.CURRENT_PSYCHOTHERAPIST_NAME}
                      customTitle={SectionTitle.CURRENT_PSYCHOTHERAPIST}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_PSYCHOTHERAPIST}
                      formFieldId={FormField.CURRENT_PSYCHOTHERAPIST_ADDRESS}
                      customTitle={SectionTitle.CURRENT_PSYCHOTHERAPIST}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_PSYCHOTHERAPIST}
                      formFieldId={FormField.CURRENT_PSYCHOTHERAPIST_PHONE_NUMBER}
                      customTitle={SectionTitle.CURRENT_PSYCHOTHERAPIST}
                    />

                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}
                      formFieldId={FormField.CURRENT_PRIMARY_CARE_PROVIDER_NAME}
                      customTitle={SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}
                      formFieldId={FormField.CURRENT_PRIMARY_CARE_PROVIDER_ADDRESS}
                      customTitle={SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}
                    />
                    <ReadOnlyInput
                      chartFragment={chartFragment}
                      sectionTitle={SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}
                      formFieldId={FormField.CURRENT_PRIMARY_CARE_PROVIDER_PHONE}
                      customTitle={SectionTitle.CURRENT_PRIMARY_CARE_PROVIDER}
                    />
                  </ELSFlexItem>
                </ELSFlex>
              </>
            )}
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.COMPREHENSION}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.COMPREHENSION}
                  formFieldId={FormField.SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS}
                  customTitle={FormFieldLabel.SPEECH_LANGUAGE_COMPREHENSION_SIMPLE_DIRECTIONS}
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.COMPREHENSION}
                  formFieldId={FormField.SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS}
                  customTitle={FormFieldLabel.SPEECH_LANGUAGE_COMPREHENSION_COMPLEX_DIRECTIONS}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>{SectionTitle.APHASIA}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.APHASIA}
                  formFieldId={FormField.SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY}
                  customTitle={FormFieldLabel.SPEECH_LANGUAGE_APHASIA_COMMUNICATE_VERBALLY}
                />
              </ELSFlexItem>
            </ELSFlex>

            {isAdultOrAuthor && (
              <>
                <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
                  <ELSFlexItem w="1o3" md="1o1">
                    <h3>{SectionTitle.DEPRESSION_EVALUATION}</h3>
                    {renderReadOnlyScale({ chartFragment, sectionTitle: SectionTitle.PATIENT_HEALTH_QUESTIONNAIRE })}
                  </ELSFlexItem>
                </ELSFlex>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

PsychosocialScreenReadOnly.displayName = 'PsychosocialScreenReadOnly';
export default PsychosocialScreenReadOnly;

import { ELSDropDownOption } from '@els/els-component-form-field-react';
import { ChartFragment, ChartFragmentRS } from 'models/api-response';
import { ChartActionsComponentProps, MarDosageRecord, MarRecord } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSCheckBox, ELSDropDown, ELSFlex, ELSFlexItem, ELSIcon, ELSTooltip } from 'components/common/els';
import InactiveOrderTooltip from './InactiveOrderTooltip';
import MarDosageSidebar, { MarSidebarProps } from './MarDosageSidebar';
import MarHoldAndDiscontinueFormSidebar from './MarHoldAndDiscontinueFormSidebar';
import MarTable from './MarTable';
import './mar.scss';
import Notice from '../order-entry/shared/Notice';

export interface MarViewProps {
  isAuthor: boolean;
  statSelected: boolean;
  scheduledSelected: boolean;
  prnSelected: boolean;
  continuousIVSelected: boolean;
  inactiveOrderSelected: boolean;
  viewDate: string;
  statRecords: MarRecord[];
  scheduledRecords: MarRecord[];
  prnRecords: MarRecord[];
  continuousIVRecords: MarRecord[];
  inactiveOrderRecords: MarRecord[];
  selectedDosageRecord: MarDosageRecord;
  doseFormMode: string;
  fragments: ChartFragment[];
  locale: Locales;
  presaveFragment: Function;
  onStatChange: Function;
  onScheduledChange: Function;
  onPrnChange: Function;
  onContinuousIVChange: Function;
  onInactiveOrderChange: Function;
  onEditDosageRecord: Function;
  handleHoldMedication: Function;
  handleDiscontinueMedication: Function;
  handleUnHoldMedication: Function;
  onRecordUpdate: Function;
  onSidebarClose: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  chartActionsProps: ChartActionsComponentProps;
  viewDateOptions: ELSDropDownOption[];
  onSelectViewDate: Function;
}

const MarView = (props: MarViewProps) => {
  const { chartActionsProps, isAuthor, locale } = props;
  const { statSelected, scheduledSelected, prnSelected, continuousIVSelected, inactiveOrderSelected, viewDate, viewDateOptions, onSelectViewDate } = props;
  const { statRecords, scheduledRecords, prnRecords, continuousIVRecords, inactiveOrderRecords } = props;
  const { onStatChange, onScheduledChange, onPrnChange, onContinuousIVChange, onInactiveOrderChange, presaveFragment } = props;
  const {
    selectedDosageRecord,
    doseFormMode,
    fragments,
    onEditDosageRecord,
    onRecordUpdate,
    onSidebarClose,
    saveChartData,
    handleHoldMedication,
    handleUnHoldMedication,
    handleDiscontinueMedication
  } = props;
  const marDosageSidebarProps: Partial<MarSidebarProps> = {
    isAuthor,
    selectedDosageRecord,
    doseFormMode,
    fragments,
    onUpdate: onRecordUpdate,
    onClose: onSidebarClose,
    saveChartData,
    viewDate,
    presaveFragment,
    ...appHelper.getChartSharedProps(props)
  };
  const baseColumnHeaderCss = ['', 'order-start', 'order-stop', 'dose', ''];
  const dosageTimesClass = 'dosage-times';
  const actionClass = 'action';

  return (
    <div className="mar">
      <h1>Medication Administration Record</h1>
      <Notice />
      <ELSFlex className="u-els-margin-top">
        <ELSFlexItem>
          <ELSCheckBox labelText="Stat" checked={statSelected} changeHandler={onStatChange} />
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-left">
          <ELSCheckBox labelText="Scheduled" checked={scheduledSelected} changeHandler={onScheduledChange} />
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-left">
          <ELSCheckBox labelText="PRN" checked={prnSelected} changeHandler={onPrnChange} />
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-left">
          <ELSCheckBox labelText="Continuous IV" checked={continuousIVSelected} changeHandler={onContinuousIVChange} />
        </ELSFlexItem>
        <ELSFlexItem className="u-els-padding-left">
          <ELSCheckBox labelText="Inactive Orders" checked={inactiveOrderSelected} changeHandler={onInactiveOrderChange} />
        </ELSFlexItem>
        <ELSFlexItem grow className="u-els-text-right">
          <span className="view-date-text">View Date</span>
        </ELSFlexItem>
        <ELSFlexItem>
          <ELSDropDown id="view-date-select" name="view-date" inline value={viewDate} options={viewDateOptions} changeHandler={onSelectViewDate} />
        </ELSFlexItem>
      </ELSFlex>

      {statSelected && (
        <Box mt3>
          <h2>Stat</h2>
          <Box mt>
            <MarTable
              viewDate={viewDate}
              hideFrequency
              hideStatus
              data={statRecords}
              columnHeaderCss={[...baseColumnHeaderCss, dosageTimesClass, actionClass]}
              onDosageClick={onEditDosageRecord}
              handleHoldMedication={handleHoldMedication}
              handleDiscontinueMedication={handleDiscontinueMedication}
              handleUnHoldMedication={handleUnHoldMedication}
              hideAdministration={false}
              locale={locale}
            />
          </Box>
        </Box>
      )}

      {scheduledSelected && (
        <Box mt3>
          <h2>Scheduled</h2>
          <Box mt>
            <MarTable
              viewDate={viewDate}
              hideStatus
              data={scheduledRecords}
              columnHeaderCss={[...baseColumnHeaderCss, '', dosageTimesClass, actionClass]}
              onDosageClick={onEditDosageRecord}
              handleHoldMedication={handleHoldMedication}
              handleDiscontinueMedication={handleDiscontinueMedication}
              handleUnHoldMedication={handleUnHoldMedication}
              hideAdministration
              locale={locale}
            />
          </Box>
        </Box>
      )}

      {prnSelected && (
        <Box mt3>
          <h2>PRN</h2>
          <Box mt>
            <MarTable
              viewDate={viewDate}
              hideStatus
              data={prnRecords}
              columnHeaderCss={[...baseColumnHeaderCss, '', dosageTimesClass, actionClass]}
              onDosageClick={onEditDosageRecord}
              handleHoldMedication={handleHoldMedication}
              handleDiscontinueMedication={handleDiscontinueMedication}
              handleUnHoldMedication={handleUnHoldMedication}
              hideAdministration={false}
              locale={locale}
            />
          </Box>
        </Box>
      )}

      {continuousIVSelected && (
        <Box mt3>
          <h2>Continuous IV</h2>
          <Box mt>
            <MarTable
              viewDate={viewDate}
              hideFrequency
              hideStatus
              data={continuousIVRecords}
              columnHeaderCss={[...baseColumnHeaderCss, dosageTimesClass, actionClass]}
              onDosageClick={onEditDosageRecord}
              handleHoldMedication={handleHoldMedication}
              handleDiscontinueMedication={handleDiscontinueMedication}
              handleUnHoldMedication={handleUnHoldMedication}
              hideAdministration={false}
              locale={locale}
            />
          </Box>
        </Box>
      )}

      {inactiveOrderSelected && (
        <Box mt3>
          <h2>
            <span>Inactive Orders </span>
            <ELSTooltip content={<InactiveOrderTooltip />} showClose>
              <ELSIcon prefix="gizmo" name="information" color="n9" size="1x1o2" align="top" />
            </ELSTooltip>
          </h2>

          <Box mt2>
            <ELSAccordion>
              <ELSAccordionItem title="Completed, discontinued, past due, and expired medications">
                <MarTable
                  viewDate={viewDate}
                  hideFrequency
                  hideAction
                  data={inactiveOrderRecords}
                  columnHeaderCss={[...baseColumnHeaderCss, dosageTimesClass, actionClass]}
                  onDosageClick={onEditDosageRecord}
                  handleHoldMedication={handleHoldMedication}
                  handleDiscontinueMedication={handleDiscontinueMedication}
                  hideAdministration
                  locale={locale}
                />
              </ELSAccordionItem>
            </ELSAccordion>
          </Box>
        </Box>
      )}

      <MarDosageSidebar {...marDosageSidebarProps} />
      <MarHoldAndDiscontinueFormSidebar {...marDosageSidebarProps} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

MarView.displayName = 'MarView';
export default MarView;

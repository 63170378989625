export enum FormField {
  CONTAINER_GYNECOLOGICAL_HISTORY = 'containerGynecologicalHistory',
  CONTAINER_MENSTRUAL = 'containerMenstrual',
  CONTAINER_SEXUALLY_TRANSMITTED_DISEASE_HISTORY = 'containerSexuallyTransmittedDiseaseHistory',
  CHIEF_INFORMANT = 'chiefInformant',
  CHIEF_COMPLAINT = 'chiefComplaint',
  ALLERGIES = 'allergies',
  HISTORY_CURRENT_PROBLEM = 'historyOfCurrentProblem',
  FAMILY_HISTORY = 'familyHistory',
  PREVIOUS_ILLNESSES = 'previousIllnesses',
  INJURIES_TRAUMA = 'injuriesOrTrauma',
  DIETARY_HISTORY = 'dietaryHistory',
  SOCIAL_HISTORY = 'socialHistory',
  CONTAGIOUS_DISEASES = 'contagiousDiseases',
  SURGICAL_HISTORY = 'surgicalHistory',
  OTHER = 'other',
  CURRENT_MEDICATIONS = 'currentMedications',
  BIRTH_HISTORY = 'pastMedicalHistoryBirthHistory',
  TETANUS_DIPHTHERIA_PERTUSSIS = 'immunizationStatusTetanusDiphtheriaPertussis',
  IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS = 'immunizationStatusHumanPapillomavirus',
  VARICELLA = 'immunizationStatusVaricella',
  ZOSTER = 'immunizationStatusZoster',
  MEASLES_MUMPS_RUBELLA = 'immunizationStatusMeaslesMumpsRubella',
  INFLUENZA = 'immunizationStatusInfluenza',
  PNEUMOCOCCAL = 'immunizationStatusPneumococcal',
  HEPATITIS_A = 'immunizationStatusHepatitisA',
  IMMUNIZATION_STATUS_HEPATITIS_B = 'immunizationStatusHepatitisB',
  MENINGOCOCCAL = 'immunizationStatusMeningococcal',
  ROTAVIRUS = 'immunizationStatusRotavirus',
  TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES = 'immunizationStatusTetanusDiphtheriaPertussisPrimarySeries',
  TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER = 'immunizationStatusTetanusDiphtheriaPertussisBooster',
  HAEMOPHILUS_INFLUENZAE_TYPE_B = 'immunizationStatusHaemophilusInfluenzaeTypeB',
  INACTIVATED_POLIO_VIRUS = 'immunizationStatusInactivatedPolioVirus',
  OTHER_IMMUNIZATIONS = 'otherImmunizations',
  LAST_PAP_SMEAR = 'lastPapSmear',
  HISTORY_OF_ABNORMAL_PAP = 'gynecologicalHistoryHistoryOfAbnormalPap',
  HISTORY_OF_ABNORMAL_PAP_DESCRIBE = 'gynecologicalHistoryHistoryOfAbnormalPapDescribe',
  UTERINE_ABNORMALITY_DES = 'gynecologicalHistoryUterineAbnormalityDES',
  UTERINE_ABNORMALITY_DES_DESCRIBE = 'gynecologicalHistoryUterineAbnormalityDESDescribe',
  LAST_MENSTRUAL_PERIOD = 'lastMenstrualPeriod',
  MENARCHE_AGE_OF_ONSET = 'menarcheAgeOfOnset',
  CONTRACEPTION_HISTORY = 'contraceptionHistory',
  DATE_OF_CONCEPTION = 'dateOfConception',
  GONORRHEA = 'sexuallyTransmittedDiseaseHistoryGonorrhea',
  GONORRHEA_DESCRIBE = 'sexuallyTransmittedDiseaseHistoryGonorrheaDescribe',
  CHLAMYDIA = 'sexuallyTransmittedDiseaseHistoryChlamydia',
  CHLAMYDIA_DESCRIBE = 'sexuallyTransmittedDiseaseHistoryChlamydiaDescribe',
  CONGENITAL_HELPERS = 'sexuallyTransmittedDiseaseHistoryCongenitalHerpes',
  CONGENITAL_HELPERS_DESCRIBE = 'sexuallyTransmittedDiseaseHistoryCongenitalHerpesDescribe',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS = 'sexuallyTransmittedDiseaseHistoryHumanPapillomavirus',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS_DESCRIBE = 'sexuallyTransmittedDiseaseHistoryHumanPapillomavirusDescribe',
  SYPHILIS = 'sexuallyTransmittedDiseaseHistorySyphilis',
  SYPHILIS_DESCRIBE = 'sexuallyTransmittedDiseaseHistorySyphilisDescribe',
  HUMAN_IMMUNODEFICIENCY_VIRUS = 'sexuallyTransmittedDiseaseHistoryHumanImmunodeficiencyVirus',
  HUMAN_IMMUNODEFICIENCY_VIRUS_DESCRIBE = 'sexuallyTransmittedDiseaseHistoryHumanImmunodeficiencyVirusDescribe',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B = 'sexuallyTransmittedDiseaseHistoryHepatitisB',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B_DESCRIBE = 'sexuallyTransmittedDiseaseHistoryHepatitisBDescribe'
}
export enum SectionTitle {
  PATIENT_INFORMATION = 'Patient Information',
  PAST_MEDICAL_HISTORY = 'Past Medical History',
  IMMUNIZATIONS = 'Immunization',
  GYNECOLOGICAL_HISTORY = 'Gynecological History',
  MENSTRUAL = 'Menstrual',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY = 'Sexually Transmitted Disease History'
}
export enum FormFieldLabel {
  CHIEF_INFORMANT = 'Chief Informant',
  CHIEF_COMPLAINT = 'Chief Complaint',
  ALLERGIES = 'Allergies',
  HISTORY_CURRENT_PROBLEM = 'History of Current Problem',
  FAMILY_HISTORY = 'Family History',
  PREVIOUS_ILLNESSES = 'Previous Illnesses',
  INJURIES_TRAUMA = 'Injuries or Trauma',
  DIETARY_HISTORY = 'Dietary History',
  SOCIAL_HISTORY = 'Social History',
  CONTAGIOUS_DISEASES = 'Contagious Diseases',
  SURGICAL_HISTORY = 'Surgical History',
  OTHER = 'Other',
  CURRENT_MEDICATIONS = 'Current Medications',
  BIRTH_HISTORY = 'Birth History (pregnancy, labor, delivery, perinatal)',
  TETANUS_DIPHTHERIA_PERTUSSIS = 'Tetanus, Diphtheria, Pertussis (Td/Tdap)',
  IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS = 'Human Papillomavirus (HPV)',
  VARICELLA = 'Varicella',
  ZOSTER = 'Zoster',
  MEASLES_MUMPS_RUBELLA = 'Measles, Mumps, Rubella (MMR)',
  INFLUENZA = 'Influenza',
  PNEUMOCOCCAL = 'Pneumococcal',
  HEPATITIS_A = 'Hepatitis A',
  IMMUNIZATION_STATUS_HEPATITIS_B = 'Hepatitis B',
  MENINGOCOCCAL = 'Meningococcal',
  ROTAVIRUS = 'Rotavirus',
  TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES = 'Tetanus, Diphtheria, Pertussis (DTaP) - Primary Series',
  TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER = 'Tetanus, Diphtheria, Pertussis (DTaP) - Booster',
  HAEMOPHILUS_INFLUENZAE_TYPE_B = 'Haemophilus influenzae type b (Hib)',
  INACTIVATED_POLIO_VIRUS = 'Inactivated Polio Virus',
  OTHER_IMMUNIZATIONS = 'Other Immunizations',
  LAST_PAP_SMEAR = 'Last Pap Smear',
  HISTORY_OF_ABNORMAL_PAP = 'History of Abnormal Pap (if Yes, describe)',
  UTERINE_ABNORMALITY_DES = 'Uterine Abnormality/DES (if Yes, describe)',
  LAST_MENSTRUAL_PERIOD = 'Last Menstrual Period',
  MENARCHE_AGE_OF_ONSET = 'Menarche (Age of Onset)',
  CONTRACEPTION_HISTORY = 'Contraception History',
  DATE_OF_CONCEPTION = 'Date of Conception (hcG+)',
  GONORRHEA = 'Gonorrhea',
  CHLAMYDIA = 'Chlamydia',
  CONGENITAL_HELPERS = 'Congenital Herpes (HSV)',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS = 'Human Papillomavirus (HPV)',
  SYPHILIS = 'Syphilis',
  HUMAN_IMMUNODEFICIENCY_VIRUS = 'Human Immunodeficiency Virus (HIV)',
  SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B = 'Hepatitis B'
}

import { History } from 'history';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { AssessmentStatus } from 'models/enum';
import { User } from 'models/ui';
import { NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { getEmptyEhrDemo, isDemoEmptyEhr } from 'helpers/assignment.helper';
import { getAssessmentByUserIdAndAssignmentId, getAssessment as getAssessmentService } from 'services/assignment.service';
import { loadFragments } from 'services/chart.service';
import { appSelectors } from 'redux/ducks/app';
import { ELSPageLoader } from 'components/common/els';

interface MatchParams {
  assignmentId: string;
}

export interface ClinicalEntryPointProps {
  history: History;
  loggedInUser: User;
}

class ClinicalEntryPoint extends Component<ClinicalEntryPointProps & RouteComponentProps<MatchParams>> {
  static displayName = 'ClinicalEntryPoint';
  assessmentId = 0;
  demoAssessment = null;

  componentDidMount() {
    this.demoAssessment = getEmptyEhrDemo()?.assessment;
    this.evaluateAssignmentLaunchPage();
  }

  navigateTo = (path: string) => this.props.history.push(path.replace(RouteParams.ASSESSMENT_ID, String(this.assessmentId)));

  evaluateAssignmentLaunchPage = () => {
    if (Number(this.props.match.params.assignmentId) > 0) {
      this.getAssessment().then((assessment) => {
        this.assessmentId = assessment.id;
        return this.getClinicalChart(assessment);
      });
    } else {
      getAssessmentService(this.demoAssessment?.demoAssessmentId).then(({ data }) => {
        this.assessmentId = data.eolsAssessmentId;
        return this.getClinicalChart(data);
      });
    }
  };

  evaluateAssessment = (chartFragments) => {
    const isInDemoEmptyEhr = isDemoEmptyEhr();
    const path = chartFragments?.[0]?.chartData && !isInDemoEmptyEhr ? RoutePath.student.summary : RoutePath.student.clinicalSetup;
    this.navigateTo(path);
  };

  getAssessment = () => {
    const { assignmentId } = this.props.match.params;
    const { id: studentId } = this.props.loggedInUser;
    return getAssessmentByUserIdAndAssignmentId(Number(assignmentId), studentId).then(({ data }) => data);
  };

  getClinicalChart = (assessment) => {
    if (assessment.status === AssessmentStatus.COMPLETED) {
      this.navigateTo(RoutePath.student.assignmentSubmission);
    } else {
      loadFragments({
        chartId: assessment.simChartId,
        navIds: [NAV_ID.PATIENT]
      }).then(({ data }) => this.evaluateAssessment(data));
    }
  };

  render() {
    return <ELSPageLoader />;
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: appSelectors.getLoggedInUser(state)
});

const enhancers = [withRouter, connect(mapStateToProps, null)];
export { ClinicalEntryPoint as BaseClinicalEntryPoint };
export default compose(...enhancers)(ClinicalEntryPoint);

import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentStatus } from 'models/enum';
import { RouteParams, RoutePath, SectionNavIdPatterns } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { SavedPatientWrapper } from 'components/common';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';
import { FormField, SectionTitle } from '../constants';

interface MatchParams {
  assessmentId: string;
}

interface SavedDrainsTubesProps {
  enableMultiStepsAuthoring: Function;
}

class SavedDrainsTubes extends Component<SavedDrainsTubesProps & RouteComponentProps<MatchParams>> {
  static displayName = 'SavedDrainsTubes';

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
  }

  isDiscontinued = (fragment: ChartFragment) => {
    return chartHelper.getFragmentValue(fragment, SectionTitle.DRAINS_TUBES, FormField.DISCONTINUED);
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: dateTimeHelper.toDateTime(fragment.chartingAt),
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      groupFragmentRef: fragment.groupFragmentRef,
      location: chartHelper.getFragmentRecords(fragment, SectionTitle.DRAINS_TUBES, FormField.LOCATION)[0]?.content,
      navElementId: fragment.navElementId,
      drainTubeType: chartHelper.getFragmentRecords(fragment, SectionTitle.DRAINS_TUBES, FormField.DRAIN_TUBE_TYPE)[0]?.content,
      drainTubeStatus: this.isDiscontinued(fragment) ? FragmentStatus.DISCONTINUED : FragmentStatus.ACTIVE
    }));
  };

  handleUpdate = (row) => {
    const { assessmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.student.drainsTubes.drainsTubes.replace(RouteParams.ASSESSMENT_ID, assessmentId),
      search: `?id=${encodeURIComponent(row.id)}`
    });
  };

  buildStatusBadge = (fragment: MultiSectionsFragment) => {
    const status = this.isDiscontinued(fragment) ? FragmentStatus.DISCONTINUED : FragmentStatus.ACTIVE;
    const isStatusBadgeActive = status === FragmentStatus.ACTIVE;
    return { status, isStatusBadgeActive };
  };

  render() {
    const columns = {
      location: {
        field: 'location',
        header: 'Location'
      },
      status: {
        field: 'drainTubeStatus',
        header: 'Drain - Tube Status',
        active: FragmentStatus.ACTIVE
      },
      type: {
        field: 'drainTubeType',
        header: 'Drain - Tube Type'
      }
    };
    const deleteMessage = `Are you sure you want to delete this entry? Note that the most recent Drain - Tube update will be deleted.
      If there is another record associated with this Drain - Tube location, the next most recent record will be displayed.`;

    const viewProps = {
      buildStatusBadge: this.buildStatusBadge,
      columns,
      chartTitle: 'Saved Drains - Tubes Charting',
      deleteMessage,
      hideDelete: true,
      navIds: [SectionNavIdPatterns.DRAINS_TUBES],
      onRecordUpdate: this.handleUpdate,
      toTableData: this.toTableData,
      ...this.props
    };
    return <SavedPatientWrapper {...viewProps} />;
  }
}

export { SavedDrainsTubes as BaseSavedDrainsTubes };
export default withRouter(SavedDrainsTubes);

import { ChartFragment } from 'models/api-response';
import { PatientContext } from 'models/ui';
import { PEDIATRIC_MAX_AGE_IN_DAYS } from 'constants/app.constant';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface AlcoholScreenReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
  patientContext: PatientContext;
}

const AlcoholScreenReadOnly = (props: AlcoholScreenReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline, patientContext } = props;
  const isPediatric = patientContext?.ageInDays < PEDIATRIC_MAX_AGE_IN_DAYS;
  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Alcohol Screen</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3>Do you drink alcohol</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.ALCOHOL_SCREEN} formFieldId={FormField.DRINK_ALCOHOL} customTitle="" />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3 className="u-els-margin-top-2x">{SectionTitle.DRINKING}</h3>
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.DRINKING} formFieldId={FormField.HOW_OFTEN_DRINK} customTitle="How often do you drink?" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DRINKING}
                  formFieldId={FormField.HOW_OFTEN_DRINK_OTHER}
                  customTitle="Other"
                  notRenderBlankField
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DRINKING}
                  formFieldId={FormField.HOW_LONG_DRINK}
                  customTitle="How long have you been drinking?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DRINKING}
                  formFieldId={FormField.HOW_LONG_DRINK_OTHER}
                  customTitle="Other"
                  notRenderBlankField
                />
                <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.DRINKING} formFieldId={FormField.WHEN_LAST_DRINK} customTitle="When was your last drink?" />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.DRINKING}
                  formFieldId={FormField.WHEN_LAST_DRINK_OTHER}
                  customTitle="Other"
                  notRenderBlankField
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <h3 className="u-els-margin-top-2x">{SectionTitle.SOBRIETY}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SOBRIETY}
                  formFieldId={FormField.REASON_BREAKING}
                  customTitle="Possible reasons for breaking sober period"
                />
                <Box pt pb>
                  <h4>Dates of longest period of sobriety</h4>
                  <ELSFlex left wrap>
                    <ELSFlexItem className="u-els-margin-right">
                      <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SOBRIETY} formFieldId={FormField.BEGINNING_DATE_LONGEST_PERIOD} customTitle="" />
                    </ELSFlexItem>
                    <ELSFlexItem>
                      <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SOBRIETY} formFieldId={FormField.END_DATE_LONGEST_PERIOD} customTitle="" />
                    </ELSFlexItem>
                  </ELSFlex>
                </Box>
                <Box pt pb>
                  <h4>Dates of most recent period of sobriety</h4>
                  <ELSFlex left wrap>
                    <ELSFlexItem className="u-els-margin-right">
                      <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SOBRIETY} formFieldId={FormField.BEGINNING_DATE_MOST_RECENT} customTitle="" />
                    </ELSFlexItem>
                    <ELSFlexItem>
                      <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.SOBRIETY} formFieldId={FormField.END_DATE_MOST_RECENT} customTitle="" />
                    </ELSFlexItem>
                  </ELSFlex>
                </Box>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SOBRIETY}
                  formFieldId={FormField.HELP_MAINTAIN_SOBRIETY}
                  customTitle="What helped you maintain sobriety?"
                />
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.SOBRIETY}
                  formFieldId={FormField.HELP_MAINTAIN_SOBRIETY_OTHER}
                  customTitle="Other"
                  notRenderBlankField
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <h3 className="u-els-margin-top-2x">{SectionTitle.WITHDRAWAL}</h3>
                <ReadOnlyInput
                  chartFragment={chartFragment}
                  sectionTitle={SectionTitle.WITHDRAWAL}
                  formFieldId={FormField.HISTORY_ALCOHOL_WITHDRAWAL}
                  customTitle="Do you have a history of alcohol withdrawal?"
                />
              </ELSFlexItem>
              {isPediatric && (
                <ELSFlexItem w="1o3" md="1o1">
                  <h3 className="u-els-margin-top-2x">{SectionTitle.CAGE_QUESTIONNAIRE}</h3>
                  <ReadOnlyInput chartFragment={chartFragment} sectionTitle={SectionTitle.CAGE_QUESTIONNAIRE} formFieldId={FormField.CAGE_QUESTIONNAIRE} customTitle="" />
                </ELSFlexItem>
              )}
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

AlcoholScreenReadOnly.displayName = 'AlcoholScreenReadOnly';
export default AlcoholScreenReadOnly;

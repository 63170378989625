import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import RoleRelationshipView from './RoleRelationshipView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export enum SectionTitle {
  ROLE_RELATIONSHIP = 'Role/Relationship'
}

export enum FormField {
  MARTIAL_PARTNER_STATUS = 'martialPartnerStatus',
  FAMILY_PROCESSES = 'familyProcesses',
  CAREGIVER_ROLE = 'caregiverRole',
  ROLE_PERFORMANCE = 'rolePerformance',
  SOCIAL_INTERACTIONS = 'socialInteractions',
  READINESS_NEWBORN = 'readinessNewborn',
  FEELING_READINESS = 'feelingReadiness',
  SOCIAL_RESOURCES = 'socialResources',
  FAMILY_PROCESSES_OTHER = 'familyProcessesOther',
  CAREGIVER_ROLE_OTHER = 'caregiverRoleOther',
  ROLE_PERFORMANCE_OTHER = 'rolePerformanceOther',
  SOCIAL_INTERACTIONS_OTHER = 'socialInteractionsOther'
}

interface RoleRelationshipState {
  isLocked: boolean;
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class RoleRelationship extends Component<ChartComponentProps, RoleRelationshipState> {
  static displayName = 'RoleRelationship';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.ROLE_RELATIONSHIP, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField, getFragmentValue, getFragmentContentIds } = chartHelper;
    const { fragment } = this.state;
    const dataMap = new Map();

    const existingDataMap = this.transformFragmentData(fragment);

    dataMap.set(
      FormField.MARTIAL_PARTNER_STATUS,
      createFormField({
        name: FormField.MARTIAL_PARTNER_STATUS,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.MARTIAL_PARTNER_STATUS),
        label: 'Marital or Partner Status'
      })
    );

    dataMap.set(
      FormField.FAMILY_PROCESSES,
      createFormField({
        name: FormField.FAMILY_PROCESSES,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.FAMILY_PROCESSES),
        label: 'Family Processes'
      })
    );

    dataMap.set(
      FormField.FAMILY_PROCESSES_OTHER,
      createFormField({
        name: FormField.FAMILY_PROCESSES_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.FAMILY_PROCESSES_OTHER),
        label: 'Other'
      })
    );

    dataMap.set(
      FormField.CAREGIVER_ROLE,
      createFormField({
        name: FormField.CAREGIVER_ROLE,
        type: FormFieldInputType.DROPDOWN,
        value: getFragmentValue(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.CAREGIVER_ROLE),
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.CAREGIVER_ROLE),
        label: 'Caregiver Role'
      })
    );

    dataMap.set(
      FormField.CAREGIVER_ROLE_OTHER,
      createFormField({
        name: FormField.CAREGIVER_ROLE_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.CAREGIVER_ROLE_OTHER),
        label: 'Other'
      })
    );

    dataMap.set(
      FormField.ROLE_PERFORMANCE,
      createFormField({
        name: FormField.ROLE_PERFORMANCE,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.ROLE_PERFORMANCE),
        label: 'Role Performance'
      })
    );

    dataMap.set(
      FormField.ROLE_PERFORMANCE_OTHER,
      createFormField({
        name: FormField.ROLE_PERFORMANCE_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.ROLE_PERFORMANCE_OTHER),
        label: 'Other'
      })
    );

    dataMap.set(
      FormField.SOCIAL_INTERACTIONS,
      createFormField({
        name: FormField.SOCIAL_INTERACTIONS,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.SOCIAL_INTERACTIONS),
        label: 'Social Interactions'
      })
    );

    dataMap.set(
      FormField.SOCIAL_INTERACTIONS_OTHER,
      createFormField({
        name: FormField.SOCIAL_INTERACTIONS_OTHER,
        type: FormFieldInputType.TEXT_BOX,
        value: getFragmentValue(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.SOCIAL_INTERACTIONS_OTHER),
        label: 'Other'
      })
    );

    dataMap.set(
      FormField.READINESS_NEWBORN,
      createFormField({
        name: FormField.READINESS_NEWBORN,
        type: FormFieldInputType.MULTISELECT_DROPDOWN,
        contentIds: this.getSelectedContentIds(existingDataMap, FormField.READINESS_NEWBORN),
        label: 'Readiness for Newborn'
      })
    );

    dataMap.set(
      FormField.FEELING_READINESS,
      createFormField({
        name: FormField.FEELING_READINESS,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.FEELING_READINESS),
        label: 'Feeling of Readiness for Newborn Care'
      })
    );

    dataMap.set(
      FormField.SOCIAL_RESOURCES,
      createFormField({
        name: FormField.SOCIAL_RESOURCES,
        type: FormFieldInputType.DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.SOCIAL_RESOURCES),
        label: 'Social Resources'
      })
    );
    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  transformFragmentData = (fragment: ChartFragment) => {
    const fieldMap = new Map();
    fieldMap.set(FormField.READINESS_NEWBORN, this.getRecordsContent(fragment, SectionTitle.ROLE_RELATIONSHIP, FormField.READINESS_NEWBORN));
    return fieldMap;
  };

  getSelectedContentIds = (existingDataMap, formFieldId): string[] => (existingDataMap ? existingDataMap.get(formFieldId)?.map((it) => it.contentId) : []);

  getRecordsContent = (fragment: ChartFragment, sectionTitle: string, formField: string) => chartHelper.getFragmentRecords(fragment, sectionTitle, formField);

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = SectionTitle.ROLE_RELATIONSHIP;
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.ROLE_RELATIONSHIP,
          records: [
            buildPatientRecord(formFieldMap, FormField.MARTIAL_PARTNER_STATUS),
            buildPatientRecord(formFieldMap, FormField.FAMILY_PROCESSES),
            buildPatientRecord(formFieldMap, FormField.FAMILY_PROCESSES_OTHER),
            buildPatientRecord(formFieldMap, FormField.CAREGIVER_ROLE),
            buildPatientRecord(formFieldMap, FormField.CAREGIVER_ROLE_OTHER),
            buildPatientRecord(formFieldMap, FormField.ROLE_PERFORMANCE),
            buildPatientRecord(formFieldMap, FormField.ROLE_PERFORMANCE_OTHER),
            buildPatientRecord(formFieldMap, FormField.FEELING_READINESS),
            ...buildPatientRecords(formFieldMap, FormField.READINESS_NEWBORN),
            buildPatientRecord(formFieldMap, FormField.SOCIAL_INTERACTIONS),
            buildPatientRecord(formFieldMap, FormField.SOCIAL_INTERACTIONS_OTHER),
            buildPatientRecord(formFieldMap, FormField.SOCIAL_RESOURCES)
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      formFieldMap
    };
    return <RoleRelationshipView {...viewProps} />;
  }
}

export { RoleRelationship as BaseRoleRelationship };
export default compose(withChartLogic, withAdmissionHistory)(RoleRelationship);

/* eslint-disable class-methods-use-this */
import produce from 'immer';
import { ChartMetaFormField, ErrorField } from 'models/ui';
import BaseHandler, { ExecuteParams } from './interface';

export default class WarningHandler implements BaseHandler {
  execute({ field, ruleId, ruleRegistry }: ExecuteParams): ChartMetaFormField {
    const rule = ruleRegistry.get(ruleId);

    return produce(field, (draft) => {
      if (rule.result) {
        const warning = {
          message: rule.ruleInfo.message,
          field: rule.ruleInfo.formFieldId,
          priority: 0,
          id: rule.ruleInfo.id,
          type: rule.ruleInfo.validationActionType
        };
        draft.warning.push(warning);
      } else {
        const newWarning = draft.warning.filter((item: ErrorField) => item.id !== rule.ruleInfo.id);
        draft.warning = newWarning.length !== draft.warning.length ? newWarning : draft.warning;
      }
    });
  }
}

export enum FormField {
  DAY_MONTH_YEARS_AGO = 'dayMonthYearsAgo',
  GESTATIONAL_WEEKS = 'gestationalWeeks',
  ANESTHESIA = 'anesthesia',
  DURATION_OF_LABOR = 'durationOfLabor',
  BIRTH_WEIGHT = 'birthWeight',
  GENDER = 'gender',
  TYPE_OF_DELIVERY = 'typeOfDelivery',
  PRETERM_LABOR = 'pretermLabor',
  COMPLICATIONS = 'complications',
  COMMENTS = 'comments',
  INFERTILITY = 'infertility',
  TOTAL_PREGNANCIES = 'totalPregnancies',
  TERM = 'term',
  MATURE = 'mature',
  ABORTION_INDUCED = 'abortionInduced',
  ABORTION_SPONTANEOUS = 'abortionSpontaneous',
  ECTOPIC = 'ectopic',
  MULTIPLE_BIRTHS = 'multipleBirths',
  LIVING_BIRTH = 'livingBirth',
  GENETIC_SCREENING_PERFORMED = 'geneticScreeningPerformed',
  GENETIC_SCREENING_PERFORMED_OTHER = 'geneticScreeningPerformedOther',
  DESCRIBE_CURRENT_PROBLEMS = 'describeCurrentProblems',
  NOTES_FROM_PRENATAL_VISITS = 'notesFromPrenatalVisits'
}

export enum FormFieldLabel {
  DAY_MONTH_YEARS_AGO = 'Day/Month/Years Ago',
  GESTATIONAL_WEEKS = 'Gestational Weeks',
  DURATION_OF_LABOR = 'Duration of Labor',
  ANESTHESIA = 'Anesthesia',
  BIRTH_WEIGHT = 'Birth Weight',
  GENDER = 'Gender',
  TYPE_OF_DELIVERY = 'Type of Delivery',
  PRETERM_LABOR = 'Preterm Labor',
  COMPLICATIONS = 'Complications',
  COMMENTS = 'Comments',
  INFERTILITY = 'Infertility',
  TOTAL_PREGNANCIES = 'Total Pregnancies',
  TERM = 'Term',
  MATURE = 'Mature',
  ABORTION_INDUCED = 'Abortion Induced',
  ABORTION_SPONTANEOUS = 'Abortion Spontaneous',
  ECTOPIC = 'Ectopic',
  MULTIPLE_BIRTHS = 'Multiple Births',
  LIVING_BIRTH = 'Living Birth',
  GENETIC_SCREENING_PERFORMED = 'Genetic Screening Performed',
  OTHER = 'Other',
  DESCRIBE_CURRENT_PROBLEMS = 'Describe Current Problems',
  NOTES_FROM_PRENATAL_VISITS = 'Notes from prenatal visits'
}

export enum SectionTitle {
  OBSTETRIC_HISTORY = 'Obstetric History',
  PREGNANT_HISTORY = 'Pregnant History',
  PREVIOUS_PREGNANCIES = 'Previous Pregnancies',
  GENETIC_SCREENING_PERFORMED = 'Genetic Screening Performed',
  CURRENT_PREGNANCY_RELATED_PROBLEMS = 'Current Pregnancy-Related Problems',
  CURRENT_PREGNANCY_PRENATAL_NOTES = 'Current Pregnancy-Prenatal Notes',
  PREGNANCY_INFORMATION = 'Pregnancy Information',
  NO_SECTION = 'none'
}

import { CaseStudy } from 'models/ui';
import { Box, ButtonClose } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import CaseStudyInfoView from './CaseStudyInfoView';

export interface CaseStudyQuickViewViewProps {
  activeTab: number;
  selectedCaseStudy: CaseStudy;
  onSelectClick: Function;
  onTabGroupClick: Function;
  onCloseSideBar: Function;
  onPreviewCaseStudy: Function;
  isBookOrganized: boolean;
}

const CaseStudyQuickViewView = (props: CaseStudyQuickViewViewProps) => {
  const { id, patient, title } = props.selectedCaseStudy || {};
  if (!id) {
    return null;
  }
  return (
    <>
      <Box mt>
        <ButtonClose onClick={props.onCloseSideBar} />
      </Box>
      <Box mt2>
        <p>Quick view</p>
        <h2>{title}</h2>
      </Box>
      <Box mt2 className="quick-view__patient-info">
        <ELSFlex spaceBetween>
          <ELSFlexItem>
            <p>Patient name (Last, First)</p>
            <h4>{`${patient?.lastName}, ${patient?.firstName}`}</h4>
          </ELSFlexItem>
          <ELSFlexItem className="u-els-float-left u-els-text-right">
            <p>Hospital Floor</p>
            <h4>{patient?.hospitalFloor}</h4>
          </ELSFlexItem>
        </ELSFlex>
        <Box mt>
          <ELSButton className="u-els-margin-right" text="Demo Case Study" type="secondary" iconName="activity-online" onClick={props.onPreviewCaseStudy} />
          <ELSButton text="Select this Case Study" onClick={props.onSelectClick} />
        </Box>
      </Box>
      <CaseStudyInfoView activeTab={props.activeTab} selectedCaseStudy={props.selectedCaseStudy} onTabGroupClick={props.onTabGroupClick} isBookOrganized={props.isBookOrganized} />
    </>
  );
};

CaseStudyQuickViewView.displayName = 'CaseStudyQuickViewView';
export default CaseStudyQuickViewView;

import { Component } from 'react';
import { LaboratoryTestResult } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { ChartHistory, MultiLine } from 'components/common';
import { FormField } from './LaboratoryTest';

export interface LaboratoryTestResultTableViewProps {
  isDeleted?: boolean;
  labResults: LaboratoryTestResult[];
}

class LaboratoryTestResultTableView extends Component<LaboratoryTestResultTableViewProps> {
  static displayName = 'LaboratoryTestResultTableView';

  resultSignificanceRenderer = (row) => <div>{row.significance && <MultiLine text={row.significance} />}</div>;

  render() {
    const labTestResultDatasource = {
      title: '',
      headers: [
        { text: 'Test', field: FormField.TEST_RESULT_NAME, sortable: true },
        { text: 'Test Date', field: FormField.DATE_OF_TEST_RESULT, align: ColumnAlign.Left, sortable: true },
        { text: 'Test Result', field: FormField.TEST_RESULT, align: ColumnAlign.Left },
        { text: 'Result Level', field: FormField.TEST_RESULT_LEVEL, align: ColumnAlign.Left },
        { text: 'Result Significance', field: FormField.TEST_RESULT_SIGNIFICANCE, align: ColumnAlign.Left, customRender: this.resultSignificanceRenderer }
      ],
      data: this.props.labResults || [],
      tableCss: this.props.isDeleted ? 'chart-history-table--deleted' : ''
    };

    return <ChartHistory dataSource={labTestResultDatasource} />;
  }
}

export default LaboratoryTestResultTableView;

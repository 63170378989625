import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import GlasgowComaScaleView, { GlasgowComaScaleViewProps } from './GlasgowComaScaleView';
import { Title } from '../shared/constants';

class GlasgowComaScale extends Component<ChartComponentProps> {
  static displayName = 'GlasgowComaScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(FormField.GLASGOW_COMA_SCALE, createFormField({ name: FormField.GLASGOW_COMA_SCALE, type: FormFieldInputType.SCALE }));
    dataMap.set(FormField.EYE_OPENING_RESPONSE_CHECKBOX, createFormField({ name: FormField.EYE_OPENING_RESPONSE_CHECKBOX, type: FormFieldInputType.CHECK_BOX }));
    dataMap.set(FormField.BEST_VERBAL_RESPONSE_CHECKBOX, createFormField({ name: FormField.BEST_VERBAL_RESPONSE_CHECKBOX, type: FormFieldInputType.CHECK_BOX }));

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    const { buildPatientRecords, buildPlainRecord } = chartService;
    return [
      {
        sectionTitle: SectionTitle.GLASGOW_COMA_SCALE,
        records: [...buildPatientRecords(formFieldMap, FormField.GLASGOW_COMA_SCALE, false, true)]
      },
      {
        sectionTitle: SectionTitle.TOTAL_SCORE,
        records: [
          buildPlainRecord({
            formField: FormField.GLASGOW_COMA_SCALE,
            title: 'Total Score',
            content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.GLASGOW_COMA_SCALE))?.score?.value,
            linkedFormFieldIds: [FormField.EYE_OPENING_RESPONSE, FormField.BEST_MOTOR_RESPONSE, FormField.BEST_VERBAL_RESPONSE]
          })
        ]
      }
    ];
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.EYE_OPENING_RESPONSE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.BEST_MOTOR_RESPONSE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.BEST_VERBAL_RESPONSE, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SPECIAL_CHARTS,
      fragmentTitle: SectionTitle.GLASGOW_COMA_SCALE,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { handleDiscardClick, displayAuthoringData, hasUnsavedChanges, enableDisplayRecordsButton, formFieldMap, formSubmittedCount } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps: GlasgowComaScaleViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };
    return <GlasgowComaScaleView {...viewProps} />;
  }
}

export { GlasgowComaScale as BaseGlasgowComaScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(GlasgowComaScale);

export enum FormField {
  SLEEP = 'sleep',
  EXERCISE_ACTIVITIES = 'exerciseActivities',
  ELIMINATION_STOOL_URINE = 'eliminationStoolUrine',
  SAFETY_ASSESSMENT_ISSUES = 'safetyAssessmentIssues',
  OTHER = 'other'
}
export enum FormFieldLabel {
  SLEEP = 'Sleep:',
  EXERCISE_ACTIVITIES = 'Exercise and Activities:',
  ELIMINATION_STOOL_URINE = 'Elimination of Stool and Urine:',
  SAFETY_ASSESSMENT_ISSUES = 'Safety Assessment and Issues:',
  OTHER = 'Other:'
}
export enum SectionTitle {
  ACTIVITIES_DAILY_LIVING = 'Activities of Daily Living (ADLs)'
}

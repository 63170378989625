export enum ButtonLabel {
  ADD_LOCATION = 'Add Location',
  CLEAR = 'Clear'
}

export enum Title {
  INFO_TEXT_TITLE = 'Directions',
  SYSTEM_ASSESSMENT = 'System Assessment',
  SPECIAL_CHARTS = 'Special Charts'
}

export enum SectionTitle {
  NUTRITION_HYDRATION_CARE = 'Nutrition and Hydration',
  NUTRITION_CARE = 'Nutrition',
  HYDRATION_CARE = 'Hydration',
  DIABETIC_CARE = 'Diabetic Care'
}

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  NUTRITION_CARE_LIST = 'nutritionCare',
  NUTRITION_CARE_LIST_NOTES = 'nutritionCareNotes',
  HYDRATION_CARE_LIST = 'hydrationCare',
  HYDRATION_CARE_LIST_NOTES = 'hydrationCareNotes',
  DIABETIC_CARE_LIST = 'diabeticCare',
  DIABETIC_CARE_LIST_NOTES = 'diabeticCareNotes'
}

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { v4 } from 'uuid';
import { ChartFragment, ChartFragmentRS } from 'models/api-response';
import { AssignmentType, FormFieldInputType, ValidationActionType, ValidationRuleType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, MarDosageRecord } from 'models/ui';
import { DateFormatByLocale, ISO_FORMAT, NAV_ID, TIME_FORMAT } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { formatDate, toMomentWithFormat } from 'helpers/datetime.helper';
import { appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel, ORDER_TYPES } from './constants';
import MarDosageSidebarView, { MarSidebarViewProps } from './MarDosageSidebarView';

export interface MarSidebarProps extends ChartComponentProps {
  selectedDosageRecord: MarDosageRecord;
  doseFormMode: string;
  fragments: ChartFragment[];
  preventScroll?: boolean;
  onUpdate: Function;
  onClose: Function;
  presaveFragment: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  viewDate: string;
}

class MarDosageSidebar extends Component<MarSidebarProps> {
  static displayName = 'MarDosageSidebar';

  componentDidUpdate(prevProps: Readonly<MarSidebarProps>) {
    if (this.props.selectedDosageRecord && prevProps.selectedDosageRecord !== this.props.selectedDosageRecord) {
      this.props.initState(this.buildFormFields(), undefined, this.buildExtraValidationRules());
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const { selectedDosageRecord, locale } = this.props;
    const dataMap = new Map();
    if (!this.props.isAuthor) {
      dataMap.set(
        FormField.ADMINISTERED_DATE,
        createFormField({
          name: FormField.ADMINISTERED_DATE,
          type: FormFieldInputType.DATE,
          label: FormFieldLabel.ADMINISTERED_DATE,
          value: formatDate({ date: moment(selectedDosageRecord.administeredDate || this.props.viewDate, Object.values(DateFormatByLocale)).toDate(), locale })
        })
      );
      dataMap.set(
        FormField.ADMINISTERED_TIME,
        createFormField({
          name: FormField.ADMINISTERED_TIME,
          type: FormFieldInputType.TIME,
          label: FormFieldLabel.ADMINISTERED_TIME,
          value: selectedDosageRecord.administeredTime
        })
      );
    }
    if (this.props.isAuthor) {
      dataMap.set(
        FormField.ADMINISTERED_TIME_OFFSET,
        createFormField({
          name: FormField.ADMINISTERED_TIME_OFFSET,
          type: FormFieldInputType.TEXT_BOX,
          label: FormFieldLabel.ADMINISTERED_TIME_OFFSET,
          value: selectedDosageRecord.administeredTimeOffset || '00:00'
        })
      );
    }
    dataMap.set(
      FormField.DOSAGE_DATE,
      createFormField({
        name: FormField.DOSAGE_DATE,
        type: FormFieldInputType.DATE,
        label: FormFieldLabel.DOSAGE_DATE,
        value: formatDate({ date: moment(selectedDosageRecord.dosageDate || this.props.viewDate, Object.values(DateFormatByLocale)).toDate(), locale })
      })
    );
    let dosageTime = selectedDosageRecord.dosageTime || selectedDosageRecord.orderStartTime;
    if (dosageTime === 'Administer') {
      dosageTime = '00:00';
    }
    dataMap.set(
      FormField.DOSAGE_TIME,
      createFormField({
        name: FormField.DOSAGE_TIME,
        type: FormFieldInputType.TIME,
        label: FormFieldLabel.DOSAGE_TIME,
        value: dosageTime
      })
    );
    dataMap.set(
      FormField.COMMENTS,
      createFormField({
        name: FormField.COMMENTS,
        type: FormFieldInputType.TEXT_AREA,
        label: FormFieldLabel.COMMENTS,
        value: selectedDosageRecord.comments
      })
    );
    dataMap.set(
      FormField.FIRST_INITIAL,
      createFormField({
        name: FormField.FIRST_INITIAL,
        type: FormFieldInputType.TEXT_BOX,
        label: FormFieldLabel.FIRST_INITIAL,
        value: selectedDosageRecord.firstInitial
      })
    );
    dataMap.set(
      FormField.LAST_INITIAL,
      createFormField({
        name: FormField.LAST_INITIAL,
        type: FormFieldInputType.TEXT_BOX,
        label: FormFieldLabel.LAST_INITIAL,
        value: selectedDosageRecord.lastInitial
      })
    );
    return dataMap;
  };

  buildExtraValidationRules = () => {
    const { assessment, chartingTime } = this.props;
    return [
      {
        message: 'This time is later than the current charting time',
        id: 'mar-administeredTime-validation-notbefore',
        formFieldId: FormField.ADMINISTERED_TIME,
        validationType: ValidationRuleType.TIME_AFTER,
        validationActionType: ValidationActionType.ERROR,
        getValueToCompare: () =>
          assessment.assignmentType === AssignmentType.EMPTY_EHR || !chartingTime ? moment().format(TIME_FORMAT) : toMomentWithFormat(chartingTime, ISO_FORMAT).format(TIME_FORMAT)
      }
    ];
  };

  handleSaveClick = () => {
    const { selectedDosageRecord, onUpdate, formFieldMap, fragments, presaveFragment, viewDate, locale } = this.props;
    const selectedFragment = fragments.find((fragment) => fragment.fragmentId === selectedDosageRecord.fragmentId);
    const { orderType } = selectedDosageRecord;
    const dosageTimes = cloneDeep(selectedDosageRecord.dosageTimes);
    const existedDosageTimes = selectedDosageRecord.originDosageTimes.filter((item) => item.isAdministered);
    const dosageTime = dosageTimes.find((item) => item.id === selectedDosageRecord.id) || {};
    dosageTime[FormField.ADMINISTERED_TIME] = formFieldMap.get(FormField.ADMINISTERED_TIME)?.value;
    dosageTime[FormField.ADMINISTERED_TIME_OFFSET] = formFieldMap.get(FormField.ADMINISTERED_TIME_OFFSET)?.value;
    dosageTime[FormField.COMMENTS] = formFieldMap.get(FormField.COMMENTS).value;
    dosageTime[FormField.FIRST_INITIAL] = formFieldMap.get(FormField.FIRST_INITIAL).value.toUpperCase();
    dosageTime[FormField.LAST_INITIAL] = formFieldMap.get(FormField.LAST_INITIAL).value.toUpperCase();
    dosageTime.isAdministered = true;
    if (orderType === ORDER_TYPES.PRN || orderType === ORDER_TYPES.STAT || orderType === ORDER_TYPES.IV) {
      dosageTime[FormField.ID] = v4();
      dosageTime[FormField.ADMINISTERED_DATE] = formatDate({
        date: moment(formFieldMap.get(FormField.ADMINISTERED_DATE)?.value || viewDate, Object.values(DateFormatByLocale)).toDate(),
        locale
      });
      dosageTime[FormField.DOSAGE_DATE] = formatDate({ date: moment(formFieldMap.get(FormField.DOSAGE_DATE)?.value, Object.values(DateFormatByLocale)).toDate(), locale });
      dosageTime[FormField.DOSAGE_TIME] = formFieldMap.get(FormField.DOSAGE_TIME)?.value;
    }
    const updatedDosageTimes = [...existedDosageTimes, dosageTime];
    const updatedChartData = {
      ...selectedFragment.chartData,
      dosageTimes: updatedDosageTimes
    };
    const updatedFragment: ChartFragment = {
      ...presaveFragment(selectedFragment),
      chartData: updatedChartData,
      linkedFragmentId: selectedFragment.linkedFragmentId || selectedFragment.fragmentId
    };

    this.props.saveChart([updatedFragment], { navId: NAV_ID.PHARMACY, afterSave: onUpdate });
  };

  render() {
    const { formFieldMap, formSubmittedCount, hasUnsavedChanges, selectedDosageRecord, doseFormMode, onClose, sidebarProps } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' })
    };
    const viewProps: MarSidebarViewProps = {
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      selectedDosageRecord,
      doseFormMode,
      onCloseClick: onClose,
      sidebarProps
    };
    return <MarDosageSidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state), // used by withChartLogic
  assessment: appSelectors.getAssessment(state),
  chartingTime: studentSelectors.getChartingTime(state)
});

export { MarDosageSidebar as BaseMarDosageSidebar };
export default compose(connect(mapStateToProps), withChartLogic)(MarDosageSidebar);

import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const SkinCareView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="skin-care">
      <h1>Skin Care</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.SKIN_CARE}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.SKIN_CARE_LIST)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.SKIN_CARE_LIST_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
            <h2>{SectionTitle.PRESSURE_INJURY_REDUCTION}</h2>
            <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.PRESSURE_ULCER_REDUCTION)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.PRESSURE_ULCER_REDUCTION_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

SkinCareView.displayName = 'SkinCareView';
export default SkinCareView;

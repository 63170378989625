import { isNil } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Paginator } from '@els/els-react--paginator';
import { AssignmentSummary, TimeSpentRS } from 'models/api-response';
import { AssessmentStatus, AssignmentType, GradingOption, GradingResult } from 'models/enum';
import { AssessmentGrading, DropDownItem, FilterConfig } from 'models/ui';
import { DateTimeAmPmFormatByLocale, Locales, RESULTS_PER_PAGE } from 'constants/app.constant';
import { appHelper, assignmentHelper, dateTimeHelper } from 'helpers';
import { getPaginationConfig } from 'helpers/app.helper';
import { appActions, appSelectors } from 'redux/ducks/app';
import { Badge, Box, EllipseIcon, RoundCheckmarkIcon, RoundCloseIcon } from 'components/common';
import { ELSButton, ELSDataTable, ELSDropDown, ELSFlex, ELSFlexItem, ELSIcon, ELSTextBox, ELSTooltip } from 'components/common/els';
import { Label, PaginatorA11y, SectionTitle, SortField } from './constants';
import { feedbackRenderer, renderSortIcon } from './GradingProgressViewHelper';
import './grading.progerss.scss';
import { featureService } from '../../../services';

export interface GradingProgressViewProps {
  assignmentId: number;
  nameSearchValue: string;
  statusFilterValue: string;
  assignmentSummary: AssignmentSummary;
  assessmentSubmissions: AssessmentGrading[];
  onSearchNameChange: Function;
  onStatusChange: Function;
  onStudentClick: Function;
  assignmentTimeSpentList: TimeSpentRS[];
  filterConfig?: FilterConfig;
  setFilterConfig?: Function;
  handleSort: Function;
  sortField: string;
  isSortDesc: boolean;
  locale: Locales;
}

interface GradingProgressViewState {
  currentPage: number;
  isInProgressGradingEnabled: boolean;
}

class GradingProgressView extends Component<GradingProgressViewProps, GradingProgressViewState> {
  static displayName = 'GradingProgressView';

  statuses: DropDownItem[];

  constructor(props) {
    super(props);
    this.statuses = [
      { name: 'All Statuses', value: '' },
      { name: assignmentHelper.getPrettyStatus(AssessmentStatus.COMPLETED), value: AssessmentStatus.COMPLETED },
      { name: assignmentHelper.getPrettyStatus(AssessmentStatus.IN_PROGRESS), value: AssessmentStatus.IN_PROGRESS },
      { name: assignmentHelper.getPrettyStatus(AssessmentStatus.NOT_STARTED), value: AssessmentStatus.NOT_STARTED },
      { name: assignmentHelper.getPrettyStatus(AssessmentStatus.COMPLETED_LATE), value: AssessmentStatus.COMPLETED_LATE },
      { name: assignmentHelper.getPrettyStatus(AssessmentStatus.PAST_DUE), value: AssessmentStatus.PAST_DUE }
    ];
    this.state = {
      currentPage: this.props.filterConfig.currentPage || 1,
      isInProgressGradingEnabled: false
    };
  }

  async componentDidMount() {
    window.addEventListener('beforeunload', this.onPageConfigReload);
    // We retrieved data from filterConfig in GradingProgress and from this constructor, so we have to clean it here
    this.props.setFilterConfig({});

    const isInProgressGradingEnabled = await featureService.isInProgressGradingEnabled();
    this.setState({ isInProgressGradingEnabled });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onPageConfigReload);
  }

  onPageConfigReload = () => {
    const { nameSearchValue, statusFilterValue, assessmentSubmissions } = this.props;
    const { realizedPage } = getPaginationConfig(assessmentSubmissions.length, RESULTS_PER_PAGE, this.state.currentPage);
    this.props.setFilterConfig({
      searchName: nameSearchValue,
      status: statusFilterValue,
      currentPage: realizedPage
    });
  };

  assignmentStatusRenderer = (row: AssessmentGrading) => (
    <Badge inline>
      <>
        {row.status === AssessmentStatus.IN_PROGRESS && <EllipseIcon />}
        {assignmentHelper.getPrettyStatus(row.status)}
      </>
    </Badge>
  );

  startedRenderer = (row: AssessmentGrading) => (
    <div>
      {row.startedAt
        ? dateTimeHelper.formatDate({
            date: dateTimeHelper.toMomentWithParsers(row.startedAt).toDate(),
            includeTime: true,
            locale: this.props.locale,
            customFormat: DateTimeAmPmFormatByLocale[this.props.locale]
          })
        : 'Not Started'}
    </div>
  );

  timeSpentRenderer = (row: AssessmentGrading) => {
    let content = 'na';
    const { assignmentTimeSpentList } = this.props;

    const studentRow = assignmentTimeSpentList?.find((x) => {
      return x.assessmentId === row?.assessmentId;
    });
    if (row.completedAt && isNil(studentRow?.assessmentTotalTime)) {
      content = appHelper.getDuration(row.durationTime, true)?.dateString;
    }
    if (row.completedAt && !isNil(studentRow?.assessmentTotalTime)) {
      content = appHelper.timeSpentFormatter(studentRow.assessmentTotalTime);
    }
    return <div>{content}</div>;
  };

  studentRenderer = (row: AssessmentGrading) => {
    const name = `${row.firstName} ${row.lastName}`;
    const { isInProgressGradingEnabled } = this.state;
    const studentLink = (
      <ELSButton type="link" onClick={() => this.props.onStudentClick(row)}>
        {name}
      </ELSButton>
    );

    if (!isInProgressGradingEnabled && assignmentHelper.isAssessmentSubmitted(AssessmentStatus[row.status])) {
      return studentLink;
    }

    if (isInProgressGradingEnabled && !!row.startedAt) {
      return studentLink;
    }

    return <span>{name}</span>;
  };

  gradeRenderer = (row: AssessmentGrading) => {
    const { assignmentSummary } = this.props;
    if (assignmentSummary.assignmentGradeType === GradingOption.NOT_GRADED) {
      return <div>{assignmentHelper.getPrettyGradingResult(GradingResult.NA)}</div>;
    }
    if (assignmentSummary.assignmentGradeType === GradingOption.PASS_FAIL) {
      if (row.gradeStatus === GradingResult.PASS) {
        return (
          <div>
            <RoundCheckmarkIcon />
            <span className="u-els-color-confirm">{assignmentHelper.getPrettyGradingResult(row.gradeStatus)}</span>
          </div>
        );
      }
      if (row.gradeStatus === GradingResult.FAIL) {
        return (
          <div>
            <RoundCloseIcon />
            <span className="u-els-color-warn">{assignmentHelper.getPrettyGradingResult(row.gradeStatus)}</span>
          </div>
        );
      }
    }
    if (assignmentSummary.assignmentGradeType === GradingOption.SCORED) {
      const { totalCorrect, totalQuestions } = row?.caseStudySubmissionSummary;
      const score = assignmentHelper.getScore(totalCorrect, totalQuestions, row.status);
      return <div>{assignmentHelper.getPrettyScore(score)}</div>;
    }
    return <div>{assignmentHelper.getPrettyGradingResult(GradingResult.NA)}</div>;
  };

  timeSpentHeaderRenderer = () => (
    <div className="u-els-nowrap">
      <span>{SectionTitle.TIME_SPENT}</span>
      <span className="u-els-margin-left-1o2">
        <ELSTooltip wrapperClassName="limit-max-width" content={Label.TOTAL_TIME_REPRESENTS} showClose showWhenHover>
          <ELSIcon prefix="gizmo" name="information" size="1x" />
        </ELSTooltip>
      </span>
    </div>
  );

  nameHeaderRenderer = () => {
    const { sortField, isSortDesc, handleSort } = this.props;
    const isSortActive = sortField === SortField.NAME;
    return (
      <button type="button" className="c-els-table__sortable-button" onClick={handleSort(SortField.NAME)}>
        <span className="u-els-margin-right-1o4 c-els-table__sortable-button-text">Name</span>
        {isSortActive && renderSortIcon(isSortDesc)}
      </button>
    );
  };

  feedbackHeaderRenderer = () => {
    const { sortField, isSortDesc, handleSort } = this.props;
    const isSortActive = sortField === SortField.FEEDBACK;
    return (
      <button type="button" className="u-els-nowrap c-els-table__sortable-button" onClick={handleSort(SortField.FEEDBACK)}>
        <span className="u-els-margin-right-1o4 c-els-table__sortable-button-text">Feedback Provided</span>
        {isSortActive && renderSortIcon(isSortDesc)}
      </button>
    );
  };

  handlePageChange = (pageNum: number) => {
    this.setState({ currentPage: pageNum });
  };

  renderPagination = (totalResults: number, pageSize: number, realizedPage: number) => {
    return (
      <div className="o-els-flex-layout">
        {totalResults > pageSize && (
          <Paginator
            className="o-els-flex-layout__item"
            resultsPerPage={pageSize}
            currentPage={realizedPage}
            totalResults={totalResults}
            a11y={PaginatorA11y}
            onPageChange={this.handlePageChange}
          />
        )}
        <Box mt mb mr className="o-els-flex-layout__item u-els-margin-left-auto u-els-font-family-italic">{`${totalResults} Students`}</Box>
      </div>
    );
  };

  handleExportClick = () => {
    const columnsHeader = ['Name', 'Assignment Status', 'Started', 'Time Spent', 'Feedback Provided', 'Grade'];
    const mappedData = this.props.assessmentSubmissions.map((submission) => {
      const dateStarted = submission.startedAt
        ? dateTimeHelper.formatDate({
            date: dateTimeHelper.toMomentWithParsers(submission.startedAt).toDate(),
            includeTime: true,
            locale: this.props.locale,
            customFormat: DateTimeAmPmFormatByLocale[this.props.locale]
          })
        : 'Not Started';
      const gradeStr = Array.isArray(this.gradeRenderer(submission).props?.children)
        ? this.gradeRenderer(submission).props.children[1]?.props.children
        : this.gradeRenderer(submission).props.children;

      return [
        `${submission.firstName} ${submission.lastName}`,
        assignmentHelper.getPrettyStatus(submission.status),
        dateStarted,
        this.timeSpentRenderer(submission).props.children,
        submission.feedback ? 'Done' : 'None',
        `${gradeStr}`
      ];
    });

    if (mappedData?.length) {
      const fileName = `scores_report_${dateTimeHelper.formatDate({
        date: new Date(),
        customFormat: 'MMDDYYYY_HHmmss'
      })}`;
      appHelper.exportCSV(mappedData, columnsHeader, fileName);
    }
  };

  render() {
    const { assignmentSummary, assessmentSubmissions, onSearchNameChange, onStatusChange, locale } = this.props;
    const { currentPage } = this.state;
    const totalStudents = assessmentSubmissions.length || 0;
    const completedStudents = assignmentSummary?.studentAssessmentSubmissions?.filter((assignment) => assignment.status === AssessmentStatus.COMPLETED).length || 0;
    const pageSize = RESULTS_PER_PAGE;
    const { realizedPage, sliceStart, sliceEnd } = getPaginationConfig(assessmentSubmissions.length, pageSize, currentPage);
    const displayedAssessmentSubmissions = assessmentSubmissions.slice(sliceStart, sliceEnd);
    const baseColumnHeaderCss = ['', 'u-els-nowrap'];

    return (
      <>
        {assignmentSummary && (
          <div className="grading-progress">
            <h1 className="u-els-padding-2x">{assignmentSummary.title}</h1>
            <Box px2 py3 className="u-els-background-color-n0">
              <h2>{SectionTitle.STUDENT_GRADING_PROGRESS}</h2>
              <ELSFlex className="u-els-margin-top" left>
                <ELSFlexItem className="u-els-margin-right-5x">
                  <div>
                    <p>{Label.START_DATE}</p>
                  </div>
                  <div>
                    {dateTimeHelper.formatDate({
                      date: dateTimeHelper.toMomentWithParsers(assignmentSummary.availableDate).toDate(),
                      includeTime: true,
                      locale,
                      customFormat: DateTimeAmPmFormatByLocale[locale]
                    })}
                  </div>
                  <br />
                  <div>
                    <p>{Label.DUE_DATE}</p>
                  </div>
                  <div>
                    {dateTimeHelper.formatDate({
                      date: dateTimeHelper.toMomentWithParsers(assignmentSummary.dueDate).toDate(),
                      includeTime: true,
                      locale,
                      customFormat: DateTimeAmPmFormatByLocale[locale]
                    })}
                  </div>
                </ELSFlexItem>
                <ELSFlexItem>
                  <div>
                    <p>{Label.GRADING}</p>
                  </div>
                  <div>{assignmentSummary.assignmentGradeType === GradingOption.NOT_GRADED && 'Not Graded'}</div>
                  <div>{assignmentSummary.assignmentGradeType === GradingOption.PASS_FAIL && 'Pass / Fail'}</div>
                  <div>{assignmentSummary.assignmentGradeType === GradingOption.SCORED && 'Scored'}</div>
                  <br />
                  <div>
                    <p>{Label.STUDENTS_SUBMITTED}</p>
                  </div>
                  <div>{`${completedStudents} / ${totalStudents}`}</div>
                </ELSFlexItem>
              </ELSFlex>
            </Box>
            <Box p2>
              <ELSFlex className="u-els-padding-top" left>
                <ELSFlexItem grow className="u-els-margin-right">
                  <ELSTextBox iconLeft="search" iconLeftPrefix="gizmo" placeholder="Search name" value={this.props.nameSearchValue} changeHandler={onSearchNameChange} />
                </ELSFlexItem>
                <ELSFlexItem>
                  <ELSDropDown options={this.statuses} value={this.props.statusFilterValue} changeHandler={onStatusChange} />
                </ELSFlexItem>
              </ELSFlex>
            </Box>

            <Box px2 pb2>
              <div>
                <div className="export-block">
                  {assignmentSummary.assignmentType === AssignmentType.CASE_STUDY ? (
                    <ELSButton
                      data-testid="btn-export-grading-student"
                      type="secondary"
                      isDisabled={!displayedAssessmentSubmissions?.length}
                      text="Export Scores"
                      className="sng-button"
                      onClick={this.handleExportClick}
                    />
                  ) : (
                    <div />
                  )}

                  {this.renderPagination(assessmentSubmissions.length, pageSize, realizedPage)}
                </div>

                <ELSDataTable cssModifier="c-els-table--row-highlight" data={displayedAssessmentSubmissions} columnHeaderCss={baseColumnHeaderCss}>
                  <column header={this.nameHeaderRenderer()} customRender={this.studentRenderer} />
                  <column header="Assignment Status" customRender={this.assignmentStatusRenderer} />
                  <column header="Started" customRender={this.startedRenderer} />
                  <column header={this.timeSpentHeaderRenderer()} customRender={this.timeSpentRenderer} />
                  <column header={this.feedbackHeaderRenderer()} customRender={feedbackRenderer} />
                  <column header="Grade" customRender={this.gradeRenderer} />
                </ELSDataTable>
                {this.renderPagination(assessmentSubmissions.length, pageSize, realizedPage)}
              </div>
            </Box>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  filterConfig: appSelectors.getFilterConfig(state)
});

const mapDispatchToProps = (dispatch) => ({
  setFilterConfig: (filterConfig) => dispatch(appActions.setFilterConfig(filterConfig))
});

export { GradingProgressView as BaseGradingProgressView };
export default connect(mapStateToProps, mapDispatchToProps)(GradingProgressView);

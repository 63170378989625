import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { ChartViewProps } from 'components/features/shared/constants';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import SensoryAssessmentView from './SensoryAssessmentView';
import { Title } from '../shared/constants';

interface SensoryAssessmentState {
  resetAll: number;
}

class SensoryAssessment extends Component<ChartComponentProps, SensoryAssessmentState> {
  static displayName = 'SensoryAssessment';

  constructor(props) {
    super(props);
    this.state = {
      resetAll: 0
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    // set map items
    const checkBoxes = [
      FormField.ASSESSMENT_NOT_REQUIRED,
      FormField.VISION_ASSESSMENT_NOT_REQUIRED,
      FormField.HEARING_ASSESSMENT_NOT_REQUIRED,
      FormField.GLASSES_OR_CONTACTS,
      FormField.PUPILLARY_REACTION_LEFT_EYE_CHECK,
      FormField.PUPILLARY_REACTION_RIGHT_EYE_CHECK
    ];
    const multiselectDropdowns = [FormField.VISION_ASSESSMENT, FormField.HEARING_ASSESSMENT];
    const radioChoices = [
      FormField.BILATERAL_ASSESSMENT_EQUAL,
      FormField.BILATERAL_ASSESSMENT_ROUND,
      FormField.PUPILLARY_REACTION_LEFT_EYE_RADIO,
      FormField.PUPILLARY_REACTION_RIGHT_EYE_RADIO,
      FormField.ACCOMMODATION_PRESENT
    ];
    const textAreas = [FormField.VISION_NOTES, FormField.HEARING_NOTES, FormField.ACCOMMODATION_NOTES];
    const textBoxes = [FormField.BILATERAL_ASSESSMENT_EQUAL_REASON, FormField.BILATERAL_ASSESSMENT_ROUND_REASON, FormField.SIZE_LEFT, FormField.SIZE_RIGHT];
    checkBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    multiselectDropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN })));
    radioChoices.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));
    textBoxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX })));

    // set unique labels
    dataMap.set(FormField.BILATERAL_ASSESSMENT_EQUAL, { ...dataMap.get(FormField.BILATERAL_ASSESSMENT_EQUAL), label: 'Equal?' });
    dataMap.set(FormField.BILATERAL_ASSESSMENT_EQUAL_REASON, { ...dataMap.get(FormField.BILATERAL_ASSESSMENT_EQUAL_REASON), label: 'Reason:' });
    dataMap.set(FormField.BILATERAL_ASSESSMENT_ROUND, { ...dataMap.get(FormField.BILATERAL_ASSESSMENT_ROUND), label: 'Round?' });
    dataMap.set(FormField.BILATERAL_ASSESSMENT_ROUND_REASON, { ...dataMap.get(FormField.BILATERAL_ASSESSMENT_ROUND_REASON), label: 'Reason:' });
    dataMap.set(FormField.SIZE_LEFT, { ...dataMap.get(FormField.SIZE_LEFT), label: 'Left pupil' });
    dataMap.set(FormField.SIZE_RIGHT, { ...dataMap.get(FormField.SIZE_RIGHT), label: 'Right pupil' });
    dataMap.set(FormField.PUPILLARY_REACTION_LEFT_EYE_RADIO, { ...dataMap.get(FormField.PUPILLARY_REACTION_LEFT_EYE_RADIO), label: 'Left eye reaction to light' });
    dataMap.set(FormField.PUPILLARY_REACTION_RIGHT_EYE_RADIO, { ...dataMap.get(FormField.PUPILLARY_REACTION_RIGHT_EYE_RADIO), label: 'Right eye reaction to light' });
    dataMap.set(FormField.ACCOMMODATION_PRESENT, { ...dataMap.get(FormField.ACCOMMODATION_PRESENT), label: 'Accommodation present?' });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.SENSORY_ASSESSMENT, fields: [FormField.ASSESSMENT_NOT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.VISION_ASSESSMENT,
        fields: [FormField.VISION_ASSESSMENT_NOT_REQUIRED, FormField.VISION_ASSESSMENT, FormField.GLASSES_OR_CONTACTS, FormField.VISION_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.HEARING_ASSESSMENT,
        fields: [FormField.HEARING_ASSESSMENT_NOT_REQUIRED, FormField.HEARING_ASSESSMENT, FormField.HEARING_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.BILATERAL_ASSESSMENT,
        fields: [
          FormField.BILATERAL_ASSESSMENT_EQUAL,
          FormField.BILATERAL_ASSESSMENT_EQUAL_REASON,
          FormField.BILATERAL_ASSESSMENT_ROUND,
          FormField.BILATERAL_ASSESSMENT_ROUND_REASON
        ],
        formFieldMap,
        parentSectionTitle: SectionTitle.PUPILS
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SIZE,
        fields: [FormField.SIZE_LEFT, FormField.SIZE_RIGHT],
        formFieldMap,
        parentSectionTitle: SectionTitle.PUPILS
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PUPILLARY_REACTION_LEFT,
        fields: [FormField.PUPILLARY_REACTION_LEFT_EYE_RADIO, FormField.PUPILLARY_REACTION_LEFT_EYE_CHECK],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PUPILLARY_REACTION_RIGHT,
        fields: [FormField.PUPILLARY_REACTION_RIGHT_EYE_RADIO, FormField.PUPILLARY_REACTION_RIGHT_EYE_CHECK],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.PUPILLARY_ACCOMODATION,
        fields: [FormField.ACCOMMODATION_PRESENT, FormField.ACCOMMODATION_NOTES],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: SectionTitle.SENSORY_ASSESSMENT,
      // think of this "records" as sections
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;

    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: ChartViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      resetAll: this.state.resetAll
    };
    return <SensoryAssessmentView {...viewProps} />;
  }
}

export { SensoryAssessment as BaseSensoryAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(SensoryAssessment);

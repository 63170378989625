import { Component } from 'react';
import { connect } from 'react-redux';
import { RoutePath } from 'constants/app.constant';
import { instructorActions, instructorSelectors } from 'redux/ducks/instructor';
import BlankChartingContentView, { BlankChartingContentViewProps } from './BlankChartingContentView';

export interface BlankChartingContentProps {
  objectives: string;
  instructions: string;
  setNewAssignment: Function;
  setPrevCreateAssignmentStep: Function;
  prevCreateAssignmentStep: string;
}

class BlankChartingContent extends Component<BlankChartingContentProps> {
  static displayName = 'BlankChartContent';

  componentDidMount() {
    const lastStep = this.props.prevCreateAssignmentStep;

    if (lastStep !== RoutePath.instructor.configureAssignment) {
      this.props.setNewAssignment({ objectives: '' });
      this.props.setNewAssignment({ instructions: '' });
    }

    this.props.setPrevCreateAssignmentStep(RoutePath.instructor.contentSelection);
  }

  handleObjectiveChange = ({ target }) => this.props.setNewAssignment({ objectives: target.value });
  handleInstructionChange = ({ target }) => this.props.setNewAssignment({ instructions: target.value });

  render() {
    const viewProps: BlankChartingContentViewProps = {
      objectives: this.props.objectives,
      instructions: this.props.instructions,
      onObjectiveChange: this.handleObjectiveChange,
      onInstructionChange: this.handleInstructionChange
    };
    return <BlankChartingContentView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  objectives: instructorSelectors.getObjectives(state),
  instructions: instructorSelectors.getInstructions(state),
  prevCreateAssignmentStep: instructorSelectors.getPrevCreateAssignmentStep(state)
});

const mapDispatchToProps = (dispatch) => ({
  setNewAssignment: (payload) => dispatch(instructorActions.setNewAssignment(payload)),
  setPrevCreateAssignmentStep: (payload) => dispatch(instructorActions.setPrevCreateAssignmentStep(payload))
});

export { BlankChartingContent as BaseBlankChartingContent };
export default connect(mapStateToProps, mapDispatchToProps)(BlankChartingContent);

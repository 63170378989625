import { History } from 'history';
import { Locales } from 'constants/app.constant';

export const checkIsValidLocale = (locale: Locales | string): boolean => {
  return Object.values(Locales).includes(locale as Locales);
};

export const watchLocaleChange = <T extends { locale: Locales }>({
  history,
  locale,
  searchParamLocale,
  changeLocaleCb,
  prevProps
}: {
  history: History;
  locale: Locales;
  searchParamLocale: string;
  changeLocaleCb: (locale: Locales) => void;
  prevProps?: Readonly<T>;
}) => {
  let currentLocale = (searchParamLocale as Locales) || locale;

  if ((prevProps && locale !== prevProps.locale) || (!prevProps && locale !== searchParamLocale)) {
    currentLocale = locale;
  }

  if (!checkIsValidLocale(currentLocale)) {
    currentLocale = locale || Locales.EN_US;
  }

  if (locale !== searchParamLocale) {
    history.replace({
      search: `?${new URLSearchParams({
        locale: currentLocale
      })}`
    });

    changeLocaleCb(currentLocale);
  }
};

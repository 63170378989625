import cx from 'classnames';
import { MouseEventHandler } from 'react';
import { AssignmentType } from 'models/enum';
import { AssignmentTile } from 'models/ui';
import { ELSIcon } from 'components/common/els';
import './assignment.selection.tile.scss';

export interface AssignmentSelectionTileProps extends AssignmentTile {
  handleClick: MouseEventHandler;
}

const AssignmentSelectionTile = ({ assignmentType, description, handleClick, isDisabled, isSelected, title }: AssignmentSelectionTileProps) => (
  <button
    type="button"
    className={cx('assignment-selection-tile', {
      'assignment-selection-tile--selected': isSelected,
      'u-els-display-none': assignmentType === AssignmentType.SIMULATION
    })}
    value={assignmentType}
    onClick={handleClick}
    disabled={isDisabled}
  >
    <span>
      <ELSIcon name="simulation-nursing" size="4x" color="primary" />
    </span>
    <span className="assignment-selection-tile__content">
      <h1 className="assignment-selection-tile__title">{title}</h1>
      <p className="assignment-selection-tile__description">{description}</p>
    </span>
    {isSelected && (
      <span className="assignment-selection-tile__checkmark">
        <ELSIcon name="checkmark" size="1x" color="white" />
      </span>
    )}
  </button>
);

AssignmentSelectionTile.displayName = 'AssignmentSelectionTile';
export default AssignmentSelectionTile;

import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import AllergyInformationHistory, { AllergyInformationChartHistory } from './AllergyInformationHistory';

export interface AllergyInformationReadOnlyProps {
  chartFragments: AllergyInformationChartHistory[];
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const AllergyInformationReadOnly = (props: AllergyInformationReadOnlyProps) => {
  const { chartFragments, statusFragment, hideHeadline } = props;

  return (
    <Box className="allergy-info-ready-only" mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragments[0] as ChartFragment} />
        </>
      )}
      <h2>Allergy Information</h2>
      <Box className="sng-article">
        {!chartFragments.length && <h3>Chart Omitted</h3>}
        {chartFragments.length > 0 && <AllergyInformationHistory chartHistory={props.chartFragments} />}
      </Box>
    </Box>
  );
};

AllergyInformationReadOnly.displayName = 'AllergyInformationReadOnly';
export default AllergyInformationReadOnly;

// eslint-disable-next-line import/no-webpack-loader-syntax,@typescript-eslint/no-var-requires
const icon = require('!!raw-loader!assets/img/constructive-feedback.svg');

interface ConstructiveFeedbackIconProps {
  id?: string;
  className?: string;
}

const ConstructiveFeedbackIcon = (props: ConstructiveFeedbackIconProps) => (
  // eslint-disable-next-line react/no-danger
  <span id={props.id} className={props.className} dangerouslySetInnerHTML={{ __html: icon }} />
);
ConstructiveFeedbackIcon.displayName = 'ConstructiveFeedbackIcon';
export default ConstructiveFeedbackIcon;

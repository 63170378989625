import cx from 'classnames';
import { ReactNode } from 'react';
import './accordion.badge.scss';

interface AccordionBadgeProps {
  children: ReactNode;
  className?: string;
}

const AccordionBadge = (props: AccordionBadgeProps) => (
  <div className={cx('u-els-display-inline-block c-els-badge c-els-badge--accordion c-els-badge--inline c-els-badge--background-subtile', props.className)}>
    <p>{props.children}</p>
  </div>
);

AccordionBadge.displayName = 'AccordionBadge';
export default AccordionBadge;

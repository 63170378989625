import produce from 'immer';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, HealthHistoryFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext, PregnancyInfoRecord, PreviousPregnanciesSection } from 'models/ui';
import { ChartLabel, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, SectionTitle } from './constants';
import HealthHistoryView from './HealthHistoryView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

const getSelectedContentIds = (existingDataMap, formFieldId): string[] => (existingDataMap ? existingDataMap.get(formFieldId)?.map((it) => it.contentId) : []);
const getSelectedContent = (existingDataMap, formFieldId): string => existingDataMap.get(formFieldId)?.content || '';

// assume the content of text box, text area would be in the first item.
const getRecordContent = (fragment: ChartFragment, sectionTitle: string, formField: string) => chartHelper.getFragmentRecords(fragment, sectionTitle, formField)[0] || '';

const getRecordsContent = (fragment: ChartFragment, sectionTitle: string, formField: string) => chartHelper.getFragmentRecords(fragment, sectionTitle, formField);

const transformFragmentData = (fragment: ChartFragment) => {
  const fieldMap = new Map();
  const formFieldRecordContents = [
    { name: FormField.ENV_HISTORY_CONCERN, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_EXPLAIN, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_BRANCH, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_DATE, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_POSITION, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_DEPLOYMENT, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_ENVIRONMENTAL, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_INJURIES, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_ASSAULTS, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.TRAVEL_EXPLAIN, sectionTitle: SectionTitle.TRAVEL },
    { name: FormField.ENDOCRINE_YEARS, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES_YEARS },
    { name: FormField.HEMATOLOGIC_CANCER, sectionTitle: SectionTitle.HEMATOLOGIC_CANCER },
    { name: FormField.ANESTHESIA_NOTE, sectionTitle: SectionTitle.ANESTHESIA_NOTE },
    { name: FormField.OTHER_HEALTH_HISTORY, sectionTitle: SectionTitle.OTHER_HEALTH_HISTORY },
    { name: FormField.RECENT_FEVER_ONSET_OTHER, sectionTitle: SectionTitle.RECENT_FEVER_ONSET_OTHER },
    { name: FormField.OBSTETRIC_HISTORY_GRAVIDA, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_PARA, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_TERM, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_PRETERM, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_ABORTION, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_LIVING, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.CURRENT_PREGNANCY_PROVIDER_NAME, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_RISK_FACTORS_IDENTIFIED, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_DESCRIBE, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_PROBLEMS_IDENTIFIED, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_TIME_OF_LAST_ACTIVITY, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.LAST_ORAL_INTAKE_DAY_DESCRIPTION, sectionTitle: SectionTitle.LAST_ORAL_INTAKE },
    { name: FormField.OBSTETRIC_HISTORY_BABY_DUE, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_LAST_MENSTRUAL_PERIOD, sectionTitle: SectionTitle.OBSTETRIC_HISTORY }
  ];
  const formFieldRecordsContents = [
    { name: FormField.NO_PREVIOUS_HEALTH_PROBLEM, sectionTitle: SectionTitle.NO_PREVIOUS_HEALTH_PROBLEM },
    { name: FormField.ENV_HISTORY_EXPOSE, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.ENV_HISTORY_MILITARY, sectionTitle: SectionTitle.ENVIRONMENT_HISTORY },
    { name: FormField.TRAVEL, sectionTitle: SectionTitle.TRAVEL },
    { name: FormField.MENTAL_HEALTH, sectionTitle: SectionTitle.MENTAL_HEALTH },
    { name: FormField.GENETIC_DISORDERS, sectionTitle: SectionTitle.GENETIC_DISORDERS },
    { name: FormField.GENERAL, sectionTitle: SectionTitle.GENERAL },
    { name: FormField.NEUROLOGICAL_PROBLEMS, sectionTitle: SectionTitle.NEUROLOGICAL_PROBLEMS },
    { name: FormField.RESPIRATORY_PROBLEMS, sectionTitle: SectionTitle.RESPIRATORY_PROBLEMS },
    { name: FormField.RESPIRATORY_UPPER, sectionTitle: SectionTitle.RESPIRATORY_UPPER },
    { name: FormField.RESPIRATORY_LOWER, sectionTitle: SectionTitle.RESPIRATORY_LOWER },
    { name: FormField.RESPIRATORY_OTHER, sectionTitle: SectionTitle.RESPIRATORY_OTHER },
    { name: FormField.CARDIOVASCULAR_PROBLEMS, sectionTitle: SectionTitle.CARDIOVASCULAR_PROBLEMS },
    { name: FormField.CONGENITAL_HEART_DEFECT, sectionTitle: SectionTitle.CONGENITAL_HEART_DEFECT },
    { name: FormField.SENSORY_HEARING, sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT },
    { name: FormField.SENSORY_HEARING_CHOICE, sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT_CHOICE },
    { name: FormField.SENSORY_VISUAL, sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT },
    { name: FormField.SENSORY_VISUAL_CHOICE, sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT_CHOICE },
    { name: FormField.INTEGUMENTORY_PROBLEMS, sectionTitle: SectionTitle.INTEGUMENTORY_PROBLEMS },
    { name: FormField.ENDOCRINE_THYROID, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_THYROID_DISEASE },
    { name: FormField.ENDOCRINE_DISORDERS, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS },
    { name: FormField.ENDOCRINE_LIVER, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_LIVER_DISEASE },
    { name: FormField.ENDOCRINE_OTHER, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_OTHER_DISORDERS },
    { name: FormField.ENDOCRINE_PITUITARY, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_PITUITARY_DISORDER },
    { name: FormField.ENDOCRINE_DIABETES, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES },
    { name: FormField.ENDOCRINE_DIABETES_COMPLIANT, sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES_COMPLIANT },
    { name: FormField.GASTROINTESTINAL, sectionTitle: SectionTitle.GASTROINTESTINAL_PROBLEMS },
    { name: FormField.HEMATOLOGIC, sectionTitle: SectionTitle.HEMATOLOGIC_PROBLEMS },
    { name: FormField.GENITOURINARY, sectionTitle: SectionTitle.GENITOURINARY },
    { name: FormField.SEXUALITY, sectionTitle: SectionTitle.SEXUALITY },
    { name: FormField.SEXUALITY_ACTIVE, sectionTitle: SectionTitle.SEXUALITY_ACTIVE },
    { name: FormField.SEXUALITY_NUMBER_PARTNERS, sectionTitle: SectionTitle.SEXUALITY_NUMBER_PARTNERS },
    { name: FormField.SEXUALITY_SEX_PARTNER, sectionTitle: SectionTitle.SEXUALITY_SEX_PARTNER },
    { name: FormField.SEXUALITY_BIRTH_CONTROL, sectionTitle: SectionTitle.SEXUALITY_BIRTH_CONTROL },
    { name: FormField.MUSCULOSKELETAL, sectionTitle: SectionTitle.MUSCULOSKELETAL_PROBLEMS },
    { name: FormField.PREVIOUS_SURGERY, sectionTitle: SectionTitle.PREVIOUS_SURGERY },
    { name: FormField.PREVIOUS_SURGERY_NONE, sectionTitle: SectionTitle.PREVIOUS_SURGERY_NONE },
    { name: FormField.ANESTHESIA, sectionTitle: SectionTitle.ANESTHESIA_PROBLEMS },
    { name: FormField.RECENT_FEVER, sectionTitle: SectionTitle.RECENT_FEVER_REQUIRING_ANTIBIOTICS },
    { name: FormField.RECENT_FEVER_ONSET, sectionTitle: SectionTitle.RECENT_FEVER_ONSET },
    { name: FormField.OBSTETRIC_HISTORY_ARE_YOU_PREGNANT, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_COMFIRMED_ULTRASOUND, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.OBSTETRIC_HISTORY_ARE_YOU_BREAST_FEEDING, sectionTitle: SectionTitle.OBSTETRIC_HISTORY },
    { name: FormField.CURRENT_PREGNANCY_RECEIVED_PRENATAL_CARE, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_GROUP_B_STREPTOCOCCUS_STATUS, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_VAGINAL_DISCHARGE, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_MULTIPLE_GESTATION, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY },
    { name: FormField.CURRENT_PREGNANCY_IS_FETUS_ACTIVE, sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY }
  ];
  const formFieldValue = [
    { name: FormField.LAST_ORAL_INTAKE_DAY, sectionTitle: SectionTitle.LAST_ORAL_INTAKE },
    { name: FormField.LAST_ORAL_INTAKE_TIME, sectionTitle: SectionTitle.LAST_ORAL_INTAKE }
  ];
  formFieldRecordContents.forEach(({ name, sectionTitle }) => fieldMap.set(name, getRecordContent(fragment, sectionTitle, name)));
  formFieldRecordsContents.forEach(({ name, sectionTitle }) => fieldMap.set(name, getRecordsContent(fragment, sectionTitle, name)));
  formFieldValue.forEach(({ name, sectionTitle }) => fieldMap.set(name, chartHelper.getFragmentValue(fragment, sectionTitle, name)));

  return fieldMap;
};

export interface HealthHistoryProps extends ChartComponentProps {
  patientContext: PatientContext;
}

interface HealthHistoryState {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  isSidebarOpen: boolean;
  previousPregnanciesHistory: ChartFragment[];
  resetKey: number;
}

class HealthHistory extends Component<HealthHistoryProps, HealthHistoryState> {
  static displayName = 'HealthHistory';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null,
      statusFragment: null,
      isSidebarOpen: false,
      previousPregnanciesHistory: [],
      resetKey: 0
    };
  }

  componentDidMount() {
    this.loadData().then((chartFragment) => {
      this.props.initState(this.buildFormFields(chartFragment));
      this.bindChartHistory(chartFragment);
    });
  }

  loadData = () => {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.HEALTH_HISTORY, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) });
        return chartFragment;
      }),
      { errorMessage: 'can not load chart data' }
    );
  };

  buildFormFields = (fragment?: HealthHistoryFragment): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const existingDataMap = transformFragmentData(fragment);

    const dataMap = new Map();
    const containers = [
      FormField.CONTAINER_ENVIRONMENT_HISTORY,
      FormField.CONTAINER_MENTAL_HEALTH,
      FormField.CONTAINER_GENETIC_DISORDERS,
      FormField.CONTAINER_GENERAL,
      FormField.CONTAINER_RESPIRATORY_PROBLEMS,
      FormField.CONTAINER_RESPIRATORY_PROBLEMS_PEDIATRIC,
      FormField.CONTAINER_CONGENITAL_HEART_DEFECT,
      FormField.CONTAINER_INTEGUMENTARY_PROBLEMS,
      FormField.CONTAINER_SEXUALITY_REPRODUCTIVE,
      FormField.CONTAINER_THYROID_DISEASE,
      FormField.CONTAINER_ENDOCRINE_DISORDERS,
      FormField.CONTAINER_OTHER_DISORDERS,
      FormField.CONTAINER_PITUITARY_DISORDER,
      FormField.CONTAINER_DIABETES,
      FormField.CONTAINER_OBSTETRIC_HISTORY,
      FormField.CONTAINER_HISTORY_CURRENT_PREGNANCY,
      FormField.CONTAINER_LAST_ORAL_INTAKE,
      FormField.CONTAINER_PREVIOUS_PREGNANCIES
    ];

    const radios = [
      { name: FormField.ENV_HISTORY_EXPOSE, label: 'Are you exposed to hazardous material and/or chemicals in the workplace or living environment?' },
      { name: FormField.ENV_HISTORY_MILITARY, label: 'Have you been in the military?' },
      { name: FormField.TRAVEL, label: 'Have you traveled to a foreign country in the past 12 months?' },
      { name: FormField.SENSORY_HEARING, label: 'Hearing impairment' },
      { name: FormField.SENSORY_VISUAL, label: 'Visual impairment' },
      { name: FormField.ENDOCRINE_DIABETES_COMPLIANT, label: '' },
      { name: FormField.SEXUALITY_ACTIVE, label: 'Sexually active?' },
      { name: FormField.SEXUALITY_SEX_PARTNER, label: 'Sex of partners' },
      { name: FormField.SEXUALITY_BIRTH_CONTROL, label: 'Birth control used' },
      { name: FormField.ANESTHESIA, label: 'Problems reported?' },
      { name: FormField.OBSTETRIC_HISTORY_ARE_YOU_PREGNANT, label: 'Are you pregnant?' },
      { name: FormField.OBSTETRIC_HISTORY_COMFIRMED_ULTRASOUND, label: 'Confirmed by ultrasound?' },
      { name: FormField.OBSTETRIC_HISTORY_ARE_YOU_BREAST_FEEDING, label: 'Are you breastfeeding?' },
      { name: FormField.CURRENT_PREGNANCY_RECEIVED_PRENATAL_CARE, label: 'Received prenatal care?' },
      { name: FormField.CURRENT_PREGNANCY_GROUP_B_STREPTOCOCCUS_STATUS, label: 'Group B Streptococcus status' },
      { name: FormField.CURRENT_PREGNANCY_VAGINAL_DISCHARGE, label: 'Vaginal discharge' },
      { name: FormField.CURRENT_PREGNANCY_MULTIPLE_GESTATION, label: 'Multiple gestation?' },
      { name: FormField.CURRENT_PREGNANCY_IS_FETUS_ACTIVE, label: 'Is fetus active?' }
    ];

    const checkboxes = [
      { name: FormField.NO_PREVIOUS_HEALTH_PROBLEM, label: '' },
      { name: FormField.SENSORY_HEARING_CHOICE, label: '' },
      { name: FormField.SENSORY_VISUAL_CHOICE, label: '' },
      { name: FormField.ENDOCRINE_OTHER, label: ChartLabel.MARK_ALL_THAT_APPLY },
      { name: FormField.PREVIOUS_SURGERY_NONE, label: '' },
      { name: FormField.RECENT_FEVER, label: '' }
    ];

    const multiselectDropdowns = [
      FormField.MENTAL_HEALTH,
      FormField.GENETIC_DISORDERS,
      FormField.GENERAL,
      FormField.NEUROLOGICAL_PROBLEMS,
      FormField.RESPIRATORY_PROBLEMS,
      FormField.RESPIRATORY_UPPER,
      FormField.RESPIRATORY_LOWER,
      FormField.RESPIRATORY_OTHER,
      FormField.CARDIOVASCULAR_PROBLEMS,
      FormField.CONGENITAL_HEART_DEFECT,
      FormField.INTEGUMENTORY_PROBLEMS,
      FormField.ENDOCRINE_THYROID,
      FormField.ENDOCRINE_DISORDERS,
      FormField.ENDOCRINE_LIVER,
      FormField.ENDOCRINE_PITUITARY,
      FormField.ENDOCRINE_DIABETES,
      FormField.GASTROINTESTINAL,
      FormField.HEMATOLOGIC,
      FormField.GENITOURINARY,
      FormField.SEXUALITY,
      FormField.MUSCULOSKELETAL,
      FormField.PREVIOUS_SURGERY
    ];

    const textboxes = [
      { name: FormField.ENV_HISTORY_BRANCH, label: 'Branch of service' },
      { name: FormField.ENV_HISTORY_DATE, label: 'Dates of military service' },
      { name: FormField.ENV_HISTORY_POSITION, label: 'Position (rank and operations)' },
      { name: FormField.ENV_HISTORY_DEPLOYMENT, label: 'Deployments (date/location)' },
      { name: FormField.ENV_HISTORY_ENVIRONMENTAL, label: 'Environmental exposures while in military' },
      { name: FormField.ENV_HISTORY_INJURIES, label: 'Injuries experienced while in military' },
      { name: FormField.ENV_HISTORY_ASSAULTS, label: 'Assaults experienced while in military (physical, verbal, sexual)' },
      { name: FormField.ENDOCRINE_YEARS, label: 'Years with diabetes' },
      { name: FormField.HEMATOLOGIC_CANCER, label: '' },
      { name: FormField.RECENT_FEVER_ONSET_OTHER, label: 'Other' },
      { name: FormField.OBSTETRIC_HISTORY_GRAVIDA, label: 'Gravida' },
      { name: FormField.OBSTETRIC_HISTORY_PARA, label: 'Para' },
      { name: FormField.OBSTETRIC_HISTORY_TERM, label: 'Term' },
      { name: FormField.OBSTETRIC_HISTORY_PRETERM, label: 'Preterm' },
      { name: FormField.OBSTETRIC_HISTORY_ABORTION, label: 'Abortion' },
      { name: FormField.OBSTETRIC_HISTORY_LIVING, label: 'Living' },
      { name: FormField.CURRENT_PREGNANCY_PROVIDER_NAME, label: 'Provider name' },
      { name: FormField.CURRENT_PREGNANCY_RISK_FACTORS_IDENTIFIED, label: 'Risk factors identified' },
      { name: FormField.CURRENT_PREGNANCY_DESCRIBE, label: 'Describe' },
      { name: FormField.CURRENT_PREGNANCY_PROBLEMS_IDENTIFIED, label: 'Problems/concerns identified:' },
      { name: FormField.CURRENT_PREGNANCY_TIME_OF_LAST_ACTIVITY, label: 'Time of last activity' },
      { name: FormField.LAST_ORAL_INTAKE_DAY_DESCRIPTION, label: 'Description of intake' },
      { name: FormField.OBSTETRIC_HISTORY_BABY_DUE, label: "Baby's due" },
      { name: FormField.OBSTETRIC_HISTORY_LAST_MENSTRUAL_PERIOD, label: 'Last menstrual period' }
    ];

    const textAreas = [
      { name: FormField.ENV_HISTORY_CONCERN, label: 'What concerns do you have about your living environment (stairs, personal safety, etc.)?' },
      { name: FormField.ENV_HISTORY_EXPLAIN, label: 'If yes, explain.' },
      { name: FormField.TRAVEL_EXPLAIN, label: 'If yes, explain where and when the travel took place.' },
      { name: FormField.ANESTHESIA_NOTE, label: '' },
      { name: FormField.OTHER_HEALTH_HISTORY, label: '' }
    ];

    const dates = [{ name: FormField.LAST_ORAL_INTAKE_DAY, label: 'Day' }];

    containers.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CONTAINER })));
    multiselectDropdowns.forEach((name) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN, contentIds: getSelectedContentIds(existingDataMap, name) }))
    );
    radios.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.RADIO_CHOICE,
          label: item.label,
          contentIds: getSelectedContentIds(existingDataMap, item.name)
        })
      )
    );
    checkboxes.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.CHECK_BOX,
          label: item.label,
          contentIds: getSelectedContentIds(existingDataMap, item.name)
        })
      )
    );
    textboxes.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.TEXT_BOX,
          label: item.label,
          value: getSelectedContent(existingDataMap, item.name)
        })
      )
    );
    textAreas.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.TEXT_AREA,
          label: item.label,
          value: getSelectedContent(existingDataMap, item.name)
        })
      )
    );
    dates.forEach((item) =>
      dataMap.set(
        item.name,
        createFormField({
          name: item.name,
          type: FormFieldInputType.DATE,
          label: item.label,
          value: existingDataMap.get(item.name)
        })
      )
    );
    dataMap.set(
      FormField.LAST_ORAL_INTAKE_TIME,
      createFormField({
        name: FormField.LAST_ORAL_INTAKE_TIME,
        type: FormFieldInputType.TIME,
        label: 'Time',
        value: existingDataMap.get(FormField.LAST_ORAL_INTAKE_TIME)
      })
    );

    dataMap.set(
      FormField.SEXUALITY_NUMBER_PARTNERS,
      createFormField({
        name: FormField.SEXUALITY_NUMBER_PARTNERS,
        type: FormFieldInputType.DROPDOWN,
        label: 'Number of sex partners',
        contentIds: getSelectedContentIds(existingDataMap, FormField.SEXUALITY_NUMBER_PARTNERS)
      })
    );
    dataMap.set(
      FormField.RECENT_FEVER_ONSET,
      createFormField({
        name: FormField.RECENT_FEVER_ONSET,
        type: FormFieldInputType.DROPDOWN,
        label: 'Onset',
        contentIds: getSelectedContentIds(existingDataMap, FormField.RECENT_FEVER_ONSET)
      })
    );
    return dataMap;
  };

  handleSaveClick = () => {
    const previousPregnanciesSection = this.state.fragment?.chartData.records.find((item) => item.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES);
    this.props.handleSaveClick([this.buildFragment(previousPregnanciesSection)], { afterSave: this.props.afterSave });
  };

  updateFragment = (newPreviousPregnanciesSection) => {
    const { fragment } = this.state;
    let result;
    if (fragment) {
      result = produce(fragment, (draft) => {
        draft.chartData.records = draft.chartData.records.map((item) => {
          if (item.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES) {
            return newPreviousPregnanciesSection;
          }
          return item;
        });
      });
    } else {
      const chartData = {
        chartTitle: 'Admission History',
        fragmentTitle: 'Health History',
        records: [newPreviousPregnanciesSection]
      };
      const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
      return { ...basicInfo, chartData };
    }
    return result;
  };

  buildFragment = (previousPregnanciesSection?) => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const records = [
      {
        sectionTitle: SectionTitle.NO_PREVIOUS_HEALTH_PROBLEM,
        records: [buildPatientRecord(formFieldMap, FormField.NO_PREVIOUS_HEALTH_PROBLEM)]
      },
      {
        sectionTitle: SectionTitle.ENVIRONMENT_HISTORY,
        records: [
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_CONCERN),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_EXPOSE),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_EXPLAIN),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_MILITARY),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_BRANCH),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_DATE),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_POSITION),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_DEPLOYMENT),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_ENVIRONMENTAL),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_INJURIES),
          buildPatientRecord(formFieldMap, FormField.ENV_HISTORY_ASSAULTS)
        ]
      },
      {
        sectionTitle: SectionTitle.TRAVEL,
        records: [buildPatientRecord(formFieldMap, FormField.TRAVEL), buildPatientRecord(formFieldMap, FormField.TRAVEL_EXPLAIN)]
      },
      {
        sectionTitle: SectionTitle.MENTAL_HEALTH,
        records: [...buildPatientRecords(formFieldMap, FormField.MENTAL_HEALTH)]
      },
      {
        sectionTitle: SectionTitle.GENETIC_DISORDERS,
        records: [...buildPatientRecords(formFieldMap, FormField.GENETIC_DISORDERS)]
      },
      {
        sectionTitle: SectionTitle.GENERAL,
        records: [...buildPatientRecords(formFieldMap, FormField.GENERAL)]
      },
      {
        sectionTitle: SectionTitle.NEUROLOGICAL_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.NEUROLOGICAL_PROBLEMS)]
      },
      {
        sectionTitle: SectionTitle.RESPIRATORY_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_PROBLEMS)]
      },
      {
        sectionTitle: SectionTitle.RESPIRATORY_UPPER,
        records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_UPPER)]
      },
      {
        sectionTitle: SectionTitle.RESPIRATORY_LOWER,
        records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_LOWER)]
      },
      {
        sectionTitle: SectionTitle.RESPIRATORY_OTHER,
        records: [...buildPatientRecords(formFieldMap, FormField.RESPIRATORY_OTHER)]
      },
      {
        sectionTitle: SectionTitle.CARDIOVASCULAR_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.CARDIOVASCULAR_PROBLEMS)]
      },
      {
        sectionTitle: SectionTitle.CONGENITAL_HEART_DEFECT,
        records: [...buildPatientRecords(formFieldMap, FormField.CONGENITAL_HEART_DEFECT)]
      },
      {
        parentSectionTitle: SectionTitle.SENSORY_IMPAIRMENT,
        sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT,
        records: [buildPatientRecord(formFieldMap, FormField.SENSORY_HEARING)]
      },
      {
        parentSectionTitle: SectionTitle.SENSORY_IMPAIRMENT,
        sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_HEARING_IMPAIRMENT_CHOICE,
        records: [...buildPatientRecords(formFieldMap, FormField.SENSORY_HEARING_CHOICE)]
      },
      {
        parentSectionTitle: SectionTitle.SENSORY_IMPAIRMENT,
        sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT,
        records: [buildPatientRecord(formFieldMap, FormField.SENSORY_VISUAL)]
      },
      {
        parentSectionTitle: SectionTitle.SENSORY_IMPAIRMENT,
        sectionTitle: SectionTitle.SENSORY_IMPAIRMENT_VISUAL_IMPAIRMENT_CHOICE,
        records: [...buildPatientRecords(formFieldMap, FormField.SENSORY_VISUAL_CHOICE)]
      },
      {
        parentSectionTitle: SectionTitle.INTEGUMENTORY_PROBLEMS,
        sectionTitle: SectionTitle.INTEGUMENTORY_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.INTEGUMENTORY_PROBLEMS)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_THYROID_DISEASE,
        records: [...buildPatientRecords(formFieldMap, FormField.ENDOCRINE_THYROID)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        records: [...buildPatientRecords(formFieldMap, FormField.ENDOCRINE_DISORDERS)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_LIVER_DISEASE,
        records: [...buildPatientRecords(formFieldMap, FormField.ENDOCRINE_LIVER)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_OTHER_DISORDERS,
        records: [...buildPatientRecords(formFieldMap, FormField.ENDOCRINE_OTHER)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_PITUITARY_DISORDER,
        records: [...buildPatientRecords(formFieldMap, FormField.ENDOCRINE_PITUITARY)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES,
        records: [...buildPatientRecords(formFieldMap, FormField.ENDOCRINE_DIABETES)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES_COMPLIANT,
        records: [buildPatientRecord(formFieldMap, FormField.ENDOCRINE_DIABETES_COMPLIANT)]
      },
      {
        parentSectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES,
        sectionTitle: SectionTitle.ENDOCRINE_DISORDERS_DIABETES_YEARS,
        records: [buildPatientRecord(formFieldMap, FormField.ENDOCRINE_YEARS)]
      },
      {
        sectionTitle: SectionTitle.GASTROINTESTINAL_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.GASTROINTESTINAL)]
      },
      {
        sectionTitle: SectionTitle.HEMATOLOGIC_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.HEMATOLOGIC)]
      },
      {
        sectionTitle: SectionTitle.HEMATOLOGIC_CANCER,
        records: [buildPatientRecord(formFieldMap, FormField.HEMATOLOGIC_CANCER)]
      },
      {
        sectionTitle: SectionTitle.GENITOURINARY,
        records: [...buildPatientRecords(formFieldMap, FormField.GENITOURINARY)]
      },
      {
        sectionTitle: SectionTitle.SEXUALITY,
        records: [...buildPatientRecords(formFieldMap, FormField.SEXUALITY)]
      },
      {
        sectionTitle: SectionTitle.SEXUALITY_ACTIVE,
        records: [buildPatientRecord(formFieldMap, FormField.SEXUALITY_ACTIVE)]
      },
      {
        sectionTitle: SectionTitle.SEXUALITY_NUMBER_PARTNERS,
        records: [buildPatientRecord(formFieldMap, FormField.SEXUALITY_NUMBER_PARTNERS)]
      },
      {
        sectionTitle: SectionTitle.SEXUALITY_SEX_PARTNER,
        records: [buildPatientRecord(formFieldMap, FormField.SEXUALITY_SEX_PARTNER)]
      },
      {
        sectionTitle: SectionTitle.SEXUALITY_BIRTH_CONTROL,
        records: [buildPatientRecord(formFieldMap, FormField.SEXUALITY_BIRTH_CONTROL)]
      },
      {
        sectionTitle: SectionTitle.MUSCULOSKELETAL_PROBLEMS,
        records: [...buildPatientRecords(formFieldMap, FormField.MUSCULOSKELETAL)]
      },
      {
        sectionTitle: SectionTitle.PREVIOUS_SURGERY,
        records: [...buildPatientRecords(formFieldMap, FormField.PREVIOUS_SURGERY)]
      },
      {
        sectionTitle: SectionTitle.PREVIOUS_SURGERY_NONE,
        records: [buildPatientRecord(formFieldMap, FormField.PREVIOUS_SURGERY_NONE)]
      },
      {
        sectionTitle: SectionTitle.ANESTHESIA_PROBLEMS,
        records: [buildPatientRecord(formFieldMap, FormField.ANESTHESIA)]
      },
      {
        sectionTitle: SectionTitle.ANESTHESIA_NOTE,
        records: [buildPatientRecord(formFieldMap, FormField.ANESTHESIA_NOTE)]
      },
      {
        sectionTitle: SectionTitle.OTHER_HEALTH_HISTORY,
        records: [buildPatientRecord(formFieldMap, FormField.OTHER_HEALTH_HISTORY)]
      },
      {
        sectionTitle: SectionTitle.RECENT_FEVER_REQUIRING_ANTIBIOTICS,
        records: [buildPatientRecord(formFieldMap, FormField.RECENT_FEVER)]
      },
      {
        sectionTitle: SectionTitle.RECENT_FEVER_ONSET,
        records: [buildPatientRecord(formFieldMap, FormField.RECENT_FEVER_ONSET)]
      },
      {
        sectionTitle: SectionTitle.RECENT_FEVER_ONSET_OTHER,
        records: [buildPatientRecord(formFieldMap, FormField.RECENT_FEVER_ONSET_OTHER)]
      },
      {
        sectionTitle: SectionTitle.OBSTETRIC_HISTORY,
        records: [
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_ARE_YOU_PREGNANT),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_BABY_DUE),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_COMFIRMED_ULTRASOUND),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_LAST_MENSTRUAL_PERIOD),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_ARE_YOU_BREAST_FEEDING),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_GRAVIDA),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_PARA),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_TERM),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_PRETERM),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_ABORTION),
          buildPatientRecord(formFieldMap, FormField.OBSTETRIC_HISTORY_LIVING)
        ]
      },
      {
        sectionTitle: SectionTitle.HISTORY_OF_CURRENT_PREGNANCY,
        records: [
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_RECEIVED_PRENATAL_CARE),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_PROVIDER_NAME),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_RISK_FACTORS_IDENTIFIED),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_GROUP_B_STREPTOCOCCUS_STATUS),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_VAGINAL_DISCHARGE),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_DESCRIBE),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_PROBLEMS_IDENTIFIED),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_MULTIPLE_GESTATION),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_IS_FETUS_ACTIVE),
          buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_TIME_OF_LAST_ACTIVITY)
        ]
      },
      {
        sectionTitle: SectionTitle.LAST_ORAL_INTAKE,
        records: [
          buildPatientRecord(formFieldMap, FormField.LAST_ORAL_INTAKE_DAY),
          buildPatientRecord(formFieldMap, FormField.LAST_ORAL_INTAKE_DAY_DESCRIPTION),
          buildPatientRecord(formFieldMap, FormField.LAST_ORAL_INTAKE_TIME)
        ]
      }
    ];

    if (previousPregnanciesSection) {
      records.push(previousPregnanciesSection);
    }

    let chartData = {
      chartTitle: 'Admission History',
      fragmentTitle: 'Health History',
      records
    };
    chartData = chartService.systemAssessment.removeEmptyRecords(chartData);
    const basicInfo = chartService.createBaseFragment({ fragmentId: this.state.fragment?.fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData };
  };

  handleAddPregnancyInformationClick = () => this.setState({ isSidebarOpen: true });

  handleCloseSidebar = () => this.setState({ isSidebarOpen: false });

  addPregnancyInfo = (pregnancyInfoRecord: PregnancyInfoRecord) => {
    const defaultSection = {
      sectionTitle: SectionTitle.PREVIOUS_PREGNANCIES,
      records: []
    };
    const previousPregnanciesSection: PreviousPregnanciesSection =
      this.state.fragment?.chartData.records.find((item) => item.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES) || defaultSection;
    // To access readonly object (previousPregnanciesSection) and array (records)
    const sectionCopy = { ...previousPregnanciesSection };
    const recordsCopy = [...sectionCopy.records];
    recordsCopy.push(pregnancyInfoRecord);
    defaultSection.records = recordsCopy;
    const newPreviousPregnanciesSection = defaultSection;

    const afterSave = () => {
      this.props.showSaveSuccess();
      this.loadData().then(this.bindChartHistory);
      this.setState((prevState) => ({ isSidebarOpen: false, resetKey: prevState.resetKey + 1 }));
    };
    this.props.saveChartData(this.updateFragment(newPreviousPregnanciesSection)).then(afterSave);
  };

  deletePregnancyInfo = (id) =>
    this.props.showDeleteConfirmation(() => {
      let previousPregnanciesSection: PreviousPregnanciesSection = this.state.fragment?.chartData.records.find((item) => item.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES);
      previousPregnanciesSection = produce(previousPregnanciesSection, (draft) => {
        draft.records = draft.records.map((item) => {
          let { active } = item;
          if (item.id === id) {
            active = false;
          }
          return {
            ...item,
            active
          };
        });
      });
      const afterSave = () => {
        this.props.showDeleteSuccess();
        this.loadData().then(this.bindChartHistory);
        this.setState((prevState) => ({ isSidebarOpen: false, resetKey: prevState.resetKey + 1 }));
      };
      this.props.saveChartData(this.updateFragment(previousPregnanciesSection)).then(afterSave);
    });

  bindChartHistory = (fragment) => {
    const previousPregnanciesSection = fragment?.chartData?.records?.find((item) => item.sectionTitle === SectionTitle.PREVIOUS_PREGNANCIES);
    const previousPregnanciesHistory = previousPregnanciesSection?.records.map((record) => ({
      ...record,
      [FormField.HOW_LONG_AGO]: record[FormField.HOW_LONG_AGO]?.content || '',
      [FormField.GESTATIONAL_WEEKS]: record[FormField.GESTATIONAL_WEEKS]?.content || '',
      [FormField.TYPE_OF_DELIVERY]: record[FormField.TYPE_OF_DELIVERY]?.content || '',
      [FormField.DURATION_OF_LABOR]: record[FormField.DURATION_OF_LABOR]?.content || '',
      [FormField.COMPLICATIONS]: record[FormField.COMPLICATIONS]?.content || '',
      [FormField.OUTCOME]: record[FormField.OUTCOME]?.content || ''
    }));

    this.setState({
      previousPregnanciesHistory: previousPregnanciesHistory || []
    });
  };

  render() {
    const { isLocked, fragment, statusFragment, isSidebarOpen, resetKey } = this.state;
    const { formFieldMap, formSubmittedCount, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, patientContext } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildFormFields(fragment)),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };

    const viewProps = {
      isLocked,
      isSidebarOpen,
      fragment,
      statusFragment,
      chartActionsProps,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap,
      previousPregnanciesHistory: this.state.previousPregnanciesHistory,
      resetKey,
      patientContext,
      addPregnancyInfo: this.addPregnancyInfo,
      deletePregnancyInfo: this.deletePregnancyInfo,
      handleCloseSidebar: this.handleCloseSidebar,
      onAddPregnancyInformationClick: this.handleAddPregnancyInformationClick,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <HealthHistoryView {...viewProps} />;
  }
}

export { HealthHistory as BaseHealthHistory };
export default compose(withChartLogic, withAdmissionHistory)(HealthHistory);

import { ChartFragment } from 'models/api-response';
import ReadOnlyInput from '../shared/ReadOnlyInput';

export interface ImmunizationsReadOnlySectionProps {
  section: string;
  frequencyId: string;
  dateReceivedId: string;
  chartFragment: ChartFragment;
}

const ImmunizationsReadOnlySection = (props: ImmunizationsReadOnlySectionProps) => {
  const { section, chartFragment, frequencyId, dateReceivedId } = props;

  return (
    <>
      <h3>{section}</h3>
      <ReadOnlyInput sectionTitle={section} formFieldId={frequencyId} chartFragment={chartFragment} customTitle="Frequency" />
      <ReadOnlyInput sectionTitle={section} formFieldId={dateReceivedId} chartFragment={chartFragment} customTitle="Date received" />
    </>
  );
};

export default ImmunizationsReadOnlySection;

import produce from 'immer';
import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TherapyRecord } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import OccupationalSidebarView, { OccupationalSidebarViewProps } from './OccupationalSidebarView';
import { FormField, FormFieldLabel, OrderTypeValue } from '../shared/constants';
import { handleDisplayIntervention } from '../shared/helpers';

interface OccupationalSidebarProps extends ChartComponentProps {
  selectedNavId: string;
  therapy: TherapyRecord;
  onCloseClick: Function;
  onEditTherapyHandler: Function;
}

interface OccupationalSidebarState {
  isCompleted: boolean;
}

class OccupationalSidebar extends Component<OccupationalSidebarProps, OccupationalSidebarState> {
  static displayName = 'OccupationalSidebar';

  constructor(props) {
    super(props);
    this.state = {
      isCompleted: false
    };
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<OccupationalSidebarProps>) {
    if (prevProps.therapy !== this.props.therapy) {
      this.props.initState(this.buildFormFields());
      this.setState({
        isCompleted: false
      });
    }
  }

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      { name: FormField.EATING_ADLS, label: FormFieldLabel.EATING },
      { name: FormField.GROOMING, label: FormFieldLabel.GROOMING },
      { name: FormField.UPPER_EXTREMITY_BATH, label: FormFieldLabel.UPPER_EXTREMITY_BATH },
      { name: FormField.LOWER_EXTREMITY_BATH, label: FormFieldLabel.LOWER_EXTREMITY_BATH },
      { name: FormField.UPPER_EXTRIMITY_DRESS, label: FormFieldLabel.UPPER_EXTRIMITY_DRESS },
      { name: FormField.LOWER_EXTRIMITY_DRESS, label: FormFieldLabel.LOWER_EXTRIMITY_DRESS },
      { name: FormField.TOILETING, label: FormFieldLabel.TOILETING },
      { name: FormField.EATING_TRANSFERS, label: FormFieldLabel.EATING },
      { name: FormField.SHOWER_TUB, label: FormFieldLabel.SHOWER_TUB },
      { name: FormField.OCCUPATIONAL_THERAPIST_NAME, label: FormFieldLabel.OCCUPATIONAL_THERAPIST_NAME },
      { name: FormField.OCCUPATIONAL_THERAPIST_SIGNATURE, label: FormFieldLabel.OCCUPATIONAL_THERAPIST_SIGNATURE }
    ];

    const textAreas = [
      { name: FormField.LOCATION, label: FormFieldLabel.LOCATION },
      { name: FormField.THERAPUTIC_EXERCISES, label: FormFieldLabel.THERAPUTIC_EXERCISES },
      { name: FormField.TREATMENT, label: FormFieldLabel.TREATMENT },
      { name: FormField.PATIENT_FAMILY_TEACHING, label: FormFieldLabel.PATIENT_FAMILY_TEACHING }
    ];

    dataMap.set(FormField.INTERVENTION_DATE, createFormField({ name: FormField.INTERVENTION_DATE, type: FormFieldInputType.DATE, label: FormFieldLabel.DATE_LABEL }));
    dataMap.set(FormField.INTERVENTION_TIME, createFormField({ name: FormField.INTERVENTION_TIME, type: FormFieldInputType.TIME, label: FormFieldLabel.TIME }));

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX })));
    textAreas.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_AREA })));
    return dataMap;
  };

  buildUpdatedFragment = () => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord } = chartService;
    return {
      [FormField.EATING_ADLS]: buildPatientRecord(formFieldMap, FormField.EATING_ADLS),
      [FormField.GROOMING]: buildPatientRecord(formFieldMap, FormField.GROOMING),
      [FormField.UPPER_EXTREMITY_BATH]: buildPatientRecord(formFieldMap, FormField.UPPER_EXTREMITY_BATH),
      [FormField.LOWER_EXTREMITY_BATH]: buildPatientRecord(formFieldMap, FormField.LOWER_EXTREMITY_BATH),
      [FormField.UPPER_EXTRIMITY_DRESS]: buildPatientRecord(formFieldMap, FormField.UPPER_EXTRIMITY_DRESS),
      [FormField.TOILETING]: buildPatientRecord(formFieldMap, FormField.TOILETING),
      [FormField.EATING_TRANSFERS]: buildPatientRecord(formFieldMap, FormField.EATING_TRANSFERS),
      [FormField.SHOWER_TUB]: buildPatientRecord(formFieldMap, FormField.SHOWER_TUB),
      [FormField.LOCATION]: buildPatientRecord(formFieldMap, FormField.LOCATION),
      [FormField.THERAPUTIC_EXERCISES]: buildPatientRecord(formFieldMap, FormField.THERAPUTIC_EXERCISES),
      [FormField.TREATMENT]: buildPatientRecord(formFieldMap, FormField.TREATMENT),
      [FormField.PATIENT_FAMILY_TEACHING]: buildPatientRecord(formFieldMap, FormField.PATIENT_FAMILY_TEACHING),
      [FormField.OCCUPATIONAL_THERAPIST_NAME]: buildPatientRecord(formFieldMap, FormField.OCCUPATIONAL_THERAPIST_NAME),
      [FormField.OCCUPATIONAL_THERAPIST_SIGNATURE]: buildPatientRecord(formFieldMap, FormField.OCCUPATIONAL_THERAPIST_SIGNATURE),
      [FormField.INTERVENTION_DATE]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_DATE),
      [FormField.INTERVENTION_TIME]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_TIME)
    };
  };

  handleSaveClick = () => {
    const { isCompleted } = this.state;
    const updatedTherapy = produce(this.props.therapy, (draft) => {
      const { chartData } = draft;
      const oldInterventionList = chartData[FormField.INTERVENTION_LIST] || [];
      draft.chartData = { ...chartData, [FormField.INTERVENTION_LIST]: [...oldInterventionList, this.buildUpdatedFragment()], isCompleted };
      draft.updatedAt = moment().toISOString();
    });
    this.props.handleSaveClick([updatedTherapy], { afterSave: this.props.onEditTherapyHandler });
  };

  onCheckCompleted = (event) => {
    const isCompleted = event.target.checked;
    this.setState({
      isCompleted
    });
  };

  render() {
    const { therapy, onCloseClick, formFieldMap, formSubmittedCount, sidebarProps } = this.props;
    const { isCompleted } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => {
        this.setState({
          isCompleted: false
        });
        this.props.handleDiscardClick(undefined, this.buildFormFields(), { selectorToScroll: '.sidebar__container' });
      }
    };
    const { chartHistory: intervention } = handleDisplayIntervention(therapy);
    const orderTypeValue = therapy?.chartData[FormField.ORDER_TYPE]?.value;
    const isTherapyCompleted = therapy?.chartData[FormField.IS_COMPLETED];
    const viewProps: OccupationalSidebarViewProps = {
      isOpen: !!therapy && orderTypeValue === OrderTypeValue.OCCUPATIONAL,
      intervention,
      onCloseClick,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      isCompleted,
      isTherapyCompleted,
      onCheckCompleted: this.onCheckCompleted,
      sidebarProps
    };
    return <OccupationalSidebarView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  selectedNavId: appSelectors.getNavId(state)
});

export { OccupationalSidebar as BaseOccupationalSidebar };
export default compose(connect(mapStateToProps), withChartLogic)(OccupationalSidebar);

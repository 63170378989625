import { delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment, PhysicalExaminationFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel } from './constants';
import PhysicalExaminationView, { PhysicalExaminationViewProps } from './PhysicalExaminationView';

export const buildFormFields = (record?: PhysicalExaminationFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();

  const radioChoices = [
    {
      name: FormField.NAUSEA_VOMITING,
      label: FormFieldLabel.NAUSEA_VOMITING,
      contentIds: [record?.chartData?.[FormField.NAUSEA_VOMITING]?.contentId]
    },
    {
      name: FormField.VAGINAL_BLEEDING,
      label: FormFieldLabel.VAGINAL_BLEEDING,
      contentIds: [record?.chartData?.[FormField.VAGINAL_BLEEDING]?.contentId]
    },
    {
      name: FormField.VAGINAL_DISCHARGE,
      label: FormFieldLabel.VAGINAL_DISCHARGE,
      contentIds: [record?.chartData?.[FormField.VAGINAL_DISCHARGE]?.contentId]
    },
    {
      name: FormField.URINARY_SIGNS_SYMPTOMS,
      label: FormFieldLabel.URINARY_SIGNS_SYMPTOMS,
      contentIds: [record?.chartData?.[FormField.URINARY_SIGNS_SYMPTOMS]?.contentId]
    },
    {
      name: FormField.CONSTIPATION,
      label: FormFieldLabel.CONSTIPATION,
      contentIds: [record?.chartData?.[FormField.CONSTIPATION]?.contentId]
    },
    {
      name: FormField.FEVER_RASH,
      label: FormFieldLabel.FEVER_RASH,
      contentIds: [record?.chartData?.[FormField.FEVER_RASH]?.contentId]
    },
    {
      name: FormField.INFECTION,
      label: FormFieldLabel.INFECTION,
      contentIds: [record?.chartData?.[FormField.INFECTION]?.contentId]
    },
    {
      name: FormField.CURRENT_PREGNANCY_OTHER,
      label: FormFieldLabel.OTHER,
      contentIds: [record?.chartData?.[FormField.CURRENT_PREGNANCY_OTHER]?.contentId]
    }
  ];
  const textBoxes = [
    {
      name: FormField.FUNDAL_HEIGHT,
      label: FormFieldLabel.FUNDAL_HEIGHT,
      value: record?.chartData?.[FormField.FUNDAL_HEIGHT]
    },
    {
      name: FormField.PELVIS_TYPE,
      label: FormFieldLabel.PELVIS_TYPE,
      value: record?.chartData?.[FormField.PELVIS_TYPE]
    },
    {
      name: FormField.VAGINA,
      label: FormFieldLabel.VAGINA,
      value: record?.chartData?.[FormField.VAGINA]
    },
    {
      name: FormField.PRESENTATION,
      label: FormFieldLabel.PRESENTATION,
      value: record?.chartData?.[FormField.PRESENTATION]
    },
    {
      name: FormField.SACRUM,
      label: FormFieldLabel.SACRUM,
      value: record?.chartData?.[FormField.SACRUM]
    },
    {
      name: FormField.ADNEXA,
      label: FormFieldLabel.ADNEXA,
      value: record?.chartData?.[FormField.ADNEXA]
    },
    {
      name: FormField.DIAGONAL_CONJUGATE,
      label: FormFieldLabel.DIAGONAL_CONJUGATE,
      value: record?.chartData?.[FormField.DIAGONAL_CONJUGATE]
    },
    {
      name: FormField.VULVA,
      label: FormFieldLabel.VULVA,
      value: record?.chartData?.[FormField.VULVA]
    },
    {
      name: FormField.FETAL_HEART_RATE,
      label: FormFieldLabel.FETAL_HEART_RATE,
      value: record?.chartData?.[FormField.FETAL_HEART_RATE]
    },
    {
      name: FormField.SPINES,
      label: FormFieldLabel.SPINES,
      value: record?.chartData?.[FormField.SPINES]
    },
    {
      name: FormField.CERVIX,
      label: FormFieldLabel.CERVIX,
      value: record?.chartData?.[FormField.CERVIX]
    },
    {
      name: FormField.FETAL_MOVEMENT,
      label: FormFieldLabel.FETAL_MOVEMENT,
      value: record?.chartData?.[FormField.FETAL_MOVEMENT]
    },
    {
      name: FormField.SP_ARCH,
      label: FormFieldLabel.SP_ARCH,
      value: record?.chartData?.[FormField.SP_ARCH]
    },
    {
      name: FormField.NAUSEA_VOMITING_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.NAUSEA_VOMITING_NOTES]
    },
    {
      name: FormField.VAGINAL_BLEEDING_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.VAGINAL_BLEEDING_NOTES]
    },
    {
      name: FormField.VAGINAL_DISCHARGE_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.VAGINAL_DISCHARGE_NOTES]
    },
    {
      name: FormField.URINARY_SIGNS_SYMPTOMS_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.URINARY_SIGNS_SYMPTOMS_NOTES]
    },
    {
      name: FormField.CONSTIPATION_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.CONSTIPATION_NOTES]
    },
    {
      name: FormField.FEVER_RASH_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.FEVER_RASH_NOTES]
    },
    {
      name: FormField.INFECTION_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.INFECTION_NOTES]
    },
    {
      name: FormField.CURRENT_PREGNANCY_OTHER_NOTES,
      label: FormFieldLabel.NOTES,
      value: record?.chartData?.[FormField.CURRENT_PREGNANCY_OTHER_NOTES]
    }
  ];
  const textAreas = [
    {
      name: FormField.GENERAL,
      label: FormFieldLabel.GENERAL,
      value: record?.chartData?.[FormField.GENERAL]
    },
    {
      name: FormField.VITAL_SIGNS,
      label: FormFieldLabel.VITAL_SIGNS,
      value: record?.chartData?.[FormField.VITAL_SIGNS]
    },
    {
      name: FormField.INTEGUMENT,
      label: FormFieldLabel.INTEGUMENT,
      value: record?.chartData?.[FormField.INTEGUMENT]
    },
    {
      name: FormField.HEENT,
      label: FormFieldLabel.HEENT,
      value: record?.chartData?.[FormField.HEENT]
    },
    {
      name: FormField.CARDIOVASCULAR,
      label: FormFieldLabel.CARDIOVASCULAR,
      value: record?.chartData?.[FormField.CARDIOVASCULAR]
    },
    {
      name: FormField.RESPIRATORY,
      label: FormFieldLabel.RESPIRATORY,
      value: record?.chartData?.[FormField.RESPIRATORY]
    },
    {
      name: FormField.GASTROINTESTINAL,
      label: FormFieldLabel.GASTROINTESTINAL,
      value: record?.chartData?.[FormField.GASTROINTESTINAL]
    },
    {
      name: FormField.GENITOURINARY,
      label: FormFieldLabel.GENITOURINARY,
      value: record?.chartData?.[FormField.GENITOURINARY]
    },
    {
      name: FormField.MUSCULOSKELETAL,
      label: FormFieldLabel.MUSCULOSKELETAL,
      value: record?.chartData?.[FormField.MUSCULOSKELETAL]
    },
    {
      name: FormField.NEUROLOGIC,
      label: FormFieldLabel.NEUROLOGIC,
      value: record?.chartData?.[FormField.NEUROLOGIC]
    },
    {
      name: FormField.DEVELOPMENTAL,
      label: FormFieldLabel.DEVELOPMENTAL,
      value: record?.chartData?.[FormField.DEVELOPMENTAL]
    },
    {
      name: FormField.ENDOCRINE,
      label: FormFieldLabel.ENDOCRINE,
      value: record?.chartData?.[FormField.ENDOCRINE]
    },
    {
      name: FormField.GENITALIA,
      label: FormFieldLabel.GENITALIA,
      value: record?.chartData?.[FormField.GENITALIA]
    },
    {
      name: FormField.LYMPHATIC,
      label: FormFieldLabel.LYMPHATIC,
      value: record?.chartData?.[FormField.LYMPHATIC]
    },
    {
      name: FormField.SUMMARY,
      label: FormFieldLabel.SUMMARY,
      value: record?.chartData?.[FormField.SUMMARY]
    }
  ];
  const containers = [FormField.OBSTETRIC_GENITALIA, FormField.CURRENT_PREGNANCY, FormField.ASSESSMENT_PLAN];

  radioChoices.forEach(({ name, label, contentIds }) => dataMap.set(name, createFormField({ name, label, contentIds, type: FormFieldInputType.RADIO_CHOICE })));
  textBoxes.forEach(({ name, label, value }) => dataMap.set(name, createFormField({ name, label, value, type: FormFieldInputType.TEXT_BOX })));
  textAreas.forEach(({ name, label, value }) => dataMap.set(name, createFormField({ name, label, value, type: FormFieldInputType.TEXT_AREA })));
  containers.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CONTAINER })));

  return dataMap;
};

export interface PhysicalExaminationProps extends ChartComponentProps {
  assessment: AssessmentRS;
}

export interface PhysicalExaminationState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: PhysicalExaminationFragment;
}

class PhysicalExamination extends Component<PhysicalExaminationProps, PhysicalExaminationState> {
  static displayName = 'PhysicalExamination';

  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      statusFragment: null,
      selectedRecord: null
    };
  }

  componentDidMount() {
    const { assessment, isAuthor } = this.props;

    if (!isAuthor) {
      const navIds = [NAV_ID.HISTORY_PHYSICAL, NAV_ID.PHYSICAL_EXAMINATION];
      const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];

      appHelper.useLoader(chartService.loadFragments({ chartId: assessment.simChartId, navIds, fragmentTypes }).then(this.bindChartHistory), {
        errorMessage: 'can not load chart fragment'
      });
    }

    this.props.initState(buildFormFields(this.createEmptyRecord()));
  }

  createEmptyRecord = () => ({
    chartData: {
      [FormField.GENERAL]: '',
      [FormField.VITAL_SIGNS]: '',
      [FormField.INTEGUMENT]: '',
      [FormField.HEENT]: '',
      [FormField.CARDIOVASCULAR]: '',
      [FormField.RESPIRATORY]: '',
      [FormField.GASTROINTESTINAL]: '',
      [FormField.GENITOURINARY]: '',
      [FormField.MUSCULOSKELETAL]: '',
      [FormField.NEUROLOGIC]: '',
      [FormField.DEVELOPMENTAL]: '',
      [FormField.ENDOCRINE]: '',
      [FormField.GENITALIA]: '',
      [FormField.LYMPHATIC]: '',
      [FormField.FUNDAL_HEIGHT]: '',
      [FormField.PELVIS_TYPE]: '',
      [FormField.VAGINA]: '',
      [FormField.PRESENTATION]: '',
      [FormField.SACRUM]: '',
      [FormField.ADNEXA]: '',
      [FormField.DIAGONAL_CONJUGATE]: '',
      [FormField.VULVA]: '',
      [FormField.FETAL_HEART_RATE]: '',
      [FormField.SPINES]: '',
      [FormField.CERVIX]: '',
      [FormField.FETAL_MOVEMENT]: '',
      [FormField.SP_ARCH]: '',
      [FormField.SUMMARY]: '',
      [FormField.NAUSEA_VOMITING_NOTES]: '',
      [FormField.VAGINAL_BLEEDING_NOTES]: '',
      [FormField.VAGINAL_DISCHARGE_NOTES]: '',
      [FormField.URINARY_SIGNS_SYMPTOMS_NOTES]: '',
      [FormField.CONSTIPATION_NOTES]: '',
      [FormField.FEVER_RASH_NOTES]: '',
      [FormField.INFECTION_NOTES]: '',
      [FormField.CURRENT_PREGNANCY_OTHER_NOTES]: ''
    }
  });

  bindChartHistory = ({ data: fragments }) => {
    const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const selectedRecord = chartFragment;

    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.PHYSICAL_EXAMINATION, NAV_ID.HISTORY_PHYSICAL);
    this.setState({
      selectedRecord,
      statusFragment,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(buildFormFields(selectedRecord));
  };

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord } = chartService;
    return {
      ...this.state.selectedRecord,
      ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
      chartData: {
        [FormField.GENERAL]: formFieldMap.get(FormField.GENERAL).value,
        [FormField.VITAL_SIGNS]: formFieldMap.get(FormField.VITAL_SIGNS).value,
        [FormField.INTEGUMENT]: formFieldMap.get(FormField.INTEGUMENT).value,
        [FormField.HEENT]: formFieldMap.get(FormField.HEENT).value,
        [FormField.CARDIOVASCULAR]: formFieldMap.get(FormField.CARDIOVASCULAR).value,
        [FormField.RESPIRATORY]: formFieldMap.get(FormField.RESPIRATORY).value,
        [FormField.GASTROINTESTINAL]: formFieldMap.get(FormField.GASTROINTESTINAL).value,
        [FormField.GENITOURINARY]: formFieldMap.get(FormField.GENITOURINARY).value,
        [FormField.MUSCULOSKELETAL]: formFieldMap.get(FormField.MUSCULOSKELETAL).value,
        [FormField.NEUROLOGIC]: formFieldMap.get(FormField.NEUROLOGIC).value,
        [FormField.DEVELOPMENTAL]: formFieldMap.get(FormField.DEVELOPMENTAL).value,
        [FormField.ENDOCRINE]: formFieldMap.get(FormField.ENDOCRINE).value,
        [FormField.GENITALIA]: formFieldMap.get(FormField.GENITALIA).value,
        [FormField.LYMPHATIC]: formFieldMap.get(FormField.LYMPHATIC).value,
        [FormField.FUNDAL_HEIGHT]: formFieldMap.get(FormField.FUNDAL_HEIGHT).value,
        [FormField.PELVIS_TYPE]: formFieldMap.get(FormField.PELVIS_TYPE).value,
        [FormField.VAGINA]: formFieldMap.get(FormField.VAGINA).value,
        [FormField.PRESENTATION]: formFieldMap.get(FormField.PRESENTATION).value,
        [FormField.SACRUM]: formFieldMap.get(FormField.SACRUM).value,
        [FormField.ADNEXA]: formFieldMap.get(FormField.ADNEXA).value,
        [FormField.DIAGONAL_CONJUGATE]: formFieldMap.get(FormField.DIAGONAL_CONJUGATE).value,
        [FormField.VULVA]: formFieldMap.get(FormField.VULVA).value,
        [FormField.FETAL_HEART_RATE]: formFieldMap.get(FormField.FETAL_HEART_RATE).value,
        [FormField.SPINES]: formFieldMap.get(FormField.SPINES).value,
        [FormField.CERVIX]: formFieldMap.get(FormField.CERVIX).value,
        [FormField.FETAL_MOVEMENT]: formFieldMap.get(FormField.FETAL_MOVEMENT).value,
        [FormField.SP_ARCH]: formFieldMap.get(FormField.SP_ARCH).value,
        [FormField.SUMMARY]: formFieldMap.get(FormField.SUMMARY).value,
        [FormField.NAUSEA_VOMITING]: buildPatientRecord(formFieldMap, FormField.NAUSEA_VOMITING),
        [FormField.NAUSEA_VOMITING_NOTES]: formFieldMap.get(FormField.NAUSEA_VOMITING_NOTES).value,
        [FormField.VAGINAL_BLEEDING]: buildPatientRecord(formFieldMap, FormField.VAGINAL_BLEEDING),
        [FormField.VAGINAL_BLEEDING_NOTES]: formFieldMap.get(FormField.VAGINAL_BLEEDING_NOTES).value,
        [FormField.VAGINAL_DISCHARGE]: buildPatientRecord(formFieldMap, FormField.VAGINAL_DISCHARGE),
        [FormField.VAGINAL_DISCHARGE_NOTES]: formFieldMap.get(FormField.VAGINAL_DISCHARGE_NOTES).value,
        [FormField.URINARY_SIGNS_SYMPTOMS]: buildPatientRecord(formFieldMap, FormField.URINARY_SIGNS_SYMPTOMS),
        [FormField.URINARY_SIGNS_SYMPTOMS_NOTES]: formFieldMap.get(FormField.URINARY_SIGNS_SYMPTOMS_NOTES).value,
        [FormField.CONSTIPATION]: buildPatientRecord(formFieldMap, FormField.CONSTIPATION),
        [FormField.CONSTIPATION_NOTES]: formFieldMap.get(FormField.CONSTIPATION_NOTES).value,
        [FormField.FEVER_RASH]: buildPatientRecord(formFieldMap, FormField.FEVER_RASH),
        [FormField.FEVER_RASH_NOTES]: formFieldMap.get(FormField.FEVER_RASH_NOTES).value,
        [FormField.INFECTION]: buildPatientRecord(formFieldMap, FormField.INFECTION),
        [FormField.INFECTION_NOTES]: formFieldMap.get(FormField.INFECTION_NOTES).value,
        [FormField.CURRENT_PREGNANCY_OTHER]: buildPatientRecord(formFieldMap, FormField.CURRENT_PREGNANCY_OTHER),
        [FormField.CURRENT_PREGNANCY_OTHER_NOTES]: formFieldMap.get(FormField.CURRENT_PREGNANCY_OTHER_NOTES).value
      }
    };
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: buildFormFields(), afterSave: this.afterSave });
  };

  afterSave = () =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.state.isLocked,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: PhysicalExaminationViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      chartMetaFormFields: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartActionsProps
    };

    return <PhysicalExaminationView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});
const enhancers = [connect(mapStateToProps), withChartLogic];
export { PhysicalExamination as BasePhysicalExamination };
export default compose(...enhancers)(PhysicalExamination);

import cx from 'classnames';
import { SectionRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Badge, BadgeDateTime, Box, ChartPermission, DeleteCell, MultiLine } from 'components/common';
import { ELSDataTable } from 'components/common/els';
import './edit.chart.scss';

export interface EditChartSectionProps {
  chartName: string;
  chartTime: string;
  deleteStatusDictionary: Map<string, boolean>;
  fragmentCreatorId: number;
  fullName: string;
  hideDelete?: boolean;
  id: string;
  isStatusBadgeActive: boolean;
  onRecordDelete: Function;
  records: SectionRecord[];
  status: string;
  useBlueBackground: boolean;
}

const EditChartSection = (props: EditChartSectionProps) => {
  const { chartName, chartTime, fullName, records, fragmentCreatorId, deleteStatusDictionary, hideDelete, status, isStatusBadgeActive } = props;
  const { onRecordDelete } = props;

  const renderTitle = (row) => <div className={cx({ 'row--deleted': !deleteStatusDictionary.get(row.id) })}>{row.title}</div>;

  const renderContent = (row) => (
    <div className={cx({ 'row--deleted': !deleteStatusDictionary.get(row.id) })}>{typeof row.content !== 'boolean' && <MultiLine text={row.content} />}</div>
  );

  const renderDelete = (row) => (
    <ChartPermission fragmentCreatorId={fragmentCreatorId}>
      {({ allowDeleteFragment }) => (
        <DeleteCell isActive={allowDeleteFragment && !row.preventDelete && deleteStatusDictionary.get(row.id)} onDeleteClick={() => onRecordDelete(row)} />
      )}
    </ChartPermission>
  );

  return (
    <div className="edit-chart-section">
      <div>
        <h3 className="u-els-margin-top">{chartName}</h3>
        {status && (
          <Badge
            className={cx('status-badge u-els-margin-right-1o2', {
              'u-els-background-color-secondary-on-dark': props.useBlueBackground,
              'status-badge--active': isStatusBadgeActive
            })}
          >
            {status}
          </Badge>
        )}
        <BadgeDateTime className="u-els-margin-top" isoTime={chartTime} />
        <p className="u-els-color-n7 u-els-italic">{`Entered by: ${fullName}`}</p>
      </div>

      <div className="sidebar__divider" />
      <Box pb2>
        {records.map((record, index) => {
          const tableHeading = `${record.parentSectionTitle || ''} ${record.sectionTitle || ''}`.trim();
          const elementKey = record.sectionTitle || index;
          return (
            <div key={elementKey} className="edit-chart-section__table">
              <ELSDataTable data={record.records}>
                <column header={<div className="header-title">{tableHeading}</div>} field="title" customRender={renderTitle} />
                <column header={<div className="u-els-hide-visually">Content</div>} field="content" align={ColumnAlign.Left} customRender={renderContent} />
                {!hideDelete && <column header={<div className="u-els-hide-visually">Delete</div>} customRender={renderDelete} />}
              </ELSDataTable>
            </div>
          );
        })}
      </Box>
    </div>
  );
};

EditChartSection.displayName = 'EditChartSection';
export default EditChartSection;

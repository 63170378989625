import { ChartFragment, PsychiatricHistoryFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, Headline } from 'components/common';
import PsychiatricHistoryEditView from './PsychiatricHistoryEditView';
import PsychiatricHistoryReadonly from './PsychiatricHistoryReadOnly';
import { SectionTitle } from './constant';

export interface PsychiatricHistoryViewProps {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: PsychiatricHistoryFragment;
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
}

export const PsychiatricHistoryView = (props: PsychiatricHistoryViewProps) => (
  <div className="psychiatric-history">
    {!props.isLocked ? (
      <>
        <h1>{SectionTitle.PSYCHIATRIC_HISTORY}</h1>
        <Box mt2>
          <PsychiatricHistoryEditView {...props} />
        </Box>
      </>
    ) : (
      <>
        <h1>History and Physical</h1>
        <Headline statusFragment={props.statusFragment} chartingFragment={props.selectedRecord} />
        <PsychiatricHistoryReadonly selectedRecord={props.selectedRecord} />
      </>
    )}
  </div>
);
PsychiatricHistoryView.displayName = 'PsychiatricHistoryView';

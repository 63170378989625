/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import cx from 'classnames';
import { ChevronRightLightIcon } from 'components/common';
import { ELSButton, ELSFlex, ELSIcon } from 'components/common/els';
import { SIZE } from 'components/common/icons/ChevronRightLightIcon';
import './admission.history.tile.scss';

export interface AdmissionHistoryTileProps {
  title: string;
  active: boolean;
  onClick: VoidFunction;
}

const AdmissionHistoryTile = (props: AdmissionHistoryTileProps) => (
  <ELSButton type="debuttonize" className="c-els-button--expanded" onClick={props.onClick} ariaLabel={props.active ? 'Chart has been edited' : 'Chart has not been edited'}>
    <div className={cx('admission-history-tile', { 'admission-history-tile--active': props.active })}>
      <ELSFlex className="admission-history-tile__icon-wrapper" middle>
        {props.active && <ELSIcon name="checkmark" color="primary" />}
      </ELSFlex>
      <ELSFlex className="admission-history-tile__content-wrapper u-els-margin-right-5x" column>
        <ELSFlex left middle>
          <div className={cx({ 'u-els-margin-right': !props.active })}>
            <div className="u-els-margin-right-1o2 admission-history-tile__title">{props.title}</div>
            {!props.active && <ChevronRightLightIcon size={SIZE.X_SMALL} />}
          </div>
        </ELSFlex>
        {props.active && (
          <ELSFlex left middle>
            <div className="u-els-line-height-single u-els-margin-right-1o2">Make Edits</div>
            <ChevronRightLightIcon size={SIZE.X_SMALL} />
          </ELSFlex>
        )}
      </ELSFlex>
    </div>
  </ELSButton>
);

AdmissionHistoryTile.displayName = 'AdmissionHistoryTile';
export default AdmissionHistoryTile;

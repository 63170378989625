import { HistoryLineChartProps } from 'components/common/chart/line/HistoryLineChart';
import { Audit, Record as ResponseRecord, Section } from 'models/api-response';

export interface ADDSRange {
  min?: number;
  max?: number;
}

export interface ADDSMatch {
  match: string;
}

export enum ADDSPointMap {
  EMERGENCY_CALL = 'Emergency Call',
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3
}

export type ADDSRangeMap = {
  [key in ADDSPointMap]?: ADDSRange[] | ADDSMatch[];
};

export enum ADDSScoringValues {
  RESPIRATION = 'respiration',
  BLOOD_PRESSURE = 'bloodPressure',
  SATURATION = 'saturation',
  AVPU_ASSESSMENT = 'avpuAssessment',
  PULSE = 'pulse',
  TEMPERATURE = 'temperature'
}

export type ADDSValueScoreMap = { [key in ADDSScoringValues]: number | string };
export type ADDSGetScores = {
  totalScore: string;
  partialScores: {
    [key in ADDSScoringValues]: {
      value: number | string;
      score: string;
    };
  };
};

export type ADDSScoringAction = {
  title: string;
  scoreRanges: (ADDSRange & Partial<ADDSMatch>)[];
  actions: string[];
};

export enum VitalSignsDataSuperKey {
  ID = 'ID',
  RESPIRATION = 'RESPIRATION',
  SATURATION = 'SATURATION',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  PULSE = 'PULSE',
  TEMPERATURE = 'TEMPERATURE',
  AVPU = 'AVPU',
  PAIN = 'PAIN',
  ADDS_SCORE = 'ADDS_SCORE',
  CHARTING_AT = 'CHARTING_AT',
  DELETE = 'DELETE',
  CREATOR = 'CREATOR',
  ACTIVE = 'ACTIVE'
}

export type VitalSignsDataValue = {
  value: string | number | boolean | undefined;
  score?: string;
  customClassnames?: string;
  isHidden?: boolean;
};

export type VitalSignsSuperKeyValueMap = Record<VitalSignsDataSuperKey, VitalSignsDataValue>;

export type VitalSignsDataType = {
  [K in VitalSignsDataSuperKey]: string | { [key: string]: string };
};

export enum AVPUAssessmentKeyMap {
  UNRESPONSIVE = 'unresponsive',
  ALERT = 'alert',
  RESPONSIVE_TO_VOICE = 'responsiveToVoice',
  RESPONSIVE_TO_PAIN = 'responsiveToPain'
}

export enum ChartField {
  TEMPERATURE = 'temperature',
  PULSE = 'pulse',
  RESPIRATION = 'respiration',
  BLOODPRESSURE = 'bloodPressure',
  OXYGENATION = 'oxygenation'
}

interface TemperatureChartData {
  date: string;
  dateTime: string;
  temperature: number;
  temperatureUnit: string;
}
interface PulseChartData {
  date: string;
  dateTime: string;
  pulse: number;
  pulseUnit: string;
}
interface RespirationChartData {
  date: string;
  dateTime: string;
  respiration: number;
  respirationUnit: string;
}
interface BloodPressureChartData {
  date: string;
  dateTime: string;
  systolic: number;
  diastolic: number;
  systolicUnit: string;
  diastolicUnit: string;
}
interface OxygenationChartData {
  date: string;
  dateTime: string;
  saturation: number;
  saturationUnit: string;
}

export interface TemperatureChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: TemperatureChartData[];
}
export interface PulseChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: PulseChartData[];
}
export interface RespirationChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: RespirationChartData[];
}
export interface BloodPressureChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: BloodPressureChartData[];
}
export interface OxygenationChartProps extends Omit<HistoryLineChartProps, 'data'> {
  data: OxygenationChartData[];
}

export interface VitalSignChartConfig {
  [ChartField.TEMPERATURE]: TemperatureChartProps;
  [ChartField.PULSE]: PulseChartProps;
  [ChartField.RESPIRATION]: RespirationChartProps;
  [ChartField.BLOODPRESSURE]: BloodPressureChartProps;
  [ChartField.OXYGENATION]: OxygenationChartProps;
}

export enum VitalSignsChartList {
  FIRST_CHART = 'selectedChart',
  SECOND_CHART = 'secondSelectedChart'
}

export enum VitalSignsChartDataKey {
  TEMPERATURE = 'temperature',
  PULSE = 'pulse',
  RESPIRATION = 'respiration',
  SYSTOLIC = 'systolic',
  DIASTOLIC = 'diastolic',
  SATURATION = 'saturation'
}

export interface VitalSignsFragmentRecords extends Audit, Partial<Omit<ResponseRecord, 'records' | 'details'>> {
  chartTitle: string;
  fragmentTitle: string;
  records: Section[];
}

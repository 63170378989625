import { Component } from 'react';

export interface SquareDotProps {
  cx?: number;
  cy?: number;
  payload?: {
    generatedDataPoint?: boolean;
    score?: string;
  };
  onGetCoordinate: Function;
  customColor?: (score?: string) => string;
}

export class SquareDot extends Component<SquareDotProps> {
  componentDidMount() {
    const { cx, cy, onGetCoordinate } = this.props;
    if (cx && cy) {
      onGetCoordinate(cx + 15, cy - 15);
    }
  }

  render() {
    const { cx, cy, payload, customColor } = this.props;
    if (payload?.generatedDataPoint) {
      return null;
    }
    return <rect width="16" height="16" x={cx - 8} y={cy - 8} style={{ fill: customColor?.(payload?.score) || '#00c1ff' }} />;
  }
}

export default SquareDot;

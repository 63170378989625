import { History } from 'history';
import { isEmpty } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { AssessmentStatus } from 'models/enum';
import { SimChartAssignment, User } from 'models/ui';
import { RouteParams, RoutePath } from 'constants/app.constant';
import { navigationService } from 'services';
import { getAssessmentByUserIdAndAssignmentId, getOrCreateAssessmentByUserIdAndAssignmentId, getSimChartAssignment } from 'services/assignment.service';
import { appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import { ELSPageLoader } from 'components/common/els';
import { isAssessmentGradingAllowed } from 'helpers/assignment.helper';

interface MatchParams {
  assignmentId: string;
}

export interface LaunchAssignmentEntryPointProps {
  loggedInUser: User;
  fullAssignment: SimChartAssignment;
  history: History;
  isInProgressGradingEnabledFlag: boolean;
}

class LaunchAssignmentEntryPoint extends Component<LaunchAssignmentEntryPointProps & RouteComponentProps<MatchParams>> {
  static displayName = 'LaunchAssignmentEntryPoint';

  componentDidMount() {
    this.navigateToCorrespondingPage();
  }

  getAssessment = () => {
    const { assignmentId } = this.props.match.params;
    const { id: studentId } = this.props.loggedInUser;

    const { assessmentCreateAllowed } = queryString.parse(this.props.location.search);
    const getAssessmentAPI = assessmentCreateAllowed === 'true' ? getOrCreateAssessmentByUserIdAndAssignmentId : getAssessmentByUserIdAndAssignmentId;

    return getAssessmentAPI(Number(assignmentId), studentId).then(({ data }) => data);
  };

  getAssignment = () => {
    if (isEmpty(this.props.fullAssignment)) {
      const { assignmentId } = this.props.match.params;
      return getSimChartAssignment(Number(assignmentId)).then((res) => res.data);
    }

    return this.props.fullAssignment;
  };

  navigateToCorrespondingPage = () =>
    Promise.all([this.getAssignment(), this.getAssessment()]).then(([assignment, assessment]) => {
      const isOverdue = moment().isAfter(assignment.dueDate, 'seconds');
      const isCompleted = assessment.status === AssessmentStatus.COMPLETED;
      const isProgress = assessment.status === AssessmentStatus.IN_PROGRESS;

      if (isOverdue || isCompleted || isAssessmentGradingAllowed(this.props.isInProgressGradingEnabledFlag, assessment.status)) {
        const redirectedUrl = RoutePath.student.assignmentSubmission.replace(RouteParams.ASSESSMENT_ID, String(assessment.id));
        this.props.history.push(redirectedUrl);
      } else {
        navigationService.navigateToAssignmentStartingPage(assignment.assignmentType, assignment.eolsAssignmentId, assessment.id);
      }
    });

  render() {
    return <ELSPageLoader />;
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: appSelectors.getLoggedInUser(state),
  fullAssignment: studentSelectors.getFullAssignment(state),
  isInProgressGradingEnabledFlag: appSelectors.getIsInProgressGradingEnabledFlag(state)
});
const enhancers = [withRouter, connect(mapStateToProps)];
export { LaunchAssignmentEntryPoint as BaseLaunchAssignmentEntryPoint };
export default compose(...enhancers)(LaunchAssignmentEntryPoint);

import cx from 'classnames';
import { ELSButton } from '../els';

export interface ButtonCloseProps {
  className?: string;
  onClick: Function;
  preventAutoPending?: boolean;
}

const ButtonClose = (props: ButtonCloseProps) => (
  <ELSButton preventAutoPending={props.preventAutoPending} className={cx('sng-button', props.className)} type="secondary" text="Close" onClick={props.onClick} />
);

ButtonClose.displayName = 'ButtonClose';
export default ButtonClose;

export enum FormField {
  DISCONTINUED = 'drainTubeDiscontinued',
  LOCATION = 'location',
  DRAIN_TUBE_TYPE = 'drainTubeType',
  TRACHEOSTOMY_TYPE = 'tracheostomyType',
  TRACHEOSTOMY_SIZE = 'tracheostomySize',
  TRACHEOSTOMY_CUFF = 'tracheostomyCuff',
  TUBE_PLACEMENT = 'tubePlacement',
  SUCTION = 'suction',
  DRAIN_TUBE_AREA = 'drainTubeArea',
  DRAINAGE = 'drainage',
  DRAIN_TUBE_PATENCY = 'drainTubePatency',
  PATIENT_RESPONSE = 'patientResponse',
  DRAIN_TUBE_CARE = 'drainTubeCare',
  DRAIN_TUBE_NOTES = 'drainTubeNotes'
}

export enum SectionTitle {
  DRAINS_TUBES = 'Drains - Tubes',
  TRACHEOSTOMY = 'Tracheostomy',
  TUBE_PLACEMENT = 'Tube Placement',
  SUCTION = 'Suction',
  DRAIN_TUBE_AREA = 'Drain - Tube Area',
  DRAINAGE = 'Drainage',
  DRAIN_TUBE_PATENCY = 'Drain - Tube Patency',
  PATIENT_RESPONSE = 'Patient Response',
  DRAIN_TUBE_CARE = 'Drain - Tube Care',
  DRAIN_TUBE_NOTES = 'Notes'
}

export enum FormFieldLabel {
  LOCATION = 'Location',
  DRAIN_TUBE_TYPE = 'Drain - Tube Type',
  TRACHEOSTOMY_TYPE = 'Tracheostomy Type',
  TRACHEOSTOMY_SIZE = 'Size'
}

import { LanguageKeys } from 'lang';
import React from 'react';
import { Locales } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, sections } from './constants';

const SafetyView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount, locale, intl } = props;
  const shownSections = sections.filter((section) => !!formFieldMap.get(section.container) && !formFieldMap.get(section.container).hide);
  const chartContent = shownSections.map((section, index) => {
    const content = (
      <ErrorFormField
        formField={formFieldMap.get(section.container)}
        formSubmittedCount={formSubmittedCount}
        inputProps={{ flexItemProps: { w: '1o2', md: '1o1', className: 'u-els-margin-top' } }}
      >
        <h3>{intl.formatMessage({ id: section.title })}</h3>
        <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(section.field)} formSubmittedCount={formSubmittedCount} />
        <ErrorFormField formField={formFieldMap.get(section.note)} formSubmittedCount={formSubmittedCount} />
      </ErrorFormField>
    );
    let sectionView = <React.Fragment key={section.container}>{content}</React.Fragment>;
    if (index % 2 === 0 && index > 0) {
      sectionView = (
        <React.Fragment key={section.container}>
          <SectionDivider className="u-els-margin-left-3x" />
          {content}
        </React.Fragment>
      );
    }
    return sectionView;
  });
  return (
    <div>
      <h1>{LanguageKeys.SAFETY.SAFETY}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.ASSESSMENT_NOT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      {locale === Locales.EN_AU && (
        <Box mt>
          <h2>{LanguageKeys.SAFETY.FALLS_RISK}</h2>
          <Box mt2>
            <h3>{LanguageKeys.SAFETY.MENTAL_STATUS}</h3>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <span>
                  {intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_IF_PATIENT_IS_CONFUSED_DISORIENTED_AGITATED_DEPRESSED }, { text: <b>{LanguageKeys.SAFETY.PLANNED}</b> })}
                </span>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.MENTAL_STATUS_ACTIONS_PLANNED)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.MENTAL_STATUS_ACTIONS_PLANNED_NOTES)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <span>
                  {intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_IF_PATIENT_IS_CONFUSED_DISORIENTED_AGITATED_DEPRESSED }, { text: <b>{LanguageKeys.SAFETY.TAKEN}</b> })}
                </span>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.MENTAL_STATUS_ACTIONS_TAKEN)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.MENTAL_STATUS_ACTIONS_TAKEN_NOTES)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </ELSFlexItem>
            </ELSFlex>

            <h3>{LanguageKeys.SAFETY.VISION}</h3>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <span>{intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_VISUAL_IMPAIRMENT }, { text: <b>{LanguageKeys.SAFETY.PLANNED}</b> })}</span>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.VISION_ACTIONS_PLANNED)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.VISION_ACTIONS_PLANNED_NOTES)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <span>{intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_VISUAL_IMPAIRMENT }, { text: <b>{LanguageKeys.SAFETY.TAKEN}</b> })}</span>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.VISION_ACTIONS_TAKEN)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.VISION_ACTIONS_TAKEN_NOTES)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </ELSFlexItem>
            </ELSFlex>

            <h3>{LanguageKeys.SAFETY.TOILETING}</h3>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <span>
                  {intl.formatMessage(
                    { id: LanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_CONFUSION_URINARY_OR_FAECAL_FREQUENCY_INCONTINENCE_URGENCY_NOCTURIA_OR_OTHER_TOILETING_ISSUES },
                    { text: <b>{LanguageKeys.SAFETY.PLANNED}</b> }
                  )}
                </span>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.TOILETING_ACTIONS_PLANNED)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.TOILETING_ACTIONS_PLANNED_NOTES)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <span>
                  {intl.formatMessage(
                    { id: LanguageKeys.SAFETY.ACTIONS_IF_PATIENT_HAS_CONFUSION_URINARY_OR_FAECAL_FREQUENCY_INCONTINENCE_URGENCY_NOCTURIA_OR_OTHER_TOILETING_ISSUES },
                    { text: <b>{LanguageKeys.SAFETY.TAKEN}</b> }
                  )}
                </span>
                <Box mt>
                  <ErrorFormField formField={formFieldMap.get(FormField.TOILETING_ACTIONS_TAKEN)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={formFieldMap.get(FormField.TOILETING_ACTIONS_TAKEN_NOTES)} formSubmittedCount={formSubmittedCount} />
                </Box>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.TOILETING_HAS_FALL_RISK_BEEN_DISCUSSED_AND_INTERVENTION_DEVELOPED)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField className="u-els-width-2o3" formField={formFieldMap.get(FormField.TOILETING_COMMENTS)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </Box>

          <SectionDivider />
        </Box>
      )}

      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          {chartContent}
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

SafetyView.displayName = 'SafetyView';
export default SafetyView;

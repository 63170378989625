import { Component } from 'react';
import { DropDownItem } from 'models/ui';
import { HistoryField, HomeMedicationHistoryProps } from './constants';
import HomeMedicationHistoryView, { HomeMedicationHistoryViewProps } from './HomeMedicationHistoryView';

interface HomeMedicationHistoryState {
  selectedHistory: string;
}

class HomeMedicationHistory extends Component<HomeMedicationHistoryProps, HomeMedicationHistoryState> {
  charts: DropDownItem[] = [];

  constructor(props) {
    super(props);
    this.state = {
      selectedHistory: HistoryField.PRESCRIPTION_MEDICATIONS
    };
    this.charts = [
      { name: 'Prescription Medications', value: HistoryField.PRESCRIPTION_MEDICATIONS },
      { name: 'Over-the-counter Products', value: HistoryField.OVER_THE_COUNTER_PRODUCTS },
      { name: 'Herbal and Natural Remedy Products', value: HistoryField.HERBAL_AND_NATURAL_REMEDY_PRODUCTS }
    ];
  }

  onHistoryChange = ({ target: { value } }) => {
    this.setState({ selectedHistory: value });
  };

  render() {
    const { locale, chartHistory, deleteHistory } = this.props;
    const { selectedHistory } = this.state;
    const viewProps: HomeMedicationHistoryViewProps = {
      charts: this.charts,
      chartHistory: chartHistory.filter((chart) => chart.homeMedicationType === selectedHistory),
      deleteHistory,
      selectedHistory,
      onHistoryChange: this.onHistoryChange,
      locale
    };
    return <HomeMedicationHistoryView {...viewProps} />;
  }
}

export default HomeMedicationHistory;

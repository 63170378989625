import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import ObstetricAssessmentView from './ObstetricAssessmentView';

export interface ObstetricAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

class ObstetricAssessment extends Component<ObstetricAssessmentProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    dataMap.set(FormField.NO_ASSESSMENT_REQUIRED, createFormField({ name: FormField.NO_ASSESSMENT_REQUIRED, type: FormFieldInputType.CHECK_BOX }));
    dataMap.set(FormField.FUNDAL_HEIGHT, createFormField({ name: FormField.FUNDAL_HEIGHT, type: FormFieldInputType.DROPDOWN, label: 'Fundal height' }));
    dataMap.set(FormField.VAGINAL_DISCHARGE_RADIO, createFormField({ name: FormField.VAGINAL_DISCHARGE_RADIO, type: FormFieldInputType.RADIO_CHOICE, label: 'Vaginal Discharge' }));
    dataMap.set(FormField.VAGINAL_DISCHARGE_COLOR, createFormField({ name: FormField.VAGINAL_DISCHARGE_COLOR, type: FormFieldInputType.MULTISELECT_DROPDOWN, label: 'Color' }));
    dataMap.set(FormField.VAGINAL_DISCHARGE_ODOR, createFormField({ name: FormField.VAGINAL_DISCHARGE_ODOR, type: FormFieldInputType.RADIO_CHOICE, label: 'Odor' }));
    dataMap.set(FormField.VAGINAL_DISCHARGE_PH, createFormField({ name: FormField.VAGINAL_DISCHARGE_PH, type: FormFieldInputType.DROPDOWN, label: 'pH' }));
    dataMap.set(FormField.VAGINAL_DISCHARGE_FERNING, createFormField({ name: FormField.VAGINAL_DISCHARGE_FERNING, type: FormFieldInputType.RADIO_CHOICE, label: 'Ferning' }));
    dataMap.set(
      FormField.VAGINAL_DISCHARGE_GROUP_BSTREPTOCOCCUS,
      createFormField({ name: FormField.VAGINAL_DISCHARGE_GROUP_BSTREPTOCOCCUS, type: FormFieldInputType.RADIO_CHOICE, label: 'Group B Streptococcus' })
    );
    dataMap.set(FormField.MEMBRANES, createFormField({ name: FormField.MEMBRANES, type: FormFieldInputType.RADIO_CHOICE, label: 'Membranes' }));
    dataMap.set(
      FormField.MEMBRANES_TIME_OF_RUPTURE_TIME,
      createFormField({ name: FormField.MEMBRANES_TIME_OF_RUPTURE_TIME, type: FormFieldInputType.TEXT_BOX, label: 'Time of rupture' })
    );
    dataMap.set(
      FormField.MEMBRANES_TIME_OF_RUPTURE_DATE,
      createFormField({ name: FormField.MEMBRANES_TIME_OF_RUPTURE_DATE, type: FormFieldInputType.TEXT_BOX, label: 'Date of rupture' })
    );
    dataMap.set(FormField.MEMBRANES_COLOR_OF_FLUID, createFormField({ name: FormField.MEMBRANES_COLOR_OF_FLUID, type: FormFieldInputType.RADIO_CHOICE, label: 'Color of Fluid' }));
    dataMap.set(FormField.MEMBRANES_VISCOSITY, createFormField({ name: FormField.MEMBRANES_VISCOSITY, type: FormFieldInputType.RADIO_CHOICE, label: 'Viscosity' }));
    dataMap.set(FormField.MEMBRANES_ODOR, createFormField({ name: FormField.MEMBRANES_ODOR, type: FormFieldInputType.RADIO_CHOICE, label: 'Odor' }));
    dataMap.set(FormField.MEMBRANES_FLUID_AMOUNT, createFormField({ name: FormField.MEMBRANES_FLUID_AMOUNT, type: FormFieldInputType.RADIO_CHOICE, label: 'Fluid Amount' }));
    dataMap.set(FormField.OTHER_TEXT_AREA, createFormField({ name: FormField.OTHER_TEXT_AREA, type: FormFieldInputType.TEXT_AREA, label: 'Other' }));

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = SectionTitle.OBSTETRIC_ASSESSMENT;
    const record = {
      chartTitle: 'System Assessment',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.OBSTETRIC_ASSESSMENT,
          records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
        },
        {
          records: [buildPatientRecord(formFieldMap, FormField.FUNDAL_HEIGHT)]
        },
        {
          records: [buildPatientRecord(formFieldMap, FormField.VAGINAL_DISCHARGE_RADIO)]
        },
        {
          sectionTitle: SectionTitle.VAGINAL_COLOR,
          records: [...buildPatientRecords(formFieldMap, FormField.VAGINAL_DISCHARGE_COLOR)]
        },
        {
          records: [
            buildPatientRecord(formFieldMap, FormField.VAGINAL_DISCHARGE_FERNING),
            buildPatientRecord(formFieldMap, FormField.VAGINAL_DISCHARGE_PH),
            buildPatientRecord(formFieldMap, FormField.VAGINAL_DISCHARGE_ODOR),
            buildPatientRecord(formFieldMap, FormField.VAGINAL_DISCHARGE_GROUP_BSTREPTOCOCCUS)
          ]
        },
        {
          sectionTitle: SectionTitle.MEMBRANES,
          records: [
            buildPatientRecord(formFieldMap, FormField.MEMBRANES),
            buildPatientRecord(formFieldMap, FormField.MEMBRANES_VISCOSITY),
            buildPatientRecord(formFieldMap, FormField.MEMBRANES_FLUID_AMOUNT),
            buildPatientRecord(formFieldMap, FormField.MEMBRANES_TIME_OF_RUPTURE_DATE),
            buildPatientRecord(formFieldMap, FormField.MEMBRANES_TIME_OF_RUPTURE_TIME),
            buildPatientRecord(formFieldMap, FormField.MEMBRANES_ODOR),
            buildPatientRecord(formFieldMap, FormField.MEMBRANES_COLOR_OF_FLUID)
          ]
        },
        {
          records: [buildPatientRecord(formFieldMap, FormField.OTHER_TEXT_AREA)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  render() {
    const chartActionsProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      formFieldMap: this.props.formFieldMap,
      chartActionsProps,
      formSubmittedCount: this.props.formSubmittedCount
    };
    return <ObstetricAssessmentView {...viewProps} />;
  }
}

export { ObstetricAssessment as BaseObstetricAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(ObstetricAssessment);

import cx from 'classnames';
import { MouseEventHandler } from 'react';
import { ELSIcon } from '../els';

export interface DeleteCellProps {
  isActive: boolean;
  onDeleteClick: MouseEventHandler;
}

const DeleteCell = (props: DeleteCellProps) => (
  <div className={cx('u-els-display-inline-block', { 'u-cursor-pointer': props.isActive })}>
    {props.isActive && (
      <button className="sng-button-icon-wrapper" type="button" aria-label="Remove" onClick={props.onDeleteClick}>
        <ELSIcon name="trash" prefix="gizmo" color="secondary" />
      </button>
    )}
    {!props.isActive && <ELSIcon name="" />}
  </div>
);

DeleteCell.displayName = 'DeleteCell';
export default DeleteCell;

import { Component, ReactNode } from 'react';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import NeurovascularAssessmentView, { NeurovascularAssessmentViewProps } from './NeurovascularAssessmentView';
import { buildDefaultNeurovascularAssessmentFormFieldItems, buildNeurovascularAssessmentFragmentRecords } from './helpers';

export type NeurovascularAssessmentProps = ChartComponentProps;

class NeurovascularAssessment extends Component<NeurovascularAssessmentProps> {
  componentDidMount(): void {
    this.initialize();
  }

  initialize = (): void => {
    const { initState } = this.props;
    initState(buildDefaultNeurovascularAssessmentFormFieldItems());
  };

  handleAfterSaved = (): void => {
    const { backToSourceLocation, navigateToSavedPatientCharting } = this.props;
    if (backToSourceLocation) backToSourceLocation();
    else navigateToSavedPatientCharting();
  };

  handleSaveClick = (): void => {
    const { saveChart, chartingTime, formFieldMap } = this.props;
    const fragmentRecords = buildNeurovascularAssessmentFragmentRecords({ chartingTime, formFieldMap });
    saveChart([fragmentRecords], {
      defaultFormFields: buildDefaultNeurovascularAssessmentFormFieldItems(),
      afterSave: this.handleAfterSaved
    });
  };

  render(): ReactNode {
    const { formFieldMap, formSubmittedCount, enableDisplayRecordsButton, hasUnsavedChanges, displayAuthoringData, handleDiscardClick } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onDisplayRecordsClick: displayAuthoringData,
      onCancelClick: () => handleDiscardClick(this.initialize),
      onSaveClick: this.handleSaveClick
    };
    const viewProps: NeurovascularAssessmentViewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount
    };

    return <NeurovascularAssessmentView {...viewProps} />;
  }
}

export default NeurovascularAssessment;

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Box, ChartActions, ChartPermission, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './Immunizations';
import ImmunizationsReadOnly from './ImmunizationsReadOnly';
import ImmunizationsSectionView from './ImmunizationsSectionView';

interface ImmunizationsViewProps {
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  formSubmittedCount: number;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  isLocked: boolean;
}

const ImmunizationsView = (props: ImmunizationsViewProps) => {
  const { isLocked, resetAll, fragment, statusFragment, formSubmittedCount, chartMetaFormFields } = props;
  return (
    <div className="immunizations">
      {isLocked ? (
        <ImmunizationsReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Immunizations</h1>
          <Box mt2>
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS}
                  frequencyNote="Frequency: Booster Every 10 years"
                  options={chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.HUMAN_PAPILLOMVIRUS}
                  frequencyNote="Frequency: 3 Doses for Females Ages 19-26"
                  options={chartMetaFormFields.get(FormField.HUMAN_PAPILLOMVIRUS_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.HUMAN_PAPILLOMVIRUS_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.VARICELLA}
                  frequencyNote="Frequency: 2 Doses (None if Pregnant)"
                  options={chartMetaFormFields.get(FormField.VARICELLA_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.VARICELLA_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.ZOSTER}
                  frequencyNote="Frequency: 1 Dose (Ages 60 and Older)"
                  options={chartMetaFormFields.get(FormField.ZOSTER_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.ZOSTER_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.MEASLES_MUMPS_RUBELLA}
                  frequencyNote="Frequency: 1 or 2 doses (Ages 19-49) or 1 dose (Ages 50 and Older), (none if pregnant)"
                  options={chartMetaFormFields.get(FormField.MEASLES_MUMPS_RUBELLA_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.MEASLES_MUMPS_RUBELLA_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.INFLUENZA}
                  frequencyNote="Frequency: 1 Annual Dose"
                  options={chartMetaFormFields.get(FormField.INFLUENZA_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.INFLUENZA_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.PNEUMOCOCCAL}
                  frequencyNote="Frequency: 1 or 2 Doses (Ages 19-64, Depending on Risk Factors) or 1 Dose (Ages 65 and Older)"
                  options={chartMetaFormFields.get(FormField.PNEUMOCOCCAL_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.PNEUMOCOCCAL_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.HEPATITIS_A}
                  frequencyNote="Frequency: 2 Doses, Depending on Risk Factors"
                  options={chartMetaFormFields.get(FormField.HEPATITIS_A_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.HEPATITIS_A_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.HEPATITIS_B}
                  frequencyNote="Frequency: 3 Doses, Depending on Risk Factors"
                  options={chartMetaFormFields.get(FormField.HEPATITIS_B_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.HEPATITIS_B_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.MENINGOCOCCAL}
                  frequencyNote="Frequency: 1 or More Doses, Depending on Risk Factors"
                  options={chartMetaFormFields.get(FormField.MENINGOCOCCAL_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.MENINGOCOCCAL_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.ROTAVIRUS}
                  frequencyNote="Frequency: 2 doses 1 month to 8 months"
                  options={chartMetaFormFields.get(FormField.ROTAVIRUS_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.ROTAVIRUS_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES}
                  frequencyNote="Frequency: Primary series 2 months to 6 year"
                  options={chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER}
                  frequencyNote="Frequency: Booster Every 10 years"
                  options={chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.HAEMOPHILUS_INFLUENZAE_TYPE_B}
                  frequencyNote="Frequency: Minimum age 6 weeks; 2 doses, 3 if indicated"
                  options={chartMetaFormFields.get(FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
            <ELSFlex wrap left>
              <ELSFlexItem w="1o2" className="u-els-padding-right">
                <ImmunizationsSectionView
                  resetAll={resetAll}
                  title={SectionTitle.INACTIVATED_POLIO_VIRUS}
                  frequencyNote="Frequency: 4 doses administered from 6 weeks through 6 years"
                  options={chartMetaFormFields.get(FormField.INACTIVATED_POLIO_VIRUS_OPTIONS)}
                  dateReceived={chartMetaFormFields.get(FormField.INACTIVATED_POLIO_VIRUS_DATE_RECEIVED)}
                  formSubmittedCount={formSubmittedCount}
                />
              </ELSFlexItem>
            </ELSFlex>
          </Box>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

ImmunizationsView.displayName = 'ImmunizationsView';
export default ImmunizationsView;

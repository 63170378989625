/* eslint-disable class-methods-use-this */
import { FormFieldInputType } from 'models/enum';
import { chartHelper } from 'helpers';
import isNil from 'lodash/fp/isNil';
import BaseValidator, { ExecuteParams } from './interface';

export default class RegexValidator implements BaseValidator {
  isReverse: boolean;

  constructor(isReverse = false) {
    this.isReverse = isReverse;
  }

  execute({ ruleInfo, field }: ExecuteParams): boolean {
    let result = false;
    const value = field.inputType === FormFieldInputType.SCALE ? chartHelper.getScaleRollUpWrapper(field)?.score?.value : field.value;
    if (!isNil(value)) {
      const regexp = new RegExp(ruleInfo.regex);
      result = regexp.test(value);
    }
    if (this.isReverse) {
      result = !result;
    }
    return result;
  }
}

/* eslint-disable react/self-closing-comp */
import { memo } from 'react';

export interface PercentageGaugeProps {
  percent: number;
  radius?: number;
  strokeWidth?: number;
  strokeColor?: string;
  percentStrokeColor?: string;
  label?: string;
}

export const defaultProps = {
  radius: 74,
  strokeWidth: 10,
  strokeColor: '#78d7ff',
  percentStrokeColor: '#339cd5',
  label: ''
};

export const PercentageGauge = (props: PercentageGaugeProps) => {
  const { percent, radius, strokeWidth, strokeColor, percentStrokeColor, label } = props;
  const width = radius * 2;
  const height = radius * 2;
  const circleR = radius - strokeWidth / 2;
  const circleCx = radius;
  const circleCy = radius;
  const circumference = 2 * circleR * Math.PI;
  const arc = (circumference * 250) / 360;
  const strokeOffset = arc - (percent / 100) * arc;

  return (
    <div className="percentage-gauge">
      <svg width={width} height={height}>
        <circle
          cx={circleCx}
          cy={circleCy}
          r={circleR}
          fill="transparent"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${arc} ${circumference}`}
          transform={`rotate(145, ${circleCx}, ${circleCy})`}
        />
        <circle
          cx={circleCx}
          cy={circleCy}
          r={circleR}
          fill="transparent"
          stroke={percentStrokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference * (250 / 360)} ${circumference}`}
          transform={`rotate(145, ${circleCx}, ${circleCy})`}
          strokeDashoffset={`${strokeOffset}`}
        />
        <text className="u-els-font-size-h1" x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
          {`${percent}%`}
        </text>
        <text className="u-els-font-size-caption u-els-color-n7" x="50%" y="90%" textAnchor="middle">
          {label}
        </text>
      </svg>
    </div>
  );
};

PercentageGauge.displayName = 'PercentageGauge';
PercentageGauge.defaultProps = defaultProps;

export default memo(PercentageGauge);

import { ELSFlex, ELSFlexItem, ELSIcon, ELSTextBox } from 'components/common/els';

interface DisplayNameProps {
  title: string;
  onChange: Function;
}

const DisplayName = (props: DisplayNameProps) => (
  <div className="els-display-name">
    <ELSFlex className="u-els-margin-bottom" left middle>
      <ELSFlexItem className="u-els-margin-right-1x1o2">
        <h3 className="u-els-font-size-h3">Display Name</h3>
      </ELSFlexItem>
      <ELSFlexItem>
        <ELSFlex left middle>
          <ELSFlexItem className="u-els-margin-right-1o2" middle>
            <ELSFlex middle>
              <ELSIcon name="eye-visible" size="1x" />
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem>
            <p>Visible to students</p>
          </ELSFlexItem>
        </ELSFlex>
      </ELSFlexItem>
    </ELSFlex>
    <ELSTextBox id="title" name="title" value={props.title} changeHandler={props.onChange}>
      Name
    </ELSTextBox>
  </div>
);

export default DisplayName;

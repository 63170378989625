import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormField, ChartMetaFormFieldParams } from 'models/ui';
import { buildFragmentRecords, formFieldsBuilder } from 'helpers/chart.helper';
import { buildPatientRecord } from 'services/chart.service';
import { FormField } from './constants';

export const getSkinAssessmentFormFieldBuilderItems = (): ChartMetaFormFieldParams[] => [
  {
    label: LanguageKeys.SKIN_ASSESSMENT.BRADEN_SCALE_COMPLETED,
    name: FormField.PRESSURE_INJURY_PREVENTION_BRADEN_SCALE_COMPLETED,
    type: FormFieldInputType.RADIO_CHOICE
  },
  {
    label: LanguageKeys.SKIN_ASSESSMENT.PATIENT_HAS_EXISTING_WOUNDS,
    name: FormField.EXISTING_WOUNDS_PATIENT_HAS_EXISTING_WOUNDS,
    type: FormFieldInputType.RADIO_CHOICE
  },
  {
    label: LanguageKeys.SKIN_ASSESSMENT.LOCATION_OR_LOCATIONS,
    name: FormField.EXISTING_WOUNDS_LOCATIONS,
    type: FormFieldInputType.TEXT_AREA
  },
  {
    label: LanguageKeys.SKIN_ASSESSMENT.WOUND_CARE_PLAN_COMPLETED,
    name: FormField.WOUND_CARE_PLAN_WOUND_CARE_PLAN_COMPLETED,
    type: FormFieldInputType.RADIO_CHOICE
  }
];

export const buildDefaultSkinAssessmentFormFieldItems = (): Map<string, ChartMetaFormField> =>
  formFieldsBuilder({
    getFormFieldItems: getSkinAssessmentFormFieldBuilderItems
  });

export const getSkinAssessmentRecords = (formFieldMap: Map<string, ChartMetaFormField>) => ({
  chartTitle: 'System Assessment',
  fragmentTitle: LanguageKeys.SKIN_ASSESSMENT.SKIN_ASSESSMENT,
  records: [
    {
      sectionTitle: LanguageKeys.SKIN_ASSESSMENT.PRESSURE_INJURY_PREVENTION,
      records: [buildPatientRecord(formFieldMap, FormField.PRESSURE_INJURY_PREVENTION_BRADEN_SCALE_COMPLETED)]
    },
    {
      sectionTitle: LanguageKeys.SKIN_ASSESSMENT.EXISTING_WOUNDS,
      records: [buildPatientRecord(formFieldMap, FormField.EXISTING_WOUNDS_PATIENT_HAS_EXISTING_WOUNDS), buildPatientRecord(formFieldMap, FormField.EXISTING_WOUNDS_LOCATIONS)]
    },
    {
      sectionTitle: LanguageKeys.SKIN_ASSESSMENT.WOUND_CARE_PLAN,
      records: [buildPatientRecord(formFieldMap, FormField.WOUND_CARE_PLAN_WOUND_CARE_PLAN_COMPLETED)]
    }
  ]
});

export const buildSkinAssessmentFragmentRecords = ({ chartingTime, formFieldMap }: { chartingTime: string; formFieldMap: Map<string, ChartMetaFormField> }): ChartFragment =>
  buildFragmentRecords({
    chartingTime,
    formFieldMap,
    getRecords: getSkinAssessmentRecords
  });

import { InterventionItem } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { BadgeDateTime, Box, Sidebar } from 'components/common';
import CarePlanFormView, { CarePlanFormViewProps } from './CarePlanFormView';

export interface CarePlanSidebarViewProps {
  openSidebar: boolean;
  chartingAt: string;
  fullName: string;
  sidebarName: string;
  planResolved: boolean;
  formSubmittedCount: number;
  formFieldMap: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  outcomeEvaluationStatus: string;
  isDisplayDocument: boolean;
  interventionItems: InterventionItem[];
  onFormFieldChange: Function;
  onDisplayDocument: Function;
  onAddInterventionItem: Function;
  onAddInterventionAction: Function;
  onCloseClick: Function;
  sidebarProps: Function;
  focusedInterventionFieldId: string;
  resetFocusedInterventionFieldId: Function;
}
const CarePlanSidebarView = (props: CarePlanSidebarViewProps) => {
  const {
    chartingAt,
    sidebarName,
    fullName,
    openSidebar,
    isDisplayDocument,
    interventionItems,
    sidebarProps,
    onCloseClick,
    onDisplayDocument,
    onAddInterventionItem,
    onAddInterventionAction,
    onFormFieldChange,
    chartActionsProps,
    formFieldMap,
    formSubmittedCount,
    outcomeEvaluationStatus,
    planResolved,
    focusedInterventionFieldId,
    resetFocusedInterventionFieldId
  } = props;

  const carePlanFormViewProps: CarePlanFormViewProps = {
    formFieldMap,
    formSubmittedCount,
    chartActionsProps,
    interventionItems,
    outcomeEvaluationStatus,
    onFormFieldChange,
    onDisplayDocument,
    onAddInterventionItem,
    onAddInterventionAction,
    isSidebarView: true,
    isDisplayDocument,
    planResolved,
    focusedInterventionFieldId,
    resetFocusedInterventionFieldId
  };
  return (
    <Sidebar open={openSidebar} onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })} sidebarProps={sidebarProps} preventAutoPending>
      <Box mt3>
        <h2>Edit Care Plan</h2>
        <h3 className="u-els-margin-top">{sidebarName}</h3>
        <BadgeDateTime className="u-els-margin-top" isoTime={chartingAt} />
        <div className="u-els-color-n7 u-els-italic">{`Entered by: ${fullName}`}</div>
      </Box>
      <div className="sidebar__divider" />
      <CarePlanFormView {...carePlanFormViewProps} />
    </Sidebar>
  );
};

CarePlanSidebarView.displayName = 'CarePlanSidebarView';
export default CarePlanSidebarView;

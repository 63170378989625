import React, { Component } from 'react';
import { Line } from 'recharts';
import { DropDownItem } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { CircleDot } from 'components/common/chart/line/CircleDot';
import { CustomRenderLeftYAxisLinesProps, LEFT_Y_AXIS_ID } from 'components/common/chart/line/HistoryLineChart';
import SquareDot from 'components/common/chart/line/SquareDot';
import AtoEAssessmentChartView, { AtoEAssessmentChartViewProps } from './AtoEAssessmentChartView';
import { dataColorByADDSPoint } from '../constants';
import { BloodPressureChartProps, OxygenationChartProps, PulseChartProps, RespirationChartProps, TemperatureChartProps } from '../types';

export interface AtoEAssessmentChartProps {
  chartConfig: TemperatureChartProps | PulseChartProps | RespirationChartProps | BloodPressureChartProps | OxygenationChartProps;
  secondChartConfig: TemperatureChartProps | PulseChartProps | RespirationChartProps | BloodPressureChartProps | OxygenationChartProps;
  charts: DropDownItem[];
  selectedChart: string;
  secondSelectedChart: string;
  onChartChange: Function;
  locale: Locales;
  isTitleHidden?: boolean;
  displaySingleChartOnSummaryPage?: boolean;
}

class AtoEAssessmentChart extends Component<AtoEAssessmentChartProps> {
  customRenderLeftYAxisLines = ({ dataKeys, handleGetCoordinate, handleDotClick, handleAnimationStart, handleAnimationEnd }: CustomRenderLeftYAxisLinesProps): JSX.Element[] =>
    dataKeys &&
    dataKeys.map((dataKey, index) => {
      const stroke = '#666666';
      const dotColor = (score: string): string => dataColorByADDSPoint[score] || '';
      const dot =
        index % 2 === 0 ? (
          <SquareDot onGetCoordinate={(cx: number, cy: number) => handleGetCoordinate(cx, cy)} customColor={dotColor} />
        ) : (
          <CircleDot stroke={stroke} strokeWidth={10} customColor={dotColor} />
        );
      const legendType = index % 2 === 0 ? 'square' : 'circle';
      return (
        <Line
          name={dataKey}
          restartAnimationOnChange
          yAxisId={LEFT_Y_AXIS_ID}
          key={dataKey}
          type="linear"
          dataKey={dataKey}
          legendType={legendType}
          stroke={stroke}
          strokeWidth={1}
          dot={dot}
          activeDot={{
            onClick: (dotValue) => handleDotClick(dotValue, dataKey)
          }}
          onAnimationStart={() => handleAnimationStart()}
          onAnimationEnd={() => handleAnimationEnd(dataKey, index)}
        />
      );
    });

  render() {
    const viewProps: AtoEAssessmentChartViewProps = {
      ...this.props,
      customRenderLeftYAxisLines: this.customRenderLeftYAxisLines
    };

    return <AtoEAssessmentChartView {...viewProps} />;
  }
}

export default AtoEAssessmentChart;

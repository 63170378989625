import { Component } from 'react';
import { ELSModalServiceType } from 'models/els';
import { GradingRadioButton } from 'models/ui';
import { Box, InfoText } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon, ELSRadio, ELSWithModalService } from 'components/common/els';
import './grading.scss';

interface GradingProps {
  modalService: ELSModalServiceType;
  infoText?: string;
  assignmentGradeType: string;
  onChange: Function;
  gradingOptions: GradingRadioButton[];
}

class Grading extends Component<GradingProps> {
  handleGradingInfoClick = () =>
    this.props.modalService.openModal({
      modalId: 'gradingInfoModalId',
      color: 'primary',
      content: (
        <ELSFlex left>
          <ELSFlexItem className="u-els-margin-right">
            <ELSIcon prefix="gizmo" name="information" size="2x" color="secondary" />
          </ELSFlexItem>
          <ELSFlexItem>
            <h2 className="u-els-font-size-h2 u-els-margin-bottom">How does grading work?</h2>
            <Box mb>SimChart assignments are graded on first attempt only.</Box>
            <Box mb>
              <h3 className="u-els-font-size-h3">Not Graded:</h3>
              <p>Students will not receive a score for the assignment. Instructors may provide feedback to students on charting, after the assignment has been submitted.</p>
            </Box>
            <Box mb>
              <h3 className="u-els-font-size-h3">Pass/Fail:</h3>
              <p>
                Students will receive a Pass for submitting the assignment by the due date. Students will receive a Fail if the assignment is not submitted by the due date.
                Instructors may provide feedback to students on charting, after the assignment has been submitted.
              </p>
            </Box>
            <Box mb>
              <h3 className="u-els-font-size-h3">Scored:</h3>
              <p>
                Students will receive a score based on the number of correct answers to quiz questions within the Case Study. Instructors may provide feedback to students on
                charting, after the assignment has been submitted.
              </p>
            </Box>
          </ELSFlexItem>
        </ELSFlex>
      )
    });

  getRadioButtons = () => {
    const { assignmentGradeType, gradingOptions, onChange } = this.props;

    return gradingOptions.map((option) => (
      <ELSRadio key={option.id} id={option.id} name="assignmentGradeType" value={option.value} checked={assignmentGradeType === option.value} changeHandler={onChange}>
        <span className="els-field__label-primary-text">{option.primaryText}</span>
        {option.helperText !== '' && <span className="els-field__label-helper-text">{option.helperText}</span>}
      </ELSRadio>
    ));
  };

  render() {
    const { infoText } = this.props;

    return (
      <ELSFlex className="els-grading" center>
        <div className="els-grading-content u-els-width-3o4">
          <ELSFlex className="u-els-margin-bottom-2x" spaceBetween>
            <ELSFlexItem>
              <ELSFlex middle>
                <ELSFlexItem className="u-els-margin-right">
                  <h3 className="u-els-font-size-h3 u-els-display-inline-block">Grading</h3>
                </ELSFlexItem>
                <ELSFlexItem>
                  <ELSButton className="u-els-display-inline-block" type="link" text="How does grading work?" onClick={this.handleGradingInfoClick} />
                </ELSFlexItem>
              </ELSFlex>
            </ELSFlexItem>
            {infoText && <InfoText text={infoText} />}
          </ELSFlex>
          <Box ml>{this.getRadioButtons()}</Box>
        </div>
      </ELSFlex>
    );
  }
}

export { Grading as BaseGrading };
export default ELSWithModalService(Grading);

import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { Locales, RoutePath } from 'constants/app.constant';
import { navigationService } from 'services';
import { Box } from 'components/common';
import { ELSIcon } from 'components/common/els';

export interface InstructorAuthorHomeViewProps {
  toChartUrl: (url: string) => string;
  locale: Locales;
  intl?: IntlShape;
}

const InstructorAuthorHomeView = ({ toChartUrl, locale, intl }: InstructorAuthorHomeViewProps) => (
  <>
    <Box pt pl2>
      <button className="page-header__close-wrapper" type="button" aria-label="Leave Simchart" onClick={() => navigationService.navigateToAppLinkSource(undefined)}>
        <ELSIcon name="close" prefix="hmds" color="secondary" size="1x" align="sub" />
      </button>
    </Box>
    <Box p2>
      <h2 className="u-els-color-primary">Instructor Author Homepage</h2>
      <ul className="u-els-margin-top-2x">
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.authoring.caseStudyMetadata)} className="c-els-link">
            Case Study Authoring
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.authoring.metadataTool)} className="c-els-link">
            Metadata
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.clinicalSetup)} className="c-els-link">
            Clinical Setup
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Admission History</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.admissionData)} className="c-els-link">
            Admission Data
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.deliveryRoomSummary)} className="c-els-link">
            Delivery Room Summary
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.alcoholScreen)} className="c-els-link">
            Alcohol Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.drugScreen)} className="c-els-link">
            Drug Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.healthHistory)} className="c-els-link">
            Health History
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.mobilityPhysicalTherapy)} className="c-els-link">
            Mobility and Physical Therapy Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.nutritionScreen)} className="c-els-link">
            Nutrition Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.planningHospitalStay)} className="c-els-link">
            Planning for Hospital Stay
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.allergy)} className="c-els-link">
            Allergy Information
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.homeMedication)} className="c-els-link">
            Home Medication Information
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.smokingScreen)} className="c-els-link">
            Smoking Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.activityOccupationalTherapy)} className="c-els-link">
            Activity - Occupational Therapy Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.roleRelationship)} className="c-els-link">
            Role/Relationship
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.infantToddlerAssessment)} className="c-els-link">
            Infant - Toddler Risk Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.adolescentRiskAssessment)} className="c-els-link">
            Adolescent Risk Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.developmentalScreen)} className="c-els-link">
            Developmental Screen
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.admissionHistory.psychosocialScreen)} className="c-els-link">
            Psychosocial Screen
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Pre-Clinical Manager</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.preClinicalManager.diagnosis)} className="c-els-link">
            Diagnosis
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.preClinicalManager.diagnosticTests)} className="c-els-link">
            Diagnostic Tests
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.preClinicalManager.medications)} className="c-els-link">
            Medications
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Provider Chart</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.landing)} className="c-els-link">
            History and Physical
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.impression)} className="c-els-link">
            Impression
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.medicalHistory)} className="c-els-link">
            Medical History
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.obstetricHistory)} className="c-els-link">
            Obstetric History
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.physicalExamination)} className="c-els-link">
            Physical Examination
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.activitiesDailyLiving)} className="c-els-link">
            Activities of Daily Living (ADLs)
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.reviewOfSystems)} className="c-els-link">
            Review of Systems
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.psychiatricHistory)} className="c-els-link">
            Psychiatric History
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.providerChart.historyAndPhysical.mentalStatus)} className="c-els-link">
            Mental Status
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">System Assessment</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.postpartumAssessment)} className="c-els-link">
            Postpartum Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.newbornAssessment)} className="c-els-link">
            Newborn Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.cardiovascularAssessment)} className="c-els-link">
            Cardiovascular Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.gastrointestinalAssessment)} className="c-els-link">
            Gastrointestinal Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.genitourinaryAssessment)} className="c-els-link">
            Genitourinary Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.integumentaryAssessment)} className="c-els-link">
            Integumentary Assessment
          </a>
        </li>
        {locale === Locales.EN_AU && (
          <li>
            <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.skinAssessment)} className="c-els-link">
              Skin Assessment
            </a>
          </li>
        )}
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.musculoskeletalAssessment)} className="c-els-link">
            Musculoskeletal Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.neurologicalAssessment)} className="c-els-link">
            Neurological Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.obstetricAssessment)} className="c-els-link">
            Obstetric Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.painAssessment)} className="c-els-link">
            Pain Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.physicalAssessment)} className="c-els-link">
            Physical Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.psychosocialAssessment)} className="c-els-link">
            Psychosocial Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.respiratoryAssessment)} className="c-els-link">
            Respiratory Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.sensoryAssessment)} className="c-els-link">
            Sensory Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment)} className="c-els-link">
            Special Precautions - Isolation Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemAssessment.symptomAnalysis)} className="c-els-link">
            Symptom Analysis
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Special Charts</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.miscellaneousNursingNotes)} className="c-els-link">
            Miscellaneous Nursing Notes
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.sbarReport)} className="c-els-link">
            SBAR
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.ballardMaturationalAssessment)} className="c-els-link">
            Ballard Maturational Assessment
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.deliveryRoomRecord)} className="c-els-link">
            Delivery Room Record
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.edinburghPostnatalDepressionScale)} className="c-els-link">
            Edinburgh Postnatal Depression Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.flaccScale)} className="c-els-link">
            FLACC Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.ciwaAr)} className="c-els-link">
            Clinical Institute Withdrawal Assessment of Alcohol Scale (CIWA-Ar)
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.hamiltonAnxietyRatingScale)} className="c-els-link">
            Hamilton Anxiety Rating Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.humptyDumptyFallsScale)} className="c-els-link">
            Humpty Dumpty Falls Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.restraints)} className="c-els-link">
            Restraints
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.pcaFlowsheet)} className="c-els-link">
            PCA Flowsheet
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.modifiedBradenQScale)} className="c-els-link">
            Modified Braden Q Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.pediatricGlasgowComaScale)} className="c-els-link">
            Pediatric Glasgow Coma Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.glasgowComaScale)} className="c-els-link">
            Glasgow Coma Scale
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.maniaQuestionnaire)} className="c-els-link">
            Mania Questionnaire
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.specialCharts.moodDisorderQuestionnaire)} className="c-els-link">
            Mood Disorder Questionnaire
          </a>
        </li>

        {/* With the current approach of the intl-react. This message id is conflicted ('Vital Signs').
         * So it is temporarily replaced with the manual condition.
         * Prefer using the intl-react.
         */}
        <h3 className="u-els-color-primary u-els-margin-top-1x">{locale === Locales.EN_US ? LanguageKeys.VITAL_SIGNS.VITAL_SIGNS : 'Pain Assessment'}</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.vitalSigns.bloodGlucose)} className="c-els-link">
            Blood Glucose
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.vitalSigns.heightWeight)} className="c-els-link">
            Height/Weight
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.vitalSigns.intakeOutput)} className="c-els-link">
            Intake/Output
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.vitalSigns.vitalSigns)} className="c-els-link">
            {intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS })}
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.vitalSigns.laborProgress)} className="c-els-link">
            Labor Progress
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Wounds</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.wounds.wounds)} className="c-els-link">
            Wounds
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Basic Nursing Care</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.basicNursingCare.activity)} className="c-els-link">
            Activity
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.basicNursingCare.safety)} className="c-els-link">
            Safety
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.basicNursingCare.hygieneDressingsComfort)} className="c-els-link">
            Hygiene - Dressings - Comfort
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.basicNursingCare.elimination)} className="c-els-link">
            Elimination
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.basicNursingCare.skinCare)} className="c-els-link">
            Skin Care
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.basicNursingCare.nutritionHydration)} className="c-els-link">
            Nutrition and Hydration
          </a>
        </li>
        <h3 className="u-els-color-primary u-els-margin-top-1x">System Nursing Interventions</h3>
        <li>
          <a
            data-testid="ins-author-home-view-item-url"
            href={toChartUrl(RoutePath.student.systemNursingInterventions.cardiovascularPeripheralVascularInterventions)}
            className="c-els-link"
          >
            Cardiovascular - Peripheral Vascular Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.respiratoryInterventions)} className="c-els-link">
            Respiratory Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.neurologicalInterventions)} className="c-els-link">
            Neurological Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.painInterventions)} className="c-els-link">
            Pain Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.musculoskeletalInterventions)} className="c-els-link">
            Musculoskeletal Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.gastrointestinalInterventions)} className="c-els-link">
            Gastrointestinal Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.genitourinaryInterventions)} className="c-els-link">
            Genitourinary Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.integumenatryInterventions)} className="c-els-link">
            Integumentary Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.safetyInterventions)} className="c-els-link">
            Safety Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.psychosocialInterventions)} className="c-els-link">
            Psychosocial Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.postpartumInterventions)} className="c-els-link">
            Postpartum Interventions
          </a>
        </li>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.systemNursingInterventions.laborInterventions)} className="c-els-link">
            Labor Interventions
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">IVs</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.ivs.ivs)} className="c-els-link">
            IVs
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Ostomies</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.ostomies.ostomies)} className="c-els-link">
            Ostomies
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Orders</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.orders.pharmacy)} className="c-els-link">
            Pharmacy
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.orders.laboratory)} className="c-els-link">
            Laboratory
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.orders.diagnosticTests)} className="c-els-link">
            Diagnostic Tests
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.orders.therapies)} className="c-els-link">
            Therapies
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.orders.generalOrders)} className="c-els-link">
            General Orders
          </a>
        </li>

        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.orders.nutrition)} className="c-els-link">
            Nutrition
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Drains - Tubes</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.drainsTubes.drainsTubes)} className="c-els-link">
            Drains - Tubes
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Patient Teaching</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.patientTeaching.patientTeaching)} className="c-els-link">
            Patient Teaching
          </a>
        </li>

        <h3 className="u-els-color-primary u-els-margin-top-1x">Patient Data</h3>
        <li>
          <a data-testid="ins-author-home-view-item-url" href={toChartUrl(RoutePath.student.patientData.homeMedication)} className="c-els-link">
            Home Medication Information
          </a>
        </li>
      </ul>
    </Box>
  </>
);

InstructorAuthorHomeView.displayName = 'InstructorAuthorHomeView';
export default InstructorAuthorHomeView;

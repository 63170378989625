import { LanguageKeys } from 'lang';

export enum FormField {
  ORDER_TYPE = 'orderType',
  ORDER_DESCRIPTION = 'orderDescription',
  ORDER_DESCRIPTION_TEXT = 'orderDescriptionText',
  ORDER_PRIORITY = 'orderPriority',
  TIMES_PER_DAY = 'orderPriorityTimesPerDay',
  TRANSPORTATION = 'transportation',
  IV_REQUIRED = 'ivRequired',
  OXYGEN_REQUIRED = 'oxygenRequired',
  DATE_FIELD = 'orderStartDate',
  TIME_FIELD = 'orderStartTime',
  SPECIAL_INSTRUCTIONS = 'specialInstructions',
  FUNCTIONAL_ASSESSMENT = 'functionalAssessment',
  OXYGEN_SATURATION_ROOM_AIR = 'oxygenSaturationRoomAir',
  OXYGEN_SATURATION_OXYGEN_SUPP = 'oxygenSaturationOxygenSupp',
  OXYGEN_THERAPY_DETAIL = 'oxygenTherapyDetail',
  ANTERIAL_BLOOD_DASES = 'anterialBloodDases',
  AEROSOL_TREATMENT = 'aerosolTreatment',
  BILEVEL_POSITIVE_AIRWAY_PRESSURE = 'bilevelPositiveAirwayPressure',
  CONTINUOUS_POSITIVE_AIRWAY_PRESSURE = 'continuousPositiveAirwayPressure',
  CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE = 'chestPhysiotherapyPosturalDrainage',
  INCENTIVE_SPIROMETER = 'incentiveSpirometer',
  INDUCED_SPUTUM = 'inducedSputum',
  INHALER = 'inhaler',
  POSITIVE_EXPIRATORY_PRESSURE_THERAPY = 'positiveExpiratoryPressureTherapy',
  FUNCTION_TEST = 'functionTest',
  SUCTIONING = 'suctioning',
  VENTILATOR = 'ventilator',
  TREATMENT = 'treatment',
  PATIENT_FAMILY_TEACHING = 'patientFamilyTeaching',
  RESPIRATORY_THERAPIST_NAME = 'respiratoryTherapistName',
  RESPIRATORY_THERAPIST_SIGNATURE = 'respiratoryTherapistSignature',
  INTERVENTION_DATE = 'interventionDate',
  INTERVENTION_TIME = 'interventionTime',
  EATING_ADLS = 'eatingADLS',
  GROOMING = 'grooming',
  UPPER_EXTREMITY_BATH = 'upperExtremityBath',
  LOWER_EXTREMITY_BATH = 'lowerExtremityBath',
  UPPER_EXTRIMITY_DRESS = 'upperExtremityDress',
  LOWER_EXTRIMITY_DRESS = 'lowerExtremityDress',
  TOILETING = 'toileting',
  EATING_TRANSFERS = 'eastingTransfers',
  SHOWER_TUB = 'showerTub',
  LOCATION = 'location',
  THERAPUTIC_EXERCISES = 'theraputicExercises',
  OCCUPATIONAL_THERAPIST_NAME = 'occupationalTherapistName',
  OCCUPATIONAL_THERAPIST_SIGNATURE = 'occupationalTherapistSignature',
  FUNCTIONAL_MOBILITY = 'functionalMobility',
  LEFT = 'left',
  RIGHT = 'right',
  SIT_SUPINE = 'sitSupine',
  SIT_STAND = 'sitStand',
  PIVOL = 'pivol',
  DEVIATIONS = 'deviations',
  STAIRS = 'stairs',
  DEVICE = 'device',
  ASSISTANCE = 'assistance',
  WEIGHT_BEARING = 'weightBaering',
  DISTANCE = 'distance',
  PHYSICAL_THERAPIST_NAME = 'physicalTherapistName',
  PHYSICAL_THERAPIST_SIGNATURE = 'physicalTherapistSignature',
  CONSULTATION_TYPE = 'consultationType',
  REASON_CONSULTATION = 'reasonConsultation',
  REFERRAL_SOURCE = 'referralSource',
  HISTORY_PRESENT_ILLNESS = 'historyPresentIllness',
  FINDINGS = 'findings',
  IMPRESSIONS = 'impressions',
  PLAN = 'plan',
  SPEECH_THERAPIST_NAME = 'speechTherapistName',
  SPEECH_THERAPIST_SIGNATURE = 'speechTherapistSignature',
  INTERVENTION_LIST = 'interventionList',
  IS_COMPLETED = 'isCompleted',
  INTERVENTION_DATE_TIME = 'interventionDateTime'
}

export const FormFieldLabel = {
  ...LanguageKeys.THERAPIES_ORDER_ENTRY,
  DATE_LABEL: LanguageKeys.DATE_LABEL
};

export enum SectionTitle {
  ADLS = 'ADLS',
  TRANSFERS = 'Transfers',
  PAIN = 'Pain (0-10 Scale)',
  INTERVENTION = 'Intervention',
  ROLLING = 'Rolling',
  GAIN = 'Gain',
  NO_SECTION = 'none'
}

export enum OrderTypeValue {
  OCCUPATIONAL = 'orderTypeOccupationalTherapy',
  PHYSICAL = 'orderTypePhysicalTherapy',
  RESPIRATORY = 'orderTypeRespiratoryTherapy',
  SPEECH = 'orderTypeSpeechTherapy'
}

export const OrderStatusValue = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  INITIATED: 'Initiated'
};

export const respiratoryFormFields = [
  FormField.INTERVENTION_DATE,
  FormField.INTERVENTION_TIME,
  FormField.FUNCTIONAL_ASSESSMENT,
  FormField.OXYGEN_SATURATION_ROOM_AIR,
  FormField.OXYGEN_SATURATION_OXYGEN_SUPP,
  FormField.OXYGEN_THERAPY_DETAIL,
  FormField.ANTERIAL_BLOOD_DASES,
  FormField.AEROSOL_TREATMENT,
  FormField.BILEVEL_POSITIVE_AIRWAY_PRESSURE,
  FormField.CONTINUOUS_POSITIVE_AIRWAY_PRESSURE,
  FormField.CHEST_PHYSIOTHERAPY_POSTURAL_DRAINAGE,
  FormField.INCENTIVE_SPIROMETER,
  FormField.INDUCED_SPUTUM,
  FormField.INHALER,
  FormField.POSITIVE_EXPIRATORY_PRESSURE_THERAPY,
  FormField.FUNCTION_TEST,
  FormField.SUCTIONING,
  FormField.VENTILATOR,
  FormField.TREATMENT,
  FormField.PATIENT_FAMILY_TEACHING,
  FormField.RESPIRATORY_THERAPIST_NAME,
  FormField.RESPIRATORY_THERAPIST_SIGNATURE
];

export const occupationalFormField = [
  FormField.INTERVENTION_DATE,
  FormField.INTERVENTION_TIME,
  FormField.EATING_ADLS,
  FormField.GROOMING,
  FormField.UPPER_EXTREMITY_BATH,
  FormField.LOWER_EXTREMITY_BATH,
  FormField.UPPER_EXTRIMITY_DRESS,
  FormField.LOWER_EXTRIMITY_DRESS,
  FormField.TOILETING,
  FormField.EATING_TRANSFERS,
  FormField.SHOWER_TUB,
  FormField.LOCATION,
  FormField.THERAPUTIC_EXERCISES,
  FormField.TREATMENT,
  FormField.PATIENT_FAMILY_TEACHING,
  FormField.OCCUPATIONAL_THERAPIST_NAME,
  FormField.OCCUPATIONAL_THERAPIST_SIGNATURE
];

export const physicalFormField = [
  FormField.INTERVENTION_DATE,
  FormField.INTERVENTION_TIME,
  FormField.FUNCTIONAL_MOBILITY,
  FormField.LEFT,
  FormField.RIGHT,
  FormField.SIT_SUPINE,
  FormField.SIT_STAND,
  FormField.PIVOL,
  FormField.DEVIATIONS,
  FormField.STAIRS,
  FormField.DEVICE,
  FormField.ASSISTANCE,
  FormField.WEIGHT_BEARING,
  FormField.DISTANCE,
  FormField.LOCATION,
  FormField.THERAPUTIC_EXERCISES,
  FormField.TREATMENT,
  FormField.PATIENT_FAMILY_TEACHING,
  FormField.PHYSICAL_THERAPIST_NAME,
  FormField.PHYSICAL_THERAPIST_SIGNATURE
];

export const speechFormField = [
  FormField.INTERVENTION_DATE,
  FormField.INTERVENTION_TIME,
  FormField.CONSULTATION_TYPE,
  FormField.REASON_CONSULTATION,
  FormField.REFERRAL_SOURCE,
  FormField.HISTORY_PRESENT_ILLNESS,
  FormField.FINDINGS,
  FormField.IMPRESSIONS,
  FormField.PLAN,
  FormField.SPEECH_THERAPIST_NAME,
  FormField.SPEECH_THERAPIST_SIGNATURE
];

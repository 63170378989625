import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { ChartActions, ChartPermission, ErrorFormField, RelatedCharts } from 'components/common';
import { FormField, SectionTitle } from './constants';

export interface GlasgowComaScaleViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const GlasgowComaScaleView = (props: GlasgowComaScaleViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="pediatric-glasgow-scale">
      <h1>{SectionTitle.GLASGOW_COMA_SCALE}</h1>
      <p>
        The Glasgow Coma Scale is based on a 15 point scale for estimating and categorizing the outcomes of brain injury on the basis of overall social capability or dependence on
        others.
        <br />
        Adapted from Teasdale G.Jennett B: Assessment of coma and impaired consciousness.
      </p>
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.GLASGOW_COMA_SCALE)}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: false }}
        formFieldMap={formFieldMap}
      />
      <p>Modified from: Teasdale G, Jennett B. Assessment of coma and impaired consciousness. A practical scale. Lancet 1974,2:81-84.</p>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
      <RelatedCharts chartIds={[NAV_ID.NEUROLOGICAL_ASSESSMENT]} />
    </div>
  );
};

export default GlasgowComaScaleView;

import moment from 'moment';
import { RefObject } from 'react';
import { ELSDayPickerType } from 'models/els';
import { AssignmentType } from 'models/enum';
import { DropDownItem } from 'models/ui';
import { DATE_TIME_FORMAT, Locales } from 'constants/app.constant';
import { Box, ButtonLinkIcon, SNGDateTime, TimeField } from 'components/common';
import { ELSButton, ELSDropDown, ELSFlex, ELSIcon } from 'components/common/els';
import './charting.actionbar.scss';
import { LocaleOptions } from './constant';

export interface ChartingActionBarViewProps {
  datePickerRef: RefObject<ELSDayPickerType>;
  isReadOnly: boolean;
  isEdit: boolean;
  isAuthor: boolean;
  isShowAuthoringPhase: boolean;
  entryByOptions: DropDownItem[];
  authoringPhaseOptions: DropDownItem[];
  assignmentType: AssignmentType;
  dateString: string;
  timeString: string;
  offset: string;
  phaseIndex: string;
  onEditClick: Function;
  onEditSubmitClick: Function;
  onEditCancelClick: Function;
  onSetToCurrentClick: Function;
  onOffsetChange: (time: string) => void;
  onDateChange: Function;
  onTimeChange: Function;
  onAuthoringPhaseChange: Function;
  onEntryByClick: Function;
  onLocaleChange: (locale: Locales) => void;
  locale: Locales;
}

const ChartingActionBarView = ({
  datePickerRef,
  isReadOnly,
  isEdit,
  isAuthor,
  isShowAuthoringPhase,
  entryByOptions,
  authoringPhaseOptions,
  assignmentType,
  dateString,
  timeString,
  offset,
  phaseIndex,
  onEditClick,
  onEditSubmitClick,
  onEditCancelClick,
  onSetToCurrentClick,
  onOffsetChange,
  onDateChange,
  onTimeChange,
  onAuthoringPhaseChange,
  onEntryByClick,
  onLocaleChange,
  locale
}: ChartingActionBarViewProps) => (
  <ELSFlex className="u-els-margin-right" middle>
    {isShowAuthoringPhase && (
      <>
        <Box mr className="u-els-color-n9">
          Phase:
        </Box>
        <Box mr>
          <ELSDropDown id="phase" name="phase" value={phaseIndex} options={authoringPhaseOptions} changeHandler={({ target }) => onAuthoringPhaseChange(target.value)} />
        </Box>
      </>
    )}
    {isAuthor && (
      <>
        <Box mr className="u-els-color-n9">
          Offset:
        </Box>
        <Box mr>
          -
          <TimeField className="datetime__time" value={offset} onChange={(event, time) => onOffsetChange(time)} />
        </Box>
      </>
    )}
    <Box mr className="u-els-color-n9">
      Charting Date and Time:
    </Box>
    {!isEdit && (
      <>
        <div className="u-els-bold">{dateString && timeString ? `${dateString} ${timeString}` : 'Current Day and Time'}</div>
        {!isReadOnly && (
          <Box ml>
            <ButtonLinkIcon iconName="edit" text="Edit" onClick={onEditClick} />
          </Box>
        )}
        {!isReadOnly && dateString && timeString && (
          <Box ml>
            <ButtonLinkIcon iconName="cycle-square" text="Set to current" onClick={onSetToCurrentClick} />
          </Box>
        )}
      </>
    )}
    {isEdit && (
      <>
        <div>
          <SNGDateTime
            disabled={assignmentType === AssignmentType.CASE_STUDY}
            selectOnly={false}
            datePickerRef={datePickerRef}
            disableAfter={new Date()}
            dateValue={dateString}
            timeValue={timeString}
            onDateChange={onDateChange}
            onTimeChange={onTimeChange}
          />
        </div>
        <Box ml>
          <ELSButton
            isDisabled={dateString === null || !moment(`${dateString} ${timeString}`, DATE_TIME_FORMAT, true).isBefore(new Date())}
            ariaLabel="Submit Edit"
            className="u-border--circle"
            iconName="checkmark"
            iconPrefix="hmds"
            size="small"
            onClick={onEditSubmitClick}
          />
        </Box>
        <button className="charting-action-bar__close-wrapper u-els-margin-left-1o4" type="button" aria-label="Cancel Edit" onClick={onEditCancelClick as VoidFunction}>
          <ELSIcon name="close" prefix="hmds" color="secondary" size="1x" />
        </button>
      </>
    )}
    {isAuthor && (
      <>
        <div className="o-els-flex-layout o-els-flex-layout--middle">
          <Box ml2 mr className="u-els-color-n9">
            Entry by:
          </Box>
          <ELSDropDown id="entry-by" name="entry-by" options={entryByOptions} changeHandler={({ target }) => onEntryByClick(target.value)} />
        </div>

        <div className="o-els-flex-layout o-els-flex-layout--middle u-els-margin-left">
          <ELSDropDown id="locale" name="locale" options={LocaleOptions} changeHandler={({ target }) => onLocaleChange(target.value)} value={locale} />
        </div>
      </>
    )}
  </ELSFlex>
);

ChartingActionBarView.displayName = 'ChartingActionBarView';
export default ChartingActionBarView;

import cx from 'classnames';
import { Box } from 'components/common';

interface SectionDividerProps {
  mt?: boolean;
  mt2?: boolean;
  mt3?: boolean;
  className?: string;
}
const SectionDivider = (props: SectionDividerProps) => {
  const { className, ...restProps } = props;
  return <Box {...(Object.keys(restProps).length === 0 ? { mt2: true } : restProps)} mb2 className={cx('c-els-divider u-els-width-1o1', className)} />;
};

SectionDivider.displayName = 'SectionDivider';
export default SectionDivider;

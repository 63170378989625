import { ChartFragmentRS, ProgressNoteFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { Box, ErrorCard } from 'components/common';
import ProgressNotesFormView from './ProgressNotesFormView';
import ProgressNotesHistoryView from './ProgressNotesHistoryView';
import ProgressNotesSidebar from './ProgressNotesSidebar';

export interface ProgressNotesViewProps {
  chartHistory: ProgressNoteFragment[];
  selectedRecord: ProgressNoteFragment;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  selectedNavId: string;
  locale: Locales;
  onEdit: Function;
  onDelete: Function;
  onUpdate: Function;
  onClose: Function;
  chartActionsProps: ChartActionsComponentProps;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const ProgressNotesView = (props: ProgressNotesViewProps) => (
  <>
    <h1>Progress Notes</h1>
    <ErrorCard chartMetaFormFields={props.chartMetaFormFields} showErrorCard={props.formSubmittedCount > 0} />
    <Box mt2>
      <h2 className="u-els-margin-bottom-2x">Saved Progress Notes</h2>
      <ProgressNotesHistoryView chartHistory={props.chartHistory} onEdit={props.onEdit} onDelete={props.onDelete} locale={props.locale} />
    </Box>
    <Box mt4>
      <h2 className="u-els-margin-bottom-2x">Add Progress Note</h2>
      <ProgressNotesFormView chartMetaFormFields={props.chartMetaFormFields} formSubmittedCount={props.formSubmittedCount} chartActionsProps={props.chartActionsProps} />
    </Box>
    <ProgressNotesSidebar
      selectedRecord={props.selectedRecord}
      selectedNavId={props.selectedNavId}
      onUpdate={props.onUpdate}
      onClose={props.onClose}
      saveChartData={props.saveChartData}
      locale={props.locale}
      {...appHelper.getChartSharedProps(props)}
    />
  </>
);

ProgressNotesView.displayName = 'ProgressNotesView';
export default ProgressNotesView;

import { LanguageKeys } from 'lang';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';
import './genitourinary.assessment.scss';

const GenitourinaryAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, resetAll, intl } = props;

  return (
    <div className="genitourinary-assessment">
      <h1 className="u-els-margin-bottom">{LanguageKeys.GENITOURINARY_ASSESSMENT.GENITOURINARY_ASSESSMENT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />

      <Box mt2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h2 className="u-els-margin-bottom">{LanguageKeys.GENITOURINARY_ASSESSMENT.URINARY_SYSTEM_ASSESSMENT}</h2>
            <Box>
              <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.URINATION_MODE)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h2 className="u-els-margin-bottom">{LanguageKeys.GENITOURINARY_ASSESSMENT.DIALYSIS}</h2>
            <ELSFlex className="u-flex-gutter-2x">
              <ELSFlexItem w="1o2" md="1o1">
                <p>{LanguageKeys.GENITOURINARY_ASSESSMENT.PERITONEAL_DIALYSIS}</p>
                <ErrorFormField formField={formFieldMap.get(FormField.DIALYSIS_PERITONEAL)} formSubmittedCount={formSubmittedCount} />
                <div className="upper-date">
                  <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.DIALYSIS_PERITONEAL_LAST_TREATMENT)} formSubmittedCount={formSubmittedCount} />
                </div>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.DIALYSIS_PERITONEAL_NEXT_TREATMENT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <p>{LanguageKeys.GENITOURINARY_ASSESSMENT.RENAL_DIALYSIS}</p>
                <ErrorFormField formField={formFieldMap.get(FormField.DIALYSIS_RENAL)} formSubmittedCount={formSubmittedCount} />
                <div className="upper-date">
                  <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.DIALYSIS_RENAL_LAST_TREATMENT)} formSubmittedCount={formSubmittedCount} />
                </div>
                <ErrorFormField key={resetAll} formField={formFieldMap.get(FormField.DIALYSIS_RENAL_NEXT_TREATMENT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </Box>

      <SectionDivider />

      <h2 className="u-els-margin-bottom">{intl.formatMessage({ id: LanguageKeys.GENITOURINARY_ASSESSMENT.URINE_COLOR_CHARACTERISTICS })}</h2>
      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.URINE_COLOR)} formSubmittedCount={formSubmittedCount} inputProps={{ resetAll }} />
          <ELSFlex className="u-flex-gutter-1x">
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.URINE_CHARACTERISTICS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.URINE_ODOR)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField
            useDefaultMargin={false}
            className="u-els-margin-bottom-1o2"
            formField={formFieldMap.get(FormField.URINARY_NO_PROBLEMS)}
            formSubmittedCount={formSubmittedCount}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.URINARY_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
          <p>{LanguageKeys.GENITOURINARY_ASSESSMENT.URINARY_DIVERSION}</p>
          <ErrorFormField formField={formFieldMap.get(FormField.HAS_URINARY_DIVERSION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.URINARY_NOTE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>

      <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_MALE)} formSubmittedCount={formSubmittedCount}>
        <SectionDivider />
        <h2 className="u-els-margin-bottom">{LanguageKeys.GENITOURINARY_ASSESSMENT.MALE}</h2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h3>{LanguageKeys.GENITOURINARY_ASSESSMENT.PENIS_ASSESSMENT}</h3>
            <ErrorFormField
              useDefaultMargin={false}
              className="u-els-margin-bottom-1o2"
              formField={formFieldMap.get(FormField.PENIS_ASSESSMENT_NO_PROBLEMS)}
              formSubmittedCount={formSubmittedCount}
            />
            <ErrorFormField formField={formFieldMap.get(FormField.PENIS_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <h3>{LanguageKeys.GENITOURINARY_ASSESSMENT.SCROTUM_TESTES_ASSESSMENT}</h3>
            <ErrorFormField
              useDefaultMargin={false}
              className="u-els-margin-bottom-1o2"
              formField={formFieldMap.get(FormField.SCROTUM_TESTES_ASSESSMENT_NO_PROBLEMS)}
              formSubmittedCount={formSubmittedCount}
            />
            <ErrorFormField formField={formFieldMap.get(FormField.SCROTUM_TESTES_ASSESSMENT)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </ErrorFormField>

      <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_FEMALE)} formSubmittedCount={formSubmittedCount}>
        <SectionDivider />
        <h2 className="u-els-margin-bottom">{LanguageKeys.GENITOURINARY_ASSESSMENT.FEMALE}</h2>
        <ELSFlex className="u-flex-gutter-3x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <h3>{LanguageKeys.GENITOURINARY_ASSESSMENT.FEMALE_EXTERNAL_GENITALIA_ASSESSMENT}</h3>
            <ErrorFormField
              useDefaultMargin={false}
              className="u-els-margin-bottom-1o2"
              formField={formFieldMap.get(FormField.EXTERNAL_GENITALIA_NO_PROBLEMS)}
              formSubmittedCount={formSubmittedCount}
            />
            <ErrorFormField formField={formFieldMap.get(FormField.EXTERNAL_GENITALIA)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={formFieldMap.get(FormField.EXTERNAL_GENITALIA_NOTE)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </ErrorFormField>

      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.GENITOURINARY_INTERVENTIONS]} />
    </div>
  );
};

GenitourinaryAssessmentView.displayName = 'GenitourinaryAssessmentView';
export default GenitourinaryAssessmentView;

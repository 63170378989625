import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, Section } from './constants';
import ModifiedBradenQScaleView, { ModifiedBradenQScaleViewProps } from './ModifiedBradenQScaleView';

class ModifiedBradenQScale extends Component<ChartComponentProps> {
  static displayName = 'ModifiedBradenQScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.MODIFIED_BRADEN_Q_SCALE,
      createFormField({ name: FormField.MODIFIED_BRADEN_Q_SCALE, type: FormFieldInputType.SCALE, errorLabel: Section.MODIFIED_BRADEN_Q_SCALE })
    );

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.SENSORY_PERCEPTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.MOISTURE, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.ACTIVITY, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.MOBILITY, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.NUTRITION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.FRICTION_SHEAR, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.TISSUE_PERFUSION, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: Section.MODIFIED_BRADEN_Q_SCALE,
      records: [
        {
          sectionTitle: Section.MODIFIED_BRADEN_Q_SCALE,
          records: [...buildPatientRecords(formFieldMap, FormField.MODIFIED_BRADEN_Q_SCALE, false, true)]
        },
        {
          sectionTitle: Section.TOTAL_SCORE,
          records: [
            buildPlainRecord({
              formField: FormField.MODIFIED_BRADEN_Q_SCALE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.MODIFIED_BRADEN_Q_SCALE))?.score?.value,
              linkedFormFieldIds: [
                FormField.SENSORY_PERCEPTION,
                FormField.MOISTURE,
                FormField.ACTIVITY,
                FormField.MOBILITY,
                FormField.NUTRITION,
                FormField.FRICTION_SHEAR,
                FormField.TISSUE_PERFUSION
              ]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: ModifiedBradenQScaleViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap()
    };

    return <ModifiedBradenQScaleView {...viewProps} />;
  }
}

export { ModifiedBradenQScale as BaseModifiedBradenQScale };
export default compose(withSavedPatientChartsPage, withChartLogic)(ModifiedBradenQScale);

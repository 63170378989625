import cx from 'classnames';
import { ReactNode } from 'react';
import './table.heading.scss';

export interface TableHeadingProps {
  className?: string;
  children: ReactNode;
}

const TableHeading = (props: TableHeadingProps) => (
  <div className={cx('table-heading', props.className)}>
    <thead>
      <tr>
        <th>{props.children}</th>
      </tr>
    </thead>
  </div>
);

TableHeading.displayName = 'TableHeading';
export default TableHeading;

import cx from 'classnames';
import { ChartFragmentRS, DiagnosticTestFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { AccordionBadge, AccordionTitle, Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSAccordion, ELSAccordionItem, ELSButton } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import NothingToSee from 'components/features/chart/pre-clinical-manager/shared/NothingToSee';
import { FormField } from './DiagnosticTest';
import EditDiagnosticTestSidebar from './EditDiagnosticTestSidebar';
import '../pre.clinical.manager.common.scss';

export interface DiagnosticTestViewProps {
  onEditSidebarOpen: Function;
  onEditSidebarClose: Function;
  chartHistory: DiagnosticTestFragment[];
  onDeleteDiagnosticTest: Function;
  onDiagnosticTestEdit: Function;
  selectedDiagnosticTest: DiagnosticTestFragment;
  formSubmittedCount: number;
  selectedNavId: string;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  chartActionsProps: ChartActionsComponentProps;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

export interface EditDianosticTestSidebarProps {
  diagnosticTest: DiagnosticTestFragment;
  onCloseClick: Function;
  onSaveClick: Function;
  preventScroll?: boolean;
  selectedNavId: string;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

const DiagnosticTestView = (props: DiagnosticTestViewProps) => {
  const editSideBarProps: EditDianosticTestSidebarProps = {
    diagnosticTest: props.selectedDiagnosticTest,
    onSaveClick: props.onDiagnosticTestEdit,
    onCloseClick: props.onEditSidebarClose,
    selectedNavId: NAV_ID.DIAGNOSTIC_TESTS,
    saveChartData: props.saveChartData,
    ...appHelper.getChartSharedProps(props)
  };

  return (
    <div className="pre-clinical-manager__page">
      <h1>Diagnostic Tests</h1>
      <Box mt2 className="diagnosis__save-section">
        <h2 className="u-els-margin-bottom-2x">Saved Diagnostic Tests</h2>
        <div className="sng-accordion">
          {props.chartHistory?.length === 0 && <NothingToSee>Nothing to see, yet! Get started adding diagnostic tests.</NothingToSee>}
          {props.chartHistory?.length > 0 && (
            <ELSAccordion id="DiagnosticAccordion">
              {props.chartHistory.map((item) => (
                <ELSAccordionItem
                  key={item.fragmentId}
                  id={item.fragmentId}
                  leftIcon="chevron-down"
                  customHeader={
                    <>
                      <AccordionTitle className={cx('u-els-margin-right-2x', { 'row--deleted': !item.active })}>{item.chartData.diagnosticTest}</AccordionTitle>
                      {item.chartData.dateOfTest && <AccordionBadge>{item.chartData.dateOfTest}</AccordionBadge>}
                    </>
                  }
                >
                  <Box py pr>
                    <div>
                      <h4>Definition and Description of the Test</h4>
                      <MultiLineWithEmpty text={item?.chartData?.definitionOfTest} />
                    </div>
                    <div className="u-els-margin-top-1x1o2">
                      <h4>Significance of the Test Being Ordered for this Patient</h4>
                      <MultiLineWithEmpty text={item?.chartData?.testOrderedDescription} />
                    </div>
                    <div className="u-els-margin-top-1x1o2">
                      <h4>Significant Findings and Results</h4>
                      <MultiLineWithEmpty text={item?.chartData?.findingsAndResults} />
                    </div>
                    {item.active && (
                      <ChartPermission fragmentCreatorId={item.creator?.id}>
                        {({ allowEditFragment, allowDeleteFragment }) => (
                          <Box mt2>
                            {allowEditFragment && <ELSButton className="sng-button u-els-margin-right" text="Edit" onClick={() => props.onEditSidebarOpen(item)} />}
                            {allowDeleteFragment && <ELSButton className="sng-button c-els-button--secondary" text="Delete" onClick={() => props.onDeleteDiagnosticTest(item)} />}
                          </Box>
                        )}
                      </ChartPermission>
                    )}
                  </Box>
                </ELSAccordionItem>
              ))}
            </ELSAccordion>
          )}
        </div>
      </Box>
      <Box mt4>
        <h2>Add Diagnostic Test</h2>
        <div className="chart-input__section-title">
          <Box mb2>
            <ErrorCard chartMetaFormFields={props.chartMetaFormFields} showErrorCard={props.formSubmittedCount > 0} />
          </Box>

          <div className="form-input__date">
            <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DATE_OF_TEST)} formSubmittedCount={props.formSubmittedCount} />
          </div>
        </div>
        <div className="u-els-width-1o3">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.CATEGORY)} formSubmittedCount={props.formSubmittedCount} />
        </div>

        <div className="u-els-width-1o3">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DIAGNOSTIC_TEST)} formSubmittedCount={props.formSubmittedCount} />
        </div>

        <div className="u-els-width-1o3">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DIAGNOSTIC_TEST_DETAIL)} formSubmittedCount={props.formSubmittedCount} />
        </div>

        <div className="u-els-width-2o3">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.DEFINITION_OF_TEST)} formSubmittedCount={props.formSubmittedCount} />
        </div>

        <div className="u-els-width-2o3">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.TEST_ORDERED_DESCRIPTION)} formSubmittedCount={props.formSubmittedCount} />
        </div>

        <div className="u-els-width-2o3">
          <ErrorFormField formField={props.chartMetaFormFields.get(FormField.FINDINGS_AND_RESULTS)} formSubmittedCount={props.formSubmittedCount} />
        </div>

        <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        <EditDiagnosticTestSidebar {...editSideBarProps} />
      </Box>
    </div>
  );
};

DiagnosticTestView.displayName = 'DiagnosticTestView';
export default DiagnosticTestView;

import { Component, ReactElement } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { withProps } from 'recompose';
import { Step } from 'models/ui';
import { appHelper } from 'helpers';
import { navigationService } from 'services';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import './create.assignment.footer.scss';

export interface CreateAssignmentFooterProps {
  steps: Step[];
  onSave: Function;
  disableNextButton: boolean;
}

class CreateAssignmentFooter extends Component<CreateAssignmentFooterProps & RouteComponentProps> {
  static defaultProps = {
    disableNextButton: true
  };

  navigateBack = (): void => {
    const { history, steps } = this.props;
    const { prevStep } = appHelper.getStepInfo(steps);

    history.push(prevStep.route);
  };

  navigateForward = (): void => {
    const { history, steps } = this.props;
    const { nextStep } = appHelper.getStepInfo(steps);

    history.push(nextStep.route);
  };

  getActionButtons = (): ReactElement => {
    const { steps, disableNextButton } = this.props;
    const { activeStep, nextStep, isLastStep } = appHelper.getStepInfo(steps);

    if (!activeStep.showActionButtons) return <></>;

    const nextBtn = isLastStep ? (
      <ELSButton id="save-btn" text="Save and Complete" onClick={this.props.onSave} isDisabled={disableNextButton} />
    ) : (
      <ELSButton id="next-btn" text={`Next: ${nextStep.title}`} onClick={this.navigateForward} isDisabled={disableNextButton} />
    );

    return (
      <ELSFlexItem>
        <ELSButton id="back-btn" className="u-els-margin-right" type="secondary" text="Back" onClick={this.navigateBack} />
        {nextBtn}
      </ELSFlexItem>
    );
  };

  handleCancelClick = () => navigationService.navigateToAppLinkSource(undefined);

  render() {
    return (
      <div className="create-assignment-workflow__footer">
        <ELSFlex spaceBetween>
          <ELSFlexItem middle>
            <ELSButton type="link" text="Cancel" onClick={this.handleCancelClick} />
          </ELSFlexItem>
          {this.getActionButtons()}
        </ELSFlex>
      </div>
    );
  }
}

export { CreateAssignmentFooter as BaseCreateAssignmentFooter };
export default withProps(() => ({
  history: useHistory()
}))(CreateAssignmentFooter);

import cx from 'classnames';
import { isEqual, startCase } from 'lodash';
import { Component } from 'react';
import { ChartMetaFormField, ErrorGroup } from 'models/ui';
import { appHelper } from 'helpers';
import { ELSFlex, ELSFlexItem, ELSIcon } from 'components/common/els';
import { SIDEBAR_CONTAINER_CLASSNAME } from 'components/common/sidebar/Sidebar';

export interface ErrorCardSection {
  groupId: string;
  displayName: string;
}

interface ErrorCardProps {
  adjustTop?: number;
  chartMetaFormFields?: Map<string, ChartMetaFormField>;
  className?: string;
  isEmbeddedChart?: boolean;
  sections?: ErrorGroup[];
  showErrorCard?: boolean;
}

interface ErrorCardState {
  sections?: ErrorCardSection[];
}

class ErrorCard extends Component<ErrorCardProps, ErrorCardState> {
  static displayName = 'ErrorCard';

  static defaultProps = {
    showErrorCard: false,
    adjustTop: 180
  };

  constructor(props) {
    super(props);

    this.state = { sections: this.updateSections(props.chartMetaFormFields) };
  }

  componentDidUpdate(): void {
    const newSections = this.updateSections(this.props.chartMetaFormFields, this.props?.sections);

    if (!isEqual(newSections, this.state.sections)) {
      this.setState({ sections: newSections });
    }
  }

  updateSections = (formFields: Map<string, ChartMetaFormField>, groupErrors?: ErrorGroup[]): ErrorCardSection[] => {
    const sections: ErrorCardSection[] = [];
    if (groupErrors) {
      formFields.forEach((field: ChartMetaFormField) => {
        const groupError = groupErrors.find((group) => group.formFields.includes(field.name));
        const isExistErrorName = sections.some((section) => section?.displayName === groupError?.displayName);
        if (field.errors.length > 0 && !isExistErrorName) {
          sections.push({
            displayName: groupError.displayName,
            groupId: `chart-field-${field.name}`
          });
        }
      });
    } else {
      formFields.forEach((field: ChartMetaFormField) => {
        if (field.errors.length > 0) {
          sections.push({
            displayName: field.errorLabel || field.extraData?.questionTitle || startCase(field.name),
            groupId: `chart-field-${field.name}`
          });
        }
      });
    }
    return sections;
  };

  jumpToSection = (id: string): void => {
    const selector = this.props.isEmbeddedChart ? `.${SIDEBAR_CONTAINER_CLASSNAME}` : undefined;
    appHelper.scrollTo(`#${id}`, 0, selector);
  };

  render() {
    const areaMessage = `${this.state.sections.length} ${this.state.sections.length === 1 ? 'area needs' : 'areas need'} to be resolved before submitting.`;

    if (!this.props.showErrorCard || !this.state.sections.length) return <div id="error-card" />;

    return (
      <div id="error-card">
        <div className={cx('c-els-card c-els-card--small c-els-card--border-negative u-els-margin-top', this.props.className)}>
          <ELSFlex left>
            <ELSFlexItem>
              <ELSIcon name="alert" prefix="gizmo" color="warn" />
            </ELSFlexItem>
            <ELSFlexItem className="u-els-margin-left">
              <div className="u-els-bold">{areaMessage}</div>
              <ul className="c-els-list u-els-margin-top">
                {this.state.sections.map((section) => (
                  <li key={section.groupId} className="c-els-list__item sng">
                    <button type="button" className="u-els-anchorize c-els-link" onClick={() => this.jumpToSection(section.groupId)}>
                      {section.displayName}
                    </button>
                  </li>
                ))}
              </ul>
            </ELSFlexItem>
          </ELSFlex>
        </div>
      </div>
    );
  }
}

export default ErrorCard;

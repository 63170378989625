import { ChartFragment } from 'models/api-response';
import { Box, Headline } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './Immunizations';
import ImmunizationsReadOnlySection from './ImmunizationsReadOnlySection';

export interface ImmunizationsReadOnlyProps {
  chartFragment: ChartFragment;
  statusFragment: ChartFragment;
  hideHeadline?: boolean;
}

const ImmunizationsReadOnly = (props: ImmunizationsReadOnlyProps) => {
  const { chartFragment, statusFragment, hideHeadline } = props;

  return (
    <Box mt2>
      {!hideHeadline && (
        <>
          <h1>Admission History</h1>
          <Headline statusFragment={statusFragment} chartingFragment={chartFragment} />
        </>
      )}
      <h2>Immunizations</h2>
      <Box className="sng-article">
        {!chartFragment && <h3>Chart Omitted</h3>}
        {chartFragment && (
          <>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS}
                  frequencyId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_OPTIONS}
                  dateReceivedId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.HUMAN_PAPILLOMVIRUS}
                  frequencyId={FormField.HUMAN_PAPILLOMVIRUS_OPTIONS}
                  dateReceivedId={FormField.HUMAN_PAPILLOMVIRUS_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.VARICELLA}
                  frequencyId={FormField.VARICELLA_OPTIONS}
                  dateReceivedId={FormField.VARICELLA_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.ZOSTER}
                  frequencyId={FormField.ZOSTER_OPTIONS}
                  dateReceivedId={FormField.ZOSTER_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.MEASLES_MUMPS_RUBELLA}
                  frequencyId={FormField.MEASLES_MUMPS_RUBELLA_OPTIONS}
                  dateReceivedId={FormField.MEASLES_MUMPS_RUBELLA_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.INFLUENZA}
                  frequencyId={FormField.INFLUENZA_OPTIONS}
                  dateReceivedId={FormField.INFLUENZA_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.PNEUMOCOCCAL}
                  frequencyId={FormField.PNEUMOCOCCAL_OPTIONS}
                  dateReceivedId={FormField.PNEUMOCOCCAL_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.HEPATITIS_A}
                  frequencyId={FormField.HEPATITIS_A_OPTIONS}
                  dateReceivedId={FormField.HEPATITIS_A_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.HEPATITIS_B}
                  frequencyId={FormField.HEPATITIS_B_OPTIONS}
                  dateReceivedId={FormField.HEPATITIS_B_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.MENINGOCOCCAL}
                  frequencyId={FormField.MENINGOCOCCAL_OPTIONS}
                  dateReceivedId={FormField.MENINGOCOCCAL_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.ROTAVIRUS}
                  frequencyId={FormField.ROTAVIRUS_OPTIONS}
                  dateReceivedId={FormField.ROTAVIRUS_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES}
                  frequencyId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_OPTIONS}
                  dateReceivedId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
            </ELSFlex>
            <ELSFlex left gutters className="o-els-flex-layout--gutters-1x1o2 u-els-margin-bottom" wrap>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER}
                  frequencyId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_OPTIONS}
                  dateReceivedId={FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.HAEMOPHILUS_INFLUENZAE_TYPE_B}
                  frequencyId={FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_OPTIONS}
                  dateReceivedId={FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
              <ELSFlexItem w="1o3" md="1o1">
                <ImmunizationsReadOnlySection
                  section={SectionTitle.INACTIVATED_POLIO_VIRUS}
                  frequencyId={FormField.INACTIVATED_POLIO_VIRUS_OPTIONS}
                  dateReceivedId={FormField.INACTIVATED_POLIO_VIRUS_DATE_RECEIVED}
                  chartFragment={chartFragment}
                />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </Box>
  );
};

ImmunizationsReadOnly.displayName = 'ImmunizationsReadOnly';
export default ImmunizationsReadOnly;

import { Component } from 'react';
import { v4 } from 'uuid';
import { Record } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, FormFieldLabel } from './constants';
import PregnancyInformationSidebarView, { PregnancyInformationSidebarViewProps } from './PregnancyInformationSidebarView';

interface PregnancyInformationSidebarProps extends ChartComponentProps {
  isOpen: boolean;
  pregnanciesInformation: Record;
  onCloseClick: Function;
  onSaveClick: Function;
  onEditTherapyHandler: Function;
}

class PregnancyInformationSidebar extends Component<PregnancyInformationSidebarProps> {
  static displayName = 'PregnancyInformationSidebar';

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<PregnancyInformationSidebarProps>) {
    const { isOpen, pregnanciesInformation } = this.props;
    if (prevProps.isOpen !== isOpen) {
      this.props.initState(this.buildFormFields(pregnanciesInformation));
    }
  }

  getValue = (pregnanciesInformation, formField, key) => pregnanciesInformation?.records.find((record) => record?.formField === formField)?.[key];

  buildFormFields = (pregnanciesInformation?): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      { name: FormField.DAY_MONTH_YEARS_AGO, label: FormFieldLabel.DAY_MONTH_YEARS_AGO, value: this.getValue(pregnanciesInformation, FormField.DAY_MONTH_YEARS_AGO, 'value') },
      { name: FormField.DURATION_OF_LABOR, label: FormFieldLabel.DURATION_OF_LABOR, value: this.getValue(pregnanciesInformation, FormField.DURATION_OF_LABOR, 'value') },
      { name: FormField.GESTATIONAL_WEEKS, label: FormFieldLabel.GESTATIONAL_WEEKS, value: this.getValue(pregnanciesInformation, FormField.GESTATIONAL_WEEKS, 'value') },
      { name: FormField.BIRTH_WEIGHT, label: FormFieldLabel.BIRTH_WEIGHT, value: this.getValue(pregnanciesInformation, FormField.BIRTH_WEIGHT, 'value') },
      { name: FormField.TYPE_OF_DELIVERY, label: FormFieldLabel.TYPE_OF_DELIVERY, value: this.getValue(pregnanciesInformation, FormField.TYPE_OF_DELIVERY, 'value') },
      { name: FormField.ANESTHESIA, label: FormFieldLabel.ANESTHESIA, value: this.getValue(pregnanciesInformation, FormField.ANESTHESIA, 'value') },
      { name: FormField.COMPLICATIONS, label: FormFieldLabel.COMPLICATIONS, value: this.getValue(pregnanciesInformation, FormField.COMPLICATIONS, 'value') }
    ];

    const radioChoices = [
      { name: FormField.GENDER, label: FormFieldLabel.GENDER, contentIds: [this.getValue(pregnanciesInformation, FormField.GENDER, 'contentId')] },
      { name: FormField.PRETERM_LABOR, label: FormFieldLabel.PRETERM_LABOR, contentIds: [this.getValue(pregnanciesInformation, FormField.PRETERM_LABOR, 'contentId')] }
    ];

    dataMap.set(
      FormField.COMMENTS,
      createFormField({
        name: FormField.COMMENTS,
        type: FormFieldInputType.TEXT_AREA,
        label: FormFieldLabel.COMMENTS,
        value: this.getValue(pregnanciesInformation, FormField.COMMENTS, 'value')
      })
    );

    textBoxes.forEach(({ name, label, value }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.TEXT_BOX, value })));
    radioChoices.forEach(({ name, label, contentIds }) => dataMap.set(name, createFormField({ name, label, type: FormFieldInputType.RADIO_CHOICE, contentIds })));
    return dataMap;
  };

  buildFragment = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap, pregnanciesInformation } = this.props;
    const records = [
      buildPatientRecord(formFieldMap, FormField.DAY_MONTH_YEARS_AGO),
      buildPatientRecord(formFieldMap, FormField.DURATION_OF_LABOR),
      buildPatientRecord(formFieldMap, FormField.GESTATIONAL_WEEKS),
      buildPatientRecord(formFieldMap, FormField.BIRTH_WEIGHT),
      buildPatientRecord(formFieldMap, FormField.GENDER),
      buildPatientRecord(formFieldMap, FormField.TYPE_OF_DELIVERY),
      buildPatientRecord(formFieldMap, FormField.ANESTHESIA),
      buildPatientRecord(formFieldMap, FormField.PRETERM_LABOR),
      buildPatientRecord(formFieldMap, FormField.COMPLICATIONS),
      buildPatientRecord(formFieldMap, FormField.COMMENTS)
    ];
    const cleanRecord = records.filter((record) => !!record);
    return { id: pregnanciesInformation?.id || v4(), active: true, records: cleanRecord };
  };

  render() {
    const { isOpen, pregnanciesInformation, onCloseClick, onSaveClick, formFieldMap, formSubmittedCount, sidebarProps } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: () => onSaveClick(this.buildFragment()),
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildFormFields(pregnanciesInformation), { selectorToScroll: '.sidebar__container' })
    };
    const viewProps: PregnancyInformationSidebarViewProps = {
      isOpen,
      onCloseClick,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      sidebarProps
    };
    return <PregnancyInformationSidebarView {...viewProps} />;
  }
}

export { PregnancyInformationSidebar as BasePregnancyInformationSidebar };
export default withChartLogic(PregnancyInformationSidebar);

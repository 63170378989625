import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, getMobilityPhysicalTherapyFormFieldBuilderItems } from './constants';
import MobilityPhysicalTherapyView from './MobilityPhysicalTherapyView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export interface MobilityPhysicalTherapyProps extends ChartComponentProps {
  navigateToLandingPage: VoidFunction;
  patientContext: PatientContext;
}

interface MobilityPhysicalTherapyState {
  isLocked: boolean;
  fragment: ChartFragment;
  resetAll: number;
  statusFragment: ChartFragment;
}
class MobilityPhysicalTherapy extends Component<MobilityPhysicalTherapyProps, MobilityPhysicalTherapyState> {
  static displayName = 'MobilityPhysicalTherapy';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.MOBILITY_PHYSICAL_THERAPY, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { patientContext } = this.props;
    const { fragment } = this.state;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getMobilityPhysicalTherapyFormFieldBuilderItems(fragment, patientContext.locale).forEach(({ name, type, label, value, contentIds, isHidden }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, type, label, value, contentIds }));
      }
    });

    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap, handleSaveClick } = this.props;
    const CHART_NAME = LanguageKeys.MOBILITY_PHYSICAL_THERAPY.MOBILITY_PHYSICAL_THERAPY_SCREEN;
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH,
          records: [...buildPatientRecords(formFieldMap, FormField.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH)]
        },
        {
          sectionTitle: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK,
          records: [...buildPatientRecords(formFieldMap, FormField.FALLS_RISK_PATIENT), buildPatientRecord(formFieldMap, FormField.FALLS_RISK_ADDITIONAL_COMMENTS)]
        },
        {
          sectionTitle: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.MOBILITY_PHYSICAL_THERAPY_SCREEN,
          records: [buildPatientRecord(formFieldMap, FormField.ORDERS_PHYSICAL_THERAPY)]
        },
        {
          sectionTitle: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION,
          records: [
            ...buildPatientRecords(formFieldMap, FormField.ADDITIONAL_INFORMATION),
            buildPatientRecord(formFieldMap, FormField.ADDITIONAL_INFORMATION_PHYSIOTHERAPIST_REASON_FOR_REFERRAL),
            buildPatientRecord(formFieldMap, FormField.ADDITIONAL_INFORMATION_OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL)
          ]
        },
        {
          sectionTitle: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS,
          records: [
            buildPatientRecord(formFieldMap, FormField.PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED),
            ...buildPatientRecords(formFieldMap, FormField.PHYSICAL_THERAPY_PROBLEMS_SELECT),
            buildPatientRecord(formFieldMap, FormField.PHYSICAL_THERAPY_PROBLEMS_CONDITION)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    const fragmentData = { ...basicInfo, chartData: cleanRecord };
    handleSaveClick([fragmentData], { afterSave: this.props.afterSave });
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges, patientContext } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      cancelButtonText: 'Cancel',
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick,
      saveButtonHasIcon: true,
      saveButtonText: 'Save and Continue'
    };
    const viewProps = {
      isLocked,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      fragment,
      formFieldMap,
      patientContext
    };
    return <MobilityPhysicalTherapyView {...viewProps} />;
  }
}

export { MobilityPhysicalTherapy as BaseMobilityPhysicalTherapy };
export default compose(withAdmissionHistory, withChartLogic)(MobilityPhysicalTherapy);

import { ELSButton } from 'components/common/els';
import { ELSQuestionWrapper } from './ELSQuestionWrapper';
import './case.study.quiz.scss';

export interface CaseStudyQuizViewProps {
  vtwId: string;
  progressText: string;
  questionType: string;
  questionPrompt: string;
  answerEvaluationMessage: string;
  questionChoices: {
    [x: string]: string;
  };
  onSelectAnswer: Function;
  selectedAnswers: string[];
  correctAnswers: string[];
  questionFeedbacks: {
    [x: string]: string;
  };
  textReference: string;
  buttonText: string;
  buttonIsDisabled: boolean;
  onButtonClick: Function;
}

const CaseStudyQuizView = (props: CaseStudyQuizViewProps) => (
  <div className="case-study-quiz">
    <div className="u-els-margin-1x u-els-margin-left-2x u-els-margin-right-2x u-els-margin-1x@desktop">
      <h2>Quiz Questions</h2>
      <div className="case-study-quiz__progress">{props.progressText}</div>
    </div>
    <div className="c-els-divider" />
    <div className="case-study-quiz__question u-els-margin-2x u-els-padding-top-1x u-els-margin-1x@desktop">
      <ELSQuestionWrapper
        questionVtwId={props.vtwId}
        answerCorrectnessText={props.answerEvaluationMessage}
        questionType={props.questionType}
        questionPrompt={props.questionPrompt}
        questionChoices={props.questionChoices}
        selectedAnswer={props.selectedAnswers}
        correctAnswer={props.correctAnswers}
        selectedAnswers={props.selectedAnswers}
        correctAnswers={props.correctAnswers}
        questionFeedbacks={props.questionFeedbacks}
        textReference={props.textReference}
        onSelectAnswer={props.onSelectAnswer}
      />
      <ELSButton text={props.buttonText} isDisabled={props.buttonIsDisabled} onClick={props.onButtonClick} pendingTimeout={1000} />
    </div>
  </div>
);

CaseStudyQuizView.displayName = 'CaseStudyQuizView';
export default CaseStudyQuizView;

import { values } from 'lodash';
import { Component } from 'react';
import { QuestionType } from 'models/enum';
import { ELSMultipleAnswer, ELSSingleBestAnswer } from '../els';

export interface QuizQuestionProps {
  answerCorrectnessText: string;
  answerEvaluationMessage?: string;
  questionVtwId: string;
  questionType: string;
  questionPrompt: string;
  questionChoices: {
    [x: string]: string;
  };
  selectedAnswers?: string[];
  correctAnswers?: string[];
  selectedAnswer?: Array<string>;
  correctAnswer?: Array<string>;
  questionFeedbacks: {
    [x: string]: string;
  };
  textReference?: string;
  onSelectAnswer?: Function;
  isShowSuccessFlagAllCorrectAnswer?: boolean;
}
/*
this is a helper class to wrap how we will show questions and rationales.
please note that the logic will control if you will see the rationale.
 */
export class ELSQuestionWrapper extends Component<QuizQuestionProps> {
  render() {
    const feedbackValues: string[] = values(this.props.questionFeedbacks);
    let rationaleTypeStr;
    let blockRationale;
    if (feedbackValues[0] !== feedbackValues[1]) {
      rationaleTypeStr = 'inline';
      blockRationale = undefined;
    } else {
      rationaleTypeStr = 'block';
      [blockRationale] = feedbackValues;
    }

    if (this.props.questionType === QuestionType.MULTIPLE_CHOICES_SINGLE_ANSWER) {
      return (
        <ELSSingleBestAnswer
          key={this.props.questionVtwId}
          questionPrompt={this.props.questionPrompt}
          questionChoices={this.props.questionChoices}
          textReference={this.props.textReference}
          correctAnswers={this.props.correctAnswer}
          selectedAnswers={this.props.selectedAnswer}
          questionFeedback={this.props.questionFeedbacks}
          onSelectAnswer={this.props.onSelectAnswer}
          rationaleType={rationaleTypeStr}
          hasAnswerBorder={false}
        />
      );
    }

    if (this.props.questionType === QuestionType.MULTIPLE_CHOICES_MULTIPLE_ANSWERS) {
      return (
        <ELSMultipleAnswer
          key={this.props.questionVtwId}
          answerCorrectnessText={this.props.answerEvaluationMessage}
          questionPrompt={this.props.questionPrompt}
          questionChoices={this.props.questionChoices}
          textReference={this.props.textReference}
          correctAnswers={this.props.correctAnswers}
          selectedAnswers={this.props.selectedAnswers}
          rationaleBlockFeedback={blockRationale}
          questionFeedback={this.props.questionFeedbacks}
          onSelectAnswer={this.props.onSelectAnswer}
          rationaleType={rationaleTypeStr}
          isShowSuccessFlagAllCorrectAnswer={this.props.isShowSuccessFlagAllCorrectAnswer}
          showAllFeedback
        />
      );
    }

    return null;
  }
}

import cx from 'classnames';
import { createRollUpContent } from 'helpers/chart.helper';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';

export interface ScaleResultProps {
  label: string;
  isHiddenScore: boolean;
  score: number;
  isHighlightContent: boolean;
}

export default function ScaleResult({ label, isHiddenScore, score, isHighlightContent }: ScaleResultProps) {
  const chartContent = createRollUpContent(undefined);

  return (
    <>
      <ELSFlex spaceBetween wrap>
        <ELSFlexItem middle>
          <h2>{label}</h2>
        </ELSFlexItem>
        {!isHiddenScore && (
          <ELSFlexItem middle>
            <span className="u-els-bold score-tag">{`Total Score: ${score || 0}`}</span>
          </ELSFlexItem>
        )}
      </ELSFlex>
      <Box mt>
        {chartContent.content.map((content) => (
          <Box key={content.label} className={cx('roll-up-content', { 'scale-roll-up--highlight': isHighlightContent && content.range.includes(Number(score)) })}>
            <p>
              <span className="special-character">•</span>
              <span className="roll-up-text">{content.label}</span>
            </p>
          </Box>
        ))}
      </Box>
    </>
  );
}

export enum SectionTitle {
  ELIMINATION = 'Elimination',
  FUNCTIONAL_ABILITY = 'Functional Ability',
  ELIMINATION_NURSING_ACTIONS = 'Elimination Nursing Actions'
}

export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'noAssessmentRequired',
  FUNCTIONAL_ABILITY = 'functionalAbility',
  FUNCTIONAL_ABILITY_NOTES = 'functionalAbilityNotes',
  ELIMINATION_NURSING_ACTIONS = 'eliminationNursingActions',
  ELIMINATION_NURSING_ACTIONS_NOTES = 'eliminationNursingActionsNotes'
}

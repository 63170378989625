export enum FormField {
  ASSESSMENT_NOT_REQUIRED = 'assessmentNotRequired',
  VISION_ASSESSMENT = 'visionAssessment',
  VISION_ASSESSMENT_NOT_REQUIRED = 'visionAssessmentNotRequired',
  GLASSES_OR_CONTACTS = 'glassesOrContacts',
  VISION_NOTES = 'visionNotes',
  HEARING_ASSESSMENT = 'hearingAssessment',
  HEARING_ASSESSMENT_NOT_REQUIRED = 'hearingAssessmentNotRequired',
  HEARING_NOTES = 'hearingNotes',
  BILATERAL_ASSESSMENT_EQUAL = 'bilateralAssessmentEqual',
  BILATERAL_ASSESSMENT_EQUAL_REASON = 'bilateralAssessmentEqualReason',
  BILATERAL_ASSESSMENT_ROUND = 'bilateralAssessmentRound',
  BILATERAL_ASSESSMENT_ROUND_REASON = 'bilateralAssessmentRoundReason',
  SIZE_LEFT = 'sizeLeft',
  SIZE_RIGHT = 'sizeRight',
  PUPILLARY_REACTION_LEFT_EYE_RADIO = 'pupillaryReactionLeftEyeRadio',
  PUPILLARY_REACTION_LEFT_EYE_CHECK = 'pupillaryReactionLeftEyeCheck',
  PUPILLARY_REACTION_RIGHT_EYE_RADIO = 'pupillaryReactionRightEyeRadio',
  PUPILLARY_REACTION_RIGHT_EYE_CHECK = 'pupillaryReactionRightEyeCheck',
  ACCOMMODATION_PRESENT = 'accommodationPresent',
  ACCOMMODATION_NOTES = 'accommodationNotes'
}

export enum SectionTitle {
  // parent section titles
  PUPILLARY_REACTION = 'Pupillary Reaction',
  PUPILS = 'Pupils',

  // section titles
  SENSORY_ASSESSMENT = 'Sensory Assessment',
  VISION_ASSESSMENT = 'Vision Assessment',
  HEARING_ASSESSMENT = 'Hearing Assessment',
  BILATERAL_ASSESSMENT = 'Pupillary Equality - Bilateral Assessment',
  SIZE = 'Size',
  PUPILLARY_REACTION_LEFT = 'Pupillary Reaction - Left eye reaction to light',
  PUPILLARY_REACTION_RIGHT = 'Pupillary Reaction - Right eye reaction to light',
  PUPILLARY_ACCOMODATION = 'Pupillary Accommodation'
}

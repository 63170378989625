import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldDataType, FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSaveAndDiscardHandlers from 'components/common/with-chart-logic/withSaveAndDiscardHandlers';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import isNil from 'lodash/fp/isNil';
import { FormField, SectionTitle } from './constants';
import PsychosocialAssessmentView, { PsychosocialAssessmentViewProps } from './PsychosocialAssessmentView';

interface PsychosocialAssessmentProps extends ChartComponentProps {
  navigateToSavedPatientCharting: VoidFunction;
}

interface PsychosocialAssessmentState {
  resetAll: number;
}

class PsychosocialAssessment extends Component<PsychosocialAssessmentProps, PsychosocialAssessmentState> {
  static displayName = 'PsychosocialAssessment';

  constructor(props) {
    super(props);
    this.state = {
      resetAll: 0
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.NO_ASSESSMENT_REQUIRED,
      createFormField({
        name: FormField.NO_ASSESSMENT_REQUIRED,
        type: FormFieldInputType.CHECK_BOX
      })
    );

    dataMap.set(
      FormField.PERCEPTION,
      createFormField({
        name: FormField.PERCEPTION,
        type: FormFieldInputType.SCALE,
        label: 'Perception'
      })
    );

    dataMap.set(
      FormField.SUPPORT,
      createFormField({
        name: FormField.SUPPORT,
        type: FormFieldInputType.SCALE,
        label: 'Support'
      })
    );

    dataMap.set(
      FormField.COPING,
      createFormField({
        name: FormField.COPING,
        type: FormFieldInputType.SCALE,
        label: 'Coping'
      })
    );

    dataMap.set(
      FormField.ANXIETY,
      createFormField({
        name: FormField.ANXIETY,
        type: FormFieldInputType.SCALE,
        label: 'Anxiety'
      })
    );

    dataMap.set(
      FormField.EVALUATION_ANXIETY,
      createFormField({
        name: FormField.EVALUATION_ANXIETY,
        type: FormFieldInputType.RADIO_CHOICE
      })
    );

    dataMap.set(
      FormField.NOTES,
      createFormField({
        name: FormField.NOTES,
        type: FormFieldInputType.TEXT_AREA
      })
    );

    return dataMap;
  };

  calculateTotalScore = (chartFormFields: Map<string, ChartMetaFormField>) => {
    let totalScore = 0;

    chartFormFields.forEach((field) => {
      if (field.inputType === FormFieldInputType.SCALE) {
        totalScore += field.chartContent
          ?.filter((content) => content.dataType === FormFieldDataType.SCALE_QUESTION)
          .map((content) => {
            const questionField = chartFormFields.get(content.formFieldId);
            return isNil(questionField.extraData.score?.value) ? 0 : Number(questionField.extraData.score.value);
          })
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      }
    });

    return totalScore;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Psychosocial Assessment';

    const totalScore = this.calculateTotalScore(formFieldMap);
    const totalScoreRecord =
      totalScore === 0
        ? null
        : buildPlainRecord({
            formField: SectionTitle.TOTAL_SCORE,
            title: SectionTitle.TOTAL_SCORE,
            content: totalScore.toString(),
            linkedFormFieldIds: [FormField.PERCEPTION_QUESTION, FormField.SUPPORT_QUESTION, FormField.COPING_QUESTION, FormField.ANXIETY_QUESTION]
          });

    const record = {
      chartTitle: 'System Assessment',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: CHART_NAME,
          records: [buildPatientRecord(formFieldMap, FormField.NO_ASSESSMENT_REQUIRED)]
        },
        {
          sectionTitle: SectionTitle.PERCEPTION,
          records: [...buildPatientRecords(formFieldMap, FormField.PERCEPTION, false, true)]
        },
        {
          sectionTitle: SectionTitle.SUPPORT,
          records: [...buildPatientRecords(formFieldMap, FormField.SUPPORT, false, true)]
        },
        {
          sectionTitle: SectionTitle.COPING,
          records: [...buildPatientRecords(formFieldMap, FormField.COPING, false, true)]
        },
        {
          sectionTitle: SectionTitle.ANXIETY,
          records: [...buildPatientRecords(formFieldMap, FormField.ANXIETY, false, true)]
        },
        {
          sectionTitle: SectionTitle.EVALUATION_ANXIETY,
          records: [buildPatientRecord(formFieldMap, FormField.EVALUATION_ANXIETY, SectionTitle.EVALUATION_ANXIETY)]
        },
        {
          sectionTitle: SectionTitle.TOTAL_SCORE,
          records: [totalScoreRecord, buildPatientRecord(formFieldMap, FormField.NOTES)]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  // This is a workaround to fix all required fields showing errors
  // after noAssessmentCheckbox unchecked with the precondition:
  // form has submitted at least one time but failed because validation errors.
  //
  // Root cause of the issue: when noAssessmentCheckbox unchecked, all required fields
  // will be validated. They will trigger errors because disabling when checking the checkbox
  // had reset field value to empty.
  //
  // Work around: when uncheck the checkbox, resetFormSubmitted value so that REQUIRED
  // errors will be filtered out before showing.
  handleNoAssessmentRequiredChange = (_, noAssessmentCheckbox) => !noAssessmentCheckbox.selected || this.props.resetFormSubmitted();

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: PsychosocialAssessmentViewProps = {
      resetAll: this.state.resetAll,
      chartActionsProps,
      totalScore: this.calculateTotalScore(this.props.formFieldMap),
      onNoAssessmentRequiredChange: this.handleNoAssessmentRequiredChange,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      isEmbeddedChart: this.props.isEmbeddedChart
    };

    return <PsychosocialAssessmentView {...viewProps} />;
  }
}

export { PsychosocialAssessment as BasePsychosocialAssessment };
export default compose(withSavedPatientChartsPage, withSaveAndDiscardHandlers, withChartLogic)(PsychosocialAssessment);

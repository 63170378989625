import { CaseStudy } from 'models/ui';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import CaseStudyInfoView from 'components/features/assignment/create-assignment-workflow/case-study-content/CaseStudyInfoView';

export interface CaseStudyQuickViewViewProps {
  activeTab: number;
  selectedCaseStudy: CaseStudy;
  onTabGroupClick: Function;
  isBookOrganized: boolean;
}

const CaseStudySubmissionView = (props: CaseStudyQuickViewViewProps) => {
  const { id, patient } = props.selectedCaseStudy || {};
  if (!id) {
    return null;
  }
  return (
    <>
      <Box mt2 className="quick-view__patient-info">
        <ELSFlex spaceBetween>
          <ELSFlexItem>
            <p>Patient name (Last, First)</p>
            <h4>{`${patient?.lastName} ${patient?.firstName}`}</h4>
          </ELSFlexItem>
          <ELSFlexItem className="u-els-float-left u-els-text-right">
            <p>Hospital Floor</p>
            <h4>{patient?.hospitalFloor}</h4>
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <CaseStudyInfoView activeTab={props.activeTab} selectedCaseStudy={props.selectedCaseStudy} onTabGroupClick={props.onTabGroupClick} isBookOrganized={props.isBookOrganized} />
    </>
  );
};

CaseStudySubmissionView.displayName = 'CaseStudySubmissionView';
export default CaseStudySubmissionView;

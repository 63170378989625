import queryString from 'query-string';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { AppMessage, NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import InjectionsView, { InjectionsViewProps } from './InjectionsView';

interface InjectionsProps extends RouteComponentProps {
  showConfirmationModal: Function;
  enableMultiStepsAuthoring: Function;
}

interface InjectionsState {
  fragment: ChartFragment;
  fragments: ChartFragment[];
}

class Injections extends Component<InjectionsProps & ChartComponentProps, InjectionsState> {
  static displayName = 'Injections';

  constructor(props) {
    super(props);
    this.state = {
      fragment: null,
      fragments: null
    };
  }

  componentDidMount() {
    const id = this.getIdFromParam(this.props);
    this.loadData(id);
    this.props.enableMultiStepsAuthoring(true);
  }

  componentDidUpdate(prevProps) {
    const prevId = this.getIdFromParam(prevProps);
    const id = this.getIdFromParam(this.props);
    if (id !== prevId && !id) {
      this.setState({ fragment: null });
      this.props.initState(this.buildFormFields());
    }
  }

  getIdFromParam = (props: InjectionsProps) => {
    const parsedQuery = queryString.parse(props.location.search);
    return parsedQuery.id;
  };

  loadData = (id) =>
    appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        let chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS && fragment.fragmentId === id && fragment.active);
        // Prevent update a complete injection
        if (this.isCompleteInjectionExisted(fragments, chartFragment)) {
          chartFragment = null;
          const injectionEntryPage = RoutePath.student.injections.injections.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
          this.props.history.push(injectionEntryPage);
        }
        this.setState({ fragment: chartFragment, fragments });
        this.props.initState(this.buildFormFields(chartFragment));
      }),
      { errorMessage: AppMessage.NO_CHART_DATA }
    );

  isCompleteInjectionExisted = (fragments: ChartFragment[], fragment: ChartFragment) => {
    if (!fragment) return false;
    const fragmentList = fragments.filter((frag) => frag.linkedFragmentId === (fragment.linkedFragmentId || fragment.fragmentId));
    return fragmentList.some((frag) => frag.active && chartHelper.getFragmentValueByKey(frag, SectionTitle.INJECTION, FormField.COMPLETE, 'content'));
  };

  buildFormFields = (fragment?: ChartFragment): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.COMPLETE,
      createFormField({
        name: FormField.COMPLETE,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: chartHelper.getFragmentContentIds(fragment, SectionTitle.INJECTION, FormField.COMPLETE)
      })
    );

    dataMap.set(
      FormField.INJECTION_TYPE,
      createFormField({
        name: FormField.INJECTION_TYPE,
        type: FormFieldInputType.RADIO_CHOICE,
        label: FormFieldLabel.INJECTION_TYPE,
        contentIds: chartHelper.getFragmentContentIds(fragment, SectionTitle.INJECTION, FormField.INJECTION_TYPE)
      })
    );

    dataMap.set(
      FormField.LOCATION,
      createFormField({
        name: FormField.LOCATION,
        type: FormFieldInputType.MULTI_SELECT_RADIO,
        label: FormFieldLabel.LOCATION,
        contentIds: chartHelper.getFragmentContentIds(fragment, SectionTitle.INJECTION, FormField.LOCATION)
      })
    );

    dataMap.set(
      FormField.INJECTION_SITE_ASSESSMENT,
      createFormField({
        name: FormField.INJECTION_SITE_ASSESSMENT,
        type: FormFieldInputType.MULTISELECT_DROPDOWN,
        contentIds: chartHelper.getFragmentContentIds(fragment, SectionTitle.INJECTION_SITE_ASSESSMENT, FormField.INJECTION_SITE_ASSESSMENT)
      })
    );

    dataMap.set(
      FormField.FINDINGS_ACTION_TAKEN,
      createFormField({
        name: FormField.FINDINGS_ACTION_TAKEN,
        type: FormFieldInputType.TEXT_AREA,
        label: FormFieldLabel.FINDINGS_ACTION_TAKEN,
        value: chartHelper.getFragmentValueByKey(fragment, SectionTitle.FINDINGS_ACTION_TAKEN, FormField.FINDINGS_ACTION_TAKEN, 'value')
      })
    );

    dataMap.set(
      FormField.NOTES,
      createFormField({
        name: FormField.NOTES,
        type: FormFieldInputType.TEXT_AREA,
        value: chartHelper.getFragmentValueByKey(fragment, SectionTitle.NOTES, FormField.NOTES, 'value')
      })
    );

    return dataMap;
  };

  handleSaveClick = () => {
    const { fragment, fragments } = this.state;
    const { formFieldMap } = this.props;
    const curLocation = formFieldMap.get(FormField.LOCATION).value;
    const isSavingGivenInjection = !formFieldMap.get(FormField.COMPLETE).value;

    const needShowWarning = isSavingGivenInjection && !fragment?.fragmentId && this.isLocationOfGivenInjectionExisted(fragments, curLocation);

    if (needShowWarning) {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Please confirm.',
        message: 'You already have an given injection at this location. Are you sure you want to add another?',
        onOkClick: this.saveChart
      });
    } else {
      this.saveChart();
    }
  };

  isLocationOfGivenInjectionExisted = (fragments, newLocation: string) => {
    const fragmentList = fragments.filter((frag) => {
      const location = chartHelper.getFragmentValueByKey(frag, SectionTitle.INJECTION, FormField.LOCATION, 'value');
      return frag.active && location === newLocation;
    });

    let result = false;

    const fragmentGroups = chartHelper.groupFragmentsById(fragmentList);
    fragmentGroups.forEach((fragmentGroup) => {
      const isInjectionComplete = fragmentGroup.some((frag) => chartHelper.getFragmentValueByKey(frag, SectionTitle.INJECTION, FormField.COMPLETE, 'content'));
      if (!isInjectionComplete) {
        result = true;
      }
    });

    return result;
  };

  saveChart = () => {
    const loadedFragment = this.state.fragment;
    const fragment = this.buildFragment();
    const defaultFormFields = this.buildFormFields(loadedFragment);
    return this.props.saveChart([fragment], { defaultFormFields, afterSave: this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { isAuthor } = this.props;
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Injections';
    const record = {
      chartTitle: CHART_NAME,
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.INJECTION,
          records: [
            buildPatientRecord(formFieldMap, FormField.COMPLETE),
            buildPatientRecord(formFieldMap, FormField.INJECTION_TYPE),
            buildPatientRecord(formFieldMap, FormField.LOCATION)
          ]
        },
        {
          sectionTitle: SectionTitle.INJECTION_SITE_ASSESSMENT,
          records: buildPatientRecords(formFieldMap, FormField.INJECTION_SITE_ASSESSMENT)
        },
        {
          sectionTitle: SectionTitle.FINDINGS_ACTION_TAKEN,
          records: [buildPatientRecord(formFieldMap, FormField.FINDINGS_ACTION_TAKEN)]
        },
        {
          sectionTitle: SectionTitle.NOTES,
          records: [buildPatientRecord(formFieldMap, FormField.NOTES)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = chartHelper.createFragmentId(NAV_ID.INJECTIONS);
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    let linkedFragmentId;
    const loadedFragment = this.state.fragment;
    if (!loadedFragment) {
      linkedFragmentId = fragmentId;
    } else {
      linkedFragmentId = loadedFragment.linkedFragmentId || loadedFragment.fragmentId;
    }
    return { ...basicInfo, linkedFragmentId, groupFragmentRef: isAuthor ? linkedFragmentId : undefined, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildFormFields(this.state.fragment));
    appHelper.scrollTop();
  };

  render() {
    const { fragment } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      cancelButtonText: 'Cancel',
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: () => this.props.displayAuthoringData(),
      isMultiStepsAuthoring: true,
      onSaveClick: this.handleSaveClick,
      saveButtonHasIcon: true,
      saveButtonText: 'Save and Continue'
    };

    const viewProps: InjectionsViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      fragment
    };
    return <InjectionsView {...viewProps} />;
  }
}

const enhancers = [withRouter, withSavedPatientChartsPage, withChartLogic];

export { Injections as BaseInjections };
export default compose(...enhancers)(Injections);

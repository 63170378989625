import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import MusculoskeletalAssessmentView from './MusculoskeletalAssessmentView';
import { Title } from '../shared/constants';

interface MusculoskeletalAssessmentState {
  resetAll: number;
}

class MusculoskeletalAssessment extends Component<ChartComponentProps, MusculoskeletalAssessmentState> {
  static displayName = 'MusculoskeletalAssessment';
  customFieldTitleMap: Map<string, string>;

  constructor(props) {
    super(props);
    this.state = { resetAll: 0 };
    this.customFieldTitleMap = this.buildCustomTitle();
  }

  componentDidMount() {
    this.props.initState(this.buildFormFields());
  }

  buildCustomTitle = (): Map<string, string> => {
    const customFieldTitleMap = new Map();
    customFieldTitleMap.set(FormField.GAIT_OR_BALANCE, SectionTitle.GAIT_BALANCE);
    return customFieldTitleMap;
  };

  buildFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const checkboxes = [FormField.NO_ASSESSMENT_REQUIRED, FormField.ROM_MOVE_ALL_EXTREMITIES, FormField.AMPUTATION_AMPUTEE, FormField.POST_ORTHOPEDIC_SURGERY];
    const radioChoices = [
      FormField.LEFT_ARM,
      FormField.RIGHT_ARM,
      FormField.LEFT_LEG,
      FormField.RIGHT_LEG,
      FormField.POST_OPERATIVE_EA_TEMPERATURE,
      FormField.POST_OPERATIVE_EA_CAPILLARY_REFILL,
      FormField.POST_OPERATIVE_EA_COLOR,
      FormField.POST_OPERATIVE_EA_SENSATION,
      FormField.SPECIALIST_SIGNS_HOMAN_SIGN,
      FormField.SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN,
      FormField.MOVEMENT_EVALUATION_FINGERS,
      FormField.MOVEMENT_EVALUATION_TOES
    ];
    const dropdowns = [FormField.GAIT_OR_BALANCE, FormField.POST_OPERATIVE_AREA_LEFT, FormField.POST_OPERATIVE_AREA_RIGHT];
    const textAreas = [FormField.GAIT_BALANCE_NOTES, FormField.AMPUTATION_DESCRIBE, FormField.POSTOPERATIVE_ORTHOPEDIC_NOTE];

    checkboxes.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX })));
    radioChoices.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE })));
    dropdowns.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTI_SELECT_RADIO })));
    textAreas.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA })));

    dataMap.set(FormField.LEFT_ARM, { ...dataMap.get(FormField.LEFT_ARM), label: FormFieldLabel.LEFT_ARM });
    dataMap.set(FormField.RIGHT_ARM, { ...dataMap.get(FormField.RIGHT_ARM), label: FormFieldLabel.RIGHT_ARM });
    dataMap.set(FormField.LEFT_LEG, { ...dataMap.get(FormField.LEFT_LEG), label: FormFieldLabel.LEFT_LEG });
    dataMap.set(FormField.RIGHT_LEG, { ...dataMap.get(FormField.RIGHT_LEG), label: FormFieldLabel.RIGHT_LEG });
    dataMap.set(FormField.AMPUTATION_DESCRIBE, { ...dataMap.get(FormField.AMPUTATION_DESCRIBE), label: FormFieldLabel.AMPUTATION_DESCRIBE });
    dataMap.set(FormField.POST_OPERATIVE_AREA_LEFT, { ...dataMap.get(FormField.POST_OPERATIVE_AREA_LEFT), label: FormFieldLabel.POST_OPERATIVE_AREA_LEFT });
    dataMap.set(FormField.POST_OPERATIVE_AREA_RIGHT, { ...dataMap.get(FormField.POST_OPERATIVE_AREA_RIGHT), label: FormFieldLabel.POST_OPERATIVE_AREA_RIGHT });
    dataMap.set(FormField.POST_OPERATIVE_EA_TEMPERATURE, { ...dataMap.get(FormField.POST_OPERATIVE_EA_TEMPERATURE), label: FormFieldLabel.POST_OPERATIVE_EA_TEMPERATURE });
    dataMap.set(FormField.POST_OPERATIVE_EA_CAPILLARY_REFILL, {
      ...dataMap.get(FormField.POST_OPERATIVE_EA_CAPILLARY_REFILL),
      label: FormFieldLabel.POST_OPERATIVE_EA_CAPILLARY_REFILL
    });
    dataMap.set(FormField.POST_OPERATIVE_EA_COLOR, { ...dataMap.get(FormField.POST_OPERATIVE_EA_COLOR), label: FormFieldLabel.POST_OPERATIVE_EA_COLOR });
    dataMap.set(FormField.POST_OPERATIVE_EA_SENSATION, { ...dataMap.get(FormField.POST_OPERATIVE_EA_SENSATION), label: FormFieldLabel.POST_OPERATIVE_EA_SENSATION });
    dataMap.set(FormField.SPECIALIST_SIGNS_HOMAN_SIGN, { ...dataMap.get(FormField.SPECIALIST_SIGNS_HOMAN_SIGN), label: FormFieldLabel.SPECIALIST_SIGNS_HOMAN_SIGN });
    dataMap.set(FormField.SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN, {
      ...dataMap.get(FormField.SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN),
      label: FormFieldLabel.SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN
    });
    dataMap.set(FormField.MOVEMENT_EVALUATION_FINGERS, { ...dataMap.get(FormField.MOVEMENT_EVALUATION_FINGERS), label: FormFieldLabel.MOVEMENT_EVALUATION_FINGERS });
    dataMap.set(FormField.MOVEMENT_EVALUATION_TOES, { ...dataMap.get(FormField.MOVEMENT_EVALUATION_TOES), label: FormFieldLabel.MOVEMENT_EVALUATION_TOES });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;

    return [
      chartHelper.buildSection({ sectionTitle: SectionTitle.MUSCULOSKELETAL_ASSESSMENT, fields: [FormField.NO_ASSESSMENT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.RANGE_OF_MOTION,
        fields: [FormField.ROM_MOVE_ALL_EXTREMITIES, FormField.LEFT_ARM, FormField.RIGHT_ARM, FormField.LEFT_LEG, FormField.RIGHT_LEG],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.GAIT_BALANCE,
        fields: [FormField.GAIT_OR_BALANCE, FormField.GAIT_BALANCE_NOTES],
        formFieldMap,
        customFormFieldTitleMap: this.customFieldTitleMap
      }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.AMPUTATION, fields: [FormField.AMPUTATION_AMPUTEE, FormField.AMPUTATION_DESCRIBE], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.POST_OPERATIVE_ORTHOPEDIC, fields: [FormField.POST_ORTHOPEDIC_SURGERY], formFieldMap }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.POST_OPERATIVE_AREA, fields: [FormField.POST_OPERATIVE_AREA_LEFT, FormField.POST_OPERATIVE_AREA_RIGHT], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.POST_OPERATIVE_EXTREMITY_ASSESSMENT,
        fields: [FormField.POST_OPERATIVE_EA_TEMPERATURE, FormField.POST_OPERATIVE_EA_CAPILLARY_REFILL, FormField.POST_OPERATIVE_EA_COLOR, FormField.POST_OPERATIVE_EA_SENSATION],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.SPECIAL_TEST_SIGNS,
        fields: [FormField.SPECIALIST_SIGNS_HOMAN_SIGN, FormField.SPECIALIST_TEST_SIGNS_PLANTAR_FLEXION_PAIN],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: SectionTitle.MOVEMENT_EVALUATION_OF_AFFECTED_LIMB,
        fields: [FormField.MOVEMENT_EVALUATION_FINGERS, FormField.MOVEMENT_EVALUATION_TOES],
        formFieldMap
      }),
      chartHelper.buildSection({ sectionTitle: SectionTitle.POST_OPERATIVE_ORTHOPEDIC, fields: [FormField.POSTOPERATIVE_ORTHOPEDIC_NOTE], formFieldMap })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: SectionTitle.MUSCULOSKELETAL_ASSESSMENT,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.navigateToSavedPatientCharting });
  };

  handleCancelClick = () => {
    this.props.initState(this.buildFormFields());
    this.setState(
      (prevState) => ({ resetAll: prevState.resetAll + 1 }),
      () => appHelper.scrollTop()
    );
  };

  render() {
    const { formFieldMap, formSubmittedCount, displayAuthoringData, intl } = this.props;
    const { resetAll } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData
    };
    const viewProps = {
      formFieldMap,
      formSubmittedCount,
      resetAll,
      chartActionsProps,
      intl
    };
    return <MusculoskeletalAssessmentView {...viewProps} />;
  }
}

export { MusculoskeletalAssessment as BaseMusculoskeletalAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(MusculoskeletalAssessment);

/* eslint-disable no-useless-computed-key */

/* eslint-disable prettier/prettier */
import { RoutePath } from 'constants/app.constant';

export enum SectionTitle {
  PATIENT_CARD = 'Patient Card'
}

export enum Section {
  GENERAL_INFORMATION,
  ACTION_LIST
}

export const SectionMapping = {
  ['Activity/Mobility|Activity as tolerated']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Activity order: :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Activity restrictions: :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate in hall']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate in room']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate on unit']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate patient']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate with assistive device']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate with oxygen']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Ambulate without oxygen']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Bathroom privileges with assistance']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Bedrest with bathroom privileges']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Bedrest with bedside commode']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Bedrest, strict']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Bedside commode']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Complete bedrest']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Dangle']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Evaluate weight bearing status']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Must be up for meals']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Needs :_ person assist']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Needs transfer assistance']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|No bending']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Nonweight bearing :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Range of motion every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Turn every 2 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Up as tolerated']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Up as tolerated with assist']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Up in chair']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Up with assistance']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Activity/Mobility :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Uses wheelchair']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Uses cane']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Uses rolling walker']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Uses walker']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Activity/Mobility|Use of quiet room']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Alerts|Watch for signs and symptoms of drug/alcohol withdrawal']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Alerts :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Admit for observation']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Admit to :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Admit to Dr. :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Admit to medical-surgical']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Condition :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Diagnosis :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Diagnosis, secondary :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|No blood pressure or sticks in left arm']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|No blood pressure or sticks in right arm']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Notify Dr. :_ of admission and room number']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Alerts|Watch for signs and symptoms of delirium tremens']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Administer with 0.9% sodium chloride']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Blood|Check labels and armband with two RNs']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Blood|No blood or blood products']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Blood :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Type and crossmatch for :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Blood|Do not use blood filter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Blood|Give :_ unit(s) of albumin as rapidly as tolerated']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ unit(s) packed red blood cells (PRBCs); give each unit over 2 hours']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ unit(s) of plasma as rapidly as tolerated']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ unit(s) of platelets as rapidly as tolerated']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ mL of albumin over :_ min/hr']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ mL of packed red blood cells (PRBCs) over :_ hours']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ mL of plasma over :_ min/hr']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Blood|Give :_ mL of platelets over :_ min/hr']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Cardiovascular/Peripheral Vascular|Test alarms for proper working order']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Cardiovascular/Peripheral Vascular|No blood draws by central line']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Cardiovascular/Peripheral Vascular|Sequential compression device (SCD) while in bed']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.cardiovascularPeripheralVascularInterventions
  },
  ['Cardiovascular/Peripheral Vascular|Telemetry']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Telemetry monitoring']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Collect blood through venous access']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Cardiovascular/Peripheral Vascular|Discontinue antiembolism pump when ambulating']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.cardiovascularPeripheralVascularInterventions
  },
  ['Cardiovascular/Peripheral Vascular|Discontinue sequential compression device']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.cardiovascularPeripheralVascularInterventions
  },
  ['Cardiovascular/Peripheral Vascular|Ankle-brachial index']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Nasal packing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Cardiovascular/Peripheral Vascular|Temporary pacemaker settings :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Pacemaker to bedside']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Cardiovascular/Peripheral Vascular|Pacemaker, external – parameters :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Peripheral vascular checks every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Cardiovascular/Peripheral Vascular :_']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment
  },
  ['Cardiovascular/Peripheral Vascular|Holter monitor']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Neurological/vascular checks']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Thromboembolic deterrent (TED) hose, knee high, remove every :_ hours']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.cardiovascularPeripheralVascularInterventions
  },
  ['Cardiovascular/Peripheral Vascular|Apply pressure to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Cardiovascular/Peripheral Vascular|Bleeding precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Cardiovascular/Peripheral Vascular|Cardiac monitor']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.cardiovascularAssessment },
  ['Code Status|Chemical code only']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Code Status|Do not intubate']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Code Status|Do not resuscitate']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Code Status|Full code']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Code Status|Code Status :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Comfort|Comfort :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Cold water compress to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Cooling blanket']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Elevate head of bed :_ degrees']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Heat to affected area :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Ice to affected area :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|End-of-life care comfort measures']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Weighted blanket to decrease anxiety']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|De-escalation techniques']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Comfort|Positioning instruction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Comfort|Warming blanket']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Conditional Orders|Blood cultures × 2 for temperature greater than :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Conditional Orders|Conditional Orders :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Conditional Orders|Draw vancomycin peak 1 hour after completion of infusion']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Conditional Orders|Random trough: draw 10 to 12 hours after first dose']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Conditional Orders|Vancomycin peak and trough on third dose']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Conditional Orders|Draw gentamicin peak 30 minutes after completion of infusion']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Conditional Orders|Draw trough 30 minutes before peak and trough dose']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.laboratory },
  ['Consents|Obtain sitter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Consent for :_ procedure on chart']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Consent to operate on chart']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Consent to release information, signed at admission, on chart']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Consent to treat, signed at admission, on chart']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Obtain signature: consent for :_ procedure']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Obtain signature: consent for :_ surgery']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Radiology: consent to x-ray, signed at admission, on chart']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Verify informed consent']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consents|Consents :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consultations :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult, occupational therapy']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult, physical therapy']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult lactation counseling as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult :_ for :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult behavioral management for evaluation']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult, case management']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult, dietary']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult, pastoral care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult, social services']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult social services for home care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult social services for home health aide']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult for psychological testing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Consultations|Consult enterostomal therapy (ET) nurse for ostomy teaching']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Consultations|Consult enterostomal therapy (ET) nurse']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Drains/Tubes|Irrigate tube every shift and as needed with :_ mL of water']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Jackson-Pratt drain(s) to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Measure, empty, and recharge drain(s) every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Hemovac(s) to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Percutaneous endoscopic gastrostomy (PEG) tube care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Discontinue drainage system and monitor site/drainage on dressing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Drain care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Drain to suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Chest tube, measure/record every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Rectal tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Check water seal chamber every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Check nasogastric tube placement/patency every shift and as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Clamp nasogastric tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Gastrostomy tube care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Insert nasogastric tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Release nasogastric clamp every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Drains/Tubes :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Nasogastric tube to continuous low suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Nasogastric tube to intermittent high suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Drains/Tubes|Nasogastric tube to intermittent low suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Dressings/Wounds|Incision line care per protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Irrigate wound with normal saline solution']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Keep dressing clean and dry']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Monitor wound drainage']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Remove dressing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Use cloth tape only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Use paper tape only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Wet-to-dry dressing using normal saline']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Wound care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Wound care consult :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Dressings/Wounds|Dressings/Wounds :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Apply dressing to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Apply Steri-Strips to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Cover with 4 x 4s and gauze wrap']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Dakin solution']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Dressing change every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Dressing change every shift']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Dressing change order :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Dressing location(s) :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Remove staples/sutures per protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Semipermeable membrane dressing to skin tear']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Staple removal tray to room']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Dressings/Wounds|Suture removal tray to room']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Dressings/Wounds|Nursing change wound vacuum']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Pack with normal saline gauze']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Pin care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Dressings/Wounds|Reinforce dressing; healthcare provider to change first dressing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Physician change wound vacuum']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Whirlpool every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Dressings/Wounds|Abdominal binder']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Dressings/Wounds|Elastic wrap to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Education|Newborn bathing education']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Education|Newborn care education']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Education|Newborn swaddling']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Education|Bottle-feeding education']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Education|Breastfeeding education']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Education|Cord care education']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Education|Tobacco cessation education']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Ostomy care education']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Education :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Anger management education']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Medication education']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Stress reduction education']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Diabetes education']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Discharge instructions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Patient education :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Education|Provide patient education materials']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Elimination|Elimination :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Elimination|Bowel program']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Elimination|Catheter care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Catheterization for specific collection']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Discontinue Foley, monitor urine output']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Discontinue indwelling catheter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Encourage frequent voiding']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Enema: soapsuds enema until clear']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Elimination|Enema :_ (type)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Elimination|Maintain Foley catheter to gravity']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Measure post-void residual']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|No rectal suppositories']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Elimination|Save all urine']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Elimination|Straight catherization if unable to void; measure and record']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions
  },
  ['Elimination|Straight catherization by nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Straight catheterization by self']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Strain urine']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Elimination|Insert Foley catheter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Irrigate Foley catheter as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Use condom catheter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Post-transurethral resection of prostate (TURP), monitor drainage for evidence of bleeding']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Elimination|Rectal stimulation']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Elimination|Save all stools']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Elimination|Leg bag during the day']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Urostomy to gravity']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Elimination|Diapers/briefs']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Elimination|Have patient void on call to operating room']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Elimination|Condom catheter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Change pouch']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Call healthcare provider if unable to void or urine output is less than 30 mL/hour']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Elimination|Ostomy care by nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ostomies.ostomies },
  ['Elimination|Ostomy: check, empty and record every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ostomies.ostomies },
  ['Elimination|Ostomy: clean or replace bag every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ostomies.ostomies },
  ['Elimination|Disimpaction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Elimination|Commode at bedside']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Elimination|Continuous bladder irrigation, sterile water']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Insert and maintain indwelling catheter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Suprapubic catheter care every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Elimination|Apply and maintain external catheter']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Bladder pressure']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Bladder program']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Bladder scan after voiding']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions },
  ['Elimination|Bladder training: clamp Foley and release every :_ hour(s)']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.genitourinaryInterventions
  },
  ['Gastrointestinal|Hemorrhoid treatment every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Gastrointestinal|Stoma care every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Insert and maintain gastric tube to suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Insert and maintain rectal tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Jejunostomy tube to feeding']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Reposition gastric feeding tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Swallowing assessment by nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Gastrointestinal|Gastric lavage']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Gastrointestinal|Gastric tube discontinuation']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Gastrointestinal|Gomco suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Assist with feeding']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Gastrointestinal|Clamp feeding tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Gastrointestinal|Clamp nasogastric tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Gastrointestinal|Encourage fluids']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Gastrointestinal|Fluid restriction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Gastrointestinal|Hemoccult stools x 3']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Gastrointestinal|Measure abdominal girth']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Gastrointestinal|Replace feeding tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Sitz bath every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Gastrointestinal|Tube feeding :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.drainsTubes.drainsTubes },
  ['Gastrointestinal|Gastrointestinal :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Gastrointestinal|Check nasogastric tube for patency']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.gastrointestinalInterventions },
  ['Hydration|Hydration :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|IV']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Nothing by mouth (NPO)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Oral fluids as desired']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Progress to oral fluids as tolerated']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Push oral fluids']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Sips and chips only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Fluids via nasogastric tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Thickened liquids only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Fluids via gastrostomy tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hydration|Fluids via percutaneous endoscopic gastrostomy (PEG) tube']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Hygiene|Mouth care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Sitz bath']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Perineal care every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Perineal care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Catheter care every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Frequent mouth care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Total nursing care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Bath, self']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Bed bath, set-up only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Bed bath, total care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|May shampoo']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Needs nursing assistance']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Shower, self']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Hygiene|Hygiene :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Intake and Output|Intake and Output :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.intakeOutput },
  ['Intake and Output|Intake and output every :_ hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.intakeOutput },
  ['Intake and Output|24-hour urine collection']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Intake and Output|Intake and output every 4 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.intakeOutput },
  ['Intake and Output|Intake and output every shift']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.intakeOutput },
  ['Intake and Output|Strict intake and output']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.intakeOutput },
  ['Intake and Output|Notify healthcare provider if urine output is less than 30 mL/hour']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Intake and Output|Fluid restriction; see Nutrition order for amounts']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Isolation|Isolation: Universal']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment },
  ['Isolation|Isolation: Contact']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment },
  ['Isolation|Isolation: Droplet']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment },
  ['Isolation|Isolation: Airborne']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment },
  ['Isolation|Isolation :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.specialPrecautionsIsolationAssessment },
  ['IV|IV :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Blood draw with IV start']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Discontinue IV']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|IV dressing change due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|IV site change due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|IV tubing change due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|No IV']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Saline lock, IV']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Start and maintain IV line']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Convert IV to heparin lock']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Heparin lock, intermittent medications, site :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Peripherally inserted central catheter (PICC) line :_ arm, measure daily and record']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Peripherally inserted central catheter (PICC) line dressing change due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Port-a-cath, change of needle per protocol, due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Maintain vascular access to device site']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|IV line for CT scan']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Hickman, dressing change due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Arteriovenous shunt to :_ (check thrill and bruit)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['IV|Heparin flush every shift per protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Patient-controlled analgesia (PCA) pump in use']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Triple lumen catheter, dressing change due :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['IV|Triple lumen catheter, flush every shift per protocol and as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.ivs.ivs },
  ['Miscellaneous|Group therapy']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Obtain healthcare directive']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Discharge']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Miscellaneous|Discharge to :_']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Miscellaneous|Family conference']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Home evaluation']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Miscellaneous|Notify healthcare provider of any acute change']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Obtain medical records']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Off-unit privileges']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|On call for :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Miscellaneous|Physician communication order :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Miscellaneous|Specialty bed :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Transfer to :_']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Miscellaneous|Nursing :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Miscellaneous|Miscellaneous :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Neurological|Neurological :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Neurological|Seizure precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.neurologicalAssessment },
  ['Neurological|Check pupils']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.neurologicalAssessment },
  ['Neurological|Check reflexes']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.neurologicalAssessment },
  ['Neurological|Neurological checks every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.neurologicalAssessment },
  ['Neurological|Assess sedation']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.neurologicalAssessment },
  ['Neurological|Encourage :_-sided attention']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Neurological|Foot drop boot']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Neurological|Position to maintain function']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Neurological|Neurological checks']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Neurological|Lumbar puncture tray to bedside']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Neurological|Intracranial pressure monitoring']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.neurologicalAssessment },
  ['Neurological|C-spine precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Neurological|Spinal precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.neurologicalInterventions },
  ['Nutrition|Total parenteral nutrition (TPN), check orders for components, change bag every 24 hours']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.basicNursingCare.nutritionHydration
  },
  ['Nutrition|Ice chips only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Feed every :_ hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Feeds self']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Feeds self, needs set-up only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Needs feeding assistance']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Total feeding assistance by nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Nothing by mouth (NPO)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Clear liquids only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Nutrition|Nutrition :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.nutritionHydration },
  ['Obstetric|Fundal examination every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Obstetric :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Fetal heart rate every :_ minute(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Fetal monitoring, continuous']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Fetal monitoring, intermittent every :_ minute(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Baseline fetal monitoring for 20 minutes']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Scalp electrode as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Scalp electrode']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Cervical examination every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Obstetric|Amnioinfusion']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.obstetricAssessment },
  ['Obstetric|Amniotomy']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.obstetricAssessment },
  ['Obstetric|500 mL bolus for deceleration of fetal heart rate']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Obstetric|500 mL bolus before spinal epidural']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Obstetric|Breast pump as needed']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Obstetric|Breastfeed on demand']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Obstetric|Pump and dump breast milk']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Obstetric|Rooming in if mother desires']: { section: Section.ACTION_LIST, linkTo: '' },
  ['Obstetric|Postpartum depression screening']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.edinburghPostnatalDepressionScale },
  ['Obstetric|Fetal pulse oximetry']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.laborProgress },
  ['Orthopedic|Physical therapy instrucions to nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Orthopedic :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Range of motion, active']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Range of motion, passive']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Partial weight bearing, :_ side']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Splint on :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Monitor signs and symptoms of circulatory impairment']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions
  },
  ['Orthopedic|Brace']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Cast care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Cast on :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Abdominal binder']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Sling']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Pin care every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Traction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Trapeze']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Use turning wedge']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Continuous passive motion (CPM) in use every :_ hour(s) while awake']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions
  },
  ['Orthopedic|Continuous passive motion (CPM), set at :_ degrees']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions
  },
  ['Orthopedic|Elastic wrap']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Elevate :_ above heart']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Full weight bearing :_ side']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Hip precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Immobilizer when out of bed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Knee immobilizer']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Log roll only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Maintain sling to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Abduction pillow when in bed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Ankle boot']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Orthopedic|Monitor traction weights; keep weights hanging freely']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions
  },
  ['Orthopedic|No weight bearing, :_ side']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.musculoskeletalInterventions },
  ['Pain|Patient-controlled analgesia (PCA) in use; see MAR']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.pcaFlowsheet },
  ['Pain|Discontinue patient-controlled analgesia (PCA)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.mar },
  ['Point of Care Testing|Notify admitting provider for glucose greater than :_']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Point of Care Testing|Notify admitting provider for glucose less than :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Point of Care Testing|Notify admitting provider for glucose less than 80 with symptoms']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Point of Care Testing|Point of care glucose 0700, 1100, 1600, 2100']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.bloodGlucose },
  ['Point of Care Testing|Point of care glucose every :_ hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.bloodGlucose },
  ['Point of Care Testing|Point of care glucose twice daily']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.bloodGlucose },
  ['Point of Care Testing|Point of care purified protein derivative (PPD) intradermal']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.pharmacy },
  ['Point of Care Testing|Point of care tuberculosis (TB) skin test bacilli Calmette-Guérin (BCG) live percutaneous']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.orders.pharmacy
  },
  ['Point of Care Testing|Point of care urine dipstick']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Point of Care Testing|Point of care group A Streptococci (rapid)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Point of Care Testing|Point of care Hemoccult']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Point of Care Testing|Point of Care Testing :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Positioning|Positioning :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Elevate head of bed :_ degrees']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ["Positioning|High Fowler's"]: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Reverse Trendelenburg']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Self-turns']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ["Positioning|Semi-Fowler's"]: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Supine only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Trendelenburg']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Turn patient every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Use turning wedge']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Trapeze']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Log roll']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Prone only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Abduction pillow']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Positioning|Elevate extremity :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Pre/Postop|Wire cutters to room']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Pre/Postop|Hibiclens shower/bath']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Pre/Postop|Monitor blood pressure and pedal pulses post heart catheterization']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes
  },
  ['Pre/Postop|Monitor groin area post heart catheterization']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Pre/Postop|Bowel prep for procedure in AM']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Pre/Postop|Simple prep enema']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Pre/Postop|Apply heat every :_ hour(s) for :_ minutes']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Pre/Postop|Apply ice pack every :_ hour(s) for :_ minutes']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Pre/Postop|Nothing by mouth (NPO) after midnight for surgery']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.nutrition },
  ['Pre/Postop|Staple removal kit to room']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Pre/Postop|Suture removal kit to room']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Pre/Postop|Warming blanket']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Pre/Postop|Remove staples/sutures per protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.wounds.wounds },
  ['Pre/Postop|Skin prep to site :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Pre/Postop|Cooling blanket']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Pre/Postop|Enemas until clear']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.elimination },
  ['Pre/Postop|Nothing by mouth (NPO) after midnight for procedure']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.orders.nutrition },
  ['Pre/Postop|On call medication ordered; see MAR']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.mar },
  ['Pre/Postop|Recent labs/reports on chart in AM']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Pre/Postop|Pre/Postop :_']: { section: Section.GENERAL_INFORMATION, linkTo: '' },
  ['Respiratory|Respiratory :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Aspiration precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Elevate head of bed :_ degrees']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Nebulizer treatment every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Pulse oximetry every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Respiratory|Read tuberculosis (TB) skin test on :_ (date)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Respiratory|Room air']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Spot check pulse oximetry every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Respiratory|Turning, coughing, deep breathing exercises every :_ hour(s)']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions
  },
  ['Respiratory|Oxygen :_ L/min by :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Oxygen :_ % by :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Apnea monitor']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Assess gag reflex']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.respiratoryAssessment },
  ['Respiratory|Chest physiotherapy per nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Postural drainage by nursing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Encourage turning, coughing, deep breathing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Suction as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Oral suction every :_ hour(s) and as needed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Chest tube to water seal']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Continuous pulse oximetry']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Incentive spirometry while awake, every :_ hour(s)']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions
  },
  ['Respiratory|Inhaler instruction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientTeaching.patientTeaching },
  ['Respiratory|Chest tube to continuous suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Respiratory|Keep tracheostomy tray and supplies at bedside']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Nasopharyngeal suction every :_ hour(s) and as needed']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions
  },
  ['Respiratory|Tracheostomy care per hospital protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Tracheostomy cuff, deflate']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Endotracheal suction every :_ hours and as needed']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions
  },
  ['Respiratory|Endotracheal tube suction']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Incentive spirometer at bedside']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Respiratory|Chest tube set-up']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.respiratoryInterventions },
  ['Safety|Radiation safety precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Keep bed rails up at all times']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Cloth wrist restraints']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.restraints },
  ['Safety|Safety floor mats around bed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Posey vest']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.restraints },
  ['Safety|Restraints, check every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.restraints },
  ['Safety|Restraints, nonbehavioral']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.restraints },
  ['Safety|Restraints, release']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.restraints },
  ['Safety|Close observation for safety']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|One-to-one observation for safety']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Safety checks every 15 Minutes']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Fall prevention protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ["Safety|Relocate patient closer to nurses' station"]: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Latex precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.patientData.allergies },
  ['Safety|Restrict visitors']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Restroom restrictions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.activity },
  ['Safety|Seizure precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Suicide precautions']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Bed in low position']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Bite block at head of bed']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Safety|Safety :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.safety },
  ['Skin Care|Skin Care :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Skin Care|Barrier cream']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Skin assessment']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemAssessment.integumentaryAssessment },
  ['Skin Care|Specialty mattress :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Use cloth tape only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Use paper tape only']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Foot care']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Foot soaks']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Perineal cleanser']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Semipermeable membrane dressing to skin tear']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Transparent dressing to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Transparent dressing with absorbent pad to decubitus wound']: {
    section: Section.ACTION_LIST,
    linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions
  },
  ['Skin Care|Heel protectors']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Hydrocolloid dressing to :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.systemNursingInterventions.integumenatryInterventions },
  ['Skin Care|Whirlpool every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Specimen Collection|Obtain culture material for wound at :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Specimen Collection|Obtain sputum for :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Specimen Collection|Obtain stool for :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Specimen Collection|Obtain stool for occult blood x :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Specimen Collection|Obtain urine for :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Specimen Collection|Specimen Collection :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.miscellaneousNursingNotes },
  ['Vital Signs|Vital Signs :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Blood pressure check on :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Blood pressure, lying, sitting, and standing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Blood pressure, sitting and standing']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.heightWeight },
  ['Vital Signs|Daily weights']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.heightWeight },
  ['Vital Signs|Height and weight']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.heightWeight },
  ['Vital Signs|Orthostatic vital signs']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Postoperative vital signs per protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Pulse checks every :_']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Pulse oximetry spot checks']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Routine vital signs']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every :_ hour(s)']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 12 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 15 minutes x 4']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 2 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 30 minutes x 4']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 4 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 6 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every 8 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs every hour x 4']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Vital signs only while awake']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.vitalSigns },
  ['Vital Signs|Weigh']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.heightWeight },
  ['Vital Signs|Weigh on admission']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.vitalSigns.heightWeight },
  ['Vital Signs|Hypothermia protocol']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.basicNursingCare.hygieneDressingsComfort },
  ['Vital Signs|Neurological checks every 2 hours']: { section: Section.ACTION_LIST, linkTo: RoutePath.student.specialCharts.neurologicalChecks }
};

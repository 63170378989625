import { LaboratoryTestResult } from 'models/api-response';
import { ChartMetaFormField, ChartMetadata } from 'models/ui';
import { LastElement } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { Box, ErrorFormField, Sidebar } from 'components/common';
import { ELSButton } from 'components/common/els';
import { FormField } from './LaboratoryTest';
import LaboratoryTestResultCard from './LaboratoryTestResultCard';

export interface EditLaboratoryTestSidebarViewProps {
  open: boolean;
  formSubmittedCount: number;
  [FormField.LAB_TEST]: string;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  labResults: LaboratoryTestResult[];
  chartMetadata: ChartMetadata;
  onSaveClick: Function;
  onCloseClick: Function;
  onLabTestResultChange: Function;
  sidebarProps: Function;
}
const EditLaboratoryTestSidebarView = (props: EditLaboratoryTestSidebarViewProps) => {
  const { open, formSubmittedCount, chartMetaFormFields, labResults, onSaveClick, onCloseClick, onLabTestResultChange, sidebarProps, chartMetadata } = props;
  return (
    <Sidebar
      open={open}
      onClose={() => chartHelper.handleCloseSidebar({ sidebarProps, onCloseClick })}
      lastEnabledElement={LastElement.ENABLED_SAVE_BUTTON}
      sidebarProps={sidebarProps}
    >
      <Box mt3>
        <h2>Edit Laboratory Test</h2>
        <h3 className="u-els-margin-top">{props[FormField.LAB_TEST]}</h3>
      </Box>
      <div className="sidebar__divider" />
      <ErrorFormField formField={chartMetaFormFields.get(FormField.DATE_OF_TEST)} formSubmittedCount={formSubmittedCount} />
      <Box mt2 className="u-els-width-2o3">
        <ErrorFormField formField={chartMetaFormFields.get(FormField.DEFINITION_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
      </Box>
      <Box mt2 className="u-els-width-2o3">
        <ErrorFormField formField={chartMetaFormFields.get(FormField.SIGNIFICANCE)} formSubmittedCount={formSubmittedCount} />
      </Box>

      <>
        {labResults?.length > 0 && (
          <div>
            <h2 className="u-els-margin-top-4x">Laboratory Test Results</h2>
            {labResults.map((item) => (
              <LaboratoryTestResultCard key={item.id} labResult={item} onLabTestResultChange={onLabTestResultChange} existedChartMetadata={chartMetadata} />
            ))}
          </div>
        )}
      </>

      <Box mt3 mb>
        <ELSButton className="sng-button" text="Save" onClick={onSaveClick} />
      </Box>
    </Sidebar>
  );
};

EditLaboratoryTestSidebarView.displayName = 'EditLaboratoryTestSidebarView';
export default EditLaboratoryTestSidebarView;

import { produce } from 'immer';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { CarePlanRecord, ChartFragment, InterventionItem } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps } from 'models/ui';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField, InterventionField } from './constants';
import { buildFormFields } from './CarePlan';
import CarePlanSidebarView, { CarePlanSidebarViewProps } from './CarePlanSidebarView';

export interface CarePlanSidebarProps extends ChartComponentProps {
  selectedRecord: CarePlanRecord;
  onClose: Function;
  onUpdate: Function;
}

interface CarePlanSidebarState {
  chartingAt: string;
  fullName: string;
  outcomeEvaluationStatus?: string;
  planResolved: boolean;
  isDisplayDocument: boolean;
  interventionItems?: InterventionItem[];
  focusedInterventionFieldId: string;
}

class CarePlanSidebar extends Component<CarePlanSidebarProps, CarePlanSidebarState> {
  static displayName = 'CarePlanSidebar';

  constructor(props) {
    super(props);
    this.state = {
      chartingAt: '',
      fullName: '',
      outcomeEvaluationStatus: '',
      planResolved: false,
      isDisplayDocument: false,
      interventionItems: [],
      focusedInterventionFieldId: null
    };
  }

  componentDidMount() {
    this.props.initState(buildFormFields());
    this.setDefaultField();
  }

  componentDidUpdate(prevProps: Readonly<CarePlanSidebarProps>) {
    if (this.props.selectedRecord && prevProps.selectedRecord !== this.props.selectedRecord) {
      this.setDefaultField();
      this.props.initState(buildFormFields(this.props.selectedRecord));
    }
  }

  setDefaultField = () => {
    this.setState({
      chartingAt: this.props.selectedRecord?.chartingAt,
      fullName: this.props.selectedRecord?.fullName,
      outcomeEvaluationStatus: this.props.selectedRecord?.outcome.status,
      interventionItems: this.props.selectedRecord?.interventionItems,
      planResolved: false
    });
  };

  hasAnyFieldValueChanged = (item: InterventionItem): boolean =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') return !isEmpty(value);
      return value.some((action: string) => !isEmpty(action));
    });

  handleSaveDataClick = () => {
    const { selectedRecord, onUpdate, handleSaveClick, formFieldMap } = this.props;
    const { outcomeEvaluationStatus, interventionItems } = this.state;
    const { buildPatientRecord } = chartService;
    const validInterventionItems = interventionItems.filter(this.hasAnyFieldValueChanged);
    const updatedData = {
      [FormField.INTERPROFESSIONAL_PROBLEM]: buildPatientRecord(formFieldMap, FormField.INTERPROFESSIONAL_PROBLEM),
      [FormField.NURSING_PROBLEM]: buildPatientRecord(formFieldMap, FormField.NURSING_PROBLEM),
      [FormField.EXPECTED_OUTCOME]: buildPatientRecord(formFieldMap, FormField.EXPECTED_OUTCOME),
      [FormField.INTERVENTION_PLAN]: buildPatientRecord(formFieldMap, FormField.INTERVENTION_PLAN),
      [FormField.OBJECTIVE_DATA]: buildPatientRecord(formFieldMap, FormField.OBJECTIVE_DATA),
      [FormField.SUBJECTIVE_DATA]: buildPatientRecord(formFieldMap, FormField.SUBJECTIVE_DATA),
      [FormField.INTERVENTIONS]: validInterventionItems,
      [FormField.EVAL_DATE]: buildPatientRecord(formFieldMap, FormField.EVAL_DATE),
      [FormField.OUTCOME_EVALUATION_STATUS]: outcomeEvaluationStatus,
      [FormField.PLAN_RESOLVED]: this.state.planResolved
    };
    const updatedFragment: ChartFragment = produce(selectedRecord, (draft) => {
      draft.chartData = { ...draft.chartData, ...updatedData };
      draft.updatedAt = moment().toISOString();
    });

    handleSaveClick([updatedFragment], { afterSave: onUpdate });
  };

  resetFocusedInterventionFieldId = () => {
    this.setState({ focusedInterventionFieldId: null });
  };

  handleFormFieldChange = (fieldId: string, event: React.ChangeEvent<HTMLInputElement>, interventionItemIndex?: number, actionIndex?: number) => {
    const { value } = event.currentTarget;

    switch (fieldId) {
      case InterventionField.NAME:
      case InterventionField.STATUS:
      case InterventionField.RATIONALE: {
        this.setState({ focusedInterventionFieldId: `${fieldId}-${interventionItemIndex}` });
        this.setState(
          produce((state) => {
            state.interventionItems[interventionItemIndex][fieldId] = value;
          })
        );
        break;
      }
      case InterventionField.ACTIONS: {
        this.setState({ focusedInterventionFieldId: `${fieldId}-${interventionItemIndex}-${actionIndex}` });
        this.setState(
          produce((state) => {
            state.interventionItems[interventionItemIndex][InterventionField.ACTIONS][actionIndex] = value;
          })
        );
        break;
      }
      case FormField.OUTCOME_EVALUATION_STATUS: {
        this.setState({ outcomeEvaluationStatus: value });
        break;
      }
      case FormField.PLAN_RESOLVED: {
        this.setState((state) => ({ planResolved: !state.planResolved }));
        break;
      }
      default:
        break;
    }
  };

  handleDisplayDocument = () => {
    this.setState((state) => ({ isDisplayDocument: !state.isDisplayDocument }));
  };

  handleAddInterventionItem = () => {
    this.setState(
      produce((state) => {
        state.interventionItems.push({ name: '', rationale: '', status: '', actions: [''] });
      })
    );
  };

  handleAddInterventionAction = (index: number) => {
    this.setState(
      produce((state) => {
        state.interventionItems[index][InterventionField.ACTIONS].push('');
      })
    );
  };

  handleDiscard = () => {
    this.setDefaultField();
    this.props.handleDiscardClick(undefined, buildFormFields(this.props.selectedRecord), { selectorToScroll: '.sidebar__container' });
  };

  enableSave = (): boolean => {
    const { hasUnsavedChanges, selectedRecord } = this.props;
    const { outcomeEvaluationStatus, planResolved, interventionItems } = this.state;
    const isOutcomeEvaluationStatusChanged = outcomeEvaluationStatus !== selectedRecord?.outcome.status;
    const isInterventionItemsChanged =
      selectedRecord?.interventionItems.length > 0 ? !isEqual(interventionItems, selectedRecord?.interventionItems) : interventionItems?.some(this.hasAnyFieldValueChanged);
    return hasUnsavedChanges || isOutcomeEvaluationStatusChanged || isInterventionItemsChanged || planResolved;
  };

  render() {
    const { formFieldMap, formSubmittedCount, selectedRecord, onClose, sidebarProps } = this.props;
    const { focusedInterventionFieldId, outcomeEvaluationStatus, chartingAt, fullName, planResolved, isDisplayDocument, interventionItems } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableSaveButton: this.enableSave(),
      onSaveClick: this.handleSaveDataClick,
      onCancelClick: this.handleDiscard
    };
    const viewProps: CarePlanSidebarViewProps = {
      openSidebar: !!this.props.selectedRecord,
      chartingAt,
      sidebarName: selectedRecord?.chartData[FormField.NURSING_PROBLEM]?.content,
      fullName,
      planResolved,
      isDisplayDocument,
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      outcomeEvaluationStatus,
      interventionItems,
      resetFocusedInterventionFieldId: this.resetFocusedInterventionFieldId,
      onFormFieldChange: this.handleFormFieldChange,
      onDisplayDocument: this.handleDisplayDocument,
      onAddInterventionItem: this.handleAddInterventionItem,
      onAddInterventionAction: this.handleAddInterventionAction,
      onCloseClick: onClose,
      sidebarProps,
      focusedInterventionFieldId
    };
    return <CarePlanSidebarView {...viewProps} />;
  }
}

export { CarePlanSidebar as BaseCarePlanSidebar };
export default withChartLogic(CarePlanSidebar);

import { delay } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, ChartFragment, PsychiatricHistoryFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { getFragmentContentIds, getFragmentValue } from 'helpers/chart.helper';
import { chartService, navigationService } from 'services';
import { buildPatientRecord } from 'services/chart.service';
import { appSelectors } from 'redux/ducks/app';
import { withChartLogic } from 'components/common';
import { PsychiatricHistoryView, PsychiatricHistoryViewProps } from './PsychiatricHistoryView';
import { FormField, FormFieldLabel, SectionTitle } from './constant';

export interface PsychiatricHistoryProps extends ChartComponentProps {
  assessment: AssessmentRS;
}

export interface PsychiatricHistoryState {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: PsychiatricHistoryFragment;
}

class PsychiatricHistory extends Component<PsychiatricHistoryProps, PsychiatricHistoryState> {
  static displayName = 'PsychiatricHistory';

  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      statusFragment: null,
      selectedRecord: null
    };
  }

  componentDidMount() {
    appHelper.useLoader(this.props.loadChartData().then(this.bindChartHistory), {
      errorMessage: 'can not load chart fragment'
    });
    this.props.initState(this.buildFormFields());
  }

  buildFormFields = (fragment?: PsychiatricHistoryFragment): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    const textBoxes = [
      {
        name: FormField.CHIEF_INFORMANT,
        label: FormFieldLabel.CHIEF_INFORMANT,
        value: getFragmentValue(fragment, SectionTitle.PATIENT_INFORMATION, FormField.CHIEF_INFORMANT)
      },
      {
        name: FormField.CHIEF_COMPLAINT,
        label: FormFieldLabel.CHIEF_COMPLAINT,
        value: getFragmentValue(fragment, SectionTitle.PATIENT_INFORMATION, FormField.CHIEF_COMPLAINT)
      },
      {
        name: FormField.ALLERGIES,
        label: FormFieldLabel.ALLERGIES,
        value: getFragmentValue(fragment, SectionTitle.PATIENT_INFORMATION, FormField.ALLERGIES)
      }
    ];

    const textAreas = [
      {
        name: FormField.HISTORY_CURRENT_PROBLEM,
        label: FormFieldLabel.HISTORY_CURRENT_PROBLEM,
        value: getFragmentValue(fragment, SectionTitle.PATIENT_INFORMATION, FormField.HISTORY_CURRENT_PROBLEM)
      },
      {
        name: FormField.PAST_PSYCHIATRIC_HISTORY,
        label: FormFieldLabel.PAST_PSYCHIATRIC_HISTORY,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.PAST_PSYCHIATRIC_HISTORY)
      },
      {
        name: FormField.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS,
        label: FormFieldLabel.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS)
      },
      {
        name: FormField.SUICIDE_HISTORY,
        label: FormFieldLabel.SUICIDE_HISTORY,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.SUICIDE_HISTORY)
      },
      {
        name: FormField.OUTPATIENT_TREATMENT,
        label: FormFieldLabel.OUTPATIENT_TREATMENT,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.OUTPATIENT_TREATMENT)
      },
      {
        name: FormField.ALCOHOL_USE,
        label: FormFieldLabel.ALCOHOL_USE,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.ALCOHOL_USE)
      },
      {
        name: FormField.SUBSTANCE_USE,
        label: FormFieldLabel.SUBSTANCE_USE,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.SUBSTANCE_USE)
      },
      {
        name: FormField.ELECTROCONVULSIVE_THERAPY,
        label: FormFieldLabel.ELECTROCONVULSIVE_THERAPY,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.ELECTROCONVULSIVE_THERAPY)
      },
      {
        name: FormField.FAMILY_HISTORY,
        label: FormFieldLabel.FAMILY_HISTORY,
        value: getFragmentValue(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.FAMILY_HISTORY)
      }
    ];

    textBoxes.forEach(({ name, label, value }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.TEXT_BOX,
          label,
          value
        })
      )
    );

    textAreas.forEach(({ name, label, value }) =>
      dataMap.set(
        name,
        createFormField({
          name,
          type: FormFieldInputType.TEXT_AREA,
          label,
          value
        })
      )
    );

    dataMap.set(
      FormField.UNABLE_ASSESS,
      createFormField({
        name: FormField.UNABLE_ASSESS,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: getFragmentContentIds(fragment, SectionTitle.PSYCHIATRIC_HISTORY, FormField.UNABLE_ASSESS)
      })
    );
    return dataMap;
  };

  bindChartHistory = ({ data: fragments }) => {
    const selectedRecord = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);
    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.PSYCHIATRIC_HISTORY, NAV_ID.HISTORY_PHYSICAL);
    this.setState({
      selectedRecord,
      statusFragment,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(this.buildFormFields(selectedRecord));
  };

  buildFragment = () => {
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'History and Physical',
      fragmentTitle: SectionTitle.PSYCHIATRIC_HISTORY,
      records: [
        {
          sectionTitle: SectionTitle.PATIENT_INFORMATION,
          records: [
            buildPatientRecord(formFieldMap, FormField.CHIEF_INFORMANT),
            buildPatientRecord(formFieldMap, FormField.CHIEF_COMPLAINT),
            buildPatientRecord(formFieldMap, FormField.HISTORY_CURRENT_PROBLEM),
            buildPatientRecord(formFieldMap, FormField.ALLERGIES)
          ]
        },
        {
          sectionTitle: SectionTitle.PSYCHIATRIC_HISTORY,
          records: [
            buildPatientRecord(formFieldMap, FormField.UNABLE_ASSESS),
            buildPatientRecord(formFieldMap, FormField.PAST_PSYCHIATRIC_HISTORY),
            buildPatientRecord(formFieldMap, FormField.PREVIOUS_PSYCHIATRIC_HOSPITALIZATIONS),
            buildPatientRecord(formFieldMap, FormField.SUICIDE_HISTORY),
            buildPatientRecord(formFieldMap, FormField.OUTPATIENT_TREATMENT),
            buildPatientRecord(formFieldMap, FormField.ALCOHOL_USE),
            buildPatientRecord(formFieldMap, FormField.SUBSTANCE_USE),
            buildPatientRecord(formFieldMap, FormField.ELECTROCONVULSIVE_THERAPY),
            buildPatientRecord(formFieldMap, FormField.FAMILY_HISTORY)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.selectedRecord?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildFormFields(),
      afterSave: this.afterSave
    });
  };

  afterSave = () =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.state.isLocked,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };
    const viewProps: PsychiatricHistoryViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartActionsProps
    };
    return <PsychiatricHistoryView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state)
});

const enhancers = [connect(mapStateToProps), withChartLogic];
export { PsychiatricHistory as BasePsychiatricHistory };
export default compose(...enhancers)(PsychiatricHistory);

import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps } from 'models/ui';
import { ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import AllergyInformationHistory, { AllergyInformationChartHistory } from './AllergyInformationHistory';
import AllergyInformationReadOnly from './AllergyInformationReadOnly';

interface AllergyInformationViewProps extends ChartViewProps {
  isLocked: boolean;
  isAuthor: boolean;
  statusFragment: ChartFragment;
  deleteHistory: Function;
  chartHistory: AllergyInformationChartHistory[];
  addAllergyActionProps: ChartActionsComponentProps;
}

const AllergyInformationView = (props: AllergyInformationViewProps) => {
  const { isLocked, isAuthor, chartHistory, statusFragment, formFieldMap, formSubmittedCount } = props;
  return (
    <div className="allergy-information">
      {isLocked ? (
        <AllergyInformationReadOnly chartFragments={chartHistory} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Allergy Information</h1>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
          <ErrorFormField formField={formFieldMap.get(FormField.NO_KNOWN_ALLERGIES)} formSubmittedCount={formSubmittedCount} />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o3" md="1o1">
              <h2 className="u-els-margin-bottom-2x">Chart Inputs</h2>
              <ErrorFormField formField={formFieldMap.get(FormField.ALLERGY_TYPE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN_MEDICATION)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ALLERGEN_MEDICATION_OTHER)} formSubmittedCount={formSubmittedCount} />
              <h4 className="u-els-margin-bottom">{SectionTitle.REACTIONS}</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.REACTIONS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.REACTIONS_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.SEVERITY)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.INFORMANT)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.INFORMANT_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.CONFIDENCE_LEVEL)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="2o3" md="1o1">
              <AllergyInformationHistory title="Chart History" chartHistory={props.chartHistory} deleteHistory={props.deleteHistory} />
            </ELSFlexItem>
          </ELSFlex>
          {!isAuthor && (
            <>
              <ChartPermission key="add-allergy">{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.addAllergyActionProps} />}</ChartPermission>
              <SectionDivider />
            </>
          )}
          <ChartPermission key="save-continue">{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

export default AllergyInformationView;

import { FormattedMessage } from 'react-intl';
import { ChartFragment } from 'models/api-response';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSTextBox } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { PatientChartLanguageKeys } from 'lang/patientCharting';
import { FormField } from './constants';
import PCAFlowsheetDocument from './PCAFlowsheetDocument';
import './pca.flowsheet.scss';

export interface PCAFlowsheetViewProps extends ChartViewProps {
  firstInitials: string;
  lastName: string;
  chartHistory: ChartFragment[];
  deleteHistory: Function;
}

const PCAFlowsheetView = (props: PCAFlowsheetViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount, firstInitials, lastName, chartHistory, deleteHistory } = props;
  return (
    <div className="pca-flowsheet">
      <h1>PCA Flowsheet</h1>
      <p>When PCA is in use, document at least every 2 hours.</p>
      <Box mt>
        <ELSFlex className="u-flex-gutter-3x" left wrap>
          <ELSFlexItem w="1o3" md="1o1" className="u-els-margin-top">
            <h2>Chart Inputs</h2>
            <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
            <h4 className="u-els-margin-top-2x u-els-margin-bottom">Order</h4>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.ORDER_MAINTENANCE_IV_RATE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.ORDER_MAINTENANCE_IV_ML)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>

            <ErrorFormField useDefaultMargin={false} formField={formFieldMap.get(FormField.ORDER_ANALGESIA)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                hideAsterisk
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formField={formFieldMap.get(FormField.ORDER_ANALGESIA_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>

            <ErrorFormField useDefaultMargin={false} formField={formFieldMap.get(FormField.ORDER_STARTING_DOSE_VOLUME)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                hideAsterisk
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formField={formFieldMap.get(FormField.ORDER_STARTING_DOSE_VOLUME_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>

            <ErrorFormField useDefaultMargin={false} formField={formFieldMap.get(FormField.ORDER_DELAY_INTERVAL)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                hideAsterisk
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formField={formFieldMap.get(FormField.ORDER_DELAY_INTERVAL_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>

            <ELSFlex left middle>
              <ErrorFormField formField={formFieldMap.get(FormField.ORDER_BASAL_RATE)} formSubmittedCount={formSubmittedCount} />
              <span className="u-els-margin-left-1o2">mL / hour</span>
            </ELSFlex>

            <ErrorFormField useDefaultMargin={false} formField={formFieldMap.get(FormField.ORDER_STARTING1_HOUR_LIMIT)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                hideAsterisk
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formField={formFieldMap.get(FormField.ORDER_STARTING1_HOUR_LIMIT_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>

            <ErrorFormField useDefaultMargin={false} formField={formFieldMap.get(FormField.ORDER_BOLUS_LOADING)} formSubmittedCount={formSubmittedCount} />
            <ELSFlex className="u-els-margin-bottom-2x" left middle>
              <ErrorFormField
                hideAsterisk
                className="u-els-padding-top u-els-padding-left-2x"
                useDefaultMargin={false}
                formField={formFieldMap.get(FormField.ORDER_BOLUS_LOADING_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlex>

            <ErrorFormField formField={formFieldMap.get(FormField.ORDER_UNRELIEVED_PAIN)} formSubmittedCount={formSubmittedCount} />

            <h4 className="u-els-margin-bottom">Monitoring</h4>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_PAIN_ASSESSMENT_SCORE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_RESPIRATORY_RATE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_OXYGEN_SATURATION)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>

            <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_OXYGEN_SATURATION_O2)} formSubmittedCount={formSubmittedCount} />

            <ELSFlex left wrap>
              <ELSFlexItem w="1o2" lg="1o1">
                <ErrorFormField
                  formField={formFieldMap.get(FormField.MONITORING_EXCESSIVE_SEDATION)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_NAUSEA)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_ITCHING)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" lg="1o1">
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_PERSISTENT_PAIN)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_VOMITING)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
                <ErrorFormField
                  formField={formFieldMap.get(FormField.MONITORING_PROVIDER_NOTIFIED_OF_PAIN)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
              </ELSFlexItem>
            </ELSFlex>

            <ErrorFormField formField={formFieldMap.get(FormField.MONITORING_BRIEF_NOTES)} formSubmittedCount={formSubmittedCount} />

            <h4 className="u-els-margin-bottom">
              <FormattedMessage id={PatientChartLanguageKeys.LABEL.STUDENT_INFO} />
            </h4>
            <ELSFlex left wrap>
              <ELSFlexItem className="u-els-margin-right u-els-margin-bottom">
                <div>First initial</div>
                <div className="pca-flowsheet__control--short">
                  <ELSTextBox value={firstInitials} isDisabled />
                </div>
              </ELSFlexItem>
              <ELSFlexItem className="u-els-width-1o1@tablet">
                <div>Last name</div>
                <div className="pca-flowsheet__control--medium">
                  <ELSTextBox value={lastName} isDisabled />
                </div>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="2o3" md="1o1" className="u-els-margin-top">
            <h2>Flowsheet Documentation</h2>
            <PCAFlowsheetDocument chartHistory={chartHistory} deleteHistory={deleteHistory} chartMetaFormFields={formFieldMap} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};

PCAFlowsheetView.displayName = 'PCAFlowsheetView';
export default PCAFlowsheetView;

import { History, Location } from 'history';
import { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { NavigationItem } from 'models/ui';
import { chartHelper } from 'helpers';
import { appActions } from 'redux/ducks/app';
import BreadcrumbView, { BreadcrumbViewProps } from './BreadcrumbView';

interface BreadcrumbProps {
  history: History;
  location: Location;
  items: NavigationItem[];
  setCurrentChartPathName: Function;
}

class Breadcrumb extends Component<BreadcrumbProps> {
  static displayName = 'Breadcrumb';
  static defaultProps = {
    location: {},
    history: {}
  };

  componentDidMount() {
    this.updateChartName();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.updateChartName();
    }
  }

  updateChartName = () => {
    const {
      items,
      location: { pathname }
    } = this.props;
    const breadcrumbsItems = chartHelper.buildBreadcrumbItems(pathname, items);
    const chartPathDescription = breadcrumbsItems.map((item) => item.text).join(' - ');
    this.props.setCurrentChartPathName(chartPathDescription);
  };

  render() {
    const {
      items,
      location: { pathname }
    } = this.props;
    const breadcrumbsItems = chartHelper.buildBreadcrumbItems(pathname, items);
    const viewProps: BreadcrumbViewProps = {
      items: breadcrumbsItems,
      currentPath: pathname
    };
    return <BreadcrumbView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentChartPathName: (description: string) => dispatch(appActions.setCurrentChartPathName(description))
});

export { Breadcrumb as BaseBreadcrumb };
export default compose(
  withProps(() => ({ location: useLocation(), history: useHistory() })),
  connect(null, mapDispatchToProps)
)(Breadcrumb);

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, ChartTable, ChartTableHeader, LungAuscultationLocation } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField } from './constants';
import { buildRecordsForEnAU, buildRecordsForEnUS, respiratoryAssessmentFormFieldBuilderItems } from './RespiratoryAssessment.helper';
import RespiratoryAssessmentAUView from './RespiratoryAssessmentAUView';
import RespiratoryAssessmentView, { RespiratoryAssessmentViewProps } from './RespiratoryAssessmentView';
import { Title } from '../shared/constants';

interface RespiratoryAssessmentState {
  resetAll: number;
  lungAuscultationLocationTableData: ChartTable;
}

class RespiratoryAssessment extends Component<ChartComponentProps, RespiratoryAssessmentState> {
  static displayName = 'RespiratoryAssessment';
  lungAuscultationLocationTableHeaders: ChartTableHeader[] = [
    { text: 'Location', field: 'location', sortable: true },
    { text: 'Sounds', field: 'sounds' },
    { text: 'Wheeze Description', field: 'wheezeDescription' },
    { text: 'Notes', field: 'notes' },
    { field: 'actionDelete' }
  ];
  lungAuscultationFormFields: ChartMetaFormField[] = [];

  constructor(props) {
    super(props);
    this.state = {
      resetAll: 0,
      lungAuscultationLocationTableData: {
        headers: this.lungAuscultationLocationTableHeaders,
        css: ['', '', '', 'column__notes', 'column__delete'],
        data: []
      }
    };
  }

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
    this.props.setCustomUnsavedChanges(() => this.props.hasUnsavedChanges || this.tableHasLocationData());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { locale, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    respiratoryAssessmentFormFieldBuilderItems(locale).forEach(({ name, type, label, isHidden }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, type, label: label && intl.formatMessage({ id: label }) }));
      }
    });

    this.lungAuscultationFormFields = [dataMap.get(FormField.LOCATION), dataMap.get(FormField.SOUNDS), dataMap.get(FormField.WHEEZE_DESCRIPTION), dataMap.get(FormField.LUNG_NOTE)];

    return dataMap;
  };

  resetForm = () =>
    this.setState((prevState) => ({
      lungAuscultationLocationTableData: { ...prevState.lungAuscultationLocationTableData, data: [] },
      resetAll: prevState.resetAll + 1
    }));

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.afterSave });
  };

  afterSave = () =>
    Promise.resolve()
      .then(() => {
        this.setState((prevState) => ({
          lungAuscultationLocationTableData: { ...prevState.lungAuscultationLocationTableData, data: [] }
        }));
      })
      .then(() => {
        if (this.props.backToSourceLocation) {
          this.props.backToSourceLocation();
        } else {
          this.props.navigateToSavedPatientCharting();
        }
      });

  buildFragment = () => {
    const { formFieldMap, locale, intl } = this.props;
    const CHART_NAME = intl.formatMessage({ id: LanguageKeys.RESPIRATORY_ASSESSMENT.RESPIRATORY_ASSESSMENT });
    const record = {
      chartTitle: Title.SYSTEM_ASSESSMENT,
      fragmentTitle: CHART_NAME,
      records: locale === Locales.EN_US ? buildRecordsForEnUS(CHART_NAME, formFieldMap, intl) : buildRecordsForEnAU(CHART_NAME, formFieldMap, intl)
    };
    // to appease Typescript, we need to specify which of the possible types `data` is here
    const lungAuscultationsTableData = this.state.lungAuscultationLocationTableData.data as LungAuscultationLocation[];
    // for each row in the lung auscultations table, we need to add a record
    lungAuscultationsTableData.forEach((lungAuscultation) => {
      record.records.push({
        sectionTitle: 'Lung Auscultation',
        records: lungAuscultation.records
      });
    });

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  handleAddLocation = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;

    // update table rows
    const allCurrentRows: LungAuscultationLocation[] = this.state.lungAuscultationLocationTableData.data as LungAuscultationLocation[];
    const newRow: LungAuscultationLocation = {
      active: true,
      location: formFieldMap.get(FormField.LOCATION).chartContent.find((item) => item.selected).name,
      sounds: formFieldMap.get(FormField.SOUNDS).chartContent.find((item) => item.value === formFieldMap.get(FormField.SOUNDS).value)?.label,
      wheezeDescription: formFieldMap.get(FormField.WHEEZE_DESCRIPTION).chartContent.find((item) => item.value === formFieldMap.get(FormField.WHEEZE_DESCRIPTION).value)?.label,
      notes: formFieldMap.get(FormField.LUNG_NOTE).value,
      records: [
        buildPatientRecord(formFieldMap, FormField.LOCATION),
        buildPatientRecord(formFieldMap, FormField.SOUNDS, 'Mark the breath sounds heard at this location'),
        buildPatientRecord(formFieldMap, FormField.WHEEZE_DESCRIPTION),
        buildPatientRecord(formFieldMap, FormField.LUNG_NOTE)
      ]
    };
    // replace the old location with the new location if it has already been added to the table
    const nonMatchingCurrentRows: LungAuscultationLocation[] = allCurrentRows.filter((row: LungAuscultationLocation) => row.location !== newRow.location);
    this.setState((previousState) => ({
      ...previousState,
      lungAuscultationLocationTableData: { ...previousState.lungAuscultationLocationTableData, data: [...nonMatchingCurrentRows, newRow] }
    }));

    // clear the input fields for lung auscultation
    this.handleClearLocations();
  };

  handleClearLocations = () => {
    // clearing the location title field magically resets all location details data
    this.props.resetSubsetOfFormFields([this.props.formFieldMap.get(FormField.LOCATION)]);
  };

  // determine if there is location data entered that has not been saved to the location table
  hasUnsavedLocationData = (): boolean => {
    const locationFieldNames = this.lungAuscultationFormFields.map((formField) => formField.name);
    const fieldsToWatch = [];

    // gather current form fields that we want to watch - location fields
    this.props.formFieldMap.forEach((formField) => {
      if (locationFieldNames.includes(formField.name)) fieldsToWatch.push(formField);
    });
    return this.props.hasChangesInTargetFields(fieldsToWatch);
  };

  // determine if there is data in the location table
  tableHasLocationData = (): boolean => this.state.lungAuscultationLocationTableData.data.length > 0;

  handleEnableAddLocationButton = (): boolean => {
    const locationHasBeenSelected = this.props.hasChangesInTargetFields([this.props.formFieldMap.get(FormField.LOCATION)]);
    const currentLocationDetailFields = [
      this.props.formFieldMap.get(FormField.SOUNDS),
      this.props.formFieldMap.get(FormField.WHEEZE_DESCRIPTION),
      this.props.formFieldMap.get(FormField.LUNG_NOTE)
    ];
    return locationHasBeenSelected && this.props.hasChangesInTargetFields(currentLocationDetailFields);
  };

  hasValidUnsavedChanges = (): boolean => {
    const namesOfFieldsToExclude = this.lungAuscultationFormFields.map((formField) => formField.name);
    const fieldsToWatch = [];

    // gather current form fields that we want to watch - all fields except location fields
    this.props.formFieldMap.forEach((formField) => {
      if (!namesOfFieldsToExclude.includes(formField.name)) fieldsToWatch.push(formField);
    });
    return this.props.hasChangesInTargetFields(fieldsToWatch) || this.tableHasLocationData();
  };

  getLungAuscultationLabel = (): string => {
    const locationHasBeenSelected = this.props.hasChangesInTargetFields([this.props.formFieldMap.get(FormField.LOCATION)]);
    return locationHasBeenSelected ? this.props.formFieldMap.get(FormField.LOCATION).chartContent.find((option) => option.selected).label : '';
  };

  handleDeleteLocation = (row) => {
    const newLocationList = this.state.lungAuscultationLocationTableData.data.filter((item: LungAuscultationLocation) => item.location !== row.location);
    this.setState((prevState) => ({
      lungAuscultationLocationTableData: { ...prevState.lungAuscultationLocationTableData, data: newLocationList }
    }));
  };

  render() {
    const { locale, intl } = this.props;
    const { resetAll, lungAuscultationLocationTableData } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.hasValidUnsavedChanges(),
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: RespiratoryAssessmentViewProps = {
      addLocation: this.handleAddLocation,
      addLocationEnabled: this.handleEnableAddLocationButton(),
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      clearLocations: this.handleClearLocations,
      clearLocationsEnabled: this.hasUnsavedLocationData(),
      deleteLungAuscultationLocation: this.handleDeleteLocation,
      formSubmittedCount: this.props.formSubmittedCount,
      lungAuscultationLocationTableData,
      lungAuscultationLabel: this.getLungAuscultationLabel(),
      resetAll,
      intl
    };

    if (locale === Locales.EN_AU) {
      return <RespiratoryAssessmentAUView {...viewProps} />;
    }

    return <RespiratoryAssessmentView {...viewProps} />;
  }
}

export { RespiratoryAssessment as BaseRespiratoryAssessment };
export default compose(withSavedPatientChartsPage, withChartLogic)(RespiratoryAssessment);

import { ReviewOfSystemsFragment } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import { TEXT_AREAS } from './ReviewOfSystems';
import { SectionTitle } from './constant';

export interface ReviewOfSystemsReadOnlyProps {
  selectedRecord: ReviewOfSystemsFragment;
}

const ReviewOfSystemsReadonly = (props: ReviewOfSystemsReadOnlyProps) => {
  const record = props.selectedRecord;
  return (
    <>
      <h2>{SectionTitle.REVIEW_OF_SYSTEMS}</h2>
      <Box className="sng-article">
        {!record ? (
          <h3>Chart Omitted</h3>
        ) : (
          <>
            <ELSFlex wrap left className="u-flex-gutter-3x u-flex-row-gap-2x">
              {TEXT_AREAS.map(({ name, label }) => (
                <ELSFlexItem w="1o2" md="1o1" key={name}>
                  <h4>{label}</h4>
                  <MultiLineWithEmpty text={record.chartData?.[name]?.content} />
                </ELSFlexItem>
              ))}
            </ELSFlex>
          </>
        )}
      </Box>
    </>
  );
};
export default ReviewOfSystemsReadonly;

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, ErrorGroup, GroupScaleQuestion } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { ErrorGroupDisplayName, ErrorGroupId, FormField, SectionTitle } from './constants';
import MoodDisorderQuestionnaireView, { MoodDisorderQuestionnaireViewProps } from './MoodDisorderQuestionnaireView';

class MoodDisorderQuestionnaire extends Component<ChartComponentProps> {
  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    dataMap.set(
      FormField.MOOD_DISORDER_QUESTIONNAIRE,
      createFormField({
        name: FormField.MOOD_DISORDER_QUESTIONNAIRE,
        type: FormFieldInputType.SCALE
      })
    );

    return dataMap;
  };

  getGroupScaleQuestion = (): GroupScaleQuestion[] => {
    return [
      {
        label: `1. ${SectionTitle.PERIOD_TIME_NOT_USUAL_SELF}`,
        formFields: [
          FormField.FELT_GOOD_HYPER_NOT_NORMAL_SELF_HYPER_TROUBLE,
          FormField.IRRITABLE_SHOUTED_STARTED_FIGHTS_ARGUMENTS,
          FormField.MORE_SELF_CONFIDENT_USUAL,
          FormField.LESS_SLEEP_USUAL_MISS,
          FormField.MORE_TALKATIVE_SPOKE_FASTER_USUAL,
          FormField.THOUGHTS_RACED_THROUGH_HEAD_YOU_SLOW_DOWN_MIND,
          FormField.EASILY_DISTRACTED_THINGS_AROUND_TROUBLE_CONCENTRATING_STAYING_TRACK,
          FormField.HAD_MORE_ENERGY_USUAL,
          FormField.MORE_ACTIVE_DID_MANY_MORE_THINGS_USUAL,
          FormField.MORE_SOCIAL_OUT_GOING_USUAL,
          FormField.MORE_INTERESTED_SEX_USUAL,
          FormField.DID_THING_UNUSUAL_THAT_OTHER_PEOPLE_THOUGHT_EXCESSIVE_FOOLISH_OR_RISKY,
          FormField.SPENDING_MONEY_GOT_FAMILY_TROUBLE
        ],
        hasSectionDivider: true
      },
      {
        label: `2. ${SectionTitle.SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME}`,
        formFields: [FormField.SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME]
      },
      {
        label: `3. ${SectionTitle.MUCH_PROBLEM_DID_ANY_THESE_CAUSE}`,
        formFields: [FormField.MUCH_PROBLEM_DID_ANY_THESE_CAUSE]
      },
      {
        label: `4. ${SectionTitle.HAVE_ANY_BLOOD_RELATIVES}`,
        formFields: [FormField.HAVE_ANY_BLOOD_RELATIVES]
      },
      {
        label: `5. ${SectionTitle.HEALTH_CARE_PROFESSIONAL_EVER_TOLD_HAVE_MANIC_DEPRESSIVE_ILLNESS_BIPOLAR_DISORDER}`,
        formFields: [FormField.HEALTH_CARE_PROFESSIONAL_EVER_TOLD_HAVE_MANIC_DEPRESSIVE_ILLNESS_BIPOLAR_DISORDER]
      }
    ];
  };

  getErrorGroups = (): ErrorGroup[] => {
    return [
      {
        groupId: ErrorGroupId.QUESTION_1,
        displayName: ErrorGroupDisplayName.QUESTION_1,
        priority: 0,
        formFields: [
          FormField.FELT_GOOD_HYPER_NOT_NORMAL_SELF_HYPER_TROUBLE,
          FormField.IRRITABLE_SHOUTED_STARTED_FIGHTS_ARGUMENTS,
          FormField.MORE_SELF_CONFIDENT_USUAL,
          FormField.LESS_SLEEP_USUAL_MISS,
          FormField.MORE_TALKATIVE_SPOKE_FASTER_USUAL,
          FormField.THOUGHTS_RACED_THROUGH_HEAD_YOU_SLOW_DOWN_MIND,
          FormField.EASILY_DISTRACTED_THINGS_AROUND_TROUBLE_CONCENTRATING_STAYING_TRACK,
          FormField.HAD_MORE_ENERGY_USUAL,
          FormField.MORE_ACTIVE_DID_MANY_MORE_THINGS_USUAL,
          FormField.MORE_SOCIAL_OUT_GOING_USUAL,
          FormField.MORE_INTERESTED_SEX_USUAL,
          FormField.DID_THING_UNUSUAL_THAT_OTHER_PEOPLE_THOUGHT_EXCESSIVE_FOOLISH_OR_RISKY,
          FormField.SPENDING_MONEY_GOT_FAMILY_TROUBLE
        ]
      },
      {
        groupId: ErrorGroupId.QUESTION_2,
        displayName: ErrorGroupDisplayName.QUESTION_2,
        priority: 0,
        formFields: [FormField.SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME]
      },
      {
        groupId: ErrorGroupId.QUESTION_3,
        displayName: ErrorGroupDisplayName.QUESTION_3,
        priority: 0,
        formFields: [FormField.MUCH_PROBLEM_DID_ANY_THESE_CAUSE]
      }
    ];
  };

  buildFragment = () => {
    const { buildPatientRecord, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: SectionTitle.MOOD_DISORDER_QUESTIONNAIRE,
      records: [
        {
          sectionTitle: SectionTitle.PERIOD_TIME_NOT_USUAL_SELF,
          records: [
            buildPatientRecord(formFieldMap, FormField.FELT_GOOD_HYPER_NOT_NORMAL_SELF_HYPER_TROUBLE),
            buildPatientRecord(formFieldMap, FormField.IRRITABLE_SHOUTED_STARTED_FIGHTS_ARGUMENTS),
            buildPatientRecord(formFieldMap, FormField.MORE_SELF_CONFIDENT_USUAL),
            buildPatientRecord(formFieldMap, FormField.LESS_SLEEP_USUAL_MISS),
            buildPatientRecord(formFieldMap, FormField.MORE_TALKATIVE_SPOKE_FASTER_USUAL),
            buildPatientRecord(formFieldMap, FormField.THOUGHTS_RACED_THROUGH_HEAD_YOU_SLOW_DOWN_MIND),
            buildPatientRecord(formFieldMap, FormField.EASILY_DISTRACTED_THINGS_AROUND_TROUBLE_CONCENTRATING_STAYING_TRACK),
            buildPatientRecord(formFieldMap, FormField.HAD_MORE_ENERGY_USUAL),
            buildPatientRecord(formFieldMap, FormField.MORE_ACTIVE_DID_MANY_MORE_THINGS_USUAL),
            buildPatientRecord(formFieldMap, FormField.MORE_SOCIAL_OUT_GOING_USUAL),
            buildPatientRecord(formFieldMap, FormField.MORE_INTERESTED_SEX_USUAL),
            buildPatientRecord(formFieldMap, FormField.DID_THING_UNUSUAL_THAT_OTHER_PEOPLE_THOUGHT_EXCESSIVE_FOOLISH_OR_RISKY),
            buildPatientRecord(formFieldMap, FormField.SPENDING_MONEY_GOT_FAMILY_TROUBLE)
          ]
        },
        {
          sectionTitle: SectionTitle.SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME,
          records: [buildPatientRecord(formFieldMap, FormField.SEVERAL_THESE_HAPPENED_DURING_SAME_PERIOD_TIME)]
        },
        {
          sectionTitle: SectionTitle.MUCH_PROBLEM_DID_ANY_THESE_CAUSE,
          records: [buildPatientRecord(formFieldMap, FormField.MUCH_PROBLEM_DID_ANY_THESE_CAUSE)]
        },
        {
          sectionTitle: SectionTitle.HAVE_ANY_BLOOD_RELATIVES,
          records: [buildPatientRecord(formFieldMap, FormField.HAVE_ANY_BLOOD_RELATIVES)]
        },
        {
          sectionTitle: SectionTitle.HEALTH_CARE_PROFESSIONAL_EVER_TOLD_HAVE_MANIC_DEPRESSIVE_ILLNESS_BIPOLAR_DISORDER,
          records: [buildPatientRecord(formFieldMap, FormField.HEALTH_CARE_PROFESSIONAL_EVER_TOLD_HAVE_MANIC_DEPRESSIVE_ILLNESS_BIPOLAR_DISORDER)]
        },
        {
          sectionTitle: 'Scoring',
          records: [
            buildPlainRecord({
              formField: FormField.MOOD_DISORDER_QUESTIONNAIRE,
              title: '',
              content: chartHelper.getScaleRollUpContentHighlight(formFieldMap.get(FormField.MOOD_DISORDER_QUESTIONNAIRE))?.label
            })
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildDefaultFormFields(),
      afterSave: this.props.navigateToSavedPatientCharting
    });
  };

  handleCancelClick = () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields());

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.handleCancelClick,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: MoodDisorderQuestionnaireViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      groupScaleQuestion: this.getGroupScaleQuestion(),
      errorGroups: this.getErrorGroups()
    };
    return <MoodDisorderQuestionnaireView {...viewProps} />;
  }
}
export { MoodDisorderQuestionnaire as BaseMoodDisorderQuestionnaire };
export default compose(withSavedPatientChartsPage, withChartLogic)(MoodDisorderQuestionnaire);

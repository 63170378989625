import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ChartFragment, MultiSectionsFragment } from 'models/api-response';
import { FragmentStatus } from 'models/enum';
import { RouteParams, RoutePath, SectionNavIdPatterns } from 'constants/app.constant';
import { chartHelper, dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { SavedPatientWrapper } from 'components/common';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';
import './saved.injections.scss';
import { FormField, SectionTitle } from '../constants';

interface MatchParams {
  assessmentId: string;
}

export interface SavedInjectionsProps extends RouteComponentProps<MatchParams> {
  enableMultiStepsAuthoring: Function;
}

class SavedInjections extends Component<SavedInjectionsProps> {
  static displayName = 'SavedInjections';

  componentDidMount() {
    this.props.enableMultiStepsAuthoring(true);
  }

  isInjectionFragmentComplete = (fragment: ChartFragment) => {
    return chartHelper.getFragmentValueByKey(fragment, SectionTitle.INJECTION, FormField.COMPLETE, 'content');
  };

  toTableData = (fragments: ChartFragment[]): TableItem[] => {
    return fragments.map((fragment) => ({
      active: fragment.active,
      enteredBy: chartService.formatName(fragment.creator),
      entryTime: dateTimeHelper.toDateTime(fragment.chartingAt),
      fragmentCreatorId: fragment.creator?.id,
      fragmentType: fragment.fragmentType,
      id: fragment.fragmentId,
      linkedFragmentId: fragment.linkedFragmentId,
      groupFragmentRef: fragment.groupFragmentRef,
      location: chartHelper.getFragmentValueByKey(fragment, SectionTitle.INJECTION, FormField.LOCATION, 'content'),
      navElementId: fragment.navElementId,
      injectionType: chartHelper.getFragmentValueByKey(fragment, SectionTitle.INJECTION, FormField.INJECTION_TYPE, 'content'),
      injectionStatus: this.isInjectionFragmentComplete(fragment) ? FragmentStatus.COMPLETED : FragmentStatus.GIVEN
    }));
  };

  handleInjectionUpdate = (row) => {
    const { assessmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.student.injections.injections.replace(RouteParams.ASSESSMENT_ID, assessmentId),
      search: `?id=${encodeURIComponent(row.id)}`
    });
  };

  buildStatusBadge = (fragment: MultiSectionsFragment) => {
    const status = this.isInjectionFragmentComplete(fragment) ? FragmentStatus.COMPLETED : FragmentStatus.GIVEN;
    const isStatusBadgeActive = status === FragmentStatus.GIVEN;
    return { status, isStatusBadgeActive };
  };

  render() {
    const columns = {
      location: {
        field: 'location',
        header: 'Location'
      },
      status: {
        field: 'injectionStatus',
        header: 'Injection Status',
        active: FragmentStatus.GIVEN
      },
      type: {
        field: 'injectionType',
        header: 'Injection Type'
      }
    };
    const deleteMessage = `Are you sure you want to delete this entry? Note that the most recent injection update will be deleted.
      If there is another record associated with this injection location, the next most recent record will be displayed.`;

    const viewProps = {
      buildStatusBadge: this.buildStatusBadge,
      className: 'saved-injections',
      columns,
      chartTitle: 'Saved Injections Charting',
      deleteMessage,
      hideDelete: true,
      navIds: [SectionNavIdPatterns.INJECTIONS],
      onRecordUpdate: this.handleInjectionUpdate,
      toTableData: this.toTableData,
      ...this.props
    };

    return <SavedPatientWrapper {...viewProps} />;
  }
}

export { SavedInjections as BaseSavedInjections };
export default withRouter(SavedInjections);

import { MultiLine } from 'components/common';

interface SectionOverviewProps {
  title?: string;
  content?: string;
}
const SectionOverview = (props: SectionOverviewProps) => (
  <div className="section-overview section-box">
    <div className="u-els-font-size-display">{props.title}</div>
    <MultiLine className="u-els-margin-top-2x" text={props.content} />
  </div>
);

export default SectionOverview;

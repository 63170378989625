import produce from 'immer';
import { Component } from 'react';
import { ChartFragmentRS, DiagnosisFragment } from 'models/api-response';
import { ChartComponentProps } from 'models/ui';
import { withChartLogic } from 'components/common';
import { FormField } from './constants';
import { buildFormFields } from './Diagnosis';
import EditDiagnosisSidebarView, { EditDiagnosisSidebarViewProps } from './EditDiagnosisSidebarView';

export interface EditDiagnosisSidebarProps extends ChartComponentProps {
  diagnosis: DiagnosisFragment;
  onCloseClick: Function;
  onSaveClick: Function;
  disableRadioContent: Function;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
}

class EditDiagnosisSidebar extends Component<EditDiagnosisSidebarProps, null> {
  static displayName = 'EditDiagnosisSidebar';

  componentDidUpdate(prevProps: Readonly<EditDiagnosisSidebarProps>) {
    const { diagnosis } = this.props;
    if (diagnosis && prevProps.diagnosis !== diagnosis) {
      const formFieldMap = buildFormFields(diagnosis);
      formFieldMap.delete(FormField.DIAGNOSIS_LIST);
      formFieldMap.delete(FormField.DIAGNOSIS_OTHER);
      this.props.initState(formFieldMap);
    }
  }

  handleSaveClick = () => {
    const { diagnosis, formFieldMap, onSaveClick } = this.props;
    const diagnosisTypeField = formFieldMap.get(FormField.DIAGNOSIS_TYPE);
    const selectedTypeContent = diagnosisTypeField.chartContent.find((contentItem) => contentItem.value === diagnosisTypeField.value);
    const updatedDiagnosis = produce(diagnosis, (draft) => {
      const { chartData } = draft;
      chartData.typeId = selectedTypeContent?.id;
      chartData.typeLabel = selectedTypeContent?.label;
      chartData.typeValue = selectedTypeContent?.value;
      chartData.displayOrder = selectedTypeContent?.displayOrder;
      chartData.pathophysiology = formFieldMap.get(FormField.PATHOPHYSIOLOGY).value;
      chartData.therapeuticRegimen = formFieldMap.get(FormField.THERAPEUTIC_REGIMEN).value;
      chartData.problemsAndChanges = formFieldMap.get(FormField.PROBLEMS_AND_CHANGES).value;
    });
    this.props.handleSaveClick([updatedDiagnosis], { afterSave: onSaveClick });
  };

  render() {
    const { diagnosis, onCloseClick, formFieldMap, formSubmittedCount, disableRadioContent, sidebarProps } = this.props;
    const viewProps: EditDiagnosisSidebarViewProps = {
      open: !!diagnosis,
      name: diagnosis?.chartData.name,
      onSaveClick: this.handleSaveClick,
      onCloseClick,
      formFieldMap,
      formSubmittedCount,
      disableRadioContent,
      sidebarProps
    };
    return <EditDiagnosisSidebarView {...viewProps} />;
  }
}
export { EditDiagnosisSidebar as BaseEditDiagnosisSidebar };
export default withChartLogic(EditDiagnosisSidebar);

import { Component } from 'react';
import { PharmacyRecord } from 'models/api-response';
import { ColumnAlign } from 'models/ui';
import { Box, ChartHistory, ClinicalKeyDrugInformation } from 'components/common';

export interface PharmacyOrderTableProps {
  chartHistory: PharmacyRecord[];
  deleteHistory: Function;
}

class PharmacyOrderTable extends Component<PharmacyOrderTableProps> {
  static displayName = 'PharmacyOrderTable';

  orderTimeRenderer = ({ orderTime }) => {
    return <div className="pharmacy-order-table__order-time-field">{orderTime || ''}</div>;
  };

  frequencyRenderer = ({ frequencyOfAdministration }) => {
    return <div>{frequencyOfAdministration || ''}</div>;
  };

  orderDescriptionRenderer = ({ orderDescription }) => {
    return (
      <div>
        {orderDescription && (
          <div>
            {orderDescription}
            <ClinicalKeyDrugInformation areaName={orderDescription} drugName={orderDescription} showLink />
          </div>
        )}
      </div>
    );
  };

  orDetailsRenderer = ({ orderDetails }) => {
    return <div>{orderDetails || ''}</div>;
  };

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true, customRender: this.orderTimeRenderer },
        { text: 'Frequency', field: 'frequencyOfAdministration', align: ColumnAlign.Left, customRender: this.frequencyRenderer },
        { text: 'Order Description', field: 'orderDescription', align: ColumnAlign.Left, customRender: this.orderDescriptionRenderer },
        { text: 'Order Details', field: 'orderDetails', align: ColumnAlign.Left, customRender: this.orDetailsRenderer },
        { text: 'Special Instructions', field: 'notes' },
        { field: 'actionDelete' }
      ],
      css: ['column__chart-time', '', '', '', 'column__note', 'column__delete'],
      data: chartHistory
    };

    return (
      <Box mt className="pharmacy-order-table">
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

export default PharmacyOrderTable;

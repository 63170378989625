import { Component } from 'react';
import { LaborProgressRecord } from 'models/api-response';
import { DropDownItem } from 'models/ui';
import { Box } from 'components/common';
import { ELSDropDown } from 'components/common/els';
import { HistoryField } from './constants';
import LaborProgressFetalAssessmentHistoryTable from './LaborProgressFetalAssessmentHistoryTable';
import LaborProgressUterineActivityHistoryTable from './LaborProgressUterineActivityHistoryTable';
import LaborProgressVaginalAssessmentHistoryTable from './LaborProgressVaginalAssessmentHistoryTable';

export interface LaborProgressHistoryViewProps {
  chartHistory: LaborProgressRecord[];
  deleteHistory: Function;
  charts: DropDownItem[];
  selectedHistory: string;
  onHistoryChange: Function;
}

class LaborProgressHistoryView extends Component<LaborProgressHistoryViewProps> {
  static displayName = 'LaborProgressHistoryView';

  render() {
    const { charts, selectedHistory, onHistoryChange, chartHistory, deleteHistory } = this.props;
    const filteredOnsetFragmentChartHistory = chartHistory.filter((item) => !item.laborOnsetDate && !item.laborOnsetTime);
    return (
      <div>
        <Box mt className="chart-input__dropdown">
          <ELSDropDown inline options={charts} value={selectedHistory} changeHandler={onHistoryChange} />
        </Box>
        {selectedHistory === HistoryField.FETAL_ASSESSMENT && (
          <Box mt>
            <LaborProgressFetalAssessmentHistoryTable chartHistory={filteredOnsetFragmentChartHistory} deleteHistory={deleteHistory} />
          </Box>
        )}
        {selectedHistory === HistoryField.UTERINE_ACTIVITY && (
          <Box mt>
            <LaborProgressUterineActivityHistoryTable chartHistory={filteredOnsetFragmentChartHistory} deleteHistory={deleteHistory} />
          </Box>
        )}
        {selectedHistory === HistoryField.VAGINAL_ASSESSMENT && (
          <Box mt>
            <LaborProgressVaginalAssessmentHistoryTable chartHistory={filteredOnsetFragmentChartHistory} deleteHistory={deleteHistory} />
          </Box>
        )}
      </div>
    );
  }
}

export default LaborProgressHistoryView;

import { LanguageKeys } from 'lang';
import { Box, ChartActions, ChartPermission, ErrorFormField } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

export type CardiovascularPeripheralVascularInterventionsViewProps = ChartViewProps;

const CardiovascularPeripheralVascularInterventionsView = ({
  chartActionsProps,
  formFieldMap,
  formSubmittedCount,
  intl
}: CardiovascularPeripheralVascularInterventionsViewProps) => {
  return (
    <div className="cardiovascular-interventions">
      <h1>{LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.CARDIOVASCULAR_PERIPHERAL_INTERVENTIONS}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.CARDIOVASCULAR_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.CARDIOVASCULAR_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{intl.formatMessage({ id: LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.DVT_SUPPRESSION })}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DVT_SUPPRESSION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.DRAIN_OR_TUBE_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.DRAIN_OR_TUBE_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{LanguageKeys.CARDIOVASCULAR_INTERVENTIONS.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
    </div>
  );
};
CardiovascularPeripheralVascularInterventionsView.displayName = 'CardiovascularPeripheralVascularInterventionsView';
export default CardiovascularPeripheralVascularInterventionsView;

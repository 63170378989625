import { isEqual, last, orderBy } from 'lodash';
import { Component } from 'react';
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Locales } from 'constants/app.constant';
import { addDays, formatDate, toMoment } from 'helpers/datetime.helper';
import { chartConfig } from 'components/common/chart/config/chart.config';
import { ELSIcon } from 'components/common/els';
import { CircleDot } from './CircleDot';
import { SquareDot } from './SquareDot';
import './line.chart.scss';

export interface CustomRenderLeftYAxisLinesProps {
  dataKeys: string[];
  handleGetCoordinate: Function;
  handleDotClick: Function;
  handleAnimationStart: Function;
  handleAnimationEnd: Function;
}

interface CustomTooltipProps {
  cx: number;
  cy: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  onCloseTooltip: Function;
  displayValue: string;
}

export const CustomTooltip = (props: CustomTooltipProps) => {
  let dataObj = null;
  if (props.value) {
    dataObj = props.value;
  }
  return (
    <div className="line-chart-tooltip__wrapper" style={{ transform: `translate(${props.cx}px, ${props.cy}px)` }}>
      <ELSIcon name="close" prefix="hmds" size="1x" onClick={props.onCloseTooltip} />
      <div className="u-els-bold">{props.displayValue}</div>
      <div className="qa-tooltip-datetime">{dataObj.payload.dateTime}</div>
    </div>
  );
};

export interface HistoryLineChartProps {
  width?: number | string;
  height?: number | string;
  xLabel?: string;
  yLabel?: string;
  rightYLabel?: string;
  data: object & { date: string }[];
  dataKeys: string[];
  rightDataKeys?: string[];
  interval?: number;
  yDomain?: number[] | string[] | Function[];
  rightYDomain?: number[] | string[] | Function[];
  xAxisDataKey?: string;
  yAxisName?: object;
  legendType?: string;
  strokeWidth?: number;
  dotFormat?: {
    stroke: string;
    strokeWidth: number;
  };
  locale?: Locales;
  customRenderLeftYAxisLinesProps?: ({ dataKeys, handleGetCoordinate, handleDotClick, handleAnimationStart, handleAnimationEnd }: CustomRenderLeftYAxisLinesProps) => JSX.Element[];
}

interface HistoryLineChartState {
  activeTooltip: boolean;
  tooltipX: number;
  tooltipY: number;
  tooltipDisplay: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltipValue: any;
  data: object & { date: string }[];
}

export const LEFT_Y_AXIS_ID = 'leftYAxis';
export const RIGHT_Y_AXIS_ID = 'rightYAxis';

class HistoryLineChart extends Component<HistoryLineChartProps, HistoryLineChartState> {
  isSameDay: boolean;

  static displayName = 'HistoryLineChart';

  constructor(props: Readonly<HistoryLineChartProps>) {
    super(props);
    const data = this.addDateForXAxis(this.props.data);
    this.state = {
      data: this.toChartData(data),
      activeTooltip: false,
      tooltipValue: null,
      tooltipX: 0,
      tooltipY: 0,
      tooltipDisplay: ''
    };
  }

  componentDidUpdate(prevProps: Readonly<HistoryLineChartProps>): void {
    if (!isEqual(prevProps.data, this.props.data)) {
      const data = this.addDateForXAxis(this.props.data);
      this.setState({ data: this.toChartData(data) });
    }
  }

  toChartData = (data: object & { date: string }[]) => [...orderBy(data, 'dateTime')];

  addDateForXAxis = (data: object & { date: string }[]) => {
    const { locale } = this.props;
    this.isSameDay = this.isDataSetSameDay(data);
    if (this.isSameDay) {
      const appendDate = {
        date: formatDate({ date: addDays(data[0].date, 1).toDate(), locale }),
        generatedDataPoint: true
      };
      return [...data, appendDate];
    }

    return data;
  };

  isDataSetSameDay = (data: object & { date: string }[]): boolean => {
    if (data.length <= 1) {
      return true;
    }
    const orderedData = orderBy(data, 'date');
    const firstDay = toMoment(orderedData[0].date);
    const lastDay = toMoment(last(orderedData).date);
    return firstDay.isSame(lastDay, 'day');
  };

  handleCloseTooltip = () => this.setState({ activeTooltip: false });

  handleAnimationEnd = (dataKey: string, index: number) => {
    if (index % 2 === 0) {
      const data = [...orderBy(this.props.data, 'dateTime')];
      const dataSize = data.length;
      const payload = data[dataSize - 1];
      const tooltipDefaultValue = {
        dataKey,
        payload,
        value: payload[dataKey]
      };
      this.setState({
        activeTooltip: true,
        tooltipValue: tooltipDefaultValue,
        tooltipDisplay: payload[`${dataKey}Display`]
      });
    }
  };

  handleGetCoordinate = (cx: number, cy: number) => this.setState({ tooltipX: cx, tooltipY: cy });

  handleDotClick = (dotValue, dataKey) =>
    this.setState({
      activeTooltip: true,
      tooltipValue: dotValue,
      tooltipX: dotValue.cx + 15,
      tooltipY: dotValue.cy - 15,
      tooltipDisplay: dotValue.payload[`${dataKey}Display`]
    });

  handleAnimationStart = () => this.setState({ activeTooltip: false });

  renderYAxisLines = (dataKeys: string[], yAxisId: string) =>
    dataKeys &&
    dataKeys.map((dataKey, index) => {
      const { dataColors } = chartConfig;
      let stroke = index % 2 === 0 ? dataColors[0] : dataColors[1];
      let dot =
        index % 2 === 0 ? (
          <SquareDot onGetCoordinate={(cx: number, cy: number) => this.handleGetCoordinate(cx, cy)} />
        ) : (
          {
            stroke: chartConfig.dataColors[1],
            strokeWidth: 10
          }
        );
      if (yAxisId === RIGHT_Y_AXIS_ID) {
        [, stroke] = dataColors;
        dot = {
          stroke: chartConfig.dataColors[1],
          strokeWidth: 10
        };
      }
      if (this.props.dotFormat) {
        dot = <CircleDot {...this.props.dotFormat} onGetCoordinate={(cx: number, cy: number) => this.handleGetCoordinate(cx, cy)} />;
      }
      const legendType = this.props.legendType || (index % 2 === 0 && yAxisId !== RIGHT_Y_AXIS_ID ? 'square' : 'circle');
      return (
        <Line
          name={this.props.yAxisName?.[yAxisId]}
          restartAnimationOnChange
          yAxisId={yAxisId}
          key={dataKey}
          type="linear"
          dataKey={dataKey}
          legendType={legendType}
          stroke={this.props.dotFormat?.stroke || stroke}
          strokeWidth={this.props.strokeWidth !== null ? this.props.strokeWidth : 2}
          dot={dot}
          activeDot={{
            onClick: (dotValue) => this.handleDotClick(dotValue, dataKey)
          }}
          onAnimationStart={() => this.handleAnimationStart()}
          onAnimationEnd={() => this.handleAnimationEnd(dataKey, index)}
        />
      );
    });

  render() {
    const width = this.props.width || chartConfig.line.defaultWidth;
    const height = this.props.height || chartConfig.line.defaultHeight;
    const lineMargin = chartConfig.line.defaultMargin;
    const legendHeight = chartConfig.line.defaultLegendHeight;
    const { interval, xAxisDataKey, xLabel, yLabel, rightYLabel, rightDataKeys, dataKeys, customRenderLeftYAxisLinesProps } = this.props;
    const { data, activeTooltip, tooltipValue, tooltipX, tooltipY, tooltipDisplay } = this.state;
    const defaultYDomain = [(dataMin) => Math.floor(dataMin * 0.9), (dataMax) => Math.ceil(dataMax * 1.1)];
    const defaultRightYDomain = [(dataMin) => Math.floor(dataMin - 1), (dataMax) => Math.ceil(dataMax + 2)];
    const yDomain = this.props.yDomain || defaultYDomain;
    const rightYDomain = this.props.rightYDomain || defaultRightYDomain;
    const customTooltipProps: CustomTooltipProps = {
      cx: tooltipX,
      cy: tooltipY,
      value: tooltipValue,
      onCloseTooltip: this.handleCloseTooltip,
      displayValue: tooltipDisplay
    };
    return (
      <div className="line-chart">
        <ResponsiveContainer width={width} height={height}>
          <LineChart data={data} margin={lineMargin}>
            <Legend verticalAlign="top" height={legendHeight} wrapperStyle={{ textTransform: 'capitalize' }} />
            <CartesianGrid vertical={false} />
            <XAxis
              axisLine={false}
              dataKey={xAxisDataKey || 'date'}
              tickMargin={8}
              interval={this.isSameDay ? data.length - 2 : interval}
              padding={{ left: 50, right: 50, bottom: 80 }}
              stroke={chartConfig.labelColor}
            >
              {xLabel && <Label value={xLabel} offset={-20} position="insideBottom" />}
            </XAxis>
            <YAxis yAxisId={LEFT_Y_AXIS_ID} axisLine={false} tickLine={false} stroke={chartConfig.labelColor} domain={yDomain}>
              {yLabel && <Label value={yLabel} angle={-90} position="left" />}
            </YAxis>
            {rightDataKeys && (
              <YAxis yAxisId={RIGHT_Y_AXIS_ID} axisLine={false} tickLine={false} stroke={chartConfig.labelColor} domain={rightYDomain} orientation="right">
                {rightYLabel && <Label value={rightYLabel} angle={-90} position="right" offset={-10} />}
              </YAxis>
            )}
            <Tooltip />
            {customRenderLeftYAxisLinesProps?.({
              dataKeys,
              handleGetCoordinate: this.handleGetCoordinate,
              handleDotClick: this.handleDotClick,
              handleAnimationStart: this.handleAnimationStart,
              handleAnimationEnd: this.handleAnimationEnd
            }) || this.renderYAxisLines(dataKeys, LEFT_Y_AXIS_ID)}
            {this.renderYAxisLines(rightDataKeys, RIGHT_Y_AXIS_ID)}
          </LineChart>
        </ResponsiveContainer>
        {activeTooltip && <CustomTooltip {...customTooltipProps} />}
      </div>
    );
  }
}

export default HistoryLineChart;

export enum Section {
  MANIA_QUESTIONNAIRE_SCALE = 'Mania Questionnaire',
  TOTAL_SCORE = 'Total Score'
}

export enum FormField {
  MANIA_QUESTIONNAIRE_SCALE = 'ratingScale',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION1 = 'maniaQuestionnaireScaleQuestion1',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION2 = 'maniaQuestionnaireScaleQuestion2',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION3 = 'maniaQuestionnaireScaleQuestion3',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION4 = 'maniaQuestionnaireScaleQuestion4',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION5 = 'maniaQuestionnaireScaleQuestion5',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION6 = 'maniaQuestionnaireScaleQuestion6',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION7 = 'maniaQuestionnaireScaleQuestion7',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION8 = 'maniaQuestionnaireScaleQuestion8',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION9 = 'maniaQuestionnaireScaleQuestion9',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION10 = 'maniaQuestionnaireScaleQuestion10',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION11 = 'maniaQuestionnaireScaleQuestion11',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION12 = 'maniaQuestionnaireScaleQuestion12',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION13 = 'maniaQuestionnaireScaleQuestion13',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION14 = 'maniaQuestionnaireScaleQuestion14',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION15 = 'maniaQuestionnaireScaleQuestion15',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION16 = 'maniaQuestionnaireScaleQuestion16',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION17 = 'maniaQuestionnaireScaleQuestion17',
  MANIA_QUESTIONNAIRE_SCALE_QUESTION18 = 'maniaQuestionnaireScaleQuestion18'
}

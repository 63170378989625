import { ChartMetaFormField, ContentItem } from 'models/ui';

/**
 * Get subset of props.
 * It requires to create an empty object of type R to work.
 * @example
 * type A = {
 *  prop1: string;
 *  prop2: number;
 *  prop3: boolean;
 *  prop4: string;
 *  prop5: string;
 * };
 * type B = {
 *  prop1: string;
 *  prop4: string;
 *  prop5: string;
 * }
 * const propsA: A = { prop1: 'a', prop2: 1, prop3: true, prop4: 'b', prop5: 'c' };
 * const dummyObjectB: B = { prop1: '', prop4: '', prop5: '' };
 * const keys = Object.keys(dummyObjectB);
 * const propsB = getProps<B, A>(keys, propsA);
 *
 * @param keys - keys of props to return
 * @param props - props to get subset of
 * @returns subset of props
 */
export const getProps = <R, T = object>(keys: string[], props: T): R => {
  return keys.reduce((acc, key) => {
    if (props[key] !== undefined && props[key] !== null) {
      return { ...acc, [key]: props[key] };
    }
    return acc;
  }, {} as R);
};

export const isHiddenRadioGroup = ({ formField, hideRadioContent }: { formField: ChartMetaFormField; hideRadioContent?: <T = object>(item: ContentItem) => T }): boolean => {
  const chartContent = formField.chartContent?.filter((item) => !item.isHidden && (item.isActive || item.isActive === undefined));
  let isHidden = true;

  if (!hideRadioContent) {
    isHidden = false;
  } else {
    chartContent.forEach((item) => {
      if (!hideRadioContent(item)) isHidden = false;
    });
  }

  return isHidden;
};

export enum FormFieldLabel {
  DEPTH = 'Depth',
  LENGTH = 'Length',
  LOCATION = 'Location',
  OTHER = 'Other',
  WIDTH = 'Width',
  WOUND_CATEGORY = 'Wound Category',
  WOUND_TYPE = 'Wound Type',
  BODY_REGION = 'Body Region'
}

export enum FormField {
  WOUND_HEALED = 'woundHealed',
  MEDICAL_DEVICE = 'medicalDevice',
  LOCATION = 'location',
  BODY_REGION = 'bodyRegion',
  WOUND_CATEGORY = 'woundCategory',
  WOUND_TYPE = 'woundType',
  FOOT_NOTE = 'footNote',
  LENGTH = 'length',
  WIDTH = 'width',
  DEPTH = 'depth',
  WOUND_AREA = 'woundArea',
  PERIWOUND_AREA = 'periwoundArea',
  SOLUTION_USED = 'solutionUsed',
  SOLUTION_USED_OTHER = 'solutionUsedOther',
  PATIENT_RESPONSE = 'patientResponse',
  WOUND_DRAINAGE_EXUDATE = 'woundDrainageExudate',
  DRESSING_WOUND_CARE = 'dressingWoundCare',
  INTERVENTIONS = 'interventions',
  NOTES = 'notes'
}

export enum SectionTitle {
  WOUNDS = 'Wounds',
  WOUND_AREA = 'Wound Area',
  PERIWOUND_AREA = 'Periwound Area',
  SOLUTION_USED = 'Solutions Used',
  PATIENT_RESPONSE = 'Patient Response',
  WOUND_DRAINAGE_EXUDATE = 'Wound Drainage/Exudate',
  DRESSING_WOUND_CARE = 'Dressing and Wound Care',
  INTERVENTIONS = 'Interventions',
  NOTES = 'Notes'
}

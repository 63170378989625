import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';

export enum FormField {
  NO_ASSESSMENT_REQUIRED = 'noAssessmentRequired',
  ORIENTATION = 'orientation',
  CONSCIOUSNESS_LEVEL = 'consciousnesslevel',
  CONSCIOUSNESS_LEVEL_NOTES = 'consciousnessLevelNotes',
  EMOTIONAL_STATE = 'emotionalState',
  EMOTIONAL_STATE_NOTES = 'emotionalStateNotes',
  PUPILS_LEFT_REACTIVITY = 'pupilsLeftReactivity',
  PUPILS_LEFT_SIZE = 'pupilsLeftSize',
  PUPILS_RIGHT_REACTIVITY = 'pupilsRightReactivity',
  PUPILS_RIGHT_SIZE = 'pupilsRightSize',
  PUPILS_NOTES = 'pupilsNotes',
  HAND_GRIP_BILATERAL_ASSESSMENT = 'handGripBilateralAssessment',
  HAND_GRIP_LEFT_STRENGTH = 'handGripLeftStrength',
  HAND_GRIP_RIGHT_STRENGTH = 'handGripRightStrength',
  HAND_GRIP_NOTES = 'handGripNotes',
  FOOT_PUMPS_BILATERAL_ASSESSMENT = 'footPumpsBilateralAssessment',
  FOOT_PUMPS_LEFT_STRENGTH = 'footPumpsLeftStrength',
  FOOT_PUMPS_RIGHT_STRENGTH = 'footPumpsRightStrength',
  FOOT_PUMPS_NOTES = 'footPumpsNotes',
  CENTRAL_NERVOUS_NO_PROBLEMS = 'centralNervousNoProblems',
  CENTRAL_NERVOUS_PROBLEMS = 'centralNervousProblems',
  CENTRAL_NERVOUS_NOTES = 'centralNervousNotes',
  EPS_AKATHISIA = 'epsAkathisia',
  EPS_AKATHISIA_OTHER = 'epsAkathisiaOther',
  EPS_ACUTE_DYSTONIA = 'epsAcuteDystonia',
  EPS_ACUTE_DYSTONIA_OTHER = 'epsAcuteDystoniaOther',
  EPS_PSEUDOPARKINSONISM = 'epsPseudoparkinsonism',
  EPS_PSEUDOPARKINSONISM_OTHER = 'epsPseudoparkinsonismOther',
  EPS_TARDIVE_DYSKINESIA = 'epsTardiveDyskinesia',
  EPS_TARDIVE_DYSKINESIA_OTHER = 'epsTardiveDyskinesiaOther',
  NEUROLEPTIC_MALIGNANT_SYNDROME = 'neurolepticMalignantSyndrome'
}

export const neurologicalAssessmentFormFieldBuilderItems = (locale: Locales): ChartMetaFormFieldParams[] => [
  { name: FormField.NO_ASSESSMENT_REQUIRED, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.ORIENTATION, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CONSCIOUSNESS_LEVEL, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.CONSCIOUSNESS_LEVEL_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.EMOTIONAL_STATE, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EMOTIONAL_STATE_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.PUPILS_LEFT_REACTIVITY, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.REACTIVITY, isHidden: locale !== Locales.EN_AU },
  { name: FormField.PUPILS_LEFT_SIZE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.SIZE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.PUPILS_RIGHT_REACTIVITY, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.REACTIVITY, isHidden: locale !== Locales.EN_AU },
  { name: FormField.PUPILS_RIGHT_SIZE, type: FormFieldInputType.DROPDOWN, label: LanguageKeys.SIZE, isHidden: locale !== Locales.EN_AU },
  { name: FormField.PUPILS_NOTES, type: FormFieldInputType.TEXT_AREA, isHidden: locale !== Locales.EN_AU },
  { name: FormField.HAND_GRIP_BILATERAL_ASSESSMENT, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.BILATERAL_ASSESSMENT },
  { name: FormField.HAND_GRIP_LEFT_STRENGTH, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.LEFT_HAND_GRIP_STRENGTH },
  { name: FormField.HAND_GRIP_RIGHT_STRENGTH, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.RIGHT_HAND_GRIP_STRENGTH },
  { name: FormField.HAND_GRIP_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.FOOT_PUMPS_BILATERAL_ASSESSMENT, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.BILATERAL_ASSESSMENT },
  { name: FormField.FOOT_PUMPS_LEFT_STRENGTH, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.LEFT_FOOT_STRENGTH },
  { name: FormField.FOOT_PUMPS_RIGHT_STRENGTH, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.RIGHT_FOOT_STRENGTH },
  { name: FormField.FOOT_PUMPS_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.CENTRAL_NERVOUS_NO_PROBLEMS, type: FormFieldInputType.CHECK_BOX },
  { name: FormField.CENTRAL_NERVOUS_PROBLEMS, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.CENTRAL_NERVOUS_NOTES, type: FormFieldInputType.TEXT_AREA },
  { name: FormField.EPS_AKATHISIA, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EPS_AKATHISIA_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, errorLabel: LanguageKeys.AKATHISIA_OTHER },
  { name: FormField.EPS_ACUTE_DYSTONIA, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EPS_ACUTE_DYSTONIA_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, errorLabel: LanguageKeys.ACUTE_DYSTONIA_OTHER },
  { name: FormField.EPS_PSEUDOPARKINSONISM, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EPS_PSEUDOPARKINSONISM_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, errorLabel: LanguageKeys.PSEUDOPARKINSONISM_OTHER },
  { name: FormField.EPS_TARDIVE_DYSKINESIA, type: FormFieldInputType.MULTISELECT_DROPDOWN },
  { name: FormField.EPS_TARDIVE_DYSKINESIA_OTHER, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.OTHER, errorLabel: LanguageKeys.TARDIVE_DYSKINESIA_OTHER },
  { name: FormField.NEUROLEPTIC_MALIGNANT_SYNDROME, type: FormFieldInputType.MULTISELECT_DROPDOWN }
];

import cx from 'classnames';
import { connect } from 'react-redux';
import { DateTimeFormatByLocale, Locales } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';
import { appSelectors } from 'redux/ducks/app';

export interface BadgeDateTimeProps {
  className?: string;
  isoTime: string;
  locale: Locales;
}

const BadgeDateTime = ({ className, isoTime, locale }: BadgeDateTimeProps) => {
  const formattedTime = dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(isoTime).toDate(), customFormat: DateTimeFormatByLocale[locale] });
  return <p className={cx('u-els-display-inline-block c-els-badge c-els-badge--background-subtile u-els-nowrap', className)}>{formattedTime}</p>;
};

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});

BadgeDateTime.displayName = 'BadgeDateTime';
export default connect(mapStateToProps)(BadgeDateTime);

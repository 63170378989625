import cx from 'classnames';
import { FragmentStatus, FragmentType } from 'models/enum';
import { Badge, ButtonLinkIcon, ChartPermission, SavedPatientCharting } from 'components/common';
import { ELSButton, ELSIcon } from 'components/common/els';

export interface SavedPatientTeachingViewProps {
  onUpdate: Function;
}

const SavedPatientTeachingView = (props: SavedPatientTeachingViewProps) => {
  const { onUpdate, ...restProps } = props;

  const statusRenderer = (row) => {
    return (
      row.active && (
        <div className="saved-patient-charting__status">
          <Badge
            className={cx('saved-patient-charting__badge', {
              'saved-patient-charting__badge--active': row.status === FragmentStatus.INITIAL,
              'u-els-background-color-secondary-on-dark': row.status === FragmentStatus.REINFORCED
            })}
          >
            {row.status}
          </Badge>
          {row.status !== FragmentStatus.COMPLETED && (
            <ButtonLinkIcon
              className="saved-patient-charting__update-btn o-els-icon-svg--sub u-cursor-pointer"
              iconSize="1x"
              iconName="new-window"
              text="Update"
              onClick={() => onUpdate(row)}
            />
          )}
        </div>
      )
    );
  };

  const actionsRenderer = (row, onViewClick, onDeleteClick) => {
    return (
      row.active && (
        <div className={cx('saved-patient-charting__action', { 'u-cursor-pointer': row.active })}>
          <ButtonLinkIcon className="o-els-icon-svg--sub" iconName="enlarge" iconSize="1x" text="View" onClick={() => onViewClick(row)} />
          <ChartPermission fragmentCreatorId={row.fragmentCreatorId}>
            {({ allowDeleteFragment }) => {
              const showTrashIcon = allowDeleteFragment && row.active && row.fragmentType !== FragmentType.AUTHORED;
              return showTrashIcon && <ELSIcon customClass="saved-patient-charting__trash-icon" name="trash" prefix="gizmo" color="secondary" onClick={() => onDeleteClick(row)} />;
            }}
          </ChartPermission>
        </div>
      )
    );
  };

  const teachingTopicRenderer = (row, onViewClick) => {
    if (row.active) {
      return <ELSButton type="link" text={row.teachingTopic} onClick={() => onViewClick(row)} />;
    }
    return <span className={cx({ 'row--deleted': !row.active })}>{row.teachingTopic}</span>;
  };

  const entryTimeRenderer = (row) => {
    return <Badge className={cx('c-els-badge--inline', { 'row--deleted': !row.active })}>{row.entryTime}</Badge>;
  };

  return (
    <SavedPatientCharting {...restProps}>
      {({ onViewClick, onDeleteClick, defaultCustomRender }) => [
        <column key="teachingTopic" field="teachingTopic" header="Teaching Topic" sortable customRender={(row) => teachingTopicRenderer(row, onViewClick)} />,
        <column key="status" field="status" header="Status" sortable customRender={(row) => statusRenderer(row)} />,
        <column key="category" field="category" header="Category" sortable customRender={defaultCustomRender('category')} />,
        <column key="entryTime" field="entryTime" header="Entry Time" sortable customRender={(row) => entryTimeRenderer(row)} />,
        <column key="enteredBy" field="enteredBy" header="Entered By" customRender={defaultCustomRender('enteredBy')} />,
        <column key="actions" header="Actions" customRender={(row) => actionsRenderer(row, onViewClick, onDeleteClick)} />
      ]}
    </SavedPatientCharting>
  );
};

export default SavedPatientTeachingView;

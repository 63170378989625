import { ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartActions, ErrorCard, ErrorFormField } from 'components/common';
import { FormField, Section } from './constants';

export interface ManiaQuestionnaireViewProps {
  formFieldMap: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  chartActionsProps: ChartActionsComponentProps;
  contextDirectionMap?: Map<string, ScaleDirection>;
}

const ManiaQuestionnaireView = (props: ManiaQuestionnaireViewProps) => {
  const { formSubmittedCount, formFieldMap, chartActionsProps, contextDirectionMap } = props;

  return (
    <div className="hamilton-anxiety-rating-scale">
      <h1>{Section.MANIA_QUESTIONNAIRE_SCALE}</h1>
      <p>
        <span className="u-els-color-error">* </span>
        All fields in this chart are required
      </p>
      <ErrorCard chartMetaFormFields={props.formFieldMap} showErrorCard={props.formSubmittedCount > 0} />
      <p>The 18 statements below refer to how you may have felt and behaved during the past week. For each item, indicate the extent to which it is true.</p>
      <ErrorFormField
        className="u-els-margin-top-2x"
        formField={formFieldMap.get(FormField.MANIA_QUESTIONNAIRE_SCALE)}
        formFieldMap={formFieldMap}
        formSubmittedCount={formSubmittedCount}
        options={{ showScaleRollUp: true, contextDirectionMap, showScoreInAnswer: false }}
      />
      <ChartActions {...chartActionsProps} />
      <p className="u-els-margin-bottom">
        Copyright © 1993 by Ivan Goldberg. All rights reserved. Adapted from the printed edition of the Goldberg Mania Inventory for electronic distribution.
      </p>
    </div>
  );
};

export default ManiaQuestionnaireView;

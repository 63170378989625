import queryString from 'query-string';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { AppMessage, NAV_ID, RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import WoundsView, { WoundsViewProps } from './WoundsView';

interface WoundProps extends RouteComponentProps {
  showConfirmationModal: Function;
  enableMultiStepsAuthoring: Function;
}

interface WoundsState {
  fragment: ChartFragment;
  fragments: ChartFragment[];
}

class Wounds extends Component<WoundProps & ChartComponentProps, WoundsState> {
  static displayName = 'Wounds';

  constructor(props) {
    super(props);
    this.state = {
      fragment: null,
      fragments: null
    };
  }

  componentDidMount() {
    const id = this.getIdFromParam(this.props);
    this.loadData(id);
    this.props.enableMultiStepsAuthoring(true);
  }

  componentDidUpdate(prevProps) {
    const prevId = this.getIdFromParam(prevProps);
    const id = this.getIdFromParam(this.props);
    if (id !== prevId && !id) {
      this.setState({ fragment: null });
      this.props.initState(this.buildDefaultFormFields());
    }
  }

  getIdFromParam = (props: WoundProps) => {
    const parsedQuery = queryString.parse(props.location.search);
    return parsedQuery.id;
  };

  loadData = (id) =>
    appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        let chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS && fragment.fragmentId === id && fragment.active);
        // Prevent update an healed wound
        if (this.isHealedWoundExisted(fragments, chartFragment)) {
          chartFragment = null;
          const woundEntryPage = RoutePath.student.wounds.wounds.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId));
          this.props.history.push(woundEntryPage);
        }
        this.setState({ fragment: chartFragment, fragments });
        this.props.initState(this.buildDefaultFormFields(chartFragment));
      }),
      { errorMessage: AppMessage.NO_CHART_DATA }
    );

  isHealedWoundExisted = (fragments: ChartFragment[], fragment: ChartFragment) => {
    if (!fragment) return false;
    const fragmentList = fragments.filter((frag) => frag.linkedFragmentId === (fragment.linkedFragmentId || fragment.fragmentId));
    return fragmentList.some((frag) => frag.active && chartHelper.getWoundSavedValue(frag, FormField.WOUND_HEALED, 'content')?.[0]);
  };

  buildDefaultFormFields = (fragment?: ChartFragment): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    // set map items
    const checkboxes = [FormField.MEDICAL_DEVICE, FormField.WOUND_HEALED];
    const dropdowns = [FormField.WOUND_CATEGORY, FormField.WOUND_TYPE];
    const multiselectDropdowns = [
      FormField.WOUND_AREA,
      FormField.PERIWOUND_AREA,
      FormField.SOLUTION_USED,
      FormField.PATIENT_RESPONSE,
      FormField.WOUND_DRAINAGE_EXUDATE,
      FormField.DRESSING_WOUND_CARE,
      FormField.INTERVENTIONS
    ];
    const multiselectRadios = [FormField.LOCATION];
    const textAreas = [FormField.NOTES];
    const textBoxes = [FormField.LENGTH, FormField.WIDTH, FormField.DEPTH, FormField.SOLUTION_USED_OTHER];
    const textBlocks = [FormField.FOOT_NOTE];

    const formFieldSectionMapping = {
      [FormField.LENGTH]: SectionTitle.WOUNDS,
      [FormField.WIDTH]: SectionTitle.WOUNDS,
      [FormField.DEPTH]: SectionTitle.WOUNDS,
      [FormField.WOUND_AREA]: SectionTitle.WOUND_AREA,
      [FormField.PERIWOUND_AREA]: SectionTitle.PERIWOUND_AREA,
      [FormField.SOLUTION_USED]: SectionTitle.SOLUTION_USED,
      [FormField.PATIENT_RESPONSE]: SectionTitle.PATIENT_RESPONSE,
      [FormField.WOUND_DRAINAGE_EXUDATE]: SectionTitle.WOUND_DRAINAGE_EXUDATE,
      [FormField.DRESSING_WOUND_CARE]: SectionTitle.DRESSING_WOUND_CARE,
      [FormField.INTERVENTIONS]: SectionTitle.INTERVENTIONS,
      [FormField.SOLUTION_USED_OTHER]: SectionTitle.SOLUTION_USED,
      [FormField.NOTES]: SectionTitle.NOTES
    };

    checkboxes.forEach((name) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.CHECK_BOX, contentIds: chartHelper.getWoundSavedValue(fragment, name, 'contentId') }))
    );
    dropdowns.forEach((name) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.DROPDOWN, contentIds: chartHelper.getWoundSavedValue(fragment, name, 'contentId') }))
    );
    multiselectDropdowns.forEach((name) =>
      dataMap.set(
        name,
        createFormField({ name, type: FormFieldInputType.MULTISELECT_DROPDOWN, contentIds: chartHelper.getFragmentContentIds(fragment, formFieldSectionMapping[name], name) })
      )
    );
    multiselectRadios.forEach((name) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.MULTI_SELECT_RADIO, contentIds: chartHelper.getWoundSavedValue(fragment, name, 'contentId') }))
    );
    textAreas.forEach((name) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_AREA, value: chartHelper.getFragmentValue(fragment, formFieldSectionMapping[name], name) }))
    );
    textBoxes.forEach((name) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, value: chartHelper.getFragmentValue(fragment, formFieldSectionMapping[name], name) }))
    );
    textBlocks.forEach((name) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BLOCK })));

    // set unique labels
    dataMap.set(FormField.LOCATION, { ...dataMap.get(FormField.LOCATION), label: FormFieldLabel.LOCATION });
    dataMap.set(FormField.WOUND_CATEGORY, { ...dataMap.get(FormField.WOUND_CATEGORY), label: FormFieldLabel.WOUND_CATEGORY });
    dataMap.set(FormField.WOUND_TYPE, { ...dataMap.get(FormField.WOUND_TYPE), label: FormFieldLabel.WOUND_TYPE });
    dataMap.set(FormField.LENGTH, { ...dataMap.get(FormField.LENGTH), label: FormFieldLabel.LENGTH });
    dataMap.set(FormField.WIDTH, { ...dataMap.get(FormField.WIDTH), label: FormFieldLabel.WIDTH });
    dataMap.set(FormField.DEPTH, { ...dataMap.get(FormField.DEPTH), label: FormFieldLabel.DEPTH });
    dataMap.set(FormField.SOLUTION_USED_OTHER, { ...dataMap.get(FormField.SOLUTION_USED_OTHER), label: FormFieldLabel.OTHER });

    const bodyRegionContent = chartHelper.getWoundSavedValue(fragment, FormField.BODY_REGION, 'contentId');
    if (!fragment || bodyRegionContent?.length) {
      dataMap.set(
        FormField.BODY_REGION,
        createFormField({
          name: FormField.BODY_REGION,
          type: FormFieldInputType.MULTI_SELECT_RADIO,
          contentIds: chartHelper.getWoundSavedValue(fragment, FormField.BODY_REGION, 'contentId'),
          label: FormFieldLabel.BODY_REGION
        })
      );
    }

    return dataMap;
  };

  handleSaveClick = () => {
    const { fragment, fragments } = this.state;
    const { formFieldMap } = this.props;
    const curLocation = formFieldMap.get(FormField.LOCATION).value;
    const isSavingActiveWound = !formFieldMap.get(FormField.WOUND_HEALED).value;

    const needShowWarning = isSavingActiveWound && !fragment?.fragmentId && this.isLocationOfActiveWoundsExisted(fragments, curLocation);

    if (needShowWarning) {
      this.props.showConfirmationModal({
        showIcon: true,
        header: 'Please confirm.',
        message: 'You already have an active wound at this location. Are you sure you want to add another?',
        onOkClick: this.saveChart
      });
    } else {
      this.saveChart();
    }
  };

  isLocationOfActiveWoundsExisted = (fragments, newLocation: string) => {
    const fragmentList = fragments.filter((frag) => {
      const location = chartHelper.getWoundSavedValue(frag, FormField.LOCATION, 'value')?.[0];
      return frag.active && location === newLocation;
    });

    let result = false;

    const fragmentGroups = chartHelper.groupFragmentsById(fragmentList);
    fragmentGroups.forEach((fragmentGroup) => {
      const isWoundHealed = fragmentGroup.some((frag) => chartHelper.getWoundSavedValue(frag, FormField.WOUND_HEALED, 'content')?.[0]);
      if (!isWoundHealed) {
        result = true;
      }
    });

    return result;
  };

  saveChart = () => {
    const loadedFragment = this.state.fragment;
    const fragment = this.buildFragment();
    const defaultFormFields = this.buildDefaultFormFields(loadedFragment);
    return this.props.saveChart([fragment], { defaultFormFields, afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  buildFragment = () => {
    const { isAuthor } = this.props;
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Wounds';
    const record = {
      chartTitle: 'Wounds',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.WOUNDS,
          records: [
            buildPatientRecord(formFieldMap, FormField.MEDICAL_DEVICE),
            buildPatientRecord(formFieldMap, FormField.WOUND_HEALED),
            buildPatientRecord(formFieldMap, FormField.BODY_REGION),
            buildPatientRecord(formFieldMap, FormField.LOCATION),
            buildPatientRecord(formFieldMap, FormField.WOUND_CATEGORY),
            buildPatientRecord(formFieldMap, FormField.WOUND_TYPE),
            buildPatientRecord(formFieldMap, FormField.LENGTH, null, `${formFieldMap.get(FormField.LENGTH).value} cm`),
            buildPatientRecord(formFieldMap, FormField.WIDTH, null, `${formFieldMap.get(FormField.WIDTH).value} cm`),
            buildPatientRecord(formFieldMap, FormField.DEPTH, null, `${formFieldMap.get(FormField.DEPTH).value} cm`)
          ]
        },
        {
          sectionTitle: SectionTitle.WOUND_AREA,
          records: buildPatientRecords(formFieldMap, FormField.WOUND_AREA)
        },
        {
          sectionTitle: SectionTitle.PERIWOUND_AREA,
          records: buildPatientRecords(formFieldMap, FormField.PERIWOUND_AREA)
        },
        {
          sectionTitle: SectionTitle.SOLUTION_USED,
          records: [...buildPatientRecords(formFieldMap, FormField.SOLUTION_USED), buildPatientRecord(formFieldMap, FormField.SOLUTION_USED_OTHER)]
        },
        {
          sectionTitle: SectionTitle.PATIENT_RESPONSE,
          records: buildPatientRecords(formFieldMap, FormField.PATIENT_RESPONSE)
        },
        {
          sectionTitle: SectionTitle.WOUND_DRAINAGE_EXUDATE,
          records: buildPatientRecords(formFieldMap, FormField.WOUND_DRAINAGE_EXUDATE)
        },
        {
          sectionTitle: SectionTitle.DRESSING_WOUND_CARE,
          records: buildPatientRecords(formFieldMap, FormField.DRESSING_WOUND_CARE)
        },
        {
          sectionTitle: SectionTitle.INTERVENTIONS,
          records: buildPatientRecords(formFieldMap, FormField.INTERVENTIONS)
        },
        {
          sectionTitle: SectionTitle.NOTES,
          records: [buildPatientRecord(formFieldMap, FormField.NOTES)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = chartHelper.createFragmentId(NAV_ID.WOUNDS);
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    let linkedFragmentId;
    const loadedFragment = this.state.fragment;
    if (!loadedFragment) {
      linkedFragmentId = fragmentId;
    } else {
      linkedFragmentId = loadedFragment.linkedFragmentId || loadedFragment.fragmentId;
    }
    return { ...basicInfo, linkedFragmentId, groupFragmentRef: isAuthor ? linkedFragmentId : undefined, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields(this.state.fragment));
    appHelper.scrollTop();
  };

  render() {
    const { fragment } = this.state;
    const chartActionsProps: ChartActionsComponentProps = {
      cancelButtonText: 'Cancel',
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: () => this.props.displayAuthoringData(),
      isMultiStepsAuthoring: true,
      onSaveClick: this.handleSaveClick,
      saveButtonHasIcon: true,
      saveButtonText: 'Save and Continue'
    };

    const viewProps: WoundsViewProps = {
      chartActionsProps,
      chartMetaFormFields: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      fragment
    };
    return <WoundsView {...viewProps} />;
  }
}

const enhancers = [withRouter, withSavedPatientChartsPage, withChartLogic];

export { Wounds as BaseWounds };
export default compose(...enhancers)(Wounds);

import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { buildPatientRecord } from 'services/chart.service';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import PCAFlowsheetView, { PCAFlowsheetViewProps } from './PCAFlowsheetView';
import { Title } from '../shared/constants';

interface PCAFlowsheetState {
  fragments: ChartFragment[];
  chartHistory: ChartFragment[];
}

class PCAFlowsheet extends Component<ChartComponentProps, PCAFlowsheetState> {
  static displayName = 'PCAFlowsheet';

  constructor(props) {
    super(props);
    this.state = {
      fragments: [],
      chartHistory: []
    };
  }

  componentDidMount() {
    this.loadPCAFlowsheetData();
  }

  loadPCAFlowsheetData = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.useLoader(
      this.props.loadChartData().then(({ data }) => {
        const records = data.map((fragment) => {
          const { fragmentId: id, active, chartingAt, creator, modifier } = fragment;
          return { id, active, chartingAt, creator, modifier, ...fragment.chartData };
        });
        this.setState({ fragments: data, chartHistory: records });
      }),
      { errorMessage: 'can not load chart fragment' }
    );
  };

  deleteHistory = (record) => {
    const updateFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(
      this.props
        .saveChartData({ ...updateFragment, active: false })
        .then(this.loadPCAFlowsheetData)
        .then(this.props.showDeleteSuccess)
    );
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    // set map items
    const textBoxes = [
      {
        name: FormField.ORDER_MAINTENANCE_IV_RATE,
        label: SectionTitle.ORDER_MAINTENANCE_IV_RATE
      },
      {
        name: FormField.ORDER_MAINTENANCE_IV_ML,
        label: SectionTitle.ORDER_MAINTENANCE_IV_ML
      },
      {
        name: FormField.ORDER_ANALGESIA_OTHER,
        label: ''
      },
      {
        name: FormField.ORDER_STARTING_DOSE_VOLUME_OTHER,
        label: ''
      },
      {
        name: FormField.ORDER_DELAY_INTERVAL_OTHER,
        label: ''
      },
      {
        name: FormField.ORDER_STARTING1_HOUR_LIMIT_OTHER,
        label: ''
      },
      {
        name: FormField.ORDER_BOLUS_LOADING_OTHER,
        label: ''
      },
      {
        name: FormField.MONITORING_RESPIRATORY_RATE,
        label: SectionTitle.MONITORING_RESPIRATORY_RATE
      },
      {
        name: FormField.MONITORING_PAIN_ASSESSMENT_SCORE,
        label: SectionTitle.MONITORING_PAIN_ASSESSMENT_SCORE
      },
      {
        name: FormField.MONITORING_OXYGEN_SATURATION,
        label: SectionTitle.MONITORING_OXYGEN_SATURATION
      },
      {
        name: FormField.ORDER_BASAL_RATE,
        label: SectionTitle.ORDER_BASAL_RATE
      }
    ];

    const radioChoices = [
      {
        name: FormField.ORDER_ANALGESIA,
        label: SectionTitle.ORDER_ANALGESIA
      },
      {
        name: FormField.ORDER_STARTING_DOSE_VOLUME,
        label: SectionTitle.ORDER_STARTING_DOSE_VOLUME
      },
      {
        name: FormField.ORDER_DELAY_INTERVAL,
        label: SectionTitle.ORDER_DELAY_INTERVAL
      },
      {
        name: FormField.ORDER_STARTING1_HOUR_LIMIT,
        label: SectionTitle.ORDER_STARTING1_HOUR_LIMIT
      },
      {
        name: FormField.ORDER_BOLUS_LOADING,
        label: SectionTitle.ORDER_BOLUS_LOADING
      },
      {
        name: FormField.ORDER_UNRELIEVED_PAIN,
        label: SectionTitle.ORDER_UNRELIEVED_PAIN
      },
      {
        name: FormField.MONITORING_EXCESSIVE_SEDATION,
        label: SectionTitle.MONITORING_EXCESSIVE_SEDATION
      },
      {
        name: FormField.MONITORING_PERSISTENT_PAIN,
        label: SectionTitle.MONITORING_PERSISTENT_PAIN
      },
      {
        name: FormField.MONITORING_NAUSEA,
        label: SectionTitle.MONITORING_NAUSEA
      },
      {
        name: FormField.MONITORING_VOMITING,
        label: SectionTitle.MONITORING_VOMITING
      },
      {
        name: FormField.MONITORING_ITCHING,
        label: SectionTitle.MONITORING_ITCHING
      },
      {
        name: FormField.MONITORING_PROVIDER_NOTIFIED_OF_PAIN,
        label: SectionTitle.MONITORING_PROVIDER_NOTIFIED_OF_PAIN
      }
    ];

    textBoxes.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.TEXT_BOX, label })));
    radioChoices.forEach(({ name, label }) => dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, label })));

    dataMap.set(
      FormField.MONITORING_BRIEF_NOTES,
      createFormField({
        name: FormField.MONITORING_BRIEF_NOTES,
        type: FormFieldInputType.TEXT_AREA,
        label: SectionTitle.MONITORING_BRIEF_NOTES
      })
    );

    dataMap.set(
      FormField.MONITORING_OXYGEN_SATURATION_O2,
      createFormField({
        name: FormField.MONITORING_OXYGEN_SATURATION_O2,
        type: FormFieldInputType.CHECK_BOX
      })
    );

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap } = this.props;
    return [
      {
        sectionTitle: 'Order',
        records: [
          buildPatientRecord(formFieldMap, FormField.ORDER_MAINTENANCE_IV_RATE),
          buildPatientRecord(formFieldMap, FormField.ORDER_MAINTENANCE_IV_ML),
          buildPatientRecord(formFieldMap, FormField.ORDER_ANALGESIA),
          buildPatientRecord(formFieldMap, FormField.ORDER_ANALGESIA_OTHER, SectionTitle.ORDER_ANALGESIA_OTHER),
          buildPatientRecord(formFieldMap, FormField.ORDER_STARTING_DOSE_VOLUME),
          buildPatientRecord(formFieldMap, FormField.ORDER_STARTING_DOSE_VOLUME_OTHER, SectionTitle.ORDER_STARTING_DOSE_VOLUME_OTHER),
          buildPatientRecord(formFieldMap, FormField.ORDER_DELAY_INTERVAL),
          buildPatientRecord(formFieldMap, FormField.ORDER_DELAY_INTERVAL_OTHER, SectionTitle.ORDER_DELAY_INTERVAL_OTHER),
          buildPatientRecord(formFieldMap, FormField.ORDER_BASAL_RATE),
          buildPatientRecord(formFieldMap, FormField.ORDER_STARTING1_HOUR_LIMIT),
          buildPatientRecord(formFieldMap, FormField.ORDER_STARTING1_HOUR_LIMIT_OTHER, SectionTitle.ORDER_STARTING1_HOUR_LIMIT_OTHER),
          buildPatientRecord(formFieldMap, FormField.ORDER_BOLUS_LOADING),
          buildPatientRecord(formFieldMap, FormField.ORDER_BOLUS_LOADING_OTHER, SectionTitle.ORDER_BOLUS_LOADING_OTHER),
          buildPatientRecord(formFieldMap, FormField.ORDER_UNRELIEVED_PAIN)
        ]
      },
      chartHelper.buildSection({
        sectionTitle: 'Monitoring',
        fields: [
          FormField.MONITORING_RESPIRATORY_RATE,
          FormField.MONITORING_PAIN_ASSESSMENT_SCORE,
          FormField.MONITORING_OXYGEN_SATURATION,
          FormField.MONITORING_OXYGEN_SATURATION_O2,
          FormField.MONITORING_EXCESSIVE_SEDATION,
          FormField.MONITORING_PERSISTENT_PAIN,
          FormField.MONITORING_NAUSEA,
          FormField.MONITORING_VOMITING,
          FormField.MONITORING_ITCHING,
          FormField.MONITORING_PROVIDER_NOTIFIED_OF_PAIN,
          FormField.MONITORING_BRIEF_NOTES
        ],
        formFieldMap
      })
    ];
  };

  handleSaveClick = () => {
    const record = {
      chartTitle: Title.SPECIAL_CHARTS,
      fragmentTitle: SectionTitle.PCA_FLOWSHEET,
      // think of this "records" as sections
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });

    const fragment = { ...basicInfo, chartData: cleanRecord } as ChartFragment;
    this.props.saveChart([fragment], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.props.backToSourceLocation || this.loadPCAFlowsheetData });
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps: PCAFlowsheetViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      firstInitials: this.props.assessment?.firstName?.substring(0, 1) || '',
      lastName: this.props.assessment?.lastName || '',
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory
    };
    return <PCAFlowsheetView {...viewProps} />;
  }
}

export { PCAFlowsheet as BasePCAFlowsheet };
export default compose(withSavedPatientChartsPage, withChartLogic)(PCAFlowsheet);

import { ELSDataTable, ELSDropDown } from 'components/common/els';
import { ChartTable, ColumnAlign, DropDownItem } from '../../../models/ui';
import { Box, HorizontalScrollableContainer } from '../../common';

export interface MetadataTableViewProps {
  chartFieldDataSource: ChartTable;
  chartFieldContentDataSource: ChartTable;
  chartFieldValidationDataSource: ChartTable;
  tableName?: string;
  filterOptions: DropDownItem[];
  onFilterChange: Function;
  onValidationFilterChange: Function;
  chartFieldFilterBy: string;
  handleContentRowClick: Function;
  handleChartFieldRowClick: Function;
  handleValidationRowClick: Function;
  customRenderNav: Function;
  customRenderDataPoint: Function;
  customRenderChartField: Function;
  customRenderParentId: Function;
  customRenderChartFieldDelete: Function;
  customRenderContentDelete: Function;
  customRenderValidationDelete: Function;
  customRenderContentId: Function;
}
const MetadataTableView = (props: MetadataTableViewProps) => (
  <div className="metadata-tool-table" aria-hidden>
    <h2>Chart Field Table</h2>
    <Box mt className="u-overflow-x--auto u-overflow-y--hidden">
      <HorizontalScrollableContainer>
        <ELSDataTable handleRowClick={props.handleChartFieldRowClick} data={props.chartFieldDataSource.data} defaultSortDirection="desc">
          {props.chartFieldDataSource.headers.map((col) => {
            if (col.field === 'id') {
              return <column key={col.field} field={col.field} header={col.text} sortable />;
            }
            if (col.field === 'navigationElement') {
              return <column key={col.field} customRender={props.customRenderNav} field={col.field} header={col.text} />;
            }
            if (col.field === 'formFieldId') {
              return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} />;
            }

            if (col.field === 'isDataPoint') {
              return <column key={col.field} customRender={props.customRenderDataPoint} field={col.field} header={col.text} align={ColumnAlign.Left} />;
            }
            return <column key={col.field} customRender={props.customRenderChartFieldDelete} align={ColumnAlign.Left} section={col.section} />;
          })}
        </ELSDataTable>
      </HorizontalScrollableContainer>
      <br />
      <br />
      <div className="metadata-tool-table" aria-hidden>
        <h2>Content Table</h2>
        <Box mt className="u-overflow-x--auto u-overflow-y--hidden">
          <h2>Filter By:</h2>
          <ELSDropDown inline changeHandler={props.onFilterChange} options={props.filterOptions} size="50" />
          <HorizontalScrollableContainer>
            <ELSDataTable handleRowClick={props.handleContentRowClick} data={props.chartFieldContentDataSource.data} defaultSortDirection="desc">
              {props.chartFieldContentDataSource.headers.map((col) => {
                if (col.field === 'id') {
                  return <column key={col.field} field={col.field} header={col.text} sortable />;
                }
                if (col.field === 'chartField') {
                  return <column key={col.field} customRender={props.customRenderChartField} field={col.field} sortable header={col.text} />;
                }
                if (col.field === 'value') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                if (col.field === 'label') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                if (col.field === 'order') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} />;
                }

                if (col.field === 'dataType') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                return <column key={col.field} customRender={props.customRenderContentDelete} align={ColumnAlign.Left} section={col.section} />;
              })}
            </ELSDataTable>
          </HorizontalScrollableContainer>
        </Box>
      </div>
      <br />
      <br />
      <div className="metadata-tool-table" aria-hidden>
        <h2>Validation Table</h2>
        <Box mt className="u-overflow-x--auto u-overflow-y--hidden">
          <h2>Filter By:</h2>
          <ELSDropDown inline changeHandler={props.onValidationFilterChange} options={props.filterOptions} size="50" />
          <HorizontalScrollableContainer>
            <ELSDataTable handleRowClick={props.handleValidationRowClick} data={props.chartFieldValidationDataSource.data} defaultSortDirection="desc">
              {props.chartFieldValidationDataSource.headers.map((col) => {
                if (col.field === 'id') {
                  return <column key={col.field} field={col.field} header={col.text} />;
                }
                if (col.field === 'chartField') {
                  return <column key={col.field} customRender={props.customRenderChartField} field={col.field} sortable header={col.text} />;
                }
                if (col.field === 'validationType') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                if (col.field === 'validationActionType') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                if (col.field === 'message') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} />;
                }

                if (col.field === 'parentValidation') {
                  return <column key={col.field} customRender={props.customRenderParentId} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                if (col.field === 'minRange') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} />;
                }

                if (col.field === 'maxRange') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} />;
                }

                if (col.field === 'regex') {
                  return <column key={col.field} field={col.field} header={col.text} align={ColumnAlign.Left} />;
                }

                if (col.field === 'chartFieldContentSelection') {
                  return <column key={col.field} customRender={props.customRenderContentId} field={col.field} header={col.text} align={ColumnAlign.Left} sortable />;
                }

                return <column key={col.field} customRender={props.customRenderValidationDelete} align={ColumnAlign.Left} section={col.section} />;
              })}
            </ELSDataTable>
          </HorizontalScrollableContainer>
        </Box>
      </div>
      <br />
      <br />
    </Box>
  </div>
);

MetadataTableView.displayName = 'MetadataTableView';
export default MetadataTableView;

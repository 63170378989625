import { Box } from 'components/common';
import EditChartSection, { EditChartSectionProps } from './EditChartSection';
import './edit.chart.scss';

export interface EditChartViewProps {
  selectedFragmentSection: EditChartSectionProps;
  linkedFragmentSections?: EditChartSectionProps[];
  hideDelete?: boolean;
  onCloseClick: Function;
}

const EditChartView = (props: EditChartViewProps) => {
  return (
    <>
      <Box mt3>
        <h2>View Chart</h2>
      </Box>
      {!props.linkedFragmentSections && (
        <EditChartSection useBlueBackground={props.selectedFragmentSection.status === 'Reinforced'} {...props.selectedFragmentSection} hideDelete={props.hideDelete} />
      )}
      {props.linkedFragmentSections?.length > 0 &&
        props.linkedFragmentSections.map((fragmentSection: EditChartSectionProps) => (
          <EditChartSection key={fragmentSection.id} {...fragmentSection} useBlueBackground={fragmentSection.status === 'Reinforced'} hideDelete={props.hideDelete} />
        ))}
    </>
  );
};

EditChartView.displayName = 'EditChartView';
export default EditChartView;

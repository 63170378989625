import { DropDownItem } from 'models/ui';
import { Box } from 'components/common';
import { ELSButton, ELSFlexItem } from 'components/common/els';
import { ChartFieldContentMeta, ChartFieldMeta, ChartFieldValidationMeta } from './MetadataInput';
import { ELSDropDown, ELSTextBox } from '../../common/els';

export interface MetadataInputViewProps {
  selectedChartField?: ChartFieldMeta;
  selectedChartContent?: ChartFieldContentMeta;
  selectedValidation?: ChartFieldValidationMeta;
  onIdChange: Function;
  onNavElementChange: Function;
  onFormFieldChange: Function;
  onDataPointChange: Function;
  onChartFieldContentIdChange: Function;
  onChartFieldIdChange: Function;
  onChartFieldVersionChange: Function;
  onChartFieldActiveChange: Function;
  onChartFieldValueChange: Function;
  onChartFieldLabelChange: Function;
  onChartFieldOrderChange: Function;
  onChartFieldDataTypeChange: Function;
  onChartFieldSaveClick: Function;
  onChartFieldContentSaveClick: Function;
  onChartFieldValidationSaveClick: Function;
  onChartFieldValidationIdChange: Function;
  onChartFieldIdValidationChange: Function;
  onChartFieldValidationVersionChange: Function;
  onChartFieldValidationActiveChange: Function;
  onValidationTypeChange: Function;
  onValidationActionTypeChange: Function;
  onMessageChange: Function;
  onParentValidationChange: Function;
  onContentSelectionChange: Function;
  onMinRangeChange: Function;
  onMaxRangeChange: Function;
  onRegexChange: Function;
  dropDownItems: DropDownItem[];
  dataTypeDropDownItems: DropDownItem[];
  orderDropDownItems: DropDownItem[];
  chartFieldOptions: DropDownItem[];
  parentValidationIdOptions: DropDownItem[];
  validationTypeOptions: DropDownItem[];
  validationActionTypeOptions: DropDownItem[];
  contentSelectionIdOptions: DropDownItem[];
}

const MetadataInputView = (props: MetadataInputViewProps) => (
  <Box pb3>
    <div className="metadata-tool-common">
      <div className="flex-container">
        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Chart Field Id</h4>
          <ELSTextBox value={props.selectedChartField?.id} changeHandler={props.onIdChange} cssClasses="chart-input__textbox chart-input__input--pull-right" isDisabled={false} />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Navigation Element Id</h4>
          <ELSTextBox
            value={props.selectedChartField?.navigationElement?.id}
            changeHandler={props.onNavElementChange}
            cssClasses="chart-input__textbox chart-input__input--pull-right"
            isDisabled={false}
          />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Form Field Id</h4>
          <ELSTextBox
            value={props.selectedChartField?.formFieldId}
            changeHandler={props.onFormFieldChange}
            cssClasses="chart-input__textbox chart-input__input--pull-right"
            isDisabled={false}
          />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Data Point</h4>
          <ELSDropDown value={props.selectedChartField?.isDataPoint} changeHandler={props.onDataPointChange} inline options={props.dropDownItems} size="50" />
        </ELSFlexItem>

        <div className="u-els-color-primary chart-input__input--pull-right o-els-flex-layout--center u-els-margin-2x">
          <ELSButton onClick={props.onChartFieldSaveClick} text="Save" />
        </div>
      </div>

      <div className="flex-container">
        <ELSFlexItem className="o-els-flex-layout__item u-els-margin-1x">
          <h4 className="u-els-color-primary">Content Id</h4>
          <ELSTextBox
            value={props.selectedChartContent?.id}
            changeHandler={props.onChartFieldContentIdChange}
            cssClasses="chart-input__textbox chart-input__input--pull-right"
            isDisabled={false}
          />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Chart Field Id</h4>
          <ELSDropDown value={props.selectedChartContent?.chartField?.id} inline changeHandler={props.onChartFieldIdChange} options={props.chartFieldOptions} size="50" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Value</h4>
          <ELSTextBox
            value={props.selectedChartContent?.value}
            changeHandler={props.onChartFieldValueChange}
            cssClasses="chart-input__textbox chart-input__input--pull-right"
            isDisabled={false}
          />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Label</h4>
          <ELSTextBox
            value={props.selectedChartContent?.label}
            changeHandler={props.onChartFieldLabelChange}
            cssClasses="chart-input__textbox chart-input__input--pull-right"
            isDisabled={false}
          />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Order</h4>
          <ELSDropDown value={props.selectedChartContent?.order} changeHandler={props.onChartFieldOrderChange} inline options={props.orderDropDownItems} size="50" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Data Type</h4>
          <ELSDropDown value={props.selectedChartContent?.dataType} changeHandler={props.onChartFieldDataTypeChange} inline options={props.dataTypeDropDownItems} size="50" />
        </ELSFlexItem>

        <div className="u-els-color-primary u-els-margin-top-2x">
          <ELSButton onClick={props.onChartFieldContentSaveClick} text="Save" />
        </div>
      </div>

      <div className="flex-container">
        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Chart Field Validation Id</h4>
          <ELSTextBox value={props.selectedValidation?.id} changeHandler={props.onChartFieldValidationIdChange} cssClasses="chart-input__textbox" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Chart Field Id</h4>
          <ELSDropDown value={props.selectedValidation?.chartField?.id} inline changeHandler={props.onChartFieldIdValidationChange} options={props.chartFieldOptions} />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Validation Type</h4>
          <ELSDropDown value={props.selectedValidation?.validationType} inline changeHandler={props.onValidationTypeChange} options={props.validationTypeOptions} />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Validation Action Type</h4>
          <ELSDropDown
            value={props.selectedValidation?.validationActionType}
            inline
            changeHandler={props.onValidationActionTypeChange}
            options={props.validationActionTypeOptions}
          />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Message</h4>
          <ELSTextBox value={props.selectedValidation?.message} changeHandler={props.onMessageChange} cssClasses="chart-input__textbox chart-input__input--pull-right" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Parent Validation</h4>
          <ELSDropDown value={props.selectedValidation?.parentValidation?.id} inline changeHandler={props.onParentValidationChange} options={props.parentValidationIdOptions} />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Min Range</h4>
          <ELSTextBox value={props.selectedValidation?.minRange} changeHandler={props.onMinRangeChange} cssClasses="chart-input__textbox chart-input__input--pull-right" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Max Range</h4>
          <ELSTextBox value={props.selectedValidation?.maxRange} changeHandler={props.onMaxRangeChange} cssClasses="chart-input__textbox chart-input__input--pull-right" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Regex</h4>
          <ELSTextBox value={props.selectedValidation?.regex} changeHandler={props.onRegexChange} cssClasses="chart-input__textbox chart-input__input--pull-right" />
        </ELSFlexItem>

        <ELSFlexItem className="u-els-margin-1x">
          <h4 className="u-els-color-primary">Chart Field Content Selection</h4>
          <ELSDropDown
            value={props.selectedValidation?.chartFieldContentSelection?.id}
            inline
            changeHandler={props.onContentSelectionChange}
            options={props.contentSelectionIdOptions}
          />
        </ELSFlexItem>

        <div className="u-els-color-primary u-els-margin-top-2x">
          <ELSButton onClick={props.onChartFieldValidationSaveClick} text="Save" />
        </div>
      </div>
    </div>
  </Box>
);
export default MetadataInputView;

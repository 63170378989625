import { Box, MultiLine } from 'components/common';
import { ELSIcon } from 'components/common/els';

interface AssignmentInstructionProps {
  instruction: string;
}

const AssignmentInstruction = (props: AssignmentInstructionProps) => {
  const { instruction } = props;
  return (
    <Box mt>
      {instruction ? (
        <MultiLine text={instruction} />
      ) : (
        <>
          <ELSIcon prefix="gizmo" name="information" title="" size="1x" align="sub" />
          <span id="qa-no-instruction" className="u-els-margin-left-1o2">
            Your instructor did not include any assignment instructions.
          </span>
        </>
      )}
    </Box>
  );
};

AssignmentInstruction.displayName = 'AssignmentInstruction';
export default AssignmentInstruction;

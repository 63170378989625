import './inactive.order.tooltip.scss';

const InactiveOrderTooltip = () => (
  <div className="inactive-orders-tooltip">
    <ul className="inactive-orders-tooltip__list">
      <li>Medications become Inactive when they are completed, discontinued, past due, or expired.</li>
      <li>Past due medications, can still be administered, but a reason for administering outside the scheduled time must be provided.</li>
      <li>Medications expire after 7 days, unless an Order Stop Date/Time was submitted via Order Entry.</li>
      <li>Notify the physician for renewal orders. Any subsequent dosing will have to accompany a new order. New medication orders are submitted via Order Entry.</li>
    </ul>
  </div>
);

InactiveOrderTooltip.displayName = 'InactiveOrderTooltip';
export default InactiveOrderTooltip;

import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { AuthoringFakeData, Locales, ParamKeys, ParamValues, RouteParams } from 'constants/app.constant';
import { getParamByKeys } from 'helpers/app.helper';
import { watchLocaleChange } from 'helpers/intl.helper';
import { appActions, appSelectors } from 'redux/ducks/app';
import { withHTMLHeadSEO } from 'components/common';
import InstructorAuthorHomeView, { InstructorAuthorHomeViewProps } from './InstructorAuthorHomeView';

const toChartUrl = (url: string): string => `#${url.replace(RouteParams.ASSESSMENT_ID, AuthoringFakeData.assessmentId)}`;

export interface InstructorAuthorHomePageProps extends RouteComponentProps<ParamValues> {
  intl: IntlShape;
  locale: Locales;
  setLocale: (locale: Locales) => void;
}

class InstructorAuthorHomePage extends React.Component<InstructorAuthorHomePageProps> {
  componentDidMount() {
    const { locale, history } = this.props;
    const searchLocale = this.getLocaleParam(this.props);

    watchLocaleChange({ history, locale, searchParamLocale: searchLocale, changeLocaleCb: this.handleChangeLocale });
  }

  componentDidUpdate(prevProps: Readonly<InstructorAuthorHomePageProps>): void {
    const { locale, history } = this.props;
    const searchLocale = this.getLocaleParam(this.props);

    watchLocaleChange<InstructorAuthorHomePageProps>({ history, locale, searchParamLocale: searchLocale, changeLocaleCb: this.handleChangeLocale, prevProps });
  }

  handleChangeLocale = (locale: Locales) => {
    const { setLocale } = this.props;
    setLocale(locale);
  };

  getLocaleParam = (props: InstructorAuthorHomePageProps): Locales => {
    return getParamByKeys<ParamValues>(props.location.search, [ParamKeys.LOCALE]).locale;
  };

  render() {
    const { intl, locale } = this.props;
    const viewProps: InstructorAuthorHomeViewProps = {
      toChartUrl,
      locale,
      intl
    };

    return <InstructorAuthorHomeView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLocale: (locale: Locales) => dispatch(appActions.setLocale(locale))
});

const enhancers = [withHTMLHeadSEO({ title: 'Instructor Homepage' }), connect(mapStateToProps, mapDispatchToProps), injectIntl];

export { InstructorAuthorHomePage as BaseInstructorAuthorHomePage };
export default compose(...enhancers)(InstructorAuthorHomePage);

import { get, isNaN } from 'lodash';

const SEPERATOR_POSIIONS = [2, 5, 8];

/**
 * Format value of time and return value with default length
 * @param {string} value
 * @param {number} length Length of
 * @returns
 */
export const formatTimeItem = (value?: string | number, length?: number): string => {
  return `${value || ''}${'0'.repeat(length || 2)}`.substring(0, length || 2);
};

/**
 * Validate and return formatted value and position
 * @param {boolean} isShowSeconds `true` if show `HH:MM:ss` and false if show `HH:MM`
 * @param {string} value New value of TimeField
 * @param {string} defaultValue Current value of TimeField
 * @param {string} seperator Seperator between hour and minute, default is `:`
 * @param {number} cursorPosition Cursor position
 * @returns [newValue, newPosition]
 */
export const validateTimeAndCursor = (isShowSeconds = false, value = '', defaultValue = '', seperator = ':', cursorPosition = 0): [string, number] => {
  const [currentHour, currentMinute, currentSecond] = [...String(defaultValue).split(seperator), ''];

  let newCursorPosition = Number(cursorPosition);
  let [newHour, newMinute, newSecond] = [...String(value).split(seperator), ''];

  if (newHour.length > 2) {
    newHour = currentHour;
    newCursorPosition -= 1;
  }
  newHour = formatTimeItem(newHour, 2);

  if (newMinute.length > 2) {
    newMinute = currentMinute;
    newCursorPosition -= 1;
  }
  newMinute = formatTimeItem(newMinute, 2);

  if (isShowSeconds) {
    newSecond = formatTimeItem(newSecond, 2);
    if (Number(newSecond[0]) > 5) {
      newSecond = currentSecond;
      newCursorPosition -= 1;
    }
  }

  const validatedValue = isShowSeconds ? `${newHour}${seperator}${newMinute}${seperator}${newSecond}` : `${newHour}${seperator}${newMinute}`;

  return [validatedValue, newCursorPosition];
};

/**
 * Props of class CalculateValueAndPosition
 */
export interface CalculateValueAndPositionProps {
  currentValue: string;
  inputElement: object;
  position: number;
  maxLength: number;
  seperator: string;
}

/**
 * Check if the value is number
 * @param {T} value
 * @returns `true` if value is number and `false` if not
 */
export const isNumber = <T>(value: T): boolean => {
  const number = Number(value);
  return !isNaN(number) && String(value) === String(number);
};

export class CalculateValueAndPosition {
  currentValue: string;
  inputElement: object;
  position: number;
  maxLength: number;
  seperator: string;
  inputValue: string;
  isTyped: boolean;
  cursorCharacter: string;
  addedCharacter: string;
  removedCharacter: string;
  replacedSingleCharacter: string;

  constructor(props: CalculateValueAndPositionProps) {
    const { currentValue, inputElement, position, maxLength, seperator } = props;
    const inputValue = get(inputElement, 'value', '');
    const isTyped = inputValue.length > currentValue.length;
    const cursorCharacter = inputValue[position - 1];
    const addedCharacter = isTyped ? cursorCharacter : null;
    const removedCharacter = isTyped ? null : currentValue[position];
    const replacedSingleCharacter = inputValue.length === currentValue.length ? currentValue[position - 1] : null;

    this.currentValue = currentValue;
    this.inputElement = inputElement;
    this.position = position;
    this.maxLength = maxLength;
    this.seperator = seperator;
    this.inputValue = inputValue;
    this.isTyped = isTyped;
    this.cursorCharacter = cursorCharacter;
    this.addedCharacter = addedCharacter;
    this.removedCharacter = removedCharacter;
    this.replacedSingleCharacter = replacedSingleCharacter;
  }

  /**
   * Main Method.
   * Calculate the value and position after user add/remove/replace character(s).
   * @returns [New Value, New Position]
   */
  calculate(): [string, number] {
    if (this.addedCharacter !== null) {
      return this.calculateWhenAdded();
    }
    return this.calculateWhenReplaced();
  }

  /**
   * Calculate the value and position after user add character(s).
   * @returns [New Value, New Position]
   */
  calculateWhenAdded(): [string, number] {
    const { currentValue, position, maxLength, seperator, inputValue, addedCharacter } = this;
    let newPosition = position;
    let newValue = currentValue;

    if (position > maxLength) {
      // User is typing past the max length
      newPosition = maxLength;
    } else if (SEPERATOR_POSIIONS.includes(position - 1) && addedCharacter === seperator) {
      // User is typing a seperator
      newValue = `${String(inputValue).substring(0, position - 1)}${seperator}${String(inputValue).substring(position + 1)}`;
    } else if (SEPERATOR_POSIIONS.includes(position - 1) && isNumber(addedCharacter)) {
      // User is typing a number on a seperator
      newValue = `${String(inputValue).substring(0, position - 1)}${seperator}${addedCharacter}${String(inputValue).substring(position + 2)}`;
      newPosition = position + 1;
    } else if (isNumber(addedCharacter)) {
      // User is typing a number
      newValue = String(inputValue).substring(0, position - 1) + addedCharacter + String(inputValue).substring(position + 1);
      if (position === 2 || position === 5) {
        newPosition = position + 1;
      }
    } else {
      // User is typing a character
      newPosition = position - 1;
    }
    return [newValue, newPosition];
  }

  /**
   * Calculate the value and position after user remove/replace character(s).
   * @returns [New Value, New Position]
   */
  calculateWhenReplaced(): [string, number] {
    const { position, seperator, currentValue, replacedSingleCharacter, inputValue, cursorCharacter, removedCharacter } = this;
    let newPosition = position;
    let newValue = currentValue;

    if (replacedSingleCharacter !== null) {
      // User is replacing a single character
      if (isNumber(cursorCharacter)) {
        newValue = SEPERATOR_POSIIONS.includes(position - 1) ? `${inputValue.substring(0, position - 1)}${seperator}${inputValue.substring(position)}` : inputValue;
      } else {
        // User is replacing a seperator
        newPosition = position - 1;
      }
    } else if (typeof cursorCharacter !== 'undefined' && cursorCharacter !== seperator && !isNumber(cursorCharacter)) {
      // User is replacing a character that is NOT a number
      newPosition = position - 1;
    } else if (removedCharacter !== null) {
      if (SEPERATOR_POSIIONS.includes(position) && removedCharacter === seperator) {
        // User is replacing a seperator
        newValue = `${inputValue.substring(0, position - 1)}0${seperator}${inputValue.substring(position)}`;
        newPosition = position - 1;
      } else {
        // User is replacing a number
        newValue = `${inputValue.substring(0, position)}0${inputValue.substring(position)}`;
      }
    }

    return [newValue, newPosition];
  }
}

import { LanguageKeys } from 'lang';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { getFragmentContentIds, getFragmentValue } from 'helpers/chart.helper';

export enum FormField {
  ORDERS_PHYSICAL_THERAPY = 'physicalTherapyOrders',
  PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED = 'physicalTherapyProblemsNotRequired',
  PHYSICAL_THERAPY_PROBLEMS_SELECT = 'physicalTherapyProblemsSelect',
  PHYSICAL_THERAPY_PROBLEMS_CONDITION = 'physicalTherapyProblemsCondition',
  PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH = 'patientUsuallyRequiresAssistanceWith',
  FALLS_RISK_PATIENT = 'fallsRiskPatient',
  FALLS_RISK_ADDITIONAL_COMMENTS = 'fallsRiskAdditionalComments',
  ADDITIONAL_INFORMATION = 'additionalInformation',
  ADDITIONAL_INFORMATION_PHYSIOTHERAPIST_REASON_FOR_REFERRAL = 'additionalInformationPhysiotherapistReasonForReferral',
  ADDITIONAL_INFORMATION_OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL = 'additionalInformationOccupationalTherapistReasonForReferral'
}

export const getMobilityPhysicalTherapyFormFieldBuilderItems = (fragment: ChartFragment, locale: Locales): ChartMetaFormFieldParams[] => [
  {
    name: FormField.ORDERS_PHYSICAL_THERAPY,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ORDERS_PHYSICAL_THERAPY,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.MOBILITY_PHYSICAL_THERAPY_SCREEN, FormField.ORDERS_PHYSICAL_THERAPY),
    isHidden: locale === Locales.EN_AU
  },
  {
    name: FormField.PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED,
    type: FormFieldInputType.CHECK_BOX,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS, FormField.PHYSICAL_THERAPY_PROBLEMS_NOT_REQUIRED)
  },
  {
    name: FormField.PHYSICAL_THERAPY_PROBLEMS_SELECT,
    type: FormFieldInputType.MULTISELECT_DROPDOWN,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS, FormField.PHYSICAL_THERAPY_PROBLEMS_SELECT)
  },
  {
    name: FormField.PHYSICAL_THERAPY_PROBLEMS_CONDITION,
    type: FormFieldInputType.RADIO_CHOICE,
    label: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS_CONDITION,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PHYSICAL_THERAPY_PROBLEMS, FormField.PHYSICAL_THERAPY_PROBLEMS_CONDITION)
  },
  {
    name: FormField.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.MARK_ALL_THAT_APPLY,
    contentIds: getFragmentContentIds(
      fragment,
      LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH,
      FormField.PATIENT_USUALLY_REQUIRES_ASSISTANCE_WITH
    ),
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.FALLS_RISK_PATIENT,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.PATIENT_MARK_ALL_THAT_APPLY,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK, FormField.FALLS_RISK_PATIENT),
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.FALLS_RISK_ADDITIONAL_COMMENTS,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_COMMENTS,
    value: getFragmentValue(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.FALLS_RISK, FormField.FALLS_RISK_ADDITIONAL_COMMENTS),
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.ADDITIONAL_INFORMATION,
    type: FormFieldInputType.CHECK_BOX,
    label: LanguageKeys.MARK_ALL_THAT_APPLY,
    contentIds: getFragmentContentIds(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION, FormField.ADDITIONAL_INFORMATION),
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.ADDITIONAL_INFORMATION_PHYSIOTHERAPIST_REASON_FOR_REFERRAL,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.REASON_FOR_REFERRAL,
    value: getFragmentValue(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION, FormField.ADDITIONAL_INFORMATION_PHYSIOTHERAPIST_REASON_FOR_REFERRAL),
    isHidden: locale !== Locales.EN_AU
  },
  {
    name: FormField.ADDITIONAL_INFORMATION_OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL,
    type: FormFieldInputType.TEXT_BOX,
    label: LanguageKeys.MOBILITY_PHYSICAL_THERAPY.REASON_FOR_REFERRAL,
    value: getFragmentValue(fragment, LanguageKeys.MOBILITY_PHYSICAL_THERAPY.ADDITIONAL_INFORMATION, FormField.ADDITIONAL_INFORMATION_OCCUPATIONAL_THERAPIST_REASON_FOR_REFERRAL),
    isHidden: locale !== Locales.EN_AU
  }
];

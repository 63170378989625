import { ChartFragment } from 'models/api-response';
import { AppMessage } from 'constants/app.constant';
import { ChartHistory } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, FormFieldLabel, SectionTitle } from '../shared/constants';

interface InterventionHistoryProps {
  chartHistory: ChartFragment[];
}

const InterventionHistory = (props: InterventionHistoryProps) => {
  const { chartHistory } = props;

  const dataSource = {
    headers: [
      { text: FormFieldLabel.TIME, field: FormField.INTERVENTION_DATE_TIME, section: SectionTitle.NO_SECTION },
      { text: FormFieldLabel.EATING, field: FormField.EATING_ADLS, section: SectionTitle.ADLS },
      { text: FormFieldLabel.GROOMING, field: FormField.GROOMING, section: SectionTitle.ADLS },
      { text: FormFieldLabel.UPPER_EXTREMITY_BATH, field: FormField.UPPER_EXTREMITY_BATH, section: SectionTitle.ADLS },
      { text: FormFieldLabel.LOWER_EXTREMITY_BATH, field: FormField.LOWER_EXTREMITY_BATH, section: SectionTitle.ADLS },
      { text: FormFieldLabel.UPPER_EXTRIMITY_DRESS, field: FormField.UPPER_EXTRIMITY_DRESS, section: SectionTitle.ADLS },
      { text: FormFieldLabel.UPPER_EXTRIMITY_DRESS, field: FormField.UPPER_EXTRIMITY_DRESS, section: SectionTitle.ADLS },
      { text: FormFieldLabel.TOILETING, field: FormField.TOILETING, section: SectionTitle.ADLS },
      { text: FormFieldLabel.EATING, field: FormField.EATING_TRANSFERS, section: SectionTitle.TRANSFERS },
      { text: FormFieldLabel.SHOWER_TUB, field: FormField.SHOWER_TUB, section: SectionTitle.TRANSFERS },
      { text: FormFieldLabel.LOCATION, field: FormField.LOCATION, section: SectionTitle.PAIN },
      { text: FormFieldLabel.THERAPUTIC_EXERCISES, field: FormField.THERAPUTIC_EXERCISES, section: SectionTitle.PAIN },
      { text: FormFieldLabel.TREATMENT, field: FormField.TREATMENT, section: SectionTitle.PAIN },
      { text: FormFieldLabel.PATIENT_FAMILY_TEACHING, field: FormField.PATIENT_FAMILY_TEACHING, section: SectionTitle.PAIN },
      { text: FormFieldLabel.OCCUPATIONAL_THERAPIST_NAME, field: FormField.OCCUPATIONAL_THERAPIST_NAME, section: SectionTitle.PAIN },
      { text: FormFieldLabel.OCCUPATIONAL_THERAPIST_SIGNATURE, field: FormField.OCCUPATIONAL_THERAPIST_SIGNATURE, section: SectionTitle.PAIN }
    ],
    data: chartHistory
  };

  return (
    <div className="intervention">
      <ELSFlex left wrap>
        <ELSFlexItem w={chartHistory.length === 0 ? '1o2' : '1o1'}>
          <ChartHistory
            noTableDataMessage=" "
            renderVertical
            tableTitleContents={[SectionTitle.NO_SECTION, SectionTitle.ADLS, SectionTitle.TRANSFERS, SectionTitle.PAIN]}
            dataSource={dataSource}
          />
        </ELSFlexItem>
        {chartHistory.length === 0 && (
          <ELSFlexItem w="1o2">
            <div className="margin-top-4x1o2 u-els-margin-left table-message">{AppMessage.NO_TABLE_DATA}</div>
          </ELSFlexItem>
        )}
      </ELSFlex>
    </div>
  );
};

InterventionHistory.displayName = 'InterventionHistory';
export default InterventionHistory;

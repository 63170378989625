import { Box, ChartActions, ChartPermission, ErrorFormField, TitleTooltip } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';

const RespiratoryInterventionsView = (props: ChartViewProps) => {
  const { formFieldMap, formSubmittedCount } = props;
  return (
    <div className="respiratory-interventions">
      <h1>Respiratory Interventions</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_CARE)} formSubmittedCount={formSubmittedCount} />
      <ELSFlex className="u-flex-gutter-3x" left wrap>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.RESPIRATORY_CARE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.RESP_INTERVENTIONS_CARE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <TitleTooltip
              className="u-els-margin-bottom"
              titleClassname="u-els-font-size-h2"
              title={SectionTitle.DRAIN_TUBE_CARE}
              tooltip={formFieldMap.get(FormField.RESP_DRAIN_TUBE_CARE)?.labelTooltip || ''}
            />
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.RESP_DRAIN_TUBE_CARE)} formSubmittedCount={formSubmittedCount} enableTooltip={false} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <Box mb>
            <h2>{SectionTitle.PATIENT_RESPONSE}</h2>
          </Box>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.CARE_NOTES)} formSubmittedCount={formSubmittedCount} />
      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};
RespiratoryInterventionsView.displayName = 'RespiratoryInterventionsView';
export default RespiratoryInterventionsView;

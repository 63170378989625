import { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { EventType, LocationType, QuestionType, StepProgress } from 'models/enum';
import { CaseStudySequence, SkinnyAssignment, TimeSpentPartial } from 'models/ui';
import { RouteParams, RoutePath } from 'constants/app.constant';
import { appHelper, assignmentHelper } from 'helpers';
import { caseStudyService } from 'services';
import { getRefreshToken } from 'services/token.service';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import CaseStudyQuizView, { CaseStudyQuizViewProps } from './CaseStudyQuizView';

const { useLoader, isDemoMode } = appHelper;

interface CaseStudyQuizProps {
  assignment: SkinnyAssignment;
  assessmentId: number;
  history: History;
  phaseIndex: number;
  sequenceChunk: CaseStudySequence;
  setDisableClickOutside: () => void;
  setTimeSpentData: Function;
  timeSpentData: TimeSpentPartial;
  isAuthor: boolean;
  userRole: string;
}

interface ButtonProps {
  buttonText: string;
  buttonIsDisabled: boolean;
  onButtonClick: Function;
}

interface CaseStudyQuizState {
  progressText: string;
  questionVtwId: string;
  questionType: string;
  questionPrompt: string;
  questionChoices: {
    [x: string]: string;
  };
  selectedAnswers: string[];
  correctAnswers: string[];
  questionFeedbacks: {
    [x: string]: string;
  };
  textReference: string[];
  isFinalQuestion: boolean;
  isFinalPhase: boolean;
  stepStatus: string;
}

class CaseStudyQuiz extends Component<CaseStudyQuizProps & RouteComponentProps, CaseStudyQuizState> {
  isDemo: boolean;

  constructor(props) {
    super(props);
    this.isDemo = isDemoMode();
    const { displayOrder, totalPhases } = props.sequenceChunk;
    const isFinalPhase = displayOrder === totalPhases;

    this.state = {
      progressText: '',
      questionVtwId: '',
      questionType: '',
      questionPrompt: '',
      questionChoices: {},
      selectedAnswers: [],
      correctAnswers: [],
      questionFeedbacks: {},
      textReference: [],
      isFinalQuestion: false,
      isFinalPhase,
      stepStatus: ''
    };
  }

  componentDidMount() {
    this.fetchQuestion();
  }

  fetchQuestion = (): Promise<void> =>
    useLoader(
      caseStudyService.getCurrentStepChunk(this.props.assessmentId).then(({ data }) => {
        this.setState({
          progressText: `${data.displayOrder + 1} of ${data.totalQuestions} Questions`,
          questionVtwId: data.questionVtwId,
          questionType: data.content.questionType,
          questionPrompt: data.content.prompt,
          questionChoices: data.content.responseChoices,
          selectedAnswers: this.isDemo ? data.content.correctResponse : [],
          correctAnswers: this.isDemo ? data.content.correctResponse : [],
          questionFeedbacks: this.isDemo ? data.content.feedback : {},
          textReference: [],
          isFinalQuestion: data.displayOrder + 1 === data.totalQuestions,
          stepStatus: data.status
        });
        this.props.setTimeSpentData({
          assignmentId: this.props.assignment.assignmentId,
          assessmentId: this.props.assessmentId,
          userId: getRefreshToken().userId,
          currentLocation: { id: data.questionVtwId, type: LocationType.PHASE_QUESTION },
          phaseIndex: this.props.phaseIndex
        });
        if (!this.props.isAuthor) {
          assignmentHelper.recordTimeSpent(this.props.timeSpentData, EventType.START);
        }
      }),
      {
        errorMessage: 'failed to fetch question'
      }
    );

  onSelectAnswer = (answer: string): void => {
    if (this.state.questionType === QuestionType.MULTIPLE_CHOICES_SINGLE_ANSWER) {
      this.setState({ selectedAnswers: [answer] });
      return;
    }

    // else questionType is multiple choice multiple answer
    // if new answer is already in selectedAnswers, remove it
    // else, add it
    this.setState(({ selectedAnswers }) => ({
      selectedAnswers: selectedAnswers.includes(answer) ? selectedAnswers.filter((prevSelectedAnswer) => prevSelectedAnswer !== answer) : selectedAnswers.concat(answer)
    }));
  };

  submitAnswer = async (): Promise<void> => {
    const { timeSpentData } = this.props;
    const { questionVtwId, correctAnswers, selectedAnswers, isFinalQuestion, isFinalPhase } = this.state;
    await useLoader(
      caseStudyService
        .submitAnswer({
          assessmentId: this.props.assessmentId,
          questionVtwId,
          selectedAnswers: this.isDemo ? correctAnswers : selectedAnswers
        })
        .then(({ data }) => {
          if (isFinalQuestion && (!this.isDemo || !isFinalPhase)) {
            this.props.setDisableClickOutside();
          }

          this.setState({
            correctAnswers: data.content.correctResponse,
            questionFeedbacks: data.content.feedback,
            textReference: data.content.textReference === null ? [] : data.content.textReference,
            stepStatus: data.status
          });
        }),
      {
        errorMessage: 'failed to submit answer'
      }
    );
    if (!this.props.isAuthor) {
      assignmentHelper.recordTimeSpent(timeSpentData, EventType.END);
    }
  };

  handleClickNextQuestion = async (): Promise<void> => {
    if (this.isDemo) {
      await this.submitAnswer();
    }
    await this.fetchQuestion();
  };

  handleCompletePhaseClick = (): void => {
    const caseStudyHome = RoutePath.student.caseStudyLanding.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessmentId));
    this.props.history.push(caseStudyHome);
  };

  handleCompleteAssignmentClick = (): void => {
    const assignmentSubmission = RoutePath.student.assignmentSubmission.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessmentId));
    this.props.history.push(assignmentSubmission);
  };

  handleComplete = async (): Promise<void> => {
    const { isFinalPhase } = this.state;

    const completeCallback = isFinalPhase ? this.handleCompleteAssignmentClick : this.handleCompletePhaseClick;

    if (this.isDemo) {
      await this.submitAnswer();
    }
    completeCallback();
  };

  getButtonProps = (): ButtonProps => {
    const { stepStatus, isFinalQuestion, isFinalPhase, selectedAnswers } = this.state;

    if (stepStatus === StepProgress.IN_PROGRESS && !this.isDemo) {
      return {
        buttonText: 'Submit Answer',
        buttonIsDisabled: !selectedAnswers.length,
        onButtonClick: this.submitAnswer
      };
    }

    if (!isFinalQuestion) {
      return {
        buttonText: 'Next Question',
        buttonIsDisabled: false,
        onButtonClick: this.handleClickNextQuestion
      };
    }

    return {
      buttonText: isFinalPhase ? 'Complete Case Study' : 'Complete Phase',
      buttonIsDisabled: isFinalPhase && this.isDemo,
      onButtonClick: this.handleComplete
    };
  };

  getAnswerEvaluationMessage = (): string => {
    const { questionType, stepStatus, selectedAnswers, correctAnswers } = this.state;

    if (questionType === QuestionType.MULTIPLE_CHOICES_MULTIPLE_ANSWERS && stepStatus === StepProgress.COMPLETED) {
      const someCorrectAnswersSelected = selectedAnswers.some((item) => correctAnswers.includes(item));
      const someCorrectAnswersWereMissed = !correctAnswers.every((item) => selectedAnswers.includes(item));

      if (someCorrectAnswersSelected && someCorrectAnswersWereMissed) {
        return 'Some correct answers were not selected.';
      }
    }

    return '';
  };

  render() {
    const { textReference, progressText, questionType, questionPrompt, questionChoices, selectedAnswers, correctAnswers, questionFeedbacks } = this.state;

    if (!questionPrompt.length) return <></>;

    const viewProps: CaseStudyQuizViewProps = {
      vtwId: this.state.questionVtwId,
      progressText,
      questionType,
      questionPrompt,
      questionChoices,
      onSelectAnswer: this.onSelectAnswer,
      selectedAnswers,
      correctAnswers,
      questionFeedbacks,
      answerEvaluationMessage: this.getAnswerEvaluationMessage(),
      textReference: textReference.length ? textReference[0] : '',
      ...this.getButtonProps()
    };

    return <CaseStudyQuizView {...viewProps} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTimeSpentData: (timeSpentData) => dispatch(appActions.setTimeSpentData(timeSpentData))
});

const mapStateToProps = (state) => ({
  assignment: studentSelectors.getAssignment(state),
  phaseIndex: studentSelectors.getPhaseIndex(state),
  timeSpentData: appSelectors.getTimeSpentData(state),
  isAuthor: appSelectors.getIsAuthor(state),
  userRole: appSelectors.getUserRole(state)
});

export { CaseStudyQuiz as BaseCaseStudyQuiz };
export default compose(
  withProps(() => ({
    history: useHistory()
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(CaseStudyQuiz);

import cx from 'classnames';
import { ELSTextarea, ToggleView } from 'components/common';

export interface ToggleNotesFieldProps {
  id?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  value: string;
  onChange: Function;
  isDisabled?: boolean;
  showLabel?: string;
  hideLabel?: string;
}

const ToggleNotesField = (props: ToggleNotesFieldProps) => (
  <ToggleView className={cx('u-els-margin-top', props.className)} showLabel={props.showLabel} hideLabel={props.hideLabel}>
    <label htmlFor={`field-input-${props.id}`}>{props.label}</label>
    <ELSTextarea id={props.id} placeholder={props.placeholder} disabled={props.isDisabled} value={props.value || ''} onChange={props.onChange} />
  </ToggleView>
);

ToggleNotesField.defaultProps = {
  showLabel: 'View notes',
  hideLabel: 'Close notes'
};
ToggleNotesField.displayName = 'ToggleNotesField';
export default ToggleNotesField;

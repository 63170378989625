import cx from 'classnames';
import { Locales } from 'constants/app.constant';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';

export const defaultCustomRender = (field) => (row) => <div className={cx({ 'row--deleted': !row.active })}>{row[field] || ''}</div>;

export const defaultCustomDateRender = (field, locale: Locales) => (row) => {
  if (row[field]) {
    return <div className={cx({ 'row--deleted': !row.active })}>{formatDate({ date: toMomentWithParsers(row[field]).toDate(), locale })}</div>;
  }
  return <></>;
};

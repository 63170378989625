import { LanguageKeys } from 'lang';
import { FormFieldInputType } from 'models/enum';
import { ChartMetaFormFieldParams } from 'models/ui';
import { FormField } from './constants';
import { FormField as CommonFormField, Title } from '../shared/constants';

export const getNutritionOrderFormFieldBuilderItems = ({ isAuthor }: { isAuthor: boolean }): ChartMetaFormFieldParams[] => [
  { name: FormField.NUTRITION_TYPE, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NUTRITION_ORDER.ORDER_TYPE },
  { name: FormField.CALORIE_COUNT, type: FormFieldInputType.RADIO_CHOICE, label: LanguageKeys.NUTRITION_ORDER.TYPE_MODEL },
  { name: FormField.DIET_TYPE, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.NUTRITION_ORDER.DIET_TYPE },
  { name: FormField.CONSISTENCY, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.NUTRITION_ORDER.CONSISTENCY },
  { name: FormField.TUBE_FEEDING, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.NUTRITION_ORDER.TUBE_FEEDING },
  { name: FormField.NOTHING_BY_MOUNT, type: FormFieldInputType.MULTISELECT_DROPDOWN, label: LanguageKeys.NUTRITION_ORDER.NOTHING_BY_MOUNT },
  { name: FormField.FORMULA, type: FormFieldInputType.MULTI_SELECT_RADIO, label: LanguageKeys.NUTRITION_ORDER.FORMULA },
  { name: FormField.DAY_SHIFT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NUTRITION_ORDER.DAY_SHIFT },
  { name: FormField.EVENING_SHIFT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NUTRITION_ORDER.EVENING_SHIFT },
  { name: FormField.NIGHT_SHIFT, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NUTRITION_ORDER.NIGHT_SHIFT },
  { name: FormField.SPECIAL_DIET, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NUTRITION_ORDER.SPECIAL_DIET },
  { name: FormField.TOTAL_HOUR, type: FormFieldInputType.TEXT_BOX, label: LanguageKeys.NUTRITION_ORDER.TOTAL_HOUR },
  { name: FormField.DATE_FIELD, type: FormFieldInputType.DATE, label: LanguageKeys.DATE_LABEL, isHidden: isAuthor },
  { name: FormField.TIME_FIELD, type: FormFieldInputType.TIME, label: LanguageKeys.TIME_LABEL, defaultValue: '00:00', isHidden: isAuthor },
  { name: CommonFormField.ORDER_START_TIME_OFFSET, type: FormFieldInputType.TEXT_BOX, label: Title.START_TIME_OFFSET, value: '00:00', isHidden: !isAuthor }
];

import { Component } from 'react';
import { TherapyRecord } from 'models/api-response';
import { Box, ChartHistory } from 'components/common';
import { ELSButton } from 'components/common/els';
import { OrderStatusValue } from './shared/constants';
import { handleDisplayIntervention } from './shared/helpers';

export interface TherapiesHistoryProps {
  chartHistory: TherapyRecord[];
  deleteHistory: Function;
  openUpdatedSidebar: Function;
}

class TherapiesHistory extends Component<TherapiesHistoryProps> {
  static displayName = 'TherapiesHistory';

  statusRender = (row) => {
    const { isCompleted } = row?.chartData;
    const { chartHistory: intervention } = handleDisplayIntervention(row);
    let buttonLabel = OrderStatusValue.PENDING;
    if (isCompleted) {
      buttonLabel = OrderStatusValue.COMPLETED;
    } else if (intervention.length > 0) {
      buttonLabel = OrderStatusValue.INITIATED;
    }
    return <ELSButton type="link" text={buttonLabel} onClick={() => this.props.openUpdatedSidebar(row.fragmentId)} />;
  };

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Order Time', field: 'orderTime', sortable: true },
        { text: 'Order Description', field: 'orderDescription' },
        { text: 'Order Details', field: 'orderDetail' },
        { text: 'Special Instructions', field: 'notes' },
        { text: 'Status', field: 'status', customRender: this.statusRender },
        { field: 'actionDelete' }
      ],
      css: ['column__chart-time', '', '', '', '', 'column__delete'],
      data: chartHistory
    };

    return (
      <Box mt>
        <ChartHistory dataSource={dataSource} deleteRecord={deleteHistory} />
      </Box>
    );
  }
}

TherapiesHistory.displayName = 'TherapiesHistory';
export default TherapiesHistory;

import { PageHeader, withHTMLHeadSEO } from 'components/common';
import CaseStudyLanding from 'components/features/case-study-landing/CaseStudyLanding';

const CaseStudyLandingPage = () => (
  <div>
    <PageHeader title="SimChart Assignment" />
    <CaseStudyLanding />
  </div>
);

export { CaseStudyLandingPage as BaseCaseStudyLandingPage };
export default withHTMLHeadSEO({ title: 'Case Study Landing Page' })(CaseStudyLandingPage);

/* eslint-disable sonarjs/no-duplicate-string */
import { History, Location } from 'history';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { ChartFragment, ChartFragmentRS, PatientCardRecord } from 'models/api-response';
import { FragmentType } from 'models/enum';
import { ChartComponentProps, ChartFieldContent, PatientCardCategory } from 'models/ui';
import { NAV_ID, RouteParams } from 'constants/app.constant';
import { appHelper, dateTimeHelper } from 'helpers';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { chartService } from 'services';
import { compose } from 'redux';
import { withFormUtilities } from 'components/common';
import { Section, SectionMapping } from './constants';
import PatientCardView, { PatientCardViewProps } from './PatientCardView';
import { FormField as GeneralFormField } from '../order-entry/general-orders/constants';
import { FormField as NutritionFormField } from '../order-entry/nutrition/constants';

interface LocationState {
  from?: string;
  id: string;
  navId: string;
  lastPerformed?: string;
}

interface PatientCardState {
  categories: PatientCardCategory[];
  nutritionOrdersFragments: ChartFragment[];
  generalOrdersFragments: ChartFragment[];
  categoryValue: string;
  generalInformationNutritionRecords: PatientCardRecord[];
  generalInformationGeneralRecords: PatientCardRecord[];
  actionListGeneralRecords: PatientCardRecord[];
  showGeneralInformation: boolean;
  showActionList: boolean;
}

interface PatientCardProps extends ChartComponentProps {
  showConfirmationModal: Function;
  history: History;
  location: Location<LocationState>;
}

class PatientCard extends Component<PatientCardProps, PatientCardState> {
  static displayName = 'PatientCard';

  viewAllCategories: PatientCardCategory = {
    id: 'view-all-categories',
    value: 'View All Categories',
    name: 'View All Categories',
    label: 'View All Categories',
    formFieldId: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      nutritionOrdersFragments: [],
      generalOrdersFragments: [],
      categories: [],
      categoryValue: '',
      generalInformationNutritionRecords: [],
      generalInformationGeneralRecords: [],
      actionListGeneralRecords: [],
      showGeneralInformation: true,
      showActionList: true
    };
  }

  componentDidMount() {
    appHelper.useLoader(
      Promise.all([this.loadCategories(), this.loadNutritionOrdersData(), this.loadGeneralOrdersData()]).then(() => {
        const { id, navId, lastPerformed } = this.props.location.state || {};
        this.updateLastPerformed({ id, navId, lastPerformed });
      }),
      { errorMessage: 'can not load patient card data' }
    );
  }

  toPatientCardCategory = (content: ChartFieldContent): PatientCardCategory => {
    return {
      id: content.id,
      value: content.label,
      name: content.label,
      label: content.label,
      formFieldId: content.formFieldId
    };
  };

  loadCategories = () => {
    return Promise.all([chartService.fetchChartMetadata(NAV_ID.NUTRITION, null), chartService.fetchChartMetadata(NAV_ID.GENERAL_ORDERS, null)]).then((data) => {
      const nutritionOrdersCategories = data[0].data.chartFieldContentSet
        .filter((content) => content.formFieldId === NutritionFormField.NUTRITION_TYPE)
        .map(this.toPatientCardCategory);
      const generalOrdersCategories = data[1].data.chartFieldContentSet
        .filter((content) => content.formFieldId === GeneralFormField.ORDER_DESCRIPTION)
        .map(this.toPatientCardCategory);
      this.setState({
        categoryValue: this.viewAllCategories.value,
        categories: [this.viewAllCategories].concat(nutritionOrdersCategories, generalOrdersCategories)
      });
    });
  };

  loadNutritionOrdersData = () => {
    const { locale, loadChartData } = this.props;
    return loadChartData([FragmentType.AUTHORED, FragmentType.CHARTING], [NAV_ID.NUTRITION]).then(({ data }) => {
      const records = data
        .filter((fragment) => fragment.active && !fragment.chartData.isDiscontinued)
        .map((fragment) => {
          const { fragmentId: id, active, chartingAt, creator, modifier, navElementId } = fragment;
          const orderDate = fragment.chartData[NutritionFormField.DATE_FIELD];
          const formattedOrderDate = formatDate({ date: toMomentWithParsers(orderDate).toDate(), locale });

          return {
            id,
            active,
            chartingAt,
            creator,
            modifier,
            orderTime: `${formattedOrderDate} ${fragment.chartData[NutritionFormField.TIME_FIELD]}`,
            description: fragment.chartData[NutritionFormField.NUTRITION_TYPE] === 'Diet' ? fragment.chartData.orderDescription : fragment.chartData.orderDetail,
            category: fragment.chartData[NutritionFormField.NUTRITION_TYPE],
            lastPerformed: dateTimeHelper.toDateTime(fragment.chartData.lastPerformed, ''),
            isDiscontinued: fragment.chartData.isDiscontinued ?? false,
            isHold: fragment.chartData.isHold ?? false,
            navId: navElementId
          };
        });

      this.setState({
        generalInformationNutritionRecords: records,
        nutritionOrdersFragments: data
      });
    });
  };

  loadGeneralOrdersData = () => {
    const { locale, loadChartData } = this.props;
    return loadChartData([FragmentType.AUTHORED, FragmentType.CHARTING], [NAV_ID.GENERAL_ORDERS]).then(({ data }) => {
      const generalInformationGeneralRecords = [];
      const actionListGeneralRecords = [];

      data
        .filter((frag) => frag.active && !frag.chartData.isDiscontinued)
        .forEach((frag) => {
          const { fragmentId: id, active, chartingAt, creator, modifier, navElementId } = frag;
          const orderDetails = frag.chartData[GeneralFormField.ORDER_DETAILS];
          const category = frag.chartData[GeneralFormField.ORDER_DESCRIPTION];
          const mapping = SectionMapping[`${category}|${orderDetails.content}`];
          const regex = /:_|(: :_)/;
          const description = orderDetails.content.match(regex) ? orderDetails.content.replace(regex, `: ${orderDetails.value}`) : orderDetails.value;
          const orderDate = frag.chartData[GeneralFormField.ORDER_DATE];
          const formattedOrderDate = formatDate({ date: toMomentWithParsers(orderDate).toDate(), locale });

          const record = {
            id,
            active,
            chartingAt,
            creator,
            modifier,
            orderTime: `${formattedOrderDate} ${frag.chartData[GeneralFormField.ORDER_TIME]}`,
            description,
            category,
            lastPerformed: dateTimeHelper.toDateTime(frag.chartData.lastPerformed, ''),
            isDiscontinued: frag.chartData.isDiscontinued ?? false,
            isHold: frag.chartData.isHold ?? false,
            navId: navElementId,
            linkTo: frag.chartData.isHold ? '' : mapping?.linkTo
          };

          if (mapping?.section === Section.ACTION_LIST) {
            actionListGeneralRecords.push(record);
          } else {
            generalInformationGeneralRecords.push(record);
          }
        });

      this.setState({
        actionListGeneralRecords,
        generalInformationGeneralRecords,
        generalOrdersFragments: data
      });
    });
  };

  getFilteredData = (data: PatientCardRecord[]): PatientCardRecord[] => {
    const { categoryValue } = this.state;
    return categoryValue === this.viewAllCategories.value ? data : data.filter((record) => record.category === categoryValue);
  };

  handleDescriptionClick = (record: PatientCardRecord) => {
    const { history, location } = this.props;
    if (record.linkTo) {
      history.push({
        pathname: record.linkTo.replace(RouteParams.ASSESSMENT_ID, String(this.props.assessment.eolsAssessmentId)),
        state: { from: location.pathname, id: record.id, navId: record.navId }
      });
    }
  };

  handleCategoryChange = (event) => {
    this.setState({
      categoryValue: event.target.value
    });
  };

  handleGeneralInformationChange = () => this.setState((state) => ({ showGeneralInformation: !state.showGeneralInformation }));

  handleActionListChange = () => this.setState((state) => ({ showActionList: !state.showActionList }));

  getChartFragmentData = (navId: string) => {
    const chartFragmentData = {
      [NAV_ID.NUTRITION]: this.state.nutritionOrdersFragments,
      [NAV_ID.GENERAL_ORDERS]: this.state.generalOrdersFragments
    };

    return chartFragmentData[navId];
  };

  getLoadFragmentFunction = (navId: string) => {
    const loadFragmentFunction = {
      [NAV_ID.NUTRITION]: this.loadNutritionOrdersData,
      [NAV_ID.GENERAL_ORDERS]: this.loadGeneralOrdersData
    };

    return loadFragmentFunction[navId];
  };

  getChartFragmentUpdate = (record) => this.getChartFragmentData(record.navId).find((fragment) => fragment.fragmentId === record.id);

  handleHoldRecord = (record) => {
    const updateFragment = this.getChartFragmentUpdate(record);
    this.updateFragmentCharting(updateFragment, { isHold: !updateFragment.chartData.isHold });
  };

  handleDiscontinueRecord = (record) => {
    const updateFragment = this.getChartFragmentUpdate(record);
    this.updateFragmentCharting(updateFragment, { isDiscontinued: true });
  };

  updateLastPerformed = ({ id, navId, lastPerformed }) => {
    if (id && navId && lastPerformed) {
      const updateFragment = this.getChartFragmentUpdate({ id, navId });
      this.updateFragmentCharting(updateFragment, { lastPerformed });
    }
  };

  updateFragmentCharting = (updateFragment: ChartFragment, recordUpdate): Promise<ChartFragmentRS> => {
    const navId = updateFragment.navElementId;
    return appHelper.useLoader(
      chartService
        .saveFragment(this.props.assessment.simChartId, navId, {
          ...updateFragment,
          chartData: { ...updateFragment.chartData, ...recordUpdate }
        })
        .then(this.getLoadFragmentFunction(navId)),
      {
        errorMessage: 'can not update fragment'
      }
    );
  };

  getGeneralInformation = () => {
    const { generalInformationNutritionRecords, generalInformationGeneralRecords } = this.state;
    return this.getFilteredData([...generalInformationNutritionRecords, ...generalInformationGeneralRecords]);
  };

  getActionList = () => {
    return this.getFilteredData(this.state.actionListGeneralRecords);
  };

  handleDiscontinueClick = (records) =>
    this.props.showConfirmationModal({
      showIcon: true,
      header: 'Please confirm',
      message: 'Are you sure you want to discontinue the order?',
      onOkClick: () => this.handleDiscontinueRecord(records)
    });

  render() {
    const { categories, categoryValue, showGeneralInformation, showActionList } = this.state;
    const viewProps = {
      generalInformation: this.getGeneralInformation(),
      actionList: this.getActionList(),
      categories,
      categoryValue,
      showGeneralInformation,
      showActionList,
      onCategoryChange: this.handleCategoryChange,
      onGeneralInformationChange: this.handleGeneralInformationChange,
      onActionListChange: this.handleActionListChange,
      onHoldRecord: this.handleHoldRecord,
      handleDiscontinueClick: this.handleDiscontinueClick,
      onDescriptionClick: this.handleDescriptionClick
    } as PatientCardViewProps;
    return <PatientCardView {...viewProps} />;
  }
}

export { PatientCard as BasePatientCard };
export default compose(withFormUtilities, withRouter)(PatientCard);

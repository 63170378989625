import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, ScaleDirection } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSaveAndDiscardHandlers from 'components/common/with-chart-logic/withSaveAndDiscardHandlers';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, SectionTitle } from './constants';
import { EdinburghPostnatalDepressionScaleView, EdinburghPostnatalDepressionScaleViewProps } from './EdinburghPostnatalDepressionScaleView';

class EdinburghPostnatalDepressionScale extends Component<ChartComponentProps> {
  static displayName = 'EdinburghPostnatalDepressionScale';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField } = chartHelper;
    const dataMap = new Map();
    dataMap.set(
      FormField.EDINBURGH_POSTNATAL_DEPRESSION_SCALE,
      createFormField({
        name: FormField.EDINBURGH_POSTNATAL_DEPRESSION_SCALE,
        type: FormFieldInputType.SCALE,
        errorLabel: 'Edinburgh Postnatal Depression Scale'
      })
    );

    return dataMap;
  };

  getContextDirectionMap = (): Map<string, ScaleDirection> => {
    const contextDirectionMap = new Map();
    contextDirectionMap.set(FormField.FIRST_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.SECOND_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.THIRD_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.FOURTH_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.FIFTH_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.SIXTH_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.SEVENTH_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.EIGHTH_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.NINTH_QUESTION, ScaleDirection.VERTICALLY);
    contextDirectionMap.set(FormField.TENTH_QUESTION, ScaleDirection.VERTICALLY);
    return contextDirectionMap;
  };

  buildFragment = () => {
    const { buildPatientRecords, buildPlainRecord } = chartService;
    const { formFieldMap } = this.props;

    const record = {
      chartTitle: 'Special Charts',
      fragmentTitle: SectionTitle.EDINBURGH_POSTNATAL_DEPRESSION_SCALE_SECTION,
      records: [
        {
          sectionTitle: SectionTitle.IN_THE_PAST_7_DAYS,
          records: [...buildPatientRecords(formFieldMap, FormField.EDINBURGH_POSTNATAL_DEPRESSION_SCALE, false, true)]
        },
        {
          sectionTitle: SectionTitle.SCORING,
          records: [
            buildPlainRecord({
              formField: FormField.EDINBURGH_POSTNATAL_DEPRESSION_SCALE,
              title: 'Total Score',
              content: chartHelper.getScaleRollUpWrapper(formFieldMap.get(FormField.EDINBURGH_POSTNATAL_DEPRESSION_SCALE))?.score?.value,
              linkedFormFieldIds: [
                FormField.FIRST_QUESTION,
                FormField.SECOND_QUESTION,
                FormField.THIRD_QUESTION,
                FormField.FOURTH_QUESTION,
                FormField.FIFTH_QUESTION,
                FormField.SIXTH_QUESTION,
                FormField.SEVENTH_QUESTION,
                FormField.EIGHTH_QUESTION,
                FormField.NINTH_QUESTION,
                FormField.TENTH_QUESTION
              ]
            })
          ]
        }
      ]
    };

    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], {
      defaultFormFields: this.buildDefaultFormFields(),
      afterSave: this.props.navigateToSavedPatientCharting
    });
  };

  handleCancelClick = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };
    const viewProps: EdinburghPostnatalDepressionScaleViewProps = {
      chartActionsProps,
      formFieldMap: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      contextDirectionMap: this.getContextDirectionMap(),
      isEmbeddedChart: this.props.isEmbeddedChart
    };
    return <EdinburghPostnatalDepressionScaleView {...viewProps} />;
  }
}

export { EdinburghPostnatalDepressionScale as BaseEdinburghPostnatalDepressionScale };
export default compose(withSavedPatientChartsPage, withSaveAndDiscardHandlers, withChartLogic)(EdinburghPostnatalDepressionScale);

import {
  ActivitiesDailyLivingFragment,
  ImpressionFragment,
  MedicalHistoryFragment,
  MentalStatusFragment,
  ObstetricHistoryFragment,
  PhysicalExaminationFragment,
  PsychiatricHistoryFragment,
  ReviewOfSystemsFragment
} from 'models/api-response';
import { PatientContext } from 'models/ui';
import ActivitiesDailyLivingReadonly from './activities-daily-living/ActivitiesDailyLivingReadonly';
import ImpressionsReadOnly from './impressions/ImpressionsReadOnly';
import MedicalHistoryReadOnly from './medical-history/MedicalHistoryReadOnly';
import MentalStatusReadOnly from './mental-status/MentalStatusReadOnly';
import ObstetricHistoryReadOnly from './obstetric-history/ObstetricHistoryReadOnly';
import PhysicalExaminationReadOnly from './physical-examination/PhysicalExaminationReadOnly';
import PsychiatricHistoryReadonly from './psychiatric-history/PsychiatricHistoryReadOnly';
import ReviewOfSystemsReadOnly from './review-of-systems/ReviewOfSystemsReadOnly';

export interface HistoryPhysicalReadOnlyProps {
  medicalHistoryRecord?: MedicalHistoryFragment;
  obstetricHistoryRecord?: ObstetricHistoryFragment;
  physicalExaminationRecord?: PhysicalExaminationFragment;
  impressionRecord?: ImpressionFragment;
  activitiesDailyLivingRecord?: ActivitiesDailyLivingFragment;
  reviewOfSystemsRecord?: ReviewOfSystemsFragment;
  psychiatricHistory?: PsychiatricHistoryFragment;
  mentalStatus?: MentalStatusFragment;
  patientContext: PatientContext;
}

const HistoryPhysicalReadOnly = (props: HistoryPhysicalReadOnlyProps) => {
  const { patientContext } = props;
  return (
    <>
      <MedicalHistoryReadOnly selectedRecord={props.medicalHistoryRecord} patientContext={patientContext} />
      <ObstetricHistoryReadOnly selectedRecord={props.obstetricHistoryRecord} />
      <PhysicalExaminationReadOnly selectedRecord={props.physicalExaminationRecord} />
      <ImpressionsReadOnly selectedRecord={props.impressionRecord} />
      <ActivitiesDailyLivingReadonly selectedRecord={props.activitiesDailyLivingRecord} />
      <ReviewOfSystemsReadOnly selectedRecord={props.reviewOfSystemsRecord} />
      <PsychiatricHistoryReadonly selectedRecord={props.psychiatricHistory} />
      <MentalStatusReadOnly selectedRecord={props.mentalStatus} />
    </>
  );
};

HistoryPhysicalReadOnly.displayName = 'HistoryPhysicalReadOnly';
export default HistoryPhysicalReadOnly;

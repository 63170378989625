import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, FormFieldDivider, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import AlcoholScreenReadOnly from './AlcoholScreenReadOnly';
import './alcohol.screen.scss';

interface AlcoholScreenViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  resetAll: number;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  patientContext: PatientContext;
}

const AlcoholScreenView = (props: AlcoholScreenViewProps) => {
  const { isLocked, fragment, statusFragment, formSubmittedCount, chartMetaFormFields, resetAll, patientContext } = props;
  return (
    <div className="alcohol-screen">
      {isLocked ? (
        <AlcoholScreenReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={patientContext} />
      ) : (
        <>
          <h1>Alcohol Screen</h1>
          <ErrorCard chartMetaFormFields={props.chartMetaFormFields} showErrorCard={props.formSubmittedCount > 0} />

          <Box mt2>
            <ErrorFormField
              className="error-field--no-bottom"
              formField={chartMetaFormFields.get(FormField.DRINK_ALCOHOL)}
              formSubmittedCount={formSubmittedCount}
              options={{ displayHorizontal: true }}
            />
          </Box>

          <SectionDivider />
          <h2 className="u-els-margin-bottom">Alcohol Consumption History</h2>
          <h3 className="u-els-margin-top u-els-margin-bottom">Drinking</h3>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HOW_OFTEN_DRINK)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HOW_OFTEN_DRINK_OTHER)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HOW_LONG_DRINK)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HOW_LONG_DRINK_OTHER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.WHEN_LAST_DRINK)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.WHEN_LAST_DRINK_OTHER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>

          <h3 className="u-els-margin-top u-els-margin-bottom">Sobriety</h3>
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.REASON_BREAKING)} formSubmittedCount={formSubmittedCount} />
              <span>Dates of longest period of sobriety</span>
              <div className="upper-date">
                <div className="u-els-display-inline-block">
                  <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.BEGINNING_DATE_LONGEST_PERIOD)} formSubmittedCount={formSubmittedCount} />
                </div>
                <FormFieldDivider />
                <div className="u-els-display-inline-block">
                  <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.END_DATE_LONGEST_PERIOD)} formSubmittedCount={formSubmittedCount} />
                </div>
              </div>

              <span>Dates of most recent period of sobriety</span>
              <div>
                <div className="u-els-display-inline-block">
                  <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.BEGINNING_DATE_MOST_RECENT)} formSubmittedCount={formSubmittedCount} />
                </div>
                <FormFieldDivider />
                <div className="u-els-display-inline-block">
                  <ErrorFormField key={resetAll} formField={chartMetaFormFields.get(FormField.END_DATE_MOST_RECENT)} formSubmittedCount={formSubmittedCount} />
                </div>
              </div>
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HELP_MAINTAIN_SOBRIETY)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HELP_MAINTAIN_SOBRIETY_OTHER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>

          <h3 className="u-els-margin-top u-els-margin-bottom">Withdrawal</h3>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.HISTORY_ALCOHOL_WITHDRAWAL)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CAGE_QUESTIONNAIRE_CONTAINER)} formSubmittedCount={formSubmittedCount}>
            <ELSFlex className="u-flex-gutter-3x" left wrap>
              <ELSFlexItem w="1o2" md="1o1" className="u-els-margin-top">
                <h2 className="u-els-margin-bottom">{SectionTitle.CAGE_QUESTIONNAIRE}</h2>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CAGE_QUESTIONNAIRE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ErrorFormField>

          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
          <RelatedCharts chartIds={[NAV_ID.CIWA_AR]} />
        </>
      )}
    </div>
  );
};

AlcoholScreenView.displayName = 'AlcoholScreenView';
export default AlcoholScreenView;

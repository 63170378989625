import { Component } from 'react';
import { ChartFragment, TherapyRecord } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { FillInTheBlankPattern } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import TherapiesView, { TherapiesViewProps } from './TherapiesView';
import { FormField, OrderTypeValue, occupationalFormField, physicalFormField, respiratoryFormFields, speechFormField } from './shared/constants';
import { getTherapiesOrderEntryFormFieldBuilderItems } from './shared/helpers';
import { FormField as CommonFormField } from '../shared/constants';

interface TherapiesState {
  therapy: TherapyRecord;
  fragments: ChartFragment[];
  chartHistory: TherapyRecord[];
}

class Therapies extends Component<ChartComponentProps, TherapiesState> {
  static displayName = 'Therapies';

  constructor(props) {
    super(props);
    this.state = {
      therapy: null,
      fragments: [],
      chartHistory: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  transformFragmentToTableRecord = (fragment: ChartFragment): TherapyRecord => {
    const { locale } = this.props;
    const { chartData } = fragment;
    const orderTime = `${formatDate({ date: toMomentWithParsers(chartData[FormField.DATE_FIELD]?.content).toDate(), locale })} ${chartData[FormField.TIME_FIELD]?.content}`;
    const orderDescription = chartData[FormField.ORDER_DESCRIPTION]?.content;
    const timesPerDayContent = chartData[FormField.TIMES_PER_DAY]?.content;
    const timesPerDay = timesPerDayContent ? `${timesPerDayContent} Times per Day` : '';
    const orderPriority = `${chartData[FormField.ORDER_PRIORITY]?.content} ${timesPerDay}`.trim();
    const transporation = chartData[FormField.TRANSPORTATION]?.content;
    const iv = chartData[FormField.IV_REQUIRED]?.content;
    const oxygen = chartData[FormField.OXYGEN_REQUIRED]?.content;
    const orderDetail = `${orderPriority}, ${transporation}, IV: ${iv}, O2: ${oxygen}`;
    const notes = chartData[FormField.SPECIAL_INSTRUCTIONS]?.content;
    const isCompleted = chartData[FormField.IS_COMPLETED]?.content;
    const oldInterventionList = chartData[FormField.INTERVENTION_LIST];
    // Handle add existed intervention for old therapy data
    let interventionList = [];
    const interventionDate = chartData[FormField.INTERVENTION_DATE]?.content;
    if (!oldInterventionList && interventionDate) {
      const orderType = chartData[FormField.ORDER_TYPE]?.value;
      const formFields = {
        [OrderTypeValue.RESPIRATORY]: respiratoryFormFields,
        [OrderTypeValue.OCCUPATIONAL]: occupationalFormField,
        [OrderTypeValue.PHYSICAL]: physicalFormField,
        [OrderTypeValue.SPEECH]: speechFormField
      };
      const intervention = (formFields[orderType] || []).map((key) => {
        const value = chartData[key];
        return [key, value];
      });
      const date = chartData[FormField.INTERVENTION_DATE].content || '';
      const time = chartData[FormField.INTERVENTION_TIME]?.content || '';
      const interventionDateTime = `${date} ${time}`;
      intervention.push([FormField.INTERVENTION_DATE_TIME, interventionDateTime]);
      interventionList = [Object.fromEntries(intervention)];
      chartData[FormField.INTERVENTION_LIST] = interventionList;
    }
    return { ...fragment, chartData, id: fragment.fragmentId, orderTime, orderDescription, orderDetail, notes, notesTitle: 'Special Instructions', isCompleted } as TherapyRecord;
  };

  loadData = () => {
    this.props.initState(this.buildDefaultFormFields());
    appHelper.useLoader(
      this.props.loadChartData().then(({ data }) => {
        const therapyRecords = data.map((fragment: ChartFragment) => this.transformFragmentToTableRecord(fragment));
        this.setState({ fragments: data, chartHistory: therapyRecords });
      }),
      { errorMessage: 'can not load chart fragment' }
    );
  };

  deleteHistory = (record) => {
    const deleteFragment = this.state.fragments.find((fragment) => fragment.fragmentId === record.id);
    appHelper.useLoader(this.props.deleteChartData(deleteFragment).then(this.loadData).then(this.props.showDeleteSuccess), { errorMessage: 'can not delete chart history' });
  };

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { isAuthor, intl } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getTherapiesOrderEntryFormFieldBuilderItems({ isAuthor }).forEach(({ name, isHidden, label, ...item }) => {
      if (!isHidden) {
        const translatedLabel = label ? intl.formatMessage({ id: label }) : '';
        dataMap.set(name, createFormField({ ...item, name, label: translatedLabel }));
      }
    });

    dataMap.get(FormField.ORDER_DESCRIPTION).changeCallback = this.handleOrderDescriptionChange;

    return dataMap;
  };

  handleOrderDescriptionChange = ({ option }) => {
    if (option?.label?.includes(FillInTheBlankPattern)) {
      const newLabel = option.label.substring(0, option.label.indexOf(FillInTheBlankPattern)).trim();
      this.props.setFormFieldLabel(FormField.ORDER_DESCRIPTION_TEXT, newLabel);
    }
  };

  buildFragment = (): ChartFragment => {
    const { formFieldMap } = this.props;
    const { buildPatientRecord } = chartService;
    const customRegex = new RegExp(`${FillInTheBlankPattern}.*`);
    const orderDescriptionField = formFieldMap.get(FormField.ORDER_DESCRIPTION);
    let orderDescriptionText = orderDescriptionField.chartContent.find((contentItem) => contentItem.value === orderDescriptionField.value)?.label ?? '';
    if (formFieldMap.get(FormField.ORDER_DESCRIPTION_TEXT).value) {
      orderDescriptionText = orderDescriptionText.replace(customRegex, formFieldMap.get(FormField.ORDER_DESCRIPTION_TEXT).value);
    }
    const chartData = {
      [FormField.ORDER_TYPE]: buildPatientRecord(formFieldMap, FormField.ORDER_TYPE),
      [FormField.ORDER_DESCRIPTION]: { ...buildPatientRecord(formFieldMap, FormField.ORDER_DESCRIPTION), content: orderDescriptionText },
      [FormField.ORDER_PRIORITY]: buildPatientRecord(formFieldMap, FormField.ORDER_PRIORITY),
      [FormField.TIMES_PER_DAY]: buildPatientRecord(formFieldMap, FormField.TIMES_PER_DAY) || '',
      [FormField.TRANSPORTATION]: buildPatientRecord(formFieldMap, FormField.TRANSPORTATION),
      [FormField.IV_REQUIRED]: buildPatientRecord(formFieldMap, FormField.IV_REQUIRED),
      [FormField.OXYGEN_REQUIRED]: buildPatientRecord(formFieldMap, FormField.OXYGEN_REQUIRED),
      [FormField.DATE_FIELD]: buildPatientRecord(formFieldMap, FormField.DATE_FIELD),
      [FormField.TIME_FIELD]: buildPatientRecord(formFieldMap, FormField.TIME_FIELD),
      [CommonFormField.ORDER_START_TIME_OFFSET]: buildPatientRecord(formFieldMap, CommonFormField.ORDER_START_TIME_OFFSET),
      [FormField.INTERVENTION_LIST]: [],
      [FormField.SPECIAL_INSTRUCTIONS]: buildPatientRecord(formFieldMap, FormField.SPECIAL_INSTRUCTIONS)
    };
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), afterSave: this.loadData });
  };

  openUpdatedSidebar = (fragmentId) => {
    const { fragments } = this.state;
    const therapy = fragments.find((fragment) => fragment.fragmentId === fragmentId) as TherapyRecord;
    this.setState({ therapy });
  };

  closeUpdatedSidebar = () => this.setState({ therapy: null });

  handleChartEdit = () => {
    this.loadData();
    this.props.showSaveSuccess();
    this.setState({ therapy: null });
  };

  render() {
    const { formSubmittedCount, formFieldMap } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      enableSaveButton: this.props.hasUnsavedChanges,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: this.props.displayAuthoringData
    };
    const viewProps: TherapiesViewProps = {
      formFieldMap,
      formSubmittedCount,
      chartActionsProps,
      therapy: this.state.therapy,
      chartHistory: this.state.chartHistory,
      deleteHistory: this.deleteHistory,
      saveChartData: this.props.saveChartData,
      onEditTherapyHandler: this.handleChartEdit,
      openUpdatedSidebar: this.openUpdatedSidebar,
      closeUpdatedSidebar: this.closeUpdatedSidebar,
      ...appHelper.getChartSharedProps(this.props)
    };
    return <TherapiesView {...viewProps} />;
  }
}

export { Therapies as BaseTherapies };
export default withChartLogic(Therapies);

import { LanguageKeys } from 'lang';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, Section } from 'models/api-response';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import withSavedPatientChartsPage from 'components/features/shared/withSavedPatientChartsPage';
import { FormField, getSafetyFormFieldBuilderItems } from './constants';
import SafetyView from './SafetyView';
import { Title } from '../shared/constants';

class Safety extends Component<ChartComponentProps> {
  static displayName = 'Safety';

  componentDidMount() {
    this.props.initState(this.buildDefaultFormFields());
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { locale } = this.props;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getSafetyFormFieldBuilderItems(locale).forEach(({ name, type, label, isHidden }) => {
      if (!isHidden) {
        dataMap.set(name, createFormField({ name, type, label }));
      }
    });

    return dataMap;
  };

  createSections = (): Section[] => {
    const { formFieldMap, intl } = this.props;

    return [
      chartHelper.buildSection({ sectionTitle: LanguageKeys.SAFETY.SAFETY, fields: [FormField.ASSESSMENT_NOT_REQUIRED], formFieldMap }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage(
          { id: LanguageKeys.SAFETY.ACTIONS_RELATED_TO },
          { action: LanguageKeys.SAFETY.PLANNED, relatedTo: `'${LanguageKeys.SAFETY.MENTAL_STATUS}'` }
        ),
        fields: [FormField.MENTAL_STATUS_ACTIONS_PLANNED, FormField.MENTAL_STATUS_ACTIONS_PLANNED_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage(
          { id: LanguageKeys.SAFETY.ACTIONS_RELATED_TO },
          { action: LanguageKeys.SAFETY.TAKEN, relatedTo: `'${LanguageKeys.SAFETY.MENTAL_STATUS}'` }
        ),
        fields: [FormField.MENTAL_STATUS_ACTIONS_TAKEN, FormField.MENTAL_STATUS_ACTIONS_TAKEN_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_RELATED_TO }, { action: LanguageKeys.SAFETY.PLANNED, relatedTo: `'${LanguageKeys.SAFETY.VISION}'` }),
        fields: [FormField.VISION_ACTIONS_PLANNED, FormField.VISION_ACTIONS_PLANNED_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_RELATED_TO }, { action: LanguageKeys.SAFETY.TAKEN, relatedTo: `'${LanguageKeys.SAFETY.VISION}'` }),
        fields: [FormField.VISION_ACTIONS_TAKEN, FormField.VISION_ACTIONS_TAKEN_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_RELATED_TO }, { action: LanguageKeys.SAFETY.PLANNED, relatedTo: `'${LanguageKeys.SAFETY.TOILETING}'` }),
        fields: [FormField.TOILETING_ACTIONS_PLANNED, FormField.TOILETING_ACTIONS_PLANNED_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SAFETY.ACTIONS_RELATED_TO }, { action: LanguageKeys.SAFETY.TAKEN, relatedTo: `'${LanguageKeys.SAFETY.TOILETING}'` }),
        fields: [FormField.TOILETING_ACTIONS_TAKEN, FormField.TOILETING_ACTIONS_TAKEN_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.TOILETING,
        fields: [FormField.TOILETING_HAS_FALL_RISK_BEEN_DISCUSSED_AND_INTERVENTION_DEVELOPED, FormField.TOILETING_COMMENTS],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: intl.formatMessage({ id: LanguageKeys.SAFETY.FALL_PRECAUTIONS }),
        fields: [FormField.FALL_PRECAUTIONS, FormField.FALL_PRECAUTIONS_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.GENERAL_SAFETY,
        fields: [FormField.GENERAL_SAFETY, FormField.GENERAL_SAFETY_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.LEVEL_OF_SAFETY_PRECAUTIONS,
        fields: [FormField.LEVEL_OF_SAFETY_PRECAUTIONS, FormField.LEVEL_OF_SAFETY_PRECAUTIONS_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY,
        fields: [FormField.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY, FormField.NURSE_PATIENT_RELATIONSHIP_STRATEGIES_FOR_SAFETY_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.PATIENT_RESPONSE_TO_SAFETY_MEASURES,
        fields: [FormField.PATIENT_RESPONSE_TO_SAFETY_MEASURES, FormField.PATIENT_RESPONSE_TO_SAFETY_MEASURES_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.ROOM_PLAN_GRADATION,
        fields: [FormField.ROOM_PLAN_GRADATION, FormField.ROOM_PLAN_GRADATION_NOTES],
        formFieldMap
      }),
      chartHelper.buildSection({
        sectionTitle: LanguageKeys.SAFETY.SAFE_IN_MILIEU,
        fields: [FormField.SAFE_IN_MILIEU, FormField.SAFE_IN_MILIEU_NOTES],
        formFieldMap
      })
    ];
  };

  buildFragment = () => {
    const record = {
      chartTitle: Title.BASIC_NURSING_CARE,
      fragmentTitle: LanguageKeys.SAFETY.SAFETY,
      records: this.createSections()
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const basicInfo = chartService.createBaseFragment({ chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleSaveClick = () => {
    this.props.saveChart([this.buildFragment()], { afterSave: this.props.backToSourceLocation || this.props.navigateToSavedPatientCharting });
  };

  render() {
    const { enableDisplayRecordsButton, hasUnsavedChanges, handleDiscardClick, displayAuthoringData, formFieldMap, formSubmittedCount, locale, intl } = this.props;

    const chartActionsProps: ChartActionsComponentProps = {
      enableDisplayRecordsButton,
      enableSaveButton: hasUnsavedChanges,
      onCancelClick: () => handleDiscardClick(undefined, this.buildDefaultFormFields()),
      onDisplayRecordsClick: displayAuthoringData,
      onSaveClick: this.handleSaveClick
    };
    const viewProps = {
      chartActionsProps,
      formFieldMap,
      formSubmittedCount,
      locale,
      intl
    };
    return <SafetyView {...viewProps} />;
  }
}
export { Safety as BaseSafety };
export default compose(withSavedPatientChartsPage, withChartLogic)(Safety);

import { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { compose, withProps } from 'recompose';
import { StepProgress } from 'models/enum';
import { CaseStudySequence } from 'models/ui';
import { caseStudyService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import CaseStudyPanelView, { CaseStudyPanelViewProps } from './CaseStudyPanelView';

export interface CaseStudyPanelProps {
  caseStudyId: string;
  params: { assessmentId: string };
  setCaseStudyId: Function;
}

interface CaseStudyPanelState {
  disableClickOutside: boolean;
  sequenceChunk: CaseStudySequence;
  showQuiz: boolean;
}

class CaseStudyPanel extends Component<CaseStudyPanelProps, CaseStudyPanelState> {
  static displayName = 'CaseStudyPanel';
  assessmentId: number;

  constructor(props) {
    super(props);

    this.state = {
      disableClickOutside: false,
      sequenceChunk: null,
      showQuiz: false
    };

    const { assessmentId: assessmentIdParam } = this.props.params;
    this.assessmentId = Number(assessmentIdParam);
  }

  componentDidMount() {
    caseStudyService.getCurrentOrFirstSequenceChunk(this.assessmentId).then(({ data }) => {
      const stepStatuses: string[] = data.stepChunks.map((step) => step.status);
      const notStartStatus = stepStatuses.filter((status) => status === StepProgress.NOT_STARTED);
      const showQuiz = notStartStatus.length !== stepStatuses.length;

      this.setState({
        showQuiz,
        sequenceChunk: data
      });
    });
  }

  setDisableClickOutside = () => this.setState({ disableClickOutside: true });

  setShowQuiz = () => this.setState({ showQuiz: true });

  render() {
    if (!this.state.sequenceChunk) return null;

    const viewProps: CaseStudyPanelViewProps = {
      assessmentId: this.assessmentId,
      disableClickOutside: this.state.disableClickOutside,
      sequenceChunk: this.state.sequenceChunk,
      setDisableClickOutside: this.setDisableClickOutside,
      setShowQuiz: this.setShowQuiz,
      showQuiz: this.state.showQuiz
    };

    return <CaseStudyPanelView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  caseStudyId: appSelectors.getCaseStudyId(state)
});

const mapDispatchToProps = (dispatch) => ({
  setCaseStudyId: (caseStudyId) => dispatch(appActions.setCaseStudyId(caseStudyId))
});

export { CaseStudyPanel as BaseCaseStudyPanel };
export default compose(
  withProps(() => ({
    params: useParams()
  })),
  connect(mapStateToProps, mapDispatchToProps)
)(CaseStudyPanel);

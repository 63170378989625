/* eslint-disable class-methods-use-this */
import { TIME_FORMAT } from 'constants/app.constant';
import { toMomentWithFormat } from 'helpers/datetime.helper';
import BaseValidator, { ExecuteParams } from './interface';

export default class TimeRangeValidator implements BaseValidator {
  isAfter: boolean;

  constructor(isAfter = false) {
    this.isAfter = isAfter;
  }

  execute({ field, ruleInfo }: ExecuteParams): boolean {
    let result = false;
    const value = toMomentWithFormat(field.value, TIME_FORMAT);
    const valueToCompare = toMomentWithFormat(ruleInfo.getValueToCompare(), TIME_FORMAT);
    if (this.isAfter) {
      result = value.isAfter(valueToCompare, 'minute');
    } else {
      result = value.isBefore(valueToCompare, 'minute');
    }
    return result;
  }
}

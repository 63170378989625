import { ChartFragment } from 'models/api-response';
import { ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import InfantToddlerAssessmentReadOnly from './InfantToddlerAssessmentReadOnly';
import { FormField, SectionTitle } from './constant';

interface InfantToddlerAssessmentViewProps extends ChartViewProps {
  isLocked: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

const InfantToddlerAssessmentView = (props: InfantToddlerAssessmentViewProps) => {
  const { isLocked, statusFragment, formFieldMap, chartActionsProps, formSubmittedCount, fragment } = props;
  return (
    <div className="mobility-physical-therapy">
      {isLocked ? (
        <InfantToddlerAssessmentReadOnly chartFragment={fragment} statusFragment={statusFragment} />
      ) : (
        <>
          <h1>Infant - Toddler Risk Assessment</h1>
          <SectionDivider />
          <h2 className="u-els-margin-bottom-2x">{SectionTitle.INDICATE_THE_PRESENCE_OF_RISK}</h2>
          <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.CHILD_PASSENGER_SAFETY_SELECT)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField formField={formFieldMap.get(FormField.CHILD_PASSENGER_SAFETY_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ABUSE_MALTREATMENT_SELECT)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField formField={formFieldMap.get(FormField.ABUSE_MALTREATMENT_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.SLEEP_SELECT)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField formField={formFieldMap.get(FormField.SLEEP_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
              <SectionDivider />
              <h4>If you select Yes to any of the above, make appropriate referral.</h4>
            </ELSFlexItem>
          </ELSFlex>
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

InfantToddlerAssessmentView.displayName = 'InfantToddlerAssessmentView';
export default InfantToddlerAssessmentView;

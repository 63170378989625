import { dateTimeHelper } from 'helpers';
import BaseValidator, { ExecuteParams } from './interface';

export default class FutureDateValidator implements BaseValidator {
  isFutureDate: boolean;

  constructor(isFutureDate = false) {
    this.isFutureDate = isFutureDate;
  }

  execute({ field }: ExecuteParams): boolean {
    this.isFutureDate = dateTimeHelper.isFutureDate(field.value);
    return this.isFutureDate;
  }
}

import { Component } from 'react';
import { connect } from 'react-redux';
import { CaseStudy } from 'models/ui';
import { instructorSelectors } from 'redux/ducks/instructor';
import CaseStudySubmissionView from './CaseStudySubmissionView';

export enum Tabs {
  OVERVIEW = 0,
  CHARTS_INVOLVED = 1,
  PHASES = 2
}

export interface CaseStudySubmissionProps {
  selectedCaseStudy: CaseStudy;
  isBookOrganized?: boolean;
}
interface CaseStudySubmissionState {
  activeTab: number;
}

class CaseStudySubmission extends Component<CaseStudySubmissionProps, CaseStudySubmissionState> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: Tabs.OVERVIEW
    };
  }

  handleTabGroupClick = (tabIndex) => this.setState({ activeTab: tabIndex });

  render() {
    const { activeTab } = this.state;
    const { selectedCaseStudy } = this.props;

    return (
      <CaseStudySubmissionView
        activeTab={activeTab}
        selectedCaseStudy={selectedCaseStudy}
        onTabGroupClick={this.handleTabGroupClick}
        isBookOrganized={this.props.isBookOrganized}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isBookOrganized: instructorSelectors.getIsBookOrganized(state)
});

export { CaseStudySubmission as BaseCaseStudySubmission };
export default connect(mapStateToProps)(CaseStudySubmission);

import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { OverallCommentFragment, TimeSpentRS } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { AssessmentStatus, AssignmentType, GradingOption, GradingResult } from 'models/enum';
import { CaseStudy, CaseStudySubmissionSummary, ChartComment, NavigationItem, PatientContext } from 'models/ui';
import { Locales, RouteParams, RoutePath } from 'constants/app.constant';
import { apiHelper, appHelper, assignmentHelper } from 'helpers';
import { assignmentService, caseStudyService, chartService, featureService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { ConfirmationModal, LoseDataWarning, withFormUtilities } from 'components/common';
import UnsavedChangesHandler from 'components/common/unsaved-changes-handler/UnsavedChangesHandler';
import AssessmentDetailView from './AssessmentDetailView';

interface MatchParam {
  assignmentId: string;
}

export interface StudentSubmission {
  studentId: number;
  assessmentId: number;
  displayName: string;
  status: AssessmentStatus;
}

export enum Tabs {
  CHART_ACTIVITY,
  QUIZ_QUESTIONS,
  CASE_STUDY_INFORMATION
}

export interface AssessmentDetailProps extends RouteComponentProps<MatchParam> {
  menuItems: NavigationItem[];
  modalService: ELSModalServiceType;
  getMenuItems: Function;
  showSaveSuccess: VoidFunction;
  fetchPatientContext: Function;
  locale: Locales;
}

interface AssignmentDetailState {
  fragmentId: string;
  assignmentType: AssignmentType;
  assignmentGradeType: GradingOption;
  gradeStatus: GradingResult;
  score?: number;
  simChartId: string;
  assignmentId: number;
  assessmentId: number;
  selectedStudent: number;
  studentNavigationIndex: number;
  overallComment: string;
  isOverallCommentChanged: boolean;
  submissions: StudentSubmission[];
  title: string;
  startedAt: string;
  completedAt: string;
  durationTime: number;
  completedCharts: string[];
  status: string;
  chartComments: ChartComment[];
  activeTab: number;
  caseStudySubmissionSummary: CaseStudySubmissionSummary;
  caseStudy: CaseStudy;
  warningMessage: string;
  timeSpentData: TimeSpentRS[];
  isInProgressGradingEnabled: boolean;
}

export class AssessmentDetail extends Component<AssessmentDetailProps, AssignmentDetailState> {
  notSubmittedWarningMessage = 'Student assignment is not yet submitted. Please check back later.';
  pastDueWarningMessage = 'Student assignment was not submitted.';
  warningUnsavedCommentModalId = 'loseDataModal';

  constructor(props) {
    super(props);
    this.state = {
      assignmentId: Number(this.props.match.params?.assignmentId),
      selectedStudent: this.getStudentFromPath(),
      fragmentId: '',
      assignmentType: null,
      assessmentId: null,
      assignmentGradeType: null,
      gradeStatus: null,
      score: null,
      simChartId: '',
      studentNavigationIndex: 0,
      overallComment: '',
      isOverallCommentChanged: false,
      submissions: [],
      title: '',
      startedAt: '',
      completedAt: '',
      durationTime: 0,
      completedCharts: [],
      status: '',
      chartComments: [],
      activeTab: Tabs.CHART_ACTIVITY,
      caseStudySubmissionSummary: null,
      caseStudy: null,
      warningMessage: '',
      timeSpentData: [],
      isInProgressGradingEnabled: false
    };
  }

  async componentDidMount() {
    this.loadAssignmentSummary();
    this.getTimeSpent();

    const isInProgressGradingEnabled = await featureService.isInProgressGradingEnabled();
    this.setState({ isInProgressGradingEnabled });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedStudent, simChartId, assessmentId, submissions } = this.state;
    const { menuItems } = this.props;
    if (!assessmentId || !menuItems?.[0]?.path.includes(assessmentId.toString())) {
      return;
    }

    if (this.props.location?.search !== prevProps.location?.search) {
      this.handleRouteChange();
      return;
    }

    if (prevState.selectedStudent !== selectedStudent) {
      this.setState({ studentNavigationIndex: this.calculateStudentNavigationIndex(selectedStudent, submissions) });
      this.loadAssessmentDetail();
    }

    if ((prevState.simChartId !== simChartId || prevProps.menuItems !== menuItems) && menuItems?.length) {
      chartService
        .buildChartComments(assessmentId?.toString(), simChartId, menuItems, this.state.completedCharts)
        .then((chartComments) => this.setState({ chartComments }))
        .catch((err) => apiHelper.showApiError(`can not load chart comments ${err}`));
    }
  }

  handleRouteChange = () => this.setState({ selectedStudent: this.getStudentFromPath() });

  getStudentFromPath = (): number => {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { student } = parsedQuery;
    return Number(student);
  };

  calculateStudentNavigationIndex = (studentId: number, submissions: StudentSubmission[]): number => {
    const index = submissions.findIndex((item) => item.studentId === studentId);
    return index !== -1 ? index + 1 : 1;
  };

  getTimeSpent = () => {
    assignmentService.getTimeSpent(this.state.assignmentId).then((res) => {
      this.setState({ timeSpentData: res.data });
    });
  };

  loadAssignmentSummary = () =>
    appHelper.useLoader(
      assignmentService
        .getAssignmentSummary(this.state.assignmentId)
        .then(({ data }) => {
          const { title, assignmentType, assignmentGradeType } = data;
          const submissions: StudentSubmission[] = data.studentAssessmentSubmissions.map((item) => {
            const status = !assignmentHelper.isAssessmentSubmitted(item.status) ? '(incomplete)' : '';
            return {
              studentId: item.userId,
              assessmentId: item.assessmentId,
              displayName: `${item.firstName} ${item.lastName} ${status}`.trim(),
              status: item.status
            };
          });
          const studentNavigationIndex = this.calculateStudentNavigationIndex(this.state.selectedStudent, submissions);
          return new Promise((resolve) => this.setState({ submissions, title, assignmentType, assignmentGradeType, studentNavigationIndex }, () => resolve('')));
        })
        .then(this.loadAssessmentDetail)
    );

  loadCaseStudy = (caseStudyId: string) =>
    caseStudyService.getCaseStudy(caseStudyId).then(({ data }) => {
      this.setState({ caseStudy: data });
    });

  bindAssessmentSubmissionSummary = (data) => {
    const { assessmentId, assignmentType, assignmentGradeType, gradeStatus, status, caseStudySubmissionSummary, simChartId } = data;
    const { startedAt, completedAt, durationTime, completedCharts } = data;
    const totalCorrect = data.caseStudySubmissionSummary?.totalCorrect;
    const totalQuestions = data.caseStudySubmissionSummary?.totalQuestions;
    const score = data.assignmentGradeType === GradingOption.SCORED ? assignmentHelper.getScore(totalCorrect, totalQuestions, status) : null;
    let warningMessage;
    switch (status) {
      case AssessmentStatus.NOT_STARTED:
      case AssessmentStatus.IN_PROGRESS:
        warningMessage = this.notSubmittedWarningMessage;
        break;
      case AssessmentStatus.PAST_DUE:
        warningMessage = this.pastDueWarningMessage;
        break;
      default:
        warningMessage = '';
    }
    this.setState({
      score,
      gradeStatus,
      simChartId,
      assessmentId,
      status,
      startedAt,
      completedAt,
      durationTime,
      completedCharts: completedCharts || [],
      caseStudySubmissionSummary: {
        assignmentType,
        assignmentGradeType,
        ...caseStudySubmissionSummary
      },
      warningMessage
    });
  };

  loadAssessmentDetail = () => {
    const { assignmentId, selectedStudent, submissions } = this.state;
    const hasAssessment = submissions.some((item) => item.studentId === selectedStudent && !!item.assessmentId);
    if (hasAssessment) {
      return appHelper.useLoader(
        assignmentService.getAssessmentSubmissionSummary(assignmentId, selectedStudent).then(async ({ data }) => {
          this.bindAssessmentSubmissionSummary(data);
          const patientContext = await this.props.fetchPatientContext(data.simchartId, data.assignmentType);

          const fetchAssessmentResultPromises = [this.props.getMenuItems(data.assessmentId, patientContext), this.loadOverallComment(data.simChartId)];
          if (data.assignmentType === AssignmentType.CASE_STUDY) {
            fetchAssessmentResultPromises.push(this.loadCaseStudy(data.caseStudySubmissionSummary.caseStudyId));
          }
          return Promise.all(fetchAssessmentResultPromises);
        }),
        { errorMessage: 'can not get assessment submission summary' }
      );
    }
    const assessmentStatus = this.state.submissions.find((item) => item.studentId === this.state.selectedStudent).status;
    this.setState(() => ({
      score: null,
      gradeStatus: null,
      simChartId: null,
      assessmentId: null,
      status: assessmentStatus,
      startedAt: null,
      completedAt: null,
      durationTime: 0,
      completedCharts: [],
      caseStudySubmissionSummary: null,
      warningMessage: assessmentStatus === AssessmentStatus.NOT_STARTED ? this.notSubmittedWarningMessage : this.pastDueWarningMessage
    }));
    return Promise.resolve();
  };

  loadOverallComment = (chartId: string) =>
    chartService
      .getOverallComment(chartId)
      .then((commentFragment: OverallCommentFragment) => this.setState({ fragmentId: commentFragment?.fragmentId || '', overallComment: commentFragment?.chartData?.comment }));

  handleStudentChange = (event) => {
    const studentId = event.target.value;
    if (!this.state.isOverallCommentChanged) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: `?student=${studentId}`
      });
    } else {
      const { modalService } = this.props;
      modalService.openModal({
        modalId: this.warningUnsavedCommentModalId,
        content: (
          <ConfirmationModal
            header="Before you Leave..."
            message={ConfirmationModal.UNSAVED_MESSAGE}
            onOkClick={() => {
              this.handleSaveClick().then(() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                this.props.history.push({
                  pathname: this.props.location.pathname,
                  search: `?student=${studentId}`
                });
              });
            }}
            onCancelClick={() => modalService.closeModal(this.warningUnsavedCommentModalId)}
          />
        )
      });
    }
  };

  handleOverallCommentChange = (event) => this.setState({ overallComment: event.target.value, isOverallCommentChanged: true });

  handleBackToDetail = () => {
    const { assignmentId } = this.props.match.params;
    this.props.history.push({
      pathname: RoutePath.instructor.grade.replace(RouteParams.ASSIGNMENT_ID, assignmentId)
    });
  };

  handleSaveClick = () => {
    const { simChartId, fragmentId, overallComment } = this.state;
    return appHelper.useLoader(
      chartService
        .saveOverallComment(simChartId, fragmentId, overallComment)
        .then(() =>
          this.setState({
            isOverallCommentChanged: false
          })
        )
        .then(this.props.showSaveSuccess),
      { errorMessage: 'can not save comment' }
    );
  };

  handleViewChart = () => {
    const { path } = this.state.chartComments[0];
    this.props.history.push(path);
  };

  handleViewSpecificChart = (path: string) => this.props.history.push(path);

  handleTabGroupClick = (tabIndex) => this.setState({ activeTab: tabIndex });

  updatePathWithStudent = (studentId: number): void =>
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?student=${studentId}`
    });

  getCurrentSubmissionIndex = (): number => this.state.submissions.findIndex((item) => item.studentId === this.state.selectedStudent);

  handleNavigationAttempt = () =>
    new Promise((resolve) => {
      const { modalService } = this.props;
      if (!this.state.isOverallCommentChanged) {
        resolve(true);
      } else {
        modalService.openModal({
          modalId: this.warningUnsavedCommentModalId,
          content: (
            <LoseDataWarning
              onLeaveClick={() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                resolve(true);
              }}
              onStayClick={() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                resolve(false);
              }}
            />
          )
        });
      }
    });

  handlePrevStudentClick = () => {
    if (!this.state.isOverallCommentChanged) {
      this.updatePathWithStudent(this.state.submissions[this.getCurrentSubmissionIndex() - 1].studentId);
    } else {
      const { modalService } = this.props;
      modalService.openModal({
        modalId: this.warningUnsavedCommentModalId,
        content: (
          <ConfirmationModal
            header="Before you Leave..."
            message={ConfirmationModal.UNSAVED_MESSAGE}
            onOkClick={() => {
              this.handleSaveClick().then(() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                this.updatePathWithStudent(this.state.submissions[this.getCurrentSubmissionIndex() - 1].studentId);
              });
            }}
            onCancelClick={() => modalService.closeModal(this.warningUnsavedCommentModalId)}
          />
        )
      });
    }
  };

  handleNextStudentClick = () => {
    if (!this.state.isOverallCommentChanged) {
      this.updatePathWithStudent(this.state.submissions[this.getCurrentSubmissionIndex() + 1].studentId);
    } else {
      const { modalService } = this.props;
      modalService.openModal({
        modalId: this.warningUnsavedCommentModalId,
        content: (
          <ConfirmationModal
            header="Before you Leave..."
            message={ConfirmationModal.UNSAVED_MESSAGE}
            onOkClick={() => {
              this.handleSaveClick().then(() => {
                modalService.closeModal(this.warningUnsavedCommentModalId);
                this.updatePathWithStudent(this.state.submissions[this.getCurrentSubmissionIndex() + 1].studentId);
              });
            }}
            onCancelClick={() => modalService.closeModal(this.warningUnsavedCommentModalId)}
          />
        )
      });
    }
  };

  render() {
    const {
      title,
      assignmentType,
      assignmentGradeType,
      gradeStatus,
      caseStudy,
      submissions,
      caseStudySubmissionSummary,
      assignmentId,
      assessmentId,
      startedAt,
      completedAt,
      durationTime,
      status,
      score,
      completedCharts,
      chartComments,
      overallComment,
      timeSpentData,
      activeTab,
      selectedStudent,
      studentNavigationIndex,
      warningMessage,
      isInProgressGradingEnabled
    } = this.state;

    const { locale } = this.props;

    const viewProps = {
      title,
      assignmentType,
      assignmentGradeType,
      submissions,
      startedAt,
      completedAt,
      durationTime,
      completedCharts,
      score,
      status,
      gradeStatus,
      selectedStudent,
      chartComments,
      overallComment,
      caseStudy,
      caseStudySubmissionSummary,
      isInProgressGradingEnabled,
      questions: caseStudySubmissionSummary?.sequenceChunkSummaries
        ?.flatMap((chunk) => chunk.stepChunks)
        .map((step) => ({
          ...step.content,
          correct: step.correct,
          selectedAnswers: step.selectedAnswers
        })),
      activeTab,
      studentNavigationIndex,
      warningMessage,
      onBackToDetail: this.handleBackToDetail,
      onViewChart: this.handleViewChart,
      onStudentChange: this.handleStudentChange,
      onTabsClick: this.handleTabGroupClick,
      onViewSpecificChart: this.handleViewSpecificChart,
      onOverallCommentChange: this.handleOverallCommentChange,
      onSaveClick: this.handleSaveClick,
      onPrevStudentClick: this.handlePrevStudentClick,
      onNextStudentClick: this.handleNextStudentClick,
      timeSpentData: timeSpentData.find((timeSpent) => timeSpent.assessmentId === assessmentId),
      assignmentId,
      assessmentId,
      locale
    };

    return (
      <>
        <AssessmentDetailView {...viewProps} />
        <UnsavedChangesHandler handler={this.handleNavigationAttempt} hasUnsavedChanges={() => this.state.isOverallCommentChanged} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: appSelectors.getMenuItems(state),
  locale: appSelectors.getLocale(state)
});

const mapDispatchToProps = (dispatch) => ({
  getMenuItems: (assessmentId: number, patientContext: PatientContext) => dispatch(appActions.fetchMenuItems(assessmentId, patientContext)),
  fetchPatientContext: (simChartAssessmentId, assignmentType: AssignmentType) => dispatch(appActions.fetchPatientContext(simChartAssessmentId, assignmentType))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withFormUtilities, withRouter)(AssessmentDetail);

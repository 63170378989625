import { Component } from 'react';
import { AnswerSubmission } from 'models/ui';
import { QuizQuestionsResultView } from './QuizQuestionsResultView';

interface QuizQuestionsResultState {
  activeQuestionTab: number;
}
interface QuizQuestionsResultProps {
  questions: AnswerSubmission[];
  totalCorrect: number;
  totalQuestions: number;
}
export enum QuestionTabs {
  INCORRECT_QUESTION = 0,
  CORRECT_QUESTION = 1
}

export class QuizQuestionsResult extends Component<QuizQuestionsResultProps, QuizQuestionsResultState> {
  constructor(props) {
    super(props);
    this.state = {
      activeQuestionTab: QuestionTabs.CORRECT_QUESTION
    };
  }

  handleQuestionTabGroupClick = (activeTab) => this.setState({ activeQuestionTab: activeTab });

  getAnswerEvaluationMessage = (question: AnswerSubmission): string => {
    const { selectedAnswers, correctResponse } = question;
    const correctAnswers = correctResponse.filter((item) => selectedAnswers.includes(item));
    const someCorrectAnswersWereMissed = correctAnswers.length > 0 && correctAnswers.length < correctResponse.length;

    if (someCorrectAnswersWereMissed) {
      return 'Some correct answers were not selected.';
    }

    return '';
  };

  render() {
    const viewProps = {
      totalCorrect: this.props.totalCorrect,
      totalQuestions: this.props.totalQuestions,
      questions: this.props.questions,
      activeQuestionTab: this.state.activeQuestionTab,
      onQuestionTabsClick: this.handleQuestionTabGroupClick,
      getAnswerEvaluationMessage: this.getAnswerEvaluationMessage
    };
    return <QuizQuestionsResultView {...viewProps} />;
  }
}

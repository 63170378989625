import { NAV_ID } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { Box, ButtonLinkIcon, ChartActions, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider, withEmbeddedChart } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import BallardMaturationalAssessment from 'components/features/chart/special-charts/ballard-maturational-assessment/BallardMaturationalAssessment';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField, SectionTitle } from './constants';
import './newborn.assessment.scss';

const EmbeddedBallardMaturationalAssessment = withEmbeddedChart(BallardMaturationalAssessment);
interface NewbornAssessmentViewProps extends ChartViewProps {
  isSidebarOpen: boolean;
  sidebarKey: number;
  totalNeuromuscularMaturityScore: string;
  totalPhysicalMaturityScore: string;
  totalScore: string;
  handleBallardClick: Function;
  handleCloseSidebar: Function;
  onSaveEmbeddedChart: Function;
  onDiscardEmbeddedChart: Function;
  onNoAssessmentRequiredChange: Function;
}

const NewbornAssessmentView = (props: NewbornAssessmentViewProps) => {
  const {
    chartActionsProps,
    formFieldMap,
    formSubmittedCount,
    handleBallardClick,
    handleCloseSidebar,
    isSidebarOpen,
    onSaveEmbeddedChart,
    onDiscardEmbeddedChart,
    onNoAssessmentRequiredChange,
    sidebarKey,
    totalNeuromuscularMaturityScore,
    totalPhysicalMaturityScore,
    totalScore
  } = props;
  return (
    <div className="newborn-assessment">
      <ErrorCard className="u-els-margin-bottom" chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <h1>{SectionTitle.NEWBORN_ASSESSMENT}</h1>
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} onChange={onNoAssessmentRequiredChange} />

      <h2 className="u-els-margin-top">{SectionTitle.GENERAL_ASSESSMENT}</h2>
      <h4 className="u-els-margin-top-2x u-els-margin-bottom-2x">Vital Signs</h4>
      <ELSFlex left wrap>
        <ELSFlexItem w="1o3" md="1o1">
          <h4 className="u-els-margin-bottom-1o2">{SectionTitle.TEMPERATURE}</h4>
          <ELSFlex className="u-flex-gutter-1x" wrap left>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.TEMPERATURE_FAHRENHEIT)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.TEMPERATURE_PULSE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.TEMPERATURE_CELSIUS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.TEMPERATURE_RESPIRATION)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <p className="u-els-margin-bottom-1o2">{SectionTitle.BLOOD_PRESSURE}</p>
          <ELSFlex className="u-flex-gutter-1x" wrap left>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.BLOOD_PRESSURE_SYSTOLIC)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.BLOOD_PRESSURE_DIALOSTIC)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </ELSFlexItem>
        <ELSFlexItem w="2o3" md="1o1">
          <h4 className="u-els-margin-bottom-1o2">{SectionTitle.MEASUREMENTS}</h4>
          <ELSFlex className="u-flex-gutter-1x" wrap left>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_POUND)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_LENGTH_INCH)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_OUNCE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_LENGTH_CENTIMETER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem>
              <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_WEIGHT_GRAM)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
          <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_HEAD)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MEASUREMENTS_CHEST)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.GESTATIONAL_AGE}</h2>
      <ButtonLinkIcon iconName="enlarge" iconSize="1x" text="Ballard Maturational Assessment" onClick={() => handleBallardClick()} preventAutoPending />

      <SectionDivider />
      <div className="scale-score">
        <div>
          <h4>Total Neuromuscular Maturity Score</h4>
        </div>
        <div className="score-tag">
          <span className="u-els-bold">{`Total Score: ${totalNeuromuscularMaturityScore}`}</span>
        </div>
      </div>
      <SectionDivider />
      <div className="scale-score">
        <div>
          <h4>Total Physical Maturity Score</h4>
        </div>
        <div className="score-tag">
          <span className="u-els-bold">{`Total Score: ${totalPhysicalMaturityScore}`}</span>
        </div>
      </div>
      <SectionDivider />
      <div className="scale-score">
        <div>
          <h4>Total Score</h4>
        </div>
        <div className="score-tag">
          <span className="u-els-bold">{`Total Score: ${totalScore}`}</span>
        </div>
      </div>
      <SectionDivider />

      <Box mt2>
        <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_GESTATIONAL_AGE_NEWBORN)} formSubmittedCount={formSubmittedCount}>
          <ELSFlex className="u-flex-gutter-1x" wrap left>
            <ELSFlexItem w="1o3" md="1o1">
              <h4 className="u-els-margin-bottom-1o2">{SectionTitle.NUTRITION}</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.NUTRITION_BREAST)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.NUTRITION_QUALITY)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.NUTRITION_BOTTLE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <h4 className="u-els-margin-bottom-1o2">{SectionTitle.ELIMINATION}</h4>
              <div className="newborn-assessment__elimination">
                <p>Void</p>
                <ErrorFormField formField={formFieldMap.get(FormField.ELIMINATION_VOID)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </div>
              <div className="newborn-assessment__elimination">
                <p>Stool</p>
                <ErrorFormField formField={formFieldMap.get(FormField.ELIMINATION_STOOL)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
              </div>
              <ErrorFormField className="u-els-margin-top" formField={formFieldMap.get(FormField.ELIMINATION_TYPE)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </ErrorFormField>
        <ErrorFormField formField={formFieldMap.get(FormField.CONTAINER_GESTATIONAL_AGE_FEMALE)} formSubmittedCount={formSubmittedCount}>
          <ELSFlex className="u-flex-gutter-1x" wrap left>
            <ELSFlexItem w="1o3" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.NUTRITIONDELIVERYROOM)} formSubmittedCount={formSubmittedCount} formFieldMap={formFieldMap} />
            </ELSFlexItem>
            <ELSFlexItem w="1o3" md="1o1">
              <h4 className="u-els-margin-bottom-1o2">Elimination</h4>
              <ErrorFormField formField={formFieldMap.get(FormField.ELIMINATION_FIRST_VOID)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField formField={formFieldMap.get(FormField.ELIMINATION_FIRST_STOOL)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            </ELSFlexItem>
          </ELSFlex>
        </ErrorFormField>
      </Box>
      <SectionDivider />
      <h2>Physical Examination</h2>
      <h4 className="u-els-margin-top-2x u-els-margin-bottom-1o2">{SectionTitle.INTEGUMENTARY}</h4>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_COLOR)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_COLOR_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_BIRTHMARKS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_BIRTHMARKS_LOCATION)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_MONGOLIAN_SPOTS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_MONGOLIAN_SPOTS_LOCATION)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_CHARACTERISTICS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.INTEGUMENTARY_TURGOR)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.GENERAL_ACTIVITY}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.ACTIVITY_MOVEMENT)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ACTIVITY_SLEEP_WAKE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.ACTIVITY_TONE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.ACTIVITY_CRY)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.HEAD_NECK}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.HEADNECK_HEAD)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField
            formField={formFieldMap.get(FormField.HEADNECK_HEAD_MOLDING1)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
            useDefaultMargin={false}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.HEADNECK_HEAD_MOLDING2)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.HEADNECK_HEAD_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.HEADNECK_FACIAL_MOVEMENT)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.EYES}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_POSITION_SPACING)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_DISCHARGE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_NARES)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_MUCOUS_MEMBRANES)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_MUCOUS_MEMBRANES_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_PUPILS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_PUPILS_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_POSITION_EARS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_MOUTH)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.EYES_MOUTH_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.CHEST_RESPIRATORY}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_SHAPE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_SHAPE_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_HEART_RHYTHM)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_HEART_RHYTHM_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_NIPPLES)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_NIPPLES_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.CHEST_RESPIRATORY_EFFORT)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.ABDOMEN_GASTROINTESTINAL}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <h4 className="u-els-margin-bottom-1o2">Abdomen</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN_GASTRO_SHAPE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN_GASTRO_BOWEL_SOUNDS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="2o3" md="1o1">
          <h4 className="u-els-margin-bottom-1o2">Umbilical Cord</h4>
          <ELSFlex>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN_GASTRO_UMBILICALCORDVESSEL_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={formFieldMap.get(FormField.ABDOMEN_GASTRO_UMBILICALCORDINTERVENTIONS_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.SPINE_NEUROLOGICAL}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.SPINE_NEURO)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.SPINE_NEURO_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.GENITALIA}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_SEX)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_TESTES_DESCENDED)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_URETHRA_PENIS)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_VAGINAL_OPENING)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_DISCHARGE_PRESENT)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_DISCHARGE_PRESENT_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <ErrorFormField
            formField={formFieldMap.get(FormField.GENITALIA_ANUS)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
            useDefaultMargin={false}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.GENITALIA_ANUSMECONIUM)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.MUSCULOSKELETAL}</h2>
      <ELSFlex className="u-flex-gutter-1x" wrap left>
        <ELSFlexItem w="1o3" md="1o1">
          <h4 className="u-els-margin-bottom-1o2">{SectionTitle.UPPER_EXTREMITIES}</h4>
          <ErrorFormField
            formField={formFieldMap.get(FormField.MUSCULOSKELETAL_UPPER_RANGE_MOTION)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_UPPER_RANGE_MOTION_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_UPPER_DIGITS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_UPPER_DIGITS_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_UPPER_CLAVICLES)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField
            formField={formFieldMap.get(FormField.MUSCULOSKELETAL_UPPER_CLAVICLES_CREPITUS)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
        </ELSFlexItem>
        <ELSFlexItem w="1o3" md="1o1">
          <h4 className="u-els-margin-bottom-1o2">{SectionTitle.LOWER_EXTREMITIES}</h4>
          <ErrorFormField
            formField={formFieldMap.get(FormField.MUSCULOSKELETAL_LOWER_RANGE_MOTION)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_LOWER_RANGE_MOTION_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_LOWER_DIGITS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_LOWER_DIGITS_OTHERBOX)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.MUSCULOSKELETAL_LOWER_HIPS)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>
      <SectionDivider />
      <h2 className="u-els-margin-bottom-2x">{SectionTitle.REFLEXES}</h2>
      <ErrorFormField formField={formFieldMap.get(FormField.REFLEXES)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.GENERAL_NOTES)} formSubmittedCount={formSubmittedCount} />
      <EmbeddedBallardMaturationalAssessment
        key={sidebarKey}
        isSidebarOpen={isSidebarOpen}
        onClose={handleCloseSidebar}
        selectedNavId={NAV_ID.BALLARD_MATURATIONAL_ASSESSMENT}
        onSave={onSaveEmbeddedChart}
        onDiscard={onDiscardEmbeddedChart}
        {...appHelper.getChartSharedProps(props)}
      />
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.DELIVERY_ROOM_SUMMARY]} />
    </div>
  );
};

export default NewbornAssessmentView;

export enum FormField {
  DIAGNOSIS_LIST = 'diagnosis',
  DIAGNOSIS_OTHER = 'diagnosisOther',
  DIAGNOSIS_TYPE = 'diagnosisType',
  PATHOPHYSIOLOGY = 'pathophysiology',
  THERAPEUTIC_REGIMEN = 'therapeuticRegimen',
  PROBLEMS_AND_CHANGES = 'problemsAndChanges'
}

export enum DIAGNOSIS_TYPES {
  PRIMARY = 'diagnosisTypePrimary',
  SECONDARY = 'diagnosisTypeSecondary',
  TERTIARY = 'diagnosisTypeTertiary'
}

export const DIAGNOSIS_LIST_OTHER = 'diagnosisListOther';

export enum Label {
  DIAGNOSIS = 'Diagnosis',
  SAVED_DIAGNOSIS = 'Saved Diagnosis',
  NOTHING_TO_SEE = 'Nothing to see, yet! Get started adding diagnoses.',
  PATHOPHYSIOLOGY = 'Pathophysiology',
  THERAPEUTIC_REGIMEN = 'Therapeutic Regimen',
  CURRENT_HEALTH_PROBLEMS_RELATED_FUNCTIONAL_CHANGES = 'Current Health Problems and Related Functional Changes',
  ADD_DIAGNOSIS = 'Add Diagnosis',
  CLINICAL_KEY_NAME = 'diagnoses'
}

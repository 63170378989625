import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, ChartSharedProps, PatientContext } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper } from 'helpers';
import { Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import HealthHistoryReadOnly from './HealthHistoryReadOnly';
import PreviousPregnancies from './PreviousPregnancies';
import PreviousPregnanciesSidebar from './PreviousPregnanciesSidebar';

interface HealthHistoryViewProps extends ChartSharedProps {
  isLocked: boolean;
  isSidebarOpen: boolean;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
  chartActionsProps: ChartActionsComponentProps;
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  formSubmittedCount: number;
  previousPregnanciesHistory: ChartFragment[];
  resetKey: number;
  addPregnancyInfo: Function;
  deletePregnancyInfo: Function;
  handleCloseSidebar: Function;
  onAddPregnancyInformationClick: Function;
  patientContext: PatientContext;
}

const HealthHistoryView = (props: HealthHistoryViewProps) => {
  const {
    isLocked,
    isSidebarOpen,
    fragment,
    statusFragment,
    formSubmittedCount,
    chartMetaFormFields,
    previousPregnanciesHistory,
    resetKey,
    handleCloseSidebar,
    onAddPregnancyInformationClick,
    addPregnancyInfo,
    deletePregnancyInfo,
    patientContext
  } = props;
  return (
    <div className="health-history">
      {isLocked ? (
        <HealthHistoryReadOnly chartFragment={fragment} statusFragment={statusFragment} patientContext={patientContext} />
      ) : (
        <>
          <h1>Health History</h1>
          <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />

          <ErrorFormField className="u-els-margin-top-2x" formField={chartMetaFormFields.get(FormField.CONTAINER_OBSTETRIC_HISTORY)} formSubmittedCount={formSubmittedCount}>
            <h2 className="u-els-margin-bottom">Obstetric History</h2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_ARE_YOU_PREGNANT)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_BABY_DUE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_COMFIRMED_ULTRASOUND)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_LAST_MENSTRUAL_PERIOD)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_ARE_YOU_BREAST_FEEDING)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_GRAVIDA)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_PARA)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_TERM)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_PRETERM)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_ABORTION)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.OBSTETRIC_HISTORY_LIVING)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ErrorFormField>

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_HISTORY_CURRENT_PREGNANCY)} formSubmittedCount={formSubmittedCount}>
            <SectionDivider />
            <h2 className="u-els-margin-bottom">History of Current Pregnancy</h2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_RECEIVED_PRENATAL_CARE)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_PROVIDER_NAME)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_RISK_FACTORS_IDENTIFIED)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_GROUP_B_STREPTOCOCCUS_STATUS)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_VAGINAL_DISCHARGE)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_PROBLEMS_IDENTIFIED)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_MULTIPLE_GESTATION)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_IS_FETUS_ACTIVE)}
                  formSubmittedCount={formSubmittedCount}
                  options={{ displayHorizontal: true }}
                />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.CURRENT_PREGNANCY_TIME_OF_LAST_ACTIVITY)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ErrorFormField>

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_HISTORY_CURRENT_PREGNANCY)} formSubmittedCount={formSubmittedCount}>
            <SectionDivider />
            <h2 className="u-els-margin-bottom">Last Oral Intake</h2>
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.LAST_ORAL_INTAKE_DAY)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.LAST_ORAL_INTAKE_DAY_DESCRIPTION)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.LAST_ORAL_INTAKE_TIME)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ErrorFormField>

          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_HISTORY_CURRENT_PREGNANCY)} formSubmittedCount={formSubmittedCount}>
            <SectionDivider />
            <h2 className="u-els-margin-bottom">Previous Pregnancies</h2>
            <PreviousPregnancies chartHistory={previousPregnanciesHistory} onDeleteClick={deletePregnancyInfo} />
            <ELSButton className="u-els-margin-top" type="secondary" text="Add Pregnancy Information" onClick={onAddPregnancyInformationClick} />
            <SectionDivider />
          </ErrorFormField>

          <ErrorFormField formField={chartMetaFormFields.get(FormField.NO_PREVIOUS_HEALTH_PROBLEM)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_ENVIRONMENT_HISTORY)} formSubmittedCount={formSubmittedCount}>
            <h2 className="u-els-margin-bottom">Environmental History</h2>
            <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_CONCERN)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_EXPOSE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_EXPLAIN)} formSubmittedCount={formSubmittedCount} />
            <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_MILITARY)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
            <ELSFlex className="u-flex-gutter-3x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_BRANCH)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_DATE)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_POSITION)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_DEPLOYMENT)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_ENVIRONMENTAL)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_INJURIES)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENV_HISTORY_ASSAULTS)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
            <SectionDivider />
          </ErrorFormField>

          <h2 className="u-els-margin-bottom">Travel</h2>
          <ErrorFormField formField={chartMetaFormFields.get(FormField.TRAVEL)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={chartMetaFormFields.get(FormField.TRAVEL_EXPLAIN)} formSubmittedCount={formSubmittedCount} />

          <SectionDivider />
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_MENTAL_HEALTH)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">Mental Health</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.MENTAL_HEALTH)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_GENETIC_DISORDERS)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.GENETIC_DISORDERS}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.GENETIC_DISORDERS)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_GENERAL)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.GENERAL}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.GENERAL)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>

            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Neurological Problems</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.NEUROLOGICAL_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_RESPIRATORY_PROBLEMS)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">Respiratory Problems</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.RESPIRATORY_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>

            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_RESPIRATORY_PROBLEMS_PEDIATRIC)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.RESPIRATORY_UPPER}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.RESPIRATORY_UPPER)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_RESPIRATORY_PROBLEMS_PEDIATRIC)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.RESPIRATORY_LOWER}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.RESPIRATORY_LOWER)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_RESPIRATORY_PROBLEMS_PEDIATRIC)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.RESPIRATORY_OTHER}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.RESPIRATORY_OTHER)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">{SectionTitle.CARDIOVASCULAR_PROBLEMS}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.CARDIOVASCULAR_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_CONGENITAL_HEART_DEFECT)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.CONGENITAL_HEART_DEFECT}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.CONGENITAL_HEART_DEFECT)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
          </ELSFlex>

          <SectionDivider />
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Sensory Impairment</h2>
              <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.SENSORY_HEARING)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField
                    className="error-field--no-bottom"
                    formField={chartMetaFormFields.get(FormField.SENSORY_HEARING_CHOICE)}
                    formSubmittedCount={formSubmittedCount}
                  />
                </ELSFlexItem>
                <ELSFlexItem w="1o2" md="1o1">
                  <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.SENSORY_VISUAL)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.SENSORY_VISUAL_CHOICE)} formSubmittedCount={formSubmittedCount} />
                </ELSFlexItem>
              </ELSFlex>
            </ELSFlexItem>
            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_INTEGUMENTARY_PROBLEMS)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">{SectionTitle.INTEGUMENTORY_PROBLEMS}</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.INTEGUMENTORY_PROBLEMS)} formSubmittedCount={formSubmittedCount} />
            </ErrorFormField>
          </ELSFlex>

          <SectionDivider />
          <h2 className="u-els-margin-bottom">Endocrine Disorders</h2>
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_THYROID_DISEASE)} formSubmittedCount={formSubmittedCount}>
                <h3 className="u-els-margin-bottom">Thyroid disease</h3>
                <div>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_THYROID)} formSubmittedCount={formSubmittedCount} />
                </div>
              </ErrorFormField>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_ENDOCRINE_DISORDERS)} formSubmittedCount={formSubmittedCount}>
                <h3 className="u-els-margin-bottom">Endocrine Disorders</h3>
                <div>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_DISORDERS)} formSubmittedCount={formSubmittedCount} />
                </div>
              </ErrorFormField>
              <h3 className="u-els-margin-bottom">Liver disease</h3>
              <div>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_LIVER)} formSubmittedCount={formSubmittedCount} />
              </div>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_OTHER_DISORDERS)} formSubmittedCount={formSubmittedCount}>
                <h3 className="u-els-margin-bottom">Other disorders</h3>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_OTHER)} formSubmittedCount={formSubmittedCount} />
              </ErrorFormField>
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_PITUITARY_DISORDER)} formSubmittedCount={formSubmittedCount}>
                <h3 className="u-els-margin-bottom">Pituitary disorder</h3>
                <div>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_PITUITARY)} formSubmittedCount={formSubmittedCount} />
                </div>
              </ErrorFormField>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.CONTAINER_DIABETES)} formSubmittedCount={formSubmittedCount}>
                <h3 className="u-els-margin-bottom">Diabetes</h3>
                <Box mb2>
                  <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.ENDOCRINE_DIABETES)} formSubmittedCount={formSubmittedCount} />
                </Box>
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_DIABETES_COMPLIANT)} formSubmittedCount={formSubmittedCount} />
                <ErrorFormField formField={chartMetaFormFields.get(FormField.ENDOCRINE_YEARS)} formSubmittedCount={formSubmittedCount} />
              </ErrorFormField>
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Gastrointestinal Problems</h2>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.GASTROINTESTINAL)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Hematologic/Immunologic Problems</h2>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HEMATOLOGIC)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.HEMATOLOGIC_CANCER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Genitourinary Problems</h2>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.GENITOURINARY)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>

            <ErrorFormField
              formField={chartMetaFormFields.get(FormField.CONTAINER_SEXUALITY_REPRODUCTIVE)}
              formSubmittedCount={formSubmittedCount}
              inputProps={{ flexItemProps: { w: '1o2', md: '1o1' } }}
            >
              <h2 className="u-els-margin-bottom">Sexuality/Reproductive</h2>
              <h3 className="u-els-margin-bottom">Reproductive problems</h3>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.SEXUALITY)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.SEXUALITY_ACTIVE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.SEXUALITY_NUMBER_PARTNERS)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.SEXUALITY_SEX_PARTNER)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.SEXUALITY_BIRTH_CONTROL)}
                formSubmittedCount={formSubmittedCount}
                options={{ displayHorizontal: true }}
              />
            </ErrorFormField>
          </ELSFlex>

          <SectionDivider />
          <ELSFlex className="u-flex-grid-2x u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Musculoskeletal Problems</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.MUSCULOSKELETAL)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h2>Previous Surgery</h2>
              <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.PREVIOUS_SURGERY_NONE)} formSubmittedCount={formSubmittedCount} />
              <Box mt>
                <ErrorFormField className="error-field--no-bottom" formField={chartMetaFormFields.get(FormField.PREVIOUS_SURGERY)} formSubmittedCount={formSubmittedCount} />
              </Box>
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Anesthesia Problems</h2>
              <ErrorFormField formField={chartMetaFormFields.get(FormField.ANESTHESIA)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
              <ErrorFormField
                className="chart-input__textarea--fixed-max-width"
                formField={chartMetaFormFields.get(FormField.ANESTHESIA_NOTE)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <h2 className="u-els-margin-bottom">Other Health History</h2>
              <ErrorFormField
                className="chart-input__textarea--fixed-max-width"
                formField={chartMetaFormFields.get(FormField.OTHER_HEALTH_HISTORY)}
                formSubmittedCount={formSubmittedCount}
              />
            </ELSFlexItem>
          </ELSFlex>

          <SectionDivider />
          <h2 className="u-els-margin-bottom">Recent Fever Requiring Antibiotics</h2>
          <ELSFlex className="u-flex-gutter-3x" wrap left>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.RECENT_FEVER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
            <ELSFlexItem w="1o2" md="1o1">
              <ErrorFormField formField={chartMetaFormFields.get(FormField.RECENT_FEVER_ONSET)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.RECENT_FEVER_ONSET_OTHER)} formSubmittedCount={formSubmittedCount} />
            </ELSFlexItem>
          </ELSFlex>

          <PreviousPregnanciesSidebar
            key={resetKey}
            isSidebarOpen={isSidebarOpen}
            selectedNavId={NAV_ID.HEALTH_HISTORY}
            handleCloseSidebar={handleCloseSidebar}
            addPregnancyInfo={addPregnancyInfo}
            {...appHelper.getChartSharedProps(props)}
          />
          <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
        </>
      )}
    </div>
  );
};

HealthHistoryView.displayName = 'HealthHistoryView';
export default HealthHistoryView;

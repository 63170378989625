import { ChartActions, ChartPermission, ErrorCard, ErrorFormField, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';
import './ivs.scss';
import { LoadableChartViewProps } from '../../shared/constants';

const IVsView = (props: LoadableChartViewProps) => {
  const { fragment, formFieldMap, formSubmittedCount } = props;

  return (
    <div className="intravenous">
      <h1>IVs</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.IV_DISCONTINUED)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.BODY_REGION)} formSubmittedCount={formSubmittedCount} inputProps={{ isDisabled: !!fragment }} />
      <ErrorFormField
        formField={formFieldMap.get(FormField.LOCATION)}
        formSubmittedCount={formSubmittedCount}
        inputProps={{ isDisabled: !!fragment || !formFieldMap.get(FormField.BODY_REGION)?.value }}
      />

      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.TIME_DISCONTINUED)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.CONDITION_OF_CATHETER)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.CONDITION_OF_CATHETER_DESCRIBE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.SITE_APPEARANCE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>

      <SectionDivider />

      <ELSFlex className="u-flex-gutter-3x" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField
            formField={formFieldMap.get(FormField.TYPE_IV_ACCESS)}
            formSubmittedCount={formSubmittedCount}
            options={{ displayHorizontal: true }}
            inputProps={{ disableRadioContent: () => !!fragment }}
          />
          <ErrorFormField formField={formFieldMap.get(FormField.CATHETER_TYPE)} formSubmittedCount={formSubmittedCount} options={{ displayHorizontal: true }} />
          <ErrorFormField formField={formFieldMap.get(FormField.CATHETER_SIZE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.NUMBER_INSERTION_ATTEMPTS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.TYPE_OF_LINE)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.NUMBER_OF_LUMENS)} formSubmittedCount={formSubmittedCount} />
          <ErrorFormField formField={formFieldMap.get(FormField.EXTERNAL_CATHETER_LENGTH)} formSubmittedCount={formSubmittedCount} useDefaultMargin={false} />
          <ErrorFormField formField={formFieldMap.get(FormField.EXTERNAL_CATHETER_NOT_APPLICABLE)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <ErrorFormField formField={formFieldMap.get(FormField.INSERTION_TIME)} formSubmittedCount={formSubmittedCount} />
        </ELSFlexItem>
      </ELSFlex>

      <ELSFlex className="u-flex-gutter-3x intravenous__sections" wrap left>
        <ELSFlexItem w="1o2" md="1o1">
          <h4>{SectionTitle.SKIN_ASSESSMENT}</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.SKIN_ASSESSMENT)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
          <h4>{SectionTitle.PATENCY}</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.PATENCY)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
        </ELSFlexItem>
        <ELSFlexItem w="1o2" md="1o1">
          <h4>{SectionTitle.DRESSING_CARE}</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.DRESSING_CARE)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
          <h4>{SectionTitle.PATIENT_RESPONSE}</h4>
          <ErrorFormField formField={formFieldMap.get(FormField.PATIENT_RESPONSE)} formSubmittedCount={formSubmittedCount} inputProps={{ disableEmptyDropdown: true }} />
        </ELSFlexItem>
      </ELSFlex>
      <ErrorFormField formField={formFieldMap.get(FormField.PUMP_IN_USE)} formSubmittedCount={formSubmittedCount} />
      <ErrorFormField formField={formFieldMap.get(FormField.NOTES)} formSubmittedCount={formSubmittedCount} />

      <ChartPermission>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...props.chartActionsProps} />}</ChartPermission>
    </div>
  );
};

IVsView.displayName = 'IVsView';
export default IVsView;

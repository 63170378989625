import { History } from 'history';
import { delay } from 'lodash';
import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment, MedicalHistoryFragment, OutdatedMedicalHistoryRecord } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField, PatientContext } from 'models/ui';
import { NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService, navigationService } from 'services';
import { buildPatientRecord } from 'services/chart.service';
import { withChartLogic, withFormUtilities } from 'components/common';
import { FormField, FormFieldLabel, SectionTitle } from './constants';
import MedicalHistoryView, { MedicalHistoryViewProps } from './MedicalHistoryView';

export const buildFormFields = (record?: MedicalHistoryFragment | OutdatedMedicalHistoryRecord): Map<string, ChartMetaFormField> => {
  const { createFormField, getFragmentContentIds, getFragmentValue } = chartHelper;
  const dataMap = new Map();

  const containers = [FormField.CONTAINER_GYNECOLOGICAL_HISTORY, FormField.CONTAINER_MENSTRUAL, FormField.CONTAINER_SEXUALLY_TRANSMITTED_DISEASE_HISTORY];

  const textBoxes = [
    {
      name: FormField.CHIEF_INFORMANT,
      label: FormFieldLabel.CHIEF_INFORMANT,
      sectionTitle: SectionTitle.PATIENT_INFORMATION
    },
    {
      name: FormField.CHIEF_COMPLAINT,
      label: FormFieldLabel.CHIEF_COMPLAINT,
      sectionTitle: SectionTitle.PATIENT_INFORMATION
    },
    {
      name: FormField.ALLERGIES,
      label: FormFieldLabel.ALLERGIES,
      sectionTitle: SectionTitle.PATIENT_INFORMATION
    },
    {
      name: FormField.OTHER_IMMUNIZATIONS,
      label: FormFieldLabel.OTHER_IMMUNIZATIONS,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.LAST_PAP_SMEAR,
      label: FormFieldLabel.LAST_PAP_SMEAR,
      sectionTitle: SectionTitle.GYNECOLOGICAL_HISTORY
    },
    {
      name: FormField.HISTORY_OF_ABNORMAL_PAP_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.GYNECOLOGICAL_HISTORY
    },
    {
      name: FormField.UTERINE_ABNORMALITY_DES_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.GYNECOLOGICAL_HISTORY
    },
    {
      name: FormField.LAST_MENSTRUAL_PERIOD,
      label: FormFieldLabel.LAST_MENSTRUAL_PERIOD,
      sectionTitle: SectionTitle.MENSTRUAL
    },
    {
      name: FormField.MENARCHE_AGE_OF_ONSET,
      label: FormFieldLabel.MENARCHE_AGE_OF_ONSET,
      sectionTitle: SectionTitle.MENSTRUAL
    },
    {
      name: FormField.CONTRACEPTION_HISTORY,
      label: FormFieldLabel.CONTRACEPTION_HISTORY,
      sectionTitle: SectionTitle.MENSTRUAL
    },
    {
      name: FormField.DATE_OF_CONCEPTION,
      label: FormFieldLabel.DATE_OF_CONCEPTION,
      sectionTitle: SectionTitle.MENSTRUAL
    },
    {
      name: FormField.GONORRHEA_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.CHLAMYDIA_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.CONGENITAL_HELPERS_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.SYPHILIS_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.HUMAN_IMMUNODEFICIENCY_VIRUS_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B_DESCRIBE,
      label: '',
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    }
  ];

  const textAreas = [
    {
      name: FormField.FAMILY_HISTORY,
      label: FormFieldLabel.FAMILY_HISTORY,
      sectionTitle: SectionTitle.PATIENT_INFORMATION,
      defaultValue: record?.chartData.patient?.[FormField.FAMILY_HISTORY]
    },
    {
      name: FormField.HISTORY_CURRENT_PROBLEM,
      label: FormFieldLabel.HISTORY_CURRENT_PROBLEM,
      sectionTitle: SectionTitle.PATIENT_INFORMATION,
      defaultValue: record?.chartData.patient?.[FormField.HISTORY_CURRENT_PROBLEM]
    },
    {
      name: FormField.PREVIOUS_ILLNESSES,
      label: FormFieldLabel.PREVIOUS_ILLNESSES,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.INJURIES_TRAUMA,
      label: FormFieldLabel.INJURIES_TRAUMA,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.DIETARY_HISTORY,
      label: FormFieldLabel.DIETARY_HISTORY,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.SOCIAL_HISTORY,
      label: FormFieldLabel.SOCIAL_HISTORY,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.CONTAGIOUS_DISEASES,
      label: FormFieldLabel.CONTAGIOUS_DISEASES,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.SURGICAL_HISTORY,
      label: FormFieldLabel.SURGICAL_HISTORY,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.OTHER,
      label: FormFieldLabel.OTHER,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.BIRTH_HISTORY,
      label: FormFieldLabel.BIRTH_HISTORY,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    },
    {
      name: FormField.CURRENT_MEDICATIONS,
      label: FormFieldLabel.CURRENT_MEDICATIONS,
      sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY
    }
  ];

  const radioChoices = [
    {
      name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS,
      label: FormFieldLabel.TETANUS_DIPHTHERIA_PERTUSSIS,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS,
      label: FormFieldLabel.IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.VARICELLA,
      label: FormFieldLabel.VARICELLA,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.ZOSTER,
      label: FormFieldLabel.ZOSTER,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.MEASLES_MUMPS_RUBELLA,
      label: FormFieldLabel.MEASLES_MUMPS_RUBELLA,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.INFLUENZA,
      label: FormFieldLabel.INFLUENZA,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.PNEUMOCOCCAL,
      label: FormFieldLabel.PNEUMOCOCCAL,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.HEPATITIS_A,
      label: FormFieldLabel.HEPATITIS_A,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.IMMUNIZATION_STATUS_HEPATITIS_B,
      label: FormFieldLabel.IMMUNIZATION_STATUS_HEPATITIS_B,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.MENINGOCOCCAL,
      label: FormFieldLabel.MENINGOCOCCAL,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.ROTAVIRUS,
      label: FormFieldLabel.ROTAVIRUS,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES,
      label: FormFieldLabel.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER,
      label: FormFieldLabel.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B,
      label: FormFieldLabel.HAEMOPHILUS_INFLUENZAE_TYPE_B,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.INACTIVATED_POLIO_VIRUS,
      label: FormFieldLabel.INACTIVATED_POLIO_VIRUS,
      sectionTitle: SectionTitle.IMMUNIZATIONS
    },
    {
      name: FormField.HISTORY_OF_ABNORMAL_PAP,
      label: FormFieldLabel.HISTORY_OF_ABNORMAL_PAP,
      sectionTitle: SectionTitle.GYNECOLOGICAL_HISTORY
    },
    {
      name: FormField.UTERINE_ABNORMALITY_DES,
      label: FormFieldLabel.UTERINE_ABNORMALITY_DES,
      sectionTitle: SectionTitle.GYNECOLOGICAL_HISTORY
    },
    {
      name: FormField.GONORRHEA,
      label: FormFieldLabel.GONORRHEA,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.CHLAMYDIA,
      label: FormFieldLabel.CHLAMYDIA,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.CONGENITAL_HELPERS,
      label: FormFieldLabel.CONGENITAL_HELPERS,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS,
      label: FormFieldLabel.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.SYPHILIS,
      label: FormFieldLabel.SYPHILIS,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.HUMAN_IMMUNODEFICIENCY_VIRUS,
      label: FormFieldLabel.HUMAN_IMMUNODEFICIENCY_VIRUS,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    },
    {
      name: FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B,
      label: FormFieldLabel.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B,
      sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY
    }
  ];

  containers.forEach((container) =>
    dataMap.set(
      container,
      createFormField({
        name: container,
        type: FormFieldInputType.CONTAINER
      })
    )
  );

  textBoxes.forEach(({ name, label, sectionTitle }) => {
    const defaultValue = record?.chartData.patient?.[name];
    dataMap.set(
      name,
      createFormField({
        name,
        type: FormFieldInputType.TEXT_BOX,
        label,
        value: getFragmentValue(record, sectionTitle, name, defaultValue)
      })
    );
  });

  textAreas.forEach(({ name, label, sectionTitle, defaultValue }) => {
    const finalDefaultValue = record?.chartData?.pastMedicalHistory?.[name] || defaultValue;
    const value = getFragmentValue(record, sectionTitle, name, finalDefaultValue);
    dataMap.set(
      name,
      createFormField({
        name,
        type: FormFieldInputType.TEXT_AREA,
        label,
        value
      })
    );
  });

  radioChoices.forEach(({ name, label, sectionTitle }) =>
    dataMap.set(
      name,
      createFormField({
        name,
        type: FormFieldInputType.RADIO_CHOICE,
        label,
        contentIds: getFragmentContentIds(record, sectionTitle, name)
      })
    )
  );

  return dataMap;
};

interface MedicalHistoryProps extends ChartComponentProps {
  history: History;
  patientContext: PatientContext;
}

interface MedicalHistoryStates {
  isLocked: boolean;
  statusFragment: ChartFragment;
  selectedRecord: MedicalHistoryFragment;
}

class MedicalHistory extends Component<MedicalHistoryProps, MedicalHistoryStates> {
  static displayName = 'MedicalHistory';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      statusFragment: null,
      selectedRecord: null
    };
  }

  componentDidMount() {
    const { assessment, isAuthor } = this.props;

    if (!isAuthor) {
      const navIds = [NAV_ID.HISTORY_PHYSICAL, NAV_ID.MEDICAL_HISTORY];
      const fragmentTypes = [FragmentType.CHARTING, FragmentType.AUTHORED, FragmentType.STATUS];

      appHelper.useLoader(chartService.loadFragments({ chartId: assessment.simChartId, navIds, fragmentTypes }).then(this.bindChartHistory), {
        errorMessage: 'can not load chart fragment'
      });
    }

    this.props.initState(buildFormFields());
  }

  bindChartHistory = ({ data: fragments }) => {
    const selectedRecord = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS);

    const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.MEDICAL_HISTORY, NAV_ID.HISTORY_PHYSICAL);

    this.setState({
      selectedRecord,
      statusFragment,
      isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId)
    });
    this.props.initState(buildFormFields(selectedRecord));
  };

  updateChartHistoryRecords = () => {
    const { formFieldMap } = this.props;
    const record = {
      chartTitle: 'History and Physical',
      fragmentTitle: 'Medical History',
      records: [
        {
          sectionTitle: SectionTitle.PATIENT_INFORMATION,
          records: [
            buildPatientRecord(formFieldMap, FormField.CHIEF_INFORMANT),
            buildPatientRecord(formFieldMap, FormField.CHIEF_COMPLAINT),
            buildPatientRecord(formFieldMap, FormField.ALLERGIES),
            buildPatientRecord(formFieldMap, FormField.FAMILY_HISTORY),
            buildPatientRecord(formFieldMap, FormField.HISTORY_CURRENT_PROBLEM)
          ]
        },
        {
          sectionTitle: SectionTitle.PAST_MEDICAL_HISTORY,
          records: [
            buildPatientRecord(formFieldMap, FormField.PREVIOUS_ILLNESSES),
            buildPatientRecord(formFieldMap, FormField.CONTAGIOUS_DISEASES),
            buildPatientRecord(formFieldMap, FormField.INJURIES_TRAUMA),
            buildPatientRecord(formFieldMap, FormField.SURGICAL_HISTORY),
            buildPatientRecord(formFieldMap, FormField.DIETARY_HISTORY),
            buildPatientRecord(formFieldMap, FormField.SOCIAL_HISTORY),
            buildPatientRecord(formFieldMap, FormField.CURRENT_MEDICATIONS),
            buildPatientRecord(formFieldMap, FormField.OTHER),
            buildPatientRecord(formFieldMap, FormField.BIRTH_HISTORY)
          ]
        },
        {
          sectionTitle: SectionTitle.IMMUNIZATIONS,
          records: [
            buildPatientRecord(formFieldMap, FormField.IMMUNIZATION_STATUS_HEPATITIS_B),
            buildPatientRecord(formFieldMap, FormField.ROTAVIRUS),
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES),
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER),
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS),
            buildPatientRecord(formFieldMap, FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B),
            buildPatientRecord(formFieldMap, FormField.INACTIVATED_POLIO_VIRUS),
            buildPatientRecord(formFieldMap, FormField.VARICELLA),
            buildPatientRecord(formFieldMap, FormField.IMMUNIZATION_STATUS_HUMAN_PAPILLOMAVIRUS),
            buildPatientRecord(formFieldMap, FormField.ZOSTER),
            buildPatientRecord(formFieldMap, FormField.MEASLES_MUMPS_RUBELLA),
            buildPatientRecord(formFieldMap, FormField.INFLUENZA),
            buildPatientRecord(formFieldMap, FormField.PNEUMOCOCCAL),
            buildPatientRecord(formFieldMap, FormField.HEPATITIS_A),
            buildPatientRecord(formFieldMap, FormField.MENINGOCOCCAL),
            buildPatientRecord(formFieldMap, FormField.OTHER_IMMUNIZATIONS)
          ]
        },
        {
          sectionTitle: SectionTitle.GYNECOLOGICAL_HISTORY,
          records: [
            buildPatientRecord(formFieldMap, FormField.LAST_PAP_SMEAR),
            buildPatientRecord(formFieldMap, FormField.HISTORY_OF_ABNORMAL_PAP),
            buildPatientRecord(formFieldMap, FormField.HISTORY_OF_ABNORMAL_PAP_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.UTERINE_ABNORMALITY_DES),
            buildPatientRecord(formFieldMap, FormField.UTERINE_ABNORMALITY_DES_DESCRIBE)
          ]
        },
        {
          sectionTitle: SectionTitle.MENSTRUAL,
          records: [
            buildPatientRecord(formFieldMap, FormField.LAST_MENSTRUAL_PERIOD),
            buildPatientRecord(formFieldMap, FormField.MENARCHE_AGE_OF_ONSET),
            buildPatientRecord(formFieldMap, FormField.CONTRACEPTION_HISTORY),
            buildPatientRecord(formFieldMap, FormField.DATE_OF_CONCEPTION)
          ]
        },
        {
          sectionTitle: SectionTitle.SEXUALLY_TRANSMITTED_DISEASE_HISTORY,
          records: [
            buildPatientRecord(formFieldMap, FormField.GONORRHEA),
            buildPatientRecord(formFieldMap, FormField.GONORRHEA_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.CHLAMYDIA),
            buildPatientRecord(formFieldMap, FormField.CHLAMYDIA_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.CONGENITAL_HELPERS),
            buildPatientRecord(formFieldMap, FormField.CONGENITAL_HELPERS_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS),
            buildPatientRecord(formFieldMap, FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HUMAN_PAPILLOMAVIRUS_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.SYPHILIS),
            buildPatientRecord(formFieldMap, FormField.SYPHILIS_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.HUMAN_IMMUNODEFICIENCY_VIRUS),
            buildPatientRecord(formFieldMap, FormField.HUMAN_IMMUNODEFICIENCY_VIRUS_DESCRIBE),
            buildPatientRecord(formFieldMap, FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B),
            buildPatientRecord(formFieldMap, FormField.SEXUALLY_TRANSMITTED_DISEASE_HISTORY_HEPATITIS_B_DESCRIBE)
          ]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.selectedRecord?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return {
      ...this.state.selectedRecord,
      ...basicInfo,
      chartData: cleanRecord
    };
  };

  navigateToHistoryAndPhysical = () => {
    if (!this.props.isAuthor) {
      navigationService.navigateToChart(RoutePath.student.providerChart.historyAndPhysical.landing, this.props.assessment.eolsAssessmentId);
    }
  };

  handleSaveClick = () => {
    this.props.saveChart([this.updateChartHistoryRecords()], { defaultFormFields: buildFormFields(), afterSave: this.afterSave });
  };

  afterSave = () =>
    this.props
      .loadChartData()
      .then(this.bindChartHistory)
      .then(() => delay(this.navigateToHistoryAndPhysical, REDIRECT_DELAY_TIME));

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.state.isLocked,
      onSaveClick: this.handleSaveClick,
      onCancelClick: this.navigateToHistoryAndPhysical,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: MedicalHistoryViewProps = {
      isLocked: this.state.isLocked,
      statusFragment: this.state.statusFragment,
      selectedRecord: this.state.selectedRecord,
      chartMetaFormFields: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      patientContext: this.props.patientContext,
      chartActionsProps
    };

    return <MedicalHistoryView {...viewProps} />;
  }
}

const enhancers = [withFormUtilities, withChartLogic];
export { MedicalHistory as BaseMedicalHistory };
export default compose(...enhancers)(MedicalHistory);

import cx from 'classnames';
import './avatar.scss';

interface AvatarProps {
  className?: string;
  name: string;
  image?: string;
}

const Avatar = (props: AvatarProps) => (
  <div className={cx('avatar__container', props.className)}>
    {!!props.image && (
      <div className="avatar__image">
        <img src={props.image} alt={props.name} />
      </div>
    )}
    {!props.image && <div className="avatar__name">{props.name}</div>}
  </div>
);

Avatar.displayName = 'Avatar';
export default Avatar;

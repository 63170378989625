import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { ChartLabel, NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import ActivityOccupationalTherapyView from './ActivityOccupationalTherapyView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export enum SectionTitle {
  ACTIVITY_OCCUPATIONAL_THERAPY = 'Activity - Occupational Therapy Screen',
  NEW_OCCUPATIONAL_THERAPY = 'New Occupational Therapy Problems',
  IMPAIRMENT_ENVIDENCE = 'Impairment Envidence'
}

export enum FormField {
  NO_NEW_OCCUPATIONAL = 'noNewOccupationalTherapyProblems',
  NEW_OCCUPATIONAL_THERAPY = 'newOccupationalTherapy',
  IMPAIRMENT_ENVIDENCE = 'impairmentEnvidence',
  OCCUPATIONAL_THERAPY_RESTRICTION = 'occupationalTherapyRestriction'
}

interface ActivityOccupationalTherapyProps extends ChartComponentProps {
  navigateToLandingPage: VoidFunction;
}

interface ActivityOccupationalTherapyState {
  isLocked: boolean;
  resetAll: number;
  fragment: ChartFragment;
  statusFragment: ChartFragment;
}

class ActivityOccupationalTherapy extends Component<ActivityOccupationalTherapyProps, ActivityOccupationalTherapyState> {
  static displayName = 'ActivityOccupationalTherapy';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      resetAll: 0,
      fragment: null,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.ACTIVITY_OCCUPATIONAL_THERAPY, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { fragment } = this.state;
    const { createFormField, getFragmentContentIds } = chartHelper;
    const dataMap = new Map();
    dataMap.set(
      FormField.NO_NEW_OCCUPATIONAL,
      createFormField({
        name: FormField.NO_NEW_OCCUPATIONAL,
        type: FormFieldInputType.CHECK_BOX,
        contentIds: getFragmentContentIds(fragment, SectionTitle.NEW_OCCUPATIONAL_THERAPY, FormField.NO_NEW_OCCUPATIONAL)
      })
    );
    dataMap.set(
      FormField.NEW_OCCUPATIONAL_THERAPY,
      createFormField({
        name: FormField.NEW_OCCUPATIONAL_THERAPY,
        type: FormFieldInputType.MULTISELECT_DROPDOWN,
        contentIds: getFragmentContentIds(fragment, SectionTitle.NEW_OCCUPATIONAL_THERAPY, FormField.NEW_OCCUPATIONAL_THERAPY)
      })
    );
    dataMap.set(
      FormField.OCCUPATIONAL_THERAPY_RESTRICTION,
      createFormField({
        name: FormField.OCCUPATIONAL_THERAPY_RESTRICTION,
        type: FormFieldInputType.RADIO_CHOICE,
        label: 'Does patient have a condition/order that restricts participation in OT? If Yes, patient must be medically cleared for occupational therapy.',
        contentIds: getFragmentContentIds(fragment, SectionTitle.ACTIVITY_OCCUPATIONAL_THERAPY, FormField.OCCUPATIONAL_THERAPY_RESTRICTION)
      })
    );
    dataMap.set(
      FormField.IMPAIRMENT_ENVIDENCE,
      createFormField({
        name: FormField.IMPAIRMENT_ENVIDENCE,
        type: FormFieldInputType.CHECK_BOX,
        label: ChartLabel.MARK_ALL_THAT_APPLY,
        contentIds: getFragmentContentIds(fragment, SectionTitle.IMPAIRMENT_ENVIDENCE, FormField.IMPAIRMENT_ENVIDENCE)
      })
    );
    return dataMap;
  };

  resetForm = () => this.setState((prevState) => ({ resetAll: prevState.resetAll + 1 }));

  handleSaveClick = () => {
    const { buildPatientRecord, buildPatientRecords } = chartService;
    const { formFieldMap, handleSaveClick } = this.props;
    const CHART_NAME = 'Activity - Occupational Therapy Screen';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.ACTIVITY_OCCUPATIONAL_THERAPY,
          records: [buildPatientRecord(formFieldMap, FormField.OCCUPATIONAL_THERAPY_RESTRICTION)]
        },
        {
          sectionTitle: SectionTitle.NEW_OCCUPATIONAL_THERAPY,
          records: [buildPatientRecord(formFieldMap, FormField.NO_NEW_OCCUPATIONAL), ...buildPatientRecords(formFieldMap, FormField.NEW_OCCUPATIONAL_THERAPY)]
        },
        {
          sectionTitle: SectionTitle.IMPAIRMENT_ENVIDENCE,
          records: [...buildPatientRecords(formFieldMap, FormField.IMPAIRMENT_ENVIDENCE)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    const fragmentData = { ...basicInfo, chartData: cleanRecord };
    handleSaveClick([fragmentData], { afterSave: this.props.afterSave });
  };

  handleCancelClick = () => {
    this.resetForm();
    this.props.initState(this.buildDefaultFormFields());
    appHelper.scrollTop();
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      isLocked,
      saveButtonText: 'Save and Continue',
      saveButtonHasIcon: true,
      cancelButtonText: 'Cancel',
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton
    };
    const viewProps = {
      isLocked,
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap
    };
    return <ActivityOccupationalTherapyView {...viewProps} />;
  }
}

export { ActivityOccupationalTherapy as BaseActivityOccupationalTherapy };
export default compose(withAdmissionHistory, withChartLogic)(ActivityOccupationalTherapy);

import { AssignmentType } from 'models/enum';
import { AssignmentWorkflowComponentProps } from 'models/ui';
import { CreateAssignmentWrapper, InstructorPageWrapper, withHTMLHeadSEO } from 'components/common';
import BlankChartingContent from 'components/features/assignment/create-assignment-workflow/blank-charting-content/BlankChartingContent';
import CaseStudyContent from 'components/features/assignment/create-assignment-workflow/case-study-content/CaseStudyContent';

const ContentSelectionPage = () => (
  <InstructorPageWrapper>
    {({ loggedInUser }) => (
      <CreateAssignmentWrapper>
        {(props: AssignmentWorkflowComponentProps) => (
          <>
            {AssignmentType.CASE_STUDY === props.newAssignment.assignmentType && <CaseStudyContent instructor={loggedInUser} {...props} />}
            {AssignmentType.EMPTY_EHR === props.newAssignment.assignmentType && <BlankChartingContent instructor={loggedInUser} {...props} />}
          </>
        )}
      </CreateAssignmentWrapper>
    )}
  </InstructorPageWrapper>
);

export { ContentSelectionPage as BaseContentSelectionPage };
export default withHTMLHeadSEO({ title: 'Add a SimChart item' })(ContentSelectionPage);

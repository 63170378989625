import { Component } from 'react';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { FormFieldInputType, FragmentType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { NAV_ID } from 'constants/app.constant';
import { appHelper, chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import ImmunizationsView from './ImmunizationsView';
import withAdmissionHistory from '../shared/withAdmissionHistory';

export enum SectionTitle {
  TETANUS_DIPHTHERIA_PERTUSSIS = 'Tetanus, Diphtheria, Pertussis (Td/Tdap)',
  HUMAN_PAPILLOMVIRUS = 'Human Papillomvirus (HPV)',
  VARICELLA = 'Varicella',
  ZOSTER = 'Zoster',
  MEASLES_MUMPS_RUBELLA = 'Measles, Mumps, Rubella (MMR)',
  INFLUENZA = 'Influenza',
  PNEUMOCOCCAL = 'Pneumococcal',
  HEPATITIS_A = 'Hepatitis A',
  HEPATITIS_B = 'Hepatitis B',
  MENINGOCOCCAL = 'Meningococcal',
  ROTAVIRUS = 'Rotavirus',
  TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES = 'Tetanus, Diphtheria, Pertussis (DTaP) - Primary Series',
  TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER = 'Tetanus, Diphtheria, Pertussis (DTaP) - Booster',
  HAEMOPHILUS_INFLUENZAE_TYPE_B = 'Haemophilus influenzae type b (Hib)',
  INACTIVATED_POLIO_VIRUS = 'Inactivated Polio Virus (IPV)'
}

export enum FormField {
  TETANUS_DIPHTHERIA_PERTUSSIS_OPTIONS = 'tetanusDiphtheriaPertussisOptions',
  TETANUS_DIPHTHERIA_PERTUSSIS_DATE_RECEIVED = 'tetanusDiphtheriaPertussisDateReceived',
  HUMAN_PAPILLOMVIRUS_OPTIONS = 'hpvOptions',
  HUMAN_PAPILLOMVIRUS_DATE_RECEIVED = 'hpvDateReceived',
  VARICELLA_OPTIONS = 'varicellaOptions',
  VARICELLA_DATE_RECEIVED = 'varicellaDateReceived',
  ZOSTER_OPTIONS = 'zosterOptions',
  ZOSTER_DATE_RECEIVED = 'zosterDateReceived',
  MEASLES_MUMPS_RUBELLA_OPTIONS = 'mmrOptions',
  MEASLES_MUMPS_RUBELLA_DATE_RECEIVED = 'mmrDateReceived',
  INFLUENZA_OPTIONS = 'influenzaOptions',
  INFLUENZA_DATE_RECEIVED = 'influenzaDateReceived',
  PNEUMOCOCCAL_OPTIONS = 'pneumococcalOptions',
  PNEUMOCOCCAL_DATE_RECEIVED = 'pneumococcalDateReceived',
  HEPATITIS_A_OPTIONS = 'hepatitisAOptions',
  HEPATITIS_A_DATE_RECEIVED = 'hepatitisADateReceived',
  HEPATITIS_B_OPTIONS = 'hepatitisBOptions',
  HEPATITIS_B_DATE_RECEIVED = 'hepatitisBDateReceived',
  MENINGOCOCCAL_OPTIONS = 'meningococcalOptions',
  MENINGOCOCCAL_DATE_RECEIVED = 'meningococcalDateReceived',
  ROTAVIRUS_OPTIONS = 'rotavirusOptions',
  ROTAVIRUS_DATE_RECEIVED = 'rotavirusDateReceived',
  TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_OPTIONS = 'tetanusDiphtheriaPertussisPrimarySeriesOptions',
  TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_DATE_RECEIVED = 'tetanusDiphtheriaPertussisPrimarySeriesDateReceived',
  TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_OPTIONS = 'tetanusDiphtheriaPertussisBoosterOptions',
  TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_DATE_RECEIVED = 'tetanusDiphtheriaPertussisBoosterDateReceived',
  HAEMOPHILUS_INFLUENZAE_TYPE_B_OPTIONS = 'hibOptions',
  HAEMOPHILUS_INFLUENZAE_TYPE_B_DATE_RECEIVED = 'hibDateReceived',
  INACTIVATED_POLIO_VIRUS_OPTIONS = 'ipvOptions',
  INACTIVATED_POLIO_VIRUS_DATE_RECEIVED = 'ipvDateReceived'
}

interface ImmunizationsProps extends ChartComponentProps {
  navigateToLandingPage: VoidFunction;
}

interface ImmunizationsState {
  fragment: ChartFragment;
  resetAll: number;
  isLocked: boolean;
  statusFragment: ChartFragment;
}

class Immunizations extends Component<ImmunizationsProps, ImmunizationsState> {
  static displayName = 'Immunizations';

  constructor(props) {
    super(props);
    this.state = {
      isLocked: false,
      fragment: null,
      resetAll: 0,
      statusFragment: null
    };
  }

  componentDidMount() {
    return appHelper.useLoader(
      this.props.loadChartData().then(({ data: fragments }) => {
        const chartFragment = fragments.find((fragment) => fragment.fragmentType !== FragmentType.STATUS) ?? null;
        const statusFragment = chartHelper.findStatusFragment(fragments, NAV_ID.IMMUNIZATIONS, NAV_ID.ADMISSION_HISTORY);
        this.setState({ fragment: chartFragment, statusFragment, isLocked: chartHelper.isChartLocked(fragments, this.props.selectedNavId) }, () => {
          this.props.initState(this.buildDefaultFormFields());
        });
      }),
      { errorMessage: 'can not load chart data' }
    );
  }

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { createFormField, getFragmentValue, getFragmentContentIds } = chartHelper;
    const { fragment } = this.state;
    const dateReceivedLabel = 'Date received';
    const dataMap = new Map();
    const radioChoices = [
      { name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_OPTIONS, sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS },
      { name: FormField.HUMAN_PAPILLOMVIRUS_OPTIONS, sectionTitle: SectionTitle.HUMAN_PAPILLOMVIRUS },
      { name: FormField.VARICELLA_OPTIONS, sectionTitle: SectionTitle.VARICELLA },
      { name: FormField.ZOSTER_OPTIONS, sectionTitle: SectionTitle.ZOSTER },
      { name: FormField.MEASLES_MUMPS_RUBELLA_OPTIONS, sectionTitle: SectionTitle.MEASLES_MUMPS_RUBELLA },
      { name: FormField.INFLUENZA_OPTIONS, sectionTitle: SectionTitle.INFLUENZA },
      { name: FormField.PNEUMOCOCCAL_OPTIONS, sectionTitle: SectionTitle.PNEUMOCOCCAL },
      { name: FormField.HEPATITIS_A_OPTIONS, sectionTitle: SectionTitle.HEPATITIS_A },
      { name: FormField.HEPATITIS_B_OPTIONS, sectionTitle: SectionTitle.HEPATITIS_B },
      { name: FormField.MENINGOCOCCAL_OPTIONS, sectionTitle: SectionTitle.MENINGOCOCCAL },
      { name: FormField.ROTAVIRUS_OPTIONS, sectionTitle: SectionTitle.ROTAVIRUS },
      { name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_OPTIONS, sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES },
      { name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_OPTIONS, sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER },
      { name: FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_OPTIONS, sectionTitle: SectionTitle.HAEMOPHILUS_INFLUENZAE_TYPE_B },
      { name: FormField.INACTIVATED_POLIO_VIRUS_OPTIONS, sectionTitle: SectionTitle.INACTIVATED_POLIO_VIRUS }
    ];
    const dates = [
      { name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS },
      { name: FormField.HUMAN_PAPILLOMVIRUS_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.HUMAN_PAPILLOMVIRUS },
      { name: FormField.VARICELLA_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.VARICELLA },
      { name: FormField.ZOSTER_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.ZOSTER },
      { name: FormField.MEASLES_MUMPS_RUBELLA_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.MEASLES_MUMPS_RUBELLA },
      { name: FormField.INFLUENZA_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.INFLUENZA },
      { name: FormField.PNEUMOCOCCAL_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.PNEUMOCOCCAL },
      { name: FormField.HEPATITIS_A_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.HEPATITIS_A },
      { name: FormField.HEPATITIS_B_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.HEPATITIS_B },
      { name: FormField.MENINGOCOCCAL_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.MENINGOCOCCAL },
      { name: FormField.ROTAVIRUS_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.ROTAVIRUS },
      {
        name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_DATE_RECEIVED,
        label: dateReceivedLabel,
        sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES
      },
      { name: FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER },
      { name: FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.HAEMOPHILUS_INFLUENZAE_TYPE_B },
      { name: FormField.INACTIVATED_POLIO_VIRUS_DATE_RECEIVED, label: dateReceivedLabel, sectionTitle: SectionTitle.INACTIVATED_POLIO_VIRUS }
    ];

    radioChoices.forEach(({ name, sectionTitle }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.RADIO_CHOICE, contentIds: getFragmentContentIds(fragment, sectionTitle, name) }))
    );

    dates.forEach(({ name, label, sectionTitle }) =>
      dataMap.set(name, createFormField({ name, type: FormFieldInputType.DATE, label, value: getFragmentValue(fragment, sectionTitle, name) }))
    );

    return dataMap;
  };

  handleSaveClick = () => {
    this.props.handleSaveClick([this.buildFragment()], { afterSave: this.props.afterSave });
  };

  buildFragment = () => {
    const { buildPatientRecord } = chartService;
    const { formFieldMap } = this.props;
    const CHART_NAME = 'Immunizations';
    const record = {
      chartTitle: 'Admission History',
      fragmentTitle: CHART_NAME,
      records: [
        {
          sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS,
          records: [
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_OPTIONS),
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_DATE_RECEIVED)
          ]
        },
        {
          sectionTitle: SectionTitle.HUMAN_PAPILLOMVIRUS,
          records: [buildPatientRecord(formFieldMap, FormField.HUMAN_PAPILLOMVIRUS_OPTIONS), buildPatientRecord(formFieldMap, FormField.HUMAN_PAPILLOMVIRUS_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.VARICELLA,
          records: [buildPatientRecord(formFieldMap, FormField.VARICELLA_OPTIONS), buildPatientRecord(formFieldMap, FormField.VARICELLA_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.ZOSTER,
          records: [buildPatientRecord(formFieldMap, FormField.ZOSTER_OPTIONS), buildPatientRecord(formFieldMap, FormField.ZOSTER_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.MEASLES_MUMPS_RUBELLA,
          records: [buildPatientRecord(formFieldMap, FormField.MEASLES_MUMPS_RUBELLA_OPTIONS), buildPatientRecord(formFieldMap, FormField.MEASLES_MUMPS_RUBELLA_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.INFLUENZA,
          records: [buildPatientRecord(formFieldMap, FormField.INFLUENZA_OPTIONS), buildPatientRecord(formFieldMap, FormField.INFLUENZA_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.PNEUMOCOCCAL,
          records: [buildPatientRecord(formFieldMap, FormField.PNEUMOCOCCAL_OPTIONS), buildPatientRecord(formFieldMap, FormField.PNEUMOCOCCAL_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.HEPATITIS_A,
          records: [buildPatientRecord(formFieldMap, FormField.HEPATITIS_A_OPTIONS), buildPatientRecord(formFieldMap, FormField.HEPATITIS_A_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.HEPATITIS_B,
          records: [buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_OPTIONS), buildPatientRecord(formFieldMap, FormField.HEPATITIS_B_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.MENINGOCOCCAL,
          records: [buildPatientRecord(formFieldMap, FormField.MENINGOCOCCAL_OPTIONS), buildPatientRecord(formFieldMap, FormField.MENINGOCOCCAL_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.ROTAVIRUS,
          records: [buildPatientRecord(formFieldMap, FormField.ROTAVIRUS_OPTIONS), buildPatientRecord(formFieldMap, FormField.ROTAVIRUS_DATE_RECEIVED)]
        },
        {
          sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES,
          records: [
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_OPTIONS),
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_PRIMARY_SERIES_DATE_RECEIVED)
          ]
        },
        {
          sectionTitle: SectionTitle.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER,
          records: [
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_OPTIONS),
            buildPatientRecord(formFieldMap, FormField.TETANUS_DIPHTHERIA_PERTUSSIS_BOOSTER_DATE_RECEIVED)
          ]
        },
        {
          sectionTitle: SectionTitle.HAEMOPHILUS_INFLUENZAE_TYPE_B,
          records: [
            buildPatientRecord(formFieldMap, FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_OPTIONS),
            buildPatientRecord(formFieldMap, FormField.HAEMOPHILUS_INFLUENZAE_TYPE_B_DATE_RECEIVED)
          ]
        },
        {
          sectionTitle: SectionTitle.INACTIVATED_POLIO_VIRUS,
          records: [buildPatientRecord(formFieldMap, FormField.INACTIVATED_POLIO_VIRUS_OPTIONS), buildPatientRecord(formFieldMap, FormField.INACTIVATED_POLIO_VIRUS_DATE_RECEIVED)]
        }
      ]
    };
    const cleanRecord = chartService.systemAssessment.removeEmptyRecords(record);
    const fragmentId = this.state.fragment?.fragmentId;
    const basicInfo = chartService.createBaseFragment({ fragmentId, chartingTime: this.props.chartingTime });
    return { ...basicInfo, chartData: cleanRecord } as ChartFragment;
  };

  handleCancelClick = () => {
    this.setState(
      (prevState) => ({
        resetAll: prevState.resetAll + 1
      }),
      () => {
        this.props.initState(this.buildDefaultFormFields());
        appHelper.scrollTop();
      }
    );
  };

  render() {
    const { isLocked, resetAll, fragment, statusFragment } = this.state;
    const { formFieldMap, formSubmittedCount, handleDiscardClick, displayAuthoringData, enableDisplayRecordsButton, hasUnsavedChanges } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => handleDiscardClick(this.handleCancelClick),
      onDisplayRecordsClick: displayAuthoringData,
      enableSaveButton: hasUnsavedChanges,
      enableDisplayRecordsButton,
      isLocked
    };

    const viewProps = {
      fragment,
      statusFragment,
      resetAll,
      chartActionsProps,
      formSubmittedCount,
      chartMetaFormFields: formFieldMap,
      isLocked
    };
    return <ImmunizationsView {...viewProps} />;
  }
}

export { Immunizations as BaseImmunizations };
export default compose(withAdmissionHistory, withChartLogic)(Immunizations);

import { delay, isEqual } from 'lodash';
import { Component } from 'react';
import { ImpressionFragment } from 'models/api-response';
import { FormFieldInputType } from 'models/enum';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { REDIRECT_DELAY_TIME } from 'constants/app.constant';
import { chartHelper } from 'helpers';
import { chartService } from 'services';
import { withChartLogic } from 'components/common';
import { FormField } from './Impressions';
import ImpressionsEditFormView, { ImpressionsEditFormViewProps } from './ImpressionsEditFormView';

export interface ImpressionsEditFormProps extends ChartComponentProps {
  isLocked: boolean;
  selectedNavId: string;
  selectedRecord?: ImpressionFragment;
  onSave: Function;
  onCancel: Function;
  onNavigate: Function;
}

export const buildFormFields = (record?: ImpressionFragment): Map<string, ChartMetaFormField> => {
  const { createFormField } = chartHelper;
  const dataMap = new Map();
  dataMap.set(
    FormField.IMPRESSIONS,
    createFormField({ name: FormField.IMPRESSIONS, type: FormFieldInputType.TEXT_AREA, label: 'Impressions', value: record?.chartData?.[FormField.IMPRESSIONS] || '' })
  );
  dataMap.set(FormField.PLAN, createFormField({ name: FormField.PLAN, type: FormFieldInputType.TEXT_AREA, label: 'Plan', value: record?.chartData?.[FormField.PLAN] || '' }));
  dataMap.set(
    FormField.SIGNATURE,
    createFormField({
      name: FormField.SIGNATURE,
      type: FormFieldInputType.TEXT_AREA,
      label: 'Provider Signature',
      value: record?.chartData?.[FormField.SIGNATURE] || ''
    })
  );
  dataMap.set(FormField.DAY, createFormField({ name: FormField.DAY, type: FormFieldInputType.DROPDOWN, label: 'Day', value: record?.chartData?.[FormField.DAY] || '' }));
  dataMap.set(FormField.TIME, createFormField({ name: FormField.TIME, type: FormFieldInputType.TIME, label: 'Time', value: record?.chartData?.[FormField.TIME] || '' }));
  return dataMap;
};

class ImpressionsEditForm extends Component<ImpressionsEditFormProps, null> {
  static displayName = 'ImpressionsEditForm';

  componentDidMount() {
    this.props.initState(buildFormFields());
  }

  componentDidUpdate(prevProps: Readonly<ImpressionsEditFormProps>) {
    if (this.props.selectedRecord && !isEqual(prevProps.selectedRecord, this.props.selectedRecord)) {
      this.props.initState(buildFormFields(this.props.selectedRecord));
    }
  }

  handleSave = () => {
    const { selectedRecord, formFieldMap } = this.props;
    this.props
      .onSave({
        ...selectedRecord,
        ...chartService.createBaseFragment({ chartingTime: this.props.chartingTime }),
        chartData: {
          ...selectedRecord?.chartData,
          [FormField.IMPRESSIONS]: formFieldMap.get(FormField.IMPRESSIONS)?.value,
          [FormField.PLAN]: formFieldMap.get(FormField.PLAN)?.value,
          [FormField.SIGNATURE]: formFieldMap.get(FormField.SIGNATURE)?.value,
          [FormField.DAY]: formFieldMap.get(FormField.DAY)?.value,
          [FormField.TIME]: formFieldMap.get(FormField.TIME)?.value
        }
      })
      .then(() => delay(this.props.onNavigate, REDIRECT_DELAY_TIME));
  };

  render() {
    const chartActionsProps: ChartActionsComponentProps = {
      saveButtonText: 'Save and Continue',
      cancelButtonText: 'Cancel',
      saveButtonHasIcon: true,
      isLocked: this.props.isLocked,
      onSaveClick: this.handleSave,
      onCancelClick: this.props.onCancel,
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: this.props.hasUnsavedChanges,
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton
    };

    const viewProps: ImpressionsEditFormViewProps = {
      fragment: this.props.selectedRecord,
      chartMetaFormFields: this.props.formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      chartActionsProps
    };
    return <ImpressionsEditFormView {...viewProps} />;
  }
}

export { ImpressionsEditForm as BaseImpressionsEditForm };
export default withChartLogic(ImpressionsEditForm);

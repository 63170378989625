import { LanguageKeys } from 'lang';
import { NAV_ID } from 'constants/app.constant';
import { Box, ChartActions, ErrorCard, ErrorFormField, RelatedCharts, SectionDivider } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { ChartViewProps } from 'components/features/shared/constants';
import { FormField } from './constants';

const IntegumentaryAssessmentView = (props: ChartViewProps) => {
  const { chartActionsProps, formFieldMap, formSubmittedCount } = props;

  return (
    <div className="integumentary-assessment">
      <h1>{LanguageKeys.INTEGUMENTARY_ASSESSMENT.INTEGUMENTARY_ASSESSMENT}</h1>
      <ErrorCard chartMetaFormFields={formFieldMap} showErrorCard={formSubmittedCount > 0} />
      <ErrorFormField formField={formFieldMap.get(FormField.NO_ASSESSMENT_REQUIRED)} formSubmittedCount={formSubmittedCount} />
      <Box mt>
        <Box mt2 mb>
          <h2>{LanguageKeys.INTEGUMENTARY_ASSESSMENT.SKIN_ASSESSMENT}</h2>
        </Box>
        <ELSFlex className="u-flex-gutter-2x u-els-margin-bottom-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.SKIN_COLOR)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-2x u-flex-grid-1x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-2x u-flex-grid-1x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.SKIN_TURGOR)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.TEMPERATURE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
          <ELSFlexItem w="1o2" md="1o1">
            <ELSFlex className="u-flex-gutter-2x u-flex-grid-1x" wrap left>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.INTEGRITY)} formSubmittedCount={formSubmittedCount} />
                <div className="u-els-margin-top-1o2 u-els-color-n3">
                  <p>Document characteristics and care in graphical body: Wounds</p>
                </div>
              </ELSFlexItem>
              <ELSFlexItem w="1o2" md="1o1">
                <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.MOISTURE)} formSubmittedCount={formSubmittedCount} />
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-gutter-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.SKIN_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{LanguageKeys.INTEGUMENTARY_ASSESSMENT.HAIR_ASSESSMENT}</h2>
        <div>
          <p>Note: Assess hair on admission. Reassess only if change is noted.</p>
        </div>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <Box mt2>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.DISTRIBUTION)} formSubmittedCount={formSubmittedCount} />
            </Box>
            <Box mt2>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.CHARACTERISTICS)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem id="hair-assessment-health-wrapper" w="2o3" md="1o1">
            <Box mt2>
              <div className="u-els-margin-bottom-1o2">
                <p>Health</p>
              </div>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.HEALTH)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.HAIR_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>

        <SectionDivider />

        <h2>{LanguageKeys.INTEGUMENTARY_ASSESSMENT.NAIL_ASSESSMENT}</h2>
        <div>
          <p>Note: Assess nails on admission. Reassess only if change is noted.</p>
        </div>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o3" md="1o1">
            <Box mt2>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.CONFIGURATION)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <Box mt2>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.CONDITION)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField
                className="error-field--no-bottom u-els-margin-top-1x"
                formField={formFieldMap.get(FormField.CONDITION_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </Box>
          </ELSFlexItem>
          <ELSFlexItem w="1o3" md="1o1">
            <Box mt2>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.NAIL_COLOR)} formSubmittedCount={formSubmittedCount} />
            </Box>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <Box mt2>
              <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.NAIL_BASE)} formSubmittedCount={formSubmittedCount} />
              <ErrorFormField
                className="error-field--no-bottom u-els-margin-top-1x"
                formField={formFieldMap.get(FormField.NAIL_BASE_OTHER)}
                formSubmittedCount={formSubmittedCount}
              />
            </Box>
          </ELSFlexItem>
        </ELSFlex>
        <ELSFlex className="u-flex-grid-2x" wrap left>
          <ELSFlexItem w="1o2" md="1o1">
            <ErrorFormField className="error-field--no-bottom" formField={formFieldMap.get(FormField.NAIL_NOTES)} formSubmittedCount={formSubmittedCount} />
          </ELSFlexItem>
        </ELSFlex>
      </Box>
      <ChartActions {...chartActionsProps} />
      <RelatedCharts chartIds={[NAV_ID.INTEGUMENTARY_INTERVENTIONS, NAV_ID.BRADEN_SCALE]} />
    </div>
  );
};

IntegumentaryAssessmentView.displayName = 'IntegumentaryAssessmentView';
export default IntegumentaryAssessmentView;

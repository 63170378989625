import { appHelper } from 'helpers';
import { logService } from 'services';
import { Box, ButtonLinkIcon } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';

enum Grading {
  NOT_GRADED = 'Not Graded',
  PASS_FAIL = 'Pass / Fail',
  SCORED = 'Scored'
}

export interface CaseStudyOverviewProps {
  diagnosis: string;
  chapter: string;
  totalTimeLimit: number;
  totalPhases: number;
  concept: string;
  learningObjective: string;
  isBookOrganized: boolean;
}

const CaseStudyOverview = (props: CaseStudyOverviewProps) => (
  <div className="quick-view__tab-body">
    <Box mt2>
      <p>Diagnosis</p>
      <h4>{props.diagnosis}</h4>
    </Box>
    <ELSFlex className="u-els-margin-top" left wrap>
      {props.isBookOrganized && (
        <ELSFlexItem w="1o2">
          <p>Chapter</p>
          <h4 className="u-els-display-inline-block">{props.chapter}</h4>
          <div className="quick-view__icon">
            <ButtonLinkIcon iconName="information-hollow" text={null} onClick={() => logService.log('Implement soon')} />
          </div>
        </ELSFlexItem>
      )}
      <ELSFlexItem w="1o2">
        <p>Estimated time to complete</p>
        <h4>{`${props.totalTimeLimit} min (${props.totalPhases} ${appHelper.toSinglePlural(props.totalPhases, 'Phase', 'Phases')})`}</h4>
      </ELSFlexItem>
    </ELSFlex>
    <Box mt>
      <p>Concept</p>
      <h4>{props.concept}</h4>
    </Box>
    <Box mt>
      <p>Learning Objective</p>
      <h4>{props.learningObjective}</h4>
    </Box>
    <Box mt mb2>
      <b>Grading options</b>
      <p>{`• ${Grading.NOT_GRADED}`}</p>
      <div className="c-els-divider" />
      <p>{`• ${Grading.PASS_FAIL}`}</p>
      <div className="c-els-divider" />
      <p>{`• ${Grading.SCORED}`}</p>
      <div className="c-els-divider" />
    </Box>
  </div>
);

CaseStudyOverview.displayName = 'CaseStudyOverview';
export default CaseStudyOverview;

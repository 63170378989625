import { CarePlanRecord, ChartFragmentRS, InterventionItem } from 'models/api-response';
import { ChartActionsComponentProps, ChartMetaFormField, DropDownItem } from 'models/ui';
import { appHelper } from 'helpers';
import { Box } from 'components/common';
import { SectionTitle } from './constants';
import CarePlanFormView, { CarePlanFormViewProps } from './CarePlanFormView';
import CarePlanHistoryView from './CarePlanHistoryView';
import CarePlanSidebar from './CarePlanSidebar';
import './care.plan.scss';

export interface CarePlanViewProps {
  chartHistory: CarePlanRecord[];
  chartActionsProps: ChartActionsComponentProps;
  formFieldMap: Map<string, ChartMetaFormField>;
  saveChartData: (payload: object) => Promise<ChartFragmentRS>;
  formSubmittedCount: number;
  priorityItems: DropDownItem[];
  interventionItems: InterventionItem[];
  selectedCarePlan: CarePlanRecord;
  isDisplayDocument: boolean;
  outcomeEvaluationStatus: string;
  onFormFieldChange: Function;
  onEditClick: Function;
  onSidebarClose: Function;
  onDeleteClick: Function;
  onCarePlanUpdate: Function;
  onDisplayDocument: Function;
  onPriorityChange: Function;
  onAddInterventionItem: Function;
  focusedInterventionFieldId: string;
  resetFocusedInterventionFieldId: Function;
}
const CarePlanView = (props: CarePlanViewProps) => {
  const {
    selectedCarePlan,
    chartHistory,
    priorityItems,
    interventionItems,
    isDisplayDocument,
    outcomeEvaluationStatus,
    chartActionsProps,
    formFieldMap,
    formSubmittedCount,
    saveChartData,
    onEditClick,
    onSidebarClose,
    onCarePlanUpdate,
    onDeleteClick,
    onDisplayDocument,
    onFormFieldChange,
    onPriorityChange,
    onAddInterventionItem,
    focusedInterventionFieldId,
    resetFocusedInterventionFieldId
  } = props;
  const sidebarProps = {
    selectedRecord: selectedCarePlan,
    onClose: onSidebarClose,
    onUpdate: onCarePlanUpdate,
    saveChartData,
    ...appHelper.getChartSharedProps(props)
  };
  const carePlanHistoryViewProps = { chartHistory, onEdit: onEditClick, onDelete: onDeleteClick, priorityItems, onPriorityChange };
  const carePlanFormViewProps: CarePlanFormViewProps = {
    formFieldMap,
    formSubmittedCount,
    chartActionsProps,
    interventionItems,
    outcomeEvaluationStatus,
    onFormFieldChange,
    isDisplayDocument,
    onDisplayDocument,
    onAddInterventionItem,
    focusedInterventionFieldId,
    resetFocusedInterventionFieldId
  };
  return (
    <div className="care-plan-manager__page">
      <h1 className="u-els-margin-bottom-2x">{SectionTitle.CARE_PLAN}</h1>
      <Box mt2 className="care-plan__save-section">
        <h2 className="u-els-margin-bottom-2x">{SectionTitle.SAVED_CARE_PLAN}</h2>
        <CarePlanHistoryView {...carePlanHistoryViewProps} />
      </Box>
      <Box mt2>
        <h2 className="u-els-margin-bottom-2x">{SectionTitle.ADD_CARE_PLAN}</h2>
        <CarePlanFormView {...carePlanFormViewProps} />
      </Box>
      <CarePlanSidebar {...sidebarProps} />
    </div>
  );
};
CarePlanView.displayName = 'CarePlanView';
export default CarePlanView;

/* eslint-disable class-methods-use-this */
import BaseValidator, { ExecuteParams } from './interface';

export default class SelectionValidator implements BaseValidator {
  isReverse: boolean;

  constructor(isReverse = false) {
    this.isReverse = isReverse;
  }

  execute({ ruleInfo, field }: ExecuteParams): boolean {
    const content = field.chartContent?.find((item) => item.value === ruleInfo.chartFieldSelectionValue);

    let result = false;
    if (content) {
      result = content.selected;
    }
    if (this.isReverse) {
      result = !result;
    }
    return result;
  }
}

import { ActivitiesDailyLivingFragment } from 'models/api-response';
import { Box } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import MultiLineWithEmpty from 'components/features/chart/pre-clinical-manager/shared/MultiLineWithEmpty';
import { FormField, SectionTitle } from './constants';

export interface ActivitiesDailyLivingReadOnlyProps {
  selectedRecord: ActivitiesDailyLivingFragment;
}

const ActivitiesDailyLivingReadonly = (props: ActivitiesDailyLivingReadOnlyProps) => {
  const record = props.selectedRecord;
  return (
    <>
      <h2>{SectionTitle.ACTIVITIES_DAILY_LIVING}</h2>
      <Box className="sng-article">
        {!record ? (
          <h3>Chart Omitted</h3>
        ) : (
          <>
            <ELSFlex wrap left className="u-flex-gutter-3x u-flex-row-gap-2x">
              <ELSFlexItem w="1o3">
                <h4>Sleep:</h4>
                <MultiLineWithEmpty text={record.chartData?.[FormField.SLEEP]?.content} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Exercise and Activities:</h4>
                <MultiLineWithEmpty text={record.chartData?.[FormField.EXERCISE_ACTIVITIES]?.content} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Elimination of Stool and Urine:</h4>
                <MultiLineWithEmpty text={record.chartData?.[FormField.ELIMINATION_STOOL_URINE]?.content} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Safety Assessment and Issues:</h4>
                <MultiLineWithEmpty text={record.chartData?.[FormField.SAFETY_ASSESSMENT_ISSUES]?.content} />
              </ELSFlexItem>
              <ELSFlexItem w="1o3">
                <h4>Other:</h4>
                <MultiLineWithEmpty text={record.chartData?.[FormField.OTHER]?.content} />
              </ELSFlexItem>
            </ELSFlex>
          </>
        )}
      </Box>
    </>
  );
};
export default ActivitiesDailyLivingReadonly;
